import classNames from 'classnames';
import React from 'react';
import { FormFeedback, FormGroup } from 'reactstrap';
import { Radio } from 'semantic-ui-react';

const ToggleComponent = (field) => {
  const { className, input, onChange, serverError, meta: { touched, error } } = field;

  // if (input.value === '') input.value = false;
  const handleChange = (val) => {
    input.onChange(!val);
    if (onChange) onChange(val);
  };

  return (
    <div className={classNames('d-inline-flex align-items-center', className)}>
      <Radio
        {...field}
        toggle
        checked={input.value}
        value={input.value}
        onChange={(e, {value}) => handleChange(value)}
      />
      {touched && error && <FormFeedback>{error}</FormFeedback>}
      {(serverError) && <FormFeedback>{serverError}</FormFeedback>}
    </div>
  );
};

export default ToggleComponent;
