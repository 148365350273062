import * as types from "../../actions/actionTypes";

const initialState = {
  location_list: null,
  location_error: null,
};

export default function gmbListingCreditUsageHistoryReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.GET_GMB_LOCATION_CREDIT_USAGE_HISTORY_SUCCESS:
      return { ...state, credit_usage_history_data: action.data };
    case types.GET_GMB_LOCATION_CREDIT_USAGE_HISTORY_FAILURE:
      return { ...state, credit_usage_history_error: action.data };

    
    default:
      return state;
  }
}
