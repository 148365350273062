import React, { useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { Dropdown, ListDescription } from "semantic-ui-react";
import FPInput from "../../common/new/forms/ui/FPInput";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import "./listing.css";
import AddIcon from "@material-ui/icons/Add";
import GoogleIcon from "@mui/icons-material/Google";
import google_svg from "../../../images/googlephoto.svg";
import warning_svg from "../../../images/warning.svg";
import emoji_svg from "../../../images/emoji.svg";
import { getGmbListingUpdateListing } from "../../../actions/listing/listingLocationAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import delete_png from "../../../images/delete_png.webp";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import { formatReviews } from "../../../libs/helper";
import duplicate_svg from "../../../images/dusplicate.svg";
import ListingFPInput from "../../common/new/Listing/ListingFPInput";
import * as listingUtils from "../../../libs/listingToast";

const UpdateCategory = (props) => {
  const { gmc_categories_data, getGmbListingUpdateListing, business_id, primaryDetail, selectReso, actions } = props;

  const addressString = [primaryDetail?.addressLines, primaryDetail?.locality, primaryDetail?.postalCode].join(", ");

  const [dropdownValues, setDropdownValues] = useState([""]);
  const [additionalCategories, setAdditionalCategories] = useState([]);

  React.useEffect(() => {
    if (primaryDetail?.category_name?.additional_category_list?.length > 0) {
      setAdditionalCategories(primaryDetail?.category_name?.additional_category_list);
    } else {
      setAdditionalCategories([]);
    }
  }, [primaryDetail?.category_name]);

  const handleDropdownChange = (index, value) => {
    const newValues = [...dropdownValues];
    newValues[index] = value;
    setDropdownValues(newValues);
  };

  const handleAddClick = () => {
    setDropdownValues([...dropdownValues, ""]);
  };

  const handleDeleteClick = (index) => {
    const newValues = [...dropdownValues];
    newValues.splice(index, 1);
    setDropdownValues(newValues);
  };

  const handleAdditionalDeleteClick = (category) => {
    setAdditionalCategories(additionalCategories?.filter((item) => item?.id !== category?.id));
  };

  const categoryOptions = gmc_categories_data?.categories?.map((category) => ({
    key: category?.name,
    text: category?.displayName,
    value: category, // Adjust if value should be different
  }));

  const handleSaveClick = async () => {
    const extractFields = (category) => ({
      displayName: category.displayName,
      moreHoursTypes: category.moreHoursTypes,
      name: category.name,
    });

    const formattedDropdownValues = dropdownValues[0] !== "" ? dropdownValues?.map(extractFields) : [];
    const formattedAdditionalCategories =
      additionalCategories?.length > 0 ? additionalCategories.map(extractFields) : [];

    const mergedAdditionalCategories = [...formattedDropdownValues, ...formattedAdditionalCategories];

    const data = {
      type: "category",
      additionalCategories: mergedAdditionalCategories,
    };

    const restodetail = {
      business_id,
      selectReso,
    };

    await getGmbListingUpdateListing(data, restodetail);

    listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    await actions.getGmbListingRefreshListing(business_id, selectReso);

    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };

  return (
    <>
      <div className="listing-container" style={{ overflow: "hidden", gap: "32px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img
                  src={
                    primaryDetail?.is_varified
                      ? verified_svg
                      : primaryDetail?.is_suspend
                      ? duplicate_svg
                      : primaryDetail?.is_duplicate
                      ? duplicate_svg
                      : ""
                  }
                  alt="verifiedImage"
                />
              </div>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{formatReviews(primaryDetail?.totalReviewCount)} Reviews</span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>{addressString}</ListDescription>
          </div>
        </div>
      </div>
      <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "15px",
                paddingBottom: "5px",
              }}
            >
              <div className="listing-name">Select the categories that best describe your business</div>
              <div className="d-flex flex-column" style={{ gap: "4px" }}>
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#1B1C1D",
                  }}
                >
                  Primary Category
                </div>
                <ListingFPInput type="text" value={primaryDetail?.category_name?.primary} readOnly />
              </div>
              <div
                style={{
                  backgroundColor: "#FEE4E2",
                  borderRadius: "12px",
                  padding: "8px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <img
                  src={warning_svg}
                  alt="warning-svg"
                  style={{
                    height: "16px",
                    width: "16px",
                  }}
                />
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#F04438",
                  }}
                >
                  Primary Category change might lead to suspension or re-verification Business profile. If you are sure,
                  please make the changes directly from Google Business Profile
                </p>
              </div>
              <div className="d-flex justify-content-end">
                <a href={primaryDetail?.mapsUri} target="_blank" rel="noreferrer">
                  <FPButton label="Edit on Google" size="small" startIcon={<GoogleIcon />} />
                </a>
              </div>
              <div className="d-flex flex-column" style={{ gap: "32px" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <h2
                    style={{
                      fontWeight: "500",
                      fontSize: "18px",
                      color: "#1B1C1D",
                    }}
                  >
                    Additional Categories (Maximum 9)
                  </h2>
                  <FPButton
                    label="AI suggested additional categories"
                    size="small"
                    startIcon={<img src={emoji_svg} alt="emojiSvg" />}
                  />
                </div>
                <div style={{ display: "grid", gap: "10px" }}>
                  {additionalCategories.length > 0 &&
                    additionalCategories.map((category, index) => (
                      <div style={{ display: "flex", alignItems: "center" }} key={index}>
                        <FPInput type="text" placeholder="Restaurant" value={category.displayName} />
                        <img
                          src={delete_png}
                          alt="deletePng"
                          style={{
                            height: "16px",
                            width: "16px",
                            color: "#FFFFFF",
                            cursor: "pointer",
                          }}
                          onClick={() => handleAdditionalDeleteClick(category)}
                        />
                      </div>
                    ))}
                  {dropdownValues?.map((value, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <Dropdown
                        fluid
                        search
                        selection
                        options={categoryOptions}
                        value={value}
                        onChange={(e, { value }) => handleDropdownChange(index, value)}
                        placeholder={`Additional Category ${index + 1}`}
                      />
                      {index !== dropdownValues.length - 1 ? (
                        <img
                          src={delete_png}
                          alt="deletePng"
                          style={{
                            height: "16px",
                            width: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDeleteClick(index)}
                        />
                      ) : additionalCategories?.length + dropdownValues?.length < 9 ? (
                        <AddIcon
                          style={{
                            color: "#BFC2CB",
                            fontSize: "18px",
                            cursor: "pointer",
                          }}
                          onClick={handleAddClick}
                        />
                      ) : (
                        <img
                          src={delete_png}
                          alt="deletePng"
                          style={{
                            height: "16px",
                            width: "16px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDeleteClick(index)}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                // paddingBottom: "10px",
                // paddingTop: "10px",
                paddingBlock: "8px",
              }}
            >
              <FPButton label="Update" size="small" onClick={handleSaveClick} disabled={primaryDetail?.is_suspend} />
            </div>
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    location_primary_detail: state?.gmbListingLocation?.location_primary_detail,
    refresh_listing_data: state?.gmbListingLocation?.refresh_listing_data,
    refresh_listing_error: state?.gmbListingLocation?.refresh_listing_error,
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getGmbListingUpdateListing: bindActionCreators(getGmbListingUpdateListing, dispatch),
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCategory);
