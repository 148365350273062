import axios_instance from '../../libs/interseptor';
import * as types from '../../actions/actionTypes';
import * as URLS from '../../libs/apiUrls';


export const  showLoader = () =>{
  return {'type': types.SHOW_LOADER};
};

export const  hideLoader = () => {
  return{'type':types.HIDE_LOADER};
};

const ratingOptionsSucess = (data) =>{
  return {'type': types.RATING_OPTIONS_SUCCESS, data:data};
};

const ratingOptionFailure = (data) => {
  return{'type':types.RATING_OPTIONS_FAILURE, data:data};
};

export const rationsOptions = () => {
  return (dispatch) => {
    dispatch( showLoader() );
    axios_instance.get(URLS.RATING_OPTIONS)
      .then((res) => {
        dispatch( hideLoader() );
        dispatch(ratingOptionsSucess(res.data));
      })
      .catch((error) => {
        dispatch( hideLoader() );
        dispatch(ratingOptionFailure(error));
      });
  };
};

const sentimentOptionsSucess = (data) =>{
  return {'type': types.SENTIMENT_OPTIONS_SUCCESS, data:data};
};

const sentimentOptionsFailure = (data) => {
  return{'type':types.SENTIMENT_OPTIONS_FAILURE, data:data};
};

export const sentimentOptions = () => {
  return (dispatch) => {
    dispatch( showLoader() );
    axios_instance.get(URLS.SENTIMENT_OPTIONS)
      .then((res) => {
        dispatch( hideLoader() );
        dispatch(sentimentOptionsSucess(res.data));
      })
      .catch((error) => {
        dispatch( hideLoader() );
        dispatch(sentimentOptionsFailure(error));
      });
  };
};

const cityOptionsSuccess = (data) => {
  return {'type':types.CITY_OPTIONS_SUCCESS,data:data};
};

const cityOptionsFailure = (data) => {
  return {'type':types.CITY_OPTIONS_FAILURE,data:data};
};

export const cityOptions = (business_id) => {
  const url = URLS.BUSINESS_SETUP+business_id+'/address/city/';
  return (dispatch) => {
    dispatch(showLoader());
    axios_instance.get(url)
      .then((res)=>{
        dispatch(hideLoader());
        dispatch(cityOptionsSuccess(res.data));
      })
      .catch((error)=>{
        dispatch(hideLoader());
        dispatch(cityOptionsFailure(error));
      });
  };
};

const stateOptionsSuccess = (data) => {
  return {'type':types.STATE_OPTIONS_SUCCESS,data:data};
};

const stateOptionsFailure = (data) => {
  return {'type':types.STATE_OPTIONS_FAILURE,data:data};
};

export const stateOptions = (business_id) => {
  const url = URLS.BUSINESS_SETUP+business_id+'/address/state/';
  return (dispatch) => {
    dispatch(showLoader());
    axios_instance.get(url)
      .then((res)=>{
        dispatch(hideLoader());
        dispatch(stateOptionsSuccess(res.data));
      })
      .catch((error)=>{
        dispatch(hideLoader());
        dispatch(stateOptionsFailure(error));
      });
  };
};

const countryOptionsSuccess = (data) => {
  return {'type':types.COUNTRY_OPTIONS_SUCCESS,data:data};
};

const countryOptionsFailure = (data) => {
  return {'type':types.COUNTRY_OPTIONS_FAILURE,data:data};
};

export const countryOptions = (business_id) => {
  const url = URLS.BUSINESS_SETUP+business_id+'/address/country/';
  return (dispatch) => {
    dispatch(showLoader());
    axios_instance.get(url)
      .then((res)=>{
        dispatch(hideLoader());
        dispatch(countryOptionsSuccess(res.data));
      })
      .catch((error)=>{
        dispatch(hideLoader());
        dispatch(countryOptionsFailure(error));
      });
  };
};

const replyOptionsSuccess = (data) =>{
  return {'type': types.REPLY_OPTIONS_SUCCESS, data:data};
};

const replyOptionsFailure = (data) => {
  return{'type':types.REPLY_OPTIONS_FAILURE, data:data};
};

export const replyOptions = () => {
  return (dispatch) => {
    axios_instance.get(URLS.REPLY_OPTIONS)
      .then((res) => {
        dispatch(replyOptionsSuccess(res.data));
      })
      .catch((error) => {
        dispatch(replyOptionsFailure(error));
      });
  };
};

const tagsOptionsSuccess = (data) =>{
  return {'type': types.TAGS_OPTIONS_SUCCESS, data:data};
};

const tagsOptionsFailure = (data) => {
  return{'type':types.TAGS_OPTIONS_FAILURE, data:data};
};

export const getTagOptions = (bId) => {
  return (dispatch) => {
    axios_instance.get(`${URLS.BUSINESS_SETUP}${bId}/tags-report/?&report_type=get_business_tags`)
      .then((res) => {
        dispatch(tagsOptionsSuccess(res.data));
      })
      .catch((error) => {
        dispatch(tagsOptionsFailure(error));
      });
  };
};

const categoryOptionsSucess = (data) =>{
  return {'type': types.CATEGORY_OPTIONS_SUCCESS, data:data};
};

const categoryOptionsFailure = (data) => {
  return{'type':types.CATEGORY_OPTIONS_FAILURE, data:data};
};

export const getCategoryOptions = () => {
  return (dispatch) => {
    dispatch( showLoader() );
    axios_instance.get(URLS.CATEGORY_OPTIONS)
      .then((res) => {
        dispatch( hideLoader() );
        dispatch(categoryOptionsSucess(res.data));
      })
      .catch((error) => {
        dispatch( hideLoader() );
        dispatch(categoryOptionsFailure(error));
      });
  };
};


const competitorBrandOptionsSuccess = (data) => {
  return{'type': types.BRAND_OPTIONS_SUCCESS, data : data};
};

const competitorBrandOptionsFailure = (data) => {
  return{'type' : types.BRAND_OPTIONS_FAILURE, data: data};
};

export const competitorBrandOptions = (business_id) => {
  return(dispatch) => {
    return axios_instance.get(`${URLS.BUSINESS_SETUP}${business_id}/competition/options/`)
      .then((res) => {
        dispatch(competitorBrandOptionsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(competitorBrandOptionsFailure(err));
        throw err.response.data;
      });
  };
};


const weportalDropDownSuccess = (data) =>{

  return{'type':types.WEB_PORTAL_DROP_DOWN_SUCCESS, data:data};
};

const weportalDropDownFailure = (error) =>{
  return {'type': types.WEB_PORTAL_DROP_DOWN_FAILURE, data:error};
};

export const webportalOptions =() =>{
  return (dispatch) => {
    dispatch( showLoader() );
    axios_instance.get(URLS.WEB_PORTAL_OPTIONS)
      .then((res) => {
        dispatch( hideLoader() );
        dispatch(weportalDropDownSuccess(res.data));
      })
      .catch((error) => {
        dispatch( hideLoader() );
        dispatch(weportalDropDownFailure(error));
      });
  };
};


const scheduleDropDownSuccess = (data) =>{

  return{'type':types.SCHEDULE_DROP_DOWN_SUCCESS, data:data};
};

const scheduleDropDownFailure = (error) =>{
  return {'type': types.SCHEDULE_DROP_DOWN_FAILURE, data:error};
};

export const scheduleOptions =() =>{
  return (dispatch) => {
    dispatch( showLoader() );
    axios_instance.get(URLS.SCHEDULE_OPTIONS)
      .then((res) => {
        dispatch( hideLoader() );
        dispatch(scheduleDropDownSuccess(res.data));
      })
      .catch((error) => {
        dispatch( hideLoader() );
        dispatch(scheduleDropDownFailure(error));
      });
  };
};

export const updateRouteParams = (data) => {
  return (dispatch) => {
    dispatch({'type': types.UPDATE_ROUTE_PARAMS,data});
  };
};

const branchTagOptionsSuccess = (data) =>{
  return{'type':types.BRANCH_TAG_OPTIONS_SUCCESS, data:data};
};

const branchTagOptionsFailure = (error) =>{
  return {'type': types.BRANCH_TAG_OPTIONS_FAILURE, data:error};
};

export const branchTagOptions =(businessId) =>{
  return (dispatch) => {
    dispatch( showLoader() );
    axios_instance.get(`${URLS.BRANCH_TAG}?business_id=${businessId}`)
      .then((res) => {
        dispatch( hideLoader() );
        dispatch(branchTagOptionsSuccess(res.data));
      })
      .catch((error) => {
        dispatch( hideLoader() );
        dispatch(branchTagOptionsFailure(error));
      });
  };
};
