import React from "react";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import KeyboardBackspaceRoundedIcon from "@mui/icons-material/KeyboardBackspaceRounded";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { FPToolTip } from "../../../components/common/new";
import cookie from "react-cookies";
import queryString from "query-string";

function AnswerHeader(props) {
  const { selectedEmail, totalEmailCount, setSelectedIndex, selectedIndex,  emailList, search } = props;
  const payload = queryString.parse(search)
  const {page_size : nextPageSize, page:nextPage } = payload
  const pageSize = +nextPageSize
  const page = +nextPage || 1

  const currentEmailNumber = (page - 1) * pageSize + selectedIndex + 1;
  const totalEmails = Math.min(page * pageSize, totalEmailCount); 

  return (
    <div>
      <div className="email-answer-header">
        <div>
          {/* <KeyboardBackspaceRoundedIcon /> */}
        </div>
        <div className="email-navigation">
          <button
            className="nav-btn"
            onClick={() =>
              setSelectedIndex((prevIndex) => Math.max(0, prevIndex - 1))
            }
            disabled={selectedIndex === 0}
          >
            <KeyboardArrowLeftRoundedIcon />
          </button>
          <div className="email-counter">
            {currentEmailNumber} of {totalEmails}
          </div>
          <button
            className="nav-btn"
            onClick={() =>
              setSelectedIndex((prevIndex) =>
                Math.min(totalEmailCount, prevIndex + 1)
              )
            }
            disabled={selectedIndex === emailList.length - 1}
          >
            <ChevronRightRoundedIcon />
          </button>
        </div>
        <div>
          <FPToolTip
            placement="bottom-start"
            title={
              "This is external mail. Once you reply or forward it will be sent it to customer email also."
            }
          >
            <ReportProblemOutlinedIcon
              style={{ color: "red", width: "32px", height: "32px" }}
            />
          </FPToolTip>
        </div>
      </div>
    </div>
  );
}

export default AnswerHeader;
