import React, { useEffect } from "react";
import * as URLS from "../../libs/apiUrls";
import { getAllParamsInArrayForm, removeLifetimeFilter } from "../../libs/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonAction from "../../actions/common/commonAction";
import * as filterListActions from "../../actions/common/filterListActions";
import { FPFilter } from "../../components/common/new";

const SalesFilter = (props) => {
  const {
    common,
    history,
    filter,
    filterAction,
    match,
    reviewSourceOptions,
    commonActions
  } = props;

  const fetchFilterList = async () => {
    const {
      params: { business_id },
    } = match;
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch("", `${URLS.BUSINESS_SETUP}${business_id}/branch/`),
        filterAction.getContentType("", `${URLS.CONTENT_OPTIONS}`),
        commonActions.cityOptions(business_id),
        commonActions.stateOptions(business_id),
        commonActions.branchTagOptions(business_id),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  useEffect(() => {
    fetchFilterList();
  }, []);

  const handleDefaultFilter = (list, key) => {
    const filter = window.location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable =
      defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list.map((item) => {
      item["isChecked"] =
        isKeyFilterAvailable &&
        defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };

  const filterList = [
    {
      id: "1",
      label: "Date Duration",
      key: "date_type",
      type: "radio",
      isSearchable: false,
      list:
        filter.date_type &&
        handleDefaultFilter(removeLifetimeFilter(filter?.date_type?.choices), "date_type"),
    },
    {
      id: "2",
      label: "Provider",
      key: "provider",
      type: "checkbox",
      isSearchable: true,
      list:
        reviewSourceOptions &&
        handleDefaultFilter(reviewSourceOptions, "provider"),
    },
    {
      id: "5",
      label: "Location",
      key: "branch",
      type: "radio",
      isSearchable: true,
      list:
        filter.branch && handleDefaultFilter(filter.branch.choices, "branch"),
    },
    {
      id: "4",
      label: "City",
      key: "city",
      type: "checkbox",
      isSearchable: true,
      list: common.city_options && handleDefaultFilter(common.city_options.choices, "city"),
    },
    {
      id: "5",
      label: "State",
      key: "state",
      type: "checkbox",
      isSearchable: true,
      list: common.state_options && handleDefaultFilter(common.state_options.choices, "state"),
    },
    {
      id: "6",
      label: "Location Tag",
      key: "branch_tag",
      type: "checkbox",
      isSearchable: true,
      list:
        common.branch_tag_options &&
        handleDefaultFilter(
          common.branch_tag_options.results.map((item) => ({
            value: item.id,
            display_name: item.title,
          })),
          "branch_tag"
        ),
    },
  ].filter((item) => !!item);

  return (
    <React.Fragment>
      <FPFilter
        data={filterList}
        className="mt-3"
        history={history}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    filter: state.filter,
    source: state.review.review_source_options,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SalesFilter);
