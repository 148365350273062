import React from 'react';

import { Table, Dropdown } from 'semantic-ui-react';

const MarketingListComponent = (props) => {
  let { marketingList, redirectToBusinessPage, toggle } = props;
  marketingList = marketingList?marketingList:[];
  return (
    <div className="ui-table-responsive">
      <Table unstackable className="mt-1">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>S.No.</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            marketingList.map((item, index) => (
              <Table.Row key={item.id}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell className="link-text cursor-pointer" onClick={() => redirectToBusinessPage(item.id, item.cooprate_branch)}>{item.name}</Table.Cell>
                <Table.Cell>{item.category}</Table.Cell>
                <Table.Cell className="text-right">
                  <Dropdown icon='ellipsis vertical' upward pointing='right' floating className='icon'>
                    <Dropdown.Menu>
                      <Dropdown.Item icon='edit' text='Edit' onClick={() => toggle(item)}/>
                    </Dropdown.Menu>
                  </Dropdown>
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </div>
  );
};

export default MarketingListComponent;
