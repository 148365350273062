import React from "react";
import { Col, Row, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import LoaderComponent from "../../components/common/loaderComponent";
import ContactTagsForm from "../../components/contacts/ContactTagsForm";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import * as ContactActions from "../../actions/contact/contactActions";
import ContactTagGroupList from "../../components/contacts/ContactTagGroupList";
import { FPBreadcrumbs, FPButton } from "../../components/common/new";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

class ContactManageTags extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isTagsFormOpen: false,
      isAddTagsOpen: false,
      editingName: null,
      editingGroupName: null,
    };
    this.openTagsForm = this.openTagsForm.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.actions.getContactTagGroups(business_id);
  }

  openTagsForm(reset, isAddTagsOpen) {
    const { isTagsFormOpen } = this.state;
    if (reset) this.setState({ tagDetail: null });
    if (isAddTagsOpen) this.setState({ isAddTagsOpen });
    this.setState({ isTagsFormOpen: !isTagsFormOpen });
  }

  addContactTagGroup = (data) => {
    const {
      match: {
        params: { business_id },
      },
      actions,
    } = this.props;
    return actions.contactTagGroupAdd(business_id, data).then((res) => {
      //eslint-disable-line
      this.openTagsForm();
      this.props.actions.getContactTagGroups(business_id);
    });
  };

  deleteTagGroup = ({ id }) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.actions.deleteTagGroup(business_id, id).then((res) => {
      //eslint-disable-line
      this.props.actions.getContactTagGroups(business_id).then((res) => {
        if (!res.data.results.length) this.setState({ isAddTagsOpen: false });
      });
    });
  };

  toggleConfirm = (confirmationFunction, data) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmationFunction,
    });
    if (data) {
      this.setState({
        headerEnd:
          (data.name ? "tag group-" : "tag-") +
          `  ${data.name || data.tag_name}`,
      });
    }
  };

  editTagGroupName = (id, groupName) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions
      .editTagGroupName(business_id, { name: groupName }, id)
      .then(() => {
        this.props.actions.getContactTagGroups(business_id);
      });
  };

  editTagName = (id, tagName) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions
      .editTagName(business_id, { name: tagName }, id)
      .then(() => {
        this.props.actions.getContactTagGroups(business_id);
      });
  };

  deleteTag = (tagData) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.actions.deleteTag(business_id, tagData).then(() => {
      this.props.actions.getContactTagGroups(business_id);
    });
  };

  addTagName = (id, value) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions
      .addTagName(business_id, { object_id: id, tag_name: value })
      .then(() => {
        this.props.actions.getContactTagGroups(business_id);
      });
  };

  clearServerError = () => {
    this.props.actions.removeEditTagNameError();
  };

  render() {
    const {
      match: {
        params: { business_id },
      },
      tagGroupsList,
      loader,
      serverError,
    } = this.props;
    const tagsList =
      tagGroupsList && tagGroupsList.results.length
        ? tagGroupsList.results
        : null;
    const {
      isTagsFormOpen,
      isAddTagsOpen,
      isConfirmOpen,
      confirmationFunction,
      headerEnd,
    } = this.state;
    const breadCrumbList = [
      {
        label: "Contacts",
        link: `/${business_id}/contacts/list?page_size=10`,
      },
      {
        label: "Manage Tags",
        onClick: this.openTagsForm,
      },
      isTagsFormOpen && {
        label: "Add Tag Group",
        link: "",
      }
    ].filter((item) => !!item);
    return (
      <div className="p-2">
        <LoaderComponent loader={loader}></LoaderComponent>
        <Row>
          <Col className="mt-4">
            <FPBreadcrumbs list={breadCrumbList} />
          </Col>
        </Row>
        <div className="mt-20 ml-1">
          <div className="d-flex flex-column">
            <div className="d-flex flex-column">
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "rgba(27, 28, 29, 1)",
                }}
              >
                Manage contact Tags
              </span>
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "rgba(100, 101, 101, 1)",
                  marginTop: "5px",
                }}
              >
                Contact tags let you cagtegorize contacts so you can run
                targeted campaigns.Here you can organise your tags into groups.
              </span>
            </div>
            {!isTagsFormOpen && (
              <div className="d-flex justify-content-end mt-10 mb-20">
                <FPButton
                  label="Add Tag Group"
                  startIcon={
                    <AddOutlinedIcon style={{ width: 16, height: 16 }} />
                  }
                  backgroundColor="#28314F"
                  onClick={() => {
                    this.openTagsForm(true);
                  }}
                />
              </div>
            )}
            {isTagsFormOpen ? (
              <div className="mt-20">
                <ContactTagsForm
                  toggleForm={this.openTagsForm}
                  addContactTagGroup={this.addContactTagGroup}
                ></ContactTagsForm>
              </div>
            ) : (
              <ContactTagGroupList
                editTagGroupName={this.editTagGroupName}
                editTagName={this.editTagName}
                serverError={serverError && serverError.errors}
                clearServerError={this.clearServerError}
                addTagName={this.addTagName}
                tagsList={tagsList}
                deleteTagGroup={(item) =>
                  this.toggleConfirm(() => this.deleteTagGroup(item), item)
                }
                deleteSelectedTag={(data) =>
                  this.toggleConfirm(() => this.deleteTag(data), data)
                }
              />
            )}
          </div>
        </div>
        {/* <Col sm="12">
          <Card body className="py-2" style={{ height: '90vh', textOverflow: 'ellipsis', overflow: 'auto' }}>
            <div style={{ display: 'block' }}>
              <Link to={`/${business_id}/contacts/list`} className="mr-2">
                <img src={BackIcon} alt="" height="20" width="20" className="align-bottom"></img>
              </Link>
              <h5 className="mt-1 d-inline-block">Manage contact Tags</h5>
            </div>
            {(!tagsList && !isAddTagsOpen) ? <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Row className="text-center">
                <div>
                  <Col sm="12" style={{ fontSize: 'x-large', fontWeight: '600' }}>No contact Tags Added yet.</Col>
                  <Col sm="12" className="mt-3" style={{ fontSize: 'medium', color: 'grey' }}> If you have contact tags in your contact import file, add them here to filter contacts and run targeted companies.</Col>
                  <Col sm="12" className="mt-3" style={{ color: 'deepskyblue' }}>Learn more about contact tags.</Col>
                  <Col sm="12" className="mt-5">
                    <Button color='blue' onClick={() => { this.openTagsForm(true, true); }}>Add contact tags</Button>
                  </Col>
                  <Col sm="12"></Col>
                </div>
              </Row>
            </div>
              :
              <div>
                <Row>
                  <Col sm="9">
                    <Row>
                      <Col sm="12" className="mt-3">Contact tags let you cagtegorize contacts so you can run targeted campaigns.Here you can organise your tags into groups.</Col>
                      <Col sm="12" className="mt-1" style={{ color: 'deepskyblue' }}>Learn more about contact tags.</Col>
                    </Row>
                  </Col>
                  {!isTagsFormOpen && <Col sm="3">
                    <div style={{ float: 'right' }}>
                      <Button basic onClick={() => { this.openTagsForm(true); }}>Add a Tag group</Button>
                    </div>
                  </Col>}
                  {isTagsFormOpen ?
                    <Col sm="12" className="p-3 mt-5 contact-tags-form">
                      <ContactTagsForm
                        toggleForm={this.openTagsForm}
                        addContactTagGroup={this.addContactTagGroup}
                      ></ContactTagsForm>
                    </Col>
                    :
                    <ContactTagGroupList
                      editTagGroupName={this.editTagGroupName}
                      editTagName={this.editTagName}
                      serverError={serverError && serverError.errors}
                      clearServerError={this.clearServerError}
                      addTagName={this.addTagName}
                      tagsList={tagsList}
                      deleteTagGroup={(item) => this.toggleConfirm(()=>this.deleteTagGroup(item), item)}
                      deleteSelectedTag={(data) => this.toggleConfirm(()=>this.deleteTag(data), data)}/>
                  }
                </Row>
              </div>}
          </Card></Col> */}
        <ConfirmationModal
          header={`Are you sure you want to delete ${headerEnd}?`}
          subHeader="This cannot be undone."
          isOpen={isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={confirmationFunction}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    tagGroupsList: state.contact.tagGroupsList,
    loader: state.common.loader,
    serverError: state.contact.error,
  };
};

ContactManageTags.propTypes = {
  actions: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ContactActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ContactManageTags);
