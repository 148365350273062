import React, { useEffect, useState } from "react";
import SearchComponent from "../../components/common/SearchComponent";
import CardWrapper from "../dashboard/component/CardWrapper";
import { FPButton, FPToolTip } from "../../components/common/new";
import tagIcon from "../../images/svg/reviewTag.svg";
import AddIcon from "@material-ui/icons/Add";
import AutoSelect from "../../components/common/AutoSelect";
import { ReactComponent as DropdownIcon } from "../../images/svg/dropdownUpIcon.svg";
import queryString from "query-string";

const TicketActionBar = ({ location, history, onManageTagClick, enable_ticket_creation, toggleCreateTicketModal }) => {
  const [selectedOrdering, setSelectedOrdering] = useState("false");

  const sortOptions = [
    {
      display_name: "Oldest",
      value: "created_at",
    },
    {
      display_name: "Newest",
      value: "-created_at",
    },
  ];

  const { pathname, search } = location;
  const updateOrdering = (sortOrder) => {
    setSelectedOrdering(sortOrder);

    let filters = { ...queryString.parse(search), ordering: sortOrder };

    history.push({ pathname, search: queryString.stringify(filters) });
  };

  useEffect(() => {
    let querKey = Object.keys(queryString.parse(search));

    if (querKey.includes("ordering")) {
      setSelectedOrdering(queryString.parse(search).ordering);
    } else {
      setSelectedOrdering(null);
    }
  }, [search]);
  return (
    <div className="my-20">
      <CardWrapper showDivider={false}>
        <section className="d-flex gap-20 align-items-center justify-content-between">
          <SearchComponent
            location={location}
            history={history}
            className="ticket-search"
            placeholder="Search Ticket"
            endIcon
            size="small"
          />
          <div className="d-flex align-items-center gap-10">
            <div>
              <AutoSelect
                placeholder="Sort By"
                options={sortOptions}
                onChange={(event, option) => {
                  updateOrdering(option);
                }}
                value={selectedOrdering}
                className="sortingBox"
                sx={{
                  width: 100,
                  "& fieldset": { borderRadius: 33 },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#F13A88 !important",
                  },
                  "& .MuiInputBase-input": {
                    height: 10,
                    minWidth: "60px !important",
                    fontSize: "12px",
                    color: "#646565 !important",
                    "&::placeholder": {
                      color: "#646565",
                    },
                  },
                }}
                disableClearable
                popupIcon={<DropdownIcon className="dropDownICon" />}
              />
            </div>
            <FPButton
              label="Create Review Ticket"
              size="small"
              style={{ height: "27px" }}
              disabled={enable_ticket_creation}
              onClick={toggleCreateTicketModal}
              endIcon={<AddIcon style={{ height: 15, width: 15, color: "white" }} />}
            />
            <FPToolTip title={"Manage Tags"}>
              <img
                src={tagIcon}
                onClick={() => onManageTagClick()}
                style={{ height: "32px", width: "32px", cursor: "pointer" }}
              />
            </FPToolTip>
          </div>
        </section>
      </CardWrapper>
    </div>
  );
};

export default TicketActionBar;
