import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as LoginActions from '../../actions/accounts/loginActions';
import * as profileAction from '../../actions/accounts/profileAction';
import LoginForm from '../../components/accounts/login/LoginForm';
import LoaderComponent from '../../components/common/loaderComponent';
import * as utils from '../../libs/utils';
import heroImage from '../../images/heroImage.png';
import LogoIcon from '../../images/fp-icon.png';
import { Col, Row } from 'reactstrap';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSignIn = this.handleSignIn.bind(this);
  }

  componentDidMount() {
    document.title = 'Login';
  }

  // submit the login details
  handleSignIn(values) {
    return this.props.actions.login.loginAction(values).
      then((res) => { // eslint-disable-line
        this.props.actions.user.getProfileInfo(values)
          .then((res) => {
            //get query string from url
            const { location: { search } } = this.props;
            const query = new URLSearchParams(search);
            const redirect = query.get('redirect');
            if(redirect== 'assign-ticket' && query.get('ticket_id') && res.data.business){
              let business_id = query.get('business_id');
              let url = '';
              if (business_id) {
                url = '/assign-ticket?ticket_id='+query.get('ticket_id')+'&business_id=' + business_id;
              } else {
                url = '/assign-ticket?ticket_id='+query.get('ticket_id')+'&business_id=' + res.data.business;
              }
              this.props.history.push(url);
              return;
            }

            let responseData = res.data;
            let {business, corporate_branch, business_type,marketing_id, screen_name, is_superuser, is_business_owner, multi_business_access} = responseData;
            let nextPageUrl = utils.nextPageUrl(business_type, is_superuser,screen_name, business, corporate_branch,marketing_id,is_business_owner, multi_business_access);
            this.props.history.push(nextPageUrl);
          },(error)=>{// eslint-disable-line

          });
      }, (error)=>{// eslint-disable-line

      });
  }

  render() {
    return (
      <React.Fragment>
        <LoaderComponent loader={this.props.loader} />
        <Row className="bg-white vh-100 max-vw-100 overflow-hidden">
          <Col
            sm={12}
            md={6}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <div className="text-center">
              <img src={LogoIcon} alt="" />
            </div>
            <h3 className="text-center mt-3 pb-1 font-blue mb-4">
              Sign In to your account
            </h3>
            <LoginForm handleSignIn={this.handleSignIn} />
          </Col>
          <Col sm={12} md={6} className="d-md-block d-none h-100  max-vw-100 hero-image-wrapper">
            <img className='h-100 object-fill hero-image' src={heroImage} alt="" />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

// map state value into props
const mapStateToProps = (state) => {
  return{
    errors: state.login.errors,
    loader:state.common.loader
  };
};


LoginPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object)
};


function mapDispatchToProps(dispatch) {
  return {
    actions:{
      login:bindActionCreators(LoginActions, dispatch),
      user: bindActionCreators(profileAction, dispatch)
    }
  };
}


export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(LoginPage);
