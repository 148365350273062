import React from 'react';
import classNames from 'classnames';

function SrChartWrapper({ heading, children, className }) {
  return (
    <div className={classNames('chart-wrapper', className)}>
      {heading && <p className="chart-heading">{heading}</p>}
      {children}
    </div>
  );
}

export default SrChartWrapper;
