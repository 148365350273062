/**
 * Project - fbs-web-app
 * Created by vivekgusain on 10/2/19
 */
import React from 'react';

export default class CustomRatingComponent extends React.PureComponent {
  constructor(props){
    super(props);
  }

  render(){
    const {willBeActive, isActive, inActiveImg, activeImg, customClass, imageWidth, style} = this.props;
    return(
      <img className={customClass} width={imageWidth} src={(willBeActive || isActive) ? activeImg : inActiveImg } style={style}/>
    );
  }
}

