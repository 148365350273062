import React from 'react';
import { reduxForm } from 'redux-form';
import SearchComponent from '../common/SearchComponent';
import queryString from 'query-string';
import LocationFiltersDropdown from '../common/LocationFiltersDropdown';
import CardWrapper from '../../modules/dashboard/component/CardWrapper';
import FPButton from '../common/new/FPButton';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { Field } from 'redux-form';


class AgencyFilters extends React.PureComponent {
  constructor(props) {
    super(props);
    const query = props.location ? queryString.parse(props.location.search) : {};
    this.state = {
      user__business_type: query.user__business_type ? query.user__business_type : '',
      category_id: query.category_id ? query.category_id : ''
    };
    this.handleBusiness = this.handleBusiness.bind(this);
    this.handleCategory = this.handleCategory.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  handleBusiness(business) {
    this.setState({
      user__business_type: business
    });
  }

  handleCategory(category) {
    this.setState({
      category_id: category
    });
  }

  applyFilters(event) {
    event.preventDefault();
    const { history, location: { pathname } } = this.props;
    let filters = Object.keys(this.state)
      .filter((key) => this.state[key] !== '' && this.state[key] !== null && this.state[key] !== 'All' && key !== 'showToastMsg')
      .reduce((acc, key) => {
        acc[key] = this.state[key];
        return acc;
      }, {});

    history.push({ pathname, search: queryString.stringify({ ...filters }) });
  }

  resetFilters(event) {
    event.preventDefault();
    const { history, location: { pathname, search }, reset } = this.props;
    this.setState({ user__business_type: '', category_id: '' });
    reset();
    const query = queryString.parse(search);
    ['user__business_type', 'category_id'].forEach((item) => delete query[item]);
    history.push({ pathname, search: queryString.stringify(query) });
  }


  render() {
    const { userOptionList, categoryOptionList } = this.props;
    const dropDownStyle = {
      background: 'white',

      padding: '12px 12px',
      border: '1px solid var(--fp-secondary-outline)',
      borderRadius: '8px'
    };

    return (
      <CardWrapper showDivider={false} className="mb-4">
        {/* <section className="d-flex gap-20 align-items-center justify-content-between">

          <div className='d-flex gap-20 align-items-center fp-flex-grow-1'>
            <SearchComponent {...this.props}  className='fp-flex-grow-1' />

            <Field
              name="business"
              placeholder="Business"
              component={LocationFiltersDropdown}
              icon='briefcase'
              button
              compact
              // width={'200px'}
              style={dropDownStyle}
              dropDownColor={'white'}
              className='icon'
              labeled
              options={userOptionList}
              handleFilterChange={this.handleBusiness}
              text={this.state.user__business_type ? this.state.user__business_type.text : 'Select Business'}
              simpleValue>
            </Field>

            <Field
              name="category"
              placeholder="Category"
              component={LocationFiltersDropdown}
              options={categoryOptionList}
              icon='list ul'
              button
              compact
              // width={'200px'}
              style={dropDownStyle}
              dropDownColor={'white'}
              className='icon'
              labeled
              handleFilterChange={this.handleCategory}
              text={this.state.category_id ? this.state.category_id.text : 'Select Category'}
              simpleValue>
            </Field>
          </div>

          <div className='fp-flex-grow-1 d-flex justify-content-end'>
            <FPButton
              label="Apply"
              onClick={this.applyFilters}
              backgroundColor="#1A2544"
              className="mr-4"
            />
            <FPButton
              startIcon={<SettingsBackupRestoreIcon />}
              label="Reset"
              onClick={this.resetFilters}
              variant="outline"
            />
          </div>
        </section> */}

        {/* Changes in code */}
        {/* <section className="d-flex gap-20 align-items-center justify-content-between row row-cols-12 row-cols-lg-12">

          <div className='d-flex gap-20 align-items-center fp-flex-grow-1 col-12 col-lg-8 searchField'>
            <SearchComponent {...this.props}  className='fp-flex-grow-1' />

            <Field
              name="business"
              placeholder="Business"
              component={LocationFiltersDropdown}
              icon='briefcase'
              button
              compact
              // width={'200px'}
              style={dropDownStyle}
              dropDownColor={'white'}
              className='icon searchFieldText'
              labeled
              options={userOptionList}
              handleFilterChange={this.handleBusiness}
              text={this.state.user__business_type ? this.state.user__business_type.text : 'Select Business'}
              simpleValue>
            </Field>

            <Field
              name="category"
              placeholder="Category"
              component={LocationFiltersDropdown}
              options={categoryOptionList}
              icon='list ul'
              button
              compact
              // width={'200px'}
              style={dropDownStyle}
              dropDownColor={'white'}
              className='icon searchFieldText'
              labeled
              handleFilterChange={this.handleCategory}
              text={this.state.category_id ? this.state.category_id.text : 'Select Category'}
              simpleValue>
            </Field>
          </div>

          <div className='col d-flex justify-content-md-end justify-content-center applyReset'>
            <FPButton
              label="Apply"
              onClick={this.applyFilters}
              backgroundColor="#1A2544"
              className="mr-4"
            />
            <FPButton
              startIcon={<SettingsBackupRestoreIcon />}
              label="Reset"
              onClick={this.resetFilters}
              variant="outline"
            />
          </div>
        </section> */}


        {/* Changes */}
        <section className="d-flex gap-20 align-items-center justify-content-between row">

          <div className='d-flex gap-20 align-items-center fp-flex-grow-1 col-12 col-lg-8 searchFieldText'>
            <div  className='row w-100'>
              <div className='col-12 col-lg-6 ms-3'>
                <SearchComponent {...this.props}  className='fp-flex-grow-1' />
              </div>
              <div className='d-flex gap-10 col-12 col-lg-6 mt-md-0 mt-3'>
                <span className='businessCategory'>
                  <Field
                    name="business"
                    placeholder="Business"
                    component={LocationFiltersDropdown}
                    icon='briefcase'
                    button
                    compact
                    // width={'200px'}
                    style={dropDownStyle}
                    dropDownColor={'white'}
                    className='icon'
                    labeled
                    options={userOptionList}
                    handleFilterChange={this.handleBusiness}
                    text={this.state.user__business_type ? this.state.user__business_type.text : 'Select Business'}
                    simpleValue>
                  </Field>
                </span>

                <span className='businessCategory'>
                  <Field
                    name="category"
                    placeholder="Category"
                    component={LocationFiltersDropdown}
                    options={categoryOptionList}
                    icon='list ul'
                    button
                    compact
                    // width={'200px'}
                    style={dropDownStyle}
                    dropDownColor={'white'}
                    className='icon'
                    labeled
                    handleFilterChange={this.handleCategory}
                    text={this.state.category_id ? this.state.category_id.text : 'Select Category'}
                    simpleValue>
                  </Field>
                </span>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-lg-end justify-content-center applyReset'>
            <FPButton
              label="Apply"
              onClick={this.applyFilters}
              backgroundColor="#1A2544"
              className="mr-4"
            />
            <FPButton
              startIcon={<SettingsBackupRestoreIcon />}
              label="Reset"
              onClick={this.resetFilters}
              variant="outline"
            />
          </div>
        </section>
      </CardWrapper>
    );
  }

}

AgencyFilters = reduxForm({// eslint-disable-line
  form: 'AgencyFiltersForm'
})(AgencyFilters);
export default AgencyFilters;