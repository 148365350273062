import React from "react";
// import { Row, Col } from "reactstrap";
// import { Card, Label, Icon, Divider, Image, Header } from "semantic-ui-react";
import LoaderComponent from "../../components/common/loaderComponent";
import NavBar from "../../components/common/NavBar";
// import CustomStarRatingComponent from "../../components/common/StarComponent";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
// import classNames from "classnames";
import cookie from "react-cookies";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ProfileActions from "../../actions/accounts/profileAction";
import * as utils from "../../libs/utils";
import { FPButton, NoData } from "../../components/common/new";
// import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
// import { Grid } from "@material-ui/core";
import { AgencyCard } from "../../modules/agency";
import * as dashboardActions from "../../actions/common/dashboardAction";

const labelColors = {
  Hotel: "rgb(7, 223, 97)",
  Retail: "rgb(239,30,252)",
  Restaurant: "rgba(248, 119, 71, 0.925)",
  "Health Care": "rgb(0, 167, 209)",
};

class AgencyStaffPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { agency_id: this.props.match.params.agency_id, businessElements: [] };
    this.businessDashboard = this.businessDashboard.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { agency_id },
      },
      userq,
    } = this.props;
    const user = userq || cookie.load("user");
    const user_id = user.id;
    document.title = "Agency Staff Dashboard";
    !!agency_id &&
      axios_instance.get(`${URLS.AGENCY_STAFF_DASHBOARD}?agency_id=${agency_id}&user_id=${user_id}`).then((res) => {
        this.setState({ businessElements: res.data.businessElements });
      });
  }

  businessDashboard(businessId) {
    this.props.dashboardActions.clearDashboardApiData();
    this.props.profileActions.getBusinessProfile(businessId).then((res) => {
      let { business, corporate_branch, business_type, marketing_id, screen_name, is_superuser, is_business_owner } =
        res.data;
      let nextPageUrl = utils.nextPageUrl(
        business_type,
        is_superuser,
        screen_name,
        business,
        corporate_branch,
        marketing_id,
        is_business_owner
      );
      this.props.history.push(nextPageUrl);
    });
  }

  render() {
    const { businessList, business_all_list } = this.props;
    const { businessElements } = this.state;
    const business_obj = Object.values(businessElements)[0];
    return (
      <div className="text-container mx-15" style={{ paddingTop: "40px" }}>
        <NavBar match={this.props.match} history={this.props.history} businessList={business_all_list}>
          <header className="mb-4 d-flex">
            {business_obj && (
              <div className="pb-2">
                <h2 className="font-dark h1 headerText">{business_obj.marketing_name}</h2>
              </div>
            )}
          </header>
          {this.props.loader ? (
            <LoaderComponent loader={this.props.loader} />
          ) : (
            <>
              {businessElements ? (
                <section className="agency-card-details-container">
                  {businessElements.map((item) => (
                    <AgencyCard key={item.id} details={item} onClick={() => this.businessDashboard(item.id)} />
                  ))}
                </section>
              ) : (
                <NoData />
              )}
            </>
          )}
        </NavBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  register: state.register.isRegister,
  loader: state.common.loader,
  businessList: state.agency.business_list,
  business_all_list: state.agency.business_all_list
});

function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(ProfileActions, dispatch),
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AgencyStaffPage);
