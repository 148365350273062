import React, { useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import { Typography } from "@material-ui/core";

const Accordion = withStyles({
  root: {
    boxShadow: "none",
    "&:before": {
      backgroundColor: "white",
    },
    "& .MuiSvgIcon-root.minus": {
      display: "none",
    },
    "& .MuiSvgIcon-root.add": {
      display: "block",
    },
    "&.Mui-expanded": {
      "& .MuiSvgIcon-root.minus": {
        display: "block !important",
      },
      "& .MuiSvgIcon-root.add": {
        display: "none !important",
      },
    },
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(242, 249, 249, 1)",
    height: "45px !important",
    minHeight: "auto !important",
    "& .MuiSvgIcon-root.minus1": {
      display: "none",
    },
    "& .MuiSvgIcon-root.add1": {
      display: "block",
    },
    "&.Mui-expanded": {
      "& .MuiSvgIcon-root.minus1": {
        display: "block !important",
      },
      "& .MuiSvgIcon-root.add1": {
        display: "none !important",
      },
    },
  },
  content: {
    margin: "0px !important",
    fontSize: "14px",
    fontWeight: "400",
  },
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "0px",
    marginTop: 3,
    gap: 7,
    "& .MuiPaper-root": {
      margin: 0,
    },
  },
}))(MuiAccordionDetails);

const SurveyTextualTableQuestion = ({ groupedData, isDisplayBranchWise, isDisplayPageWise }) => {
  const [expanded, setExpanded] = useState("");
  const [branchExpanded, setBranchExpanded] = useState("");
  const [pageExpanded, setPageExpanded] = useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleBranchChange = (pan) => (e, newExp) => {
    setBranchExpanded(newExp ? pan : false);
  };

  const handlePageChange = (p) => (eve, newEx) => {
    setPageExpanded(newEx ? p : false);
  };

  return (
    <>
      {groupedData.length > 0 &&
        groupedData.map((item, index) => {
          return (
            <Accordion square expanded={expanded === index} onChange={handleChange(index)} key={index}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <div className="d-flex align-items-center pl-4">
                  <RemoveIcon
                    style={
                      isDisplayBranchWise || isDisplayPageWise
                        ? { color: "#00B5AD", width: 15, height: 15 }
                        : { width: 15, height: 15 }
                    }
                    className="minus"
                  />
                  <AddIcon
                    style={
                      isDisplayBranchWise || isDisplayPageWise
                        ? { color: "#00B5AD", width: 15, height: 15 }
                        : { width: 15, height: 15 }
                    }
                    className="add"
                  />
                  <Typography
                    style={
                      isDisplayBranchWise || isDisplayPageWise
                        ? {
                            color: "#00B5AD",
                            fontWeight: "600",
                            fontSize: "16px",
                          }
                        : { fontWeight: "500", fontSize: "16px" }
                    }
                    className="ml-1"
                  >
                    {isDisplayBranchWise || (isDisplayBranchWise && isDisplayPageWise)
                      ? item.branch
                      : isDisplayPageWise
                      ? item.page
                        ? item.page
                        : "survey question page 1"
                      : item.question}
                  </Typography>
                </div>
              </AccordionSummary>
              {item && isDisplayBranchWise && isDisplayPageWise ? (
                <AccordionDetails className="flex-column p-0">
                  {item &&
                    item.details &&
                    item.details.length > 0 &&
                    item.details.map((page, i) => (
                      <Accordion square expanded={branchExpanded === i} onChange={handleBranchChange(i)} key={i}>
                        <AccordionSummary>
                          <div className="d-flex align-items-center pl-8">
                            <RemoveIcon className="minus1" style={{ width: 15, height: 15 }} />
                            <AddIcon className="add1" style={{ width: 15, height: 15 }} />
                            <Typography className="weight-500 ml-1 font" style={{ fontSize: "16px" }}>
                              {page.page ? page.page : "survey question page 1"}
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="flex-column p-0">
                          {page &&
                            page.details &&
                            page.details.length > 0 &&
                            page.details.map((que, ind) => (
                              <Accordion
                                square
                                expanded={pageExpanded === ind}
                                onChange={handlePageChange(ind)}
                                key={i}
                              >
                                <AccordionSummary>
                                  <div className="d-flex align-items-center" style={{ marginLeft: "5rem" }}>
                                    <RemoveIcon className="minus1" style={{ width: 15, height: 15 }} />
                                    <AddIcon className="add1" style={{ width: 15, height: 15 }} />
                                    <Typography className="weight-500 ml-1" style={{ fontSize: "16px" }}>
                                      {que.question}
                                    </Typography>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails
                                  className="flex-column"
                                  style={{
                                    marginTop: "10px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {Array.isArray(que.options) ? (
                                    que.options.map((opt, index) => (
                                      <Typography
                                        className="pl-7Rem"
                                        style={{
                                          fontSize: "15px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {opt}
                                      </Typography>
                                    ))
                                  ) : (
                                    <Typography
                                      className="pl-7Rem"
                                      style={{
                                        fontSize: "15px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {que.options}
                                    </Typography>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            ))}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </AccordionDetails>
              ) : item && (isDisplayBranchWise || isDisplayPageWise) ? (
                <AccordionDetails className="flex-column p-0">
                  {item &&
                    item.details &&
                    item.details.length > 0 &&
                    item.details.map((que, i) => (
                      <Accordion square expanded={branchExpanded === i} onChange={handleBranchChange(i)} key={i}>
                        <AccordionSummary>
                          <div className="d-flex align-items-center pl-8">
                            <RemoveIcon className="minus1" style={{ width: 15, height: 15 }} />
                            <AddIcon className="add1" style={{ width: 15, height: 15 }} />
                            <Typography className="weight-500 ml-1" style={{ fontSize: "16px" }}>
                              {que.question}
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className="flex-column" style={{ marginTop: "10px", marginBottom: "5px" }}>
                          {Array.isArray(que.options) ? (
                            que.options.map((opt, index) => (
                              <Typography className="pl-5Rem" style={{ fontSize: "15px", fontWeight: "400" }}>
                                {opt}
                              </Typography>
                            ))
                          ) : (
                            <Typography className="pl-5Rem" style={{ fontSize: "15px", fontWeight: "400" }}>
                              {que.options}
                            </Typography>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </AccordionDetails>
              ) : (
                <AccordionDetails className="flex-column" style={{ marginTop: "10px", marginBottom: "5px" }}>
                  {Array.isArray(item?.details?.option) ? (
                    item.details.option.map((detail, detailIndex) => (
                      <Typography className="pl-4Rem" style={{ fontSize: "15px", fontWeight: "400" }}>
                        {detail}
                      </Typography>
                    ))
                  ) : (
                    <Typography className="pl-4Rem" style={{ fontSize: "15px", fontWeight: "400" }}>
                      {item?.details?.option}
                    </Typography>
                  )}
                </AccordionDetails>
              )}
            </Accordion>
          );
        })}
    </>
  );
};

export default SurveyTextualTableQuestion;
