import React from "react";
import {
  CardHeader,
  Typography,
  Skeleton,
  Box,
} from "@mui/material";
import { Divider } from "@material-ui/core";
import classNames from "classnames";

const TicketCardSkeleton = ({ className, ...rest }) => {
  return (
    <div
      className={classNames("fp-card fp-card-outline review-card-wrapper bg-white", className)}
      showDivider={false}
      {...rest}
    >
      <Box className="review-card-header">
        <div className="d-flex align-items-center gap-10">
          <Box sx={{ width: 40, height: 40 }}>
            <Skeleton
              rectangular="rectangular"
              width={"100%"}
              height={"100%"}
            ></Skeleton>
          </Box>
          <Skeleton width="10%" height={18}>
            <Typography>.</Typography>
          </Skeleton>
        </div>
      </Box>
      <CardHeader
        avatar={<Skeleton variant="circular" width={40} height={40} />}
        title={<Skeleton width="40%" height={20} />}
        subheader={<Skeleton width="30%" height={15} />}
        className="p-0 m-3"
      />

      <div className="m-3 mb-0">
        <Box mb={2}>
          <Typography variant="body1">
            <Skeleton width="80%" height={20} />
          </Typography>
        </Box>
        <Divider className="my-3" style={{ background: "#F6F6F8" }} />
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <Skeleton variant="circular" width={26} height={26} />
          <Skeleton variant="circular" width={26} height={26} />
          <Skeleton width="30%" height={18}>
            <Typography>.</Typography>
          </Skeleton>
        </Box>
      </div>
    </div>
  );
};

export default TicketCardSkeleton;
