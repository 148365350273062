import React from "react";
import AuthLayout from "./AuthLayout";
import LogoIcon from "../../images/fp-icon.png";

const MailSentSuccessfulPage = () => {
  return (
    <div>
      <AuthLayout
        logoIcon={LogoIcon}
        title={"Password reset link has been sent on your email"}
        linkText={"Go back to login"}
      />
    </div>
  );
};

export default MailSentSuccessfulPage;
