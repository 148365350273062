import React from "react";
import PropTypes from "prop-types";
import CardWrapper from "./CardWrapper";
import DateSelector from "./DateSelector";
import "../../../../src/modules/dashboard/dashboard-page.css";
import { Link } from "react-router-dom";
import { DashboardRedirect } from "../../../images/svg";
import { Skeleton } from "@mui/material";

const Chartwrapper = (props) => {
  const {
    children,
    onHeaderClick,
    className,
    headerTitle,
    onDateChange,
    isForLifeTimeSupported,
    redirectionLink,
    skeletonLoader,
    ...rest
  } = props;

  const header = () => {
    return (
      skeletonLoader ? (
        <div className="d-flex justify-content-between align-items-center">
          <Skeleton width="50%" height={30} />
          <Skeleton width="20%" height={30} /> {/* Added skeleton for DateSelector */}
        </div>
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-6">
            <p className="m-0">{headerTitle}</p>
            {redirectionLink && (
              <Link to={redirectionLink}>
                <DashboardRedirect />
              </Link>
            )}
          </div>
          <DateSelector
            className="chart-date-filter"
            onChange={(date) => onDateChange(date)}
            isForLifeTimeSupported={isForLifeTimeSupported}
          />
        </div>
      )
    );
    
  };
  return (
    <CardWrapper
      className={className}
      onHeaderClick={onHeaderClick}
      headerTitle={header()}
      {...rest}
    >
      {children}
    </CardWrapper>
  );
};

Chartwrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Chartwrapper;
