import React from 'react';
import { Field } from 'formik';
import FPCheckbox from '../FPCheckbox';
import ErrorMessage from './ui/ErrorMessage';

const FormCheckbox = ({ name, label,mainDivClassName, ...rest }) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <React.Fragment>
          <div className={mainDivClassName ? mainDivClassName : "mb-20"}>
            <FPCheckbox
              checked={meta.value}
              label={label}
              name={name}
              error={!!(meta.touched && meta.error)}
              {...field}
              {...rest}
            />
            {meta.touched && meta.error && (
              <ErrorMessage>{meta.error}</ErrorMessage>
            )}
          </div>
        </React.Fragment>
      )}
    </Field>
  );
};

export default FormCheckbox;
