import React, { useState, useEffect } from "react";
import LoaderComponent from "../../components/common/loaderComponent";
import { FPTab } from "../../components/common/new/tabs";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import AdsFilter from "../../components/restro-pilot/AdsFilter";
import { Table, Tag } from "antd";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import PaginationComponent from "../../components/common/Pagination";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import queryString from "query-string";

const AdsCampaignsPage = (props) => {
  const { history, location, location: { pathname, search }, match, match: { params: {business_id } } } = props;
  const [loading, setLoading] = useState(false);
  const [mainTab, setMainTab] = useState(0);
  const [allData, setAllData] = useState([]);
  const [activeData, setActiveData] = useState({});
  const [completedData, setCompletedData] = useState({});

  useEffect(() => {
    document.title = "All Campaigns";
    props.actions.setSelectedTab(3);
  }, []); //eslint-disable-line

  useEffect(() => {
    setLoading(true);
    axios_instance.get(`${utils.format(URLS.ADS_CAMPAIGN_ALL, [business_id])}${search}`)
      .then((res) => {
        setAllData(res.data)
        setLoading(false);
        console.log(res);
      }).catch((err) => {
        setLoading(false);
        console.log(err);
      });

    axios_instance.get(`${utils.format(URLS.ADS_CAMPAIGN_ACTIVE, [business_id])}${search}`)
      .then((res) => {
        setActiveData(res.data);
        console.log(res);
      }).catch((err) => {
        console.log(err);
      });

    axios_instance.get(`${utils.format(URLS.ADS_CAMPAIGN_COMPLETED, [business_id])}${search}`)
      .then((res) => {
        setCompletedData(res.data);
        console.log(res);
      }).catch((err) => {
        console.log(err);
      });
  }, [search]); // eslint-disable-line
  
  const redirectTo = (subUrl) => {
    setTimeout(() => {
      history.push(`/${business_id}${subUrl}`);
    }, 400);
  };


  const handleRootTabChange = (value) => {
    switch (value) {
      case 0:
        redirectTo("/ads?date_type=prior_30_day");
        break;
      case 1:
        redirectTo("/ads-campaign?date_type=prior_30_day&page_size=10&page=1");
        break;
      default:
        break;
    }
  };

  const handleTabChange = (tab) => {
    const query = queryString.parse(search);
    history.push({
      pathname,
      search: queryString.stringify({ ...query, page: 1 }),
    });
    setMainTab(tab);
  }

  return (
    <div className="my-4">
      <LoaderComponent loader={loading} />
      <div className={"my-4"}>
        <FPTab
          tabLabels={["Ads Performance", "All Campaigns"]}
          minTabWidth={220}
          defaultValue={1}
          onChange={handleRootTabChange}
        />
      </div>
      <div>
        <AdsFilter history={history} location={location} match={match} />
        <CardWrapper showDivider={false} className="mt-4" >
          <FPTab
            tabLabels={["All", "Active", "Completed"]}
            minTabWidth={220}
            defaultValue={mainTab}
            onChange={(val) => handleTabChange(val)}
          />
          {mainTab === 0 && <AllTable dataList={allData?.results || []} campaign_name="all" />}
          {mainTab === 1 && <AllTable dataList={activeData?.results || []} campaign_name="active" />}
          {mainTab === 2 && <AllTable dataList={completedData?.results || []} campaign_name="completed" />}
          {mainTab === 0 && <div className="my-4">
            <PaginationComponent
              count={allData?.count || 0}
              location={location}
              pageSizeCookie="ads_compaign_all"
            />
          </div>}
          {mainTab === 1 && <div className="my-4">
            <PaginationComponent
              count={activeData?.count || 0}
              location={location}
              pageSizeCookie="ads_compaign_active"
            />
          </div>}
          {mainTab === 2 && <div className="my-4">
            <PaginationComponent
              count={completedData?.count || 0}
              location={location}
              pageSizeCookie="ads_compaign_completed"
            />
          </div>}
        </CardWrapper>
      </div>
    </div>
  );
}


const AllTable = ({ dataList, campaign_name }) => {
  let columns = [];

  columns.push({
    title: "Provider",
    dataIndex: "provider__provider",    
    render: (_, data) => <span style={{ textTransform: 'capitalize' }} >{data.provider__provider}</span>,
  });

  columns.push({
    title: "Campaign ID",
    dataIndex: "campaign_id",
    sorter: { compare: (a, b) => b.campaign_id - a.campaign_id },
  });

  columns.push({
    title: "Start Date",
    dataIndex: "start_date",
    sorter: { compare: (a, b) => a.start_date.localeCompare(b.start_date) },
  });

  columns.push({
    title: "End Date",
    dataIndex: "end_date",
    sorter: { compare: (a, b) => a.end_date.localeCompare(b.end_date) },
  });

  columns.push({
    title: "Spends",
    dataIndex: "spends",
    sorter: { compare: (a, b) => b.spends - a.spends },
    render: (_, data) => <span>₹{data.spends?.toFixed(2)}</span>,
  });

  columns.push({
    title: "Impressions",
    dataIndex: "impressions",
    sorter: { compare: (a, b) => b.impressions - a.impressions },
  });

  columns.push({
    title: "Menu Visits",
    dataIndex: "menu_visits",
    sorter: { compare: (a, b) => b.menu_visits - a.menu_visits },
  });

  columns.push({
    title: "Orders",
    dataIndex: "orders",
    sorter: { compare: (a, b) => b.orders - a.orders },
  });

  columns.push({
    title: "Sales",
    dataIndex: "sales",
    sorter: { compare: (a, b) => b.sales - a.sales },
    render: (_, data) => <span>₹{data.sales?.toFixed(2)}</span>,
  });

  if(campaign_name === "all") {
    columns.push({
      title: "Status",
      dataIndex: "status",
      sorter: { compare: (a, b) => a.status.localeCompare(b.status) },
      render: (_, data) => (<span key={data.campaign_id} className="d-flex justify-content-center">
        <Tag bordered={false} color={`${data.status==="completed" ? "green" : "orange"}`} >{data.status} </Tag>
      </span>)
    });
  }

  const onChange = (filters, sorter, extra) => {
    console.log("params", filters, sorter, extra);
  };

  return (
    <div className="my-4" style={{ overflowX : 'auto' }}>
      <Table
        columns={columns}
        dataSource={dataList}
        onChange={onChange}
        pagination={false}
        showSorterTooltip={false}
      />
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(setSelectedTab, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
  };
};

AdsCampaignsPage.propTypes = {
  loader: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsCampaignsPage);

