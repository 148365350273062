import React from "react";
import { Doughnut, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

const SearchKeywords = () => {
  const monthData = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const data = {
    labels: monthData,
    datasets: [
      {
        label: "",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        borderColor: "blue",
        backgroundColor: "blue",
        tension: 0.1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        ticks: {
          stepSize: 0,
        },
        grid: {
          display: false, 
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => `Value: ${context.parsed.y}`,
        },
      },
    },
  };

  const visibilityData = {
    datasets: [
      {
        data: [32, 68], // Score: 32% and Remaining: 68%
        backgroundColor: ["#8E24AA", "#EDEDED"], // Purple and Light Gray
        hoverOffset: 4,
      },
    ],
  };

  const optionsss = {
    maintainAspectRatio: false,
    responsive: true,
    cutout: "60%", // Adjust inner radius
    plugins: {
      legend: { display: false }, // Hide default legend
      tooltip: { enabled: true }, // Enable tooltips
    },
  };

  return (
    <div>
      <h2>Search Keywords</h2>
      <div
        className="row"
        style={{
          marginLeft: "0px",
          marginRight: "0px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          alignItems: "stretch", // Ensures equal height for children
        }}
      >
        <div className="col-6" style={{ display: "flex" }}>
          <div
            style={{
              flex: 1, // Allows the inner content to stretch
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <h4 style={{ margin: "0 0 10px", fontWeight: "bold" }}>
                Month on Month Ranking Keyword
              </h4>
            </div>
            <Line data={data} options={options} />
          </div>
        </div>

        <div className="col-6" style={{ display: "flex" }}>
          <div
            style={{
              flex: 1, // Stretch content to match height
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
              borderRadius: "10px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <h4 style={{ margin: "0 0 10px", fontWeight: "bold" }}>
                Branded vs Discovery Impression{" "}
                <span style={{ color: "#aaa", fontSize: "14px" }}>
                  0 Lifetime Impression
                </span>
              </h4>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center", // Align content to the top
                justifyContent: "space-between",
                height:"100%",
              }}
            >
              <div style={{ width: "120px", marginRight: "20px" }}>
                <Doughnut data={visibilityData} options={optionsss} />
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "4px",
                  }}
                >
                  <span
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "green",
                      borderRadius: "50%",
                      marginRight: "8px",
                    }}
                  ></span>
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    Branded
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "4px",
                  }}
                >
                  <span
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: "red",
                      borderRadius: "50%",
                      marginRight: "8px",
                    }}
                  ></span>
                  <span style={{ color: "#333", fontSize: "14px" }}>
                    Discovery
                  </span>
                </div>
              </div>
              <div
                style={{
                  display: "grid",
                  alignItems: "flex-start", // Align items to the top
                  marginBottom: "4px",
                }}
              >
                <span>0.00%</span>
                <span>0.00%</span>
              </div>
              <div
                style={{
                  display: "grid",
                  alignItems: "flex-start", // Align items to the top
                  marginBottom: "4px",
                }}
              >
                <span style={{ color: "#aaa", fontSize: "14px" }}>
                  0 Keywords
                </span>
                <span style={{ color: "#aaa", fontSize: "14px" }}>
                  0 Keywords
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchKeywords;
