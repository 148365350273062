import React from "react";
import { Field } from "formik";
import FPInput from "./ui/FPInput";
import ErrorMessage from "./ui/ErrorMessage";
import classNames from "classnames";
import { FormFeedback } from "reactstrap";

const FormInput = ({
  name,
  autoComplete,
  label,
  wrapperClass,
  endIcon,
  ...rest
}) => {
  return (
    <Field name={name}>
      {({ field, meta }) => (
        <div className={classNames("mb-20", wrapperClass)}>
          <FPInput
            autoComplete={autoComplete || "off"}
            label={label}
            name={name}
            error={!!(meta.touched && meta.error)}
            {...field}
            {...rest}
            endIcon={endIcon ? endIcon : ""}
          />
          {meta.touched && meta.error && (
            <ErrorMessage>{meta.error}</ErrorMessage>
          )}
        </div>
      )}
    </Field>
  );
};

export default FormInput;
