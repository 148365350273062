import React, { useEffect, useState } from "react";
import { FPTab } from "../../components/common/new/tabs";
import AdsFilter from "../../components/restro-pilot/AdsFilter";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import AdsEngagement from "../../components/restro-pilot/AdsEngagement";
import AdsSpending from "../../components/restro-pilot/AdsSpending";
import AdsRoiTime from "../../components/restro-pilot/AdsRoiTime";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import LoaderComponent from "../../components/common/loaderComponent";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import AdsDietary from "../../components/restro-pilot/AdsDietary";
import * as adsPerformanceActions from "../../actions/restro-pilot/adsPerformanceActions"
import queryString from "query-string";

const adPerformanceCard = [
  { id: "sales", title : "Ad Sales" },
  { id: "spends", title : "Ad Spend" },
  { id: "impressions", title : "Ad Impressions" },
  { id: "orders", title : "Ad Orders" },
  { id: "menu_visits", title : "Ad Menu Visits" },
  { id: "roi", title : "Ad ROI" }
]

const AdsPage = (props) => {
  const { adsPerformanceActions, engagementTableOvertime, engagementTableLocation, spendingTableOvertime, spendingTableLocation, dietaryTableOvertime, dietaryTableLocation, history, location, match, location : { search } } = props;
  const { match: { params: { business_id } } } = props;
  const [activeTab, setActiveTab] = useState("1");
  const [mainData, setMainData] = useState([]);
  const [selectedCard, setSelectedCard] = useState("sales");
  const [loading, setLoading] = useState(false);
  const [adsEngagementData, setAdsEngagementData] = useState(null);
  const [adsDietaryData, setAdsDietaryData] = useState(null);
  const [isProviderWiseEngage, setIsProviderWiseEngage] = useState(false);
  const [isProviderWiseSpend, setIsProviderWiseSpend] = useState(false);
  const [isProviderWiseDietary, setIsProviderWiseDietary] = useState(false);
  const [adsSpendData, setAdsSpendData] = useState(null);
  const [adsRoiTimeSlotData, setRoiTimeSlotData] = useState([]);

  useEffect(() => {
    document.title = "Ads Performance";
    props.actions.setSelectedTab(3);
  }, []); //eslint-disable-line

  const handleTabsApi = async () => {
    setLoading(true);
    axios_instance.get(`${utils.format(URLS.ADS_ENGAGEMENT, [business_id])}${search ? search + '&' : '?'}selected_option=${selectedCard}`)
      .then((res) => {
        console.log(res.data);
        setAdsEngagementData(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });

    axios_instance.get(`${utils.format(URLS.ADS_DIETARY, [business_id])}${search ? search + '&' : '?'}selected_option=${selectedCard}`)
      .then((res) => {
        setAdsDietaryData(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });

    axios_instance.get(`${utils.format(URLS.ADS_SPEND, [business_id])}${search ? search + '&' : '?'}selected_option=${selectedCard}`)
      .then((res) => {
        setAdsSpendData(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });

    axios_instance.get(`${utils.format(URLS.ADS_ROI_TIME_SLOT, [business_id])}${search ? search + '&' : '?'}`)
      .then((res) => {
        setRoiTimeSlotData(res.data);
      }).catch((err) => console.log(err));

      const param = {
        business_id: business_id,
        ...queryString.parse(search),
        is_provider_wise: isProviderWiseEngage,
        is_overtime_table: true,
        selected_option: selectedCard
      }

      try {
        await Promise.all([
          adsPerformanceActions.getEngagementTableOvertime(param),
          adsPerformanceActions.getSpendingTableOvertime(param),
          adsPerformanceActions.getDietaryTableOvertime(param)
        ]);
      } catch (error) {
        console.error("Error in on fetch table overtime list:", error);
      }
      const param_location = {
        business_id: business_id,
        ...queryString.parse(search),
        is_provider_wise: isProviderWiseEngage,
        is_overtime_table: false,
        selected_option: selectedCard
      }

      try {
        await Promise.all([
          adsPerformanceActions.getDietaryTableLocation(param_location),
          adsPerformanceActions.getEngagementTableLocation(param_location),
          adsPerformanceActions.getSpendingTableLocation(param_location)
        ])
      } catch (error) {
        console.error("Error in on fetch  table location list:", error);
      }
  }

  useEffect(() => {
    axios_instance.get(`${utils.format(URLS.ADS_PAGE, [business_id])}${search ? search + '&' : '?'}`)
      .then((res) => {
        setMainData({
          "sales": res.data?.ads_sales || [],
          "spends": res.data?.ads_spend || [],
          "impressions": res.data?.ads_impressions || [],
          "orders": res.data?.ads_orders || [],
          "menu_visits": res.data?.ads_menu_visits || [],
          "roi": res.data?.ads_roi || [],
        })
      }).catch((err) => {
        console.log(err);
      });

    handleTabsApi();
    }, [search]); //eslint-disable-line

  const toggleTab = (val) => {
    setActiveTab(val);
  }

  const selectedCardTitle = adPerformanceCard.find((item) => item.id === selectedCard)?.title || "Ad Sales";
  const handleCardClick = async (id) => {
    setSelectedCard(id);
    setLoading(true);
    axios_instance.get(`${utils.format(URLS.ADS_ENGAGEMENT, [business_id])}${search ? search + '&' : '?'}selected_option=${id}`)
      .then((res) => {
        setAdsEngagementData(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });

    axios_instance.get(`${utils.format(URLS.ADS_DIETARY, [business_id])}${search ? search + '&' : '?'}selected_option=${id}`)
      .then((res) => {
        setAdsDietaryData(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });

    axios_instance.get(`${utils.format(URLS.ADS_SPEND, [business_id])}${search ? search + '&' : '?'}selected_option=${id}`)
      .then((res) => {
        setAdsSpendData(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });

    const param = {
      business_id: business_id,
      ...queryString.parse(search),
      is_provider_wise: isProviderWiseEngage,
      is_overtime_table: true,
      selected_option: id
    }

    try {
      await Promise.all([
        adsPerformanceActions.getEngagementTableOvertime(param),
        adsPerformanceActions.getSpendingTableOvertime(param),
        adsPerformanceActions.getDietaryTableOvertime(param)
      ]);
    } catch (error) {
      console.error("Error in on fetch table overtime list:", error);
    }
    const param_location = {
      business_id: business_id,
      ...queryString.parse(search),
      is_provider_wise: isProviderWiseEngage,
      is_overtime_table: false,
      selected_option: id
    }

    try {
      await Promise.all([
        adsPerformanceActions.getDietaryTableLocation(param_location),
        adsPerformanceActions.getEngagementTableLocation(param_location),
        adsPerformanceActions.getSpendingTableLocation(param_location)
      ])
    } catch (error) {
      console.error("Error in on fetch  table location list:", error);
    }
  }

  const handleDownload = (view, isLocation) => {
    let url ='';
    let fileName = '';
    if(view==='engagement') {
      url= `${utils.format(URLS.ADS_ENGAGEMENT_DOWNLOAD, [business_id])}${search ? search + '&' : '?'}is_location_download=${isLocation}`;
      fileName='ads-engagement.csv';
    } else if(view==='dietary') {
      url= `${utils.format(URLS.ADS_DIETARY_DOWNLOAD, [business_id])}${search ? search + '&' : '?'}is_location_download=${isLocation}`;
      fileName='ads-dietary.csv';
    } else if(view==='spend') {
      url= `${utils.format(URLS.ADS_SPEND_DOWNLOAD, [business_id])}${search ? search + '&' : '?'}is_location_download=${isLocation}`;
      fileName='ads-spends.csv';
    } else if(view==='roi_time_slot') {
      url= `${utils.format(URLS.ADS_ROI_TIME_SLOT_DOWNLOAD, [business_id])}${search ? search + '&' : '?'}is_location_download=${isLocation}`;
      fileName='ads-roi.csv';
    }
    props.reviewsActions.downloadTemplate(url, fileName);
  }

  const handleProviderWiseEngage = (event, { checked }) => {
    setIsProviderWiseEngage(checked);
    const param = {
      business_id: business_id,
      ...queryString.parse(search),
      is_provider_wise: checked,
      is_overtime_table: true,
      selected_option: selectedCard
    }
    try {
      adsPerformanceActions.getEngagementTableOvertime(param);
    } catch (error) {
      console.error("Error in on fetch engagement table overtime list:", error);
    }

    const param_location = {
      business_id: business_id,
      ...queryString.parse(search),
      is_provider_wise: checked,
      is_overtime_table: false,
      selected_option: selectedCard
    }

    try {
      adsPerformanceActions.getEngagementTableLocation(param_location);
    } catch (error) {
      console.error("Error in on fetch engagement table location list:", error);
    }
  }

  const handleProviderWiseSpend = (event, { checked }) => {
    setIsProviderWiseSpend(checked);
    const param = {
      business_id: business_id,
      ...queryString.parse(search),
      is_provider_wise: checked,
      is_overtime_table: true,
      selected_option: selectedCard
    }
    try {
      adsPerformanceActions.getSpendingTableOvertime(param);
    } catch (error) {
      console.error("Error in on fetch spend table overtime list:", error);
    }
    
    const param_location = {
      business_id: business_id,
      ...queryString.parse(search),
      is_provider_wise: checked,
      is_overtime_table: false,
      selected_option: selectedCard
    }

    try {
      adsPerformanceActions.getSpendingTableLocation(param_location);
    } catch (error) {
      console.error("Error in on fetch spend table location list:", error);
    }
  }

  const handleProviderWiseDietary = (event, { checked }) => {
    setIsProviderWiseDietary(checked);
    const param = {
      business_id: business_id,
      ...queryString.parse(search),
      is_provider_wise: checked,
      is_overtime_table: true,
      selected_option: selectedCard
    }
    try {
      adsPerformanceActions.getDietaryTableOvertime(param);
    } catch (error) {
      console.error("Error in on fetch dietary table overtime list:", error);
    }
    const param_location = {
      business_id: business_id,
      ...queryString.parse(search),
      is_provider_wise: checked,
      is_overtime_table: false,
      selected_option: selectedCard
    }
    try {
      adsPerformanceActions.getDietaryTableLocation(param_location);
    } catch (error) {
      console.error("Error in on fetch dietary table location list:", error);
    }
  }

  const redirectTo = (subUrl) => {
    setTimeout(() => {
      history.push(`/${business_id}${subUrl}`);
    }, 400);
  };

  const handleRootTabChange = (value) => {
    switch (value) {
      case 0:
        redirectTo("/ads?date_type=prior_30_day");
        break;
      case 1:
        redirectTo("/ads-campaign?date_type=prior_30_day&page_size=10&page=1");
        break;
      default:
        break;
    }
  };

  return (<div className="my-4">
    <LoaderComponent loader={loading} />
    <div className={"my-4"}>
      <FPTab
        tabLabels={["Ads Performance", "All Campaigns"]}
        minTabWidth={220}
        defaultValue={0}
        onChange={handleRootTabChange}
      />
    </div>
    <div>
      <AdsFilter history={history} location={location} match={match} />
      <div
        className="business-summary-grid"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
          gap: "12px",
          marginBottom: "24px",
          padding: "10px 0",
        }}
      >
        {adPerformanceCard.map((item, index) => (
          <div
            key={item.id}
            className={classnames("price-special-card shadow-sm", {
              "selected-card": selectedCard === item.id,
            })}
            onClick={() => handleCardClick(item.id)}
            style={{ backgroundColor: selectedCard === item.id ? "#FDF2F7" : "white", display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
          >
            <span style={{ fontSize: "16px", color: "#1B1C1D" }}>{item.title}</span>
            { mainData && mainData[item.id] && 
              <div className="price-card">
                <div className="d-flex flex-column" style={{ gap: "10px", overflowY: 'auto', maxHeight: '160px', padding: '2px 9px' }}>
                  { mainData[item.id].map((val_item) => (
                      <div className="d-flex align-items-center justify-content-between">
                        {val_item.provider_name === 'All' 
                          ? <span style={{ fontWeight: "400", fontSize: "16px", color: "#7A8193" }}>All</span>
                          : <img src={val_item.provider_logo} alt={val_item.provider_name} style={{ width: '22px' }} />}
                        <span style={{ fontWeight: "500", fontSize: "16px", color: "#1B1C1D" }}> 
                          { index!== 2 && index!== 3 && index!== 4 && index!== 5 && "₹"} { val_item?.total ? Intl.NumberFormat('en-IN').format( val_item.total) : 0}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </div>
            }
          </div>
        ))}
      </div>
      <CardWrapper showDivider={false} headerTitle={selectedCardTitle}>
        <div className="report-ThirdRow mb-4">
          <Nav className="Setup_Sub_Nav">
            <NavItem>
              <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => toggleTab("1")}>
                Engagement Level
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === "2" })} onClick={() => toggleTab("2")}>
                Spending Potential
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === "3" })} onClick={() => toggleTab("3")}>
                Dietary Preference
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === "4" })} onClick={() => toggleTab("4")}>
                ROI by Time Slot
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {activeTab==='1' && 
          <AdsEngagement 
            adsEngagementData={adsEngagementData} 
            engagementTableOvertime={engagementTableOvertime}
            engagementTableLocation={engagementTableLocation}
            isProviderWise={isProviderWiseEngage} 
            handleProviderWise={handleProviderWiseEngage} 
            handleDownload={handleDownload} 
            selectedCard={selectedCard}
          />
        }
        {activeTab==='2' && 
          <AdsSpending 
            spendingTableOvertime={spendingTableOvertime}
            spendingTableLocation={spendingTableLocation}
            adsSpendData={adsSpendData} 
            handleDownload={handleDownload} 
            handleProviderWise={handleProviderWiseSpend}
            isProviderWise={isProviderWiseSpend}
            selectedCard={selectedCard}
          />
        }
        {activeTab==='3' && 
          <AdsDietary 
            dietaryTableOvertime={dietaryTableOvertime}
            dietaryTableLocation={dietaryTableLocation}
            adsDietaryData={adsDietaryData} 
            handleDownload={handleDownload} 
            handleProviderWise={handleProviderWiseDietary}
            isProviderWise={isProviderWiseDietary}
            selectedCard={selectedCard}
          />
        }
        {activeTab==='4' && 
          <AdsRoiTime 
            adsRoiTimeSlotData={adsRoiTimeSlotData} 
            handleDownload={handleDownload} 
          />
        }
      </CardWrapper>
    </div>
  </div>);
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(setSelectedTab, dispatch),
    reviewsActions: bindActionCreators(ReviewsActions, dispatch),
    adsPerformanceActions: bindActionCreators(adsPerformanceActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
    engagementTableOvertime: state.adsPeformance.engagementTableOvertime,
    engagementTableLocation: state.adsPeformance.engagementTableLocation,
    spendingTableOvertime: state.adsPeformance.spendingTableOvertime,
    spendingTableLocation: state.adsPeformance.spendingTableLocation,
    dietaryTableOvertime: state.adsPeformance.dietaryTableOvertime,
    dietaryTableLocation: state.adsPeformance.dietaryTableLocation
  };
};

AdsPage.propTypes = {
  loader: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdsPage);