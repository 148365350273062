import React from "react";
import { Popup } from "semantic-ui-react";
import LoaderComponent from "../common/loaderComponent";
import moment from "moment";
import { useState } from "react";
import UploadStaffModal from "./UploadStaffModal";
import { Link } from "react-router-dom";
import { FPButton, FPToolTip } from "../common/new";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import AddIcon from "@material-ui/icons/Add";
import add from "../../images/svg/plus-black.svg";
import minus from "../../images/svg/Subtract.svg";
import deleteIcon from "../../images/svg/trash.svg";
import ConfirmationModal from "../common/ConfirmationModal";
import PaginationComponent from "../common/Pagination";

const StaffResponseListComponent = (props) => {
  const { staffList, addStaff, loader, businessId, history, location, deleteStaff, pageSizeCookie } = props;

  const [expandedRow, setExpandedRow] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);
  const [staffId, setStaffId] = useState(null);

  const renderAliases = (aliases) => {
    // if (aliases && aliases.length > 0) {
    //   return aliases.join(', ');
    // }

    if (aliases && aliases.length > 0) {
      if (aliases.length > 3) {
        return aliases.slice(0, 3).join(", ") + " +" + (aliases.length - 3);
      } else {
        return aliases.join(", ");
      }
    }
    return "N/A";
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleRowExpansion = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const toggleUploadReview = () => {
    setIsOpen(!isOpen);
  };

  const toggleConfirm = () => {
    deleteStaff(staffId);
    setIsConfirm(false);
  };

  const handleClick = (id) => {
    setStaffId(id);
    setIsConfirm(!isConfirm);
  };

  return (
    <div>
      <LoaderComponent loader={loader} />
      <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
        <span
          style={{
            fontSize: "18px",
            fontWeight: "400",
            color: "rgba(100, 101, 101, 1)",
          }}
        >
          Staff
        </span>
        <div>
          <FPButton
            label="Add Staff"
            type="button"
            onClick={addStaff}
            style={{ height: "30px" }}
            endIcon={<AddIcon style={{ height: 16, width: 16, color: "white" }} />}
          />
          <FPButton
            label="Bulk Upload"
            variant="outline"
            type="button"
            style={{ height: "30px" }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            className="ml-10"
            endIcon={<AddIcon style={{ height: 16, width: 16 }} />}
          />
        </div>
      </div>
      {isOpen && <UploadStaffModal isOpen={isOpen} toggle={toggleUploadReview} history={history} location={location} />}
      <CardWrapper showDivider={false} contentClass="p-3">
        <div className="ui-table-responsive">
          <Table className="fp-table fp-table-center">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>S.No</TableCell>
                <TableCell>Location Name</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell style={{ width: "200px" }}>Aliases</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staffList && staffList?.results?.length > 0 ? (
                staffList?.results?.map((item, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell>
                        {item.staff_members.length > 0 &&
                          (expandedRow === index ? (
                            <img
                              src={minus}
                              alt="-"
                              onClick={() => toggleRowExpansion(index)}
                              className="cursor-pointer"
                            />
                          ) : (
                            <img
                              src={add}
                              alt="+"
                              onClick={() => toggleRowExpansion(index)}
                              className="cursor-pointer"
                            />
                          ))}
                      </TableCell>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {item.location_name} ({item.staff_members.length})
                        {/* <div>
                          <FPToolTip title={item.location_name}>
                            <span class="d-inline-block text-truncate width-md">
                              {item.location_name}
                            </span>
                            <span> ({item.staff_members.length})</span>
                          </FPToolTip>
                        </div> */}
                      </TableCell>
                      <TableCell>{"ALL"}</TableCell>
                      <TableCell>{"ALL"}</TableCell>
                      <TableCell>{"ALL"}</TableCell>
                      <TableCell
                        style={{
                          whiteSpace: "break-spaces",
                          maxWidth: "200px",
                        }}
                      >
                        {"ALL"}
                      </TableCell>
                      <TableCell className="text-nowrap">{moment(item.created_at).format("DD-MM-YYYY")}</TableCell>
                      {expandedRow === index && <TableCell></TableCell>}
                    </TableRow>
                    {expandedRow === index &&
                      item.staff_members.map((staff, _index) => (
                        <TableRow style={{ backgroundColor: "rgba(242, 249, 249, 1)" }}>
                          <TableCell> </TableCell>
                          <TableCell>
                            {index + 1}.{_index + 1}
                          </TableCell>
                          <TableCell>{item.location_name}</TableCell>
                          <TableCell>
                            <Link title={item.title} to={`/${businessId}/staff?id=${staff.id}`} className="font-dark">
                              {staff.name}
                            </Link>
                          </TableCell>
                          <TableCell>{staff.department}</TableCell>
                          <TableCell>{staff.designation}</TableCell>
                          <TableCell
                            // give width to this cell and more than show below side rest of the aliases
                            style={{
                              whiteSpace: "break-spaces",
                              maxWidth: "200px",
                            }}
                          >
                            {renderAliases(staff.aliases)}
                            {staff.aliases.length > 3 && (
                              <Popup
                                size="tiny"
                                content={
                                  <div
                                    style={{
                                      fontSize: "11.7px",
                                      lineHeight: "17.55px",
                                      fontWeight: "400",
                                      whiteSpace: "break-spaces",
                                    }}
                                  >
                                    {staff.aliases.join(", ")}
                                  </div>
                                }
                                trigger={
                                  <span className="cursor-pointer" style={{ color: "#2185d0" }}>
                                    {"..."}
                                    {staff.aliases.length > 3 && " +"}
                                    {staff.aliases.length > 3 && staff.aliases.length - 3}
                                  </span>
                                }
                              />
                            )}
                          </TableCell>
                          <TableCell className="text-nowrap">{moment(item.created_at).format("DD-MM-YYYY")}</TableCell>

                          <TableCell>
                            <FPToolTip title="Delete">
                              <img src={deleteIcon} onClick={() => handleClick(staff.id)} />
                            </FPToolTip>
                          </TableCell>
                        </TableRow>
                      ))}
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="8" textAlign="center">
                    No Records Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <PaginationComponent count={staffList && staffList.count} location={location} pageSizeCookie={pageSizeCookie} />
        <ConfirmationModal
          header="Are you sure!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader={`Are you sure you want to delete this staff?`}
          isOpen={isConfirm}
          toggle={() => setIsConfirm(!isConfirm)}
          onConfirm={() => toggleConfirm()}
        />
      </CardWrapper>
    </div>
  );
};

export default StaffResponseListComponent;
