import React, { useEffect, useRef, useState } from "react";
import "./ReviewEmailStyle.css";
import AnswerHeader from "./components/AnswerHeader";
import { FPAvatar, FPButton } from "../../components/common/new";
import AddReviewDetailsModal from "../../components/business/AddReviewDetailsModal";
import EmailHeaderActivity from "./components/EmailHeaderActivity";
import emailPdfIcon from "../../images/svg/emailPdfIcon.svg";
import replyleft from "../../images/svg/Reply.svg";
import ReplytoAllIcon from "../../images/svg/ReplytoAllIcon.svg";
import ForwardIcon from "../../images/svg/ForwardIcon.svg";
import { Box } from "@material-ui/core";
import ViewEmailTicketActivity from "./components/ViewEmailTicketActivity";
import FormModal from "../../components/common/FormModal";
import EmailReplyCard from "./components/EmailReplyCard";
import { AiOutlineDelete } from "react-icons/ai";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
} from "@mui/material";
import attachment from "../../images/svg/attachment.svg";
import LoaderComponent from "../../components/messenger/Common/LoaderComponent";
import EmailAttachments from "./components/EmailAttachments";
import CreateTicketForm from "../../components/tickets/CreateTicketForm";

function EmailAnswer({
  selectedEmail,
  totalEmailCount,
  setSelectedIndex,
  selectedIndex,
  isLoader,
  emailListActions,
  emailList,
  setIsLoader,
  search,
  branchOptions,
  match: {
    params,
  },
  match: {
    params: { business_id },
  },
  ticketActions, teamActions
}) {
  const [openDetailsModel, setOpenDetailsModel] = useState(false);
  const [open, setOpen] = useState(false);
  const [openReplyCard, setOpenReplyCard] = useState(null);
  const [isReplayToAll, setIsReplayToAll] = useState(false);
  const [expanded, setExpanded] = useState(selectedEmail?.id);
  const [selectedEmailData, setSelectedEmailData] = useState(null);
  const [ticketData, setTicketData] = useState(null);
  const [forwardMail, setForwardMail] = useState(false);
  const [isTicketModal, setIsTicketModal] = useState(false);
  const emailContainerRef = useRef(null);
  const closeToggle = () => {
    setOpen(false);
  };
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (selectedEmail) {
      setSelectedEmailData(Object.values(selectedEmail));
    }
  }, [selectedEmail]);

  useEffect(() => {
    if (selectedEmailData?.length > 0) {
      setExpanded(selectedEmailData[selectedEmailData.length - 1]?.id);
    }
  }, [selectedEmailData]);

  useEffect(() => {
    if (emailContainerRef.current) {
      emailContainerRef.current.scrollIntoView({
        behavior: "instant",
        block: "end",
      });
    }
  }, [selectedEmailData]);

  const toggleModel = (data) => {
    setTicketData(data);
    setOpen(true);
  };

  const sendEmail = async(data) => {
    setIsLoader(true);
    const {
      subject,
      message_id: email_message_id,
      thread_id,
      user_id: from,
    } = openReplyCard;

    const {
      content: body,
      attachments: files,
      bccEmails: bcc,
      ccEmails: cc,
      toEmails: to,
    } = data;
    const payload = {
      subject,
      email_message_id,
      thread_id,
      from,
      body,
      to,
      ...(cc.length > 0 ? { cc } : {}),
      ...(bcc.length > 0 ? { bcc } : {}),
    };
    const formData = new FormData();

    Object.entries(payload).forEach(([key, value]) => {
      if (value) formData.append(key, value);
    });
    
    if (files?.length) {
      const filePromises = files.map(async (fileObj, index) => {
        try {
          const response = await fetch(fileObj.url);
          if (!response.ok) throw new Error(`Failed to fetch file: ${fileObj.url}`);
          
          const blob = await response.blob();
          const file = new File([blob], `file-${Date.now()}-${index}`, { type: fileObj.type });
          return file;
        } catch (error) {
          console.error("Error fetching file:", error);
          return null;
        }
      });
    
      const fileResults = await Promise.all(filePromises);
    
      fileResults.forEach((file) => {
        if (file) formData.append("files", file);
      });
    }    
    emailListActions.sendEmail(formData, business_id).then(() => {
      const thread_id = emailList[selectedIndex]?.thread_id;
      emailListActions
        .ReviewEmailDetails(thread_id, business_id)
        .then(() => setIsLoader(false))
        .catch(() => setIsLoader(false));
    });
    setForwardMail(false);
  };

  const replyToAll = (data) => {
    setIsReplayToAll(true);
    setOpenReplyCard(data);
  };

  const isForwardAll = (data) => {
    setForwardMail(true);
    setOpenReplyCard(data);
  };

  const getTeamList = (query) => {
    const showLoader = false
    return teamActions.teamList({ ...params, ...query }, business_id, showLoader);
  };

  const handleTicketForm = (data) => {
    console.log("data",data);
    setIsLoader(true);
    let final_data = { ...data, source: "manual", source_id: 1 };
    const showLoader = false
    return ticketActions.createTicket(final_data, business_id, showLoader)
      .then(() => {
        const thread_id = emailList[selectedIndex]?.thread_id;
        emailListActions
          .ReviewEmailDetails(thread_id, business_id)
          .then(() => {setIsTicketModal(false); setIsLoader(false)})
          .catch(() => {setIsTicketModal(false); setIsLoader(false)});
      });
  };

  return (
    <>
      <div className="right-sidebar-content">
        <div className="email-answer">
          <AnswerHeader
            selectedEmail={selectedEmail}
            totalEmailCount={totalEmailCount}
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
            emailList={emailList}
            search={search}
          />
          {isLoader ? (
            <div className="my-5">
              <LoaderComponent showLoader={isLoader} />
            </div>
          ) : (
            <div className="email-main-content" ref={emailContainerRef}>
              <div className="email-title">
                {selectedEmailData?.length > 0 && selectedEmailData[0]?.subject}
              </div>
              {selectedEmailData?.map((data, index) => (
                <Accordion
                  key={data.id}
                  expanded={expanded === data.id}
                  onChange={handleChange(data?.id)}
                >
                  <AccordionSummary
                    className={`${data?.ticket &&
                      Object.keys(data.ticket).length > 0 &&
                      expanded === data.id &&
                      "first-email-header"
                      }`}
                  >
                    {expanded === data.id ? (
                      <div style={{ width: "100%" }}>
                        <div className="email-meta-2">
                          <div className="sender-details">
                            <FPAvatar
                              src={data?.ticket}
                              labelClass={"email-user-name"}
                              avatarCenter={true}
                              userName={data?.sender_id}
                            />
                          </div>
                          <div className="activity-header">
                            {(index === 0 && (!data?.ticket || Object.keys(data?.ticket).length === 0)) && (
                              <FPButton
                                label="Create Ticket"
                                height="24px"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setIsTicketModal(true);
                                }}
                              />
                            )}

                            <div className="timestamp">{data?.msg_create}</div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                          width: "100%",
                        }}
                      >
                        <FPAvatar
                          avatarCenter={false}
                          src={data?.ticket?.reviewer}
                          alt=""
                          className="email-avatar"
                        />
                        <div style={{ width: "100%" }}>
                          <div className="d-flex justify-content-between w-100">
                            <p className="email-user-name mb-0">
                              {data?.sender_id}
                            </p>
                            <Typography variant="body2" color="textSecondary">
                              <div className="d-flex">
                                {data?.attachments > 0 && (
                                  <img
                                    src={attachment}
                                    alt="attachment-icon"
                                    style={{ height: "18px" }}
                                  />
                                )}
                                <div className="activity-header">
                                  <div className="activity-header">
                                  {(index === 0 && (!data?.ticket || Object.keys(data?.ticket).length === 0)) &&  (
                                      <FPButton
                                        label="Create Ticket"
                                        height="24px"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setIsTicketModal(true);
                                        }}
                                      />
                                    )}
                                    <div className="timestamp">{data?.msg_create}</div>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </div>
                          <Typography variant="subtitle1" gutterBottom>
                            {data?.subject}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="email-details">
                      <>
                        <div className="email-header-activity mb-3">
                          {data?.ticket &&
                            Object.keys(data.ticket).length > 0 ? (
                            <EmailHeaderActivity
                              item={data.ticket}
                              replyDisabled={false}
                              ticketActivity={true}
                              respondedMenubox={true}
                              toggleTicketModal={(item) => toggleModel(data)}
                              changeStatus={() =>
                                console.log("Change Ticket Status")
                              }
                            />
                          ) : null}
                        </div>
                      </>

                      <div
                        className="email-content"
                        style={{ padding: "0px 20px 15px 20px" }}
                      >
                        <p
                          style={{ overflowWrap: "break-word" }}
                          dangerouslySetInnerHTML={{
                            __html: data?.body.replace(/\n/g, "<br />"),
                          }}
                        ></p>
                      </div>
                      {data?.attachments?.length > 0 && (
                        <EmailAttachments data={data} />
                      )}
                      {openReplyCard?.id !== data.id && (
                        <div className="email-actions d-flex justify-content-between">
                          <div className="d-flex gap-10">
                            {[
                              {
                                icon: replyleft,
                                text: "Reply",
                                action: () => setOpenReplyCard(data),
                              },
                              {
                                icon: ReplytoAllIcon,
                                text: "Reply to All",
                                action: () => replyToAll(data),
                              },
                              {
                                icon: ForwardIcon,
                                text: "Forward",
                                action: () => isForwardAll(data),
                              },
                            ].map((btn, index) => (
                              <Box
                                key={index}
                                sx={{
                                  display: "flex",
                                  cursor: "pointer",
                                  alignItems: "center",
                                  marginRight: "10px",
                                }}
                                onClick={btn.action}
                              >
                                <img
                                  src={btn.icon}
                                  style={{
                                    height: "18px",
                                    width: "18px",
                                    marginRight: "6px",
                                  }}
                                />
                                {btn.text}
                              </Box>
                            ))}
                          </div>
                          {!data?.ticket && (
                            <Box
                              sx={{
                                display: "flex",
                                cursor: "pointer",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <AiOutlineDelete
                                className="footer-icon"
                                style={{ marginRight: "5px" }}
                              />
                              Delete
                            </Box>
                          )}
                        </div>
                      )}
                    </div>
                  </AccordionDetails>
                  {openReplyCard?.id === data.id && (
                    <EmailReplyCard
                      recipientName={isReplayToAll ? data?.to : data?.to[0]}
                      onClose={() => setOpenReplyCard(null)}
                      onSend={(data) => sendEmail(data)}
                      bodyData={openReplyCard}
                      forwardMail={forwardMail}
                      setForwardMail={setForwardMail}
                    />
                  )}
                </Accordion>
              ))}
            </div>
          )}
        </div>

        <AddReviewDetailsModal
          isOpen={openDetailsModel}
          toggle={() => setOpenDetailsModel(false)}
          heading={"Add Details"}
          businessId={business_id}
          review_id={openReplyCard?.ticket?.id}
        />
        <FormModal
          isOpen={open}
          toggle={closeToggle}
          heading="View Email Ticket Activity"
          width={"100%"}
          maxWidth={"lg"}
          headerClass="d-flex align-items-center"
          paddingDense
        >
          <ViewEmailTicketActivity item={ticketData} />
        </FormModal>

        <FormModal
                isOpen={isTicketModal}
                toggle={() => setIsTicketModal(false)}
                size="lg"
                width={"700px"}
                heading="Create Ticket"
              >
                <CreateTicketForm
                  onSubmit={handleTicketForm}
                  branchOptions={branchOptions && branchOptions?.choices}
                  // getTeamList={teamList?.results}
                  getTeamList={getTeamList}
                />
              </FormModal>

      </div>
    </>
  );
}

export default EmailAnswer;
