import React from "react";
import { connect } from "react-redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { FPButton } from "../common/new";
import FPInput from "../common/new/forms/ui/FPInput";
import { Box } from "@material-ui/core";
// import ConfirmationModal from "../common/ConfirmationModal";

const emptyString = (value) =>
  !value && "Please enter a name before submitting";

class TagAddForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tagNameChars: 25,
      // isSuceessConfirmOpen: false
    };
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const { addOrEditTag } = this.props;
    return addOrEditTag(data);
  }

  componentDidUpdate() {
    let { tagValue, change } = this.props;
    let tagNameChars;

    if (tagValue && tagValue.length > 25) {
      tagValue = tagValue.substring(0, 25);
      tagNameChars = 0;
      change("name", tagValue);
    } else {
      tagNameChars = 25 - (tagValue ? tagValue.length : 0);
    }
    this.setState({ tagNameChars });
  }

  renderInput = ({ input, type, meta: { error, touched } }) => {
    return (
      <span>
        <FPInput label="Tag name" {...input} type={type}></FPInput>
        {touched && error && <span style={{ color: "red" }}>{error}</span>}
      </span>
    );
  };

  // toggleConfirmSuccess(){
  //   this.setState({isSuceessConfirmOpen: !this.state.isSuceessConfirmOpen})
  // }

  render() {
    const {
      handleSubmit,
      submitting,
      toggle,
      showCancelButton = "true",
    } = this.props;
    return (
      <div style={{paddingBottom: '10px'}}>
        <form onSubmit={handleSubmit(this.submit)}>
          <div >
            <Field  
              name="name"
              component={this.renderInput}
              validate={emptyString}
              type="text"
            ></Field>
          </div>
          <Box
            sx={{ fontSize: "smaller", color: "#1B1C1D", marginTop: "10px" }}
          >
            {this.state.tagNameChars} characters left
          </Box>
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            className="-mb-10 mt-10"
          >
            <FPButton
              size="small"
              disabled={submitting}
              type="submit"
              className="mr-3 px-5"
              label="Add"
              minWi
              successResponse = {()=>{
                this.toggleConfirmSuccess()
              }}
            />
            {showCancelButton && (
              <FPButton
                size="small"
                variant="outline"
                onClick={(e) => {
                  e.preventDefault();
                  toggle();
                }}
                type="button"
                label="Cancel"
              />
            )}
          </div>
        </form>

        {/* <ConfirmationModal
          header="Are you sure you want to delete?"
          subHeader="This cannot be undone."
          isOpen={this.state.isSuceessConfirmOpen}
          toggle={this.toggleConfirmSuccess}
          onConfirm={this.state.confirmationFunction}
        /> */}
      </div>
    );
  }
}

TagAddForm = reduxForm({
  // eslint-disable-line
  form: "TagAddForm",
  enableReinitialize: true,
})(TagAddForm);

const selector = formValueSelector("TagAddForm");

TagAddForm = connect((state) => {
  // eslint-disable-line
  const tagValue = selector(state, "name");
  return { tagValue };
})(TagAddForm);

export default TagAddForm;