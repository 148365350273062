import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import cookie from "react-cookies";
import ChangePasswordForm from "../accounts/changePassword/ChangePasswordForm";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import * as ChangePasswordActions from "../../actions/accounts/changePasswordActions";
import * as commonAction from "../../actions/common/commonAction";
import UserForm from "../accounts/profile/profileForm";
import * as ProfileActions from "../../actions/accounts/profileAction";
import AgencyTeamMapping from "../../containers/Agency/AgencyTeamMapping";
import NavBar from "../common/NavBar";
import PaymentsPage from "../../containers/payments/PaymentsPage";
import * as routerStateAction from "../../actions/common/routerStateAction";
import NotificationUI from "../../containers/Notifications/NotificatioinUI";
import * as getEnterpriseData from "../../actions/business/businessSetupActions";

const MyProfile = (props) => {
  const {
    match,
    history,
    location,
    profileUser,
    changePasswordActions,
    actions,
    commonActions,
    business_userq,
    businessList,
    enterpriseData,enterpriseList
  } = props;
  const { search } = location;
  
  const {
    params: { agency_id, business_id },
  } = match;

  useEffect(() => {
    enterpriseData.getEnterpriseData(business_id)
  }, [search])
  console.log("enterpriseList", enterpriseList);
  const [activeTab, setActiveTab] = useState(props.routerState?.activeTab || "1");
  const user = profileUser || cookie.load("user");
  const business_user = business_userq || cookie.load("business_user") || {};
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };
  const goBack = () => {
    const id = business_id ? business_id : agency_id;
    history.push(`/${id}/dashboard`);
  };
  const changePassword = (values) => {
    return changePasswordActions.changePasswordAction(values);
    // .then(() => togglePasswordModal());
  };
  const saveProfile = (values, businessId) => {
    return actions.profileAction(values, businessId).then(() => {
      businessId ? toggleBusiness() : toggle();
    });
  };
  const toggleBusiness = () => {
    Promise.all([
      actions.getBusinessProfile(business_id),
      commonActions.rationsOptions(),
      commonActions.webportalOptions(),
      commonActions.scheduleOptions(),
    ]);
    // .then(() => setIsBusinessOpen(true));
  };
  const toggle = () => {
    Promise.all([
      actions.getProfileInfo(),
      commonActions.rationsOptions(),
      commonActions.webportalOptions(),
      commonActions.scheduleOptions(),
    ]);
    // .then(() => setIsOpen(true));
  };
  const uploadImage = (image, businessId) => {
    return actions
      .uploadProfileImage(image, businessId)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  return (
    <div className="mt-20">
      {!business_id && <NavBar match={match} history={history} />}
      <CardWrapper
        headerTitle="Settings"
        showDivider={false}
        contentClass="p-3"
      >
        <Row className="m-0">
          <Col xs={12} sm={12} className="p-0">
            <Nav className="Central_nav">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggleTab("1");
                  }}
                >
                  My Profile
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggleTab("2");
                  }}
                >
                  Password
                </NavLink>
              </NavItem>
              {user.is_business_owner && business_id && (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === "3",
                    })}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Payments
                  </NavLink>
                </NavItem>
              )}
              {user.business_type === "marketing" && (
                <>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "4",
                      })}
                      onClick={() => {
                        toggleTab("4");
                      }}
                    >
                      Teams
                    </NavLink>
                  </NavItem>
                  {business_id && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          toggleTab("5");
                        }}
                      >
                        Business
                      </NavLink>
                    </NavItem>
                  )}
                </>
              )}
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "6",
                  })}
                  onClick={() => {
                    toggleTab("6");
                  }}
                >
                  Notifications
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>
        <TabContent activeTab={activeTab} className="mt-2">
          <TabPane tabId="1">
            <UserForm
              hideHeading
              headingText="User Information"
              profileSave={saveProfile}
              initialValues={user}
              uploadImage={(image) => uploadImage(image)}
              showEmailAlerts={user.business_type !== "marketing"}
              showScheduleAlerts={user.business_type !== "marketing"}
              goBack={goBack}
            />
          </TabPane>
          <TabPane tabId="2">
            <ChangePasswordForm
              handleChangePassword={changePassword}
              goBack={goBack}
            />
          </TabPane>
          <TabPane tabId="3">
            <PaymentsPage history={history} location={location} match={match} />
          </TabPane>
          <TabPane tabId="4">
            <AgencyTeamMapping
              history={history}
              location={location}
              match={match}
            />
          </TabPane>
          <TabPane tabId="5">
            <UserForm
              hideHeading
              headingText="Business Profile"
              profileSave={(data) => saveProfile(data, business_user.business)}
              initialValues={business_user}
              uploadImage={(image) =>
                uploadImage(image, business_user.business)
              }
              enterpriseList={enterpriseList}
              showScheduleAlerts
              showEmailAlerts
              goBack={goBack}
            />
          </TabPane>
          <TabPane tabId="6">
            <NotificationUI
              showDivider={true}
              dateTimeII={true}
              tableBorder={{ border: "1px solid #EAECF0", borderRadius: "8px" }}
              tableTop="mt-3"
              tableContainerClass="mt-4"
              tableBodyClass="px-4"
              tableBodyRowII="mx-2"
              viewBtn={true}
              tableBodyRowI="px-4"
            />
          </TabPane>
        </TabContent>
      </CardWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profileUser: state.profile.user,
    business_userq: state.profile.business_user,
    routerState: state.routerState.state,
    enterpriseList: state.business.enterprise_list
  };
};
MyProfile.propTypes = {
  actions: PropTypes.instanceOf(Object),
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ProfileActions, dispatch),
    commonActions: bindActionCreators(commonAction, dispatch),
    changePasswordActions: bindActionCreators(ChangePasswordActions, dispatch),
    enterpriseData: bindActionCreators(getEnterpriseData, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(MyProfile);
