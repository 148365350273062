import * as types from "../../actions/actionTypes";

export default function OfflineStoreAlerts(
  state = [],
  action
) {
  switch (action.type) {
    case types.OFFLINE_STORE_ALERTS_SUCCESS:
      return Object.assign({}, state, {offlineStoreAlerts: {...action.data}});
    case types.OFFLINE_STORE_ALERTS_FAILURE:
      return Object.assign({}, state, { errors: { ...state.errors, serviceRequestOverview: { ...action.data } }});
    default:
      return state;
  }
}
