import React from "react";
import { FPCheckbox } from "../common/new";
import ErrorMessage from "../common/new/forms/ui/ErrorMessage";

class RenderCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStar: props.input.value || [],
    };
  }

  handleCheckboxChange = (value) => {
    const {
      input: { onChange },
    } = this.props;

    const selectedStar = this.state.selectedStar.includes(value)
      ? this.state.selectedStar.filter((s) => s !== value)
      : [...this.state.selectedStar, value];
    this.setState({ selectedStar });
    onChange(selectedStar);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      this.setState({ selectedStar: this.props.input.value || [] });
    }
  }

  render() {
    const { selectedStar } = this.state;
    const {
      options,
      labelKey = "display_name",
      valueKey = "value",
      input,
      meta: { touched, error, serverError },
      ...rest
    } = this.props;
    return (
      <div>
        <div className="d-flex gap-10">
          {options?.length > 0 &&
            options.map((item) => (
              <label key={item[valueKey]}>
                <FPCheckbox
                  type="checkbox"
                  value={item[valueKey]}
                  checked={selectedStar.includes(item[valueKey])}
                  onChange={() => this.handleCheckboxChange(item[valueKey])}
                  {...rest}
                />
                {item[labelKey]}
              </label>
            ))}
        </div>
        {touched && error && <ErrorMessage>{error}</ErrorMessage>}
        {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
      </div>
    );
  }
}

const MultiSelectRedux = (props) => {
  return <RenderCheckbox {...props} />;
};
export default MultiSelectRedux;
