import React, { memo } from "react";
import { MessageBox } from "../../components/common/new";
import Linkify from "react-linkify";
import Highlighter from "react-highlight-words";
import moment from "moment";
import "./userDetails.css";
import CustomRatingComponent from "../../components/common/customRating";
import star from "../../images/reviewMethods/star_B_W.png";
import star_active from "../../images/reviewMethods/star_Color.png";
import Rater from "react-rater";
import { capitalize } from "../../libs/utils";
import { useMemo } from "react";

const ReviewText = ({ item, searchText, commentDisabled }) => {
  const messageTime = useMemo(
    () => (item.review_create ? moment(item.review_create).format("llll") : ""),
    [item.review_create]
  );

  const replyTime = useMemo(
    () =>
      item.reviews_comment
        ? moment(item.reviews_comment.created_at).format("llll")
        : "",
    [item.reviews_comment?.created_at]
  );

  return (
    <React.Fragment>
      <div className="d-flex flex-column gap-10">
        {item?.description &&
          (item.provider_name === "ewards" ? (
            <MessageBox isSelfBox={false} time={messageTime}>
              {item.description.split(",").map((item_split, item_index) => {
                return (
                  <p key={item_index} className="fp-size-13">
                    {item_split}
                  </p>
                );
              })}
            </MessageBox>
          ) : item.provider_name === "inhouse" ? (
            <MessageBox isSelfBox={false} time={messageTime}>
              {item.description.split("\\n").map((item_split, item_index) => {
                return (
                  <div key={item_index}>
                    {item_split.includes("Feedback Link") ? (
                      <span className="fp-size-13">
                        Feedback Link :
                        <a
                          rel="noreferrer"
                          href={item_split.split("Feedback Link :")[1]}
                          target="_blank"
                        >
                          {item_split.split("Feedback Link :")[1]}
                        </a>
                      </span>
                    ) : (
                      <p>{item_split}</p>
                    )}
                  </div>
                );
              })}
            </MessageBox>
          ) : item.provider_name === "google" ? (
            <MessageBox isSelfBox={false} time={messageTime}>
              {item.description.split("\n").map((item_split, item_index) => {
                // return <p key={item_index}>{item_split}</p>;
                return (
                  <Linkify
                    properties={{ target: "_blank" }}
                    className="font-blue-gray fp-size-13"
                    key={item_index}
                  >
                    <Highlighter
                      highlightStyle={{
                        color: item.rating > 3 ? "green" : "red",
                        fontWeight: "bold",
                      }}
                      searchWords={[searchText]}
                      textToHighlight={item_split || ""}
                    />
                  </Linkify>
                );
              })}
            </MessageBox>
          ) : (
            <MessageBox isSelfBox={false} time={messageTime}>
              <React.Fragment>
                <Linkify
                  properties={{ target: "_blank" }}
                  className="font-blue-gray fp-size-13"
                >
                  <Highlighter
                    highlightStyle={{
                      color: item.rating > 3 ? "green" : "red",
                      fontWeight: "bold",
                    }}
                    searchWords={[searchText]}
                    textToHighlight={item.description || ""}
                  />
                </Linkify>
                {item.survey_pages.length > 0 && (
                  <table className="review-survey-res">
                    <tbody>
                      {item.survey_pages.map((sp, index) => {
                        return (
                          <tr key={sp.question__question} className="">
                            <td>
                              <span className="font-blue-gray fp-size-13">
                                {index + 1}.{" "}
                              </span>
                              <span className="font-blue-gray fp-size-13">
                                {sp.question__question}{" "}
                              </span>
                            </td>
                            <td>
                              {sp.question__question_type === "RATING" ? (
                                <Rater
                                  total={5}
                                  interactive={false}
                                  rating={+sp.extra_text}
                                >
                                  <CustomRatingComponent
                                    activeImg={star_active}
                                    inActiveImg={star}
                                    imageWidth={30}
                                    style={{ marginRight: "2px" }}
                                  />
                                </Rater>
                              ) : sp.question__question_type ===
                                "NPS QUESTION" ? (
                                <div className="nps-score">
                                  {Array(10)
                                    .fill(+sp.extra_text)
                                    .map((_, index) => {
                                      return (
                                        <div
                                          style={{
                                            display: "inline-block",
                                            maxHeight: "35px",
                                            maxWidth: "35px",
                                          }}
                                          className={`mr-2 ${
                                            +sp.extra_text === index + 1
                                              ? "review-img-active"
                                              : "review-img-inactive"
                                          }`}
                                          key={index}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                            className={`nps-method-circles ${
                                              +sp.extra_text === index + 1
                                                ? "nps-score-active"
                                                : "nps-score-inactive"
                                            }`}
                                          >
                                            <div>{index + 1}</div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              ) : sp.extra_text != null ? (
                                <span
                                  style={{
                                    fontWeight: "700px",
                                    color: "#1B1C1D",
                                    fontSize: "13px",
                                  }}
                                >
                                  {capitalize(sp.extra_text)}
                                </span>
                              ) : sp.option__option != null ? (
                                <span
                                  style={{
                                    fontWeight: "700px",
                                    color: "#1B1C1D",
                                    fontSize: "13px",
                                  }}
                                >
                                  {capitalize(sp.option__option)}
                                </span>
                              ) : (
                                <span
                                  style={{
                                    fontWeight: "700px",
                                    color: "#1B1C1D",
                                    fontSize: "13px",
                                  }}
                                >
                                  {capitalize(sp.option__option_text)}
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </React.Fragment>
            </MessageBox>
          ))}

        {item?.reviews_comment &&
          !commentDisabled &&
          item?.reviews_comment?.comments && (
            <MessageBox
              backgroundColor="#FDE6F1"
              time={replyTime}
              author={item.reviews_comment.added_by}
            >
              <p className="mb-0 font-dark fp-size-13">
                {item.reviews_comment.comments}
              </p>
            </MessageBox>
          )}
      </div>
    </React.Fragment>
  );
};

export default memo(ReviewText);
