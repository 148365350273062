import React, { useEffect, useState } from "react";
import * as URLS from "../../libs/apiUrls";
import { getAllParamsInArrayForm, getParams, removeLifetimeFilter } from "../../libs/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonAction from "../../actions/common/commonAction";
import * as filterListActions from "../../actions/common/filterListActions";
import { FPButton, FPFilter, FPToolTip } from "../../components/common/new";
import FormModal from "../../components/common/FormModal";
import { ReactComponent as DownloadIcon } from "../../images/svg/Download.svg";
import { IconButton } from "@material-ui/core";
import moment from "moment";

const ComplaintFilter = (props) => {
  const {
    common,
    history,
    filter,
    filterAction,
    match,
    downloadExcel,
    reviewSourceOptions,
    complaintTypes,
    pageSizeCookie,
    getData
  } = props;
  const [isMoreThanNinety, setIsMoreThanNinety] = useState(false);

  const dateType = getParams(window.location.search, "date_type");
  const startDate = getParams(window.location.search, "start_date");
  const endDate = getParams(window.location.search, "end_date");

  const fetchFilterList = async () => {
    const {
      params: { business_id },
    } = match;
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch("", `${URLS.BUSINESS_SETUP}${business_id}/branch/`),
        filterAction.getContentType("", `${URLS.CONTENT_OPTIONS}`),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  const handleReviewDownloadFlow = () => {
    if (dateType === "other") {
      const M_startDate = moment(startDate);
      const M_endDate = moment(endDate);
      const daysDifference = M_endDate.diff(M_startDate, "days");
      if (daysDifference > 90) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    } else {
      if (["prior_6_month", "current_year", "prior_12_months"].indexOf(dateType) > -1) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    }
  };

  useEffect(() => {
    fetchFilterList();
  }, []);

  const handleDefaultFilter = (list, key) => {
    const filter = window.location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable = defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    
    return list?.map((item) => {
      return {
        ...item,
        isChecked: isKeyFilterAvailable && defaultFilter[key].includes(item.value.toString()),
        parentKey: key
      };
    });
  };

  const statusData = [
    {
      value: "False",
      display_name: "Open",
      parentKey: "is_resolved",
    },
    {
      value: "True",
      display_name: "Resolved",
      parentKey: "is_resolved",
    },
  ];

  const filterList = [
    {
      id: "1",
      label: "Created Date Duration",
      key: "date_type",
      type: "radio",
      isSearchable: false,
      list: filter.date_type && handleDefaultFilter(removeLifetimeFilter(filter?.date_type?.choices), "date_type"),
    },
    {
      id: "2",
      label: "Complaint Pull Date",
      key: "complaint_pull_date",
      type: "radio",
      isSearchable: false,
      list: filter.date_type && handleDefaultFilter(removeLifetimeFilter(filter?.date_type?.choices), "complaint_pull_date"),
    },
    {
      id: "3",
      label: "Source",
      key: "provider",
      type: "checkbox",
      isSearchable: true,
      list: reviewSourceOptions && handleDefaultFilter(reviewSourceOptions, "provider"),
    },
    {
      id: "4",
      label: "City",  
      key: "city",
      type: "checkbox",
      isSearchable: true,
      // list: handleDefaultFilter(serviceOptions, "city"),
      list: common.city_options && handleDefaultFilter(common.city_options.choices, "city"),
    },
    {
      id: "5",
      label: "State",
      key: "state",
      type: "checkbox",
      isSearchable: true,
      list: common.state_options && handleDefaultFilter(common.state_options.choices, "state"),
    },
    {
      id: "6",
      label: "Location",
      key: "branch",
      type: "radio",
      isSearchable: true,
      list: filter.branch && handleDefaultFilter(filter.branch.choices, "branch"),
    },
    {
      id: "7",
      label: "Complaint Type",
      key: "complaint_type",
      type: "checkbox",
      isSearchable: "true",
      list: complaintTypes && handleDefaultFilter(complaintTypes, "complaint_type"),
    },
    {
      id: "8",
      label: "Location Tag",
      key: "branch_tag",
      type: "checkbox",
      isSearchable: false,
      list:
        common.branch_tag_options &&
        handleDefaultFilter(
          common.branch_tag_options.results.map((item) => ({
            value: item.id,
            display_name: item.title,
          })),
          "branch_tag"
        ),
    },
    {
      id: "9",
      label: "Status",
      key: "is_resolved",
      type: "radio",
      isSearchable: false,
      isNotEllipsisLabel: true,
      list: statusData && handleDefaultFilter(statusData, "is_resolved"),
    },
  ];
  return (
    <React.Fragment>
      <FPFilter
        data={filterList}
        className="mt-3"
        history={history}
        pageSizeCookie={pageSizeCookie}
        ticketFilter="TICKET"
        getData={getData}
        match={match}
        actionRender={() => (
          <FPToolTip title={"Download"}>
            <IconButton component="span" className="circular-button" onClick={() => handleReviewDownloadFlow()}>
              <DownloadIcon />
            </IconButton>
          </FPToolTip>
        )}
      />
      {isMoreThanNinety && (
        <FormModal
          isOpen={isMoreThanNinety}
          toggle={() => setIsMoreThanNinety(false)}
          heading="Attention !"
          maxWidth="xs"
          showDivider={true}
          dialogActions={<FPButton size="small" onClick={() => setIsMoreThanNinety(false)} label="OK" />}
        >
          <p className="fp-size-16">Please contact to support to download data older than 90 days.</p>
        </FormModal>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    filter: state.filter,
    source: state.review.review_source_options,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ComplaintFilter);
