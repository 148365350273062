import React from "react";
import AutoSelect from "./AutoSelect";
import ErrorMessage from "./new/forms/ui/ErrorMessage";

const AutoSelectField = ({
  input,
  meta: { touched, error },
  serverError,
  placeholder,
  minHeight,
  multiple = false,
  selectAll = false,
  ...rest
}) => {
  const { value, onChange, onBlur, onFocus } = input;
  
  return (
    <>
      <AutoSelect
        {...rest}
        placeholder={placeholder}
        value={!!value ? value : null}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        onBlur={() => onBlur(value)}
        onFocus={() => onFocus(value)}
        multiple={multiple}
        selectAll = {selectAll}
      />
          {touched && error && <ErrorMessage>{error}</ErrorMessage>}
          {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
    </>
  );
};

export const renderAutoSelectField = (props) => <AutoSelectField {...props} />;
