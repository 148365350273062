import * as types from '../../actions/actionTypes';

export default function reviewsDetails(state = [], action) {
  switch (action.type) {
    case types.REVIEWS_DETAILS_SUCCESS:
      return Object.assign({}, state, { list: { ...action.data } });
    case types.POST_COMMENTS_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.POST_COMMENTS_FAILURE:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.REVIEW_CSV_UPLOAD_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.REVIEW_CSV_UPLOAD_FAILURE:
      return Object.assign({}, state, action.data);
    case types.REVIEW_SOURCE_OPTIONS_SUCCESS:
      return Object.assign({}, state, { review_source_options: { ...action.data } });
    case types.POST_REVIEWS_FORM_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.POST_REVIEWS_FORM_FAILURE:
      return Object.assign({}, state, action.data);
    case types.SOURCE_SYSTEM_ORDER_DETAIL_SETTING_SUCCESS:
      return Object.assign({}, state, { orderDetailSetting: { ...action.data } });
    case types.SOURCE_SYSTEM_ORDER_DETAIL_SETTING_FAILURE:
      return Object.assign({}, state, { orderDetailSetting: { ...action.data } });
    case types.TAG_OPTIONS_SUCCESS:
      return Object.assign({}, state, { tag_options: { ...action.data } });
    case types.TAG_OPTIONS_FAILURE:
      return Object.assign({}, state );
    default:
      return state;
  }
}
