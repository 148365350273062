import PropTypes from "prop-types";
import React from "react";
import { SubmissionError, reduxForm } from "redux-form";
import { Divider, Checkbox } from "semantic-ui-react";
import { Label, Row, Col, Input, FormGroup } from "reactstrap";
import ErrorModal from "../../components/messenger/Common/ErrorModal";
import LoaderComponent from "../common/loaderComponent";
import cookie from "react-cookies";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { IconButton } from "@material-ui/core";
import deleteIcon from "../../images/svg/trash.svg";
import { FPButton, FPMenuBox } from "../common/new";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ErrorMessage from "../common/new/forms/ui/ErrorMessage";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { AddOptionBtn, QuestionActions, RemoveBtn } from "../campaign/genericTemplate/ActionsBtn";
import Rater from "react-rater";
import CustomRatingComponent from "../common/customRating";
import star_active from "../../images/reviewMethods/star_Color.png";
import star from "../../images/reviewMethods/star_B_W.png";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import "../../components/campaign/genericTemplate/genericTemplateForm.css";
import * as utils from "../../libs/utils";
import ConfirmationModal from "../common/ConfirmationModal";

class TicketResolutions extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.deleteSurveyPageTitle = this.deleteSurveyPageTitle.bind(this);
    this.addSurveyPageTitleFn = this.addSurveyPageTitleFn.bind(this);
    this.choiceQuestinFunction = this.choiceQuestinFunction.bind(this);
    this.addMoreChoicesOfQuestionFn = this.addMoreChoicesOfQuestionFn.bind(this);
    this.deleteChoicesQuestionFn = this.deleteChoicesQuestionFn.bind(this);
    this.deleteMoreChoicesOfQuestionFn = this.deleteMoreChoicesOfQuestionFn.bind(this);
    this.toggleConfirm = this.toggleConfirm.bind(this);
    this.myRef = [];
    this.state = {
      error: "",
      dropdownOpen: false,
      AddQuestionDropdownOpen: false,
      ReAddQuestionOpen: false,
      addSurveyPagetitle: false,
      AddPageBelowForm: [
        {
          page: "",
          title: "",
          pageQuestionAddError: "",
          titleBoolean: false,
          edit: false,
          titleError: "",
          allQuestions: [],
        },
      ],
      MainDropdownIndex: 0,
      titleInputIndex: 0,
      choiceQuestinDropDown: 0,
      pageTitle: "",
      pageTitleIndex: 0,
      pageTitleError: "",
      pageQuestionInputIndex: 0,
      pageQuestionInputError: "",
      pageQuestionOptionInputIndex: 0,
      pageQuestionOptionInputError: "",
      errorModalShow: false,
      sendMessageError: "",
      surveyFormSubmit: false,
      nps_array: Array(10).fill(false),
      activeIndex: -1,
    };
  }

  componentDidMount() {
    const { resolutionEnabled, resolutionData } = this.props;
    var survey_pages_array = [];
    if (resolutionEnabled && resolutionData && resolutionData.length > 0 && resolutionData[0].survey_pages_data) {
      resolutionData[0].survey_pages_data.map((page) => {
        var page_obj = {
          page_id: page.page_id,
          page: "",
          title: page.title,
          pageQuestionAddError: "",
          titleBoolean: true,
          edit: true,
          titleError: "",
        };
        var question_array = [];
        page.allQuestions.map((question) => {
          var question_type = "choicesQues";
          if (question.question_type == "Checkbox") {
            question_type = "checkboxQues";
          }
          if (question.question_type == "Drop-down") {
            question_type = "dropdownQues";
          }
          if (question.question_type == "SHORT ANSWER") {
            question_type = "shortAnswerQues";
          }
          if (question.question_type == "PARAGRAPH") {
            question_type = "paragraphQues";
          }
          if (question.question_type == "RATING") {
            question_type = "ratingQues";
          }
          if (question.question_type == "NPS QUESTION") {
            question_type = "scoreQues";
          }
          if (question.question_type == "ATTACHMENT") {
            question_type = "attachmentQues";
          }
          var question_obj = {
            question_id: question.question_id,
            type: question_type,
            input: question.input,
            required: question.question_required,
            edit: true,
            questionError: "",
            shortFormQuestion: question.question_short_form,
            doneError: "",
          };
          var option_array = [];
          if (question_type == "choicesQues" || (question_type == "checkboxQues") | (question_type == "dropdownQues")) {
            question.options.map((option) => {
              var option_obj = {
                id: option.id,
                option: option.title,
                questionOptionError: "",
              };
              option_array.push(option_obj);
            });
          }
          if (question_type == "ratingQues" || question_type == "scoreQues") {
            question_obj["lowRatingInput"] = question.lowRatingInput;
            question_obj["HighRatingInput"] = question.HighRatingInput;
          }
          if (option_array.length > 0) {
            question_obj["options"] = option_array;
          }
          question_array.push(question_obj);
        });
        page_obj["allQuestions"] = question_array;
        survey_pages_array.push(page_obj);
      });
    } else {
      survey_pages_array.push({
        page: "",
        title: "",
        pageQuestionAddError: "",
        titleBoolean: false,
        edit: false,
        titleError: "",
        allQuestions: [],
      });
    }
    this.setState({ AddPageBelowForm: survey_pages_array });
  }

  componentDidUpdate(prevProps) {
    const { resolutionEnabled, resolutionData } = this.props;
    const { resolutionEnabled: prevResolutionEnabled, resolutionData: prevResolutionData } = prevProps;
    if (resolutionEnabled != prevResolutionEnabled || resolutionData != prevResolutionData) {
      var survey_pages_array = [];
      if (resolutionEnabled && resolutionData && resolutionData.length > 0 && resolutionData[0].survey_pages_data) {
        resolutionData[0].survey_pages_data.map((page) => {
          var page_obj = {
            page_id: page.page_id,
            page: "",
            title: page.title,
            pageQuestionAddError: "",
            titleBoolean: true,
            edit: true,
            titleError: "",
          };
          var question_array = [];
          page.allQuestions.map((question) => {
            var question_type = "choicesQues";
            if (question.question_type == "Checkbox") {
              question_type = "checkboxQues";
            }
            if (question.question_type == "Drop-down") {
              question_type = "dropdownQues";
            }
            if (question.question_type == "SHORT ANSWER") {
              question_type = "shortAnswerQues";
            }
            if (question.question_type == "PARAGRAPH") {
              question_type = "paragraphQues";
            }
            if (question.question_type == "RATING") {
              question_type = "ratingQues";
            }
            if (question.question_type == "NPS QUESTION") {
              question_type = "scoreQues";
            }
            if (question.question_type == "ATTACHMENT") {
              question_type = "attachmentQues";
            }
            var question_obj = {
              question_id: question.question_id,
              type: question_type,
              input: question.input,
              required: question.question_required,
              edit: true,
              questionError: "",
              shortFormQuestion: question.question_short_form,
              doneError: "",
            };
            var option_array = [];
            if (
              question_type == "choicesQues" ||
              (question_type == "checkboxQues") | (question_type == "dropdownQues")
            ) {
              question.options.map((option) => {
                var option_obj = {
                  id: option.id,
                  option: option.title,
                  questionOptionError: "",
                };
                option_array.push(option_obj);
              });
            }
            if (question_type == "ratingQues" || question_type == "scoreQues") {
              question_obj["lowRatingInput"] = question.lowRatingInput;
              question_obj["HighRatingInput"] = question.HighRatingInput;
            }
            if (option_array.length > 0) {
              question_obj["options"] = option_array;
            }
            question_array.push(question_obj);
          });
          page_obj["allQuestions"] = question_array;
          survey_pages_array.push(page_obj);
        });
      } else {
        survey_pages_array.push({
          page: "",
          title: "",
          pageQuestionAddError: "",
          titleBoolean: true,
          edit: false,
          titleError: "",
          allQuestions: [],
        });
      }
      this.setState({ AddPageBelowForm: survey_pages_array });
    }
  }

  toggleConfirm = (confirmationFunction) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmationFunction,
    });
  };

  toggle(index) {
    if (this.state.dropdownOpen === false) {
      this.setState({ dropdownOpen: true, MainDropdownIndex: index });
    } else {
      this.setState({ dropdownOpen: false, MainDropdownIndex: index });
    }
  }

  AddQuestionToggle(index) {
    if (this.state.AddQuestionDropdownOpen === false) {
      this.setState({
        AddQuestionDropdownOpen: true,
        MainDropdownIndex: index,
      });
    } else {
      this.setState({
        AddQuestionDropdownOpen: false,
        MainDropdownIndex: index,
      });
    }
  }

  ReAddQuestionList(cindex) {
    if (this.state.ReAddQuestionOpen === false) {
      this.setState({ ReAddQuestionOpen: true, choiceQuestinDropDown: cindex });
    } else {
      this.setState({
        ReAddQuestionOpen: false,
        choiceQuestinDropDown: cindex,
      });
    }
  }

  addSurveyPageTitleFn(index) {
    let formValues = this.state.AddPageBelowForm;
    if (formValues[index].titleBoolean === false) {
      formValues[index].titleBoolean = true;
      this.setState({ formValues });
      this.forceUpdate();
    }
  }

  deleteSurveyPageTitle(index) {
    let formValues = this.state.AddPageBelowForm;
    if (formValues[index].titleBoolean === true) {
      formValues[index].titleBoolean = false;
      formValues[index].title = "";
      this.setState({ formValues, pageTitleError: "" });
      this.forceUpdate();
    }
  }

  addMorePageFunction() {
    let formValues = this.state.AddPageBelowForm;
    formValues.push({
      page: "",
      title: "",
      titleBoolean: true,
      edit: false,
      titleError: "",
      allQuestions: [],
    });
    this.setState({ formValues });
    this.forceUpdate();
  }

  surveyTitleInputHandler(i, e) {
    let formValues = this.state.AddPageBelowForm;
    if (e.target.value.length > 0) {
      formValues[i]["title"] = e.target.value;
      formValues[i]["titleError"] = "";
    } else {
      formValues[i]["title"] = "";
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  submitSurveyTitleInputHandler(index, edit) {
    let formValues = this.state.AddPageBelowForm;
    if (edit === false) {
      formValues[index]["edit"] = true;
    } else {
      formValues[index]["edit"] = false;
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  questionInputHandler(i, qi, e) {
    let formValues = this.state.AddPageBelowForm;
    if (e.target.value.length > 0) {
      formValues[i]["allQuestions"][qi]["questionError"] = "";
      formValues[i]["allQuestions"][qi]["input"] = e.target.value;
    } else {
      formValues[i]["allQuestions"][qi]["input"] = "";
      formValues[i]["allQuestions"][qi]["questionError"] = "Please enter question title.";
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  shortFormQuestionInputHandler(i, qi, e) {
    let formValues = this.state.AddPageBelowForm;
    if (e.target.value.length > 0) {
      formValues[i]["allQuestions"][qi]["shortFormQuestion"] = e.target.value;
    } else {
      formValues[i]["allQuestions"][qi]["shortFormQuestion"] = "";
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  questionOptionInputHandler(i, qi, oi, e) {
    let formValues = this.state.AddPageBelowForm;
    if (e.target.value.length > 0) {
      formValues[i]["allQuestions"][qi]["options"][oi]["option"] = e.target.value;
      formValues[i]["allQuestions"][qi]["options"][oi]["questionOptionError"] = "";
    } else {
      formValues[i]["allQuestions"][qi]["options"][oi]["option"] = "";
      formValues[i]["allQuestions"][qi]["options"][oi]["questionOptionError"] = "Please enter option";
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  ratingScoreInputHandler(i, qi, inputtype, e) {
    let formValues = this.state.AddPageBelowForm;
    if (e.target.value.length > 0) {
      if (inputtype === "low") {
        formValues[i]["allQuestions"][qi]["lowRatingInputError"] = "";
        formValues[i]["allQuestions"][qi]["lowRatingInput"] = e.target.value;
      } else if (inputtype === "high") {
        formValues[i]["allQuestions"][qi]["HighRatingInputError"] = "";
        formValues[i]["allQuestions"][qi]["HighRatingInput"] = e.target.value;
      }
    } else {
      if (inputtype === "low") {
        formValues[i]["allQuestions"][qi]["lowRatingInputError"] = "Please enter low rating";
        formValues[i]["allQuestions"][qi]["lowRatingInput"] = "";
      } else if (inputtype === "high") {
        formValues[i]["allQuestions"][qi]["HighRatingInputError"] = "Please enter high rating";
        formValues[i]["allQuestions"][qi]["HighRatingInput"] = "";
      }
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  submitQuestionInputHandler(i, qi, edit, type) {
    let formValues = this.state.AddPageBelowForm;
    if (formValues[i]["allQuestions"][qi]["input"] === "" && edit === false) {
      formValues[i]["allQuestions"][qi]["questionError"] = "Please enter question title.";
    } else if (formValues[i]["allQuestions"][qi]["input"] !== "" && edit === false) {
      formValues[i]["allQuestions"][qi]["edit"] = true;
      formValues[i]["allQuestions"][qi]["doneError"] = "";
    } else if (formValues[i]["allQuestions"][qi]["input"] !== "" && edit === true) {
      formValues[i]["allQuestions"][qi]["edit"] = false;
    }
    if (type === "choices" || type === "checkbox" || type === "dropdown") {
      const options = formValues[i]["allQuestions"][qi]["options"];
      for (let ii = 0; ii < options.length; ii++) {
        if (options[ii]["option"] === "" || formValues[i]["allQuestions"][qi]["input"] === "") {
          if (options[ii]["option"] === "") {
            formValues[i]["allQuestions"][qi]["options"][ii]["questionOptionError"] = "Please enter option";
            formValues[i]["allQuestions"][qi]["edit"] = false;
          }
        } else {
          formValues[i]["allQuestions"][qi]["options"][ii]["questionOptionError"] = "";
        }
      }
    }
    if (type === "score" || type === "rating") {
      if (formValues[i]["allQuestions"][qi]["lowRatingInput"] === "" && edit === false) {
        formValues[i]["allQuestions"][qi]["lowRatingInputError"] = "Please enter low rating.";
        formValues[i]["allQuestions"][qi]["edit"] = false;
      } else if (
        formValues[i]["allQuestions"][qi]["lowRatingInput"] !== "" &&
        formValues[i]["allQuestions"][qi]["input"] !== "" &&
        edit === false
      ) {
        formValues[i]["allQuestions"][qi]["edit"] = true;
      }
      if (formValues[i]["allQuestions"][qi]["HighRatingInput"] === "" && edit === false) {
        formValues[i]["allQuestions"][qi]["HighRatingInputError"] = "Please enter high rating.";
        formValues[i]["allQuestions"][qi]["edit"] = false;
      } else if (
        formValues[i]["allQuestions"][qi]["HighRatingInput"] !== "" &&
        formValues[i]["allQuestions"][qi]["input"] !== "" &&
        edit === false
      ) {
        formValues[i]["allQuestions"][qi]["edit"] = true;
      }
    }
    this.setState({ formValues });
    this.forceUpdate();
  }

  RemoveMoreSurveyPage(i) {
    let formValues = this.state.AddPageBelowForm;
    if (formValues.length === 1) {
      this.setState({
        AddPageBelowForm: [
          {
            page: "",
            title: "",
            pageQuestionAddError: "",
            titleBoolean: true,
            edit: false,
            titleError: "",
            allQuestions: [],
          },
        ],
      });
    } else {
      formValues.splice(i, 1);
      this.setState({ formValues });
    }

    this.forceUpdate();
  }

  choiceQuestinFunction(index, type) {
    let formValues = this.state.AddPageBelowForm;
    if (formValues[index]["allQuestions"].length <= 4) {
      if (type === "choices") {
        formValues[index]["allQuestions"].push({
          type: "choicesQues",
          input: "",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          options: [{ option: "", questionOptionError: "" }],
        });
      } else if (type === "checkbox") {
        formValues[index]["allQuestions"].push({
          type: "checkboxQues",
          input: "",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          options: [{ option: "", questionOptionError: "" }],
        });
      } else if (type === "dropdown") {
        formValues[index]["allQuestions"].push({
          type: "dropdownQues",
          input: "",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          options: [{ option: "", questionOptionError: "" }],
        });
      } else if (type === "shortAnswer") {
        formValues[index]["allQuestions"].push({
          type: "shortAnswerQues",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          input: "",
        });
      } else if (type === "paragraph") {
        formValues[index]["allQuestions"].push({
          type: "paragraphQues",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          input: "",
        });
      } else if (type === "rating") {
        formValues[index]["allQuestions"].push({
          type: "ratingQues",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          input: "",
          lowRatingInput: "",
          lowRatingInputError: "",
          HighRatingInput: "",
          HighRatingInputError: "",
          ratingChoice: 0,
          ratingChoiceError: "",
        });
      } else if (type === "score") {
        formValues[index]["allQuestions"].push({
          type: "scoreQues",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          input: "",
          lowRatingInput: "",
          lowRatingInputError: "",
          HighRatingInput: "",
          HighRatingInputError: "",
          ratingChoice: 0,
          ratingChoiceError: "",
        });
      } else if (type === "attachment") {
        formValues[index]["allQuestions"].push({
          type: "attachmentQues",
          required: false,
          edit: false,
          questionError: "",
          shortFormQuestion: "",
          doneError: "",
          input: "",
        });
      }
      formValues[index]["pageQuestionAddError"] = "";
      this.setState({ formValues, AddQuestionDropdownOpen: false });
      this.forceUpdate();
    } else {
      // this.setState({
      //   errorModalShow: true,
      //   AddQuestionDropdownOpen: false,
      //   sendMessageError: "Only 5 questions can be added per page.",
      // });
      utils.displayMessage("negative", "Only 5 questions can be added per page");
      this.forceUpdate();
    }
  }

  requiredQuestinFunction(e, index, qindex) {
    let formValues = this.state.AddPageBelowForm;
    formValues[index]["allQuestions"][qindex]["required"] = e.target.checked;
    this.setState({ formValues });
  }

  addMoreChoicesOfQuestionFn(index, i) {
    let formValues = this.state.AddPageBelowForm;
    formValues[index]["allQuestions"][i]["options"].push({
      option: "",
      questionOptionError: "",
    });
    this.setState({ formValues });
    this.forceUpdate();
  }

  deleteChoicesQuestionFn(index, i) {
    let formValues = this.state.AddPageBelowForm;
    formValues[index]["allQuestions"].splice(i, 1);
    this.setState({
      formValues,
      pageQuestionInputIndex: 0,
      pageQuestionInputError: "",
    });
    this.forceUpdate();
  }

  deleteMoreChoicesOfQuestionFn(index, chqi, ci) {
    let formValues = this.state.AddPageBelowForm;
    formValues[index]["allQuestions"][chqi]["options"].splice(ci, 1);
    this.setState({ formValues });
    this.forceUpdate();
  }

  submit() {
    let surveyQuestionForm = this.state.AddPageBelowForm;
    for (let si = 0; si < surveyQuestionForm.length; si++) {
      if (surveyQuestionForm[si]["allQuestions"].length === 0) {
        surveyQuestionForm[si]["pageQuestionAddError"] = "Please add question.";
      } else {
        for (let qi = 0; qi < surveyQuestionForm[si]["allQuestions"].length; qi++) {
          if (surveyQuestionForm[si]["allQuestions"][qi]["input"] === "") {
            surveyQuestionForm[si]["allQuestions"][qi]["questionError"] = "Please enter question title.";
          }
          if (
            surveyQuestionForm[si]["allQuestions"][qi]["type"] === "choicesQues" ||
            surveyQuestionForm[si]["allQuestions"][qi]["type"] === "checkboxQues" ||
            surveyQuestionForm[si]["allQuestions"][qi]["type"] === "dropdownQues"
          ) {
            for (let ci = 0; ci < surveyQuestionForm[si]["allQuestions"][qi]["options"].length; ci++) {
              if (surveyQuestionForm[si]["allQuestions"][qi]["options"][ci]["option"] === "") {
                surveyQuestionForm[si]["allQuestions"][qi]["options"][ci]["questionOptionError"] =
                  "Please enter question option";
              }
            }
          }
          if (
            surveyQuestionForm[si]["allQuestions"][qi]["type"] === "ratingQues" ||
            surveyQuestionForm[si]["allQuestions"][qi]["type"] === "scoreQues"
          ) {
            if (surveyQuestionForm[si]["allQuestions"][qi]["HighRatingInput"] === "") {
              surveyQuestionForm[si]["allQuestions"][qi]["HighRatingInputError"] = "Please enter high rating.";
            }
            if (surveyQuestionForm[si]["allQuestions"][qi]["lowRatingInput"] === "") {
              surveyQuestionForm[si]["allQuestions"][qi]["lowRatingInputError"] = "Please enter low rating.";
            }
          }
          if (surveyQuestionForm[si]["allQuestions"][qi]["edit"] === false) {
            surveyQuestionForm[si]["allQuestions"][qi]["doneError"] = "Please submit survey questions.";
            break;
          }
        }
      }
    }

    this.setState({ surveyQuestionForm });
    let isIssueGenerated = false;
    let issueIndex = -1;
    var exit_loops = false;
    for (let si = 0; si < surveyQuestionForm.length; si++) {
      if (surveyQuestionForm[si]["allQuestions"].length === 0) {
        isIssueGenerated = true;
        issueIndex = si;
        exit_loops = true;
        break;
      } else {
        for (let qi = 0; qi < surveyQuestionForm[si]["allQuestions"].length; qi++) {
          if (surveyQuestionForm[si]["allQuestions"][qi]["input"] === "") {
            isIssueGenerated = true;
            issueIndex = si;
            exit_loops = true;
            break;
          } else if (
            surveyQuestionForm[si]["allQuestions"][qi]["type"] === "choicesQues" ||
            surveyQuestionForm[si]["allQuestions"][qi]["type"] === "checkboxQues" ||
            surveyQuestionForm[si]["allQuestions"][qi]["type"] === "dropdownQues"
          ) {
            var isError = false;
            for (let ci = 0; ci < surveyQuestionForm[si]["allQuestions"][qi]["options"].length; ci++) {
              if (surveyQuestionForm[si]["allQuestions"][qi]["options"][ci]["option"] === "") {
                isIssueGenerated = true;
                issueIndex = si;
                isError = true;
              }
            }
            if (isError) {
              exit_loops = true;
              break;
            }
          } else if (
            surveyQuestionForm[si]["allQuestions"][qi]["type"] === "ratingQues" ||
            surveyQuestionForm[si]["allQuestions"][qi]["type"] === "scoreQues"
          ) {
            if (surveyQuestionForm[si]["allQuestions"][qi]["HighRatingInput"] === "") {
              isIssueGenerated = true;
              issueIndex = si;
              exit_loops = true;
              break;
            } else if (surveyQuestionForm[si]["allQuestions"][qi]["lowRatingInput"] === "") {
              isIssueGenerated = true;
              issueIndex = si;
              exit_loops = true;
              break;
            }
          }
          if (surveyQuestionForm[si]["allQuestions"][qi]["edit"] === false) {
            isIssueGenerated = true;
            issueIndex = si;
            exit_loops = true;
            break;
          }
        }
      }
      if (exit_loops) {
        break;
      }
    }
    var final_data = {
      surveyQuestion: JSON.stringify(this.state.AddPageBelowForm),
    };
    if (isIssueGenerated) {
      this.myRef[issueIndex].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      this.setState({ surveyFormSubmit: false });
      this.forceUpdate();
      const errobj = {
        "Survey Questions": "Please Submit Survey Questions.",
      };
      this.setState({ error: errobj });
      throw new SubmissionError(errobj);
    } else {
      this.setState({ surveyFormSubmit: true });
      this.forceUpdate();
      this.props.submitTicketResolutionData(final_data);
    }
  }

  errorModalCallback(data) {
    if (data === false) {
      this.setState({ errorModalShow: false });
    }
  }

  selectionList(pindex) {
    return [
      {
        label: "Single Choice",
        value: "choice",
        onClick: () => this.choiceQuestinFunction(pindex, "choices"),
      },
      {
        label: "Multiple Selection",
        value: "checkbox",
        onClick: () => this.choiceQuestinFunction(pindex, "checkbox"),
      },
      {
        label: "Dropdown",
        value: "dropdown",
        onClick: () => this.choiceQuestinFunction(pindex, "dropdown"),
      },
      {
        label: "Short Answer",
        value: "shortAnswer",
        onClick: () => this.choiceQuestinFunction(pindex, "shortAnswer"),
      },
      {
        label: "Long Answer",
        value: "paragraph",
        onClick: () => this.choiceQuestinFunction(pindex, "paragraph"),
      },
      {
        label: "Rating",
        value: "rating",
        onClick: () => this.choiceQuestinFunction(pindex, "rating"),
      },
      {
        label: "Net Promoter Score",
        value: "score",
        onClick: () => this.choiceQuestinFunction(pindex, "score"),
      },
      {
        label: "Attachment",
        value: "attachment",
        onClick: () => this.choiceQuestinFunction(pindex, "attachment"),
      },
    ];
  }

  handleNpsHover = (index, value) => {
    let newArray = [...this.state.nps_array];
    newArray[index] = value;
    this.setState({ nps_array: [...newArray] });
  };

  render() {
    const loggedin_user = cookie.load("business_user");
    const {
      resolutionEnabled,
      toggleTicketResolution,
      handleSubmit,
      errors,
      toggleTicketCommentBox,
      toggleProductIssueReasons,
      ticketCommentBoxDisabled,
      enableProductIssueReasons,
      toggleRestrictTicketClosingWithoutSurvey,
      restrictTicketClosingWithoutSurvey,
      businessInfo
    } = this.props;
    const {
      AddPageBelowForm,
      MainDropdownIndex,
      choiceQuestinDropDown,
      dropdownOpen,
      ReAddQuestionOpen,
      AddQuestionDropdownOpen,
      errorModalShow,
      sendMessageError,
      reviewMethod,
      isConfirmOpen,
      confirmationFunction,
    } = this.state;

    const CommentSectionModalSwitch = loggedin_user.is_business_owner
    ? !enableProductIssueReasons && AddPageBelowForm[0].allQuestions.length < 1
    : true
    return (
      <CardWrapper
        showDivider={false}
        contentClass="p-3"
        // headerTitle={
        //   <div className="d-flex justify-content-between align-items-center">
        //     <span>Ticket Resolution Survey</span>
        //     <div className="mt-2 float-right p-0">
        //       {/* <span className="mr-2" style={{ verticalAlign: "top" }}>
        //           {resolutionEnabled ? "Disable" : "Enable"} Ticket Resolution
        //         </span> */}
        //       <Radio
        //         style={{ zIndex: "1" }}
        //         toggle
        //         checked={resolutionEnabled}
        //         onClick={(e, value) => toggleTicketResolution(value.checked)}
        //         disabled={!loggedin_user.is_business_owner}
        //       />
        //     </div>
        //   </div>
        // }
      >
        <div className="d-flex justify-content-between align-items-start">
          <FPLabel required={false}>Ticket Resolution Survey</FPLabel>
          <Checkbox
            toggle
            checked={resolutionEnabled}
            onClick={(e, value) => toggleTicketResolution(value.checked)}
            disabled={!loggedin_user.is_business_owner}
          />
        </div>

        {resolutionEnabled && (
          <div className="generic-template-form">
            <div className="text_RadioBox">
              <div className="text_Radio">
                <span className="" style={{ fontSize: "14px" }}>
                  {enableProductIssueReasons ? "Disable" : "Enable"} Product Issue-Reasons Mapping Questions
                </span>
                <Checkbox
                  // style={{ zIndex: "1" }}
                  toggle
                  checked={enableProductIssueReasons}
                  onClick={(e, value) => {
                    if (loggedin_user.is_business_owner) {
                      toggleProductIssueReasons(value.checked)}
                    }
                  }
                  disabled={!loggedin_user.is_business_owner}
                />
              </div>
              <div className="text_Radio">
                <span className="" style={{ fontSize: "14px" }}>
                  {businessInfo?.disable_comment_section_in_ticket_review_modal ? "Enable" : "Disable"} Comment Section in Ticket Review Modal
                </span>
                <Checkbox
                  toggle
                  checked={businessInfo?.disable_comment_section_in_ticket_review_modal}
                  onClick={(e, value) => {
                    if (!CommentSectionModalSwitch) {
                      toggleTicketCommentBox(value.checked);
                    }
                  }}  
                  disabled={CommentSectionModalSwitch}
                />
              </div>
              <div className="text_Radio">
                <span className="" style={{ fontSize: "14px" }}>
                  Restrict Ticket Closing Without Submitting Ticket Survey
                </span>
                <Checkbox
                  toggle
                  checked={businessInfo?.restrict_ticket_closing_without_submitting_ticket_survey}
                  onClick={(e, value) => {
                    if (!CommentSectionModalSwitch) {
                    toggleRestrictTicketClosingWithoutSurvey(value.checked)}}
                  }
                  disabled={CommentSectionModalSwitch}
                />
              </div>
            </div>
            <div className="" style={{ marginTop: "5px" }}>
              <LoaderComponent loader={this.props.surveyFormLoading} />
              <div>
                <form className="grid-form" onSubmit={handleSubmit(this.submit)}>
                  {errors && errors.non_field_errors
                    ? errors.non_field_errors.map((error, index) => (
                        <p key={index} className="text-danger">
                          {error}
                        </p>
                      ))
                    : null}

                  {AddPageBelowForm.map((element, pindex) => (
                    <div className="survey-section-div mb-3" key={pindex} ref={(ref) => (this.myRef[pindex] = ref)}>
                      <Row>
                        <Col sm="3">
                          <div className="survey-section-page">
                            Page {pindex + 1} of {AddPageBelowForm.length}
                          </div>
                        </Col>
                      </Row>
                      {element.titleBoolean === true ? (
                        <Row className="mt-3 mb-3">
                          <Col sm="12">
                            <div className="page-title-section">
                              <div className="page-title-input">
                                <Label for="surveyPageTitle" className="mt-0">
                                  {" "}
                                  Page title(optional){" "}
                                </Label>
                                <Input
                                  name="title"
                                  placeholder="Page title"
                                  type="text"
                                  value={element.title}
                                  onChange={(e) => this.surveyTitleInputHandler(pindex, e)}
                                  readOnly={element.edit}
                                />
                                <p style={{ color: "red", fontSize: "12px" }}>{element.titleError}</p>
                              </div>
                              <div className="mt-2 d-flex justify-content-end align-items-center gap-10">
                                <IconButton
                                  type="button"
                                  size="small"
                                  disabled={element.edit}
                                  onClick={() => this.deleteSurveyPageTitle(pindex)}
                                  className="cursor-pointer"
                                >
                                  <img src={deleteIcon} role="button" className="cursor-pointer" alt="delete" />
                                </IconButton>
                                {element.edit === false ? (
                                  <FPButton
                                    type="button"
                                    onClick={() => this.submitSurveyTitleInputHandler(pindex, element.edit)}
                                    size="small"
                                    label="Done"
                                  />
                                ) : (
                                  <FPButton
                                    endIcon={<EditOutlinedIcon style={{ height: 16, width: 16 }} />}
                                    type="button"
                                    onClick={() => this.submitSurveyTitleInputHandler(pindex, element.edit)}
                                    size="small"
                                    label="Edit"
                                  />
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      ) : (
                        ""
                      )}

                      {element.allQuestions.length > 0 &&
                        element.allQuestions.map((question, qi) => {
                          //* Radio Question
                          if (question.type === "choicesQues") {
                            return (
                              <Row className="mt-3 mb-3" key={`${qi}-radio`} id={`surveyQuestion${pindex}${qi}`}>
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    {/* Title */}
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Choice question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) => this.questionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.questionError}</ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3">
                                      <Col sm={12}>
                                        <div className="choices-title-input pb-1">
                                          <FPLabel for="surveyPageTitle" isRequired={false}>
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) => this.shortFormQuestionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                        </div>
                                        <FPLabel className="mt-4 mb-0" isRequired={false}>
                                          Answer Options
                                        </FPLabel>
                                        <Divider className="mt-1 mb-0" />
                                      </Col>
                                    </Row>
                                    {/* options */}
                                    {question.options.length > 0 &&
                                      question.options.map((optiond, optionIndex) => (
                                        <Row className="mt-3" key={`${optionIndex}-radio-option`}>
                                          <Col>
                                            <div className="flex-between-center gap-10">
                                              <FormGroup
                                                style={{
                                                  minHeight: "unset",
                                                }}
                                              >
                                                <Input
                                                  disabled={question.edit}
                                                  id="radioInput"
                                                  name="radio"
                                                  type="radio"
                                                  value="true"
                                                />
                                              </FormGroup>
                                              <div className="question-choices-input flex-grow-1">
                                                <Input
                                                  name="option"
                                                  placeholder="Option title"
                                                  type="text"
                                                  value={optiond.option}
                                                  onChange={(e) =>
                                                    this.questionOptionInputHandler(pindex, qi, optionIndex, e)
                                                  }
                                                  readOnly={question.edit}
                                                />
                                              </div>
                                              <div style={{ width: "23px" }} className="text-right">
                                                {optionIndex !== 0 && (
                                                  <RemoveBtn
                                                    disabled={!(question.edit === false)}
                                                    onClick={() =>
                                                      this.deleteMoreChoicesOfQuestionFn(
                                                        pindex,
                                                        qi,
                                                        optionIndex,
                                                        "choices"
                                                      )
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </div>
                                            <ErrorMessage>{optiond.questionOptionError}</ErrorMessage>
                                          </Col>
                                        </Row>
                                      ))}
                                    {/* Actions */}
                                    <AddOptionBtn
                                      disabled={question.edit}
                                      onClick={() => this.addMoreChoicesOfQuestionFn(pindex, qi, "choices")}
                                    />
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDone={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "choices")
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "choices")
                                      }
                                      id={`choiceQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) => this.requiredQuestinFunction(e, pindex, qi, "choices")}
                                      onDelete={() => this.deleteChoicesQuestionFn(pindex, qi, "choices")}
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * CheckBox Question
                          if (question.type === "checkboxQues") {
                            return (
                              <Row className="mt-3 mb-3" key={`${qi}-checkbox`} id={`surveyQuestion${pindex}${qi}`}>
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Checkbox question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) => this.questionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.questionError}</ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel for="surveyPageTitle" isRequired={false}>
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) => this.shortFormQuestionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                        </div>
                                        <FPLabel className="mt-4 mb-0" isRequired={false}>
                                          Answer Options
                                        </FPLabel>
                                        <Divider className="mt-1 mb-0" />
                                      </Col>
                                    </Row>
                                    {/* options */}
                                    {question.options.length > 0 &&
                                      question.options.map((optionCD, optionCIndex) => (
                                        <Row className="mt-3" key={`${optionCIndex}-checkbox-option`}>
                                          <Col>
                                            {/* Option-Input */}
                                            <div className="flex-between-center gap-10">
                                              <FormGroup
                                                style={{
                                                  minHeight: "unset",
                                                  // marginBottom: '20px'
                                                }}
                                              >
                                                <Input
                                                  disabled={question.edit}
                                                  id="checkboxInput"
                                                  name="checkbox"
                                                  type="checkbox"
                                                  value="true"
                                                  // style={{margin: '12px 2px'}}
                                                />
                                              </FormGroup>
                                              <div
                                                className="question-choices-input flex-grow-1"
                                                //  style={{margin: '0px 25px'}}
                                              >
                                                <Input
                                                  name="option"
                                                  placeholder="Option title"
                                                  type="text"
                                                  value={optionCD.option}
                                                  onChange={(e) =>
                                                    this.questionOptionInputHandler(pindex, qi, optionCIndex, e)
                                                  }
                                                  readOnly={question.edit}
                                                />
                                              </div>

                                              <div
                                                style={{
                                                  width: "23px",
                                                }}
                                                className="text-right"
                                              >
                                                {optionCIndex !== 0 && (
                                                  <RemoveBtn
                                                    disabled={!(question.edit === false)}
                                                    onClick={() =>
                                                      this.deleteMoreChoicesOfQuestionFn(
                                                        pindex,
                                                        qi,
                                                        optionCIndex,
                                                        "checkbox"
                                                      )
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </div>

                                            {optionCD.questionOptionError && (
                                              <ErrorMessage>{optionCD.questionOptionError}</ErrorMessage>
                                            )}
                                          </Col>
                                        </Row>
                                      ))}
                                    {/* Actions */}
                                    <AddOptionBtn
                                      disabled={question.edit}
                                      onClick={() => this.addMoreChoicesOfQuestionFn(pindex, qi, "checkbox")}
                                    />{" "}
                                    {/* Done & Required */}
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() => this.deleteChoicesQuestionFn(pindex, qi, "checkbox")}
                                      id={`checkBoxQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) => this.requiredQuestinFunction(e, pindex, qi, "checkbox")}
                                      onDone={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "checkbox")
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "checkbox")
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * Dropdown Question
                          if (question.type === "dropdownQues") {
                            return (
                              <Row className="mt-3 mb-3" key={`${qi}-dropdown`} id={`surveyQuestion${pindex}${qi}`}>
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Drop down question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) => this.questionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.questionError}</ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel for="surveyPageTitle" isRequired={false}>
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) => this.shortFormQuestionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                        </div>
                                        <FPLabel className="mt-4 mb-0" isRequired={false}>
                                          Answer Options
                                        </FPLabel>
                                        <Divider className="mt-1 mb-0" />
                                      </Col>
                                    </Row>
                                    {/* options */}
                                    {question.options.length > 0 &&
                                      question.options.map((optionCD, optionCIndex) => (
                                        <Row className="mt-3" key={`${optionCIndex}-dropdown-option`}>
                                          <Col sm="12">
                                            <div className="flex-between-center gap-10">
                                              <Input
                                                name="option"
                                                placeholder="Option title"
                                                type="text"
                                                value={optionCD.option}
                                                onChange={(e) =>
                                                  this.questionOptionInputHandler(pindex, qi, optionCIndex, e)
                                                }
                                                readOnly={question.edit}
                                              />
                                              <div style={{ width: "23px" }} className="text-right">
                                                {optionCIndex !== 0 && (
                                                  <RemoveBtn
                                                    disabled={!(question.edit === false)}
                                                    onClick={() =>
                                                      this.deleteMoreChoicesOfQuestionFn(
                                                        pindex,
                                                        qi,
                                                        optionCIndex,
                                                        "dropdown"
                                                      )
                                                    }
                                                  />
                                                )}
                                              </div>
                                            </div>
                                            <ErrorMessage>{optionCD.questionOptionError}</ErrorMessage>
                                          </Col>
                                        </Row>
                                      ))}
                                    {/* Actions */}
                                    <AddOptionBtn
                                      disabled={question.edit}
                                      type="button"
                                      className="add-more-choices-option"
                                      onClick={() => this.addMoreChoicesOfQuestionFn(pindex, qi, "dropdown")}
                                    />
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() => this.deleteChoicesQuestionFn(pindex, qi, "dropdown")}
                                      id={`dropDownQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) => this.requiredQuestinFunction(e, pindex, qi, "dropdown")}
                                      onDone={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "dropdown")
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "dropdown")
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * Short Question
                          if (question.type === "shortAnswerQues") {
                            return (
                              <Row className="mt-3 mb-3" key={`${qi}-sortAnswer`} id={`surveyQuestion${pindex}${qi}`}>
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Short answer question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) => this.questionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.questionError}</ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-2">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel for="surveyPageTitle" isRequired={false}>
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) => this.shortFormQuestionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    {/* Actions */}
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() => this.deleteChoicesQuestionFn(pindex, qi, "shortAnswer")}
                                      id={`shortAnswerQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) => this.requiredQuestinFunction(e, pindex, qi, "shortAnswer")}
                                      onDone={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "shortAnswer")
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "shortAnswer")
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * Long Question
                          if (question.type === "paragraphQues") {
                            return (
                              <Row className="mt-3 mb-3" key={`${qi}-longQue`} id={`surveyQuestion${pindex}${qi}`}>
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Paragraph question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) => this.questionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.questionError}</ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-2">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel for="surveyPageTitle" isRequired={false}>
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) => this.shortFormQuestionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    {/* Actions */}
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() => this.deleteChoicesQuestionFn(pindex, qi, "paragraph")}
                                      id={`paragraphQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) => this.requiredQuestinFunction(e, pindex, qi, "paragraph")}
                                      onDone={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "paragraph")
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "paragraph")
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * Ratings Question
                          if (question.type === "ratingQues") {
                            return (
                              <Row className="mt-3 mb-3" key={`${qi}-ratingQue`} id={`surveyQuestion${pindex}${qi}`}>
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Rating question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) => this.questionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.questionError}</ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel for="surveyPageTitle" isRequired={false}>
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) => this.shortFormQuestionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                        </div>
                                        <FPLabel className="mt-4 mb-0" isRequired={false}>
                                          Answer Options
                                        </FPLabel>
                                        <Divider className="mt-1 mb-0" />
                                      </Col>
                                    </Row>

                                    <Row className="mt-3 mb-3">
                                      <Col sm="6">
                                        <div className="choices-title-input">
                                          <FPLabel for="exampleEmail">Low rating label</FPLabel>
                                          <Input
                                            name="lowRatingInput"
                                            placeholder="Low rating"
                                            type="text"
                                            value={question.lowRatingInput}
                                            onChange={(e) => this.ratingScoreInputHandler(pindex, qi, "low", e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.lowRatingInputError}</ErrorMessage>
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <div className="choices-title-input">
                                          <FPLabel for="exampleEmail">High rating label</FPLabel>
                                          <Input
                                            name="HighRatingInput"
                                            placeholder="High rating"
                                            type="text"
                                            value={question.HighRatingInput}
                                            onChange={(e) => this.ratingScoreInputHandler(pindex, qi, "high", e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.HighRatingInputError}</ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row className="mt-3 mb-3 nps-score">
                                      <Col sm="12">
                                        <CardWrapper contentClass="p-2" showDivider={false}>
                                          {/* eslint-disable-next-line: */}
                                          <div className="d-flex align-items-center gap-20 justify-content-center">
                                            <Rater total={5}>
                                              <CustomRatingComponent
                                                activeImg={star_active}
                                                inActiveImg={star}
                                                customClass={`mr-2 ${
                                                  reviewMethod === "star" ? "review-img-active" : "review-img-inactive"
                                                }`}
                                                imageWidth={35}
                                              />
                                            </Rater>
                                          </div>
                                        </CardWrapper>
                                      </Col>
                                    </Row>
                                    {/* Action */}
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() => this.deleteChoicesQuestionFn(pindex, qi, "rating")}
                                      id={`ratingQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) => this.requiredQuestinFunction(e, pindex, qi, "rating")}
                                      onDone={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "rating")
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "rating")
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * NPS
                          if (question.type === "scoreQues") {
                            return (
                              <Row className="mt-3 mb-3" key={`${qi}-nps`} id={`surveyQuestion${pindex}${qi}`}>
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Score question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) => this.questionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.questionError}</ErrorMessage>
                                        </div>
                                      </Col>
                                      {/* <Col sm="2">
                                      <div className="add-QuestionList">
                                        <Dropdown isOpen={choiceQuestinDropDown === qi ? ReAddQuestionOpen:false} toggle={()=>this.ReAddQuestionList(qi)} direction='down'>
                                          <DropdownToggle style={{width:'100%',backgroundColor:'#0077d2'}}>Net Promoter Score</DropdownToggle>
                                        </Dropdown>
                                      </div>
                                    </Col> */}
                                    </Row>
                                    <Row className="mb-3">
                                      <Col sm="12">
                                        <div className="choices-title-input">
                                          <FPLabel for="surveyPageTitle" isRequired={false}>
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) => this.shortFormQuestionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                        </div>
                                        <FPLabel className="mt-4 mb-0" isRequired={false}>
                                          Answer Options
                                        </FPLabel>
                                        <Divider className="mt-1 mb-0" />
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-3">
                                      <Col sm="6">
                                        <div className="choices-title-input">
                                          <FPLabel for="exampleEmail">Low rating label</FPLabel>
                                          <Input
                                            name="lowRatingInput"
                                            placeholder="Low rating"
                                            type="text"
                                            value={question.lowRatingInput}
                                            onChange={(e) => this.ratingScoreInputHandler(pindex, qi, "low", e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.lowRatingInputError}</ErrorMessage>
                                        </div>
                                      </Col>
                                      <Col sm="6">
                                        <div className="choices-title-input">
                                          <FPLabel for="exampleEmail">High rating label</FPLabel>
                                          <Input
                                            name="HighRatingInput"
                                            placeholder="High rating"
                                            type="text"
                                            value={question.HighRatingInput}
                                            onChange={(e) => this.ratingScoreInputHandler(pindex, qi, "high", e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.HighRatingInputError}</ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-3 nps-score">
                                      <Col sm="12">
                                        <CardWrapper contentClass="p-2" showDivider={false}>
                                          {/* eslint-disable-next-line: */}
                                          <div className="d-flex align-items-center gap-20 justify-content-center">
                                            {this.state.nps_array.map((_item, index) => {
                                              return (
                                                <div>
                                                  <div
                                                    style={{
                                                      display: "inline-block",
                                                      maxHeight: "35px",
                                                      maxWidth: "35px",
                                                    }}
                                                    className={`mr-2 ${
                                                      reviewMethod === "nps_score"
                                                        ? "review-img-active"
                                                        : "review-img-inactive"
                                                    }`}
                                                    key={index}
                                                    onMouseOver={() => this.handleNpsHover(index, true)}
                                                    onMouseLeave={() => this.handleNpsHover(index, false)}
                                                  >
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                      }}
                                                      className={`nps-method-circles ${
                                                        _item ? "nps-score-active" : "nps-score-inactive"
                                                      }`}
                                                    >
                                                      <div>{Number(index + 1)}</div>
                                                    </div>
                                                  </div>
                                                  {/* <div className="MuiCollapse-root MuiCollapse-hidden">
                                                      <Input
                                                        name="radio1"
                                                        type="radio"
                                                        id={`${index}-npsLabel`}
                                                        style={{
                                                          position: "unset",
                                                          marginTop: "unset",
                                                          marginLeft: "unset",
                                                        }}
                                                        onClick={() =>
                                                          this.setState({
                                                            activeIndex: index,
                                                          })
                                                        }
                                                      />
                                                    </div> */}
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </CardWrapper>
                                      </Col>
                                    </Row>
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() => this.deleteChoicesQuestionFn(pindex, qi, "score")}
                                      id={`scoreQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) => this.requiredQuestinFunction(e, pindex, qi, "score")}
                                      onDone={() => this.submitQuestionInputHandler(pindex, qi, question.edit, "score")}
                                      onEdit={() => this.submitQuestionInputHandler(pindex, qi, question.edit, "score")}
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }

                          // * Attachment
                          if (question.type === "attachmentQues") {
                            return (
                              <Row className="mt-3 mb-3" key={qi} id={`surveyQuestion${pindex}${qi}`}>
                                <Col sm="12">
                                  <div className="choices-question-div">
                                    <Row className="mt-3">
                                      <Col sm="12">
                                        <div className="question-title">
                                          <Input
                                            name="input"
                                            placeholder="Attachment question title"
                                            type="text"
                                            value={question.input}
                                            onChange={(e) => this.questionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                          <ErrorMessage>{question.questionError}</ErrorMessage>
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="mt-3 mb-2">
                                      <Col sm="12">
                                        <div className="choices-title-input pb-1">
                                          <FPLabel for="surveyPageTitle" isRequired={false}>
                                            Question {qi + 1}
                                          </FPLabel>
                                          <Input
                                            name="shortFormQuestion"
                                            placeholder="Short form question"
                                            type="text"
                                            value={question.shortFormQuestion}
                                            onChange={(e) => this.shortFormQuestionInputHandler(pindex, qi, e)}
                                            readOnly={question.edit}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <QuestionActions
                                      isEdit={question.edit}
                                      onDelete={() => this.deleteChoicesQuestionFn(pindex, qi, "attachment")}
                                      id={`attachmentQuesRequired${pindex}${qi}`}
                                      defaultRequired={question.required}
                                      onRequired={(e) => this.requiredQuestinFunction(e, pindex, qi, "attachment")}
                                      onDone={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "attachment")
                                      }
                                      onEdit={() =>
                                        this.submitQuestionInputHandler(pindex, qi, question.edit, "attachment")
                                      }
                                      errorMessage={question.doneError}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            );
                          }
                        })}
                      {/* Add */}
                      <Row className="">
                        <Col sm="12">
                          <div style={{ textAlign: "left" }}>
                            <p style={{ color: "red", fontSize: "12px" }}>{element.pageQuestionAddError}</p>
                          </div>
                        </Col>
                      </Row>

                      <div className="d-flex align-items-center justify-content-between">
                        <div
                          className="d-flex align-items-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.RemoveMoreSurveyPage(pindex)}
                        >
                          <img src={deleteIcon} role="button" />
                          <span
                            style={{
                              fontSize: "15px",
                              fontWeight: "400",
                              marginLeft: "7px",
                              color: "black",
                            }}
                          >
                            Delete Page
                          </span>
                        </div>
                        <div className="d-flex gap-10">
                          <FPMenuBox
                            title={"Add question"}
                            options={this.selectionList(pindex)}
                            size={"small"}
                            startIcon={<AddOutlinedIcon style={{ width: 16, height: 16 }} />}
                            hideDropDownArrowIcon={true}
                            className={"fp-select-as-small-btn fp-primary-bg fp-size-12"}
                          />
                          <FPButton
                            size="small"
                            label="Add Page"
                            variant="outline"
                            borderColor="#BFC2CB"
                            textColor="#1B1C1D"
                            onClick={() => this.addMorePageFunction()}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <ErrorModal
                    showModal={errorModalShow}
                    error={sendMessageError}
                    modalCloseCallBack={this.errorModalCallback.bind(this)}
                  />
                  <div className="d-flex justify-content-end gap-3" style={{ gap: "15px" }}>
                    <FPButton
                      size="small"
                      variant="outline"
                      onClick={() => {
                        this.toggleConfirm(() => this.props.deleteAllSurvey());
                      }}
                      label="Delete"
                      disabled={
                        loggedin_user.is_business_owner ? (this.props.resolutionDataId === null ? true : false) : true
                      }
                    />

                    <FPButton
                      label="Save"
                      size="small"
                      type="submit"
                      disabled={loggedin_user.is_business_owner ? AddPageBelowForm[0].allQuestions.length < 1 : true}
                    />
                  </div>
                </form>
              </div>
            </div>
            <ConfirmationModal
              header="Are you sure!"
              style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
              subHeader={`Are you sure you want to delete this survey?`}
              isOpen={isConfirmOpen}
              toggle={this.toggleConfirm}
              onConfirm={confirmationFunction}
            />
          </div>
        )}
      </CardWrapper>
    );
  }
}

TicketResolutions.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

TicketResolutions = reduxForm({
  // eslint-disable-line
  form: "TicketResolutions",
  enableReinitialize: false,
})(TicketResolutions);

export default TicketResolutions;
