import React from 'react';
import 'react-rater/lib/react-rater.css';
import mobile from '../../images/mobile.png';

function PhonePreview({ template, type }) {
  return (
    <div className="d-flex justify-content-center mobileTemp">
      <div className="mobileImg">
        <img src={mobile} width={'100%'} height={'100%'} />
        <p className="mobileTextContainer flex-wrap">
          {type === 'review' ? template.body : template.message}
          <a style={{ color: '#1a76d2' }}>
            https://tinyurl.com/{'{review_id}'}
          </a>
        </p>
      </div>
    </div>
  );
}

export default PhonePreview;