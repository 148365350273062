import React, { useState } from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
import InputComponent from "../common/InputComponent";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import cookie from "react-cookies";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { FPButton } from "../common/new";
import { Box } from "@material-ui/core";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { Form, Button } from "semantic-ui-react";
const validate = (values) => {
  const errors = {};
  if (values.zomato_res_id && !/^[0-9]+$/i.test(values.zomato_res_id)) {
    errors.zomato_res_id = "Please enter only numbers";
  }
  if (values.swiggy_res_id && !/^[0-9]+$/i.test(values.swiggy_res_id)) {
    errors.swiggy_res_id = "Please enter only numbers";
  }
  return errors;
};

let RestaurantIDSetup = (props) => {
  const [open, setOpen] = useState(false);
  const [error, seterr] = useState(null);
  const {
    handleSubmit,
    submitting,
    handleBusinessSetup,
    isEditable,
    toggleState,
  } = props;

  const submitID = (data) => {
    console.log(data);
    let {
      zomato_res_id,
      swiggy_res_id,
      magicpin_res_id,
      google_res_id,
      yelp_res_id,
      booking_res_id,
      agoda_res_id,
      deliveroo_res_id,
      foodpanda_res_id,
      grabfood_res_id,
      talabat_res_id,
      ctrip_res_id,
      grubhub_res_id,
      doordash_res_id,
    } = data;
    data.zomato_res_id = zomato_res_id == "" ? null : zomato_res_id;
    data.swiggy_res_id = swiggy_res_id == "" ? null : swiggy_res_id;
    data.magicpin_res_id = magicpin_res_id == "" ? null : magicpin_res_id;
    data.google_res_id = google_res_id == "" ? null : google_res_id;
    data.yelp_res_id = yelp_res_id == "" ? null : yelp_res_id;
    data.booking_res_id = booking_res_id == "" ? null : booking_res_id;
    data.agoda_res_id = agoda_res_id == "" ? null : agoda_res_id;
    data.deliveroo_res_id = deliveroo_res_id == "" ? null : deliveroo_res_id;
    data.foodpanda_res_id = foodpanda_res_id == "" ? null : foodpanda_res_id;
    data.talabat_res_id = talabat_res_id == "" ? null : talabat_res_id;
    data.grabfood_res_id = grabfood_res_id == "" ? null : grabfood_res_id;
    data.ctrip_res_id = ctrip_res_id == "" ? null : ctrip_res_id;
    data.grubhub_res_id = grubhub_res_id == "" ? null : grubhub_res_id;
    data.doordash_res_id = doordash_res_id == "" ? null : doordash_res_id;

    return handleBusinessSetup(data).catch((err) => {
      setOpen(true);
      seterr(err[Object.keys(err)[0]]);
      console.log(error);
      const errObj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errObj);
    });
  };

  // console.log(this.props.state.business.info);

  const service_type = cookie.load("service_category");
  return (
    <Row>
      <Col>
        <Form className="grid-form" onSubmit={handleSubmit(submitID)}>
          <CardWrapper
            headerTitle={
              <div className="d-flex justify-content-between">
                <Box>Restaurant ID Setup</Box>
                <div>
                  {/* {isEditable ? (
                    <FPButton
                      label="Save"
                      size="small"
                      type="submit"
                      disabled={submitting}
                    />
                  ) : (
                    <FPButton
                      size="small"
                      variant="outline"
                      onClick={() => toggleState("isIdSetup")}
                      label="Edit"
                    />
                  )} */}
                  {isEditable ? (
                    <Form.Field
                      control={Button}
                      type="submit"
                      disabled={submitting}
                      style={{
                        borderRadius: "80px",
                        backgroundColor: "#F13A88",
                        color: "white",
                      }}
                    >
                      Save
                    </Form.Field>
                  ) : (
                    <Button
                      basic
                      onClick={() => toggleState("isIdSetup")}
                      className="edit-btn"
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </div>
            }
          >
            <div className="row mb-2">
              <div className="col-sm-6">
                <>
                  <FPLabel isRequired={false}>Zomato Restaurant ID</FPLabel>
                  <Field
                    name="zomato_res_id"
                    component={InputComponent}
                    type="text"
                    placeholder="Enter Zomato Restaurant Id"
                    disabled={!isEditable}
                    minHeight="0px"
                  />
                </>
              </div>
              <div className="col-sm-6">
                <>
                  <FPLabel isRequired={false}>Swiggy Restaurant ID</FPLabel>
                  <Field
                    name="swiggy_res_id"
                    component={InputComponent}
                    type="text"
                    placeholder="Enter Swiggy Restaurant Id"
                    disabled={!isEditable}
                    minHeight="0px"
                  />
                </>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-6">
                {!(service_type === "Hotel") && (
                  <>
                    <FPLabel isRequired={false}>Magicpin ID</FPLabel>
                    <Field
                      name="magicpin_res_id"
                      component={InputComponent}
                      type="text"
                      placeholder="Enter Magicpin Id"
                      disabled={!isEditable}
                      minHeight="0px"
                    />
                  </>
                )}
              </div>
              <div className="col-sm-6">
                {!(service_type === "Hotel") && (
                  <>
                    <FPLabel isRequired={false}>Yelp ID</FPLabel>
                    <Field
                      name="yelp_res_id"
                      component={InputComponent}
                      type="text"
                      placeholder="Enter Yelp Hotel Id"
                      disabled={!isEditable}
                      minHeight="0px"
                    />
                  </>
                )}
              </div>
            </div>
            {!(service_type === "Restaurant") && (
              <div className="row mb-2">
                <div className="col-sm-6">
                  <>
                    <FPLabel isRequired={false}>Booking ID</FPLabel>
                    <Field
                      name="booking_res_id"
                      component={InputComponent}
                      type="text"
                      placeholder="Enter Booking Hotel Id"
                      disabled={!isEditable}
                      minHeight="0px"
                    />
                  </>
                </div>
                <div className="col-sm-6">
                  <>
                    <FPLabel isRequired={false}>Agoda ID</FPLabel>
                    <Field
                      name="agoda_res_id"
                      component={InputComponent}
                      type="text"
                      placeholder="Enter Booking Hotel Id"
                      disabled={!isEditable}
                      minHeight="0px"
                    />
                  </>
                </div>
              </div>
            )}
            {service_type === "Restaurant" && (
              <div className="row mb-2">
                <div className="col-sm-6">
                  <>
                    <FPLabel isRequired={false}>Deliveroo ID</FPLabel>
                    <Field
                      name="deliveroo_res_id"
                      component={InputComponent}
                      type="text"
                      placeholder="Enter Deliveroo Id"
                      disabled={!isEditable}
                      minHeight="0px"
                    />
                  </>
                </div>
                <div className="col-sm-6">
                  <>
                    <FPLabel isRequired={false}>FoodPanda ID</FPLabel>
                    <Field
                      name="foodpanda_res_id"
                      component={InputComponent}
                      type="text"
                      placeholder="Enter Food Panda Id"
                      disabled={!isEditable}
                      minHeight="0px"
                    />
                  </>
                </div>
              </div>
            )}
            <Row className="mb-2">
              {service_type === "Restaurant" && (
                <Col sm="6">
                  <FPLabel isRequired={false}>Grab Food ID</FPLabel>
                  <Field
                    name="grabfood_res_id"
                    component={InputComponent}
                    type="text"
                    placeholder="Enter Grab food Id"
                    disabled={!isEditable}
                    minHeight="0px"
                  />
                </Col>
              )}
              <Col sm="6">
                <FPLabel isRequired={false}>Google ID</FPLabel>
                <Field
                  name="google_res_id"
                  component={InputComponent}
                  type="text"
                  placeholder="Enter Google Id"
                  disabled={!isEditable}
                  minHeight="0px"
                />
              </Col>
              {service_type === "Restaurant" && (
                <Col sm="6" className="mt-2">
                  <FPLabel isRequired={false}>Talabat Restaurant ID</FPLabel>
                  <Field
                    name="talabat_res_id"
                    component={InputComponent}
                    type="text"
                    placeholder="Enter Talabat Restaurant ID"
                    disabled={!isEditable}
                    minHeight="0px"
                  />
                </Col>
              )}

              {service_type === "Hotel" && (
                <Col sm="6" className="mt-2">
                  <FPLabel isRequired={false}>Ctrip Hotel ID</FPLabel>
                  <Field
                    name="ctrip_res_id"
                    component={InputComponent}
                    type="text"
                    placeholder="Enter Ctrip Hotel ID"
                    disabled={!isEditable}
                    minHeight="0px"
                  />
                </Col>
              )}
              {service_type === "Restaurant" && (
                <Col sm="6" className="mt-2">
                  <FPLabel isRequired={false}>Grubhub Restaurant ID</FPLabel>
                  <Field
                    name="grubhub_res_id"
                    component={InputComponent}
                    type="text"
                    placeholder="Enter Grubhub Restaurant ID"
                    disabled={!isEditable}
                    minHeight="0px"
                  />
                </Col>
              )}
              {service_type === "Restaurant" && (
                <Col sm="6" className="mt-2">
                  <FPLabel isRequired={false}>Doordash Restaurant ID</FPLabel>
                  <Field
                    name="doordash_res_id"
                    component={InputComponent}
                    type="text"
                    placeholder="Enter Doordash Restaurant ID"
                    disabled={!isEditable}
                    minHeight="0px"
                  />
                </Col>
              )}
            </Row>
          </CardWrapper>
        </Form>
      </Col>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        center
        closeOnOverlayClick={true}
        classNames={{
          modal: "customModal",
        }}
      >
        <h1
          style={{
            color: "red",
            margin: "15px 0",
          }}
        >
          Error!
        </h1>
        <h5
          style={{
            padding: "20px",
          }}
        >
          {error}
        </h5>
      </Modal>
    </Row>
  );
};

RestaurantIDSetup = reduxForm({
  form: "RestaurantIDSetup",
  validate,
  enableReinitialize: true,
})(RestaurantIDSetup);

RestaurantIDSetup = connect((state) => ({
  initialValues: {
    zomato_res_id: state.business.info && state.business.info.zomato_res_id,
    swiggy_res_id: state.business.info && state.business.info.swiggy_res_id,
    magicpin_res_id: state.business.info && state.business.info.magicpin_res_id,
    google_res_id: state.business.info && state.business.info.google_res_id,
    yelp_res_id: state.business.info && state.business.info.yelp_res_id,
    booking_res_id: state.business.info && state.business.info.booking_res_id,
    agoda_res_id: state.business.info && state.business.info.agoda_res_id,
    deliveroo_res_id:
      state.business.info && state.business.info.deliveroo_res_id,
    foodpanda_res_id:
      state.business.info && state.business.info.foodpanda_res_id,
    talabat_res_id: state.business.info && state.business.info.talabat_res_id,
    grabfood_res_id: state.business.info && state.business.info.grabfood_res_id,
    ctrip_res_id: state.business.info && state.business.info.ctrip_res_id,
    grubhub_res_id: state.business.info && state.business.info.grubhub_res_id,
    doordash_res_id: state.business.info && state.business.info.doordash_res_id,
  },
}))(RestaurantIDSetup);

export default RestaurantIDSetup;
