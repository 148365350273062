import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";

export function getSalesTrendOvertimeSuccess(data) {
  return { type: types.GET_SALES_TREND_OVERTIME_SUCCESS, data };
}

export function getSalesTrendOvertimeFailure(error) {
  let data = {};
  data.errors = error;
  return { type: types.GET_SALES_TREND_OVERTIME_FAILURE, data };
}

export const getSalesTrendOvertime = (data) => {
  const { ...params } = data;
  return async (dispatch) => {
    return axios_instance.get(`${utils.format(URLS.SALES_TREND_OVERTIME, [data.business_id])}`, {params})
      .then((res) => {
        dispatch(getSalesTrendOvertimeSuccess(res.data));
      }).catch((err) => {
        dispatch(getSalesTrendOvertimeFailure(err));
      });
  }
}

export function getSalesTrendLocationSuccess(data) {
  return { type: types.GET_SALES_TREND_LOCATION_SUCCESS, data };
}

export function getSalesTrendLocationFailure(error) {
  let data = {};
  data.errors = error;
  return { type: types.GET_SALES_TREND_LOCATION_FAILURE, data };
}

export const getSalesTrendLocation = (data) => {
  const { ...params } = data;
  return async (dispatch) => {
    return axios_instance.get(`${utils.format(URLS.SALES_TREND_LOCATION, [data.business_id])}`, {params})
      .then((res) => {
        dispatch(getSalesTrendLocationSuccess(res.data));
      }).catch((err) => {
        dispatch(getSalesTrendLocationFailure(err));
      });
  }
}

export function getOrdersTrendOvertimeSuccess(data) {
  return { type: types.GET_ORDERS_TREND_OVERTIME_SUCCESS, data };
}

export function getOrdersTrendOvertimeFailure(error) {
  let data = {};
  data.errors = error;
  return { type: types.GET_ORDERS_TREND_OVERTIME_FAILURE, data };
}

export const getOrdersTrendOvertime = (data) => {
  const { ...params } = data;
  return async (dispatch) => {
    return axios_instance.get(`${utils.format(URLS.ORDERS_TREND_OVERTIME, [data.business_id])}`, {params})
      .then((res) => {
        dispatch(getOrdersTrendOvertimeSuccess(res.data));
      }).catch((err) => {
        dispatch(getOrdersTrendOvertimeFailure(err));
      });
  }
}

export function getOrdersTrendLocationSuccess(data) {
  return { type: types.GET_ORDERS_TREND_LOCATION_SUCCESS, data };
}

export function getOrdersTrendLocationFailure(error) {
  let data = {};
  data.errors = error;
  return { type: types.GET_ORDERS_TREND_LOCATION_FAILURE, data };
}

export const getOrdersTrendLocation = (data) => {
  const { ...params } = data;
  return async (dispatch) => {
    return axios_instance.get(`${utils.format(URLS.ORDERS_TREND_LOCATION, [data.business_id])}`, {params})
      .then((res) => {
        dispatch(getOrdersTrendLocationSuccess(res.data));
      }).catch((err) => {
        dispatch(getOrdersTrendLocationFailure(err));
      });
  }
}
