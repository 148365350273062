import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { FPToolTip } from "../common/new";
import {
  insightsLocationBgColors,
  insightsLocationColors,
} from "../../libs/constant";

const LocationsList = (props) => {
  const { locationGrading } = props;

  const locationHeading =
    locationGrading.results &&
    Object.values(locationGrading.results)[0] &&
    Object.keys(Object.values(locationGrading.results)[0].grading);

  return (
    <div className="ui-table-responsive">
      {locationGrading && locationGrading ? (
        <Table className="fp-table fp-table-center">
          <TableHead>
            <TableRow>
              <TableCell
                className="width-md-th tableHeaderHover"
                style={{ textAlign: "center" }}
              >
                Locations
              </TableCell>
              {locationHeading &&
                locationHeading.map((heading, i) => {
                  const newHeadng = heading[0]?.toUpperCase() + heading.slice(1);
                  return (
                    <TableCell className="tableHeaderHover">
                      {newHeadng}
                    </TableCell>
                  );
                })}
            </TableRow>
          </TableHead>
          <TableBody>
            {locationGrading &&
              Object.keys(locationGrading.results).map((item, index) => {
                return (
                  <TableRow>
                    <TableCell>
                      <FPToolTip
                        title={item}
                      >
                        <div
                          style={{
                            width: "150px",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item}
                        </div>
                      </FPToolTip>
                    </TableCell>
                    {locationGrading &&
                      Object.values(locationGrading.results[item].grading).map(
                        (grading, index) => {
                          return (
                            <TableCell>
                              <div
                                style={{
                                  backgroundColor: `${insightsLocationBgColors[grading]}`,
                                  border:
                                    grading === "-"
                                      ? "1px solid transparent"
                                      : `1px solid ${insightsLocationColors[grading]}`,
                                }}
                                className="grading-view"
                              >
                                <p
                                  style={{
                                    color: `${insightsLocationColors[grading]}`,
                                  }}
                                >
                                  {grading}
                                </p>
                              </div>
                            </TableCell>
                          );
                        }
                      )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      ) : (
        "Loading"
      )}
    </div>
  );
};

export default LocationsList;
