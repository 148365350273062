import React from 'react';
import cookie from 'react-cookies';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import * as recurringCampaignActions from '../../actions/campaign/recurringCampaignActions';
import RecurringCampaignList from '../../components/campaign/RecurringCampaignList';
import LoaderComponent from '../../components/common/loaderComponent';
import * as utils from '../../libs/utils';

class RecurringCampaignPage extends React.Component {
  constructor(props) {
    super(props);
    this.goToAddCampaign = this.goToAddCampaign.bind(this);
    this.fetchTabData = this.fetchTabData.bind(this);
    const pageSize = cookie.load('pageSize') || {};
    const { history, location: { pathname,search }} = props;
    history.replace({ pathname, search: queryString.stringify({ ...queryString.parse(search), page_size: pageSize.campaigns_review || 10 }) });
  }

  componentDidMount() {
    this.fetchTabData();
    document.title = 'Recurring Campaign|Review Request';
  }

  componentDidUpdate(prevProps){
    if(this.props.location.search!==prevProps.location.search){
      this.fetchTabData();
    }
  }

  fetchTabData() {
    const { match: { params: { business_id }}, location: { search } } = this.props;
    this.props.actions.getAllRecurringCampaign(queryString.parse(search), business_id);
  }

  goToAddCampaign() {
    const { history, match: { params: { business_id }} } = this.props;
    history.push(`/${business_id}/recurring-campaigns/review/add`);
  }

  render() {
    const {match: { params: { business_id }}, recurringCampaign: { recurringCampaignList }, business_userq} = this.props;
    const ordering = queryString.parse(this.props.location.search).ordering;
    const user = business_userq || cookie.load('business_user');
    return (
      <div>
        <Row className="content-area">
          <LoaderComponent loader={this.props.loader}/>
          <Col xs={12} sm={12} className="p-3 business-setup">
            <RecurringCampaignList
              list={recurringCampaignList}
              location={this.props.location}
              goToAddCampaign={this.goToAddCampaign}
              ordering={ordering}
              sortFunction={(on) => utils.toggleSort(this.props.history,this.props.location,on)}
              user={user}
              pageSizeCookie='campaigns_review'
              business_id={business_id}
              history={this.props.history}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    recurringCampaign : state.recurringCampaign,
    loader: state.common.loader,
    userq: state.profile.user,
    business_userq: state.profile.business_user
  };
};


RecurringCampaignPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  recurringCampaign:PropTypes.instanceOf(Object),
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(recurringCampaignActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(RecurringCampaignPage);
