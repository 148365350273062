import React, { useState } from "react";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";
import FPInput from "../../../../../components/common/new/forms/ui/FPInput";
import google_svg from "../../../../../images/googlephoto.svg";
import GoogleIcon from "@mui/icons-material/Google";
import warning_svg from "../../../../../images/warning.svg";
import BusinessList from "../BusinessList";

const UpdatePhoneNuimber = (props) => {
  const { business_id, busines_status } = props;
  const [selectedBusinessList, setSelectedBusinessList] = useState([]);
  const [showBusiness, setShowBusiness] = useState(true);
  const handleHideBusinessScreen = () => {
    setShowBusiness(false);
  };

  const handleShowBusinessScreen = () => {
    setShowBusiness(true);
  };

  const [selectedBusinessCount, setSelectedBusinessCount] = useState([]); // State to hold the selected businesses count
  console.log("==================================", selectedBusinessCount);

  const handleSelectedBusinessCount = (selectBusiness) => {
    setSelectedBusinessCount(selectBusiness);
  };

  return (
    <>
      {showBusiness ? (
        <div>
          <BusinessList
            type="phone_number"
            busines_status={busines_status}
            business_id={business_id}
            primaryDetail={null}
            selectedBusinessList={selectedBusinessList}
            onSelectedBusinessCountChange={handleSelectedBusinessCount}
          />
          {selectedBusinessCount?.length > 0 ? (
            <div
              className="d-flex mt-10 mb-15"
              style={{
                display: "flex",
                justifyContent: "end",
                fontWeight: "600",
                fontSize: "24px",
                color: "#1A2544",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "20px",
              }}
            >
              <FPButton label="Next" onClick={handleHideBusinessScreen} />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-9">
            <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
              {/* <div className="listing-name">
                Add Primary & Additional Business Phone Numbers
              </div> */}
              <div className="d-flex flex-column" style={{ gap: "4px" }}>
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#1B1C1D",
                  }}
                >
                  Primary Phone Number
                </div>
                <FPInput type="text" value={67895678956} />
              </div>

              <div style={{ display: "grid", gap: "10px" }}>
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#1B1C1D",
                  }}
                >
                  Additional Phone Number
                </div>
                <FPInput type="text" placeholder={"Enter Additional  Number (optional)"} value={4564355464} />
              </div>

              <div
                style={{
                  backgroundColor: "#FEE4E2",
                  borderRadius: "12px",
                  padding: "8px 16px",
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <img
                  src={warning_svg}
                  alt="warning-svg"
                  style={{
                    height: "16px",
                    width: "16px",
                  }}
                />
                <p
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    color: "#F04438",
                  }}
                >
                  Phone Number & Additional Number change might lead to suspension or re-verification Business profile.
                  If you are sure, please add the phone number directly from Google Business Profile
                </p>
              </div>
              <div className="d-flex justify-content-end">
                <a href="#" target="_blank" rel="noreferrer">
                  <FPButton label="Edit on Google" size="small" startIcon={<GoogleIcon />} />
                </a>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  // paddingBottom: "10px",
                  // paddingTop: "10px",
                  paddingBlock: "20px",
                }}
              >
                <div>
                  <FPButton label="Back" size="small" onClick={handleShowBusinessScreen} />
                </div>
                {/* <div><FPButton
                  // onClick={handleSaveClick}
                  label="Update"
                  size="small"
                /></div> */}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <img src={google_svg} alt="verifiedImage" width={"100%"} />
          </div>
        </div>
      )}
    </>
  );
};

export default UpdatePhoneNuimber;
