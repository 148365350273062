import debounce from 'lodash.debounce';
import {useEffect, useMemo, useRef} from 'react';

const useDebounce = (callback, debounceTime = 1000) => {
  const ref  = useRef();

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  const debouncedCallback = useMemo(() => {
    const func = () => {
      ref.current && ref.current();
    };

    return debounce(func, debounceTime);
  }, []);

  return debouncedCallback;
};

export default useDebounce;
