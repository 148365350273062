import React from "react";
import { Button, Form, Header, Segment, Icon } from "semantic-ui-react";
import CustomRatingComponent from "../common/customRating";
import { Row, Col, Label, Progress } from "reactstrap";
import Rater from "react-rater";
import starActive from "../../images/reviewMethods/star_Color.png";
import starInActive from "../../images/reviewMethods/star_B_W.png";
import SemiCircleProgressBar from "react-progressbar-semicircle";
import LoaderComponent from "../common/loaderComponent";
import FPSelect from "../common/new/forms/ui/FPSelect";
import ChatFileShow from "../messenger/Common/ChatFileShow";
import { Box } from "@material-ui/core";
import imgDrag from "../../images/svg/fileSvg.svg";
import checkIcon from "../../images/svg/_Checkbox base.svg";
import AutoSelect from "../common/AutoSelect";

class TicketResolutionsSurveyForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageform: this.props.ticketResolutionResponse
        ? this.props.ticketResolutionResponse
        : { pageIndex: props.survey_current_page, SurveyQuestions: [] },
      paragraphInput: "",
      shortAnswerInput: "",
      dropDownSelect: "",
      checkBoxArray: [],
      starRating: 0,
      npsScore: 0,
      pageNumber: 0,
      attachedFiles: null,
      setAllFiles: [],
      showUserFileModal: false,
      selectAttchFile: null,
    };

    this.inputChoiceHandle = this.inputChoiceHandle.bind(this);
    this.surveyParagraphInputQuestion = this.surveyParagraphInputQuestion.bind(this);
    this.surveyShortAnswerInputQuestion = this.surveyShortAnswerInputQuestion.bind(this);
    this.surveyDropDownQuestion = this.surveyDropDownQuestion.bind(this);
    this.surveyCheckBoxHandle = this.surveyCheckBoxHandle.bind(this);
    this.setStarRating = this.setStarRating.bind(this);
    this.setNpsScore = this.setNpsScore.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.setAttachedFiles = this.setAttachedFiles.bind(this);
  }

  componentDidMount() {
    if (this.props.ticketResolutionResponse) {
      const nps_que = this.props.ticketResolutionResponse.SurveyQuestions.find(
        (question) => question.type === "NPS QUESTION"
      );
      if (nps_que && nps_que.Answer[0]) {
        const new_key = this.props.ticketResolutionResponse.pageIndex + "_" + nps_que.index;
        if (this.state.npsScore == 0) {
          this.setState({ npsScore: { [new_key]: nps_que.Answer[0].option } });
        } else {
          const new_rating = this.state.npsScore;
          new_rating[new_key] = nps_que.Answer[0].option;
          this.setState({ npsScore: new_rating });
        }
      }

      const ms_ques = this.props.ticketResolutionResponse.SurveyQuestions.find(
        (question) => question.type === "Checkbox"
      );
      if (ms_ques && ms_ques.Answer) {
        let checkBoxArray = this.state.checkBoxArray;
        ms_ques.Answer.map((ms_que) => {
          const checkb = checkBoxArray.findIndex((question) => question == ms_que);
          if (checkb <= -1) {
            checkBoxArray.push(ms_que);
          }
        });
      }
    }
    if (this.props.attached_files_urls) {
      this.setState({ attachedFiles: this.props.attached_files_urls });
    }
  }

  closeUserFileModal = () => {
    this.setState({ showUserFileModal: false });
  };
  showUserFileModalFn = (attached_file_data) => {
    var attached_file = attached_file_data.file;
    let preview_data = {};
    let msg_type = "image";
    if (
      attached_file.includes(".pdf") ||
      attached_file.includes(".docx") ||
      attached_file.includes(".doc") ||
      attached_file.includes(".xlsx") ||
      attached_file.includes(".xls") ||
      attached_file.includes(".txt") ||
      attached_file.includes(".csv")
    ) {
      msg_type = "file";
    }
    if (attached_file.includes(".mp4") || attached_file.includes(".webm")) {
      msg_type = "video";
    }
    preview_data.msg_type = msg_type;
    preview_data.attached_file = attached_file;
    preview_data.file_attached = attached_file;
    this.setState({ selectAttchFile: preview_data, showUserFileModal: true });
  };

  setAttachedFiles(event, que_index, question, current_page_index) {
    let file_name_list = [];
    let all_files = event.target.files;

    // Process each file
    Object.keys(all_files).map((file_item) => {
      file_name_list.push(all_files[file_item].name);
      let existing_files = this.state.setAllFiles;
      existing_files.push(all_files[file_item]);
      this.setState({ setAllFiles: existing_files });
    });
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == que_index);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: file_name_list }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: file_name_list }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      });
    }

    const new_key = current_page_index + "_" + que_index;
    // add file
    // Commented code is old
    // if (this.state.attachedFiles === null){
    //   this.setState({attachedFiles:{[new_key]:event.target.files}});
    // }else{
    //   const new_attach = this.state.attachedFiles;
    //   if (new_key in new_attach){
    //     var existing_length = new_attach[new_key].length;
    //     var existing_attached_state = new_attach[new_key];
    //     for (var i = 0; i < event.target.files.length ; i++){
    //       existing_attached_state[existing_length + i ] = event.target.files[i];
    //     }
    //     existing_attached_state['length'] = existing_length + event.target.files.length;
    //     new_attach[new_key] = existing_attached_state;
    //   }else{
    //     new_attach[new_key] = event.target.files;
    //   }
    //   this.setState({attachedFiles:new_attach});
    // }
    if (this.state.attachedFiles === null) {
      // Clear previous errMsg
      this.setState({
        errorMessage: {
          ...this.state.errorMessage,
          [new_key]: null,
        },
      });

      this.setState({ attachedFiles: { [new_key]: event.target.files } });
    } else {
      const new_attach = { ...this.state.attachedFiles };
      // Convert FileList to an array
      const newFiles = Array.from(event.target.files);
      // Ensure there's only one file and replace the existing file
      if (newFiles.length > 0) {
        new_attach[new_key] = [newFiles[0]]; // Replace with the new file
      }
      this.setState({ attachedFiles: new_attach });
    }
    this.setState({ choiceValues });

    // image size validation
    for (let i = 0; i < all_files.length; i++) {
      const file = all_files[i];

      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.src = e.target.result;

          img.onload = () => {
            const width = img.width;
            const height = img.height;

            if (width > 800 || height > 400) {
              if (this.state.errorMessage !== null) {
                // Show errMsg & not add Image
                const newErrorMessage = {
                  ...this.state.errorMessage,
                  [new_key]: "Please upload an image with a maximum size of 800x400px",
                };
                this.setState({ errorMessage: newErrorMessage });

                // Clear the attached file
                this.setState({ attachedFiles: null });
              }
            } else {
              if (this.state.attachedFiles === null) {
                // Clear previous errMsg
                this.setState({
                  errorMessage: {
                    ...this.state.errorMessage,
                    [new_key]: null,
                  },
                });

                this.setState({
                  attachedFiles: { [new_key]: event.target.files },
                });
              } else {
                const new_attach = { ...this.state.attachedFiles };
                // Convert FileList to an array
                const newFiles = Array.from(event.target.files);
                // Ensure there's only one file and replace the existing file
                if (newFiles.length > 0) {
                  new_attach[new_key] = [newFiles[0]]; // Replace with the new file
                }
                this.setState({ attachedFiles: new_attach });
              }
              this.setState({ choiceValues });
            }
          };
        };
        reader.readAsDataURL(file);
      }
    }
  }

  setNpsScore(que_index, question, val, current_page_index) {
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == que_index);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: val }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: val }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      });
    }
    const new_key = current_page_index + "_" + que_index;
    if (this.state.npsScore == 0) {
      this.setState({ npsScore: { [new_key]: val } });
    } else {
      const new_rating = this.state.npsScore;
      new_rating[new_key] = val;
      this.setState({ npsScore: new_rating });
    }
    // this.setState({ npsScore: val });
    this.setState({ choiceValues });
  }

  setStarRating(que_index, question, val, current_page_index) {
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == que_index);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: val }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: val }],
        type: question.question_type,
        index: que_index,
        que_req: question.question_required,
      });
    }
    const new_key = current_page_index + "_" + que_index;
    if (this.state.starRating == 0) {
      this.setState({ starRating: { [new_key]: val } });
    } else {
      const new_rating = this.state.starRating;
      new_rating[new_key] = val;
      this.setState({ starRating: new_rating });
    }
    this.setState({ choiceValues });
  }

  nextSurveyPage = () => {
    this.props.getSurveyResponsefn(this.state.pageform);
  };

  submitSurveyPage = () => {
    var new_data = {
      data: this.state.pageform,
      ticket_id: this.props.single_ticket_id,
    };
    if (this.props.ticketResolutionResponse) {
      new_data["resolution_data"] = this.props.ticketResolutionResponse;
    }
    if (this.state.attachedFiles) {
      new_data["attached_files"] = this.state.setAllFiles;
    }
    this.props.finalSubmitSurveyQuestin(new_data);
  };
  handleValueSelect = (text) => {
    this.setState({ dropDownSelect: text });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.pageform.pageIndex !== nextProps.survey_current_page) {
      this.setState({
        dropDownSelect: "",
        checkBoxArray: [],
        shortAnswerInput: "",
        pageform: {
          pageIndex: nextProps.survey_current_page,
          SurveyQuestions: [],
        },
        paragraphInput: "",
      });
    }
    if (this.props.ticketResolutionResponse != nextProps.ticketResolutionResponse) {
      const nps_que = nextProps.ticketResolutionResponse.SurveyQuestions.find(
        (question) => question.type === "NPS QUESTION"
      );
      if (nps_que && nps_que.Answer[0]) {
        const new_key = nextProps.ticketResolutionResponse.pageIndex + "_" + nps_que.index;
        if (this.state.npsScore == 0) {
          this.setState({ npsScore: { [new_key]: nps_que.Answer[0].option } });
        } else {
          const new_rating = this.state.npsScore;
          new_rating[new_key] = nps_que.Answer[0].option;
          this.setState({ npsScore: new_rating });
        }
      }

      const ms_ques = nextProps.ticketResolutionResponse.SurveyQuestions.find(
        (question) => question.type === "Checkbox"
      );
      if (ms_ques && ms_ques.Answer) {
        let checkBoxArray = this.state.checkBoxArray;
        ms_ques.Answer.map((ms_que) => {
          const checkb = checkBoxArray.findIndex((question) => question == ms_que);
          if (checkb <= -1) {
            checkBoxArray.push(ms_que);
          }
        });
        this.setState({ checkBoxArray: checkBoxArray });
      }
      if (this.props.attached_files_urls) {
        this.setState({ attachedFiles: this.props.attached_files_urls });
      }
      this.setState({
        pageform: nextProps.ticketResolutionResponse
          ? nextProps.ticketResolutionResponse
          : { pageIndex: nextProps.survey_current_page, SurveyQuestions: [] },
      });
    }
  }

  inputChoiceHandle(event, qi, question) {
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == qi);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      });
    }
    this.setState({ choiceValues });
  }

  surveyParagraphInputQuestion(event, qi, question) {
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == qi);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      });
    }
    this.setState({ choiceValues });
  }

  surveyShortAnswerInputQuestion(event, qi, question) {
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == qi);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      });
    }
    this.setState({ choiceValues });
  }

  surveyDropDownQuestion(event, qi, question, current_page_index) {
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == qi);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: [{ option: event.target.value }],
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      });
    }
    const new_key = current_page_index + "_" + qi;
    if (this.state.dropDownSelect == "") {
      this.setState({ dropDownSelect: { [new_key]: event.target.value } });
    } else {
      const new_dropdown = this.state.dropDownSelect;
      new_dropdown[new_key] = event.target.value;
      this.setState({ dropDownSelect: new_dropdown });
    }
    this.setState({ choiceValues });
  }

  surveyCheckBoxHandle(event, qi, question) {
    let checkBoxArray = this.state.checkBoxArray;
    if (event.target.checked) {
      checkBoxArray.push(event.target.value);
    } else {
      const checkb = checkBoxArray.findIndex((question) => question == event.target.value);
      if (checkb > -1) {
        checkBoxArray.splice(checkb, 1);
      }
    }
    let choiceValues = this.state.pageform.SurveyQuestions;
    const result = choiceValues.findIndex((question) => question.index == qi);
    if (result > -1) {
      choiceValues[result] = {
        Question: question.input,
        Answer: checkBoxArray,
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      };
    } else {
      choiceValues.push({
        Question: question.input,
        Answer: checkBoxArray,
        type: question.question_type,
        index: qi,
        que_req: question.question_required,
      });
    }
    this.setState({ choiceValues });
  }
  handlePageClick(selectedPage) {
    this.props.handleSurveyPageChange(selectedPage.selected);
  }

  render() {
    const {
      survey_pages,
      survey_current_page,
      surveySubmitError,
      totalSurveyPageCount,
      nextPageCount,
      ticketResolutionResponse,
      isUserNotAssignee,
      isTicketClosed,
    } = this.props;
    const { attachedFiles } = this.state;
    return (
      <div>
        <LoaderComponent loader={this.props.surveyFormLoading} />
          <div style={{ width: "415px" }}>
            <div style={{ maxWidth: 410 }} className="p-0">
              <Form size="large">
                <Box
                  sx={{
                    height: "500px",
                    overflowX: "hidden",
                    overflowY: "auto",
                    margin: "10px 5px",
                  }}
                >
                  <Header
                    as="h5"
                    textAlign="center"
                    style={{
                      marginBottom: "20px",
                      marginTop: "10px",
                      color: "#646565",
                    }}
                  >
                    Ticket Resolution Survey{" "}
                  </Header>
                  <h6 style={{ backgroundColor: "#FAFAFB", padding: "5px 10px" }}>
                    Page {survey_current_page + 1} of {totalSurveyPageCount}{" "}
                    {survey_pages && survey_pages.title != "" && `- ${survey_pages.title}`}
                  </h6>
                  {survey_pages &&
                    survey_pages.allQuestions.map((question, que_index) => {
                      const dropdownValue =
                        question &&
                        question.options &&
                        question.options.map((i) => ({
                          display_name: i.title,
                          value: i.title,
                        }));
                      return (
                        question && (
                          <Form.Field
                            required={question.question_required}
                            key={`Question${survey_current_page}${que_index}`}
                            style={{ padding: "0 10px" }}
                          >
                            <Row className="mt-3 mb-3">
                              <Col sm="12">
                                <p style={{ color: "#7A8193", fontSize: "1rem" }}>
                                  {que_index + 1}. {question.question_short_form}
                                </p>
                              </Col>
                            </Row>
                            <Row className="mt-3 mb-3">
                              {question.question_type === "Multiple Choice" &&
                                question?.options?.map((option, option_index) => {
                                  return (
                                    <Row
                                      key={option_index}
                                      style={{
                                        width: "90%",
                                        padding: "0 40px",
                                      }}
                                    >
                                      <Col sm="12" className="d-flex align-items-center mb-10">
                                        <input
                                          id={`mccQuestion${survey_current_page}${que_index}${option_index}`}
                                          name={question.input}
                                          type="radio"
                                          value={option.title}
                                          onChange={(e) => this.inputChoiceHandle(e, que_index, question)}
                                          checked={
                                            ticketResolutionResponse &&
                                            ticketResolutionResponse.SurveyQuestions[que_index]?.Answer[0] &&
                                            ticketResolutionResponse.SurveyQuestions[que_index]?.Answer[0].option ===
                                              option.title
                                          }
                                          disabled={isTicketClosed === false ? isUserNotAssignee : true}
                                        />
                                        <Label
                                          for={`mccQuestion${survey_current_page}${que_index}${option_index}`}
                                          style={{
                                            color: "#7A8193",
                                            fontSize: "1rem",
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          {option.title}
                                        </Label>
                                      </Col>
                                    </Row>
                                  );
                                })}
                              {question.question_type === "Checkbox" &&
                                question.options.map((option, option_index) => {
                                  return (
                                    <Row
                                      key={option_index}
                                      style={{
                                        width: "90%",
                                        padding: "0 40px",
                                      }}
                                    >
                                      <Col sm="12" className="d-flex align-items-center mb-10">
                                        <input
                                          id={`checkBoxx${survey_current_page}${que_index}${option_index}`}
                                          name={question.input}
                                          type="checkbox"
                                          value={option.title}
                                          onChange={(e) => this.surveyCheckBoxHandle(e, que_index, question)}
                                          checked={
                                            ticketResolutionResponse &&
                                            ticketResolutionResponse.SurveyQuestions[que_index].Answer.indexOf(
                                              option.title
                                            ) > -1
                                          }
                                          disabled={isTicketClosed === false ? isUserNotAssignee : true}
                                        />
                                        <Label
                                          for={`checkBoxx${survey_current_page}${que_index}${option_index}`}
                                          style={{
                                            color: "#7A8193",
                                            fontSize: "1rem",
                                            marginLeft: "10px",
                                            cursor: "pointer",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          {option.title}
                                        </Label>
                                      </Col>
                                    </Row>
                                  );
                                })}
                              {question.question_type === "Drop-down" && (
                                <div style={{ width: "90%", marginLeft: "22px" }}>
                                  <AutoSelect
                                    placeholder="Select item"
                                    name="select"
                                    value={
                                      ticketResolutionResponse &&
                                      ticketResolutionResponse.SurveyQuestions[que_index].Answer[0]
                                        ? ticketResolutionResponse.SurveyQuestions[que_index].Answer[0].option
                                        : this.state.dropDownSelect == ""
                                        ? this.state.dropDownSelect
                                        : this.state.dropDownSelect[`${survey_current_page}_${que_index}`]
                                    }
                                    onChange={(e) =>
                                      this.surveyDropDownQuestion(e, que_index, question, survey_current_page)
                                    }
                                    disabled={isTicketClosed === false ? isUserNotAssignee : true}
                                    options={dropdownValue}
                                    sx={{
                                      "& input": {
                                        border: "none !important",
                                      },
                                    }}
                                  ></AutoSelect>
                                </div>
                              )}
                              {question.question_type === "SHORT ANSWER" && (
                                <textarea
                                  name="text"
                                  type="textarea"
                                  onChange={(e) => this.surveyShortAnswerInputQuestion(e, que_index, question)}
                                  className="custom-textArea"
                                  placeholder={question.question_short_form}
                                  rows={3}
                                  cols={3}
                                  style={{
                                    color: "#000",
                                    fontSize: "1rem",
                                    marginLeft: "20px",
                                    width: "90%",
                                    height: "70px",
                                    whiteSpace: "pre-line",
                                  }}
                                  value={
                                    ticketResolutionResponse &&
                                    ticketResolutionResponse.SurveyQuestions[que_index].Answer[0] &&
                                    ticketResolutionResponse.SurveyQuestions[que_index].Answer[0].option
                                  }
                                  disabled={isTicketClosed === false ? isUserNotAssignee : true}
                                />
                              )}
                              {question.question_type === "PARAGRAPH" && (
                                <textarea
                                  name="text"
                                  type="textarea"
                                  id="description"
                                  onChange={(e) => this.surveyParagraphInputQuestion(e, que_index, question)}
                                  className="custom-textArea"
                                  placeholder={question.question_short_form}
                                  rows={5}
                                  cols={5}
                                  style={{
                                    color: "#000",
                                    fontSize: "1rem",
                                    marginLeft: "20px",
                                    width: "90%",
                                    height: "70px",
                                    whiteSpace: "pre-line",
                                  }}
                                  value={
                                    ticketResolutionResponse &&
                                    ticketResolutionResponse.SurveyQuestions[que_index].Answer[0] &&
                                    ticketResolutionResponse.SurveyQuestions[que_index].Answer[0].option
                                  }
                                  disabled={isTicketClosed === false ? isUserNotAssignee : true}
                                />
                              )}
                              {question.question_type === "RATING" && (
                                <Row className="">
                                  <Col>
                                    <Col
                                      sm="12"
                                      className="no-label"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "30px",
                                        marginLeft: "25px",
                                      }}
                                    >
                                      <Rater
                                        total={5}
                                        rating={
                                          ticketResolutionResponse &&
                                          ticketResolutionResponse.SurveyQuestions[que_index].Answer[0]
                                            ? ticketResolutionResponse.SurveyQuestions[que_index].Answer[0].option
                                            : this.state.starRating == 0
                                            ? this.state.starRating
                                            : this.state.starRating[`${survey_current_page}_${que_index}`]
                                        }
                                        onRate={({ rating }) =>
                                          this.setStarRating(que_index, question, rating, survey_current_page)
                                        }
                                        interactive={isTicketClosed === false ? !isUserNotAssignee : false}
                                      >
                                        <CustomRatingComponent
                                          activeImg={starActive}
                                          inActiveImg={starInActive}
                                          customClass="review-method-img"
                                          imageWidth={35}
                                          style={{ marginRight: "12px" }}
                                        />
                                      </Rater>
                                    </Col>
                                    <Header
                                      as="h6"
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontSize: "15px",
                                        alignItems: "center",
                                        marginTop: "5px",
                                        marginLeft: "44px",
                                      }}
                                    >
                                      <h6>{question.lowRatingInput}</h6>
                                      <p style={{ marginTop: "3px" }}>
                                        <Icon name="caret right" size="small" />
                                        ----------------------
                                        <Icon name="caret left" size="small" />
                                      </p>
                                      <h6>{question.HighRatingInput}</h6>
                                    </Header>
                                  </Col>
                                </Row>
                              )}
                              {question.question_type === "NPS QUESTION" && (
                                <Row className="" style={{ margin: "auto" }}>
                                  <Col sm="12" className="nps-score-center" style={{}}>
                                    <div className="npsRatingDiv">
                                      <span
                                        style={{
                                          position: "absolute",
                                          width: "30px",
                                          textAlign: "center",
                                          bottom: "0",
                                          left: "-29px",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {question.lowRatingInput}
                                      </span>
                                      {/* <div id="speedometer" style={{ marginLeft: "-10px" }}>
                                        <div className="wrapper">
                                          <div className="indicator-wrapper">
                                            <div className="indicator-wrapper-inner">
                                              <div id="indicator"></div>
                                            </div>
                                          </div>
                                          <div
                                            className="bar bar-1"
                                            onClick={() =>
                                              this.setNpsScore(que_index, question, 1, survey_current_page)
                                            }
                                          >
                                            <span>1</span>
                                          </div>
                                          <div
                                            className="bar bar-2"
                                            onClick={() =>
                                              this.setNpsScore(que_index, question, 2, survey_current_page)
                                            }
                                          >
                                            <span>2</span>
                                          </div>
                                          <div
                                            className="bar bar-3"
                                            onClick={() =>
                                              this.setNpsScore(que_index, question, 3, survey_current_page)
                                            }
                                          >
                                            <span>3</span>
                                          </div>
                                          <div
                                            className="bar bar-4"
                                            onClick={() =>
                                              this.setNpsScore(que_index, question, 4, survey_current_page)
                                            }
                                          >
                                            <span>4</span>
                                          </div>
                                          <div
                                            className="bar bar-5"
                                            onClick={() =>
                                              this.setNpsScore(que_index, question, 5, survey_current_page)
                                            }
                                          >
                                            <span>5</span>
                                          </div>
                                          <div
                                            className="bar bar-6"
                                            onClick={() =>
                                              this.setNpsScore(que_index, question, 6, survey_current_page)
                                            }
                                          >
                                            <span>6</span>
                                          </div>
                                          <div
                                            className="bar bar-7"
                                            onClick={() =>
                                              this.setNpsScore(que_index, question, 7, survey_current_page)
                                            }
                                          >
                                            <span>7</span>
                                          </div>
                                          <div
                                            className="bar bar-8"
                                            onClick={() =>
                                              this.setNpsScore(que_index, question, 8, survey_current_page)
                                            }
                                          >
                                            <span>8</span>
                                          </div>
                                          <div
                                            className="bar bar-9"
                                            onClick={() =>
                                              this.setNpsScore(que_index, question, 9, survey_current_page)
                                            }
                                          >
                                            <span>9</span>
                                          </div>
                                          <div
                                            className="bar bar-10"
                                            onClick={() =>
                                              this.setNpsScore(que_index, question, 10, survey_current_page)
                                            }
                                          >
                                            <span>10</span>
                                          </div>
                                        </div>
                                        <div
                                          className="needle"
                                          style={{
                                            transform:
                                              this.state.npsScore == 0
                                                ? "rotate(0deg)"
                                                : `rotate(${
                                                    this.state.npsScore[`${survey_current_page}_${que_index}`] > 2
                                                      ? this.state.npsScore[`${survey_current_page}_${que_index}`] > 5
                                                        ? this.state.npsScore[`${survey_current_page}_${que_index}`] *
                                                          1.2 *
                                                          15
                                                        : this.state.npsScore[`${survey_current_page}_${que_index}`] *
                                                          1.8 *
                                                          9
                                                      : this.state.npsScore[`${survey_current_page}_${que_index}`] *
                                                        1.5 *
                                                        7
                                                  }deg)`,
                                            left: "75px",
                                          }}
                                        ></div>
                                        <div className="semiCircleProgressBar" style={{ left: "76px" }}>
                                          <SemiCircleProgressBar
                                            percentage={
                                              this.state.npsScore == 0
                                                ? this.state.npsScore * 0
                                                : this.state.npsScore[`${survey_current_page}_${que_index}`] < 6
                                                ? this.state.npsScore[`${survey_current_page}_${que_index}`] < 3
                                                  ? this.state.npsScore[`${survey_current_page}_${que_index}`] * 7
                                                  : this.state.npsScore[`${survey_current_page}_${que_index}`] * 9.3
                                                : this.state.npsScore[`${survey_current_page}_${que_index}`] * 10
                                            }
                                            diameter={160}
                                            stroke={"#0f62fe"}
                                            strokeWidth={5}
                                            background={"#fff"}
                                          />
                                        </div>
                                      </div> */}
                                      
                                          <div className="nps-meter">
                                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => {
                                              let selectedScore = this.state.npsScore == 0 ? 0 : this.state.npsScore[`${survey_current_page}_${que_index}`];
                                              // Determine the color class based on selection
                                              let colorClass;

                                              if (selectedScore && num <= selectedScore) {
                                                // If a score is selected, use its color for all numbers up to it
                                                if (selectedScore <= 6)
                                                  colorClass = "nps-rating-colour-red filled";
                                                else if (selectedScore <= 8)
                                                  colorClass =
                                                    "nps-rating-colour-yellow filled";
                                                else
                                                  colorClass = "nps-rating-colour-green filled";
                                              } else {
                                                // Default colors when no selection or for numbers beyond selection
                                                if (num <= 6)
                                                  colorClass = "nps-rating-colour-red";
                                                else if (num <= 8)
                                                  colorClass = "nps-rating-colour-yellow";
                                                else colorClass = "nps-rating-colour-green";
                                              }

                                              return (
                                                <span
                                                  key={num}
                                                  className={colorClass}
                                                  onClick={() =>
                                                    this.setNpsScore(
                                                      que_index,
                                                      question,
                                                      num,
                                                      survey_current_page
                                                    )
                                                  }
                                                >
                                                  {num}
                                                </span>
                                              );
                                            })}
                                          </div>
                                      <span
                                        style={{
                                          position: "absolute",
                                          width: "35px",
                                          textAlign: "center",
                                          bottom: "0",
                                          right: "-23px",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {question.HighRatingInput}
                                      </span>
                                    </div>
                                  </Col>
                                  <input
                                    name="text"
                                    type="text"
                                    id="rating"
                                    className="custom-textArea"
                                    value={this.state.starRating}
                                    style={{ display: "none" }}
                                  />
                                </Row>
                              )}
                              {question.question_type === "ATTACHMENT" && (
                                <Row style={{ width: "98%", margin: "auto" }}>
                                  <Col sm="12">
                                    <Box
                                      component="label"
                                      className="imageDragBox"
                                      sx={{ padding: "5px 0px" }}
                                      htmlFor={`tsr_attachement${survey_current_page}${que_index}`}
                                    >
                                      <Box sx={{ margin: "" }}>
                                        <img src={imgDrag} />
                                      </Box>
                                      <Box sx={{ color: "#7A8193" }}>
                                        <Box component={"span"} className="text-center" sx={{ fontSize: "14px" }}>
                                          <Box
                                            component={"span"}
                                            sx={{
                                              color: "#00B5AD",
                                              fontWeight: 500,
                                            }}
                                          >
                                            Click to upload
                                          </Box>{" "}
                                          or drag and drop
                                        </Box>

                                        <Box component={"span"} className="d-block" sx={{ fontSize: "13px" }}>
                                          SVG, PNG, JPG (max. 800x400px)
                                        </Box>
                                      </Box>
                                    </Box>

                                    <input
                                      id={`tsr_attachement${survey_current_page}${que_index}`}
                                      disabled={isTicketClosed === false ? isUserNotAssignee : true}
                                      type="file"
                                      style={{ display: "none" }}
                                      onChange={(event) =>
                                        this.setAttachedFiles(event, que_index, question, survey_current_page)
                                      }
                                      multiple
                                    />
                                    {this.state.errorMessage && (
                                      <Box
                                        sx={{
                                          color: "red",
                                          fontSize: "13px",
                                          marginTop: "4px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {/* {this.state.errorMessage} */}
                                        {this.state.errorMessage[`${survey_current_page}_${que_index}`]}
                                      </Box>
                                    )}
                                  </Col>
                                  {attachedFiles &&
                                    attachedFiles[`${survey_current_page}_${que_index}`] &&
                                    attachedFiles[`${survey_current_page}_${que_index}`].length > 0 &&
                                    Object.keys(attachedFiles[`${survey_current_page}_${que_index}`]).map(
                                      (file_key) => {
                                        const fileSizeInBytes =
                                          attachedFiles[`${survey_current_page}_${que_index}`][file_key].size;
                                        const fileSizeInKB = fileSizeInBytes / 1024;
                                        const fileSizeDisplay =
                                          fileSizeInKB >= 1024
                                            ? `${(fileSizeInKB / 1024).toFixed(2)} MB`
                                            : `${fileSizeInKB.toFixed(2)} KB`;
                                        return (
                                          file_key != "length" && (
                                            <Col
                                              sm="12"
                                              key={file_key}
                                              onClick={() =>
                                                this.showUserFileModalFn(
                                                  attachedFiles[`${survey_current_page}_${que_index}`][file_key]
                                                )
                                              }
                                            >
                                              {attachedFiles[`${survey_current_page}_${que_index}`][
                                                file_key
                                              ].name.includes(".pdf") && (
                                                <Box
                                                  component=""
                                                  className="imageDragBox d-flex mt-2"
                                                  sx={{
                                                    padding: "5px 5px",
                                                    borderColor: "#5FAAB1",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <Box sx={{ width: "10%" }}>
                                                    <img src={imgDrag} className="d-flex m-auto" />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      width: "80%",
                                                      fontSize: "13px",
                                                      paddingLeft: "7px",
                                                    }}
                                                  >
                                                    <Box>
                                                      {
                                                        attachedFiles[`${survey_current_page}_${que_index}`][file_key]
                                                          .name
                                                      }
                                                    </Box>
                                                    <Box>{fileSizeDisplay}</Box>
                                                    <Box>
                                                      <Box className="align-center gap-10">
                                                        <div className="w-100">
                                                          <Progress
                                                            value={100}
                                                            // max={maxCount.nouns}
                                                            className="custom-progress-bar"
                                                          />
                                                        </div>
                                                        <Box
                                                          sx={{
                                                            color: "#1B1C1D",
                                                            textAlign: "",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          100%
                                                        </Box>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box sx={{ width: "10%" }}>
                                                    <Box
                                                      component="img"
                                                      src={checkIcon}
                                                      sx={{ display: "flex", margin: "4px auto auto" }}
                                                    />
                                                  </Box>
                                                </Box>
                                              )}
                                              {attachedFiles[`${survey_current_page}_${que_index}`][
                                                file_key
                                              ].name.includes(".doc") && (
                                                <Box
                                                  component=""
                                                  className="imageDragBox d-flex mt-2"
                                                  sx={{
                                                    padding: "5px 5px",
                                                    borderColor: "#5FAAB1",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <Box sx={{ width: "10%" }}>
                                                    <img src={imgDrag} className="d-flex m-auto" />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      width: "80%",
                                                      fontSize: "13px",
                                                      paddingLeft: "7px",
                                                    }}
                                                  >
                                                    <Box>
                                                      {
                                                        attachedFiles[`${survey_current_page}_${que_index}`][file_key]
                                                          .name
                                                      }
                                                    </Box>
                                                    <Box>{fileSizeDisplay}</Box>
                                                    <Box>
                                                      <Box className="align-center gap-10">
                                                        <div className="w-100">
                                                          <Progress
                                                            value={100}
                                                            // max={maxCount.nouns}
                                                            className="custom-progress-bar"
                                                          />
                                                        </div>
                                                        <Box
                                                          sx={{
                                                            color: "#1B1C1D",
                                                            textAlign: "",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          100%
                                                        </Box>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box sx={{ width: "10%" }}>
                                                    <Box
                                                      component="img"
                                                      src={checkIcon}
                                                      sx={{ display: "flex", margin: "4px auto auto" }}
                                                    />
                                                  </Box>
                                                </Box>
                                              )}
                                              {/* {attachedFiles[`${survey_current_page}_${que_index}`][file_key].name.includes('.xlsx') && 
                                    <p style={{cursor:'pointer', border:'1px dashed black', padding:'3px 5px', borderRadius:'10px', marginBottom:'5px'}}><Icon name='file excel' size='large' /> {attachedFiles[`${survey_current_page}_${que_index}`][file_key].name}</p>
                                  } */}
                                              {attachedFiles[`${survey_current_page}_${que_index}`][
                                                file_key
                                              ].name.includes(".xls") && (
                                                <Box
                                                  component=""
                                                  className="imageDragBox d-flex mt-2"
                                                  sx={{
                                                    padding: "5px 5px",
                                                    borderColor: "#5FAAB1",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <Box sx={{ width: "10%" }}>
                                                    <img src={imgDrag} className="d-flex m-auto" />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      width: "80%",
                                                      fontSize: "13px",
                                                      paddingLeft: "7px",
                                                    }}
                                                  >
                                                    <Box>
                                                      {
                                                        attachedFiles[`${survey_current_page}_${que_index}`][file_key]
                                                          .name
                                                      }
                                                    </Box>
                                                    <Box>{fileSizeDisplay}</Box>
                                                    <Box>
                                                      <Box className="align-center gap-10">
                                                        <div className="w-100">
                                                          <Progress
                                                            value={100}
                                                            // max={maxCount.nouns}
                                                            className="custom-progress-bar"
                                                          />
                                                        </div>
                                                        <Box
                                                          sx={{
                                                            color: "#1B1C1D",
                                                            textAlign: "",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          100%
                                                        </Box>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box sx={{ width: "10%" }}>
                                                    <Box
                                                      component="img"
                                                      src={checkIcon}
                                                      sx={{ display: "flex", margin: "4px auto auto" }}
                                                    />
                                                  </Box>
                                                </Box>
                                              )}
                                              {attachedFiles[`${survey_current_page}_${que_index}`][
                                                file_key
                                              ].name.includes(".txt") && (
                                                <Box
                                                  component=""
                                                  className="imageDragBox d-flex mt-2"
                                                  sx={{
                                                    padding: "5px 5px",
                                                    borderColor: "#5FAAB1",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <Box sx={{ width: "10%" }}>
                                                    <img src={imgDrag} className="d-flex m-auto" />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      width: "80%",
                                                      fontSize: "13px",
                                                      paddingLeft: "7px",
                                                    }}
                                                  >
                                                    <Box>
                                                      {
                                                        attachedFiles[`${survey_current_page}_${que_index}`][file_key]
                                                          .name
                                                      }
                                                    </Box>
                                                    <Box>{fileSizeDisplay}</Box>
                                                    <Box>
                                                      <Box className="align-center gap-10">
                                                        <div className="w-100">
                                                          <Progress
                                                            value={100}
                                                            // max={maxCount.nouns}
                                                            className="custom-progress-bar"
                                                          />
                                                        </div>
                                                        <Box
                                                          sx={{
                                                            color: "#1B1C1D",
                                                            textAlign: "",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          100%
                                                        </Box>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box sx={{ width: "10%" }}>
                                                    <Box
                                                      component="img"
                                                      src={checkIcon}
                                                      sx={{ display: "flex", margin: "4px auto auto" }}
                                                    />
                                                  </Box>
                                                </Box>
                                              )}
                                              {attachedFiles[`${survey_current_page}_${que_index}`][
                                                file_key
                                              ].name.includes(".csv") && (
                                                <Box
                                                  component=""
                                                  className="imageDragBox d-flex mt-2"
                                                  sx={{
                                                    padding: "5px 5px",
                                                    borderColor: "#5FAAB1",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <Box sx={{ width: "10%" }}>
                                                    <img src={imgDrag} className="d-flex m-auto" />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      width: "80%",
                                                      fontSize: "13px",
                                                      paddingLeft: "7px",
                                                    }}
                                                  >
                                                    <Box>
                                                      {
                                                        attachedFiles[`${survey_current_page}_${que_index}`][file_key]
                                                          .name
                                                      }
                                                    </Box>
                                                    <Box>{fileSizeDisplay}</Box>
                                                    <Box>
                                                      <Box className="align-center gap-10">
                                                        <div className="w-100">
                                                          <Progress
                                                            value={100}
                                                            // max={maxCount.nouns}
                                                            className="custom-progress-bar"
                                                          />
                                                        </div>
                                                        <Box
                                                          sx={{
                                                            color: "#1B1C1D",
                                                            textAlign: "",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          100%
                                                        </Box>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box sx={{ width: "10%" }}>
                                                    <Box
                                                      component="img"
                                                      src={checkIcon}
                                                      sx={{ display: "flex", margin: "4px auto auto" }}
                                                    />
                                                  </Box>
                                                </Box>
                                              )}
                                              {attachedFiles[`${survey_current_page}_${que_index}`][
                                                file_key
                                              ].name.includes(".png") && (
                                                <Box
                                                  component=""
                                                  className="imageDragBox d-flex mt-2"
                                                  sx={{
                                                    padding: "5px 5px",
                                                    borderColor: "#5FAAB1",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <Box sx={{ width: "10%" }}>
                                                    <img src={imgDrag} className="d-flex m-auto" />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      width: "80%",
                                                      fontSize: "13px",
                                                      padding: "0px 7px",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    <Box>
                                                      {
                                                        attachedFiles[`${survey_current_page}_${que_index}`][file_key]
                                                          .name
                                                      }
                                                    </Box>
                                                    <Box>{fileSizeDisplay}</Box>
                                                    <Box>
                                                      <Box className="align-center gap-10">
                                                        <div className="w-100">
                                                          <Progress
                                                            value={100}
                                                            // max={maxCount.nouns}
                                                            className="custom-progress-bar"
                                                          />
                                                        </div>
                                                        <Box
                                                          sx={{
                                                            color: "#1B1C1D",
                                                            textAlign: "",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          100%
                                                        </Box>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box sx={{ width: "10%" }}>
                                                    <Box
                                                      component="img"
                                                      src={checkIcon}
                                                      sx={{ display: "flex", margin: "4px auto auto" }}
                                                    />
                                                  </Box>
                                                </Box>
                                              )}
                                              {attachedFiles[`${survey_current_page}_${que_index}`][
                                                file_key
                                              ].name.includes(".jpeg") && (
                                                <Box
                                                  component=""
                                                  className="imageDragBox d-flex mt-2"
                                                  sx={{
                                                    padding: "5px 5px",
                                                    borderColor: "#5FAAB1",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <Box sx={{ width: "10%" }}>
                                                    <img src={imgDrag} className="d-flex m-auto" />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      width: "80%",
                                                      fontSize: "13px",
                                                      padding: "0px 7px",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    <Box>
                                                      {
                                                        attachedFiles[`${survey_current_page}_${que_index}`][file_key]
                                                          .name
                                                      }
                                                    </Box>
                                                    <Box>{fileSizeDisplay}</Box>
                                                    <Box>
                                                      <Box className="align-center gap-10">
                                                        <div className="w-100">
                                                          <Progress
                                                            value={100}
                                                            // max={maxCount.nouns}
                                                            className="custom-progress-bar"
                                                          />
                                                        </div>
                                                        <Box
                                                          sx={{
                                                            color: "#1B1C1D",
                                                            textAlign: "",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          100%
                                                        </Box>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box sx={{ width: "10%" }}>
                                                    <Box
                                                      component="img"
                                                      src={checkIcon}
                                                      sx={{ display: "flex", margin: "4px auto auto" }}
                                                    />
                                                  </Box>
                                                </Box>
                                              )}
                                              {attachedFiles[`${survey_current_page}_${que_index}`][
                                                file_key
                                              ].name.includes(".jpg") && (
                                                <Box
                                                  component=""
                                                  className="imageDragBox d-flex mt-2"
                                                  sx={{
                                                    padding: "5px 5px",
                                                    borderColor: "#5FAAB1",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <Box sx={{ width: "10%" }}>
                                                    <img src={imgDrag} className="d-flex m-auto" />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      width: "80%",
                                                      fontSize: "13px",
                                                      padding: "0px 7px",
                                                      wordWrap: "break-word",
                                                    }}
                                                  >
                                                    <Box>
                                                      {
                                                        attachedFiles[`${survey_current_page}_${que_index}`][file_key]
                                                          .name
                                                      }
                                                    </Box>
                                                    <Box>{fileSizeDisplay}</Box>
                                                    <Box>
                                                      <Box className="align-center gap-10">
                                                        <div className="w-100">
                                                          <Progress
                                                            value={100}
                                                            // max={maxCount.nouns}
                                                            className="custom-progress-bar"
                                                          />
                                                        </div>
                                                        <Box
                                                          sx={{
                                                            color: "#1B1C1D",
                                                            textAlign: "",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          100%
                                                        </Box>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box sx={{ width: "10%" }}>
                                                    <Box
                                                      component="img"
                                                      src={checkIcon}
                                                      sx={{ display: "flex", margin: "4px auto auto" }}
                                                    />
                                                  </Box>
                                                </Box>
                                              )}
                                              {(attachedFiles[`${survey_current_page}_${que_index}`][
                                                file_key
                                              ].name.includes(".mp4") ||
                                                attachedFiles[`${survey_current_page}_${que_index}`][
                                                  file_key
                                                ].name.includes(".webm")) && (
                                                <Box
                                                  component=""
                                                  className="imageDragBox d-flex mt-2"
                                                  sx={{
                                                    padding: "5px 5px",
                                                    borderColor: "#5FAAB1",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <Box sx={{ width: "10%" }}>
                                                    <img src={imgDrag} className="d-flex m-auto" />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      width: "80%",
                                                      fontSize: "13px",
                                                      paddingLeft: "7px",
                                                    }}
                                                  >
                                                    <Box>
                                                      {
                                                        attachedFiles[`${survey_current_page}_${que_index}`][file_key]
                                                          .name
                                                      }
                                                    </Box>
                                                    <Box>{fileSizeDisplay}</Box>
                                                    <Box>
                                                      <Box className="align-center gap-10">
                                                        <div className="w-100">
                                                          <Progress
                                                            value={100}
                                                            // max={maxCount.nouns}
                                                            className="custom-progress-bar"
                                                          />
                                                        </div>
                                                        <Box
                                                          sx={{
                                                            color: "#1B1C1D",
                                                            textAlign: "",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          100%
                                                        </Box>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box sx={{ width: "10%" }}>
                                                    <Box
                                                      component="img"
                                                      src={checkIcon}
                                                      sx={{ display: "flex", margin: "4px auto auto" }}
                                                    />
                                                  </Box>
                                                </Box>
                                              )}
                                              {attachedFiles[`${survey_current_page}_${que_index}`][
                                                file_key
                                              ].name.includes(".mp3") && (
                                                <Box
                                                  component=""
                                                  className="imageDragBox d-flex mt-2"
                                                  sx={{
                                                    padding: "5px 5px",
                                                    borderColor: "#5FAAB1",
                                                    textAlign: "left",
                                                  }}
                                                >
                                                  <Box sx={{ width: "10%" }}>
                                                    <img src={imgDrag} className="d-flex m-auto" />
                                                  </Box>
                                                  <Box
                                                    sx={{
                                                      width: "80%",
                                                      fontSize: "13px",
                                                      paddingLeft: "7px",
                                                    }}
                                                  >
                                                    <Box>
                                                      {
                                                        attachedFiles[`${survey_current_page}_${que_index}`][file_key]
                                                          .name
                                                      }
                                                    </Box>
                                                    <Box>{fileSizeDisplay}</Box>
                                                    <Box>
                                                      <Box className="align-center gap-10">
                                                        <div className="w-100">
                                                          <Progress
                                                            value={100}
                                                            // max={maxCount.nouns}
                                                            className="custom-progress-bar"
                                                          />
                                                        </div>
                                                        <Box
                                                          sx={{
                                                            color: "#1B1C1D",
                                                            textAlign: "",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          100%
                                                        </Box>
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                  <Box sx={{ width: "10%" }}>
                                                    <Box
                                                      component="img"
                                                      src={checkIcon}
                                                      sx={{ display: "flex", margin: "4px auto auto" }}
                                                    />
                                                  </Box>
                                                </Box>
                                              )}
                                            </Col>
                                          )
                                        );
                                      }
                                    )}
                                  <ChatFileShow
                                    showModal={this.state.showUserFileModal}
                                    closeModal={this.closeUserFileModal}
                                    selectAttchFile={this.state.selectAttchFile}
                                  />
                                </Row>
                              )}
                            </Row>
                          </Form.Field>
                        )
                      );
                    })}
                  <Row className="mt-2 mb-2">
                    <Col sm="12">
                      <p
                        style={{
                          color: "red",
                          fontSize: "1rem",
                          textAlign: "center",
                        }}
                      >
                        {surveySubmitError}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mb-2">
                    <Col sm="12" className="mt-2 text-center">
                      {totalSurveyPageCount === nextPageCount ? (
                        <Form.Field
                          control={Button}
                          color="teal"
                          type="submit"
                          style={{
                            color: "white",
                            background: "black",
                            float: "right",
                            borderRadius: "25px",
                          }}
                          onClick={this.submitSurveyPage.bind(this)}
                          disabled={isTicketClosed === false ? isUserNotAssignee : true}
                        >
                          Save
                        </Form.Field>
                      ) : (
                        <Form.Field
                          control={Button}
                          color="teal"
                          type="button"
                          style={{
                            color: "white",
                            background: "black",
                            float: "right",
                            borderRadius: "25px",
                          }}
                          onClick={this.nextSurveyPage.bind(this)}
                          disabled={isTicketClosed === false ? isUserNotAssignee : true}
                        >
                          Save & Continue
                        </Form.Field>
                      )}
                    </Col>
                  </Row>
                </Box>
              </Form>
            </div>
          </div>
      </div>
    );
  }
}

export default TicketResolutionsSurveyForm;
