import React from "react";
import cookie from "react-cookies";
import { Switch, Route } from "react-router";
import { BrowserRouter, Redirect } from "react-router-dom";
import App from "./containers/App";
import HomePage from "./containers/HomePage";
import LoginPage from "./containers/accounts/LoginPage";
import ForgotPasswordPage from "./containers/accounts/ForgotPasswordPage";
import MailSentSuccessfulPage from "./containers/common/MailSentSuccessfulPage";
import RegisterPage from "./containers/accounts/RegisterPage";
import RegisterConfirmPage from "./containers/common/RegisterConfirmPage";
import RegisterSuccessfulPage from "./containers/common/RegisterSuccessfulPage";

import ResetPasswordPage from "./containers/accounts/ResetPasswordPage";
import ResetPasswordSuccessfulPage from "./containers/common/ResetPasswordSuccessfulPage";
import BranchSetupPage from "./containers/business/BranchSetup";

import FeedbackPage from "./containers/campaign/FeedbackPage";
import UnsubscribePage from "./containers/campaign/UnsubscribePage";
import NotFoundPage from "./containers/common/NotFoundPage";
import RedirectPage from "./containers/common/RedirectPage";
import PermissionDenyPage from "./containers/common/PermissionDenyPage";
import ServerErrorPage from "./containers/common/ServerErrorPage";
import MarketingPage from "./containers/marketing/MarketingList";
import BusinessPage from "./containers/admin/BusinessPage";
import ContactPage from "./containers/common/ContactPage";
import * as utils from "./libs/utils";
import AppTemplatePage from "./containers/common/AppTemplatePage";
import AgencyPage from "./containers/Agency/AgencyPage";
import AgencyTeamMapping from "./containers/Agency/AgencyTeamMapping";
import TeamMappingForm from "./components/Agency/TeamMappingForm";
import AgencyStaffPage from "./containers/Agency/AgencyStaffPage";
import AssignTicketPage from "./containers/assignTicket/assignTicketPage";
import NegativeReviewsPage from "./components/common/NegativeReviewsPage";
import MultiStepForm from "./modules/campaignsRoot/templates/Generic/MultiStepForm";
import MyProfile from "./components/accountSettings/MyProfile";
import InstagramRedirectPage from "./containers/common/InstagramRedirectPage";
import FacebookRedirectPage from "./containers/common/FacebookRedirectPage";
import GenericFeedbackPage from "./containers/genericFeedbackPage/GenericFeedbackPage";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const ROLE = rest.requireRole;
  const authToken = cookie.load("authToken");
  const user = cookie.load("user");
  let nextPage;

  const {
    computedMatch : { params : { business_id, marketing_id }}
  } = rest;
  const business_ids = cookie.load("business_ids")
  let url = "";
  if(business_ids && !user.is_superuser && 
    ( 
      (business_id && !business_ids.includes(business_id))
      || (marketing_id && !business_ids.includes(marketing_id))
    ))  {
    url = utils.nextPageUrl(
      user.business_type,
      user.is_superuser,
      user.screen_name,
      user.business,
      user.corporate_branch,
      user.marketing_id,
      user.is_business_owner,
      user.multi_business_access,
    );
    console.log("reaching .... ", business_ids.includes(business_id));
    console.log(url);
    nextPage = <Redirect to={url} />;
  }
  if (!authToken || !user || (ROLE && ROLE != user.business_type)) {
    nextPage = <Redirect to="/login" />;
  }
  
  return (
    <Route
      {...rest}
      render={(props) => {
        return nextPage ? nextPage : <Component {...props} />;
      }}
    />
  );
};

const NonLoginRoute = ({ component: Component, ...rest }) => {
  const authToken = cookie.load("authToken");
  const user = cookie.load("user");
  let nextPage;
  if (authToken && user) {
    try {
      // get query string from url
      const {
        location: { search },
      } = rest;
      const query = new URLSearchParams(search);
      const redirect = query.get("redirect");
      if (
        redirect == "assign-ticket" &&
        query.get("ticket_id") &&
        user.business
      ) {
        let business_id = query.get("business_id");
        let url = "";
        if (business_id) {
          url =
            "/assign-ticket?ticket_id=" +
            query.get("ticket_id") +
            "&business_id=" +
            business_id;
        } else {
          url =
            "/assign-ticket?ticket_id=" +
            query.get("ticket_id") +
            "&business_id=" +
            user.business;
        }
        nextPage = <Redirect to={url} />;
        return (
          <Route
            {...rest}
            render={(props) => {
              return nextPage ? nextPage : <Component {...props} />;
            }}
          />
        );
      }

      let nextPageLink = utils.nextPageUrl(
        user.business_type,
        user.is_superuser,
        user.screen_name,
        user.business,
        user.corporate_branch,
        user.marketing_id,
        user.is_business_owner,
        user.multi_business_access
      );
      nextPage = <Redirect to={nextPageLink} />;
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        return nextPage ? nextPage : <Component {...props} />;
      }}
    />
  );
};

export default (
  <BrowserRouter>
    <App>
      <Switch>
        <NonLoginRoute exact path="/" component={HomePage} />
        <NonLoginRoute path="/login" component={LoginPage} />
        <NonLoginRoute path="/register" component={RegisterPage} />
        <Route
          path="/reviews/:businessId/negative-reviews"
          component={NegativeReviewsPage}
        />

        <Route path="/invite/:id/feedback" component={FeedbackPage} />
        <Route
          path="/reviews/:template_id/feedback/"
          component={FeedbackPage}
        />
        <Route
          path="/reviews/:template_id/new-feedback/"
          component={GenericFeedbackPage}
        />
        <Route path="/invite/:id/unsubscribe" component={UnsubscribePage} />

        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route
          path="/accounts/password/reset/:uid/:token"
          component={ResetPasswordPage}
        />
        <Route  
          path="/reset-password-success"
          component={ResetPasswordSuccessfulPage}
        />
        <Route
          path="/mail-sent-successful"
          component={MailSentSuccessfulPage}
        />

        <Route path="/register-successful" component={RegisterSuccessfulPage} />
        <Route
          path="/register-confirm/:uid/:token"
          component={RegisterConfirmPage}
        />
        <Route path="/redirect/uri/" component={RedirectPage} />
        <Route path="/facebook/redirect/uri/" component={FacebookRedirectPage} />
        <Route path="/instagram/redirect/uri/" component={InstagramRedirectPage} />
        <Route path="/not-found" component={NotFoundPage} />
        <Route path="/permission-deny" component={PermissionDenyPage} />
        <Route path="/server-error" component={ServerErrorPage} />
        <Route path="/:agency_id/agency-brands" component={AgencyPage} />
        <Route path="/contact" component={ContactPage} />
        <Route path="/:agency_id/agency-team" component={AgencyTeamMapping} />
        <Route
          path="/:agency_id/add-team-mapping"
          component={TeamMappingForm}
        />
        <Route
          path="/:agency_id/agency-staff-dashboard"
          component={AgencyStaffPage}
        />
        <Route path="/example" component={MultiStepForm} />
        <Route path="/:agency_id/account-settings" component={MyProfile} />

        <PrivateRoute
          path="/:business_id/branch-setup"
          component={BranchSetupPage}
        />
        <PrivateRoute
          path="/marketing"
          component={MarketingPage}
          requireRole="marketing"
        />
        <PrivateRoute path="/admin" component={BusinessPage} />
        <PrivateRoute path="/assign-ticket" component={AssignTicketPage} />

        <PrivateRoute path="/:business_id" component={AppTemplatePage} />
      </Switch>
    </App>
  </BrowserRouter>
);
