import React from "react";
import { Row, Col } from "reactstrap";
import Home from "../components/home/Home";

import { Box } from "@material-ui/core";

class HomePage extends React.Component {
  render() {
    return (
      <Row className="full-height img-background">
        <Col xs={12}>
          <Row className="h-100">
            <Home />
          </Row>
        </Col>
      </Row>
    );
  }
}

export default HomePage;
