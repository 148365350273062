import React, { useState } from "react";
import { Avatar, Box, Popover } from "@material-ui/core";
import EditSvg from "../../images/svg/EditSvg";
import deleteIcon from "../../images/svg/deleteSvg.svg";
import FormModal from "../../components/common/FormModal";
import TemplateModal from "./TemplateModal";
import EmojiPicker from "emoji-picker-react";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import AddComments from "./AddComments";
import moment from "moment";

const ThreadComponent = (props) => {
  const { rpl, socialCommentsActions, getComments, pageData } = props;
  const [editOpen, setEditOpen] = useState(false);
  const [editText, setEditText] = useState(rpl?.message);
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [commentData, setCommentData] = useState({});

  const handleEditComment = (item) => {
    const req = {
      page_id: item.page_id,
      post_id: item.post_id,
      comment_text: editText,
      comment_id: item.comment_id,
    };
    socialCommentsActions.editSocialComment(req).then((res) => {
      getComments(pageData);
    });
  };

  const toggleConfirm = () => {
    handleDeleteComment();
    setIsConfirm(false);
  };

  const handleCommentChange = (e) => {
    setEditText(e.target.value);
  };

  const handleDeleteComment = () => {
    // setLoader(true);
    const deleteReq = {
      page_id: commentData.page_id,
      comment_id: commentData.comment_id,
      is_thread: true,
    };
    socialCommentsActions.deleteSocialComment(deleteReq).then((res) => {
      getComments(pageData);
    });
  };

  const handleClose = () => {
    setOpenEmojiPicker(null);
  };
  const handleClick = (data) => {
    setCommentData(data);
    setIsConfirm(!isConfirm);
  };
  const open = Boolean(openEmojiPicker);

  const toggleTemplateOpen = () => {
    setIsTemplateOpen(!isTemplateOpen);
  };

  const handleEmojiSelect = ({ emoji }) => {
    setEditText((preValue) => preValue + " " + emoji);
  };

  const handleEditOpen = () => {
    setEditOpen(true);
  };

  return (
    <>
      <div className="d-flex mt-3 justify-content-between">
        <div className="d-flex">
          <Avatar src={rpl.from_profile_pic} />
          {/* <img
            alt=""
            src={rpl.from_profile_pic}
            style={{
              height: "40px",
              width: "40px",
              borderRadius: "50%",
            }}
          /> */}
          <Box className="pb-2 px-3 mb-2" sx={{}}>
            <Box
              component="span"
              sx={{
                color: "#7A8193",
                display: "block",
                marginBottom: "4px",
                fontSize: "13px",
              }}
            >
              {moment(rpl.created_time).format("llll")}
            </Box>
            <Box
              component="span"
              sx={{
                color: "#1B1C1D",
                // fontSize: '16px',
                display: "block",
                fontSize: "15px",
                // fontWeight: 500,
                margin: "3px 0px",
              }}
            >
              @{rpl.from_name}
              <span style={{ color: "#7A8193" }}>&nbsp;{rpl.message}</span>
            </Box>
            <Box component="span" sx={{ color: "#1B1C1D", fontSize: "15px" }}>
              Replied as
              <span style={{ color: "#7A8193" }}>: {rpl.from_name}</span>
            </Box>
          </Box>
        </div>
        <div className="d-flex gap-15">
          <div className="cursor-pointer" onClick={() => handleEditOpen()}>
            <EditSvg />
          </div>
          <img
            alt=""
            onClick={() => handleClick(rpl)}
            src={deleteIcon}
            style={{
              width: "16px",
              height: "16px",
              marginTop: "3px",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
      {editOpen && (
        <AddComments
          item={rpl}
          commentText={editText}
          handleCommentChange={handleCommentChange}
          toggleTemplateOpen={toggleTemplateOpen}
          setOpenEmojiPicker={setOpenEmojiPicker}
          handleReplyComment={handleEditComment}
        />
      )}
      <FormModal isOpen={isTemplateOpen} toggle={toggleTemplateOpen} width="750px" heading={"Templates"}>
        <TemplateModal />
      </FormModal>

      <Popover
        open={open}
        anchorEl={openEmojiPicker}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <EmojiPicker
          skinTonesDisabled={true}
          width={300}
          size={10}
          lazyLoad={true}
          lazyLoadEmojis={true}
          onEmojiClick={handleEmojiSelect}
        />
      </Popover>
      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={`Are you sure you want to delete this comment?`}
        isOpen={isConfirm}
        toggle={() => setIsConfirm(!isConfirm)}
        onConfirm={() => toggleConfirm()}
      />
    </>
  );
};

export default ThreadComponent;
