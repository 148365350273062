import React from "react";
import { Bar } from "react-chartjs-2";

const ReportsStackedBarChart = ({
  dataKeys,
  dataSets,
  primaryYLabel,
  secondaryYLabel,
  businessDetails,
  labelFontSize
}) => {
  const plugins = [
    {
      beforeDraw: function (c) {
        var legends = c.legend.legendItems;
        legends.forEach(function (e, index) {
          e.fillStyle = dataSets[index].legendColor;
        });
      },
    },
  ];
  const data = {
    labels: dataKeys,
    datasets: dataSets,
  };
  const options = {
    animation: {
      duration: 2000,
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontSize: 16,
          fontStyle: "normal",
          fontFamily: "Outfit",
          boxWidth: 6,
          boxHeight: 6,
          padding: 10,
          usePointStyle: true,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          font : {
            weight : function (context) {
            const label = dataKeys[context.index];
            return label === businessDetails ? 'bold' : 'normal';
          },
          size: labelFontSize ? labelFontSize : 12,
        }
        }
      },
      y: {
        beginAtZero: true,
        display: false,
        stacked: true,
        grid: {
          borderDash: [7],
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      "y-axis-1": {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: primaryYLabel,
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      "y-axis-2": {
        type: "linear",
        position: "right",
        title: {
          display: true,
          text: secondaryYLabel,
        },
        grid: {
          display: false,
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
    },
  };
  return (
    <div style={{ height: "400px" }}>
      <Bar data={data} options={options} plugins={plugins} />
    </div>
  );
};
export default ReportsStackedBarChart;
