import React, { useState, useEffect } from "react";
import { FormInput, FormTextArea } from "../../components/common/new/forms";
import { Form, Formik } from "formik";
import FormAutoComplete from "../../components/common/new/forms/FormAutoSelect";
import FPLabel from "../../components/common/new/forms/ui/FPLabel";
import { Divider } from "semantic-ui-react";
import {
  FPBadge,
  FPButton,
  FPMenuBox,
  FPToolTip,
} from "../../components/common/new";
import FPChip from "../../components/common/new/FPChip/FPChip";
import FPRadioGroup from "../../components/common/new/FPFilter/FPRadioGroup";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenOutlinedIcon from "@material-ui/icons/LockOpenOutlined";
import { Box } from "@material-ui/core";
import { Col, Row } from "reactstrap";
import { MentionsInput, Mention } from "react-mentions";
import CommentCard from "../../components/common/CommentCardComponent";
import FormModal from "../../components/common/FormModal";
import ManageRequestType from "./ManageRequestType";
import * as yup from "yup";
import ErrorMessage from "../../components/common/new/forms/ui/ErrorMessage";
import classNames from "classnames";
import { snakeToCapital } from "../../libs/utils";
import ServiceActivity from "../../images/svg/serviceActivity.svg";
import SrReopen from "../../images/svg/SrReopen.svg";
import SrClose from "../../images/svg/SrClose.svg";
import { lightCommentColor } from "../../libs/constant";
import cookie from "react-cookies";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import axios_instance from "../../libs/interseptor";
import UploadComponent from "../../components/common/new/forms/FormUploadFile";
import MediaViewer from "../../components/common/MediaViewer";
import ViewActivityLog from "./SrActivityLog/ViewActivityLog";
import SrCommentComponents from "./SrCommentComponents";

const removeNullValues = (obj) => {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== null && value !== "")
  );
};

const isNotEmpty = (obj) => Object.keys(obj).length > 0;

const CreateServiceRequest = (props) => {
  const {
    item,
    createService,
    locationList,
    ticketRequestTypeList,
    departmentList,
    actions,
    match,
    editService,
    addComment,
    comment,
    setComment,
    selectedFile,
    setSelectedFile,
    profileId,
    teamList,
    match: {
      params: { business_id },
    },
  } = props;

  const validationSchema = yup.object().shape({
    is_request: yup.string().required("Select Request mode"),
    service_request_type: yup.string().required("Select Service Request Type"),
    location: yup.string().nullable().required("Select Location"),
    assignees: yup.string().nullable().required("Select Assignees"),
    priority: yup.string().required("Select Priority"),
    // attachment: yup.array().min(1, "At least one file is required"),
  });

  const [initialData, setInitialData] = useState({
    service_request_type: "",
    is_request: "",
    description: "",
    priority: "",
    location: "",
    assignees: "",
    customer_email: "",
    customer_name: "",
    customer_mobile: "",
    room_number: "",
    table_number: "",
    bill_number: "",
    department: "",
    attachment: null,
  });

  const [open, setOpen] = useState(false);
  const [totalTeamMember, setTotalTeamMember] = useState([]);
  const [editLocationList, setEditLocationList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [fileAttachments, setFileAttachments] = useState([]);
  const [openComment, setOpenComment] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [selectedOption, setSelectedOption] = useState("comment");
  const [files, setFiles] = useState([]);

  const allUploads = [...attachments, ...files];
  
  const handleAddComment = (id, fileDetails) => {
    const user = cookie.load("user");
    const selectData = {
      description: comment ? comment : selectedFile?.name,
      email_id: user.email,
      attached_file: fileDetails,
    };
    const withData = {
      description: comment,
      email_id: user.email,
    };
    const data = selectedFile ? selectData : withData;
    addComment(id, data);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const CloseToggle = () => {
    setOpen(false);
  };

  const formValueDisabled = profileId !== item?.created_by;

  useEffect(() => {
    setInitialData({
      is_request: item?.is_request?.toString() || "",
      service_request_type: item?.service_request_type || "",
      description: item?.description || "",
      priority: item?.priority || "",
      location: item?.location || "",
      department: item?.department || "",
      assignees: item?.assignees || "",
      customer_name: item?.customer_name || "",
      customer_mobile: item?.customer_mobile || "",
      customer_email: item?.customer_email || "",
      room_number: item?.room_number || "",
      table_number: item?.table_number || "",
      bill_number: item?.bill_number || "",
      attachment: item?.attachment || "",
    });
    if (item) {
      const data = {
        business_id: business_id,
        branch_id: item?.location,
        department_id: item?.department,
      };
      return axios_instance
        .get(URLS.AGENCY_USERS, { params: data })
        .then((res) => {
          setTotalTeamMember(res.data.user_list);
        });
    }
  }, [item]);

  useEffect(() => {
    if (item?.assignees) {
      return axios_instance
        .get(`${utils.format(URLS.TEAM, [business_id])}${item?.assignees}/`)
        .then((res) => {
          setEditLocationList(res?.data?.location);
        });
    }
  }, [item?.assignees]);

  const handleSelectionChange = (object) => {
    if (object === null) {
      setTotalTeamMember([]);
      return;
    }
    const sanitizePayload = removeNullValues(object);
    if (isNotEmpty(sanitizePayload)) {
      const payload = {
        business_id: business_id,
        ...sanitizePayload,
      };

      return axios_instance
        .get(URLS.AGENCY_USERS, { params: payload })
        .then((res) => {
          setTotalTeamMember(res.data.user_list);
        });
    } else {
      setTotalTeamMember([]);
    }
  };

  const requestTypeOption = ticketRequestTypeList?.map((item) => ({
    value: item.id,
    display_name: item.title,
  }));

  const departmentOption = departmentList?.map((item) => ({
    value: item.id,
    display_name: item.name,
  }));

  const priorityData = [
    { display_name: "Low", value: "low" },
    { display_name: "Medium", value: "medium" },
    { display_name: "High", value: "high" },
    { display_name: "Urgent", value: "urgent" },
  ];

  const getPriorityColor = (status) => {
    const statusColor = {
      low: "#858d9d",
      medium: "#FDB022",
      high: "#EF6820",
      urgent: "#D92D20",
    };
    return statusColor[status];
  };
  const getPriorityBorderColor = (status) => {
    const statusColor = {
      low: "#858d9d",
      medium: "#feb130",
      high: "#f16824",
      urgent: "#db2c1f",
    };
    return statusColor[status];
  };
  const getPriorityBackGroundColor = (status) => {
    const statusColor = {
      low: "#d1d1d1",
      medium: "#fdeed3",
      high: "#fbe4da",
      urgent: "#FEE4E2",
    };
    return statusColor[status];
  };

  const request_options = [
    { value: "true", display_name: "Request" },
    { value: "false", display_name: "Complaint" },
  ];

  const handleCloseTicket = (status) => {
    const data = {
      status: status,
    };
    editService(data, item.id);
  };

  const getStatusColor = (status) => {
    const statusColor = {
      in_queue: "#5826ef",
      assigned: "#f8ae00",
      resolved: "#17b26a",
      reopened: "#e16b16",
      waiting_for_customer: "#FFDC54",
      in_progress: "#0096FF",
    };
    return statusColor[status];
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const uniqueCreatedBy = {};

  item &&
    item?.service_request_comment?.length &&
    item?.service_request_comment?.map((comment) => {
      uniqueCreatedBy[comment?.created_by] = true;
    });

  const uniqueCreatedByArray = Object.keys(uniqueCreatedBy);

  const getColor = (name) => {
    const index = uniqueCreatedByArray.findIndex(
      (commenterName) => commenterName === name
    );
    return lightCommentColor[index + 1] || lightCommentColor[0];
  };

  const statusOptions = [
    {
      label: "In Queue",
      value: "in_queue",
      onClick: () => handleCloseTicket("in_queue"),
      startIcon: <FPBadge color="#5826ef" size="11" />,
    },
    {
      label: "Assigned",
      value: "assigned",
      onClick: () => handleCloseTicket("assigned"),
      startIcon: <FPBadge color="#f8ae00" size="11" />,
    },
    {
      label: "Waiting for Customer",
      value: "waiting_for_customer",
      onClick: () => handleCloseTicket("waiting_for_customer"),
      startIcon: <FPBadge color="#FFDC54" size="11" />,
    },
    {
      label: "In Progress",
      value: "in_progress",
      onClick: () => handleCloseTicket("in_progress"),
      startIcon: <FPBadge color="#0096FF" size="11" />,
    },
  ];

  return (
    <>
      <Formik
        initialValues={initialData}
        onSubmit={(values) => {
          if (allUploads.length > 0) {
            values.attachment = allUploads;
          }
          if (item) {
            editService(values, item.id);
          } else {
            createService(values);
          }
        }}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ setFieldValue, values, errors, touched }) => {
          return (
            <Form>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "70%", paddingRight: "20px" }}>
                  <div
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "16px",
                      padding: "15px",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    {item ? (
                      <div className="d-flex justify-content-between align-items-center mb-2">
                        <div className="d-flex align-items-center gap-6">
                          <img src={ServiceActivity} />
                          <span
                            style={{
                              fontSize: "17px",
                              color: "#646565",
                              fontWeight: "600",
                            }}
                          >{`#SR${item.service_request_id
                            .toString()
                            .padStart(2, "0")}`}</span>
                          <div className="d-flex align-items-center gap-15">
                            {(item && item.status === "assigned") ||
                            item.status === "waiting_for_customer" ||
                            item.status === "in_queue" ||
                            item.status === "in_progress" ? (
                              <FPMenuBox
                                title={snakeToCapital(item.status)}
                                options={statusOptions}
                                showDivider={false}
                                className="assign-box"
                                showSelected={true}
                                isLeft={true}
                                selectedValue={item.status}
                                startIcon={
                                  <FPBadge
                                    color={getStatusColor(item.status)}
                                    size="11"
                                  />
                                }
                              />
                            ) : (
                              <FPChip
                                size="small"
                                variant="outlined"
                                className="assign-box-fp-chip"
                                label={
                                  <div className="d-flex align-items-center justify-content-center">
                                    <FPBadge
                                      color={getStatusColor(item.status)}
                                      size="11"
                                    />
                                    &nbsp;&nbsp;
                                    <span style={{ fontSize: "12px" }}>
                                      {snakeToCapital(item.status)}
                                    </span>
                                  </div>
                                }
                              />
                            )}
                          </div>
                        </div>
                        <FPToolTip
                          title={
                            item && item.status === "resolved"
                              ? "Reopen"
                              : "Close"
                          }
                        >
                          <img
                            src={
                              item && item.status === "resolved"
                                ? SrReopen
                                : SrClose
                            }
                            alt="SR resolved icons"
                            onClick={() =>
                              handleCloseTicket(
                                item && item.status === "resolved"
                                  ? "reopened"
                                  : "resolved"
                              )
                            }
                            style={{
                              cursor: formValueDisabled
                                ? "not-allowed"
                                : "pointer",
                              opacity: formValueDisabled ? 0.5 : 1,
                            }}
                          />
                        </FPToolTip>
                      </div>
                    ) : (
                      <div style={{ fontSize: "17px", color: "#646565" }}>
                        Service Request Details
                      </div>
                    )}
                    <Divider className="w-100 mt-2" />
                    <FPRadioGroup
                      name="is_request"
                      options={request_options}
                      defaultValue={values.is_request}
                      onChange={(event) => {
                        setFieldValue("is_request", event.target.value);
                      }}
                      disabled={formValueDisabled}
                    />
                    {errors.is_request && touched.is_request && (
                      <ErrorMessage className="mt-0">
                        {errors.is_request}
                      </ErrorMessage>
                    )}

                    <div className="d-flex justify-content-between">
                      <FPLabel className="mt-10">Request Type</FPLabel>
                      <span
                        className={classNames({
                          "fp-disabled":
                            item &&
                            (item.status === "resolved" || formValueDisabled),
                        })}
                        style={{
                          color: "#F13A88",
                          fontSize: "13px",
                          cursor: "pointer",
                          marginTop: "10px",
                        }}
                        onClick={() => setOpen(true)}
                      >
                        Manage Request Type
                      </span>
                    </div>
                    <FormAutoComplete
                      placeholder="Request Type"
                      options={requestTypeOption}
                      name="service_request_type"
                      sx={{
                        "& .MuiInputBase-input": {
                          height: 15,
                          fontSize: "12px",
                          color: "#646565 !important",
                          "&::placeholder": {
                            color: "#646565",
                          },
                        },
                      }}
                      disabled={formValueDisabled}
                    />
                    {errors.service_request_type &&
                      touched.service_request_type && (
                        <ErrorMessage className="mt-0">
                          {errors.service_request_type}
                        </ErrorMessage>
                      )}
                    <FPLabel className="mt-15" isRequired={false}>
                      Request Details
                    </FPLabel>
                    <FormTextArea
                      name="description"
                      placeholder="Request Details"
                      sx={{
                        "& .fp-textarea": {
                          fontSize: "12px",
                          color: "#646565",
                        },
                      }}
                      disabled={formValueDisabled}
                    />
                    <div className="d-flex justify-content-between">
                      <div className="w-100">
                        <FPLabel isRequired={true}>Priority</FPLabel>
                        <div
                          className="d-flex align-items-center gap-10"
                          style={{ height: "27px" }}
                        >
                          {priorityData?.map((item) => {
                            const isSelected = values.priority === item.value;
                            return (
                              <FPChip
                                key={item.value}
                                onClick={() =>
                                  setFieldValue("priority", item.value)
                                }
                                size="small"
                                variant="outlined"
                                style={{
                                  height: "27px",
                                  borderColor: isSelected
                                    ? getPriorityBorderColor(item.value)
                                    : "#BFC2CB",
                                  backgroundColor: isSelected
                                    ? getPriorityBackGroundColor(item.value)
                                    : "transparent",
                                }}
                                label={
                                  <div className="d-flex align-items-center justify-content-center">
                                    <FPBadge
                                      color={getPriorityColor(item.value)}
                                      size="11"
                                    />
                                    &nbsp;&nbsp;
                                    <span
                                      style={{
                                        color: "#1B1C1D",
                                        fontWeight: "400",
                                        fontSize: "13px",
                                      }}
                                    >
                                      {item.display_name}
                                    </span>
                                  </div>
                                }
                                disabled={formValueDisabled}
                              />
                            );
                          })}
                        </div>
                        {errors.priority && touched.priority && (
                          <ErrorMessage className="mt-1">
                            {errors.priority}
                          </ErrorMessage>
                        )}
                      </div>
                      {/* <FPLabel className="mt-3" isRequired={false}>
                      Attachment (photo, audio, video)
                    </FPLabel> */}
                      <UploadComponent
                        name="mediaAttachment"
                        type="image"
                        accept=".jpg, .jpeg, .png, .gif, .mp4, .mp3"
                        multiple={true}
                        placeholder="Upload Media"
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        attachments={attachments}
                        setAttachments={setAttachments}
                        files={files}
                        setFiles={setFiles}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "16px",
                      padding: "15px",
                      marginTop: "20px",
                      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div style={{ fontSize: "17px" }}>Assignment</div>
                    <Divider className="w-100 mt-2" />
                    <div style={{ display: "flex", gap: "20px" }}>
                      <div style={{ flex: 1 }}>
                        <FPLabel isRequired={true}>Location</FPLabel>
                        <FormAutoComplete
                          name={"location"}
                          placeholder="Location"
                          options={
                            item?.assignees ? editLocationList : locationList
                          }
                          sx={{
                            "& .MuiInputBase-input": {
                              height: 15,
                              fontSize: "12px",
                              color: "#646565 !important",
                              "&::placeholder": {
                                color: "#646565",
                              },
                            },
                          }}
                          onChange={(event, option) => {
                            setFieldValue("assignees", "");
                            const payload = {
                              branch_id: option,
                              department_id: values.department,
                            };
                            handleSelectionChange(payload);
                          }}
                          disabled={formValueDisabled}
                        />
                        {errors.location && touched.location && (
                          <ErrorMessage className="mt-0">
                            {errors.location}
                          </ErrorMessage>
                        )}
                      </div>
                      <div style={{ flex: 1 }}>
                        <FPLabel isRequired={false}>Department</FPLabel>
                        <FormAutoComplete
                          name={"department"}
                          placeholder="Department"
                          options={departmentOption}
                          sx={{
                            "& .MuiInputBase-input": {
                              height: 15,
                              fontSize: "12px",
                              color: "#646565 !important",
                              "&::placeholder": {
                                color: "#646565",
                              },
                            },
                          }}
                          disabled={formValueDisabled}
                          onChange={(event, option) => {
                            setFieldValue("assignees", "");
                            handleSelectionChange(option, values);
                            const payload = {
                              branch_id: values.location,
                              department_id: option,
                            };
                            handleSelectionChange(payload);
                          }}
                        />
                      </div>
                      <div style={{ flex: 1 }}>
                        <FPLabel isRequired={true}>Assign To</FPLabel>
                        <FormAutoComplete
                          name={"assignees"}
                          placeholder="Assign To"
                          options={
                            totalTeamMember &&
                            totalTeamMember.map((item) => ({
                              display_name: `${item.first_name} ${item.last_name}`,
                              value: item.id,
                            }))
                          }
                          sx={{
                            "& .MuiInputBase-input": {
                              height: 15,
                              fontSize: "12px",
                              color: "#646565 !important",
                              "&::placeholder": {
                                color: "#646565",
                              },
                            },
                          }}
                          disabled={formValueDisabled}
                        />
                        {errors.assignees && touched.assignees && (
                          <ErrorMessage className="mt-0">
                            {errors.assignees}
                          </ErrorMessage>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <Box
                  sx={{
                    width: "30%",
                    backgroundColor: "#FFFFFF",
                    borderRadius: "16px",
                    padding: "0px 15px",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div style={{ fontSize: "17px", paddingTop: "15px" }}>
                    <span>Customer Details</span>{" "}
                    <span style={{ color: "#00B5AD" }}>(Optional)</span>
                  </div>
                  <Divider className="w-100 mt-2" />
                  <FPLabel isRequired={false}>Customer Name</FPLabel>
                  <FormInput
                    name="customer_name"
                    placeholder="Customer Name"
                    size="small"
                    type="text"
                    disabled={formValueDisabled}
                  />

                  <FPLabel isRequired={false}>Mobile Number</FPLabel>
                  <FormInput
                    name="customer_mobile"
                    placeholder="Mobile Number"
                    size="small"
                    type="text"
                    disabled={formValueDisabled}
                  />

                  <FPLabel isRequired={false}>Email</FPLabel>
                  <FormInput
                    name="customer_email"
                    placeholder="Email"
                    size="small"
                    type="email"
                    disabled={formValueDisabled}
                  />

                  <FPLabel isRequired={false}>Room No</FPLabel>
                  <FormInput
                    name="room_number"
                    placeholder="Room No."
                    size="small"
                    type="number"
                    disabled={formValueDisabled}
                  />

                  <FPLabel isRequired={false}>Table No</FPLabel>
                  <FormInput
                    name="table_number"
                    placeholder="Table No."
                    size="small"
                    type="text"
                    disabled={formValueDisabled}
                  />

                  <FPLabel isRequired={false}>Bill No</FPLabel>
                  <FormInput
                    name="bill_number"
                    placeholder="Bill No."
                    size="small"
                    type="text"
                    wrapperClass="mb-0"
                    disabled={formValueDisabled}
                  />
                </Box>
              </div>
              <div style={{ marginTop: "20px", textAlign: "right" }}>
                <FPButton
                  label={item ? "Save Changes" : "Submit"}
                  type="submit"
                  size="small"
                  disabled={
                    item && (item.status === "resolved" || formValueDisabled)
                  }
                />
              </div>
            </Form>
          );
        }}
      </Formik>
      <FormModal
        isOpen={open}
        toggle={CloseToggle}
        heading="Manage Request Type"
        showDivider={true}
        width={"800px"}
        headerClass="d-flex align-items-center"
        paddingDense={true}
      >
        <ManageRequestType
          requestTypeOption={ticketRequestTypeList}
          actions={actions}
          match={match}
        />
      </FormModal>

      <FormModal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        footer={null}
        width="1000px"
        height="80%"
        paddingDense={true}
        showCloseBtn={false}
        bodyClass={"p-0"}
        contentClass={"m-0 h-100"}
      >
        <MediaViewer mediaList={attachments} />
      </FormModal>

      <FormModal
        isOpen={openComment}
        toggle={() => setOpenComment(false)}
        footer={null}
        width="1000px"
        height="80%"
        paddingDense={true}
        showCloseBtn={false}
        bodyClass={"p-0"}
        contentClass={"m-0 h-100"}
      >
        <MediaViewer mediaList={fileAttachments} />
      </FormModal>

      {item && (
        <div>
          <div className="radio-group">
            <input
              type="radio"
              id="comment"
              name="viewOption"
              value="comment"
              checked={selectedOption === "comment"}
              onChange={() => setSelectedOption("comment")}
            />
            <label htmlFor="comment">Comment</label>

            <input
              type="radio"
              id="activityLog"
              name="viewOption"
              value="activityLog"
              checked={selectedOption === "activityLog"}
              onChange={() => setSelectedOption("activityLog")}
            />
            <label htmlFor="activityLog">View Activity Log</label>
          </div>

          {selectedOption === "comment" && (
            <SrCommentComponents
              item={item}
              handleAddComment={handleAddComment}
              handleCommentChange={handleCommentChange}
              handleFileChange={handleFileChange}
              comment={comment}
              selectedFile={selectedFile}
              getColor={getColor}
              setOpenComment={setOpenComment}
              setFileAttachments={setFileAttachments}
              setSelectedFile={setSelectedFile} 
              attachments={fileAttachments}
            />
          )}

          {selectedOption === "activityLog" && (
            <div style={{border: "1px solid #BFC2CB",borderRadius: "16px",marginTop: "15px", padding: "15px"}}>
              <ViewActivityLog logs={item?.service_request_logs}/>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CreateServiceRequest;
