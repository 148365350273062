import axios_instance from '../../libs/interseptor';
import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';
import * as utils from '../../libs/utils';

import * as commonAction from '../common/commonAction';

export function genericTemplatesSuccess(data) {
  return { type: types.GENERIC_TEMPLATES_LIST_SUCCSESS, data};
}

export function genericTemplatesFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GENERIC_TEMPLATES_LIST_FAILURE, data };
}

export const genericTemplatesList = (parameters) => {
  const { branch_id, ...params } = parameters;
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.GENERIC_TEMPLATES_LIST,[branch_id]), { params })
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(genericTemplatesSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(genericTemplatesFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getGenericTemplateDetailsSuccess(data) {
  return { type: types.GET_GENERIC_TEMPLATE_DETAILS_SUCCESS, data };
}

export function getGenericTemplateDetailsFaiure(data) {
  return { type: types.GET_GENERIC_TEMPLATE_DETAILS_FAILURE, data };
}

export const getGenericTemplateDetails = (parameters) => {
  const { branch_id, id } = parameters;
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.GENERIC_TEMPLATE_DETAILS,[branch_id,id]))
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getGenericTemplateDetailsSuccess(res?.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getGenericTemplateDetailsFaiure(err));
          throw err?.response?.data;
        }
      );
  };
};

export function postGenericTemplateSuccess(data) {
  return { type: types.POST_GENERIC_TEMPLATE_SUCCESS, data };
}

export function postGenericTemplateFailure(data) {
  return { type: types.POST_GENERIC_TEMPLATE_FAILURE, data };
}

export const postGenericTemplate = (data, newData, branch_id) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    if (newData.id) {
      return axios_instance.patch(utils.format(URLS.GENERIC_TEMPLATE_DETAILS,[branch_id,newData.id]), data,{
        headers: {
          'accept': 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(
          (res) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postGenericTemplateSuccess(res.data));
            return res;
          }
        )
        .catch(
          (err) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postGenericTemplateFailure(err));
            throw err.response.data;
          }
        );
    } else {
      return axios_instance.post(utils.format(URLS.GENERIC_TEMPLATES_LIST,[branch_id]), data,{
        headers: {
          'accept': 'application/json',
          'Accept-Language': 'en-US,en;q=0.8',
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(
          (res) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postGenericTemplateSuccess(res.data));
            return res;
          }
        )
        .catch(
          (err) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postGenericTemplateFailure(err));
            throw err.response.data;
          }
        );
    }
  };
};

export function genericTemplateDetailSuccess(data) {
  return { type: types.GENERIC_TEMPLATE_DELETE_SUCCESS, data};
}

export function genericTemplateDetailFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GENERIC_TEMPLATE_DELETE_FAILURE, data };
}

export const genericTemplateDelete = (data, branch_id) => {

  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.delete(utils.format(URLS.GENERIC_TEMPLATE_DETAILS,[branch_id,data.id]), data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(genericTemplateDetailSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(genericTemplateDetailFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function genericTemplateCloneSuccess(data) {
  return { type: types.GENERIC_TEMPLATE_CLONE_SUCCESS, data};
}

export function genericTemplateCloneFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GENERIC_TEMPLATE_CLONE_FAILURE, data };
}

export const genericTemplateClone = (data, branch_id) => {

  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(utils.format(URLS.GENERIC_TEMPLATE_CLONE,[branch_id,data.id]), data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(genericTemplateCloneSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(genericTemplateCloneFailure(err));
          throw err.response.data;
        }
      );
  };
};


export function genericTemplatesContactsListSuccess(data) {
  return { type: types.GENERIC_TEMPLATES_CONTACTS_SUCCESS, data };
}

export function genericTemplatesContactsListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GENERIC_TEMPLATES_CONTACTS_FAILURE, data };
}

export const genericTemplatesContactsList = (params, branchId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.GENERIC_TEMPLATE_CONTACTS_LIST, [branchId]);
    return axios_instance.get(url, { params })
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(genericTemplatesContactsListSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(genericTemplatesContactsListFailure(err));
          throw err.response.data;
        }
      );
  };
};


export function genericContactDeleteSuccess(data) {
  return { type: types.GENERIC_CONTACT_DELETE_SUCCESS, data};
}

export function genericContactDeleteFailure(data) {
  return { type: types.GENERIC_CONTACT_DELETE_FAILURE, data };
}

export const genericContactDelete = (branch_id,id) => {
  const url = utils.format(URLS.GENERIC_CONTACTS,[branch_id]);
  return (dispatch) => {
    return axios_instance.delete(url+`${id}/`)
      .then(
        (res) => {
          dispatch(genericContactDeleteSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(genericContactDeleteFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getGenericContactDetailsSuccess(data) {
  return { type: types.GET_GENERIC_CONTACT_DETAILS_SUCCESS, data };
}

export function getGenericContactDetailsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_GENERIC_CONTACT_DETAILS_FAILURE, data };
}

export const getGenericContactDetails = (branchId, data) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.GENERIC_CONTACTS, [branchId]);
    return axios_instance.get(url + `${data.id}/`, data)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(getGenericContactDetailsSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(getGenericContactDetailsFailure(err));
          throw err.response.data;
        }
      );
  };
};


export function genericContactEditSuccess(data) {
  return { type: types.GENERIC_CONTACT_EDIT_SUCCESS, data };
}

export function genericContactEditFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GENERIC_CONTACT_EDIT_FAILURE, data };
}

export const genericContactEdit = (branchId, data) => {

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.GENERIC_CONTACTS, [branchId]);
    return axios_instance.put(url + `${data.id}/`, data)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(genericContactEditSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(genericContactEditFailure(err));
          throw err.response.data;
        }
      );
  };
};