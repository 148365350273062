import React from 'react';
import { Row, Col } from 'reactstrap';
import GmbLogo from '../../images/google_business.png';
import axios_instance from '../../libs/interseptor';
import LoaderComponent from '../../components/common/loaderComponent';
import * as SocialSetupActions from '../../actions/business/socialSetupActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as URLS from '../../libs/apiUrls';
import { withRouter } from 'react-router';
import GmbMessagesForm from './GmbMessagesForm';
import FormModal from '../common/FormModal';
import cookie from 'react-cookies';

class SetupGMBMessenger extends React.Component {
  
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.business_id !== prevState.business_id) {
      return { business_id: nextProps.business_id };
    }
    return null;                                                                                                    
  }
  
  constructor(props) {
    super(props);
    this.state = {
      business_id: this.props.business_id,
      btnText: 'Connect GMB Messages',
      name: this.props.gmb_messenger_status ? this.props.gmb_messenger_status.name : '',
      connected: this.props.gmb_messenger_status ? this.props.gmb_messenger_status.connected : false,
      loader: false,
      agentDetailPopup:false
    };
    this.toggleAgentDetailPopup = this.toggleAgentDetailPopup.bind(this);
    this.submitGMBMessagesForm = this.submitGMBMessagesForm.bind(this);
  }

  submitGMBMessagesForm(data) {
    this.setState({loader:true});
    const req_data = {
      'agent_name':data.agentName,
      'agent_id':data.agentId,
      'brand_id':data.brandId,
      'business_id':this.state.business_id
    };
    const authToken = cookie.load('authToken');
    const headers = {
      'Authorization': `Bearer ${authToken}`
    };

    return axios_instance.post(`${URLS.MESSENGER}gmb-setup/`, req_data, {headers: headers})
      .then(
        (res) => {
          if(res.data.connected === true){
            this.setState({loader:false, connected:true,initial_check:true,name:res.data.name,btnText:'Log Out', business_id:this.state.business_id, agentDetailPopup:false});
            this.props.callSocialProfileApi();
            this.props.handleConnectProfile();
          }else{
            this.setState({initial_check:true, loader:false, agentDetailPopup:false});
          }
        }
      )
      .catch(
        (err) => {
          this.setState({loader:false});
          throw err.response.data;
        }
      );
  }

  toggleAgentDetailPopup() {
    const { agentDetailPopup } = this.state;
    this.setState({ agentDetailPopup: !agentDetailPopup });
  }

  logOut() {
    const { match: { params: { business_id } } } = this.props;
    const authToken = cookie.load('authToken');
    const headers = {
      'Authorization': `Bearer ${authToken}`
    };

    return axios_instance.get(`${URLS.MESSENGER}gmb-logout/?business_id=${business_id}`, {headers: headers})
      .then(
        (res) => {
          if(res.data.connected === false){
            this.setState({connected:false,initial_check:true,name:'',btnText:'Connect to GMB Messages', business_id:business_id});
          }          
        }
      )
      .catch(
        (err) => {
          throw err.response.data;
        }
      );
  }

  render() {
    const { connectedStatus, toggleTab } = this.props;
    const { loader } = this.state;
    return (
      <div>
        <div  className="my-2">
          <Row>
            <LoaderComponent loader = {loader}/>
            <Col>
              <div className="text-center mb-3">
                <span 
                  className="text-center google-button cursor-pointer" 
                  style={{ display: 'flex', gap: '10px', borderRadius: '25px', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}
                  onClick={() => this.toggleAgentDetailPopup()}
                >
                  <div style={{ background: 'white', borderRadius: '4px'}}>
                    <img src={GmbLogo} alt='Playstore Icon' height="25"  style={{ padding: '4px'}}/>
                  </div>
                  <span>Connect GMB Messages</span>
                </span>
                <div className='mt-2' onClick={toggleTab} style={{ textAlign: 'center', fontWeight: '600', textDecoration: 'underline', fontSize: '14px', cursor: 'pointer' }}>
                  {connectedStatus ? connectedStatus : this.state.connected ? 1 : 0} profile already connected
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {this.state.agentDetailPopup &&
          <FormModal
            isOpen={this.state.agentDetailPopup}
            toggle={this.toggleAgentDetailPopup}
            heading='Set Google Business Messages'
            size="lg"
          >
            <GmbMessagesForm
              submitGmbMessagesForm={this.submitGMBMessagesForm}
            />
          </FormModal>}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    business: state.business,
    socialSetupList: state.socialSetup.list,
    isLoggedIn: state.socialSetup.isLoggedIn,
    branchList : state.business.branch_list
  };
};

function mapDispatchToProps(dispatch) {
  return {
    SocialSetupActions: bindActionCreators(SocialSetupActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SetupGMBMessenger));