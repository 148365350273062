import React from "react";
import { Row, Col } from "reactstrap";
import queryString from "query-string";
import moment from "moment/moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as genericTemplatesActions from "../../../actions/genericTemplates/genericTemplatesActions";

import GenericTemplateForm from "../../../components/campaign/genericTemplate/GenericTemplateForm";
import apiUrls from "../../../libs/apiUrls";
import axios_instance from "../../../libs/interseptor";

class GenericTemlateEditPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submitData = this.submitData.bind(this);
    this.state = {
      provider: null,
    };
  }

  getProvider(param) {
    const { reviewChannels, ...params } = param;
    const url = reviewChannels
      ? `${apiUrls.REVIEW_SOURCE}?${reviewChannels}`
      : `${apiUrls.REVIEW_SOURCE}`;
    axios_instance
      .get(url, { params })
      .then((res) => {
        this.setState({ provider: res.data });
        return res;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  submitData(data) {
    const {
      match: {
        params: { branch_id, business_id },
      },
    } = this.props;
    data = { ...data, branch: branch_id };
    let newData = new FormData();
    Object.keys(data).map((key) => {
      if (
        key === "background_image" &&
        typeof data[key] != "string" &&
        data[key] !== null
      )
        newData.append(key, data[key], data[key] && data[key].filename);
      else if (
        key === "background_image" &&
        data["background_image"] === null
      ) {
        return true;
      } else newData.append(key, data[key]);
    });
    return this.props.actions.postGenericTemplate(newData, data, business_id);
  }

  componentDidMount() {
    // TO DO
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const {
      route: {
        location: { search },
      },
    } = this.context.router;
    const { id } = queryString.parse(search);
    const reviewChannels = "review_source=review_channels";
    if (id)
      this.props.actions.getGenericTemplateDetails({
        branch_id: business_id,
        id,
      });
    this.getProvider({ reviewChannels, business_id });
  }

  render() {
    const {
      route: {
        location: { search },
      },
    } = this.context.router;
    const { id } = queryString.parse(search);
    const {
      genericTemplate: { detail },
    } = this.props;
    const { provider } = this.state;
    const { type } = queryString.parse(search);    
    const newData = {
      name:
        `Review Request${type === "sms" ? "SMS" : "Email"}` +
        moment().format("_DD-MM(HH:mm:ss)"),
      heading: "How was your experience?",
      message:
        "Thank you for choosing us! Please take a moment to leave us a review. Your responses will help us serve you better in the future.",
      symbol_type: "thumb",
      thanks_line:
        "Hey {customer name}, \nThank you for your valuable time and feedback.",
      consent_text: "By Checking this box, you agree to the brand T&Cs and agree to receive marketing communications from the brand."
    };
    if (id && +id !== (detail && detail.id)) return null;
    let new_detail = { ...detail };
    if (detail && detail.thanks_line === "null") {
      new_detail.thanks_line = "";
    }
    if (detail && detail.landing_page_heading === "null") {
      new_detail.landing_page_heading = "";
    }
    if (detail && detail.body === "null") {
      new_detail.body = "";
    }
    const formData = id ? new_detail : newData;
    const nameDisabled = id ? true : false;
    return (
      <Row className="content-area">
        <Col className="business-setup p-3">
          <GenericTemplateForm
            surveyPages={
              id ? [...this.props.genericTemplate.detail.survey_pages] : ""
            }
            surveySectionActivation={
              id ? this.props.genericTemplate.detail.survey_section : ""
            }
            surveySectionActivationNegative={
              id
                ? this.props.genericTemplate.detail.survey_section_negative
                : ""
            }
            initialValues={formData}
            submitData={this.submitData}
            nameDisabled={nameDisabled}
            reviewChannels={provider && provider.choices}
            location={this.props.location}
            businessLogo={
              this.props.genericTemplate.detail !== undefined
                ? id ? this.props.genericTemplate.detail.business.logo : ""
                : ""
            }
            businessBg={
              this.props.genericTemplate.detail !== undefined
                ? this.props.genericTemplate.detail.background_image
                : ""
            }
          />
        </Col>
      </Row>
    );
  }
}

GenericTemlateEditPage.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  return {
    genericTemplate: state.genericTemplate,
    loader: state.common.loader,
    // provider: state.review.review_source_options
  };
};

GenericTemlateEditPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  template: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(genericTemplatesActions, dispatch),
    // reviewActions: bindActionCreators(ReviewsActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(GenericTemlateEditPage);
