import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import EmailInboxList from "./EmailInboxList";
import EmailAnswer from "./EmailAnswer";
import "./ReviewEmailStyle.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as reviewEmailList from "../../actions/email/reviewEmailActions";
import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import queryString from "query-string";
import LoaderComponent from "../../components/common/loaderComponent";
import { NoData } from "../../components/common/new";
import PaginationComponent from "../../components/common/Pagination";
import * as TeamAction from "../../actions/team/teamAction";
import * as ticketActions from "../../actions/ticket/ticketActions";

function EmailComponent(props) {
  const {
    history,
    location,
    match: {
      params: { business_id },
    },
    loader,
    match,
    emailListActions,
    emailList,
    emailDetails,
    emailListCount,
    email,
    businessActions, branchOptions, teamActions, ticketActions
  } = props;
  const { pathname, search } = location;
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    document.title = "ReviewEmail";
    const payload = queryString.parse(search);
    emailListActions.ReviewEmailListing(payload, business_id);
    businessActions.businessBranchOptions({ business: business_id }, true);
    if (emailList?.length > 0) {
      setIsLoader(true);
      const thread_id = emailList[0]?.thread_id;
      emailListActions
        .ReviewEmailDetails(thread_id, business_id)
        .then(() => setIsLoader(false))
        .catch(() => setIsLoader(false));
    } 
  }, [search]);

  useEffect(() => {
    if (emailList?.length > 0) {
      setIsLoader(true);
      const thread_id = emailList[selectedIndex]?.thread_id;
      setTimeout(() => {
        emailListActions
          .ReviewEmailDetails(thread_id, business_id)
          .then(() => setIsLoader(false))
          .catch(() => setIsLoader(false));
      }, 1500)
    }
  }, [selectedIndex, emailList]);

  const setIndex = (email, index) => {
    if (index >= 0 && index < emailList.length) {
      setSelectedIndex(index);
    }
  };

  return (
    <>
      {loader ? (
        <LoaderComponent loader={loader} />
      ) : emailList && emailList.length !== 0 ? (
        <Row className="mt-3  ">
          <Col xs={12} sm={12} className="business-setup mb-1">
            <Row className="m-1">
              <Col sm="4" className="email_left_side_bar">
                <EmailInboxList
                  list={emailList}
                  setSelectedIndex={setSelectedIndex}
                  selectedIndex={selectedIndex}
                  props={props}
                  setIndex={setIndex}
                />
              </Col>
              <Col sm="8" className="email_right_side_bar ms-2">
                {/* <div className="right-sidebar-content"> */}
                  <EmailAnswer
                    selectedEmail={emailDetails}
                    totalEmailCount={emailListCount}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    isLoader={isLoader}
                    emailListActions={emailListActions}
                    emailList={emailList}
                    setIsLoader={setIsLoader}
                    search={search}
                    branchOptions={branchOptions}
                    teamActions={teamActions}
                    match={match}
                    ticketActions={ticketActions}
                  />
                {/* </div> */}
              </Col>
            </Row>
            {emailList && emailListCount > 0 && (
              <Row>
                <Col sm="12">
                  <PaginationComponent
                    count={emailList && emailListCount}
                    location={location}
                    pageSizeCookie="review_email_page"
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      ) : (
        <Row className="mb-3 d-flex justify-content-center align-items-center" style={{ minHeight: "85vh" }}>
          <NoData />
        </Row>

      )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    emailList: state?.reviewEmailList?.reviewEmailList?.results,
    emailListCount: state?.reviewEmailList?.reviewEmailList?.count,
    emailDetails: state?.reviewEmailList?.reviewEmailDetails,
    email: state?.reviewEmailList?.reviewEmailList,
    loader: state.common.loader,
    branchOptions: state.business.branches,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    emailListActions: bindActionCreators(reviewEmailList, dispatch),
    businessActions: bindActionCreators(BusinessSetupActions, dispatch),
    teamActions: bindActionCreators(TeamAction, dispatch),
    ticketActions: bindActionCreators(ticketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(EmailComponent);
