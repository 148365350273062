import React from "react";

const FaceFour = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 16C31 24.2843 24.2843 31 16 31C7.71574 31 1 24.2843 1 16C1 7.71573 7.71574 1 16 1C24.2843 1 31 7.71572 31 16Z"
        fill="white"
        stroke="#EC554E"
        stroke-width="2"
      />
      <path
        d="M6.70951 9.01064C8.75415 9.9712 11.1021 10.0679 13.219 9.27891C13.61 9.13343 13.7958 8.73843 13.6465 8.43899C13.4968 8.13859 13.1203 8.02149 12.7952 8.1417C10.9839 8.81688 8.97493 8.73405 7.22539 7.91217C6.91127 7.76556 6.52634 7.85136 6.35251 8.1384C6.17893 8.42446 6.33181 8.83351 6.70951 9.01064Z"
        fill="#1B1C1D"
      />
      <path
        d="M9.99112 14.9725C11.2299 14.9725 12.2342 13.9682 12.2342 12.7294C12.2342 11.4906 11.2299 10.4863 9.99112 10.4863C8.7523 10.4863 7.74805 11.4906 7.74805 12.7294C7.74805 13.9682 8.7523 14.9725 9.99112 14.9725Z"
        fill="#1B1C1D"
      />
      <path
        d="M26.0216 9.01064C23.977 9.9712 21.629 10.0679 19.5122 9.27891C19.1212 9.13343 18.9353 8.73843 19.0847 8.43899C19.2343 8.13859 19.6108 8.02149 19.936 8.1417C21.7472 8.81688 23.7562 8.73405 25.5057 7.91217C25.8199 7.76556 26.2048 7.85136 26.3786 8.1384C26.5522 8.42446 26.3993 8.83351 26.0216 9.01064Z"
        fill="#1B1C1D"
      />
      <path
        d="M22.7431 14.9725C23.9819 14.9725 24.9861 13.9682 24.9861 12.7294C24.9861 11.4906 23.9819 10.4863 22.7431 10.4863C21.5043 10.4863 20.5 11.4906 20.5 12.7294C20.5 13.9682 21.5043 14.9725 22.7431 14.9725Z"
        fill="#1B1C1D"
      />
      <path
        d="M23.4476 24.7836C23.4476 21.5291 20.2765 18.8906 16.3651 18.8906C12.4537 18.8906 9.2832 21.5291 9.2832 24.7836H23.4476Z"
        fill="#1B1C1D"
      />
    </svg>
  );
};
export default FaceFour;
