import React from "react";
import { Box, TextareaAutosize, alpha, makeStyles } from "@material-ui/core";
import FPLabel from "./FPLabel";

const FPTextArea = ({
  defaultValue,
  label,
  isRequired,
  placeholder,
  error,
  maxRows,
  minRows,
  fullWidth,
  sx,
  padding,
  ...rest
}) => {
  return (
    <Box
      sx={{
        padding: `${padding ? padding : "10px 12px"}`,
        border: "1px solid #BFC2CB",
        borderColor: ({ error }) => (error ? "#f44336" : "#BFC2CB"),
        transition:
          " border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        "&:focus-within": {
          boxShadow: ({ error }) =>
            `${alpha(error ? "#f44336" : "#008eff", 0.25)} 0 0 0 0.2rem`,
          borderColor: ({ error }) => (error ? "#f44336" : "#008eff"),
        },
        backgroundColor: "#ffffff",
        position: "relative",
        width: "100%",
        borderRadius: "8px",
        "& textarea": {
          overflow: "auto !important",
          padding: '0 !important',
          border: "unset",
          outline: 0,
          fontSize: 16,
          "&::placeholder": {
            opacity: 0.6,
          },
          "::-ms-input-placeholder": {
            opacity: 0.6,
          },
          height: "100%",
          resize: "none",
          width: "100%",
        },
        ...sx,
      }}
    >
      {label && <FPLabel isRequired={isRequired}>{label}</FPLabel>}
      <TextareaAutosize
        minRows={minRows || 4}
        maxRows={maxRows}
        placeholder={placeholder || `Enter ${label}`}
        defaultValue={defaultValue}
        className={`fp-textarea`}
        {...rest}
      />
    </Box>
    // <Box
    //   sx={{
    //     width: fullWidth ? "100%" : "auto",
    //     display: "inline-block",
    //     position: "relative",
    //   }}
    // >
    //   {label && <FPLabel isRequired={isRequired}>{label}</FPLabel>}
    //   <Box
    //     sx={{
    //       boxSizing: "border-box",
    //       borderRadius: "3px",
    //       backgroundColor: "#ffffff",
    //       padding: "8px 2px 8px 10px",
    //       width: fullWidth ? "100%" : "auto",
    //       minHeight: 45,
    //       display: "inline-flex",
    //       alignItems: "center",

    //       "& textarea": {
    //         overflow: "auto !important",
    //         fontSize: "14px",
    //         padding: 0,
    //         paddingRight: 2,
    //         minHeight: "19px",
    //         // lineHeight: '16px',
    //         maxHeight: "52px",
    //         fontFamily: "Open Sans",
    //         color: "#001F38",
    //         resize: "none",
    //         outline: "none",
    //         border: "none",
    //         width: "100%",
    //         fontWeight: "400",
    //         scrollbarGutter: "stable",
    //         borderRadius: "3px",
    //         "&:focus": {
    //           "&::placeholder": {
    //             opacity: 0.75,
    //           },
    //         },
    //         "&:hover": {
    //           "&::placeholder": {
    //             opacity: 0.75,
    //           },
    //         },
    //         "&::placeholder": {
    //           color: "#001f38",
    //           fontWeight: "400",
    //           opacity: 1,
    //           transition: "opacity 0.3s ease",
    //         },
    //       },
    //       ...sx,
    //     }}
    //   >
    //     <TextareaAutosize {...rest} />
    //   </Box>
    // </Box>
  );
};

export default FPTextArea;
