import React, { useState, useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import {
  Button,
  // Form,
  // Segment,
  // Image,
  Checkbox,
  // Icon,
  // Modal,
  Dropdown,
} from "semantic-ui-react";
import { Col, Row } from "reactstrap";
// import ImageUpload from "../../images/Group36.png";
import DatePicker from "react-datepicker";
import moment from "moment";
import MultiSelect from "../../components/common/SelectComponent";
import LoaderComponent from "../../components/common/loaderComponent";
import "../../containers/business/publisher.css";
import fileIcon from "../../images/svg/fileIconWhite.svg";
import FormModal from "../common/FormModal";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { Box } from "@material-ui/core";
import { FPButton, FPToolTip } from "../common/new";
import imgDrag from "../../images/svg/image_drag.svg";
import SearchComponent from "../common/SearchComponent";
import deleteIcon from "../../images/svg/delete_grey.svg";
import calenderLogo from "../../images/svg/calender.svg";

const SocialPostOfferForm = (props) => {
  const {
    setaddPostModal,
    business_id,
    createPostHandler,
    branchTags,
    branchLocationList,
    handleBranchTagFilter,
    applyTagsResponse,
    setapplyTagsResponse,
    setbranchLocationList,
    setOpenAlert,
  } = props;

  const [recurringPost, setrecurringPost] = useState(false);
  const [selectedImage, setselectedImage] = useState(null);
  const [postTitleValue, setpostTitleValue] = useState(null);
  const [postDescValue, setpostDescValue] = useState(null);
  const [eventStartDate, seteventStartDate] = useState(null);
  const [eventEndDate, seteventEndDate] = useState(null);
  const [voucherCode, setvoucherCode] = useState(null);
  const [offerLink, setofferLink] = useState(null);
  const [offerTerms, setofferTerms] = useState(null);
  const [schedulepopup, setschedulepopup] = useState(false);
  const [scheduleDate, setscheduleDate] = useState(null);
  const [scheduleTime, setscheduleTime] = useState(0);
  const [chooseLocationPopup, setchooseLocationPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const [allData, setallData] = useState({});
  const [selectedTags, setselectedTags] = useState([]);
  const [imageUrl, setimageUrl] = useState(null);
  const [selectAll, setselectAll] = useState(false);
  const [selectedLocationCount, setSelectedLocationCount] = useState(null);
  const [openSelectedLocation, setOpenSelectedLocation] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);

  const time_options = [
    { key: "0", text: "0", value: "0" },
    { key: "1", text: "1", value: "1" },
    { key: "2", text: "2", value: "2" },
    { key: "3", text: "3", value: "3" },
    { key: "4", text: "4", value: "4" },
    { key: "5", text: "5", value: "5" },
    { key: "6", text: "6", value: "6" },
    { key: "7", text: "7", value: "7" },
    { key: "8", text: "8", value: "8" },
    { key: "9", text: "9", value: "9" },
    { key: "10", text: "10", value: "10" },
    { key: "11", text: "11", value: "11" },
    { key: "12", text: "12", value: "12" },
    { key: "13", text: "13", value: "13" },
    { key: "14", text: "14", value: "14" },
    { key: "15", text: "15", value: "15" },
    { key: "16", text: "16", value: "16" },
    { key: "17", text: "17", value: "17" },
    { key: "18", text: "18", value: "18" },
    { key: "19", text: "19", value: "19" },
    { key: "20", text: "20", value: "20" },
    { key: "21", text: "21", value: "21" },
    { key: "22", text: "22", value: "22" },
    { key: "23", text: "23", value: "23" },
  ];

  useEffect(() => {
    if (applyTagsResponse) {
      setLoader(false);
      setapplyTagsResponse(false);
    }
  }, [applyTagsResponse]);

  const chooseLocationHandler = () => {
    var error_exist = false;
    const formData = new FormData();
    formData.append("business", business_id);
    formData.append("platform", "google_business");
    formData.append("post_type", "offer");

    if (postTitleValue !== null) {
      formData.append("title", postTitleValue);
      // const title_element = document.getElementById("offer_post_title");
      // title_element.style.borderColor = "#22242626";
      setTitleError(false);
    } else {
      // const title_element = document.getElementById("offer_post_title");
      // title_element.style.borderColor = "red";
      error_exist = true;
      setTitleError(true);
    }
    if (selectedImage !== null) {
      formData.append("image", selectedImage);
    }
    if (postDescValue !== null) {
      formData.append("description", postDescValue);
    }
    if (eventStartDate !== null) {
      formData.append("offer_start_date", eventStartDate);
      // const title_element = document.getElementById("offer_start_date");
      // title_element.style.borderColor = "#22242626";
      setStartDateError(false);
    } else {
      // const title_element = document.getElementById("offer_start_date");
      // title_element.style.borderColor = "red";
      setStartDateError(true);
      error_exist = true;
    }
    if (eventEndDate !== null) {
      formData.append("offer_end_date", eventEndDate);
      // const title_element = document.getElementById("offer_end_date");
      // title_element.style.borderColor = "#22242626";
      setEndDateError(false);
    } else {
      // const title_element = document.getElementById("offer_end_date");
      // title_element.style.borderColor = "red";
      setEndDateError(true);
      error_exist = true;
    }
    if (voucherCode !== null) {
      formData.append("coupon_code_name", voucherCode);
    }
    if (offerLink !== null) {
      formData.append("link_offer", offerLink);
    }
    if (offerTerms !== null) {
      formData.append("terms_condition", offerTerms);
    }
    setallData(formData);
    if (error_exist !== true) {
      setchooseLocationPopup(true);
    }
  };

  const imageHandler = (event) => {
    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onloadend = function () {
      setimageUrl(reader.result);
    };
    setselectedImage(event.target.files[0]);
  };

  const toggleRecurringPost = () => {
    setrecurringPost(!recurringPost);
  };
  const handleScheduleDate = (date) => {
    setscheduleDate(moment(date).format("YYYY-MM-DD"));
  };

  const handleSelectBranchTag = (e, val) => {
    let tagArr;
    if (val.includes(",") && val !== "") {
      tagArr = val.split(",");
    } else if (val !== "") {
      tagArr = [val];
    } else {
      tagArr = [];
    }
    setselectedTags(tagArr);
  };

  const handleEventStartDate = (date) => {
    seteventStartDate(moment(date).format("YYYY-MM-DD"));
  };
  const handleEventEndDate = (date) => {
    seteventEndDate(moment(date).format("YYYY-MM-DD"));
  };
  const applyTags = () => {
    setLoader(true);
    handleBranchTagFilter(selectedTags);
  };

  const handleSelectAll = () => {
    const updateconso = branchLocationList.map((branch) => ({
      ...branch,
      isChecked: !selectAll,
    }));
    setbranchLocationList(updateconso);
    setselectAll(!selectAll);
    if (updateconso.length > 0) {
      setOpenSelectedLocation(!selectAll);
      setSelectedLocationCount(updateconso.length);
    }
  };

  const handleSingleBranchSelect = (item) => {
    const updatecoonso = branchLocationList.map((branch) =>
      branch.id === item ? { ...branch, isChecked: !branch.isChecked } : branch
    );
    const selectLocationCount = updatecoonso.filter(
      (branch) => branch?.isChecked
    );
    setbranchLocationList(updatecoonso);
    for (var i = 0; i < updatecoonso.length; i++) {
      if (!updatecoonso[i].isChecked) {
        setselectAll(false);
        break;
      } else {
        setselectAll(true);
      }
    }
    if (updatecoonso.length > 0) {
      setOpenSelectedLocation(true);
      setSelectedLocationCount(selectLocationCount.length);
    }
  };

  const handlePostSubmit = (status) => {
    var collected_data = allData;
    if (selectAll && selectedTags.length !== 0) {
      collected_data.append("branch_tags", selectedTags);
    } else if (selectAll && selectedTags.length === 0) {
      var branch_array = [];
      branchLocationList.map((item) => {
        branch_array.push(item.id);
      });
      collected_data.append("branches", branch_array);
    } else {
      var selected_branch_array = [];
      branchLocationList.map((item) => {
        if (item.isChecked) {
          selected_branch_array.push(item.id);
        }
      });
      collected_data.append("branches", selected_branch_array);
    }
    collected_data.append("post_status", status);
    if (status === "scheduled") {
      setschedulepopup(true);
      setallData(collected_data);
    } else {
      createPostHandler(collected_data);
      const updateconso = branchLocationList.map((branch) => ({
        ...branch,
        isChecked: false,
      }));
      setbranchLocationList(updateconso);
    }
  };

  const handlePostSchedule = () => {
    var collected_data = allData;
    var final_time = scheduleTime.concat(":00:00");
    collected_data.append("schedule_date", scheduleDate);
    collected_data.append("schedule_time", final_time);
    collected_data.append("scheduled", true);
    createPostHandler(collected_data);
    const updateconso = branchLocationList.map((branch) => ({
      ...branch,
      isChecked: false,
    }));
    setbranchLocationList(updateconso);
    setOpenAlert(true);
  };

  const formatPostData = (status) => {
    var error_exist = false;
    const formData = new FormData();
    formData.append("business", business_id);
    formData.append("post_status", status);
    formData.append("platform", "google_business");
    formData.append("post_type", "offer");

    if (postTitleValue !== null) {
      formData.append("title", postTitleValue);
      // const title_element = document.getElementById("offer_post_title");
      // title_element.style.borderColor = "#22242626";
      setTitleError(false);
    } else {
      // const title_element = document.getElementById("offer_post_title");
      // title_element.style.borderColor = "red";
      setTitleError(true);
      error_exist = true;
    }
    if (selectedImage !== null) {
      formData.append("image", selectedImage);
    }
    if (postDescValue !== null) {
      formData.append("description", postDescValue);
    }
    if (eventStartDate !== null) {
      formData.append("offer_start_date", eventStartDate);
      // const title_element = document.getElementById("offer_start_date");
      // title_element.style.borderColor = "#22242626";
      setStartDateError(false);
    } else {
      // const title_element = document.getElementById("offer_start_date");
      // title_element.style.borderColor = "red";
      setStartDateError(true);
      error_exist = true;
    }
    if (eventEndDate !== null) {
      formData.append("offer_end_date", eventEndDate);
      // const title_element = document.getElementById("offer_end_date");
      // title_element.style.borderColor = "#22242626";
      setEndDateError(false);
    } else {
      // const title_element = document.getElementById("offer_end_date");
      // title_element.style.borderColor = "red";
      setEndDateError(true);
      error_exist = true;
    }
    if (voucherCode !== null) {
      formData.append("coupon_code_name", voucherCode);
    }
    if (offerLink !== null) {
      formData.append("link_offer", offerLink);
    }
    if (offerTerms !== null) {
      formData.append("terms_condition", offerTerms);
    }
    if (status === "draft" && error_exist !== true) {
      createPostHandler(formData);
    }
  };

  const toggleLocationModal = () => {
    setchooseLocationPopup(!chooseLocationPopup);
  };

  const toggleScheduleModel = () => {
    setschedulepopup(!schedulepopup);
  };

  const deleteSelectedImg = () => {
    setselectedImage(null);
    setimageUrl(null);
  };

  return (
    <div className="social_post_new_form">
      <form>
        <label className="imageDragBox">
          <Box sx={{ margin: "6px 0px" }}>
            <img
              src={imgDrag}
              alt="drag"
              // src={selectedImage ? imageUrl : imgDrag}
              // style={{width: selectedImage ?  '100px': 'none',
              //   height: selectedImage ?  '100px' : 'none' }}
              // className={classnames({selectedImage ? '' : ''})}
            />
          </Box>
          <Box sx={{ color: "#7A8193" }}>
            <Box component={"span"} className="text-center">
              <Box
                component={"span"}
                sx={{ color: "#00B5AD", fontWeight: 500 }}
              >
                Click to upload
              </Box>{" "}
              or drag and drop
            </Box>

            <Box component={"span"} className="d-block">
              PNG, JPG (max. 720x720px)
            </Box>
            {/* {selectedImage ? selectedImage.name : "No file selected"} */}
          </Box>
          <input
            id="file"
            hidden
            type="file"
            accept=".jpg, .jpeg, .png, .gif"
            onChange={(event) => {
              imageHandler(event);
            }}
          />
        </label>
        {selectedImage && (
          <div className="my-4 align-center gap-20">
            <div className="selectedImg">
              <img src={imageUrl} alt="drag" />
              <div className="d-flex flex-column">
                <Box
                  component="span"
                  sx={{
                    color: "#363A44",
                    fontSize: "16px",
                    fontWeight: 500,
                    display: "block",
                  }}
                >
                  Img
                </Box>
                <Box
                  component="span"
                  sx={{ color: "#667085", fontSize: "13px" }}
                >
                  150 MB - 100% Uploaded
                </Box>
              </div>
            </div>
            <FPToolTip title={"Delete"}>
              <img
                src={deleteIcon}
                onClick={() => deleteSelectedImg()}
                role="button"
                alt="delete"
              />
            </FPToolTip>
          </div>
        )}
        <Box
          className="align-center gap-15 mt-4 mb-3"
          style={{ fontSize: "14px", color: "#1B1C1D" }}
        >
          Setup as a Recurring Post (Optional)
          <Checkbox
            name="recurring_post"
            style={{ margin: "0px" }}
            toggle
            onChange={() => {
              toggleRecurringPost();
            }}
          />
        </Box>
        <div>
          <div className="my-4">
          <div className="d-flex justify-content-between">
              <FPLabel isRequired={true}>Title</FPLabel>
              {
                postTitleValue?.length > 58 ? 
                <span className="errorMsg">{postTitleValue?.length} / 58</span>
                : 
                <span>{postTitleValue?.length || 0} / 58</span>
              }
            </div>
            <input
              className="input-Field"
              type="text"
              id="offer_post_title"
              name="title"
              placeholder="Title (max 58 characters)"
              value={postTitleValue}
              onChange={(event) => {
                setpostTitleValue(event.target.value);
              }}
            />
            {
              postTitleValue?.length > 58 ? 
              <span className="errorMsg">Title must not exceed 58 characters</span>
              :""
            }
            {titleError && (
              <span className="errorMsg">This field is required</span>
            )}
          </div>
          <div>
            <FPLabel isRequired={false}>Description</FPLabel>
            <textarea
              name="offer_post_desc"
              placeholder="Add post description here"
              rows={4}
              cols={40}
              value={postDescValue}
              onChange={(event) => {
                setpostDescValue(event.target.value);
              }}
              className="textAreaUI"
            />
          </div>
          <div className="col-12 d-flex justify-content-between mt-4 p-0 postDateSelector">
            <div className="col-6 pl-0">
              <FPLabel isRequired={true}>Start Date</FPLabel>
              <div className="position-relative">
                <DatePicker
                  id="offer_start_date"
                  name="event_start_date"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    handleEventStartDate(date);
                  }}
                  className="form-control form-control-sm"
                  type="text"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  value={eventStartDate !== null && eventStartDate}
                  placeholderText={"Start Date"}
                />
                <img
                  src={calenderLogo}
                  alt="calender"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "27%",
                    transform: "translate(-50%)",
                  }}
                />
              </div>
              {startDateError && (
                <span className="errorMsg">This field is required</span>
              )}
            </div>
            <div className="col-6 pr-0">
              <FPLabel isRequired={true}>End Date</FPLabel>
              <div className="position-relative">
                <DatePicker
                  id="offer_end_date"
                  name="event_end_date"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    handleEventEndDate(date);
                  }}
                  className="form-control form-control-sm"
                  type="text"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  value={eventEndDate !== null && eventEndDate}
                  placeholderText={"End Date"}
                />
                <img
                  src={calenderLogo}
                  alt="calender"
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "27%",
                    transform: "translate(-50%)",
                  }}
                />
              </div>
              {endDateError && (
                <span className="errorMsg">This field is required</span>
              )}
            </div>
          </div>
          <div className="my-4">
            <FPLabel isRequired={false}>Voucher Code</FPLabel>
            <input
              className="input-Field"
              type="text"
              placeholder="Voucher Code"
              value={voucherCode}
              onChange={(event) => {
                setvoucherCode(event.target.value);
              }}
            />
          </div>
          <div>
            <FPLabel isRequired={false}>Link to redeem offer</FPLabel>
            <input
              className="input-Field"
              type="text"
              placeholder="Add a Link"
              value={offerLink}
              onChange={(event) => {
                setofferLink(event.target.value);
              }}
            />
          </div>
          <div className="my-4">
            <FPLabel isRequired={false}>Terms & Conditions</FPLabel>
            <textarea
              name=""
              rows={4}
              cols={40}
              value={offerTerms}
              onChange={(event) => {
                setofferTerms(event.target.value);
              }}
              className="textAreaUI"
            />
          </div>

          <div className="align-center justify-content-between mt-4">
            <FPButton
              className=""
              label="Draft"
              size="small"
              onClick={() => {
                formatPostData("draft");
              }}
              backgroundColor="#1B1C1D"
              startIcon={<img src={fileIcon} alt="file" />}
            />
            <div className="d-flex gap-15">
              <FPButton
                size="small"
                label="Cancel"
                variant="outline"
                borderColor="#BFC2CB"
                textColor="#1B1C1D"
                onClick={() => {
                  setaddPostModal(false);
                }}
                className="px-4"
              />
              <FPButton
                className=""
                label="Choose Loactions"
                size="small"
                disabled={postTitleValue?.length > 58}
                onClick={() => {
                  chooseLocationHandler("posted");
                }}
                backgroundColor="#F13A88"
              />
            </div>
          </div>
        </div>

        {/*  */}
        {/* <Segment stacked textAlign="left" className="post_new_form_segment">
          <Row>
            <Col md="4">
              <label style={{ fontSize: "12px", fontWeight: "bold" }}>
                Setup as a Recurring Post (Optional)
              </label>
            </Col>
            <Col md="8">
              <Checkbox
                name="recurring_post"
                style={{ margin: "0px" }}
                toggle
                onChange={() => {
                  toggleRecurringPost();
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <input
                type="file"
                id="offer_post-image"
                onChange={(event) => {
                  imageHandler(event);
                }}
                accept=".png, .jpg, .jpeg"
                style={{ display: "none", visibility: "none" }}
              />
              <label
                htmlFor="offer_post-image"
                title="Click to add"
                style={{ width: "100%" }}
              >
                <Image
                  size="medium"
                  className="image-upload"
                  src={selectedImage ? imageUrl : ImageUpload}
                  wrapped
                />
                {selectedImage ? selectedImage.name : "No file selected"}
              </label>
            </Col>
            <Col md="8">
              <input
                className="mb-4"
                type="text"
                id="offer_post_title"
                name="title"
                placeholder="Title* -Maximum 58 char"
                value={postTitleValue}
                onChange={(event) => {
                  setpostTitleValue(event.target.value);
                }}
              />
              <textarea
                name="offer_post_desc"
                placeholder="Enter your post description here"
                rows={4}
                cols={40}
                value={postDescValue}
                onChange={(event) => {
                  setpostDescValue(event.target.value);
                }}
              />
            </Col>
          </Row>
          <div style={{ marginBottom: "1rem", marginTop: "1rem" }}>
            <h6 style={{ color: "#007bff" }}>Add Event Date</h6>
            <Row>
              <Col md="6">
                <DatePicker
                  id="offer_start_date"
                  name="event_start_date"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    handleEventStartDate(date);
                  }}
                  className="form-control form-control-sm"
                  type="text"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  value={eventStartDate !== null && eventStartDate}
                  placeholderText={"Start Date*"}
                />
              </Col>
              <Col md="6">
                <DatePicker
                  id="offer_end_date"
                  name="event_end_date"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    handleEventEndDate(date);
                  }}
                  className="form-control form-control-sm"
                  type="text"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  value={eventEndDate !== null && eventEndDate}
                  placeholderText={"End Date*"}
                />
              </Col>
            </Row>
          </div>
          <div>
            <Row className="mb-2">
              <Col md="12">
                <input
                  type="text"
                  placeholder="Voucher Code (optional)"
                  value={voucherCode}
                  onChange={(event) => {
                    setvoucherCode(event.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-2">
              <Col md="12">
                <input
                  type="text"
                  placeholder="Link to redeem offer"
                  value={offerLink}
                  onChange={(event) => {
                    setofferLink(event.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <input
                  type="text"
                  placeholder="Terms and Conditions(optional)"
                  value={offerTerms}
                  onChange={(event) => {
                    setofferTerms(event.target.value);
                  }}
                />
              </Col>
            </Row>
          </div>
          <hr />
          <Row>
            <Col md="2">
              <Button
                style={{ float: "left" }}
                onClick={() => {
                  setaddPostModal(false);
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col md="2"></Col>
            <Col md="3">
              <Button
                onClick={() => {
                  formatPostData("draft");
                }}
              >
                <Icon name="sticky note"></Icon>To Draft
              </Button>
            </Col>
            <Col md="5" style={{ textAlign: "end" }}>
              <Button
                className="teal"
                onClick={() => {
                  chooseLocationHandler("posted");
                }}
              >
                <Icon name="map marker alternate"></Icon>Choose Locations
              </Button>
            </Col>
          </Row>
        </Segment> */}
      </form>

      {/* Schedule POPUP */}
      {schedulepopup && (
        <FormModal
          isOpen={schedulepopup}
          toggle={toggleScheduleModel}
          heading="Schedule Date & Time"
          width={"550px"}
          headerClass="align-center"
        >
          <div className="align-center justify-content-between">
            <div>
              <FPLabel>Date</FPLabel>
              <DatePicker
                id="schedule_date"
                name="schedule_date"
                dateFormat="dd/MM/yyyy"
                onChange={(date) => {
                  handleScheduleDate(date);
                }}
                className="form-control form-control-sm"
                type="text"
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
                value={scheduleDate !== null && scheduleDate}
                placeholderText={"Select Date"}
              />
              <img
                src={calenderLogo}
                alt="calender"
                style={{
                  position: "absolute",
                  right: "56%",
                  top: "54%",
                  transform: "translate(-50%)",
                }}
              />
            </div>
            <div>
              <FPLabel>
                Time{" "}
                <Box component="span" sx={{ color: "#7A8193" }}>
                  (24 hr)
                </Box>
              </FPLabel>
              <Dropdown
                name="schedule_time"
                placeholder="Select Time"
                fluid
                selection
                options={time_options}
                value={scheduleTime}
                onChange={(event, data) => {
                  setscheduleTime(data.value);
                }}
              />
            </div>
          </div>
          <div className="d-flex gap-10 float-right mt-3">
            <FPButton
              size="small"
              label="Cancel"
              variant="outline"
              borderColor="#BFC2CB"
              textColor="#1B1C1D"
              onClick={() => setschedulepopup(false)}
              className="px-4"
            />
            <FPButton
              className=""
              label="Submit"
              size="small"
              onClick={handlePostSchedule}
              disabled={scheduleDate === null || scheduleTime === 0}
              backgroundColor="#F13A88"
            />
          </div>
        </FormModal>
      )}

      {/* Location Popup */}
      {chooseLocationPopup && (
        <FormModal
          isOpen={chooseLocationPopup}
          toggle={toggleLocationModal}
          heading="Select Locations"
          width={"650px"}
          headerClass="align-center"
        >
          <div>
            <LoaderComponent loader={loader} />
            <Row>
              <Col md="9">
                <Field
                  name="branch_tag"
                  component={MultiSelect}
                  placeholder="Branch Tag"
                  options={branchTags}
                  val={selectedTags}
                  onChange={handleSelectBranchTag}
                  labelKey="title"
                  valueKey="title"
                  multi
                  simpleValue
                />
              </Col>
              <Col md="3">
                <Button className="teal" onClick={applyTags}>
                  Apply
                </Button>
              </Col>
            </Row>

            <Box className="align-center gap-10 my-3" sx={{ color: "#1B1C1D" }}>
              <input
                type="checkbox"
                name="select_all"
                onChange={handleSelectAll}
                checked={selectAll}
                style={{ color: "#1B1C1D" }}
              />
              <div>
                <Box component="span" sx={{ fontWeight: 500 }}>
                  Locations
                </Box>
                &nbsp;
                {openSelectedLocation && (
                  <Box component="span" sx={{ fontWeight: 500 }}>
                    ({selectedLocationCount})
                  </Box>
                )}
              </div>
            </Box>
            <div className="mb-4">
              <SearchComponent
                // location={location}
                // history={history}
                className="fp-flex-grow-1"
                // isSorting={true}
                placeholder="Search"
              />
            </div>
            <div className="">
              {branchLocationList.map((item, index) => {
                return (
                  <>
                    <Box
                      className="align-center gap-10"
                      sx={{ color: "#1B1C1D", height: "30px" }}
                    >
                      <input
                        type="checkbox"
                        name={`select_location_${item.id}`}
                        onChange={() => {
                          handleSingleBranchSelect(item.id);
                        }}
                        checked={item.isChecked}
                      />

                      <Box component="span">
                        {item.alias !== null
                          ? item.alias
                          : item.location_address}
                      </Box>
                    </Box>
                  </>
                );
              })}
            </div>

            <div className="align-center justify-content-between mt-4">
              <FPButton
                className=""
                label="Schedule"
                size="small"
                onClick={() => {
                  handlePostSubmit("scheduled");
                }}
                backgroundColor="#1B1C1D"
              />
              <div className="d-flex gap-15">
                <FPButton
                  size="small"
                  label="Cancel"
                  variant="outline"
                  borderColor="#BFC2CB"
                  textColor="#1B1C1D"
                  onClick={() => setchooseLocationPopup(false)}
                  className="px-4"
                />
                <FPButton
                  className=""
                  label="Post"
                  size="small"
                  onClick={() => {
                    handlePostSubmit("posted");
                  }}
                  backgroundColor="#F13A88"
                />
              </div>
            </div>
          </div>
        </FormModal>
      )}
    </div>
  );
};

export default reduxForm({
  form: "SocialPostOfferForm",
  enableReinitialize: true,
})(SocialPostOfferForm);
