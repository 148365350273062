import React from 'react';
import { Popup, Icon } from 'semantic-ui-react';

export default class PopupIconComponent extends React.PureComponent {
  constructor(props){
    super(props);
    this.state = {
      iconSize: 'large',
    };
  }

  render(){
    const { iconName, content, onClick } = this.props;
    return (
      <Popup
        onOpen={()=>this.setState({iconSize: 'big'})}
        onClose={()=>this.setState({iconSize: 'large'})}
        trigger={<Icon name={iconName} className="help-icon cursor-pointer" size={this.state.iconSize} onClick={onClick}/>}
        content={content}
      />
    );
  }
}
