import React from "react";

const TemplateCard = ({ template }) => {
  const {
    name,
    date,
    ticketNo,
    greeting,
    message,
    context,
    flow,
    phone,
    hasWhatsApp,
  } = template;

  const getFlowClass = (flowText) => {
    if (flowText.includes("Ticket")) return "ticket";
    if (flowText.includes("Welcome")) return "welcome";
    if (flowText.includes("Not Mapped")) return "not-mapped";
    return "";
  };

  return (
    <div className="template-card">
      <div className="template-header">
        <div className="template-title">
          <h3>{name}</h3>
          <span className="template-date">{date}</span>
        </div>
        {hasWhatsApp && (
          <div className="whatsapp-icon">
            <svg
              width="29"
              height="28"
              viewBox="0 0 29 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.75 28C22.482 28 28.75 21.732 28.75 14C28.75 6.26801 22.482 0 14.75 0C7.01801 0 0.75 6.26801 0.75 14C0.75 21.732 7.01801 28 14.75 28Z"
                fill="#29A71A"
              />
              <path
                d="M20.9211 7.82676C19.4645 6.35565 17.5302 5.45346 15.467 5.28293C13.4038 5.11241 11.3476 5.68477 9.66928 6.89678C7.99093 8.10879 6.80094 9.88066 6.31395 11.8928C5.82695 13.9049 6.07502 16.0248 7.0134 17.8702L6.09226 22.3422C6.0827 22.3867 6.08243 22.4327 6.09147 22.4774C6.1005 22.522 6.11864 22.5642 6.14476 22.6015C6.18302 22.6581 6.23764 22.7017 6.30132 22.7264C6.36499 22.7512 6.4347 22.7559 6.50113 22.7399L10.8841 21.7011C12.7242 22.6157 14.8292 22.8478 16.8244 22.3561C18.8196 21.8644 20.5757 20.6808 21.7801 19.0159C22.9846 17.351 23.5593 15.3127 23.402 13.2638C23.2447 11.215 22.3656 9.28832 20.9211 7.82676ZM19.5545 18.734C18.5467 19.739 17.249 20.4024 15.8442 20.6307C14.4393 20.8591 12.9983 20.6408 11.7241 20.0068L11.1132 19.7045L8.42613 20.3409L8.43408 20.3074L8.9909 17.6029L8.69181 17.0127C8.04074 15.734 7.81107 14.2821 8.0357 12.8649C8.26032 11.4477 8.92771 10.138 9.94226 9.12335C11.2171 7.84894 12.9458 7.13302 14.7484 7.13302C16.551 7.13302 18.2797 7.84894 19.5545 9.12335C19.5654 9.1358 19.5771 9.1475 19.5895 9.15835C20.8485 10.436 21.5514 12.1597 21.5448 13.9534C21.5383 15.7472 20.8228 17.4656 19.5545 18.734Z"
                fill="white"
              />
              <path
                d="M19.3154 16.7495C18.9861 17.2682 18.4659 17.9029 17.812 18.0604C16.6665 18.3372 14.9086 18.07 12.7211 16.0304L12.694 16.0066C10.7706 14.2232 10.2711 12.7388 10.392 11.5616C10.4588 10.8934 11.0156 10.2888 11.4849 9.89429C11.5591 9.83096 11.6471 9.78587 11.7419 9.76263C11.8366 9.73939 11.9355 9.73864 12.0306 9.76044C12.1256 9.78224 12.2143 9.82598 12.2894 9.88818C12.3646 9.95037 12.4242 10.0293 12.4634 10.1186L13.1713 11.7095C13.2173 11.8127 13.2344 11.9264 13.2206 12.0385C13.2069 12.1506 13.1629 12.2569 13.0934 12.3459L12.7354 12.8104C12.6586 12.9063 12.6122 13.0231 12.6023 13.1455C12.5924 13.268 12.6194 13.3907 12.6797 13.4977C12.8802 13.8493 13.3606 14.3663 13.8936 14.8452C14.4918 15.3861 15.1552 15.8809 15.5752 16.0495C15.6876 16.0954 15.8111 16.1066 15.9299 16.0817C16.0487 16.0567 16.1573 15.9968 16.2418 15.9095L16.657 15.4911C16.7371 15.4121 16.8367 15.3558 16.9457 15.3278C17.0547 15.2999 17.1692 15.3013 17.2774 15.332L18.959 15.8093C19.0518 15.8377 19.1368 15.887 19.2076 15.9534C19.2784 16.0197 19.3331 16.1014 19.3675 16.1921C19.4019 16.2829 19.415 16.3803 19.406 16.4769C19.397 16.5735 19.366 16.6667 19.3154 16.7495Z"
                fill="white"
              />
            </svg>
          </div>
        )}
      </div>

      <div className="template-content">
        <p className="greeting">{greeting}</p>
        <p className="message">{message}</p>
        <p className="context">{context}</p>
        <p className="ticket-no">Ticket Number: {ticketNo}</p>
        <p className="team-info">
          Our Team is already working on it and will update you soon.
        </p>
        <p className="thanks">Thanking for your patience.</p>
        <p className="signature">Helpful Digital Cafe</p>
      </div>

      <div className="template-footer">
        <div className="flow-info">
          <span className="flow-label">Flow:</span>
          <span className={`flow-value ${getFlowClass(flow)}`}>{flow}</span>
        </div>
        <div className="view-button">View</div>
      </div>
        <div className="phone-number">{phone}</div>
    </div>
  );
};

export default TemplateCard;
