import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import * as listingUtils from "../../../../../libs/listingToast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import BusinessList from "../BusinessList";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";
import google_svg from "../../../../../images/googlephoto.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    maxWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const OpenHour = (props) => {

  const { primaryDetail, actions, business_id, queryStringData, busines_status, bulk_update_listing_data } = props;


  const [selectedBusinessList, setSelectedBusinessList] = useState([]);
  const [showBusiness, setShowBusiness] = useState(true);
  const handleHideBusinessScreen = () => {
    setShowBusiness(false);
  }

  const handleShowBusinessScreen = () => {
    setShowBusiness(true);
  }

  const [selectedBusinessCount, setSelectedBusinessCount] = useState([]); // State to hold the selected businesses count
  console.log("==================================", selectedBusinessCount)

  const handleSelectedBusinessCount = (selectBusiness) => {
    setSelectedBusinessCount(selectBusiness);
  };


  const addressString = [
    primaryDetail?.addressLines,
    primaryDetail?.locality,
    primaryDetail?.postalCode,
  ].join(", ");
  const classes = useStyles();

  const [openingDate, setOpeningDate] = useState(
    primaryDetail?.openInfo_openingDate
  );

  React.useEffect(() => {
    if (primaryDetail?.openInfo_openingDate) {
      setOpeningDate(dayjs(primaryDetail?.openInfo_openingDate));
    } else {
      setOpeningDate(dayjs(new Date()));
    }
  }, [primaryDetail?.openInfo_openingDate]);

  const businessOpeningDateHandler = async () => {
    const year = dayjs(openingDate).year();
    const month = dayjs(openingDate).month() + 1;
    const day = dayjs(openingDate).date();

    const data = {
      type: "openInfo_openingDate",
      location_ids: selectedBusinessCount,
      openingDate: {
        year,
        month,
        day,
      },
    };
    const restodetail = {
      business_id,
    };
    listingUtils?.displayMessage(
      "info",
      "Listing getting updated, It may take a few minutes."
    );
    await actions.getGmbListingBulkUpdateListing(data, restodetail);
    listingUtils?.displayMessage("close");
    if (bulk_update_listing_data) {
      listingUtils?.displayMessage("positive", "updated Successfully.");
    }
  };




  return (
    <>
      {
        showBusiness ?
          <div>
            < BusinessList business_id={business_id} busines_status={busines_status} type="opening_date" queryStringData={queryStringData} selectedBusinessList={selectedBusinessList} onSelectedBusinessCountChange={handleSelectedBusinessCount} />
            {selectedBusinessCount?.length > 0 ?
              <div
                className="d-flex mt-10 mb-15"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  fontWeight: "600",
                  fontSize: "24px",
                  color: "#1A2544",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  paddingBlock: "20px"
                }}    >
                < FPButton
                  label="Next"
                  onClick={handleHideBusinessScreen}
                />
              </div>
              : ""
            }
          </div >
          : <div className="row">
            <div className="col-12 col-lg-9">
              <div
                className="listing-container"
                style={{ display: "grid", gap: "32px" }}
              >
                <div className="listing-name">
                  Add Business Opening date to define age of Business
                </div>
                <div
                  style={{
                    padding: "16px",
                    backgroundColor: "#F6F6F8",
                    borderRadius: "8px",
                  }}
                >
                  <div style={{ display: "flex", gap: "10px" }}>
                    {/* <div style={{ width: "100px" }}> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        //   label="Controlled picker"
                        value={dayjs(openingDate)}
                        onChange={(newValue) => setOpeningDate(newValue)}
                        maxDate={dayjs(new Date())}
                        format="DD/MM/YYYY"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": { width: "100%" },
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </div>
                {/* </div> */}
                <div style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  // paddingBottom: "10px",
                  // paddingTop: "10px",
                  paddingBlock: "20px"
                }}>
                  <div><FPButton
                    label="Back"
                    size="small"
                    onClick={handleShowBusinessScreen}
                  /></div>
                  <div><FPButton
                    onClick={businessOpeningDateHandler}
                    label="Update"
                    size="small"
                  /></div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3">
              <img src={google_svg} alt="verifiedImage" width="100%" />
            </div>
          </div>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    bulk_update_listing_data: state.gmbListingLocation.bulk_update_listing_data,

  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenHour);
