import React, { useState } from "react";
import { FPButton } from "./new";
import { Avatar } from "@material-ui/core";

const SocialSelectButton = ({ socialData }) => {
  const [selected, setSelected] = useState(0);

  const handleClick = (index) => {
    setSelected(index);
  };
  return (
    <div>
      <div className="d-flex gap-20">
        {socialData &&
          socialData.map((item, i) => {
            const isActive = selected === i;
            return (
              <div key={`${i}`}>
                <FPButton
                  type="button"
                  onClick={() => handleClick(i)}
                  variant="outline"
                  backgroundColor={isActive ? "#DDEFF0" : "#ffffff"}
                  borderColor={isActive ? "#00B5AD" : "#ffffff"}
                  textColor={isActive ? "#00B5AD" : "#1B1C1D"}
                  borderWidth={1}
                  size="small"
                  startIcon={
                    <Avatar className="clean-small-avatar border-0">
                      <img src={item.logo} alt="" style={{ height: 15, width: 15 }} />
                    </Avatar>
                  }
                >
                  {item.display_name}
                </FPButton>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default SocialSelectButton;
