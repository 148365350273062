import React from "react";
import { useField, useFormikContext } from "formik";
import { FormControl, FormLabel, Box } from "@mui/material";
import PhoneInput from "./PhoneInput";



const FeedBackPhoneInput = ({
  name,
  label,
  placeholder = "Enter phone number",
  className,
  required = false,
  ...otherProps
}) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  const handleChange = (value) => {
    setFieldValue(name, value);
  };

  const hasError = meta.touched && meta.error;

  return (
    <FormControl fullWidth error={hasError} sx={{ mb: 2 }}>
      {label && (
        <FormLabel sx={{ mb: 1, display: 'block' }}>
          {label} {required && <span style={{ color: 'error.main' }}>*</span>}
        </FormLabel>
      )}
      <PhoneInput
        id={name}
        name={name}
        value={field.value || ""}
        onChange={handleChange}
        placeholder={placeholder}
        error={hasError ? meta.error : undefined}
        {...otherProps}
      />
    </FormControl>
  );
};

export default FeedBackPhoneInput;