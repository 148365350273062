import React, { useState } from 'react';

const NPSRatingLabel = ({ isShowUpToHover = true, clickAble = false }) => {
  const nps_array = Array(10).fill(false);
  const [npsArray, setNpsArray] = useState(nps_array);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleNpsHover = (index, value) => {
    let newArray = [...nps_array];
    if (isShowUpToHover) {
      newArray = newArray.fill(value, 0, index + 1);
    } else {
      newArray[index] = true;
    }
    setNpsArray([...newArray]);
  };

  const handelOnClick = (index) => {
    clickAble && setActiveIndex(index);
  };
  return (
    <div className="nps-score">
      {npsArray.map((item, index) => {
        return (
          <label key={index}>
            <div
              style={{
                display: 'inline-block',
                maxHeight: '35px',
                maxWidth: '35px',
              }}
              onMouseOver={() => handleNpsHover(index, true)}
              onMouseLeave={() => handleNpsHover(index, false)}
              onClick={() => handelOnClick(index)}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                className={`nps-method-circles ${
                  item || activeIndex ? 'nps-score-active' : 'nps-score-inactive'
                }`}
              >
                <div>{index + 1}</div>
              </div>
            </div>
          </label>
        );
      })}
    </div>
  );
};

export default NPSRatingLabel;
