import React from 'react';
import FormModal from '../FormModal';
import FPButton from './FPButton';

const TagManageModalWrapper = (props) => {
  const { header, isOpen, children, toggle, onAddNewTagClick } = props;

  return (
    <FormModal
      isOpen={isOpen}
      size={'md'}
      toggle={toggle}
      heading={header || 'Manage Tags'}
      showDivider={true}
      width='800px'
      paddingDense={true}
      dialogActions={
        <React.Fragment>
          <FPButton   
            onClick={onAddNewTagClick}
            label="Add new tag"
            size='small'
          />
          {/* <FPButton
            onClick={toggle}
            label="Close"
            variant='outline'
            size='small'                      
          /> */}
        </React.Fragment>
      }
    >
      {children}
    </FormModal>
  );
};

export default TagManageModalWrapper;
