import { Field } from "formik";
import React from "react";
import FPTextArea from "./ui/FPTextArea"; // Import your FPTextArea component
import ErrorMessage from "./ui/ErrorMessage";
import classNames from "classnames";

const FormTextArea = (props) => {
  const { name, id, label, className, placeholder, sx, disabled } = props;
  return (
    <div className={classNames(className, "mb-20")}>
      <Field name={name}>
        {({ field, meta }) => (
          <React.Fragment>
            <FPTextArea
              {...field}
              label={label}
              id={id}
              error={!!(meta.touched && meta.error)}
              placeholder={placeholder}
              sx={sx}
              disabled={disabled}
            />
            {meta.touched && meta.error && <ErrorMessage>{meta.error}</ErrorMessage>}
          </React.Fragment>
        )}
      </Field>
    </div>
  );
};

export default FormTextArea;
