import React, { useState } from 'react';

export const Legend = ({item, ci, meta, index}) => {
  const [clicked, setClicked] = useState(false);
  return (
    <li
      style={{ display: 'flex', alignItems: 'center' , cursor: 'pointer'}}
      onClick={
        () => {
          console.log(item);
          console.log(ci);
          setClicked(prev => !prev);
          // See controller.isDatasetVisible comment
          meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
          console.log(meta.hidden);
          // We hid a dataset ... rerender the chart
          ci.update();
        }}
    >
      <div className='mr-1' style={{ height: '7px', width: '15px', background: `${item.borderColor}` }}></div>
      <div style={{ color: `${item.borderColor}`, fontStyle: 'italic', marginRight: '2em', fontSize: '10px', textDecoration: clicked ? 'line-through' : 'none', textDecorationColor: `${item.borderColor}` }}>
        {item.label}
      </div>
    </li>
  );
};