import { Typography } from "@mui/material";
import { useFormikContext } from "formik";
import FeedBackStarRating from "./FormContainer/FeedBackStarRating";
import FPLabel from "../../../components/common/new/forms/ui/FPLabel";
import { FormTextArea } from "../../../components/common/new/forms";
import { styled } from "@mui/material/styles";
import { Box } from "@material-ui/core";
import FeedbackEmojiSelect from "./FormContainer/FeedbackEmojiSelect";
import FeedbackNpsNumber from "./FormContainer/FeedbackNpsNumber";
import LikeDislikeSelect from "./FormContainer/FeedbackLike";

const UserExperiencePage = ({ showLabel }) => {
  const { setFieldValue, values, errors, touched } = useFormikContext();
  const Description = styled(Typography)({
    fontSize: "14px",
    textAlign: "center",
  });

  return (
    <>
      <Description>
        Thank you for choosing us! Please take a moment to leave us a review.
        Your responses will help us serve you better in the future.
      </Description>

      {false && <FeedBackStarRating
        onRatingChange={(rating) => setFieldValue("start_rating", rating)}
        values={values?.start_rating}
        error={touched?.start_rating && errors.start_rating}
      />}
      {false && <FeedbackEmojiSelect
        onEmojiChange={(rating) => setFieldValue("emoji_rating", rating)}
        value={values?.emoji_rating}
        error={touched?.emoji_rating && errors.emoji_rating}
      />}

      {true && <FeedbackNpsNumber
        onNpsChange={(rating) => setFieldValue("nps_score", rating)}
        value={values?.nps_score}
        error={touched?.nps_score && errors.nps_score}
      />}

      {false && <LikeDislikeSelect
        onLikeChange={(rating) => setFieldValue("like_score", rating)}
        value={values?.like_score}
        error={touched?.like_score && errors.like_score}
      />}

      <FPLabel isRequired={true} className="mt-4">
        What would you tell others about your experience?
      </FPLabel>
      <Box
        component="div"
        className="form-group mb-0"
        sx={{
          "& .MuiBox-root": {
            border: "none !important",
            backgroundColor: "#F6F6F8 !important",
            borderRadius: "10px !important",
            padding: "11px 12px",
          },
        }}
      >
        <FormTextArea
          name="user_experience"
          placeholder="Write here..."
          sx={{
            "& .fp-textarea": {
              fontSize: "12px",
              color: "#646565",
              backgroundColor: "#F6F6F8 !important",
            },
          }}
        />
      </Box>
    </>
  );
};

export default UserExperiencePage;
