/* eslint-disable no-unused-vars */
import React, { memo } from "react";
import "./messageBox.css";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  messageBoxWrapper: {
    // marginBottom: '20px',
    // '&:last-child': {
    //   marginBottom: '0px',
    // },
    display: "flex",
    flexDirection: "column",
    alignItems: ({ isSelfBox }) => (isSelfBox ? "end" : "start"),
  },
  messageBox: {
    color: "#7A8193",
    background: ({ backgroundColor }) => backgroundColor || "#F6F6F8",
    padding: ({ as }) => (as === "button" ? "7px 15px" : "12px"),
    fontSize: "12px",
    maxWidth: ({ as }) => (as === "button" ? "100%" : "90%"),
    // textAlign: 'justify',
    display: "inline-block",
    borderRadius: ({ isSelfBox }) =>
      isSelfBox ? "24px 24px 0 24px" : "0 24px 24px 24px",
    cursor: ({ as }) => (as === "button" ? "pointer" : "default"),
  },
  time: {
    fontSize: "11px",
    fontWeight: "400",
    color: "#7A8193",
    marginTop: "5px !important",
    display: "block",
    textAlign: ({ isSelfBox }) => (isSelfBox ? "end" : "start"),
  },
  author: {
    color: "#1A2544",
    fontWeight: "500",
  },
  disable: {
    color: "rgba(0, 0, 0, 0.26) !important",
    backgroundColor: "rgba(0, 0, 0, 0.26) !important",
    cursor: "default !important",
  },
  disablePointer: {
    pointerEvents: "none !important",
  },
}));

const MessageBox = ({
  isSelfBox = true,
  className,
  backgroundColor,
  children,
  time,
  author,
  disable,
  captionClass,
  as,
  wrapperClassName,
  ...rest
}) => {
  const classes = useStyles({ backgroundColor, isSelfBox, as });
  return (
    <section
      className={classNames(
        classes.messageBoxWrapper,
        disable && classes.disablePointer,
        wrapperClassName
      )}
      {...rest}
    >
      <div
        className={classNames(
          classes.messageBox,
          disable && classes.disable,
          className
        )}
      >
        {children}
      </div>
      {time && (
        <p className={classNames(classes.time, captionClass)}>
          {author && <span className={classes.author}>{author} | </span>}
          {time && time}
        </p>
      )}
    </section>
  );
};

export default memo(MessageBox);
