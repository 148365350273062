import React, { useState, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Box,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormFeedback } from "reactstrap";

const countries = [
  { name: "India", dialCode: "+91", code: "IN" },
  { name: "United States", dialCode: "+1", code: "US" },
  { name: "United Kingdom", dialCode: "+44", code: "GB" },
  { name: "Canada", dialCode: "+1", code: "CA" },
];

const PhoneInput = (props) => {
  const { value, onChange, onCountryChange, placeholder = "Enter phone number", error, ...otherProps } = props;

  const [selectedCountry, setSelectedCountry] = useState(countries[0]); // Default: First country
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (value) {
      // Extract country code from value (e.g., "+91XXXXXXXXXX")
      const matchingCountry = countries.find((country) => value.startsWith(country.dialCode));

      if (matchingCountry) {
        setSelectedCountry(matchingCountry);
        if (onCountryChange) onCountryChange(matchingCountry);
      }

      // Remove country code and update phone number
      const formattedValue = value.replace(matchingCountry?.dialCode || "", "");
      setInputValue(formattedValue);
    }
  }, [value]);

  const handleCountryChange = (event) => {
    const country = countries.find((c) => c.code === event.target.value);
    if (country) {
      setSelectedCountry(country);
      if (onCountryChange) onCountryChange(country);

      const phoneWithoutCode = inputValue.replace(/^\+\d+\s?/, "");
      onChange(`${country.dialCode}${phoneWithoutCode}`);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value.replace(/[^0-9]/g, "").slice(0, 10); // Restrict to 10 digits
    setInputValue(newValue);
    onChange(`${selectedCountry.dialCode}${newValue}`);
  };

  return (
    <Box>
      <FormControl fullWidth error={!!error}>
        <TextField
          {...otherProps}
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          error={!!error}
          fullWidth
          sx={{
            "& .MuiOutlinedInput-notchedOutline": { border: "none !important", width: "100%" },
            "& .MuiInputBase-root::before": { border: "none !important" },
            "& .MuiInputBase-root ": { backgroundColor: "#F6F6F8 !important", paddingLeft: "0px !important", borderRadius: "10px !important" },
            "& .MuiOutlinedInput-input ": { padding: "9.5px 14px 9.5px 0px !important", fontSize: "13px !important" },
            "& .MuiBox-root": {
                fontSize:"13px !important",
              },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FormControl variant="standard">
                  <Select
                    value={selectedCountry.code}
                    onChange={handleCountryChange}
                    IconComponent={KeyboardArrowDownIcon}
                    sx={{
                      "& .MuiSelect-select": { padding: "5px 10px", display: "flex", alignItems: "center" },
                    }}
                  >
                    {countries.map((country) => (
                      <MenuItem key={country.code} value={country.code}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box component="span" sx={{ fontWeight: "medium" }}>
                            {country.code}
                          </Box>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* <Box sx={{ color: "text.secondary" }}>{selectedCountry.dialCode}</Box> */}
              </InputAdornment>
            ),
          }}
          variant="outlined"
        />
        {error && <FormFeedback>{error}</FormFeedback>}
      </FormControl>
    </Box>
  );
};

export default PhoneInput;
