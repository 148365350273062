import React from "react";
import { Line } from "react-chartjs-2";

const OfferLocationChart = ({ labels, locationsData }) => {
  const data = {
    labels: [...labels],
    datasets: [...locationsData],
  };
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        type: "linear",
        position: "left",
        grid: {
          borderDash: [7],
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      x: {
        grid: { display: false },
        ticks: {
          callback: function (value) {
            const dateLabel = this.getLabelForValue(value);
            return dateLabel.substr(0, 20);
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontSize: 16,
          fontStyle: "normal",
          fontFamily: "Outfit",
          boxWidth: 6,
          boxHeight: 6,
          padding: 10,
          usePointStyle: true,
        },
      },
    },
    animation: {
      duration: 2000,
    },
    maintainAspectRatio: false,
  };
  return (
    <div style={{ height: "500px" }}>
      <Line datasetIdKey="id" data={data} options={options} />
    </div>
  );
};

export default OfferLocationChart;
