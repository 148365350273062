import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import * as ResetPasswordActions from '../../actions/accounts/changePasswordActions';
import ResetPasswordForm from '../../components/accounts/resetPassword/ResetPasswordForm';
import LoaderComponent from '../../components/common/loaderComponent';

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  componentDidMount() {
    document.title = 'Forgot Password';
  }

  handleResetPassword(values) {
    const { router: { history, route: { match: { params } } } } = this.context;
    return this.props.actions.resetPasswordAction({ ...params, new_password: values.new_password })
      .then(() => history.push('/reset-password-success'));
  }

  render() {
    return (
      <Row className="align-items-center justify-content-center login-bg full-height" >
        <LoaderComponent loader={this.props.loader}/>
        <Col xs={12}>
          <Row className="justify-content-center">
            {/* <Col xs={10} sm={10}> */}
              <ResetPasswordForm handleResetPassword={this.handleResetPassword} />
            {/* </Col> */}
          </Row>
        </Col>
      </Row>
    );
  }
}


const mapStateToProps = (state) => ({
  loader:state.common.loader
});

ResetPasswordPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
};

ResetPasswordPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ResetPasswordActions, dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(ResetPasswordPage);
