import React from 'react';
import PropTypes from 'prop-types';

const MessegeCount = (props) => {
  const { mId, cArray } = props;
  const filter = cArray.findIndex(ca => {
    return ca.messenger_id === mId;
  });
  if(filter > -1){
    return(
      <p className="chat-notification-count">{cArray[filter]['count']}</p>
    );
  }else{
    return(
      <p></p>

    );
  }
};

MessegeCount.propTypes = {
  mId:PropTypes.number.isRequired,
  cArray:PropTypes.array.isRequired
};
export default MessegeCount;
