import React from "react";
import { connect } from "react-redux";
import { InputField } from "react-semantic-redux-form";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { FPButton } from "../common/new";
import closeCancel from "../../images/svg/close.svg";
import FPLabel from "../common/new/forms/ui/FPLabel";

class LinksForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const { submitSocialLinks, submitComplaintLinks } = this.props;
    const newData = [
      {
        key: data.web_portal.provider,
        link: data.link,
      },
    ];

    // try {
    //   if(submitSocialLinks) {
    //     return submitSocialLinks(newData);
    //   } else if(submitComplaintLinks) {
    //     return submitComplaintLinks(newData);
    //   }
    // }

    if (submitSocialLinks) {
      return submitSocialLinks(newData).catch((err) => {
        const linkError = err[0].link && err[0].link[0].substring(0, err[0].link[0].length - 1);
        const errobj = err[0]
          ? { link: linkError && linkError + " with http/https." }
          : { _error: "Oops!!! But it will get fixed soon:)" };
        throw new SubmissionError(errobj);
      });
    } else if (submitComplaintLinks) {
      return submitComplaintLinks(newData).catch((err) => {
        const errobj = err[0] ? { ...err[0] } : { _error: "Oops!!! But it will get fixed soon:)" };
        throw new SubmissionError(errobj);
      });
    }
  }

  clearLink(e) {
    const { change } = this.props;
    change("link", "");
    e.preventDefault();
    this.setState({ link: "" });
  }

  render() {
    const { submitting, handleSubmit, errors } = this.props;

    return (
      <form className="grid-form" onSubmit={handleSubmit(this.submit)}>
        {errors && errors.non_field_errors
          ? errors.non_field_errors.map((error, index) => (
              <p key={index} className="text-danger">
                {error}
              </p>
            ))
          : null}
        <FPLabel isRequired={false}>URL</FPLabel>
        <Field
          name="link"
          // action
          // actionPosition="right"
          component={InputField}
          placeholder="https://"
          type="text"
          className="w-100"
          // style={{ width: "100%" }}
        >
          <input style={{ paddingRight: "30px" }} />
          <img
            src={closeCancel}
            alt="Clear"
            type="button"
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={(e) => {
              this.clearLink(e);
            }}
          />
        </Field>
        <FPButton className="mt-4" label="Submit" type="submit" disabled={submitting} fullWidth />
      </form>
    );
  }
}

LinksForm = reduxForm({
  // eslint-disable-line
  form: "LinksForm",
  enableReinitialize: true,
})(LinksForm);

LinksForm = connect((state) => {
  // eslint-disable-line
  return {};
})(LinksForm);

export default LinksForm;
