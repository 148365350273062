import React from "react";

const MenuHighlights = () => {
    return (
        <div className="mt-4">
            <h1>Menu Highlights Pages</h1>
        </div>
    )
}

export default MenuHighlights