import React from "react";
import PropTypes from "prop-types";
import { Icon } from "semantic-ui-react";
import MessageBox from "../common/new/messageBox/MessageBox";
import classNames from "classnames";
import LoadError from "../../images/loadError.webp";

const MessageTypeComponent = (props) => {
  const addDefaultSrc = (ev) => {
    ev.target.src = LoadError;
    ev.target.style.border = "1px solid gray";
  };

  const { messageData, isSelfBox } = props;

  if (messageData.is_deleted && messageData.is_deleted) {
    return <p>This message is deleted by sender.</p>;
  } else if (messageData.is_story_reply) {
    return (
      <div>
        <img src={messageData?.file_url} height="60" width="60" alt="The story content is no longer available." />
        <p>{messageData?.message_text}</p>
      </div>
    );
  } else if (messageData?.msg_type === "text") {
    return (
      <MessageBox
        isSelfBox={isSelfBox}
        backgroundColor={isSelfBox ? "#FDE6F1" : "#F6F6F8"}
        className={classNames({
          "font-dark": isSelfBox,
        })}
        {...props.messageBoxProps}
      >
        {messageData?.message_text}
      </MessageBox>
    );
  } else if (messageData?.msg_type === "image" || messageData?.msg_type === "photo") {
    if (messageData?.attached_file != null) {
      return <img src={messageData?.file_attached} alt="file attached" height="60" width="60" />;
    } else if (messageData?.file_url != null) {
      return (
        <img
          onError={addDefaultSrc}
          src={messageData?.file_url}
          // height="60"
          width="200"
          alt="The story content is no longer available."
        />
      );
    } else {
      return null;
    }
  } else if (messageData.msg_type === "file") {
    if (messageData.attached_file != null) {
      if (messageData.attached_file.includes(".pdf")) {
        return (
          <p>
            <Icon name="file pdf" size="large" /> {decodeURI(messageData.file_attached).split("messages/")[1]}
          </p>
        );
      } else if (messageData.attached_file.includes(".docx")) {
        return (
          <p>
            <Icon name="file word" size="large" /> {decodeURI(messageData.file_attached).split("messages/")[1]}
          </p>
        );
      } else if (messageData.attached_file.includes(".doc")) {
        return (
          <p>
            <Icon name="file word" size="large" /> {decodeURI(messageData.file_attached).split("messages/")[1]}
          </p>
        );
      } else if (messageData.attached_file.includes(".xlsx")) {
        return (
          <p>
            <Icon name="file excel" size="large" /> {decodeURI(messageData.file_attached).split("messages/")[1]}
          </p>
        );
      } else if (messageData.attached_file.includes(".xls")) {
        return (
          <p>
            <Icon name="file excel" size="large" /> {decodeURI(messageData.file_attached).split("messages/")[1]}
          </p>
        );
      } else if (messageData.attached_file.includes(".txt")) {
        return (
          <p>
            <Icon name="file text" size="large" /> {decodeURI(messageData.file_attached).split("messages/")[1]}
          </p>
        );
      } else if (messageData.attached_file.includes(".csv")) {
        return (
          <p>
            <Icon name="file text" size="large" /> {decodeURI(messageData.file_attached).split("messages/")[1]}
          </p>
        );
      } else {
        return null;
      }
    } else {
      if (messageData.file_url.includes(".pdf/")) {
        return (
          <p>
            <Icon name="file pdf" size="large" /> {messageData.file_url.split(".pdf/")[1].split("?")[0]}
          </p>
        );
      } else if (messageData.file_url.includes(".docx/")) {
        return (
          <p>
            <Icon name="file word" size="large" /> {messageData.file_url.split(".docx/")[1].split("?")[0]}
          </p>
        );
      } else if (messageData.file_url.includes(".doc/")) {
        return (
          <p>
            <Icon name="file word" size="large" /> {messageData.file_url.split(".doc/")[1].split("?")[0]}
          </p>
        );
      } else if (messageData.file_url.includes(".txt/")) {
        return (
          <p>
            <Icon name="file text" size="large" /> {messageData.file_url.split(".txt/")[1].split("?")[0]}
          </p>
        );
      } else if (messageData.file_url.includes(".xlsx/")) {
        return (
          <p>
            <Icon name="file excel" size="large" /> {messageData.file_url.split(".xlsx/")[1].split("?")[0]}
          </p>
        );
      } else if (messageData.file_url.includes(".xls/")) {
        return (
          <p>
            <Icon name="file excel" size="large" /> {messageData.file_url.split(".xls/")[1].split("?")[0]}
          </p>
        );
      } else if (messageData.file_url.includes(".csv/")) {
        return (
          <p>
            <Icon name="file text" size="large" /> {messageData.file_url.split(".csv/")[1].split("?")[0]}
          </p>
        );
      } else {
        return null;
      }
    }
  } else if (messageData.msg_type === "video" || messageData.msg_type === "animated_gif") {
    if (messageData.attached_file != null) {
      return <iframe src={messageData.file_attached} frameBorder="0" allowFullScreen title="video" width="200" />;
    } else {
      if (messageData.file_url.includes("&dl")) {
        return (
          <iframe
            src={messageData.file_url.split("&dl")[0]}
            frameBorder="0"
            allowFullScreen
            title="video"
            width="200"
          />
        );
      } else {
        return <iframe src={messageData.file_url} frameBorder="0" allowFullScreen title="video" width="200" />;
      }
    }
  } else if (messageData.msg_type === "audio") {
    if (messageData.attached_file != null) {
      return (
        <audio controls>
          <source src={messageData.file_attached} type="audio/ogg" />
        </audio>
      );
    } else {
      return (
        <audio controls>
          <source src={messageData.file_url} type="audio/ogg" />
        </audio>
      );
    }
  } else {
    return null;
  }
};

MessageTypeComponent.propTypes = {
  messageData: PropTypes.object.isRequired,
};

export default MessageTypeComponent;
