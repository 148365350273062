import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Label, Form } from "semantic-ui-react";
import { Box } from "@material-ui/core";
import ErrorMessage from "./new/forms/ui/ErrorMessage";

const PhoneInputField = (field) => {
  const [selectedCountry, setSelectedCountry] = useState("IN");
  const onChange = (val) => {
    if (!val) {
      field.input.onChange("");
    } else field.input.onChange(val);
  };
  let {
    input,
    label,
    required,
    width,
    inline,
    meta: { touched, error },
    disabled,
    serverError
  } = field;
  return (
    <Form.Field
      error={!!(touched && error)}
      required={required}
      width={width}
      inline={inline}
    >
      {label && <label>{label}</label>}
      <Box
        className="position-relative"
        sx={{
          pointerEvents: disabled ? "none" : "auto",
          opacity: disabled ? "0.45" : 1,
        }}
      >
        <PhoneInput
          placeholder="Enter phone number"
          name={"currentItems"}
          value={input.value ? input.value : undefined}
          onChange={(param) => onChange(param)}
          onBlur={input.onBlur}
          defaultCountry="IN"
          required={required}
          className="fp-mobile"
          {...field}
          disabled={false}
          international
          // countryCallingCodeEditable={false}
          onCountryChange={(value) => setSelectedCountry(value)}
        />
        {selectedCountry && (
          <span className="mobile-picker-country-name">{selectedCountry}</span>
        )}
      </Box>

      {touched && error && <ErrorMessage  style={{fontSize : "12.25px"}}>{error}</ErrorMessage>}
        {serverError && <ErrorMessage  style={{fontSize : "12.25px"}}>{serverError}</ErrorMessage>}
    </Form.Field>
  );
};

export default PhoneInputField;
