import React, { Fragment, useState } from 'react';
import { Col } from 'reactstrap';

import starActive from '../../images/reviewMethods/star_Color.png';
import starInActive from '../../images/reviewMethods/star_B_W.png';
import thumbsUpActive from '../../images/reviewMethods/thumbs-up_Color.png';
import thumbsUpInActive from '../../images/reviewMethods/thumbs-up_B_W.png';
import thumbDownActive from '../../images/reviewMethods/thumb-down_Color.png';
import thumbDownInActive from '../../images/reviewMethods/thumb-down_B_W.png';
import smileyHappyActive from '../../images/reviewMethods/happy_Color.png';
import smileyHappyInActive from '../../images/reviewMethods/happy_B_W.png';
import smileyNeutralActive from '../../images/reviewMethods/neutral_Color.png';
import smileyNeutralInActive from '../../images/reviewMethods/neutral_B_W.png';
import smileyUnHappyActive from '../../images/reviewMethods/unhappy_Color.png';
import smileyUnHappyInActive from '../../images/reviewMethods/unhappy_B_W.png';
import mobile from '../../images/mobile.png';

import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css';
import CustomRatingComponent from '../common/customRating';
import SocialButtonsGroup from '../common/SocialButtonsGroup';
import SocialMediaIcon, {SocialIconMobile} from '../common/SocialButtonsGroup/SocialMediaIcon';

function EmailPreview({ template }) {
  const ratingType = template.symbol_type;
  const [starRating, setStarRating] = useState(0);
  const [thumbRating, setThumbRating] = useState(0);
  const [smileyRating, setSmileyRating] = useState(0);
  const [npsScore, setNpsScore] = useState(0);

  return (
    <div className="emailTemp row text-center">
      <div className="left">
        <div className="header">
          <div className="d-flex">
            <div className="flex-shrink-0">
              <img src="..." alt="..." />
            </div>
            <div className="gap-5">
              Wednesday weekly promo, W40
              <button type='button' className="btn btn-sm previewBtn">Preview mode</button>
            </div>
          </div>
        </div>
        <div className="main">
          <div className="d-flex justify-content-between w-100 px-5">
            <p className="">15% discount on all burgers</p>
            <p style={{ textAlign: 'justify' }}>
              <a
                style={{
                  color: '#848181',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                View this email in your browsers
              </a>
            </p>
          </div>

          <div className="card-Box mx-5 border-bottom bg-white">
            <div className="card-image">Image</div>
            <div className="card-Content">
              <h4 style={{ margin: '1.5rem', fontSize: '1.3rem' }}>
                Hello {'{Name of Recipient}'}
              </h4>
              {/* <p style={{ margin: '1.5rem' }}>
                {type === 'review' ? template.body : template.message}
              </p> */}
              <p style={{ margin: '1rem 1.5rem', fontSize: '1rem' }}>
                {
                  'Please take a moment to leave us a review. Your responses will help us serve you better in the future.'
                }
              </p>
              <div className="row my-3">
                {ratingType === 'star' && (
                  <Col sm="12" className="no-label">
                    <Rater
                      total={5}
                      rating={starRating}
                      onRate={(rating) => setStarRating(rating)}
                    >
                      <CustomRatingComponent
                        activeImg={starActive}
                        inActiveImg={starInActive}
                        customClass="mr-2 review-method-img"
                        imageWidth={90}
                      />
                    </Rater>
                  </Col>
                )}
                <div className="ratingIcon">
                  {ratingType === 'thumb' && (
                    <Col sm="12">
                      <span className="mr-2">
                        <img
                          width={100}
                          className="review-method-img"
                          onMouseEnter={() => setThumbRating(1)}
                          onMouseLeave={() => setThumbRating(0)}
                          src={
                            thumbRating === 1
                              ? thumbsUpActive
                              : thumbsUpInActive
                          }
                        />
                      </span>
                      <span>
                        <img
                          width={100}
                          className="review-method-img"
                          onMouseEnter={() => setThumbRating(2)}
                          onMouseLeave={() => setThumbRating(0)}
                          src={
                            thumbRating === 2
                              ? thumbDownActive
                              : thumbDownInActive
                          }
                        />
                      </span>
                    </Col>
                  )}
                  {ratingType === 'smiley' && (
                    <Col sm="12">
                      <span className="mr-2">
                        <img
                          width={100}
                          className="review-method-img"
                          onMouseEnter={() => setSmileyRating(1)}
                          onMouseLeave={() => setSmileyRating(0)}
                          src={
                            smileyRating === 1
                              ? smileyHappyActive
                              : smileyHappyInActive
                          }
                        />
                      </span>
                      <span className="mr-2">
                        <img
                          width={100}
                          className="review-method-img"
                          onMouseEnter={() => setSmileyRating(2)}
                          onMouseLeave={() => setSmileyRating(0)}
                          src={
                            smileyRating === 2
                              ? smileyNeutralActive
                              : smileyNeutralInActive
                          }
                        />
                      </span>
                      <span>
                        <img
                          width={100}
                          className="review-method-img"
                          onMouseEnter={() => setSmileyRating(3)}
                          onMouseLeave={() => setSmileyRating(0)}
                          src={
                            smileyRating === 3
                              ? smileyUnHappyActive
                              : smileyUnHappyInActive
                          }
                        />
                      </span>
                    </Col>
                  )}
                </div>
                {ratingType === 'nps_score' && (
                  <Fragment>
                    <Col sm="12" className="nps-score">
                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => {
                        return (
                          <div key={index} className="mr-2 nps-container">
                            <div style={{ width: '100%' }}>
                              <div
                                style={{
                                  width: '45px',
                                  height: '45px',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                                onMouseEnter={() => setNpsScore(index + 1)}
                                onMouseLeave={() => setNpsScore(0)}
                                className={`nps-method-circles ${
                                  npsScore > index
                                    ? 'nps-score-active'
                                    : 'nps-score-inactive'
                                }`}
                              >
                                <div>{index + 1}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      <div></div>
                    </Col>
                    <Col sm="12 mb-5" className="likeliness-labels">
                      <div
                        className="likeliness-left-position"
                        style={{ color: 'rgb(192,5,5)' }}
                      >
                        Not at all likely
                      </div>
                      <div
                        className="likeliness-right-position"
                        style={{ color: 'rgb(33, 228, 7)' }}
                      >
                        Extremely Likely
                      </div>
                    </Col>
                  </Fragment>
                )}
              </div>
            </div>
            {/* Review Button */}
            <div className="pb-3">
              <SocialButtonsGroup
                links={template.review_channels}
                className="MobileReviewBtn"
              />
            </div>
          </div>
          {/* Social-Media-Icon */}
          <div className="">
            <SocialMediaIcon />
          </div>
          <div className="d-flex flex-column text-center">
            <span className="spanItalic mt-3">
              Copyright 2015{' '}
              <a style={{ cursor: 'pointer' }}>
                {template.business.business_name}
              </a>{' '}
              , All rights reserved.
            </span>
            <span>You are receiving this email because you opted in.</span>
            <span className="spanBold mt-3">Our mailing address is : {}</span>
            <span>Demo Restaurant</span>
            <span>
              W 34th St, New Your, NY
            </span>
          </div>
        </div>

        {/* Footer */}
        {template.business && (
          <div className="footer">
            <p>
              Well automatically add
              <a className="text-info"> your contact info </a>
              into the email footer.
              <a className="text-info"> learn more</a>
            </p>
          </div>
        )}
      </div>
      <div className="right">
        <img src={mobile} className="mobileImg" />
        <div className="mobileContainer px-4">
          <p>Image</p>
          <div className="mobileContent">
            <h4 style={{ margin: '1.5rem', fontSize: '1.2rem' }}>
              Hello <span className="spanBold"> {'{Name of Recipient}'} </span>
            </h4>
            {/* <p style={{ margin: '1.5rem' }}>
                {type === 'review' ? template.body : template.message}
              </p> */}
            <p
              style={{
                margin: '1rem 1.5rem',
                fontSize: '12px',
                fontWeight: 500,
              }}
            >
              {
                'Please take a moment to leave us a review. Your responses will help us serve you better in the future.'
              }
            </p>
            <div className="row mt-4">
              {ratingType === 'star' && (
                <Col sm="12" className="no-label">
                  <Rater
                    total={5}
                    rating={starRating}
                    onRate={(rating) => setStarRating(rating)}
                  >
                    <CustomRatingComponent
                      activeImg={starActive}
                      inActiveImg={starInActive}
                      customClass="mr-2 review-method-img"
                      imageWidth={90}
                    />
                  </Rater>
                </Col>
              )}
              <div className="ratingIcon">
                {ratingType === 'thumb' && (
                  <Col sm="12">
                    <span className="mr-2">
                      <img
                        width={100}
                        className="review-method-img"
                        onMouseEnter={() => setThumbRating(1)}
                        onMouseLeave={() => setThumbRating(0)}
                        src={
                          thumbRating === 1 ? thumbsUpActive : thumbsUpInActive
                        }
                      />
                    </span>
                    <span>
                      <img
                        width={100}
                        className="review-method-img"
                        onMouseEnter={() => setThumbRating(2)}
                        onMouseLeave={() => setThumbRating(0)}
                        src={
                          thumbRating === 2
                            ? thumbDownActive
                            : thumbDownInActive
                        }
                      />
                    </span>
                  </Col>
                )}
                {ratingType === 'smiley' && (
                  <Col sm="12">
                    <span className="mr-2">
                      <img
                        width={100}
                        className="review-method-img"
                        onMouseEnter={() => setSmileyRating(1)}
                        onMouseLeave={() => setSmileyRating(0)}
                        src={
                          smileyRating === 1
                            ? smileyHappyActive
                            : smileyHappyInActive
                        }
                      />
                    </span>
                    <span className="mr-2">
                      <img
                        width={100}
                        className="review-method-img"
                        onMouseEnter={() => setSmileyRating(2)}
                        onMouseLeave={() => setSmileyRating(0)}
                        src={
                          smileyRating === 2
                            ? smileyNeutralActive
                            : smileyNeutralInActive
                        }
                      />
                    </span>
                    <span>
                      <img
                        width={100}
                        className="review-method-img"
                        onMouseEnter={() => setSmileyRating(3)}
                        onMouseLeave={() => setSmileyRating(0)}
                        src={
                          smileyRating === 3
                            ? smileyUnHappyActive
                            : smileyUnHappyInActive
                        }
                      />
                    </span>
                  </Col>
                )}
              </div>
              {ratingType === 'nps_score' && (
                <Fragment>
                  <Col sm="12" className="nps-score">
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((_, index) => {
                      return (
                        <div key={index} className="mr-2 nps-container">
                          <div style={{ width: '100%' }}>
                            <div
                              style={{
                                width: '45px',
                                height: '45px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                              onMouseEnter={() => setNpsScore(index + 1)}
                              onMouseLeave={() => setNpsScore(0)}
                              className={`nps-method-circles ${
                                npsScore > index
                                  ? 'nps-score-active'
                                  : 'nps-score-inactive'
                              }`}
                            >
                              <div>{index + 1}</div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div></div>
                  </Col>
                  <Col sm="12 mb-5" className="likeliness-labels">
                    <div
                      className="likeliness-left-position"
                      style={{ color: 'rgb(192,5,5)' }}
                    >
                      Not at all likely
                    </div>
                    <div
                      className="likeliness-right-position"
                      style={{ color: 'rgb(33, 228, 7)' }}
                    >
                      Extremely Likely
                    </div>
                  </Col>
                </Fragment>
              )}
            </div>

            <div className="py-3" style={{ margin: '0rem 0.6rem' }}>
              <SocialButtonsGroup
                links={template.review_channels}
                className="mobileReviewBtn"
              />
            </div>
          </div>
          {/* Social_Media_Icon */}
          <div className="">
            <SocialIconMobile />
          </div>

          {/* Footer */}
          {template.business && (
            <div className="footer shadow" style={{ margin: '0rem 0.6rem' }}>
              <p style={{ textAlign: 'justify' }}>
                keen on missing out on yummazing emails from Zomato? Please
                <a
                  style={{
                    color: '#1a76d2',
                    cursor: 'pointer',
                  }}
                >
                  <span> unsubscribe here.</span>
                </a>{' '}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default EmailPreview;
