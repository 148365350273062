/* eslint-disable */
import React from "react";
import { Bar } from "react-chartjs-2";
import chartjsPluginDataLabels from "chartjs-plugin-datalabels";

class StackedBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps(props) {
    const {
      dataKeys,
      primaryYLabel,
      secondaryYLabel,
      location_addresses,
      fontStyle,
      fontSize,
      boxWidth,
      notUsePoint,
      data,
      title,
    } = props;
    return {
      chartData: {
        datasets: data,
        labels: dataKeys,
      },
      chartOptions: {
        animation: {
          duration: 2000,
        },
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
        title: {
          display: title ? true : false,
          text: title,
          position: "top",
          fontStyle: "bold",
          fontSize: 20,
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: (value) => {
                location_addresses ? location_addresses[value[0].index] : dataKeys[value[0].index];
              },
            },
          },
          legend: {
            display: false,
            position: "top",
            labels: {
              usePointStyle: notUsePoint ? false : true,
              boxWidth: boxWidth || 5,
              fontStyle: fontStyle,
              fontSize: fontSize,
              // if legend is true but not show dots & color use this
              // boxWidth: 0,
              // color: 'transparent',
            },
          },
          datalabels: {
            anchor: "end",
            align: "top",
            offset: 0,
            color: "#1B1C1D",
            font: {
              size: 11,
              weight: 400,
            },
            formatter: (value, context) => {
              // if (value === 0) {
              //   return null;
              // }
              const datasetArray = [];
              context.chart.data.datasets.forEach((dataset) => {
                if (dataset.data[context.dataIndex] != undefined) {
                  datasetArray.push(dataset.data[context.dataIndex]);
                }
              });
              function totalSum(total, datapoint) {
                return total + datapoint;
              }

              let sum = datasetArray.reduce(totalSum, 0);
              if (context.datasetIndex == datasetArray.length - 1) return sum;
              else return "";
            },
          },
        },
        layout: {
          padding: {
            top: 18,
          },
        },
        scales: {
          x: {
            // beginAtZero: true,
            border: {
              color: "#bfc2cb",
            },
            stacked: true,
            display: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 12,
                weight: 400,
              },
              color: "#1B1C1D",
            },
          },
          y: {
            // beginAtZero: true,
            stacked: true,
            grid: {
              borderDash: [7],
            },
            border: {
              dash: [6, 6],
              color: "transparent",
            },
          },
        },
      },
    };
  }

  render() {
    const { chartData, chartOptions } = this.state;
    return <Bar data={chartData} options={chartOptions} plugins={[chartjsPluginDataLabels]} />;
  }
}

export default StackedBarChart;
