import React from "react";
import { Line } from "react-chartjs-2";

const LocationLineChart = ({ tableDataLocation }) => {
  // Extract Labels (branch__alias)
  const lineChartLabels = tableDataLocation?.map((item) => item.branch__alias);

  // Extract dataset values
  const impressSet = {
    label: "Impressions",
    data: tableDataLocation?.map((item) => item.total_impressions_count),
    fill: false,
    borderColor: "#FBBD66",
    tension: 0,
    backgroundColor: "#FBBD66",
    borderWidth: 2,
  };

  const impressMenu = {
    label: "Impressions to Menu",
    data: tableDataLocation?.map(
      (item) => item.total_impressions_to_menu_count
    ),
    fill: false,
    borderColor: "#FB7B5E",
    tension: 0,
    borderWidth: 2,
    backgroundColor: "#FB7B5E",
  };

  const menuCart = {
    label: "Menu to Cart",
    data: tableDataLocation?.map((item) => item.total_menu_to_cart_count),
    fill: false,
    borderColor: "#E4A7E0",
    backgroundColor: "#E4A7E0",
    tension: 0,
    borderWidth: 2,
  };

  const cartOrder = {
    label: "Cart to Order",
    data: tableDataLocation?.map((item) => item.total_cart_to_order_count),
    fill: false,
    borderColor: "#82B8E4",
    tension: 0,
    backgroundColor: "#82B8E4",
    borderWidth: 2,
  };

  // Chart options
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        type: "linear",
        position: "left",
        grid: {
          borderDash: [7],
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "top",
        align: "end",
        labels: {
          fontSize: 16,
          fontStyle: "normal",
          fontFamily: "Outfit",
          boxWidth: 6,
          boxHeight: 6,
          padding: 10,
          usePointStyle: true,
        },
      },
    },
    animation: {
      duration: 2000,
    },
    maintainAspectRatio: false,
  };

  return (
    <Line
      datasetIdKey="id"
      data={{
        labels: lineChartLabels,
        datasets: [impressSet, impressMenu, menuCart, cartOrder],
      }}
      options={options}
    />
  );
};

export default LocationLineChart;
