import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import queryString from "query-string";

import { TabContent, TabPane, Row, Col } from "reactstrap";
import ReportsTab from "../../components/reports/Tab";
import ReportsTable from "../../components/reports/Table";
import TicketTable from "../../components/reports/TicketTable";
import UsageReportsTable from "../../components/reports/UsageReports";
import CombinationChart from "../../components/common/CombinationChart";
import MentionsChart from "../../components/mentions/MentionsChart";
import LoaderComponent from "../../components/common/loaderComponent";

import * as ReviewsActions from "../../actions/business/reviewsActions";
import * as ReportActions from "../../actions/report/reportActions";
import * as CommonActions from "../../actions/common/commonAction";
import * as MentionActions from "../../actions/mentions/mentionsActions";
import * as ticketActions from "../../actions/ticket/ticketActions";
import * as filterListActions from "../../actions/common/filterListActions";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import DoughnutChart from "../../components/common/DoughnutChart";
import ApexChart from "../../components/common/HorizontalBarChart";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import SurveyTableAll from "../../components/reports/SurveyTableAll";
import SurveyGraphAll from "../../components/reports/SurveyGraphAll";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { connect } from "react-redux";
import ReportBarChart from "../../components/common/ReportBarChart";
import ReportsStackedBarChart from "../../components/common/ReportsStackedBarChart";
import CampaignReportChart from "../../components/common/CampaignReportChart";
import SurveyTextualTable from "../../components/reports/SurveyTextualTable";
import TableReviewChannel from "../../components/reports/TableReviewChannel";
import { NoData } from "../../components/common/new";

const CHART_BACKGROUND_COLOR_1 = "#9E74E9";
const CHART_HOVER_COLOR_1 = "#19E3E3";
const CHART_BACKGROUND_COLOR_2 = "#F13A88";

class ReportsPage extends React.Component {
  constructor(props) {
    super(props);
    const query = queryString.parse(this.props.location.search);
    this.state = {
      showPercent: true,
      downloadLink: "",
      branchSelected: null,
      list: null,
      provider: null,
      locationlistby: null,
      keywordSelected: null,
      defaultfilterstate: "",
      defaultfilterstateNps: "",
      defaultfilterstateSentimental: "",
      defaultfilterstateTags: "",
      defaultfilterstateStar: "",
      average_click_count: 0,
      average_click_countForNps: 0,
      average_click_countForSentimental: 0,
      average_click_countForTags: 0,
      average_click_countForStar: 0,
      average_click_countForTicket: 0,
      defaultfilterstateTicket: "",
      activeCol: "",

      displayLevel: query.displayLevel ? query.displayLevel : "question",
      isDisplayBranchWise:
        query.displayLevel === "both" || query.displayLevel === "branch"
          ? true
          : false,
      isDisplayPageWise:
        query.displayLevel === "both" || query.displayLevel === "page"
          ? true
          : false,
    };

    this.toggleShowPercent = this.toggleShowPercent.bind(this);
    this.getReport = this.getReport.bind(this);
    this.emailReport = this.emailReport.bind(this);
    this.downloadReport = this.downloadReport.bind(this);
    this.handleBranchValue = this.handleBranchValue.bind(this);
    this.handleListValue = this.handleListValue.bind(this);
    this.handleProviderValue = this.handleProviderValue.bind(this);
    this.handleTagValue = this.handleTagValue.bind(this);
    this.handleLocationListBy = this.handleLocationListBy.bind(this);
    this.handleKeyword = this.handleKeyword.bind(this);
    this.toggleDisplayLevel = this.toggleDisplayLevel.bind(this);
    this.getUpdatedSurveyReport = this.getUpdatedSurveyReport.bind(this);
    this.handleSortTicketData = this.handleSortTicketData.bind(this);
    this.handleTicketSourceValue = this.handleTicketSourceValue.bind(this);
  }

  componentDidMount() {
    const {
      location: { pathname, search },
      history,
      match: {
        params: { business_id, reportType, report },
      },
    } = this.props;
    const query = queryString.parse(search);
    this.props.sourceFilterAction.reviewSourceOptions({ business_id });
    this.props.mentionActions.getMentionsKeywords(business_id);
    this.props.CommonActions.getTagOptions(business_id);
    this.props.CommonActions.cityOptions(business_id);
    this.props.CommonActions.stateOptions(business_id);
    this.props.CommonActions.branchTagOptions(business_id);
    this.props.ticketActions.getTicketSourceOptions();
    this.props.filterAction.getDateType(
      "",
      `${URLS.BUSINESS_SETUP}dashboard-filter/`
    );
    this.props.filterAction.getBranch(
      "",
      `${URLS.BUSINESS_SETUP}${business_id}/branch/`
    );
    // report === 'tickets' ? :

    if (report === "tickets") {
      this.props.filterAction.getListByFilter("", `ticket-listby-filter/`);
    } else {
      this.props.filterAction.getListByFilter(
        "",
        `${URLS.BUSINESS_SETUP}listby-filter/`
      );
    }

    if (!("date_type" in query) && !("list" in query)) {
      if (reportType === "overtime") {
        history.push({
          pathname,
          search: queryString.stringify({
            ...query,
            date_type: "prior_30_day",
            list: "date",
          }),
        });
      } else if (report === "display-report") {
        history.push({
          pathname,
          search: queryString.stringify({
            ...query,
          }),
        });
      } else {
        history.push({
          pathname,
          search: queryString.stringify({
            ...query,
            date_type: "prior_30_day",
          }),
        });
        this.getReport({
          ...query,
          business_id,
          report,
          report_type: reportType,
        });
      }
    } else {
      this.getReport({
        ...query,
        business_id,
        report,
        report_type: reportType,
      });
    }
    //Set Title
    document.title = "Reports";
    
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search: nextSearch },
      match: {
        params: { business_id: businessId, report, reportType, sectionType },
      },
    } = this.props;
    const {
      location: { search: prevSearch },
      match: {
        params: {
          report: prevReport,
          reportType: prevReportType,
          sectionType: prevSectionType,
        },
      },
    } = prevProps;
    if (
      reportType !== prevReportType ||
      report !== prevReport ||
      prevSearch !== nextSearch
    ) {
      this.getReport({
        ...queryString.parse(nextSearch),
        business_id: businessId,
        report_type: reportType,
        report,
      });
    }
    if (
      sectionType === "mention-section" &&
      prevSectionType !== "mention-section"
    )
      this.props.sourceFilterAction.reviewSourceOptions({
        reviewChannels: "review_source=mention",
        business_id: businessId,
      });
    else if (
      sectionType !== "mention-section" &&
      prevSectionType === "mention-section"
    )
      this.props.sourceFilterAction.reviewSourceOptions({
        business_id: businessId,
      });
  }

  toggleShowPercent() {
    this.setState({
      showPercent: !this.state.showPercent,
    });
  }

  toggleDisplayLevel(level, e, { checked }) {
    const { isDisplayBranchWise, isDisplayPageWise } = this.state;
    let new_level = "question";
    if (level === "page" && checked) {
      if (isDisplayBranchWise) {
        new_level = "both";
      } else {
        new_level = "page";
      }
      this.setState({ isDisplayPageWise: true });
    } else if (level === "page" && !checked) {
      if (isDisplayBranchWise) {
        new_level = "branch";
      } else {
        new_level = "question";
      }
      this.setState({ isDisplayPageWise: false });
    } else if (level === "branch" && checked) {
      if (isDisplayPageWise) {
        new_level = "both";
      } else {
        new_level = "branch";
      }
      this.setState({ isDisplayBranchWise: true });
    } else {
      if (isDisplayPageWise) {
        new_level = "page";
      } else {
        new_level = "question";
      }
      this.setState({ isDisplayBranchWise: false });
    }
    this.setState({ displayLevel: new_level });
    this.getUpdatedSurveyReport(new_level);
  }

  getUpdatedSurveyReport(level) {
    const {
      location: { search },
      history,
      match: {
        path,
        params: { business_id, reportType, report, sectionType, view },
      },
    } = this.props;

    const url = path
      .replace(":business_id", business_id)
      .replace(":sectionType", sectionType)
      .replace(":reportType", reportType)
      .replace(":report", report)
      .replace(":view", view);

    let search_obj = queryString.parse(search);
    search_obj.displayLevel = level;
    history.push(url + ("?" + queryString.stringify(search_obj)));
  }

  getReport(params) {
    const { business_id, report, ...rest } = params;
    let url = utils.format(URLS.DOWNLOAD_REPORT, [business_id, report]);
    if (report == "display-report") {
      rest.date_type = moment(new Date()).format("YYYY-MM-DD");
      delete rest.list;
      delete rest.branch;
    }
    url = `${url}&${queryString.stringify(rest)}`;
    if (report === "tickets") {
      url = utils.format(URLS.DOWNLOAD_TICKET_REPORT, [business_id]);
      rest.download = true;
      url = `${url}?${queryString.stringify(rest)}`;
    }
    this.setState({ downloadLink: url });
    if (report !== "mention") {
      return this.props.actions.getReport(params);
    } else if (report === "mention") {
      return this.props.mentionActions.getMentionsReport(business_id, params);
    }
  }

  handleBranchValue = (branch) => {
    this.setState({ branchSelected: branch });
  };

  handleListValue = (list) => {
    this.setState({ listSelected: list });
  };

  handleProviderValue = (provider) => {
    this.setState({ providerSelected: provider });
  };

  handleTicketSourceValue = (source) => {
    this.setState({ source: source });
  };

  handleTagValue = (tag) => {
    this.setState({ tagSelected: tag });
  };

  handleLocationListBy = (listby) => {
    this.setState({ locationlistby: listby });
  };

  handleKeyword = (keyword) => {
    this.setState({ keywordSelected: keyword });
  };
  handleFilterDataByReview(dats) {
    if (this.state.average_click_count === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["review"] - a[1]["review"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_count: 1,
        defaultfilterstate: ab,
        activeCol: "review",
      });
    } else if (this.state.average_click_count === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["review"] - b[1]["review"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_count: 0,
        defaultfilterstate: cd,
        activeCol: "review",
      });
    }
  }
  handleFilterDataByAvg_rating(dats) {
    if (this.state.average_click_count === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["avg rating"] - a[1]["avg rating"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_count: 1,
        defaultfilterstate: ab,
        activeCol: "rating",
      });
    } else if (this.state.average_click_count === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["avg rating"] - b[1]["avg rating"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_count: 0,
        defaultfilterstate: cd,
        activeCol: "rating",
      });
    }
  }
  handleFilterDataByNps(dats) {
    if (this.state.average_click_countForNps === 0) {
      const ef = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["nps"] - a[1]["nps"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForNps: 1,
        defaultfilterstateNps: ef,
        activeCol: "rating",
      });
    } else if (this.state.average_click_countForNps === 1) {
      const gh = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["nps"] - b[1]["nps"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForNps: 0,
        defaultfilterstateNps: gh,
        activeCol: "rating",
      });
    }
  }
  handleFilterDataByNpsReview(dats) {
    if (this.state.average_click_countForNps === 0) {
      const ef = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["review"] - a[1]["review"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForNps: 1,
        defaultfilterstateNps: ef,
        activeCol: "review",
      });
    } else if (this.state.average_click_countForNps === 1) {
      const gh = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["review"] - b[1]["review"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForNps: 0,
        defaultfilterstateNps: gh,
        activeCol: "review",
      });
    }
  }

  handleFilterDataBySentimentalNegative(dats) {
    if (this.state.average_click_countForSentimental === 0) {
      const ig = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["negative"] - a[1]["negative"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 1,
        defaultfilterstateSentimental: ig,
        activeCol: "negative",
      });
    } else if (this.state.average_click_countForSentimental === 1) {
      const kl = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["negative"] - b[1]["negative"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 0,
        defaultfilterstateSentimental: kl,
        activeCol: "negative",
      });
    }
  }
  handleFilterDataBySentimentalNeutral(dats) {
    if (this.state.average_click_countForSentimental === 0) {
      const ig = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["neutral"] - a[1]["neutral"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 1,
        defaultfilterstateSentimental: ig,
        activeCol: "neutral",
      });
    } else if (this.state.average_click_countForSentimental === 1) {
      const kl = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["neutral"] - b[1]["neutral"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 0,
        defaultfilterstateSentimental: kl,
        activeCol: "neutral",
      });
    }
  }
  handleFilterDataBySentimentalPositive(dats) {
    if (this.state.average_click_countForSentimental === 0) {
      const ig = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["positive"] - a[1]["positive"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 1,
        defaultfilterstateSentimental: ig,
        activeCol: "positive",
      });
    } else if (this.state.average_click_countForSentimental === 1) {
      const kl = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["positive"] - b[1]["positive"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 0,
        defaultfilterstateSentimental: kl,
        activeCol: "positive",
      });
    }
  }
  handleFilterDataBySentimentalTotal(dats) {
    if (this.state.average_click_countForSentimental === 0) {
      const ig = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["total"] - a[1]["total"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 1,
        defaultfilterstateSentimental: ig,
        activeCol: "total",
      });
    } else if (this.state.average_click_countForSentimental === 1) {
      const kl = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["total"] - b[1]["total"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 0,
        defaultfilterstateSentimental: kl,
        activeCol: "total",
      });
    }
  }
  handleFilterDataBySentimentalPercentage(dats) {
    if (this.state.average_click_countForSentimental === 0) {
      const ig = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(b[1]["positive %"] ? b[1]["positive %"] : 0) -
            Number(a[1]["positive %"] ? a[1]["positive %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 1,
        defaultfilterstateSentimental: ig,
      });
    } else if (this.state.average_click_countForSentimental === 1) {
      const kl = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(a[1]["positive %"] ? a[1]["positive %"] : 0) -
            Number(b[1]["positive %"] ? b[1]["positive %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 0,
        defaultfilterstateSentimental: kl,
      });
    }
  }
  handleFilterDataBySentimentalPercentagenegative(dats) {
    if (this.state.average_click_countForSentimental === 0) {
      const ig = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(b[1]["negative %"] ? b[1]["negative %"] : 0) -
            Number(a[1]["negative %"] ? a[1]["negative %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 1,
        defaultfilterstateSentimental: ig,
      });
    } else if (this.state.average_click_countForSentimental === 1) {
      const kl = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(a[1]["negative %"] ? a[1]["negative %"] : 0) -
            Number(b[1]["negative %"] ? b[1]["negative %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 0,
        defaultfilterstateSentimental: kl,
      });
    }
  }
  handleFilterDataBySentimentalPercentageneutral(dats) {
    if (this.state.average_click_countForSentimental === 0) {
      const ig = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(b[1]["neutral %"] ? b[1]["neutral %"] : 0) -
            Number(a[1]["neutral %"] ? a[1]["neutral %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 1,
        defaultfilterstateSentimental: ig,
      });
    } else if (this.state.average_click_countForSentimental === 1) {
      const kl = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(a[1]["neutral %"] ? a[1]["neutral %"] : 0) -
            Number(b[1]["neutral %"] ? b[1]["neutral %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForSentimental: 0,
        defaultfilterstateSentimental: kl,
      });
    }
  }
  handleFilterDataByTagsNegative(dats) {
    if (this.state.average_click_countForTags === 0) {
      const mn = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["negative"] - a[1]["negative"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 1,
        defaultfilterstateTags: mn,
        activeCol: "negative",
      });
    } else if (this.state.average_click_countForTags === 1) {
      const op = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["negative"] - b[1]["negative"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 0,
        defaultfilterstateTags: op,
        activeCol: "negative",
      });
    }
  }

  handleFilterDataByTagsNeutral(dats) {
    if (this.state.average_click_countForTags === 0) {
      const mn = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["neutral"] - a[1]["neutral"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 1,
        defaultfilterstateTags: mn,
        activeCol: "neutral",
      });
    } else if (this.state.average_click_countForTags === 1) {
      const op = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["neutral"] - b[1]["neutral"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 0,
        defaultfilterstateTags: op,
        activeCol: "neutral",
      });
    }
  }

  handleFilterDataByTagsPositive(dats) {
    if (this.state.average_click_countForTags === 0) {
      const mn = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["positive"] - a[1]["positive"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 1,
        defaultfilterstateTags: mn,
        activeCol: "positive",
      });
    } else if (this.state.average_click_countForTags === 1) {
      const op = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["positive"] - b[1]["positive"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 0,
        defaultfilterstateTags: op,
        activeCol: "positive",
      });
    }
  }

  handleFilterDataByTagsTotal(dats) {
    if (this.state.average_click_countForTags === 0) {
      const mn = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["total"] - a[1]["total"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 1,
        defaultfilterstateTags: mn,
        activeCol: "total",
      });
    } else if (this.state.average_click_countForTags === 1) {
      const op = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["total"] - b[1]["total"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 0,
        defaultfilterstateTags: op,
        activeCol: "total",
      });
    }
  }

  handleFilterDataByTagName(dats) {
    if (this.state.average_click_countForTags === 0) {
      const mn = Object.entries(dats)
        .sort(function (a, b) {
          return a[0].localeCompare(b[0]);
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 1,
        defaultfilterstateTags: mn,
        activeCol: "tags",
      });
    } else if (this.state.average_click_countForTags === 1) {
      const op = Object.entries(dats)
        .sort(function (a, b) {
          return b[0].localeCompare(a[0]);
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 0,
        defaultfilterstateTags: op,
        activeCol: "tags",
      });
    }
  }
  handleFilterDataByTagsPercentage(dats) {
    if (this.state.average_click_countForTags === 0) {
      const mn = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(b[1]["positive %"] ? b[1]["positive %"] : 0) -
            Number(a[1]["positive %"] ? a[1]["positive %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 1,
        defaultfilterstateTags: mn,
      });
    } else if (this.state.average_click_countForTags === 1) {
      const op = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(a[1]["positive %"] ? a[1]["positive %"] : 0) -
            Number(b[1]["positive %"] ? b[1]["positive %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 0,
        defaultfilterstateTags: op,
      });
    }
  }
  handleFilterDataByTagsPercentagenegative(dats) {
    if (this.state.average_click_countForTags === 0) {
      const mn = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(b[1]["negative %"] ? b[1]["negative %"] : 0) -
            Number(a[1]["negative %"] ? a[1]["negative %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 1,
        defaultfilterstateTags: mn,
      });
    } else if (this.state.average_click_countForTags === 1) {
      const op = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(a[1]["negative %"] ? a[1]["negative %"] : 0) -
            Number(b[1]["negative %"] ? b[1]["negative %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 0,
        defaultfilterstateTags: op,
      });
    }
  }
  handleFilterDataByTagsPercentageneutral(dats) {
    if (this.state.average_click_countForTags === 0) {
      const mn = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(b[1]["neutral %"] ? b[1]["neutral %"] : 0) -
            Number(a[1]["neutral %"] ? a[1]["neutral %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 1,
        defaultfilterstateTags: mn,
      });
    } else if (this.state.average_click_countForTags === 1) {
      const op = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(a[1]["neutral %"] ? a[1]["neutral %"] : 0) -
            Number(b[1]["neutral %"] ? b[1]["neutral %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForTags: 0,
        defaultfilterstateTags: op,
      });
    }
  }

  handleFilterDataByStarFour(dats) {
    if (this.state.average_click_countForStar === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["fourStar"] - a[1]["fourStar"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: ab,
        activeCol: "fourStar",
      });
    } else if (this.state.average_click_countForStar === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["fourStar"] - b[1]["fourStar"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: cd,
        activeCol: "fourStar",
      });
    }
  }

  handleFilterDataByStarFirst(dats) {
    if (this.state.average_click_countForStar === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["oneStar"] - a[1]["oneStar"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: ab,
        activeCol: "oneStar",
      });
    } else if (this.state.average_click_countForStar === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["oneStar"] - b[1]["oneStar"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: cd,
        activeCol: "oneStar",
      });
    }
  }

  handleFilterDataByStarSecond(dats) {
    if (this.state.average_click_countForStar === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["twoStar"] - a[1]["twoStar"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: ab,
        activeCol: "twoStar",
      });
    } else if (this.state.average_click_countForStar === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["twoStar"] - b[1]["twoStar"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: cd,
        activeCol: "twoStar",
      });
    }
  }

  handleFilterDataByStarThree(dats) {
    if (this.state.average_click_countForStar === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["threeStar"] - a[1]["threeStar"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: ab,
        activeCol: "threeStar",
      });
    } else if (this.state.average_click_countForStar === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["threeStar"] - b[1]["threeStar"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: cd,
        activeCol: "threeStar",
      });
    }
  }

  handleFilterDataByStarFive(dats) {
    if (this.state.average_click_countForStar === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["fiveStar"] - a[1]["fiveStar"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: ab,
        activeCol: "fiveStar",
      });
    } else if (this.state.average_click_countForStar === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["fiveStar"] - b[1]["fiveStar"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: cd,
        activeCol: "fiveStar",
      });
    }
  }

  handleFilterDataByStarTotal(dats) {
    if (this.state.average_click_countForStar === 0) {
      const mn = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["total"] - a[1]["total"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: mn,
        activeCol: "total",
      });
    } else if (this.state.average_click_countForStar === 1) {
      const op = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["total"] - b[1]["total"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: op,
        activeCol: "total",
      });
    }
  }
  handleFilterDataByStarAverage(dats) {
    if (this.state.average_click_countForStar === 0) {
      const mn = Object.entries(dats)
        .sort(function (a, b) {
          return b[1]["avg_rating"] - a[1]["avg_rating"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: mn,
        activeCol: "rating",
      });
    } else if (this.state.average_click_countForStar === 1) {
      const op = Object.entries(dats)
        .sort(function (a, b) {
          return a[1]["avg_rating"] - b[1]["avg_rating"];
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: op,
        activeCol: "rating",
      });
    }
  }
  handleFilterDataByStarPercentage(dats) {
    if (this.state.average_click_countForStar === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(b[1]["oneStar %"] ? b[1]["oneStar %"] : 0) -
            Number(a[1]["oneStar %"] ? a[1]["oneStar %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: ab,
      });
    } else if (this.state.average_click_countForStar === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(a[1]["oneStar %"] ? a[1]["oneStar %"] : 0) -
            Number(b[1]["oneStar %"] ? b[1]["oneStar %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: cd,
      });
    }
  }
  handleFilterDataByStarPercentageone(dats) {
    if (this.state.average_click_countForStar === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(b[1]["twoStar %"] ? b[1]["twoStar %"] : 0) -
            Number(a[1]["twoStar %"] ? a[1]["twoStar %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: ab,
      });
    } else if (this.state.average_click_countForStar === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(a[1]["twoStar %"] ? a[1]["twoStar %"] : 0) -
            Number(b[1]["twoStar %"] ? b[1]["twoStar %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: cd,
      });
    }
  }

  handleFilterDataByStarPercentagetwo(dats) {
    if (this.state.average_click_countForStar === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(b[1]["threeStar %"] ? b[1]["threeStar %"] : 0) -
            Number(a[1]["threeStar %"] ? a[1]["threeStar %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: ab,
      });
    } else if (this.state.average_click_countForStar === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(a[1]["threeStar %"] ? a[1]["threeStar %"] : 0) -
            Number(b[1]["threeStar %"] ? b[1]["threeStar %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: cd,
      });
    }
  }
  handleFilterDataByStarPercentagethree(dats) {
    if (this.state.average_click_countForStar === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(b[1]["fourStar %"] ? b[1]["fourStar %"] : 0) -
            Number(a[1]["fourStar %"] ? a[1]["fourStar %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: ab,
      });
    } else if (this.state.average_click_countForStar === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(a[1]["fourStar %"] ? a[1]["fourStar %"] : 0) -
            Number(b[1]["fourStar %"] ? b[1]["fourStar %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: cd,
      });
    }
  }
  handleFilterDataByStarPercentagefour(dats) {
    if (this.state.average_click_countForStar === 0) {
      const ab = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(b[1]["fiveStar %"] ? b[1]["fiveStar %"] : 0) -
            Number(a[1]["fiveStar %"] ? a[1]["fiveStar %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 1,
        defaultfilterstateStar: ab,
      });
    } else if (this.state.average_click_countForStar === 1) {
      const cd = Object.entries(dats)
        .sort(function (a, b) {
          return (
            Number(a[1]["fiveStar %"] ? a[1]["fiveStar %"] : 0) -
            Number(b[1]["fiveStar %"] ? b[1]["fiveStar %"] : 0)
          );
        })
        .reduce(
          (_sortedObj, [k, v]) => ({
            ..._sortedObj,
            [k]: v,
          }),
          {}
        );
      this.setState({
        average_click_countForStar: 0,
        defaultfilterstateStar: cd,
      });
    }
  }
  handleSortTicketData(dats, col_name) {
    if (this.state.average_click_countForTicket === 0) {
      const mn =
        dats &&
        Object.entries(dats)
          .sort(function (a, b) {
            return b[1][col_name] - a[1][col_name];
          })
          .reduce(
            (_sortedObj, [k, v]) => ({
              ..._sortedObj,
              [k]: v,
            }),
            {}
          );
      this.setState({
        average_click_countForTicket: 1,
        defaultfilterstateTicket: mn,
        activeCol: col_name,
      });
    } else if (this.state.average_click_countForTicket === 1) {
      const op =
        dats &&
        Object.entries(dats)
          .sort(function (a, b) {
            return a[1][col_name] - b[1][col_name];
          })
          .reduce(
            (_sortedObj, [k, v]) => ({
              ..._sortedObj,
              [k]: v,
            }),
            {}
          );
      this.setState({
        average_click_countForTicket: 0,
        defaultfilterstateTicket: op,
        activeCol: col_name,
      });
    }
  }

  emailReport() {
    return this.props.actions
      .emailReport(this.state.downloadLink)
      .then((res) => {
        utils.displayMessage("positive", res.data.msg);
      })
      .catch(() => {
        utils.displayMessage("negative", "Error");
      });
  }

  downloadReport() {
    const {
      location: { search },
      match: {
        params: { report },
      },
    } = this.props;
    var new_down_link = this.state.downloadLink;
    let newData = { ...queryString.parse(search) };
    let oldData = {
      ...queryString.parse(this.state.downloadLink.split("?")[1]),
    };
    if (newData.date_type !== oldData.date_type) {
      var old_date = "&date_type=" + oldData.date_type;
      var new_date = "&date_type=" + newData.date_type;
      new_down_link = this.state.downloadLink.replace(old_date, new_date);
    }
    return this.props.actions.downloadReport(new_down_link, report);
  }

  render() {
    const {
      match: {
        params: { report, reportType, view, business_id },
      },
      ratingData,
      visitorData,
      conversionData,
      reviewData,
      dataKeys,
      location_addresses,
      npsData,
      starData,
      sentimentData,
      tagsData,
      mentionsNumber,
      socialReach,
      mentionDataKeys,
      closedTickets,
      responseTime,
      errors,
    } = this.props;
    let { tableData } = this.props;
    let tableHeader;
    let graphHeader;    

    const defaultfilterstate = this.state.defaultfilterstate;
    const defaultfilterstateNps = this.state.defaultfilterstateNps;
    const defaultfilterstateSentimental =
      this.state.defaultfilterstateSentimental;
    const defaultfilterstateTags = this.state.defaultfilterstateTags;
    const defaultfilterstateStar = this.state.defaultfilterstateStar;
    const defaultfilterstateTicket = this.state.defaultfilterstateTicket;

    tableData =
      tableData && report == "display-report"
        ? errors === undefined
          ? tableData
          : []
        : tableData;
    const backgroundColor = [
      "#B49FDC",
      "#C5EBFE",
      "#FEFD97",
      "#A5F8CE",
      "#FEC9A7",
      "#F197C0",
      "#FEFFBF",
      "#FCDCDF",
      "#F498C2",
      "#B0EFEF",
      "#C997C6",
    ];
    if (reportType === "overtime") {
      tableHeader = "Date";
      graphHeader = "Overtime";
    } else if (reportType === "source") {
      tableHeader = "Source";
      graphHeader = "By Source";
    } else if (reportType === "location") {
      tableHeader = "Location";
      graphHeader = "By Location";
    } else if (reportType === "status") {
      tableHeader = "Status";
      graphHeader = "By Status";
    } else if (reportType === "assignees") {
      tableHeader = "Owner";
      graphHeader = "By Owner";
    } else if (reportType === "count") {
      tableHeader = "Tags";
      graphHeader = "By Count";
    }
    const ordering = queryString.parse(this.props.location.search).ordering;
    let chartData = [];
    dataKeys &&
      dataKeys.map((item, index) => {
        return chartData.push([
          item,
          tagsData.total[index],
          tagsData.positive[index],
          tagsData.negative[index],
          tagsData.neutral[index],
        ]);
      });

    reportType === "count" &&
      chartData.sort(function (a, b) {
        return a[1] - b[1];
      });
    reportType === "overtime" &&
      chartData.sort(function (a, b) {
        return a[0] - b[0];
      });
    reportType === "count" && chartData.reverse();

    this.props.location.search.includes("list=day") &&
      chartData.push(chartData[0]) &&
      chartData.push(chartData[1]) &&
      chartData.push(chartData[2]);
    chartData = this.props.location.search.includes("list=day")
      ? chartData.slice(3)
      : chartData;
    const chartLabels = [];
    const chartPositive = [];
    const chartNegative = [];
    const chartNeutal = [];
    const chartTotal = [];

    chartData = chartData.slice(0, 31);
    chartData.map((item) => {
      chartLabels.push(item[0]);
      chartPositive.push(item[2]);
      chartNegative.push(item[3]);
      chartNeutal.push(item[4]);
      chartTotal.push(item[1]);
    });

    const series = [
      {
        name: "Negative",
        data: chartNegative,
      },
      {
        name: "Neutral",
        data: chartNeutal,
      },
      {
        name: "Positive",
        data: chartPositive,
      },
    ];
    return (
      <Row className="content-area">
        <LoaderComponent loader={this.props.loader} />
        <Col xs={12} sm={12} className="p-3">
          <Row className="mt-2">
            <Col xs={12} sm={12}>
              <ReportsTab
                match={this.props.match}
                history={this.props.history}
                location={this.props.location}
                downloadReport={this.downloadReport}
                emailReport={this.emailReport}
                branch={this.state.branchSelected}
                list={this.state.listSelected}
                list_by={this.state.locationlistby}
                provider={this.state.providerSelected}
                tag={this.state.tagSelected}
                keyword={this.state.keywordSelected}
                printReport={() => window.print()}
                toggleShowPercent={this.toggleShowPercent}
                showPercent={this.state.showPercent}
                onTabChange={() => this.setState({ activeCol: "" })}
                branchList={this.props.filter.branch}
                listByData={this.props.filter.listBy}
              />
            </Col>
            <Col xs={12} sm={12}>
              <TabContent activeTab={report}>
                {view === "graph" && (
                  <TabPane tabId="reviews">
                    {ratingData && ratingData.length ? (
                      <CardWrapper
                        headerTitle={`Reviews and Ratings ${graphHeader}`}
                        className="p-3"
                      >
                        <ReportBarChart
                          dataKeys={dataKeys}
                          dataSets={[
                            {
                              label: "Ratings",
                              type: "line",
                              data: ratingData,
                              yAxisID: "y-axis-1",
                              backgroundColor: "rgba(225, 33, 105, 0.1)",
                              // background: 'linear-gradient(180deg, rgba(241,58,136,0.22335653011204482) 0%, rgba(244,97,160,0.18974308473389356) 67%, rgba(255,255,255,1) 100%)',
                              borderColor: CHART_BACKGROUND_COLOR_2,
                              legendColor: CHART_BACKGROUND_COLOR_2,
                              lineTension: 0.6,
                              // fill: 'true',
                              fill: {
                                target: "origin",
                                // above: "to right, #ff7e5f, #feb47b"
                              },
                            },
                            {
                              label: "Reviews",
                              type: "bar",
                              data: reviewData,
                              yAxisID: "y-axis-2",
                              backgroundColor: CHART_BACKGROUND_COLOR_1,
                              legendColor: CHART_BACKGROUND_COLOR_1,
                              hoverBackgroundColor: CHART_BACKGROUND_COLOR_1,
                            },
                          ]}
                          tooltip={{
                            callbacks: {
                              label: (context) => {
                                return context.dataset.label === "Ratings"
                                  ? `Total Ratings: ${context.formattedValue}`
                                  : `Total Reviews: ${context.formattedValue}`;
                              },
                            },
                          }}
                          primaryYLabel="Ratings"
                          secondaryYLabel="Reviews"
                        />
                      </CardWrapper>
                    ) : null}
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="reviews">
                    <ReportsTable
                      data={tableData}
                      newData={defaultfilterstate}
                      tableHeader={tableHeader}
                      column1="avg rating"
                      column2="review"
                      dataHeader={`Reviews and Ratings ${graphHeader}`}
                      headerKeys={["date", "avg_rating", "reviews"]}
                      activeCol={this.state.activeCol}
                      ordering={ordering}
                      sortFunction={() =>
                        this.handleFilterDataByReview(tableData)
                      }
                      sortFunctionFirst={() =>
                        this.handleFilterDataByAvg_rating(tableData)
                      }
                      showPercent={this.state.showPercent}
                    />
                  </TabPane>
                )}
                {view === "graph" && (
                  <TabPane tabId="nps">
                    {npsData && npsData.length ? (
                      <CardWrapper
                        headerTitle={`Net Promoter Score ${graphHeader}`}
                        className="p-3"
                      >
                        <ReportBarChart
                          dataKeys={dataKeys}
                          dataSets={[
                            {
                              label: "Net Promoter Score",
                              data: npsData,
                              backgroundColor: "rgba(225, 33, 105, 0.1)",
                              borderColor: CHART_BACKGROUND_COLOR_2,
                              legendColor: CHART_BACKGROUND_COLOR_2,
                              yAxisID: "y-axis-1",
                              lineTension: 0.6,
                              type: "line",
                              fill: {
                                target: "origin",
                              },
                            },
                            {
                              label: "Reviews",
                              data: reviewData,
                              type: "bar",
                              backgroundColor: CHART_BACKGROUND_COLOR_1,
                              legendColor: CHART_BACKGROUND_COLOR_1,
                              hoverBackgroundColor: CHART_BACKGROUND_COLOR_1,
                              yAxisID: "y-axis-2",
                            },
                          ]}
                          primaryYLabel="Net Promoter Score"
                          secondaryYLabel="Reviews"
                        />
                      </CardWrapper>
                    ) : null}
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="nps">
                    <ReportsTable
                      loader={this.props.loader}
                      data={tableData}
                      newData={defaultfilterstateNps}
                      tableHeader={tableHeader}
                      column1="nps"
                      column2="review"
                      activeCol={this.state.activeCol}
                      dataHeader={`Net Promoter Score ${graphHeader}`}
                      headerKeys={["date", "avg_rating", "reviews"]}
                      ordering={ordering}
                      sortFunction={() =>
                        this.handleFilterDataByNpsReview(tableData)
                      }
                      sortFunctionFirst={() =>
                        this.handleFilterDataByNps(tableData)
                      }
                      showPercent={this.state.showPercent}
                    />
                  </TabPane>
                )}
                {view === "graph" && (
                  <TabPane tabId="sentiment">
                    {sentimentData && sentimentData.positive ? (
                      <CardWrapper
                        headerTitle={`Sentiment Report ${graphHeader}`}
                        className="p-3"
                      >
                        <ReportBarChart
                          dataKeys={dataKeys}
                          dataSets={[
                            {
                              label: "Positive",
                              data: sentimentData.positive,
                              backgroundColor: "rgba(50, 213, 131, 0.1)",
                              borderColor: "#57e32c",
                              yAxisID: "y-axis-1",
                              lineTension: 0.6,
                              type: "line",
                              legendColor: "#57e32c",
                              fill: {
                                target: "origin",
                              },
                            },
                            {
                              label: "Negative",
                              data: sentimentData.negative,
                              backgroundColor: "#ffe234",
                              borderColor: "#ffe234",
                              yAxisID: "y-axis-1",
                              lineTension: 0.6,
                              type: "line",
                              legendColor: "#ffe234",
                            },
                            {
                              label: "Neutral",
                              data: sentimentData.neutral,
                              backgroundColor: "#ff4545",
                              borderColor: "#ff4545",
                              yAxisID: "y-axis-1",
                              lineTension: 0.6,
                              type: "line",
                              legendColor: "#ff4545",
                            },
                            {
                              label: "Total Reviews",
                              data: sentimentData.total,
                              type: "bar",
                              backgroundColor: CHART_BACKGROUND_COLOR_1,
                              hoverBackgroundColor: CHART_BACKGROUND_COLOR_1,
                              yAxisID: "y-axis-2",
                              legendColor: CHART_BACKGROUND_COLOR_1,
                            },
                          ]}
                          primaryYLabel="Ratings"
                          secondaryYLabel="Reviews"
                        />
                      </CardWrapper>
                    ) : null}
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="sentiment">
                    <ReportsTable
                      data={tableData}
                      newData={defaultfilterstateSentimental}
                      tableHeader={tableHeader}
                      column17="positive"
                      column18="negative"
                      column19="neutral"
                      column25="positive%"
                      column26="negative%"
                      column27="neutral%"
                      column4="total"
                      dataHeader={`Sentiment Report ${graphHeader}`}
                      headerKeys={["date", "avg_rating", "reviews"]}
                      ordering={ordering}
                      noFix
                      activeCol={this.state.activeCol}
                      sortFunction={() =>
                        this.handleFilterDataBySentimentalNegative(tableData)
                      }
                      sortFunctionFirst={() =>
                        this.handleFilterDataBySentimentalPositive(tableData)
                      }
                      sortFunctionTwo={() =>
                        this.handleFilterDataBySentimentalNeutral(tableData)
                      }
                      sortFunctionThree={() =>
                        this.handleFilterDataBySentimentalTotal(tableData)
                      }
                      handleFilterDataBySentimentalPercentage={() =>
                        this.handleFilterDataBySentimentalPercentage(tableData)
                      }
                      handleFilterDataBySentimentalPercentagenegative={() =>
                        this.handleFilterDataBySentimentalPercentagenegative(
                          tableData
                        )
                      }
                      handleFilterDataBySentimentalPercentageneutral={() =>
                        this.handleFilterDataBySentimentalPercentageneutral(
                          tableData
                        )
                      }
                      showPercent={this.state.showPercent}
                    />
                  </TabPane>
                )}
                {view === "graph" && (
                  <TabPane tabId="tags">
                    {tagsData &&
                    reportType !== "count" &&
                    reportType !== "overtime" &&
                    tagsData.positive ? (
                      <CardWrapper
                        headerTitle={`Tags Report ${graphHeader}`}
                        className="p-3"
                      >
                        <CombinationChart
                          dataKeys={dataKeys}
                          location_addresses={location_addresses}
                          data={[
                            {
                              label: "Positive",
                              data: tagsData.positive,
                              backgroundColor: "#57e32c",
                              borderColor: "#57e32c",
                              yAxisID: "primary-y-axis",
                              type: "line",
                              fill: "false",
                            },
                            {
                              label: "Negative",
                              data: tagsData.negative,
                              backgroundColor: "#ff4545",
                              borderColor: "#ff4545",
                              yAxisID: "primary-y-axis",
                              type: "line",
                              fill: "false",
                            },
                            {
                              label: "Neutral",
                              data: tagsData.neutral,
                              backgroundColor: "#FAE25A",
                              borderColor: "#FAE25A",
                              yAxisID: "primary-y-axis",
                              type: "line",
                              fill: "false",
                            },
                            {
                              label: "Total Count",
                              data: tagsData.total,
                              type: "bar",
                              backgroundColor: CHART_BACKGROUND_COLOR_1,
                              hoverBackgroundColor: CHART_HOVER_COLOR_1,
                              yAxisID: "primary-y-axis",
                            },
                          ]}
                          primaryYLabel="Count"
                        />
                      </CardWrapper>
                    ) : (
                        <CardWrapper
                        headerTitle={`Tags Report ${graphHeader}`}
                        className="p-3"
                      >
                       {dataKeys?.length > 0 ? (
                        <ApexChart
                        series={series}
                        dataKeys={chartLabels}
                        report={reportType}
                        overtime={reportType === "overtime"}
                      />
                       ) : (<NoData />)}
                        {/* <HorizontalBarChart 
                          dataKeys={chartLabels}
                          dataSets={[
                            {
                              label: "Positive",
                              data: chartNegative,
                              backgroundColor: "#57e32c",
                              borderColor: "#57e32c",
                              yAxisID: "y-axis",
                              fill: "false",
                            },
                            {
                              label: "Negative",
                              data: chartPositive,
                              backgroundColor: "#ff4545",
                              borderColor: "#ff4545",
                              yAxisID: "y-axis",
                              fill: "false",
                              borderRadius: 3
                            },
                            {                                                                                                                     
                              label: "Neutral",
                              data: chartNeutal,
                              backgroundColor: "#FAE25A",
                              borderColor: "#FAE25A",                                                                                                                                                                                                                                                                                                                             
                              yAxisID: "y-axis",
                              fill: "false",
                            },
                            
                          ]}
                          XLabel="Count"
                          YLabel="Tags"
                        /> */}
                      </CardWrapper> 
                    )}
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="tags">
                    {/* {Object.keys(tableData).length > 0 ? ( */}
                    <ReportsTable
                      data={tableData}
                      tableHeader={tableHeader}
                      newData={defaultfilterstateTags}
                      column28="positive"
                      column29="negative"
                      column3="neutral"
                      column4="total"
                      column6="positive %"
                      column7="negative %"
                      column8="neutral %"
                      dataHeader={`Tags Report ${graphHeader}`}
                      headerKeys={["date", "avg_rating", "reviews"]}
                      ordering={ordering}
                      activeCol={this.state.activeCol}
                      sortFunction={() =>
                        this.handleFilterDataByTagsNegative(tableData)
                      }
                      sortFunctionFirst={() =>
                        this.handleFilterDataByTagsPositive(tableData)
                      }
                      sortFunctionTwo={() =>
                        this.handleFilterDataByTagsNeutral(tableData)
                      }
                      handleFilterDataByTagsPercentage={() =>
                        this.handleFilterDataByTagsPercentage(tableData)
                      }
                      handleFilterDataByTagsPercentagenegative={() =>
                        this.handleFilterDataByTagsPercentagenegative(tableData)
                      }
                      handleFilterDataByTagsPercentageneutral={() =>
                        this.handleFilterDataByTagsPercentageneutral(tableData)
                      }
                      sortFunctionThree={() =>
                        this.handleFilterDataByTagsTotal(tableData)
                      }
                      handleFilterDataByTagName={() =>
                        this.handleFilterDataByTagName(tableData)
                      }
                      businessId={business_id}
                      queryData={queryString.parse(this.props.location.search)}
                      querySearch={this.props.location.search}
                      noFix
                      showPercent={this.state.showPercent}
                    />
                    {/* ) : (
                      <NoData />
                    )} */}
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="display-report">
                    <TableReviewChannel
                      dataHeader={"Review Channels Display Standing Report"}
                      data={tableData && tableData.result}
                    />
                    {/* <ReportsTable
                      data={tableData}
                      // tableHeader={tableHeader}
                      // column1='positive'
                      // column2='negative'
                      // column3='neutral'
                      // column4='total'
                      dataHeader={"Review Channels Display Standing Report"}
                      // headerKeys={['date', 'avg_rating', 'reviews']}
                      ordering={ordering}
                      // sortFunction={(on) => utils.toggleSort(this.props.history, this.props.location, on)}
                      noFix
                      zomatoSwiggyRating
                      showPercent={this.state.showPercent}
                    /> */}
                  </TabPane>
                )}
                {view === "graph" && (
                  <TabPane tabId="star">
                    <CardWrapper
                      headerTitle={`Star Rating ${graphHeader}`}
                      className="p-3"
                    >
                      <ReportsStackedBarChart
                        dataKeys={dataKeys}
                        dataSets={[
                          {
                            label: "Avg Rating",
                            data: starData ? starData.avg_rating : [],
                            type: "line",
                            backgroundColor: "rgba(225, 33, 105, 0.1)",
                            borderColor: CHART_BACKGROUND_COLOR_2,
                            legendColor: CHART_BACKGROUND_COLOR_2,
                            lineTension: 0.6,
                            yAxisID: "y-axis-1",
                            fill: {
                              target: "origin",
                            },
                          },
                          {
                            label: "1 Star",
                            data: starData ? starData.oneStar : [],
                            type: "bar",
                            backgroundColor: "#ff4545",
                            hoverBackgroundColor: "#ff4545",
                            legendColor: "#ff4545",
                            // yAxisID: "y-axis-2",
                          },
                          {
                            label: "2 Star",
                            data: starData ? starData.twoStar : [],
                            type: "bar",
                            backgroundColor: "#ffa534",
                            hoverBackgroundColor: "#ffa534",
                            legendColor: "#ffa534",
                            // yAxisID: "y-axis-2",
                          },
                          {
                            label: "3 Star",
                            data: starData ? starData.threeStar : [],
                            type: "bar",
                            backgroundColor: "#ffe234",
                            hoverBackgroundColor: "#ffe234",
                            legendColor: "#ffe234",
                            // yAxisID: "y-axis-2",
                          },
                          {
                            label: "4 Star",
                            data: starData ? starData.fourStar : [],
                            type: "bar",
                            backgroundColor: "#b7dd29",
                            hoverBackgroundColor: "#b7dd29",
                            legendColor: "#b7dd29",
                            // yAxisID: "y-axis-2",
                          },
                          {
                            label: "5 Star",
                            data: starData ? starData.fiveStar : [],
                            type: "bar",
                            backgroundColor: "#57e32c",
                            hoverBackgroundColor: "#57e32c",
                            legendColor: "#57e32c",
                            yAxisID: "y-axis-2",
                          },
                        ]}
                        primaryYLabel="Ratings"
                        secondaryYLabel="Count"
                      />
                    </CardWrapper>
                    {/* {starData && starData.oneStar && <VerticalBarChart starData={starData}   series={chartLabels} location_addresses={location_addresses}  dataKeys={chartLabels} report={reportType} overtime={reportType === 'overtime'}/>} */}
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="star">
                    <ReportsTable
                      data={tableData}
                      tableHeader={tableHeader}
                      newData={defaultfilterstateStar}
                      column10="total"
                      column16="avg rating"
                      column11={
                        <React.Fragment>
                          1 <Icon name="star" style={{ color: "#F9BB17" }} />
                        </React.Fragment>
                      }
                      column12={
                        <React.Fragment>
                          2 <Icon name="star" style={{ color: "#F9BB17" }} />
                        </React.Fragment>
                      }
                      column13={
                        <React.Fragment>
                          3 <Icon name="star" style={{ color: "#F9BB17" }} />
                        </React.Fragment>
                      }
                      column14={
                        <React.Fragment>
                          4 <Icon name="star" style={{ color: "#F9BB17" }} />
                        </React.Fragment>
                      }
                      column15={
                        <React.Fragment>
                          5 <Icon name="star" style={{ color: "#F9BB17" }} />
                        </React.Fragment>
                      }
                      column20={
                        <React.Fragment>
                          1 %<Icon name="star" style={{ color: "#F9BB17" }} />
                        </React.Fragment>
                      }
                      column21={
                        <React.Fragment>
                          2 %<Icon name="star" style={{ color: "#F9BB17" }} />
                        </React.Fragment>
                      }
                      column22={
                        <React.Fragment>
                          3 %<Icon name="star" style={{ color: "#F9BB17" }} />
                        </React.Fragment>
                      }
                      column23={
                        <React.Fragment>
                          4 %<Icon name="star" style={{ color: "#F9BB17" }} />
                        </React.Fragment>
                      }
                      column24={
                        <React.Fragment>
                          5 %<Icon name="star" style={{ color: "#F9BB17" }} />
                        </React.Fragment>
                      }
                      dataHeader={`Star Rating Report ${graphHeader}`}
                      headerKeys={["date", "avg_rating", "reviews"]}
                      ordering={ordering}
                      activeCol={this.state.activeCol}
                      handleFilterDataByStarFirst={() =>
                        this.handleFilterDataByStarFirst(tableData)
                      }
                      handleFilterDataByStarSecond={() =>
                        this.handleFilterDataByStarSecond(tableData)
                      }
                      handleFilterDataByStarThree={() =>
                        this.handleFilterDataByStarThree(tableData)
                      }
                      handleFilterDataByStarFour={() =>
                        this.handleFilterDataByStarFour(tableData)
                      }
                      handleFilterDataByStarFive={() =>
                        this.handleFilterDataByStarFive(tableData)
                      }
                      handleFilterDataByStarPercentage={() =>
                        this.handleFilterDataByStarPercentage(tableData)
                      }
                      handleFilterDataByStarPercentageone={() =>
                        this.handleFilterDataByStarPercentageone(tableData)
                      }
                      handleFilterDataByStarPercentagetwo={() =>
                        this.handleFilterDataByStarPercentagetwo(tableData)
                      }
                      handleFilterDataByStarPercentagethree={() =>
                        this.handleFilterDataByStarPercentagethree(tableData)
                      }
                      handleFilterDataByStarPercentagefour={() =>
                        this.handleFilterDataByStarPercentagefour(tableData)
                      }
                      handleFilterDataByStarTotal={() =>
                        this.handleFilterDataByStarTotal(tableData)
                      }
                      handleFilterDataByStarAverage={() =>
                        this.handleFilterDataByStarAverage(tableData)
                      }
                      noFix
                      showPercent={this.state.showPercent}
                    />
                  </TabPane>
                )}
                {
                  <TabPane tabId="mention">
                    {mentionsNumber &&
                      (reportType === "overtime" ? (
                        <MentionsChart
                          title={"Mentions Report"}
                          dataKeys={mentionDataKeys}
                          customLegend={true}
                          noTitle={true}
                          data={[
                            {
                              label: "# Of Mentions",
                              data: mentionsNumber,
                              pointStyle: "rectRounded",
                              rotation: 45,
                              radius: 3,
                              backgroundColor: "#FF6384",
                              borderWidth: 3,
                              borderColor: "#FF6384",
                              yAxisID: "primary-y-axis",
                              borderDash: [5, 5],
                              tension: 0.6,
                              type: "line",
                              fill: "false",
                            },
                            {
                              label: "Social Reach",
                              data: socialReach,
                              borderColor: "#36A2EB",
                              backgroundColor: "#81c0eb",
                              yAxisID: "secondary-y-axis",
                              type: "line",
                              fill: "start",
                              tension: 0.6,
                              pointStyle: "crossRot",
                              rotation: 45,
                              radius: 5,
                              borderWidth: 3,
                            },
                          ]}
                          primaryYLabel="# of Mentions"
                          secondaryYLabel="Social Reach"
                          minYPrimary={0}
                          minYSecondary={0}
                          scaleLabelCustom={{
                            primaryYAxis: {
                              fontColor: "#FF6384",
                              fontSize: 18,
                            },
                            secondaryYAxis: {
                              fontColor: "#36A2EB",
                              fontSize: 18,
                            },
                          }}
                        />
                      ) : (
                        <DoughnutChart
                          data={[
                            {
                              label: "mention",
                              data: mentionsNumber,
                              backgroundColor,
                            },
                            {
                              label: "social",
                              data: socialReach,
                              backgroundColor,
                            },
                          ]}
                          dataKeys={mentionDataKeys}
                          semi
                        />
                      ))}
                  </TabPane>
                }
                {view === "graph" && (
                  <TabPane tabId="visitor">
                    {visitorData && visitorData.length ? (
                      <CardWrapper
                        headerTitle={"Visitor Report"}
                        className="p-3"
                      >
                        <CampaignReportChart
                          dataKeys={dataKeys}
                          dataSets={[
                            {
                              label: "Visitors",
                              data: visitorData,
                              type: "bar",
                              backgroundColor: CHART_BACKGROUND_COLOR_1,
                              hoverBackgroundColor: CHART_BACKGROUND_COLOR_1,
                            },
                          ]}
                        />
                      </CardWrapper>
                    ) : null}
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="visitor">
                    <ReportsTable
                      showPercent={this.state.showPercent}
                      data={tableData}
                      tableHeader="Location"
                      column1="visitors"
                      dataHeader="Visitors"
                      headerKeys={["location", "visitors"]}
                      ordering={ordering}
                      sortFirstFunction={(on) =>
                        utils.toggleSort(
                          this.props.history,
                          this.props.location,
                          on
                        )
                      }
                    />
                  </TabPane>
                )}
                {view === "graph" && (
                  <TabPane tabId="conversion">
                    <Row>
                      <Col sm="6" xs="12">
                        {conversionData &&
                        Object.keys(conversionData).length ? (
                          <CardWrapper showDivider={false} height={10}>
                            <CombinationChart
                              chartType="pie"
                              dataKeys={[
                                "Email Invite Success",
                                "1st Request Sent",
                                "2nd Request Sent",
                                "Unsubscribed",
                              ]}
                              data={[
                                {
                                  data: [
                                    conversionData &&
                                      conversionData.success_email_invite &&
                                      conversionData.success_email_invite.percentage.toFixed(
                                        2
                                      ),
                                    conversionData &&
                                      conversionData.active_email_invite &&
                                      conversionData.active_email_invite.percentage.toFixed(
                                        2
                                      ),
                                    conversionData &&
                                      conversionData.in_process_email_invite &&
                                      conversionData.in_process_email_invite.percentage.toFixed(
                                        2
                                      ),
                                    conversionData &&
                                      conversionData.disabled_email_invite &&
                                      conversionData.disabled_email_invite.percentage.toFixed(
                                        2
                                      ),
                                  ],
                                  backgroundColor: [
                                    "#00CACA",
                                    "#EE588D",
                                    "green",
                                    "#5d5d5d",
                                  ],
                                  hoverBorderColor: [
                                    "#C0E0E0",
                                    "#FAD5E2",
                                    "#BFDFBF",
                                  ],
                                  hoverBorderWidth: 6,
                                },
                              ]}
                            />
                          </CardWrapper>
                        ) : null}
                      </Col>
                      <Col sm="6" xs="12">
                        {conversionData &&
                        Object.keys(conversionData).length ? (
                          <CardWrapper showDivider={false}>
                            <CombinationChart
                              chartType="pie"
                              dataKeys={[
                                "SMS Invite Success",
                                "1st Request Sent",
                                "2nd Request Sent",
                                "Unsubscribed",
                              ]}
                              data={[
                                {
                                  data: [
                                    conversionData &&
                                      conversionData.success_sms_invite &&
                                      conversionData.success_sms_invite.percentage.toFixed(
                                        2
                                      ),
                                    conversionData &&
                                      conversionData.active_sms_invite &&
                                      conversionData.active_sms_invite.percentage.toFixed(
                                        2
                                      ),
                                    conversionData &&
                                      conversionData.in_process_sms_invite &&
                                      conversionData.in_process_sms_invite.percentage.toFixed(
                                        2
                                      ),
                                    conversionData &&
                                      conversionData.disabled_sms_invite &&
                                      conversionData.disabled_sms_invite.percentage.toFixed(
                                        2
                                      ),
                                  ],
                                  backgroundColor: [
                                    "#00CACA",
                                    "#EC588D",
                                    "green",
                                    "#5d5d5d",
                                  ],
                                  hoverBorderColor: [
                                    "#C0E0E0",
                                    "#FAD5E2",
                                    "#BFDFBF",
                                  ],
                                  hoverBorderWidth: 5,
                                },
                              ]}
                            />
                          </CardWrapper>
                        ) : null}
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="conversion">
                    <UsageReportsTable
                      data={tableData}
                      tableHeader="Usage"
                      column1="visitors"
                      dataHeader="Usage Report"
                    />
                  </TabPane>
                )}
                {view === "graph" && (
                  <TabPane tabId="tickets">
                    {closedTickets && closedTickets.length ? (
                      <CardWrapper
                        headerTitle={`Average Resolution Time ${graphHeader}`}
                        className="p-3"
                      >
                        <ReportBarChart
                          dataKeys={dataKeys}
                          dataSets={[
                            {
                              label: "Response Time",
                              data: responseTime,
                              backgroundColor: "rgba(225, 33, 105, 0.1)",
                              borderColor: CHART_BACKGROUND_COLOR_2,
                              legendColor: CHART_BACKGROUND_COLOR_2,
                              yAxisID: "y-axis-1",
                              lineTension: 0.6,
                              type: "line",
                              fill: {
                                target: "origin",
                              },
                            },
                            {
                              label:
                                dataKeys[0] === "total tickets"
                                  ? "Tickets"
                                  : "Closed Tickets",
                              data: closedTickets,
                              type: "bar",
                              backgroundColor: CHART_BACKGROUND_COLOR_1,
                              hoverBackgroundColor: CHART_BACKGROUND_COLOR_1,
                              legendColor: CHART_BACKGROUND_COLOR_1,
                              yAxisID: "y-axis-2",
                              // barPercentage: 0.5,
                              // barThickness: 20,
                              // maxBarThickness: 20,
                            },
                          ]}
                          // tooltip={{
                          //   callbacks: {
                          //     label: (context) => {
                          //       return context.dataset.label === "Ratings"
                          //         ? `Total Ratings: ${context.formattedValue}`
                          //         : `Total Reviews: ${context.formattedValue}`;
                          //     },
                          //   },
                          // }}
                          primaryYLabel="Response Time (hours)"
                          secondaryYLabel={
                            dataKeys[0] === "total tickets"
                              ? "Tickets"
                              : "Closed Tickets"
                          }
                        />
                      </CardWrapper>
                    ) : null}
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="tickets">
                    <TicketTable
                      data={tableData}
                      tableHeader={tableHeader}
                      column1="num of tickets"
                      column2="unresponded reviews"
                      column3="open tickets"
                      column4="close tickets"
                      column5="percentage open tickets"
                      column6="response time msg"
                      column10="assigned user response duration"
                      column7=">24Hrs"
                      column8=">48Hrs"
                      column9=">7Days"
                      dataHeader={`Tickets ${graphHeader}`}
                      activeCol={this.state.activeCol}
                      handleSortTicketData={this.handleSortTicketData}
                      newData={defaultfilterstateTicket}
                    />
                  </TabPane>
                )}
                {view === "graph" && (
                  <TabPane tabId="standard">
                    <SurveyGraphAll tableData={tableData} />
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="standard">
                    <SurveyTableAll
                      tableData={tableData}
                      getUpdatedSurveyReport={this.getUpdatedSurveyReport}
                      displayLevel={this.state.displayLevel}
                      isDisplayBranchWise={this.state.isDisplayBranchWise}
                      isDisplayPageWise={this.state.isDisplayPageWise}
                      toggleDisplayLevel={this.toggleDisplayLevel}
                    />
                  </TabPane>
                )}
                {view === "graph" && (
                  <TabPane tabId="textual">
                    <SurveyGraphAll tableData={tableData} />
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="textual">
                    <SurveyTextualTable
                      tableData={tableData}
                      getUpdatedSurveyReport={this.getUpdatedSurveyReport}
                      displayLevel={this.state.displayLevel}
                      isDisplayBranchWise={this.state.isDisplayBranchWise}
                      isDisplayPageWise={this.state.isDisplayPageWise}
                      toggleDisplayLevel={this.toggleDisplayLevel}
                    />
                  </TabPane>
                )}
                {view === "graph" && (
                  <TabPane tabId="ticket-survey">
                    <SurveyGraphAll tableData={tableData} />
                  </TabPane>
                )}
                {view === "table" && (
                  <TabPane tabId="ticket-survey">
                    <SurveyTextualTable
                      tableData={tableData}
                      getUpdatedSurveyReport={this.getUpdatedSurveyReport}
                      displayLevel={this.state.displayLevel}
                      isDisplayBranchWise={this.state.isDisplayBranchWise}
                      isDisplayPageWise={this.state.isDisplayPageWise}
                      toggleDisplayLevel={this.toggleDisplayLevel}
                    />
                  </TabPane>
                )}
              </TabContent>
            </Col>
          </Row>
        </Col>
        {/* <Col xs={12} sm={3} className="p-2 pt-3">
          <ReportsFilter
            ticket={report === 'tickets'}
            showLocationFilter={report !== 'mention' && (report !== 'visitor' && reportType !== 'location')}
            showListByFilter={reportType === 'overtime'}
            showListByLoctionFilter={report !== 'mention' && (reportType === 'location' || report === 'visitor')}
            showSourceFilter={(reportType === 'location' || (reportType === 'overtime' && report !== 'mention') || (reportType === 'count' && report !== 'mention') || reportType === 'keyword' || reportType === 'assignees' || reportType === 'status')}
            history={this.props.history}
            location={this.props.location}
            handleBranchValue={this.handleBranchValue}
            handleListValue={this.handleListValue}
            handleProviderValue={this.handleProviderValue}
            handleTagValue={this.handleTagValue}
            handleLocationListBy={this.handleLocationListBy}
            handleKeyword={this.handleKeyword}
            businessId={business_id}
            match={this.props.match}
            reviewSourceOptions={reviewSourceOptions && reviewSourceOptions.choices}
            keywordOptions={report === 'mention' && keywordOptions && keywordOptions.results}
            showTagFilter={reportType === 'overtime' || reportType === 'count'}
            tagOptions={this.props.tagOptions}
            hideDateShowCalander={report === 'zomato-swiggy' ? true : false}
            branchTagOptions={branchTagOptions && branchTagOptions.results}
            showTicketSourceFilter={report === 'tickets'}
            ticketSourceOptions={ticketSourceOptions && ticketSourceOptions.choices}
            handleTicketSourceValue={this.handleTicketSourceValue}
          />
        </Col> */}
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    state: state,
    dataKeys: state.report.dataKeys,
    mentionsNumber: state.mentions.mentions,
    mentionDataKeys: state.mentions.dataKeys,
    socialReach: state.mentions.reachData,
    location_addresses: state.report.location_addresses,
    ratingData: state.report.rating,
    tableData: state.report.tableData,
    npsData: state.report.npsData,
    sentimentData: state.report.sentimentData,
    tagsData: state.report.tagsData,
    reviewData: state.report.reviewData,
    visitorData: state.report.visitorData,
    conversionData: state.report.conversionData,
    closedTickets: state.report.closedTickets,
    responseTime: state.report.responseTime,
    errors: state.report.errors,
    loader: state.common.loader,
    reviewSourceOptions: state.review.review_source_options,
    keywordOptions: state.mentions.keywordsList,
    tagOptions: state.common.tag,
    starData: state.report.starData,
    branchTagOptions: state.common.branch_tag_options,
    ticketSourceOptions: state.tickets.source_options,
    filter: state.filter,
  };
};

ReportsPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  CommonActions: PropTypes.instanceOf(Object),
  reviewSourceOptions: PropTypes.instanceOf(Object),
  branchTagOptions: PropTypes.instanceOf(Object),
  filterAction: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ReportActions, dispatch),
    CommonActions: bindActionCreators(CommonActions, dispatch),
    sourceFilterAction: bindActionCreators(ReviewsActions, dispatch),
    mentionActions: bindActionCreators(MentionActions, dispatch),
    ticketActions: bindActionCreators(ticketActions, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsPage);
