import React from "react";
import BulkProductFilter from "./BulkProductFilter";
import BulkProductList from "./bulkProductList";
import RootHeader from "../../../../modules/listing/bulkupdate/RootHeader";
import * as setSelectedTab from "../../../../actions/common/setSelectedTabAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoaderComponent from "../../../../components/common/loaderComponent";

class BulkProduct extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.actionsSelectTab.setSelectedTab(2);
  }

  render() {
    const { loader } = this.props;
    return (
      <>
        <div style={{ padding: "16px" }}>
          <LoaderComponent loader={loader} />
          <RootHeader defaultValue={2} history={this.props.history} />
          <div>
            <BulkProductFilter
              history={this.props.history}
              location={this.props.location}
            />
          </div>
          <BulkProductList />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkProduct);
