import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import * as commonAction from "../common/commonAction";

export function getDepartmentSuccess(data) {
  return { type: types.GET_DEPARTMENT_SUCCESS, data };
}

export function getDepartmentFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_DEPARTMENT_FAILURE, data };
}

export const getDepartment = (data, businessId) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.GET_DEPARTMENT, [businessId]), { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getDepartmentSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getDepartmentFailure(err));
        throw err.response.data;
      });
  };
};

export function departmentAddSuccess(data) {
  return { type: types.CREATE_DEPARTMENT_SUCCESS, data };
}

export function departmentAddFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.CREATE_DEPARTMENT_FAILURE, data };
}

export const departmentAdd = (data, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(utils.format(URLS.GET_DEPARTMENT, [businessId]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(departmentAddSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(departmentAddFailure(err));
        throw err.response.data;
      });
  };
};

export function departmentEditSuccess(data) {
  return { type: types.EDIT_DEPARTMENT_SUCCESS, data };
}

export function departmentEditFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.EDIT_DEPARTMENT_FAILURE, data };
}

export const departmentEdit = (data) => {
  const { deps: name, business_id: businessId, departmentId: id } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .put(utils.format(URLS.DEPARTMENT, [businessId, id]), name)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(departmentEditSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(departmentEditFailure(err));
        throw err.response.data;
      });
  };
};

export function departmentDeleteSuccess(data) {
  return { type: types.DELETE_DEPARTMENT_SUCCESS, data };
}

export function departmentDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.DELETE_DEPARTMENT_FAILURE, data };
}

export const departmentDelete = (business_id, data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(utils.format(URLS.DEPARTMENT, [business_id, data.id]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(departmentDeleteSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(departmentDeleteFailure(err));
        throw err.response.data;
      });
  };
};
