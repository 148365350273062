import React from "react";
import { Divider, Button } from "semantic-ui-react";
import classnames from "classnames";
import TagEditModal from "../common/TagEditModal";
import { capitalize } from "../../libs/utils";
import { FPButton } from "../common/new";

class TagsListModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.addOrDeselectTags = this.addOrDeselectTags.bind(this);
  }

  addOrDeselectTags = (group_name, tagName) => {
    const { input } = this.props;
    let existingGroup =
      input.value.length &&
      input.value.find((item) => item.group === group_name);
    let newSelectedTags = [...input.value];
    if (existingGroup) {
      newSelectedTags = newSelectedTags.filter(
        (item) => item.group !== group_name
      );
      if (existingGroup.tags.find((item) => item === tagName)) {
        existingGroup.tags = [
          ...existingGroup.tags.filter((item) => item !== tagName),
        ];
      } else {
        existingGroup.tags = [...existingGroup.tags, tagName];
      }
      newSelectedTags = existingGroup.tags.length
        ? [...newSelectedTags, existingGroup]
        : [...newSelectedTags];
      input.onChange([...newSelectedTags]);
    } else {
      const selectedTag = { group: group_name, tags: [tagName] };
      input.onChange([...input.value, selectedTag]);
    }
    this.forceUpdate();
  };

  render() {
    const { isContactTagsOpen, toggleContactTags, header, tagsList, input } =
      this.props;
    return (
      <div>
          <TagEditModal
            isOpen={isContactTagsOpen}
            toggle={toggleContactTags}
            noClose={true}
            header={header}
            width="750px"
            height="600px"
            bodyClass='py-0 my-2'
            dialogActions={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <FPButton
                    onClick={toggleContactTags}
                    className="mr-3"
                    backgroundColor="rgba(0, 181, 173, 1)"
                    style={{ height: "34px" }}
                  >
                    Cancel
                  </FPButton>
                  <FPButton
                    onClick={toggleContactTags}
                    style={{ height: "34px" }}
                  >
                    Save
                  </FPButton>
                </div>
            }
          >
            <div className="d-flex flex-column gap-20">
              {tagsList &&
                tagsList.map((item) => {
                  const groupChecked =
                    input.value.length &&
                    input.value.find((group) => group.group === item.name);
                  return (
                    <div
                      key={item.id}
                      className="py-3"
                      style={{
                        border: "1px solid #d0d0d0",
                        borderRadius: "8px",
                        background: "#ffffff",
                      }}
                    >
                      <span
                        className="tag-group-text"
                        style={{
                          fontSize: "16px",
                          fontWeight: "500",
                          color: "rgba(27, 28, 29, 1)",
                          marginLeft: "15px",
                          // color: "rgba(0, 181, 173, 1)",
                        }}
                      >
                        {capitalize(item.name)}
                      </span>
                      <Divider className="mt-3" />
                      <div className="d-flex align-items-center flex-wrap">
                        {item.tag_objs.map(({ name, id }, index) => {
                          return (
                            <div
                              className={classnames(
                                "ml-3 mt-2 contact-tag-item",
                                {
                                  "selected-contact-tag":
                                    groupChecked &&
                                    groupChecked.tags.find(
                                      (tag) => tag === name
                                    ),
                                }
                              )}
                              onClick={() =>
                                this.addOrDeselectTags(item.name, name)
                              }
                              key={index}
                            >
                              <span
                                className="tag-text-view"
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "400",
                                }}
                              >
                                {name}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </TagEditModal>
      </div>
    );
  }
}

export default TagsListModal;
