import React from "react";
import { Bar } from "react-chartjs-2";
import { Box, Typography, IconButton } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";

const data = [
  { name: "Name", mentions: 1 },
  { name: "Categories", mentions: 3 },
  { name: "Reviews", mentions: 0 },
  { name: "Services", mentions: 0 },
  { name: "Products", mentions: 0 },
  { name: "Description", mentions: 16 },
];

const MentionAndSentiment = () => {
  // Extract labels and mentions from data
  const labels = data.map((item) => item.name);
  const mentions = data.map((item) => item.mentions);

  const chartData = {
    labels, // X-axis labels
    datasets: [
      {
        label: "Keyword Mentions",
        data: mentions, // Y-axis values
        backgroundColor: "#5E35B1",
        borderColor: "#5E35B1",
        borderWidth: 2,
        borderRadius: 5,
        barThickness: 40,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          color: "rgba(200, 200, 200, 0.2)",
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
    },
  };

  return (
    <div className="hyperlocal-ranking-data">
      <h3>Mentions And Sentiment Analysis</h3>
      <div>
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: 1,
            p: 3,
            width: "100%",
          }}
        >
          {/* Header Section */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Keyword Mentions In Your Listing{" "}
              <Typography
                component="span"
                variant="body2"
                sx={{ color: "gray" }}
              >
                Total {mentions.reduce((a, b) => a + b, 0)} Mentions
              </Typography>
            </Typography>

            {/* Share Button */}
            <IconButton>
              <ShareIcon />
            </IconButton>
          </Box>

          {/* Chart Section */}
          <div style={{ height: "400px" }}>
            <Bar data={chartData} options={options} />
          </div>
        </Box>
      </div>
    </div>
  );
};

export default MentionAndSentiment;
