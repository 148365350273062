import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Icon, Popup } from "semantic-ui-react";
import * as URLS from "../../libs/apiUrls";
import { InputField } from "react-semantic-redux-form";
import { Field, reduxForm } from "redux-form";
import SelectComponent from "../../components/common/Select";
import RadioComponent from "../../components/common/RadioComponent";
import EmailPreview from "./EmailPreview";
import PhonePreview from "./PhonePreview";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { FPButton } from "../common/new";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";

const validate = (values) => {
  const errors = {};
  if (!values.template) errors.template = "Select Template";
  if (!values.name) errors.name = "Enter Name";

  return errors;
};

class CampaignForm extends React.PureComponent {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.branch_id !== prevState.branch_id) {
      return { branch_id: nextProps.branch_id };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      branch_id: this.props.branch_id,
      selectedTemplate: {},
    };
    this.onUpload = this.onUpload.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
  }

  onUpload = (file) => {
    this.setState({ uploadedFile: file });
  };

  downloadExcel = () => {
    window.location.href = "https://api.famepilot.com/accounts/contact-list-format/";
  };

  submit = (data) => {
    data = data.reply_to_business ? data : { ...data, reply_to_business: false };
    this.props.onSubmit(data);
  };

  render() {
    const {
      handleSubmit,
      type,
      errors,
      onSelectBtnContactsClick,
      initialValues,
      business_id,
      list,
      newsLetterList,
      selectedTemplate,
      setSelectedTemplate,
    } = this.props;
    const campaignType = type === "review" ? "Review" : "Newsletter";
    const handleChange = (val) => {
      const templates = type === "review" ? list : newsLetterList;
      const selectedTemplate = templates.find((template) => template.id === val);
      if (selectedTemplate) {
        setSelectedTemplate(selectedTemplate);
      }
    };

    const CustomInput = ({ ...rest }) => <InputField {...rest} className="w-100" />;
    const CustomSelect = ({ ...rest }) => <SelectComponent {...rest} className="upward" />;

    return (
      <form className="grid-form" onSubmit={handleSubmit(this.submit)}>
        <div style={{ backgroundColor: "white", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, padding: 16 }}>
          {errors && errors.non_field_errors
            ? errors.non_field_errors.map((error, index) => (
                <p key={index} className="text-danger">
                  {error}
                </p>
              ))
            : null}
          <Row fluid className="py-2">
            <Col xs={12} sm={12} md={6}>
              <FPLabel>
                Name&nbsp;
                <Popup content="Enter the campaign name for future references" trigger={<Icon name="info circle" />} />
              </FPLabel>
              <Field name="name" component={CustomInput} placeholder="Name" type="text" />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <FPLabel>Template</FPLabel>
              <Field
                name="template"
                type="template"
                selectOnBlur={false}
                component={CustomSelect}
                onChange={(_, val) => handleChange(val)}
                placeholder="Template"
                apiurl={`${URLS.CAMPAIGN}${business_id}/template/${campaignType}/options/`}
              />
            </Col>
          </Row>
          <Row fluid className="py-2">
            <Col xs={12} sm={12} md={6}>
              {selectedTemplate.method === "Email" && (
                <Field
                  name="reply_to_business"
                  component={RadioComponent}
                  label={
                    <div>
                      Reply To
                      <Popup trigger={<Icon name="info circle" />}>
                        Replies will be sent to the location Email Address. You can edit the email address in Setup.
                      </Popup>
                    </div>
                  }
                  checked={initialValues && initialValues.reply_to_business}
                  options={[
                    { display_name: "Location Email Address", value: true },
                    { display_name: "no_reply@famepilot.com", value: false },
                  ]}
                />
              )}
            </Col>
          </Row>
          <FPButton
            className="d-flex ml-auto"
            label="Select Contacts"
            size="small"
            onClick={onSelectBtnContactsClick}
            endIcon={<ArrowForwardOutlinedIcon />}
          />
        </div>
        {selectedTemplate && selectedTemplate.id && (
          <Row fluid className="py-2">
            <Col sm="12" className="mt-4">
              <div className={`justify-content-center d-flex ${selectedTemplate.method === "Sms" && "remove-card"}`}>
                {selectedTemplate.method === "Email" ? (
                  <EmailPreview template={selectedTemplate} type={type} />
                ) : (
                  <PhonePreview template={selectedTemplate} type={type} />
                )}
              </div>
            </Col>
          </Row>
        )}
      </form>
    );
  }
}

CampaignForm = reduxForm({
  // eslint-disable-line
  form: "CampaignForm",
  validate,
  enableReinitialize: true,
})(CampaignForm);

CampaignForm = connect((state) => {
  // eslint-disable-line
  return {};
})(CampaignForm);

export default CampaignForm;
