import React from "react";
import moment from "moment";
import { Popup } from "semantic-ui-react";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { FPButton, FPToolTip } from "../common/new";
import plus from "../../images/svg/plus-black.svg";
import locationicon from "../../images/svg/location.svg";
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import PaginationComponent from "../common/Pagination";
import BranchSelector from "../business/BranchSelector";
import FPLabel from "../common/new/forms/ui/FPLabel";
import editIcon from "../../images/svg/EditPen.svg";
import deleteIcon from "../../images/svg/trash.svg";
import ConfirmationModal from "../common/ConfirmationModal";

class BranchCompetitors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  toggleConfirm = (confirmationFunction) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmationFunction,
    });
  };

  render() {
    const {
      addBranchCompetitors,
      addCompetitorsLinks,
      toggle,
      deleteBranch,
      branchCompetitors,
      rerenderParentCallback,
      blankField,
      location,
    } = this.props;
    const branchCompetitorsList = branchCompetitors ? branchCompetitors : [];
    const { isConfirmOpen, confirmationFunction } = this.state;
    return (
      <>
        {/* <div className="mt-3 mb-4">
          <div className="mb-3">
            <span
              style={{
                fontSize: "18px",
                fontWeight: "400",
                color: "rgba(100, 101, 101, 1)",
              }}
            >
              Branch Competitors
            </span>
          </div>
          <div className="selectLocation">
            <div className="col-6">
              <FPLabel style={{ color: "#00B5AD", fontSize: "15px" }}>Select Location</FPLabel>
              <BranchSelector rerenderParentCallback={rerenderParentCallback} blankField={blankField} />
            </div>
          </div>
        </div> */}

        <div className="ui-table-responsive mt-2 mb-2">
          <CardWrapper
            showDivider={false}
            headerTitle={
              <div className="d-flex justify-content-between align-items-center my-2">
                <div className="d-flex gap-15 my-3">
                  <div className="d-flex align-items-center">
                    <Box component="span" className="statusDot" sx={{ background: "#17B26A !important" }} />
                    <span>Connected</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <Box component="span" className="statusDot" sx={{ background: "#F04438 !important" }} />
                    <span>Setup Review Link</span>
                  </div>
                  <div className="d-flex align-items-center">
                    <Box component="span" className="statusDot" sx={{ background: "#FDB022 !important" }} />
                    <span>Disconnected</span>
                  </div>
                </div>
                <div className="d-flex gap-10">
                  <FPButton
                    className="d-flex ml-auto"
                    label="Add Branch Competitor"
                    size="small"
                    fontSize={15}
                    onClick={addBranchCompetitors}
                    style={{ height: "35px" }}
                    endIcon={<img src={locationicon} height="15px" width="15px" />}
                  />
                  <FPButton
                    className="d-flex ml-auto"
                    label="Add Competitor Link"
                    size="small"
                    fontSize={15}
                    onClick={addCompetitorsLinks}
                    endIcon={<img src={plus} />}
                    style={{ height: "35px" }}
                    variant="outline"
                  />
                </div>
              </div>
            }
          >
            <div className="ui-table-responsive">
              <Table className="fp-table fp-table-center">
                <TableHead>
                  <TableRow>
                    <TableCell className="width-insights-th">Competitor Link</TableCell>
                    <TableCell>Brand Name</TableCell>
                    <TableCell>Created On</TableCell>
                    <TableCell>Created By</TableCell>
                    {branchCompetitorsList &&
                      branchCompetitorsList?.results &&
                      branchCompetitorsList?.results?.length > 0 &&
                      Object.entries(branchCompetitorsList?.results?.[0].connections).map(([key, value]) => {
                        return (
                          <TableCell textAlign="center" key={key}>
                            {!value ? value : ""}
                            <img
                              src={`https://dev-api.famepilot.com/static/images/${
                                key === "makemytrip" ? "mmt" : key === "practo" ? "Practo": key
                              }.png`}
                              alt=""
                              height="22"
                              width="22"
                              className="cursor-pointer"
                              title={key}
                            ></img>
                          </TableCell>
                        );
                      })}
                    {/* <Table.HeaderCell textAlign='center'>
                    <img
                      src={googleIcon}
                      alt=""
                      height="16"
                      width="15"
                      className="cursor-pointer"
                      title={'google'}></img>
                  </Table.HeaderCell> */}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {branchCompetitorsList && branchCompetitorsList?.results?.length
                    ? branchCompetitorsList?.results?.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell className="width-insights">
                            <FPToolTip title={item.location_address}>{item.location_address}</FPToolTip>
                          </TableCell>
                          <TableCell>{item.competition}</TableCell>
                          <TableCell>{moment(item.created_at).format("DD-MM-YYYY")}</TableCell>
                          <TableCell>{item.created_by}</TableCell>
                          {Object.entries(item.connections).map(([key, value]) => {
                            return (
                              <TableCell key={key}>
                                <Popup
                                  trigger={
                                    <span
                                      onClick={() => {
                                        this.props.editLinks(item.id);
                                      }}
                                      className={`status-dot ${
                                        value === "active" ? "bg-Green" : value === "inactive" ? "bg-Orange" : "bg-Red"
                                      }`}
                                    />
                                  }
                                  content={
                                    value === "active"
                                      ? "Connected"
                                      : value === "inactive"
                                      ? "Disconnected"
                                      : "Setup Review Link"
                                  }
                                />
                              </TableCell>
                            );
                          })}
                          {/* <Table.Cell textAlign='center'>
                          <Popup
                            trigger={
                              <span onClick={() => { this.props.editLinks(item.id); }} className={`status-dot ${item.connections.google === 'active' ? 'bg-Green' : (item.connections.google === 'inactive' ? 'bg-Orange' : 'bg-Red')}`} />
                            }
                            content={item.connections.google === 'active' ? 'Connected' : (item.connections.google === 'inactive' ? 'Disconnected' : 'Setup Review Link')}
                          /></Table.Cell>
                        */}
                          <TableCell>
                            <div className="actionButton">
                              <FPToolTip title={"Edit"}>
                                <img
                                  src={editIcon}
                                  role="button"
                                  alt="edit"
                                  onClick={() => {
                                    toggle(item.id);
                                  }}
                                />
                              </FPToolTip>
                              <FPToolTip title={"Delete"}>
                                <img
                                  src={deleteIcon}
                                  role="button"
                                  alt="delete"
                                  onClick={() => this.toggleConfirm(() => deleteBranch(item.id))}
                                />
                              </FPToolTip>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    : []}
                </TableBody>
              </Table>
            </div>
            <PaginationComponent
              count={branchCompetitorsList && branchCompetitorsList.count}
              location={location}
              pageSizeCookie="offer_page"
              className="pb-0"
            />
          </CardWrapper>
        </div>
        <ConfirmationModal
          header="Are you sure!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader={`Are you sure you want to delete this branch competitor?`}
          isOpen={isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={confirmationFunction}
        />
      </>
    );
  }
}
export default BranchCompetitors;
