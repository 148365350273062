/* eslint-disable */
import React, { useEffect, useState } from "react";
import { serviceOptions } from "../../libs/constant";
import * as URLS from "../../libs/apiUrls";
import { getAllParamsInArrayForm, getParams, removeLifetimeFilter } from "../../libs/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonAction from "../../actions/common/commonAction";
import * as filterListActions from "../../actions/common/filterListActions";
import { FPButton, FPFilter, FPToolTip } from "../../components/common/new";
import FormModal from "../../components/common/FormModal";
import cookie from "react-cookies";
import { IconButton } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../images/svg/Download.svg";
import moment from "moment";

const TicketFilter = (props) => {
  const {
    common,
    history,
    filter,
    filterAction,
    match,
    source,
    downloadExcel,
    teamList,
    replyOptions,
    ticketSourceOptions,
    pageSizeCookie,
  } = props;
  const [isMoreThanNinety, setIsMoreThanNinety] = useState(false);

  const dateType = getParams(location.search, "date_type");
  const startDate = getParams(location.search, "start_date");
  const endDate = getParams(location.search, "end_date");
  const service_category = cookie.load("service_category");

  const fetchFilterList = async () => {
    const {
      params: { business_id },
    } = match;
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch("", `${URLS.BUSINESS_SETUP}${business_id}/branch/`),
        filterAction.getContentType("", `${URLS.CONTENT_OPTIONS}`),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  const handleReviewDownloadFlow = () => {
    if (dateType === "other") {
      const M_startDate = moment(startDate);
      const M_endDate = moment(endDate);
      const daysDifference = M_endDate.diff(M_startDate, "days");
      if (daysDifference > 90) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    } else {
      if (["prior_6_month", "current_year", "prior_12_months"].indexOf(dateType) > -1) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    }
  };

  useEffect(() => {
    fetchFilterList();
  }, []);

  const handleDefaultFilter = (list, key) => {
    const filter = location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable = defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list.map((item) => {
      item["isChecked"] = isKeyFilterAvailable && defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };

  const filterList = [
    {
      id: "1",
      label: "Date Duration",
      key: "date_type",
      type: "radio",
      isSearchable: false,
      list: filter.date_type && handleDefaultFilter(removeLifetimeFilter(filter?.date_type?.choices), "date_type"),
    },
    {
      id: "2",
      label: "Source",
      key: "source",
      type: "checkbox",
      isSearchable: true,
      list: ticketSourceOptions && handleDefaultFilter(ticketSourceOptions, "source"),
    },
    {
      id: "3",
      label: "Star Rating",
      key: "rating",
      type: "checkbox",
      isSearchable: false,
      isNotEllipsisLabel: false,
      list: common.ratings && handleDefaultFilter(common.ratings.choices, "rating"),
    },
    {
      id: "4",
      label: "Assigned To",
      key: "assignees",
      type: "checkbox",
      isSearchable: true,
      list: teamList && handleDefaultFilter(teamList, "assignees"),
    },
    {
      id: "5",
      label: "Status",
      key: "status",
      type: "checkbox",
      isSearchable: false,
      isNotEllipsisLabel: false,
      list: replyOptions && handleDefaultFilter(replyOptions, "status"),
    },
    {
      id: "6",
      label: "Sentiment",
      key: "sentiment",
      type: "checkbox",
      isSearchable: false,
      // list: handleDefaultFilter(sentimentOptions, "sentiment"),
      list:
        common.sentiment_options &&
        handleDefaultFilter(
          common.sentiment_options.choices.filter((item) => item.display_name !== "Review Sentiment"),
          "sentiment"
        ),
    },
    {
      id: "7",
      label: "Country",
      screenName: "country",
      key: "country",
      type: "checkbox",
      isSearchable: true,
      list: common.country_options && handleDefaultFilter(common.country_options.choices, "country"),
    },
    {
      id: "8",
      label: "State",
      key: "state",
      type: "checkbox",
      isSearchable: true,
      list: common.state_options && handleDefaultFilter(common.state_options.choices, "state"),
    },
    {
      id: "9",
      label: "City",
      key: "city",
      type: "checkbox",
      isSearchable: true,
      // list: handleDefaultFilter(serviceOptions, "city"),
      list: common.city_options && handleDefaultFilter(common.city_options.choices, "city"),
    },
    {
      id: "10",
      label: "Location",
      key: "branch",
      type: "radio",
      isSearchable: true,
      list: filter.branch && handleDefaultFilter(filter.branch.choices, "branch"),
    },
    {
      id: "11",
      label: "Provider",
      key: "provider",
      type: "checkbox",
      isSearchable: true,
      list: source && handleDefaultFilter(source.choices, "provider"),
    },
    {
      id: "12",
      label: "Location Tag",
      key: "branch_tag",
      type: "checkbox",
      isSearchable: true,
      list:
        common.branch_tag_options &&
        handleDefaultFilter(
          common.branch_tag_options.results.map((item) => ({
            value: item.id,
            display_name: item.title,
          })),
          "branch_tag"
        ),
    },
    service_category === "Restaurant" && {
      id: "13",
      label: "Service Type",
      key: "service_type",
      type: "radio",
      isSearchable: false,
      list: handleDefaultFilter(serviceOptions, "service_type"),
    },
  ].filter((item) => !!item);
  return (
    <React.Fragment>
      <FPFilter
        data={filterList}
        className="mt-3"
        history={history}
        pageSizeCookie={pageSizeCookie}
        actionRender={() => (
          <FPToolTip title={"Download"}>
            <IconButton component="span" className="circular-button" onClick={() => handleReviewDownloadFlow()}>
              <DownloadIcon />
            </IconButton>
          </FPToolTip>
        )}
        ticketFilter="TICKET"
      />
      {isMoreThanNinety && (
        <FormModal
          isOpen={isMoreThanNinety}
          toggle={() => setIsMoreThanNinety(false)}
          heading="Attention !"
          maxWidth="xs"
          showDivider={true}
          dialogActions={<FPButton size="small" onClick={() => setIsMoreThanNinety(false)} label="OK" />}
        >
          <p className="fp-size-16">Please contact to support to download data older than 90 days.</p>
        </FormModal>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    filter: state.filter,
    source: state.review.review_source_options,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TicketFilter);
