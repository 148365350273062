import React from "react";
import "./listing.css";
import success_svg from "../../../images/success.svg";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";

const WebsiteTable = (props) => {
  return (
    <div className="d-flex flex-column gap-10">
      <div className="d-flex justify-content-between gap-10 website-card">
        <div className="">
          <div className="header" style={{ fontSize: "14px", fontWeight: "500", color: "#1B1C1D" }}>
            Website & Menu Link
          </div>
          <div style={{ display: "flow-root" }}>
            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <img
                style={{ height: "15px", fontSize: "12px", fontWeight: "400" }}
                src={`https://www.google.com/s2/favicons?domain=${props?.websiteUri?.websiteUri}&sz=64`}
                alt="icon"
              />
              <a
                key={props?.id}
                className="description"
                style={{ fontSize: "12px", fontWeight: "400", color: "#1B1C1D" }}
                href={props?.websiteUri?.websiteUri}
                target="_blank"
                rel="noreferrer"
              >
                {props?.websiteUri?.websiteUri}
              </a>
            </div>
          </div>
        </div>
        <div className="">
          <img src={success_svg} alt="succesImage" height="25px" width="25px" />
        </div>
      </div>

      {Object.keys(props?.websiteUri?.location_links || {}).map((key_data) => {
        const items = props?.websiteUri?.location_links[key_data]; // Get the array for the key
        return (
          <div className="d-flex justify-content-between gap-10 website-card" key={key_data}>
            <div className="">
              <div className="header" style={{ fontSize: "14px", fontWeight: "500", color: "#1B1C1D" }}>
                {key_data.replace(/_/g, " ")}
              </div>
              {items.map((item) => {
                return (
                  <>
                    <div style={{ display: "flow-root" }}>
                      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                        <img
                          style={{ height: "15px", fontSize: "12px", fontWeight: "400" }}
                          src={`https://www.google.com/s2/favicons?domain=${item.uri}&sz=64`}
                          alt="icon"
                        />
                        <a
                          key={item.id}
                          className="description"
                          style={{ fontSize: "12px", fontWeight: "400", color: "#1B1C1D" }}
                          href={item.uri}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {item?.uri?.slice(0, 50)}
                        </a>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="">
              <img src={success_svg} alt="successImage" height="25px" width="25px" />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default WebsiteTable;
