import React from 'react';
import { FPButton, FPToolTip } from './new';
import { updateProviderLogo } from '../../libs/helper';
import { Avatar } from '@material-ui/core';

export default class MultipleButtonSelect extends React.Component {
  constructor(props)
  {
    super(props);
    this.state = {
      providersToggle: [],
      modifyReviewChannel: this.props.reviewChannels || []
    };
  }

  componentDidMount() {
    const { initialValues, reviewChannels } = this.props;
    const updatedReviewChannels = reviewChannels && reviewChannels.map((channel) => ({
      ...channel, ['logo'] : updateProviderLogo(channel.display_name)
    })) || []; 

    this.setState({modifyReviewChannel: updatedReviewChannels});

    if(initialValues) {
      const newValues = updatedReviewChannels && updatedReviewChannels.map((item)=>{ 
        return initialValues[item.display_name.toLowerCase()] ? 
          { provider: item.display_name, status: 'active', id: item.value }
          : { provider: item.display_name, status: 'inactive', id: item.value }; });
      this.setState({ providersToggle: newValues });
      this.props.handleReviewChannel(newValues);
    } else {
      const vals = updatedReviewChannels && updatedReviewChannels.map((item) => {
        if(['Google', 'Zomato', 'Tripadvisor','Booking'].includes(item.display_name))
        {
          return { provider: item.display_name, status: 'active', id: item.value };
        }
        else return { provider: item.display_name, status: 'inactive', id: item.value };
      });
      this.setState({ providersToggle: vals });
      this.props.handleReviewChannel(vals);
    }
  }

  handleClick = (e, id) => {
    e.preventDefault();
    const { providersToggle } = this.state;
    const providerTog = providersToggle;
    if((providerTog && providerTog[id] && providerTog[id].status) && providerTog[id].status === 'active')
    {
      providerTog[id].status = 'inactive';
    }
    else { providerTog[id].status = 'active' ;}
    this.setState({providersToggle: providerTog});
    this.props.handleReviewChannel(this.state.providersToggle);
  }

  render() {
    const { initialValues } = this.props;
    const { providersToggle, modifyReviewChannel} = this.state;
    const isActive = (i) => providersToggle && providersToggle.length
      ? providersToggle[i] &&
      providersToggle[i].status === 'inactive'
        ? false
        : true
      : false ||
      (providersToggle &&
        providersToggle.length &&
        initialValues)
        ? providersToggle[i] &&
      providersToggle[i].status === 'inactive'
          ? false
          : true
        : false;
    return (
      <div>
        <div className='d-flex gap-20'>
          {modifyReviewChannel &&
            modifyReviewChannel.map((item, i) => {
              return (
                <FPToolTip title={item.display_name} key={`${i}`}>
                  <FPButton
                    type="button"
                    onClick={(e) => {
                      this.handleClick(e, i);
                    }}
                    variant="outline"
                    backgroundColor="#F6F6F8"
                    borderColor={isActive(i) ? '#00B5AD': '#F6F6F8'}
                    textColor="#1B1C1D"
                    borderWidth={2}
                    size='small'
                    startIcon={
                      <Avatar className='clean-small-avatar border-0' >
                        <img src={item.logo} alt='' style={{height: 15, width: 15}}/>
                      </Avatar>
                    }
                  >
                    {item.display_name}
                  </FPButton>
                </FPToolTip>
              );
            })}
        </div>
      </div>
    );
  }
}