import React from 'react';
import PropTypes from 'prop-types';

const FPTabPanel = (props) => {
  const { children, value, index, ...rest } = props;
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
      className='mt-4'
    >
      {value === index && children}
    </div>
  );
};

FPTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default FPTabPanel;
