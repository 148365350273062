import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';  
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';

import * as RegisterActions from '../../actions/accounts/registerActions';
import * as commonAction from '../../actions/common/commonAction';
import RegsiterForm from '../../components/accounts/register/RegisterForm';
import LoaderComponent from '../../components/common/loaderComponent';
import { Header } from 'semantic-ui-react';
import LogoIcon from '../../images/fp-icon.png';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
    this.registerSuccessful = this.registerSuccessful.bind(this);


  }

  componentDidMount() {
    document.title = 'Sign Up';
  }
  
  UNSAFE_componentWillMount(){
    this.props.actions.getUserType();
    this.props.common.getCategoryOptions();
  }

  handleRegister(values) {
    return this.props.actions.registerAction(values);
  }

  registerSuccessful() {
    this.props.history.push('/register-successful');
  }

  render() {
    if(this.props.register) {
      this.registerSuccessful();
    }
    const { businessOptions, categoryOptions } = this.props;
    return (
      <Row className="align-items-center justify-content-center login-bg full-height" >
        <LoaderComponent loader={this.props.loader}/>
        <Col xs={12} sm={10} md={8}>
          <div className="text-center"><img src={LogoIcon} alt="" /></div>
          <Header as='h2' color='teal' textAlign='center' className="mt-3 pb-1">
              Create your new account!
          </Header>
          <Row className="justify-content-center">
            <Col xs={10} sm={10}>
              <RegsiterForm
                handleRegister={this.handleRegister}
                userOptionList={businessOptions && businessOptions.choices.map((item)=>({ label: item.display_name, value:item.value }))}
                categoryOptionList={categoryOptions && categoryOptions.choices.map((item)=>({ label: item.display_name, value:item.value }))}
              />
              <p className='text-center'>
              Go back to <a href="/login">Login</a>
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}


const mapStateToProps = (state) => ({
  register: state.register.isRegister,
  businessOptions: state.register.businessOptions,
  categoryOptions: state.common.categories,
  errors: state.register.errors,
  loader:state.common.loader
});


RegisterPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object)
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(RegisterActions, dispatch),
    common: bindActionCreators(commonAction, dispatch)
  };
}


export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(RegisterPage);
