import * as types from "../../actions/actionTypes";
// import * as utils from '../../libs/utils';

export default function contact(state = [], action) {
  switch (action.type) {
    case types.GET_REPORT_SUCCESS: {
      if (Object.keys(action.data).length) {
        let dataKeys = Object.keys(action.data).length
          ? Object.keys(action.data)
          : null; // eslint-disable-line

        const location_addresses = Object.keys(action.data).length
          ? Object.keys(action.data).map(
              (item) => action.data[item]["location_address"]
            )
          : null;
        const rating = Object.keys(action.data).length
          ? Object.keys(action.data).map(
              (item) => +action.data[item]["avg rating"]
            )
          : null; // eslint-disable-line
        const ratingData = rating.map((i) => +i.toFixed(2));

        const nps = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].nps)
          : null; // eslint-disable-line
        const npsData = nps.map((i) => +i.toFixed(2));

        const starOne = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].oneStar)
          : null; // eslint-disable-line
        const starTwo = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].twoStar)
          : null;
        const starThree = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].threeStar)
          : null;
        const starFour = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].fourStar)
          : null;
        const starFive = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].fiveStar)
          : null;
        const starTotal = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].totalStar)
          : null;
        const starAvg = Object.keys(action.data).length
          ? Object.keys(action.data).map(
              (item) => +action.data[item].avg_rating
            )
          : null;
        const starData = {
          oneStar: [...starOne],
          twoStar: [...starTwo],
          threeStar: [...starThree],
          fourStar: [...starFour],
          fiveStar: [...starFive],
          totalStar: [...starTotal],
          avg_rating: [...starAvg],
        };
        const starTableData = action.data;

        const sentimentPos = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].positive)
          : null; // eslint-disable-line
        const sentimentNeg = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].negative)
          : null;
        const sentimentNeu = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].neutral)
          : null;
        const sentimentTot = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].total)
          : null;
        const sentimentData = {
          positive: [...sentimentPos],
          negative: [...sentimentNeg],
          neutral: [...sentimentNeu],
          total: [...sentimentTot],
        };

        const tagsPos = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].positive)
          : null; // eslint-disable-line
        const tagsNeg = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].negative)
          : null;
        const tagsNeu = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].neutral)
          : null;
        const tagsTot = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].total)
          : null;
        const tagsData = {
          positive: [...tagsPos],
          negative: [...tagsNeg],
          neutral: [...tagsNeu],
          total: [...tagsTot],
        };

        const review = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => +action.data[item].review)
          : null; // eslint-disable-line
        const reviewData = review.map((i) => +i.toFixed(2));

        const visitorData = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) => action.data[item])
          : null; // eslint-disable-line

        let closedTickets = Object.keys(action.data).length
          ? Object.keys(action.data).map(
              (item) => +action.data[item]["close tickets"]
            )
          : null; // eslint-disable-line
        let responseTime = Object.keys(action.data).length
          ? Object.keys(action.data).map((item) =>
              (parseFloat(action.data[item]["response time"]) / 3600).toFixed(2)
            )
          : null; // eslint-disable-line
        const totalTickets = action.data["total tickets"];

        if (totalTickets) {
          closedTickets = closedTickets.slice(1);
          responseTime = responseTime.slice(1);
          dataKeys = dataKeys.slice(1);
        }

        const responseTimeMessage = Object.keys(action.data).length
          ? Object.keys(action.data).map(
              (item) => +action.data[item]["response time msg"]
            )
          : null; // eslint-disable-line

        const conversionData = action.data;
        const zomatoSwiggyReviewData = action.data;
        return Object.assign({}, state, {
          errors: undefined,
          dataKeys,
          npsData,
          starData,
          starTableData,
          sentimentData,
          tagsData,
          zomatoSwiggyReviewData,
          totalTickets,
          closedTickets,
          responseTime,
          responseTimeMessage,
          location_addresses,
          conversionData,
          reviewData,
          visitorData,
          tableData: { ...action.data },
          rating: ratingData,
        });
      } else {
        return {};
      }
    }
    case types.GET_REPORT_FAILURE:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
}
