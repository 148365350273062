import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../actions/listing/listingLocationAction";
import LoaderComponent from "../../../../components/common/loaderComponent";

const BusinessList = (props) => {
  const {
    locationList,
    actions,
    business_id,
    type,
    busines_status,
    queryStringData,
    loader,
  } = props;
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(true);

  useEffect(() => {
    if (busines_status === false) {
      if (type) {
        actions.getGmbListingLoaction(
          business_id,
          queryStringData?.gmb_location_type,
          "verified",
          queryStringData?.completion_status,
          queryStringData?.openstatus,
          queryStringData?.search,
          // "shipra mall",
          type,
          "",
          "",
          "",
          "business_list"
        );
      }
    } else {
      actions.getGmbListingLoaction(
        business_id,
        queryStringData?.gmb_location_type,
        "verified",
        queryStringData?.completion_status,
        queryStringData?.openstatus,
        queryStringData?.search,
        "",
        "",
        "",
        "",
        "business_list",
        // "shipra mall"
      );
    }
  }, [actions, business_id, type, busines_status, queryStringData]);

  useEffect(() => {
    if (locationList) {
      setLoaderStatus(false);
      setSelectedBusinesses(locationList.map((business) => business.id));
    } else {
      setLoaderStatus(true); // Reset the loader status if locationList is empty or loading
    }
  }, [locationList]);

  const handleCheckboxChange = (business) => {
    if (selectedBusinesses.includes(business.id)) {
      setSelectedBusinesses(
        selectedBusinesses.filter((b) => b !== business.id)
      );
    } else {
      setSelectedBusinesses([...selectedBusinesses, business.id]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectedBusinesses.length === locationList.length) {
      setSelectedBusinesses([]);
    } else {
      setSelectedBusinesses(locationList.map((business) => business.id));
    }
  };

  useEffect(() => {
    props.onSelectedBusinessCountChange(selectedBusinesses);
  }, [selectedBusinesses, props]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          paddingBottom: "10px",
        }}
      >
        {/* Select All Checkbox */}
        <h4>All the changes will be applicable for only verified listings.</h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <input
              type="checkbox"
              onChange={handleSelectAllChange}
              id="select_all"
              checked={selectedBusinesses.length === locationList?.length}
              disabled={loaderStatus} // Disable while loading
            />
            <label
              style={{
                cursor: "pointer",
                fontSize: "15px",
                fontWeight: "400",
                marginBottom: "0px",
              }}
              htmlFor="select_all"
            >
              Select All Businesses
            </label>
          </div>
          <div>
            <span
              style={{
                color: "rgb(233 70 133)",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              (selected {selectedBusinesses?.length} / total{" "}
              {locationList?.length})
            </span>
          </div>
        </div>

        {/* Loader and Content */}
        <LoaderComponent loader={loaderStatus} />
        {!loaderStatus &&
          locationList?.length > 0 &&
          locationList.map((business, index) => {
            const addressString = [
              business?.addressLines,
              business?.locality,
              business?.postalCode,
            ]
              .filter(Boolean)
              .join(", ");
            return (
              <div
                key={business.id}
                style={{
                  display: "flex",
                  gap: "10px",
                  border: "1px solid #6e6d6d",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <div style={{ paddingTop: "3px" }}>
                    <input
                      type="checkbox"
                      id={`business${index}`}
                      checked={selectedBusinesses.includes(business.id)}
                      onChange={() => handleCheckboxChange(business)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor={`business${index}`}
                      style={{ cursor: "pointer", marginBottom: "0px" }}
                    >
                      <h2
                        style={{
                          color: "#000000d9",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginBottom: "2px",
                        }}
                      >
                        {business?.title}
                      </h2>
                      <p
                        style={{
                          color: "#8b8b8d",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {addressString}
                      </p>
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    locationList: state.gmbListingLocation.location_list,
    error: state.gmbListingLocation.location_error,
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessList);


// import React, { useEffect, useRef, useState } from "react";
// import { bindActionCreators } from "redux";
// import { connect } from "react-redux";
// import * as locationActions from "../../../../actions/listing/listingLocationAction";
// import LoaderComponent from "../../../../components/common/loaderComponent";

// const BusinessList = (props) => {
//   const {
//     locationList,
//     actions,
//     business_id,
//     type,
//     busines_status,
//     queryStringData,
//     loader,
//     filterBusinessCount,
//   } = props;

//   const [selectedBusinesses, setSelectedBusinesses] = useState([]);
//   const [loaderStatus, setLoaderStatus] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [allBusinesses, setAllBusinesses] = useState([]); // Accumulate all paginated data
//   const itemsPerPage = 10;
//   const popupRef = useRef(null);

//   // Function to handle the scroll event and update the page

//   const handleScroll = () => {
//     const popupElement = popupRef.current;
//     if (popupElement) {
//       const scrollTop = popupElement.scrollTop;
//       const scrollHeight = popupElement.scrollHeight;
//       const clientHeight = popupElement.clientHeight;

//       if (scrollTop + clientHeight >= scrollHeight - 100) {
//         setCurrentPage((prevPage) => prevPage + 1);
//       }
//     }
//   };

//   const lastPage = filterBusinessCount / itemsPerPage;

//   useEffect(() => {
//     console.log(
//       "lastPage > currentPagelastPage > currentPagelastPage > currentPage",
//       lastPage > currentPage
//     );
//     if (lastPage > currentPage) {
//       const popupElement = popupRef.current;
//       if (popupElement) {
//         popupElement.addEventListener("scroll", handleScroll);
//       }

//       return () => {
//         if (popupElement) {
//           popupElement.removeEventListener("scroll", handleScroll);
//         }
//       };
//     }
//   }, []);

//   // Fetch data based on business status and page number
//   useEffect(() => {
//     const fetchData = async () => {
//       if (busines_status === false) {
//         if (type) {
//           await actions.getGmbListingLoaction(
//             business_id,
//             queryStringData?.gmb_location_type,
//             "verified",
//             queryStringData?.completion_status,
//             queryStringData?.openstatus,
//             queryStringData?.search,
//             type,
//             "",
//             itemsPerPage,
//             currentPage
//           );
//         }
//       } else {
//         await actions.getGmbListingLoaction(
//           business_id,
//           queryStringData?.gmb_location_type,
//           "verified",
//           queryStringData?.completion_status,
//           queryStringData?.openstatus,
//           queryStringData?.search,
//           "",
//           "",
//           itemsPerPage,
//           currentPage
//         );
//       }
//     };
  
//     // Get the integer part of the lastPage
//     const integerPart = Math.floor(lastPage);
//     const decimalPart = lastPage - integerPart;
//       if (currentPage <= (decimalPart > 0 ? integerPart + 1 : integerPart)) {
//       fetchData();
//     }
//   }, [actions, business_id, type, busines_status, queryStringData, currentPage, lastPage]);
  
//   // Accumulate the data fetched into allBusinesses array
//   useEffect(() => {
//     if (locationList && locationList.length > 0) {
//       setLoaderStatus(false);
//       setAllBusinesses((prevBusinesses) => [
//         ...prevBusinesses,
//         ...locationList,
//       ]); // Append new data
//       setSelectedBusinesses((prevSelected) =>
//         [
//           ...prevSelected,
//           ...locationList.map((business) => business.id),
//         ].filter((v, i, a) => a.indexOf(v) === i)
//       ); // Ensure unique selected IDs
//     }
//   }, [locationList]);

//   const handleCheckboxChange = (business) => {
//     if (selectedBusinesses.includes(business.id)) {
//       setSelectedBusinesses(
//         selectedBusinesses.filter((b) => b !== business.id)
//       );
//     } else {
//       setSelectedBusinesses([...selectedBusinesses, business.id]);
//     }
//   };

//   const handleSelectAllChange = () => {
//     if (selectedBusinesses.length === allBusinesses.length) {
//       setSelectedBusinesses([]);
//     } else {
//       setSelectedBusinesses(allBusinesses.map((business) => business.id));
//     }
//   };

//   useEffect(() => {
//     props.onSelectedBusinessCountChange(selectedBusinesses);
//   }, [selectedBusinesses, props]);

//   return (
//     <>
//       <div
//         ref={popupRef}
//         style={{
//           height: "400px",
//           overflowY: "auto",
//           display: "flex",
//           flexDirection: "column",
//           gap: "20px",
//           paddingBottom: "10px",
//         }}
//       >
//         {/* Select All Checkbox */}
//         <h4>All the changes will be applicable for only verified listings.</h4>
//         <div
//           style={{
//             display: "flex",
//             alignItems: "center",
//             gap: "10px",
//             marginLeft: "10px",
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               alignItems: "center",
//               gap: "10px",
//             }}
//           >
//             <input
//               type="checkbox"
//               onChange={handleSelectAllChange}
//               id="select_all"
//               checked={selectedBusinesses.length === allBusinesses.length}
//               disabled={loaderStatus}
//             />
//             <label
//               style={{
//                 cursor: "pointer",
//                 fontSize: "15px",
//                 fontWeight: "400",
//                 marginBottom: "0px",
//               }}
//               htmlFor="select_all"
//             >
//               Select All Businesses
//             </label>
//           </div>
//           <div>
//             <span
//               style={{
//                 color: "rgb(233 70 133)",
//                 fontSize: "14px",
//                 fontWeight: "600",
//               }}
//             >
//               (selected {selectedBusinesses?.length} / total{" "}
//               {filterBusinessCount})
//             </span>
//           </div>
//         </div>

//         {/* Loader and Content */}
//         <LoaderComponent loader={loaderStatus} />
//         {!loaderStatus &&
//           allBusinesses?.length > 0 &&
//           allBusinesses.map((business, index) => {
//             const addressString = [
//               business?.addressLines,
//               business?.locality,
//               business?.postalCode,
//             ]
//               .filter(Boolean)
//               .join(", ");
//             return (
//               <div
//                 key={business.id}
//                 style={{
//                   display: "flex",
//                   gap: "10px",
//                   border: "1px solid #6e6d6d",
//                   borderRadius: "20px",
//                   padding: "10px",
//                 }}
//               >
//                 <div
//                   style={{
//                     overflow: "hidden",
//                     display: "flex",
//                     alignItems: "center",
//                     gap: "20px",
//                   }}
//                 >
//                   <div style={{ paddingTop: "3px" }}>
//                     <input
//                       type="checkbox"
//                       id={`business${index}`}
//                       checked={selectedBusinesses.includes(business.id)}
//                       onChange={() => handleCheckboxChange(business)}
//                     />
//                   </div>
//                   <div>
//                     <label
//                       htmlFor={`business${index}`}
//                       style={{ cursor: "pointer", marginBottom: "0px" }}
//                     >
//                       <h2
//                         style={{
//                           color: "#000000d9",
//                           fontSize: "16px",
//                           fontWeight: "500",
//                           marginBottom: "2px",
//                         }}
//                       >
//                         {business?.title}
//                       </h2>
//                       <p
//                         style={{
//                           color: "#8b8b8d",
//                           fontSize: "14px",
//                           fontWeight: "400",
//                         }}
//                       >
//                         {addressString}
//                       </p>
//                     </label>
//                   </div>
//                 </div>
//               </div>
//             );
//           })}
//       </div>
//     </>
//   );
// };

// const mapStateToProps = (state) => {
//   return {
//     locationList: state.gmbListingLocation.location_list,
//     error: state.gmbListingLocation.location_error,
//     loader: state.common.loader,
//     filterBusinessCount: state.gmbListingLocation.filterBusinessCount,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     actions: bindActionCreators(locationActions, dispatch),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(BusinessList);
