import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import PaginationComponent from "../common/Pagination";
import SearchComponent from "../common/SearchComponent";

const BusinessListComponent = (props) => {
  const { businessList, count, location, pageSizeCookie } = props;
  return (
    <div>
      <div className="d-flex align-items-center justify-content-between my-3">
        <span style={{ color: "#646565", fontWeight: "400", fontSize: "18px" }}>Business</span>
        <SearchComponent {...props} className="common-search" startIcon />
      </div>
      <CardWrapper showDivider={false}>
        <div className="ui-table-responsive">
          <Table className="fp-table fp-table-center">
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "justify" }}>S.No</TableCell>
                <TableCell style={{ textAlign: "justify" }}>Name</TableCell>
                <TableCell>Created at</TableCell>
                <TableCell>Agency Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>No. of Locations</TableCell>
                <TableCell style={{ width: "150px" }}>Last month Whatsapp Usage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {businessList && businessList.count ? (
                businessList.results.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell style={{ textAlign: "justify" }}>{index + 1}</TableCell>
                      <TableCell>
                        <Link to={`/${item.id}/dashboard?date_type=prior_30_day`}>
                          <div style={{ textAlign: "justify" }} className="templateName">{item.name}</div>
                        </Link>
                      </TableCell>
                      <TableCell>{moment(item.created_at).format("DD-MM-YYYY")}</TableCell>
                      <TableCell>{item.agency_name}</TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.no_of_locations}</TableCell>
                      <TableCell>{item.last_month_whatsapp_usage}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={10}>No records Found </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <PaginationComponent count={count} location={location} pageSizeCookie={pageSizeCookie} />
      </CardWrapper>
    </div>
  );
};
BusinessListComponent.propTypes = {
  businessList: PropTypes.object.isRequired,
};

export default BusinessListComponent;
