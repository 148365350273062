import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FieldArray, useField } from "formik";
import { RadioGroup, makeStyles } from "@material-ui/core";
import FPCheckbox from "../FPCheckbox";
import "./fpfilter.css";
import DebounceSearchbar from "./DebounceSearchbar";
import ClearIcon from "@material-ui/icons/Clear";
import FPButton from "../FPButton";
import FPToolTip from "../FPToolTip";
import RadioWithTooltip from "./RadioWithTooltip";
import CheckBoxWithTooltip from "./CheckBoxWithTooltip";
import { snakeToCapital } from "../../../../libs/utils";

const useStyles = makeStyles({
  fromControl: {
    "&.MuiFormControlLabel-root .MuiTypography-root": {
      color: "#7A8193 !important",
    },
  },
});

const MultiAutoCompleteCheckbox = forwardRef(
  (
    {
      options: dataOption,
      name,
      onChange,
      children,
      type,
      isSearchable,
      isNotEllipsisLabel,
      className,
      isSelectAll,
      setIsSelectAll,
      ticketFilter,
      currentOpenFilterItem,
      setSearchQuery,
      searchQuery,
      isSearchRealTime,
      handleSearchApi
    },
    ref
  ) => {
    useImperativeHandle(ref, () => {
      return {
        handleCheckboxChange,
        setIsSelectAll,
      };
    });
    const [options, setOptions] = useState(dataOption);
    // eslint-disable-next-line no-unused-vars
    const [field, _meta, helpers] = useField(name);
    const { setValue } = helpers;

    useEffect(() => {
      setOptions(dataOption);
    }, [dataOption]);

    const handleOnChange = (event, option, arrayHelpers) => {
      handleCheckboxChange(option);
      const index = Array.isArray(field.value) ? field.value.findIndex((filter) => filter.value === option.value) : -1;
      event.target.checked ? arrayHelpers.push(option) : arrayHelpers.remove(index);

      // * -1 cause field.value running one step behind
      field.value.length === options.length - 1 ? setIsSelectAll(true) : setIsSelectAll(false);
    };

    const handleCheckboxChange = (selectedOption, mapOptions) => {
      const currentOption = mapOptions || options;
      const modifiedOptions = currentOption.map((option) => {
        if (option.value === selectedOption.value) {
          return { ...option, isChecked: !option.isChecked };
        }
        return option;
      });
      setOptions(modifiedOptions);
      onChange && onChange(field.value);
    };

    const handleRadioChange = (item, arrayHelpers) => {
      if (field.value[0] && field.value[0] && field.value[0].value === item.value) {
        arrayHelpers.remove(0);
      } else {
        arrayHelpers.remove(0);
        arrayHelpers.push(item);
      }
      onChange && onChange(field.value);
    };

    const handleSearch = (event) => {
      const query = event?.target?.value || "";
      setSearchQuery(query);
      if(isSearchRealTime) {
        handleSearchApi(query);
      }
    };

    const filteredOptions = options?.filter((option) =>
      option.display_name.replace(/\s/g, "")?.toLowerCase().includes(searchQuery?.toLowerCase())
    );

    const handleClearAll = () => {
      setValue([]);
      setIsSelectAll(false);
    };

    const handleSelectAll = (event) => {
      setValue([]);
      setIsSelectAll(event.target.checked);
      if (event.target.checked) {
        const allSelectedOption = options?.map((option) => {
          option.isChecked = true;
          return option;
        });
        setOptions(allSelectedOption);
        setValue(allSelectedOption);
      }
    };

    const classes = useStyles();

    return (
      <React.Fragment>
        {currentOpenFilterItem && (
          <FieldArray
            name={name}
            render={(arrayHelpers) => (
              <section className={className ? className : ""}>
                {isSearchable && (
                  <div
                    className={`d-flex align-items-center justify-content-between ${
                      !isSearchable && type === "radio" ? "mb-0" : "mb-3"
                    }`}
                  >
                    <DebounceSearchbar
                      onChange={(event) => handleSearch(event)}
                      debounceTime={isSearchRealTime ? 1000 : 0 }
                      className="contacts-filter-search"
                    />
                    {type !== "radio" && (
                      <div className="ml-auto">
                        <FPButton
                          startIcon={<ClearIcon style={{ color: "#7A8193" }} />}
                          label="Clear All"
                          fontWeight={400}
                          fontSize="12px"
                          size="small"
                          textColor="#7A8193"
                          backgroundColor="#FAFAFB"
                          onClick={handleClearAll}
                          className="mr-3 clearAll"
                          style={{
                            height: 25,
                          }}
                        />
                        <FPButton
                          htmlFor="selectAll-filter"
                          label="Select All"
                          fontSize="12px"
                          role="label"
                          textColor="#7A8193"
                          size="small"
                          backgroundColor="#FAFAFB"
                          fontWeight={400}
                          className="selectAllBtn"
                          style={{
                            height: 25,
                          }}
                        >
                          <FPCheckbox
                            onChange={(event) => handleSelectAll(event)}
                            id="selectAll-filter"
                            label="Select All"
                            checked={isSelectAll}
                            labelClassName={classes.fromControl}
                            style={{ color: "#7A8193" }}
                            className="selectAllBtn"
                          />
                        </FPButton>
                      </div>
                    )}
                  </div>
                )}

                {type === "radio" ? (
                  <RadioGroup
                    name={name}
                    value={
                      field?.value && (field?.value[0]?.value || field?.value[0]?.value === "")
                        ? field?.value[0]?.value
                        : null
                    }
                  >
                    {/* CheckRadioWithTooltip */}
                    <section className="vertical-align flex-wrap filter-item-list-wrapper circleBtn">
                      {filteredOptions?.map((item) => (
                        <FPToolTip key={`${item.display_name}_${item.value}`} title={item.display_name}>
                          <RadioWithTooltip
                            item={item}
                            arrayHelpers={arrayHelpers}
                            handleRadioChange={handleRadioChange}
                            style={{
                              width: isNotEllipsisLabel && "auto",
                            }}
                            isNotEllipsisLabel={isNotEllipsisLabel}
                            ticketFilter={ticketFilter}
                          />
                        </FPToolTip>
                      ))}
                    </section>
                  </RadioGroup>
                ) : (
                  // CheckBoxWithTooltip
                  <section className="vertical-align flex-wrap gap-20 gap-y-10 filter-item-list-wrapper rectangleBtn">
                    {filteredOptions?.map((option, index) => (
                      <FPToolTip
                        key={`${index}-${option.value}-${option.isChecked}-MultiAutoCompleteCheckbox`}
                        title={snakeToCapital(option.display_name)}
                      >
                        <CheckBoxWithTooltip
                          option={option}
                          arrayHelpers={arrayHelpers}
                          field={field}
                          handleOnChange={handleOnChange}
                          style={{
                            width: isNotEllipsisLabel && "auto",
                          }}
                          isNotEllipsisLabel={isNotEllipsisLabel}
                          ticketFilter={ticketFilter}
                        />
                      </FPToolTip>
                    ))}
                  </section>
                )}
                {children && typeof children === "function"
                  ? children(arrayHelpers, handleCheckboxChange, setIsSelectAll)
                  : children}
              </section>
            )}
          />
        )}
      </React.Fragment>
    );
  }
);

export default MultiAutoCompleteCheckbox;
