import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import cookie from "react-cookies";
import queryString from "query-string";
import * as ContactActions from "../../actions/contact/contactActions";
import * as CampaignActions from "../../actions/campaign/campaignActions";
import * as GenericTemplateActions from "../../actions/genericTemplates/genericTemplatesActions";
import * as BusinessActions from "../../actions/business/businessSetupActions";
import Contacts from "../../components/contacts/Contacts";
import ContactsForm from "../../components/contacts/ContactsForm";
import LoaderComponent from "../../components/common/loaderComponent";
import TagEditModal from "../../components/common/TagEditModal";
import LockedComponent from "../../components/common/LockedComponent/LockedComponent";
import * as utils from "../../libs/utils";
import { CONTACTS_TEMPLATE } from "../../libs/apiUrls";
import UploadFileModal from "../../components/common/new/UploadFileModal";
import FormModal from "../../components/common/FormModal";
import { FPButton } from "../../components/common/new";
import FPSelect from "../../components/common/new/forms/ui/FPSelect";

class ContactsListPage extends React.Component {
  constructor(props) {
    const pageSize = cookie.load("pageSize") || {};
    super(props);
    this.toggle = this.toggle.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.state = {
      isOpen: false,
      regionOption: [],
      getRegionFlag: false,
      branch: null,
      initialFilters: { filters: [{}] },
    };
    this.editContact = this.editContact.bind(this);
    this.addContact = this.addContact.bind(this);
    this.getContactList = this.getContactList.bind(this);
    this.deleteContacts = this.deleteContacts.bind(this);
    this.gotToContactManageTags = this.gotToContactManageTags.bind(this);
    this.state = {
      currentTab: "1",
      contactFormType: null,
      isBulkUploadOpen: false,
      resetSelectedContacts: false,
      params: {
        1: `page_size=${pageSize.contacts_page_all || "10"}`,
        2: `page_size=${pageSize.contacts_page_generic || "10"}`,
      },
    };

    //PageSize Handling
    const {
      history,
      location: { pathname },
    } = props;
    history.replace({
      pathname,
      search: queryString.stringify({
        page_size: pageSize.contacts_page_all || 10,
      }),
    });
  }

  toggleMessageBox = () => {
    const { showMessage } = this.state;
    this.setState({ showMessage: !showMessage });
  };

  componentDidMount() {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const params = { ...queryString.parse(this.props.location.search) };
    !params?.hasOwnProperty("page_size") &&
      (params.page_size = cookie.load("pageSize")?.contacts_page_all || 10);
    this.getContactList(params, this.props);
    this.props.actions.getContactTagGroups(business_id);
    this.props.businessActions.businessBranchOptions(
      { business: business_id },
      true
    );
    //Set Title
    document.title = "Contacts";
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      location: { search },
    } = this.props;
    const {
      location: { search: prevSearch },
    } = prevProps;
    const { currentTab } = this.state;
    const { currentTab: prevCurrentTab } = prevState;
    const { isOpen } = this.state;
    if (prevState.isOpen !== isOpen) {
      this.props.actions.getCountryLocation();
    }
    if (
      (search !== prevSearch && currentTab === prevCurrentTab) ||
      currentTab !== prevCurrentTab
    ) {
      if (currentTab === "1")
        this.getContactList(queryString.parse(search), this.props);
      else if (currentTab === "2")
        this.getGenericContactsList(queryString.parse(search), this.props);
    }
  }

  handleTabParams = (query, tab) => {
    const {
      history,
      location: { pathname },
    } = this.props;
    this.setState({ params: query, currentTab: tab });
    history.push({ pathname, search: this.state.params[tab] });
  };

  getGenericContactsList = (data, props) => {
    const {
      match: {
        params: { business_id },
      },
    } = props;
    this.props.genericTemplateActions.genericTemplatesContactsList(
      data,
      business_id
    );
  };

  getContactList(data, props) {
    const {
      match: {
        params: { business_id },
      },
    } = props;
    this.props.actions
      .contactList(data, business_id)
      .catch(
        (err) =>
          err === "lock" &&
          this.setState({ lock: "Contacts Module is locked." })
      );
    this.props.actions
      .contactIds(data, business_id)
      .catch(
        (err) =>
          err === "lock" &&
          this.setState({ lock: "Contacts Module is locked." })
      );
  }

  onCountryChange(value) {
    const queryparams = { country: value };
    this.props.actions.getRegionLocation(queryparams);
  }

  toggle(item, type) {
    const { isOpen } = this.state;
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    if (!isOpen) {
      if (item) {
        if (type === "All_contacts") {
          this.props.actions.getContact(business_id, item).then(() => {
            this.setState({ isOpen: true, contactFormType: "Edit" });
          });
        } else {
          this.props.genericTemplateActions
            .getGenericContactDetails(business_id, item)
            .then(() =>
              this.setState({ isOpen: true, contactFormType: "Edit" })
            );
        }
      } else {
        this.setState({ isOpen: true, contactFormType: "Add" });
      }
    } else this.setState({ isOpen: !isOpen });
  }

  gotToContactManageTags() {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    return history.push(`/${business_id}/contacts/manage-tags`);
  }

  addContact(data) {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    return this.props.actions.contactAdd(business_id, data).then(() => {
      this.toggle();
      this.props.actions.contactList(queryString.parse(search), business_id);
    });
  }

  editContact(data) {
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions.contactEdit(business_id, data).then(() => {
      this.setState({ isOpen: false });
      this.props.actions.contactList(queryString.parse(search), business_id);
    });
  }

  editGenericContact = (data) => {
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.genericTemplateActions
      .genericContactEdit(business_id, data)
      .then(() => {
        this.toggle();
        this.props.genericTemplateActions.genericTemplatesContactsList(
          queryString.parse(search),
          business_id
        );
      });
  };

  deleteContacts(data) {
    const {
      match: {
        params: { business_id },
      },
      history,
      location: { search },
    } = this.props;
    const { page, ...queryparams } = queryString.parse(search); //eslint-disable-line
    return this.props.actions
      .deleteContacts(business_id, { contacts: data })
      .then((res) => {
        utils.displayMessage("positive", res.data.message);
        this.setState({ resetSelectedContacts: true });
        page > 1
          ? history.push({
              pathname: "list",
              search: queryString.stringify(queryparams),
            })
          : this.getContactList({ ...queryparams }, this.props);
      })
      .catch((err) => {
        console.log(err);
        utils.displayMessage("negative", err.contacts || "Error");
      });
  }

  toggleConfirm = (confirmationFunction) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmationFunction,
    });
  };

  deleteGenericContact = (id) => {
    const {
      history,
      location: { pathname, search },
      match: {
        params: { business_id },
      },
    } = this.props;
    const { page_size, ...query } = queryString.parse(search);
    this.props.genericTemplateActions
      .genericContactDelete(business_id, id)
      .then((res) => {
        // eslint-disable-line
        Object.keys(query).length === 0
          ? this.getGenericContactsList({ page_size }, this.props)
          : history.push({
              pathname,
              search: `?page_size=${page_size}`,
              state: { tab: "2" },
            });
      });
  };

  toggleBulkUpload = () => {
    const { isBulkUploadOpen } = this.state;
    this.setState({ isBulkUploadOpen: !isBulkUploadOpen });
  };

  toggleContactsTagsAdd = () => {
    const { isContactsTagsAddOpen } = this.state;
    this.setState({ isContactsTagsAddOpen: !isContactsTagsAddOpen });
  };

  downloadExcel = () => {
    window.location.href = CONTACTS_TEMPLATE;
  };

  uploadExcelFile = () => {
    const { file, branch } = this.state;
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions
      .contactCsvUpload(business_id, branch, file)
      .then(() => {
        utils.displayMessage("positive", "File Uploaded Successfully");
        this.getContactList(
          queryString.parse(this.props.location.search),
          this.props
        );
        this.getGenericContactsList(
          queryString.parse(this.props.location.search),
          this.props
        );
        this.setState({ isBranchSelectorOpen: false });
      })
      .catch((err) => {
        this.setState({ showMessage: true, message: err.error });
      });
  };

  uploadExcel = (val) => {
    const file = val.target.files[0];

    this.setState({ showMessage: false });
    const fileExt = file && file.name.split(".").pop();
    const excelExt = ["xls", "xlsx", "xlsm", "csv"];

    if (excelExt.indexOf(fileExt) > -1) {
      const newDetail = new FormData();
      if (file) {
        newDetail.append("csv_file", file);
      }
      return this.setState({
        file: newDetail,
        isBranchSelectorOpen: true,
        isBulkUploadOpen: false,
      });
    } else {
      document.getElementById("file").value = null;
      this.setState({
        showMessage: true,
        message:
          "Invalid file type! Allowed extensions are .xls, .xlsx, .csv, .xlsm.",
      });
    }
    return true;
  };

  handleResetSelectedContacts = (value) => {
    this.setState({ resetSelectedContacts: value });
  };

  render() {
    const {
      contact,
      contactIds,
      contactDetail,
      history,
      match,
      search,
      genericTemplateDetailsList,
      genericContactDetail,
      campaign: { list },
      countryOption,
      stateOption,
      tagGroups,
      branches,
    } = this.props;
    const tagsList =
      tagGroups && tagGroups.results.length ? tagGroups.results : [];
    const {
      isOpen,
      isConfirmOpen,
      confirmationFunction,
      contactFormType,
      lock,
      currentTab,
      isBulkUploadOpen,
      showMessage,
      message,
      resetSelectedContacts,
      isBranchSelectorOpen,
    } = this.state;
    const querySearch = queryString.parse(this.props.location.search).ordering;
    const initialValues =
      contactFormType === "Edit"
        ? currentTab === "1"
          ? contactDetail
          : genericContactDetail
        : {
            country_type: countryOption && countryOption.default,
            region_type: stateOption && stateOption.default,
            terms: true,
          };
    return (
      <Row className="content-area">
        <LoaderComponent loader={this.props.loader} />
        <LockedComponent message={lock} />
        <Col xs={12} sm={12} className="business-setup p-3">
          <Contacts
            handleTabParams={this.handleTabParams}
            toggleBulkUpload={this.toggleBulkUpload}
            resetSelectedContacts={resetSelectedContacts}
            handleResetSelectedContacts={this.handleResetSelectedContacts}
            deleteContacts={(contacts) => this.deleteContacts(contacts)}
            gotToContactManageTags={this.gotToContactManageTags}
            contactList={contact && contact.results}
            contactIds={contactIds && contactIds.ids}
            genericTemplateDetails={
              genericTemplateDetailsList && genericTemplateDetailsList.results
            }
            templateList={list}
            genericContactsCount={
              genericTemplateDetailsList && genericTemplateDetailsList.count
            }
            contactsCount={contact && contact.count}
            toggle={this.toggle}
            history={history}
            match={match}
            genericContactDelete={this.deleteGenericContact}
            params={this.state.params}
            search={search}
            location={this.props.location}
            sortFunction={(on) =>
              utils.toggleSort(history, this.props.location, on)
            }
            ordering={querySearch}
            editContact={this.editContact}
            initialFilters={this.state.initialFilters}
            setInitialFilters={(data) =>
              this.setState({ initialFilters: data })
            }
          />
            <TagEditModal
              isOpen={isOpen}
              toggle={this.toggle}
              header={
                contactFormType === "Edit" ? "Edit Contact" : "Add Contact"
              }
              width="600px"
            >
              <ContactsForm
                tagsList={tagsList}
                initialValues={initialValues}
                toggleContactsTagsAdd={this.toggleContactsTagsAdd}
                addOrEditContact={
                  contactFormType === "Edit"
                    ? currentTab === "1"
                      ? this.editContact
                      : this.editGenericContact
                    : this.addContact
                }
                onCountryChange={this.onCountryChange}
                currentTab={currentTab}
                countryOptionList={
                  countryOption &&
                  countryOption.choices &&
                  countryOption.choices.map((item) => ({
                    text: item.display_name,
                    value: item.value,
                  }))
                }
                regionOptionList={
                  stateOption &&
                  stateOption.choices &&
                  stateOption.choices.map((item) => ({
                    text: item.display_name,
                    value: item.value,
                  }))
                }
                branchOptionList={
                  branches &&
                  branches.choices.map((i) => ({
                    text: i.display_name,
                    value: i.value,
                  }))
                }
                edit
              />
            </TagEditModal>

            <UploadFileModal
              isOpen={isBulkUploadOpen}
              toggle={this.toggleBulkUpload}
              heading={"Bulk Upload Contacts"}
              uploadExcel={this.uploadExcel}
              downloadExcel={() => this.downloadExcel()}
              showMessage={showMessage}
              toggleMessage={() => this.toggleMessageBox()}
              message={message}
            />
            
            <FormModal
              isOpen={isBranchSelectorOpen}
              toggle={() =>
                this.setState({ isBranchSelectorOpen: !isBranchSelectorOpen })
              }
              heading="Select Branch"
              width={"480px"}
              dialogActions={
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <FPButton onClick={this.uploadExcelFile}>Upload</FPButton>
                </div>
              }
            >
              <FPSelect
                options={
                  branches &&
                  branches.choices.map((i) => ({
                    label: i.display_name,
                    value: i.value,
                  }))
                }
                placeHolder="Select Branch"
                onChange={(e) => this.setState({ branch: e.target.value })}
              />

              {/* <Menu>
                <Dropdown
                  className="dropdown-select"
                  placeholder="Select Branch"
                  options={
                    branches &&
                    branches.choices.map((i) => ({
                      text: i.display_name,
                      value: i.value,
                    }))
                  }
                  onChange={(e, { value }) => this.setState({ branch: value })}
                />
              </Menu> */}
            </FormModal>
          {/* <ConfirmationModal
            header="Are you sure you want to delete?"
            subHeader="This cannot be undone."
            isOpen={isConfirmOpen}
            toggle={this.toggleConfirm}
            onConfirm={confirmationFunction}
          /> */}
        </Col>
      </Row>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    contact: state.contact.list,
    contactIds: state.contact.ids,
    genericTemplateDetailsList: state.genericTemplate.genericContacts,
    countryOption: state.contact.country,
    stateOption: state.contact.region,
    campaign: state.campaign,
    contactDetail: state.contact.detail,
    genericContactDetail: state.genericTemplate.genericContact,
    errors: state.contact.errors,
    tagGroups: state.contact.tagGroupsList,
    loader: state.common.loader,
    branches: state.business.branches,
  };
};

ContactsListPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  templateActions: PropTypes.instanceOf(Object),
  CommonActions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  contact: PropTypes.instanceOf(Object),
  campaign: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ContactActions, dispatch),
    templateActions: bindActionCreators(CampaignActions, dispatch),
    genericTemplateActions: bindActionCreators(
      GenericTemplateActions,
      dispatch
    ),
    businessActions: bindActionCreators(BusinessActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ContactsListPage);
