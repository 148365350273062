import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { Button } from 'reactstrap';


const AttachFilePreview = (props) => {
  const { messageData } = props;
  function attchmentDownload(data){
    let url = '';
    let filename='';

    if(data.attached_file != null){
      url = data.file_attached;
      if(data.msg_type === 'video'){
        filename = data.attached_file.split('messenger_attachement/')[1];
      }else if (data.msg_type === 'image'){
        filename = data.attached_file.split('messenger_attachement/')[1];
      }else if (data.msg_type === 'audio'){
        filename = data.attached_file.split('messenger_attachement/')[1];
      }else if (data.msg_type === 'file'){
        filename = data.attached_file.split('messenger_attachement/')[1];
      }
    }else{
      url = data.file_url;
      if(data.msg_type === 'video'){
        filename = data.file_url.split('.mp4/')[1].split('?')[0];
      }else if (data.msg_type === 'image'){
        filename = 'image.png';
      }else if (data.msg_type === 'audio'){
        filename = 'audio.mp3';
      }else if (data.msg_type === 'file'){
        if(data.file_url.includes('.pdf/')){
          filename = data.file_url.split('.pdf/')[1].split('?')[0];
        }else if(data.file_url.includes('.docx/')){
          filename = data.file_url.split('.docx/')[1].split('?')[0];
        }else if(data.file_url.includes('.doc/')){
          filename = data.file_url.split('.doc/')[1].split('?')[0];
        }else if(data.file_url.includes('.txt/')){
          filename = data.file_url.split('.txt/')[1].split('?')[0];
        }else if(data.file_url.includes('.xlsx/')){
          filename = data.file_url.split('.xlsx/')[1].split('?')[0];
        }else if(data.file_url.includes('.xls/')){
          filename = data.file_url.split('.xls/')[1].split('?')[0];
        }else if(data.file_url.includes('.csv/')){
          filename = data.file_url.split('.csv/')[1].split('?')[0];
        }else{
          filename = data.attached_file.split('messenger_attachement/')[1];
        }
      }
    }
    if(url != ''){
      saveAs(
        url,
        filename
      );
    }
  }
  if(messageData.msg_type === 'image' || messageData.is_story_reply){
    if(messageData.attached_file !== null){
      return (<img src={messageData.file_attached} alt="attach image" style={{width:'300px',height:'250px'}}/>);
    }else{
      if(messageData.file_url.includes('&dl')){
        return (<img src={messageData.file_url.split('&dl')[0]} alt="attach image" style={{width:'300px',height:'250px'}}/>);
      }else{
        return (<img src={messageData.file_url} alt="attach image" style={{width:'300px',height:'250px'}}/>);
      }
    }
  }else if(messageData.msg_type === 'file'){
    if(messageData.attached_file !== null){
      if(messageData.attached_file.includes('docx')){
        return (<iframe src={`https://docs.google.com/gview?url=${messageData.file_attached}&embedded=true`}
          frameBorder='0'
          allowFullScreen
          title='video' style={{height:'100%',width:'100%'}}/>);
      }else if(messageData.attached_file.includes('doc')){
        return (<iframe src={`https://docs.google.com/gview?url=${messageData.file_attached}&embedded=true`}
          frameBorder='0'
          allowFullScreen
          title='video' style={{width:'100%',height:'100% '}}/>);
      }else if (messageData.attached_file.includes('pdf')){
        return (<embed src={messageData.file_attached}
          type='application/pdf' style={{width:'100%',height:'100%'}}/>);
      }else if (messageData.attached_file.includes('xlsx')){
        return (<iframe src={`https://docs.google.com/gview?url=${messageData.file_attached}&embedded=true`}
          frameBorder='0' allowFullScreen style={{width:'100%',height:'100%'}}/>);
      }else if (messageData.attached_file.includes('xls')){
        return (<iframe src={`https://docs.google.com/gview?url=${messageData.file_attached}&embedded=true`}
          frameBorder='0' allowFullScreen style={{width:'100%',height:'100%'}}/>);
      }else if (messageData.attached_file.includes('txt')){
        return (<embed src={`https://docs.google.com/viewer?url=${messageData.file_attached}&embedded=true`}
          type='application/pdf' style={{width:'100%',height:'100%'}}/>);
      }else if (messageData.attached_file.includes('csv')){
        return(<div><p>No preview available</p><Button onClick={()=>attchmentDownload(messageData)}>Download file</Button></div>);
      }else{
        return(<p>No preview</p>);
      }
    }else{
      if(messageData.file_url.includes('docx')){
        return(<div><p>No preview available</p><Button onClick={()=>attchmentDownload(messageData)}>Download file</Button></div>);
      }else if(messageData.file_url.includes('doc')){
        return(<div><p>No preview available</p><Button onClick={()=>attchmentDownload(messageData)}>Download file</Button></div>);
      }else if (messageData.file_url.includes('pdf')){
        return(<div><p>No preview available</p><Button onClick={()=>attchmentDownload(messageData)}>Download file</Button></div>);
      }else if (messageData.file_url.includes('txt')){
        return(<div><p>No preview available</p><Button onClick={()=>attchmentDownload(messageData)}>Download file</Button></div>);
      }else if (messageData.file_url.includes('xlsx')){
        return(<div><p>No preview available</p><Button onClick={()=>attchmentDownload(messageData)}>Download file</Button></div>);
      }else if (messageData.file_url.includes('xls')){
        return(<div><p>No preview available</p><Button onClick={()=>attchmentDownload(messageData)}>Download file</Button></div>);
      }else if (messageData.file_url.includes('csv')){
        return(<div><p>No preview available</p><Button onClick={()=>attchmentDownload(messageData)}>Download file</Button></div>);
      }else{
        return('no preview');
      }
    }
  }else if(messageData.msg_type === 'video'){
    if(messageData.attached_file !== null){
      return (<iframe src={messageData.file_attached}
        frameBorder='0'
        allowFullScreen
        title='video' style={{width:'300px',height:'300px'}}/>);
    }else{
      if(messageData.file_url.includes('&dl')){
        return (<iframe src={messageData.file_url.split('&dl')[0]}
          frameBorder='0'
          allowFullScreen
          title='video' style={{width:'300px',height:'300px'}}/>);
      }else{
        return (<iframe src={messageData.file_url}
          frameBorder='0'
          allowFullScreen
          title='video' style={{width:'300px',height:'300px'}}/>);
      }
    }
  }else{
    return ('no file');
  }
};

AttachFilePreview.propTypes = {
  messageData: PropTypes.object.isRequired,
};

export default AttachFilePreview;
