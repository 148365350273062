import React, { Component } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import MultiSelect from "../common/SelectComponent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FPLabel from "../common/new/forms/ui/FPLabel";
import FPInput from "../common/new/forms/ui/FPInput";
import FPTextArea from "../common/new/forms/ui/FPTextArea";
import { FPButton, FPToolTip } from "../common/new";
import InfoIcon from "../../images/svg/Information.svg";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import SelectComponent from "../common/SelectComponent";
import ConfirmationModal from "../common/ConfirmationModal";

export class TeamMappingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mappingName: null,
      loader: false,
      agency_id: this.props.agency_id,
      mappingDescription: null,
      selectedBusiness: null,
      selectedBranches: [],
      existingTeam: [],
      selectedUsers: [],
      teamMappingId: null,
      businessBranches: [],
      agency_users: [],
      newAssigneeOptions: [],
      businessBranches: [],
      businessList: [],
    };
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.deleteTeamMapping = this.deleteTeamMapping.bind(this);
  }

  componentDidMount() {
    const { location, mappingData } = this.props;
    axios_instance.get(URLS.AGENCY_USERS + "?agency_id=" + this.state.agency_id).then((res) => {
      this.setState({ agency_users: res.data.user_list, newAssigneeOptions: res.data.user_list });
    });
    if (mappingData) {
      var business_branches = mappingData.branch;
      business_branches.forEach(function (element) {
        element.value = element.id;
        element.display_name = element.alias ? element.alias : element.location_address;
      });
      axios_instance.get(`${URLS.BUSINESS_SETUP}${mappingData.business.id}/branch/`).then((res) => {
        this.setState({ businessBranches: res.data.choices.slice(1) });
      });
      const results = this.state.agency_users.filter(
        ({ id: id_1 }) => !mappingData.staff.some(({ id: id_2 }) => id_2 === id_1)
      );
      this.setState({
        mappingName: mappingData.name,
        mappingDescription: mappingData.description,
        selectedBusiness: mappingData.business.id,
        selectedBranches: business_branches,
        existingTeam: mappingData.staff,
        teamMappingId: mappingData.id,
        newAssigneeOptions: results,
      });
    }
    axios_instance.get(utils.format(`${URLS.AGENCY_BUSINESS_ALL}`, [this.state.agency_id])).then((res) => {
      this.setState({ businessList: res.data });
    });
  }

  handleNameChange = (e) => {
    this.setState({ mappingName: e.target.value });
  };

  handleDescriptionChange(e) {
    this.setState({ mappingDescription: e.target.value });
  }

  handleBusinessChange(val) {
    if (val && val.value != "") {
      this.setState({ selectedBusiness: val.value });
      axios_instance.get(`${URLS.BUSINESS_SETUP}${val.value}/branch/`).then((res) => {
        this.setState({ businessBranches: res.data.choices.slice(1) });
      });
      axios_instance
        .get(`${utils.format(URLS.TEAM, [val.value])}`, {
          page_size: 100,
        })
        .then((res) => {
          const results = this.state.agency_users.filter(
            ({ id: id_1 }) => !res.data.results.some(({ id: id_2 }) => id_2 === id_1)
          );
          this.setState({
            existingTeam: res.data.results,
            newAssigneeOptions: results,
          });
        });
    } else {
      this.setState({ selectedBusiness: null, existingTeam: [] });
    }
  }

  handleBranchChange(val) {
    const branchValues = val.map((branch) => branch.value).join(",");
    axios_instance
      .get(`${utils.format(URLS.TEAM, [this.state.selectedBusiness])}?branch=${branchValues}`, { page_size: 100 })
      .then((res) => {
        const results = this.state.agency_users.filter(
          ({ id: id_1 }) => !res.data.results.some(({ id: id_2 }) => id_2 === id_1)
        );
        this.setState({
          existingTeam: res.data.results,
          newAssigneeOptions: results,
        });
      });
  }

  toggleConfirm = (confirmationFunction) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmationFunction,
    });
  };

  deleteTeamMapping(id) {
    const { onSuccess } = this.props;
    this.setState({ loader: true });
    axios_instance.delete(utils.format(`${URLS.TEAM_MAPPING}${id}/`, [this.state.agency_id])).then(() => {
      this.setState({ loader: false });
      onSuccess?.();
      // history.push(`/${agency_id}/agency-team`);
    });
  }

  onSubmit(data) {
    const { onSuccess } = this.props;
    const staff = data["new_assignee"] ? data["new_assignee"].map((item) => item.value).join(",") : "";
    const selectedBranches = data["selected_branches"]
      ? data["selected_branches"].map((item) => item.value).join(",")
      : "";
    if (this.state.teamMappingId) {
      this.setState({ loader: true });
      var branches = this.state.selectedBranches;
      // if ("selected_branches" in data) {
      //   branches = data["selected_branches"];
      // }
      var already_staff = this.state.existingTeam.map((a) => a.id).join(", ");
      if ("existing_team" in data) {
        already_staff = data["existing_team"];
      }
      let new_data = {
        name: this.state.mappingName,
        description: this.state.mappingDescription,
        business: this.state.selectedBusiness,
        marketing: this.state.agency_id,
        // branch: selectedBranches,
        // staff: staff,
        existing_staff: already_staff,
      };
      if (staff) {
        new_data["staff"] = staff;
      }
      if (selectedBranches) {
        new_data["branch"] = selectedBranches;
      }

      axios_instance
        .put(utils.format(`${URLS.TEAM_MAPPING}${this.state.teamMappingId}/`, [this.state.agency_id]), new_data)
        .then((res) => {
          if (res.data.success === true) {
            this.setState({ loader: false });
            onSuccess?.();
          } else {
            this.setState({ loader: false });
            alert(res.data.message);
          }
        });
    } else {
      this.setState({ loader: true });
      var already_staff_post = this.state.existingTeam.map((a) => a.id).join(", ");
      if ("existing_team" in data) {
        already_staff_post = data["existing_team"];
      }
      let new_data = {
        name: this.state.mappingName,
        description: this.state.mappingDescription,
        business: this.state.selectedBusiness,
        marketing: this.state.agency_id,
        // branch: selectedBranches,
        // staff: staff,
        existing_staff: already_staff_post,
      };
      if (staff) {
        new_data["staff"] = staff;
      }
      if (selectedBranches) {
        new_data["branch"] = selectedBranches;
      }

      axios_instance.post(utils.format(`${URLS.TEAM_MAPPING}`, [this.state.agency_id]), new_data).then((res) => {
        if (res.data.success === true) {
          this.setState({ loader: false });
          onSuccess?.();
        } else {
          this.setState({ loader: false });
          alert(res.data.message);
        }
      });
    }
  }

  render() {
    const { handleSubmit } = this.props;
    const { existingTeam, businessList } = this.state;
    return (
      <div>
        <form className="grid-form" onSubmit={handleSubmit(this.onSubmit)}>
          {/* {errors && errors.non_field_errors
            ? errors.non_field_errors.map((error, index) => (
                <p key={index} className="text-danger">
                  {error}
                </p>
              ))
            : null} */}
          <div>
            <div className="mb-3">
              <FPLabel isRequired={false}>Name</FPLabel>
              <FPInput
                name="mapping_name"
                value={this.state.mappingName}
                onChange={this.handleNameChange}
                placeholder="Name"
              />
            </div>
            <div>
              <FPLabel isRequired={false}>Description</FPLabel>
              <FPTextArea
                name="mapping_description"
                rows={3}
                cols={40}
                value={this.state.mappingDescription}
                onChange={this.handleDescriptionChange}
                placeholder="Description"
                style={{
                  fontSize: "14px",
                  height: "80px",
                  padding: "13px",
                  borderRadius: "8px",
                }}
                minRows={12}
              />
            </div>
            <div className="my-3">
              <div className="d-flex align-center gap-6">
                <FPLabel>Business</FPLabel>
                <FPToolTip title={"Please select business to add team mapping"}>
                  <img src={InfoIcon} />
                </FPToolTip>
              </div>
              <Field
                name="selected_business"
                component={SelectComponent}
                options={businessList.map((item) => ({
                  display_name: item.name,
                  value: item.id,
                }))}
                placeholder="Select Business"
                labelKey="display_name"
                valueKey="value"
                onChange={(e, val) => {
                  this.handleBusinessChange(val);
                }}
                val={this.state.selectedBusiness}
              />
            </div>
            <div>
              <FPLabel isRequired={false}>Locations</FPLabel>
              <Field
                name="selected_branches"
                component={MultiSelect}
                placeholder="Select Locations"
                onChange={this.handleBranchChange}
                options={
                  this.state.businessBranches &&
                  this.state.businessBranches.map((item) => ({
                    label: item.display_name,
                    value: item.value,
                  }))
                }
                val={
                  this.state.selectedBranches.length != 0 &&
                  this.state.selectedBranches.map((item) => ({
                    label: item.display_name,
                    value: item.value,
                  }))
                }
                labelKey="label"
                valueKey="value"
                multi
                disabled={!this.state.selectedBusiness}
                className="h-100"
              />
            </div>
            <div className="my-3">
              <FPLabel isRequired={false}>Existing Team</FPLabel>
              <Field
                name="existing_team"
                component={MultiSelect}
                valueKey="value"
                labelKey="label"
                placeholder="Existing Team Members"
                options={existingTeam.map((item) => ({
                  label: item.email,
                  value: item.id,
                }))}
                val={
                  existingTeam.length != 0 &&
                  existingTeam.map((item) => ({
                    label: item.email,
                    value: item.id,
                  }))
                }
                className="h-100"
                multi
              />
            </div>
            <div>
              <FPLabel isRequired={false}>New Assignee</FPLabel>
              <Field
                name="new_assignee"
                component={MultiSelect}
                placeholder="Select Team Members to assign"
                labelKey="label"
                valueKey="value"
                options={
                  this.state.newAssigneeOptions?.length > 0 &&
                  this.state.newAssigneeOptions.map((item) => ({
                    label: item.email,
                    value: item.id,
                  }))
                }
                multi
                className="h-100"
              />
            </div>
          </div>
          {this.state.teamMappingId ? (
            <div className="d-flex align-items-center gap-20 mt-4">
              <FPButton
                label="Delete"
                className="w-50"
                borderRadius="8px"
                variant="outline"
                onClick={() => this.toggleConfirm(() => this.deleteTeamMapping(this.state.teamMappingId))}
              />
              <FPButton
                type="submit"
                label="Save"
                className="w-50"
                disabled={!this.state.selectedBusiness}
                borderRadius="8px"
              />
            </div>
          ) : (
            <FPButton
              type="submit"
              label="Save"
              className="mt-4"
              disabled={!this.state.selectedBusiness}
              fullWidth
              borderRadius="8px"
            />
          )}
        </form>
        <ConfirmationModal
          header="Are you sure!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader={`Are you sure you want to delete this team?`}
          isOpen={this.state.isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={this.state.confirmationFunction}
        />
      </div>
    );
  }
}

TeamMappingModal.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

TeamMappingModal.propTypes = {
  actions: PropTypes.instanceOf(Object),
};

TeamMappingModal = reduxForm({
  // eslint-disable-line
  form: "TeamMappingModal",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(TeamMappingModal);

const selector = formValueSelector("TeamMappingModal");

TeamMappingModal = connect((state) => {
  // eslint-disable-line
  const selectedBranches = selector(state, "selected_branches");
  const selectedUsers = selector(state, "new_assignee");
  const existingTeam = selector(state, "existing_team");
  return {
    selectedBranches,
    selectedUsers,
    existingTeam,
  };
})(TeamMappingModal);

export default TeamMappingModal;
