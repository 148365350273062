import React, { useEffect, useState } from "react";
import replyleft from "../../../images/svg/Reply.svg";
import attachment from "../../../images/svg/attachment.svg";
import CloseIcon from "@mui/icons-material/Close";
import { AiOutlineDelete } from "react-icons/ai";
import { FPButton, FPToolTip } from "../../../components/common/new";
import { Send } from "../../../images/svg";
import ConfirmationModal from "../../../components/common/ConfirmationModal";
import MediaViewer from "../../../components/common/MediaViewer";
import FormModal from "../../../components/common/FormModal";
import FPInput from "../../../components/common/new/forms/ui/FPInput";
import { Box } from "@mui/material";

export default function EmailReplyCard({
  recipientName = "",
  onClose,
  onSend,
  bodyData, forwardMail, setForwardMail
}) {
  const [content, setContent] = useState("");
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [toEmails, setToEmails] = useState(recipientName);
  const [ccEmails, setCcEmails] = useState("");
  const [bccEmails, setBccEmails] = useState("");
  const [ccError, setCcError] = useState("");
  const [toError, setToError] = useState("");
  const [bccError, setBccError] = useState("");
  const [replyAllEmail, setReplyAllEmail] = useState(false);
  const [attachments, setAttachments] = useState([]);
useEffect(() => {
  if (bodyData?.body && forwardMail) {
    
    setContent(bodyData?.body)
    setToEmails("")
  }

}, [bodyData])
  const validateEmails = (emails) => {
    const emailArray = !Array.isArray(emails)
      ? emails?.split(",")?.map((email) => email.trim())
      : emails?.map((email) => email.trim());
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailArray.every((email) => emailRegex.test(email));
  };

  const handleFileUpload = (event) => {
    const files = Array.from(event.target.files);
    const newAttachments = files.map((file) => ({
      file,
      url: URL.createObjectURL(file),
      type: file.type,
    }));
    setAttachments([...attachments, ...newAttachments]);
  };

  const removeAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const formatEmails = (input) => {
    return input.replace(/\s+/g, ", ").replace(/,+/g, ",");
  };

  const handleToChange = (e) => {
    const formattedEmails = formatEmails(e.target.value);
    setToEmails(formattedEmails);

    if (formattedEmails && !validateEmails(formattedEmails)) {
      setToError("Invalid recipient email format");
    } else {
      setToError("");
    }
  };

  const handleCcChange = (e) => {
    const formattedEmails = formatEmails(e.target.value);
    setCcEmails(formattedEmails);

    if (formattedEmails && !validateEmails(formattedEmails)) {
      setCcError("Invalid CC email format");
    } else {
      setCcError("");
    }
  };

  const handleBccChange = (e) => {
    const formattedEmails = formatEmails(e.target.value);
    setBccEmails(formattedEmails);

    if (formattedEmails && !validateEmails(formattedEmails)) {
      setBccError("Invalid BCC email format");
    } else {
      setBccError("");
    }
  };

  const handleSend = () => {
    if (!validateEmails(toEmails)) {
      setToError(!toEmails ? "Enter Receiver email" : "Invalid recipient email format");
      return;
    }

    if (showCc && ccEmails && !validateEmails(ccEmails)) {
      setCcError("Invalid CC email format");
      return;
    } else {
      setCcError("");
    }

    if (showBcc && bccEmails && !validateEmails(bccEmails)) {
      setBccError("Invalid BCC email format");
      return;
    } else {
      setBccError("");
    }

    setReplyAllEmail(true);
  };
  const handleClose = () => {
    onClose();
    setToEmails("");
    setCcEmails("");
    setBccEmails("");
    setContent("");
    setAttachments("");
    setForwardMail(false)
  };
  const handleConfirmSend = () => {
    setReplyAllEmail(false);
    if (onSend) {
      onSend({
        content,
        attachments,
        isCcSelected: showCc,
        toEmails: !Array.isArray(toEmails)
          ? toEmails.split(",").map((email) => email.trim())
          : toEmails.map((email) => email.trim()),
        ccEmails: showCc
          ? ccEmails.split(",").map((email) => email.trim())
          : [],
        isBccSelected: showBcc,
        bccEmails: showBcc
          ? bccEmails.split(",").map((email) => email.trim())
          : [],
      });
    }
  };

  return (
    <>
      <div className="email-reply-card">
        <div className="email-reply-header">
          <div className="email-reply-header-left">
            <FPToolTip title="Replying to Customer">
              <img src={replyleft} className="reply-icon" alt="Reply" />
            </FPToolTip>
            <div className="recipient-field">
              <div className="email-input-field  w-100 align-items-center gap-10">
                <div style={{paddingBottom: toError ? "15px" : "0px"}}>To:</div>
                <div>
                  <Box component='div' className="position-relative">
                    <FPInput
                      placeholder="Enter recipient emails"
                      value={toEmails}
                      onChange={handleToChange}
                    />
                    <CloseIcon
                      onClick={() => {
                        setToEmails("");
                      }}
                      className="position-absolute cursor-pointer"
                      sx={{
                        top: '9px',
                        right: '7px'
                      }}
                    />
                  </Box>
                  <div>

                  {toError && <p className="error-text">{toError}</p>}
                  </div>
                </div>
              </div>

              {showCc && (
                <div className="email-input-field w-100 align-items-center gap-10">
                  <div style={{paddingBottom: ccError ? "15px" : "0px"}}>CC:</div>
                  <div>
                    <Box component='div' className="position-relative">
                      <FPInput
                        placeholder="Enter CC emails"
                        value={ccEmails}
                        onChange={handleCcChange}
                      />
                      <CloseIcon
                        onClick={() => {
                          setShowCc(!showCc);
                          setCcEmails("");
                        }}
                        className="position-absolute cursor-pointer"
                        sx={{
                          top: '8.5px',
                          right: '7px'
                        }}
                      />
                    </Box>
                    {ccError && <p className="error-text">{ccError}</p>}
                  </div>
                </div>
              )}

              {showBcc && (
                <div className="email-input-field w-100 align-items-center gap-10">
                  <div style={{paddingBottom: bccError ? "15px" : "0px"}}>Bcc:</div>
                  <div>
                    <Box component='div' className="position-relative">
                      <FPInput
                        placeholder="Enter BCC emails"
                        value={bccEmails}
                        onChange={handleBccChange}
                      />
                      <CloseIcon
                        onClick={() => {
                          setShowBcc(!showBcc);
                          setBccEmails("");
                        }}
                        className="position-absolute cursor-pointer"
                        sx={{
                          top: '9px',
                          right: '7px'
                        }}
                      />
                    </Box>

                    {bccError && <p className="error-text">{bccError}</p>}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="email-reply-header-right">
              <span
                onClick={() => setShowCc(!showCc)}
                className="cc-and-bcc-hover"
                style={{
                  
                  cursor: "pointer",
                }}
              >
                Cc
              </span>
              <span
                onClick={() => setShowBcc(!showBcc)}
                className="cc-and-bcc-hover"

                style={{
                  cursor: "pointer",
                }}
              >
                Bcc
              </span>
          </div>
        </div>

        <div className="email-reply-content">
          <textarea
            placeholder="Write your reply here..."
            value={content}
            onChange={(e) => setContent(e.target.value)}
          ></textarea>
        </div>

        <div className="email-reply-footer">
          <div className="action-buttons">
            <label>
              <img
                src={attachment}
                alt="Attachment"
                style={{ height: "30px", width: "30px", cursor: "pointer" }}
              />
              <input
                type="file"
                multiple
                style={{ display: "none" }}
                accept="image/*,video/*,audio/*"
                onChange={handleFileUpload}
              />
            </label>

            {attachments.map((file, index) => (
              <div key={index} className="attachment-preview">
                <img
                  src={file.url}
                  alt={`attachment-${index}`}
                  className="attachment-thumbnail"
                />
                <CloseIcon
                  className="clear-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeAttachment(index);
                  }}
                  sx={{
                    fontSize: "12px",
                    backgroundColor: "red",
                    color: "#fff",
                  }}
                />
              </div>
            ))}
          </div>
          <div className="d-flex gap-10 align-center">
            <AiOutlineDelete
              className="footer-icon"
              onClick={() => handleClose()}
            />
            <FPButton
              label="Send"
              size="small"
              endIcon={<Send />}
              onClick={handleSend}
            />
          </div>
        </div>
      </div>

      <ConfirmationModal
        header={forwardMail ? "Forward this Email!" : "Reply to Email!"}
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader= {forwardMail ? "This is an external communication. This mail will also be forwarded to customer. Do want to continue?" : "This is an external communication. You are replying to customer. Do want to continue?"}
        isOpen={replyAllEmail}
        toggle={() => setReplyAllEmail(!replyAllEmail)}
        onConfirm={handleConfirmSend}
        buttonName="Continue"
      />
    </>
  );
}
