import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import DoneAllOutlinedIcon from "@material-ui/icons/DoneAllOutlined";
import CloseIcon from "@material-ui/icons/Close";
import { ClipLoader } from "react-spinners";

toast.configure({
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    position: toast.POSITION.BOTTOM_LEFT,
});

const CloseButton = ({ closeToast }) => (
    <span className="material-icons m-auto">
        <CloseIcon onClick={closeToast} role="button" />
    </span>
);

const renderMessage = (type, message) => (
    <div className="d-flex align-items-center gap-10">
        <span>
            {type === "success" ? <DoneAllOutlinedIcon /> : (type === "error" ? <ErrorOutlineIcon /> : <ClipLoader color="#ffffff" />)}
        </span>
        <span>{message}</span>
    </div>
);


const renderSyncMessage = (type, message, messageValue, updateCount, totalCount) => (
    <div className="d-flex align-items-center gap-10">
        <span>
            {type === "success" ? <DoneAllOutlinedIcon /> : (type === "error" ? <ErrorOutlineIcon /> : <ClipLoader color="#ffffff" />)}
        </span>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span>{message}</span>
            <span>{messageValue}</span>
            {type === "info" || updateCount || totalCount ? <progress value={updateCount} max={totalCount} /> : ""}
        </div>
    </div>
);


export const displayMessage = (messageType, message, type) => {
    if (messageType === "positive")
        return toast.success(renderMessage("success", message), {
            closeButton: <CloseButton />,
            autoClose: 5000, // Close after 5 seconds
        });
    else if (messageType === "negative")
        return toast.error(renderMessage("error", message), {
            closeButton: <CloseButton />,
            autoClose: 5000, // Close after 5 seconds
        });
    else if (messageType === "close")
        return toast.dismiss();
    else
        return toast.info(renderMessage("info", message), {
            closeButton: <CloseButton />,
            autoClose: false
        });
};

export const displaySyncTost = (messageType, message, messageValue, updateCount, totalCount) => {
    if (messageType === "positive")
        return toast.success(renderSyncMessage("success", message, messageValue, updateCount, totalCount), {
            closeButton: <CloseButton />,
            autoClose: 5000, // Close after 5 seconds
        });
    else if (messageType === "negative")
        return toast.error(renderSyncMessage("error", message), {
            closeButton: <CloseButton />,
            autoClose: 5000, // Close after 5 seconds
        });
    else if (messageType === "close")
        return toast.dismiss();
    else
        return toast.info(renderSyncMessage("info", message, messageValue, updateCount, totalCount), {
            closeButton: <CloseButton />,
            autoClose: false
        });
};
