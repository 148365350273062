import React from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import moment from "moment";
import { bindActionCreators } from "redux";
import { Dropdown } from "semantic-ui-react";
import Flatpickr from "react-flatpickr";

import * as OverviewActions from "../../actions/overview/OverviewActions";
import { connect } from "react-redux";

class Filters extends React.Component {
  constructor(props) {
    super(props);
    this.filtersToQuery = this.filtersToQuery.bind(this);
    this.state = {
      date: {},
      selectedDate: null,
    };
  }

  filtersToQuery(filters) {
    const {
      history,
      history: { location },
    } = this.props;
    const url = location.pathname + "?" + queryString.stringify(filters);
    history.push(url);
  }

  componentDidMount() {
    const that = this;
    const { blank_req } = this.props;

    // * handle selected Date

    this.props.OverviewActions.getDateFilter({ blank_req }).then((resp) => {
      if (resp.data.default) {
        const filter = resp.data.choices.filter(
          (item) => item.value === resp.data.default
        )[0];
        that.filtersToQuery({ filter });
      }
    });

    this.handleSelectedDate();
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.dateFilterRef &&
      this.startDateRef &&
      this.endDateRef &&
      !this.dateFilterRef.contains(event.target) &&
      !this.startDateRef.flatpickr.calendarContainer.contains(event.target) &&
      !this.endDateRef.flatpickr.calendarContainer.contains(event.target)
    ) {
      this.setState({ dropdownOpen: false });
    }
  };

  handleSelectedDate() {
    const {
      overview: { filters },
    } = this.props;
    const {
      date: { date_type, start_date, end_date },
    } = this.state;
    let selected =
      (filters && filters.choices.filter((item) => item.value === date_type)) ||
      [];

    let start_date_obj = new Date(start_date);
    let end_date_obj = new Date(end_date);
    let sdate = start_date_obj.getDate();
    sdate = sdate < 10 ? `0${sdate}` : sdate;
    let smonth = start_date_obj.getMonth() + 1;
    smonth = smonth < 10 ? `0${smonth}` : smonth;
    let syear = start_date_obj.getFullYear();
    let edate = end_date_obj.getDate();
    edate = edate < 10 ? `0${edate}` : edate;
    let emonth = end_date_obj.getMonth() + 1;
    emonth = emonth < 10 ? `0${emonth}` : emonth;
    let eyear = end_date_obj.getFullYear();
    selected =
      selected.length > 0
        ? selected[0].display_name
        : date_type === "other"
        ? `${sdate}/${smonth}/${syear} to ${edate}/${emonth}/${eyear}`
        : "Select Date Range";

    this.setState({
      ...this.state,
      ["selectedDate"]: selected,
    });
  }

  handleDateChange(payload, dateType) {
    const { date } = this.state;
    const { onChange } = this.props;
    onChange({ ...date, ...payload }, dateType);
    this.handleSelectedDate();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.date && nextProps.date !== prevState.date) {
      return { date: nextProps.date };
    }
  }

  render() {
    const {
      overview: { filters },
      className,
      ...rest
    } = this.props;
    const {
      date: { start_date, end_date },
      dropdownOpen,
      selectedDate,
    } = this.state;

    return (
      <div ref={(node) => (this.dateFilterRef = node)} className="date-select">
        <div
          role="listbox"
          aria-expanded={dropdownOpen}
          className={`ui dropdown select-range ${dropdownOpen && "active"} ${
            className && className
          }`}
          tabIndex="0"
          onClick={() => this.setState({ dropdownOpen: !dropdownOpen })}
          {...rest}
        >
          <span style={selectedDate ? { opacity: 1 } : { opacity: 0.4 }}>
            {selectedDate}
          </span>
          <i aria-hidden="true" className="dropdown icon"></i>
          <Dropdown.Menu className="scroll50Y full-width" open={dropdownOpen}>
            <div className="other-date" onClick={(e) => e.stopPropagation()}>
              <Flatpickr
                ref={(node) => (this.startDateRef = node)}
                className="mr-1 input-width"
                value={moment(start_date).format("YYYY-MM-DD")}
                onChange={(value) =>
                  this.handleDateChange(
                    { start_date: value[0], date_type: "other" },
                    "start_date"
                  )
                }
              />
              <span style={{ lineHeight: "36px" }} className="to-text">
                To
              </span>
              <Flatpickr
                ref={(node) => (this.endDateRef = node)}
                className="mr-1 ml-1 input-width"
                value={moment(end_date).format("YYYY-MM-DD")}
                onChange={(value) =>
                  this.handleDateChange(
                    { end_date: value[0], date_type: "other" },
                    "end_date"
                  )
                }
                options={{
                  minDate: moment(start_date).format("YYYY-MM-DD"),
                }}
              />
            </div>
            {filters &&
              filters.choices.map((item, key) => (
                <Dropdown.Item
                  className="text-center"
                  key={key}
                  text={item.display_name}
                  onClick={() =>
                    this.handleDateChange(
                      { date_type: item.value },
                      "date_type"
                    )
                  }
                />
              ))}
          </Dropdown.Menu>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    overview: state.overview,
  };
};

Filters.propTypes = {
  OverviewActions: PropTypes.instanceOf(Object),
  overview: PropTypes.instanceOf(Object),
  date_type: PropTypes.string,
  start_date: PropTypes.object,
  end_date: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    OverviewActions: bindActionCreators(OverviewActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Filters);
