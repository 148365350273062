export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const GET_AUTH_LOGIN_URL_SUCCESS = "GET_AUTH_LOGIN_URL_SUCCESS";
export const GET_AUTH_LOGIN_URL_FAILURE = "GET_AUTH_LOGIN_URL_FAILURE";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const BUSINESSLIST_SUCCESS = "BUSINESSLIST_SUCCESS";
export const BUSINESSLIST_FAILURE = "BUSINESSLIST_FAILURE";

export const REGISTER_CONFIRM_SUCCESS = "REGISTER_CONFIRM_SUCCESS";
export const REGISTER_CONFIRM_FAILURE = "REGISTER_CONFIRM_FAILURE";

export const GET_BUSINESS_TYPE_SUCCESS = "GET_BUSINESS_SUCCESS";
export const GET_BUSINESS_TYPE_FAILURE = "GET_BUSINESS_FAILURE";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const PROFILE_UPDATE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_UPDATE_FAILURE = "PROFILE_FAILURE";
export const PROFILE_INFO_SUCCESS = "PROFILE_INFO_SUCCESS";
export const PROFILE_INFO_FAILURE = "PROFILE_INFO_FAILURE";

export const BUSINESS_SETUP_SUCCESS = "BUSINESS_SETUP_SUCCESS";
export const BUSINESS_SETUP_FAILURE = "BUSINESS_SETUP_FAILURE";

export const GET_BUSINESS_SUCCESS = "GET_BUSINESS_SUCCESS";
export const GET_BUSINESS_FAILURE = "GET_BUSINESS_FAILURE";

export const BUSINESS_BRANCH_DETAILS_SUCCESS = "BUSINESS_BRANCH_DEATILS_SUCCESS";
export const BUSINESS_BRANCH_DEATILS_FAILURE = "BUSINESS_BRANCH_DEATILS_FAILURE";

export const GET_BUSINESS_SOCIAL_LINKS_SUCCESS = "GET_BUSINESS_SOCIAL_LINKS_SUCCESS";
export const GET_BUSINESS_SOCIAL_LINKS_FAILURE = "GET_BUSINESS_SOCIAL_LINKS_FAILURE";

export const GET_BUSINESS_COMPLAINTS_LINKS_SUCCESS = "GET_BUSINESS_COMPLAINTS_LINKS_SUCCESS";
export const GET_BUSINESS_COMPLAINTS_LINKS_FAILURE = "GET_BUSINESS_COMPLAINTS_LINKS_FAILURE";

export const REVIEWS_DETAILS_SUCCESS = "REVIEWS_DETAILS_SUCCESS";
export const REVIEWS_DETAILS_FAILURE = "REVIEWS_DETAILS_FAILURE";

export const RATING_OPTIONS_SUCCESS = "RATINGS_OPTIONS_SUCCESS";
export const RATING_OPTIONS_FAILURE = "RATING_OPTIONS_FAILURE";

export const CATEGORY_OPTIONS_SUCCESS = "CATEGORY_OPTIONS_SUCCESS";
export const CATEGORY_OPTIONS_FAILURE = "CATEGORY_OPTIONS_FAILURE";

export const WEB_PORTAL_DROP_DOWN_SUCCESS = "WEB_PORTAL_DROP_DOWN_SUCCESS";
export const WEB_PORTAL_DROP_DOWN_FAILURE = "WEB_PORATL_DROP_DOWN_FAILURE";

export const SCHEDULE_DROP_DOWN_SUCCESS = "SCHEDULE_DROP_DOWN_SUCCESS";
export const SCHEDULE_DROP_DOWN_FAILURE = "SCHEDULE_DROP_DOWN_FAILURE";

export const SETUP_BRANCH_LOCATION_SUCCESS = "SETUP_BRANCH_LOCATION_SUCCESS";
export const SETUP_BRANCH_LOCATION_FALIURE = "SETUP_BRANCH_LOCATION_FALIURE";

export const BUSINESS_BRANCH_OPTIONS_SUCCESS = "BUSINESS_BRANCH_OPTIONS_SUCCESS";
export const BUSINESS_BRANCH_OPTIONS_FAILURE = "BUSINESS_BRANCH_OPTIONS_FAILURE";

export const ADD_TEAM_MEMBER_SUCCESS = "ADD_TEAM_MEMBER_SUCCESS";
export const ADD_TEAM_MEMBER_FAILURE = "ADD_TEAM_MEMBER_FAILURE";

export const TEAM_MEMBER_LIST_SUCCESS = "TEAM_MEMBER_LIST_SUCCESS";
export const TEAM_MEMBER_LSIT_FAILURE = "TEAM_MEMBER_LIST_FAILURE";

export const TEAM_ADD_SUCCESS = "TEAM_ADD_SUCCESS";
export const TEAM_ADD_FAILURE = "TEAM_ADD_FAILURE";

export const TEAM_LIST_SUCCESS = "TEAM_LIST_SUCCESS";
export const TEAM_LIST_FAILURE = "TEAM_LIST_FAILURE";

export const TEAM_DEPARTMENTS_USERS_SUCCESS = "TEAM_DEPARTMENTS_USERS_SUCCESS";
export const TEAM_DEPARTMENTS_USERS_FAILURE = "TEAM_DEPARTMENTS_USERS_FAILURE";

export const CONTACT_ADD_SUCCESS = "CONTACT_ADD_SUCCESS";
export const CONTACT_ADD_FAILURE = "CONTACT_ADD_FAILURE";

export const COUNTRY_CONTACT_SUCCESS = "COUNTRY_CONTACT_SUCCESS";
export const COUNTRY_CONTACT_FAILURE = "COUNTRY_CONTACT_FAILURE";

export const SEND_EMAIL_SUCCESS = "SEND EMAIL SUCCESS";
export const SEND_EMAIL_FAILURE = "SEND EMAIL FAILURE";

export const REGION_CONTACT_SUCCESS = "REGION_CONTACT_SUCCESS";
export const REGION_CONTACT_FAILURE = "REGION_CONTACT_FAILURE";

export const CONTACT_EDIT_SUCCESS = "CONTACT_EDIT_SUCCESS";
export const CONTACT_EDIT_FAILURE = "CONTACT_EDIT_FAILURE";

export const CONTACT_LIST_SUCCESS = "CONTACT_LIST_SUCCESS";
export const CONTACT_LIST_FAILURE = "CONTACT_LIST_FAILURE";

export const CONTACT_IDS_SUCCESS = "CONTACT_IDS_SUCCESS";
export const CONTACT_IDS_FAILURE = "CONTACT_IDS_FAILURE";

export const CONTACT_CSV_UPLOAD_SUCCESS = "CONTACT_CSV_UPLOAD_SUCCESS";
export const CONTACT_CSV_UPLOAD_FAILURE = "CONTACT_CSV_UPLOAD_FAILURE";

export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS";
export const GET_CONTACT_FAILURE = "GET_CONTACT_FAILURE";

export const CONTACT_DELETE_SUCCESS = "CONTACT_DELETE_SUCCESS";
export const CONTACT_DELETE_FAILURE = "CONTACT_DELETE_FAILURE";

export const AGENCY_LIST_SUCCESS = "MARKETING_LIST_SUCCESS";
export const AGENCY_LIST_FAILURE = "MARKETING_LIST_FAILURE";

export const AGENCY_ALL_BUSINESS_SUCCESS = "AGENCY_ALL_BUSINESS_SUCCESS";
export const AGENCY_ALL_BUSINESS_FAILURE = "AGENCY_ALL_BUSINESS_FAILURE";


export const POST_COMMENTS_SUCCESS = "POST_COMMENTS_SUCCESS";
export const POST_COMMENTS_FAILURE = "POST_COMMENTS_FAILURE";

export const GET_TEAM_SUCCESS = "GET_TEAM_SUCCESS";
export const GET_TEAM_FAILURE = "GET_TEAM_FAILURE";

export const TEAM_EDIT_SUCCESS = "TEAM_EDIT_SUCCESS";
export const TEAM_EDIT_FAILURE = "TEAM_EDIT_FAILURE";

export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILURE = "CREATE_CAMPAIGN_FAILURE";

export const SEND_FEEDBACK_SUCCESS = "SEND_FEEDBACK_SUCCESS";
export const SEND_FEEDBACK_FAILURE = "SEND_FEEDBACK_FAILURE";

export const POST_CAMPAIGN_SUCCESS = "POST_CAMPAIGN_SUCCESS";
export const POST_CAMPAIGN_FAILURE = "POST_CAMPAIGN_FAILURE";

export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const GET_CAMPAIGN_FAILURE = "GET_CAMPAIGN_FAILURE";

export const GET_ALL_CAMPAIGN_SUCCESS = "GET_ALL_CAMPAIGN_SUCCESS";
export const GET_ALL_CAMPAIGN_FAILURE = "GET_ALL_CAMPAIGN_FAILURE";

export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";
export const DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";

export const GET_CAMPAIGN_DETAILS_SUCCESS = "GET_CAMPAIGN_DETAILS_SUCCESS";
export const GET_CAMPAIGN_DETAILS_FAILURE = "GET_CAMPAIGN_DETAILS_FAILURE";

export const GET_CAMPAIGN_INFO_SUCCESS = "GET_CAMPAIGN_INFO_SUCCESS";
export const GET_CAMPAIGN_INFO_FAILURE = "GET_CAMPAIGN_INFO_FAILURE";

export const TEMPLATE_DELETE_SUCCESS = "TEMPLATE_DELETE_SUCCESS";
export const TEMPLATE_DELETE_FAILURE = "TEMPLATE_DELETE_FAILURE";

export const TEMPLATE_CLONE_SUCCESS = "TEMPLATE_CLONE_SUCCESS";
export const TEMPLATE_CLONE_FAILURE = "TEMPLATE_CLONE_FAILURE";

export const MARKETING_ADD_SUCCESS = "MARKETING_ADD_SUCCESS";
export const MARKETING_ADD_FAILURE = "MARKETING_ADD_FAILURE";

export const GET_MARKETING_BUSINESS_SUCCESS = "GET_MARKETING_BUSINESS_SUCCESS";
export const GET_MARKETING_BUSINESS_FAILURE = "GET_MARKETING_BUSINESS_FAILURE";

export const MARKETING_EDIT_SUCCESS = "MARKETING_EDIT_SUCCESS";
export const MARKETING_EDIT_FAILURE = "MARKETING_EDIT_FAILURE";

export const ENTERPRISE_LIST_SUCCESS = "ENTERPRISE_LIST_SUCCESS";
export const ENTERPRISE_LIST_FAILURE = "ENTERPRISE_LIST_FAILURE";

export const ENTERPRISE_USER_LIST_SUCCESS = "ENTERPRISE_USER_LIST_SUCCESS";
export const ENTERPRISE_USER_LIST_FAILURE = "ENTERPRISE_USER_LIST_FAILURE";

export const USER_ADD_SUCCESS = "USER_ADD_SUCCESS";
export const USER_ADD_FAILURE = "USER_ADD_FAILURE";

export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const REVIEW_INVITE_SUCCESS = "REVIEW_INVITE_SUCCESS";
export const REVIEW_INVITE_FAILURE = "REVIEW_INVITE_FAILURE";

export const SOCIAL_ACCOUNTS_SETUP_SUCCESS = "SOCIAL_ACCOUNTS_SETUP_SUCCESS";
export const SOCIAL_ACCOUNTS_SETUP_FAILURE = "SOCIAL_ACCOUNTS_SETUP_FAILURE";

export const GET_AUTH_URL_SUCCESS = "GET_AUTH_URL_SUCCESS";
export const GET_AUTH_URL_FAILURE = "GET_AUTH_URL_FAILURE";

export const POST_SOCIAL_CODE_SUCCESS = "POST_SOCIAL_CODE_SUCCESS";
export const POST_SOCIAL_CODE_FAILURE = "POST_SOCIAL_CODE_FAILURE";

export const GET_LOCATIONS_SUCCESS = "GET_LOCATIONS_SUCCESS";
export const GET_LOCATIONS_FAILURE = "GET_LOCATIONS_FAILURE";

export const GET_STATS_SUCCESS = "GET_STATS_SUCCESS";
export const GET_STATS_FAILURE = "GET_STATS_FAILURE";

export const GET_TRENDS_SUCCESS = "GET_TRENDS_SUCCESS";
export const GET_TRENDS_FAILURE = "GET_TRENDS_FAILURE";

export const GET_DASHBOARD_FILTERS_SUCCESS = "GET_DASHBOARD_FILTERS_SUCCESS";
export const GET_DASHBOARD_FILTERS_FAILURE = "GET_DASHBOARD_FILTERS_FAILURE";

export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILURE = "GET_REPORT_FAILURE";

export const CHECK_LOGGED_IN_SUCCESS = "CHECK_LOGGED_IN_SUCCESS";
export const CHECK_LOGGED_IN_FAILURE = "CHECK_LOGGED_IN_FAILURE";

export const LOG_OUT_SUCCESS = "LOG_OUT_SUCCESS";
export const LOG_OUT_FAILURE = "LOG_OUT_FAILURE";

export const TEMPLATE_LIST_SUCCESS = "TEMPLATE_LIST_SUCCESS";
export const TEMPLATE_LIST_FAILURE = "TEMPLATE_LIST_FAILURE";

export const GET_TEMPLATE_DETAILS_SUCCESS = "GET_TEMPLATE_DETAILS_SUCCESS";
export const GET_TEMPLATE_DETAILS_FAILURE = "GET_TEMPLATE_DETAILS_FAILURE";

export const POST_TEMPLATE_SUCCESS = "POST_TEMPLATE_SUCCESS";
export const POST_TEMPLATE_FAILURE = "POST_TEMPLATE_FAILURE";

export const GET_INVITED_MEMBERS_SUCCESS = "GET_INVITED_MEMBERS_SUCCESS";
export const GET_INVITED_MEMBERS_FAILURE = "GET_INVITED_MEMBERS_FAILURE";

export const BUSINESS_DETAIL_SUCCESS = "BUSINESS_DETAIL_SUCCESS";
export const BUSINESS_DETAIL_FAILURE = "BUSINESS_DETAIL_FAILURE";

export const EDIT_BRANCH_LOCATION_SUCCESS = "EDIT_BRANCH_LOCATION_SUCCESS";
export const EDIT_BRANCH_LOCATION_FALIURE = "EDIT_BRANCH_LOCATION_FALIURE";

export const TEAM_MEMBER_DELETE_SUCCESS = "TEAM_MEMBER_DELETE_SUCCESS";
export const TEAM_MEMBER_DELETE_FAILURE = "TEAM_MEMBER_DELETE_FAILURE";

export const REVIEW_CSV_UPLOAD_SUCCESS = "REVIEW_CSV_UPLOAD_SUCCESS";
export const REVIEW_CSV_UPLOAD_FAILURE = "REVIEW_CSV_UPLOAD_FAILURE";

export const FOLLOW_UP_SUCCESS = "FOLLOW_UP_SUCCESS";
export const FOLLOW_UP_FAILURE = "FOLLOW_UP_FAILURE";

export const REPLY_OPTIONS_SUCCESS = "REPLY_OPTIONS_SUCCESS";
export const REPLY_OPTIONS_FAILURE = "REPLY_OPTIONS_FAILURE";

export const STATUS_UPDATE_SUCCESS = "STATUS_UPDATE_SUCCESS";
export const STATUS_UPDATE_FAILURE = "STATUS_UPDATE_FAILURE";

export const RESPONSE_LIST_SUCCESS = "RESPONSE_LIST_SUCCESS";
export const RESPONSE_LIST_FAILURE = "RESPONSE_LIST_FAILURE";

export const STAFF_LIST_SUCCESS = "STAFF_LIST_SUCCESS";
export const STAFF_LIST_FAILURE = "STAFF_LIST_FAILURE";

export const STAFF_DETAIL_SUCCESS = "STAFF_DETAIL_SUCCESS";
export const STAFF_DETAIL_FAILURE = "STAFF_DETAIL_FAILURE";

export const GET_STAFF_PERFORMANCE_SUCCESS = "GET_STAFF_PERFORMANCE_SUCCESS";
export const GET_STAFF_PERFORMANCE_FAILURE = "GET_STAFF_PERFORMANCE_FAILURE";

export const AI_RESPONSE_LIST_SUCCESS = "AI_RESPONSE_LIST_SUCCESS";
export const AI_RESPONSE_LIST_FAILURE = "AI_RESPONSE_LIST_FAILURE";

export const POST_RESPONSE_SUCCESS = "POST_RESPONSE_SUCCESS";
export const POST_RESPONSE_FAILURE = "POST_RESPONSE_FAILURE";

export const POST_AI_RESPONSE_SUCCESS = "POST_AI_RESPONSE_SUCCESS";
export const POST_AI_RESPONSE_FAILURE = "POST_AI_RESPONSE_FAILURE";

export const ALLOWED_FOR_OPTIONS_SUCCESS = "ALLOWED_FOR_OPTIONS_SUCCESS";
export const ALLOWED_FOR_OPTIONS_FAILURE = "ALLOWED_FOR_OPTIONS_FAILURE";

export const REVIEW_TYPE_OPTIONS_SUCCESS = "REVIEW_TYPE_OPTIONS_SUCCESS";
export const REVIEW_TYPE_OPTIONS_FAILURE = "REVIEW_TYPE_OPTIONS_FAILURE";

export const GET_RESPONSE_DETAILS_SUCCESS = "GET_RESPONSE_DETAILS_SUCCESS";
export const GET_RESPONSE_DETAILS_FAILURE = "GET_RESPONSE_DETAILS_FAILURE";

export const GET_AI_RESPONSE_DETAILS_SUCCESS = "GET_AI_RESPONSE_DETAILS_SUCCESS";
export const GET_AI_RESPONSE_DETAILS_FAILURE = "GET_AI_RESPONSE_DETAILS_FAILURE";

export const RESPONSE_DELETE_SUCCESS = "RESPONSE_DELETE_SUCCESS";
export const RESPONSE_DELETE_FAILURE = "RESPONSE_DELETE_FAILURE";

export const AI_RESPONSE_DELETE_SUCCESS = "AI_RESPONSE_DELETE_SUCCESS";
export const AI_RESPONSE_DELETE_FAILURE = "AI_RESPONSE_DELETE_FAILURE";

export const RESPONSE_OPTIONS_SUCCESS = "RESPONSE_OPTIONS_SUCCESS";
export const RESPONSE_OPTIONS_FAILURE = "RESPONSE_OPTIONS_FAILURE";

export const TOGGLE_SMART_RESPONSE_SUCCESS = "TOGGLE_SMART_RESPONSE_SUCCESS";
export const TOGGLE_SMART_RESPONSE_FAILURE = "TOGGLE_SMART_RESPONSE_FAILURE";

export const REVIEW_SOURCE_OPTIONS_SUCCESS = "REVIEW_SOURCE_OPTIONS_SUCCESS";
export const REVIEW_SOURCE_OPTIONS_FAILURE = "REVIEW_SOURCE_OPTIONS_FAILURE";

export const UPDATE_ROUTE_PARAMS = "UPDATE_ROUTE_PARAMS";

export const GET_WORD_CLOUD_SUCCESS = "GET_WORD_CLOUD_SUCCESS";
export const GET_WORD_CLOUD_FAILURE = "GET_WORD_CLOUD_FAILURE";

export const GET_MENU_PERFORMANCE_SUCCESS = "GET_MENU_PERFORMANCE_SUCCESS";
export const GET_MENU_PERFORMANCE_FAILURE = "GET_MENU_PERFORMANCE_FAILURE";

export const GET_COMPLAINT_DETAIL_SUCCESS = "GET_COMPLAINT_DETAIL_SUCCESS";
export const GET_COMPLAINT_DETAIL_FAILURE = "GET_COMPLAINT_DETAIL_FAILURE";

export const GET_COMPLAINT_SOURCE_OPTIONS_SUCCESS = "GET_COMPLAINT_SOURCE_OPTIONS_SUCCESS";
export const GET_COMPLAINT_SOURCE_OPTIONS_FAILURE = "GET_COMPLAINT_SOURCE_OPTIONS_FAILURE";

export const GET_INSIGHTS_CATEGORY_SUCCESS = "GET_INSIGHTS_CATEGORY_SUCCESS";
export const GET_INSIGHTS_CATEGORY_FAILURE = "GET_INSIGHTS_CATEGORY_FAILURE";

export const POST_INSIGHTS_CATEGORY_SUCCESS = "POST_INSIGHTS_CATEGORY_SUCCESS";
export const POST_INSIGHTS_CATEGORY_FAILURE = "POST_INSIGHTS_CATEGORY_FAILURE";

export const INSIGHTS_LIST_DELETE_SUCCESS = "INSIGHTS_LIST_DELETE_SUCCESS";
export const INSIGHTS_LIST_DELETE_FAILURE = "INSIGHTS_LIST_DELETE_SUCCESS";

export const GET_INSIGHTS_DETAIL_SUCCESS = "GET_INSIGHTS_DETAIL_SUCCESS";
export const GET_INSIGHTS_DETAIL_FAILURE = "GET_INSIGHTS_DETAIL_FAILURE";

export const GET_RECOMMENDED_KEYWORDS_SUCCESS = "GET_RECOMMENDED_KEYWORDS_SUCCESS";
export const GET_RECOMMENDED_KEYWORDS_FAILURE = "GET_RECOMMENDED_KEYWORDS_FAILURE";

export const GET_WORD_CLOUD_DATA_SUCCESS = "GET_WORD_CLOUD_DATA_SUCCESS";
export const GET_WORD_CLOUD_DATA_FAILURE = "GET_WORD_CLOUD_DATA_FAILURE";

export const GET_LOCATION_GRADING_SUCCESS = "GET_LOCATION_GRADING_SUCCESS";
export const GET_LOCATION_GRADING_FAILURE = "GET_LOCATION_GRADING_FAILURE";
export const NEWS_LETTER_LIST_SUCCESS = "GET_NEWS_LETTER_LIST_SUCCESS";
export const NEWS_LETTER_LIST_FAILURE = "GET_NEWS_LETTER_LIST_FAILURE";

export const GET_NEWS_LETTER_DETAILS_SUCCESS = "GET_NEWS_LETTER_DETAILS_SUCCESS";
export const GET_NEWS_LETTER_DETAILS_FAILURE = "GET_NEWS_LETTER_DETAILS_FAILURE";

export const POST_NEWS_LETTER_SUCCESS = "POST_NEWS_LETTER_SUCCESS";
export const POST_NEWS_LETTER_FAILURE = "POST_NEWS_LETTER_FAILURE";

export const NEWS_LETTER_DELETE_SUCCESS = "NEWS_LETTER_DELETE_SUCCESS";
export const NEWS_LETTER_DELETE_FAILURE = "NEWS_LETTER_DELETE_FAILURE";

export const NEWS_LETTER_CLONE_SUCCESS = "NEWS_LETTER_CLONE_SUCCESS";
export const NEWS_LETTER_CLONE_FAILURE = "NEWS_LETTER_CLONE_FAILURE";

export const SENTIMENT_OPTIONS_SUCCESS = "SENTIMENT_OPTIONS_SUCCESS";
export const SENTIMENT_OPTIONS_FAILURE = "SENTIMENT_OPTIONS_FAILURE";

export const GET_COMPETITORS_OPTIONS_SUCCESS = "GET_COMPETITORS_OPTIONS_SUCCESS";
export const GET_COMPETITORS_OPTIONS_FAILURE = "GET_COMPETITORS_OPTIONS_FAILURE";

export const GET_BRANCH_COMPETITORS_SUCCESS = "GET_BRANCH_COMPETITORS_SUCCESS";
export const GET_BRANCH_COMPETITORS_FAILURE = "GET_BRANCH_COMPETITORS_FAILURE";

export const POST_BRANCH_COMPETITOR_SUCCESS = "POST_BRANCH_COMPETITOR_SUCCESS";
export const POST_BRANCH_COMPETITOR_FAILURE = "POST_BRANCH_COMPETITOR_FAILURE";

export const ADD_COMPETITORS_SUCCESS = "ADD_COMPETITORS_SUCCESS";
export const ADD_COMPETITORS_FAILURE = "ADD_COMPETITORS_FAILURE";

export const GET_COMPETITORS_SUCCESS = "GET_COMPETITORS_SUCCESS";
export const GET_COMPETITORS_FAILURE = "GET_COMPETITORS-FAILURE";

export const BRAND_OPTIONS_SUCCESS = "BRAND_OPTIONS_SUCCESS";
export const BRAND_OPTIONS_FAILURE = "BRAND_OPTIONS_FAILURE";

export const GET_COMPETITOR_LINKS_DETAILS_SUCCESS = "GET_COMPETITOR_LINKS_DETAILS_SUCCESS";
export const GET_COMPETITOR_LINKS_DETAILS_FAILURE = "GET_COMPETITOR_LINKS_DETAILS_FAILURE";

export const GET_COMPETITORS_DETAIL_SUCCESS = "GET_COMPEIITORS_DETAIL_SUCCESS";
export const GET_COMPETITORS_DETAIL_FAILURE = "GET_COMPETITORS_DETAIL_FAILURE";

export const GET_COMPETITORS_OVERVIEW_SUCCESS = "GET_COMPETITORS_OVERVIEW_SUCCESS";
export const GET_COMPETITORS_OVERVIEW_FAILURE = "GET_COMPETITORS_OVERVIEW_FAILURE";

export const GET_COMPETITORS_RATING_SUCCESS = "GET_COMPETITORS_RATING_SUCCESS";
export const GET_COMPETITORS_RATING_FALIURE = "GET_COMPETITORS_RATING_FALIURE";

export const GET_COMPETITORS_INSIGHTS_SUCCESS = "GET_COMPETITORS_INSIGHTS_SUCCESS";
export const GET_COMPETITORS_INSIGHTS_FAILURE = "GET_COMPETITORS_INSIGHTS_FAILURE";

export const POST_COMPETITOR_LINKS_SUCCESS = "POST_COMPETITOR_LINKS_SUCCESS";
export const POST_COMPETITOR_LINKS_FAILURE = "POST_COMPETITOR_LINKS_FAILURE";

export const DELETE_COMEPETITOR_SUCCESS = "DELETE_COMEPETITOR_SUCCESS";
export const DELETE_COMPETITOR_FAILURE = "DELETE_COMPETITOR_FAILURE";

export const GET_NOUN_LIST_SUCCESS = "GET_NOUN_LIST_SUCCESS";
export const GET_NOUN_LIST_FAILURE = "GET_NOUN_LIST_FAILURE";

export const GET_ADJ_LIST_SUCCESS = "GET_ADJ_LIST_SUCCESS";
export const GET_ADJ_LIST_FAILURE = "GET_ADJ_LIST_FAILURE";

export const GET_CHUNK_LIST_SUCCESS = "GET_CHUNK_LIST_SUCCESS";
export const GET_CHUNK_LIST_FAILURE = "GET_CHUNK_LIST_FAILURE";

export const GET_BRANCH_COMP_DETAILS_SUCCESS = "GET_BRANCH_COMP_DETAILS_SUCCESS";
export const GET_BRANCH_COMP_DETAILS_FAILURE = "GET_BRANCH_COMP_DETAILS_FAILURE";

export const DELETE_BRANCH_COMEPETITOR_SUCCESS = "DELETE_BRANCH_COMEPETITOR_SUCCESS";
export const DELETE_BRANCH_COMPETITOR_FAILURE = "DELETE_BRANCH_COMPETITOR_FAILURE";

export const EDIT_BRANCH_COMPETITOR_SUCCESS = "EDIT_BRANCH_COMPETITOR_SUCCESS";
export const EDIT_BRANCH_COMPETITOR_FAILURE = "EDIT_BRANCH_COMPETITOR_FAILURE";

export const EDIT_COMPETITOR_SUCCESS = "EDIT_COMPETITOR_SUCCESS";
export const EDIT_COMPETITOR_FAILURE = "EDIT_COMPETITOR_FAILURE";

export const COMPETITOR_SOURCE_OPTIONS_SUCCESS = "COMPETITOR_SOURCE_OPTIONS_SUCCESS";
export const COMPETITOR_SOURCE_OPTIONS_FAILURE = "COMPETITOR_SOURCE_OPTIONS_FAILURE";

export const GET_CAMPAIGN_NEWSLETTER_SUCCESS = "GET_CAMPAIGN_NEWSLETTER_SUCCESS";
export const GET_CAMPAIGN_NEWSLETTER_FAILURE = "GET_CAMPAIGN_NEWSLETTER_FAILURE";

export const DELETE_NEWS_LETTER_CAMPAIGN_SUCCESS = "DELETE_NEWS_LETTER_CAMPAIGN_SUCCESS";
export const DELETE_NEWS_LETTER_CAMPAIGN_FAILURE = "DELETE_NEWS_LETTER_CAMPAIGN_FAILURE";

export const POST_NEWS_LETTER_CAMPAIGN_SUCCESS = "POST_NEWS_LETTER_CAMPAIGN_SUCCESS";
export const POST_NEWS_LETTER_CAMPAIGN_FAILURE = "POST_NEWS_LETTER_CAMPAIGN_FAILURE";

export const GET_NEWS_LETTER_INVITED_MEMBERS_SUCCESS = "GET_NEWS_LETTER_INVITED_MEMBERS_SUCCESS";
export const GET_NEWS_LETTER_INVITED_MEMBERS_FAILURE = "GET_NEWS_LETTER_INVITED_MEMBERS_FAILURE";

export const RELEVANT_COUNTRIES_SUCCESS = "RELEVANT_COUNTRIES_SUCCESS";
export const RELEVANT_COUNTRIES_FAILURE = "RELEVANT_COUNTRIES_FAILURE";

export const RELEVANT_STATES_SUCCESS = "RELEVANT_STATES_SUCCESS";
export const RELEVANT_STATES_FAILURE = "RELEVANT_STATES_FAILURE";

export const GENERIC_TEMPLATES_LIST_SUCCSESS = "GENERIC_TEMPLATES_LIST_SUCCSESS";
export const GENERIC_TEMPLATES_LIST_FAILURE = "GENERIC_TEMPLATES_LIST_FAILURE";

export const GET_GENERIC_TEMPLATE_DETAILS_SUCCESS = "GET_GENERIC_TEMPLATE_DETAILS_SUCCESS";
export const GET_GENERIC_TEMPLATE_DETAILS_FAILURE = "GET_GENERIC_TEMPLATE_DETAILS_FAILURE";

export const POST_GENERIC_TEMPLATE_SUCCESS = "POST_GENERIC_TEMPLATE_SUCCESS";
export const POST_GENERIC_TEMPLATE_FAILURE = "POST_GENERIC_TEMPLATE_FAILURE";

export const GENERIC_TEMPLATE_DELETE_SUCCESS = "GENERIC_TEMPLATE_DELETE_SUCCESS";
export const GENERIC_TEMPLATE_DELETE_FAILURE = "GENERIC_TEMPLATE_DELETE_FAILURE";

export const GENERIC_TEMPLATE_CLONE_SUCCESS = "GENERIC_TEMPLATE_CLONE_SUCCESS";
export const GENERIC_TEMPLATE_CLONE_FAILURE = "GENERIC_TEMPLATE_CLONE_FAILURE";

export const GET_GENERIC_TEMPLATE_FEEDBACK_SUCCESS = "GET_GENERIC_TEMPLATE_FEEDBACK_SUCCESS";
export const GET_GENERIC_TEMPLATE_FEEDBACK_FAILURE = "GET_GENERIC_TEMPLATE_FEEDBACK_FAILURE";

export const GENERIC_TEMPLATES_CONTACTS_SUCCESS = "GENERIC_TEMPLATES_CONTACTS_SUCCESS";
export const GENERIC_TEMPLATES_CONTACTS_FAILURE = "GENERIC_TEMPLATES_CONTACTS_FAILURE";

export const GENERIC_CONTACT_DELETE_SUCCESS = "GENERIC_CONTACT_DELETE_SUCCESS";
export const GENERIC_CONTACT_DELETE_FAILURE = "GENERIC_CONTACT_DELETE_FAILURE";

export const GET_GENERIC_CONTACT_DETAILS_SUCCESS = "GET_GENERIC_CONTACT_DETAILS_SUCCESS";
export const GET_GENERIC_CONTACT_DETAILS_FAILURE = "GET_GENERIC_CONTACT_DETAILS_FAILURE";

export const GENERIC_CONTACT_EDIT_SUCCESS = "GENERIC_CONTACT_EDIT_SUCCESS";
export const GENERIC_CONTACT_EDIT_FAILURE = "GENERIC_CONTACT_EDIT_FAILURE";

export const BUSINESS_PROFILE_SUCCESS = "BUSINESS_PROFILE_SUCCESS";
export const BUSINESS_PROFILE_FAILURE = "BUSINESS_PROFILE_FAILURE";

export const REMOVE_BUSINESS_PROFILE = "REMOVE_BUSINESS_PROFILE";

export const POST_REVIEWS_FORM_SUCCESS = "POST_REVIEWS_FORM_SUCCESS";
export const POST_REVIEWS_FORM_FAILURE = "POST_REVIEWS_FORM_FAILURE";

export const ADD_KEYWORD_SUCCESS = "ADD_KEYWORD_SUCCESS";
export const ADD_KEYWORD_FAILURE = "ADD_KEYWORD_FAILURE";

export const GET_MENTIONS_DATA_SUCCESS = "GET_MENTIONS_DATA_SUCCESS";
export const GET_MENTIONS_DATA_FAILURE = "GET_MENTIONS_DATA_FAILURE";

export const GET_MENTIONS_KEYWORDS_SUCCESS = "GET_MENTIONS_KEYWORDS_SUCCESS";
export const GET_MENTIONS_KEYWORDS_FAILURE = "GET_MENTIONS_KEYWORDS_FAILURE";

export const DELETE_MENTION_REVIEW_SUCCESS = "DELETE_MENTION_REVIEW_SUCCESS";
export const DELETE_MENTION_REVIEW_FAILURE = "DELETE_MENTION_REVIEW_FAILURE";

export const UPDATE_REVIEW_STATUS_SUCCESS = "UPDATE_REVIEW_STATUS_SUCCESS";
export const UPDATE_REVIEW_STATUS_FAILURE = "UPDATE_REVIEW_STATUS_FAILURE";

export const DELETE_MENTION_KEYWORD_SUCCESS = "DELETE_MENTION_KEYWORD_SUCCESS";
export const DELETE_MENTION_KEYWORD_FAILURE = "DELETE_MENTION_KEYWORD_FAILURE";

export const GET_MENTIONS_PROVIDERS_SUCCESS = "GET_MENTIONS_PROVIDERS_SUCCESS";
export const GET_MENTIONS_PROVIDERS_FAILURE = "GET_MENTIONS_PROVIDERS_FAILURE";

export const GET_TAGS_LIST_SUCCESS = "GET_TAGS_LIST_SUCCESS";
export const GET_TAGS_LIST_FAILURE = "GET_TAGS_LIST_FAILURE";

export const GET_CATEGORY_TAGS_LIST_SUCCESS = "GET_CATEGORY_TAGS_LIST_SUCCESS";
export const GET_CATEGORY_TAGS_LIST_FAILURE = "GET_CATEGORY_TAGS_LIST_FAILURE";

export const POST_TAG_SUCCESS = "POST_TAG_SUCCESS";
export const POST_TAG_FAILURE = "POST_TAG_FAILURE";

export const GET_STATUS_SUCCESS = "GET_STATUS_SUCCESS";
export const GET_STATUS_FAILURE = "GET_STATUS_FAILURE";

export const GET_MENTION_REPORT_SUCCESS = "GET_MENTION_REPORT_SUCCESS";
export const GET_MENTION_REPORT_FAILURE = "GET_MENTION_REPORT_FAILURE";

export const GET_CONTACT_TAG_GROUPS_SUCCESS = "GET_CONTACT_TAG_GROUPS_SUCCESS";
export const GET_CONTACT_TAG_GROUPS_FAILURE = "GET_CONTACT_TAG_GROUPS_FAILURE";

export const CONTACT_TAG_GROUP_ADD_SUCCESS = "CONTACT_TAG_GROUP_ADD_SUCCESS";
export const CONTACT_TAG_GROUP_ADD_FAILURE = "CONTACT_TAG_GROUP_ADD_FAILURE";

export const DELETE_TAG_GROUP_SUCCESS = "DELETE_TAG_GROUP_SUCCESS";
export const DELETE_TAG_GROUP_FAILURE = "DELETE_TAG_GROUP_FAILURE";

export const GET_LOCKED_MODULES_SUCCESS = "GET_LOCKED_MODULES_SUCCESS";
export const GET_LOCKED_MODULES_FAILURE = "GET_LOCKED_MODULES_FAILURE";

export const MENTIONS_SOCIAL_STATUS_SUCCESS = "MENTIONS_SOCIAL_STATUS_SUCCESS";

export const MENTIONS_SOCIAL_CONNECT_SUCCESS = "MENTIONS_SOCIAL_CONNECT_SUCCESS";
export const ALL_MENTIONS_SOCIAL_CONNECT_SUCCESS = "ALL_MENTIONS_SOCIAL_CONNECT_SUCCESS";

export const TICKET_DETAILS_SUCCESS = "TICKET DETAILS SUCCESS";
export const TICKET_DETAILS_FAILURE = "TICKET DETAILS FAILURE";

export const SERVICE_TICKET_DETAILS_SUCCESS = "SERVICE TICKET DETAILS SUCCESS";
export const SERVICE_TICKET_DETAILS_FAILURE = "SERVICE TICKET DETAILS FAILURE";

export const REVIEW_EMAIL_LIST_SUCCESS = "REVIEW EMAIL LIST SUCCESS";
export const REVIEW_EMAIL_LIST_FAILURE = "REVIEW EMAIL LIST FAILURE";

export const WHATS_APP_LIST_SUCCESS = "WHATS_APP_LIST_SUCCESS";
export const WHATS_APP_LIST_FAILURE = "WHATS_APP_LIST_FAILURE";

export const WHATSAPP_CONTACT_DETAILS_SUCCESS = "WHATSAPP_CONTACT_DETAILS_SUCCESS";
export const WHATSAPP_CONTACT_DETAILS_FAILURE = "WHATSAPP_CONTACT_DETAILS_FAILURE";

export const REVIEW_EMAIL_DETAILS_SUCCESS = "REVIEW EMAIL DETAILS SUCCESS";
export const REVIEW_EMAIL_DETAILS_FAILURE = "REVIEW EMAIL DETAILS FAILURE";

export const SERVICE_REQUEST_OVERVIEW_SUCCESS = "SERVICE REQUEST OVERVIEW SUCCESS";
export const SERVICE_REQUEST_OVERVIEW_FAILURE = "SERVICE REQUEST OVERVIEW FAILURE";

export const OFFLINE_STORE_ALERTS_SUCCESS = "OFFLINE STORE ALERTS SUCCESS";
export const OFFLINE_STORE_ALERTS_FAILURE = "OFFLINE STORE ALERTS FAILURE";

export const SERVICE_REQUEST_TYPE_SUCCESS = "SERVICE REQUEST TYPE SUCCESS";
export const SERVICE_REQUEST_TYPE_FAILURE = "SERVICE REQUEST TYPE FAILURE";

export const GET_SERVICE_TICKET_SUCCESS = "GET_SERVICE_TICKET_SUCCESS";
export const GET_SERVICE_TICKET_FAILURE = "GET_SERVICE_TICKET_FAILURE";

export const ADD_SERVICE_REQUEST_TYPE_SUCCESS = "ADD_CREATE_SERVICE_TICKET_SUCCESS";
export const ADD_SERVICE_REQUEST_TYPE_FAILURE = "ADD_CREATE_SERVICE_TICKET_FAILURE";

export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAILURE = "CREATE_TICKET_FAILURE";

export const SINGLE_TICKET_DETAILS_SUCCESS = "SINGLE_TICKET_DETAILS_SUCCESS";
export const SINGLE_TICKET_DETAILS_FAILURE = "SINGLE_TICKET_DETAILS_FAILURE";

export const TICKET_STATUS_OPTIONS_SUCCESS = "TICKET_STATUS_SUCCESS";
export const TICKET_STATUS_OPTIONS_FAILURE = "TICKET_STATUS_FAILURE";

export const GET_TICKET_SOURCE_OPTIONS_SUCCESS = "GET_TICKET_SOURCE_OPTIONS_SUCCESS";
export const GET_TICKET_SOURCE_OPTIONS_FAILURE = "GET_TICKET_SOURCE_OPTIONS_FAILURE";

export const EDIT_TAG_GROUP_NAME_SUCCESS = "EDIT_TAG_GROUP_NAME_SUCCESS";
export const EDIT_TAG_GROUP_NAME_FAILURE = "EDIT_TAG_GROUP_NAME_FAILURE";

export const DELETE_TAG_SUCCESS = "DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILURE = "DELETE_TAG_FAILURE";

export const EDIT_TAG_NAME_SUCCESS = "EDIT_TAG_NAME_SUCCESS";
export const EDIT_TAG_NAME_FAILURE = "EDIT_TAG_NAME_FAILURE";

export const ADD_TAG_NAME_SUCCESS = "ADD_TAG_NAME_SUCCESS";
export const ADD_TAG_NAME_FAILURE = "ADD_TAG_NAME_FAILURE";

export const GET_INVOICE_LIST_SUCCESS = "GET_INVOICE_LIST_SUCCESS";
export const GET_INVOICE_LIST_FAILURE = "GET_INVOICE_LIST_FAILURE";

export const GET_INVOICE_OPTIONS_SUCCESS = "GET_INVOICE_OPTIONS_SUCCESS";
export const GET_INVOICE_OPTIONS_FAILURE = "GET_INVOICE_OPTIONS_FAILURE";

export const POST_PAYMENT_DETAILS_SUCCESS = "POST_PAYMENT_DETAILS_SUCCESS";
export const POST_PAYMENT_DETAILS_FAILURE = "POST_PAYMENT_DETAILS_FAILURE";

export const REMOVE_EDIT_TAGNAME_ERROR = "REMOVE_EDIT_TAGNAME_ERROR";

export const CITY_OPTIONS_SUCCESS = "CITY_OPTIONS_SUCCESS";
export const CITY_OPTIONS_FAILURE = "CITY_OPTIONS_FAILURE";

export const STATE_OPTIONS_SUCCESS = "STATE_OPTIONS_SUCCESS";
export const STATE_OPTIONS_FAILURE = "STATE_OPTIONS_FAILURE";

export const COUNTRY_OPTIONS_SUCCESS = "COUNTRY_OPTIONS_SUCCESS";
export const COUNTRY_OPTIONS_FAILURE = "COUNTRY_OPTIONS_FAILURE";

export const ENTERPRISE_DATA_SUCCESS = "ENTERPRISE_DATA_SUCCESS";
export const ENTERPRISE_DATA_FAILURE = "ENTERPRISE_DATA_FAILURE";

export const TICKETS_RULES_DATA_SUCCESS = "TICKETS_RULES_DATA_SUCCESS";
export const TICKETS_RULES_DATA_FAILURE = "TICKETS_RULES_DATA_FAILURE";

export const BRANCH_LIST_SUCCESS = "BRANCH_LIST_SUCCESS";
export const BRANCH_LIST_FAILURE = "BRANCH_LIST_FAILURE";

export const RULE_DELETE_SUCCESS = "RULE_DELETE_SUCCESS";
export const RULE_DELETE_FAILURE = "RULE_DELETE_FAILURE";

export const TAGS_OPTIONS_SUCCESS = "TAGS_OPTIONS_SUCCESS";
export const TAGS_OPTIONS_FAILURE = "TAGS_OPTIONS_FAILURE";

export const REVIEW_CONTENT_TYPE_OPTIONS_SUCCESS = "REVIEW_CONTENT_TYPE_OPTIONS_SUCCESS";
export const REVIEW_CONTENT_TYPE_OPTIONS_FAILURE = "REVIEW_CONTENT_TYPE_OPTIONS_FAILURE";

export const AGENCY_MAPPING_LIST_SUCCESS = "AGENCY_MAPPING_LIST_SUCCESS";
export const AGENCY_MAPPING_LIST_FAILURE = "AGENCY_MAPPING_LIST_FAILURE";

export const ASSIGN_MULTI_LOCATIONS_SUCCESS = "ASSIGN_MULTI_LOCATIONS_SUCCESS";
export const ASSIGN_MULTI_LOCATIONS_FAILURE = "ASSIGN_MULTI_LOCATIONS_FAILURE";

export const CREATE_RECURRING_CAMPAIGN_SUCCESS = "CREATE_RECURRING_CAMPAIGN_SUCCESS";
export const CREATE_RECURRING_CAMPAIGN_FAILURE = "CREATE_RECURRING_CAMPAIGN_FAILURE";

export const CREATE_NEWSLETTER_RECURRING_CAMPAIGN_SUCCESS = "CREATE_NEWSLETTER_RECURRING_CAMPAIGN_SUCCESS";
export const CREATE_NEWSLETTER_RECURRING_CAMPAIGN_FAILURE = "CREATE_NEWSLETTER_RECURRING_CAMPAIGN_FAILURE";

export const GET_ALL_RECURRING_CAMPAIGN_SUCCESS = "GET_ALL_RECURRING_CAMPAIGN_SUCCESS";
export const GET_ALL_RECURRING_CAMPAIGN_FAILURE = "GET_ALL_RECURRING_CAMPAIGN_FAILURE";

export const GET_ALL_NEWSLETTER_RECURRING_CAMPAIGN_SUCCESS = "GET_ALL_NEWSLETTER_RECURRING_CAMPAIGN_SUCCESS";
export const GET_ALL_NEWSLETTER_RECURRING_CAMPAIGN_FAILURE = "GET_ALL_NEWSLETTER_RECURRING_CAMPAIGN_FAILURE";

export const GET_RECURRING_CAMPAIGN_DETAILS_SUCCESS = "GET_RECURRING_CAMPAIGN_DETAILS_SUCCESS";
export const GET_RECURRING_CAMPAIGN_DETAILS_FAILURE = "GET_RECURRING_CAMPAIGN_DETAILS_FAILURE";

export const GET_NEWSLETTER_RECURRING_CAMPAIGN_DETAILS_SUCCESS = "GET_NEWSLETTER_RECURRING_CAMPAIGN_DETAILS_SUCCESS";
export const GET_NEWSLETTER_RECURRING_CAMPAIGN_DETAILS_FAILURE = "GET_NEWSLETTER_RECURRING_CAMPAIGN_DETAILS_FAILURE";

export const RECURRING_CAMPAIGN_DELETE_SUCCESS = "RECURRING_CAMPAIGN_DELETE_SUCCESS";
export const RECURRING_CAMPAIGN_DELETE_FAILURE = "RECURRING_CAMPAIGN_DELETE_FAILURE";

export const NEWSLETTER_RECURRING_CAMPAIGN_DELETE_SUCCESS = "NEWSLETTER_RECURRING_CAMPAIGN_DELETE_SUCCESS";
export const NEWSLETTER_RECURRING_CAMPAIGN_DELETE_FAILURE = "NEWSLETTER_RECURRING_CAMPAIGN_DELETE_FAILURE";

export const BRANCH_OWNERS_SUCCESS = "BRANCH_OWNERS_SUCCESS";
export const BRANCH_OWNERS_FAILURE = "BRANCH_OWNERS_FAILURE";

export const BRANCH_TAG_OPTIONS_SUCCESS = "BRANCH_TAG_OPTIONS_SUCCESS";
export const BRANCH_TAG_OPTIONS_FAILURE = "BRANCH_TAG_OPTIONS_FAILURE";

export const RULE_DETAIL_SUCCESS = "RULE_DETAIL_SUCCESS";
export const RULE_DETAIL_FAILURE = "RULE_DETAIL_FAILURE";

export const GET_OPEN_TICKET_SUCCESS = "GET_OPEN_TICKET_SUCCESS";
export const GET_OPEN_TICKET_FAILURE = "GET_OPEN_TICKET_FAILURE";

// * Review Summary
export const GET_REVIEW_SUMMARY_SUCCESS = "GET_REVIEW_SUMMARY_SUCCESS";
export const GET_REVIEW_SUMMARY_FAILURE = "GET_REVIEW_SUMMARY_FAILURE";

// * Net Promoter Score
export const GET_NPS_SUMMARY_SUCCESS = "GET_NPS_SUMMARY_SUCCESS";
export const GET_NPS_SUMMARY_FAILURE = "GET_NPS_SUMMARY_FAILURE";

export const GET_BRANCH_SUCCESS = "GET_BRANCH_SUCCESS";
export const GET_BRANCH_FAILURE = "GET_BRANCH_FAILURE";

export const GET_CITY_SUCCESS = "GET_CITY_SUCCESS";
export const GET_CITY_FAILURE = "GET_CITY_FAILURE";

export const GET_STATE_SUCCESS = "GET_STATE_SUCCESS";
export const GET_STATE_FAILURE = "GET_STATE_FAILURE";

export const ALL_CURRENT_PAGE_FILTER_CONFIG = "ALL_CURRENT_PAGE_FILTER_CONFIG";

export const GET_DATE_TYPE_SUCCESS = "GET_DATE_TYPE_SUCCESS";
export const GET_DATE_TYPE_FAILURE = "GET_DATE_TYPE_FAILURE";

export const GET_LAST_ASYNC_TYPE_SUCCESS = "GET_LAST_ASYNC_TYPE_SUCCESS";
export const GET_LAST_ASYNC_TYPE_FAILURE = "GET_LAST_ASYNC_TYPE_FAILURE";

export const GET_OUT_OF_STOCK_ITEMS_SUCCESS = "GET_OUT_OF_STOCK_ITEMS_SUCCESS";
export const GET_OUT_OF_STOCK_ITEMS_FAILURE = "GET_OUT_OF_STOCK_ITEMS_FAILURE";

export const ACCOUNT_GET_DATE_TYPE_SUCCESS = "ACCOUNT_GET_DATE_TYPE_SUCCESS";
export const ACCOUNT_GET_DATE_TYPE_FAILURE = "ACCOUNT_GET_DATE_TYPE_FAILURE";

export const GET_CONTENT_TYPE_SUCCESS = "GET_CONTENT_TYPE_SUCCESS";
export const GET_CONTENT_TYPE_FAILURE = "GET_CONTENT_TYPE_FAILURE";

export const GET_COUNT_AVERAGE_SUCCESS = "GET_COUNT_AVERAGE_SUCCESS";
export const GET_COUNT_AVERAGE_FAILURE = "GET_COUNT_AVERAGE_FAILURE";

export const COUPON_LIST_SUCCESS = "COUPON_LIST_SUCCESS";
export const COUPON_LIST_FAILURE = "COUPON_LIST_FAILURE";

export const COUPON_CSV_UPLOAD_SUCCESS = "COUPON_CSV_UPLOAD_SUCCESS";
export const COUPON_CSV_UPLOAD_FAILURE = "COUPON_CSV_UPLOAD_FAILURE";

export const COUPON_SUMMARY_SUCCESS = "COUPON_SUMMARY_SUCCESS";
export const COUPON_SUMMARY_FAILURE = "COUPON_SUMMARY_FAILURE";

export const TICKET_RULE_PROVIDERS_SUCCESS = "TICKET_RULE_PROVIDERS_SUCCESS";
export const TICKET_RULE_PROVIDERS_FAILURE = "TICKET_RULE_PROVIDERS_FAILURE";

export const SOURCE_SYSTEM_ORDER_DETAIL_SETTING_SUCCESS = "SOURCE_SYSTEM_ORDER_DETAIL_SETTING_SUCCESS";
export const SOURCE_SYSTEM_ORDER_DETAIL_SETTING_FAILURE = "SOURCE_SYSTEM_ORDER_DETAIL_SETTING_FAILURE";

export const TICKET_RESOLUTION_SUCCESS = "TICKET_RESOLUTION_SUCCESS";
export const TICKET_RESOLUTION_FAILURE = "TICKET_RESOLUTION_FAILURE";

export const CREATE_TICKET_RESOLUTION_SUCCESS = "CREATE_TICKET_RESOLUTION_SUCCESS";
export const CREATE_TICKET_RESOLUTION_FAILURE = "CREATE_TICKET_RESOLUTION_FAILURE";

export const CREATE_TICKET_RESOLUTION_RESPONSE_SUCCESS = "CREATE_TICKET_RESOLUTION_RESPONSE_SUCCESS";
export const CREATE_TICKET_RESOLUTION_RESPONSE_FAILURE = "CREATE_TICKET_RESOLUTION_RESPONSE_FAILURE";

export const GET_TICKET_RESOLUTION_RESPONSE_SUCCESS = "GET_TICKET_RESOLUTION_RESPONSE_SUCCESS";
export const GET_TICKET_RESOLUTION_RESPONSE_FAILURE = "GET_TICKET_RESOLUTION_RESPONSE_FAILURE";

export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const TAG_OPTIONS_SUCCESS = "TAG_OPTIONS_SUCCESS";
export const TAG_OPTIONS_FAILURE = "TAG_OPTIONS_FAILURE";

export const AGENCY_ADD_SUCCESS = "AGENCY_ADD_SUCCESS";
export const AGENCY_ADD_FAILURE = "AGENCY_ADD_FAILURE";

export const GET_AGENCY_BUSINESS_SUCCESS = "GET_AGENCY_BUSINESS_SUCCESS";
export const GET_AGENCY_BUSINESS_FAILURE = "GET_AGENCY_BUSINESS_FAILURE";

export const AGENCY_EDIT_SUCCESS = "AGENCY_EDIT_SUCCESS";
export const AGENCY_EDIT_FAILURE = "AGENCY_EDIT_FAILURE";

export const GET_SERVICE_SUCCESS = "GET_SERVICE_SUCCESS";
export const GET_SERVICE_FAILURE = "GET_SERVICE_FAILURE";

export const GET_GMB_LISTING_OVERVIEW_SUCCESS = "GET_GMB_LISTING_OVERVIEW_SUCCESS";
export const GET_GMB_LISTING_OVERVIEW_FAILURE = "GET_GMB_LISTING_OVERVIEW_FAILURE";

export const GET_GMB_LISTING_BULK_GOOGLE_UPDATES_SUCCESS = "GET_GMB_LISTING_BULK_GOOGLE_UPDATES_SUCCESS";
export const GET_GMB_LISTING_BULK_GOOGLE_UPDATES_FAILURE = "GET_GMB_LISTING_BULK_GOOGLE_UPDATES_FAILURE";

export const GET_GMB_LISTING_LOCATION_SUCCESS = "GET_GMB_LISTING_LOCATION_SUCCESS";
export const GET_GMB_LISTING_LOCATION_FAILURE = "GET_GMB_LISTING_LOCATION_FAILURE";

export const GET_GMB_LISTING_MENU_DETAIL_SUCCESS = "GET_GMB_LISTING_MENU_DETAIL_SUCCESS";
export const GET_GMB_LISTING_MENU_DETAIL_FAILURE = "GET_GMB_LISTING_MENU_DETAIL_FAILURE";

export const GET_GMB_LISTING_PRIMARY_DETAIL_SUCCESS = "GET_GMB_LISTING_PRIMARY_DETAIL_SUCCESS";
export const GET_GMB_LISTING_PRIMARY_DETAIL_FAILURE = "GET_GMB_LISTING_PRIMARY_DETAIL_FAILURE";

export const GET_GMB_LISTING_REFRESH_SUCCESS = "GET_GMB_LISTING_REFRESH_SUCCESS";
export const GET_GMB_LISTING_REFRESH_FAILURE = "GET_GMB_LISTING_REFRESH_FAILURE";

export const GET_LIST_BY_FILTER_SUCCESS = "GET_LIST_BY_FILTER_SUCCESS";
export const GET_LIST_BY_FILTER_FAILURE = "GET_LIST_BY_FILTER_FAILURE";

export const GET_GMB_BASIC_DETAIL_SUCCESS = "GET_GMB_BASIC_DETAIL_SUCCESS";
export const GET_GMB_BASIC_DETAIL_FAILURE = "GET_GMB_BASIC_DETAIL_FAILURE";

export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_FAILURE = "GET_DEPARTMENT_FAILURE";

export const CREATE_DEPARTMENT_SUCCESS = "CREATE_DEPARTMENT_SUCCESS";
export const CREATE_DEPARTMENT_FAILURE = "CREATE_DEPARTMENT_FAILURE";

export const EDIT_DEPARTMENT_SUCCESS = "EDIT_DEPARTMENT_SUCCESS";
export const EDIT_DEPARTMENT_FAILURE = "EDIT_DEPARTMENT_FAILURE";

export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_FAILURE = "DELETE_DEPARTMENT_FAILURE";

export const GET_DESIGNATION_SUCCESS = "GET_DESIGNATION_SUCCESS";
export const GET_DESIGNATION_FAILURE = "GET_DESIGNATION_FAILURE";

export const CREATE_DESIGNATION_SUCCESS = "CREATE_DESIGNATION_SUCCESS";
export const CREATE_DESIGNATION_FAILURE = "CREATE_DESIGNATION_FAILURE";

export const EDIT_DESIGNATION_SUCCESS = "EDIT_DESIGNATION_SUCCESS";
export const EDIT_DESIGNATION_FAILURE = "EDIT_DESIGNATION_FAILURE";

export const DELETE_DESIGNATION_SUCCESS = "DELETE_DESIGNATION_SUCCESS";
export const DELETE_DESIGNATION_FAILURE = "DELETE_DESIGNATION_FAILURE";

export const GET_TICKET_ESCALATION_SUCCESS = "GET_TICKET_ESCALATION_SUCCESS";
export const GET_TICKET_ESCALATION_FAILURE = "GET_TICKET_ESCALATION_FAILURE";

export const CREATE_TICKET_ESCALATION_SUCCESS = "CREATE_TICKET_ESCALATION_SUCCESS";
export const CREATE_TICKET_ESCALATION_FAILURE = "CREATE_TICKET_ESCALATION_FAILURE";

export const EDIT_TICKET_ESCALATION_SUCCESS = "EDIT_TICKET_ESCALATION_SUCCESS";
export const EDIT_TICKET_ESCALATION_FAILURE = "EDIT_TICKET_ESCALATION_FAILURE";

export const DELETE_TICKET_ESCALATION_SUCCESS = "DELETE_TICKET_ESCALATION_SUCCESS";
export const DELETE_TICKET_ESCALATION_FAILURE = "DELETE_TICKET_ESCALATION_FAILURE";

export const GET_GMB_CATEGORIES_SUCCESS = "GET_GMB_CATEGORIES_SUCCESS";
export const GET_GMB_CATEGORIES_FAILURE = "GET_GMB_CATEGORIES_FAILURE";

export const GET_GMB_UPDATE_LISTING_SUCCESS = "GET_GMB_UPDATE_LISTING_SUCCESS";
export const GET_GMB_UPDATE_LISTING_FAILURE = "GET_GMB_UPDATE_LISTING_FAILURE";

export const GET_GMB_PALCE_ACTION_METADATA_SUCCESS = "GET_GMB_PALCE_ACTION_METADATA_SUCCESS";
export const GET_GMB_PALCE_ACTION_METADATA_FAILURE = "GET_GMB_PALCE_ACTION_METADATA_FAILURE";

export const GET_GMB_ATTRIBUTES_SUCCESS = "GET_GMB_ATTRIBUTES_SUCCESS";
export const GET_GMB_ATTRIBUTES_FAILURE = "GET_GMB_ATTRIBUTES_FAILURE";

export const SET_SELECTED_TAB_UPDATE_PHOTO = "SET_SELECTED_TAB_UPDATE_PHOTO";

export const GET_GMB_DELETE_MEDIA_SUCCESS = "GET_GMB_DELETE_MEDIA_SUCCESS";
export const GET_GMB_DELETE_MEDIA_FAILURE = "GET_GMB_DELETE_MEDIA_FAILURE";

export const GET_GMB_MEDIA_SUCCESS = "GET_GMB_MEDIA_SUCCESS";
export const GET_GMB_MEDIA_FAILURE = "GET_GMB_MEDIA_FAILURE";

export const GET_SOCIAL_PROFILE_SUCCESS = "GET_SOCIAL_PROFILE_SUCCESS";
export const GET_SOCIAL_PROFILE_FAILURE = "GET_SOCIAL_PROFILE_FAILURE";

export const GET_SOCIAL_COMMENTS_SUCCESS = "GET_SOCIAL_COMMENTS_SUCCESS";
export const GET_SOCIAL_COMMENTS_FAILURE = "GET_SOCIAL_COMMENTS_FAILURE";

export const GET_SOCIAL_PAGE_LOCATION_MAPPING_SUCCESS = "GET_SOCIAL_PAGE_LOCATION_MAPPING_SUCCESS";
export const GET_SOCIAL_PAGE_LOCATION_MAPPING_FAILURE = "GET_SOCIAL_PAGE_LOCATION_MAPPING_FAILURE";

export const POST_SOCIAL_COMMENTS_SUCCESS = "POST_SOCIAL_COMMENTS_SUCCESS";
export const POST_SOCIAL_COMMENTS_FAILURE = "POST_SOCIAL_COMMENTS_FAILURE";

export const POST_SOCIAL_PAGE_LOCATION_MAPPING_SUCCESS = "POST_SOCIAL_PAGE_LOCATION_MAPPING_SUCCESS";
export const POST_SOCIAL_PAGE_LOCATION_MAPPING_FAILURE = "POST_SOCIAL_PAGE_LOCATION_MAPPING_FAILURE";

export const GET_GMB_CONSOLE_LIST_SUCCESS = "GET_GMB_CONSOLE_LIST_SUCCESS";
export const GET_GMB_CONSOLE_LIST_FAILURE = "GET_GMB_CONSOLE_LIST_FAILURE";

export const GET_GMB_BULK_UPDATE_LISTING_SUCCESS = "GET_GMB_BULK_UPDATE_LISTING_SUCCESS";
export const GET_GMB_BULK_UPDATE_LISTING_FAILURE = "GET_GMB_BULK_UPDATE_LISTING_FAILURE";

export const GET_GMB_SERVICE_TYPE_SUCCESS = "GET_GMB_SERVICE_TYPE_SUCCESS";
export const GET_GMB_SERVICE_TYPE_FAILURE = "GET_GMB_sERVICE_TYPE_FAILURE";

export const GET_GMB_SYNC_DATA_SUCCESS = "GET_GMB_SYNC_DATA_SUCCESS";
export const GET_GMB_SYNC_DATA_FAILURE = "GET_GMB_SYNC_DATA_FAILURE";

export const GET_GMB_LOCATION_FOODMENU_SUCCESS = "GET_GMB_LOCATION_FOODMENU_SUCCESS";
export const GET_GMB_LOCATION_FOODMENU_FAILURE = "GET_GMB_LOCATION_FOODMENU_FAILURE";

export const GET_GMB_LOCATION_SYNC_DATA_STATUS_SUCCESS = "GET_GMB_LOCATION_SYNC_DATA_STATUS_SUCCESS";
export const GET_GMB_LOCATION_SYNC_DATA_STATUS_FAILURE = "GET_GMB_LOCATION_SYNC_DATA_STATUS_FAILURE";

export const GET_GMB_BULK_CATEGORIES_SUCCESS = "GET_GMB_BULK_CATEGORIES_SUCCESS";
export const GET_GMB_BULK_CATEGORIES_FAILURE = "GET_GMB_BULK_CATEGORIES_FAILURE";

export const GET_GMB_LOCATION_UPDATE_SERVICE_SUCCESS = "GET_GMB_LOCATION_UPDATE_SERVICE_SUCCESS";
export const GET_GMB_LOCATION_UPDATE_SERVICE_FAILURE = "GET_GMB_LOCATION_UPDATE_SERVICE_FAILURE";

export const GET_GMB_LOCATION_QUESTION_ANSWER_SUCCESS = "GET_GMB_LOCATION_QUESTION_ANSWER_SUCCESS";
export const GET_GMB_LOCATION_QUESTION_ANSWER_FAILURE = "GET_GMB_LOCATION_QUESTION_ANSWER_FAILURE";
export const SET_ROUTER_STATE = "SET_ROUTER_STATE";

export const GET_GMB_LOCATION_UPDATE_DIFF_DATA_SUCCESS = "GET_GMB_LOCATION_UPDATE_DIFF_DATA_SUCCESS";
export const GET_GMB_LOCATION_UPDATE_DIFF_DATA_FAILURE = "GET_GMB_LOCATION_UPDATE_DIFF_DATA_FAILURE";

export const GET_GMB_GOOGLE_LOCATION_SUCCESS = "GET_GMB_GOOGLE_LOCATION_SUCCESS";
export const GET_GMB_GOOGLE_LOCATION_FAILURE = "GET_GMB_GOOGLE_LOCATION_FAILURE";

export const GET_LISTING_PERFORMANCE_METRICS_SUCCESS = "GET_LISTING_PERFORMANCE_METRICS_SUCCESS";
export const GET_LISTING_PERFORMANCE_METRICS_FAILURE = "GET_LISTING_PERFORMANCE_METRICS_FAILURE";

export const GET_LISTING_OVERALL_COUNT_SUCCESS = "GET_LISTING_OVERALL_COUNT_SUCCESS";
export const GET_LISTING_OVERALL_COUNT_FAILURE = "GET_LISTING_OVERALL_COUNT_FAILURE";

export const GET_LISTING_POPULAR_TIMES_SUCCESS = "GET_LISTING_POPULAR_TIMES_SUCCESS";
export const GET_LISTING_POPULAR_TIMES_FAILURE = "GET_LISTING_POPULAR_TIMES_FAILURE";

export const GET_LISTING_SEARCH_KEYWORD_SUCCESS = "GET_LISTING_SEARCH_KEYWORD_SUCCESS";
export const GET_LISTING_SEARCH_KEYWORD_FAILURE = "GET_LISTING_SEARCH_KEYWORD_FAILURE";

export const GET_GMB_BULK_CHANGE_LOG_SUCCESS = "GET_GMB_BULK_CHANGE_LOG_SUCCESS";
export const GET_GMB_BULK_CHANGE_LOG_FAILURE = "GET_GMB_BULK_CHANGE_LOG_FAILURE";

export const GET_GMB_LOCATION_CHANGE_LOG_SUCCESS = "GET_GMB_LOCATION_CHANGE_LOG_SUCCESS";
export const GET_GMB_LOCATION_CHANGE_LOG_FAILURE = "GET_GMB_LOCATION_CHANGE_LOG_FAILURE";

export const GET_PRODUCT_ITEM_SUCCESS = "GET_PRODUCT_ITEM_SUCCESS";
export const GET_PRODUCT_ITEM_FAILURE = "GET_PRODUCT_ITEM_FAILURE";

export const DELETE_SERVICE_REQUEST_SUCCESS = "DELETE_SERVICE_REQUEST_SUCCESS";
export const DELETE_SERVICE_REQUEST_FAILURE = "DELETE_SERVICE_REQUEST_FAILURE";

export const GET_ENGAGEMENT_TABLE_OVERTIME_SUCCESS = "GET_ENGAGEMENT_TABLE_OVERTIME_SUCCESS";
export const GET_ENGAGEMENT_TABLE_OVERTIME_FAILURE = "GET_ENGAGEMENT_TABLE_OVERTIME_FAILURE";

export const GET_ENGAGEMENT_TABLE_LOCATION_SUCCESS = "GET_ENGAGEMENT_TABLE_LOCATION_SUCCESS";
export const GET_ENGAGEMENT_TABLE_LOCATION_FAILURE = "GET_ENGAGEMENT_TABLE_LOCATION_FAILURE";

export const GET_SPENDING_TABLE_OVERTIME_SUCCESS = "GET_SPENDING_TABLE_OVERTIME_SUCCESS";
export const GET_SPENDING_TABLE_OVERTIME_FAILURE = "GET_SPENDING_TABLE_OVERTIME_FAILURE";

export const GET_SPENDING_TABLE_LOCATION_SUCCESS = "GET_SPENDING_TABLE_LOCATION_SUCCESS";
export const GET_SPENDING_TABLE_LOCATION_FAILURE = "GET_SPENDING_TABLE_LOCATION_FAILURE";

export const GET_DIETARY_TABLE_OVERTIME_SUCCESS = "GET_DIETARY_TABLE_OVERTIME_SUCCESS";
export const GET_DIETARY_TABLE_OVERTIME_FAILURE = "GET_DIETARY_TABLE_OVERTIME_FAILURE";

export const GET_DIETARY_TABLE_LOCATION_SUCCESS = "GET_DIETARY_TABLE_LOCATION_SUCCESS";
export const GET_DIETARY_TABLE_LOCATION_FAILURE = "GET_DIETARY_TABLE_LOCATION_FAILURE";

export const GET_GMB_LOCATION_GEO_GRID_RANKER_SUCCESS = "GET_GMB_LOCATION_GEO_GRID_RANKER_SUCCESS";
export const GET_GMB_LOCATION_GEO_GRID_RANKER_FAILURE = "GET_GMB_LOCATION_GEO_GRID_RANKER_FAILURE";

export const GET_GMB_LOCATION_TOP_KEYWORD_SUCCESS = "GET_GMB_LOCATION_TOP_KEYWORD_SUCCESS";
export const GET_GMB_LOCATION_TOP_KEYWORD_FAILURE = "GET_GMB_LOCATION_TOP_KEYWORD_FAILURE";

export const GET_GMB_LOCATION_SCAN_HISTORY_SUCCESS = "GET_GMB_LOCATION_SCAN_HISTORY_SUCCESS";
export const GET_GMB_LOCATION_SCAN_HISTORY_FAILURE = "GET_GMB_LOCATION_SCAN_HISTORY_FAILURE";

export const GET_GMB_LOCATION_CREDIT_USAGE_HISTORY_SUCCESS = "GET_GMB_LOCATION_CREDIT_USAGE_HISTORY_SUCCESS";
export const GET_GMB_LOCATION_CREDIT_USAGE_HISTORY_FAILURE = "GET_GMB_LOCATION_CREDIT_USAGE_HISTORY_FAILURE";

export const GET_SALES_TREND_OVERTIME_SUCCESS = "GET_SALES_TREND_OVERTIME_SUCCESS";
export const GET_SALES_TREND_OVERTIME_FAILURE = "GET_SALES_TREND_OVERTIME_FAILURE";

export const GET_SALES_TREND_LOCATION_SUCCESS = "GET_SALES_TREND_LOCATION_SUCCESS";
export const GET_SALES_TREND_LOCATION_FAILURE = "GET_SALES_TREND_LOCATION_FAILURE";

export const GET_ORDERS_TREND_OVERTIME_SUCCESS = "GET_ORDERS_TREND_OVERTIME_SUCCESS";
export const GET_ORDERS_TREND_OVERTIME_FAILURE = "GET_ORDERS_TREND_OVERTIME_FAILURE";

export const GET_ORDERS_TREND_LOCATION_SUCCESS = "GET_ORDERS_TREND_LOCATION_SUCCESS";
export const GET_ORDERS_TREND_LOCATION_FAILURE = "GET_ORDERS_TREND_LOCATION_FAILURE";

export const USE_SCAN_HISTORY = "USE_SCAN_HISTORY";

export const GET_PRODUCT_NAME_LIST_SUCCESS = "GET_PRODUCT_NAME_LIST_SUCCESS";
export const GET_PRODUCT_NAME_LIST_FAILURE = "GET_PRODUCT_NAME_LIST_FAILURE";

export const CATEGORY_PERFORMANCE_SUCCESS = "CATEGORY_PERFORMANCE_SUCCESS";
export const CATEGORY_PERFORMANCE_FAILURE = "CATEGORY_PERFORMANCE_FAILURE";

export const TOP_BOTTOM_LOCATION_SUCCESS = "TOP_BOTTOM_LOCATION_SUCCESS";
export const TOP_BOTTOM_LOCATION_FAILURE = "TOP_BOTTOM_LOCATION_FAILURE";

export const CLEAR_DASHBOARD_API_SUCCESS = "CLEAR_DASHBOARD_API_SUCCESS";
