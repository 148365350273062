import React from "react";
import { Dropdown } from "semantic-ui-react";
import axios_instance from "../../libs/interseptor";
import FormFeedback from "reactstrap/lib/FormFeedback";
import classNames from "classnames";

class SelectComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { options: [] };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    // eslint-disable-line
    const { apiurl, options } = this.props;
    if (options) {
      this.setState({ options });
    } else {
      if (apiurl === "ticket-listby-filter/") {
        this.setState({
          options: [
            { text: "Hour", value: "hour" },
            { text: "Date", value: "date" },
            { text: "Month", value: "month" },
          ],
        });
      } else {
        apiurl &&
          axios_instance.get(apiurl).then((res) => {
            const options = res.data.choices.length
              ? res.data.choices.map((i) => ({
                  text: i.display_name,
                  value: i.value,
                }))
              : [];
            this.setState({ options });
          });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { apiurl, options } = this.props;
    if (options) {
      if (
        JSON.stringify(...options) !== JSON.stringify(...prevProps?.options)
      ) {
        this.setState({ options });
      }
    } else {
      if (prevProps.apiurl !== apiurl) {
        if (apiurl === "ticket-listby-filter/") {
          this.setState({
            options: [
              { text: "Hour", value: "hour" },
              { text: "Date", value: "date" },
              { text: "Month", value: "month" },
            ],
          });
        } else {
          apiurl &&
            axios_instance.get(apiurl).then((res) => {
              const options = res.data.choices.length
                ? res.data.choices.map((i) => ({
                    text: i.display_name,
                    value: i.value,
                  }))
                : [];
              this.setState({ options });
            });
        }
      }
    }
  }

  handleChange(a, val) {
    // eslint-disable-line
    const { input, onChange } = this.props;
    input.onChange(val.value);
    if (onChange) onChange(val.value);
  }

  render() {
    const {
      input,
      label,
      meta: { touched, error },
      selectedValue,
      className,
      ...rest
    } = this.props;
    let value = input.value ? input.value : selectedValue;
    return (
      <div className="select__component">
        <div>{label}</div>
        <Dropdown
          className={classNames("height-input", className)}
          {...rest}
          search
          selection
          options={this.state.options}
          onChange={this.handleChange}
          value={value}
          style={{ width: "100%" }}
        />
        <FormFeedback>{touched && error}</FormFeedback>
      </div>
    );
  }
}

export default SelectComponent;
