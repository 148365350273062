import React, { useState, useRef } from "react";
import { Chip, Stack } from "@mui/material";
import "./Addmenu.css"; // Create a CSS file for your styles
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import ListingFPInput from "../../../../../components/common/new/Listing/ListingFPInput";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";

const AddMenu = (props) => {
    const { location_food_menu_data, actions, selectReso, business_id, indexValue } = props;
    console.log("indexValueindexValueindexValueindexValueindexValue", indexValue)
    const fileInputRef = useRef(null);

    // State to store input values
    const [itemName, setItemName] = useState("");
    const [itemPrice, setItemPrice] = useState("");
    const [itemDescription, setItemDescription] = useState("");
    const [selectedDietary, setSelectedDietary] = useState("");

    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const handleChipClick = (dietary) => {
        // Toggle selection: if already selected, unselect it; otherwise, select it
        setSelectedDietary((prevDietary) => (prevDietary === dietary ? "" : dietary));
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        console.log("Selected files:", files);
    };

    const updateMenuItem = async () => {
        const restodetail = { business_id, selectReso };

        // New section to be added
        const newSection = {

            labels: [
                {
                    displayName: itemName,
                    description: itemDescription,
                    languageCode: "en"
                },
            ],
            attributes: {
                price: {
                    currencyCode: "INR",
                    units: itemPrice,
                },
                dietaryRestriction: selectedDietary,
            },

        };

        // Update the existing food menu data with the new section
        const updatedMenuData = { ...location_food_menu_data };
        updatedMenuData?.menus[0]?.sections[indexValue]?.items?.push(newSection);

        // Create the payload with updated menu data
        const updateCode = {
            type: "updateMenu",
            updateMenuData: updatedMenuData,
        };

        // console.log("Payload being sent >>>>>>>>>", updateCode);

        // // Dispatch the action or make the API call
        await actions?.getGmbListingUpdateListing(updateCode, restodetail);
        await actions.getGmbLocationFoodmenu(business_id, selectReso);
    };


    return (
        <>
            <div className="add-menu-container">
                <div className="menu-header">
                    <h3>Create a new menu</h3>
                    <p className="menu-subtext">This menu will be publicly visible on your profile</p>
                </div>
                <div className="menu-items">
                    <div className="menu-item-header">
                        <label className="item-label">Add menu item</label>
                        <p className="item-subtext">Enter at least one item per section. You can add more later</p>
                    </div>
                    <div className="menu-item-grid">
                        <div className="menu-item-inputs">
                            <div className="input-group">
                                <ListingFPInput
                                    placeholder={"Item name*"}
                                    value={itemName}
                                    onChange={(e) => setItemName(e.target.value)}
                                />
                                <p>{itemName?.length} / 40</p>
                            </div>
                            <div className="input-group">
                                <ListingFPInput
                                    placeholder={"Item price (INR)"}
                                    value={itemPrice}
                                    onChange={(e) => setItemPrice(e.target.value)}
                                />
                                <p>{itemPrice?.length} / 40</p>
                            </div>
                            <div className="input-group">
                                <textarea
                                    className="textAreaUI"
                                    placeholder={"Item description"}
                                    value={itemDescription}
                                    onChange={(e) => setItemDescription(e.target.value)}
                                />
                                <p>{itemDescription?.length} / 40</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dietary-restrictions">
                    <label className="dietary-label">Dietary restrictions</label>
                    <p className="dietary-subtext">Select all that apply</p>
                </div>
                <Stack direction="row" spacing={1}>
                    <Chip
                        label="Vegetarian"
                        variant={selectedDietary === "Vegetarian" ? "filled" : "outlined"}
                        onClick={() => handleChipClick("Vegetarian")}
                        sx={{
                            backgroundColor: selectedDietary === "Vegetarian" ? "#bfe0e2" : "#F0F1F3",
                            color: selectedDietary === "Vegetarian" ? "#00b5ad" : "default",
                            fontSize: "18px",
                            fontWeight: "400",
                            padding: "12px",
                            border: "0",
                        }}
                    />
                    <Chip
                        label="Vegan"
                        variant={selectedDietary === "Vegan" ? "filled" : "outlined"}
                        onClick={() => handleChipClick("Vegan")}
                        sx={{
                            backgroundColor: selectedDietary === "Vegan" ? "#bfe0e2" : "#F0F1F3",
                            color: selectedDietary === "Vegan" ? "#00b5ad" : "default",
                            fontSize: "18px",
                            fontWeight: "400",
                            padding: "12px",
                            border: "0",
                        }}
                    />
                </Stack>
            </div>
            <div className="menu-footer">
                <FPButton label="Save" size="Small" onClick={updateMenuItem} />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        location_food_menu_data: state.gmbListingLocation.location_food_menu_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(locationActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMenu);
