import React from 'react';
import { Col, Row } from 'reactstrap';
import { Card } from 'semantic-ui-react';

const TicketStatusSummaryCard = (props) => {
  const {leftHeader, rightHeader, timeData} = props;
  return (
    <Card className='ticket-status'>
      <Card.Header>
        <p className='header-left'>{leftHeader}</p>
        <p className='header-right'>{rightHeader}</p>
      </Card.Header>
      <Row className="time-section-wrapper">
        <Col className="time-section">
          <p className='time-title'>&gt;&nbsp;24Hr</p>
          <p className='time-caption'>{timeData['24h']}</p>
        </Col>
        <Col className="time-section">
          <p className='time-title'>&gt;&nbsp;48Hr</p>
          <p className='time-caption'>{timeData['48h']}</p>
        </Col>
        <Col className="time-section">
          <p className='time-title'>&gt;&nbsp;7Day</p>
          <p className='time-caption'>{timeData['7d']}</p>
        </Col>
      </Row>
    </Card>
  );
};

export default TicketStatusSummaryCard;
