import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { FPCheckbox } from "./new";
import CheckIcon from "@mui/icons-material/Check";
import { Box, Button, ListItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export default function AutoSelect({ multiple = false, placeholder, defaultData, value, variant, options, onChange, sx, selectAll = false, ...rest }) {
  const [selectedOptions, setSelectedOptions] = React.useState(multiple ? [] : null);
  
  React.useEffect(() => {
    if (multiple) {
      if (value && value?.length > 0) {
        const valuesArray = value.map((item) => item.value || item);
        setSelectedOptions(valuesArray);
      } else {
        setSelectedOptions(value);
      }
    } else {
      setSelectedOptions(value?.value || value ||defaultData);
    }
  }, [value, multiple]);  

const handleSelectAll = (isChecked) => {
  const allValues = options?.map((option) => option?.value);  
  setSelectedOptions(isChecked ? allValues : []);
  onChange?.(null, isChecked ? allValues : []);
};

const handleChange = (event, newValue) => {
  
  if (multiple) {
    if (selectAll) {
      const isSelectAllSelected = newValue.some((opt) => opt?.value === "select_all");      
      if (isSelectAllSelected) {
        const isChecked = selectedOptions?.length !== options?.length;
        handleSelectAll(isChecked);
        return;
      }
    }    
    setSelectedOptions(newValue?.map((v) => v?.value) || []);
    onChange?.(event, newValue?.filter((v) => v.value !== "select_all")?.map((option) => option?.value) || []);
  } else {
    setSelectedOptions(newValue ? newValue?.value : null);
    onChange?.(event, newValue ? newValue?.value : null);
  }
};

const extendedOptions = React.useMemo(() => {
  if (selectAll && multiple) {
    return [
      { value: "select_all", display_name: "Select All" }, 
      ...options,
    ];
  }
  return options;
}, [options, selectAll, multiple]);

return (
    <Autocomplete
      sx={{
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          boxShadow: "rgba(0, 142, 255, 0.25) 0px 0px 0px 0.2rem",
          borderColor: "rgb(0, 142, 255)",
        },
        // "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        //   borderColor: "rgb(0, 142, 255)", // Optional: add hover effect for outline
        // },
        "& .MuiOutlinedInput-notchedOutline": {
          minWidth: "100%",
          transition:
            "border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
        // "& .MuiOutlinedInput-notchedOutline.Mui-focused": {
        //   borderColor: "red",
        // },
        "& .MuiAutocomplete-tag": {
          backgroundColor: "#ddeff0",
          color: "#1b1c1d",
          fontSize: "14px",
        },
        "& .MuiChip-deleteIcon": {
          color: "#646565 !important",
        },
        ...sx,
      }}
      disableCloseOnSelect={multiple}
      size="small"
      multiple={multiple}
      id="combo-box-demo"
      options={extendedOptions}
      getOptionLabel={(option) => option?.display_name}
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        const isSelected = multiple
          ? selectedOptions?.some((opt) => opt?.value === option?.value)
          : option?.value === selectedOptions;
          return (
            <>
            {multiple ? (
            <ListItem
              key={key}
              {...optionProps}
              dense
              sx={{
                "&": {
                  backgroundColor: isSelected ? "#F2F4F7 !important" : "transparent",
                },
              }}
            >
              <FPCheckbox
                checked={option.value === "select_all" ? selectedOptions?.length === options?.length : selected}
              />
              {option?.display_name}
            </ListItem>
            ): (
              <ListItem
                key={key}
                {...optionProps}
                dense
                sx={{
                  "&": {
                    justifyContent: "space-between !important",
                    backgroundColor: isSelected ? "#F2F4F7 !important" : "transparent ",
                  },
                }}
              >
                <span>{option?.display_name}</span>
                {selected && (
                  <CheckIcon
                    fontSize="small"
                    style={{
                      color: "#F13A88",
                    }}
                  />
                )}
              </ListItem>
            )}
            </>
          );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={multiple ? (selectedOptions?.length ? "" : placeholder) : placeholder}
          variant={variant}
        />
      )}
      popupIcon={<KeyboardArrowDownIcon />}
      value={multiple ? extendedOptions?.filter((option) => selectedOptions?.includes(option?.value)) : extendedOptions?.find((option) => option?.value === selectedOptions) || null}
      onChange={handleChange}
      {...rest}
    />
);
}
