import React from "react";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import DefaultUserIcon from "../../images/boy.png";
import ChatFileShow from "../messenger/Common/ChatFileShow";
import { MessageBox } from "./new";
import { Avatar } from "@material-ui/core";

const swapTags = (text) => {
  let displayText = text.slice();
  const tags = text.match(/@\{\{[^\}]+\}\}/gi) || []; //eslint-disable-line
  const spans = [];
  tags.map((myTag) => {
    const idx = displayText.indexOf(myTag);
    spans.push(<span>{displayText.slice(0, idx)}</span>);
    spans.push(<span className="comments-textarea__mention">{myTag.slice(3, -2).split("||")[2]}</span>);
    displayText = displayText.slice(idx + myTag.length, displayText.length);
  });
  displayText && spans.push(<span>{displayText}</span>);
  return spans;
};

class CommentCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserFileModal: false,
      selectAttchFile: null,
    };
  }
  closeUserFileModal = () => {
    this.setState({ showUserFileModal: false });
  };
  showUserFileModalFn = () => {
    const { attached_file } = this.props;
    let preview_data = {};
    let msg_type = "image";
    if (
      attached_file.includes(".pdf") ||
      attached_file.includes(".docx") ||
      attached_file.includes(".doc") ||
      attached_file.includes(".xlsx") ||
      attached_file.includes(".xls") ||
      attached_file.includes(".txt") ||
      attached_file.includes(".csv")
    ) {
      msg_type = "file";
    }
    if (attached_file.includes(".mp4") || attached_file.includes(".webm")) {
      msg_type = "video";
    }
    preview_data.msg_type = msg_type;
    preview_data.attached_file = attached_file;
    preview_data.file_attached = attached_file;
    this.setState({ selectAttchFile: preview_data, showUserFileModal: true });
  };

  render() {
    const {
      description,
      backgroundColor,
      profile_pic,
      created_by,
      created_at,
      voice_comment,
      attached_file,
      createdByName,
    } = this.props;
    return (
      <MessageBox
        backgroundColor={backgroundColor}
        isSelfBox={false}
        className={"w-100 max-w-100"}
        time={moment(created_at).format("llll")}
        // author={created_by}
        captionClass="align-self-end"
      >
        <div className="d-flex gap-10 align-items-start">
          {created_by ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Avatar src={profile_pic || DefaultUserIcon} alt={created_by} />
              <p style={{ fontSize: "10px", color: "#1B1C1D" }} className="text-center text-nowrap mt-1">
                {created_by}
              </p>
            </div>
          ) : (
            <Avatar src={profile_pic || DefaultUserIcon} alt={created_by} />
          )}
          <span className="v-divider" style={{ height: 60 }}></span>
          {voice_comment == null ? (
            <div onClick={this.showUserFileModalFn} className="align-self-center">
              {swapTags(description).map((i) => {
                return i;
              })}
              <br />
              {attached_file && attached_file.includes(".pdf") && (
                <p style={{ cursor: "pointer" }}>
                  <Icon name="file pdf" size="large" />{" "}
                  {decodeURI(attached_file).split("ticket_comment_attachement/")[1]}
                </p>
              )}
              {attached_file && attached_file.includes(".doc") && (
                <p style={{ cursor: "pointer" }}>
                  <Icon name="file word" size="large" />{" "}
                  {decodeURI(attached_file).split("ticket_comment_attachement/")[1]}
                </p>
              )}
              {attached_file && attached_file.includes(".xlsx") && (
                <p style={{ cursor: "pointer" }}>
                  <Icon name="file excel" size="large" />{" "}
                  {decodeURI(attached_file).split("ticket_comment_attachement/")[1]}
                </p>
              )}
              {attached_file && attached_file.includes(".xls") && (
                <p style={{ cursor: "pointer" }}>
                  <Icon name="file excel" size="large" />{" "}
                  {decodeURI(attached_file).split("ticket_comment_attachement/")[1]}
                </p>
              )}
              {attached_file && attached_file.includes(".txt") && (
                <p style={{ cursor: "pointer" }}>
                  <Icon name="file text" size="large" />{" "}
                  {decodeURI(attached_file).split("ticket_comment_attachement/")[1]}
                </p>
              )}
              {attached_file && attached_file.includes(".csv") && (
                <p style={{ cursor: "pointer" }}>
                  <Icon name="file text" size="large" />{" "}
                  {decodeURI(attached_file).split("ticket_comment_attachement/")[1]}
                </p>
              )}
              {attached_file && attached_file.includes(".png") && (
                <img src={attached_file} style={{ width: "100px", cursor: "pointer" }} />
              )}
              {attached_file && attached_file.includes(".jpeg") && (
                <img src={attached_file} style={{ width: "100px", cursor: "pointer" }} />
              )}
              {attached_file && attached_file.includes(".jpg") && (
                <img src={attached_file} style={{ width: "100px", cursor: "pointer" }} />
              )}
              {attached_file && (attached_file.includes(".mp4") || attached_file.includes(".webm")) && (
                <iframe src={attached_file} frameBorder="0" allowFullScreen title="video" height="60" width="60" />
              )}
              {attached_file && attached_file.includes(".mp3") && (
                <audio controls>
                  <source src={attached_file} type="audio/ogg" />
                </audio>
              )}
            </div>
          ) : (
            <div>
              <audio src={voice_comment} controls />
            </div>
          )}
        </div>
        <ChatFileShow
          showModal={this.state.showUserFileModal}
          closeModal={this.closeUserFileModal}
          selectAttchFile={this.state.selectAttchFile}
        />
      </MessageBox>
    );
  }
}

export default CommentCard;
