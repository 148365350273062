import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import React, { useEffect, useState } from "react";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import classnames from "classnames";
import { FPButton } from "../common/new";
import { FaPlus } from "react-icons/fa6";
import ServiceRequestReminder from "../tickets/ServiceRequestReminder";

const ServiceRequestRule = ({
  rulesList,
  addRules,
  ticketEscalationList,
  loader,
  designation,
  addServiceReminder,
  editServiceReminder,
  deleteServiceReminder
}) => {
  const [activeRequestTab, setActiveRequestTab] = useState("1");
  const [openEscalationForm, setOpenEscalationForm] = useState(false);

  const toggleTicketTab = (tab) => {
    if (activeRequestTab !== tab) {
      setActiveRequestTab(tab);
    }
  };

  useEffect(() => {
    setOpenEscalationForm(false)
  }, [activeRequestTab])

  return (
    <>
      <p className="mb-0 fp-card-header my-4">Service Request Rules</p>

      <CardWrapper
        showDivider={false}
        headerTitle={
          <div className="d-flex justify-content-between align-items-center ticketAssignment">
            <Nav className="Setup_Sub_Nav ">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeRequestTab === "1",
                  })}
                  onClick={() => {
                    toggleTicketTab("1");
                  }}
                >
                  Service Request Reminder Rule
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeRequestTab === "2",
                  })}
                  onClick={() => {
                    toggleTicketTab("2");
                  }}
                >
                  Service Request Escalation Rule
                </NavLink>
              </NavItem>
            </Nav>
            {activeRequestTab === "1" && rulesList && rulesList.length > 0 && (
               <FPButton
               className="d-flex ml-auto"
               label="Add Escalation Rule"
               size="small"
               fontSize={15}
               onClick={() => setOpenEscalationForm(true)}
               endIcon={<FaPlus style={{ fontSize: "14px" }} />}
             />
            )}
          </div>
        }
      >
        <div>
          <TabContent activeTab={activeRequestTab} className="mt-2">
            <TabPane tabId="1">
              <ServiceRequestReminder
                loader={loader}
                ticketEscalationList={ticketEscalationList}
                openEscalationForm={openEscalationForm}
                handleEscalationOpen={() => setOpenEscalationForm(true)}
                handleEscalationClose={() => setOpenEscalationForm(false)}
                designation={designation}
                addServiceReminder={addServiceReminder}
                editServiceReminder={editServiceReminder}
                deleteServiceReminder={deleteServiceReminder}
                activeRequestTab={activeRequestTab}
              />
            </TabPane>
            <TabPane tabId="2">
              <ServiceRequestReminder
                loader={loader}
                ticketEscalationList={ticketEscalationList}
                openEscalationForm={openEscalationForm}
                handleEscalationOpen={() => setOpenEscalationForm(true)}
                handleEscalationClose={() => setOpenEscalationForm(false)}
                designation={designation}
                addServiceReminder={addServiceReminder}
                editServiceReminder={editServiceReminder}
                deleteServiceReminder={deleteServiceReminder}
                activeRequestTab={activeRequestTab}
              />
            </TabPane>
          </TabContent>
        </div>
      </CardWrapper>
    </>
  );
};

export default ServiceRequestRule;
