import * as types from '../actionTypes';
import * as commonAction from '../common/commonAction';
import axios_instance from '../../libs/interseptor';
import * as URLS from '../../libs/apiUrls';
import * as utils from '../../libs/utils';

const complaintsDetailsSuccess = (data) => {
  return({type: types.GET_COMPLAINT_DETAIL_SUCCESS, data});
};

const complaintsDetailsFailure= (error) => {
  return({type: types.GET_COMPLAINT_DETAIL_FAILURE, error});
};

export const complaintsActions = (data,businessId) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.COMPLAINT_DETAILS,[businessId]),{ params })
      .then((res) => {
        dispatch( commonAction.hideLoader() );
        dispatch(complaintsDetailsSuccess(res.data));
      })
      .catch((err) => {
        dispatch( commonAction.hideLoader() );
        dispatch(complaintsDetailsFailure(err));
        throw err;
      });
  };
};




export const complaintSourceOptions = (data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.COMPLAINT_SOURCE_OPTIONS,[data.businessId]))
      .then((res) => {
        dispatch( commonAction.hideLoader() );
        dispatch(complaintSourceOptionsSuccess(res.data));
      })
      .catch((err) => {
        dispatch( commonAction.hideLoader() );
        dispatch(complaintSourceOptionsFailure(err));
        throw err;
      });
  };
};

const complaintSourceOptionsSuccess = (data) => {
  return ({type: types.GET_COMPLAINT_SOURCE_OPTIONS_SUCCESS, data});
};

const complaintSourceOptionsFailure = (err) => {
  return ({type: types.GET_COMPLAINT_SOURCE_OPTIONS_FAILURE, err});
};