import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as listingGeoGridRankerAction from "../../../actions/listing/GeoGridRankerAction";
import LoaderComponent from "../../common/loaderComponent";
import FPInput from "../../common/new/forms/ui/FPInput";
import { FPButton } from "../../common/new";
import { InfoWindow } from "@react-google-maps/api";
import closeIcon from "../../../images/svg/x.svg";
import { Box } from "@material-ui/core";

const GeoGridScan = (props) => {
  const [selectedGrid, setSelectedGrid] = useState(3);
  const [selectedKeyWordGrid, setSelectedKeyWordGrid] = useState(3);
  const [selectedDistance, setSelectedDistance] = useState(2);
  const [keyword, setKeyword] = useState("");
  const [selectKeyword, setSelectKeyword] = useState("");
  const [geoGridData, setGeoGridData] = useState([]);

  const [search, setSearch] = useState(true);

  useEffect(() => {
    if (search) {
      setGeoGridData(props?.geo_grid_ranker_data?.grid);
    }
  }, [props?.geo_grid_ranker_data?.grid]);

  useEffect(() => {
    if (props?.use_scan_history_data) {
      setKeyword(props.use_scan_history_data?.keyword);
      setSelectKeyword(props.use_scan_history_data?.keyword);
      setSelectedDistance(props?.use_scan_history_data?.distance);
      setSelectedKeyWordGrid(props?.use_scan_history_data?.grid_size);
      setSelectedGrid(props?.use_scan_history_data?.grid_size);
      setGeoGridData(props?.use_scan_history_data?.grid_data);
      setSearch(false)
    }
  }, [props?.use_scan_history_data]);

  const handleInputChange = (event) => {
    setKeyword(event.target.value);
    if (props.use_scan_history_data?.keyword) {
      setSelectKeyword(props.use_scan_history_data?.keyword);
    }
  };

  useEffect(() => {
    if (
      selectedGrid &&
      selectedDistance &&
      props.business_id &&
      props.location_id &&
      !keyword
    ) {
      props.actions.getGmbLocationGeoGridRanker(
        props.business_id,
        props.location_id,
        selectedGrid,
        selectedDistance,
        keyword,
        props?.city,
        props?.state,
        props?.branch_tag
      );
      setSelectKeyword(
        props.use_scan_history_data?.keyword
          ? props.use_scan_history_data?.keyword
          : keyword
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.actions,
    selectedGrid,
    selectedDistance,
    props.business_id,
    props.location_id,
    props?.city,
    props?.state,
    props?.branch_tag,
  ]);

  const gridOptions = [
    { key: "3", value: 3, text: "3x3 Grid" },
    { key: "5", value: 5, text: "5x5 Grid" },
    { key: "7", value: 7, text: "7x7 Grid" },
    { key: "9", value: 9, text: "9x9 Grid" },
  ];

  const mapsOptions = [
    // { key: "1", value: "1", text: "1x1 Grid" },
    { key: "map", value: "google_map", text: "Google Maps" },
  ];

  const distanceOptions = [
    { key: "2", value: 2, text: "2 km" },
    { key: "4", value: 4, text: "4 km" },
    { key: "6", value: 6, text: "6 km" },
    { key: "8", value: 8, text: "8 km" },
    { key: "10", value: 10, text: "10 km" },
    { key: "12", value: 12, text: "12 km" },
    { key: "14", value: 14, text: "14 km" },
    { key: "16", value: 16, text: "16 km" },
    { key: "18", value: 18, text: "18 km" },
    { key: "20", value: 20, text: "20 km" },
    { key: "22", value: 22, text: "22 km" },
    { key: "24", value: 24, text: "24 km" },
  ];

  const handleKeywordClick = (clickedKeyword) => {
    setSearch(true);
    setKeyword(clickedKeyword);
  };

  const handleGridChange = (e, { value }) => {
    setSearch(true);
    setSelectedGrid(value);
  };

  const handleDistanceChange = (e, { value }) => {
    setSearch(true);
    setSelectedDistance(value);
  };

  const handleSerachKeyword = async () => {
    setSearch(true);
    if (keyword.length > 1) {
      await props.actions.getGmbLocationGeoGridRanker(
        props.business_id,
        props.location_id,
        selectedGrid,
        selectedDistance,
        keyword
      );
      await setSelectKeyword(keyword);
      await setSelectedKeyWordGrid(selectedGrid);
    }
  };

  const convertedArray = geoGridData?.map((item) => ({
    lat: item.center_lat,
    lng: item.center_lng,
  }));

  var bounds = new props.google.maps.LatLngBounds();

  for (var i = 0; i < convertedArray?.length; i++) {
    bounds.extend(convertedArray[i]);
  }

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "calc(100vh - 100px)",
  };

  const clearInputField = () => {
    setKeyword("");
    setSelectKeyword("");
  };

  return (
    <>
      <LoaderComponent loader={props.loader} />
      <div style={{ display: "grid", gap: "15px" }}>
        <div>
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <span style={{ fontWeight: "500", fontSize: "14px" }}>
              Top Keywords:
            </span>
            {props?.top_keyword_data?.length > 0
              ? props?.top_keyword_data?.map((topKeyword) => (
                  <div
                    key={topKeyword}
                    style={{
                      border: "1px solid",
                      padding: "5px",
                      paddingInline: "15px",
                      borderRadius: "15px",
                      width: "fit-content",
                      borderColor:
                        topKeyword === keyword ? "#54b1bf" : "#757371",
                      cursor: "pointer",
                      color: topKeyword === keyword ? "white" : "",
                      backgroundColor: topKeyword === keyword ? "#54b1bf" : "",
                    }}
                    onClick={() => handleKeywordClick(topKeyword)}
                  >
                    <span
                      style={{
                        color: topKeyword === keyword ? "white" : "#757371",
                        fontWeight: "500",
                      }}
                    >
                      {topKeyword}
                    </span>
                  </div>
                ))
              : "loading... "}
          </div>
        </div>

        <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
          <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
            <FPInput
              placeholder="Enter Keywords"
              type="search"
              value={keyword}
              onChange={handleInputChange}
              endIcon={
                <Box
                  component="img"
                  src={closeIcon}
                  onClick={() => clearInputField()}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    left: "10px",
                  }}
                />
              }
            />
          </div>
          <div>
            <Dropdown
              fluid
              search
              selection
              options={mapsOptions}
              placeholder="Google Maps"
            />
          </div>
          <div>
            <Dropdown
              fluid
              search
              selection
              placeholder="Select Grid"
              options={gridOptions}
              onChange={handleGridChange}
              value={selectedGrid}
            />
          </div>
          <div>
            <Dropdown
              fluid
              search
              selection
              placeholder="Select Distance"
              options={distanceOptions}
              onChange={handleDistanceChange}
              value={selectedDistance}
            />
          </div>
          <div>
            <FPButton label="Search Keyword" onClick={handleSerachKeyword} />
          </div>
        </div>
        {/* Map */}
        <div>
          <Map
            google={props.google}
            bounds={bounds}
            initialCenter={{
              lat: props?.geo_grid_ranker_data?.latlng?.latitude || 0,
              lng: props?.geo_grid_ranker_data?.latlng?.longitude || 0,
            }}
            styles={[
              // {
              //   featureType: "poi",
              //   stylers: [{ visibility: "off" }], // Hide points of interest
              // },
              {
                featureType: "transit",
                stylers: [{ visibility: "off" }], // Hide transit stations
              },

              {
                featureType: "landscape",
                elementType: "labels",
                stylers: [{ visibility: "off" }], // Lighten landscape
              },
              {
                featureType: "water",
                elementType: "labels",
                stylers: [{ visibility: "off" }], // Lighten water
              },
              {
                featureType: "administrative.locality",
                elementType: "labels",
                stylers: [{ visibility: "off" }], // Hide administrative labels
              },
              {
                featureType: "administrative.land_parcel",
                elementType: "labels",
                stylers: [{ visibility: "off" }], // Hide administrative labels
              },
              // {
              //   featureType: "administrative.neighborhood",
              //   elementType: "labels",
              //   stylers: [{ visibility: "off" }], // Hide administrative labels
              // },
              // {
              //   featureType: "administrative.province",
              //   elementType: "labels",
              //   stylers: [{ visibility: "off" }], // Hide administrative labels
              // },
              {
                featureType: "road",
                elementType: "labels",
                stylers: [{ visibility: "off" }], // Hide administrative labels
              },
            ]}
            containerStyle={containerStyle}
          >
            {/* {props?.geo_grid_ranker_data?.latlng ? (
              <Marker
                position={{
                  lat: props?.geo_grid_ranker_data?.latlng?.latitude,
                  lng: props?.geo_grid_ranker_data?.latlng?.longitude,
                }}
              />
            ) : (
              ""
            )} */}
            {geoGridData?.length > 0
              ? geoGridData?.map((marker, index) =>
                  props?.geo_grid_ranker_data?.latlng?.latitude !==
                    marker?.center_lat ||
                  props?.geo_grid_ranker_data?.latlng?.longitude !==
                    marker?.center_lng ? (
                    <Marker
                      key={index}
                      position={{
                        lat: marker?.center_lat,
                        lng: marker?.center_lng,
                      }}
                      icon={{
                        url:
                          selectKeyword?.length > 1
                            ? "data:image/svg+xml;charset=UTF-8," +
                              encodeURIComponent(`
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" width="60" height="60">
                          <circle cx="12" cy="12" r="10" fill="${
                            !marker?.rank
                              ? "#ff3358"
                              : marker?.rank < 5
                              ? "#38761D"
                              : marker?.rank <= 10
                              ? "#ff9033"
                              : "#ff3358"
                          }"  />
                          <text x="12" y="16" font-size="10" fill="white" text-anchor="middle" font-family="Arial, sans-serif">${
                            marker?.rank ? marker?.rank : "20+"
                          }</text>
                        </svg>
                      `)
                            : " data:image/svg+xml;charset=UTF-8," +
                              encodeURIComponent(`
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="gray" width="80" height="80">
                                            <circle cx="12" cy="12" r="10" fill="#6c757d" />
                                            <path d="M12 6V3l-4 4 4 4V8c3.31 0 6 2.69 6 6 0 1.86-.85 3.53-2.19 4.62L15 19.35C16.9 17.95 18 15.6 18 13c0-3.87-3.13-7-7-7z" fill="white" />
                                        </svg>
                                        `),
                        scaledSize: keyword
                          ? selectedKeyWordGrid === 3
                            ? new props.google.maps.Size(60, 60)
                            : new props.google.maps.Size(40, 40)
                          : selectedGrid === 3
                          ? new props.google.maps.Size(60, 60)
                          : new props.google.maps.Size(40, 40),
                      }}
                    />
                  ) : (
                    <Marker
                      position={{
                        lat: marker?.center_lat,
                        lng: marker?.center_lng,
                      }}
                      icon={{
                        url:
                          "data:image/svg+xml;charset=UTF-8," +
                          encodeURIComponent(`
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="50" height="50">
                                          <path d="M12 2C8.13 2 5 5.13 5 9c0 4.68 5.6 11.39 6.23 12.16.39.47 1.14.47 1.54 0C13.4 20.39 19 13.68 19 9c0-3.87-3.13-7-7-7z" fill="#FF3358"/>
                                          <circle cx="12" cy="9" r="3" fill="#900"/>
                                          <text x="12" y="16" font-size="8" fill="white" text-anchor="middle" font-family="Arial, sans-serif">${
                                            marker?.rank ? marker?.rank : ""
                                          }</text>
                                        </svg>
                                        `),
                        scaledSize: keyword
                          ? selectedKeyWordGrid === 3
                            ? new props.google.maps.Size(60, 60)
                            : new props.google.maps.Size(40, 40)
                          : selectedGrid === 3
                          ? new props.google.maps.Size(60, 60)
                          : new props.google.maps.Size(40, 40),
                      }}
                    />
                  )
                )
              : ""}
          </Map>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
    geo_grid_ranker_data: state.listingGeoGridRanker.geo_grid_ranker_data,
    use_scan_history_data: state.listingGeoGridRanker.use_scan_history_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // filterAction: bindActionCreators(filterListActions, dispatch),
  // actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
  actions: bindActionCreators(listingGeoGridRankerAction, dispatch),
});

export default GoogleApiWrapper({
  //   apiKey: "AIzaSyDtiQSYOJ6tdOSXoM6ZWbpNAOcQgmLWDxY",
})(connect(mapStateToProps, mapDispatchToProps)(GeoGridScan));
