import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm, SubmissionError } from "redux-form";
import TagInputComponent from "../common/TagInputComponent";
import InputComponent from "../common/InputComponent";
import { FPBreadcrumbs, FPButton } from "../common/new";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import FPLabel from "../common/new/forms/ui/FPLabel";
import TendingKeyWordBadge from "../../modules/dashboard/component/TendingKeyWordBadge";
import { capitalize } from "../../libs/utils";
import { Box } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

class InsightsCategoryForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      word: [],
      colorWord: [],
      wordsToShow: 20,
      indexes: [],
    };
  }

  componentDidMount() {
    const {
      initialValues: { words },
    } = this.props;
    this.setState({ colorWord: [...words] });
  }

  componentDidUpdate(prevProps) {
    const {
      words,
      initialValues: { id },
    } = this.props;
    const {
      words: prevWords,
      initialValues: { id: prevId },
    } = prevProps;
    if (id !== prevId) {
      this.setState({ colorWord: [...this.props.initialValues.words] });
    }
    if (words && words.length !== prevWords.length) {
      this.setState({ colorWord: [...this.props.initialValues.words] });
    }
    if (JSON.stringify(prevProps.recommendedKeywords) !== JSON.stringify(this.props.recommendedKeywords)) {
      if (this.props.recommendedKeywords) {
        const data = Object.values(this.props.recommendedKeywords).map((item, index) => item.sentiment);
        // const positiveIndex = data.findIndex((item) => item === "Positive");
        const negativeIndex = data.findIndex((item) => item === "Negative");
        const neutralIndex = data.findIndex((item) => item === "Neutral");

        this.setState({
          indexes: [negativeIndex, neutralIndex],
        });
      }
    }
  }

  showMore = () => {
    const { recommendedKeywords } = this.props;
    const { wordsToShow } = this.state;
    const recommended = recommendedKeywords && Object.keys(recommendedKeywords).map((item) => item);
    if (wordsToShow + 20 <= recommended.length) {
      this.setState({ wordsToShow: wordsToShow + 20 });
    } else {
      this.setState({ wordsToShow: recommended.length });
    }
    setTimeout(() => {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }, 0);
  };

  onSubmit = (formValues) => {
    const {
      router: { history },
    } = this.context;
    formValues["word_list"] = formValues["words"];
    return this.props
      .submitData(formValues)
      .then(() =>
        history.push({
          pathname: "central-setup",
          state: { tab: "6" },
        })
      )
      .catch((err) => {
        const errobj = {
          name: err.non_field_errors,
        };
        throw new SubmissionError(errobj);
      });
  };

  onBackButtonClick = () => {
    this.context.router.history.push({
      pathname: "central-setup",
      state: { tab: "6" },
    });
  };

  saveKeyword = (e, item) => {
    this.setState({ word: e.target.innerHTML });
    const newWord = this.state.colorWord;
    if (newWord.includes(item)) {
      return null;
    } else {
      this.setState({ colorWord: [item, ...newWord] });
    }
  };

  handleWordsChange = (wordsArr) => {
    if (!wordsArr.includes(this.state.word)) this.setState({ word: null });
    this.setState({ colorWord: [...wordsArr] });
  };

  alphanNumericValidate = (value) => {
    const regExp = /^[0-9a-zA-Z ]+$/;
    if (value && !regExp.test(value)) return "Please enter an alphanumeric(only numbers and alphabets) value.";
    else return null;
  };

  render() {
    const { errors, recommendedKeywords, id } = this.props;

    const { wordsToShow } = this.state;

    const breadCrumbList = [
      {
        label: "Central Setup",
        link: "",
      },
      {
        label: "Insights",
        onClick: () =>
          this.context.router.history.push({
            pathname: "central-setup",
            state: { tab: "6" },
          }),
      },
      {
        label: id ? "Edit Category" : "Add Category",
        link: "",
      },
    ];

    return (
      <div>
        <FPBreadcrumbs list={breadCrumbList} className="my-2 mb-4" />
        <CardWrapper headerTitle={id ? "Edit Category" : "Add Category"} contentClass="p-4">
          <form className="grid-form">
            <FPLabel>Category</FPLabel>
            <Field
              name="name"
              component={InputComponent}
              placeholder="Enter a Category"
              autoComplete="off"
              validate={this.alphanNumericValidate}
              serverError={errors && errors.name}
            />
            <FPLabel>Keywords</FPLabel>
            <Field
              name="words"
              component={TagInputComponent}
              word={this.state.word}
              handleWordsChange={this.handleWordsChange}
              placeholder="Enter Keywords"
            />
            <div className="mt-4 mb-2" style={{ float: "right" }}>
              <FPButton type="button" size="small" variant="outline" onClick={this.onBackButtonClick} className="mr-10">
                Cancel
              </FPButton>
              <FPButton type="button" size="small" onClick={this.props.handleSubmit(this.onSubmit)}>
                Submit
              </FPButton>
            </div>
          </form>
          <CardWrapper headerTitle="Recommended Keywords">
            <div>
              {recommendedKeywords && (
                <div className="word-cloud-table">
                  {Object.values(recommendedKeywords)
                    .slice(0, this.state.wordsToShow)
                    .map((item, index) => {
                      return (
                        <>
                          {this.state.indexes.includes(index) && (
                            <>
                              <Box
                                sx={{
                                  borderBottom: "2px dashed #BFC2CB",
                                  width: "100%",
                                }}
                              ></Box>
                            </>
                          )}
                          <div key={index} style={{ display: "inline-block" }}>
                            <TendingKeyWordBadge
                              label={capitalize(item.word)}
                              value={item.count}
                              type={item.sentiment.toLowerCase()}
                              className="cursor-pointer"
                              withBorder={
                                this.state.colorWord.length &&
                                this.state.colorWord
                                  .map((selectedWord) => selectedWord.toLowerCase())
                                  .includes(item.word.toLowerCase())
                              }
                              onLabelClick={(e) => this.saveKeyword(e, item.word)}
                            />
                          </div>
                        </>
                      );
                    })}
                </div>
              )}
              <p
                onClick={this.showMore}
                role="button"
                className="cursor-pointer d-flex align-items-center mt-2"
                style={{ color: "#7A8193" }}
              >
                <KeyboardArrowDownIcon style={{ marginRight: "10px" }} />
                {wordsToShow === (recommendedKeywords && Object.keys(recommendedKeywords).length)
                  ? "No More"
                  : "View More"}
              </p>
            </div>
          </CardWrapper>
        </CardWrapper>
      </div>
    );
  }
}

InsightsCategoryForm.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

const validate = (formValues) => {
  const errors = {};
  if (!formValues.name) {
    errors.name = "Enter a category";
  }

  if (!formValues.words || !formValues.words.length) {
    errors.words = "Enter keywords";
  }
  return errors;
};

const formWrapped = reduxForm({
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: "InsightsCategoryForm",
})(InsightsCategoryForm);

export default formWrapped;
