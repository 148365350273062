import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import classnames from "classnames";
import PaginationComponent from "../common/Pagination";
import AIReviewReply from "./AIReviewReply";

function AIReplyTable(props) {
  const {
    smartResponseOnCheck,
    ordering,
    sortFunction,
    activeUser,
    businessId,
    count,
    location,
    pageSizeCookie,
    aiResponseOnCheck,
    aiResponseList,
    deleteAiResponse,
  } = props;
  const [activeTab, setActiveTab] = useState("1");

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <CardWrapper
        showDivider={false}
        className="mt-4"
        headerTitle={
          <div className="d-flex justify-content-between align-items-center ticketAssignment">
            <Nav className="Setup_Sub_Nav ">
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggleTab("1");
                  }}
                >
                  Review Reply
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggleTab("2");
                  }}
                >
                  Inbox Reply
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggleTab("3");
                  }}
                >
                  Social Comments Reply
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === "4",
                  })}
                  onClick={() => {
                    toggleTab("4");
                  }}
                >
                  Mention Reply
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        }
      >
        <div>
          <TabContent activeTab={activeTab} className="mt-2">
            <TabPane tabId="1">
              <AIReviewReply
                aiResponseOnCheck={aiResponseOnCheck}
                ordering={ordering}
                sortFunction={sortFunction}
                aiResponseList={aiResponseList}
                activeUser={activeUser}
                businessId={businessId}
                deleteAiResponse={deleteAiResponse}
              />
              {smartResponseOnCheck ? (
                <PaginationComponent count={count} location={location} pageSizeCookie={pageSizeCookie} />
              ) : (
                ""
              )}
            </TabPane>
            <TabPane tabId="2"></TabPane>
            <TabPane tabId="3"></TabPane>
          </TabContent>
        </div>
      </CardWrapper>
    </div>
  );
}

export default AIReplyTable;
