import React from "react";
import { Row, Col } from "reactstrap";
import "../../styles/styles.css";

import InboxListComponent from "../../components/messenger/InboxListComponent";
//import MatrixTabComponent from '../../components/messenger/MatrixTabComponent';
import ActiveUserComponent from "../../components/messenger/ActiveUserComponent";
import ChatBoxComponent from "../../components/messenger/ChatBoxComponent";
import ChatInputComponent from "../../components/messenger/ChatInputComponent";
import FilterComponent from "../../components/messenger/FilterComponent";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import cookie from "react-cookies";
import ErrorModal from "../../components/messenger/Common/ErrorModal";
import moment from "moment";
import LockedComponent from "../../components/common/LockedComponent/LockedComponent";
import FacebookImage from "../../images/facebook.png";
import Instagram from "../../images/instagram_logo.png";
import Whatsapp from "../../images/whatsappicon.png";
import Googlebusiness from "../../images/google.png";
// import { Button, Popup } from "semantic-ui-react";
// import { Link } from "react-router-dom";
// import { Link } from 'react-router-dom';
// import QAComponent from "../../components/messenger/QAComponent";
import * as utils from "../../libs/utils";
import Answer from "../../components/messenger/AnswerComponent";
import QAreplyComponent from "../../components/messenger/QAreplyComponent";
// import LoaderComponent from "../../components/common/loaderComponent";
import { displayMessage } from "../../libs/utils";
import "../../components/messenger/messenger.css";
import { Badge, Box, Collapse } from "@material-ui/core";
import { FPToolTip } from "../../components/common/new";
import InboxCategory from "../../components/messenger/InboxCategory";
import { FPTab } from "../../components/common/new/tabs";
import { Divider } from "antd";
import UserListActionBar from "../../components/messenger/UsetListActionBar";
import queryString from "query-string";
import XIcon from "../../images/X.jpg";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FormModal from "../../components/common/FormModal";
import SocialConnect from "../../components/mentions/SocialConnect/SocialConnect";
import * as mentionsActions from "../../actions/mentions/mentionsActions";
import InboxSocialConnect from "../../components/mentions/SocialConnect/InboxSocialConnect";

const socket_url = process.env.REACT_APP_WEB_SOCKET_URL;
const ws = new WebSocket(socket_url);

// const getIcon = {
//   fbmessenger: FacebookImage,
//   igmessenger: Instagram,
//   xmessenger: XIcon,
//   whatsappmessenger: Whatsapp,
// };

const getIcon = {
  facebook: FacebookImage,
  instagram: Instagram,
  twitter: XIcon,
  whatsappmessenger: Whatsapp,
};

class MessengerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      businessId: this.props.match.params.business_id,
      tabKey: "normal",
      activeTab: "1",
      respondedList: [],
      unrespondedList: [],
      teamList: [],
      branchId: "",
      messengerList: [],
      activeUser: {},
      contactLoader: false,
      chatBoxLoader: false,
      page: 1,
      totalPage: 0,
      next: "",
      addUserDetailModal: false,
      userAssignModal: false,
      userAssignError: "",
      userDetailUpdateLoader: false,
      userUpdateError: "",
      errorModalShow: false,
      sendMessageError: "",
      countArray: [],
      count: 0,
      expended: false,
      msgSendSocket: {},
      base644: "",
      pageList: [],
      socialStatusData: [],
      questionList: [],
      question: {},
      questionBranch: [],
      qa_pageNum: 1,
      isFetching: true,
      question_id: null,
      showFilter: false,
      x_connected: false,
    };
    this.toggleQuestion = this.toggleQuestion.bind(this);
    this.toggleReply = this.toggleReply.bind(this);
    this.filterQuestionList = this.filterQuestionList.bind(this);
    this.setPageNum = this.setPageNum.bind(this);
    this.checkXLoggedIn = this.checkXLoggedIn.bind(this);

    setTimeout(() => {
      this.getConversationUserList();
    }, 100);

    ws.onopen = (event) => {
      console.log(event, "socket callll");
    };

    ws.onmessage = (event) => {
      let activeUser = this.state.activeUser;
      console.log(activeUser,'activeUser details from inbox list');
      const data = JSON.parse(event.data);
      console.log(data,'data of message from socket');
      console.log(data.business_id,'business id of conversation and type', typeof(data.business_id));
      console.log(this.state.businessId,'business id from state and type', typeof(this.state.businessId));
      if (this.state.businessId == data.business_id){
        console.log('business match found the correct inbox');
        if (data.success === true) {
          const messageDate = moment(data.messege.created_at).format(
            "YYYY-MM-DD"
          );
          if (activeUser.id === data.messenger_id) {
            let messengerListtt = this.state.messengerList;
            if (data.messege?.is_deleted) {
              let date_index = null;
              let date_msg_index = null;
              messengerListtt.map((item, list_index) => {
                item.data.map((data_item, data_item_index) => {
                  if (data_item.id === data.messege.id) {
                    date_index = list_index;
                    date_msg_index = data_item_index;
                  }
                  return data_item;
                });
                return item;
              });
              if (date_index && date_msg_index) {
                messengerListtt[date_index]["data"][date_msg_index][
                  "is_deleted"
                ] = true;
                this.setState({ messengerList: messengerListtt });
              }
            } else {
              const messageIndex = messengerListtt.findIndex(
                (x) => x.date === messageDate
              );
              if (messageIndex > -1) {
                messengerListtt[messageIndex]["data"].push(data.messege);
                this.setState({ messengerList: messengerListtt });
              } else {
                const newdata = { date: messageDate, data: [data.messege] };
                messengerListtt.push(newdata);
                this.setState({ messengerList: messengerListtt });
              }
            }
          }
        } else if (data.success === false) {
          this.setState({ errorModalShow: true, sendMessageError: data.error });
        }
        let carray = this.state.countArray;
        if (activeUser.id !== data.messenger_id && !data.messege?.is_deleted) {
          if (carray.length === 0) {
            let countt = this.state.count + 1;
            carray.push({ messenger_id: data.messenger_id, count: countt });
            this.setState({ countArray: carray });
          } else {
            const cindex = carray.findIndex(
              (x) => x.messenger_id === data.messenger_id
            );
            if (cindex > -1) {
              let countt = carray[cindex]["count"] + 1;
              carray[cindex]["count"] = countt;
              this.setState({ countArray: carray });
            } else {
              let countt = this.state.count + 1;
              carray.push({ messenger_id: data.messenger_id, count: countt });
              this.setState({ countArray: carray });
            }
          }
        }

        let unresondUsers = this.state.unrespondedList;
        let respondedUsers = this.state.respondedList;

        let reesuserIndex = respondedUsers.findIndex(
          (x) => x.id === data.messenger_id
        );

        if (reesuserIndex > -1) {
          const unUser = respondedUsers[reesuserIndex];
          unresondUsers.unshift(unUser);
          respondedUsers.splice(reesuserIndex, 1);
          this.setState({
            respondedList: respondedUsers,
            unrespondedList: unresondUsers,
          });
        }

        let unresuserIndex = unresondUsers.findIndex(
          (x) => x.id === data.messenger_id
        );
        if (unresuserIndex > -1) {
          const selteuser = unresondUsers[unresuserIndex];
          unresondUsers.splice(unresuserIndex, 1);
          unresondUsers.unshift(selteuser);
          this.setState({ unrespondedList: unresondUsers });
        } else if (data.success === true) {
          unresondUsers.unshift(data.messenger);
          this.setState({ unrespondedList: unresondUsers });
        }
      }
    };
  }

  componentDidMount() {
    this.props.actionsSelectTab.setSelectedTab(1);
    document.title = "Messenger";
    // this.socialStatus();
    this.fetchGoogleQa();
    this.checkXLoggedIn();
    // this.getMentionsSocialStatus();
    this.callSocialProfileApi();
  }

  chatMessage = (message) => {
    let activeUser = this.state.activeUser;
    if (
      message.msg_type === "image" ||
      message.msg_type === "file" ||
      message.msg_type === "video" ||
      message.msg_type === "audio"
    ) {
      let reader = new FileReader();
      reader.readAsDataURL(message.attached_file);
      reader.onload = () => {
        const msgSendData = {
          messenger: activeUser.id,
          msg_type: message.msg_type,
          message_text: message.message,
          attached_file: reader.result,
          file_type: message.file_type,
          extension: message.attached_file.name.split(".")[1],
          name: message.name,
        };
        ws.send(JSON.stringify(msgSendData));
      };
    } else if (message.msg_type === "text" && message.attached_file === null) {
      const msgSendData = {
        messenger: activeUser.id,
        msg_type: message.msg_type,
        message_text: message.message,
        attached_file: message.attached_file,
        file_type: message.file_type,
        name: message.name,
      };
      ws.send(JSON.stringify(msgSendData));
    } else if (message.msg_type === "text" && message.attached_file !== null) {
      let reader = new FileReader();
      reader.readAsDataURL(message.attached_file);
      reader.onload = () => {
        const msgSendData = {
          messenger: activeUser.id,
          msg_type: "file",
          message_text: message.message,
          attached_file: reader.result,
          file_type: message.file_type,
          extension: message.attached_file.name.split(".")[1],
          name: message.name,
        };
        ws.send(JSON.stringify(msgSendData));
      };
    }
    this.getMessengerList(this.state.page);
  };

  chatMessageUpdate(data) {
    this.setState({ messengerList: data });
  }

  getConversationUserList(params) {
    this.setState({ contactLoader: true });
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    const updateParams = params
      ? params
      : queryString.parse(this.props.location.search);

    return axios_instance
      .get(
        `${URLS.MESSENGER_ALL_CONVERSATION}?business_id=` +
          this.state.businessId,
        {
          headers: headers,
          params: updateParams,
        }
      )
      .then((res) => {
        if (res.data.success === true) {
          if (res.data.detail !== "") {
            this.setState({ contactLoader: false });
            this.setState({ lock: "Messenger Module is locked." });
          } else {
            if (
              res.data.data.responded_list.length > 0 ||
              res.data.data.unresponded_list.length > 0
            ) {
              this.setState({
                pageList: res.data.page_list,
                activeUser: {},
                expended: false,
                branchId: res.data.data.branch_id,
                respondedList: res.data.data.responded_list,
                unrespondedList: res.data.data.unresponded_list,
                teamList: res.data.data.team_list,
                contactLoader: false,
              });
              // TODO : Change here
              this.getMessengerList(this.state.page);
            } else {
              // const msg = 'If you have not setted up your facebook messenger account yet. Please go to central page setup to setup facebook messenger page. If you have already setted up your facebook account then wait for a minute, fetching your data.';
              // const msg = 'Either you have not connected any account or you do not have any conversation in you setup account. Conversation will be there as soon as your set up account will receive any message.';
              // this.setState({errorModalShow:true, sendMessageError:msg, messengerList:[],pageList:res.data.page_list, activeUser:{} ,expended:false,branchId:res.data.data.branch_id, respondedList:res.data.data.responded_list, unrespondedList:res.data.data.unresponded_list, teamList:res.data.data.team_list, contactLoader: false});
              this.setState({
                messengerList: [],
                pageList: res.data.page_list,
                activeUser: {},
                expended: false,
                branchId: res.data.data.branch_id,
                respondedList: res.data.data.responded_list,
                unrespondedList: res.data.data.unresponded_list,
                teamList: res.data.data.team_list,
                contactLoader: false,
              });
            }
            if (res.data.data.unresponded_list.length === 0) {
              this.setState({ expended: true });
            }
          }
        }
      })
      .catch((err) => {
        console.log(err, "111");
      });
  }
  hideMessengerLoader() {
    this.setState({ chatBoxLoader: false });
  }
  getMessengerList(page) {
    const authToken = cookie.load("authToken");
    let activeUser = this.state.activeUser;
    this.setState({ chatBoxLoader: true });
    if (!this.state.activeUser.id) {
      if (this.state.unrespondedList.length > 0) {
        // TODO : update active user here
        activeUser = this.state.unrespondedList[0];
      } else {
        activeUser = this.state.respondedList[0];
      }
    }

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    if (activeUser?.id) {
      return axios_instance
        .get(
          `${URLS.MESSENGER_CONVERSATION_MESSAGES}?messenger=${activeUser.id}`,
          { headers: headers }
        )
        .then((res) => {
          this.setState({
            messengerList: res.data.data,
            activeUser: activeUser,
            chatBoxLoader: false,
          });
        })
        .catch((err) => {
          throw err;
        });
    }
  }

  handleUserCallback = (childData) => {
    this.setState({
      activeUser: childData,
      messengerList: [],
      page: 1,
      next: "",
      tabKey: "normal",
    });
    localStorage.setItem("activeUser", JSON.stringify(this.state.activeUser));

    let carray = this.state.countArray;
    const cindex = carray.findIndex((x) => x.messenger_id === childData.id);
    if (cindex > -1) {
      carray.splice(cindex, 1);
      this.setState({ countArray: carray });
    }

    setTimeout(() => {
      this.getMessengerList(this.state.page);
    }, 100);
  };

  sendMessageFunction(msg) {
    const oldMessenger = this.state.messengerList;
    let activeUser = this.state.activeUser;
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    let formdata = new FormData();
    formdata.append("messenger", activeUser.id);
    formdata.append("msg_type", msg.msg_type);
    formdata.append("message_text", msg.message);
    formdata.append("attached_file", msg.attached_file);
    formdata.append("file_type", msg.file_type);
    return axios_instance
      .post(`${URLS.MESSENGER_CONVERSATION_MESSAGES}`, formdata, {
        headers: headers,
      })
      .then((res) => {})
      .catch((err) => {
        this.setState({
          messengerList: oldMessenger,
          chatBoxLoader: false,
          errorModalShow: true,
          sendMessageError: err.response.data.error,
        });
        throw err.response.data;
      });
  }

  userDetailUpdate(udetail) {
    this.userDetailUpdateApi(udetail);
  }

  userDetailUpdateApi(detail) {
    this.setState({
      addUserDetailModal: false,
      userDetailUpdateLoader: false,
      userUpdateError: "",
    });
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    return axios_instance
      .post(`${URLS.MESSENGER_CREATE_CONTACT}`, detail, { headers: headers })
      .then((res) => {
        if (res.data.success === true) {
          this.setState({
            addUserDetailModal: true,
            userDetailUpdateLoader: true,
            userUpdateError: "",
            activeUser: {},
          });
          let respondedd = this.state.respondedList;
          let unresonded = this.state.unrespondedList;
          const respondedIndex = respondedd.findIndex(
            (x) => x.id === res.data.data.id
          );
          const unresondedIndex = unresonded.findIndex(
            (x) => x.id === res.data.data.id
          );
          if (respondedIndex > -1) {
            respondedd[respondedIndex] = res.data.data;
            this.setState({
              respondedList: respondedd,
              addUserDetailModal: true,
              userDetailUpdateLoader: true,
              userUpdateError: "",
              activeUser: res.data.data,
            });
          }
          if (unresondedIndex > -1) {
            unresonded[unresondedIndex] = res.data.data;
            this.setState({
              unrespondedList: unresonded,
              addUserDetailModal: true,
              userDetailUpdateLoader: true,
              userUpdateError: "",
              activeUser: res.data.data,
            });
          }
        } else if (res.data.success === false) {
          this.setState({
            addUserDetailModal: false,
            userDetailUpdateLoader: true,
            userUpdateError: res.data.data,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  userAssignUpdateFn(data) {
    this.userAssignUpdateApi(data);
  }

  userAssignUpdateApi(data) {
    this.setState({ userAssignModal: false, userAssignError: "" });
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    return axios_instance
      .post(`${URLS.MESSENGER_ASSIGN_CONVERSATION}`, data, { headers: headers })
      .then((res) => {
        if (res.data.success === true) {
          let unresponl = this.state.unrespondedList;
          let unrespondedListIndex = unresponl.findIndex(
            (x) => x.id === res.data.data.id
          );
          if (unrespondedListIndex > -1) {
            unresponl[unrespondedListIndex] = res.data.data;
            this.setState({
              unrespondedList: unresponl,
              userAssignModal: true,
              userUpdateError: "",
              activeUser: res.data.data,
            });
          }
        } else if (res.data.success === false) {
          this.setState({ userAssignError: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  fetchGoogleQa() {
    this.setState({ isFetching: true });
    return axios_instance
      .get(utils.format(`${URLS.REVIEW_QA}?page=${1}`, [this.state.businessId]))
      .then((res) => {
        // TODO : Change here
        this.setState({ questionList: res.data });
        this.setState({ isFetching: false });
        return axios_instance
          .get(`${URLS.BUSINESS_SETUP}${this.state.businessId}/branch/`)
          .then((resp) => {
            this.setState({ questionBranch: resp.data.choices });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // toggleTab(tab) {
  //   this.setState({ tabKey: tab, question: {} });
  //   if (tab === "Q&A") {
  //     this.fetchGoogleQa();
  //   } else {
  //     this.setState({ questionBranch: [], qa_pageNum: 1 });
  //   }
  // }

  setPageNum(page) {
    this.setState({ qa_pageNum: page });
  }

  filterQuestionList(data) {
    this.setState({ isFetching: true });
    const { qa_pageNum } = this.state;
    const url =
      data === ""
        ? utils.format(`${URLS.REVIEW_QA}?page=${qa_pageNum}`, [
            this.state.businessId,
          ])
        : utils.format(
            `${URLS.REVIEW_QA}?page=${qa_pageNum}&branch_id=${data}`,
            [this.state.businessId]
          );
    return axios_instance
      .get(url)
      .then((res) => {
        this.setState({ questionList: res.data });
        this.setState({ isFetching: false });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggleQuestion(question) {
    this.setState({ tabKey: "Q&A" });
    if (question) {
      this.setState({ question: question, question_id: question.id });
    } else {
      const { questionList, question_id } = this.state;
      questionList &&
        questionList.results &&
        Array.isArray(questionList.results) &&
        questionList.results.map((value) => {
          if (value.id === question_id) {
            this.setState({ question: value, question_id: value.id });
          }
        });
    }
  }

  toggleReply(answer, id, branch_id) {
    const body = {
      text: answer,
    };
    return axios_instance
      .post(
        utils.format(`${URLS.REVIEW_QA_REPLY}`, [
          this.state.businessId,
          branch_id,
          id,
        ]),
        body
      )
      .then((res) => {
        console.log(res);
        this.setState({ isFetching: true });
        return axios_instance
          .get(utils.format(`${URLS.REVIEW_QA}`, [this.state.businessId]))
          .then((res) => {
            this.setState({ questionList: res.data });
            this.toggleQuestion(null);
            this.setState({ isFetching: false });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        if (error.response) {
          displayMessage("negative", error.response.data.detail);
        } else if (error.request) {
          console.error("No response received from server");
          displayMessage("negative", "No response received from server!");
        } else {
          console.error("Error setting up request:", error.message);
        }
      });
  }

  errorModalCallback(data) {
    if (data === false) {
      this.setState({ errorModalShow: false });
    }
  }

  userFilterFunction(params) {
    this.setState({
      contactLoader: true,
      respondedList: [],
      unrespondedList: [],
      teamList: [],
      messengerList: [],
    });
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };
    const queryObject = queryString.parse(params?.queryString);

    return axios_instance
      .get(
        `${URLS.MESSENGER_ALL_CONVERSATION}?business_id=${this.state.businessId}`,
        {
          headers: headers,
          params: queryObject,
        }
      )
      .then((res) => {
        if (res.data.data.responded_list.length > 0) {
          this.setState({
            expended: true,
            activeUser: res.data.data.responded_list[0],
            branchId: res.data.data.branch_id,
            respondedList: res.data.data.responded_list,
            unrespondedList: res.data.data.unresponded_list,
            teamList: res.data.data.team_list,
            contactLoader: false,
          });
          this.getMessengerList(this.state.page);
        } else if (res.data.data.unresponded_list.length > 0) {
          this.setState({
            expended: true,
            activeUser: res.data.data.unresponded_list[0],
            unrespondedList: res.data.data.unresponded_list,
            teamList: res.data.data.team_list,
            contactLoader: false,
          });
          this.getMessengerList(this.state.page);
        } else {
          this.setState({
            errorModalShow: true,
            sendMessageError: "No data found. Please change filter options.",
            expended: true,
            activeUser: {},
            unrespondedList: res.data.data.unresponded_list,
            respondedList: res.data.data.responded_list,
            teamList: res.data.data.team_list,
            contactLoader: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  // socialStatus() {
  //   const authToken = cookie.load("authToken");
  //   const headers = {
  //     Authorization: `Bearer ${authToken}`,
  //   };
  //   return axios_instance
  //     .get(
  //       `${URLS.MESSENGER_SOCIAL_STATUS}?business_id=${this.state.businessId}`,
  //       { headers: headers }
  //     )
  //     .then((res) => {
  //       console.log("res.datares.datares.datares.data", res.data);

  //       this.setState({ socialStatusData: res.data });
  //     });
  // }

  filterReset() {
    this.getConversationUserList({});
    this.filterQuestionList("");
  }

  // userFilterbySource(data) {
  //   if (data.platform !== "" && data.page_id !== "") {
  //     const type = "platform=" + data.platform + "&page_id=" + data.page_id;
  //     this.userFilterFunction(type);
  //   } else if (data.platform !== "" && data.page_id === "") {
  //     const type = "platform=" + data.platform;
  //     this.userFilterFunction(type);
  //   }
  // }

  // pageFilterData(data) {
  //   if (data.platform !== "" && data.page_id !== "") {
  //     const type = "platform=" + data.platform + "&page_id=" + data.page_id;
  //     this.userFilterFunction(type);
  //   } else if (data.platform === "" && data.page_id !== "") {
  //     const type = "page_id=" + data.page_id;
  //     this.userFilterFunction(type);
  //   }
  // }

  // userFilterResetFunction(data) {
  //   if (data === "") {
  //     this.getConversationUserList();
  //   }
  // }

  checkXLoggedIn() {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    axios_instance
      .get(`${URLS.MESSENGER}twitter-status/?business_id=${business_id}`)
      .then((res) => {
        this.setState({ x_connected: res.data.connected });
      })
      .catch((err) => console.log(err));
  }
  

  toggleSocialConnect = () => {
    this.props.history.push({
      pathname: "central-setup",
      state: { tab: "8" },
    })
  };
    
  postCode = (code, provider) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions
      .postMentionsSocialCode(code, business_id, provider)
      .then(() => {
        utils.displayMessage("positive", `${provider} Connected Successfully`);
      })
      .catch(() => {
        utils.displayMessage("negative", "Error");
      });
  };

  connectSocial = (data, provider) => {
    const code = {
      ...data,
      provider: provider,
    };
    // const code = provider === "twitter" ? data.oauth_verifier : data.code;
    window.removeEventListener("message", this.connectSocialRef);
    this.postCode(code, provider);
    this.newWindow.close();
  };

  getAuthUrl = (provider) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions.getAuthUrl(business_id, provider).then((res) => {
      this.newWindow = window.open(
        res.auth_url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
      );
      this.connectSocialRef = (response) =>
        this.connectSocial(response.data, provider);
      window.addEventListener("message", this.connectSocialRef);
    });
  };

  // getMentionsSocialStatus = () => {
  //   const {
  //     match: {
  //       params: { business_id },
  //     },
  //   } = this.props;
  //   return this.props.actions.getMentionsSocialStatus(business_id);
  // };

  callSocialProfileApi = () => {
      const {
      match: {
        params: { business_id },
      },
    } = this.props;
    axios_instance
      .get(`${URLS.SOCIAL_PROFILE_INTEGRATION}?business_id=${business_id}`)
      .then((res) => {
        const profileCount = {};
        res.data &&
          Array.isArray(res.data) &&
          res.data.map((value) => {
            profileCount[value.provider] = value.no_of_profiles;
            return profileCount;
          });
        this.setState({ socialStatusData: profileCount });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  mentionsSocialLogout = (provider) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    if (provider == "facebook") {
      const authToken = cookie.load("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      return axios_instance
        .get(`${URLS.MESSENGER}logout/?business_id=${business_id}`, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.connected == false) {
            this.socialStatus();
            utils.displayMessage(
              "positive",
              `${provider} Disconnected Successfully`
            );
          }
        })
        .catch((err) => {
          utils.displayMessage("negative", "Error");
          throw err.response.data;
        });
    } else if (provider == "instagram") {
      const authToken = cookie.load("authToken");
      const headers = {
        Authorization: `Bearer ${authToken}`,
      };

      return axios_instance
        .get(`${URLS.MESSENGER}ig-logout/?business_id=${business_id}`, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.connected == false) {
            this.socialStatus();
            utils.displayMessage(
              "positive",
              `${provider} Disconnected Successfully`
            );
          }
        })
        .catch((err) => {
          utils.displayMessage("negative", "Error");
          throw err.response.data;
        });
    } else {
      return this.props.actions
        .mentionSocialLogout(business_id, provider)
        .then(() => {
          utils.displayMessage(
            "positive",
            `${provider} Disconnected Successfully`
          );
        })
        .catch(() => {
          utils.displayMessage("negative", "Error");
        });
    }
  };

  setupFbMsg = (data) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const req_data = {
      access_token: data.accessToken,
      user_id: data.userID,
      business_id: business_id,
    };
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    return axios_instance
      .post(`${URLS.MESSENGER}setup/`, req_data, { headers: headers })
      .then((res) => {
        if (res.data.connected == true) {
          this.socialStatus();
          utils.displayMessage("positive", "Facebook Connected Successfully");
        } else {
          utils.displayMessage("negative", "Error");
        }
      })
      .catch((err) => {
        utils.displayMessage("negative", err);
        throw err.response.data;
      });
  };

  setupInstaMsg = (data) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const req_data = {
      access_token: data.accessToken,
      user_id: data.userID,
      business_id: business_id,
    };
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    return axios_instance
      .post(`${URLS.MESSENGER}ig-setup/`, req_data, { headers: headers })
      .then((res) => {
        if (res.data.connected == true) {
          this.socialStatus();
          utils.displayMessage("positive", "Instagram Connected Successfully");
        } else {
          utils.displayMessage("negative", "Error");
        }
      })
      .catch((err) => {
        utils.displayMessage("negative", err);
        throw err.response.data;
      });
  };

  handleSearchConversation = (search) => {
    this.getConversationUserList(search);
  };

  // handleSortCOnversation = (order) => {
  //   console.log("orderorderorderorderorder", order);
  // };
  render() {
    // const businessId = this.state.businessId;
    const {
      pageList,
      questionBranch,
      lock,
      activeUser,
      messengerList,
      contactLoader,
      chatBoxLoader,
      totalPage,
      addUserDetailModal,
      userDetailUpdateLoader,
      userUpdateError,
      userAssignModal,
      userAssignError,
      errorModalShow,
      sendMessageError,
      countArray,
      expended,
      showFilter,
      x_connected,
      respondedList,
      unrespondedList,
      teamList,
      socialStatusData,
    } = this.state;

    return (
      <Row className="content-area messenger-setup">
        <LockedComponent message={lock} />

        <Col xs={12} sm={12} className="business-setup pt-3">
          <div className="m-0 mb-4">
            <div className="d-flex justify-content-between">
              <Box className="mb-0 fp-card-header">Messenger</Box>
              <div className="d-flex gap-20" onClick={this.toggleSocialConnect}>
                {["facebook", "instagram", "twitter"]?.map((key) => (
                  <FPToolTip
                    key={key}
                    title={
                      socialStatusData[key] === true
                        ? "Connected"
                        : "Go to central Setup"
                    }
                  >
                    <Badge
                      color={
                        socialStatusData[key] >= 1 ? "Primary" : "Error"
                      }
                      variant="dot"
                    >
                      <img
                        src={getIcon[key]}
                        alt=""
                        style={{ width: 20, height: 20 }}
                        className="cursor-pointer"
                      ></img>
                    </Badge>
                  </FPToolTip>
                ))}

                {/* <FPToolTip title={"Go to central Setup"}>
                  <Badge color="error" variant="dot">
                    <img
                      src={Googlebusiness}
                      alt=""
                      style={{ width: 20, height: 20 }}
                      className="cursor-pointer"
                    ></img>
                  </Badge>
                </FPToolTip>

                <FPToolTip
                  title={
                    x_connected === true ? "Connected" : "Go to central Setup"
                  }
                >
                  <Badge
                    color={x_connected === true ? "Primary" : "Error"}
                    variant="dot"
                  >
                    <img
                      src={XIcon}
                      alt=""
                      style={{ width: 20, height: 20, borderRadius: "2px" }}
                      className="cursor-pointer"
                    ></img>
                  </Badge>
                </FPToolTip> */}
              </div>
            </div>
            <FormModal
              heading={"Connect Social Accounts"}
              isOpen={this.state.isSocialConnectOpen}
              size="lg"
              toggle={this.toggleSocialConnect}
              width={"700px"}
              headerClass="d-flex align-items-center"
            >
              <InboxSocialConnect
                getStatus={this.callSocialProfileApi}
                // getStatus={this.getMentionsSocialStatus}
                getAuthUrl={this.getAuthUrl}
                isLoggedIn={socialStatusData}
                x_connected={x_connected}
                logout={this.mentionsSocialLogout}
                postCode={this.postCode}
                setupFbMsg={this.setupFbMsg}
                setupInstaMsg={this.setupInstaMsg}
              />
            </FormModal>
            <Collapse in={showFilter}>
              <FilterComponent
                onFilterApply={(params) => this.userFilterFunction(params)}
                pageList={pageList}
                history={this.props.history}
                match={this.props.match}
                branches={questionBranch}
                onReset={() => this.filterReset()}
              />
            </Collapse>
          </div>

          <Row className="pb-4">
            <Col sm="4" className="pr-0">
              {/* <Box
                sx={{
                  width: "50%",
                  backgroundColor: "#FAFAFB",
                  borderRadius: 20,
                  padding: 16,
                }}
              >
                <InboxCategory />
              </Box> */}
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#FAFAFB",
                  borderRadius: 20,
                  padding: 16,
                  paddingRight: 0,
                  position: "relative",
                }}
              >
                <div className="d-flex justify-content-between gap-20 flex-wrap">
                  <FPTab
                    tabLabels={[
                      `Open (${respondedList.length})`,
                      `Close (${unrespondedList.length})`,
                    ]}
                    minTabWidth={80}
                    defaultValue={0}
                    size="small"
                    // onChange={(value) =>
                    //   this.toggleTab(+value === 0 ? "dashboard" : '"Q&A"')
                    // }
                  />
                  <UserListActionBar
                    toggleFilter={() =>
                      this.setState({ showFilter: !this.state.showFilter })
                    }
                    showFilter={showFilter}
                    setSearchValue={this.handleSearchConversation}
                    // setSortOrder={this.handleSortCOnversation}
                  />
                </div>
                <p className="font-blue-gray m-0 mt-3">
                  {respondedList && Array.isArray(respondedList)
                    ? respondedList.length
                    : 0}{" "}
                  total conversations |{" "}
                  {unrespondedList && Array.isArray(unrespondedList)
                    ? unrespondedList.length
                    : 0}{" "}
                  unread
                </p>

                <Divider className="my-2" />
                {/* <Nav tabs>
                  <NavItem className="first-item">
                    <NavLink
                      className={classnames({ active: tabKey === "dashboard" })}
                      onClick={() => {
                        this.toggleTab("dashboard");
                      }}
                    >
                      Inbox
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: tabKey === "Q&A" })}
                      onClick={() => {
                        this.toggleTab("Q&A");
                      }}
                    >
                      Google Q&A
                    </NavLink>
                  </NavItem>
                </Nav> */}
                {/* <TabContent activeTab={tabKey}>
                  <TabPane tabId="dashboard"> */}
                <Box
                  sx={{
                    marginTop: "10px",
                    height: "70vh",
                    overflow: "auto",
                    scrollbarGutter: "stable",
                    marginRight: "4px",
                    paddingRight: "4px",
                  }}
                >
                  <InboxListComponent
                    expended={expended}
                    respondedList={respondedList}
                    unrespondedList={unrespondedList}
                    teamList={teamList}
                    userCallback={this.handleUserCallback}
                    showLoader={contactLoader}
                    userAssignCallBackFn={this.userAssignUpdateFn.bind(this)}
                    userAssignModalClose={userAssignModal}
                    userAssignError={userAssignError}
                    countArray={countArray}
                    activeUser={this.state.tabKey !== "Q&A" && activeUser}
                  />
                  {/* </TabPane> */}
                  {/* <TabPane tabId="Q&A"> */}
                  {/* {this.state.isFetching ? (
                    <div className="pd-2 msg-user-list">
                      <LoaderComponent loader={this.state.isFetching} />
                    </div>
                  ) : (
                    <QAComponent
                      setPageNum={this.setPageNum}
                      questionList={this.state.questionList}
                      isFetching={this.state.isFetching}
                      toggleQuestion={this.toggleQuestion}
                      businessId={this.state.businessId}
                      selectedQuestion={this.state.tabKey === "Q&A" && this.state.question}
                    />
                  )*/}
                </Box>
                {/* </TabPane>
                </TabContent> */}
              </Box>
            </Col>
            <Col sm="8" className="pr-0">
              <div className="right-sidebar-content">
                <React.Fragment>
                  <div className="Chat-wrapper">
                    {this.state.tabKey === "Q&A" ? (
                      <React.Fragment>
                        <Answer question={this.state.question} />
                        <QAreplyComponent
                          toggleReply={this.toggleReply}
                          question={this.state.question}
                        />
                      </React.Fragment>
                    ) : (
                      <>
                        <ActiveUserComponent
                          active_user={activeUser}
                          userDupdateCallBack={this.userDetailUpdate.bind(this)}
                          afterResponse={addUserDetailModal}
                          userLoader={userDetailUpdateLoader}
                          userUpdateError={userUpdateError}
                          unrespondedList={unrespondedList}
                        />
                        <ChatBoxComponent
                          messages={messengerList}
                          showLoader={chatBoxLoader}
                          totalPage={totalPage}
                          hideMsgLoader={this.hideMessengerLoader.bind(this)}
                        />
                        <ChatInputComponent
                          messsageCallback={this.chatMessage.bind(this)}
                          active_user={activeUser}
                        />
                      </>
                    )}
                  </div>
                </React.Fragment>
              </div>
            </Col>
          </Row>
        </Col>
        <ErrorModal
          showModal={errorModalShow}
          error={sendMessageError}
          modalCloseCallBack={this.errorModalCallback.bind(this)}
        />
      </Row>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(mentionsActions, dispatch),
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(MessengerPage);
