import { AutoComplete, Input, Select, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import { Button } from 'semantic-ui-react';
import axios_instance from '../../libs/interseptor';
import * as utils from '../../libs/utils';
import * as URLS from '../../libs/apiUrls';
import {  Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { displayMessage } from '../../libs/utils';
import { FPButton } from "../common/new";
import AddIcon from "@material-ui/icons/Add";
import { IoIosCloseCircleOutline, IoIosWarning } from "react-icons/io";
import FormModal from '../common/FormModal';

const CentralInsightsFame = ({ businessId }) => { 
  const [loading, setLoading] = useState(false);
  const [mainData, setMainData ] = useState(null);
  const [newEditTagModal, setNewEditTagModal] = useState(false);
  const [deleteTagModal, setDeleteTagModal] = useState(false);
  const [editTagData, setEditTagData] = useState(null);
  const [deleteTagData, setDeleteTagData ] = useState(null);
  const [categoryGroup , setCategoryGroup] = useState('');
  const [category, setCategory] = useState('');
  const [categoryGroupOptions, setCategoryGroupOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isAddModal, setIsAddModal] = useState(false);
  const [groupModal, setGroupModal] = useState(false);
  const [groupValue, setGroupValue] = useState('');
  const [isGroupDelete, setIsGroupDelete] = useState(false);
 
  useEffect(() => {
    setCategoryOptions([]);
    setCategoryGroupOptions([]);
    handleMainApiCall();
    getCategoryGroupList();
  },[businessId]); //eslint-disable-line

  const handleMainApiCall = () => {
    setLoading(true);
    console.log(businessId);
    axios_instance.get(`${utils.format(URLS.FAME_AI_CATEGORY_LIST, [businessId])}categories/`)
      .then((res) => {
        setMainData(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const getCategoryGroupList = () => {
    axios_instance.get(`${utils.format(URLS.FAME_AI_CATEGORY_LIST, [businessId])}`)
      .then((res) => {
        const list = [];
        res.data.tag_category_groups.map((value)=> {
          list.push({ id: value.id, value: value.name , label : value.name });
          return value;
        });
        setCategoryGroupOptions(list);
      }).catch((err) => console.log(err));
  };

  const handleAddCategory = () => {
    console.log(categoryGroup);
    let id_group = categoryGroupOptions.find((value) => value.value === categoryGroup);
    console.log("ID Group : ", id_group);
    
    let payload = {};
    payload = {
      'category_group' : categoryGroup && categoryGroup.trim(),
      'category' : category && category.trim()
    }

    axios_instance.post(`${utils.format(URLS.FAME_AI_CATEGORY_LIST, [businessId])}`, payload)
      .then((res) => {
        console.log(res);
        displayMessage('positive', 'Created Successfully!');
        setNewEditTagModal(false);
        handleMainApiCall();
      }).catch((err) => {
        console.log(err.response);
      });
  };

  const handleDelete = () => {
    const id = deleteTagData.category_id ? deleteTagData.category_id : deleteTagData.group_id;
    axios_instance.delete(`${utils.format(URLS.FAME_AI_CATEGORY_LIST, [businessId])}${id}/`)
      .then((res) => {
        console.log(res);
        displayMessage('positive', 'Tag deleted Successfully !');
        handleMainApiCall();
        setDeleteTagModal(false);
      }).catch((err) => {
        console.log(err);
        // displayMessage('negative', 'Error !');
      });
  };

  const handleEdit = () => {
    let group_val = categoryGroupOptions.find((value) => value.value === categoryGroup);
    let payload = {};
    payload = {
      "category_group" : {
        id: group_val.id,
        name : categoryGroup && categoryGroup.trim() 
      },
      "category" : {
        id : editTagData.category_id,
        name : category && category.trim()
      }
    }

    axios_instance.put(`${utils.format(URLS.FAME_AI_CATEGORY_LIST, [businessId])}modify/` , payload)
      .then((res) => {
        console.log(res);
        displayMessage('positive', 'Tag Edited Successfully !');
        handleMainApiCall();
        setNewEditTagModal(false);
      }).catch((err) => {
        console.log(err.response);
      });
  };

  const getColumns = () => {
    const baseColumns = [
      { title: 'Category Group', dataIndex: 'group_name',  sorter: { compare: (a, b) => a.group_name ? a.group_name.localeCompare(b.group_name) : 0, multiple: 3 }, width: '40%' },
      { title: 'Category', dataIndex: 'category_name',  sorter: { compare: (a, b) => a.category_name ? a.category_name.localeCompare(b.category_name) : 0, multiple: 3 }, width: '40%'  },
      { title: 'Edit / Delete', 
        dataIndex: 'Edit / Delete',  
        render: (_, data ) => {
          return <span key={data.category_id} style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}>
            <Tag color='geekblue' onClick={() => {
              setIsAddModal(false);
              setEditTagData(data);
              setCategoryGroup(data.group_name ? data.group_name : '');
              setCategory(data.category_name ? data.category_name : '');
              setNewEditTagModal(true);
            }}>Edit</Tag>
            <Tag color='volcano' onClick={() => {
              setDeleteTagData(data);
              setDeleteTagModal(true);
              setIsGroupDelete(false);
            }}>Delete</Tag>
          </span>;
        },
        width: '20%' 
      }
    ];
    return baseColumns;
  };

  const onChange = (filters, sorter, extra) => {
    console.log('params', filters, sorter, extra);
  };

  const handleAddGroup = () => {
    const payload ={ group_name : groupValue }

    axios_instance.post(`${utils.format(URLS.FAME_AI_CATEGORY_LIST, [businessId])}create_group/`, payload)
      .then((res) => {
        displayMessage("postive", "Suuccess");
        setGroupValue("");
        setGroupModal(false);
        getCategoryGroupList();
    }).catch((err) => {
      console.log(err);
    })
  }

  const handleGroupDelete = () => {
    axios_instance.delete(`${utils.format(URLS.FAME_AI_CATEGORY_LIST, [businessId])}${deleteTagData.id}/destroy_group/`)
      .then((res) => {
        setIsGroupDelete(false);
        setDeleteTagModal(false);
        setDeleteTagData(null);
        displayMessage('positive', 'Successfully Deleted !');
        getCategoryGroupList();
        handleMainApiCall();
      }).catch((err) => {
        setIsGroupDelete(false);
        setDeleteTagModal(false);
        console.log(err);
        if(err.response.status === 400) {
          displayMessage('negative', err.response.data.error);
        } else {
          displayMessage('negative', 'Error !')
        }
      });
  };

  return <div >
    <div className="d-flex ml-4 justify-content-between align-items-center mb-4 mt-4">
      <span style={{ fontSize: "18px", fontWeight: "400", color: "rgba(100, 101, 101, 1)" }} >
        Setup FameAI Categories
      </span>
      <div style={{ display: 'flex', gap: '20px'}}>
        <FPButton
          label="Add Group"
          type="button"
          onClick={() => setGroupModal(true) }
          style={{ height: "30px" }}
          endIcon={ <AddIcon style={{ height: 16, width: 16, color: "white" }} /> }
        />
        <FPButton
          label="Add Category"
          type="button"
          onClick={() => {
            setCategory('');
            setCategoryGroup(null);
            setNewEditTagModal(true);
            setIsAddModal(true);
          }}
          style={{ height: "30px" }}
          endIcon={ <AddIcon style={{ height: 16, width: 16, color: "white" }} /> }
        />
      </div>
    </div>
    <Card body className='mt-2 mb-4'>
      <div style={{ marginLeft: '4px' }}>All Category Group  </div>
      {categoryGroupOptions && categoryGroupOptions.length > 0 && <hr style={{ margin: '4px' }}/> }
      <div style={{ display: 'flex', gap: '6px', flexWrap: 'wrap' }}>
        {categoryGroupOptions && categoryGroupOptions.map((value, key) => {
          return (<div className="category-word" style={{ display: 'flex', alignItems: 'center', gap: '5px'}} key={key}>
              <span>{value.label}</span>
              <IoIosCloseCircleOutline 
                onClick={() =>  {
                  setIsGroupDelete(true);
                  setDeleteTagData(value);
                  setDeleteTagModal(true);
                }} 
                style={{color : '#F13A88', cursor: 'pointer' }}/>
            </div>);
        })}
      </div>
    </Card>
    <Card body className='my-2'>
      <Table
        loading={loading}
        columns={getColumns()}
        dataSource={mainData}
        onChange={onChange}
        pagination={{ 
          pageSizeOptions: [5, 10, 20, 50, 100],
          showSizeChanger: true,
        }}
        rowKey={(record) =>  record.category_id}
        bordered
      />
    </Card>

    <Modal size='lg' centered contentClassName='custom-modal-style-tag' isOpen={newEditTagModal} >
      <ModalHeader toggle={() =>  setNewEditTagModal(false) }>
      {isAddModal ? 'Add FameAI Tag' : 'Edit FameAI Tag' }
      </ModalHeader>
      <ModalBody>
        <div className='mx-4 my-2' style={{ display: 'flex', justifyContent: 'space-evenly', gap:'20px' }}>

          <Select
            style={{ width: '100%' }}
            placeholder="Select Category Group"
            options={categoryGroupOptions}
            value={categoryGroup}
            onChange={(value) => setCategoryGroup(value)}
          />

          <AutoComplete 
            style={{ width: '100%' }}
            options={categoryOptions.filter(option => option.label.toLowerCase().includes(category.toLowerCase()) )}
            placeholder="Add Group Category"
            onSearch={(value) => setCategory(value)}
            onChange={(value) => setCategory(value)}
            value={category}
          />

        </div>

      </ModalBody>
      <ModalFooter>
        {isAddModal ? 
          <Button 
            color='teal' 
            onClick={() => handleAddCategory()}
            disabled ={!categoryGroup ||  category.trim().length <=0 }
          > Add </Button>
          :<Button color='teal' onClick={() => handleEdit()}> Edit </Button>}

      </ModalFooter>
    </Modal>

    <FormModal
      isOpen={deleteTagModal}
      toggle={() => setDeleteTagModal(false)}
      heading={isGroupDelete ? 'Delete Group' : 'Delete Tag'}
      width="600px"
      contentClass="mb-0"
      bodyClass="mb-0"
    >
      <div style={{textAlign: 'center'}}>
        {!isGroupDelete && <h5 className='mb-4' style={{color: 'red', fontSize: '18px' }}> 
          Do you want to delete FameAI tag ?
        </h5>}
        
        {isGroupDelete && <h5 className='mb-4' style={{color: 'red'}}>
          <div>
            <IoIosWarning style={{fontSize: '52px'}} />
          </div>
          <span className='mx-4' style={{ fontSize: '18px' }}> Deleting this will delete all tags related to this group ! </span> 
        </h5>}
        <div className='mb-6' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '25px'}}> 
          {isGroupDelete  
            ? <Button color='red' onClick={() => handleGroupDelete()}>Delete</Button>
            : <Button color='red' onClick={() => handleDelete()}>Delete</Button>}
          <Button onClick={() => setDeleteTagModal(false)}>Cancel</Button>
        </div>
      </div>
    </FormModal>

    <FormModal
      isOpen={groupModal}
      toggle={() => setGroupModal(false)}
      heading={'Add Group'}
      width="600px"
      contentClass="mb-0"
      bodyClass="mb-0"
    >
      <div style={{width: '100%'}}>
          <Input 
            placeholder='Add Group' 
            style={{width: '100%'}} 
            value={groupValue}  
            onChange={(event) => setGroupValue(event.target.value)} 
          />
          <div style={{ marginTop: '15px', marginLeft: '4px' }}> Existed Group : </div>
          {categoryGroupOptions && categoryGroupOptions.length > 0 && <hr style={{ margin: '4px' }}/> }
          <div>
            {categoryGroupOptions && categoryGroupOptions.map((value, key) => {
              return (<div className="category-word" key={key}>
                  {value.label}
                </div>);
            })}
          </div>
        </div>
      <div className='my-4' style={{ display: 'flex', justifyContent: 'flex-end'}}>
        <Button 
          color='teal' 
          onClick={() => handleAddGroup()}
          disabled= {!groupValue || groupValue.trim().length <=0 }
        > Add </Button>
      </div>
    </FormModal> 
  </div>;
};

export default CentralInsightsFame;