import React, { useState } from "react";
import { Checkbox, Radio } from "semantic-ui-react";
import "./listing.css";
import AddIcon from "@material-ui/icons/Add";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import delete_png from "../../../images/delete_png.webp";
import deleteWhiteSvg from "../../../images/svg/deleteWhiteSvg.svg";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import * as listingUtils from "../../../libs/listingToast";

const SpecialHour = (props) => {
  const { primaryDetail, business_id, selectReso, actions } = props;
  console.log("specialHoursspecialHours", primaryDetail?.specialHours?.specialHourPeriods);
  const [checked, setChecked] = useState(true);
  const [startDate, setStartDate] = useState({});
  const [locationInputs, setLocationInputs] = useState([{ openTime: null, closeTime: null }]);
  const [specialHoursList, setSepicalHoursList] = useState([]);

  React.useEffect(() => {
    if (primaryDetail?.specialHours?.specialHourPeriods?.length > 0) {
      const data = primaryDetail?.specialHours?.specialHourPeriods;
      const transformedData = data.reduce((acc, item) => {
        const dateKey = dayjs(new Date(item.startDate.year, item.startDate.month - 1, item.startDate.day)).format(
          "YYYY-MM-DD"
        );
        if (!acc[dateKey]) {
          acc[dateKey] = {
            date: dateKey,
            checked: true,
            time: [],
          };
        }
        acc[dateKey].time.push({
          openTime: item.openTime,
          closeTime: item.closeTime,
        });
        return acc;
      }, {});
      console.log(transformedData, "transformedData");
      setSepicalHoursList(Object.values(transformedData));
    }
  }, [primaryDetail?.specialHours?.specialHourPeriods]);

  console.log(specialHoursList, "specialHoursList");
  const handleSwitch = (event, dateData, index) => {
    const { checked } = event.target;
    console.log(checked, "hhhhhhhhhhh");
    console.log(dateData, "dateData");
    event.preventDefault();
    const newData = [...specialHoursList];
    let dateIndex = newData.findIndex((date) => date?.date === dateData?.date);
    if (index !== dateIndex) {
      dateIndex = index;
    }
    // // if (dateIndex === -1) return;
    newData[dateIndex].checked = !newData[dateIndex].checked;
    setSepicalHoursList(newData);
    setChecked(!value);
  };

  const handleTimeChange = (pindex, index, type, newValue, selectedDate) => {
    const newData = [...specialHoursList];
    let dateIndex = newData.findIndex((data) => data?.date === selectedDate.date);
    if (dateIndex === -1) return;
    if (pindex !== dateIndex) {
      dateIndex = pindex;
    }
    newData[dateIndex].time[index][type] = {
      hours: dayjs(newValue).hour(),
      minutes: dayjs(newValue).minute(),
    };
    setSepicalHoursList(newData);

    // const updatedInputs = [...locationInputs];
    // updatedInputs[index][type] = newValue;
    // setLocationInputs(updatedInputs);
  };

  const addTimeSlotHandler = (date) => {
    console.log(date, "4444444");
    const newData = [...specialHoursList];
    const dateIndex = newData.findIndex((data) => data?.date === date.date);
    console.log(dateIndex, "777777777777777777");
    if (dateIndex === -1) return;
    newData[dateIndex]["time"].push({
      openTime: {
        hours: "10",
        minutes: 0,
      },
      closeTime: {
        hours: "19",
        minutes: 0,
      },
    });
    setSepicalHoursList(newData);
  };
  const deleteTimeSlotHandler = (date, index) => {
    console.log(date, "4444444");
    const newData = [...specialHoursList];
    const dateIndex = newData.findIndex((data) => data?.date === date.date);
    console.log(dateIndex, "777777777777777777");
    if (dateIndex === -1) return;
    newData[dateIndex]["time"]?.splice(index, 1);
    setSepicalHoursList(newData);
  };
  const deleteDateSlotHandler = (date, index) => {
    console.log(date, "555");
    const newData = [...specialHoursList];
    const dateIndex = newData.findIndex((data) => data?.date === date.date);
    console.log(dateIndex, "8888");
    if (dateIndex === -1) return;
    newData?.splice(dateIndex, 1);
    setSepicalHoursList(newData);
  };

  const dateChangeHandler = (newValue, date, pindex) => {
    const newData = [...specialHoursList];
    let dateIndex = newData.findIndex((data) => data?.date === date.date);
    if (dateIndex === -1) return;
    if (pindex !== dateIndex) {
      dateIndex = pindex;
    }
    newData[dateIndex].date = dayjs(newValue).format("YYYY-MM-DD");
    setSepicalHoursList(newData);
  };

  console.log("locationInputslocationInputslocationInputs", locationInputs);
  console.log("specialHoursListspecialHoursListspecialHoursList", specialHoursList);
  const businessMoreSpicaialDateHandler = () => {
    let newDate = dayjs(new Date()).format("YYYY-MM-DD");
    const isDateExist = specialHoursList.some((date) => date.date === newDate);
    if (isDateExist) {
      // one day increment
      newDate = dayjs(new Date()).add(1, "day").format("YYYY-MM-DD");
    }
    setSepicalHoursList([
      ...specialHoursList,
      {
        date: newDate,
        checked: true,
        time: [
          {
            openTime: {
              hours: "10",
              minutes: 0,
            },
            closeTime: {
              hours: "19",
              minutes: 0,
            },
          },
        ],
      },
    ]);
  };

  function convertHours(input) {
    console.log(specialHoursList, "input");
    const result = {
      specialHour: {
        specialHourPeriods: [],
      },
      type: "specialHour",
    };

    input.forEach((dayEntry) => {
      if (dayEntry.checked) {
        const year = dayjs(dayEntry?.date).year();
        const month = dayjs(dayEntry?.date).month() + 1;
        const date = dayjs(dayEntry?.date).date();
        dayEntry.time.forEach((timeEntry) => {
          const period = {
            startDate: {
              year: year,
              month: month,
              day: date,
            },
            endDate: {
              year: year,
              month: month,
              day: date,
            },
            openTime: timeEntry.openTime || {},
            closeTime: timeEntry.closeTime || {},
          };
          result.specialHour.specialHourPeriods.push(period);
        });
      }
    });

    return result;
  }

  const updateSpecialDateHandler = async () => {
    const restodetail = { business_id, selectReso };

    const updatedCode = convertHours(specialHoursList);
    console.log(updatedCode, "updatedCode");
    await actions.getGmbListingUpdateListing(updatedCode, restodetail);
    listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    await actions.getGmbListingRefreshListing(business_id, selectReso);

    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");

    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };
  const [value, setValue] = useState(dayjs("2022-04-17"));

  return (
    <>
      {specialHoursList?.length > 0 &&
        specialHoursList?.map((startDate, pindex) => {
          return (
            <div
              style={{
                border: "1px solid",
                borderRadius: "5px",
                borderColor: "#BFC2CB",
                padding: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                  padding: "10px",
                  gap: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  <Radio
                    toggle
                    checked={startDate?.checked}
                    value={startDate?.checked}
                    onChange={(event) => handleSwitch(event, startDate, pindex)}
                  />
                  {/* <Checkbox
                    toggle
                    checked={startDate?.checked}
                    className="toggle-btn"
                    onChange={(event) => handleSwitch(event, startDate, pindex)}
                  /> */}
                  <div style={{ width: "" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        value={dayjs(startDate.date)}
                        onChange={(newValue) => dateChangeHandler(newValue, startDate, pindex)}
                        minDate={dayjs(new Date())} // Set minimum date to current date
                        format="DD/MM/YYYY"
                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            width: "100%",
                          },
                        }}
                        slotProps={{ textField: { size: "small" } }}
                        disabled={!startDate?.checked}
                      />
                    </LocalizationProvider>
                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                          <DatePicker label="Uncontrolled picker" defaultValue={dayjs('2022-04-17')} />
                          <DatePicker
                            label="Controlled picker"
                            minDate={dayjs(new Date())}
                            value={value}
                            onChange={(newValue) => setValue(newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider> */}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }}
                >
                  {startDate?.time?.map((openDay_values, index, arr) => {
                    const openTime = dayjs()
                      .hour(openDay_values?.openTime?.hours ? openDay_values?.openTime?.hours : "12")
                      .minute(openDay_values?.openTime?.minutes ? openDay_values?.openTime?.minutes : "00");
                    const closeTime = dayjs()
                      .hour(openDay_values?.closeTime?.hours ? openDay_values?.closeTime?.hours : "12")
                      .minute(openDay_values?.closeTime?.minutes ? openDay_values?.closeTime?.minutes : "00");
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div style={{ width: "100px" }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker
                              ampm={false}
                              value={openTime}
                              onChange={(newValue) => handleTimeChange(pindex, index, "openTime", newValue, startDate)}
                              openTo="hours"
                              views={["hours", "minutes"]}
                              format="HH:mm"
                              slotProps={{ textField: { size: "small" } }}
                              disabled={!startDate?.checked}
                            />
                          </LocalizationProvider>
                        </div>
                        <div>-</div>
                        {/* <div key={index} style={{ display: "flex", alignItems: "center", gap: "10px", }}> */}
                        <div style={{ width: "100px" }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker
                              ampm={false}
                              value={closeTime}
                              onChange={(newValue) => handleTimeChange(pindex, index, "closeTime", newValue, startDate)}
                              openTo="hours"
                              views={["hours", "minutes"]}
                              format="HH:mm"
                              slotProps={{ textField: { size: "small" } }}
                              disabled={!startDate?.checked}
                            />
                          </LocalizationProvider>
                        </div>
                        {index !== startDate?.time?.length - 1 ? (
                          <img
                            src={delete_png}
                            alt="deletePng"
                            style={{
                              height: "16px",
                              width: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() => deleteTimeSlotHandler(startDate, index)}
                          />
                        ) : (
                          startDate?.checked && (
                            <AddIcon
                              style={{
                                color: "#667085",
                                fontSize: "18px",
                                cursor: "pointer",
                              }}
                              onClick={() => addTimeSlotHandler(startDate)}
                            />
                          )
                        )}
                      </div>
                      // </div>
                    );
                  })}
                </div>

                {/* : (
            <>
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                <Checkbox
                  toggle
                  checked={checked}
                  className="toggle-btn"
                  onChange={(event) => handleSwitch(event, checked)}
                />
                <div style={{ width: "" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      value={dayjs(startDate?.date)}
                      onChange={(newValue) => setStartDate(newValue)}
                      maxDate={dayjs(new Date())}
                      format="DD/MM/YYYY"
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": { width: "100%" },
                      }}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </LocalizationProvider>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                {startDate?.time?.map((openDay_values, index, arr) => {
                  const openTime = dayjs()
                    .hour(
                      openDay_values?.openTime?.hours
                        ? openDay_values?.openTime?.hours
                        : "12"
                    )
                    .minute(
                      openDay_values?.openTime?.minutes
                        ? openDay_values?.openTime?.minutes
                        : "00"
                    );
                  const closeTime = dayjs()
                    .hour(
                      openDay_values?.closeTime?.hours
                        ? openDay_values?.closeTime?.hours
                        : "12"
                    )
                    .minute(
                      openDay_values?.closeTime?.minutes
                        ? openDay_values?.closeTime?.minutes
                        : "00"
                    );
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div style={{ width: "100px" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <MobileTimePicker
                            ampm={false}
                            value={openTime}
                            onChange={(newValue) =>
                              handleTimeChange(index, "openTime", newValue)
                            }
                            openTo="hours"
                            views={["hours", "minutes"]}
                            format="HH:mm"
                            slotProps={{ textField: { size: "small" } }}
                          />
                        </LocalizationProvider>
                      </div>
                      <div>-</div>
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <div style={{ width: "100px" }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileTimePicker
                              ampm={false}
                              value={closeTime}
                              onChange={(newValue) =>
                                handleTimeChange(index, "closeTime", newValue)
                              }
                              openTo="hours"
                              views={["hours", "minutes"]}
                              format="HH:mm"
                              slotProps={{ textField: { size: "small" } }}
                            />
                          </LocalizationProvider>
                        </div>
                        {index !== startDate?.time?.length - 1 ? (
                          <img
                            src={delete_png}
                            alt="deletePng"
                            style={{
                              height: "16px",
                              width: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              deleteTimeSlotHandler(startDate, index)
                            }
                          />
                        ) : (
                          <AddIcon
                            style={{
                              color: "#667085",
                              fontSize: "18px",
                              cursor: "pointer",
                            }}
                            onClick={() => addTimeSlotHandler(startDate)}
                          />
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )} */}
              </div>
              <div style={{ display: "flex", justifyContent: "end" }}>
                {/* {pindex !== specialHoursList?.length - 1 && ( */}
                {specialHoursList?.length && (
                  <FPButton size="small" onClick={() => deleteDateSlotHandler(startDate, pindex)}>
                    <img
                      src={deleteWhiteSvg}
                      alt="delete"
                      style={{
                        height: "14px",
                        width: "14px",
                        marginRight: "3px",
                      }}
                    />
                    Delete
                  </FPButton>
                )}
              </div>
            </div>
          );
        })}
      <div className="d-flex justify-content-end" style={{ gap: "6px" }}>
        <FPButton label="Add More" size="small" onClick={businessMoreSpicaialDateHandler} />
        <FPButton label="Update" size="small" disabled={primaryDetail?.is_suspend} onClick={updateSpecialDateHandler} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // attribute_data: state.gmbListingLocation.attribute_data,
    // attribute_error: state.gmbListingLocation.attribute_error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // getGmbListingUpdateListing: bindActionCreators(
  //   getGmbListingUpdateListing,
  //   dispatch
  // ),
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SpecialHour);
