import * as types from '../../actions/actionTypes';

export default function mentionsReducer(state = [], action) {
  switch (action.type) {
    case types.ADD_KEYWORD_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.ADD_KEYWORD_FAILURE:
      return Object.assign({}, state, { error: { ...action.data.errors } });
    case types.GET_MENTIONS_DATA_SUCCESS:
      return Object.assign({}, state, { mentionsData: { ...action.data } });
    case types.GET_MENTIONS_DATA_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GET_MENTIONS_KEYWORDS_SUCCESS:
      return Object.assign({}, state, { keywordsList: { ...action.data } });
    case types.GET_MENTIONS_KEYWORDS_FAILURE:
      return Object.assign({}, state, action.data);
    case types.DELETE_MENTION_REVIEW_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.DELETE_MENTION_REVIEW_FAILURE:
      return Object.assign({}, state, { detail: {} });
    case types.GET_MENTIONS_PROVIDERS_SUCCESS:
      return Object.assign({}, state, { mention_providers: { ...action.data } });
    case types.GET_MENTIONS_PROVIDERS_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GET_STATUS_SUCCESS:
      return Object.assign({},state,{ status: { ...action.data }});
    case types.MENTIONS_SOCIAL_STATUS_SUCCESS:
      return Object.assign({},state,{ isLoggedIn: { ...action.data }});
    case types.MENTIONS_SOCIAL_CONNECT_SUCCESS:
      return Object.assign({}, state, { isLoggedIn: { ...state.isLoggedIn, ...action.data }});
    case types.GET_MENTION_REPORT_SUCCESS: {
      const dataKeys = Object.keys(action.data).length ? Object.keys(action.data) : null;
      const mentions = Object.keys(action.data).length ? Object.keys(action.data).map((item) => (+(action.data)[item]['mention'])): null;
      const reach = Object.keys(action.data).length ? Object.keys(action.data).map((item) => (+(action.data[item]['social_reach']))): null;
      const mentionsData = mentions ? mentions.map((i) => +(i.toFixed(2))) : null;
      const socialReach = reach ? reach.map((i) => +(i.toFixed(2))) : null;
      return Object.assign({}, state, { dataKeys, mentions: mentionsData, reachData: socialReach });
    }
    case types.GET_MENTION_REPORT_FAILURE:
      return Object.assign({}, state, action.data);
    default:
      return state;
  }
}