import React, { useState } from 'react';
import axios_instance from '../../libs/interseptor';
import * as URLS from '../../libs/apiUrls';
import cookie from 'react-cookies';
import FormModal from '../common/FormModal';
import FPInput from '../common/new/forms/ui/FPInput';
import { FPButton } from '../common/new';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import PersonIcon from '@material-ui/icons/Person';

const AddReviewDetailsModal = ({
  isOpen,
  toggle,
  Name,
  Email,
  Order,
  Phone,
  review_id,
  businessId,
  heading
}) => {
  const iconStyle = {
    width: '18px',
    height: '18px',
    color: '#F13A88',
    marginRight: '3px'
  };
  const [name, setName] = useState(Name ? Name : '');
  const [email, setEmail] = useState(Email ? Email : '');
  const [phone, setPhone] = useState(Phone ? Phone : '');
  const [order_id, setOrder] = useState(Order ? Order : '');
  const service_category = cookie.load('service_category');

  const handleSend = async () => {
    console.log({ name, email, phone, order_id });
    const url = `${URLS.REVIEWS_DETAILS}${businessId}/review/${review_id}/`;
    const obj = { name: name, email: email, phone: phone, order_id: order_id };
    const res = await axios_instance.put(url, obj);
    const results = res.data;
    console.log(results);
    setName('');
    setEmail('');
    setPhone('');
    setOrder('');
    toggle();
  };

  return (
    <FormModal
      isOpen={isOpen}
      toggle={toggle}
      alignment="top"
      heading= {heading}
      bod
      width={'400px'}
      showDivider={true}
      bodyClass={'pb-0'}
      maxWidth='sm'
      rightToLeft = {false}
      dialogActions={
        <div>
          <FPButton
            onClick={toggle}
            label="Close"
            variant="outline"
            size="small"
            className='mr-2'
          />
          <FPButton size="small" onClick={handleSend} label="Submit" />
        </div>
      }
    >
      <div className='d-flex flex-column gap-10 '>
        <FPInput
          startIcon={<PersonIcon style={iconStyle} />}
          placeholder="Name"
          style={{ fontSize: '12px' }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <FPInput
          startIcon={<EmailOutlinedIcon style={iconStyle} />}
          style={{ fontSize: '12px' }}
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <FPInput
          startIcon={<PhoneOutlinedIcon style={iconStyle} />}
          placeholder="Phone"
          style={{ fontSize: '12px' }}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
        <FPInput
          startIcon={<AssignmentOutlinedIcon style={iconStyle} />}
          style={{ fontSize: '12px' }}
          placeholder={
            service_category === 'Restaurant'
              ? 'Order Id'
              : service_category === 'hotel'
                ? 'Reservation Id'
                : service_category === 'hospital'
                  ? 'Appointment Id'
                  : ''
          }
          value={order_id}
          onChange={(e) => setOrder(e.target.value)}
        />
      </div>
    </FormModal>
  );
};

export default AddReviewDetailsModal;
