import React from "react";
import { FPToolTip } from "../common/new";
import downloadIcon from "../../images/downloadReport.png";
import printIcon from "../../images/Printer.png";
import { IconButton } from "@material-ui/core";

function RightIcon({ downloadReport, disableDownload }) {
  return (
    <div
      className="d-flex justify-content-end mt-3"
      style={{ height: "100%", borderRadius: "2px" }}
    >
      <FPToolTip title={"Download Review"}>
        <IconButton
          onClick={downloadReport}
          disabled={disableDownload}
          size="small"
        >
          <img src={downloadIcon} alt="download" />
        </IconButton>
      </FPToolTip>
      <FPToolTip title={"Print Review"}>
        <IconButton size="small" className="ml-2" disabled={disableDownload} onClick={() => window.print()}>
          <img src={printIcon} alt="print" />
        </IconButton>
      </FPToolTip>
    </div>
  );
}

export default RightIcon;
