import React, { useState, useEffect } from "react";
import * as URLS from "../../libs/apiUrls";
import { displayMessage } from "../../libs/utils";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import queryString from "query-string";
import FormModal from "../common/FormModal";
import TagIcon from "../../images/svg/Tag.svg";
import { Col, Row } from "reactstrap";
import FPLabel from "../common/new/forms/ui/FPLabel";
import AutoSelect from "../common/AutoSelect";
import { FPButton } from "../common/new";
import { CSSTransitionGroup } from "react-transition-group";
import FPChip from "../common/new/FPChip/FPChip";
import AddAttributeModal from "./AddAttributeModal";
import LoaderComponent from "../messenger/Common/LoaderComponent";

const ProductTagModal = (props) => {
  const {
    open,
    toggle,
    getData,
    business_id,
    selectedTag,
    location,
    reviewId,
    tagOptions,
  } = props;
  const [attributeOptions, setAttributeOptions] = useState([]);
  const [aspectValue, setAspectValue] = useState("");
  const [attributeValue, setAttributeValue] = useState("");
  const [sentimentValue, setSentimentValue] = useState(
    selectedTag?.sentiment ? selectedTag?.sentiment : ""
  );
  const [ratingValue, setRatingValue] = useState("");
  const [addAttributeModal, setAddAttributeModal] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setAspectValue(selectedTag?.product_name ? selectedTag?.product_name : "");
    setAttributeValue(
      selectedTag?.associated_opinion ? selectedTag?.associated_opinion : ""
    );
    setRatingValue(
      selectedTag?.product_rating ? String(selectedTag?.product_rating) : ""
    );
  }, [selectedTag]);

  const sentimentOptions = [
    { value: "positive", display_name: "Positive" },
    { value: "neutral", display_name: "Neutral" },
    { value: "negative", display_name: "Negative" },
  ];

  const ratingOptions = [
    { value: "1", display_name: "1 Star" },
    { value: "2", display_name: "2 Star" },
    { value: "3", display_name: "3 Star" },
    { value: "4", display_name: "4 Star" },
    { value: "5", display_name: "5 Star" },
  ];

  useEffect(() => {
    setLoader(true);
    setAttributeOptions([]);
    axios_instance
      .get(
        `${utils.format(URLS.MENU_ATTRIBUTE, [business_id])}/?product_name=${
          selectedTag.product_name ? selectedTag.product_name : ""
        }`
      )
      .then((res) => {
        setLoader(false);
        let list_attribute = [];
        res.data.results.map((val) => {
          val.attribute_name &&
            list_attribute.push({
              id: val.id,
              value: val.attribute_name || "",
              display_name: val.attribute_name || "",
              sentiment: val.sentiment || "",
              is_ai_generated: val.is_ai_generated,
              product_name: val.product_name || "",
            });
        });

        let UniqueAttributeList = [];
        let uniqueAttributeObj = {};

        for (let i in list_attribute) {
          let attributeValue = list_attribute[i]["value"];
          uniqueAttributeObj[attributeValue] = list_attribute[i];
        }

        for (let i in uniqueAttributeObj) {
          UniqueAttributeList.push(uniqueAttributeObj[i]);
        }

        setAttributeOptions([...UniqueAttributeList]);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  }, [selectedTag]);

  const handleAttributeSearch = (value) => {
    setAttributeValue(value);
  };

  const handleSentimentSearch = (value) => {
    setSentimentValue(value);
  };

  const handleProduct = () => {
    const obj = {
      product_reviews_id: selectedTag.id,
      associated_opinion: attributeValue ? attributeValue : "",
    };
    axios_instance
      .patch(`${utils.format(URLS.GET_PRODUCT_TAG, [business_id])}`, obj)
      .then((res) => {
        console.log(res);
        getData({
          ...queryString.parse(location.search),
          businessId: business_id,
        });
        displayMessage("positive", "Product Tag Edited Successfully");
        toggle();
      })
      .catch((err) => {
        console.log(err);
        displayMessage("negative", "Product Tag Edit Failed");
      });
  };

  return (
    <>
      <FormModal
        isOpen={open}
        showDivider={false}
        toggle={toggle}
        heading={
          <>
            <span style={{ fontSize: "20px !important" }} className="mr-2">
              Product Tag
            </span>
            <span style={{ color: "gray", fontSize: "20px !important" }}>
              ({aspectValue})
            </span>
          </>
        }
        width="700px"
      >
        <div
          className="top-tag-view"
          style={{
            borderWidth: "1.5px",
            borderColor: "#BFC2CB",
          }}
        >
          <div className="d-flex align-items-center gap-6">
            <img src={TagIcon} />
            <span style={{ fontSize: "14px", color: "#1B1C1D" }}>Tag</span>
          </div>

          <span style={{ color: "#1B1C1D", fontWeight: "400" }}>
            {aspectValue && aspectValue.trim().length > 0
              ? aspectValue
              : "Product Name"}
            &nbsp;-{" "}
            {attributeValue && attributeValue.trim().length > 0
              ? attributeValue
              : "Attribute"}
            &nbsp;-{" "}
            {ratingValue && String(ratingValue).trim().length > 0
              ? ratingValue
              : "Product Rating"}
          </span>
        </div>
        <Row>
          <Col sm="6" className="mt-20">
            <FPLabel isRequired={false}>Attribute</FPLabel>
            <AutoSelect
              placeholder="Attribute Name"
              options={attributeOptions}
              onChange={(event, option) => {
                handleAttributeSearch(option);
              }}
              value={attributeValue}
            />
          </Col>
          <Col sm="6" className="mt-20">
            <FPLabel isRequired={false}>Sentiment</FPLabel>
            <AutoSelect
              placeholder="Sentiment"
              options={sentimentOptions}
              onChange={(event, option) => {
                handleSentimentSearch(option);
              }}
              value={sentimentValue}
            />
          </Col>
        </Row>

        <div className="mt-15">
          <span
            style={{
              fontSize: "14px",
              color: "#1B1C1D",
              fontWeight: "500",
              marginBottom: "10px",
            }}
          >
            Attributes
          </span>
          {loader ? (
            <LoaderComponent showLoader={loader} />
          ) : (
            <CSSTransitionGroup
              transitionName="reviews-tags"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
              className="d-flex flex-wrap gap-10 align-items-center mt-10"
            >
              {attributeOptions.map((tag, index) => {
                return (
                  <FPChip
                    key={`${index}-attributes-tags`}
                    size="small"
                    variant="outlined"
                    style={{
                      borderColor: "#BFC2CB",
                      borderWidth: "2px",
                      backgroundColor:
                        attributeValue === tag.value
                          ? "#E1E1E1"
                          : "transparent",
                    }}
                    label={
                      <span
                        className="cursor-pointer"
                        onClick={() =>
                          handleAttributeSearch(tag.value ? tag.value : "")
                        }
                      >
                        {utils.snakeToCapital(tag.display_name)}
                      </span>
                    }
                  />
                );
              })}
            </CSSTransitionGroup>
          )}
        </div>
        <div className="d-flex justify-content-between mt-20">
          <FPButton
            label="Add Attribute"
            backgroundColor="#00B5AD"
            onClick={() => setAddAttributeModal(true)}
          />
          <FPButton label="Submit" onClick={handleProduct} />
        </div>
      </FormModal>
      {addAttributeModal && (
        <AddAttributeModal
          open={addAttributeModal}
          toggle={() => setAddAttributeModal(false)}
          business_id={business_id}
          initialValues={{ product_name: aspectValue }}
        />
      )}
    </>
  );
};

export default ProductTagModal;
