import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import * as commonAction from "../common/commonAction";

export function getSocialCommentSuccess(data) {
  return { type: types.GET_SOCIAL_COMMENTS_SUCCESS, data };
}

export function getSocialCommentFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_SOCIAL_COMMENTS_FAILURE, data };
}

export const getSocialComment = (params) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(URLS.SOCIAL_PROFILE_COMMENTS, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getSocialCommentSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getSocialCommentFailure(err));
        throw err.response.data;
      });
  };
};

export function postSocialCommentSuccess(data) {
  return { type: types.POST_SOCIAL_COMMENTS_SUCCESS, data };
}

export function postSocialCommentFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.POST_SOCIAL_COMMENTS_FAILURE, data };
}

export const postSocialComment = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(URLS.SAVE_COMMENTS, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(postSocialCommentSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(postSocialCommentFailure(err));
        throw err.response.data;
      });
  };
};

export const editSocialComment = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .put(URLS.EDIT_COMMENTS, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
};

export const replySocialComment = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(URLS.REPLY_COMMENTS, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
};

export const deleteSocialComment = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .request({
        url: URLS.DELETE_COMMENTS,
        method: "DELETE",
        data: data,
      })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      });

    // return axios_instance
    //   .delete(URLS.DELETE_COMMENTS, data)
    //   .then((res) => {
    //     dispatch(commonAction.hideLoader());
    //     return res;
    //   })
    //   .catch((err) => {
    //     dispatch(commonAction.hideLoader());
    //     throw err.response.data;
    //   });
  };
};

export function getSocialPageLocationMappingSuccess(data) {
  return { type: types.GET_SOCIAL_PAGE_LOCATION_MAPPING_SUCCESS, data };
}

export function getSocialPageLocationMappingFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_SOCIAL_PAGE_LOCATION_MAPPING_FAILURE, data };
}

export const getSocialPageLocationMapping = (params) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(URLS.SOCIAl_PAGE_LOCATION_MAPPING, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getSocialPageLocationMappingSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getSocialPageLocationMappingFailure(err));
        throw err.response.data;
      });
  };
};

export function postSocialPageLocationMappingSuccess(data) {
  return { type: types.POST_SOCIAL_PAGE_LOCATION_MAPPING_SUCCESS, data };
}

export function postSocialPageLocationMappingFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.POST_SOCIAL_PAGE_LOCATION_MAPPING_FAILURE, data };
}

export const postSocialPageLocationMapping = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(URLS.SOCIAl_PAGE_LOCATION_MAPPING, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(postSocialPageLocationMappingSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(postSocialPageLocationMappingFailure(err));
        throw err.response.data;
      });
  };
};
