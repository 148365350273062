import React, { useState } from "react";
import { connect } from "react-redux";
import { Col } from "reactstrap";
import ListingFPTab from "../../../components/common/new/Listing/tabs/listingFPTabs";
import FullMenu from "../../../components/listing/ListingDetail/listingMenu/FullMenu";
import PhotosMenu from "../../../components/listing/ListingDetail/listingMenu/PhotosMenu";
import MenuHighlights from "../../../components/listing/ListingDetail/listingMenu/MenuHighlights";

const RootHeader = (props) => {
  // const redirectTo = (url) => {
  //     setTimeout(() => {
  //         props.history.push(url);
  //     }, 400);
  // };

  // const handleRootTabChange = (value) => {
  // switch (value) {
  //   case 0:
  //     redirectTo(`/${props.businessProfile?.business}/console-list`);
  //     break;
  //   case 1:
  //     redirectTo(`/${props.businessProfile?.business}/bulk-changes-log`);
  //     break;
  //   case 2:
  //     redirectTo(`/${props.businessProfile?.business}/bulk-product`);
  //     break;
  //   case 3:
  //     redirectTo(`/${props.businessProfile?.business}/bulk-menu`);
  //     break;
  //   default:
  //     break;
  // }
  // };
  const [activeTab, setActiveTab] = useState(0); // 0: Full Menu, 1: Photos of Menu, 2: Menu Highlights

  const handleRootTabChange = (value) => {
    setActiveTab(value); // Update the active tab based on the selected value
  };

  return (
    <Col className={"mb-4"}>
      <ListingFPTab
        className="border-0"
        tabLabels={["Full Menu", "Photos of menu", "Menu highlights"]}
        border="0"
        minTabWidth={20}
        defaultValue={props.defaultValue}
        onChange={(value) => handleRootTabChange(value)}
      />
      {activeTab === 0 && <FullMenu business_id={props?.business_id} selectReso={props?.selectReso} />}
      {activeTab === 1 && <PhotosMenu />}
      {activeTab === 2 && <MenuHighlights />}
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    businessProfile: state.profile.business_user,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(RootHeader);
