import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import axios_instance from "../../libs/interseptor";
import * as commonAction from "../common/commonAction";
import cookie from "react-cookies";

export function businessSetupSuccess(data) {
  return { type: types.BUSINESS_SETUP_SUCCESS, data };
}

export function businessSetupFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.BUSINESS_SETUP_FAILURE, data };
}

export const businessSetupAction = (data) => {
  const url = URLS.BUSINESS_SETUP + data.business + "/branch/" + data.corporate_branch + "/";
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .patch(url, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(businessSetupSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(businessSetupFailure(err));
        throw err.response.data;
      });
  };
};

export function getBusinessSuccess(data) {
  return { type: types.GET_BUSINESS_SUCCESS, data };
}

export function getBusinessFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_BUSINESS_FAILURE, data };
}

export const getBusinessAction = () => {
  const url = URLS.BUSINESS_SETUP;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getBusinessSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getBusinessFailure(err));
        throw err.response.data;
      });
  };
};

export function getSocialLinksSuccess(data) {
  return { type: types.GET_BUSINESS_SOCIAL_LINKS_SUCCESS, data };
}

export function getSocialLinksFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_BUSINESS_SOCIAL_LINKS_FAILURE, data };
}

export const getSocialLinksAction = (data) => {
  const url = URLS.BUSINESS_SETUP + data.business + "/" + data.corporate_branch + "/social-links/";
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getSocialLinksSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getSocialLinksFailure(err));
        throw err.response.data;
      });
  };
};

export const postSocialLinksAction = (data, buissnessData) => {
  const business = buissnessData.business;
  const corporate_branch = buissnessData.corporate_branch;
  const url = URLS.BUSINESS_SETUP + business + "/" + corporate_branch + "/social-links/";
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(url, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
};

export function getComplaintLinksSuccess(data) {
  return { type: types.GET_BUSINESS_COMPLAINTS_LINKS_SUCCESS, data };
}

export function getComplaintLinksFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_BUSINESS_COMPLAINTS_LINKS_FAILURE, data };
}

export const getComplaintLinksAction = (data) => {
  const url = URLS.BUSINESS_SETUP + data.business + "/complaint-links/";
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getComplaintLinksSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getComplaintLinksFailure(err));
        throw err.response.data;
      });
  };
};

export const postComplaintLinksAction = (data, businessData) => {
  const business = businessData.business;
  const url = URLS.BUSINESS_SETUP + business + "/complaint-links/";
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(url, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
};

export function businessBranchDetailsSuccess(data) {
  return { type: types.BUSINESS_BRANCH_DETAILS_SUCCESS, data };
}

export function businessBranchDetailsFailure(data) {
  return { type: types.BUSINESS_BRANCH_DEATILS_FAILURE, data };
}

export const businessBranchDetails = (data) => {
  const url = URLS.BUSINESS_SETUP + data.business + "/branch/" + data.corporate_branch + "/";
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(businessBranchDetailsSuccess(res.data));
        return res.data;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(businessBranchDetailsFailure(err));
        throw err;
      });
  };
};

export function setupBranchLocationSuccess(data) {
  return { type: types.SETUP_BRANCH_LOCATION_SUCCESS, data };
}

export function setupBranchLocationFaliure(data) {
  return { type: types.SETUP_BRANCH_LOCATION_FALIURE, data };
}

export const setupBranchLocation = (data) => {
  const { ...rest } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(`${URLS.BUSINESS_SETUP}${data.id}/${URLS.BRANCH}`, rest)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(setupBranchLocationSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(setupBranchLocationFaliure(err));
        throw err.response.data;
      });
  };
};

export function businessBranchOptionsSuccess(data) {
  return { type: types.BUSINESS_BRANCH_OPTIONS_SUCCESS, data };
}

export function businessBranchOptionsFailure(data) {
  return { type: types.BUSINESS_BRANCH_OPTIONS_FAILURE, data };
}

export const businessBranchOptions = (data, blank_required) => {
  const url = URLS.BUSINESS_SETUP + data.business + "/branch" + (blank_required ? "/?blank_req=false" : "/");
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(businessBranchOptionsSuccess(res.data));
        return res.data;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(businessBranchOptionsFailure(err));
        throw err.response.data;
      });
  };
};

export function enterpriseListSuccess(data) {
  return { type: types.ENTERPRISE_LIST_SUCCESS, data };
}

export function enterpriseListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.ENTERPRISE_LIST_FAILURE, data };
}

export const enterpriseList = (data, params) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.BUSINESS_SETUP}${data.id}/enterprise/`, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(enterpriseListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(enterpriseListFailure(err));
        throw err.response.data;
      });
  };
};

export function businessListSuccess(data) {
  return { type: types.BUSINESSLIST_SUCCESS, data };
}

export function businessListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.BUSINESSLIST_FAILURE, data };
}

export const getAdminBusineesList = (params) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(URLS.BUSINESS_LISTING, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(businessListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(businessListFailure(err));
        throw err.response.data;
      });
  };
};

export function enterpriseUserListSuccess(data) {
  return { type: types.ENTERPRISE_USER_LIST_SUCCESS, data };
}

export function enterpriseUserListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.ENTERPRISE_USER_LIST_FAILURE, data };
}

export const enterpriseUserList = (businessId, branch_id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.ENTERPRISE_USER_LIST, [businessId]), {
        params: { branch_id },
      })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(enterpriseUserListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(enterpriseUserListFailure(err));
        throw err.response.data;
      });
  };
};

export function userAddSuccess(data) {
  return { type: types.USER_ADD_SUCCESS, data };
}

export function userAddFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.USER_ADD_FAILURE, data };
}

export const userAdd = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(`${URLS.BUSINESS_SETUP}${data.business_id}/${URLS.BRANCH}${data.id}/add_owner/`, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(userAddSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(userAddFailure(err));
        throw err.response.data;
      });
  };
};

export function deleteUserSuccess(data) {
  return { type: types.DELETE_USER_SUCCESS, data };
}

export function deleteUserFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.DELETE_USER_FAILURE, data };
}

export const deleteUser = (data) => {
  const { business_id, id } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(`${URLS.BUSINESS_SETUP}${business_id}/${URLS.BRANCH}${id}/remove_owner/`, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(deleteUserSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(deleteUserFailure(err));
        throw err.response.data;
      });
  };
};

export function socialAccountsSetupSuccess(data) {
  return { type: types.SOCIAL_ACCOUNTS_SETUP_SUCCESS, data };
}

export function socialAccountsSetupFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.SOCIAL_ACCOUNTS_SETUP_FAILURE, data };
}

export const socialAccountsSetup = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(URLS.SOCIAL_SETUP, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(socialAccountsSetupSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(socialAccountsSetupFailure(err));
        throw err.response.data;
      });
  };
};

export function clearBusiness() {
  return (dispatch) => {
    dispatch(businessDetailSuccess(null));
  };
}

export function businessDetailSuccess(data) {
  return { type: types.BUSINESS_DETAIL_SUCCESS, data };
}

export function businessDetailFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.BUSINESS_DETAIL_FAILURE, data };
}

export const businessDetail = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.BUSINESS_SETUP}${data.id}/`, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(businessDetailSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(businessDetailFailure(err));
        throw err.response.data;
      });
  };
};

export function editBranchLocationSuccess(data) {
  return { type: types.EDIT_BRANCH_LOCATION_SUCCESS, data };
}

export function editBranchLocationFaliure(data) {
  return { type: types.EDIT_BRANCH_LOCATION_FALIURE, data };
}

export const editBranchLocation = (data) => {
  const { id, business_id, ...rest } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .patch(`${URLS.BUSINESS_SETUP}${business_id}/${URLS.BRANCH}${id}/`, rest)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(editBranchLocationSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(editBranchLocationFaliure(err));
        throw err.response.data;
      });
  };
};

export function smartResponseListSuccess(data) {
  return { type: types.RESPONSE_LIST_SUCCESS, data };
}

export function smartResponseListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.RESPONSE_LIST_FAILURE, data };
}

export const smartResponseList = (data, params) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.SMART_RESPONSES}${data.id}/`, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(smartResponseListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(smartResponseListFailure(err));
        throw err.response.data;
      });
  };
};

export function staffResponseListSuccess(data) {
  return { type: types.STAFF_LIST_SUCCESS, data };
}

export function staffResponseListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.STAFF_LIST_FAILURE, data };
}

// staff response list
export const staffList = (params) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(URLS.STAFF_LIST, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(staffResponseListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(staffResponseListFailure(err));
        throw err.response.data;
      });
  };
};

// delete staff response
export function deleteStaff(businessId, id) {
  console.log("businessId", businessId, id);
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(`${URLS.STAFF_LIST}${id}/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
}

export function aiResponseListSuccess(data) {
  return { type: types.AI_RESPONSE_LIST_SUCCESS, data };
}

export function aiResponseListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.AI_RESPONSE_LIST_FAILURE, data };
}

export const aiResponseList = (data, params) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.SMART_RESPONSES}aiResponse/${data.id}/`, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(aiResponseListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(aiResponseListFailure(err));
        throw err.response.data;
      });
  };
};

export function postBranchCompetitorSuccess(data) {
  return { type: types.POST_BRANCH_COMPETITOR_SUCCESS, data };
}

export function postBranchCompetitorFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.POST_BRANCH_COMPETITOR_FAILURE, data };
}

export function postBranchCompetitor(branchId, data) {
  const { ...rest } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader);
    return axios_instance
      .post(utils.format(`${URLS.COMPETITORS_BRANCH_LIST}`, [branchId]), rest)
      .then((res) => {
        dispatch(commonAction.hideLoader);
        dispatch(postBranchCompetitorSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader);
        dispatch(postBranchCompetitorFailure(err));
        throw err.response.data;
      });
  };
}

export function getBranchCompetitorsSuccess(data) {
  return { type: types.GET_BRANCH_COMPETITORS_SUCCESS, data };
}

export function getBranchCompetitorsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_BRANCH_COMPETITORS_FAILURE, data };
}
export function getBranchCompetitors(branchId) {
  return (dispatch) => {
    dispatch(commonAction.showLoader);
    return axios_instance
      .get(utils.format(`${URLS.COMPETITORS_BRANCH_LIST}`, [branchId]))
      .then((res) => {
        dispatch(commonAction.hideLoader);
        dispatch(getBranchCompetitorsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader);
        dispatch(getBranchCompetitorsFailure(err));
      });
  };
}

export function postSmartResponseSuccess(data) {
  return { type: types.POST_RESPONSE_SUCCESS, data };
}

export function postSmartResponseFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.POST_RESPONSE_FAILURE, data };
}

export const postSmartResponse = (data, businessId) => {
  if (data.id) {
    return (dispatch) => {
      dispatch(commonAction.showLoader());
      return axios_instance
        .patch(`${URLS.SMART_RESPONSES}${businessId}/${data.id}/`, data)
        .then((res) => {
          dispatch(commonAction.hideLoader());
          return res;
        })
        .catch((err) => {
          dispatch(commonAction.hideLoader());
          throw err.response.data;
        });
    };
  } else {
    return (dispatch) => {
      dispatch(commonAction.showLoader());
      return axios_instance
        .post(`${URLS.SMART_RESPONSES}${businessId}/`, data)
        .then((res) => {
          dispatch(commonAction.hideLoader());
          return res;
        })
        .catch((err) => {
          dispatch(commonAction.hideLoader());
          throw err.response.data;
        });
    };
  }
};

export const postStaffResponse = (formData) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(`${URLS.STAFF_CREATE}`, formData, config)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
};

export function putStaffResponse(data, id) {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .put(`${URLS.STAFF_LIST}${id}/`, data, config)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
}

export function postAiResponseSuccess(data) {
  return { type: types.POST_AI_RESPONSE_SUCCESS, data };
}

export function postAiResponseFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.POST_AI_RESPONSE_FAILURE, data };
}

export const postAiResponse = (data, businessId) => {
  if (data.id) {
    return (dispatch) => {
      dispatch(commonAction.showLoader());
      return axios_instance
        .patch(`${URLS.SMART_RESPONSES}aiResponse/${businessId}/${data.id}/`, data)
        .then((res) => {
          dispatch(commonAction.hideLoader());
          return res;
        })
        .catch((err) => {
          dispatch(commonAction.hideLoader());
          throw err.response.data;
        });
    };
  } else {
    return (dispatch) => {
      dispatch(commonAction.showLoader());
      return axios_instance
        .post(`${URLS.SMART_RESPONSES}aiResponse/${businessId}/`, data)
        .then((res) => {
          dispatch(commonAction.hideLoader());
          return res;
        })
        .catch((err) => {
          dispatch(commonAction.hideLoader());
          throw err.response.data;
        });
    };
  }
};

export function allowedForOptionsSuccess(data) {
  return { type: types.ALLOWED_FOR_OPTIONS_SUCCESS, data };
}

export function allowedForOptionsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.ALLOWED_FOR_OPTIONS_FAILURE, data };
}

export const allowedForOptions = (businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.SMART_RESPONSES}${businessId}/options/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(allowedForOptionsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(allowedForOptionsFailure(err));
        throw err.response.data;
      });
  };
};

export function reviewTypeOptionsSuccess(data) {
  return { type: types.REVIEW_TYPE_OPTIONS_SUCCESS, data };
}

export function reviewTypeOptionsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.REVIEW_TYPE_OPTIONS_FAILURE, data };
}

export const reviewTypeOptions = (businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.SMART_RESPONSES}${businessId}/review_type/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(reviewTypeOptionsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(reviewTypeOptionsFailure(err));
        throw err.response.data;
      });
  };
};

export function getResponseDetailsSuccess(data) {
  return { type: types.GET_RESPONSE_DETAILS_SUCCESS, data };
}

export function getResponseDetailsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_RESPONSE_DETAILS_FAILURE, data };
}

export const getResponseDetails = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(
        `${URLS.SMART_RESPONSES}${data.business_id}/${data.id}/${
          data.review_id ? `content/?review_id=${data.review_id}` : ""
        }`
      )
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getResponseDetailsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getResponseDetailsFailure(err));
        throw err.response.data;
      });
  };
};

// staff details
export function getStaffDetailsSuccess(data) {
  return { type: types.STAFF_DETAIL_SUCCESS, data };
}

export function getStaffDetailsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.STAFF_DETAIL_FAILURE, data };
}

export const getStaffDetails = (id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.STAFF_LIST}${id}/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getStaffDetailsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getStaffDetailsFailure(err));
        throw err.response.data;
      });
  };
};

export function getAiResponseDetailsSuccess(data) {
  return { type: types.GET_AI_RESPONSE_DETAILS_SUCCESS, data };
}

export function getAiResponseDetailsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_AI_RESPONSE_DETAILS_FAILURE, data };
}

export const getAiResponseDetails = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(
        `${URLS.SMART_RESPONSES}aiResponse/${data.business_id}/${data.id}/${
          data.review_id ? `content/?review_id=${data.review_id}` : ""
        }`
      )
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getAiResponseDetailsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getAiResponseDetailsFailure(err));
        throw err.response.data;
      });
  };
};

export function responseDeleteSuccess(data) {
  return { type: types.RESPONSE_DELETE_SUCCESS, data };
}

export function responseDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.RESPONSE_DELETE_FAILURE, data };
}

export const responseDelete = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(`${URLS.SMART_RESPONSES}${data.business_id}/${data.id}/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(responseDeleteSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(responseDeleteFailure(err));
        throw err.response.data;
      });
  };
};

export function aiResponseDeleteSuccess(data) {
  return { type: types.AI_RESPONSE_DELETE_SUCCESS, data };
}

export function aiResponseDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.AI_RESPONSE_DELETE_FAILURE, data };
}

export const aiResponseDelete = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(`${URLS.SMART_RESPONSES}aiResponse/${data.business_id}/${data.id}/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(aiResponseDeleteSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(aiResponseDeleteFailure(err));
        throw err.response.data;
      });
  };
};

const responseOptionsSuccess = (data) => {
  return { type: types.RESPONSE_OPTIONS_SUCCESS, data: data };
};

const responseOptionsFailure = (error) => {
  return { type: types.RESPONSE_OPTIONS_FAILURE, data: error };
};

export const responseOptions = (businessId, params) => {
  return (dispatch) => {
    axios_instance
      .get(`${URLS.SMART_RESPONSES}${businessId}/review_response/`, { params })
      .then((res) => {
        dispatch(responseOptionsSuccess(res.data));
      })
      .catch((error) => {
        dispatch(responseOptionsFailure(error));
      });
  };
};

const toggleSmartResponseSuccess = (data) => {
  return { type: types.TOGGLE_SMART_RESPONSE_SUCCESS, data: data };
};

const toggleSmartResponseFailure = (error) => {
  return { type: types.TOGGLE_SMART_RESPONSE_FAILURE, data: error };
};

export const toggleSmartResponse = (businessId, data) => {
  return (dispatch) => {
    axios_instance
      .post(`${URLS.SMART_RESPONSES}${businessId}/toggle/`, data)
      .then((res) => {
        dispatch(toggleSmartResponseSuccess(res.data));
      })
      .catch((error) => {
        dispatch(toggleSmartResponseFailure(error));
      });
  };
};

export function getInsightsCategorySuccess(data) {
  return {
    type: types.GET_INSIGHTS_CATEGORY_SUCCESS,
    payload: data,
  };
}

export function getInsightsCategoryFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return {
    type: types.GET_INSIGHTS_CATEGORY_FAILURE,
    payload: data,
  };
}

export const getInsightsCategoryAction = (data, params) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.INSIGHTS, [data.id]), { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getInsightsCategorySuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getInsightsCategoryFailure(err));
        throw err.response.data;
      });
  };
};

export function postInsightsCategorySuccess(data) {
  return { type: types.POST_INSIGHTS_CATEGORY_SUCCESS, data };
}

export function postInsightsCategoryFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.POST_INSIGHTS_CATEGORY_FAILURE, data };
}

export const postInsights = (businessId, data) => {
  if (data.id) {
    return (dispatch) => {
      dispatch(commonAction.showLoader);
      return axios_instance
        .patch(utils.format(URLS.INSIGHTS_DATA, [businessId, data.id]), data)
        .then((res) => {
          dispatch(commonAction.hideLoader());
          return res;
        })
        .catch((err) => {
          dispatch(commonAction.hideLoader());
          throw err.response.data;
        });
    };
  } else {
    return (dispatch) => {
      dispatch(commonAction.showLoader);
      return axios_instance
        .post(utils.format(URLS.INSIGHTS, [businessId]), data)
        .then((res) => {
          dispatch(commonAction.hideLoader());
          return res;
        })
        .catch((err) => {
          dispatch(commonAction.hideLoader());
          throw err.response.data;
        });
    };
  }
};

export function insightsListDeleteSuccess(data) {
  return { type: types.INSIGHTS_LIST_DELETE_SUCCESS, data };
}

export function insightsListDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.INSIGHTS_LIST_DELETE_FAILURE, data };
}

export const insightsListDelete = (data, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(utils.format(URLS.INSIGHTS_DATA, [businessId, data.id]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(insightsListDeleteSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(insightsListDeleteFailure(err));
        throw err.response.data;
      });
  };
};

export function getInsightsDetailSuccess(data) {
  return { type: types.GET_INSIGHTS_DETAIL_SUCCESS, data };
}

export function getInsightsDetailFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_INSIGHTS_DETAIL_FAILURE, data };
}

export const getInsightsDetails = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.INSIGHTS_DATA, [data.business_id, data.id]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getInsightsDetailSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getInsightsDetailFailure(err));
        throw err.response.data;
      });
  };
};

export function getRecommendedKeywordsSuccess(data) {
  return { type: types.GET_RECOMMENDED_KEYWORDS_SUCCESS, data };
}

export function getRecommendedKeywordsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_RECOMMENDED_KEYWORDS_FAILURE, data };
}

export const getRecommendedKeywords = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.INSIGHTS_RECOMMENDED_KEYWORDS, [data]))
      .then((res) => {
        dispatch(getRecommendedKeywordsSuccess(res.data));
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(getRecommendedKeywordsFailure(err));
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
};

export function relevantCountriesSuccess(data) {
  return { type: types.RELEVANT_COUNTRIES_SUCCESS, data };
}

export function relevantCountriesFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.RELEVANT_COUNTRIES_FAILURE, data };
}

export const getRelevantCountries = (businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.RELEVANT_COUNTRIES, [businessId]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(relevantCountriesSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(relevantCountriesFailure(err));
        throw err.response.data;
      });
  };
};

export function relevantStatesSuccess(data) {
  return { type: types.RELEVANT_STATES_SUCCESS, data };
}

export function relevantStatesFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.RELEVANT_STATES_FAILURE, data };
}

export const getRelevantStates = (params, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.RELEVANT_STATES, [businessId]), { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(relevantStatesSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(relevantStatesFailure(err));
        throw err.response.data;
      });
  };
};

export function getTagsListSuccess(data) {
  return { type: types.GET_TAGS_LIST_SUCCESS, data };
}

export function getTagsListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_TAGS_LIST_FAILURE, data };
}

export const getTagsList = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.TAGS_API, [data.businessId, data.type]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getTagsListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getTagsListFailure(err));
        throw err.response.data;
      });
  };
};

export function getCategoryTagsListSuccess(data) {
  return { type: types.GET_CATEGORY_TAGS_LIST_SUCCESS, data };
}

export function getCategoryTagsListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_CATEGORY_TAGS_LIST_FAILURE, data };
}

export const getCategoryTagsList = (businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(`${utils.format(URLS.FAME_AI_CATEGORY_LIST, [businessId])}categories/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getCategoryTagsListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getCategoryTagsListFailure(err));
        throw err.response.data;
      });
  };
};


export function getProductNameListSuccess(data) {
  return { type: types.GET_PRODUCT_NAME_LIST_SUCCESS, data };
}

export function getProductNameListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_PRODUCT_NAME_LIST_FAILURE, data };
}

export const getProductNameList = (businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(`${utils.format(URLS.PRODUCT_NAME_LIST, [businessId])}`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getProductNameListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getProductNameListFailure(err));
        throw err.response.data;
      });
  };
};

const postTagSuccess = (data) => {
  return { type: types.POST_TAG_SUCCESS, data: data };
};

const postTagFailure = (error) => {
  return { type: types.POST_TAG_FAILURE, data: error };
};

export const postTag = (data) => {
  const { businessId, type, tagMethod, ...rest } = data;
  const url = tagMethod
    ? utils.format(URLS.TAGS_API, [businessId, type]) + `${tagMethod}`
    : utils.format(URLS.TAGS_API, [businessId, type]);
  return (dispatch) => {
    return axios_instance
      .post(url, { ...rest })
      .then((res) => {
        dispatch(postTagSuccess(res.data));
        return res;
      })
      .catch((error) => {
        dispatch(postTagFailure(error));
        return error;
      });
  };
};

export const editTagName = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader);
    return axios_instance
      .patch(utils.format(URLS.TAGS_API, [data.businessId, data.type]) + `${data.id}/`, { name: data.name })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
};

export const deleteTag = (data) => {
  const { businessId, id, type } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(utils.format(URLS.TAGS_API, [businessId, type]) + `${id}/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
};

const getLockedModulesSuccess = (data) => {
  return { type: types.GET_LOCKED_MODULES_SUCCESS, data };
};

const getLockedModulesFailure = (error) => {
  return { type: types.GET_LOCKED_MODULES_FAILURE, error };
};

export const getLockedModules = (business_id) => {
  return (dispatch) => {
    return axios_instance
      .get(utils.format(URLS.LOCKED_MODULES, [business_id]))
      .then((res) => {
        dispatch(getLockedModulesSuccess(res.data));
        return res.data;
      })
      .catch((err) => {
        dispatch(getLockedModulesFailure(err));
        return err.response.data;
      });
  };
};

export const addNewRule = (data, business_id) => {
  const url = `${URLS.TICKET}${business_id}/ticket_rules/`;
  if (data.id) {
    return (dispatch) => {
      dispatch(commonAction.showLoader());
      return axios_instance
        .patch(`${URLS.TICKET}${business_id}/ticket_rules/${data.id}/`, data)
        .then(() => {
          dispatch(commonAction.hideLoader());
          //return res;
        })
        .catch((err) => {
          dispatch(commonAction.hideLoader());
          throw err.response.data;
        });
    };
  } else {
    return (dispatch) => {
      dispatch(commonAction.showLoader());
      return axios_instance
        .post(url, data)
        .then(() => {
          dispatch(commonAction.hideLoader());
          //return res;
        })
        .catch((err) => {
          dispatch(commonAction.hideLoader());
          throw err.response.data;
        });
    };
  }
};

const enterpriseDataSuccess = (data) => {
  return { type: types.ENTERPRISE_DATA_SUCCESS, data };
};

const enterpriseDataFailure = (data) => {
  return { type: types.ENTERPRISE_DATA_FAILURE, data };
};

export const getEnterpriseData = (business_id) => {
  const url = `${URLS.BUSINESS_SETUP}${business_id}/enterprise/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    axios_instance
      .get(url)
      .then((res) => {
        dispatch(enterpriseDataSuccess(res.data));
        dispatch(commonAction.hideLoader());
        return res.data;
      })
      .catch((err) => {
        dispatch(enterpriseDataFailure(err));
        dispatch(commonAction.hideLoader());
        return err.response.data;
      });
  };
};

const ticketDataSuccess = (data) => {
  return { type: types.TICKETS_RULES_DATA_SUCCESS, data };
};

const ticketDataFailure = (data) => {
  return { type: types.TICKETS_RULES_DATA_FAILURE, data };
};

export const getCreatedRulesAction = (data, queryData) => {
  const url = `${URLS.TICKET}${data.id}/ticket_rules/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url, { params: queryData })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketDataSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketDataFailure(err));
      });
  };
};

const branchListSuccess = (data) => {
  return { type: types.BRANCH_LIST_SUCCESS, data };
};

const branchListFailure = (data) => {
  return { type: types.BRANCH_LIST_FAILURE, data };
};

export const getBranchList = (business_id, blank_required) => {
  const url = URLS.BUSINESS_SETUP + business_id + "/branch" + (blank_required ? "/?blank_req=false" : "/");
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(branchListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(branchListFailure(err.response.data));
        return err;
      });
  };
};

export function ruleDeleteSuccess(data) {
  return { type: types.RULE_DELETE_SUCCESS, data };
}

export function ruleDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.RULE_DELETE_FAILURE, data };
}

export const ruleDelete = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(`${URLS.TICKET}${data.businessId}/ticket_rules/${data.id}/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ruleDeleteSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ruleDeleteFailure(err));
        throw err.response.data;
      });
  };
};

export function ruleDetailSuccess(data) {
  return { type: types.RULE_DETAIL_SUCCESS, data };
}

export function ruleDetailFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.RULE_DETAIL_FAILURE, data };
}

export const ruleDetail = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.TICKET}${data.business_id}/ticket_rules/${data.id}/`)
      .then((res) => {
        if (res.data.hasOwnProperty("content_choice")) {
          if (typeof res.data.content_choice === "string") {
            res.data.content_choice = res.data.content_choice.split(",");
          }
        }
        dispatch(commonAction.hideLoader());
        dispatch(ruleDetailSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ruleDetailFailure(err));
        throw err.response.data;
      });
  };
};

export function reviewContentTypeOptionsSuccess(data) {
  return { type: types.REVIEW_CONTENT_TYPE_OPTIONS_SUCCESS, data };
}

export function reviewContentTypeOptionsFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.REVIEW_CONTENT_TYPE_OPTIONS_FAILURE, data };
}

export const reviewContentTypeOptions = (businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.SMART_RESPONSES}${businessId}/content_choice/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(reviewContentTypeOptionsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(reviewContentTypeOptionsFailure(err));
        throw err.response.data;
      });
  };
};

export function branchOwnersSuccess(data) {
  return { type: types.BRANCH_OWNERS_SUCCESS, data };
}

export function branchOwnersFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.BRANCH_OWNERS_FAILURE, data };
}

export const branchOwners = (data) => {
  const { businessId, branches } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.BUSINESS_SETUP}branch-owners/?business_id=${businessId}&branches=${branches}`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(branchOwnersSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(branchOwnersFailure(err));
        throw err.response.data;
      });
  };
};

export const downloadCouponSample = (url, filename) => {
  const token = cookie.load("authToken");
  return async (dispatch) => {
    dispatch(commonAction.showLoader());
    try {
      const res = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/csv",
        },
      });
      const blob = await res.blob();
      dispatch(commonAction.hideLoader());
      let winUrl = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = winUrl;
      a.download = filename || "coupon-format.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      console.log(err);
      dispatch(commonAction.hideLoader());
    }
  };
};

export function couponListSuccess(data) {
  return { type: types.COUPON_LIST_SUCCESS, data };
}

export function couponListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.COUPON_LIST_FAILURE, data };
}

export const couponList = (data) => {
  const { businessId, ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.COUPON_LIST, [businessId]), { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(couponListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(couponListFailure(err));
        throw err.response.data;
      });
  };
};

export function couponCsvUploadSuccess(data) {
  return { type: types.COUPON_CSV_UPLOAD_SUCCESS, data };
}

export function couponCsvUploadFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.COUPON_CSV_UPLOAD_FAILURE, data };
}

export const couponCsvUpload = (business_id, data) => {
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const url = utils.format(URLS.COUPON_UPLOAD_CSV, [business_id]);
  return (dispatch) => {
    return axios_instance
      .post(url, data, config)
      .then((res) => {
        dispatch(couponCsvUploadSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(couponCsvUploadFailure(err));
        throw err.response.data;
      });
  };
};

export function couponSummarySuccess(data) {
  return { type: types.COUPON_SUMMARY_SUCCESS, data };
}

export function couponSummaryFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.COUPON_SUMMARY_FAILURE, data };
}

export const couponSummary = (business_id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.COUPON_SUMMARY, [business_id]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(couponSummarySuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(couponSummaryFailure(err));
        throw err.response.data;
      });
  };
};

export function ticketRuleProvidersSuccess(data) {
  return { type: types.TICKET_RULE_PROVIDERS_SUCCESS, data };
}

export function ticketRuleProvidersFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.TICKET_RULE_PROVIDERS_FAILURE, data };
}

export const ticketRuleProviders = (businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(`${URLS.SMART_RESPONSES}${businessId}/ticket_providers_options/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketRuleProvidersSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketRuleProvidersFailure(err));
        throw err.response.data;
      });
  };
};
