/* eslint-disable quotes */
import React, { memo } from "react";
import { Avatar } from "@material-ui/core";
import classNames from "classnames";
import FPChip from "./FPChip/FPChip";
import FPBadge from "./FPBadge/FPBadge";
import { capitalizeWords, snakeToCapital } from "../../../libs/utils";

const getStatusColor = (status) => ({
  New: "#228B22",
  Repeat: "#f8ae00",
  Lapsed: "#e16b16",
}[status]);

const FPAvatar = ({
  userName,
  labelClass,
  subLabel,
  subLabelClass,
  userStatus,
  typeChip,
  avatarCenter = true,
  ...rest
}) => (
  <div className={`d-flex ${avatarCenter && "gap-10 align-center"}`}>
    <Avatar {...rest} />
    <div>
      <div className="d-flex align-items-center gap-10">
        {userName && <span className={classNames(labelClass)}>{userName}</span>}
        {userStatus && (
          <p className="high-value-customer-badge mb-0">
            {capitalizeWords(userStatus)}
          </p>
        )}
        {typeChip && (
          <FPChip
            size="small"
            variant="outlined"
            className="high-value-assign-box-fp-chip"
            label={
              <div className="d-flex align-items-center justify-content-center">
                <FPBadge color={getStatusColor(typeChip)} size="11" />
                &nbsp;&nbsp;
                <span className="chip-text">
                  {snakeToCapital(typeChip)}
                </span>
              </div>
            }
          />
        )}
      </div>
      {subLabel && (
        <span className={classNames(subLabelClass)}>{subLabel}</span>
      )}
    </div>
  </div>
);

export default memo(FPAvatar);
