import React from "react";

function EditSvg() {
  return (
    <div>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.33398 2.66666H4.53398C3.41388 2.66666 2.85383 2.66666 2.426 2.88464C2.04968 3.07639 1.74372 3.38235 1.55197 3.75867C1.33398 4.1865 1.33398 4.74655 1.33398 5.86666V11.4667C1.33398 12.5868 1.33398 13.1468 1.55197 13.5746C1.74372 13.951 2.04968 14.2569 2.426 14.4487C2.85383 14.6667 3.41388 14.6667 4.53398 14.6667H10.134C11.2541 14.6667 11.8141 14.6667 12.242 14.4487C12.6183 14.2569 12.9243 13.951 13.116 13.5746C13.334 13.1468 13.334 12.5868 13.334 11.4667V8.66666M5.33397 10.6667H6.45033C6.77645 10.6667 6.93951 10.6667 7.09296 10.6298C7.22901 10.5972 7.35907 10.5433 7.47836 10.4702C7.61292 10.3877 7.72822 10.2724 7.95882 10.0418L14.334 3.66666C14.8863 3.11437 14.8863 2.21894 14.334 1.66666C13.7817 1.11437 12.8863 1.11437 12.334 1.66665L5.95881 8.04182C5.7282 8.27242 5.6129 8.38772 5.53045 8.52228C5.45734 8.64157 5.40347 8.77163 5.37081 8.90768C5.33397 9.06113 5.33397 9.22419 5.33397 9.55031V10.6667Z"
          stroke="black"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}

export default EditSvg;
