import * as types from "../../actions/actionTypes";

export default function teamReducer(state = [], action) {
  switch (action.type) {
    case types.ADD_TEAM_MEMBER_SUCCESS:
      return action.data;
    case types.ADD_TEAM_MEMBER_FAILURE:
      return action.data;
    case types.TEAM_LIST_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.GET_TEAM_SUCCESS:
      return Object.assign({}, state, { detail: action.data });
    case types.GET_TEAM_FAILURE:
      return Object.assign({}, state, { detail: action.data });
    case types.TEAM_EDIT_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.TEAM_EDIT_FAILURE:
      return Object.assign({}, state, action.data);
    case types.TEAM_MEMBER_DELETE_SUCCESS:
      return Object.assign({}, state, { detail: action.data });
    case types.TEAM_MEMBER_DELETE_FAILURE:
      return Object.assign({}, state, { detail: action.data });
    case types.TEAM_DEPARTMENTS_USERS_SUCCESS:
      return Object.assign({}, state, { team_department: action.data });
    case types.TEAM_DEPARTMENTS_USERS_FAILURE:
      return Object.assign({}, state, { team_department_error: action.data });
    default:
      return state;
  }
}
