import * as types from "../../actions/actionTypes";

export default function socialProfileReducer(state = [], action) {
  switch (action.type) {
    case types.GET_SOCIAL_PROFILE_SUCCESS:
      return Object.assign({}, state, { details: action.data });
    case types.GET_SOCIAL_PROFILE_FAILURE:
      return Object.assign({}, state, { details: action.data });
    case types.ALL_MENTIONS_SOCIAL_CONNECT_SUCCESS:
      return Object.assign({}, state, { isLoggedIn: { ...state.isLoggedIn, ...action.data }});
    default:
      return state;
  }
}
