/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { useParams } from 'react-router-dom';
import { Popup } from "semantic-ui-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { toArray } from "lodash";
import SortIcon from "../../images/svg/Sort.svg";
import TagReviews from "./TagReviews";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
// import axios_instance from '../../libs/interseptor';
// import * as URLS from '../../libs/apiUrls';
import "../campaign/campaignTemplate.css";
import DisplayPercentage from "../../containers/common/DisplayPercentage";
import { FPToolTip, NoData } from "../common/new";
import CustomStarRatingComponent from "../common/StarComponent";
import { setDecimalIndex, sortChangeColor } from "../../libs/utils";
import classNames from "classnames";

const ReportTableComponent = (props) => {
  let [dataa, setDataState] = useState("");
  let [swigyData, setswigyData] = useState("");
  let [sortingType, setsortingType] = useState(0);
  let [reviewModelOpen, setReviewModelOpen] = useState(false);
  let [sentimentType, setSentimentType] = useState(null);
  let [tagName, setTagName] = useState(null);
  let [channelActiveCol, setChannelActiveCol] = useState("");
  let {
    data,
    tableHeader,
    column1,
    column2,
    column3,
    column4,
    column6,
    column5,
    column7,
    column8,
    column9,
    column10,
    column11,
    column12,
    column13,
    column14,
    column15,
    column16,
    column17,
    column18,
    column19,
    column20,
    column21,
    column22,
    column23,
    column24,
    column25,
    column26,
    column27,
    column28,
    column29,
    dataHeader,
    headerKeys,
    sortFunction,
    sortFunctionFirst,
    sortFunctionTwo,
    sortFunctionThree,
    sortFunctionFour,
    ordering,
    noFix,
    zomatoSwiggyRating,
    handleFilterDataByTagName,
    queryData,
    querySearch,
    showPercent,
    activeCol,
    loader
  } = props;
  useEffect(() => {
    setDataState(data);
    setswigyData(data);
  }, [props]);

  // const sorting = average_click_count === 1 ?

  const sort =
    ordering && ordering[0] === "-"
      ? { on: ordering.slice(1), by: "descending" }
      : { on: ordering, by: "ascending" };
  data =
    zomatoSwiggyRating && data !== undefined
      ? toArray(data)
      : zomatoSwiggyRating && data === undefined
      ? []
      : data;

  useEffect(() => {
    if (props.newData !== undefined && props.newData !== "") {
      setDataState(props.newData);
    }
  }, [props.newData]);

  function handleTagClick(sentiment_type, tag_name) {
    setSentimentType(sentiment_type);
    setTagName(tag_name);
    setReviewModelOpen(true);
  }

  function sortZomatoSwigyData(col_name) {
    if (col_name === "swiggy_display_rating") {
      if (sortingType === 0) {
        const ab = data.sort(function (a, b) {
          return a["swiggy_display_rating"] - b["swiggy_display_rating"];
        });
        setsortingType(1);
        setswigyData(ab);
        setChannelActiveCol("swiggy_display_rating");
      } else {
        const ab = data.sort(function (a, b) {
          return b["swiggy_display_rating"] - a["swiggy_display_rating"];
        });
        setsortingType(0);
        setswigyData(ab);
        setChannelActiveCol("swiggy_display_rating");
      }
    }
    if (col_name === "zomato_delivery_display_rating") {
      if (sortingType === 0) {
        const ab = data.sort(function (a, b) {
          return (
            a["zomato_delivery_display_rating"] -
            b["zomato_delivery_display_rating"]
          );
        });
        setsortingType(1);
        setswigyData(ab);
        setChannelActiveCol("zomato_delivery_display_ratingg");
      } else {
        const ab = data.sort(function (a, b) {
          return (
            b["zomato_delivery_display_rating"] -
            a["zomato_delivery_display_rating"]
          );
        });
        setsortingType(0);
        setswigyData(ab);
        setChannelActiveCol("zomato_delivery_display_rating");
      }
    }
    if (col_name === "zomato_dining_display_rating") {
      if (sortingType === 0) {
        const ab = data.sort(function (a, b) {
          return (
            a["zomato_dining_display_rating"] -
            b["zomato_dining_display_rating"]
          );
        });
        setsortingType(1);
        setswigyData(ab);
        setChannelActiveCol("zomato_dining_display_rating");
      } else {
        const ab = data.sort(function (a, b) {
          return (
            b["zomato_dining_display_rating"] -
            a["zomato_dining_display_rating"]
          );
        });
        setsortingType(0);
        setswigyData(ab);
        setChannelActiveCol("zomato_dining_display_rating");
      }
    }
    if (col_name === "google_no_of_reviews") {
      if (sortingType === 0) {
        const ab = data.sort(function (a, b) {
          return a["google_no_of_reviews"] - b["google_no_of_reviews"];
        });
        setsortingType(1);
        setswigyData(ab);
      } else {
        const ab = data.sort(function (a, b) {
          return b["google_no_of_reviews"] - a["google_no_of_reviews"];
        });
        setsortingType(0);
        setswigyData(ab);
      }
    }
    if (col_name === "google_review_rating") {
      if (sortingType === 0) {
        const ab = data.sort(function (a, b) {
          return a["google_review_rating"] - b["google_review_rating"];
        });
        setsortingType(1);
        setswigyData(ab);
        setChannelActiveCol("google_review_rating");
      } else {
        const ab = data.sort(function (a, b) {
          return b["google_review_rating"] - a["google_review_rating"];
        });
        setsortingType(0);
        setswigyData(ab);
        setChannelActiveCol("google_review_rating");
      }
    }
  }
  return (
    <div>
      {!zomatoSwiggyRating && (
        <CardWrapper headerTitle={dataHeader} className="p-3">
          {data && Object.keys(data).length > 0 ? (
            <div className="ui-table-responsive">
              <Table className="fp-table fp-table-center">
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell
                      className="width-insights-th"
                      style={
                        activeCol === "tags"
                          ? sortChangeColor("#1f9095", "#d9f0f0")
                          : null
                      }
                      onClick={() => handleFilterDataByTagName()}
                    >
                      {tableHeader} {tableHeader === "Tags" ? <SortIcon /> : ""}
                    </TableCell>
                    {column1 && (
                      <TableCell
                        style={
                          activeCol === "rating"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                        onClick={() => sortFunctionFirst()}
                      >
                        {dataHeader.includes("Reviews and Ratings")
                          ? column1
                          : column1[0].toUpperCase() + column1.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column6 && !showPercent && (
                      <TableCell
                        onClick={() => props.handleFilterDataByTagsPercentage()}
                      >
                        {dataHeader.includes("Reviews and Ratings")
                          ? column6
                          : column6[0].toUpperCase() + column6.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column28 && showPercent && (
                      <TableCell
                        onClick={() => sortFunctionFirst()}
                        style={
                          activeCol === "positive"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {" "}
                        {column28[0].toUpperCase() + column28.slice(1)}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column29 && showPercent && (
                      <TableCell
                        onClick={() => sortFunction()}
                        style={
                          activeCol === "negative"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {" "}
                        {column29[0].toUpperCase() + column29.slice(1)}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column2 && (
                      <TableCell
                        onClick={() =>
                          column2 === "twoStar"
                            ? sortFunctionFour()
                            : sortFunction()
                        }
                        style={
                          activeCol === "review"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {column2[0].toUpperCase() + column2.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column7 && !showPercent && (
                      <TableCell
                        onClick={() =>
                          props.handleFilterDataByTagsPercentagenegative()
                        }
                      >
                        {dataHeader.includes("Reviews and Ratings")
                          ? column7
                          : column7[0].toUpperCase() + column7.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column3 && showPercent && (
                      <TableCell
                        onClick={() =>
                          column3 === "threeStar"
                            ? sortFunctionFour()
                            : sortFunctionTwo()
                        }
                        style={
                          activeCol === "neutral"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {dataHeader.includes("Reviews and Ratings")
                          ? column3
                          : column3[0].toUpperCase() + column3.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column8 && !showPercent && (
                      <TableCell
                        onClick={() =>
                          props.handleFilterDataByTagsPercentageneutral()
                        }
                      >
                        {column8[0].toUpperCase() + column8.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column5 && (
                      <TableCell onClick={() => sortFunctionFour()}>
                        {column5[0].toUpperCase() + column5.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column9 && (
                      <TableCell onClick={() => sortFunctionFour()}>
                        {column9[0].toUpperCase() + column9.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column11 && showPercent && (
                      <TableCell
                        onClick={() => props.handleFilterDataByStarFirst()}
                        style={
                          activeCol === "oneStar"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {column11}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column20 && !showPercent && (
                      <TableCell
                        onClick={() => props.handleFilterDataByStarPercentage()}
                      >
                        {column20} <SortIcon />
                      </TableCell>
                    )}
                    {column12 && showPercent && (
                      <TableCell
                        onClick={() => props.handleFilterDataByStarSecond()}
                        style={
                          activeCol === "twoStar"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {column12} <SortIcon />
                      </TableCell>
                    )}
                    {column21 && !showPercent && (
                      <TableCell
                        onClick={() =>
                          props.handleFilterDataByStarPercentageone()
                        }
                      >
                        {" "}
                        {column21}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column13 && showPercent && (
                      <TableCell
                        onClick={() => props.handleFilterDataByStarThree()}
                        style={
                          activeCol === "threeStar"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {column13}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column22 && !showPercent && (
                      <TableCell
                        onClick={() =>
                          props.handleFilterDataByStarPercentagetwo()
                        }
                      >
                        {column22}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column14 && showPercent && (
                      <TableCell
                        onClick={() => props.handleFilterDataByStarFour()}
                        style={
                          activeCol === "fourStar"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {" "}
                        {column14}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column23 && !showPercent && (
                      <TableCell
                        onClick={() =>
                          props.handleFilterDataByStarPercentagethree()
                        }
                      >
                        {" "}
                        {column23}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column15 && showPercent && (
                      <TableCell
                        onClick={() => props.handleFilterDataByStarFive()}
                        style={
                          activeCol === "fiveStar"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {" "}
                        {column15}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column24 && !showPercent && (
                      <TableCell
                        onClick={() =>
                          props.handleFilterDataByStarPercentagefour()
                        }
                      >
                        {" "}
                        {column24}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column16 && showPercent && (
                      <TableCell
                        onClick={() => props.handleFilterDataByStarAverage()}
                        style={
                          activeCol === "rating"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {column16[0].toUpperCase() + column16.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column10 && showPercent && (
                      <TableCell
                        onClick={() => props.handleFilterDataByStarTotal()}
                        style={
                          activeCol === "total"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {column10[0].toUpperCase() + column10.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column17 && showPercent && (
                      <TableCell
                        onClick={() => sortFunctionFirst()}
                        style={
                          activeCol === "positive"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {column17[0].toUpperCase() + column17.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column25 && !showPercent && (
                      <TableCell
                        onClick={() =>
                          props.handleFilterDataBySentimentalPercentage()
                        }
                      >
                        {column25[0].toUpperCase() + column25.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column18 && showPercent && (
                      <TableCell
                        onClick={() => sortFunction()}
                        style={
                          activeCol === "negative"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {column18[0].toUpperCase() + column18.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column26 && !showPercent && (
                      <TableCell
                        onClick={() =>
                          props.handleFilterDataBySentimentalPercentagenegative()
                        }
                      >
                        {column26[0].toUpperCase() + column26.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column19 && showPercent && (
                      <TableCell
                        onClick={() => sortFunctionTwo()}
                        style={
                          activeCol === "neutral"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {column19[0].toUpperCase() + column19.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column27 && !showPercent && (
                      <TableCell
                        onClick={() =>
                          props.handleFilterDataBySentimentalPercentageneutral()
                        }
                      >
                        {column27[0].toUpperCase() + column27.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                    {column4 && showPercent && (
                      <TableCell
                        onClick={() => sortFunctionThree()}
                        style={
                          activeCol === "total"
                            ? sortChangeColor("#1f9095", "#d9f0f0")
                            : null
                        }
                      >
                        {dataHeader.includes("Reviews and Ratings")
                          ? column4
                          : column4[0].toUpperCase() + column4.slice(1)}{" "}
                        <SortIcon />
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataa && Object.keys(dataa).length ? (
                    Object.keys(dataa).map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell
                            className={classNames("commonHeader", {
                              "width-insights": tableHeader === "Location",
                              location: tableHeader !== "Location",
                              sortCellStyle: activeCol === "tags",
                            })}
                            onClick={() => {
                              tableHeader === "Tags"
                                ? handleTagClick("All", dataa[item].tag_id)
                                : "";
                            }}
                            style={{
                              cursor:
                                tableHeader === "Tags" ? "pointer" : "default",
                              maxWidth: "150px",
                              whiteSpace: "break-spaces",
                            }}
                          >
                            {dataa[item].hasOwnProperty("location_address") ? (
                              <Popup
                                trigger={<div>{item}</div>}
                                size="mini"
                                content={dataa[item].location_address}
                              />
                            ) : (
                              <div>{item}</div>
                            )}
                          </TableCell>
                          {column1 && (
                            <TableCell>
                              <span
                                style={
                                  activeCol === "rating"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {Object.keys(dataa[item]).length
                                  ? dataa[item][column1] && !noFix
                                    ? dataa[item][column1].toFixed(2)
                                    : dataa[item][column1]
                                  : dataa[item]}
                              </span>
                            </TableCell>
                          )}
                          {column6 && !showPercent && (
                            <TableCell>
                              {dataa[item][column6] ? dataa[item][column6] : 0}
                            </TableCell>
                          )}
                          {column28 && showPercent && (
                            <TableCell
                              onClick={() => {
                                tableHeader === "Tags"
                                  ? handleTagClick("Positive", dataa[item].tag_id)
                                  : "";
                              }}
                              style={{
                                cursor:
                                  tableHeader === "Tags"
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              <span
                                style={
                                  activeCol === "positive"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item][column28]
                                  ? dataa[item][column28]
                                  : 0}
                              </span>
                              &nbsp;
                              <DisplayPercentage
                                currentValue={dataa[item][column28]}
                                totalValue={dataa[item][column4]}
                                style={
                                  activeCol === "positive"
                                    ? sortChangeColor("#1f9095")
                                    : { fontSize: "10px", color: "#7A8193" }
                                }
                              />
                            </TableCell>
                          )}
                          {column29 && showPercent && (
                            <TableCell
                              onClick={() => {
                                tableHeader === "Tags"
                                  ? handleTagClick("Negative", dataa[item].tag_id)
                                  : "";
                              }}
                              style={{
                                cursor:
                                  tableHeader === "Tags"
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              <span
                                style={
                                  activeCol === "negative"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item][column29]
                                  ? dataa[item][column29]
                                  : 0}
                              </span>
                              &nbsp;
                              <DisplayPercentage
                                currentValue={dataa[item][column29]}
                                totalValue={dataa[item][column4]}
                                style={
                                  activeCol === "negative"
                                    ? sortChangeColor("#1f9095")
                                    : { fontSize: "10px", color: "#7A8193" }
                                }
                              />
                            </TableCell>
                          )}
                          {column2 && (
                            <TableCell>
                              <span
                                style={
                                  activeCol === "review"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item][column2]}
                              </span>
                            </TableCell>
                          )}
                          {column7 && !showPercent && (
                            <TableCell>
                              {dataa[item][column7] ? dataa[item][column7] : 0}
                            </TableCell>
                          )}
                          {column3 && showPercent && (
                            <TableCell
                              onClick={() => {
                                tableHeader === "Tags"
                                  ? handleTagClick("Neutral", dataa[item].tag_id)
                                  : "";
                              }}
                              style={{
                                cursor:
                                  tableHeader === "Tags"
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              <span
                                style={
                                  activeCol === "neutral"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item][column3]}
                              </span>
                              &nbsp;
                              <DisplayPercentage
                                currentValue={dataa[item][column3]}
                                totalValue={dataa[item][column4]}
                                style={
                                  activeCol === "neutral"
                                    ? sortChangeColor("#1f9095")
                                    : { fontSize: "10px", color: "#7A8193" }
                                }
                              />
                            </TableCell>
                          )}
                          {column8 && !showPercent && (
                            <TableCell>
                              {dataa[item][column8] ? dataa[item][column8] : 0}
                            </TableCell>
                          )}
                          {column5 && (
                            <TableCell>
                              {dataa[item][column5] ? dataa[item][column5] : 0}
                            </TableCell>
                          )}
                          {column9 && (
                            <TableCell>
                              {dataa[item][column9] ? dataa[item][column9] : 0}
                            </TableCell>
                          )}
                          {column11 && showPercent && (
                            <TableCell>
                              {/* <span style={{fontWeight: 500}}>{dataa[item]['oneStar'] ? dataa[item]['oneStar'] : 0}</span> */}
                              <span
                                style={
                                  activeCol === "oneStar"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item]["oneStar"]
                                  ? dataa[item]["oneStar"]
                                  : 0}
                              </span>
                              &nbsp;
                              <DisplayPercentage
                                currentValue={dataa[item]["oneStar"]}
                                totalValue={dataa[item][column10]}
                                style={
                                  activeCol === "oneStar"
                                    ? sortChangeColor("#1f9095")
                                    : { fontSize: "10px", color: "#7A8193" }
                                }
                              />
                            </TableCell>
                          )}
                          {column20 && !showPercent && (
                            <TableCell>
                              {dataa[item]["oneStar %"]
                                ? dataa[item]["oneStar %"]
                                : 0}
                            </TableCell>
                          )}
                          {column12 && showPercent && (
                            <TableCell>
                              <span
                                style={
                                  activeCol === "twoStar"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item]["twoStar"]
                                  ? dataa[item]["twoStar"]
                                  : 0}
                              </span>
                              &nbsp;
                              <DisplayPercentage
                                currentValue={dataa[item]["twoStar"]}
                                totalValue={dataa[item][column10]}
                                style={
                                  activeCol === "twoStar"
                                    ? sortChangeColor("#1f9095")
                                    : { fontSize: "10px", color: "#7A8193" }
                                }
                              />
                            </TableCell>
                          )}
                          {column21 && !showPercent && (
                            <TableCell>
                              {dataa[item]["twoStar %"]
                                ? dataa[item]["twoStar %"]
                                : 0}
                            </TableCell>
                          )}
                          {column13 && showPercent && (
                            <TableCell>
                              <span
                                style={
                                  activeCol === "threeStar"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item]["threeStar"]
                                  ? dataa[item]["threeStar"]
                                  : 0}
                              </span>
                              &nbsp;
                              <DisplayPercentage
                                currentValue={dataa[item]["threeStar"]}
                                totalValue={dataa[item][column10]}
                                style={
                                  activeCol === "threeStar"
                                    ? sortChangeColor("#1f9095")
                                    : { fontSize: "10px", color: "#7A8193" }
                                }
                              />
                            </TableCell>
                          )}
                          {column22 && !showPercent && (
                            <TableCell>
                              {dataa[item]["threeStar %"]
                                ? dataa[item]["threeStar %"]
                                : 0}
                            </TableCell>
                          )}
                          {column14 && showPercent && (
                            <TableCell>
                              <span
                                style={
                                  activeCol === "fourStar"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item]["fourStar"]
                                  ? dataa[item]["fourStar"]
                                  : 0}
                              </span>
                              &nbsp;
                              <DisplayPercentage
                                currentValue={dataa[item]["fourStar"]}
                                totalValue={dataa[item][column10]}
                                style={
                                  activeCol === "fourStar"
                                    ? sortChangeColor("#1f9095")
                                    : { fontSize: "10px", color: "#7A8193" }
                                }
                              />
                            </TableCell>
                          )}
                          {column23 && !showPercent && (
                            <TableCell>
                              {dataa[item]["fourStar %"]
                                ? dataa[item]["fourStar %"]
                                : 0}
                            </TableCell>
                          )}
                          {column15 && showPercent && (
                            <TableCell>
                              <span
                                style={
                                  activeCol === "fiveStar"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item]["fiveStar"]
                                  ? dataa[item]["fiveStar"]
                                  : 0}
                              </span>
                              &nbsp;
                              <DisplayPercentage
                                currentValue={dataa[item]["fiveStar"]}
                                totalValue={dataa[item][column10]}
                                style={
                                  activeCol === "fiveStar"
                                    ? sortChangeColor("#1f9095")
                                    : { fontSize: "10px", color: "#7A8193" }
                                }
                              />
                            </TableCell>
                          )}
                          {column24 && !showPercent && (
                            <TableCell>
                              {dataa[item]["fiveStar %"]
                                ? dataa[item]["fiveStar %"]
                                : 0}
                            </TableCell>
                          )}
                          {column16 && showPercent && (
                            <TableCell>
                              <span
                                style={
                                  activeCol === "rating"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item]["avg_rating"]
                                  ? parseFloat(
                                      dataa[item]["avg_rating"]
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </TableCell>
                          )}
                          {column10 && showPercent && (
                            <TableCell>
                              <span
                                style={
                                  activeCol === "total"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item][column10]
                                  ? dataa[item][column10]
                                  : 0}
                              </span>
                            </TableCell>
                          )}
                          {column17 && showPercent && (
                            <TableCell>
                              <span
                                style={
                                  activeCol === "positive"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item]["positive"]
                                  ? dataa[item]["positive"]
                                  : 0}
                              </span>
                              &nbsp;
                              <DisplayPercentage
                                currentValue={dataa[item]["positive"]}
                                totalValue={dataa[item][column4]}
                                style={
                                  activeCol === "positive"
                                    ? sortChangeColor("#1f9095")
                                    : { fontSize: "10px", color: "#7A8193" }
                                }
                              />
                            </TableCell>
                          )}
                          {column25 && !showPercent && (
                            <TableCell>
                              {dataa[item]["positive %"]
                                ? dataa[item]["positive %"]
                                : 0}
                            </TableCell>
                          )}
                          {column18 && showPercent && (
                            <TableCell>
                              <span
                                style={
                                  activeCol === "negative"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item]["negative"]
                                  ? dataa[item]["negative"]
                                  : 0}
                              </span>
                              &nbsp;
                              <DisplayPercentage
                                currentValue={dataa[item]["negative"]}
                                totalValue={dataa[item][column4]}
                                style={
                                  activeCol === "negative"
                                    ? sortChangeColor("#1f9095")
                                    : { fontSize: "10px", color: "#7A8193" }
                                }
                              />
                            </TableCell>
                          )}
                          {column26 && !showPercent && (
                            <TableCell>
                              {dataa[item]["negative %"]
                                ? dataa[item]["negative %"]
                                : 0}
                            </TableCell>
                          )}
                          {column19 && showPercent && (
                            <TableCell>
                              <span
                                style={
                                  activeCol === "neutral"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item]["neutral"]
                                  ? dataa[item]["neutral"]
                                  : 0}
                              </span>
                              &nbsp;
                              <DisplayPercentage
                                currentValue={dataa[item]["neutral"]}
                                totalValue={dataa[item][column4]}
                                style={
                                  activeCol === "neutral"
                                    ? sortChangeColor("#1f9095")
                                    : { fontSize: "10px", color: "#7A8193" }
                                }
                              />
                            </TableCell>
                          )}
                          {column27 && !showPercent && (
                            <TableCell>
                              {dataa[item]["neutral %"]
                                ? dataa[item]["neutral %"]
                                : 0}
                            </TableCell>
                          )}
                          {column4 && showPercent && (
                            <TableCell
                              onClick={() => {
                                tableHeader === "Tags"
                                  ? handleTagClick("All", dataa[item].tag_id)
                                  : "";
                              }}
                              style={{
                                cursor:
                                  tableHeader === "Tags"
                                    ? "pointer"
                                    : "default",
                              }}
                            >
                              <span
                                style={
                                  activeCol === "total"
                                    ? sortChangeColor("#1f9095")
                                    : { fontWeight: 500 }
                                }
                              >
                                {dataa[item][column4]}
                              </span>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell className="text-center" colSpan={10}>
                        No records Found{" "}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
          ) : (
            !loader && <NoData />
          )}
        </CardWrapper>
      )}
      {zomatoSwiggyRating && (
        <CardWrapper headerTitle={dataHeader} className="p-3">
          <div className="ui-table-responsive">
            <Table className="fp-table fp-table-center">
              <TableHead>
                <TableRow>
                  <TableCell
                    className="width-insights-th"
                    style={{ textAlign: "justify" }}
                  >
                    Branch Name
                  </TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell
                    style={
                      channelActiveCol === "swiggy_display_rating"
                        ? sortChangeColor("#1f9095", "#d9f0f0")
                        : null
                    }
                    onClick={() => sortZomatoSwigyData("swiggy_display_rating")}
                  >
                    Swiggy Display <br /> Rating / Reviews <SortIcon />
                  </TableCell>
                  <TableCell
                    style={
                      channelActiveCol === "zomato_delivery_display_rating"
                        ? sortChangeColor("#1f9095", "#d9f0f0")
                        : null
                    }
                    onClick={() =>
                      sortZomatoSwigyData("zomato_delivery_display_rating")
                    }
                  >
                    Zomato Delivery Display <br /> Rating / Review <SortIcon />
                  </TableCell>
                  <TableCell
                    style={
                      channelActiveCol === "zomato_dining_display_rating"
                        ? sortChangeColor("#1f9095", "#d9f0f0")
                        : null
                    }
                    onClick={() =>
                      sortZomatoSwigyData("zomato_dining_display_rating")
                    }
                  >
                    Zomato Dining Display <br /> Rating / Review <SortIcon />
                  </TableCell>
                  <TableCell
                    style={
                      channelActiveCol === "google_review_rating"
                        ? sortChangeColor("#1f9095", "#d9f0f0")
                        : null
                    }
                    onClick={() => sortZomatoSwigyData("google_review_rating")}
                  >
                    Google <br /> Rating / Review <SortIcon />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {swigyData && swigyData[0].length ? (
                  swigyData[0].map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <FPToolTip
                            title={item.branch_name || item["Branch Name"]}
                          >
                            <div
                              style={{
                                width: "160px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textAlign: "justify",
                              }}
                            >
                              {item.branch_name || item["Branch Name"]}
                            </div>
                          </FPToolTip>
                        </TableCell>
                        <TableCell>{item.date || item["Date"]}</TableCell>
                        <TableCell>
                          <div className="reportRating-tag">
                            <CustomStarRatingComponent
                              name="rating"
                              starCount={1}
                              style={
                                channelActiveCol === "swiggy_display_rating"
                                  ? sortChangeColor("#1f9095")
                                  : { fontWeight: 500 }
                              }
                              // value={setDecimalIndex(
                              //   item.swiggy_display_rating ||
                              //     item["Swiggy Display Rating"]
                              // ) || '1'}
                              value={
                                (+item["Swiggy Display"]["Rating"] < 1
                                  ? 1
                                  : setDecimalIndex(
                                      item["Swiggy Display"]["Rating"]
                                    )) || "1"
                              }
                              editing={false}
                            />
                            <span
                              className="review-info"
                              style={
                                channelActiveCol === "swiggy_display_rating"
                                  ? sortChangeColor("#1f9095")
                                  : null
                              }
                            >
                              {(+item["Swiggy Display"]["Rating"] < 1
                                ? 0
                                : setDecimalIndex(
                                    item["Swiggy Display"]["Rating"]
                                  )) || "0"}
                            </span>
                          </div>
                          <span
                            style={
                              channelActiveCol === "swiggy_display_rating"
                                ? sortChangeColor("#1f9095")
                                : { color: "#7A8193", fontSize: "12px" }
                            }
                          >
                            {item["Swiggy Display"]["Reviews"] || "0"}
                          </span>
                        </TableCell>
                        <TableCell>
                          <div className="reportRating-tag">
                            <CustomStarRatingComponent
                              name="rating"
                              starCount={1}
                              style={
                                channelActiveCol ===
                                "zomato_delivery_display_rating"
                                  ? sortChangeColor("#1f9095")
                                  : { fontWeight: 500 }
                              }
                              value={
                                (+item["Zomato Delivery Display"]["Rating"] < 1
                                  ? 1
                                  : setDecimalIndex(
                                      item["Zomato Delivery Display"]["Rating"]
                                    )) || "1"
                              }
                              editing={false}
                            />
                            <span
                              className="review-info"
                              style={
                                channelActiveCol ===
                                "zomato_delivery_display_rating"
                                  ? sortChangeColor("#1f9095")
                                  : null
                              }
                            >
                              {/* {setDecimalIndex(
                                item.zomato_delivery_display_rating ||
                                  item["Zomato Delivery Display Rating"]
                              ) ||} */}
                              {(+item["Zomato Delivery Display"]["Rating"] < 1
                                ? 0
                                : setDecimalIndex(
                                    item["Zomato Delivery Display"]["Rating"]
                                  )) || "0"}
                            </span>
                          </div>
                          <span
                            style={
                              channelActiveCol ===
                              "zomato_delivery_display_rating"
                                ? sortChangeColor("#1f9095")
                                : { color: "#7A8193", fontSize: "12px" }
                            }
                          >
                            {item["Zomato Delivery Display"]["Reviews"] || "0"}
                          </span>
                        </TableCell>
                        <TableCell>
                          {/* <span style={{fontWeight: 500, display: 'block'}}>{item.zomato_dining_display_rating}</span> */}
                          <div className="reportRating-tag">
                            <CustomStarRatingComponent
                              name="rating"
                              starCount={1}
                              style={
                                channelActiveCol ===
                                "zomato_dining_display_rating"
                                  ? sortChangeColor("#1f9095")
                                  : { fontWeight: 500 }
                              }
                              value={
                                (+item["Zomato Dining Display"]["Rating"] < 1
                                  ? 1
                                  : setDecimalIndex(
                                      item["Zomato Dining Display"]["Rating"]
                                    )) || "1"
                              }
                              // value={setDecimalIndex(
                              //   item.zomato_dining_display_rating ||
                              //     item["Zomato Dining Display Rating"]
                              // ) || '1'}
                              editing={false}
                            />
                            <span
                              className="review-info"
                              style={
                                channelActiveCol ===
                                "zomato_dining_display_rating"
                                  ? sortChangeColor("#1f9095")
                                  : null
                              }
                            >
                              {/* {setDecimalIndex(
                                item.zomato_dining_display_rating ||
                                  item["Zomato Dining Display Rating"]
                              ) || '0'} */}
                              {(+item["Zomato Dining Display"]["Rating"] < 1
                                ? 0
                                : setDecimalIndex(
                                    item["Zomato Dining Display"]["Rating"]
                                  )) || "0"}
                            </span>
                          </div>
                          <span
                            style={
                              channelActiveCol ===
                              "zomato_dining_display_rating"
                                ? sortChangeColor("#1f9095")
                                : { color: "#7A8193", fontSize: "12px" }
                            }
                          >
                            {item["Zomato Dining Display"]["Reviews"] || "0"}
                          </span>
                        </TableCell>
                        {/* <TableCell><span style={{fontWeight: 500}}>{item.zomato_delivery_display_reviews}</span></TableCell>
                          <TableCell><span style={{fontWeight: 500}}>{item.zomato_dining_display_reviews}</span></TableCell> */}
                        {/* <TableCell><span style={{fontWeight: 500}}>{item.google_no_of_reviews}</span></TableCell> */}
                        <TableCell>
                          <div className="reportRating-tag">
                            <CustomStarRatingComponent
                              name="rating"
                              starCount={1}
                              style={
                                channelActiveCol === "google_review_rating"
                                  ? sortChangeColor("#1f9095")
                                  : { fontWeight: 500 }
                              }
                              // value={setDecimalIndex(
                              //   item.google_review_rating ||
                              //     item["Google Display Review Rating"]
                              // ) || '1'}
                              value={
                                (+item["Google Display"]["Rating"] < 1
                                  ? 1
                                  : setDecimalIndex(
                                      item["Google Display"]["Rating"]
                                    )) || "1"
                              }
                              editing={false}
                            />
                            <span
                              className="review-info"
                              style={
                                channelActiveCol === "google_review_rating"
                                  ? sortChangeColor("#1f9095")
                                  : null
                              }
                            >
                              {setDecimalIndex(
                                item["Google Display"]["Rating"]
                              ) || "0"}
                            </span>
                          </div>
                          <span
                            style={
                              channelActiveCol === "google_review_rating"
                                ? sortChangeColor("#1f9095")
                                : { color: "#7A8193", fontSize: "12px" }
                            }
                          >
                            {item["Google Display"]["Reviews"] || "0"}
                          </span>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell className="text-center" colSpan={10}>
                      No records Found{" "}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </CardWrapper>
      )}
      {reviewModelOpen && (
        <TagReviews
          isOpen={reviewModelOpen}
          toggle={() => setReviewModelOpen(!reviewModelOpen)}
          headerText={`${sentimentType} Reviews`}
          sentimentType={sentimentType}
          tagName={tagName}
          business_id={props.businessId}
          queryData={queryData && queryData}
          querySearch={querySearch && querySearch}
        />
      )}
    </div>
  );
};

ReportTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ReportTableComponent;
