import React, { useEffect, useState } from "react";
import "./central-branch.css";
import { Divider } from "semantic-ui-react";
import { Box } from "@material-ui/core";
import { FPButton } from "../common/new";
import AddIcon from "@material-ui/icons/Add";
import { Form, Formik, FieldArray } from "formik";
import FormAutoComplete from "../common/new/forms/FormAutoSelect";
import LoaderComponent from "../common/loaderComponent";
import FormInput from "../common/new/forms/FormInput";
import ConnectDisconnectModal from "../common/ConnectDisconnectModal";

function LocationPublisher(props) {
  const { branchList, socialCommentsActions, pageMapping, getPageMapping, business_id } = props;
  const [emailOption, setEmailOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const [confirmDisconnect, setConfirmDisconnect] = useState(false);
  const [index, setIndex] = useState(-1);

  const addLocationMapping = (data) => {
    setIsLoading(true);
    const commentReq = {
      page_id: data.page_id,
      business_id: business_id,
    };
    data &&
      socialCommentsActions
        .postSocialPageLocationMapping({
          page_id: data.page_id,
          branch_id: data.branch_id,
        })
        .then((res) => {
          getPageMapping();
          setIsLoading(false);
          socialCommentsActions.getSocialComment(commentReq);
        })
        .catch(() => {
          setIsLoading(false);
        });
  };

  const openConfirmModal = (index) => {
    setIndex(index);
    setConfirmDisconnect(true);
  };

  const handleDisconnect = () => {
    setIsLoading(true);
    const data = pageMapping?.linked_pages[index];
    const commentReq = {
      page_id: data.page_id,
      business_id: business_id,
    };
    socialCommentsActions
      .postSocialPageLocationMapping({
        page_id: data.page_id,
        branch_id: data.branch_id,
        connect: false,
      })
      .then((res) => {
        getPageMapping();
        setIsLoading(false);
        // socialCommentsActions.getSocialComment(commentReq);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (pageMapping?.unlinked_profile_pages) {
      const emails = Object.keys(pageMapping?.unlinked_profile_pages);
      setEmailOption(
        emails.map((item) => ({
          display_name: item,
          value: item,
        }))
      );
    }

    if (pageMapping?.linked_pages && branchList) {
      const initialEntries = pageMapping.linked_pages.map((page) => {
        return {
          page_id: page.page_name,
          branch_id: page.branch,
          email: page.email,
        };
      });

      // if (initialEntries.length === 0) {
      //   initialEntries.push({ page_id: "", branch_id: "", email: "" });
      // }

      setEntries(initialEntries);
    }
  }, [pageMapping, branchList]);

  return (
    <>
      <LoaderComponent loader={isLoading} />
      <Formik
        initialValues={{
          mappings: entries,
        }}
        enableReinitialize
      >
        {({ setFieldValue, values, initialValues }) => {
          return (
            <Form>
              <div className="mt-4">
                <div className="my-4 d-flex justify-content-between align-items-center">
                  <Box sx={{ fontWeight: 500, color: "#1B1C1D", fontSize: "17px" }}>
                    Reviews/Inbox/Post Comments/Listings/Publisher/Mentions
                  </Box>
                  <FPButton
                    type="button"
                    size="small"
                    label={"Add New"}
                    backgroundColor={"#F13A88"}
                    startIcon={<AddIcon style={{ height: 16, width: 16, color: "white" }} />}
                    className="ml-2"
                    onClick={() => {
                      setFieldValue("mappings", [...values.mappings, { page_id: "", branch_id: "", email: "" }]);
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <div className="col-4">
                    <Box sx={{ fontWeight: 500, color: "#1B1C1D" }}>Locations</Box>
                  </div>
                  <div className="col-4">
                    <Box sx={{ fontWeight: 500, color: "#1B1C1D" }}>Emails</Box>
                  </div>
                  <div className="col-4">
                    <Box sx={{ fontWeight: 500, color: "#1B1C1D" }}>Page/Profile Name</Box>
                  </div>
                </div>
                <Divider />

                <FieldArray name="mappings">
                  {({ remove, push }) => (
                    <>
                      {values.mappings.map((mapping, index) => {
                        return (
                          <div key={index}>
                            {initialValues.mappings.length > index ? (
                              <>
                                <div className="d-flex justify-content-between">
                                  <div className="col-4 w-50">
                                    <FormInput
                                      name={`mappings.${index}.branch_id`}
                                      placeholder="Locations"
                                      disabled
                                      wrapperClass="mb-0"
                                    />
                                  </div>
                                  <Divider />
                                  <div className="col-4 w-50">
                                    <FormInput
                                      name={`mappings.${index}.email`}
                                      placeholder="Emails"
                                      disabled
                                      wrapperClass="mb-0"
                                    />
                                  </div>
                                  <div className="col-4 d-flex gap-15 w-100">
                                    <FormInput
                                      name={`mappings.${index}.page_id`}
                                      placeholder="Page/Profile Name"
                                      disabled
                                      wrapperClass="mb-0 w-100"
                                    />
                                    <div className="d-flex align-items-center">
                                      <FPButton
                                        size="small"
                                        label={"Disconnect"}
                                        backgroundColor={"#F04438"}
                                        className="ml-2"
                                        onClick={() => openConfirmModal(index)}
                                        style={{ minWidth: "93px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <Divider />
                              </>
                            ) : (
                              <>
                                <div className="d-flex justify-content-between">
                                  <div className="col-4 w-50">
                                    <FormAutoComplete
                                      name={`mappings.${index}.branch_id`}
                                      placeholder="Locations"
                                      options={branchList}
                                    />
                                  </div>
                                  <Divider />
                                  <div className="col-4 w-50">
                                    <FormAutoComplete
                                      name={`mappings.${index}.email`}
                                      placeholder="Emails"
                                      options={emailOption}
                                    />
                                  </div>
                                  <div className="col-4 d-flex gap-15">
                                    <FormAutoComplete
                                      key={mapping.email}
                                      name={`mappings.${index}.page_id`}
                                      placeholder="Page/Profile Name"
                                      options={
                                        pageMapping?.unlinked_profile_pages &&
                                        pageMapping?.unlinked_profile_pages?.hasOwnProperty(mapping.email)
                                          ? pageMapping?.unlinked_profile_pages?.[mapping.email]?.map((item) => ({
                                              value: item.page_id,
                                              display_name: item.page_name,
                                            }))
                                          : []
                                      }
                                      sx={{
                                        width: "100%",
                                        flexGrow: 1,
                                      }}
                                    />
                                    <div className="d-flex align-items-center">
                                      <FPButton
                                        size="small"
                                        label={"Connect"}
                                        backgroundColor={"#2E90FA"}
                                        className="ml-2"
                                        onClick={() => addLocationMapping(mapping)}
                                        style={{ minWidth: "93px" }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <Divider />
                              </>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                </FieldArray>
              </div>
            </Form>
          );
        }}
      </Formik>
      <ConnectDisconnectModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader="All social details will be removed from the respective sections. Are you sure to Disconnect this page ?"
        isOpen={confirmDisconnect}
        toggle={() => setConfirmDisconnect(false)}
        onConfirm={handleDisconnect}
      />
    </>
  );
}

export default LocationPublisher;
