import React from "react";
import "./console.css";
import ConsoleFilter from "./ConsoleFilter";
import BulkDashboard from "./BulkDashboard";
import CategoriesUpdates from "./CategoriesUpdates";
import ServiceUpdate from "./ServiceUpdate";
import SociallinkUpdate from "./SociallinkUpdate";
import WebsiteUpdate from "./WebsiteUpdate";
import RootHeader from "../../../../modules/listing/bulkupdate/RootHeader";
import { bindActionCreators } from "redux";
import * as setSelectedTab from "../../../../actions/common/setSelectedTabAction";
import { connect } from "react-redux";
import LoaderComponent from "../../../../components/common/loaderComponent";
import * as locationActions from "../../../../actions/listing/listingLocationAction";
import queryString from "query-string";

class ConsolePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryStringData: [],
      nextSearch: [],
      prevSearch: [],
    };
  }

  componentDidMount() {
    this.props.actionsSelectTab.setSelectedTab(2);
    document.title = "listing-bulk";
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;

    this.props.actions.getGmbListingLoaction(business_id, "", "", "", "", ""); // Replace 'yourProvider' with the actual provider
  }

  componentWillReceiveProps = (nextProps) => {
    const {
      location: { search: prevSearch },
    } = this.props;
    this.setState({ prevSearch: prevSearch });
    const {
      match: {
        params: { business_id },
      },
      location: { search: nextSearch },
    } = nextProps;
    this.setState({ nextSearch: nextSearch });
    if (prevSearch !== nextSearch) {
      const queryParams = queryString.parse(nextSearch);
      this.setState({ queryStringData: queryParams });
      this.props.actions.getGmbListingLoaction(
        business_id,
        queryParams?.gmb_location_type,
        queryParams?.status_type,
        queryParams?.completion_status,
        queryParams?.openstatus,
        queryParams?.search
      );
    }
  };

  render() {
    const {
      loader,
      match: {
        params: { business_id },
      },
      locationList,
      filterBusinessCount,
      filter,
    } = this.props;
    const { queryStringData, nextSearch, prevSearch } = this.state;

    const formattedAccountData = (filter?.account_date_type?.results || []).map(
      (item) => ({
        value: item.id,
        display_name: item.account_name,
        ...item,
      })
    );
    return (
      <>
        <div style={{ padding: "16px" }}>
          <LoaderComponent loader={loader} />
          <RootHeader defaultValue={0} history={this.props.history} />

          <ConsoleFilter
            history={this.props.history}
            location={this.props.location}
            business_id={business_id}
            formattedAccountData={formattedAccountData}
          />

          {/* <SearchComponent placeholder="Search Location" {...props} /> */}
          <div>
            <div style={{ paddingTop: "16px" }}>
              <div className="d-flex align-baseline gap-10 ">
                <h2
                  style={{
                    color: "#060658",
                    fontSize: "18px",
                    fontWeight: "500",
                    margin: "0",
                  }}
                >
                  Bulk Dashboard{" "}
                </h2>
                <h3
                  style={{
                    color: "#F13A88",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  ({/* {filterBusinessCount} Selected / */}
                  {locationList
                    ? locationList?.[0]?.verified_locations
                    : 0}{" "}
                  Total listings)
                </h3>
              </div>
            </div>
          </div>
          <div>
            <div style={{ paddingBlock: "12px" }}>
              <BulkDashboard
                business_id={business_id}
                locationList={locationList}
                queryStringData={queryStringData}
                filterBusinessCount={
                  locationList ? locationList?.[0]?.verified_locations : 0
                }
              />
            </div>
          </div>
          <div>
            <div className="flex align-center gap-10">
              <div
                style={{
                  paddingBlock: "10px",
                  width: "100%",
                }}
              >
                <CategoriesUpdates
                  business_id={business_id}
                  locationList={locationList}
                  queryStringData={queryStringData}
                  filterBusinessCount={
                    locationList ? locationList?.[0]?.verified_locations : 0
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div
              className="flex align-center gap-10 "
              // style={{ paddingBlock: "25px" }}
            >
              <div
                style={{
                  paddingBlock: "10px",
                  width: "100%",
                }}
              >
                <ServiceUpdate
                  business_id={business_id}
                  locationList={locationList}
                  queryStringData={queryStringData}
                  filterBusinessCount={
                    locationList ? locationList?.[0]?.verified_locations : 0
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div
              className="flex align-center gap-10 "
              // style={{ paddingBlock: "25px" }}
            >
              <div
                style={{
                  paddingBlock: "10px",
                  width: "100%",
                }}
              >
                <SociallinkUpdate
                  business_id={business_id}
                  locationList={locationList}
                  queryStringData={queryStringData}
                  filterBusinessCount={
                    locationList ? locationList?.[0]?.verified_locations : 0
                  }
                />
              </div>
            </div>
          </div>
          <div>
            <div
              className="flex align-center gap-10"
              // style={{ paddingBlock: "25px" }}
            >
              <div
                style={{
                  paddingBlock: "10px",
                  width: "100%",
                }}
              >
                <WebsiteUpdate
                  business_id={business_id}
                  locationList={locationList}
                  queryStringData={queryStringData}
                  filterBusinessCount={
                    locationList ? locationList?.[0]?.verified_locations : 0
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locationList: state.gmbListingLocation.location_list,
    filterBusinessCount: state.gmbListingLocation.filterBusinessCount,
    error: state.gmbListingLocation.location_error,
    loader: state.common.loader,
    filter: state.filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsolePage);
