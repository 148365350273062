import * as types from "../../actions/actionTypes";

export default function socialCommentsReducer(state = [], action) {
  switch (action.type) {
    case types.GET_SOCIAL_COMMENTS_SUCCESS:
      return Object.assign({}, state, { comments: action.data });
    case types.GET_SOCIAL_COMMENTS_FAILURE:
      return Object.assign({}, state, { comments: action.data });
    case types.GET_SOCIAL_PAGE_LOCATION_MAPPING_SUCCESS:
      return Object.assign({}, state, { pageLocation: action.data });
    case types.GET_SOCIAL_PAGE_LOCATION_MAPPING_FAILURE:
      return Object.assign({}, state, { pageLocation: action.data });
    default:
      return state;
  }
}
