import React from "react";
import PropTypes from "prop-types";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import SearchComponent from "../common/SearchComponent";
import LoaderComponent from "../common/loaderComponent";
import { useState } from "react";
import { Checkbox, Divider } from "semantic-ui-react";
import { FPButton } from "../common/new";
import "./SmartResponseList.module.css";
import { FaPlus } from "react-icons/fa6";
import classnames from "classnames";
import "./SmartResponse.css";
import information from "../../images/Information.png";
import SmartReplyTable from "./SmartReplyTable";
import AIReplyTable from "./AIReplyTable";

const SmartResponseListComponent = (props) => {
  const {
    smartResponseList,
    aiResponseList,
    addResponse,
    addAiResponse,
    sortFunction,
    ordering,
    businessId,
    smartResponseOnCheck,
    aiResponseOnCheck,
    functionalityToggle,
    activeUser,
    loader,
    count,
    location,
    pageSizeCookie,
    deleteResponse,
    deleteAiResponse,
    aiResponseCount,
  } = props;
  // const sort =
  //   ordering && ordering[0] === "-"
  //     ? { on: ordering.slice(1), by: "descending" }
  //     : { on: ordering, by: "ascending" };
  // const [toggle, setToggle] = useState("ascending");
  const [activeTab, setActiveTab] = useState("1");

  // const sortCreatedAtFunction = () => {
  //   if (toggle === "ascending") {
  //     smartResponseList.sort(
  //       (a, b) => new Date(b.created_at) - new Date(a.created_at)
  //     );
  //     setToggle("descending");
  //   }
  //   if (toggle === "descending") {
  //     smartResponseList.sort(
  //       (a, b) => new Date(a.created_at) - new Date(b.created_at)
  //     );
  //     setToggle("ascending");
  //   }
  // };

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Divider className="mb-0"></Divider>
      <Nav className="smart-Nav">
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "1",
            })}
            onClick={() => {
              toggleTab("1");
            }}
          >
            Smart Reply
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === "2",
            })}
            onClick={() => {
              toggleTab("2");
            }}
          >
            AI Reply
          </NavLink>
        </NavItem>
      </Nav>
      <Divider className="mt-1"></Divider>
      <LoaderComponent loader={loader} />

      <div>
        <TabContent activeTab={activeTab} className="mt-2">
          <TabPane tabId="1">
            <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "400",
                  color: "rgba(100, 101, 101, 1)",
                }}
              >
                Smart Reply
              </span>
              <div className="d-flex align-items-center gap-15">
                <SearchComponent {...props} className="contacts-filter-search" startIcon placeholder="Search here" />
                {smartResponseOnCheck && activeUser.is_business_owner && (
                  <FPButton
                    label="Add Reply"
                    size="small"
                    onClick={addResponse}
                    endIcon={<FaPlus style={{ fontSize: "14px" }} />}
                  />
                )}
              </div>
            </div>
            <div>
              {activeUser.is_business_owner && (
                <div
                  className="container-fluid d-flex justify-content-between py-3 align-items-center my-4"
                  style={{
                    background: "#ECFDF3",
                    gap: "20px",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex align-items-center " style={{ gap: "10px" }}>
                    <img src={information} />
                    <span style={{ color: "#039855", fontSize: "10px" }}>
                      {`Smart Response And Autoresponding is currently ${smartResponseOnCheck ? "ENABLED" : "DISABLED"}.
                      Templates are being used by staff/auto-responding for public responding.
                      ${
                        smartResponseOnCheck
                          ? "If you would like to change/edit templates. Please DISABLE the feature here."
                          : ""
                      }`}
                    </span>
                  </div>
                  <div className="">
                    <Checkbox
                      toggle
                      checked={smartResponseOnCheck}
                      onClick={(e, value) => functionalityToggle(value.checked)}
                    />
                  </div>
                </div>
              )}

              {/* Smart-Table */}
              <SmartReplyTable
                smartResponseOnCheck={smartResponseOnCheck}
                ordering={ordering}
                smartResponseList={smartResponseList}
                sortFunction={sortFunction}
                activeUser={activeUser}
                businessId={businessId}
                count={count}
                location={location}
                pageSizeCookie={pageSizeCookie}
                deleteResponse={deleteResponse}
              />
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div>
              {aiResponseOnCheck && (
                <div>
                  <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "400",
                        color: "rgba(100, 101, 101, 1)",
                      }}
                    >
                      AI Reply
                    </span>
                    <div className="d-flex align-items-center gap-15">
                      <SearchComponent
                        {...props}
                        className="contacts-filter-search"
                        startIcon
                        placeholder="Search here"
                      />
                      {smartResponseOnCheck && activeUser.is_business_owner && (
                        <FPButton
                          label="Add AI Response"
                          size="small"
                          onClick={addAiResponse}
                          endIcon={<FaPlus style={{ fontSize: "14px" }} />}
                        />
                      )}
                    </div>
                  </div>

                  <div className="my-2 py-3 d-flex" style={{ background: "#ECFDF3", borderRadius: "10px" }}>
                    <div className="d-flex align-items-center" style={{}}>
                      <img src={information} className="mx-3" />
                      <span style={{ color: "#039855", fontSize: "10px" }}>
                        AI Response rules will only work for reviews with some content(description). To setup auto reply
                        for only rating reviews(without content), Please setup Smart Response rules with auto enabled.
                      </span>
                    </div>
                  </div>

                  <AIReplyTable
                    aiResponseOnCheck={aiResponseOnCheck}
                    ordering={ordering}
                    sortFunction={sortFunction}
                    aiResponseList={aiResponseList}
                    activeUser={activeUser}
                    businessId={businessId}
                    count={aiResponseCount}
                    location={location}
                    pageSizeCookie={pageSizeCookie}
                    smartResponseOnCheck={smartResponseOnCheck}
                    deleteAiResponse={deleteAiResponse}
                  />
                </div>
              )}
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

SmartResponseListComponent.propTypes = {
  SmartResponseList: PropTypes.array.isRequired,
};

export default SmartResponseListComponent;
