import React from 'react';
import FPSelect from './ui/FPSelect';
import { useField } from 'formik';
import ErrorMessage from './ui/ErrorMessage';

const FormSelect = ({ name, label, options, ...rest }) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  return (
    <div className='mb-20'>
      <FPSelect
        {...field}
        label={label}
        error={!!(meta.touched && meta.error)}
        {...rest}
        options={options}
        onChange={(event) => setValue(event.target.value)}
        {...rest}
      />
      {meta.touched && meta.error && (
        <ErrorMessage>{meta.error}</ErrorMessage>
      )}
    </div>
  );
};
export default FormSelect;
