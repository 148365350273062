import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
// import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import SocialSelectButton from "../common/SocialSelectButton";
import { sourceImages } from "../../images/source";
// import LocationReviewsTable from "./LocationReviewsTable";
// import { FPCheckbox } from "../common/new";
// import { Box } from "@material-ui/core";
import LocationPublisher from "./LocationPublisher";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import { displayMessage } from "../../libs/utils";
import cookie from "react-cookies";
import * as SocialCommentsActions from "../../actions/socialComments/socialCommentsActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

const socialData = [
  // {
  //   value: 1,
  //   display_name: "Google",
  //   logo: sourceImages.google,
  // },
  {
    value: 2,
    display_name: "Facebook",
    logo: sourceImages.facebook,
  },
  // {
  //   value: 3,
  //   display_name: "Twitter",
  //   logo: sourceImages.twitter,
  // },
  // {
  //   value: 4,
  //   display_name: "Instagram",
  //   logo: sourceImages.instagram,
  // },
  // {
  //   value: 5,
  //   display_name: "Youtube",
  //   logo: sourceImages.youtube,
  // },
];
const LocationMapping = ({
  business_id,
  socialCommentsActions,
  data,
  pageMapping,
  connectedProvidersList
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [selectedCheckbox, setSelectedCheckbox] = useState("");

  const [emailsOption, setEmailOptions] = useState([]);

  const [pageProfile, setPageProfile] = useState([]);

  const [reviewData, setReviewData] = useState([]);

  const branchList =
    pageMapping &&
    pageMapping.unlinked_branches.map((branch) => ({
      value: branch.branch_id,
      display_name: branch.location_address,
    }));

  useEffect(() => {
    const { email } = cookie.load("user");
    const email_arr = [];
    email_arr.push({ value: email, display_name: email });
    setEmailOptions(email_arr);

    !!data?.[0] &&
      business_id && connectedProvidersList &&
      axios_instance
        .get(
          `${URLS.SOCIAL_PROFILE_INTEGRATION}facebook/pages/?business_id=${business_id}&web_portal_id=${connectedProvidersList}&is_published=False&is_linked=True`
        )
        .then((res) => {
          const profile_page = [];
          res.data.connected_pages?.map((page) => {
            profile_page.push({
              display_name: page.page_name,
              value: page.page_id,
            });
            return page;
          });
          setPageProfile(profile_page);
        })
        .catch((err) => console.log(err));

    getPageMapping();
  }, [business_id, data]);

  const getPageMapping = () => {
    if (!!data?.[0] && business_id) {
      const params = {
        business_id: business_id,
        web_portal_id: connectedProvidersList,
      };

      socialCommentsActions
        .getSocialPageLocationMapping(params)
        .then((res) => {})
        .catch((err) => console.log(err));
    }
  };

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const checkBoxHandler = (e) => {
    setSelectedCheckbox(e.target.name);
  };

  const handleConnect = (index, pageBody) => {
    const reqBody = {
      branch_id: reviewData[index]?.id,
      page_id: pageBody?.value,
    };
    axios_instance
      .post(`${URLS.SOCIAL_PROFILE_INTEGRATION}page-location-mapping`, reqBody)
      .then((res) => {
        console.log(res);
        displayMessage("positive", "Successfully Connected");
      })
      .catch((err) => {
        displayMessage("negative", "Error ! Not Connected");
        console.log(err);
      });
  };

  return (
    <div>
      <SocialSelectButton socialData={socialData} />
      <Row className="">
        <Col xs={12} sm={12} className="business-set-- p-3">
          <Row className="m-0">
            <Col xs={12} sm={12} className="p-0">
              {/* <div className="d-flex justify-content-between">
                <Nav className="Central_nav">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggleTab("1");
                      }}
                    >
                      Reviews
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggleTab("2");
                      }}
                    >
                      Listings
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        toggleTab("3");
                      }}
                    >
                      Publisher
                    </NavLink>
                  </NavItem>
                </Nav>
                {(activeTab === "1" || activeTab === "2") && (
                  <div className="d-flex gap-10 align-items-center">
                    <div>
                      <FPCheckbox name="Map" onChange={checkBoxHandler} checked={selectedCheckbox === "Map"} />
                      <Box component="span" sx={{ fontSize: "14px", color: "#1B1C1D" }}>
                        Mapped
                      </Box>
                    </div>

                    <div>
                      <FPCheckbox
                        name="Unmapped"
                        onChange={checkBoxHandler}
                        checked={selectedCheckbox === "Unmapped"}
                      />
                      <Box component="span" sx={{ fontSize: "14px", color: "#1B1C1D" }}>
                        Unmapped
                      </Box>
                    </div>
                  </div>
                )}
              </div> */}
              {/* <div className="mt-2">
                <TabContent activeTab={activeTab} className="mt-2">
                  <TabPane tabId="1">
                    <LocationReviewsTable
                      handleConnect={handleConnect}
                      reviewData={reviewData}
                      emailsOption={emailsOption}
                      pageProfile={pageProfile}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <LocationReviewsTable />
                  </TabPane>
                  <TabPane tabId="3">
                    <LocationPublisher
                      branchList={branchList}
                      pageProfile={pageProfile}
                      socialCommentsActions={socialCommentsActions}
                    />
                  </TabPane>
                </TabContent>
              </div> */}
              <LocationPublisher
                branchList={branchList}
                pageProfile={pageProfile}
                emailsOption={emailsOption}
                socialCommentsActions={socialCommentsActions}
                getPageMapping={getPageMapping}
                pageMapping={pageMapping}
                business_id={business_id}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    pageMapping: state.socialComments.pageLocation,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    socialCommentsActions: bindActionCreators(SocialCommentsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(LocationMapping);
