export const placeActionTypeMetadata = 

    {
        "placeActionTypeMetadata": [
            {
                "placeActionType": "FOOD_ORDERING",
                "displayName": "Food ordering links"
            },
            {
                "placeActionType": "FOOD_DELIVERY",
                "displayName": "Food delivery links"
            },
            {
                "placeActionType": "FOOD_TAKEOUT",
                "displayName": "Food takeout links"
            },
            {
                "placeActionType": "DINING_RESERVATION",
                "displayName": "Reservations links"
            }
        ]
    }
