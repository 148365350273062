import * as types from '../../actions/actionTypes';

export default function businessSetup(state = [], action) {
  switch (action.type) {
    case types.GET_BUSINESS_SUCCESS:
      return Object.assign({}, state, { list: { ...action.data } });
    case types.BUSINESS_SETUP_SUCCESS:
      return Object.assign({}, state, { info: { ...action.data } });
    case types.BUSINESS_BRANCH_DETAILS_SUCCESS:
      return Object.assign({}, state, { info: { ...action.data } });
    case types.BUSINESS_BRANCH_DEATILS_FAILURE:
      return action.data;
    case types.GET_BUSINESS_SOCIAL_LINKS_SUCCESS:
      return Object.assign({}, state, { social_links: action.data });
    case types.GET_BUSINESS_COMPLAINTS_LINKS_SUCCESS:
      return Object.assign({}, state, { complaint_links: action.data });
    case types.BUSINESS_BRANCH_OPTIONS_SUCCESS:
      return Object.assign({}, state, { branches: { ...action.data } });
    case types.ENTERPRISE_LIST_SUCCESS:
      return Object.assign({}, state, { enterprise: { ...action.data } });
    case types.ENTERPRISE_USER_LIST_SUCCESS:
      return Object.assign({}, state, { userList: { ...action.data } });
    case types.BUSINESS_DETAIL_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.BUSINESSLIST_SUCCESS:
      return Object.assign({}, state, { business: { ...action.data } });
    case types.BUSINESSLIST_FAILURE:
      return Object.assign({}, state, { business: { ...action.data } });
    case types.RESPONSE_LIST_SUCCESS:
      return Object.assign({}, state, { response_list: { ...action.data } });
    case types.STAFF_LIST_SUCCESS: 
      return Object.assign({}, state, { staff_list: { ...action.data } });
    case types.STAFF_LIST_FAILURE:
      return Object.assign({}, state, { staff_list: { ...action.data } });
    case types.STAFF_DETAIL_SUCCESS:
      return Object.assign({}, state, { staff_detail: { ...action.data } });
    case types.STAFF_DETAIL_FAILURE:
      return Object.assign({}, state, { staff_detail: { ...action.data } });
    case types.AI_RESPONSE_LIST_SUCCESS:
      return Object.assign({}, state, { airesponse_list: { ...action.data } });
    case types.ALLOWED_FOR_OPTIONS_SUCCESS:
      return Object.assign({}, state, { allowed_for_options: { ...action.data } });
    case types.REVIEW_TYPE_OPTIONS_SUCCESS:
      return Object.assign({}, state, { review_type_options: { ...action.data } });
    case types.GET_RESPONSE_DETAILS_SUCCESS:
      return Object.assign({}, state, { response_details: { ...action.data } });
    case types.GET_AI_RESPONSE_DETAILS_SUCCESS:
      return Object.assign({}, state, { ai_response_details: { ...action.data } });  
    case types.RESPONSE_OPTIONS_SUCCESS:
      return Object.assign({}, state, { 'response_options': { ...action.data }, response_details: {} });
    case types.RESPONSE_OPTIONS_FAILURE:
      return Object.assign({}, state, { 'response_options': {}, response_details: {} });
    case types.TOGGLE_SMART_RESPONSE_SUCCESS:
      return Object.assign({}, state, { detail: { ...state.detail, ...action.data } });
    case types.GET_INSIGHTS_CATEGORY_SUCCESS:
      return Object.assign({}, state, { insights_category_list: action.payload });
    case types.GET_INSIGHTS_CATEGORY_FAILURE:
      return Object.assign({}, state, { insights_category_list: action.payload });
    case types.POST_INSIGHTS_CATEGORY_SUCCESS:
      return Object.assign({}, state, { category: { ...action.data } });
    case types.INSIGHTS_LIST_DELETE_SUCCESS:
      return Object.assign({}, state, { insight: action.data });
    case types.INSIGHTS_LIST_DELETE_FAILURE:
      return Object.assign({}, state, { insight: action.data });
    case types.GET_INSIGHTS_DETAIL_SUCCESS:
      return Object.assign({}, state, { insight_detail: { ...action.data } });
    case types.GET_RECOMMENDED_KEYWORDS_SUCCESS:
      return Object.assign({}, state, { recommended_keywords: { ...action.data } });
    case types.RELEVANT_COUNTRIES_SUCCESS:
      return Object.assign({}, state, { countryList: action.data });
    case types.RELEVANT_COUNTRIES_FAILURE:
      return Object.assign({}, state, action.data);
    case types.RELEVANT_STATES_SUCCESS:
      return Object.assign({}, state, { stateList: action.data });
    case types.RELEVANT_STATES_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GET_TAGS_LIST_SUCCESS:
      return Object.assign({}, state, { tags_list: [ ...action.data ] });
    case types.GET_TAGS_LIST_FAILURE:
      return Object.assign({}, state, { tags_list: action.data });
    case types.GET_LOCKED_MODULES_SUCCESS:
      return Object.assign({}, state, { lockedModules: { ...action.data[0] }  });
    case types.ENTERPRISE_DATA_SUCCESS:
      return Object.assign({}, state, { enterprise_list: { ...action.data } });
    case types.ENTERPRISE_DATA_FAILURE:
      return Object.assign({}, state, { enterprise_list: action.data });
    case types.TICKETS_RULES_DATA_SUCCESS:
      return Object.assign({}, state, { ticket_list: action.data });
    case types.TICKETS_RULES_DATA_FAILURE:
      return Object.assign({}, state, { ticket_list: action.data });
    case types.BRANCH_LIST_SUCCESS:
      return Object.assign({}, state, { branch_list: action.data });
    case types.BRANCH_LIST_FAILURE:
      return Object.assign({}, state, { branch_list: action.data });
    case types.REVIEW_CONTENT_TYPE_OPTIONS_SUCCESS:
      return Object.assign({}, state, { review_content_type_options: { ...action.data } });
    case types.BRANCH_OWNERS_SUCCESS:
      return Object.assign({}, state, { branch_owners_list: action.data });
    case types.BRANCH_OWNERS_FAILURE:
      return Object.assign({}, state, { branch_owners_list: action.data });
    case types.RULE_DETAIL_SUCCESS:
      return Object.assign({}, state, { rule_details: { ...action.data } });
    case types.COUPON_LIST_SUCCESS:
      return Object.assign({}, state, { coupon_list_data: action.data });
    case types.COUPON_LIST_FAILURE:
      return Object.assign({}, state, { coupon_list_data: action.data });
    case types.COUPON_SUMMARY_SUCCESS:
      return Object.assign({}, state, { coupon_summary: action.data });
    case types.COUPON_SUMMARY_FAILURE:
      return Object.assign({}, state, { coupon_summary: action.data });
    case types.TICKET_RULE_PROVIDERS_SUCCESS:
      return Object.assign({}, state, { ticket_provider_options: action.data });
    case types.TICKET_RULE_PROVIDERS_FAILURE:
      return Object.assign({}, state, { ticket_provider_options: action.data });
    case types.GET_CATEGORY_TAGS_LIST_SUCCESS:
      return Object.assign({}, state, { category_tags_list: [ ...action.data ] });
    case types.GET_CATEGORY_TAGS_LIST_FAILURE:
      return Object.assign({}, state, { category_tags_list: action.data });
    case types.GET_PRODUCT_NAME_LIST_SUCCESS:
        return Object.assign({}, state, { productNameList: [ ...action.data ] });
    case types.GET_PRODUCT_NAME_LIST_FAILURE:
        return Object.assign({}, state, { productNameList: [] });
    default:
      return state;
  }
}
