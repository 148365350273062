import * as types from '../../actions/actionTypes';

export default function changePasswordReducer(state=[], action) {
  switch (action.type) {
    case types.CHANGE_PASSWORD_SUCCESS:
      return action.data;
    case types.CHANGE_PASSWORD_FAILURE:
      return action.data;
    case types.RESET_PASSWORD_SUCCESS:
      return action.data;
    case types.RESET_PASSWORD_FAILURE:
      return action.data;
    default:
      return state;
  }
}