import { Divider } from "semantic-ui-react";
import { FPAvatar } from "../../components/common/new";
import CardWrapper from "../dashboard/component/CardWrapper";
import { Box, Popover } from "@material-ui/core";
import Redirect from "../../images/svg/Redirect.svg";
import eyesSvg from "../../images/svg/eyesvg.svg";
import Replay from "../../images/svg/replyIcon.svg";
import deleteIcon from "../../images/svg/deleteSvg.svg";
import { useState } from "react";
import FormModal from "../../components/common/FormModal";
import SocialCommentsCardPopup from "./SocialCommentsCardPopup";
import EmojiPicker from "emoji-picker-react";
import TemplateModal from "./TemplateModal";
import { sourceImages } from "../../images/source";
import ThumbsUp from "../../images/svg/ThumsUp.svg";
import ThumbsDown from "../../images/svg/ThumsDown.svg";
import AddComments from "./AddComments";
import moment from "moment";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import ThreadComponent from "./ThreadComponent";

const SocialCommentsCard = (props) => {
  const { item, getComments, pageData, socialCommentsActions, setLoader } = props;
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [commentData, setCommentData] = useState(null);
  const [commentText, setCommentText] = useState("");

  const handleReplyComment = (item) => {
    const req = {
      page_id: item.page_id,
      post_id: item.post_id,
      comment_text: commentText,
      comment_id: item.comment_id,
    };
    socialCommentsActions.postSocialComment(req).then((res) => {
      setCommentText("");
      setShowReply(false);
      getComments(pageData);
    });
  };

  const toggleConfirm = () => {
    handleDeleteComment();
    setIsConfirm(false);
  };

  const handleCommentChange = (e) => {
    setCommentText(e.target.value);
  };

  const handleDeleteComment = () => {
    setLoader(true);
    const deleteReq = {
      page_id: commentData.page_id,
      comment_id: commentData.comment_id,
      is_thread: false,
    };
    socialCommentsActions.deleteSocialComment(deleteReq).then((res) => {
      getComments(pageData);
    });
  };

  const handleClose = () => {
    setOpenEmojiPicker(null);
  };
  const handleClick = (data) => {
    setCommentData(data);
    setIsConfirm(!isConfirm);
  };
  const open = Boolean(openEmojiPicker);

  const toggleTemplateOpen = () => {
    setIsTemplateOpen(!isTemplateOpen);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleReply = () => {
    setShowReply(!showReply);
  };
  const handleEmojiSelect = ({ emoji }) => {
    setCommentText((preValue) => preValue + " " + emoji);
  };

  return (
    <>
      <CardWrapper className="fp-card-outline review-card-wrapper mt-4 social-comments-wrapper" showDivider={false}>
        <section className="review-card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <FPAvatar
              // alt={item.reviewer.name}
              src={item.from_profile_pic}
              labelClass="user-name"
              subLabel={`Commented on the post shared by ${item.post_from_name}`}
              userName={item.from_name}
              subLabelClass="d-block"
            />
            <div>
              <img src={sourceImages.facebook} style={{ width: "30px", height: "30px" }} />
            </div>
          </div>
          <Divider />
          <div className="d-flex gap-20">
            <div>
              <img
                src={item.post_image}
                className="cursor-pointer"
                style={{ height: "100px", width: "100px", borderRadius: "12px" }}
                onClick={() => toggleOpen()}
              />
            </div>
            <div className="d-flex flex-column w-100">
              <div className="d-flex justify-content-between">
                <div>
                  <Box
                    component="span"
                    sx={{
                      color: "#7A8193",
                      display: "block",
                      marginBottom: "4px",
                      fontSize: "13px",
                    }}
                  >
                    {moment(item.created_time).format("llll")}
                  </Box>
                  <Box component="span" sx={{ color: "#7A8193", display: "block", fontSize: "15px" }}>
                    {item.message}
                  </Box>
                  <div className="d-flex gap-15 mt-2 align-items-center">
                    <Box component="div" sx={{ color: "#1B1C1D" }} className="d-flex align-items-center gap-10">
                      {/* <img
                        src={likeIcon}
                        style={{ height: "16px", width: "16px", marginRight: "10px", cursor: "pointer" }}
                      /> */}
                      <div className="d-flex align-items-center gap-10">
                        <img src={ThumbsUp} />
                        <img src={ThumbsDown} />
                      </div>
                      <div className="d-flex align-items-center gap-6 cursor-pointer" onClick={() => handleReply()}>
                        <img src={Replay} style={{ height: "20px", width: "18px" }} /> Reply
                      </div>
                    </Box>
                  </div>
                </div>
                <div className="d-flex gap-15 align-items-baseline">
                  <img
                    src={eyesSvg}
                    alt="EyesIcon"
                    style={{ height: "18px", width: "18px", cursor: "pointer" }}
                    onClick={() => toggleOpen()}
                  />
                  <img
                    src={deleteIcon}
                    alt="delete Icon"
                    style={{ height: "18px", width: "18px", cursor: "pointer" }}
                    onClick={() => handleClick(item)}
                  />
                </div>
              </div>

              {showReply && (
                <AddComments
                  item={item}
                  commentText={commentText}
                  handleCommentChange={handleCommentChange}
                  toggleTemplateOpen={toggleTemplateOpen}
                  setOpenEmojiPicker={setOpenEmojiPicker}
                  handleReplyComment={handleReplyComment}
                />
              )}

              {item.threads.length > 0 &&
                item.threads.map((rpl, i) => {
                  return (
                    <ThreadComponent
                      rpl={rpl}
                      key={i}
                      socialCommentsActions={socialCommentsActions}
                      getComments={getComments}
                      pageData={pageData}
                    />
                  );
                })}
            </div>
          </div>
          <Divider />
          <div>
            <Box
              component="span"
              className="d-flex align-items-center gap-6"
              sx={{ fontSize: "16px", color: "#1B1C1D", cursor: "pointer" }}
              onClick={() => {
                window.open(item.post_target_url, "_blank");
              }}
            >
              View Post <Redirect />
            </Box>
          </div>
        </section>

        <FormModal
          isOpen={isOpen}
          toggle={toggleOpen}
          width="70%"
          height="80%"
          contentClass="mb-0"
          maxWidth="xl"
          headerWrapperClass="pt-2 pb-2 px-2"
          bodyClass="pb-2 mb-0 pt-0"
        >
          <SocialCommentsCardPopup
            commentDetails={item}
            commentText={commentText}
            handleCommentChange={handleCommentChange}
            toggleTemplateOpen={toggleTemplateOpen}
            setOpenEmojiPicker={setOpenEmojiPicker}
            handleReply={handleReply}
            getComments={getComments}
            pageData={pageData}
            socialCommentsActions={socialCommentsActions}
          />
        </FormModal>

        <FormModal isOpen={isTemplateOpen} toggle={toggleTemplateOpen} width="750px" heading={"Templates"}>
          <TemplateModal />
        </FormModal>

        <Popover
          open={open}
          anchorEl={openEmojiPicker}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <EmojiPicker
            skinTonesDisabled={true}
            width={300}
            size={10}
            lazyLoad={true}
            lazyLoadEmojis={true}
            onEmojiClick={handleEmojiSelect}
          />
        </Popover>
        <ConfirmationModal
          header="Are you sure!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader={`Are you sure you want to delete this comment?`}
          isOpen={isConfirm}
          toggle={() => setIsConfirm(!isConfirm)}
          onConfirm={() => toggleConfirm()}
        />
      </CardWrapper>
    </>
  );
};

export default SocialCommentsCard;
