import React, { useEffect } from "react";
import success_svg from "../../../../images/suceess.svg";
import { Box, Dialog, DialogActions, IconButton } from "@mui/material";
import BusinessList from "./BusinessList";
import CloseIcon from "@material-ui/icons/Close";
import UpdatePhoneNuimber from "./Models/UpdatePhoneNumber";
import UpdateWebsiteLink from "./Models/UpdateWebsiteLink";
import UpdateAppointment from "./Models/UpdateAppointment";
import UpdateCategory from "./Models/UpdateCategory";
import UpdateOpenHour from "./Models/UpdateOpenHour";
import UpdateAttribute from "./Models/UpdateAttribute";
import UpdatePhotos from "./Models/UpdatePhotos";
import UpdateQuestion from "./Models/UpdateQuestion";
import UpdateDescription from "./Models/UpdateDescription";
import UpdateOpenDate from "./Models/UpdateOpenDate";
import * as locationActions from "../../../../actions/listing/listingLocationAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FormModal from "../../../../components/common/FormModal";

const BulkDashboard = (props) => {
  const { business_id, actions, console_list_data, queryStringData } = props;
  const [openTab, setOpenTab] = React.useState(null);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (dialog, scrollType) => {
    setOpenTab(dialog);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpenTab(null);
  };

  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };

  useEffect(() => {
    actions.getGmbListingConsoleLog(
      business_id,
      queryStringData?.gmb_location_type,
      "verified",
      queryStringData?.completion_status,
      queryStringData?.openstatus,
      queryStringData?.search
    );
  }, [business_id, queryStringData]);

  return (
    <>
      <div
        className="bulk-dashboard-grid"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
          gap: "12px",
        }}
      >
        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }}
          onClick={() => handleClickOpen("phone_number", "paper")}
        >   </div> */}
        <div
          className="bulk-card-content fp-card-primary-elevation"
          onClick={() => handleClickOpen("phone_number", "paper")}
        >
          <span className="bulk-card-heading">Phone Number</span>
          {console_list_data?.phone_number_exists > 0 ? (
            <>
              <span className="number-text">
                {console_list_data?.phone_number_exists}
              </span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* {console_list_data?.websiteUri && */}
        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("website_link", "paper")}> </div> */}
        <div
          className="bulk-card-content fp-card-primary-elevation"
          onClick={() => handleClickOpen("website_link", "paper")}
        >
          <span className="bulk-card-heading">Website Link</span>
          {console_list_data?.websiteUri > 0 ? (
            <>
              <span className="number-text">
                {console_list_data?.websiteUri}
              </span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* // } */}

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("appointment", "paper")}> </div> */}
        <div
          className="bulk-card-content fp-card-primary-elevation"
          onClick={() => handleClickOpen("appointment", "paper")}
        >
          <span className="bulk-card-heading">Appointment Link</span>
          {console_list_data?.appointment_links > 0 ? (
            <>
              <span className="number-text">
                {console_list_data?.appointment_links}
              </span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("add_category", "paper")}></div> */}
        <div
          className="bulk-card-content fp-card-primary-elevation"
          onClick={() => handleClickOpen("add_category", "paper")}
        >
          <span className="bulk-card-heading">Add Categories</span>
          {console_list_data?.primary_categories > 0 ? (
            <>
              <span className="number-text">
                {console_list_data?.additional_categories}
              </span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}{" "}
        </div>

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("Opening_date", "paper")}> </div> */}
        <div
          className="bulk-card-content fp-card-primary-elevation"
          onClick={() => handleClickOpen("Opening_date", "paper")}
        >
          <span className="bulk-card-heading">Opening Date</span>
          {console_list_data?.opening_date > 0 ? (
            <>
              <span className="number-text">
                {console_list_data?.opening_date}
              </span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("Opening_hour", "paper")}> </div> */}
        <div
          className="bulk-card-content fp-card-primary-elevation"
          onClick={() => handleClickOpen("Opening_hour", "paper")}
        >
          <span className="bulk-card-heading">Opening Hours</span>
          {console_list_data?.opening_hours > 0 ? (
            <>
              <span className="number-text">
                {console_list_data?.opening_hours}
              </span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} > </div> */}
        <div
          className="bulk-card-content fp-card-primary-elevation"
          onClick={() => handleClickOpen("attributes", "paper")}
        >
          <span className="bulk-card-heading">Attributes</span>
          {console_list_data?.attributes > 0 ? (
            <>
              <span className="number-text">
                {console_list_data?.attributes}
              </span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("photos", "paper")}> </div> */}
        <div
          className="bulk-card-content fp-card-primary-elevation"
          onClick={() => handleClickOpen("photos", "paper")}
        >
          <div className="d-flex align-center ant-row-space-between">
            <span className="bulk-card-heading">Photos</span>
            {/* <span style={{ color: "#7A8193" }}>4 avg words</span> */}
          </div>
          {console_list_data?.photos > 0 ? (
            <>
              <span className="number-text">{console_list_data?.photos}</span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("videos", "paper")}></div> */}
        <div
          className="bulk-card-content fp-card-primary-elevation"
          onClick={() => handleClickOpen("videos", "paper")}
        >
          <div className="d-flex align-center ant-row-space-between">
            <span className="bulk-card-heading">Videos</span>
            {/* <span style={{ color: "#7A8193" }}>4 avg words</span> */}
          </div>
          {console_list_data?.videos > 0 ? (
            <>
              <span className="number-text">{console_list_data?.videos}</span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("business_logo", "paper")}> </div> */}
        <div
          className="bulk-card-content fp-card-primary-elevation"
          onClick={() => handleClickOpen("business_logo", "paper")}
        >
          <span className="bulk-card-heading">Business Logo</span>
          {console_list_data?.logo_photo > 0 ? (
            <>
              <span className="number-text">
                {console_list_data?.logo_photo}
              </span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("products", "paper")}>
          <div className="card-content">
            <div className="d-flex align-center ant-row-space-between">
              <h3 className="card-heading">Products</h3>
              // <span style={{ color: "#7A8193" }}>4 avg words</span>
            </div>
            <h2>{console_list_data?.appointment_links}</h2>
            <span className="card-review">Listing Missing</span>
          </div>
        </div> */}
        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }}
        // onClick={() => handleClickOpen("question_ans", "paper")}
        ></div> */}
        <div className="bulk-card-content fp-card-primary-elevation">
          <div className="d-flex align-center ant-row-space-between">
            <span className="bulk-card-heading">Q & A</span>
            {/* <span style={{ color: "#7A8193" }}>4 avg words</span> */}
          </div>
          {console_list_data?.questions > 0 ? (
            <>
              <span className="number-text">
                {console_list_data?.questions}
              </span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }}
          //  onClick={() => handleClickOpen("service", "paper")}
          >  </div> */}
        <div className="bulk-card-content fp-card-primary-elevation">
          <span className="bulk-card-heading">Service</span>
          {console_list_data?.services > 0 ? (
            <>
              <span className="number-text">{console_list_data?.services}</span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("description", "paper")}> </div> */}
        <div
          className="bulk-card-content fp-card-primary-elevation"
          onClick={() => handleClickOpen("description", "paper")}
        >
          <div className="d-flex align-center ant-row-space-between">
            <span className="bulk-card-heading">Description</span>
            {/* <span style={{ color: "#7A8193" }}>4 avg words</span> */}
          </div>
          {console_list_data?.description > 0 ? (
            <>
              <span className="number-text">
                {console_list_data?.description}
              </span>
              <span className="card-review-error">Listing Missing</span>
            </>
          ) : (
            <>
              <img
                src={success_svg}
                height="20px"
                className="successImg"
                alt="succesImageBulk"
              />
              {/* <span className="card-review-success">Listing Missing</span> */}
            </>
          )}
        </div>

        {/* <div className="card-item special-card shadow-sm" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("profile_section", "paper")}>
          <div className="card-content">
            <h3 className="card-heading">Profile Protection</h3>
            {console_list_data?.primary_categories > 0 ?
              <>
                <h2>{console_list_data?.primary_categories}</h2>
                <span className="card-review">Listing Missing</span>
              </>
              :
              <>
                <img
                  src={success_svg}
                  height="40px"
                  className="successImg"
                  alt="succesImageBulk"
                />
                // <span className="card-review-success">Listing Missing</span>
              </>
            }
          </div>
        </div> */}
      </div>
      <FormModal
        isOpen={
          openTab === "phone_number" &&
          console_list_data?.phone_number_exists > 0
        }
        toggle={handleClose}
        heading="Add Primary & Additional Business Phone Numbers"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdatePhoneNuimber
          queryStringData={queryStringData}
          busines_status={false}
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={openTab === "website_link" && console_list_data?.websiteUri > 0}
        toggle={handleClose}
        heading="Update Website Links"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdateWebsiteLink
          queryStringData={queryStringData}
          busines_status={false}
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={
          openTab === "appointment" && console_list_data?.appointment_links > 0
        }
        toggle={handleClose}
        heading="Update Appointments"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdateAppointment
          queryStringData={queryStringData}
          busines_status={false}
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={
          openTab === "add_category" &&
          console_list_data?.primary_categories > 0
        }
        toggle={handleClose}
        heading="Update Category"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdateCategory
          queryStringData={queryStringData}
          busines_status={false}
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={
          openTab === "Opening_date" && console_list_data?.opening_date > 0
        }
        toggle={handleClose}
        heading="Update Open Date"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdateOpenDate
          queryStringData={queryStringData}
          busines_status={false}
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={
          openTab === "Opening_date" && console_list_data?.opening_hours > 0
        }
        toggle={handleClose}
        heading="Update Open Date"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdateOpenDate
          queryStringData={queryStringData}
          busines_status={false}
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={openTab === "attributes" && console_list_data?.attributes > 0}
        toggle={handleClose}
        heading="Update Attribute"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdateAttribute
          queryStringData={queryStringData}
          busines_status={false}
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={openTab === "photos" && console_list_data?.photos > 0}
        toggle={handleClose}
        heading="Update Photos"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdatePhotos
          queryStringData={queryStringData}
          busines_status={false}
          type="photo"
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={openTab === "videos" && console_list_data?.videos > 0}
        toggle={handleClose}
        heading="Update Videos"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdatePhotos
          queryStringData={queryStringData}
          busines_status={false}
          type="video"
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={
          openTab === "business_logo" && console_list_data?.logo_photo > 0
        }
        toggle={handleClose}
        heading="Business List"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdatePhotos
          queryStringData={queryStringData}
          busines_status={false}
          type="logo"
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={openTab === "products" && console_list_data?.opening_date > 0}
        toggle={handleClose}
        heading="Update Products"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <BusinessList
          queryStringData={queryStringData}
          busines_status={false}
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={openTab === "question_ans" && console_list_data?.questions > 0}
        toggle={handleClose}
        heading="Update Question & Answer"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdateQuestion
          queryStringData={queryStringData}
          busines_status={false}
          type="questions"
          business_id={business_id}
        />
      </FormModal>
      <FormModal
        isOpen={openTab === "service"}
        toggle={handleClose}
        heading="Update Service"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <Box sx={style}>
          <div style={{ padding: "25px 25px 0 25px" }}>
            
              <DialogActions>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </DialogActions>
            </div>
            {/* <BusinessList type="service" business_id={business_id} /> */}
        </Box>
      </FormModal>

      <FormModal
        isOpen={openTab === "description" && console_list_data?.description > 0}
        toggle={handleClose}
        heading="Update Description"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdateDescription
          queryStringData={queryStringData}
          busines_status={false}
          type="description"
          business_id={business_id}
        />
      </FormModal>

      <FormModal
        isOpen={
          openTab === "profile_section" && console_list_data?.opening_date > 0
        }
        toggle={handleClose}
        heading="Update Profile Section"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        styleHeader={true}
        headerClass="mt-10"
        paddingDense={false}
        showCloseBtn={true}
      >
        {/* <BusinessList business_id={business_id} /> */}
      </FormModal>
    </>
  );
};

// const mapDispatchToProps = (dispatch) => {
//   return {
//     actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
//     actions: bindActionCreators(locationActions, dispatch),
//   };
// };

const mapStateToProps = (state) => {
  return {
    console_list_data: state.gmbListingLocation.console_list_data,
    console_list_error: state.gmbListingLocation.console_list_error,
    loader: state.common.loader,
  };
};

// export default UpdateOpenDate;
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkDashboard);

// export default BulkDashboard;
