import React from "react";
import { connect } from "react-redux";
import { Row, Col, Label } from "reactstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Form, Button, Divider } from "semantic-ui-react";
import InputComponent from "../common/InputComponent";
import { FPButton } from "../common/new";
import FPLabel from "../common/new/forms/ui/FPLabel";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { Box } from "@material-ui/core";

let SocialIDSetup = (props) => {
  const { handleSubmit, submitting, handleBusinessSetup, isEditable, toggleState } = props;

  const submitSocialId = (data) => {
    let newData = { ...data, fb_page_id: data.fb_page_id };
    return handleBusinessSetup(newData).catch((err) => {
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  };

  return (
    <form className="grid-form mt-2" onSubmit={handleSubmit(submitSocialId)}>
      <CardWrapper
        headerTitle={
          <div className="d-flex justify-content-between">
            <Box>Social ID Setup</Box>
            <div>
              {/* {isEditable ? (
                <FPButton
                  label="Save"
                  size="small"
                  type="submit"
                  disabled={submitting}
                />
              ) : (
                <FPButton
                  size="small"
                  variant="outline"
                  onClick={() => toggleState("isSocialProfiles")}
                  label="Edit"
                />
              )} */}
              {isEditable ? (
                <Form.Field
                  control={Button}
                  color="teal"
                  type="submit"
                  disabled={submitting}
                  style={{ borderRadius: "80px", backgroundColor: "#F13A88", color: "white" }}
                >
                  Save
                </Form.Field>
              ) : (
                <Button basic onClick={() => toggleState("isSocialProfiles")} className="edit-btn">
                  Edit
                </Button>
              )}
            </div>
          </div>
        }
      >
        <Row className="mb-2">
          <Col sm="6">
            <FPLabel isRequired={false}>Facebook Page ID</FPLabel>
            <Field
              name="fb_page_id"
              component={InputComponent}
              type="text"
              placeholder="Enter Facebook Page Id"
              disabled={!isEditable}
              minHeight="0px"
            />
          </Col>
          <Col sm="6"></Col>
        </Row>
      </CardWrapper>
    </form>
  );
};

SocialIDSetup = reduxForm({
  form: "SocialIDSetup",
  enableReinitialize: true,
})(SocialIDSetup);

SocialIDSetup = connect((state) => ({
  initialValues: {
    fb_page_id: state.business.info && state.business.info.fb_page_id,
  }, // pull initial values from account reducer
}))(SocialIDSetup);

export default SocialIDSetup;
