import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Card, Nav, NavItem, NavLink } from "reactstrap";
import { Checkbox } from "semantic-ui-react";
import CodeHighlighter from "./CodeHighlighter";
import { displayMessage } from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import ReviewWidgetFilter from "./ReviewWidgetFilter";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { FPButton } from "../common/new";
import classnames from "classnames";
import ProviderWidget from "./ProviderWidget";

const ReviewWidget = ({ businessId }) => {
  const [sourceOptions, setSourceOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [dateOptions, setDateOptions] = useState([]);
  const [withContent, setWithContent] = useState(true);
  const [code, setCode] = useState("");
  const [displayLength, setDisplayLength] = useState("none");
  const [srcUrl, setSrcUrl] = useState("");
  const iframeRef = useRef(null);
  const [iframeHeight, setIframeHeight] = useState("0px");
  const [tab, setTab] = useState("1");
  const [numberOfReview, setNumberOfReview] = useState(0);

  const minLen = [
    { value: "10", display_name: "10 Min. character length" },
    { value: "50", display_name: "50" },
    { value: "100", display_name: "100" },
    { value: "200", display_name: "200" },
  ];
  const maxLen = [
    { value: "100", display_name: "100 Max. character length" },
    { value: "200", display_name: "200" },
    { value: "300", display_name: "300" },
    { value: "400", display_name: "400" },
    { value: "500", display_name: "500" },
  ];
  const review = [
    { value: "3", display_name: "3 Number of Reviews" },
    { value: "5", display_name: "5" },
    { value: "8", display_name: "8" },
    { value: "10", display_name: "10" },
    { value: "20", display_name: "20" },
  ];

  useEffect(() => {
    axios_instance
      .get(`${URLS.REVIEW_SOURCE}?business_id=${businessId}`)
      .then((res) => {
        const test = [];
        res.data && res.data.choices.map((data) => test.push({ value: data.value, display_name: data.display_name }));
        setSourceOptions(test);
      })
      .catch((err) => {
        console.log(err);
      });

    axios_instance
      .get(utils.format(`${URLS.BUSINESS_SETUP}{}/branch/`, [businessId]))
      .then((res) => {
        const test = [];
        res.data && res.data.choices.map((data) => test.push({ value: data.value, display_name: data.display_name }));
        setLocationOptions(test);
      })
      .catch((err) => {
        console.log(err);
      });

    axios_instance
      .get(`${URLS.BUSINESS_SETUP}dashboard-filter/`)
      .then((res) => {
        const test = [];
        res.data && res.data.choices.map((data) => test.push({ value: data.value, display_name: data.display_name }));
        setDateOptions(test);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [businessId]);

  const handleApi = (value) => {
    let min_char_len = value.min_char_len.length ? value.min_char_len[0].value : 10;
    let max_char_len = value.max_char_len.length > 0 ? value.max_char_len[0].value : 500;
    let num_of_reviews = value.num_of_reviews.length > 0 ? value.num_of_reviews[0].value : 3;
    let location_id = value.location_id.length > 0 ? value.location_id[0].value : "";
    let provider_id = value.provider_id.map((item) => item.value).join();
    setNumberOfReview(num_of_reviews);
    axios_instance
      .get(
        `${utils.format(URLS.REVIEW_WIDGET, [
          businessId,
        ])}?with_content=${withContent}&min_char_len=${min_char_len}&max_char_len=${max_char_len}&num_of_reviews=${num_of_reviews}&location_id=${location_id}&provider_id=${provider_id}`
      )
      .then((res) => {
        setSrcUrl(res.data.url);
        let text = `<script src="https://api.famepilot.com/static/widget/review_widget.js"></script> \n<iframe id="fp_review_widget" src="${res.data.url}" frameborder="0" scrolling="no" style="min-width: 100%; width: 100%;" style="border: none;"></iframe>`;
        setCode(text);
      })
      .catch((err) => {
        console.log(err);
        displayMessage("Error", "Error");
      });
  };

  const handleSubmit = (value) => {
    if (!withContent) {
      handleApi(value);
    } else if (
      withContent &&
      parseInt(value.min_char_len.length > 0 ? value.min_char_len[0].value : 10) <=
        parseInt(value.max_char_len.length ? value.max_char_len[0].value : 500)
    ) {
      handleApi(value);
    } else {
      setCode("Minimum Character Length should be equal to or smaller than Maximum Character Length");
      return;
    }
  };

  useEffect(() => {
    if (withContent) {
      setDisplayLength("flex");
    } else {
      setDisplayLength("none");
    }
  }, [withContent]);

  useEffect(() => {
    const handleMessage = (e) => {
      if (Array.isArray(e.data) && e.data.length >= 2) {
        const eventName = e.data[0];
        const data = e.data[1];
        if (eventName === "fp.setHeight" && data && data.id === "fp_review_widget") {
          setIframeHeight(data.height + "px");
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [srcUrl]);

  return (
    <div>
      <CardWrapper showDivider={false}>
        <Nav className="Setup_Sub_Nav">
          <NavItem>
            <NavLink
              className={classnames({ active: tab === "1" })}
              onClick={() => {
                setTab("1");
              }}
            >
              Review Widget
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: tab === "2" })}
              onClick={() => {
                setTab("2");
              }}
            >
              Provider Widget
            </NavLink>
          </NavItem>
        </Nav>
      </CardWrapper>
      {tab === "1" && (
        <Card className="my-4">
          <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", padding: "10px 0px" }}>
            <div style={{ display: "flex", gap: "10px", alignItems: "center", fontSize: "16px", padding: "0px 15px" }}>
              <span>Content</span>
              <Checkbox
                toggle
                checked={withContent}
                onClick={() => setWithContent((prev) => !prev)}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 40 } }}
              />
            </div>
            <ReviewWidgetFilter
              minLen={minLen}
              maxLen={maxLen}
              review={review}
              sourceOptions={sourceOptions}
              locationOptions={locationOptions}
              handleSubmit={handleSubmit}
              withContent={withContent}
            />
          </div>
        </Card>
      )}

      {tab === "1" && (
        <CardWrapper headerTitle="Preview" className="my-4">
          <iframe
            ref={iframeRef}
            src={srcUrl}
            title="Review Widget"
            style={{ width: "100%", height: iframeHeight, border: "none" }}
          />
          <div>
            <span>Total Reviews:</span> <span>{numberOfReview}</span>{" "}
          </div>
        </CardWrapper>
      )}

      {tab === "1" && (
        <Card body>
          <CodeHighlighter code={code} className="mb-2" />
          <div style={{ textAlign: "right" }}>
            <FPButton
              label="Copy Code"
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(code);
                displayMessage("positive", "Copied to clipboard");
              }}
            />
          </div>
        </Card>
      )}

      {tab === "2" && (
        <ProviderWidget
          businessId={businessId}
          sourceOptions={sourceOptions}
          locationOptions={locationOptions}
          dateOptions={dateOptions}
        />
      )}
    </div>
  );
};

export default ReviewWidget;
