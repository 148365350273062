import React, { useEffect, useState } from "react";
import ListingFPInput from "../../common/new/Listing/ListingFPInput";
import delete_png from "../../../images/delete_png.webp";

const CreateQuestion = ({ setCreateQuestion }) => {

    const [question, setQuestion] = useState("");
    const [answers, setAnswers] = useState([""]);
    console.log("questionquestionquestion", question)
    console.log("questionquestionquestion", answers)
    // Handle question input change
    const handleQuestionChange = (e) => {
        setQuestion(e.target.value);
    };

    const handleAnswerChange = (e) => {
        setAnswers(e.target.value);
    };

    useEffect(() => {
        const payload = {
            question: question,
            answers: answers, // Filter out empty answers
        };
        setCreateQuestion(payload); // Pass the payload to the onSubmit handler
    }, [question, answers])


    return (
        <>
            < div
                style={{
                    padding: "16px",
                    backgroundColor: "#F6F6F8",
                    borderRadius: "8px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                }}
            >
                <div style={{ display: "grid", gap: "10px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            {/* <img style={{ height: "auto", width: "25px " }} src={"https:" + questionAnsewrList?.author?.profilePhotoUri} /> */}
                            <span style={{
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "#1B1C1D",
                            }}>
                                {/* {questionAnsewrList?.author?.displayName}</span> */}
                            </span>
                        </div>
                        <div
                            style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#1B1C1D",
                            }}
                        >
                            Question
                        </div>
                    </div>
                    <ListingFPInput
                        type="text"
                        // value={questionAnsewrList?.question}
                        onChange={(e) => handleQuestionChange(e)}
                        placeholder={
                            "Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
                        }
                    />
                    <div
                        style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#7A8193",
                        }}
                    >
                        0 /1000 words
                    </div>
                </div>
                {/* {questionAnsewrList?.answers?.length > 0 ?
                      questionAnsewrList?.answers?.map((answer) => {
                        console.log("answeransweransweransweransweranswer", answer)
                        return ( */}
                < div style={{ display: "grid", gap: "10px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                            {/* <img style={{ height: "auto", width: "25px " }} src={answer?.author?.type !== "MERCHANT" ? "https:" + answer?.author?.profilePhotoUri : ""} /> */}
                            <span style={{
                                fontWeight: "500",
                                fontSize: "14px",
                                color: "#1B1C1D",
                            }}>
                                {/* {answer?.author?.type === "MERCHANT" ? "By Merchant" : answer?.author?.displayName}</span> */}
                            </span>
                        </div>
                        <div
                            style={{
                                fontWeight: "400",
                                fontSize: "14px",
                                color: "#1B1C1D",
                            }}
                        >
                            Answer
                        </div>
                    </div>
                    <textarea
                        id="w3review"
                        name="w3review"
                        rows="4"
                        onChange={(e) => handleAnswerChange(e)}
                        placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                        style={{
                            width: "100%",
                            borderRadius: "7px",
                            borderColor: "#BFC2CB",
                            fontWeight: "400",
                            fontSize: "14px",
                            color: "#7A8193",
                            padding: "10px 14px",
                        }}
                    />
                    <div
                        style={{
                            fontWeight: "400",
                            fontSize: "12px",
                            color: "#7A8193",
                        }}
                    >
                        0 / 1000 words
                    </div>
                </div>
                {/* //     )
                    //   }) : <div className="listing-name">No Existing Answer</div>} */}
                <div>
                    <div style={{ borderBottom: "1px solid #BFC2CB" }}></div>
                </div>
                <div className="d-flex justify-content-end">
                    <img
                        src={delete_png}
                        alt=""
                        style={{ height: "16px", width: "16px", color: "#FFFFFF" }}
                    />
                </div>
            </div >
        </>
    )
}

export default CreateQuestion