import React, { useState, useEffect, useCallback } from "react";
import classnames from "classnames";
import FormModal from "../common/FormModal";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import FPInput from "../common/new/forms/ui/FPInput";
import { FPButton } from "../common/new";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import tagsvg from "../../images/svg/Tag.svg";
import SearchIcon from "@material-ui/icons/Search";

const TicketingTagsModal = (props) => {
  const {
    isReviewTagModalOpen,
    toggleReviewTagModal,
    item,
    postTagOnReview,
    error,
    handleTagChange,
    addtag,
    tagsList = [],
  } = props;

  const [tagArray, setTagArray] = useState(tagsList);
  const [addNewTagModel, setAddNewTagModel] = useState(false);
  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const maxCharLimit = 25;

  useEffect(() => {
    if (!value) {
      setTagArray(tagsList);
    };
  }, [tagsList]);

  // Search handler
  const handleTagSearch = (e) => {
    if (value) return;
    const searchKeyword = e.target.value.toLowerCase();
    setSearchValue(searchKeyword);

    const filteredTags = tagsList.filter((tag) =>
      tag.name.toLowerCase().includes(searchKeyword)
    );

    setTagArray(filteredTags);
  };

  const handleCloseTagModal = () => {
    setAddNewTagModel(false);
    setValue("");
    setSearchValue("");
    setTagArray(tagsList);
  };

  const handleTagButtonClick = () => {
    setAddNewTagModel(true);
  };

  const handleTagClick = useCallback(
    (tag) => {
      postTagOnReview(
        {
          tag_name: tag.name,
          object_id: item?.id,
          tag_id: tag?.id,
          tagMethod: "add_tags/",
        },
        item?.tag
      );
    },
    [postTagOnReview, item]
  );

  const handleAddTag = () => {
    if (!value.trim()) {
      return;
    }
    if (tagsList.some((tag) => tag.name.toLowerCase() === value.toLowerCase())) {
      return;
    }
    addtag(item?.id);
    setValue("");
    handleCloseTagModal();
  };

  return (
    <>
      <FormModal
        isOpen={isReviewTagModalOpen}
        width="800px"
        showDivider
        bodyClass="pt-0"
        showCloseBtn={false}
        paddingDense
        toggle={toggleReviewTagModal}
        heading={
          <div className="d-flex justify-content-between">
            <div>Assign a New Tag</div>
            <div className="d-flex align-items-center gap-10">
              <FPButton
                label="Add Tag"
                size="small"
                style={{ width: "100px", height: "30px" }}
                onClick={handleTagButtonClick}
              />
              <FPButton
                onClick={toggleReviewTagModal}
                size="small"
                label="Close"
                style={{
                  width: "100px",
                  height: "30px",
                  color: "#1B1C1D",
                  border: "1px solid #BFC2CB",
                }}
                backgroundColor="#ffffff"
              />
            </div>
          </div>
        }
      >
        <>
          <div style={{ margin: "10px -5px" }}>
            <FPInput
              type="text"
              name="searchTag"
              placeholder="Search tags"
              onChange={handleTagSearch}
              value={searchValue}
              style={{ fontSize: "14px" }}
              endIcon={
                <SearchIcon style={{ color: "#BFC2CB", fontSize: "18px" }} />
              }
            />
          </div>
          <div style={{ color: "red", margin: "0 12px" }}>{error}</div>
          {tagArray?.map((tag) => (
            <div
              key={tag?.id}
              className={classnames("filter-tags font-dark d-flex gap-10", {
                "tags-highlight-reviews": item?.tag?.includes(tag?.name),
              })}
              style={{ marginBottom: "15px" }}
            >
              <img src={tagsvg} alt="Tag Icon" />
              <div
                className="w-100 cursor-pointer d-flex justify-content-between"
                onClick={() => handleTagClick(tag)}
              >
                <span>{tag?.name}</span>
                <div className="float-right">
                  {item?.tag?.includes(tag?.name) && <CheckOutlinedIcon />}
                </div>
              </div>
            </div>
          ))}
        </>
      </FormModal>

      <FormModal
        isOpen={addNewTagModel}
        showDivider
        toggle={handleCloseTagModal}
        heading="Add New Tag"
        width="600px"
        headerClass="d-flex align-items-center"
        paddingDense
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <CardWrapper showDivider={false}>
            <div style={{ margin: "0px -15px" }}>
              <FPInput
                error={error}
                placeholder="Enter tag"
                fluid
                name="addTag"
                onChange={(e) => {
                  if (e.target.value.length <= maxCharLimit) {
                    handleTagChange(e);
                    setValue(e.target.value);
                  }
                }}
                value={value}
                className="mb-1"
              />
              <p style={{ color: "#1B1C1D", fontSize: "12px" }}>
                {maxCharLimit - value.length} characters left
              </p>
            </div>
            <FPButton
              label="Add"
              size="small"
              onClick={handleAddTag}
              className="float-right"
            />
          </CardWrapper>
        </form>
      </FormModal>
    </>
  );
};

export default TicketingTagsModal;
