import React, { useState, useEffect } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Check } from "lucide-react";
import { FormFeedback } from "reactstrap";
import FPLabel from "../../../../components/common/new/forms/ui/FPLabel";
import { SurveyDislike, SurveyLike } from "../../../../images/svg";

const ButtonContainer = styled(Box)(({ selected }) => ({
    width: 45,
    height: 45,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    background: selected ? "#5FAAB1" : "",
    transition: "all 0.3s ease",
    position: "relative",
}));

const SelectionMark = styled(Box)({
    position: "absolute",
    top: -17,
    left: "50%",
    transform: "translateX(-50%)",
    width: 20,
    height: 20,
    borderRadius: "50%",
    background: "#DDEFF0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
});

const LikeDislikeSelect = ({
    onLikeChange,
    value,
    error,
    title,
    containerMargin = true,
    showLabel = true,
}) => {
    const [selectedLike, setSelectedLike] = useState(value || null);

    useEffect(() => {
        setSelectedLike(value);
    }, [value]);

    const options = [
        { value: "like", icon: <SurveyLike />, label: "Best", color: "#43A047" },
        { value: "dislike", icon: <SurveyDislike />, label: "Worst", color: "#E53935" },
    ];

    const handleSelect = (optionValue) => {
        setSelectedLike(optionValue);
        if (onLikeChange) onLikeChange(optionValue);
    };

    return (
        <>
            {title && (
                <FPLabel isRequired={false} className="mt-2">
                    {title}
                </FPLabel>
            )}
            <Stack
                direction="row"
                spacing={3}
                justifyContent="center"
                sx={{ mb: containerMargin ? 2 : 0, mt: containerMargin ? 3 : 0, gap: "10px" }}
            >
                {options.map((option) => (
                    <Box key={option.value} display="flex" flexDirection="column" alignItems="center">
                        <ButtonContainer
                            selected={selectedLike === option.value}
                            onClick={() => handleSelect(option.value)}
                        >
                            {selectedLike === option.value && (
                                <SelectionMark>
                                    <Check size={16} color="#00CBCB" />
                                </SelectionMark>
                            )}
                            {option.icon}
                        </ButtonContainer>
                    </Box>
                ))}
            </Stack>

            {showLabel && ( 
                !selectedLike ? (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                        maxWidth={options.length * 60}
                        mx="auto"
                        mt={2}
                    >
                        <Typography variant="caption" sx={{ fontSize: 12, color: "#888" }}>
                            Worst
                        </Typography>
                        <Typography variant="caption" sx={{ fontSize: 12, color: "#888" }}>
                            Best
                        </Typography>
                    </Box>
                ) : (
                    // Show only the selected label
                    <Box
                        mt={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        px={2}
                        py={0.5}
                        borderRadius={10}
                        sx={{
                            backgroundColor: options.find((opt) => opt.value === selectedLike)?.color,
                            color: "black",
                            fontSize: "14px",
                            transition: "0.3s",
                            width: "fit-content",
                            margin: "auto",
                        }}
                    >
                        <Typography variant="caption" fontWeight="bold">
                            {options.find((opt) => opt.value === selectedLike)?.label}
                        </Typography>
                    </Box>
                )
            )}

            {error && <FormFeedback>{error}</FormFeedback>}
        </>
    );
};


export default LikeDislikeSelect;
