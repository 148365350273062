import React from "react";
import { Line } from "react-chartjs-2";

const OfferChart = ({ labels, overtimeData, isAdsPage, primaryYLabel }) => {

  const data = {
    labels: [...labels],
    datasets: [...overtimeData],
  };
  const options = {
    scales: {
      y: {
        beginAtZero: true,
        type: "linear",
        position: "left",
        title: {
          display: primaryYLabel ? true : false,
          text: primaryYLabel,
        },
        grid: {
          borderDash: [20],
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: !isAdsPage,
        position: "bottom",
        labels: {
          fontSize: 16,
          fontStyle: "normal",
          fontFamily: "Outfit",
          boxWidth: 6,
          boxHeight: 6,
          padding: 10,
          usePointStyle: true,
        },
      },
    },
    animation: {
      duration: 2000,
    },
    maintainAspectRatio: false,
  };
  return (
    <div style={{ height: "500px" }}>
      <Line datasetIdKey="id" data={data} options={options} />
    </div>
  );
};

export default OfferChart;
