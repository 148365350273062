import React, { useEffect, useState } from "react";
import cookie from "react-cookies";
import FPHeaderTabs from "../../components/common/new/tabs/FPHeaderTabs";

const Header = ({
  handleRootTabChange,
  defaultTab,
  lockedModules,
  match: {
    params: { business_id },
  },
}) => {
  const service_category = cookie.load("service_category");
  const pageSize = cookie.load("pageSize") || {};

  const user = cookie.load("user");
  const [tabs, setTabs] = useState([]);
  const [lockIndexes, setLockIndexes] = useState([]);

  useEffect(() => {
    let tabsConfig = [
      { label: "Reviews", path: `/${business_id}/dashboard?date_type=prior_30_day` },
      { label: "Social", path: `/${business_id}/messenger` },
      { label: "Listings", path: `/${business_id}/listing-overview?page_size=${pageSize["listing_overview_page"] || 10}` },
    ];

    if (service_category === "Restaurant") {
      tabsConfig.push({ label: "Marketplace", path: `/${business_id}/restro-pilot?date_type=prior_30_day&type=${"daily"}` });
    }

    tabsConfig.push({
      label: "Service Request",
      path: `/${business_id}/ticket-service-overview?date_type=prior_30_day`,
    });
    tabsConfig.push({ label: "WhatsApp", path: `/${business_id}/whatsapp-messages` })
    
    const locked = [];
    tabsConfig.forEach((tab, index) => {
      if (
        (tab.label === "Listings" &&
          (!lockedModules["listing"] || !user?.["enable_listing_product"])) ||
        (tab.label === "Marketplace" &&
          (lockedModules["marketplace"] || !user?.["enable_marketplace"])) ||
        (tab.label === "Service Request" && lockedModules["service_request"]) ||
        (tab.label === "WhatsApp" && !user?.["enable_whatsapp"])
      ) {
        locked.push(index);
      }
    });


    setTabs(tabsConfig);
    setLockIndexes(locked);
  }, [business_id, lockedModules,  service_category,]);

  return (
    <FPHeaderTabs
      variant="round-fill"
      tabLabels={tabs.map((tab) => tab.label)}
      tabPaths={tabs.map((tab) => tab.path)}
      className="dashboard-tab"
      defaultValue={user?.enable_sr_module ? 0 : defaultTab || 0}
      onChange={handleRootTabChange}
      lockIndexes={lockIndexes}
      minTabWidth={90}
    />
  );
};

export default Header;
