/* eslint-disable */
import React, { useState } from "react";
import { getParams } from "../../../../libs/utils";
import moment from "moment";
import Flatpickr from "react-flatpickr";
import "./dateRange.css";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import classNames from "classnames";

const DateRange = ({ onChange, className }) => {
  const setDateFormate = (date, format) => moment(date).format(format || "YYYY-MM-DD");
  const startDate = getParams(location.search, "start_date");
  const endDate = getParams(location.search, "end_date");

  const [dateRange, setDateRange] = useState({
    start_date: setDateFormate(startDate || new Date()),
    end_date: setDateFormate(endDate || new Date()),
  });

  const handleDataRange = (value, type) => {
    const formattedValue = setDateFormate(value);
    if (type === "start_date") {
      setDateRange((preValue) => {
        return { ...preValue, start_date: formattedValue };
      });
      setTimeout(() => {
        onChange &&
          onChange({
            date_type: "other",
            start_date: formattedValue,
            end_date: dateRange.end_date,
          });
      }, 100);
    } else {
      setDateRange((preValue) => {
        return { ...preValue, end_date: formattedValue };
      });
      setTimeout(() => {
        onChange &&
          onChange({
            date_type: "other",
            start_date: dateRange.start_date,
            end_date: formattedValue,
          });
      }, 100);
    }
  };

  return (
    <div className={classNames("mb-3", className)}>
      <div className="position-relative d-inline-block">
        <Flatpickr
          className="input-width fp-date-range"
          defaultValue={dateRange.start_date}
          value={dateRange.start_date}
          onChange={([date]) => handleDataRange(date, "start_date")}
        />
        <KeyboardArrowDownIcon className="fp-drop-down-icon" color="disabled" />
      </div>
      <span style={{ lineHeight: "36px" }} className="to-text">
        To
      </span>
      <div className="position-relative d-inline-block">
        <Flatpickr
          className=" input-width fp-date-range"
          defaultValue={dateRange.end_date}
          value={dateRange.end_date}
          onChange={([date]) => handleDataRange(date, "end_date")}
          options={{
            minDate: setDateFormate(dateRange.start_date),
          }}
        />
        <KeyboardArrowDownIcon className="fp-drop-down-icon" color="disabled" />
      </div>
    </div>
  );
};

export default DateRange;
