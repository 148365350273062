import * as types from '../../actions/actionTypes';

export default function competitorsDetails(state=[], action) {
  switch (action.type) {
    case types.GET_COMPETITORS_DETAIL_SUCCESS:
      return Object.assign({},state,{ list: {...action.data}});
    case types.GET_COMPETITORS_DETAIL_FAILURE:
      return Object.assign({},state,{ list: {}});
    case types.GET_COMPETITORS_OVERVIEW_SUCCESS:
      return Object.assign({}, state, {overview_details: {...action.data}});
    case types.GET_COMPETITORS_OVERVIEW_FAILURE:
      return Object.assign({}, state, { overview_details: {}});
    case types.GET_COMPETITORS_RATING_SUCCESS:
      return Object.assign({}, state, {rating_details: {...action.data}});
    case types.GET_COMPETITORS_RATING_FALIURE:
      return Object.assign({}, state, { rating_details: {}});
    case types.GET_COMPETITORS_INSIGHTS_SUCCESS:
      return Object.assign({}, state, {insights_details: {...action.data}});
    case types.GET_COMPETITORS_INSIGHTS_FAILURE:
      return Object.assign({}, state, { insights_details: {}});case types.POST_BRANCH_COMPETITOR_SUCCESS:
      return Object.assign({}, state, {competitors_branch_list : {...action.data}  });
    case types.POST_BRANCH_COMPETITOR_FAILURE:
      return Object.assign({}, state, { competitors_branch_list : {...action.data} });
    case types.GET_COMPETITORS_OPTIONS_SUCCESS:
      return Object.assign({}, state, { competitor_options: {...action.data}});
    case types.GET_COMPETITORS_OPTIONS_FAILURE:
      return Object.assign({}, state, { competitor_options: {...action.data}});
    case types.GET_BRANCH_COMPETITORS_SUCCESS:
      return Object.assign({}, state, {competitors_branch_list : {...action.data} });      
    case types.GET_BRANCH_COMPETITORS_FAILURE:
      return Object.assign({}, state, { competitors_branch_list : {...action.data} }); 
    case types.GET_BRANCH_COMP_DETAILS_SUCCESS:
      return Object.assign({}, state, {competitors_branch_details : {...action.data} });      
    case types.GET_BRANCH_COMP_DETAILS_FAILURE:
      return Object.assign({}, state, { competitors_branch_details : {...action.data} }); 
    case types.ADD_COMPETITORS_SUCCESS:
      return Object.assign({}, state);
    case types.ADD_COMPETITORS_FAILURE:
      return Object.assign({}, state, {competitors_list : {...action.data}});
    case types.GET_COMPETITORS_SUCCESS:
      return Object.assign({},state, {competitors_list: {...action.data}});
    case types.GET_COMPETITORS_FAILURE:
      return Object.assign({}, state,{competitors_list: {...action.data}});
    case types.GET_COMPETITOR_LINKS_DETAILS_SUCCESS:
      return Object.assign({}, state, {competitor_links:{...action.data}});
    case types.GET_COMPETITOR_LINKS_DETAILS_FAILURE:
      return Object.assign({}, state, {}, {competitor_links:{...action.data}} );
    case types.POST_COMPETITOR_LINKS_SUCCESS:
      return Object.assign({}, state, {competitor_links : {...action.data}});
    case types.POST_COMPETITOR_LINKS_FAILURE:
      return Object.assign({}, state, {competitor_links : {...action.data}});
    case types.COMPETITOR_SOURCE_OPTIONS_SUCCESS:
      return Object.assign({}, state, {competitor_source_options: [...action.data]});
    case types.COMPETITOR_SOURCE_OPTIONS_FAILURE:
      return Object.assign({}, state, {competitor_source_options: []});
    default :
      return state;
  }
}