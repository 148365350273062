import React, { Fragment } from "react";
import { Row, Col } from "reactstrap";
import classnames from "classnames";
import { Divider, Icon } from "semantic-ui-react";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import deleteIcon from "../../images/svg/trash.svg";
import { capitalize } from "../../libs/utils";

class ContactTagGroupList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editTagId: null,
      editTagGroupId: null,
      tagValue: "",
      addGroupId: null,
      error: { tagGroup: false, tagItem: false },
    };
  }

  editItem = (id) => {
    this.setState({ editTagId: id });
  };

  editTagGroupId = (name) => {
    this.setState({ editTagGroupId: name });
  };

  addItem = (tagId, id) => {
    this.setState({ editTagId: tagId, addGroupId: id });
  };

  handleChange = (e, type) => {
    const { error } = this.state;
    const { clearServerError } = this.props;
    const limit = type == "tagGroup" ? 26 : 16;
    if (e.target.value.length < limit) this.setState({ error: { ...error, [type]: false } });
    if (type === "tagGroup") clearServerError();
    this.setState({ tagValue: e.target.value });
  };

  handleKeyDown = (e, type) => {
    const { tagValue, error, editTagGroupId, editTagId } = this.state;
    const { editTagGroupName, editTagName } = this.props;
    const functionToRun =
      type === "tagGroup" ? () => editTagGroupName(editTagGroupId, tagValue) : () => editTagName(editTagId, tagValue);
    if (e.key === "Enter") {
      if (tagValue && tagValue.length < 25) {
        return functionToRun().then(() => {
          this.setState({
            editTagGroupId: null,
            editTagId: null,
            tagValue: "",
          });
        });
      } else if (!tagValue) this.setState({ error: { ...error, [type]: "Please enter a value" } });
      else
        this.setState({
          error: { ...error, [type]: "Less than 25 characters allowed" },
        });
    }
  };

  handleAddTag = (e, id) => {
    const { tagValue, error } = this.state;
    const { addTagName } = this.props;
    if (e.key === "Enter") {
      if (tagValue && tagValue.length < 25) {
        return addTagName(id, tagValue).then(() => {
          this.setState({ editTagId: null, tagValue: "" });
        });
      } else if (!tagValue) this.setState({ error: { ...error, tagItem: "Please enter a value" } });
      else
        this.setState({
          error: { ...error, tagItem: "Less than 25 characters allowed" },
        });
    }
  };

  handleBlur = (type) => {
    const { error } = this.state;
    const idType = type === "tagGroup" ? "editTagGroupId" : "editTagId";
    this.setState({
      [idType]: null,
      error: { ...error, [type]: false },
      tagValue: "",
    });
  };

  render() {
    const { tagsList, deleteSelectedTag, deleteTagGroup, serverError } = this.props;
    const { error, tagValue, editTagGroupId, editTagId, addGroupId } = this.state;
    return (
      <div className="d-flex flex-column">
        {tagsList &&
          tagsList.map((item) => {
            return (
              <div
                key={item.id}
                className="py-3"
                style={{
                  border: "1px solid #d0d0d0",
                  // padding: "10px 15px",
                  borderRadius: "8px",
                  background: "#ffffff",
                  marginBottom: "15px",
                }}
              >
                <div className="d-flex align-items-center justify-content-between px-3">
                  <div
                    className={classnames("contact-tag-group", {
                      "editing-tag-group": item.id === editTagGroupId,
                    })}
                  >
                    <span
                      className="tag-group-text"
                      style={{
                        cursor: "pointer",
                        fontSize: "16px",
                        fontWeight: "500",
                        // color: "rgba(0, 181, 173, 1)",
                      }}
                      onDoubleClick={() => this.editTagGroupId(item.id)}
                    >
                      {capitalize(item.name)}
                    </span>
                    <input
                      className={classnames("edit mt-1", {
                        error: error.tagGroup,
                      })}
                      autoComplete="off"
                      size={25}
                      name="tagGroupName"
                      value={tagValue}
                      onChange={(e) => this.handleChange(e, "tagGroup")}
                      onKeyDown={(e) => this.handleKeyDown(e, "tagGroup")}
                      onBlur={() => this.handleBlur("tagGroup")}
                      required
                    ></input>
                    {item.id === editTagGroupId && (
                      <Fragment>
                        <label htmlFor="tagGroupName" className="label-name">
                          <span className="content-name text-nowrap">Group Name</span>
                        </label>
                      </Fragment>
                    )}
                  </div>

                  <div
                    className="d-flex align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => deleteTagGroup(item)}
                  >
                    <img src={deleteIcon} role="button" />
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "rgba(122, 129, 147, 1)",
                        marginLeft: "7px",
                      }}
                    >
                      Delete Group
                    </span>
                    {(error.tagGroup || serverError) && item.id === editTagGroupId && (
                      <div>
                        <div style={{ color: "red", opacity: "0.7" }}>{error.tagGroup || serverError.name[0]}</div>
                      </div>
                    )}
                  </div>
                </div>
                <Divider className="mt-3" />
                <div className="d-flex align-items-center flex-wrap">
                  {item.tag_objs.map(({ name, id }, index) => {
                    return (
                      <div
                        className={classnames("ml-3 mt-2 contact-tag-item", {
                          "editing-tag": id === this.state.editTagId,
                        })}
                        key={index}
                      >
                        <span
                          className="tag-text-view"
                          onDoubleClick={() => this.editItem(id)}
                          style={{ fontSize: "16px", fontWeight: "400" }}
                        >
                          {name}&nbsp;&nbsp;
                          <ClearIcon
                            style={{
                              color: "rgba(122, 129, 147, 1)",
                              width: "15px",
                              height: "15px",
                              marginTop: "2px",
                            }}
                            onClick={() =>
                              deleteSelectedTag({
                                object_id: item.id,
                                tag_name: name,
                              })
                            }
                          />
                        </span>
                        <input
                          className={classnames("edit", {
                            error: error.tagItem,
                          })}
                          name="tagName"
                          type="text"
                          value={tagValue}
                          required
                          onBlur={() => this.handleBlur("tagItem")}
                          onKeyDown={(e) => this.handleKeyDown(e, "tagItem")}
                          onChange={(e) => this.handleChange(e, "tagItem")}
                          autoComplete="off"
                        ></input>
                        {id === this.state.editTagId && (
                          <label htmlFor="tagName" className="tag-name-label">
                            <span className="tag-name-content">Edit Tag</span>
                          </label>
                        )}
                      </div>
                    );
                  })}
                  <div
                    className={classnames("ml-3 mt-2 contact-tag-item", {
                      "editing-tag": "add" === editTagId && item.id === addGroupId,
                    })}
                  >
                    <div className="d-flex align-items-center">
                      <AddIcon
                        style={{
                          width: 18,
                          height: 18,
                          color: "rgba(122, 129, 147, 1)",
                          marginRight: "3px",
                        }}
                        className="add-tag-view"
                      />
                      <span className="add-tag-view" onClick={() => this.addItem("add", item.id)}>
                        Add Tags
                      </span>
                    </div>
                    <input
                      className={classnames("edit", { error: error.tagItem })}
                      name="tagName"
                      placeholder="Add tag"
                      type="text"
                      value={tagValue}
                      required
                      onBlur={() => this.handleBlur("tagItem")}
                      onKeyDown={(e) => this.handleAddTag(e, item.id)}
                      onChange={(e) => this.handleChange(e, "tagItem")}
                      autoComplete="off"
                    ></input>
                    {/* {"add" === editTagId && item.id === addGroupId && (
                      <label htmlFor="tagName" className="tag-name-label">
                        <span className="tag-name-content">Add Tag</span>
                      </label>
                    )} */}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default ContactTagGroupList;
