import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Button, Form, Grid,Segment} from 'semantic-ui-react';
import { InputField } from 'react-semantic-redux-form';


const validate = (values) => {
  const errors = {};
  if(!values.agentName) {
    errors.agentName = 'Please Enter Agent Name';
  }
  if(!values.agentId) {
    errors.agentId = 'Please Enter Agent ID';
  }
  if(!values.brandId) {
    errors.brandId = 'Please Enter Brand ID';
  }
  return errors;
};

const GmbMessagesForm = (props) => {
  const { handleSubmit, submitGmbMessagesForm } = props;
  const submit = (data) => {
    return submitGmbMessagesForm(data)
      .catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  };

  return (
    <Grid textAlign='center' verticalAlign='middle'>
      <Grid.Column>
        <Form onSubmit={handleSubmit(submit)}>          
          <Segment stacked textAlign='left'>
            <Field label="Agent Name" name='agentName' component={InputField} placeholder='Enter Agent Name' type='text' />
            <Field label="Agent ID" name='agentId' component={InputField} placeholder='Enter Agent Id' type='text' />
            <Field label="Brand ID" name='brandId' component={InputField} placeholder='Enter Brand Id' type='text' />
          </Segment>
          <Form.Field control={Button} color='teal' fluid type='submit' style={{margin:'10px 0'}}>
            Submit
          </Form.Field>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default reduxForm({
  form: 'GmbMessagesForm',
  enableReinitialize: true,
  validate
})(GmbMessagesForm);
