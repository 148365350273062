import React from 'react';
import ReviewsList from '../business/ReviewsList';
import PaginationComponent from '../common/Pagination';
import LoaderComponent from '../common/loaderComponent';

class CompetitorsReviews extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { location, history, match, businessId, data, replyOptions, handleReplyOption, getData, loader } = this.props;
    return (
      <div>
        <LoaderComponent loader={loader}/>
        <ReviewsList
          location={location}
          match={match}
          tagsDisabled={true}
          history={history}
          businessId={businessId}
          data={data}
          replyOptions={replyOptions}
          replyDisabled={true}
          isCompetitors={true}
          handleReplyOption={handleReplyOption}
          getData={getData}
        >
        </ReviewsList>
        <PaginationComponent
          count={data && data.count}
          location={this.props.location}
          pageSizeCookie='competitors_reviews'
        />
      </div>
    );
  }
}

export default CompetitorsReviews;
