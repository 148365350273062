import React, { useEffect, useRef, useState, forwardRef } from "react";
import google_svg from "../../../images/googlephoto.svg";
import burger_images from "../../../images/burger.jpg";
import addicon_svg from "../../../images/svg/addicon.svg";
import addcoverphoto_svg from "../../../images/svg/addcoverphoto.svg";
import { Card, Progress } from "reactstrap";
import SortIcon from "../../../images/svg/Sort.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import "./listing.css";
import PaginationComponent from "../../common/Pagination";
import { FPToolTip } from "../../common/new";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import BasicBusinessDetail from "./BasicBusinessDetail";
import ServiceQuestion from "./ServiceQuestion";
import BusinessHours from "./BusinessHours";
import LogoPhotoVideo from "./LogoPhotoVideo";
import WebsiteAppointment from "./WebsiteAppointment";
import { Box, Dialog, DialogActions, IconButton, Slide } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import UpdatePhotos from "../../../containers/listing/UpdatePhotos";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { setDateFormat } from "../../../libs/utils";
import LoaderComponent from "../../common/loaderComponent";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const TableList = [
  {
    date: "24-04-2024.",
    username: "Sukriti Tewari",
    mudule_name: "logo photo update",
    chnages: "5 photos added on 10 locations",
  },
  {
    date: "24-04-2024.",
    username: "Sukriti Tewari",
    mudule_name: "logo photo update",
    chnages: "5 photos added on 10 locations",
  },
  {
    date: "24-04-2024.",
    username: "Sukriti Tewari",
    mudule_name: "logo photo update",
    chnages: "5 categories added on 4 locations",
  },
  {
    date: "24-04-2024.",
    username: "Sukriti Tewari",
    mudule_name: "logo photo update",
    chnages: "5 photos added on 1 location",
  },
  {
    date: "24-04-2024.",
    username: "Sukriti Tewari",
    mudule_name: "logo photo update",
    chnages: "5 photos added on 10 locations",
  },
  {
    date: "24-04-2024.",
    username: "Sukriti Tewari",
    mudule_name: "logo photo update",
    chnages: "5 photos added on 10 locations",
  },
  {
    date: "24-04-2024.",
    username: "Sukriti Tewari",
    mudule_name: "logo photo update",
    chnages: "5 photos added on 10 locations",
  },
];

const LocationListingDetail = (props) => {
  const {
    history,
    business_id,
    basic_detail_data,
    primaryDetail,
    gmc_categories_data,
    attribute_data,
    selectReso,
    location,
    location_changes_logs,
    search,
    actions
  } = props;
  const [statusColor, setStatusColor] = useState("");
  const [statusTextColor, setStatusTextColor] = useState("");
  const [statusText, setStatusText] = useState("");

  useEffect(() => {
    if (primaryDetail?.overall_progress <= 20) {
      setStatusColor("very-low-color");
      setStatusTextColor("#57e32c");
      setStatusText("Very Low");
    } else if (primaryDetail?.overall_progress <= 40) {
      setStatusColor("low-color");
      setStatusTextColor("#ffa534");
      setStatusText("Low");
    } else if (primaryDetail?.overall_progress <= 60) {
      setStatusColor("moderate-color");
      setStatusTextColor("#ffe234");
      setStatusText("Moderate");
    } else if (primaryDetail?.overall_progress <= 80) {
      setStatusColor("high-color");
      setStatusTextColor("#b7dd29");
      setStatusText("High");
    } else {
      setStatusColor("very-high-color");
      setStatusTextColor("#57e32c");
      setStatusText("Very High");
    }
  }, [primaryDetail?.overall_progress]);

  const addressString = [
    primaryDetail?.addressLines, // Spread the address lines array
    primaryDetail?.locality, // Add locality
    primaryDetail?.postalCode, // Add postal code
  ].join(", ");

  const fileInputRef = useRef(null);

  // const handleDivClick = () => {
  //   fileInputRef.current.click();
  // };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log(file);
    }
  };
  const completionStatus = primaryDetail?.completion_score;

  let objectLength = "";
  if (completionStatus) {
    objectLength = Object.keys(completionStatus).length;
  }

  let count100 = "";
  if (completionStatus) {
    count100 = Object.values(completionStatus).filter(
      (value) => value !== 100
    ).length;
  }

  const [open, setOpen] = React.useState({
    photos: false,
    video: false,
    coverphoto: false,
  });
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (dialog, scrollType) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: true,
    }));
    setScroll(scrollType);
  };

  const handleClose = (dialog) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: false,
    }));
  };

  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };
  // let pageSize = 100;

  const queryParams = new URLSearchParams(search);

  const currentPage = queryParams.get("page");

  const pageSize = queryParams.get("page_size");

  useEffect(() => {
    actions.getGmbLocationChangeLog(business_id,selectReso,currentPage,pageSize )
  },[actions, business_id, selectReso,currentPage,pageSize])
  
  return (
    <>
      <Card>
        <div
          className="d-flex justify-content-between"
          style={{ padding: "20px" }}
        >
          <div className="fp-flex-grow-1" style={{ marginRight: "25px" }}>
            <div className="location-listing-container-detail">
              <div className="position-relative">
                <div
                  className="d-flex align-center justify-content-center gap-10"
                  style={{
                    height: "160px",
                    backgroundImage: `url(${addcoverphoto_svg})`,
                    borderTopLeftRadius: "22px",
                    borderTopRightRadius: "22px",
                    cursor: "pointer",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    style={{ backgroundColor: "#7A8193", borderRadius: "20px" }}
                    src={addicon_svg}
                    alt=""
                    onClick={() => handleClickOpen("coverphoto", "paper")}
                  />
                  <div
                    style={{ color: "#1B1C1D" }}
                    onClick={() => handleClickOpen("coverphoto", "paper")}
                  >
                    Add Cover Photo
                  </div>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
                <div
                  className="position-absolute"
                  style={{
                    left: "24px",
                    bottom: "0",
                    transform: "translateY(40%)",
                  }}
                >
                  <img
                    className="listing-card-image"
                    style={{
                      borderRadius: "50%",
                    }}
                    src={burger_images}
                    alt="burgerImage"
                  />
                </div>
              </div>
              <div className="location-listing-text">
                <div className="listing-header">
                  <div className="listing-info">
                    <div className="listing-name">{primaryDetail?.title}</div>
                    <FPButton
                      fontSize={"10px"}
                      backgroundColor={
                        // primaryDetail?.recommendationReason === ""
                        //   ? "#2462DE"
                        //   : primaryDetail?.recommendationReason ===
                        //     "BUSINESS_LOCATION_SUSPENDED"
                        //     ? "#F04438"
                        //     : "#F04438"
                        primaryDetail?.is_varified
                          ? "#2462DE"
                          : primaryDetail?.is_suspend
                          ? "#F04438"
                          : primaryDetail?.is_duplicate
                          ? "#F04438"
                          : ""
                      }
                      label={
                        // primaryDetail?.recommendationReason === ""
                        //   ? "Verified"
                        //   : primaryDetail?.recommendationReason ===
                        //     "BUSINESS_LOCATION_SUSPENDED"
                        //     ? "Suspended"
                        //     : "Duplicate"
                        primaryDetail?.is_varified
                          ? "Verified"
                          : primaryDetail?.is_suspend
                          ? "Suspended"
                          : primaryDetail?.is_duplicate
                          ? "Duplicate"
                          : ""
                      }
                      size="small"
                    />
                  </div>
                </div>
                <div className="review-address d-flex gap-10">
                  <p className="mb-0">Category:</p>
                  <p className="location-text">
                    {primaryDetail?.category_name?.primary}
                  </p>
                </div>
                <div className="review-address d-flex gap-10">
                  <p className="mb-0">Open Status:</p>
                  <p
                    className="location-text"
                    style={{
                      cursor: "grab",
                      color:
                        primaryDetail?.openInfo_status === "OPEN"
                          ? "#12B76A"
                          : primaryDetail?.openInfo_status ===
                            "CLOSED_TEMPORARILY"
                          ? "#F04438"
                          : "#F04438",
                    }}
                  >
                    {primaryDetail?.openInfo_status === "OPEN"
                      ? "Open"
                      : primaryDetail?.openInfo_status === "CLOSED_TEMPORARILY"
                      ? "Closed Temporarily"
                      : "Closed Permanently"}
                  </p>
                </div>
                <div className="review-address">
                  <p
                    className="location-text"
                    style={{
                      color: "#646565",
                    }}
                  >
                    {addressString}
                  </p>
                </div>
                <div
                  className="review-address"
                  style={{ display: "grid", gap: "6px" }}
                >
                  <p
                    className="location-text"
                    style={{
                      fontSize: "18px",
                    }}
                  >
                    Completion Status
                  </p>
                  <div>
                    <Progress
                      style={{ height: "8px" }}
                      barClassName={statusColor}
                      color={statusColor}
                      value={primaryDetail?.overall_progress}
                    />
                  </div>
                  <p
                    className="review-address"
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    {count100} out of {objectLength} sections are incomplete
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <img
              className="listing-container-image"
              src={google_svg}
              alt="verifiedImage"
            />
          </div>
        </div>
      </Card>

      <div className="listing-detail-card">
        <div>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#1A2544",
              padding: "12px 20px 2px 20px",
            }}
          >
            Basic Business Details - Categories, Address, & Status
          </h2>
        </div>
        <BasicBusinessDetail
          primaryDetail={primaryDetail}
          basic_detail_data={basic_detail_data}
          gmc_categories_data={gmc_categories_data}
          business_id={business_id}
          selectReso={selectReso}
        />
      </div>
      <div className="listing-detail-card">
        <div>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#1A2544",
              padding: "12px 20px 2px 20px",
            }}
          >
            Service, FAQ's Amenities
          </h2>
        </div>
        <ServiceQuestion
          primaryDetail={primaryDetail}
          basic_detail_data={basic_detail_data}
          attribute_data={attribute_data}
          business_id={business_id}
          selectReso={selectReso}
        />
      </div>

      <div className="listing-detail-card">
        <div>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#1A2544",
              padding: "12px 20px 2px 20px",
            }}
          >
            Business Opening & Closing Hours
          </h2>
        </div>
        <BusinessHours
          primaryDetail={primaryDetail}
          basic_detail_data={basic_detail_data}
          business_id={business_id}
          selectReso={selectReso}
        />
      </div>
      <div className="listing-detail-card">
        <div>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#1A2544",
              padding: "12px 20px 2px 20px",
            }}
          >
            Logo, Photos, Videos
          </h2>
        </div>
        <LogoPhotoVideo
          primaryDetail={primaryDetail}
          basic_detail_data={basic_detail_data}
          business_id={business_id}
          selectReso={selectReso}
        />
      </div>
      <div className="listing-detail-card">
        <div>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "400",
              color: "#1A2544",
              padding: "12px 20px 2px 20px",
            }}
          >
            Websites, Menu, Appointment & Lock Update
          </h2>
        </div>
        <WebsiteAppointment
          primaryDetail={primaryDetail}
          basic_detail_data={basic_detail_data}
          business_id={business_id}
          selectReso={selectReso}
        />
      </div>
      <div className="listing-detail-card" style={{ padding: "5px 24px" }}>
        <h2
          style={{
            fontSize: "16px",
            fontWeight: "400",
            color: "#1A2544",
            padding: "12px 20px 0px 0px",
          }}
        >
          Changes log
        </h2>
        <div
          className="mt-3 bg-white"
          style={{ padding: "5px 24px", borderRadius: "16px" }}
        >
          <Table className="fp-table fp-table-center">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "#1A2544",
                  }}
                >
                  Date & Time
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "#1A2544",
                    backgroundColor: "#DDEFF0",
                  }}
                >
                  Username
                  <SortIcon />
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "#1A2544",
                  }}
                >
                  Module Name
                  <SortIcon />
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "13px",
                    fontWeight: "400",
                    color: "#1A2544",
                  }}
                >
                  Changes
                  <SortIcon />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {location_changes_logs?.results?.length > 0 ? (
                location_changes_logs?.results?.map((data) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell>
                          <div>{setDateFormat(data?.created_at)}</div>
                        </TableCell>
                        <TableCell
                          className="width-md"
                          // onClick={() => viewInvitedMembers(item)}
                        >
                          <FPToolTip
                            title={data?.username}
                            className="templateName"
                          >
                            {data?.username}
                          </FPToolTip>
                        </TableCell>
                        {/* <TableCell>
                              <span>{data?.username}</span>
                            </TableCell> */}
                        <TableCell>
                          <span>{data?.module_name}</span>
                        </TableCell>
                        <TableCell>
                          <span>{data?.changes}</span>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell className="text-center" colSpan={10}>
                    No records Found{" "}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>{" "}
          </Table>
          {/* <PaginationComponent
            count={location_changes_logs?.count}
            location={location}
            pageSizeCookie={pageSize}
          /> */}
        </div>
      </div>
      <Dialog
        open={open.coverphoto}
        onClose={() => handleClose("coverphoto")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="custom-dialog"
        maxWidth="lg"
        fullWidth
        // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height:"90vh",
            width: "100%",
          },
        }}  
      >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              className="align-center justify-content-between mb-2"
              style={{
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
              }}
            >
              <span className="mb-0">Update Photos</span>
              <CloseIcon onClick={() => handleClose("coverphoto")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("coverphoto")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdatePhotos
              defaultvalue={2}
              type="coverphoto"
              business_id={business_id}
              primaryDetail={primaryDetail}
              selectReso={selectReso}
            />
          </div>
        </Box>
      </Dialog>
    </>
  );
};

// export default LocationListingDetail;

const mapStateToProps = (state) => {
  return {
    location_changes_logs: state.gmbListingLocation.location_changes_logs,
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(LocationListingDetail);
