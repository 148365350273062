/* eslint-disable no-unused-vars */
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Box, makeStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import { Divider } from "semantic-ui-react";
import { FPButton } from "./new";
import backArrow from "../../images/svg/back-Arrow.svg";

const useStyle = makeStyles({
  header: {
    padding: "24px",
    paddingBottom: "0",
  },
  body: {
    padding: "24px",
  },
  footer: {
    padding: "10px 24px",
  },
  iconHeader: {},
});

const DefaultDialog = withStyles({
  paper: {
    borderRadius: "12px",
    width: ({ width }) => (width ? width : "auto"),
    height: ({ height }) => (height ? height : "auto"),
  },
  scrollPaper: {
    alignItems: ({ alignment }) =>
      alignment === "top" ? "flex-start" : "center",
    marginTop: ({ alignment }) => (alignment === "top" ? "60px" : "0px"),
  },
})((props) => <Dialog {...props} />);

const PostModel = (props) => {
  const {
    isOpen,
    toggle,
    heading,
    children,
    onClose,
    dialogActions,
    size,
    maxWidth,
    width,
    className,
    showDivider = false,
    showDeleteBtn = true,
    actionClass,
    bodyClass,
    headerClass,
    headerWrapperClass,
    styleHeader = false,
    alignment,
    contentClass,
    subHeading,
    height,
    deletePost,
    ...rest
  } = props;

  const handleClose = () => {
    toggle();

    onClose && onClose();
  };

  const classes = useStyle({ width });

  return (
    <div>
      <DefaultDialog
        open={isOpen}
        onClose={handleClose}
        width={width}
        height={height}
        alignment={alignment}
        scroll="paper"
        className={classNames(className)}
        fullWidth={true}
        maxWidth={maxWidth || "md"}
        {...rest}
      >
        {(heading || showDeleteBtn) && (
          <DialogTitle
            disableTypography
            id="scroll-dialog-title"
            className={`${(classes.header, headerWrapperClass)}`}
          >
            <div
              className={classNames(
                "d-flex justify-content-between align-item-start",
                headerClass
              )}
            >
              <div
                className="font-blue h3 align-center gap-10"
                onClick={toggle}
              >
                <Box component="img" src={backArrow} sx={{}} />
                {heading && heading}
              </div>
              {showDeleteBtn && (
                // <CloseIcon role="button" style={{ color: "#1B1C1D" }} aria-label="close" onClick={toggle} />
                <FPButton
                  size="small"
                  label="Delete"
                  variant="outline"
                  borderColor="#BFC2CB"
                  textColor="#1B1C1D"
                  onClick={deletePost}
                  className="px-4"
                />
              )}
            </div>
            {subHeading && subHeading}
          </DialogTitle>
        )}
        {showDivider && <Divider className="m-0" />}
        <DialogContent
          component="section"
          className={classNames(classes.body, bodyClass, {
            "pt-0": !styleHeader,
          })}
        >
          <DialogContentText
            component="div"
            id="scroll-dialog-description"
            tabIndex={-1}
            className={classNames(contentClass)}
          >
            {children}
          </DialogContentText>
        </DialogContent>
        {dialogActions && (
          <DialogActions className={classNames(classes.footer, actionClass)}>
            {dialogActions}
          </DialogActions>
        )}
      </DefaultDialog>
    </div>
  );
};

export default PostModel;
