import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Form } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { InputField, SelectField } from "react-semantic-redux-form";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import PhoneInputField from "../common/phonefields";
import TagsListModal from "./TagsListModal";
import DateInput from "../common/DateInput";
import { FPButton } from "../common/new";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";

const validate = (values, props) => {
  // eslint-disable-line
  const errors = {};
  if (!values.name) {
    errors.name = "Enter name";
  } else if (!/^[a-zA-Z\s]+$/i.test(values.name)) {
    errors.name = "Invalid Name";
  }
  if (
    !values.email &&
    !values.contact_number &&
    (("currentTab" in props && props.currentTab === "1") ||
      !("currentTab" in props))
  ) {
    if (!values.email) errors.email = "Enter Email or Mobile Number";
    if (!values.contact_number)
      errors.contact_number = "Enter Mobile Number or Email";
  }

  if (!values.branch) {
    errors.branch = "Select Branch";
  }

  return errors;
};

class ContactsForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isContactTagsOpen: false };
    this.submit = this.submit.bind(this);
    this.toggleContactTags = this.toggleContactTags.bind(this);
  }
  submit(data) {
    const { addOrEditContact } = this.props;
    const { assigned_tags: tag_list, dob, anniversary, ...rest } = data;
    if (dob != undefined) {
      var db = dob.split("T")[0];
    } else {
      db = null;
    }
    if (anniversary != undefined) {
      var ad = anniversary.split("T")[0];
    } else {
      ad = null;
    }
    return addOrEditContact({
      tag_list: tag_list ? tag_list : [],
      dob: db,
      anniversary: ad,
      ...rest,
    }).catch((err) => {
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  }
  componentDidUpdate(prevProps) {
    const { onCountryChange, initialValues } = this.props;
    if (initialValues && initialValues.country) {
      if (!prevProps.initialValues) {
        onCountryChange(initialValues.country);
      } else if (prevProps.initialValues.country !== initialValues.country) {
        onCountryChange(initialValues.country);
      }
    }
  }

  toggleContactTags() {
    const { isContactTagsOpen } = this.state;
    this.setState({ isContactTagsOpen: !isContactTagsOpen });
  }

  render() {
    const {
      submitting,
      handleSubmit,
      errors,
      countryOptionList,
      regionOptionList,
      tagsList,
      onCountryChange,
      tagsSelected,
      branchOptionList,
      selectedCountry,
    } = this.props;
    const { isContactTagsOpen } = this.state;
    return (
      <Form className="grid-form" onSubmit={handleSubmit(this.submit)}>
        {errors && errors.non_field_errors
          ? errors.non_field_errors.map((error, index) => (
              <p key={index} className="text-danger">
                {error}
              </p>
            ))
          : null}
        <Row>
          <Col sm="6" className="mt-1">
            <Field
              name="name"
              component={InputField}
              placeholder="Name"
              type="text"
              label="Name"
              required
            />
            <Field
              name="gender"
              type="text"
              component={SelectField}
              selectOnBlur={false}
              label="Gender"
              placeholder="Select Gender"
              options={[
                { text: "Male", value: "male" },
                { text: "Female", value: "female" },
                { text: "Others", value: "other" },
              ]}
            />
            <Field
              name="email"
              component={InputField}
              placeholder="E-mail address"
              type="email"
              label="E-mail"
            />
            <Field
              name="contact_number"
              component={PhoneInputField}
              placeholder="Mobile Number"
              type="text"
              label="Mobile Number"
            />
            <Field
              name="dob"
              component={DateInput}
              placeholder="Date of Birth"
              type="text"
              label="Date of Birth"
            />
          </Col>
          <Col sm="6" className="mt-1">
            <Field
              name="country"
              type="text"
              component={SelectField}
              label="Country"
              placeholder="Select Country"
              options={countryOptionList}
              onChange={(e, val) => onCountryChange(val)}
            />
            <Field
              name="state"
              type="text"
              component={SelectField}
              label="State"
              placeholder="Select State"
              options={regionOptionList}
              disabled={!selectedCountry}
            />
            <Field
              name="city"
              component={InputField}
              placeholder="City"
              type="text"
              label="City"
            />
            <Field
              name="branch"
              type="text"
              component={SelectField}
              label="Branch"
              placeholder="Select Branch"
              options={branchOptionList}
              required
            />
            <Field
              name="anniversary"
              component={DateInput}
              placeholder="Anniversary"
              type="text"
              label="Anniversary"
            />
          </Col>

          <Col sm="12">
            <Field
              name="assigned_tags"
              component={TagsListModal}
              isContactTagsOpen={isContactTagsOpen}
              toggleContactTags={this.toggleContactTags}
              tagsList={tagsList}
              header={
                <div style={{ fontSize: "16px", fontWeight: "400" }}>
                  Select or Deselect the tags you want to associate with this
                  contact.
                  <div>
                    <span>Don't see the tag you need?</span>
                    <span className="ml-2">
                      <Link
                        to={{ pathname: "manage-tags" }}
                        style={{ color: "rgba(0, 181, 173, 1)" }}
                      >
                        Manage Tags
                      </Link>
                    </span>
                  </div>
                </div>
              }
            />
            <div className="d-flex align-items-center justify-content-between gap-10 mt-15">
              <FPButton
                startIcon={
                  <LocalOfferOutlinedIcon
                    style={{
                      width: "15px",
                      height: "15px",
                      marginRight: "-5px",
                    }}
                  />
                }
                className="px-3"
                backgroundColor="#161616"
                size="small"
                fontWeight="400"
                fontSize="13px"
                onClick={(e) => {
                  e.preventDefault();
                  this.toggleContactTags();
                }}
                label={
                  tagsSelected && tagsSelected.length
                    ? "Manage Tags"
                    : "Select Tags"
                }
                style={{
                  height: 32,
                }}
              />
              <FPButton
                type="submit"
                label="Save"
                backgroundColor="#F13A88"
                fontWeight="400"
                fontSize="13px"
                style={{
                  height: 32,
                }}
              />
            </div>
          </Col>
        </Row>
      </Form>
    );
  }
}

ContactsForm = reduxForm({
  // eslint-disable-line
  form: "ContactsForm",
  validate,
  enableReinitialize: true,
})(ContactsForm);

const selector = formValueSelector("ContactsForm");

ContactsForm = connect((state) => {
  // eslint-disable-line
  return {
    tagsSelected: selector(state, "assigned_tags"),
    selectedCountry: selector(state, "country"),
  };
})(ContactsForm);

export default ContactsForm;
