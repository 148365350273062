export const startRatingColors = [
  "#ff4545", //0 to 1
  "#ffa534", //1.1 to 2
  "#ffe234", // 2.1 to 3
  "#b7dd29", // 3.1 to 4
  "#57e32c", // 4.1 to 5
];

export const startRatingBgColors = [
  "#fbd9d3",
  "#ffeeeb", // 2
  "#fffbeb", // 3
  "#f4fee8", //4
  "#f5fee8", // 5
];

export const pastelColorScale = [
  "rgb(255, 99, 132)", // Red
  "rgb(54, 162, 235)", // Blue
  "rgb(255, 205, 86)", // Yellow
  "rgb(255, 159, 64)", // Orange
  "rgb(75, 192, 192)", // Teal
  "rgb(153, 102, 255)", // Purple
  "rgb(255, 50, 50)", // Dark Red
  "rgb(70, 130, 180)", // Steel Blue
  "rgb(255, 140, 0)", // Dark Orange
  "rgb(0, 128, 0)", // Green
  "rgb(255, 69, 0)", // Red-Orange
  "rgb(0, 0, 128)", // Navy
  "rgb(139, 0, 139)", // Dark Magenta
  "rgb(0, 255, 127)", // Spring Green
  "rgb(255, 165, 0)", // Orange
  "rgb(128, 0, 128)", // Purple
  "rgb(0, 255, 255)", // Cyan
  "rgb(255, 192, 203)", // Pink
  "rgb(255, 255, 0)", // Yellow
  "rgb(0, 128, 128)", // Teal
  "rgb(255, 0, 255)", // Magenta
  "rgb(0, 255, 0)", // Lime
  "rgb(128, 128, 0)", // Olive
  "rgb(128, 0, 0)", // Maroon
  "rgb(0, 0, 255)", // Pure Blue
  "rgb(165, 42, 42)", // Brown
  "rgb(0, 128, 255)", // Sky Blue
];

export const colorList = [
  ...startRatingColors,
  ...pastelColorScale,
  "#FFC16C", // Light orange
  "#E96A7A", // Light pink
  "#FFA566", // Light peach
  "#7EABFF", // Light blue
  "#8FAE6A", // Light green
  "#7480A3", // Light navy
  "#7F8E8F", // Light teal
  "#FF7AB3", // Light magenta
  "#9BD2B3", // Light mint green
  "#FFAC66", // Light salmon
  "#809EC1", // Light sky blue
  "#FF99FF", // Light lavender
  "#B07AFF", // Light purple
  "#FFDE93", // Light mustard
  "#C89CAB", // Light rose
  "#FFD9B3", // Light apricot
  "#AAD4FF", // Light periwinkle
  "#BCE08A", // Light lime
  "#A7B5CC", // Light steel blue
  "#B2C4B2", // Light sage
  "#FFADD6", // Light mauve
  "#C8EAC8", // Light mint
  "#FFD180", // Light gold
  "#9b82b6", // Light lavender gray
  "#FFB2E6", // Light pink-purple
  "#FFEDB3", // Light cream
  "#9ACD32", // Yellow-green
  "#4682B4", // Steel blue
  "#FF6347", // Tomato
  "#556B2F", // Dark olive green
  "#20B2AA", // Light sea green
  "#8A2BE2", // Blue violet
  "#CD5C5C", // Indian red
  "#6A5ACD", // Slate blue
  "#F08080", // Light coral
  "#2F4F4F", // Dark slate gray
  "#8B4513", // Saddle brown
  "#2E8B57", // Sea green
  "#9932CC", // Dark orchid
  "#FF8C00", // Dark orange
  "#483D8B", // Dark slate blue
  "#DAA520", // Goldenrod
  "#00CED1", // Dark turquoise
  "#8B0000", // Dark red
  "#FF1493", // Deep pink
  "#800080", // Purple
  "#808000", // Olive
  "#4169E1", // Royal blue
  "#20B2AA", // Light sea green
  "#191970", // Midnight blue
  "#FA8072", // Salmon
  "#6B8E23", // Olive drab
];

export const serviceOptions = [
  { value: "dining", display_name: "Dining" },
  { value: "delivery", display_name: "Delivery" },
  { value: "Takeaway", display_name: "Takeaway" },
];

export const sourceOptions = [
  { value: "youtube", display_name: "Youtube" },
  { value: "facebook", display_name: "Facebook" },
  { value: "linkedin", display_name: "Linkedin" },
  { value: "Instagram", display_name: "Instagram" },
  { value: "twitter", display_name: "Twitter" },
];

export const messageTypeOptions = [
  { value: "comments, ad comments", display_name: "comments" },
  { value: "ad comments", display_name: "ad comments" },
];

export const lightCommentColor = [
  "#F6F6F8",
  "#F2F9F9",
  "#FFFFF0",
  "#F0FFF3",
  "#F9F0FF",
  "#FFF5F2",
  "#F2FFF4",
  "#F7F9F9",
  "#F5F5F0",
  "#F0F5FF",
  "#FAF0F9",
  "#F4F2F9",
  "#FCF9F0",
  "#F0FCF4",
  "#F9F0FC",
  "#FCF5F0",
  "#F2F2F9",
  "#F9FCF0",
  "#F0F9FC",
  "#FFF0F2",
  "#F9F0F5",
];

export const insightsLocationColors = {
  "-": "rgba(26, 37, 68, 1)",
  F: "rgba(240, 68, 56, 1)",
  D: "rgba(249, 112, 102, 1)",
  C: "rgba(243, 135, 68, 1)",
  "C+": "rgba(239, 104, 32, 1)",
  B: "rgba(247, 144, 9, 1)",
  "B+": "rgba(220, 104, 3, 1)",
  A: "rgba(18, 183, 106, 1)",
  "A+": "rgba(3, 152, 85, 1)",
  "": "#ffffff"
};

export const insightsLocationBgColors = {
  "-": "white",
  F: "rgba(254, 243, 242, 1)",
  D: "rgba(254, 243, 242, 1)",
  C: "rgba(254, 246, 238, 1)",
  "C+": "rgba(254, 246, 238, 1)",
  B: "rgba(255, 250, 235, 1)",
  "B+": "rgba(255, 250, 235, 1)",
  A: "rgba(236, 253, 243, 1)",
  "A+": "rgba(236, 253, 243, 1)",
  "": "#ffffff"
};

export const daysList = Array.from({ length: 30 }, (value, index) => ({
  display_name: (index + 1).toString(),
  value: (index + 1).toString(),
}));

export const hourList = Array.from({ length: 24 }, (value, index) => ({
  display_name: (index + 1).toString(),
  value: (index + 1).toString(),
}));

export const newWeekDays = [
  {
    label: "Monday",
    day: "MONDAY",
    type: "closed",
    closeTime: [],
    openTime: [],
    time: [],
  },
  {
    label: "Tuesday",
    day: "TUESDAY",
    type: "closed",
    closeTime: [],
    openTime: [],
    time: [],
  },
  {
    label: "Wednesday",
    day: "WEDNESDAY",
    type: "closed",
    closeTime: [],
    openTime: [],
    time: [],
  },
  {
    label: "Thursday",
    day: "THURSDAY",
    type: "closed",
    closeTime: [],
    openTime: [],
    time: [],
  },
  {
    label: "Friday",
    day: "FRIDAY",
    type: "closed",
    closeTime: [],
    openTime: [],
    time: [],
  },
  {
    label: "Saturday",
    day: "SATURDAY",
    type: "closed",
    closeTime: [],
    openTime: [],
    time: [],
  },
  {
    label: "Sunday",
    day: "SUNDAY",
    type: "closed",
    closeTime: [],
    openTime: [],
    time: [],
  },
];
