import React from "react";
import CustomStarRatingComponent from "../../../../components/common/StarComponent";
import { setDecimalIndex } from "../../../../libs/utils";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
  pageHeading: {
    lineHeight: 1,
    fontSize: "20px",
  },
  ratingWrapper: {
    padding: "3px 9px",
    borderRadius: "50px",
    backgroundColor: "#F6F6F8",
    color: "#7A8193;",
    display: "flex",
    justifyContent: "center",
  },
  ratingInfo: {
    fontSize: "16px !important",
    paddingTop: "2px",
  },
  totalRecord: {
    // paddingTop: '-14px',
    fontSize: "16px",
    paddingTop: "2px",
  },
});
const ReviewPageSummary = ({ reviewList }) => {
  const reviewAvgRating = reviewList ? reviewList.rating_avg : 0;
  const totalRecord = reviewList ? reviewList.count : 0;

  const classes = useStyles();

  return (
    <div className="d-flex align-items-center gap-10">
      <h3 className={classNames(classes.pageHeading, "font-blue mb-0 weight-600 pageHeading")}>Reviews</h3>
      <div className={classes.ratingWrapper}>
        <CustomStarRatingComponent
          name=""
          starCount={1}
          style={{ fontSize: "16px" }}
          className="fp-size-18"
          value={setDecimalIndex(reviewAvgRating)}
          editing={false}
        />
        <span className={classNames(classes.ratingInfo, "font-blue-gray fp-size-14 ml-2 weight-500")}>
          {setDecimalIndex(reviewAvgRating)}
        </span>
      </div>
      <span className={classNames(classes.totalRecord, "font-blue-gray fp-size-16")}>{totalRecord} Results</span>
    </div>
  );
};

const mapStateToProps = (state) => ({
  reviewList: state.review.list,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(ReviewPageSummary);
