import React from "react";

function EmptyUnread({setSelected}) {
    return (
        <div className="empty-state d-flex justify-content-center align-items-center">
            <div>

                <p className="no-chats">No unread chats</p>
                <p onClick={()=>{setSelected("All")}} className="view-all">View all chats</p>
            </div>
        </div>
    );
}

export default EmptyUnread;
