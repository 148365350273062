import React from "react";
// import BulkUpdatePage from "../../components/listing/bulkupdate/BulkUpdatePage";

class BulkUpdate extends React.Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (
      <>
          {/* <LoaderComponent loader={loader} /> */}
        {/* <BulkUpdatePage history={this.props.history}/> */}
      </>
    );
  }
}

export default BulkUpdate;
