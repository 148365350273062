import React from "react";
import { Table } from "antd";
import DisplayPercentage from "../common/DisplayPercentage";
import { NoData } from "../../components/common/new";
import LoaderComponent from "../../components/common/loaderComponent";

const renderPercentage = (value, total) => {
  const percentage = total > 0 ? ((value / total) * 100).toFixed(2) : 0;
  return (
    <div className="d-flex align-items-baseline">
      <p className="m-0 pr-1">{value}</p>
      <DisplayPercentage totalValue={total} currentValue={value} />
    </div>
  );
};

const columns = [
  {
    title: "Business Name",
    dataIndex: "ownerCompetitorName",
  },
  {
    title: "1 star",
    dataIndex: "oneStar",
    sorter: {
      compare: (a, b) => a.oneStar - b.oneStar,
    },
    render: (_, { oneStar, total }) => renderPercentage(oneStar, total),
  },
  {
    title: "2 star",
    dataIndex: "twoStar",
    sorter: {
      compare: (a, b) => a.twoStar - b.twoStar,
    },
    render: (_, { twoStar, total }) => renderPercentage(twoStar, total),
  },
  {
    title: "3 star",
    dataIndex: "threeStar",
    sorter: {
      compare: (a, b) => a.threeStar - b.threeStar,
    },
    render: (_, { threeStar, total }) => renderPercentage(threeStar, total),
  },
  {
    title: "4 star",
    dataIndex: "fourStar",
    sorter: {
      compare: (a, b) => a.fourStar - b.fourStar,
    },
    render: (_, { fourStar, total }) => renderPercentage(fourStar, total),
  },
  {
    title: "5 star",
    dataIndex: "fiveStar",
    sorter: {
      compare: (a, b) => a.fiveStar - b.fiveStar,
    },
    render: (_, { fiveStar, total }) => renderPercentage(fiveStar, total),
  },
  {
    title: "Total",
    dataIndex: "total",
    sorter: {
      compare: (a, b) => a.total - b.total,
    },
  },
];

const StarRatingTable = ({ ratingDetails, loader, businessDetails }) => {
  const dataSource = ratingDetails.map((item, index) => ({
    ownerCompetitorName: item.owner_name || item.competitor_name,
    oneStar: item.one_star_count,
    twoStar: item.two_star_count,
    threeStar: item.three_star_count,
    fourStar: item.four_star_count,
    fiveStar: item.five_star_count,
    total: item.total_star_count,
  }));

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  if (dataSource.length === 0) {
    return <NoData />;
  }

  return (
    <div className="px-3">
      <LoaderComponent loader={loader} />
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        onChange={onChange}
        showSorterTooltip={{ title: "" }}
        rowClassName={(record) => businessDetails === record.ownerCompetitorName ? "highlight-row" : ""}
      />
    </div>
  );
};

export default StarRatingTable;
