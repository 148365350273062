import React from "react";
import { Button } from "semantic-ui-react";
import FormModal from "./FormModal";
import DoneIcon from "@material-ui/icons/Done";
import Warning from "@material-ui/icons/ReportProblemOutlined";

class SubmitConfirmationModal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { isOpen, type, toggle, header, onConfirm, subHeader, style } = this.props;
    return (
      <FormModal
        isOpen={isOpen}
        toggle={toggle}
        showDivider={false}
        showCloseBtn={false}
        maxWidth="xs"
        headerClass="d-flex justify-content-center align-items-center"
        styleHeader={true}
        dialogActions={
          <div className="d-flex justify-content-between align-item-start mb-3 w-100" style={{ gap: "12px" }}>
            <Button
              className="w-50"
              style={{
                borderRadius: "8px",
                width: "170px",
                background: "none",
                border: "2px solid #BFC2CB",
                color: "#1B1C1D",
              }}
              type="submit"
              color="black"
              onClick={toggle}
            >
              Cancel
            </Button>
            <Button
              className="w-50"
              style={{ borderRadius: "8px", width: "170px" }}
              type="submit"
              color={type === "warning" ? "red" : "green"}
              onClick={() => {
                toggle();
                onConfirm();
              }}
            >
              Yes, Submit
            </Button>
          </div>
        }
      >
        <div className="d-flex justify-content-center flex-column align-items-center">
          <div
            style={{
              height: "48px",
              width: "48px",
              borderRadius: "50%",
              background: type === "warning" ? "#FFFAEB" : "#EDFCF3",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#DE3024",
            }}
            className="mb-4"
          >
            {type === "warning" ? <Warning style={{ color: "#DC6803" }} /> : <DoneIcon style={{ color: "green" }} />}
          </div>
          <p style={style}>{header}</p>
        </div>
        {subHeader && (
          <p style={{ fontWeight: 400, fontSize: "15px", textAlign: "center" }} className="fp-size-16">
            {subHeader}
          </p>
        )}
      </FormModal>
    );
  }
}

export default SubmitConfirmationModal;
