import React from "react";
import SortIcon from "../../../../images/svg/Sort.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Box, Dialog, DialogActions, IconButton } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import RootHeader from "../../../../modules/listing/bulkupdate/RootHeader";
import { FPToolTip } from "../../../../components/common/new";
import PaginationComponent from "../../../../components/common/Pagination";
import * as setSelectedTab from "../../../../actions/common/setSelectedTabAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoaderComponent from "../../../../components/common/loaderComponent";
import CardWrapper from "../../../../modules/dashboard/component/CardWrapper";
import * as locationActions from "./../../../../actions/listing/listingLocationAction";
import { setDateFormat } from "../../../../libs/utils";
import FPButton from "../../../../components/common/new/FPFilter/Listing/FPButton"; //"../../../../../common/new/FPFilter/Listing/FPButton";
// import * as locationActions from "./../../../actions/listing/listingLocationAction";
import queryString from "query-string";
import BulkChangesStatus from "./BulkChangesStatus";

class Bulkchanges extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openTab: false,
      scroll: "paper",
      bulkChangesStatus: [],
    };
    // this.handleClose = this.handleClose.bind(this);
    // this.handleViewChnagesStatusClick = this.handleViewChnagesStatusClick.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { business_id },
      },
      location: { search, pathname },
    } = this.props;
    const queryParams = new URLSearchParams(search);
    let pageSize = queryParams.get("page_size");
    let page = queryParams.get("page");

    this.props.actionsSelectTab.setSelectedTab(2);
    this.props.actions.getGmbBulkChangeLog(business_id,pageSize,page);
  }

  componentWillReceiveProps = (nextProps) => {
    const {
      location: { search: prevSearch },
    } = this.props;

    const {
      match: {
        params: { business_id },
      },
      location: { search: nextSearch },
    } = nextProps;

    if (prevSearch !== nextSearch) {
      const queryParams = queryString.parse(nextSearch);
      this.props.actions.getGmbBulkChangeLog(
        business_id,
        queryParams?.page_size,
        queryParams?.page
      );
    }
  };

  handleClose = () => {
    this.setState({
      openTab: false,
      scroll: "paper",
    });
  };

  handleViewChnagesStatusClick = (changeLogId, data) => {
    const { bulkChangeLogData } = this.props;

    this.setState({
      openTab: true,
      scroll: "paper",
      bulkChangesStatus: data,
    });
  };

  render() {
    const { loader, bulkChangeLogData, location } = this.props;
    const { openTab, scroll, bulkChangesStatus } = this.state;

    // const handleViewChnagesStatusClick = (changeLogId) => {
    //   const bulkChnageLogObj = bulkChangeLogData?.results?.find((data) => data.id === changeLogId)
    //   const bulkChnagesStatus = bulkChnageLogObj.bulk_changes_status
    //   handleClickOpen()
    //   console.log(bulkChnagesStatus)
    // }

    const style = {
      position: "relative",
      border: "none",
      "&:focus-visible": {
        outline: "none",
      },
      minHeight: "80vh",
    };

    return (
      <>
        <div style={{ paddingBlock: "16px" }}>
          <LoaderComponent loader={loader} />
          <RootHeader defaultValue={1} history={this.props.history} />
          <CardWrapper showDivider={false}>
            <div className="ui-table-responsive">
              <Table className="fp-table fp-table-center">
                <TableHead>
                  <TableRow>
                    <TableCell>Date & Time</TableCell>
                    <TableCell>
                      Username
                      <SortIcon />
                    </TableCell>
                    <TableCell>
                      Module Name
                      <SortIcon />
                    </TableCell>
                    <TableCell style={{ width: "300px" }}>
                      Changes
                      <SortIcon />
                    </TableCell>
                    {/* <TableCell style={{ widt: "300px" }}>
                      Chnages Status
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bulkChangeLogData?.results?.length > 0 ? (
                    bulkChangeLogData?.results?.map((data) => {
                      console.log("datadatadata>>>>>>>>>>>>>", data);

                      return (
                        <>
                          <TableRow>
                            <TableCell>
                              <div>{setDateFormat(data?.created_at)}</div>
                            </TableCell>
                            <TableCell
                              className="width-md"
                              // onClick={() => viewInvitedMembers(item)}
                            >
                              <FPToolTip
                                title={data?.username}
                                className="templateName"
                              >
                                {data?.username}
                              </FPToolTip>
                            </TableCell>
                            {/* <TableCell>
                              <span>{data?.username}</span>
                            </TableCell> */}
                            <TableCell>
                              <span>{data?.module_name}</span>
                            </TableCell>
                            <TableCell>
                              <span
                                style={{
                                  backgroundColor:
                                    data?.success_locations ===
                                    data?.total_locations
                                      ? "green"
                                      : "red",
                                  padding: "10px",
                                  borderRadius: "20px",
                                  color: "white",
                                  cursor:"pointer"
                                }}
                                onClick={() =>
                                  this.handleViewChnagesStatusClick(
                                    data?.id,
                                    data
                                  )
                                }
                              >{`${data?.success_locations} out of ${data?.total_locations} completed locations`}</span>
                            </TableCell>
                            {/* <TableCell>
                              <FPButton
                                label="View Chnages Status"
                                size="small"
                                style={{ height: "30px" }}
                                onClick={() =>
                                  this.handleViewChnagesStatusClick(
                                    data?.id,
                                    data
                                  )
                                }
                              />
                            </TableCell> */}
                          </TableRow>
                        </>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell className="text-center" colSpan={10}>
                        No records Found{" "}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <PaginationComponent
                count={bulkChangeLogData?.count}
                location={location}
                pageSizeCookie={"bulk-changes-log"}
              />
            </div>
          </CardWrapper>
        </div>

        <Dialog
          open={openTab}
          onClose={this.handleClose}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
        >
          <Box sx={style}>
            <div style={{ padding: "25px 25px 0 25px" }}>
              <div
                className="d-flex mt-10 "
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "600",
                  fontSize: "24px",
                  color: "#1A2544",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  paddingTop: "20px",
                }}
              >
                <BulkChangesStatus
                  bulkChangesStatus={this.state.bulkChangesStatus}
                />
                {/* <DialogActions>
                  <IconButton onClick={this.handleClose}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions> */}
              </div>
              {/* <UpdateWebsiteLink queryStringData={queryStringData} busines_status={false} business_id={business_id} /> */}
            </div>
          </Box>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
    bulkChangeLogData: state.gmbListingLocation.bulkChangeLogData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Bulkchanges);
