import React from 'react';

import PropTypes from 'prop-types';

class MarkerComponent extends React.PureComponent {
  componentDidMount() {
    if (!this.marker) this.renderMarker();
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.map !== prevProps.map) ||
      (JSON.stringify(this.props.position) !== JSON.stringify(prevProps.position))
    ) {
      // The relevant props have changed
      if (Object.keys(this.props.position).length) {
        this.renderMarker();
      }
      else if (this.marker) {
        this.marker.setMap(null);
        // this.marker = null;
      }
    }
  }

  componentWillUnmount() {
    if (this.marker) {
      this.marker.setMap(null);
    }
  }

  getMarkerColor(color) {
    let pinColor = '';
    console.log("colorcolorcolorcolorcolor============", color)
    switch (color) {
      case 'red':
        pinColor = 'FE7569';
        break;
      case 'green':
        pinColor = '00ca3f';
        break;
      case 'blue':
        pinColor = '2E62CF';
        break;
      default:
        pinColor = 'FE7569';
    }

    const pinImage = new google.maps.MarkerImage('http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|' + pinColor, // eslint-disable-line
      new google.maps.Size(21, 34), // eslint-disable-line
      new google.maps.Point(0, 0), // eslint-disable-line
      new google.maps.Point(10, 34)); // eslint-disable-line

    return pinImage;
  }

  renderMarker() {
    const { map, google, draggable, mapCenter, setShapeInfo, data, onClick, label, zIndex, color } = this.props;
    console.log("colorcolorcolorcolorcolorcolor", color)
    let { position } = this.props;
    const pos = position || mapCenter;
    const icon = this.getMarkerColor(color);
    position = new google.maps.LatLng(pos.lat, pos.lng);
    const pref = {
      map,
      position,
      draggable,
      label,
      zIndex,
      icon,
    };
    const geocoder = new google.maps.Geocoder();

    if (this.marker) this.marker.setMap(null);
    this.marker = new google.maps.Marker(pref);

    google.maps.event.addListener(this.marker, 'dragend', (event) => {
      const marker = {
        type: 'Point',
        coordinates: [event.latLng.lng(), event.latLng.lat()],
        address: '',
      };
      const latLng = {
        lat: parseFloat(marker.coordinates[1]),
        lng: parseFloat(marker.coordinates[0]),
      };
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            marker.address = results[0].formatted_address.toString();
          }
        }
        setShapeInfo(marker);
      });
    });

    this.marker.addListener('click', (event) => {
      if (onClick) onClick(data, this.marker, event);
    });
  }

  render() {
    return null;
  }
}

MarkerComponent.propTypes = {
  map: PropTypes.object,
  draggable: PropTypes.bool,
  position: PropTypes.object,
  label: PropTypes.string,
  setShapeInfo: PropTypes.func,
  onClick: PropTypes.func,
  zIndex: PropTypes.number,
};

export default MarkerComponent;
