import React, { useState } from "react";
import ServiceActivity from "../../../images/svg/serviceActivity.svg";
import {
  FPAvatar,
  FPBadge,
  FPMenuBox,
  FPToolTip,
} from "../../../components/common/new";
import { formatCustomDate, snakeToCapital } from "../../../libs/utils";
import FPChip from "../../../components/common/new/FPChip/FPChip";
import { UserIcon } from "../../../images/svg";
import SrReopen from "../../../images/svg/SrReopen.svg";
import SrClose from "../../../images/svg/SrClose.svg";
import { Divider, makeStyles } from "@material-ui/core";
import AddReviewDetailsModal from "../../../components/business/AddReviewDetailsModal";
import TicketUserDetails from "../../../modules/ticket/TicketUserDetails";
import cookie from "react-cookies";
import whiteTag from "../../../images/svg/whiteTag.svg";
import { CSSTransitionGroup } from "react-transition-group";
import emailPdfIcon from "../../../images/svg/emailPdfIcon.svg";
import SrCommentComponents from "../../serviceRequest/SrCommentComponents";
import ViewActivityLog from "../../serviceRequest/SrActivityLog/ViewActivityLog";

function ViewEmailTicketActivity(props) {
  const { item } = props;

  const [openDetailsModel, setOpenDetailsModel] = useState(false);
  const [selectedOption, setSelectedOption] = useState("comment");
  const [openComment, setOpenComment] = useState(false);
  const [fileAttachments, setFileAttachments] = useState([]);

  const getStatusColor = (status) => {
    const statusColor = {
      new: "#2375ef",
      assigned: "#f8ae00",
      resolved: "#17b26a",
      reopened: "#e16b16",
      waiting_for_customer: "#FFDC54",
    };
    return statusColor[status];
  };

  const assignedOptions = [
    {
      label: "Mark as Assigned",
      value: "assigned",
      //   onClick: () => this.changeStatus("assigned"),
    },
  ];
  const waitingForCustomerOptions = [
    {
      label: "Mark as Waiting for Customer",
      value: "waiting_for_customer",
      //   onClick: () => this.changeStatus("waiting_for_customer"),
    },
  ];

  const service_category = cookie.load("service_category");

  const onAddOrderId = (item, isItemFilled) => {
    setOpenDetailsModel(true); // ✅ Open modal
  };

  const serviceDelivery = [
    { label: "Delivery", value: "Delivery" },
    { label: "Dining", value: "Dining" },
    { label: "Takeaway", value: "Takeaway" },
  ];

  const statusData = [
    { label: "Responded", value: "Responded" },
    { label: "Not responded", value: "Not responded" },
    { label: "Deleted", value: "Deleted" },
  ];

  const useStyles = makeStyles((theme) => ({
    menuBox: {
      color: "#1B1C1D",
      fontSize: "13px",
      gap: 0,
      backgroundColor: "#F6F6F8",
      border: "unset",
      height: "30px",
    },
    button: {
      padding: "0 12px",
      fontSize: "13px",
      border: "unset",
      height: "30px",

      "& .MuiButton-label": {
        fontSize: "13px",
        fontWeight: "400",
      },
    },
  }));

  const classes = useStyles({ isTicket: item?.ticket });

  const tags =
    item?.tag?.length !== 0
      ? item?.tag
      : item?.review_tags[0] !== null
      ? item?.review_tags
      : null;

  const handleAddComment = (id, fileDetails) => {
    console.log(fileDetails);
  };
  const handleCommentChange = (e) => {
    // setComment(e.target.value);
  };

  return (
    <>
      <div
        style={{
          backgroundColor: "#FFFFFF",
          borderRadius: "16px",
          padding: "15px",
          border: "1px solid #BFC2CB",
        }}
      >
        <div className="d-flex justify-content-between align-items-center mb-2">
          <div className="d-flex align-items-center gap-6">
            <img src={ServiceActivity} />
            <span
              style={{
                fontSize: "17px",
                color: "#646565",
                fontWeight: "600",
              }}
            >{`#${item.id.toString().padStart(2, "0")}`}</span>
            <div className="d-flex align-items-center gap-15">
              {item?.ticket?.status === "assigned" ||
              item?.ticket?.status === "waiting_for_customer" ? (
                <>
                  {item?.ticket?.status === "assigned" && (
                    <FPMenuBox
                      title={"Assigned"}
                      startIcon={<FPBadge color="#f8ae00" size="11" />}
                      options={waitingForCustomerOptions}
                      className="assign-box"
                    />
                  )}
                  {item?.ticket?.status === "waiting_for_customer" && (
                    <FPMenuBox
                      title={"Waiting for customer"}
                      startIcon={<FPBadge color="#FFDC54" size="11" />}
                      options={assignedOptions}
                      className="assign-box"
                    />
                  )}
                </>
              ) : (
                <FPChip
                  size="small"
                  variant="outlined"
                  className="assign-box-fp-chip"
                  label={
                    <div className="d-flex align-items-center justify-content-center">
                      <FPBadge color={getStatusColor(item?.ticket?.status)} size="11" />
                      &nbsp;&nbsp;
                      <span style={{ fontSize: "12px" }}>
                        {snakeToCapital(item?.ticket?.status)}
                      </span>
                    </div>
                  }
                />
              )}
            </div>
            {item?.ticket?.assignee_name && (
              <div
                style={{
                  borderRadius: "30px",
                  height: "28px",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #BFC2CB",
                }}
                className="d-flex flex-column gap-10 px-2"
              >
                <div className="d-flex gap-2 align-items-center">
                  <UserIcon />
                  <p
                    className="ml-1"
                    style={{
                      color: "#1B1C1D",
                      fontWeight: "400",
                      fontSize: "13px",
                    }}
                  >
                    {item?.ticket?.assignee_name || "Unassigned"}
                  </p>
                </div>
              </div>
            )}
          </div>
          <FPToolTip
            title={item && item.ticket?.status === "resolved" ? "Reopen" : "Close"}
          >
            <img
              src={item && item.ticket?.status === "resolved" ? SrReopen : SrClose}
              alt="SR resolved icons"
              // onClick={() =>
              //   handleCloseTicket(
              //     item && item.status === "resolved"
              //       ? "reopened"
              //       : "resolved"
              //   )
              // }
              // style={{
              //   cursor: formValueDisabled
              //     ? "not-allowed"
              //     : "pointer",
              //   opacity: formValueDisabled ? 0.5 : 1,
              // }}
            />
          </FPToolTip>
        </div>
        <Divider className="my-3" />
        <div className="">
          <div className="email-title px-0">{item?.subject}</div>
          <div className="email-meta px-0">
            <div className="sender-details">
              <FPAvatar
                src={item?.ticket.reviewer}
                labelClass={"user-name"}
                subLabel={
                  <TicketUserDetails
                    item={item}
                    onAddOrderId={onAddOrderId}
                    service_category={service_category}
                  />
                }
                userName={item?.sender_id}
              />
            </div>
            <div className="activity-header">
              <div className="timestamp">
                {formatCustomDate(item?.msg_create)}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "-23px", marginBottom: "10px" }}>
            <div
              className="d-flex align-items-center gap-10 justify-content-end"
            >
              <FPMenuBox
                title={"Not responded"}
                options={statusData}
                className={classes.menuBox}
                rounded={true}
                size={"small"}
              />
              <FPMenuBox
                title={
                  ["offline", "customercare", "tripadvisor"].includes(
                    item?.source_data?.provider_name
                  )
                    ? "Dining"
                    : "Delivery"
                }
                options={serviceDelivery}
                className={classes.menuBox}
                rounded={true}
                size={"small"}
              />
            </div>
          </div>
          <div className="email-content" style={{ padding: "15px 0px" }}>
            <p>{item?.body}</p>
            {item?.ticket?.tag?.length ? (
                      <div className="tags-section">
                        <div className="mt-15 d-flex justify-content-between align-center gap-10">
                          <div className="d-flex gap-10">
                            <>
                              <FPToolTip title="Tags">
                                <div className="email-tagIcon">
                                  <img
                                    src={whiteTag}
                                    alt="whiteTags"
                                    style={{
                                      height: "15px",
                                      width: "15px",
                                      marginTop: "2px",
                                    }}
                                  />
                                  <span style={{ color: "#fff" }}>Tags</span>
                                </div>
                              </FPToolTip>
                              <CSSTransitionGroup
                                transitionName="reviews-tags"
                                transitionEnterTimeout={500}
                                transitionLeaveTimeout={500}
                                className="d-flex flex-wrap gap-10 align-items-center"
                              >
                                {item?.ticket?.tag?.map((tag, index) => {
                                  return (
                                    <FPChip
                                      key={`${index}-ticket-tags`}
                                      size="small"
                                      variant="outlined"
                                      style={{
                                        borderColor: "#BFC2CB",
                                        borderWidth: "1.5px",
                                        height: "26px",
                                      }}
                                      label={
                                        <span style={{ fontSize: "12.5px" }}>
                                          {snakeToCapital(tag)}
                                        </span>
                                      }
                                      onDelete={
                                        () => console.log("call")
                                        // this.toggleConfirm(() =>
                                        //   postTag({
                                        //     object_id: item.id,
                                        //     tag_name: tag,
                                        //     tagMethod: "remove_tags/",
                                        //   })
                                        // )
                                      }
                                    />
                                  );
                                })}
                              </CSSTransitionGroup>
                            </>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
          </div>
          {item?.attachments > 0 && (
              <>
              
            <Divider className="mt-4" />
            <div className="attachments-section">
              <div className="attachments-header">
                <span>{item?.attachments} Attachments</span>
                <button className="download-all">Download All</button>
              </div>
              <div className="attachments-list">
                <div className="attachment-item">
                  <img src={emailPdfIcon} alt="PDF" />
                  <div className="file-info">
                    <span className="file-name">
                      Report - fresh2Cart project.pdf
                    </span>
                    <span className="file-size">2.4 MB</span>
                  </div>
                </div>
              </div>
            </div>
              </>
            )}
        </div>
      </div>
      <div className="mt-4">
        {item && (
          <div>
            <div className="radio-group">
              <input
                type="radio"
                id="comment"
                name="viewOption"
                value="comment"
                checked={selectedOption === "comment"}
                onChange={() => setSelectedOption("comment")}
              />
              <label htmlFor="comment">Comment</label>

              <input
                type="radio"
                id="activityLog"
                name="viewOption"
                value="activityLog"
                checked={selectedOption === "activityLog"}
                onChange={() => setSelectedOption("activityLog")}
              />
              <label htmlFor="activityLog">View Activity Log</label>
            </div>

            {selectedOption === "comment" && (
              <SrCommentComponents
                item={item}
                handleAddComment={handleAddComment}
                handleCommentChange={handleCommentChange}
                //   comment={comment}
                setOpenComment={setOpenComment}
                setFileAttachments={setFileAttachments}
                //   setSelectedFile={setSelectedFile}
                attachments={fileAttachments}
              />
            )}

            {selectedOption === "activityLog" && (
              <div
                style={{
                  border: "1px solid #BFC2CB",
                  borderRadius: "16px",
                  marginTop: "15px",
                  padding: "15px",
                }}
              >
                <ViewActivityLog logs={item?.service_request_logs} />
              </div>
            )}
          </div>
        )}
      </div>
      <AddReviewDetailsModal
        isOpen={openDetailsModel}
        toggle={() => setOpenDetailsModel(false)}
        heading={"Add Details"}
      />
    </>
  );
}

export default ViewEmailTicketActivity;
