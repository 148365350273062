import React from 'react';
// import { connect } from 'react-redux';
import { Card, Row, Col } from 'reactstrap';
import { Button, Form,  } from 'semantic-ui-react';
import { InputField } from 'react-semantic-redux-form';
import { Field, reduxForm, SubmissionError } from 'redux-form';

const validate = (values) => {// eslint-disable-line
  const errors = {};
  if(!values.name) {
    errors.name = 'Enter name';
  }
  if(!values.waba_id) {
    errors.waba_id = 'Enter Whatsapp Business Account ID';
  }
  if(!values.waba_phone_id) {
    errors.waba_phone_id = 'Enter Whatsapp Phone Number ID';
  }
  if(!values.parmanent_token) {
    errors.parmanent_token = 'Enter Parmanent Token';
  }
  return errors;
};

class SetupWhatsappForm  extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const { saveWhatsappDetail } = this.props;
    return saveWhatsappDetail(data)
      .catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  }

  render() {
    const { handleSubmit, errors } = this.props;

    return (
      <Form className="grid-form mt-2" onSubmit={handleSubmit(this.submit)}>
        { errors && errors.non_field_errors
          ? errors.non_field_errors.map (
            (error, index) => (<p key={index} className="text-danger">{error}</p>)
          )
          : null
        }
        <Row>
          <Col sm="12" className="mt-1">
            <Card body>
              <Field
                name="name"
                type="text"
                component={InputField}
                label="Name"
                placeholder="Enter Name"
              />
              <Field
                name="waba_id"
                type="text"
                component={InputField}
                label="Whatsapp Business Account ID"
                placeholder="Enter Whatsapp Business Account ID"
              />
              <Field
                name="waba_phone_id"
                type="text"
                component={InputField}
                label="Whatsapp Phone Number ID"
                placeholder="Enter Whatsapp Phone Number ID"
              />
              <Field
                name="parmanent_token"
                type="text"
                component={InputField}
                label="Parmanent Token"
                placeholder="Enter Parmanent Token"
              />
              <div className="mt-3 text-right">
                <Form.Field control={Button} color='teal' type='submit'>
                    Save
                </Form.Field>
              </div>
            </Card>
          </Col>
        </Row>
      </Form>
    );
  }
}

SetupWhatsappForm =  reduxForm({// eslint-disable-line
  form: 'SetupWhatsappForm',
  validate,
  enableReinitialize:true,
})(SetupWhatsappForm);

// EnterpriseUserForm = connect((state) => {// eslint-disable-line
//   return {};
// })(EnterpriseUserForm);

export default SetupWhatsappForm;
