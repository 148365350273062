import * as types from '../../actions/actionTypes';

export default function contact(state = [], action) {
  switch (action.type) {
    case types.CONTACT_ADD_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.CONTACT_ADD_FAILURE:
      return Object.assign({}, state, action.data);
    case types.CONTACT_EDIT_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.CONTACT_EDIT_FAILURE:
      return Object.assign({}, state, action.data);
    case types.CONTACT_DELETE_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.CONTACT_DELETE_FAILURE:
      return Object.assign({}, state, action.data);
    case types.CONTACT_LIST_SUCCESS:
      return Object.assign({}, state, { list: action.data });
    case types.CONTACT_LIST_FAILURE:
      return Object.assign({}, state, action.data);
    case types.CONTACT_IDS_SUCCESS:
      return Object.assign({}, state, { ids: action.data });
    case types.CONTACT_IDS_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GET_CONTACT_SUCCESS:
      return Object.assign(state, { detail: action.data });
    case types.GET_CONTACT_FAILURE:
      return Object.assign({}, state, action.data);
    case types.CONTACT_CSV_UPLOAD_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.CONTACT_CSV_UPLOAD_FAILURE:
      return Object.assign({}, state, action.data);
    case types.COUNTRY_CONTACT_SUCCESS:
      return Object.assign({}, state, { country: action.data });
    case types.COUNTRY_CONTACT_FAILURE:
      return Object.assign({}, state, action.data);
    case types.REGION_CONTACT_SUCCESS:
      return Object.assign({}, state, { region: action.data });
    case types.REGION_CONTACT_FAILURE:
      return Object.assign({}, state, action.data);
    case types.GET_CONTACT_TAG_GROUPS_SUCCESS:
      return Object.assign({}, state, { tagGroupsList: action.data });
    case types.GET_CONTACT_TAG_GROUPS_FAILURE:
      return Object.assign({}, state, action.data);
    case types.EDIT_TAG_GROUP_NAME_SUCCESS:
      return Object.assign({}, state, action.data);
    case types.EDIT_TAG_GROUP_NAME_FAILURE:
      return Object.assign({}, state, {error: action.data});
    case types.REMOVE_EDIT_TAGNAME_ERROR:
      return Object.assign({}, state, { error: action.data });
    default:
      return state;
  }
}
