import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";
import { Row, Col } from "reactstrap";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import cookie from "react-cookies";
import LoaderComponent from "../../components/common/loaderComponent";
import InstagramLogo from "../../images/instagram_logo.png";
import * as SocialProfileActions from "../../actions/socialProfile/socialProfileActions";
import * as utils from "../../libs/utils";

const APP_ID = process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_KEY;

class SetupInstaAccount extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.business_id !== prevState.business_id) {
      return { business_id: nextProps.business_id };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      business_id: this.props.business_id,
      btnText:
        this.props.insta_status && this.props.insta_status.connected
          ? "Log Out"
          : "Connect to Instagram",
      name: this.props.insta_status ? this.props.insta_status.name : "",
      connected: this.props.insta_status
        ? this.props.insta_status.connected
        : false,
      loader: false,
      initial_check: false,
      instagramXData:localStorage.getItem('instagram_x_data'),
    };
  }

  componentDidMount() {
    window.fbAsyncInit = function () {
      window.FB.init({ appId: APP_ID, cookie: true, xfbml: true, version: "v14.0", });
    }.bind(this); //eslint-disable-line

    // Load the SDK asynchronously
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "//connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.insta_status !== this.props.insta_status) {
      this.setState({
        btnText:
          this.props.insta_status && this.props.insta_status.connected
            ? "Log Out"
            : "Login to Instagram",
        name: this.props.insta_status ? this.props.insta_status.name : "",
        connected: this.props.insta_status
          ? this.props.insta_status.connected
          : false,
        loader: false,
        initial_check: false,
      });
    }
    if (JSON.stringify(prevState.instagramXData) !== JSON.stringify(this.state.instagramXData)) {
      console.log('changing...');
      this.connectSocial();
    }
  }

  fbLogin() {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          this.createSocialConnect(response.authResponse);
        }
      },
      {
        scope:
          "email, instagram_basic, instagram_manage_messages, pages_manage_metadata",
        return_scopes: true,
      }
    );
  }

  createSocialConnect(data) {
    this.setState({ loader: true });
    const req_data = {
      access_token: data.accessToken,
      user_id: data.userID,
      business_id: this.state.business_id,
    };
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    return axios_instance
      .post(`${URLS.MESSENGER}ig-setup/`, req_data, { headers: headers })
      .then((res) => {
        if (res.data.connected === true) {
          this.setState({
            loader: false,
            connected: true,
            initial_check: true,
            name: res.data.name,
            btnText: "Log Out",
            business_id: this.state.business_id,
          });
        } else {
          this.setState({ initial_check: true, loader: false });
        }
      })
      .catch((err) => {
        this.setState({ loader: false });
        throw err.response.data;
      });
  }

  removeSocialConnect(business_id) {
    const authToken = cookie.load("authToken");
    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    return axios_instance.get(`${URLS.MESSENGER}ig-logout/?business_id=${business_id}`, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.connected === false) {
          this.setState({ connected: false, initial_check: true, name: "", btnText: "Login to Instagram", business_id: business_id, });
          this.props.callSocialProfileApi();
          this.props.handleConnectProfile();
        }
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  postCode = (code, provider) => {
    this.setState({ loader: true });
    console.log(code, 'code and provider in postcode function vvvvvv',provider);
    if (provider == "twitter") {
      return this.props.SocialProfileActions
        .postAllMentionsSocialCode(code, this.props.business_id, provider)
        .then((res) => {
          if (res.data.connected === true) {
            this.setState({
              loader: false,
              connected: true,
              initial_check: true,
              name: res.data.name,
              btnText: "Log Out",
              business_id: this.state.business_id,
            });
            utils.displayMessage(
              "positive",
              `${provider} Connected Successfully`
            );
          } else {
            this.setState({ initial_check: true, loader: false });
            utils.displayMessage("negative", "Error");
          }
        })
        .catch(() => {
          this.setState({ loader: false });
          utils.displayMessage("negative", "Error");
        });
    } else {
      return this.props.SocialProfileActions
        .postAllMentionsSocialCode({ code }, this.props.business_id, provider)
        .then((res) => {
          console.log(res, 'insta and fb response after process vvvvvv');
          console.log(res.data, 'insta and fb response after process new data res.data vvvvvv');
          if (res.data.connected === true) {
            this.setState({
              loader: false,
              connected: true,
              initial_check: true,
              name: res.data.name,
              btnText: "Log Out",
              business_id: this.state.business_id,
            });
            utils.displayMessage(
              "positive",
              `${provider} Connected Successfully`
            );
          } else {
            this.setState({ initial_check: true, loader: false });
            utils.displayMessage("negative", "Error");
          }
        })
        .catch(() => {
          this.setState({ loader: false });
          utils.displayMessage("negative", "Error");
        });
    }
  };

  connectSocial = () => {
    let  { instagramXData } = this.state;
    this.postCode(instagramXData.code, 'instagram');
  };

  getAuthUrl = (provider) => {
    return this.props.SocialProfileActions.getSocialAuthUrl(this.props.business_id, provider).then((res) => {
      this.newWindow = window.open(
        res.auth_url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
      );
        
      // window.addEventListener("message", this.connectSocialRef);
      localStorage.removeItem('instagram_x_data');
      
      const interval = setInterval(() => {
        let messagess = localStorage.getItem('instagram_x_data');
        if (messagess) {
          this.setState({instagramXData:JSON.parse(messagess)});
          // this.connectSocial(JSON.parse(messagess),provider);
          clearInterval(interval);
        }
      }, 10000);

      // Clear the interval after a certain time to avoid infinite logging
      setTimeout(() => clearInterval(interval), 60*1000);
    });
  };

  handleClick() {
    // this.fbLogin();
    this.getAuthUrl('instagram');
  }

  render() {
    const { connectedStatus, toggleTab } = this.props;
    const { loader } = this.state;
    const cssStyle = { display: 'flex', gap: '10px', borderRadius: '25px', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(49.12deg, #FFDD55 6.62%, #FF543E 50.06%, #C837AB 93.5%)', cursor: 'pointer', textAlign: 'center'}
    return (
      <div>
        <div className="my-2">
          <Row>
            <LoaderComponent loader={loader} />
            <Col>
              <div className="text-center mb-2">
                <span className=" google-button" style={cssStyle} onClick={() => this.handleClick()} >
                  <div>
                    <img src={InstagramLogo} alt="Instagram Logo" height="30"  />
                  </div>
                  <span>Connect to Instagram</span>
                </span>
                <div className='mt-2' onClick={toggleTab} style={{ textAlign: 'center', fontWeight: '600', textDecoration: 'underline', fontSize: '14px', cursor: 'pointer' }}>
                  {connectedStatus ? connectedStatus : this.state.connected ? 1 : 0} profile already connected
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

// export default SetupInstaAccount;
const mapStateToProps = (state) => {
  return {
    // business: state.business,
    // businessInfo: state.business.detail,
    socialProfile: state.socialProfile.details,
  };
};

SetupInstaAccount.propTypes = {
  SocialProfileActions: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    SocialProfileActions: bindActionCreators(SocialProfileActions,dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SetupInstaAccount);