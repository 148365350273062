import React from 'react';
import { Radio, FormControlLabel, Typography, Box } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

const CustomRadio = ({ label, checked, onChange, value, name }) => {
  return (
    <Box 
      sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: "space-between", padding: "0px 10px" }}
    >
      {/* Label before the radio button */}
      <Typography style={{ fontWeight: 400 }}>{label}</Typography>
      <FormControlLabel
        value={value}
        control={
          <Radio
            checked={checked}
            onChange={onChange}
            name={name}
            icon={<RadioButtonUncheckedIcon style={{ color: '#e0e0e0' }} />}
            checkedIcon={<RadioButtonCheckedIcon style={{ color: '#00BFA6' }} />}
          />
        }
        sx={{
          margin: 0,
        }}
      />
    </Box>
  );
};

export default CustomRadio;
