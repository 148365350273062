import React, { useState } from "react";
import { Card, Col, Form } from "reactstrap";
import { FPButton } from "../common/new";
import { Divider } from "antd";
import SendIcon from "@material-ui/icons/Send";
import FPTextArea from "../common/new/forms/ui/FPTextArea";

const QAreplyComponent = ({ toggleReply, question }) => {
  const [answer, setAnswer] = useState("");

  const handleText = (e) => {
    setAnswer(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (question && question.id) {
      toggleReply(answer, question.id, question.branch_id);
    }
    setAnswer("");
  };

  return (
    <div className="mt-2">
      <Divider className="my-3" />
      <FPTextArea
        autoHeight
        placeholder="Enter a Comment"
        className="massager-chatbot"
        value={answer}
        onChange={(e) => handleText(e)}
        // onKeyUp={sendMessage}
        minRows={5}
      />
      <div className="d-flex justify-content-end mt-2">
        <FPButton
          onClick={(e) => handleSubmit(e)}
          endIcon={<SendIcon />}
          size="small"
        >
          Send
        </FPButton>
      </div>
    </div>
  );
};

export default QAreplyComponent;
