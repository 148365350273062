import React, { Component } from "react";
import LoaderComponent from "../common/loaderComponent";
import "../../../src/styles/styles.css";
import PropTypes from "prop-types";
import ConfirmationModal from "../common/ConfirmationModal";
import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as utils from "../../libs/utils";
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Checkbox } from "semantic-ui-react";
import CustomStarRatingComponent from "../common/StarComponent";
import { FaPlus } from "react-icons/fa6";
import { FPButton, FPToolTip } from "../common/new";
import deleteIcon from "../../images/svg/trash.svg";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import "../tickets/central-branch.css";
import PaginationComponent from "../common/Pagination";
import editIcon from "../../images/svg/EditPen.svg";
import TicketAssignmentRule from "./TicketAssignmentRule";
import FormModal from "../common/FormModal";

class TicketAutomation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isConfirmOpen: false,
      ruleName: "",
      rule: [],
      selectedRule: null,
      selectedRules: [],
      allChecked: false,
    };
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  deleteRule(id) {
    const { history, businessId } = this.props;
    this.props.actions
      .ruleDelete({ businessId, id })
      .then(() => {
        utils.displayMessage("positive", "Rule Deleted Successfully");
        history.push({
          pathname: "central-setup",
          search: "?page_size=10",
          state: { tab: "7" },
        });
        const data = {
          id: businessId,
        };
        this.props.actions.getCreatedRulesAction(data, { page_size: "10" });
      })
      .catch((err) => {
        utils.displayMessage("negative", "Error Deleting Response");
        console.log(err);
      });
  }

  toggleConfirm = (confirmationFunction) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmationFunction,
    });
  };

  toggleOpen(item) {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
    this.setState({ selectedRule: item });
  }

  handleClick = (item) => {
    this.setState({ ruleName: item.rule_name });
    this.toggleConfirm(() => this.deleteRule(item.id));
  };

  handleCheckboxChange = (index) => {
    const { selectedRules } = this.state;
    const selectedIndex = selectedRules.indexOf(index);
    if (selectedIndex > -1) {
      selectedRules.splice(selectedIndex, 1);
    } else {
      selectedRules.push(index);
    }
    this.setState({ selectedRules });
  };

  handleSelectAll = () => {
    const { rulesList } = this.props;
    const { allChecked } = this.state;
    if (allChecked) {
      this.setState({ selectedRules: [], allChecked: false });
    } else {
      const allIndexes = rulesList.map((_, index) => index);
      this.setState({ selectedRules: allIndexes, allChecked: true });
    }
  };

  render() {
    let pageNumber = "";
    for (let i = 6; i <= 10; i++) {
      if (this.props.location.search[i] === "&") break;
      pageNumber += this.props.location.search[i];
    }
    pageNumber = parseInt(pageNumber);
    const { rulesList, enterpriseList, branchList } = this.props;
    const { selectedRules, allChecked, isOpen, selectedRule } = this.state;

    let allLocations = [];
    branchList &&
      branchList.map((item) => {
        allLocations.push({
          label: item.display_name,
          value: item.value,
        });
      });
    let allMembersList = [];
    enterpriseList.map((item) =>
      item.branch_owner.map((owners) =>
        allMembersList.push({
          display_name: owners.name,
          value: owners.id,
        })
      )
    );
    const jsonObject = allMembersList.map(JSON.stringify);
    const uniqueNames = new Set(jsonObject);
    allMembersList = Array.from(uniqueNames).map(JSON.parse);

    let locationMap = {};
    let membersMap = {};
    allLocations.map((item) => (locationMap[item.value] = item.label));
    allMembersList.map((item) => (membersMap[item.value] = item.display_name));

    const ratingOptions = {
      one_rating: "1",
      two_rating: "2",
      three_rating: "3",
      four_rating: "4",
      five_rating: "5",
    };
    const { businessId, count, pageSizeCookie } = this.props;

    return (
      <div>
        <LoaderComponent loader={this.props.loader} />
        {rulesList && rulesList.length ? (
          <CardWrapper showDivider={false} contentClass="p-3">
            <div className="ui-table-responsive">
              <Table className="fp-table fp-table-center">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Checkbox id="all" checked={allChecked} onChange={this.handleSelectAll}>
                        Select All
                      </Checkbox>
                    </TableCell>
                    <TableCell>S.No</TableCell>
                    <TableCell>Rule Name</TableCell>
                    <TableCell style={{ minWidth: "230px" }}>Rating</TableCell>
                    <TableCell>Locations</TableCell>
                    <TableCell>Service Type</TableCell>
                    <TableCell>Assigned To</TableCell>
                    <TableCell>Followed By</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rulesList &&
                    rulesList.length &&
                    rulesList.map((item, index) => {
                      const isChecked = selectedRules.indexOf(item.id) > -1;
                      return (
                        <TableRow key={index}>
                          <TableCell>
                            <Checkbox
                              id={item.id}
                              checked={isChecked}
                              onChange={() => this.handleCheckboxChange(item.id)}
                            />
                          </TableCell>
                          <TableCell>{!isNaN(pageNumber) ? pageNumber * 10 - 9 + index : index + 1}</TableCell>
                          <TableCell style={{ width: "180px" }} className="cursor-pointer tableHeaderHover">
                            {item.rule_name ? (
                              <div className="d-flex" title={item.rule_name} onClick={() => this.toggleOpen(item)}>
                                <span
                                  className="threeDot"
                                  style={{
                                    width: "140px",
                                    color: "#1B1C1D",
                                    textDecoration: "underline",
                                    textTransform: "capitalize",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.rule_name}
                                </span>
                              </div>
                            ) : (
                              <label className="width-md">{item.rule_names}</label>
                            )}
                          </TableCell>
                          <TableCell
                            style={{
                              display: "grid",
                              gridTemplateColumns: "auto auto auto",
                              gap: "5px",
                              justifyContent: "center",
                              height: "100px",
                              alignItems: "center",
                              minWidth: "230px",
                            }}
                          >
                            {item.rating_options
                              .slice()
                              .sort((a, b) => {
                                const order = [
                                  "one_rating",
                                  "two_rating",
                                  "three_rating",
                                  "four_rating",
                                  "five_rating",
                                ];
                                return order.indexOf(a) - order.indexOf(b);
                              })
                              .map((rating, i) => {
                                const ratingValue = ratingOptions[rating];
                                const { star_border_color } = utils.starRatingColor(ratingValue);

                                return (
                                  <div key={rating} className="rating" style={{ borderColor: star_border_color }}>
                                    <CustomStarRatingComponent
                                      name={`rate-${i}`}
                                      starCount={1}
                                      className="align-bottom"
                                      starColor="#FBBD08"
                                      emptyStarColor="#FFFFFF"
                                      value={ratingValue}
                                      editing={false}
                                    />
                                    <span style={{ marginLeft: "10px" }}>{ratingValue}</span>
                                  </div>
                                );
                              })}
                          </TableCell>

                          <TableCell className="width-md-th">
                            <FPToolTip>
                              <span className="d-inline-block text-truncate width-sm">
                                {item.locations
                                  .slice(0, 2)
                                  .map((i, index) => locationMap[i])
                                  .join(", ")}
                              </span>
                            </FPToolTip>
                            {item.locations.length > 2 && (
                              <span
                                style={{
                                  marginLeft: "8px",
                                  color: "#646565",
                                }}
                              >
                                {`+${item.locations.length - 2}`}
                              </span>
                            )}
                          </TableCell>
                          <TableCell className="width-md-th">
                            <span className="threeDot">
                              {item.service_type && item.service_type.length > 0
                                ? item.service_type.map((serviceType) => serviceType).join(", ")
                                : null}
                            </span>
                            {/* {item.service_type.length > 2 && (
                              <span
                                style={{
                                  marginLeft: "8px",
                                  color: "#646565",
                                }}
                              >
                                {`+${item.service_type.length - 2}`}
                              </span>
                            )} */}
                          </TableCell>
                          <TableCell>{membersMap[item.assigned_to]}</TableCell>
                          <TableCell
                            className=""
                            style={{
                              height: "100px",
                              justifyContent: "center",
                            }}
                          >
                            <div className="d-flex align-center justify-content-center">
                              <div className="threeDot" style={{ display: "inline-block" }}>
                                {item.followed_by
                                  .slice(0, 2)
                                  .map((i) => membersMap[i])
                                  .join(", ")}
                              </div>
                              {item.followed_by.length > 2 && (
                                <div
                                  style={{
                                    marginLeft: "8px",
                                    color: "#646565",
                                  }}
                                >
                                  {`+${item.followed_by.length - 2}`}
                                </div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="actionButton">
                              {item.rule_name ? (
                                <Link title={item.rule_name} to={`/${businessId}/new-rule?id=${item.id}`}>
                                  <FPToolTip title={"Edit"}>
                                    <img src={editIcon} role="button" alt="edit" />
                                  </FPToolTip>
                                </Link>
                              ) : (
                                <></>
                              )}

                              <FPToolTip title={"Delete"}>
                                <img
                                  src={deleteIcon}
                                  onClick={() => this.handleClick(item)}
                                  role="button"
                                  alt="delete"
                                />
                              </FPToolTip>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <PaginationComponent count={count} location={this.props.location} pageSizeCookie={pageSizeCookie} />

            <ConfirmationModal
              header={`Are you sure you want to delete "${this.state.ruleName}" ?`}
              subHeader="This cannot be undone."
              isOpen={this.state.isConfirmOpen}
              toggle={this.toggleConfirm}
              onConfirm={this.state.confirmationFunction}
            />
          </CardWrapper>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <div className="py-5">
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "rgba(100, 101, 101, 1)",
                }}
              >
                No Assignment Rules Added
              </span>
              <FPButton
                className="d-flex mt-10"
                fullWidth
                label="Add Assignment Rule"
                fontWeight="400"
                fontSize={16}
                onClick={this.props.addRules}
                endIcon={<FaPlus style={{ fontSize: "14px" }} />}
              />
            </div>
          </div>
        )}
        {isOpen && (
          <FormModal
            isOpen={isOpen}
            toggle={this.toggleOpen}
            heading={"Ticket Assignment Rule"}
            width="900px"
            contentClass="mb-0"
            bodyClass="mb-0"
          >
            <TicketAssignmentRule
              assignMentRuleData={selectedRule}
              enterpriseList={enterpriseList}
              branchList={branchList}
            />
          </FormModal>
        )}
      </div>
    );
  }
}

TicketAutomation.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(TicketAutomation);
