import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Card, Row, Col } from 'reactstrap';
import { Feed } from 'semantic-ui-react';
import DefaultUserIcon from '../../images/boy.png';
import CustomStarRatingComponent from '../common/StarComponent';
import Linkify from 'react-linkify';
import Highlighter from 'react-highlight-words';
import 'react-responsive-modal/styles.css';
import axios_instance from '../../libs/interseptor';
import * as utils from '../../libs/utils';
import * as URLS from '../../libs/apiUrls';
import LoaderComponent from '../../components/common/loaderComponent';

const NegativeReviewsPage = ({match,location}) => {
  const [reviews, setReviews]= useState([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetching(true);
        const response = await axios_instance.get(utils.format(`${URLS.NEGATIVE_REVIEWS}${location.search}`, [match.params.businessId]));
        setReviews(response.data);
        setIsFetching(false);
      } catch (error) {
        console.log(error);
        setIsFetching(false);
      }
    };
    fetchData();
  }, [match.params.businessId, location.search]);
  return (
    <div style={{padding:'20px'}}>
      <LoaderComponent loader={isFetching} />
      {Array.isArray(reviews) ? reviews.map((item) => {
        return (
          <Card key={item.id} body className="py-2 px-3 mt-2 review-border">
            <Row className="pl-3">
              <Col sm="11" className="pl-0 pt-1">
                <Feed>
                  <Feed.Event>
                    <Feed.Label className="review-time">
                      {item.review_type === 'star' &&
                        <div className="rating" style={ { marginTop: '6px' }}>
                          <CustomStarRatingComponent
                            name="rate1"
                            starCount={5}
                            className="align-bottom"
                            starColor="#FBBD08"
                            emptyStarColor="#FFFFFF"
                            value={+item.rating}
                            editing={false}
                          />
                        </div>}
                    </Feed.Label>
                    <Feed.Content>
                      { <Feed.Date><span className="text-dark" style={{ fontSize: '13px', fontWeight: 'bold', letterSpacing: '0' }}>{ item.branch}</span><span className="d-block">{moment(item.review_create ? item.review_create : item.updated_at).format('llll')}</span></Feed.Date>}
                    </Feed.Content>
                  </Feed.Event>
                </Feed>
              </Col>
              <Col sm="1" className="pt-2" style={{paddingLeft:'25px'}}>
                <img src={item.provider} alt="" className="float-right" height="25" width="25" />
              </Col>
            </Row>
            <Row className="mt-3">
              <div className="ui feed ml-3">
                <div className="event">
                  <div className="label">
                    <img src={item.reviewer.image ? item.reviewer.image : DefaultUserIcon} alt={item.reviewer.name} />
                  </div>
                  <div className="mt-2 ml-2">
                    <strong>{(item.provider_name === 'swiggy' || item.provider_name === 'zomato_ors') ? item.order_id : (item.reviewer.unique_code ? item.reviewer.unique_code : (item.reviewer.name && item.reviewer.name.toString()))}</strong>
                  </div>
                  {(item.provider_name === 'famepilot' || item.provider_name === 'reservego') && <div className="mt-2 ml-2">
                    {Object.keys(item.reviewer).length && Object.keys(item.reviewer).map((data, index) => {
                      return (
                        <span key={index}>
                          {data !== 'unique_code' && <span style={{ color: 'gray', fontStyle: 'normal', fontSize: '14px' }}>
                            {item.reviewer[data] && <span>&nbsp;|&nbsp;{item.reviewer[data]}</span>}
                          </span>}
                        </span>
                      );
                    })}
                  </div>}
                  {['offline', 'customercare'].includes(item.provider_name) &&
                    <div className="mt-2">{(item.reviewer.phone && item.reviewer.phone !== 'None') && <span style={{ color: 'gray', fontStyle: 'normal', fontSize: '14px' }}>&nbsp;|&nbsp;{item.reviewer.phone}</span>}
                      {item.reviewer.email && <span style={{ fontWeight: 'normal', color: 'gray', fontStyle: 'normal', fontSize: '14px' }}>&nbsp;|&nbsp;{item.reviewer.email}</span>}</div>}
                </div>
              </div>
            </Row>
            {item.heading && <Row className="ml-1 mt-2" style={{ color: '#12488C', fontSize: 14, fontWeight: 'bold', fontStyle: 'italic' }}>
              <div>{item.heading}</div>
            </Row>}
            <Row className="mt-2 ml-0">
              <div className="content content-break">
                <Linkify properties={{ target: '_blank' }}>
                  <Highlighter
                    highlightStyle={{ color: (+item.rating) > 3 ? 'green' : 'red', fontWeight: 'bold' }}
                    searchWords={[]}
                    textToHighlight={item.description}>
                  </Highlighter>
                </Linkify>
              </div>
            </Row>
            <Row className="mt-2 ml-0 survey-questionss">
              {item.survey_pages.length > 0 && item.survey_pages.map((sp, index)=>
                <Col sm="12" className="pl-0 pt-1" key={sp.question__question}>
                  <span>{index+1}.  </span>
                  <span>{sp.question__question} -  </span>
                  {sp.extra_text != null
                    ?
                    (<span>{sp.extra_text}</span>)
                    : (sp.option__option != null)
                      ?(<span>{sp.option__option}</span>)
                      :(<span>{sp.option__option_text}</span>)
                  }
                </Col>
              )}
            </Row>
            <Row className="mt-3">
              <Col sm={12} xs={12} className="px-3 center-position" >
              </Col>
            </Row>
          </Card>
        );
      }) : null
      }
    </div>
  );
};

export default NegativeReviewsPage;