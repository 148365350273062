import React, { Component } from "react";
import CardWrapper from "../dashboard/component/CardWrapper";
import TicketCardHeader from "./TicketCardHeader";
import { FPAvatar, FPButton, FPToolTip, MessageBox } from "../../components/common/new";
import DefaultUserIcon from "../../images/boy.png";
import cookie from "react-cookies";
import TicketHeaderActivity from "./TicketHeaderActivity";
import moment from "moment";
import { CSSTransitionGroup } from "react-transition-group";
import FPChip from "../../components/common/new/FPChip/FPChip";
import { displayMessage, snakeToCapital } from "../../libs/utils";
import { Divider } from "@material-ui/core";
import queryString from "query-string";
import AddReviewDetailsModal from "../../components/business/AddReviewDetailsModal";
import TicketUserDetails from "./TicketUserDetails";
import TicketingTagsModal from "../../components/tickets/TicketingTagsModal";
import TicketText from "./TicketText";
import FeedbackImage from "../../components/business/FeedbackImage";
import whiteTag from "../../images/svg/whiteTag.svg";
import TicketMailModal from "../../components/tickets/TicketMailModal";
import FormModal from "../../components/common/FormModal";
import ConfirmationModal from "../../components/common/ConfirmationModal";

export class TicketList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addReviewDetailsModal: false,
      isName: false,
      isEmail: false,
      isPhone: false,
      isOrder_id: false,
      detail_review_id: null,
      isReviewTagModalOpen: false,
      relevantTags: [],
      tagSelectedItem: null,
      addReviewDetailsModalHeading: "Add Details",
      isMailModal : false,
      ticket_detail: {},
      notSendMail : false
    };
  }
  componentDidUpdate(prevProps) {
    const { tags } = this.props;
    const { tags: prevTags } = prevProps;

    if ((prevTags && prevTags.length) !== (tags && tags.length)) this.setState({ relevantTags: [...tags] });
    else {
      for (let i = 0; prevTags && i < prevTags.length; i++) {
        if (tags[i].name !== prevTags[i].name) {
          this.setState({ relevantTags: [...tags] });
          break;
        }
      }
    }
  }
  handleTagChange = (e) => {
    const { tags } = this.props;
    const tagValue = e.target.value;
    this.setState({ tagValue, error: null });
    const newRelevantTags = tags.length
      ? tags.filter((tag) => {
          return tag.name.includes(tagValue);
        })
      : [];
    this.setState({ relevantTags: [...newRelevantTags] });
  };
  onAddOrderId = (item, isItemFilled) => {
    this.setState({
      isName: item.customer["name"],
      isEmail: item.customer["email"],
      isPhone: item.customer["phone"] || item.customer["contact_number"],
      addReviewDetailsModal: true,
      addReviewDetailsModalHeading: isItemFilled
        ? "Edit Details"
        : "Add Details",
      isOrder_id: item?.source_data?.extra_data?.order_id ? item?.source_data?.extra_data?.order_id : "",
      detail_review_id: item.id,
    });
  };
  changeStatus = (status, t_id) => {
    const {
      location: { search },
    } = this.props;
    this.props
      .patchTicket({ status }, t_id)
      .then((res) => {
        displayMessage("positive", `Ticket ${res.data.status === "reopened" ? "Reopened" : "Closed"} Successfully`);
        this.props.getData({
          ...queryString.parse(search),
          businessId: this.props.businessId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleToggle = async () => {
    this.setState({ addReviewDetailsModal: false });
    const {
      location: { search },
    } = this.props;
    await this.props.getData({
      ...queryString.parse(search),
      businessId: this.props.businessId,
    });
  };
  addtag = (review_id) => {
    const { tagValue } = this.state;
    if (tagValue.length > 254) {
      this.setState({
        error: "Ensure tag name has no more than 254 characters.",
      });
    } else {
      if (tagValue)
        this.props.postTag({ name: tagValue }).then((res) => {
          if (res.data) {
            this.props.postTag({
              tag_name: res.data.name,
              object_id: review_id,
              tag_id: res.data.id,
              tagMethod: "add_tags/",
            });
            this.setState({ isReviewTagModalOpen: false });
          } else {
            this.setState({ error: "Tag with this name already exists." });
          }
        });
      else this.setState({ error: "Please enter a tag name before submitting!" });
    }
  };
  toggleReviewTagModal = () => {
    this.setState({ isReviewTagModalOpen: !this.state.isReviewTagModalOpen });
  };
  handleReviewTagsChange = (e, item) => {
    this.setState({
      tagSelectedItem: item,
      isReviewTagModalOpen: !this.state.isReviewTagModalOpen,
      error: null,
      relevantTags: [],
    });
  };

  postTagOnReview = (data, tags) => {
    const { postTag } = this.props;
    if (tags && tags.includes(data.tag_name)) return null;
    else postTag(data);
    this.setState({ isReviewTagModalOpen: false });
  };

  handleMailModal = (ticket) => {
    const { isMailModal } = this.state;
    this.setState({ isMailModal: !isMailModal, ticket_detail: ticket });
  }

  handleTicketMailSubmit = (data) => {
    const { ticket_detail } = this.state;
    const { location : {search} } = this.props;
    data['message_id']= ticket_detail?.source_data?.id;

    this.props.sendMail(data, ticket_detail.id)
      .then((res) => {
        displayMessage("positive", res.data.msg);
        this.setState({ isMailModal: false });
        this.props.getData({
          ...queryString.parse(search),
          businessId: this.props.businessId,
        });
      }).catch((error) => {
        console.log(error);
        displayMessage("negative", error?.response?.data?.msg ? error?.response?.data?.msg : error );
        throw new Error("Error");
      })
  }

  getToEmail = (to_email) => {
    if(to_email)  {
      let email = to_email.split(/[<>]/);
      if (email.length === 1) return email[0]
      else if(email.length > 1)  return email[1]
    } 
    return '';
  }

  render() {
    const { data, replyDisabled, ticketActivity, toggleTicketModal, businessId, tags } = this.props;
    const { ticket_detail, addReviewDetailsModal, isEmail, isName, isOrder_id, isPhone, detail_review_id, relevantTags, error, addReviewDetailsModalHeading, isMailModal, notSendMail } =
      this.state;
    const service_category = cookie.load("service_category");
    const searched = this.props.location ? queryString.parse(this.props.location.search) : {};
    const searchText = searched ? searched.search : [];
    const tagsList = relevantTags.length ? relevantTags : tags && tags.length ? tags : null;
    return (
      <div className="d-flex flex-column gap-20">
        {Array.isArray(data)
          ? data.map((item) => {
              const tags = item.tag.length !== 0 ? item.tag : item.review_tags[0] !== null ? item.review_tags : null;
              return (
                <CardWrapper
                  key={`${item.id}-reviewCard`}
                  className="fp-card-outline review-card-wrapper"
                  showDivider={false}
                >
                  <TicketCardHeader data={item} service_category={service_category} />
                  <section className="review-card-body">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <FPAvatar
                        src={(item.source_data.reviewer || {}).image || DefaultUserIcon}
                        labelClass={"user-name"}
                        subLabel={
                          <TicketUserDetails
                            item={item}
                            onAddOrderId={this.onAddOrderId}
                            service_category={service_category}
                          />
                        }
                        userName={
                          item.source_data && item.source_data.provider_name === "inhouse"
                            ? item.source_data.reviewer.name && item.source_data.reviewer.name.toString()
                            : (item.source_data.provider || {}).provider_name === "swiggy"
                            ? item.source_data.order_id
                            : (item.source_data.provider || {}).provider_name === "famepilot"
                            ? item.source_data.unique_code
                            : (item.customer || {}).name
                        }
                      />
                      <div>
                        <TicketHeaderActivity
                          item={item}
                          replyDisabled={replyDisabled}
                          ticketActivity={ticketActivity}
                          toggleTicketModal={toggleTicketModal}
                          changeStatus={() =>
                            this.changeStatus(
                              item.status && item.status === "resolved" ? "reopened" : "resolved",
                              item.id
                            )
                          }
                        />
                        <div className="d-flex gap-6 justify-content-end align-items-center mt-3">
                          {item.source_data.created_at && (
                            <div>
                              <span style={{ color: "black" }}>Created at:</span>
                              <span className="font-blue-gray">
                                &nbsp;
                                {moment(item.created_at).format("llll")}
                              </span>
                            </div>
                          )}
                          {item.source_data.updated_at && (
                            <React.Fragment>
                              <div className="fp-vertical-small-divider" />
                              <div>
                                <span style={{ color: "black" }}>Last Updated:</span>
                                <span className="font-blue-gray">
                                  &nbsp;
                                  {moment(item.updated_at).format("llll")}
                                </span>
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* {item.description ? <MessageBox isSelfBox={false} time={moment(item.source_data.created_at).format('llll')}>
                  {item.description.split(',').map((item_split, item_index) => {
                    return <p key={item_index} className='fp-size-13'>{item_split}</p>;
                  })}
                </MessageBox> : ''} */}
                    {item.source_data.heading && (
                      <p className="review-details-heading m-0 mb-1 ">{item.source_data.heading}</p>
                    )}
                    <TicketText item={item} searchText={searchText} />
                    {item.source_data.extra_data &&
                    item.source_data.extra_data.image_links &&
                    item.source_data.extra_data.image_links.length !== 0 ? (
                      <FeedbackImage reviews={item.source_data.extra_data} />
                    ) : (
                      ""
                    )}
                    {item.source_data_reply && <MessageBox
                      backgroundColor="#FDE6F1"
                      time={moment(item?.source_data_reply?.created_at).format("llll")}
                      author={item?.source_data_reply?.sender_id}
                    ><div><b>Subject : </b>{item?.source_data_reply?.subject}</div>  
                    <b>Body : </b>{item?.source_data_reply?.body}
                    </MessageBox>}
                    <Divider className="my-3" style={{ background: "#F6F6F8" }} />
                    <div className="mt-15 d-flex justify-content-between align-center gap-10">
                      <div className="d-flex gap-10">
                        <FPToolTip title="Tags">
                          <div className="tagIcon">
                            <img
                              src={whiteTag}
                              alt="whiteTags"
                              style={{ height: "15px", width: "15px" }}
                              onClick={(e) => {
                                this.handleReviewTagsChange(e, item);
                              }}
                            />
                          </div>
                        </FPToolTip>
                        {tags.length ? (
                          <CSSTransitionGroup
                            transitionName="reviews-tags"
                            transitionEnterTimeout={500}
                            transitionLeaveTimeout={500}
                            className="d-flex flex-wrap gap-10 align-items-center"
                          >
                            {tags.map((tag, index) => {
                              return (
                                <FPChip
                                  key={`${index}-ticket-tags`}
                                  size="small"
                                  variant="outlined"
                                  style={{
                                    borderColor: "#BFC2CB",
                                    borderWidth: "1.5px",
                                    maxHeight: "24px",
                                  }}
                                  label={<span style={{ fontSize: "10.5px" }}>{snakeToCapital(tag)}</span>}
                                />
                              );
                            })}
                          </CSSTransitionGroup>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        {item?.source === 'message' && <FPButton size="small" label="Send Mail" onClick={()=>  {
                            if (item?.is_send_email) this.handleMailModal(item)
                            else this.setState({ notSendMail: true, ticket_detail: item })
                          }} 
                        />}
                      </div>
                    </div>
                  </section>
                </CardWrapper>
              );
            })
          : null}
        {addReviewDetailsModal && (
          <AddReviewDetailsModal
            Order={isOrder_id}
            Email={isEmail}
            Name={isName}
            Phone={isPhone}
            isOpen={addReviewDetailsModal}
            toggle={this.handleToggle}
            review_id={detail_review_id}
            businessId={businessId}
            heading={addReviewDetailsModalHeading}
          />
        )}
        {/* Tag manager */}
          <TicketingTagsModal
            tagsList={tagsList}
            isReviewTagModalOpen={this.state.isReviewTagModalOpen}
            toggleReviewTagModal={this.toggleReviewTagModal}
            item={this.state.tagSelectedItem}
            postTagOnReview={this.postTagOnReview}
            error={error}
            handleTagChange={this.handleTagChange}
            addtag={this.addtag}
          />

        <FormModal
          isOpen={this.state.isMailModal}
          toggle={() =>  this.setState({isMailModal: !isMailModal})}
          size="lg"
          width={"700px"}
          heading="Send Mail"
        >
          <TicketMailModal
            initialValues={{
              from: this.getToEmail(ticket_detail?.source_data?.user_id),
              to: this.getToEmail(ticket_detail?.source_data?.sender_id),
              cc: "",
              subject: "",
              body: "",
            }}
            onSubmit={(data) => this.handleTicketMailSubmit(data)}
          />
        </FormModal>

        <ConfirmationModal
          header="Warning!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader={
            `This ${ticket_detail?.source_data?.user_id} email does not match your logged-in Gmail account. Please switch to the correct Gmail account or log in with the right email.`
          }
          isOpen={notSendMail}
          toggle={() => this.setState({ notSendMail : !notSendMail })}
          onConfirm={() => this.setState({ notSendMail : !notSendMail })}
          success={false}
          buttonName={"Back"}
        />
      </div>
    );
  }
}

export default TicketList;
