/* eslint-disable no-unused-vars */
import React, { memo } from 'react';
import {
  Checkbox,
  FormControlLabel,
  makeStyles,
  withStyles,
} from '@material-ui/core';

const DefaultCheckbox = withStyles({
  root: {
    // color: '#1B1C1D',
    color:'#BFC2CB',
    '&$checked': {
      color: '#F13A88',
    },
  },
  disabled:{
    color: 'rgba(0, 0, 0, 0.26) !important',
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles({
  fromControl: {
    '&.MuiFormControlLabel-root': {
      margin: '0',
    },

    '&.MuiFormControlLabel-root .MuiButtonBase-root': {
      padding: '0',
      paddingRight: '4px',
    },

    '&.MuiFormControlLabel-root .MuiTypography-root': {
      color: '#1B1C1D'
    },

    '&.MuiFormControlLabel-root .MuiTypography-root.Mui-disabled' :{
      color: 'rgba(0, 0, 0, 0.26) !important',
    }
  },
});

const FPCheckbox = ({ label, labelClassName, ...rest }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={<DefaultCheckbox {...rest} />}
      label={label}
      className={`${classes.fromControl} ${labelClassName ? labelClassName : ''}`}
    />
  );
};

export default memo(FPCheckbox);
