import React, { useEffect, useState } from "react";
import * as URLS from "../../libs/apiUrls";
import { getAllParamsInArrayForm } from "../../libs/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonAction from "../../actions/common/commonAction";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import * as filterListActions from "../../actions/common/filterListActions";
import { FPButton, FPFilter, FPToolTip } from "../../components/common/new";
import * as utils from "../../libs/utils";
import cookie from "react-cookies";
import moment from "moment";
import { IconButton } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../images/svg/Download.svg";
import FormModal from "../common/FormModal";

const MenuMarketFilter = (props) => {
  const {
    common,
    filter,
    filterAction,
    match,
    source,
    history,
    location,
    actions,
    commonActions,
    downloadExcel,
    pageSizeCookie,
  } = props;

  const [isMoreThanNinety, setIsMoreThanNinety] = useState(false);

  const dateType = utils.getParams(window.location.search, "date_type");
  const startDate = utils.getParams(window.location.search, "start_date");
  const endDate = utils.getParams(window.location.search, "end_date");
  const service_category = cookie.load("service_category");

  const fetchFilterList = async () => {
    const {
      params: { business_id },
    } = match;
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch(
          "",
          `${URLS.BUSINESS_SETUP}${business_id}/branch/`
        ),
        filterAction.getLastSyncType(
          "",
          `${utils.format(URLS.FILTER_LAST_SYNC, [business_id])}`
        ),
        filterAction.getOutOfStockItems(
          "",
          `${utils.format(URLS.FILTER_OUT_OF_STOCK_ITEMS, [business_id])}`
        ),
        commonActions.cityOptions(business_id),
        commonActions.stateOptions(business_id),
        commonActions.branchTagOptions(business_id),
        actions.reviewSourceOptions({ business_id }),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  const handleReviewDownloadFlow = () => {
    if (dateType === "other") {
      const M_startDate = moment(startDate);
      const M_endDate = moment(endDate);
      const daysDifference = M_endDate.diff(M_startDate, "days");

      if (daysDifference > 90) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    } else {
      if (
        ["prior_6_month", "current_year", "prior_12_months"].indexOf(dateType) >
        -1
      ) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    }
  };

  useEffect(() => {
    fetchFilterList();
  }, []); // eslint-disable-line

  const handleDefaultFilter = (list, key) => {
    const filter = location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable =
      defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list.map((item) => {
      item["isChecked"] =
        isKeyFilterAvailable &&
        defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };
  const providerList = source?.choices?.filter(
    (data) => data.display_name === "Zomato" || data.display_name === "Swiggy"
  );

  const handelSearch = (searchField) => {
    const { params: { business_id } } = match;
    let search_param = searchField ? searchField : ''
    filterAction.getOutOfStockItems(
      "",
      `${utils.format(URLS.FILTER_OUT_OF_STOCK_ITEMS, [business_id])}?word=${search_param}`
    )
  }

  const filterList = [
    {
      id: "1",
      label: "Date Duration",
      key: "date_type",
      type: "radio",
      isSearchable: false,
      list:
        filter.date_type &&
        handleDefaultFilter(utils.removeLifetimeFilter(filter?.date_type?.choices), "date_type"),
    },
    // {
    //   id: "2",
    //   label: "Last Synced Date",
    //   key: "last_synced_date",
    //   type: "radio",
    //   isSearchable: false,
    //   list: filter.last_synced_dates ? handleDefaultFilter(filter.last_synced_dates, "last_synced_dates") : [],
    // },
    {
      id: "2",
      label: "Last Synced Time",
      key: "last_synced_times",
      type: "radio",
      isSearchable: false,
      list: filter.last_synced_times
        ? handleDefaultFilter(filter.last_synced_times, "last_synced_times")
        : [],
    },
    {
      id: "3",
      label: "Location",
      key: "branch",
      type: "checkbox",
      isSearchable: true,
      list:
        filter.branch && handleDefaultFilter(filter.branch?.choices, "branch"),
    },
    {
      id: "4",
      label: "City",
      key: "city",
      type: "checkbox",
      isSearchable: true,
      list:
        common.city_options &&
        handleDefaultFilter(common.city_options?.choices, "city"),
    },
    {
      id: "5",
      label: "State",
      key: "state",
      type: "checkbox",
      isSearchable: true,
      list:
        common.state_options &&
        handleDefaultFilter(common.state_options?.choices, "state"),
    },
    {
      id: "6",
      label: "Provider",
      key: "provider",
      type: "checkbox",
      isSearchable: true,
      list: source?.choices && handleDefaultFilter(providerList, "provider"),
    },
    {
      id: "7",
      label: "Location Tag",
      key: "branch_tag",
      type: "checkbox",
      isSearchable: true,
      list:
        common.branch_tag_options &&
        handleDefaultFilter(
          common.branch_tag_options.results.map((item) => ({
            value: item.id,
            display_name: item.title,
          })),
          "branch_tag"
        ),
    },
    {
      id: "8",
      label: "Item",
      key: "out_of_stock_items",
      type: "checkbox",
      isSearchRealTime: true,
      handleSearchApi: (val) => handelSearch(val),
      isSearchable: true,
      list: filter.out_of_stock_items
        ? handleDefaultFilter(filter.out_of_stock_items, "out_of_stock_items")
        : [],
    },
  ].filter((item) => !!item);
  return (
    <React.Fragment>
      <FPFilter
        data={filterList}
        className="mt-3"
        history={history}
        pageSizeCookie={pageSizeCookie}
        location={window.location}
        actionRender={() => (
          <>
            <FPToolTip title={"Download"}>
              <IconButton
                component="span"
                className="circular-button"
                onClick={() => handleReviewDownloadFlow()}
              >
                <DownloadIcon />
              </IconButton>
            </FPToolTip>
          </>
        )}
      />

      {isMoreThanNinety && (
        <FormModal
          isOpen={isMoreThanNinety}
          toggle={() => setIsMoreThanNinety(false)}
          heading="Attention !"
          maxWidth="xs"
          showDivider={true}
          dialogActions={
            <FPButton
              size="small"
              onClick={() => setIsMoreThanNinety(false)}
              label="OK"
            />
          }
        >
          <p className="fp-size-16">
            Please contact to support to download data older than 90 days.
          </p>
        </FormModal>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    filter: state.filter,
    source: state.review.review_source_options,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
    actions: bindActionCreators(ReviewsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(MenuMarketFilter);
