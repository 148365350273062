import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import cookie from 'react-cookies';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import * as CampaignActions from '../../actions/campaign/campaignActions';
import CampaignList from '../../components/campaign/CampaignList';
import LoaderComponent from '../../components/common/loaderComponent';
import LockedComponent from '../../components/common/LockedComponent/LockedComponent';
import * as utils from '../../libs/utils';
import ConfirmationModal from '../../components/common/ConfirmationModal';

class CampaignNewsLetterPage extends React.PureComponent
{
  constructor(props)
  {
    super(props);
    this.state = { isAddNewsLetterCammpaign: false };
    this.goToAddNewsLetterCampaign = this.goToAddNewsLetterCampaign.bind(this);
    this.viewInvitedMembers = this.viewInvitedMembers.bind(this);
    this.getCampaignNewsLetter = this.getCampaignNewsLetter.bind(this);
    this.deleteNewsLetterCampaign = this.deleteNewsLetterCampaign.bind(this);
    this.goToManageNewsletterRecurringCampaign = this.goToManageNewsletterRecurringCampaign.bind(this);
    //PageSize Handling
    const pageSize = cookie.load('pageSize') || {};
    const { history, location: { pathname,search }} = props;
    history.replace({ pathname, search: queryString.stringify({ ...queryString.parse(search), page_size: pageSize.campaigns_newsletter || 10 }) });
  }

  componentDidMount()
  {
    this.getCampaignNewsLetter( this.props, queryString.parse(this.props.location.search));
    //Set Title
    document.title = 'Campaign|Newsletter';
  }

  componentDidUpdate(prevProps)
  {
    const { location : { search : prevSearch } } = prevProps;
    const { location : { search } } = this.props;

    if(prevSearch !== search)
    {
      this.getCampaignNewsLetter( this.props, queryString.parse(search) ); 
    }

  }

  getCampaignNewsLetter(props,data)
  {
    const { match : { params : { business_id } } } = props;
    this.props.actions.getCampaignNewsLetter(data, business_id)
      .catch((err)=>err==='lock' && this.setState({lock: 'Campaigns Module is locked.'}));

  }

  deleteNewsLetterCampaign(item)
  {
    const { history, location : { pathname, search }, match : { params : { business_id } } } = this.props;
    return this.props.actions.deleteNewsLetterCampaign(item.id, business_id)
      .then(() => {
        const query = queryString.parse(search);
        if(query.page){
          history.push(pathname);
        }
        else{
          this.getCampaignNewsLetter(this.props, queryString.parse(this.props.location.search));
        }
      });
  }
  
  goToAddNewsLetterCampaign()
  {
    const { history, match: { params: { business_id }} } = this.props;
    history.push(`/${business_id}/campaigns/news-letter/add`);
  }
  goToManageNewsletterRecurringCampaign(){
    const { history, match: { params: { business_id }} } = this.props;
    history.push(`/${business_id}/recurring-campaigns/news-letter/list`);
  }

  viewInvitedMembers(item)
  {
    const { history, match : { params: { business_id } } } = this.props;
    history.push(`/${business_id}/campaigns/news-letter/${item.id}/members`);
  }

  toggleConfirm = (confirmationFunction) => {
    this.setState({ isConfirmOpen: !this.state.isConfirmOpen, confirmationFunction });
  }
  render()
  {
    const { campaign: { newsLetterCampaignList }} = this.props;
    const { lock} = this.state;
    const ordering = queryString.parse(this.props.location.search).ordering;

    return (
      <div>
        <LockedComponent message={lock}></LockedComponent>
        <LoaderComponent loader={this.props.loader}/>
        <Row className="content-area">
          <Col xs={12} sm={12} className="p-3 business-setup">
            <CampaignList
              deleteCampaign={(item) => this.toggleConfirm(() =>this.deleteCampaign(item))}
              // cloneCampaign={this.cloneCampaign}
              list={ newsLetterCampaignList }
              goToAddCampaign={this.goToAddNewsLetterCampaign}
              location={this.props.location}
              history={this.props.history}
              viewInvitedMembers={this.viewInvitedMembers}
              goToManageRecurringCampaign={this.goToManageNewsletterRecurringCampaign}
              ordering={ordering}
              isCampaignNewsLetter = {true} 
              sortFunction={(on)=>utils.toggleSort(this.props.history,this.props.location,on)}
              pageSizeCookie='campaigns_newsletter'
            />
          </Col>
          <ConfirmationModal
            header='Are you sure!'
            subHeader='Are you sure you want to delete the selected items? This action cannot be undone.'
            isOpen={this.state.isConfirmOpen}
            toggle={this.toggleConfirm}
            onConfirm={this.state.confirmationFunction}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campaign : state.campaign,
    loader: state.common.loader,
  };
};

CampaignNewsLetterPage.propTypes = {
  actions : PropTypes.instanceOf(Object)
};

function mapDispatchToProps(dispatch){
  
  return {
    actions : bindActionCreators(CampaignActions, dispatch),
  };

}

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(CampaignNewsLetterPage);