import React, { useEffect, useState } from "react";
import { Table } from "antd";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import CustomStarRatingComponent from "../common/StarComponent";

const TableReviewChannel = (props) => {
  const { dataHeader, data } = props;
  const [columns, setColumns] = useState([]);
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    if (data) {
      let col = [];
      if (data.length > 0) {
        let keys = data[0];
        delete keys["ID"];
        delete keys["Branch ID"];
        col = Object.keys(keys);
        // console.log(keys);
      }
      const new_col = [];
      const new_data = [];
      col.map((val) => {
        new_col.push({
          title: val,
          dataIndex: val,
          className: 'text-center',
          render: (_, value) => {
            const dataInd = data[value.key];
            const main_value = dataInd[val];
            if (main_value && typeof main_value === "object") {
              const rating = Number(main_value.Rating);
              return (
                <div>
                  <div className="reportRating-tag">
                    <CustomStarRatingComponent
                      name="rating"
                      starCount={1}
                      value={isNaN(rating) ? '1' : rating || '1'}
                    />
                    <span className="review-info">{isNaN(rating) ? '0' : rating}</span>
                  </div>
                  <p className="text-center"> {main_value.Reviews} </p>
                </div>
              );
            } else {
              return <span>{main_value ? main_value : "__"}</span>;
            }
          },
        });
      });

      data.map((val, index) => {
        new_data.push({ ...data[val], key: index });
      });
      setColumns([...new_col]);
      setAllData([...new_data]);
    }
  }, [data]);

  const onChange = (filters, sorter, extra) => {
    console.log("params", filters, sorter, extra);
  };

  return (
    <CardWrapper headerTitle={dataHeader} className="p-3">
      <Table
        loading={false}
        columns={columns}
        dataSource={allData}
        onChange={onChange}
        pagination={false}
      />
    </CardWrapper>
  );
};

export default TableReviewChannel;