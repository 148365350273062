import { createAsyncAction } from "../../libs/helper";
import * as types from "../actionTypes";

export const getBranch = createAsyncAction(types.GET_BRANCH_SUCCESS, types.GET_BRANCH_FAILURE);

export const getCity = createAsyncAction(types.GET_CITY_SUCCESS, types.GET_CITY_FAILURE);

export const getState = createAsyncAction(types.GET_STATE_SUCCESS, types.GET_STATE_FAILURE);

export const getDateType = createAsyncAction(types.GET_DATE_TYPE_SUCCESS, types.GET_DATE_TYPE_FAILURE);

export const getLastSyncType = createAsyncAction(types.GET_LAST_ASYNC_TYPE_SUCCESS, types.GET_LAST_ASYNC_TYPE_FAILURE);

export const getOutOfStockItems = createAsyncAction(types.GET_OUT_OF_STOCK_ITEMS_SUCCESS, types.GET_OUT_OF_STOCK_ITEMS_FAILURE);

export const getDateTypeAccount = createAsyncAction(
  types.ACCOUNT_GET_DATE_TYPE_SUCCESS,
  types.ACCOUNT_GET_DATE_TYPE_FAILURE
);

export const getContentType = createAsyncAction(types.GET_CONTENT_TYPE_SUCCESS, types.GET_CONTENT_TYPE_FAILURE);

export const setCurrentPageFilterConfig = (data) => {
  return (dispatch) => {
    dispatch({ type: types.ALL_CURRENT_PAGE_FILTER_CONFIG, data });
  };
};

export const getListByFilter = createAsyncAction(types.GET_LIST_BY_FILTER_SUCCESS, types.GET_LIST_BY_FILTER_FAILURE);

export const getProductItems = createAsyncAction(types.GET_PRODUCT_ITEM_SUCCESS, types.GET_PRODUCT_ITEM_FAILURE);

// export const getTicketListByFilter = createAsyncAction(
//   types.GET_TICKET_LIST_BY_FILTER_SUCCESS,
//   types.GET_TICKET_LIST_BY_FILTER_FAILURE
// );
