/* eslint-disable */
// * WE MANAGE DATE RANGE FILTER INTO COMMON FILTER COMPONENT
import React, { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import CardWrapper from "../../../../modules/dashboard/component/CardWrapper";
import {
  getQueryParams,
  setDateFormat,
  getParams,
  areObjectsEqualJSON,
  removeNullValues,
  compareObjects,
} from "../../../../libs/utils";
import "./fpfilter.css";
import ActionButton from "./ActionButton";
import SelectedItems from "./SelectedItems";
import ItemSelectionList from "./ItemSelectionList";
import cookie from "react-cookies";
import queryString from "query-string";

const FPFilter = (props) => {
  const {
    data,
    className,
    history,
    actionRender,
    pageSizeCookie,
    ticketFilter,
    onApply,
    customResetFuc,
    applyBtnText,
    hideResetBtn = false,
    customOnApply,
    onChange,
    lightResetBtn,
    locationFilter,
    tagsListing,
    categoryTagsListing,
    isReviewTag,
    isCategoryTag,
    withContent,
    ReviewWidgetFilter,
    getData,
    match,
    isProductName,
    productNameList,
  } = props;

  const [allFilterList, setAllFilterList] = useState(data || []);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const [currentOpenFilterItem, setCurrentOpenFilterItem] = useState(null);

  const [showAll, setShowAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [initialValues, setInitialValues] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  // * set current open filter
  const handelFilterItem = (item) => {
    const isSameClicked = areObjectsEqualJSON(currentOpenFilterItem, item);

    isSameClicked
      ? setCurrentOpenFilterItem(null)
      : setCurrentOpenFilterItem(item);
  };

  const activeItemKey = currentOpenFilterItem ? currentOpenFilterItem.key : "";

  const dateType = getParams(location?.search, "date_type");
  const startDate = getParams(location?.search, "start_date");
  const endDate = getParams(location?.search, "end_date");
  const tag = getParams(location?.search, "tag_id");
  const category_tag = getParams(location?.search, "tag_category_id");
  const product = getParams(location?.search, "product");

  // * for update list when dateFilter and data update
  useEffect(() => {
    setAllFilterList(data);

    //* set Default value
    let initialFormValues = {};
    allFilterList.map((filterItem) => {
      const defaultList =
        filterItem.list &&
        filterItem.list.filter((listItem) => listItem.isChecked);
      initialFormValues[filterItem.key] = defaultList;
      return filterItem;
    });

    //* SET default date Range value
    if (dateType === "other") {
      initialFormValues["date_type"] = [
        {
          display_name: `${setDateFormat(startDate)} to ${setDateFormat(
            endDate
          )}`,
          parentKey: "date_type",
          value: "other",
        },
      ];
    }

    if (tag) {
      const tags = tag.split(",");
      const test = tags.map((item) => {
        return {
          display_name: tagsListing.find(
            (tag) => Number(tag.id) === Number(item)
          )?.display_name,
          isChecked: true,
          parentKey: "tag_id",
          value: item,
        };
      });
      initialFormValues["tag_id"] = test;
    }

    if (category_tag) {
      const tags = category_tag.split(",");

      const test = tags.map((item) => {
        return {
          display_name: categoryTagsListing.find(
            (tag) => Number(tag.id) === Number(item)
          )?.display_name,
          isChecked: true,
          parentKey: "tag_category_id",
          value: item,
        };
      });
      initialFormValues["tag_category_id"] = test;
    }

    if (product) {
      const tags = product.split(",");

      const test = tags.map((item) => {
        return {
          display_name: productNameList.find((tag) => item === tag.display_name)
            ?.display_name,
          isChecked: true,
          parentKey: "product",
          value: item,
        };
      });
      initialFormValues["product"] = test;
    }

    setInitialValues(initialFormValues);
  }, [data]);


  const handelApply = (formValue) => {
    const { pathname } = location;

    // OLD
    const oldParams = JSON.parse(
      JSON.stringify(queryString.parse(window.location.search))
    );

    // NEW
    const params = JSON.parse(JSON.stringify(getQueryParams(formValue)));
    const parseParams = JSON.parse(JSON.stringify(queryString.parse(params)));

    let formValuesKeys = JSON.parse(
      JSON.stringify([Object.keys(formValue)][0])
    );

    if (
      oldParams?.date_type === "other" &&
      parseParams?.date_type === "other"
    ) {
      formValuesKeys = formValuesKeys.filter(
        (field) => field !== "start_date" && field !== "end_date"
      );
    }
    let emptyFormParams = {};

    formValuesKeys.map((item) => {
      emptyFormParams[item] = null;
    });

    // FINAL MERGE : OLD + NEW
    let sanitizeOldParams = removeNullValues(
      Object.assign(oldParams, emptyFormParams, parseParams)
    );

    // QUERY-STR
    if (
      sanitizeOldParams?.page &&
      "?" + queryString.stringify(sanitizeOldParams) !== location?.search
    ) {
      sanitizeOldParams.page = 1;
    }
    const newQueryString = queryString.stringify(sanitizeOldParams);

    onApply?.({
      sanitizeOldParams,
      queryString: newQueryString,
    });
    history.push({
      pathname,
      search: newQueryString,
    });
    setCurrentOpenFilterItem(null);

    const differences = compareObjects(initialValues, formValue);
    const business_id = match?.params?.business_id;

    if (Object.keys(differences).length === 0) {
      if (getData) {
        getData({
          ...sanitizeOldParams,
          ...(match && { businessId: business_id }),
        });
      }
    }
    setSearchQuery("");
  };

  const onResetCookie = () => {
    let pageSize = cookie.load("pageSize") || {};
    pageSize[pageSizeCookie] = 10;
    cookie.save("pageSize", pageSize, { path: "/" });
  };

  const handelReset = (formikConfig) => {
    setIsSelectAll(false);
    onResetCookie();
    const { pathname } = location;
    customResetFuc
      ? customResetFuc({ pathname, location, history })
      : history.push({
          pathname,
          search: "date_type=prior_30_day&page_size=10",
        });

    setCurrentOpenFilterItem(null);
  };

  const isFirstRendered = useRef(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const toggleModal = () => {
    setTagPopupName(null);
    setShowModal(!showModal);
  };

  return (
    <CardWrapper
      showDivider={false}
      className={`fp-filter ${className ? className : ""}`}
      contentClass="py-2 px-3"
    >
      <Formik
        initialValues={{
          ...initialValues,
          start_date: [],
          end_date: [],
        }}
        enableReinitialize={true}
        onSubmit={(formValue) =>
          customOnApply?.(formValue) || handelApply(formValue)
        }
      >
        {(formikConfig) => {
          useEffect(() => {
            if (isFirstRendered.current) {
              onChange?.({
                values: formikConfig.values,
                setFieldValue: formikConfig.setFieldValue,
                activeItemKey,
              });
            } else {
              isFirstRendered.current = true;
            }
          }, [formikConfig.values]);
          return (
            <Form>
              <React.Fragment>
                {/* <div
                  className={classNames(
                    "justify-content-between flex-row-reverse",
                    showAll ? "d-block" : "align-center"
                  )}
                  style={{ gap: "100px" }}
                >
                  <div
                    className={classNames(
                      "d-flex justify-content-end",
                      showAll ? "mb-1" : ""
                    )}
                  >
                    <ActionButton
                      applyBtnText={applyBtnText}
                      hideResetBtn={hideResetBtn}
                      onResetClick={() => handelReset(formikConfig)}
                      actionRender={actionRender}
                      lightResetBtn={!!lightResetBtn}
                    />
                  </div>
                  <ItemSelectionList
                    allFilterList={allFilterList}
                    formikConfig={formikConfig}
                    handelFilterItem={handelFilterItem}
                    activeItemKey={activeItemKey}
                    showAll={showAll}
                    toggleShowAll={toggleShowAll}
                  />
                </div> */}
                <ItemSelectionList
                  allFilterList={allFilterList}
                  formikConfig={formikConfig}
                  handelFilterItem={handelFilterItem}
                  activeItemKey={activeItemKey}
                  showAll={showAll}
                  toggleShowAll={toggleShowAll}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  toggleModal={toggleModal}
                  location={location}
                  tagsListing={tagsListing}
                  categoryTagsListing={categoryTagsListing}
                  isReviewTag={isReviewTag}
                  isCategoryTag={isCategoryTag}
                  isProductName={isProductName}
                  productNameList={productNameList}
                  withContent={withContent}
                  setCurrentOpenFilterItem={setCurrentOpenFilterItem}
                  ReviewWidgetFilter={ReviewWidgetFilter}
                  locationFilter
                />

                {/* // *FORM control */}
                <SelectedItems
                  allFilterList={allFilterList}
                  currentOpenFilterItem={currentOpenFilterItem}
                  formikConfig={formikConfig}
                  isSelectAll={isSelectAll}
                  setIsSelectAll={setIsSelectAll}
                  ticketFilter={ticketFilter}
                  applyBtnText={applyBtnText}
                  hideResetBtn={hideResetBtn}
                  onResetClick={() => handelReset(formikConfig)}
                  actionRender={actionRender}
                  lightResetBtn={!!lightResetBtn}
                  locationFilter={locationFilter}
                  setCurrentOpenFilterItem={setCurrentOpenFilterItem}
                  ReviewWidgetFilter={ReviewWidgetFilter}
                  withContent={withContent}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                />
              </React.Fragment>
            </Form>
          );
        }}
      </Formik>
    </CardWrapper>
  );
};

export default FPFilter;
