import React from "react";
import queryString from "query-string";
import { Row, Card } from "reactstrap";
import { Field, reduxForm } from "redux-form";
import SelectComponent from "../common/Select";
import MultiSelect from "../common/SelectComponent";
import "react-datepicker/dist/react-datepicker.css";
import { FPButton } from "../common/new";
import ResetIcon from "../../images/Reset.png";
import DateSelector from "../../modules/dashboard/component/DateSelector";

class RestroFilter extends React.PureComponent {
  constructor(props) {
    super(props);
    const query = queryString.parse(props.location.search);
    this.toQuery = this.toQuery.bind(this);
    this.resetSource = this.resetSource.bind(this);
    this.handleFilterApply = this.handleFilterApply.bind(this);
    this.state = {
      branch: query.branch ? parseInt(query.branch) : undefined,
      provider: query.provider ? query.provider.split(",") : [],
      date: {
        start_date: query.start_date ? query.start_date : new Date(),
        end_date: query.end_date ? query.end_date : new Date(),
        date_type: query.date_type ? query.date_type : undefined ,
      },
      selectedDate: null,
      filterApply: null,
    };
  }

  componentDidMount() {
    const { location: { search } } = this.props;
    const query = queryString.parse(search);
    this.setState({ 
      branch:  query.branch ? parseInt(query.branch) : undefined, 
      provider: query.provider ? query.provider.split(",") : [],
    })
  }
  componentDidUpdate(prevProps) {
    const { location: { search: nextSearch } } = this.props;
    const { location: { search: prevSearch } } = prevProps;
    const { date } = this.state;
    const prevQuery = queryString.parse(prevSearch);
    const nextQuery = queryString.parse(nextSearch);
    if (prevQuery.date_type !== nextQuery.date_type) {
      this.setState({ date: { ...date, date_type: nextQuery.date_type } });
    }
  }

  toQuery(key, data) {
    const { location: { search } } = this.props;
    let newData;
    if (key === "date_type") {
      newData = { ...queryString.parse(search), ...data,};
    } else {
      newData = {  ...queryString.parse(search), [key]: data };
    }
    console.log(key, data);
    this.setState({ filterApply: newData });
  }

  handleFilterApply() {
    const { history, location: { pathname }, onApply } = this.props;
    let { filterApply } = this.state;
    if(filterApply.date_type !== "other") {
      filterApply = {...filterApply, start_date : '', end_date: ''};
    }
    history.push({ pathname, search: queryString.stringify(filterApply)});
    onApply();
  }

  resetSource() {
    const { reset, history, location: { pathname, search }, onApply, } = this.props;
    let filters = queryString.parse(search);
    if ( filters.branch || filters.date_type || filters.provider || filters.source) {
      reset();
      delete filters.branch;
      delete filters.provider;
      delete filters.end_date;
      delete filters.start_date;
      delete filters.date_type;
      delete filters.source;
      filters.date_type = "prior_30_day";
      this.setState({ provider: [], tag: [], selectedDate: null });
      const url = pathname + "?" + queryString.stringify(filters);
      history.push(url);
      onApply();
    }
  }

  render() {
    const { showLocationFilter, reviewSourceOptions, showSourceFilter, branchList } = this.props;
    const { branch , provider } = this.state;
    return (
      <Card body className="py-3 form-label reportFilter">
        <h6> Filters </h6>
        <Row className="m-0">
          <form className="w-100">
            {showLocationFilter && (
              <div>
                <Field
                  name="location"
                  type="text"
                  component={SelectComponent}
                  placeholder="Location"
                  selectedValue={branch}
                  options={ branchList.map((i) => ({  text: i.display_name, value: i.value })) || [] }
                  onChange={(e, val) => {
                    this.setState({branch : val})
                    this.toQuery("branch", val);
                  }}
                />
              </div>
            )}
            <div className="mt-2 pt-1 side-filter">
              <DateSelector
                dropDownClassName="m-0"
                onChange={(date) => this.toQuery("date_type", date)}
                isForLifeTimeSupported={false}
              />
            </div>

            {showSourceFilter && (
              <div className="mt-2 pt-1 side-filter">
                <Field
                  name="provider"
                  component={MultiSelect}
                  className={"h-100"}
                  placeholder={"Source"}
                  onChange={(e, val) => this.toQuery("provider", val)}
                  options={reviewSourceOptions}
                  val={provider}
                  labelKey="display_name"
                  valueKey="value"
                  multi
                  simpleValue
                />
              </div>
            )}
            
            <div className="d-flex gap-10 mt-3">
              <FPButton
                type="button"
                label="Apply"
                size="large"
                fontSize="14px"
                className="w-50 button"
                onClick={() => this.handleFilterApply()}
              />
              <FPButton
                type="button"
                onClick={this.resetSource}
                label="Reset"
                size="large"
                fontSize="14px"
                backgroundColor="#00B5AD"
                className="button w-50"
                startIcon={<img src={ResetIcon} alt="Reset" />}
              />
            </div>
          </form>
        </Row>
      </Card>
    );
  }
}

export default reduxForm({
  form: "reportFilterForm",
})(RestroFilter);
