import React, { useState, useEffect } from "react";
import { FPButton } from "../../components/common/new";
import { Form, Formik } from "formik";
import { FormInput } from "../../components/common/new/forms";

const AddRequestTypeModal = (props) => {
  const { addNewRequest, editId } = props;
  const [initialData, setInitialData] = useState({ title: "", is_request: true });
  const [maxCharLimit] = useState(25);

  useEffect(() => {
    if (editId) {
      setInitialData({ title: editId.title, is_request: editId.is_request });
    }
  }, [editId]);

  return (
    <>
      <Formik
        initialValues={initialData}
        onSubmit={(values) => {
          if (editId) {
            addNewRequest({ item: values, ticket_id: editId.id });
          } else {
            addNewRequest({ item: values });
          }
        }}
        enableReinitialize={true}
      >
        {({ setFieldValue, values }) => (
          <Form>
            <FormInput
              name="title"
              placeholder="Request Type Name"
              size="small"
              type="text"
              wrapperClass="mb-0"
              value={values.title}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= maxCharLimit) {
                  setFieldValue("title", inputValue);
                }
              }}
            />

            <p style={{ color: "#1B1C1D", fontSize: "12px" }}>{maxCharLimit - values.title.length} characters left</p>

            <div style={{ textAlign: "right", marginTop: "20px" }}>
              <FPButton label={editId ? "Update" : "Add"} size="small" type="submit" />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddRequestTypeModal;
