import React from "react";
import { Input } from "semantic-ui-react";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { Box } from "@material-ui/core";
import { FPButton } from "../common/new";
import youtubeIcon from "../../images/youtubeNewLogo.png";
import instagramIcon from "../../images/instagram_logo.png";
import twitterIcon from "../../images/twitterNewIcon.png";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import InstagramLogin from 'react-instagram-login';
import FaceBookIcon from "../../images/facebook.png";
import InstagramLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Button } from "reactstrap";
// Import necessary libraries for Twitter and YouTube logins (Assume hypothetical ones for example)

class AddKeywordForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      keywordValue: "",
      error: null,
    };
    this.handleKeywordChange = this.handleKeywordChange.bind(this);
  }

  handleKeywordChange(e) {
    this.setState({ keywordValue: e.target.value });
  }

  onKeywordSubmit() {
    const { addKeyword } = this.props;
    const { keywordValue } = this.state;
    if (!keywordValue) {
      this.setState({
        error: "Please enter a keyword value in order to proceed.",
      });
    } else {
      addKeyword(keywordValue.toLowerCase());
    }
  }

  // responseInstagram = (response) => {
  //   const { postCode } = this.props;
  //   postCode(response.accessToken, "instagram").then(() => {
  //     this.setState({ activeTab: "connections" });
  //   });
  // };

  responseInstagram = (response) => {
    console.log(response, 'insta response....');
    const { postCode } = this.props;
    return postCode(response.accessToken, "instagram").then(() => {
      this.setState({ activeTab: "connections" });
    });
  };

  responseFacebook = (response) => {
    const { postCode, isLoggedIn, logout } = this.props;
    if (isLoggedIn["facebook"]?.status === "connected") {
      logout("facebook");
    } else {
      postCode(response.accessToken, "facebook").then(() => {
        this.setState({ activeTab: "connections" });
      });
    }
  };

  responseTwitter = (response) => {
    const { postCode } = this.props;
    console.log(response, 'responseresponseresponseresponseresponse');
    postCode(response.accessToken, "twitter").then(() => {
      this.setState({ activeTab: "connections" });
    });
  };

  responseYouTube = (response) => {
    const { postCode } = this.props;
    postCode(response.accessToken, "youtube").then(() => {
      this.setState({ activeTab: "connections" });
    });
  };

  render() {
    const { value, keywordEmpty, serverError, isAnySocialConnected, isLoggedIn } = this.props;
    const { error } = this.state;

    const connections = [
      { id: "Instagram", icon: instagramIcon, width: "60", height: "60" },
      { id: "Youtube", icon: youtubeIcon, width: "60", height: "60" },
      { id: "Twitter", icon: twitterIcon, width: "60", height: "60" },
      { id: "Facebook", icon: FaceBookIcon, width: "60", height: "60" }
    ];

    return (
      <>
        {isAnySocialConnected && (
          <form>
            <CardWrapper showDivider={false} className="p-4 mb-5" style={{ border: "5px solid red !important" }}>
              <Box component="span" sx={{ fontSize: "20px", color: "#1A2544", fontWeight: 600 }}>
                Brand Monitoring
              </Box>
              <div className="mt-4">
                <Input
                  error={(keywordEmpty && !value) || serverError}
                  value={value}
                  fluid
                  placeholder="Enter the name of company or brand you want to monitor (Add Keyword)"
                  onChange={(e) => this.handleKeywordChange(e)}
                  className="mb-2"
                />
                <Box component="span" sx={{ fontSize: "14px", color: "#1B1C1D" }}>
                  A new keyword can contain multiple words with space. Please use your unique Brand Name, Campaign Name,
                  Competitor Name for Brand Monitoring. Do not use generic keywords for Brand Monitoring.
                </Box>
              </div>
              <FPButton
                label="Submit"
                size="small"
                style={{ height: "37px" }}
                onClick={() => this.onKeywordSubmit()}
                className="float-right mt-4 px-5"
              />
              <Box sx={{ marginTop: "8px" }}>
                {error && <div style={{ color: "red" }}>Please enter a keyword value in order to proceed.</div>}
                {serverError && <div style={{ color: "red" }}>{serverError.keyword[0]}</div>}
              </Box>
            </CardWrapper>
          </form>
        )}

        {isAnySocialConnected === false && (
          <CardWrapper showDivider={false} className="px-4 py-2 mb-5">
            <div className="socialAccountHead mb-4">
              <span>Connect Social Accounts</span>
              <span>Connect your Social Media to track your Brand Mentions</span>
            </div>
            <div className="row container mx-auto">
              {connections.map((item, index) => (
                <div className="d-flex flex-column col-4 text-center mt-3" key={index}>
                  <div>
                    <img width={item.width} height={item.height} src={item.icon} alt={`${item.id} logo`} />
                  </div>
                  <Box component="span" className="mb-3 mt-2" sx={{ color: "#1B1C1D", fontSize: "14px" }}>
                    {item.id}
                  </Box>
                  <div className="mt-1">
                    {item.id === "Facebook" && (
                      <FacebookLogin
                        appId={process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_KEY}
                        fields="name,email,picture"
                        callback={this.responseFacebook}
                        render={(renderProps) => (
                          <FPButton
                            size="small"
                            onClick={renderProps.onClick}
                            backgroundColor="#F13A88"
                            textColor="white"
                          >
                            {isLoggedIn.facebook?.status === "connected" ? "Disconnect" : "Connect"}
                          </FPButton>
                        )}
                      />
                    )}
                    {item.id === "Instagram" && (
                      <InstagramLogin
                        appId={process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_KEY}
                        fields="name,email,picture"
                        scope="instagram_basic,pages_read_user_content,pages_manage_engagement,pages_read_engagement"
                        responseType='code'
                        enable_profile_selector={true}
                        state={process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_SECRET}
                        callback={this.responseInstagram}
                        render={(renderProps) => (
                          <Button className="ml-2" onClick={renderProps.onClick} style={{ background: "#8D44A1" }}>
                            Connect
                          </Button>
                        )}
                        version="21.0"
                      />
                    )}
                    {item.id === "Twitter" && (
                      <FPButton
                        size="small"
                        backgroundColor="#F13A88"
                        textColor="white"
                        onClick={() => this.responseTwitter()}
                      >
                        {isLoggedIn.twitter?.status === "connected" ? "Disconnect" : "Connect"}
                      </FPButton>
                    )}
                    {item.id === "Youtube" && (
                      <FPButton
                        size="small"
                        backgroundColor="#F13A88"
                        textColor="white"
                        onClick={() => this.responseYouTube()}
                      >
                        {isLoggedIn.youtube?.status === "connected" ? "Disconnect" : "Connect"}
                      </FPButton>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </CardWrapper>
        )}
      </>
    );
  }
}

export default AddKeywordForm;
