import React, {Fragment, useEffect, useState } from "react"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoPrintOutline } from "react-icons/io5";
import { FPToolTip } from "../../components/common/new";
import Radio from "@material-ui/core/Radio";
import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import AdsStackedGraph from "./AdsStackedGraph";
import { makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

const dinner_impression = {
  label: "Dinner",
  data: [],
  type: "bar",
  // barThickness: 18,
  borderRadius: 3,
  backgroundColor: "#e56377",
  borderColor: '#e56377',
  legendColor: '#e56377',
  fill: { target: "origin" },
  categorySpacing: 10,
  stack: "stack 0"
};

const dinner_roi = {
  label: "Dinner",
  data: [],
  type: "bar",
  // barThickness: 18,
  borderRadius: 3,
  backgroundColor: "rgb(229 99 119 / 52%)",
  borderColor: 'rgb(229 99 119 / 52%)',
  legendColor: 'rgb(229 99 119 / 52%)',
  stack: "stack 1",
  fill: { target: "origin", },
  yAxisID: "y-axis-2"
}

const lunch_impression = {
  label: "Lunch",
  data: [],
  type: "bar",
  // barThickness: 18,
  borderRadius: 3,
  backgroundColor: "#f0b967",
  hoverBackgroundColor: "#f0b967",
  legendColor: "#f0b967",
  stack: "stack 0"
}

const lunch_roi = {
  label: "Lunch",
  data: [],
  type: "bar",
  // barThickness: 18,
  borderRadius: 3,
  backgroundColor: "rgb(240 185 103 / 52%)",
  hoverBackgroundColor: "rgb(240 185 103 / 52%)",
  legendColor: "rgb(240 185 103 / 52%)",
  stack: "stack 1",
  yAxisID: "y-axis-2"
}

const snacks_impression = {
  label: "Snacks",
  data: [],
  type: "bar",
  // barThickness: 18,
  borderRadius: 3,
  backgroundColor: "#faf278",
  hoverBackgroundColor: "#faf278",
  legendColor: "#faf278",
  stack: "stack 0",
}

const snacks_roi = {
  label: "Snacks",
  data: [],
  type: "bar",
  // barThickness: 18,
  borderRadius: 3,
  backgroundColor: "rgb(250 242 120 / 53%)",
  hoverBackgroundColor: "rgb(250 242 120 / 53%)",
  legendColor: "rgb(250 242 120 / 53%)",
  stack: "stack 1",
  yAxisID: "y-axis-2"
}

const ln_impression = {
  label: "LN 1 (12-5 am)",
  data: [],
  type: "bar",
  // barThickness: 18,
  borderRadius: 3,
  backgroundColor: "#87d5f9",
  hoverBackgroundColor: "#87d5f9",
  legendColor: "#87d5f9",
  stack: "stack 0"
}

const ln_roi = {
  label: "LN 1 (12-5 am)",
  data: [],
  type: "bar",
  // barThickness: 18,
  borderRadius: 3,
  backgroundColor: "rgb(135 213 249 / 51%)",
  hoverBackgroundColor: "rgb(135 213 249 / 51%)",
  legendColor: "rgb(135 213 249 / 51%)",
  stack: "stack 1",
  yAxisID: "y-axis-2"
}

const ln_two_impression = {
  label: "LN 2 (11-12 pm)",
  data: [],
  type: "bar",
  // barThickness: 18,
  borderRadius: 3,
  backgroundColor: "#9ae66e",
  hoverBackgroundColor: "#9ae66e",
  legendColor: "#9ae66e",
  stack: "stack 0"
}

const ln_two_roi = {
  label: "LN 2 (11-12 pm)",
  data: [],
  type: "bar",
  borderRadius: 3,
  // barThickness: 18,
  backgroundColor: "rgb(154 230 110 / 51%)",
  hoverBackgroundColor: "rgb(154 230 110 / 51%)",
  legendColor: "rgb(154 230 110 / 51%)",
  stack: "stack 1",
  yAxisID: "y-axis-2"
}

const breakfast_impression = {
  label: "Breakfast",
  data: [],
  type: "bar",
  // barThickness: 18,
  borderRadius: 3,
  backgroundColor: "#ce81f8",
  hoverBackgroundColor: "#ce81f8",
  legendColor: "#ce81f8",
  stack: "stack 0"
}

const breakfast_roi = {
  label: "Breakfast",
  data: [],
  type: "bar",
  // barThickness: 18,
  borderRadius: 3,
  backgroundColor: "rgb(206 129 248 / 44%)",
  hoverBackgroundColor: "rgb(206 129 248 / 44%)",
  legendColor: "rgb(206 129 248 / 44%)",
  stack: "stack 1",
  yAxisID: "y-axis-2"
}

const AdsRoiTime = ({ adsRoiTimeSlotData, handleDownload }) => {
  const [activeSection, setActiveSection] = useState("overtime");
  const [view, setView] = useState("Graph");
  const [locationLabels, setLocationLabels] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [locationTableData, setLocationTableData] = useState([]);
  const [overtimeLabels, setOvertimeLabels] = useState([]);
  const [overtimeData, setOvertimeData] = useState([]);
  const [overtimeTableData, setOvertimeTableData] = useState([]);

  useEffect(() => { 
    if(adsRoiTimeSlotData) {

      // Overtime Labels data
      const overtime_labels = [];
      Object.keys(adsRoiTimeSlotData.overtime.dinner).map((val) => overtime_labels.push(val));
      setOvertimeLabels(overtime_labels);

      // Overtime Table Graph data conversion
      const overtime_graph_data = [];
      const overtime_dinner_impression = [];
      const overtime_dinner_roi = [];
      const overtime_lunch_impression = [];
      const overtime_lunch_roi = [];
      const overtime_snacks_impression = [];
      const overtime_snacks_roi = [];
      const overtime_ln_impression = [];
      const overtime_ln_roi = [];
      const overtime_ln_two_impression = [];
      const overtime_ln_two_roi = [];
      const overtime_breakfast_impression = [];
      const overtime_breakfast_roi = [];

      const overtime_table_data = [];

      overtime_labels.map((val, index) => {
        
        // Table
        const overtime_table = [];
        overtime_table.push({ 
          name: "Dinner",
          impression: adsRoiTimeSlotData.overtime.dinner[val].imp,
          roi : adsRoiTimeSlotData.overtime.dinner[val].roi,
        });

        overtime_table.push({ 
          name: "Lunch",
          impression: adsRoiTimeSlotData.overtime.lunch[val].imp,
          roi : adsRoiTimeSlotData.overtime.lunch[val].roi,
        });

        overtime_table.push({ 
          name: "Snacks",
          impression: adsRoiTimeSlotData.overtime.snacks[val].imp,
          roi : adsRoiTimeSlotData.overtime.snacks[val].roi,
        });

        overtime_table.push({ 
          name: "LN 1 (12-5 am)",
          impression: adsRoiTimeSlotData.overtime.ln1[val].imp,
          roi : adsRoiTimeSlotData.overtime.ln1[val].roi,
        });

        overtime_table.push({ 
          name: "LN 2 (11-12 pm)",
          impression: adsRoiTimeSlotData.overtime.ln2[val].imp,
          roi : adsRoiTimeSlotData.overtime.ln2[val].roi,
        });

        overtime_table.push({ 
          name: "Breakfast",
          impression: adsRoiTimeSlotData.overtime.breakfast[val].imp,
          roi : adsRoiTimeSlotData.overtime.breakfast[val].roi,
        });
        
        overtime_table_data.push({
          id: index,
          date: val,
          data: overtime_table
        })

        //Graph
        overtime_dinner_impression.push(adsRoiTimeSlotData.overtime.dinner[val].imp);
        overtime_dinner_roi.push(adsRoiTimeSlotData.overtime.dinner[val].roi);
        overtime_lunch_impression.push(adsRoiTimeSlotData.overtime.lunch[val].imp);
        overtime_lunch_roi.push(adsRoiTimeSlotData.overtime.lunch[val].roi);
        overtime_snacks_impression.push(adsRoiTimeSlotData.overtime.snacks[val].imp);
        overtime_snacks_roi.push(adsRoiTimeSlotData.overtime.snacks[val].roi);
        overtime_ln_impression.push(adsRoiTimeSlotData.overtime.ln1[val].imp);
        overtime_ln_roi.push(adsRoiTimeSlotData.overtime.ln1[val].roi);
        overtime_ln_two_impression.push(adsRoiTimeSlotData.overtime.ln2[val].imp);
        overtime_ln_two_roi.push(adsRoiTimeSlotData.overtime.ln2[val].roi);
        overtime_breakfast_impression.push(adsRoiTimeSlotData.overtime.breakfast[val].imp);
        overtime_breakfast_roi.push(adsRoiTimeSlotData.overtime.breakfast[val].roi);

        return val;
      });

      overtime_graph_data.push({...dinner_impression, data: overtime_dinner_impression });
      overtime_graph_data.push({...dinner_roi, data: overtime_dinner_roi });
      overtime_graph_data.push({...lunch_impression, data: overtime_lunch_impression });
      overtime_graph_data.push({...lunch_roi, data: overtime_lunch_roi });
      overtime_graph_data.push({...snacks_impression, data: overtime_snacks_impression });
      overtime_graph_data.push({...snacks_roi, data: overtime_snacks_roi });
      overtime_graph_data.push({...ln_impression, data: overtime_ln_impression });
      overtime_graph_data.push({...ln_roi, data: overtime_ln_roi });
      overtime_graph_data.push({...ln_two_impression, data: overtime_ln_two_impression });
      overtime_graph_data.push({...ln_two_roi, data: overtime_ln_two_roi });
      overtime_graph_data.push({...breakfast_impression, data: overtime_breakfast_impression });
      overtime_graph_data.push({...breakfast_roi, data: overtime_breakfast_roi });

      setOvertimeData(overtime_graph_data);
      setOvertimeTableData(overtime_table_data);

      // Location Labels data
      const location_labels = [];
      Object.keys(adsRoiTimeSlotData.location.dinner).map((val) => location_labels.push(val));
      setLocationLabels(location_labels);

      // Location Table Graph data conversion
      const location_graph_data = [];
      const location_dinner_impression = [];
      const location_dinner_roi = [];
      const location_lunch_impression = [];
      const location_lunch_roi = [];
      const location_snacks_impression = [];
      const location_snacks_roi = [];
      const location_ln_impression = [];
      const location_ln_roi = [];
      const location_ln_two_impression = [];
      const location_ln_two_roi = [];
      const location_breakfast_impression = [];
      const location_breakfast_roi = [];

      const location_table_data = [];

      location_labels.map((val, index) => {
        
        // Table
        const location_table = [];
        location_table.push({ 
          name: "Dinner",
          impression: adsRoiTimeSlotData.location.dinner[val].imp,
          roi : adsRoiTimeSlotData.location.dinner[val].roi,
        });

        location_table.push({ 
          name: "Lunch",
          impression: adsRoiTimeSlotData.location.lunch[val].imp,
          roi : adsRoiTimeSlotData.location.lunch[val].roi,
        });

        location_table.push({ 
          name: "Snacks",
          impression: adsRoiTimeSlotData.location.snacks[val].imp,
          roi : adsRoiTimeSlotData.location.snacks[val].roi,
        });

        location_table.push({ 
          name: "LN 1 (12-5 am)",
          impression: adsRoiTimeSlotData.location.ln1[val].imp,
          roi : adsRoiTimeSlotData.location.ln1[val].roi,
        });

        location_table.push({ 
          name: "LN 2 (11-12 pm)",
          impression: adsRoiTimeSlotData.location.ln2[val].imp,
          roi : adsRoiTimeSlotData.location.ln2[val].roi,
        });

        location_table.push({ 
          name: "Breakfast",
          impression: adsRoiTimeSlotData.location.breakfast[val].imp,
          roi : adsRoiTimeSlotData.location.breakfast[val].roi,
        });
        
        location_table_data.push({
          id: index,
          date: val,
          data: location_table
        })

        //Graph
        location_dinner_impression.push(adsRoiTimeSlotData.location.dinner[val].imp);
        location_dinner_roi.push(adsRoiTimeSlotData.location.dinner[val].roi);
        location_lunch_impression.push(adsRoiTimeSlotData.location.lunch[val].imp);
        location_lunch_roi.push(adsRoiTimeSlotData.location.lunch[val].roi);
        location_snacks_impression.push(adsRoiTimeSlotData.location.snacks[val].imp);
        location_snacks_roi.push(adsRoiTimeSlotData.location.snacks[val].roi);
        location_ln_impression.push(adsRoiTimeSlotData.location.ln1[val].imp);
        location_ln_roi.push(adsRoiTimeSlotData.location.ln1[val].roi);
        location_ln_two_impression.push(adsRoiTimeSlotData.location.ln2[val].imp);
        location_ln_two_roi.push(adsRoiTimeSlotData.location.ln2[val].roi);
        location_breakfast_impression.push(adsRoiTimeSlotData.location.breakfast[val].imp);
        location_breakfast_roi.push(adsRoiTimeSlotData.location.breakfast[val].roi);

        return val;
      });

      location_graph_data.push({...dinner_impression, data: location_dinner_impression });
      location_graph_data.push({...dinner_roi, data: location_dinner_roi });
      location_graph_data.push({...lunch_impression, data: location_lunch_impression });
      location_graph_data.push({...lunch_roi, data: location_lunch_roi });
      location_graph_data.push({...snacks_impression, data: location_snacks_impression });
      location_graph_data.push({...snacks_roi, data: location_snacks_roi });
      location_graph_data.push({...ln_impression, data: location_ln_impression });
      location_graph_data.push({...ln_roi, data: location_ln_roi });
      location_graph_data.push({...ln_two_impression, data: location_ln_two_impression });
      location_graph_data.push({...ln_two_roi, data: location_ln_two_roi });
      location_graph_data.push({...breakfast_impression, data: location_breakfast_impression });
      location_graph_data.push({...breakfast_roi, data: location_breakfast_roi });

      setLocationData(location_graph_data);
      setLocationTableData(location_table_data);
    }

  }, [adsRoiTimeSlotData]);

  return <div>
      <div className="report-ThirdRow mb-4">
        <Nav tabs className="py-1 box-shadow-none" style={{ borderBottom: "none" }}>
          <Fragment>
            <NavItem>
              <NavLink
                className={classnames({ active: activeSection === "overtime" })}
                onClick={() => setActiveSection("overtime")}
              >
                Overtime
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeSection === "location" })}
                onClick={() => setActiveSection("location")}
              >
                Location
              </NavLink>
            </NavItem>
          </Fragment>
        </Nav>
      </div>

    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "gray" }}>
        <FormControlLabel
          value="Graph"
          label="Graph"
          control={<Radio />}
          onClick={() => setView("Graph")}
          checked={view === "Graph"}
        />
        <FormControlLabel
          value="Table"
          label="Table"
          control={<Radio />}
          onClick={() => setView("Table")}
          checked={view === "Table"}
        />
      </div>
      <div style={{ display: "flex", gap: "20px" }}>
        <FPToolTip title={"Download"} placement={"top"}>
          <MdOutlineFileDownload style={{ fontSize: "20px", cursor: "pointer" }} onClick={() => handleDownload('roi_time_slot', activeSection==='location' )} />
        </FPToolTip>
        <FPToolTip title={"Print"} placement={"top"}>
          <IoPrintOutline style={{ fontSize: "18px", cursor: "pointer" }} onClick={() =>  window.print()} />
        </FPToolTip>
      </div>
    </div>

    {activeSection === "overtime" && view === "Table" && <AdsEngagementTable dataList={overtimeTableData || []} />}
    {activeSection === "overtime" && view === "Graph" &&  <div className="mb-4" style={{ background: '#f5f5f57d', borderRadius: '20px', padding: '16px' }}>
      <AdsStackedGraph
        dataKeys={overtimeLabels}
        dataSets={overtimeData}
        primaryYLabel="Impressions"
        secondaryYLabel="ROI"
      />
    </div>}

    {activeSection === "location" && view === "Table" && <AdsEngagementTable dataList={locationTableData || []} />}
    {activeSection === "location" && view === "Graph" &&  <div className="my-4 " style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '15px'}}>
      <AdsStackedGraph
        dataKeys={locationLabels}
        dataSets={locationData}
        primaryYLabel="Impressions"
        secondaryYLabel="ROI"
      />
    </div>}
  </div>;
}


const AdsEngagementTable = ({ dataList }) => {
  const useStyles = makeStyles(theme => ({
    root: {},
    tableRightBorder: {
        borderWidth: 0,
        borderRightWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
    },
}));
  return <div className="my-4" >
  {dataList && Array.isArray(dataList) && dataList.length > 0 ? <div className="ui-table-responsive">
    <Table className="fp-table fp-table-center">
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Time Slot</TableCell>
          <TableCell>Impressions</TableCell>
          <TableCell>ROI</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {dataList.map((item, index) => {
          return item.data.map((val, i) => {
            return (
              <TableRow key={index}>
                {i===0 && <TableCell className={useStyles.tableRightBorder} rowSpan={6}>{item.date}</TableCell>}
                <TableCell className={useStyles.tableRightBorder}>{val.name}</TableCell>
                <TableCell className={useStyles.tableRightBorder}>{val.impression}</TableCell>
                <TableCell className={useStyles.tableRightBorder}>{val.roi}</TableCell>
              </TableRow>
            );
          })
        })}
      </TableBody>
    </Table>
  </div>
  : <h2 style={{ textAlign: 'center', color: 'gray'}}>No data found </h2>}
</div>
}

export default AdsRoiTime;