import React, { useState } from 'react';
import { Form, Formik, ErrorMessage, Field } from 'formik';
import { Card } from 'reactstrap';
import * as Yup from 'yup';
import { FormInput } from '../../../../components/common/new/forms';
import { FPButton } from '../../../../components/common/new';
import cardLogo from '../../../../images/card-logo.png';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import '../Generic/create-template.css';
import mobile from '../../../../images/mobileDesign.png';
import { FormControl, FormControlLabel, Radio, RadioGroup, FormLabel } from '@material-ui/core';


// Step 1: Name and Email Component
const Step1 = ({ onNext }) => (
  <div>
    <div className="position-relative d-flex justify-content-center">
      <img
        src={mobile}
        alt="mobileImg"
        className="mobileImg"
        style={{ width: '350px' }}
      />
      <div className="mobileContent">
        <Formik
          initialValues={{ name: '', email: '' }}
          validationSchema={Yup.object({
            name: Yup.string().required('Please enter your name'),
            email: Yup.string().email().required('Please enter your mail')
          })}
          onSubmit={(values) => {
            onNext(values);
          }}
        >
          <Form>
            <Card className="p-3">
              <img src={cardLogo} className="logo-card" />
              <div className="">
                <FormInput name="name" label="Name" />
                <FormInput name="email" label="Email" />
                <div className="d-flex justify-content-end nextBtn">
                  <FPButton
                    label="Next"
                    type="submit"
                    endIcon={
                      <ArrowForwardOutlinedIcon className="arrowBtn" />
                    }
                  />
                </div>
              </div>
            </Card>
          </Form>
        </Formik>
      </div>
    </div>    
  </div>
);

// Step 2: Message Component
const Step2 = ({onNext}) => (
  <div>
    <div className="position-relative d-flex justify-content-center">
      <img
        src={mobile}
        alt="mobileImg"
        className="mobileImg"
        style={{ width: '350px' }}
      />
      <div className="mobileContent">
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object({
            message: Yup.string().required('Please enter message')
          })}
          onSubmit={(values) => {
            onNext({ ...values });
          }}
        >
          {({ values, handleChange }) => (
            <Form>
              <Card className="p-3">
                <img src={cardLogo} className="logo-card" />
                <div className="reviewSection text-center">
                  <span>How was your experience ?</span>
                  <div className="starRating">Rating</div>
                  <p>Very Poor ........ Excellent</p>
                  <span style={{ textAlign: 'justify' }}>
                  Thank you for choosing us! Please take a moment to leave
                  us a review. Your responses will help us serve you better
                  in the future.
                  </span>
                  <br />
                  <div className="flex-column mt-3">
                    <label style={{ fontSize: '12px', display: 'flex' }}>
                    What would you tell others about your experience?
                    </label>
                    <FormControl value={values.option} onChange={handleChange} >
                      <Field name="message" as="textarea" rows={4} cols={34} />
                      <p className='errMsg'><ErrorMessage name="message" /></p>
                    </FormControl>
                  </div>
                  <div className="d-flex justify-content-end nextBtn mt-4">
                    <FPButton
                      label="Next"
                      type="submit"
                      endIcon={
                        <ArrowForwardOutlinedIcon className="arrowBtn" />
                      }
                    />
                  </div>
                </div>
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  </div>
);


// Step 3: Option Component
const Step3 = ({onNext}) => (
  <div>
    <div className="position-relative d-flex justify-content-center">
      <img
        src={mobile}
        alt="mobileImg"
        className="mobileImg"
        style={{ width: '350px' }}
      />
      <div className="mobileContent">
        <Formik
          initialValues={{ option: '' }}
          validationSchema={Yup.object({
            option: Yup.string().required('Please select an option'),
          })}
          onSubmit={(values) => {
            console.log('Selected option:', values.option);
            onNext({ ...values });
          }}
        >
          {({values, handleChange}) => (
            <Form>
              <Card className="p-3">
                <img src={cardLogo} className="logo-card" />
                <div>
                  <FormControl component="fieldset">
                    <FormLabel className='radio-formLabel'>Food Quality</FormLabel>
                    <RadioGroup name="option" value={values.option} onChange={handleChange} style={{color: 'rgba(0, 0, 0, 0.54)'}}>
                      <FormControlLabel value="Good" control={<Radio />} label="Good" />
                      <FormControlLabel value="Average" control={<Radio /> } label="Average" />
                      <FormControlLabel value="Bad" control={<Radio />} label="Bad" />
                    </RadioGroup>
                    <p className='errMsg'><ErrorMessage name="option" /></p>
                  </FormControl>
                  <div className="d-flex justify-content-end nextBtn mt-4">
                    <FPButton
                      label="Submit"
                      type="submit"
                    />
                  </div>
                </div>
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  </div>
);


// Step 4: Thanks Msg Component
const Step4 = ({ onClose }) => (
  <div className="position-relative d-flex justify-content-center">
    <img
      src={mobile}
      alt="mobileImg"
      className="mobileImg"
      style={{ width: '350px' }}
    />
    <div className="mobileContent">
      <Card className="p-3">
        <img src={cardLogo} className="logo-card" />
        <div className="text-center">
          <span className="my-3" style={{ padding: '18px 0px', fontSize: '14px'}}>
                      Thank You!
          </span>
          <p className="my-3" style={{fontSize: '14px'}}>
                      Thank you for your valuable time and feedback.
          </p>
        </div>
        <div className="d-flex justify-content-end nextBtn mt-2">
          <FPButton
            label="Close"
            type="submit"
            onClick={onClose}
          />
        </div>
      </Card>
    </div>
  </div>
);


const MultiStepForm = () => {
  const [step, setStep] = useState(1);

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleClose = () => {
    console.log('Form closed.');
  };

  return (
    <div>
      {step === 1 && <Step1 onNext={handleNext} />}
      {step === 2 && <Step2 onNext={handleNext}  />}
      {step === 3 && <Step3 onNext={handleNext} />}
      {step === 4 && <Step4 onClose={handleClose} />}
    </div>
  );
};

export default MultiStepForm;
