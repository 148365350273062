import React, { useState } from "react";
import moment from "moment";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import deleteIcon from "../../images/svg/trash.svg";
import editIcon from "../../images/svg/EditPen.svg";
import SortIcon from "../../images/svg/Sort.svg";
import ConfirmationModal from "../common/ConfirmationModal";
import FormModal from "../common/FormModal";
import "./SocialPostTable.css";
import PostModalContent from "./PostModalContent";
import add from "../../images/svg/plus-black.svg";
import minus from "../../images/svg/Subtract.svg";
import { FPToolTip } from "../common/new";
import { Link } from "react-router-dom";
import PaginationComponent from "../common/Pagination";

const SocialPostTable = (props) => {
  const { list_type, location,post_list, handlePostEdit, handlePostDelete, branchList } = props;

  const [isConfirm, setIsConfirm] = useState(false);
  const [deletePostId, setDeletePostId] = useState(null);
  const [postOrBranch, setPostOrBranch] = useState(null);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);
  const [selectedPost, setSelectedPost] = useState(null);
  const [value, setValue] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);

  const postHandler = (item, type) => {
    setPostOrBranch(type)
    setIsConfirm(!isConfirm);
    setDeletePostId(item);
  };

  const toggleConfirm = () => {
    handlePostDelete(deletePostId, postOrBranch);
    setIsConfirm(false);
    setIsPostModalOpen(false)
  };

  const handelSelectedPost = async (postData) => {
    setIsPostModalOpen(true);
    if (postData) {
      const branches =
        postData.branch_tags &&
        postData.branch_tags.map((tags, index) => {
          if (postData.branch_tags.length - 1 === index) {
            return tags.title;
          } else {
            return `${tags.title},`;
          }
        });

      const locationValue = { ...postData.branches };
      // const locations = branchList?.filter(
      //   (branch, i) => postData.branches.includes(branch.value) // Proper filtering
      // );
      const locations = postData?.branch_wise_post?.map((location) => ({
        display_name:location?.branch_name,
        value:location.id,
        is_posted : location.is_posted,
        api_error: location.api_error,
        api_error_msg: location.api_error_msg || "Hello",
        location : location
      }))
      let newPostData = {
        title: postData.title,
        id:postData.id,
        platform: postData.platform,
        post_type: postData.post_type,
        branch_tags: branches.join(""),
        branches: postData.branches,
        location: locations,
        posted_date: postData.posted_date
          ? moment(postData.posted_date).format("DD-MM-YYYY")
          : "",
        is_posted : postData.is_posted,
        image: postData.image,
      };

      setSelectedPost(newPostData);
    }
  };

  const handleTagSearch = (e, selectedPost) => {
    const searchKeyword = e?.target?.value?.toLowerCase() || "";
    setValue(searchKeyword);
    const matchedPost = post_list?.results?.find((post) => post.id === selectedPost.id);
    const locations = matchedPost?.branch_wise_post?.map((location) => ({
      display_name: location?.branch_name,
      value: location?.id,
      api_error: location.api_error,
      api_error_msg: location.api_error_msg,
      is_posted: location?.is_posted,
      location,
    })) || [];
    const filteredTags = locations?.filter((tag) =>
      tag?.display_name?.toLowerCase()?.includes(searchKeyword)
    );
    setSelectedPost((prev) => ({ ...prev, location: filteredTags }));
  };
  
    const toggleRowExpansion = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };
  const sort = "";
  //     ordering && ordering[0] === "-"
  //       ? { on: ordering.slice(1), by: "descending" }
  //       : { on: ordering, by: "ascending" };
  return (
    <div>
      <div className="ui-table-responsive mt-4">
        <Table className="fp-table fp-table-center">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                {/* <Checkbox
                  id="all"
                  checked={""}
                  // onChange={handleCheckbox}
                >
                  Select All
                </Checkbox> */}
                S.No.
              </TableCell>
              <TableCell>Title</TableCell>
              <TableCell className="text-nowrap">Post Type</TableCell>
              <TableCell>Platform</TableCell>
              {list_type === "scheduled" && (
                <TableCell className="text-nowrap">Schedule Date</TableCell>
              )}
              {list_type === "posted" && (
                <TableCell
                  className="text-nowrap"
                  // onClick={() => sortFunction("posted_date")}
                  // style={
                  //   sort.on === "posted_date"
                  //     ? sortChangeColor("#1f9095", "#d9f0f0")
                  //     : null
                  // }
                >
                  Posted Date <SortIcon />
                </TableCell>
              )}
              {list_type === "posted" && (
                <TableCell
                  className="text-nowrap"
                  // onClick={() => sortFunction("branch-tag")}
                  // style={
                  //   sort.on === "branch-tag"
                  //     ? sortChangeColor("#1f9095", "#d9f0f0")
                  //     : null
                  // }
                >
                  No. of Locations <SortIcon />
                </TableCell>
              )}
              <TableCell
                className="text-nowrap"
                // onClick={() => sortFunction("branch-tag")}
                // style={
                //   sort.on === "branch-tag"
                //     ? sortChangeColor("#1f9095", "#d9f0f0")
                //     : null
                // }
              >
                Branch Tags <SortIcon />
              </TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {post_list.results?.map((item, key) => {
              return (
                <React.Fragment key={key}>
                  <TableRow key={key} active={item.is_default}>
                    <TableCell>
                      {item?.branch_wise_post?.length > 0 &&
                        (expandedRow === key ? (
                          <img
                            src={minus}
                            alt="-"
                            onClick={() => toggleRowExpansion(key)}
                            className="cursor-pointer"
                          />
                        ) : (
                          <img
                            src={add}
                            alt="+"
                            onClick={() => toggleRowExpansion(key)}
                            className="cursor-pointer"
                          />
                        ))}
                    </TableCell>
                    <TableCell className="">
                      {/* <span className="tableSpecificDataBold">
                      <Checkbox label={key + 1} />
                    </span> */}
                      {key + 1}
                    </TableCell>
                    <TableCell>
                      <span
                        className={list_type === "posted" ? "templateName" : ""}
                        onClick={() => {
                          list_type === "posted" && handelSelectedPost(item);
                        }}
                      >
                        {item.title}
                      </span>
                    </TableCell>
                    <TableCell>{item.post_type}</TableCell>
                    <TableCell>{item.platform}</TableCell>
                    {list_type === "scheduled" && (
                      <TableCell>
                        {item.schedule_date
                          ? moment(item.schedule_date).format("DD-MM-YYYY")
                          : "-"}
                      </TableCell>
                    )}
                    {list_type === "posted" && (
                      <TableCell
                        className={`text-nowrap 
                        // ${sort.on === "posted_date" ? "sortCellStyle" : ""}`}
                        // onClick={() => sortFunction("posted_date")}
                      >
                        {/* <span
                        style={
                          sort.on === "posted_date"
                            ? sortChangeColor("#1f9095")
                            : null
                        }
                      ></span> */}
                        {item.posted_date
                          ? moment(item.posted_date).format("DD-MM-YYYY")
                          : "-"}
                      </TableCell>
                    )}
                    {list_type === "posted" && (
                      <TableCell
                        className={`text-nowrap templateName
                        // ${sort.on === "posted_date" ? "sortCellStyle" : ""}`}
                        onClick={() => {
                          handelSelectedPost(item);
                        }}
                      >
                        {item.branches ? item.branches.length : 0}
                      </TableCell>
                    )}
                    <TableCell
                    // onClick={() => sortFunction("branch-tag")}
                    >
                      {item.branch_tags &&
                        item.branch_tags.map((tags, index) => {
                          if (item.branch_tags.length - 1 === index) {
                            return tags.title;
                          } else {
                            return `${tags.title},`;
                          }
                        })}
                    </TableCell>
                    <TableCell>
                      <div className="actionButton">
                        <FPToolTip title={"Edit"}>
                          <img
                            src={editIcon}
                            onClick={(event) => {
                              handlePostEdit(event, item, "post");
                            }}
                            role="button"
                            alt="edit"
                          />
                        </FPToolTip>

                        <FPToolTip title={"Delete"}>
                          <img
                            src={deleteIcon}
                            onClick={() => postHandler(item, "post")}
                            role="button"
                            alt="delete"
                          />
                        </FPToolTip>
                      </div>
                    </TableCell>
                  </TableRow>
                  {expandedRow === key &&
                    item.branch_wise_post.map((branch, _index) => (
                      <TableRow
                        style={{ backgroundColor: "rgba(242, 249, 249, 1)" }}
                      >
                        <TableCell> </TableCell>
                        <TableCell>
                          {key + 1}.{_index + 1}
                        </TableCell>
                        <TableCell>
                          <span>{branch.title}</span>
                        </TableCell>
                        <TableCell>{branch.post_type}</TableCell>
                        <TableCell>{branch.platform}</TableCell>
                        {list_type === "scheduled" && (
                          <TableCell>
                            {branch.schedule_date
                              ? moment(branch.schedule_date).format(
                                  "DD-MM-YYYY"
                                )
                              : "-"}
                          </TableCell>
                        )}
                        {list_type === "posted" && (
                          <TableCell
                            className={`text-nowrap 
                        // ${sort.on === "posted_date" ? "sortCellStyle" : ""}`}
                          >
                            {branch.posted_date
                              ? moment(branch.posted_date).format("DD-MM-YYYY")
                              : "-"}
                          </TableCell>
                        )}
                        {list_type === "posted" && (
                          <TableCell
                        //     className={`text-nowrap templateName
                        // // ${sort.on === "posted_date" ? "sortCellStyle" : ""}`}
                        //     onClick={() => {
                        //       handelSelectedPost(branch);
                        //     }}
                          >
                            {branch.branch_name ? branch.branch_name : "-"}
                          </TableCell>
                        )}
                        <TableCell>
                          {branch.branch_tags &&
                            branch.branch_tags.map((tags, index) => {
                              if (branch.branch_tags.length - 1 === index) {
                                return tags.title;
                              } else {
                                return `${tags.title},`;
                              }
                            })}
                        </TableCell>
                        <TableCell>
                          <div className="actionButton">
                            <FPToolTip title={"Edit"}>
                              <img
                                src={editIcon}
                                onClick={(event) => {
                                  handlePostEdit(event, branch, "branch");
                                }}
                                role="button"
                                alt="edit"
                              />
                            </FPToolTip>

                            <FPToolTip title={"Delete"}>
                              <img
                                src={deleteIcon}
                                onClick={() => postHandler(branch, "branch")}
                                role="button"
                                alt="delete"
                              />
                            </FPToolTip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                </React.Fragment>
              );
            })}
            {post_list && !post_list?.results?.length && (
              <TableRow>
                <TableCell className="text-center" colSpan={7}>
                  No records Found{" "}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>

      <PaginationComponent
          count={post_list?.count}
          location={location}
          pageSizeCookie={"scheduler"}
        />

      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={`Are you sure you want to delete this ${list_type}`}
        isOpen={isConfirm}
        toggle={() => setIsConfirm(false)}
        onConfirm={() => toggleConfirm()}
      />

      <FormModal
        isOpen={isPostModalOpen}
        toggle={() => setIsPostModalOpen(false)}
        maxWidth={"lg"}
        width="100%"
        heading="Posted"
        showDivider={false}
        bodyClass="pb-0"
        rightToLeft={true}
        paddingDense={true}
      >
        <PostModalContent
          selectedPost={selectedPost}
          handlePostEdit={handlePostEdit}
          postHandler={postHandler}
          handleTagSearch={handleTagSearch}
          value={value}
        />
      </FormModal>
    </div>
  );
};

export default SocialPostTable;
