import React, { useEffect, useState } from "react";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import SetupGmailSocialAccounts from "../../components/business/SetupGmailAccount";
import SetupFBMsgAccounts from "../../components/business/SetupFBMsgAccounts";
import SetupGPSAccounts from "../../components/business/SetupGPSAccounts";
import SetupIASAccounts from "../../components/business/SetupIASAccount";
import SetupInstaAccount from "../../components/business/SetupInstaAccount";
import SetupWhatsappAccounts from "../../components/business/SetupWhatsappAccount";
import { socialPlatform } from "../../images";
import { Box } from "@material-ui/core";
import SetupGMBMessenger from "../business/SetupGMBMessenger";
// import LocationMapping from "./LocationMapping";
import ConnectedProfile from "../business/setupIntegration/ConnectedProfiles";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import LocationMapping from "./LocationMapping";
import SetupTwitterAccount from "../business/SetupTwitterAccount";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as SocialProfileActions from "../../actions/socialProfile/socialProfileActions";
import * as MentionsProfileActions from "../../actions/mentions/mentionsActions";
import SetupLinkedinAccount from "../business/SetupLinkedinAccount";
import LoaderComponent from "../common/loaderComponent";
import * as utils from "../../libs/utils";

const SetupIntegrationPage = ({
  businessInfo,
  branchList,
  business_id,
  socialProfileActions,
  socialProfile,
  getAuthUrl,
  mentionProfileActions,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [mainData, setMainData] = useState([]);
  const [connectedProvidersList, setConnectedProvidersList] = useState(null);
  const [filterOptions, setFilterOptions] = useState([]);
  const [sortOptions, setSortOptions] = useState([]);
  const [loaderConnectedProile, setLoaderConnectedProile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [connectedStatus, setConnectedStatus] = useState({
    gmail: 0,
    facebook: 0,
    instagram: 0,
    whatsappmessenger: 0,
    gpsaccounts: 0,
    gmbmessenger: 0,
    linkedin: 0,
  });

  const toggleTab = (tab) => {
    setActiveTab(tab);
  };

  const handleSocialApi = (res) => {
    const newData = res?.data;
    if (
      newData.connected_profiles &&
      Array.isArray(newData.connected_profiles)
    ) {
      setMainData(newData.connected_profiles);
      const connected_providers_ids = newData.connected_profiles.map(profile => profile.web_portal_id);
      setConnectedProvidersList(connected_providers_ids);
    }
    setFilterOptions(res?.data?.web_portal_list);
    setSortOptions(res?.data?.sort_by);
  };

  const handleConnectProfile = () => {
    const data = {
      business_id: business_id,
    };
    if (businessInfo && businessInfo.id) {
      socialProfileActions
        .getSocialProfile(data)
        .then((res) => {
          handleSocialApi(res);
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    callSocialProfileApi();
  }, []); //eslint-disable-line

  useEffect(() => {
    const data = {
      business_id: business_id,
    };
    if (businessInfo && businessInfo.id) {
      socialProfileActions
        .getSocialProfile(data)
        .then((res) => {
          handleSocialApi(res);
        })
        .catch((err) => console.log(err));
    }
  }, []); //eslint-disable-line

  const callSocialProfileApi = () => {
    setLoading(true);
    axios_instance
      .get(`${URLS.SOCIAL_PROFILE_INTEGRATION}?business_id=${business_id}`)
      .then((res) => {
        const profileCount = {};
        res.data &&
          Array.isArray(res.data) &&
          res.data.map((value) => {
            profileCount[value.provider] = value.no_of_profiles;
            return profileCount;
          });
        setConnectedStatus(profileCount);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleFilterApi = (filterSelected, sortSelected) => {
    setLoaderConnectedProile(true);
    axios_instance
      .get(
        `${URLS.SOCIAL_PROFILE_INTEGRATION}details/?business_id=${business_id}${
          filterSelected ? "&web_portal_id=" + filterSelected : ""
        }${sortSelected ? "&sort_by=" + sortSelected : ""}`
      )
      .then((res) => {
        handleSocialApi(res);
        setLoaderConnectedProile(false);
      })
      .catch((err) => {
        setLoaderConnectedProile(false);
        console.log(err);
      });
  };

  const connectSocial = async (data, business_id, provider) => {
    setLoading(true)
    await mentionProfileActions.postMentionsSocialCode(
      data.data,
      business_id,
      provider
    );
    utils.displayMessage("positive", `${provider} connected successfully`);
    setLoading(false)
  };

  const onConnect = async (provider) => {
    setLoading(true);
    const response = await mentionProfileActions.getAuthUrl(
      business_id,
      provider
    );
    const authUrl = response.auth_url;

    if (authUrl) {
      window.open(
        authUrl,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
      );

      const handleMessage = (event) => {
        connectSocial(event, business_id, provider);
        window.removeEventListener("message", handleMessage); // Remove after first execution
      };

      window.addEventListener("message", handleMessage);
    }
    setLoading(false);
  };

  return (
    <div>
      <LoaderComponent loader={loading} />
      <p className="mb-0 fp-card-header my-4">Setup Integration</p>
      <CardWrapper showDivider={false}>
        <Nav className="Setup_Sub_Nav">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              onClick={() => toggleTab("1")}
            >
              Connect New Profiles
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "2" })}
              onClick={() => toggleTab("2")}
            >
              Connected Profiles
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggleTab("3")}
            >
              Page Location Mapping
            </NavLink>
          </NavItem>
        </Nav>
        <div className="mt-4 mb-8">
          <TabContent activeTab={activeTab} className="mt-2">
            <TabPane tabId="1">
              <CardWrapper
                headerTitle="Connect New Profile"
                contentClass="p-4"
                className="bg-surface"
              >
                <Row className="mt-5">
                  <Col className="text-center" xs="4">
                    <SetupGmailSocialAccounts
                      business_id={businessInfo && businessInfo.id}
                      connectedStatus={connectedStatus.gmail}
                      handleConnectProfile={handleConnectProfile}
                      callSocialProfileApi={callSocialProfileApi}
                      toggleTab={() => toggleTab("2")}
                    />
                  </Col>
                  <Col className="text-center" xs="4">
                    <SetupFBMsgAccounts
                      business_id={businessInfo && businessInfo.id}
                      connectedStatus={connectedStatus.facebook}
                      handleConnectProfile={handleConnectProfile}
                      callSocialProfileApi={callSocialProfileApi}
                      toggleTab={() => toggleTab("2")}
                    />
                  </Col>
                  <Col className="text-center" xs="4">
                    <SetupInstaAccount
                      business_id={businessInfo && businessInfo.id}
                      connectedStatus={connectedStatus.instagram}
                      handleConnectProfile={handleConnectProfile}
                      callSocialProfileApi={callSocialProfileApi}
                      toggleTab={() => toggleTab("2")}
                      getAuthUrl={getAuthUrl}
                    />
                  </Col>
                  <Col className="text-center" xs="4">
                    <SetupWhatsappAccounts
                      business_id={businessInfo && businessInfo.id}
                      connectedStatus={connectedStatus.whatsappmessenger}
                      handleConnectProfile={handleConnectProfile}
                      callSocialProfileApi={callSocialProfileApi}
                      toggleTab={() => toggleTab("2")}
                    />
                  </Col>
                  <Col className="text-center" xs="4">
                    <SetupGPSAccounts
                      business_id={businessInfo && businessInfo.id}
                      connectedStatus={connectedStatus.gpsaccounts}
                      handleConnectProfile={handleConnectProfile}
                      callSocialProfileApi={callSocialProfileApi}
                      toggleTab={() => toggleTab("2")}
                    />
                  </Col>
                  <Col className="text-center" xs="4">
                    <SetupIASAccounts
                      business_id={businessInfo && businessInfo.id}
                      connectedStatus={connectedStatus.gpsaccounts}
                      handleConnectProfile={handleConnectProfile}
                      callSocialProfileApi={callSocialProfileApi}
                      toggleTab={() => toggleTab("2")}
                    />
                  </Col>
                </Row>
                <div className="social-card-container">
                  <SetupLinkedinAccount
                    business_id={businessInfo && businessInfo.id}
                    connectedStatus={connectedStatus.linkedin}
                    handleConnectProfile={handleConnectProfile}
                    callSocialProfileApi={callSocialProfileApi}
                    toggleTab={() => toggleTab("2")}
                  />
                  <SetupTwitterAccount
                    business_id={businessInfo && businessInfo.id}
                    connectedStatus={connectedStatus.twitter || 0}
                    handleConnectProfile={handleConnectProfile}
                    callSocialProfileApi={callSocialProfileApi}
                    toggleTab={() => toggleTab("2")}
                  />
                  {socialPlatform.map((item, index) => {
                    const provider_name = item.provider;
                    return (
                      <div key={index}>
                        <div
                          className="rounded-pill"
                          onClick={() => onConnect(item.provider)}
                          style={{
                            display: "flex",
                            height: "47px",
                            cursor: "pointer",
                            background: item.backgroundColor,
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "25px",
                          }}
                        >
                          <div className="d-flex align-items-center gap-10">
                            <img
                              src={item.logo}
                              alt={item.label}
                              height="25px"
                              width="25px"
                              style={{ padding: "4px" }}
                            />
                            <Box
                              component="p"
                              sx={{
                                color: "white",
                                fontSize: "16px",
                                fontWeight: "400",
                              }}
                            >
                              {item.label}
                            </Box>
                          </div>
                        </div>
                        <p
                          className="text-center text-underline mt-2"
                          style={{
                            cursor: "pointer",
                            fontWeight: "600",
                            fontSize: "14px",
                          }}
                          onClick={() => toggleTab("2")}
                        >
                          {connectedStatus[provider_name] ? connectedStatus[provider_name] : item.count} profiles already connected
                        </p>
                      </div>
                    );
                  })}
                </div>
              </CardWrapper>
            </TabPane>
            <TabPane tabId="2">
              <div
                className="py-4 px-2 mt-2 "
                style={{
                  width: "100%",
                  border: "2px solid #d3d3d321",
                  borderRadius: "10px",
                }}
              >
                <ConnectedProfile
                  data={mainData}
                  filterOptions={filterOptions}
                  handleFilterApi={handleFilterApi}
                  sortOptions={sortOptions}
                  loader={loaderConnectedProile}
                  callSocialProfileApi={callSocialProfileApi}
                  handleConnectProfile={handleConnectProfile}
                  socialProfile={socialProfile}
                />
              </div>
            </TabPane>
            <TabPane tabId="3">
              <LocationMapping
                data={mainData}
                branchList={branchList}
                business_id={businessInfo && businessInfo.id}
                connectedProvidersList={connectedProvidersList}
              />
            </TabPane>
          </TabContent>
        </div>
      </CardWrapper>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    businessInfo: state.business.detail,
    pageMapping: state.socialComments.pageLocation,
    socialProfile: state.socialProfile.details,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    socialProfileActions: bindActionCreators(SocialProfileActions, dispatch),
    mentionProfileActions: bindActionCreators(MentionsProfileActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SetupIntegrationPage);