/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import cookie from "react-cookies";
import { Header, Divider } from "semantic-ui-react";
// import { Progress } from 'antd';

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Card, CardTitle, Row, Col } from "reactstrap";
import * as commonAction from "../../actions/common/commonAction";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import * as complaintsActions from "../../actions/business/complaintsActions";
import ComplaintsList from "../../components/business/ComplaintsList";

import PaginationComponent from "../../components/common/Pagination";
// import ReviewsFilter from '../../components/business/ReviewsFilter';
import LoaderComponent from "../../components/common/loaderComponent";
import ComplaintsAnswer from "../../components/business/ComplaintsAnswer";
import axios_instance from "../../libs/interseptor";
import ComplaintFilter from "./ComplaintFilter";
import { NoData } from "../../components/common/new";
import { Box } from "@material-ui/core";
import "../../components/business/complaints.css";

class ComplaintsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      isComplainOpen: false,
      complaintAnswer: {},
      complaintDetail: null,
      isFetching: false,
      complaintStats: null,
      complaint_type: null,
    };
    this.getData = this.getData.bind(this);
    this.searchComplaints = this.searchComplaints.bind(this);
    this.handleReplyOption = this.handleReplyOption.bind(this);
    this.downloadExcel = this.downloadExcel.bind(this);
    this.complaintOpen = this.complaintOpen.bind(this);
    this.getComplaintStats = this.getComplaintStats.bind(this);
    this.getFilterData = this.getFilterData.bind(this);

    //PageSize Handling
    const pageSize = cookie.load("pageSize") || {};
    const {
      history,
      location: { pathname, search },
    } = props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        page_size: pageSize.complaints_page || 10,
      }),
      date_type: "prior_30_day",
    });
  }

  componentDidMount() {
    // eslint-disable-line
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.commonActions.replyOptions();
    this.props.commonActions.rationsOptions();
    this.props.actions.complaintSourceOptions({
      ...queryString.parse(search),
      businessId: business_id,
    });
    this.props.commonActions.cityOptions(business_id),
      this.props.commonActions.stateOptions(business_id),
      this.props.reviewsActions.reviewSourceOptions({ business_id }),
      this.getComplaintStats(search);
    this.setState({ isFetching: true });
    this.getData({ ...queryString.parse(search), businessId: business_id })
      .then((res) => {
        this.setState({ isFetching: false });
      })
      .catch((err) => {
        this.setState({ isFetching: false });
        this.setState({ complaintAnswer: {} });
        console.log(err);
      });

    //Set Title
    document.title = "Complaints";
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-line
    const {
      location: { search: prevSearch },
      match: { params: prevParams },
    } = this.props;
    const {
      location: { search: nextSearch },
      match: { params: nextParams },
    } = nextProps;
    if (prevSearch !== nextSearch) {
      this.getComplaintStats(nextSearch);
      this.getData({
        ...queryString.parse(nextSearch),
        businessId: nextParams.business_id,
      })
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }

  getData(data) {
    let dt = { ...data };
    delete dt.businessId;
    return this.props.actions.complaintsActions(dt, data.businessId);
  }

  getComplaintStats(search) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    axios_instance
      .get(`${URLS.REVIEWS_DETAILS}${business_id}/reviews-complaints-statistics/${search}`)
      .then((res) => {
        this.setState({ complaintStats: res.data });
        const dat = res.data.map((test) => {
          return {
            value: test.complaint_type,
            display_name: test.complaint_type.replace(/_/g, " "),
          };
        });
        this.setState({ complaint_type: dat });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getFilterData(searchQuery) {
    this.getData(searchQuery)
    
    delete searchQuery.businessId
    const fullQueryString = "?"+queryString.stringify(searchQuery)
    this.getComplaintStats(fullQueryString)
  }

  searchComplaints(queryparams) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.getData({ ...queryparams, businessId: business_id });
  }

  handleReplyOption(status, id) {
    const data = { status: status };
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.reviewsActions
      .statusUpdate(id, data)
      .then(() => {
        this.getData({ ...queryString.parse(search), businessId: business_id });
      })
      .catch((err) => {
        utils.displayMessage("negative", err.status);
      });
  }
  downloadExcel() {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const url = `${URLS.REVIEWS_DETAILS}${business_id}/reviews-complaint/download/${search}`;
    return this.props.reviewsActions.downloadTemplate(url, "famepilot-complaint-report.csv");
  }

  complaintOpen(data) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.setState({
      isLoader: true,
    });
    axios_instance
      .get(`${utils.format(URLS.COMPLAINT_DETAILS, [business_id])}${data.id}/`)
      .then((res) => {
        this.setState({
          isLoader: false,
        });
        this.setState({ complaintAnswer: res?.data, isComplainOpen: true });
      })
      .catch((err) => {
        console.log("errorsss",err);
      });
  }

  render() {
    const {
      data,
      match: {
        params: { business_id },
      },
      replyOptions,
      reviewSourceOptions,
      title,
      location: { search },
    } = this.props;
    const complaintList = data;
    const { complaintStats, complaint_type } = this.state;
    const sourceOptions = [];
    reviewSourceOptions &&
      reviewSourceOptions.choices.map((value) => {
        if (value.display_name === "Swiggy") {
          sourceOptions.push(value);
        } else if (value.display_name === "Zomato") {
          sourceOptions.push(value);
        }
      });
      const totalComplaints = complaintStats?.reduce((sum, complaint) => sum + complaint.count, 0);
    return (
      <div>
        <Row className="content-area dashboard-page">
          <LoaderComponent loader={this.props.loader} />
          <Col xs={12} sm={12} className="business-setup  mb-1">
            <Row>
              <Col xs={12} sm={12} md={12} className="mt-3 business-setup">
                <ComplaintFilter
                  history={this.props.history}
                  match={this.props.match}
                  downloadExcel={this.downloadExcel}
                  reviewSourceOptions={sourceOptions}
                  replyOptions={replyOptions && replyOptions.choices}
                  complaintTypes={complaint_type}
                  pageSizeCookie="complaints_page"
                  getData={(query) => this.getFilterData(query)}
                />
              </Col>
            </Row>
            {complaintList && complaintStats && complaintStats.length > 0 ? (
              <div
                className="complaint-grid mt-3"
                style={{
                  background: "rgb(220 220 220 / 3%)",
                  padding: "5px 0px 10px 0px",
                  marginBottom: "5px",
                }}
              >
                {totalComplaints && totalComplaints > 0 ? (
                  <Box
                    className="complaint-info-card fp-card-primary-elevation"
                    sx={{
                      backgroundColor: "#FDF2F7",
                    }}
                  >
                    <Box className="complaint-card-text">Total</Box>
                    <Box
                      sx={{
                        border: "0.1px solid #FCFCFD",
                        margin: "14px auto",
                      }}
                    ></Box>
                    <div className="d-flex justify-content-between">
                      <p className="complaint-card-num">{totalComplaints ? totalComplaints : 0}</p>
                    </div>
                  </Box>
                ) : (
                  ""
                )}
                {complaintStats &&
                  complaintStats.map((type, index) => {
                    return (
                      <div key={index} className="complaint-info-card fp-card-primary-elevation">
                        <Box className="complaint-card-text">{type.complaint_type.replace(/_/g, " ")}</Box>
                        <Divider className="my-3" />
                        <div className="align-center justify-content-between">
                          <p className="complaint-card-num">{type.count}</p>
                          {complaintList && (
                            <span
                              style={{
                                fontSize: "11px",
                                background: "#F2F5FE",
                                width: "26%",
                                height: "27px",
                                borderRadius: "25px",
                                color: "#2375EF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontWeight: 500,
                              }}
                            >
                              {(type.count ? Math.floor((type.count / complaintList.count) * 100) : "0") + "%"}
                            </span>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              !this.props.loader && <NoData title={title} />
            )}

            <Row className="mt-1">
              <Col sm="4" className="comp-left_side_bar">
                {complaintList && (
                  <ComplaintsList
                    businessId={business_id}
                    complaintList={complaintList}
                    toggle={this.complaintOpen}
                    handleComplaint={() =>
                      this.setState({
                        complaintAnswer: {},
                        isComplainOpen: false,
                      })
                    }
                  />
                )}
              </Col>
              <Col sm="8" className="right_side_bar ms-2" style={{ marginTop: "7px" }}>
                <div className="right-sidebar-content">
                  {this.state.isComplainOpen && (
                    <ComplaintsAnswer
                      complaint={this.state.complaintAnswer}
                      complaintOpen={this.complaintOpen}
                      isLoader={this.state.isLoader}
                      getData={this.getData}
                      search={search}
                      businessId={business_id}
                    />
                  )}
                </div>
              </Col>
            </Row>
            {complaintList && complaintList.count > 0 && (
              <Row>
                <Col sm="12">
                  <PaginationComponent
                    count={complaintList && complaintList.count}
                    location={this.props.location}
                    pageSizeCookie="complaints_page"
                    complaintPage={true}
                  />
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  data: state.complaints.list,
  replyOptions: state.common.reply_options,
  complaintSourceOptions: state.complaints.sources,
  loader: state.common.loader,
  businessInfo: state.business.detail,
  stateOptions: state.common.state_options,
  cityOptions: state.common.city_options,
  reviewSourceOptions: state.review.review_source_options,
});

ComplaintsPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
  cityOptions: PropTypes.instanceOf(Object),
  stateOptions: PropTypes.instanceOf(Object),
  replyOptions: PropTypes.instanceOf(Object),
  complaintSourceOptions: PropTypes.instanceOf(Object),
  reviewSourceOptions: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(complaintsActions, dispatch),
    commonActions: bindActionCreators(commonAction, dispatch),
    businessActions: bindActionCreators(BusinessSetupActions, dispatch),
    reviewsActions: bindActionCreators(ReviewsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ComplaintsPage);
