import React, { useEffect, useState } from "react";
import { Card, Row, Col, Button } from "reactstrap";
import { Feed, Divider, Icon } from "semantic-ui-react";
import CustomStarRatingComponent from "../../components/common/StarComponent";
import { CSSTransitionGroup } from "react-transition-group";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import { ActivityLogComponent } from "../../components/tickets/ActivityLog/ActivityLogComponent";
import CommentCard from "../../components/common/CommentCardComponent";
import moment from "moment";
import DefaultUserIcon from "../../images/boy.png";
import LoaderComponent from "../../components/common/loaderComponent";
import RecordView from "../../components/common/Recorder";
import { MentionsInput, Mention } from "react-mentions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as ticketActions from "../../actions/ticket/ticketActions";

/* eslint-disable quotes */
const AssignTicketPage = (props) => {
  const [ticketDetails, setTicketDetails] = useState({});
  const [loading, setloading] = useState(true);
  const [teamList, setTeamList] = useState(null);
  const [description, setDescription] = useState("");
  const [disableAudio, setDisableAudio] = useState(false);
  const [disableComment, setDisableComment] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const ticket_id = urlParams.get("ticket_id");
  const business_id = urlParams.get("business_id");

  const getTicketDetails = () => {
    // get ticket id from query string
    document.title = "Ticket";
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const ticket_id = urlParams.get("ticket_id");
    const business_id = urlParams.get("business_id");

    return axios_instance
      .get(`${URLS.TICKET}${business_id}/${ticket_id}/`)
      .then((res) => {
        setTicketDetails(res.data);
        document.title = "Ticket - #" + res.data.ticket_id.split("#")[1];
        setTimeout(() => {
          setloading(false);
        }, 500);
      })
      .catch((err) => {
        console.log(err);
        setloading(false);
      });
  };

  const fetchTeamList = () => {
    return axios_instance
      .get(URLS.AGENCY_USERS + "?business_id=" + business_id)
      .then((res) => {
        setTeamList(res.data.user_list);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInputChange = (e, description) => {
    if (description.length > 0) {
      setDisableAudio(true);
    } else {
      setDisableAudio(false);
    }
    setDescription(description);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const addCommentHandler = async () => {
    setDisableAudio(false);
    const formData = new FormData();
    if (selectedFile) {
      formData.append("attached_file", selectedFile, selectedFile.name);
    }
    if (description !== undefined) {
      formData.append("description", description);
    } else {
      formData.append("description", "");
    }
    return props.ticketActions.addComment(formData, business_id, ticket_id).then(() => {
      setDescription("");
      setSelectedFile(null);
      getTicketDetails();
    });
  };

  const enableInput = () => {
    setDisableComment(!disableComment);
  };

  useEffect(() => {
    fetchTeamList();
    getTicketDetails();
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <LoaderComponent loader={loading} />
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            maxWidth: "60%",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              padding: "5px 10px",
              background: "#f2f2f2",
            }}
          >
            {ticketDetails && ticketDetails.ticket_id}
            <Button className="float-right ml-3" style={{ padding: "6px" }} color="blue">
              {ticketDetails.status === "resolved" ? "Reopen Ticket" : "Close Ticket"}
              <i className={`ml-2 fa fa-${ticketDetails.status === "resolved" ? "lock-open" : "lock"}`} />
            </Button>
            <span className="float-right text-capitalize">
              <i
                className="fa fa-circle mr-1"
                style={{ color: `${ticketDetails.status === "assigned" ? "green" : "red"}` }}
              />
              {`${ticketDetails.status}`}
            </span>
          </div>
          <Card
            body
            className="py-2 px-3 mt-2 review-border-blue"
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Row className="pl-3">
              <Feed.Label className="review-time">
                {ticketDetails && ticketDetails.source_data && ticketDetails.source_data.review_type === "star" && (
                  <div className="rating">
                    <CustomStarRatingComponent
                      name="rate1"
                      starCount={5}
                      className="align-bottom"
                      starColor="#FBBD08"
                      emptyStarColor="#FFFFFF"
                      value={+ticketDetails.source_data.rating}
                      editing={false}
                    />
                  </div>
                )}
                {ticketDetails && ticketDetails.source_data && ticketDetails.source_data.review_type === "thumb" && (
                  <Icon
                    color="teal"
                    size="large"
                    name={ticketDetails.source_data.is_thum_up ? "thumbs up" : "thumbs down"}
                  />
                )}
                {ticketDetails && ticketDetails.source_data && ticketDetails.source_data.review_type === "smiley" && (
                  <Icon color="teal" size="large" />
                )}
              </Feed.Label>
              <Feed.Content>
                <Feed.Date>
                  <span className="text-dark " style={{ fontSize: "13px", fontWeight: "bold", letterSpacing: "0" }}>
                    {ticketDetails.branch}
                  </span>
                  {ticketDetails && ticketDetails.source_data && ticketDetails.source_data.created_at && (
                    <span className="d-block">{moment(ticketDetails.source_data.created_at).format("llll")}</span>
                  )}
                </Feed.Date>
              </Feed.Content>
              {ticketDetails && ticketDetails.source_data && ticketDetails.source_data.provider && (
                <Col>
                  <img
                    src={ticketDetails.source_data.provider}
                    alt=""
                    className="float-right cursor-pointer"
                    height="25"
                    width="25"
                  />
                </Col>
              )}
            </Row>
            <Row className="mt-3">
              <div className="ui feed ml-3">
                <div className="event">
                  <div className="label">
                    <img
                      src={(ticketDetails.source_data.reviewer || {}).image || DefaultUserIcon}
                      alt={(ticketDetails.source_data.reviewer || {}).name}
                    />
                  </div>
                  <div className="ml-2">
                    <strong>
                      {ticketDetails.source_data && ticketDetails.source_data.provider_name === "inhouse"
                        ? ticketDetails.source_data.reviewer.name && ticketDetails.source_data.reviewer.name.toString()
                        : (ticketDetails.source_data.provider || {}).provider_name === "swiggy"
                        ? ticketDetails.source_data.order_id
                        : (ticketDetails.source_data.provider || {}).provider_name === "famepilot"
                        ? ticketDetails.source_data.unique_code
                        : (ticketDetails.customer || {}).name}
                    </strong>
                    {ticketDetails.source_data.order_id !== null && ticketDetails.source_data.order_id !== "" && (
                      <div className="" style={{ display: "block" }}>
                        <Icon name="file alternate outline" />
                        <span style={{ fontWeight: "normal", color: "gray", fontStyle: "normal", fontSize: "12px" }}>
                          &nbsp;{ticketDetails.source_data.order_id}
                        </span>
                      </div>
                    )}
                  </div>
                  {ticketDetails.source_data.provider_name === "famepilot" && (
                    <div className="mt-2 ml-2">
                      {Object.keys(ticketDetails.source_data.reviewer).length &&
                        Object.keys(ticketDetails.source_data.reviewer).map((data, index) => {
                          return (
                            <span key={index}>
                              {
                                <span style={{ color: "gray", fontStyle: "normal", fontSize: "14px" }}>
                                  {ticketDetails.source_data.reviewer[data] && (
                                    <span>&nbsp;|&nbsp;{ticketDetails.source_data.reviewer[data]}</span>
                                  )}
                                </span>
                              }
                            </span>
                          );
                        })}
                    </div>
                  )}
                  {["offline", "customercare"].includes(ticketDetails.source_data.provider_name) && (
                    <div className="mt-2">
                      {ticketDetails.source_data.reviewer.phone &&
                        ticketDetails.source_data.reviewer.phone !== "None" && (
                          <span style={{ color: "gray", fontStyle: "normal", fontSize: "14px" }}>
                            &nbsp;|&nbsp;{ticketDetails.source_data.reviewer.phone}
                          </span>
                        )}
                      {ticketDetails.source_data.reviewer.email && (
                        <span style={{ fontWeight: "normal", color: "gray", fontStyle: "normal", fontSize: "14px" }}>
                          &nbsp;|&nbsp;{ticketDetails.source_data.reviewer.email}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Row>
            {/* <Row className="mt-3">
              <div className="ui feed ml-3">
                <div className="event">
                  <div className="label">
                    <img src={(ticketDetails && ticketDetails.source_data && ticketDetails.source_data.reviewer && ticketDetails.source_data.reviewer.image) || DefaultUserIcon} alt={ticketDetails && ticketDetails.source_data && ticketDetails.source_data.reviewer && ticketDetails.source_data.reviewer.name} height="35" width="35" />
                  </div>
                  <div className="mt-2 ml-2">
                    <strong>{(ticketDetails && ticketDetails.source_data && ticketDetails.source_data.provider || {}).provider_name === 'swiggy' ? ticketDetails.source_data.order_id : ((ticketDetails && ticketDetails.source_data && ticketDetails.source_data.provider || {}).provider_name === 'famepilot' ? ticketDetails.source_data.unique_code : (ticketDetails.customer || {}).name)}</strong>
                  </div>
                </div>
              </div>
            </Row> */}
            <Row className="content content-break mt-3">
              <Col sm="11">{ticketDetails.description}</Col>
              <Col
                sm={12}
                xs={12}
                className="px-3 center-position"
                style={{
                  marginTop: "15px",
                }}
              >
                {ticketDetails && ticketDetails.review_tags && ticketDetails.review_tags.length > 0 && (
                  <div style={{ color: "gray", display: "flex", alignItems: "center" }}>
                    {ticketDetails.review_tags.length > 0 && (
                      <div style={{ width: "250px" }}>
                        <Icon name="tag"></Icon>Tags: &nbsp;
                      </div>
                    )}
                    <CSSTransitionGroup
                      transitionName="reviews-tags"
                      transitionEnterTimeout={500}
                      transitionLeaveTimeout={500}
                    >
                      {ticketDetails.review_tags.map((tag, index) => {
                        return (
                          <div key={index} style={{ display: "inline-block" }}>
                            <span
                              style={{
                                padding: "4px 10px",
                                marginBottom: "10px",
                                background: "#ebebeb",
                                borderRadius: "10px",
                              }}
                              className="mr-2 cursor-pointer align-center"
                            >
                              <span style={{ padding: "0 0 3px 0" }}>{tag}</span>&nbsp;&nbsp;
                            </span>
                          </div>
                        );
                      })}
                      {ticketDetails.tag &&
                        ticketDetails.tag.map((tag, index) => {
                          return (
                            <div key={index} style={{ display: "inline-block" }}>
                              <span
                                style={{
                                  padding: "4px 10px",
                                  marginBottom: "10px",
                                  background: "#ebebeb",
                                  borderRadius: "10px",
                                }}
                                className="mr-2 cursor-pointer align-center"
                              >
                                <span style={{ padding: "0 0 3px 0" }}>{tag}</span>&nbsp;&nbsp;
                              </span>
                            </div>
                          );
                        })}
                    </CSSTransitionGroup>
                  </div>
                )}
              </Col>
            </Row>
          </Card>
          <Divider />
          {/* <Row> */}
          {ticketDetails && ticketDetails.action_logs && ticketDetails.action_logs.length > 0 && (
            <ActivityLogComponent logs={ticketDetails.action_logs} />
          )}
          {/* </Row> */}
          <Divider />
          {ticketDetails.status !== "resolved" ? (
            <Row className="mt-3">
              <Col sm="7" className="mb-0">
                <MentionsInput
                  value={description}
                  onChange={handleInputChange}
                  placeholder="Write a comment. Use @ to tag team members."
                  className="comments-textarea"
                  disabled={disableComment}
                >
                  <Mention
                    trigger="@"
                    markup="@{{__user__||__id__||__display__}}"
                    data={teamList && teamList.map((i) => ({ id: i.id, display: i.first_name + " " + i.last_name }))}
                    className="comments-textarea__mention"
                    displayTransform={(id, display) => `@${display}`}
                    appendSpaceOnAdd
                  />
                </MentionsInput>
                {selectedFile && <span>{selectedFile.name}</span>}
              </Col>
              <Col sm="5">
                <Row className="mt-3">
                  <label htmlFor="comment_attachement">
                    <Icon name="attach" size="large" style={{ margin: "8px 5px" }}></Icon>
                  </label>
                  <input id="comment_attachement" type="file" style={{ display: "none" }} onChange={handleFileChange} />
                  <Button className="float-right blue" onClick={addCommentHandler} disabled={disableComment}>
                    Comment
                  </Button>
                  {disableAudio == false && (
                    <RecordView
                      businessId={business_id}
                      singleTicketDetails={ticketDetails}
                      addComment={addCommentHandler}
                      ticketId={ticketDetails.id}
                      enableInput={enableInput}
                    />
                  )}
                </Row>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col sm="12" className="text-center">
                Reopen Ticket to enable commenting.
              </Col>
            </Row>
          )}
          {ticketDetails && ticketDetails.comment && ticketDetails.comment.length > 0 && (
            <div style={{ height: "200px", overflowY: "scroll", overflowX: "hidden" }}>
              {ticketDetails &&
                ticketDetails.comment &&
                ticketDetails.comment.length > 0 &&
                ticketDetails.comment.map((item, index) => <CommentCard key={index} {...item} />)}
            </div>
          )}
          <Divider />
        </div>
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  // map your state to props if needed
  return {
    teamList: state,
  };
}

function mapDispatchToProps(dispatch) {
  // bind your ticketActions to the dispatch function
  return {
    ticketActions: bindActionCreators(ticketActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AssignTicketPage);
