import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';
import axios_instance from '../../libs/interseptor';
import * as commonAction from '../common/commonAction';

export function getAuthUrlSuccess(data) {
  return { type: types.GET_AUTH_URL_SUCCESS, data};
}

export function getAuthUrlFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_AUTH_URL_FAILURE, data };
}

export const getAuthUrl = (data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(`${URLS.SOCIAL}${data.provider}/auth_url/`)
      .then((res) => {
        dispatch( commonAction.hideLoader() );
        dispatch(getAuthUrlSuccess(res.data));
      })
      .catch((err) => {
        dispatch( commonAction.hideLoader() );
        dispatch(getAuthUrlFailure(err));
      });
  };
};

export function postSocialCodeSuccess(data) {
  return { type: types.POST_COMMENTS_SUCCESS, data };
}

export function postSocialCodeFailure(data) {
  return { type: types.POST_COMMENTS_FAILURE, data };
}

export const postSocialCode = (params) => {
  const { provider, ...data } = params;
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(`${URLS.SOCIAL}${provider}/`, data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(postSocialCodeSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(postSocialCodeFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function checkLoggedInSuccess(data) {
  return { type: types.CHECK_LOGGED_IN_SUCCESS, data };
}

export function checkLoggedInFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.CHECK_LOGGED_IN_FAILURE, data };
}

export const checkLoggedIn = (data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(`${URLS.SOCIAL}${URLS.BRANCH}${data.branch_id}/${data.provider}/${URLS.STATUS}`)
      .then((res) => {
        dispatch( commonAction.hideLoader() );
        dispatch(checkLoggedInSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch( commonAction.hideLoader() );
        dispatch(checkLoggedInFailure(err));
        throw err.response.data;
      });
  };
};

export function logOutSuccess(data) {
  return { type: types.LOG_OUT_SUCCESS, data };
}

export function logOutFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.LOG_OUT_FAILURE, data };
}

export const logOut = (data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(`${URLS.SOCIAL}${URLS.BRANCH}${data.branch_id}/${data.provider}/${URLS.STATUS}`)
      .then((res) => {
        dispatch( commonAction.hideLoader() );
        dispatch(logOutSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch( commonAction.hideLoader() );
        dispatch(logOutFailure(err));
        throw err.response.data;
      });
  };
};
