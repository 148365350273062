import React, { useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { ListDescription } from "semantic-ui-react";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import "./listing.css";
import google_svg from "../../../images/googlephoto.svg";
import emoji_svg from "../../../images/emoji.svg";
import { formatReviews } from "../../../libs/helper";
import { getGmbListingUpdateListing } from "../../../actions/listing/listingLocationAction";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import * as listingUtils from "../../../libs/listingToast";
import duplicate_svg from "../../../images/dusplicate.svg";

const UpdateDescription = (props) => {
  const { primaryDetail, business_id, selectReso, getGmbListingUpdateListing, actions } = props;
  const [description, setDescription] = useState(primaryDetail?.description);
  const [descriptionError, setDescriptionError] = useState(null);

  const updateDescriptionHandler = (e) => {
    const { value } = e.target;
    if (value.length > 750) {
      setDescriptionError("Description should be less than 750 characters");
    } else {
      setDescription(value);
      setDescriptionError(null);
    }
  };
  const handleSaveClick = async () => {
    const data = {
      type: "description",
      description: description,
    };
    const restodetail = {
      business_id,
      selectReso,
    };
    await getGmbListingUpdateListing(data, restodetail);
    listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    await actions.getGmbListingRefreshListing(business_id, selectReso);

    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };
  return (
    <>
      <div className="listing-container" style={{ overflow: "hidden", gap: "15px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img
                  src={
                    primaryDetail?.is_varified
                      ? verified_svg
                      : primaryDetail?.is_suspend
                      ? duplicate_svg
                      : primaryDetail?.is_duplicate
                      ? duplicate_svg
                      : ""
                  }
                  alt="verifiedImage"
                />
              </div>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(2)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{formatReviews(primaryDetail?.totalReviewCount)} Reviews</span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>
              {primaryDetail?.addressLines.join(", ")} {primaryDetail?.locality} {primaryDetail?.postalCode}
            </ListDescription>
          </div>
        </div>
      </div>
      <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "calc(100% - 120px)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  top: 0,
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  paddingBlock: "10px",
                }}
              >
                <div className="listing-name">Inroduce your business & business strengths</div>

                <FPButton
                  label="AI suggested Description"
                  size="small"
                  startIcon={<img src={emoji_svg} alt="emojiSvg" />}
                  star
                />
              </div>
              <div
                style={{
                  padding: "10px",
                  backgroundColor: "#F6F6F8",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                <div style={{ display: "grid", gap: "10px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Description
                  </div>
                  <textarea
                    id="w3review"
                    name="w3review"
                    rows="4"
                    placeholder={`Welcome to ${primaryDetail?.title}, ${primaryDetail?.locality}`}
                    style={{
                      width: "100%",
                      borderRadius: "7px",
                      borderColor: "#BFC2CB",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#7A8193",
                      padding: "10px 14px",
                    }}
                    value={description}
                    onChange={updateDescriptionHandler}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "400",
                        fontSize: "12px",
                        color: "#7A8193",
                      }}
                    >
                      {description ? description?.length : 0}
                      /750 characters
                    </div>
                    {descriptionError && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {descriptionError}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* <div style={{ display: "grid", gap: "10px" }}>
                            <div style={{ fontWeight: "400", fontSize: "14px", color: "#1B1C1D", }} >
                                Visible Short Description
                            </div>
                            <textarea id="w3review" name="w3review" rows="4" placeholder="Welcome to Wa-a-Burger,Bhopal" style={{ width: "100%", borderRadius: "7px", borderColor: "#BFC2CB", fontWeight: "400", fontSize: "14px", color: "#7A8193" }} />
                            
                        </div> */}
              {/* <div>
                            <div style={{ borderBottom: "1px solid #BFC2CB" }} >

                            </div>

                        </div>
                        <div className="d-flex justify-content-end">
                            <img src={delete_png} alt="" style={{ height: "16px", width: "16px", color: "#FFFFFF" }} />

                        </div> */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "10px",
              }}
            >
              <FPButton
                label="Update"
                size="small"
                onClick={handleSaveClick}
                disabled={!description || descriptionError ? true : false || primaryDetail?.is_suspend}
              />
            </div>
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

// export default UpdateDescription;
const mapDispatchToProps = (dispatch) => ({
  getGmbListingUpdateListing: bindActionCreators(getGmbListingUpdateListing, dispatch),
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(UpdateDescription);
