import React from 'react';
import { IconButton } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkIcon from '@material-ui/icons/Link';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

function SocialMediaIcon() {
  return (
    <div>
      <div className="socialMediaIcon px-5">
        <div className="mx-5 d-flex justify-content-center gap-20 py-3 border-bottom">
          <IconButton
            component="span"
            className="circular-button border-0"
            style={{ backgroundColor: '#77c9ed' }}
          >
            <TwitterIcon style={{ color: 'white' }} />
          </IconButton>
          <IconButton
            component="span"
            className="circular-button border-0"
            style={{ backgroundColor: '#557bc1' }}
          >
            <FacebookIcon style={{ color: 'white' }} />
          </IconButton>
          <IconButton
            component="span"
            className="circular-button border-0"
            style={{ backgroundColor: '#5be987' }}
          >
            <LinkIcon style={{ color: 'white' }} />
          </IconButton>
        </div>
      </div>
    </div>
  );
}


function SocialIconMobile() {
  return (
    <div>
      <div
        className="socialMediaIcon border-top border-danger"
        style={{ margin: '0rem 0.6rem' }}
      >
        <IconButton
          component="span"
          className="circular-button border-0 bg-white"
        >
          <FacebookIcon style={{ color: 'black' }} />
        </IconButton>
        <IconButton
          component="span"
          className="circular-button border-0 bg-white"
        >
          <TwitterIcon style={{ color: 'black' }} />
        </IconButton>
        <IconButton
          component="span"
          className="circular-button border-0 bg-white"
        >
          <InstagramIcon style={{ color: 'black' }} />
        </IconButton>
        <IconButton
          component="span"
          className="circular-button border-0 bg-white"
        >
          <LinkedInIcon style={{ color: 'black' }} />
        </IconButton>
        <IconButton
          component="span"
          className="circular-button border-0 bg-white"
        >
          <YouTubeIcon style={{ color: 'black' }} />
        </IconButton>
      </div>
    </div>
  );
}

export default SocialMediaIcon;
export { SocialIconMobile };