import React, { useState, useEffect } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { ListDescription, Popup, Radio } from "semantic-ui-react";
import FPInput from "../../common/new/forms/ui/FPInput";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import "./listing.css";
import { Checkbox } from "semantic-ui-react";
import google_svg from "../../../images/googlephoto.svg";
import AddIcon from "@material-ui/icons/Add";
import red_delete_png from "../../../images/deleteRed.png";
import { formatReviews } from "../../../libs/helper";
import StarIcon from "@mui/icons-material/Star";
import duplicate_svg from "../../../images/dusplicate.svg";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import validator from "validator";
import * as listingUtils from "../../../libs/listingToast";

const UpdateAppointment = (props) => {
  const { primaryDetail, business_id, selectReso, actions, placeLocationMetaData } = props;
  const [inputFields, setInputFields] = useState({});
  const [originalFields, setOriginalFields] = useState({});
  const [useWebsiteUri, setUseWebsiteUri] = useState({});
  const [usePayload, setUsePayload] = useState([]);

  const [appointmentLinkList, setAppointmentLinkList] = useState([]);

  useEffect(() => {
    if (business_id && selectReso) {
      actions.getGmbListingPlaceActionMetaData(business_id, selectReso);
    }
  }, [actions, business_id, selectReso]);

  useEffect(() => {
    if (placeLocationMetaData) {
      const placeActionTypeMetadata = placeLocationMetaData?.placeActionTypeMetadata;

      const data = primaryDetail?.location_links;
      if (data?.length > 0) {
        const validationMap = placeActionTypeMetadata.reduce((acc, curr) => {
          acc[curr.placeActionType] = curr.displayName;
          return acc;
        }, {});
        const transformedData = data.reduce((acc, curr) => {
          const { placeActionType, providerType, name, uri } = curr;

          if (!acc[placeActionType]) {
            acc[placeActionType] = {
              placeActionType,
              providerType,
              name,
              uriList: [],
              sameAsWebsiteURL: false,
            };
          }
          // uri check that is valid or not
          const isValidURL = validator.isURL(uri);
          //       // uri same as website url check
          const sameAsWebsiteURL = uri === primaryDetail?.websiteUri;
          acc[placeActionType].sameAsWebsiteURL = sameAsWebsiteURL;
          acc[placeActionType].uriList.push({
            uri,
            error: !isValidURL,
            type: "update",
          });

          return acc;
        }, {});

        // Ensure all validationList entries are included
        placeActionTypeMetadata.forEach(({ placeActionType, displayName }) => {
          if (!transformedData[placeActionType]) {
            transformedData[placeActionType] = {
              displayName,
              placeActionType,
              providerType: "",
              name: "",
              uriList: [
                {
                  uri: "",
                  error: false,
                  type: "create",
                },
              ],
              sameAsWebsiteURL: false,
            };
          } else {
            transformedData[placeActionType].displayName = displayName;
          }
        });
        const result = placeActionTypeMetadata.map(({ placeActionType }) => transformedData[placeActionType]);
        setAppointmentLinkList(result);
      } else {
        setAppointmentLinkList(
          placeActionTypeMetadata?.map((placeActionType) => ({
            displayName: placeActionType.displayName,
            placeActionType: placeActionType.placeActionType,
            providerType: "AGGREGATOR_3P",
            name: "",
            uriList: [
              {
                uri: "",
                error: false,
                type: "create",
              },
            ],
            sameAsWebsiteURL: false,
          }))
        );
      }
    }
  }, [placeLocationMetaData?.placeActionTypeMetadata]);

  const handleAddLink = (location) => {
    const newList = [...appointmentLinkList];
    const appointmentIndex = newList.findIndex((item) => item.placeActionType === location.placeActionType);
    if (appointmentIndex === -1) return;
    const uriList = newList[appointmentIndex].uriList;
    uriList.push({ uri: "", error: false, type: "create" });
    newList[appointmentIndex].uriList = uriList;
    setAppointmentLinkList(newList);
  };

  const handleInputChange = (location, index, event) => {
    const { value } = event.target;
    const isValidURL = value?.length > 0 ? validator.isURL(value) : true;
    const newList = [...appointmentLinkList];
    const appointmentIndex = newList.findIndex((item) => item.placeActionType === location?.placeActionType);
    if (appointmentIndex === -1) return;
    const uriList = newList[appointmentIndex].uriList;
    uriList[index].uri = value;
    uriList[index].error = !isValidURL;
    newList[appointmentIndex].uriList = uriList;
    setAppointmentLinkList(newList);
  };

  const handleRemoveField = (location, index) => {
    const newList = [...appointmentLinkList];
    const appointmentIndex = newList.findIndex((item) => item.placeActionType === location?.placeActionType);
    if (appointmentIndex === -1) return;
    const uriList = newList[appointmentIndex].uriList;
    // uriList.splice(index, 1);
    if (uriList[index].type === "create") {
      uriList.splice(index, 1);
    } else if (uriList[index].type === "update") {
      uriList[index].type = "delete";
    }
    newList[appointmentIndex].uriList = uriList;
    setAppointmentLinkList(newList);
  };

  const handleSameAsWebsiteURLSwitch = (e, location) => {
    const { checked } = e.target;
    const newList = [...appointmentLinkList];
    const appointmentIndex = newList.findIndex((item) => item.placeActionType === location?.placeActionType);
    if (appointmentIndex === -1) return;
    newList[appointmentIndex].sameAsWebsiteURL = checked;
    let uriList = newList[appointmentIndex].uriList;
    if (checked) {
      uriList.forEach((item) => {
        const isValidURL = validator.isURL(primaryDetail?.websiteUri);
        item.uri = primaryDetail?.websiteUri;
        item.error = !isValidURL;
      });
    } else {
      const data = primaryDetail?.location_links;
      const transformedData = data.reduce((acc, curr) => {
        const { placeActionType, providerType, name, uri } = curr;
        if (placeActionType === location?.placeActionType) {
          const isValidURL = validator.isURL(uri);
          acc.push({
            uri,
            error: !isValidURL,
            type: "update",
          });
        }
        return acc;
      }, []);
      if (transformedData?.length === 0) {
        uriList = [{ uri: "", error: false, type: "create" }];
      } else {
        uriList = transformedData;
      }
    }
    newList[appointmentIndex].uriList = uriList;
    setAppointmentLinkList(newList);
  };

  const addressString = [primaryDetail?.addressLines, primaryDetail?.locality, primaryDetail?.postalCode].join(", ");

  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event, value) => {
    event.preventDefault();
    setChecked(!value);
    console.log("handleCheckboxChangehandleCheckboxChange", value);
  };

  const [UTMSource, setUTMSource] = useState("");
  const [UTMMedium, setUTMMedium] = useState("");

  useEffect(() => {
    console.log("checkedcheckedchecked>>>>>>>>>>>>>>>", checked);
    if (!checked) {
      setUTMSource("");
      setUTMMedium("");

      // if (websiteUrl) {
      //   try {
      //     let url = new URL(websiteUrl);
      //     url.search = "";

      //     setWebsiteUrl(url.toString());
      //   } catch (error) {
      //     console.error("Invalid URL:", websiteUrl);
      //   }
      // }
    }
  }, [checked, UTMSource, UTMMedium]);
  console.log("UTMMediumUTMMediumUTMMedium", UTMMedium, UTMSource);

  const UTMSourceHandler = (event) => {
    setUTMSource(event.target.value);
  };
  const UTMMediumHandler = (event) => {
    setUTMMedium(event.target.value);
  };

  const convertPayload = (payload) => {
    const result = {
      type: "actionLinks",
      actionLinks: [],
    };
    payload.forEach((item) => {
      const { uriList } = item;
      if (uriList.length > 0) {
        uriList.forEach((uri) => {
          if (!uri.error && uri.uri !== "") {
            if (UTMSource && UTMMedium) {
              const url = new URL(uri.uri);
              url.searchParams.set(UTMSource, UTMMedium);
              uri.uri = url.toString();
            }
            result.actionLinks.push({
              uri: uri.uri,
              name: item.name,
              providerType: item.providerType,
              type: uri.type,
              placeActionType: item.placeActionType,
            });
          }
        });
      }
    });
    return result;
  };

  const updateAppointmentHandler = async () => {
    // Generate the payload
    const restodetail = {
      business_id,
      selectReso,
    };
    const payload = convertPayload(appointmentLinkList);
    await actions.getGmbListingUpdateListing(payload, restodetail);
    listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    await actions.getGmbListingRefreshListing(business_id, selectReso);
    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };
  return (
    <>
      <div className="listing-container" style={{ overflow: "hidden", gap: "15px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img
                  src={
                    // primaryDetail?.recommendationReason === ""
                    //   ? verified_svg
                    //   : primaryDetail?.recommendationReason ===
                    //     "BUSINESS_LOCATION_SUSPENDED"
                    //     ? duplicate_svg
                    //     : duplicate_svg
                    primaryDetail?.is_varified
                      ? verified_svg
                      : primaryDetail?.is_suspend
                      ? duplicate_svg
                      : primaryDetail?.is_duplicate
                      ? duplicate_svg
                      : ""
                  }
                  alt="verifiedImage"
                />
              </div>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{formatReviews(primaryDetail?.totalReviewCount)} Reviews</span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>{addressString}</ListDescription>
          </div>
        </div>
      </div>
      <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "calc(100% - 120px)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "15px",
                paddingBottom: "5px",
              }}
            >
              {appointmentLinkList?.map((location) => (
                <div style={{ display: "grid", gap: "12px" }} key={location?.placeActionType}>
                  <div style={{ display: "grid", gap: "4px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "400",
                          fontSize: "14px",
                          color: "#1B1C1D",
                        }}
                      >
                        {location?.displayName}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          color: "#00B5AD",
                          fontWeight: "400",
                          fontSize: "16px",
                          alignContent: "center",
                          gap: "5px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleAddLink(location)}
                      >
                        <AddIcon />
                        <div>add Link</div>
                      </div>
                    </div>
                    {location?.uriList?.map((field, findex) => {
                      if (field.type !== "delete") {
                        return (
                          <>
                            <div
                              key={findex}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              {/* <div style={{ display: "flex", flexDirection: "column" }}> */}
                              <FPInput
                                type="text"
                                value={field?.uri}
                                placeholder={`Enter ${location?.displayName} URL`}
                                onChange={(event) => handleInputChange(location, findex, event)}
                                endIcon={
                                  <Popup trigger={<StarIcon color="disabled" />} position="top center" on="hover">
                                    <div
                                      style={{
                                        fontWeight: "400",
                                        fontSize: "12px",
                                        color: "#1B1C1D",
                                      }}
                                    >
                                      Set as preferred link
                                    </div>
                                  </Popup>
                                }
                              />
                              {/* </div> */}
                              {/* {findex !== location?.uriList?.length - 1 && ( */}
                              <img
                                src={red_delete_png}
                                alt="deletePng"
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  color: "#FFFFFF",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleRemoveField(location, findex)}
                              />
                              {/* )} */}
                            </div>
                            {field?.error && <p style={{ color: "red" }}>Please enter a valid URL</p>}
                            {/* {!validUrls[location]?.[findex] && field?.uri && (
                          <p style={{ color: "red" }}>
                            Please enter a valid URL
                          </p>
                        )} */}
                          </>
                        );
                      }
                    })}
                  </div>
                  {location?.uriList?.length > 0 && location?.uriList?.some((item) => item.type !== "delete") && (
                    <div
                      style={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        id={`same_as_url_${location?.placeActionType}`}
                        checked={
                          location?.sameAsWebsiteURL &&
                          primaryDetail?.websiteUri &&
                          !location?.uriList?.some((item) => item.uri !== primaryDetail?.websiteUri)
                        }
                        onChange={(e) => handleSameAsWebsiteURLSwitch(e, location)}
                      />

                      <label
                        htmlFor={`same_as_url_${location?.placeActionType}`}
                        style={{
                          cursor: "pointer",
                          fontWeight: "400",
                          fontSize: "12px",
                          color: "#7A8193",
                          marginBottom: 0,
                        }}
                      >
                        Same as website URL
                      </label>
                    </div>
                  )}
                </div>
              ))}

              {/* <div style={{ display: "grid", gap: "32px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div className="listing-name">Add UTM source & Medium</div>
                <Checkbox
                  toggle
                  checked={checked}
                  className="toggle-btn"
                  onChange={(event) => handleCheckboxChange(event, checked)}
                />
              </div>
              <div className="d-flex justify-content-end">
                <FPButton
                  label="Update"
                  size="small"
                  disabled={primaryDetail?.recommendationReason === "BUSINESS_LOCATION_SUSPENDED"}
                />
              </div>
            </div> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div className="listing-name">Add UTM sdfasdsource & Medium</div>
                <Radio
                  toggle
                  checked={checked}
                  value={checked}
                  onChange={(event) => handleCheckboxChange(event, checked)}
                />
                {/* <Checkbox
                  toggle
                  checked={checked}
                  className="toggle-btn"
                  onChange={(event) => handleCheckboxChange(event, checked)}
                /> */}
              </div>
              <div className="row">
                <div className="col-6" style={{ display: "grid", gap: "4px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    UTM Source{" "}
                  </div>
                  <FPInput
                    disabled={!checked}
                    type="text"
                    placeholder={"Add UTM source eg. Google"}
                    // onChange={UTMSourceHandler}
                    value={UTMSource}
                    onChange={(e) => UTMSourceHandler(e, "source")}
                  />
                </div>
                <div className="col-6" style={{ display: "grid", gap: "4px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    UTM Medium{" "}
                  </div>
                  <FPInput
                    disabled={!checked}
                    type="text"
                    placeholder={"Add UTM medium eg. GMB"}
                    // onChange={UTMMediumHandler}
                    value={UTMMedium}
                    onChange={(e) => UTMMediumHandler(e, "source")}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "10px",
              }}
            >
              <FPButton
                label="Update URL's"
                size="small"
                disabled={primaryDetail?.is_suspend}
                onClick={updateAppointmentHandler}
              />
            </div>
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    placeLocationMetaData: state.gmbListingLocation.placeLocationMetaData,
    placeLoationMetadata_error: state.gmbListingLocation.placeLoationMetadata_error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAppointment);
