import React, { useState, useMemo, useRef } from "react";
import classnames from "classnames";
import { VariableSizeList as List } from "react-window";
import { FPButton, FPCheckbox } from "../common/new";
import DebounceSearchbar from "../common/new/FPFilter/DebounceSearchbar";
import "../../containers/business/mention.css";

const ReviewTag = (props) => {
  const { tagsListing, onAdd, initialValue, toggleModal, isProduct } = props;
  const [selectTag, setSelectTag] = useState(initialValue || []);
  const [searchTerm, setSearchTerm] = useState("");
  const listRef = useRef(null);

  const handleOrderSelection = (event, tag) => {
    let newArray = [...selectTag];
    const isChecked = event.target.checked;

    if (isChecked) {
        if(isProduct) {
          newArray.push({
            display_name: tag.display_name.trim(),
            value: tag.display_name,
          });
        } else {
          newArray.push({
            display_name: tag.display_name.trim(),
            value: tag.id,
          });
        }
    } else {
      if(isProduct) {
        newArray = newArray.filter((item) => item.display_name !== tag.display_name);
      } else {
        newArray = newArray.filter((item) => item.value !== tag.id);
      }
    }
    setSelectTag(newArray);
  };

  const handleAdd = () => {
    onAdd(JSON.parse(JSON.stringify(selectTag)));
    toggleModal();
  };

  const filteredTags = useMemo(() => {
    return tagsListing.filter((tag) => tag.display_name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [tagsListing, searchTerm]);

  const calculateTextHeight = (text, width, fontSize = 14, lineHeight = 1.5) => {
    const tempDiv = document.createElement("div");
    Object.assign(tempDiv.style, {
      position: "absolute",
      visibility: "hidden",
      width: `${width}px`,
      fontSize: `${fontSize}px`,
      lineHeight: `${lineHeight}`,
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    });
    tempDiv.innerText = text;
    document.body.appendChild(tempDiv);
    const height = tempDiv.offsetHeight;
    document.body.removeChild(tempDiv);
    return height;
  };

  const getItemSize = (index) => {
    const content = filteredTags[index];
    const listWidth = 400;
    return Math.max(40, Math.min(calculateTextHeight(content.display_name, listWidth), 200));
  };

  const Row = ({ index, style }) => {
    const tag = filteredTags[index];

    return (
      <div key={tag.id} style={style} className="filter-tags d-flex align-items-center pr-3 pb-0">
        {isProduct ?
          <FPCheckbox
            checked={selectTag.some((item) => item.display_name === tag.display_name)}
            onClick={(event) => handleOrderSelection(event, tag)}
          />
          :
          <FPCheckbox
            checked={selectTag.some((item) => item.value === tag.id)}
            onClick={(event) => handleOrderSelection(event, tag)}
          />
        }
        <div className={classnames("d-flex gap-10")} style={{ color: "#1B1C1D" }}>
          <div className="manageTagModelText">{tag.display_name}</div>
        </div>
      </div>
    );
  };

  const handleSearch = (e) => {
    setSearchTerm(e?.target?.value || "");
    if (listRef.current) {
      listRef.current.resetAfterIndex(0, true);
    }
  };

  return (
    <React.Fragment>
      <DebounceSearchbar debounceTime={300} className="mb-10" onChange={handleSearch} />
      <div className="d-flex flex-column gap-15">
        {filteredTags.length > 0 ? (
          <List
            ref={listRef}
            height={525}
            itemCount={filteredTags.length}
            itemSize={getItemSize}
            width={"100%"}
            style={{ overflowY: "visible" }}
          >
            {Row}
          </List>
        ) : (
          <div style={{ textAlign: "center" }}>
            <span>No tags found</span>
          </div>
        )}
      </div>
      <div className="d-flex justify-content-end mt-10">
        <FPButton size="small" label="Add" onClick={() => handleAdd()} />
      </div>
    </React.Fragment>
  );
};

export default ReviewTag;
