import React, { useContext, useEffect, useState } from "react";
import MenuMarketFilter from "./MenuMarketFilter";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import PaginationComponent from "../common/Pagination";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import LoaderComponent from "../../components/common/loaderComponent";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import LimitModal from "../common/LimitModal";
import { SideNavContext } from "../../Context/sideNavContext";
// import { MdOutlineFileDownload } from "react-icons/md";
// import { FPToolTip } from "../../components/common/new";
import { Checkbox } from "semantic-ui-react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import queryString from "query-string";
import { Table } from "antd";

const MenuMarket = (props) => {
  const { history, location, match, location : { search, pathname }, reviewActions } = props;
  const { match: { params: { business_id } } } = props;
  const [menuStockList, setMenuStockList] = useState({});
  const [loading, setLoading] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const { isAccordionTabSelected } = useContext(SideNavContext);
  const [isLocationWise, setIsLocationWise] = useState(false);
  const [isDayWise, setIsDayWise] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [menuStockReport, setMenuStockReport] = useState({});

  useEffect(() => {
    document.title = "Menu Market";
    props.actions.setSelectedTab(3);
    isAccordionTabSelected();
  }, []); // eslint-disable-line

  useEffect(() => {
    setLoading(true);
    axios_instance.get(`${utils.format(URLS.MENU_OUT_OF_STOCK_MONITOR, [business_id])}${search ? search + '&' : '?'}`)
    .then((res) => {
      setMenuStockList(res.data);
      setLoading(false);
    }).catch((err) => {
      console.log(err);
      setLoading(false);
    });

    axios_instance.get(`${utils.format(URLS.MENU_OUT_OF_STOCK_MONITOR_REPORT, [business_id])}${search ? search + '&' : '?'}is_location_wise=${isLocationWise}`)
      .then((res) => {
        setMenuStockReport(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [search]) // eslint-disable-line

  const toggleDownloadModal = () => {
    setIsDownloadModalOpen(!isDownloadModalOpen);
  };

  const handleDownloadModal = (downloadFlag) => {
    setIsDownloadModalOpen(!isDownloadModalOpen);
    if (!downloadFlag) return;
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = props;
    const url = activeTab === '1'
      ? `${URLS.MENU_OUT_OF_STOCK_MONITOR_DOWNLOAD.replace('{}', business_id)}${search ? search : ''}`
      : `${URLS.MENU_OUT_OF_STOCK_MONITOR_REPORT_DOWNLOAD.replace('{}', business_id)}${search ? search + '&' : '?'}is_location_wise=${isLocationWise}&is_day_wise=${isDayWise}`

    const file_name = activeTab === '1' 
      ? "famepilot-out-of-stock-monitor.xlsx" 
      : "famepilot-out-of-stock-monitor-report.xlsx"
    return reviewActions.downloadTemplateXlsx(url, file_name);
  };

  const handleLocationWise = (check) => {
    setIsLocationWise(check);
    setLoading(true);
    axios_instance.get(`${utils.format(URLS.MENU_OUT_OF_STOCK_MONITOR_REPORT, [business_id])}${search ? search + '&' : '?'}is_location_wise=${check}`)
      .then((res) => {
        setMenuStockReport(res.data);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  const handleTabChange = (tab) => {
    const query = queryString.parse(search);
    history.push({
      pathname,
      search: queryString.stringify({ ...query, page: 1 }),
    });
    setActiveTab(tab);
  }

  const getColumnsMonitor = () => {
    let baseColumns = [];

    baseColumns.push({
      title: "Date",
      dataIndex: "date",
      sorter: {
        compare: (a, b) =>
          a?.date ? a?.date?.localeCompare(b?.date) : 0,
        multiple: 3,
      },        
    });

    baseColumns.push({
      title: "Time",
      dataIndex: "time",
      sorter: {
        compare: (a, b) =>
          a?.time ? a?.time?.localeCompare(b?.time) : 0,
        multiple: 3,
      },
    });

    baseColumns.push({
      title: "Provider Name",
      dataIndex: "provider_name",
      sorter: {
        compare: (a, b) =>
          a?.provider_name ? a?.provider_name?.localeCompare(b?.provider_name) : 0,
        multiple: 3,
      },
    });

    baseColumns.push({
      title: "Location",
      dataIndex: "location",
      sorter: {
        compare: (a, b) =>
          a?.location ? a?.location?.localeCompare(b?.location) : 0,
        multiple: 3,
      },
    });

    baseColumns.push({
      title: "Out of Stock Item",
      dataIndex: "item",
      sorter: {
        compare: (a, b) =>
          a?.item ? a?.item?.localeCompare(b?.item) : 0,
        multiple: 3,
      },
    });

    return baseColumns;
  }

  const getColumnsReport = () => {
    let baseColumns = [];

    if (isLocationWise) {
      baseColumns.push(
        {
          title: "Location",
          dataIndex: "location",
          sorter: {
            compare: (a, b) =>
              a?.location ? a?.location?.localeCompare(b?.location) : 0,
            multiple: 3,
          },        
        },
      )
    }

    baseColumns.push(
      {
        title: "Item Name",
        dataIndex: "item_name",
        sorter: {
          compare: (a, b) =>
            a?.item_name ? a?.item_name?.localeCompare(b?.item_name) : 0,
          multiple: 3,
        },        
      },
    )

    if (isDayWise) {
      baseColumns.push({
        title: "Out of Stock Days",
        dataIndex: "day_count",
        sorter: {
          compare: (a, b) => b["day_count"] - a["day_count"],

        },        
        align: "center",
      });

      baseColumns.push({
        title: "Total Days",
        dataIndex: "total_days",
        sorter: {
          compare: (a, b) => b["total_days"] - a["total_days"],
        },        
        align: "center",
      });
    }
    else {
      baseColumns.push({
        title: "Out of Stock Check Slots",
        dataIndex: "time_count",
        sorter: {
          compare: (a, b) => b["time_count"] - a["time_count"],
        },        
        align: "center",
      });

      baseColumns.push({
        title: "Total Slots",
        dataIndex: "total_checks",
        sorter: {
          compare: (a, b) => b["total_checks"] - a["total_checks"],
        },        
        align: "center",
      });
    }

    return baseColumns;
  }

  const onChange = (filters, sorter, extra) => {
    console.log("params", filters, sorter, extra);
  };

  return (
    <div>
      <LoaderComponent loader={loading} />
      <div className="mt-4" style={{ display: "flex", fontSize: "12px" }}>
        Last Synced: {menuStockList?.last_sync}
      </div>
      <div>
        <MenuMarketFilter history={history} location={location} match={match} downloadExcel={toggleDownloadModal}/>
      </div>
      <CardWrapper showDivider={false} className="mt-3">
        <div className="report-ThirdRow mb-4">
          <Nav className="Setup_Sub_Nav">
            <NavItem>
              <NavLink className={classnames({ active: activeTab === "1" })} onClick={() => handleTabChange("1")}>
                Out of Stock Monitor
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink className={classnames({ active: activeTab === "2" })} onClick={() => handleTabChange("2")}>
                Out of Stock Report
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        {activeTab === '1' ? 
          <>
            <div className="ui-table-responsive">
              <Table
                columns={getColumnsMonitor()}
                dataSource={menuStockList?.results || []}
                onChange={onChange}
                pagination={false}
                showSorterTooltip={false}
              />
            </div>
            <PaginationComponent count={menuStockList?.count || 0} location={location} pageSizeCookie="menu_market_out_of_stock" />
          </>
          :<>
            <div className="mb-3" style={{ display: "flex", justifyContent: 'right', gap: "20px", alignItems: 'center' }}>
              <div className="d-flex align-items-center gap-6">
                <span style={{ fontSize: "14px", fontWeight: "400", color: "rgba(27, 28, 29, 1)" }} > Location Wise </span>
                <Checkbox
                  toggle
                  value="Location wise"
                  checked={isLocationWise}
                  onChange={(e, checked) => handleLocationWise(!isLocationWise)}
                ></Checkbox>
              </div>
              <div className="d-flex align-items-center gap-6">
                <span style={{ fontSize: "14px", fontWeight: "400", color: "rgba(27, 28, 29, 1)" }} > Day Wise </span>
                <Checkbox
                  toggle
                  value="Day wise"
                  checked={isDayWise}
                  onChange={(e, checked) => setIsDayWise(!isDayWise)}
                ></Checkbox>
              </div>
            </div>
            <div className="ui-table-responsive">
              <Table
                columns={getColumnsReport()}
                dataSource={menuStockReport?.results || []}
                onChange={onChange}
                pagination={false}
                showSorterTooltip={false}
              />
            </div>
            <PaginationComponent key={JSON.stringify(menuStockReport?.count || 0)} count={menuStockReport?.count || 0} location={location} pageSizeCookie="menu_market_out_of_stock_report" />
          </>
        }
      </CardWrapper>

      {isDownloadModalOpen && (
        <LimitModal
          isOpen={isDownloadModalOpen}
          isReviewsPage={true}
          toggle={() => setIsDownloadModalOpen(!isDownloadModalOpen)}
          HeaderText={"Out of Stock Monitor Report"}
          message={[
            "Hey you are good to go. Click Ok to download Out of Stock Monitor Report. "
          ]}
          handleDownloadModal={handleDownloadModal}
        />
      )}

    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(setSelectedTab, dispatch),
    reviewActions: bindActionCreators(ReviewsActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
  };
};

MenuMarket.propTypes = {
  loader: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuMarket);
