import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import cookie from "react-cookies";
import { bindActionCreators } from "redux";
import * as ReviewsActions from "../../actions/business/reviewsActions";
import * as ProfileActions from "../../actions/accounts/profileAction";
import * as commonAction from "../../actions/common/commonAction";
import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import * as ticketActions from "../../actions/ticket/ticketActions";
import * as URLS from "../../libs/apiUrls";
import PaginationComponent from "../../components/common/Pagination";
import queryString from "query-string";
import CreateTicketForm from "../../components/tickets/CreateTicketForm";
import FormModal from "../../components/common/FormModal";
import LimitModal from "../../components/common/LimitModal";
import LoaderComponent from "../../components/common/loaderComponent";
import * as TeamAction from "../../actions/team/teamAction";
import TagEditModal from "../../components/common/TagEditModal";
import TagAddForm from "../../components/mentions/TagAddForm";
import ManageTags from "../../components/business/ManageTags";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import TicketingReviewsModal from "../../components/tickets/TicketingReviewModal";
import PropTypes from "prop-types";
import axios_instance from "../../libs/interseptor";
import { TagManageModalWrapper } from "../../components/common/new";
import TicketActionBar from "../../modules/ticket/TicketActionBar";
import TicketFilter from "../../modules/ticket/TicketFilter";
import TicketList from "../../modules/ticket/TicketList";

class TicketsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloadModalOpen: false,
      data: null,
      isTicketModal: false,
      pageData: "",
      isTagEditOpen: false,
      tagDetail: {},
      isConfirmOpen: false,
      isManageTagModalOpen: false,
      tagFilter: "",
      totalTeamMember: [],
      survey_pages:
        this.props.resolutionDetail &&
        this.props.resolutionDetail.results &&
        this.props.resolutionDetail.results.length > 0
          ? this.props.resolutionDetail.results[0].survey_pages_data
          : null,
      survey_page_length:
        this.props.resolutionDetail &&
        this.props.resolutionDetail.results &&
        this.props.resolutionDetail.results.length > 0
          ? this.props.resolutionDetail.results[0].survey_pages_data.length
          : null,
      ticketResolutionEnabled:
        this.props.businessInfo && this.props.businessInfo.enable_ticket_resolution ? true : false,
      getSurveyResponse: false,
      surveySubmitError: "",
      surveyPageQuestion: [],
      surveyFormLoading: false,
      productIssueReasonsMappingEnabled:
        this.props.businessInfo && this.props.businessInfo.enable_product_issue_reasons_mapping_questions
          ? true
          : false,
      issueReasonError: "",
      productMappingSubmitted: false,
      disableTicketCommentBox:
        this.props.businessInfo && this.props.businessInfo.disable_comment_section_in_ticket_review_modal
          ? true
          : false,
      restrictTicketClosingOnSurvey:
        this.props.businessInfo && this.props.businessInfo.restrict_ticket_closing_without_submitting_ticket_survey
          ? true
          : false,
    };
    this.handleTagFilter = this.handleTagFilter.bind(this);
    this.getSurveyResponse = this.getSurveyResponse.bind(this);
    this.finalSubmitSurveyQuestin = this.finalSubmitSurveyQuestin.bind(this);
    this.handleSurveyPageChange = this.handleSurveyPageChange.bind(this);
    this.submitProductIssueReasonsMappingQuestions = this.submitProductIssueReasonsMappingQuestions.bind(this);
    this.sendMail = this.sendMail.bind(this);
    //PageSize Handling
    const pageSize = cookie.load("pageSize") || {};
    const {
      history,
      location: { pathname, search },
    } = props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        page_size: pageSize.tickets_page || 10,
        date_type: "prior_30_day",
      }),
    });
  }

  componentDidMount() {
    const {
      match: {
        params: { business_id },
      },
      match: { params },
      location: { search },
    } = this.props;

    this.props.commonActions.rationsOptions();
    this.props.commonActions.sentimentOptions();
    this.props.profileActions.getProfileInfo();
    this.props.teamActions.teamList(params, business_id);
    this.props.ticketActions.getTicketStatusOptions();
    this.props.ticketActions.getTicketSourceOptions();
    this.props.businessActions.getTagsList({
      businessId: business_id,
      type: "ticket",
    });
    this.props.commonActions.cityOptions(business_id);
    this.props.commonActions.stateOptions(business_id);
    this.props.commonActions.countryOptions(business_id);
    this.props.commonActions.branchTagOptions(business_id);
    this.props.actions.reviewSourceOptions({ business_id });
    this.props.businessActions.businessBranchOptions({ business: business_id }, true);
    const newQuery = queryString.stringify({
      ...queryString.parse(search),
      date_type: "prior_30_day",
    });
    this.getData({ ...queryString.parse(newQuery), businessId: business_id });
    this.props.ticketActions.ticketResolution(business_id).then((res) => {
      if (res.data && res.data.results && res.data.results.length > 0) {
        this.setState({
          survey_pages: res.data.results[0].survey_pages_data,
          survey_current_page: 0,
          survey_page_length: res.data.results[0].survey_pages_data.length,
        });
      }
    });
    //Set Title
    document.title = "Tickets";
    return axios_instance.get(URLS.AGENCY_USERS + "?business_id=" + business_id).then((res) => {
      this.setState({ totalTeamMember: res.data.user_list });
    });
  }
  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps = (nextProps) => {
    const {
      location: { search: prevSearch },
      match: { params: prevParams },
    } = this.props;
    const {
      location: { search: nextSearch },
      match: { params: nextParams },
    } = nextProps;
    if (prevSearch !== nextSearch) {
      if (prevParams.page_size === "100") {
        const newQuery = queryString.stringify({
          ...queryString.parse(nextSearch),
          date_type: "prior_30_day",
        });
        this.getData({
          ...queryString.parse(newQuery),
          businessId: nextParams.business_id,
        });
      } else {
        this.getData({
          ...queryString.parse(nextSearch),
          businessId: nextParams.business_id,
        });
      }
    }
    if (this.props.resolutionDetail != nextProps.resolutionDetail) {
      if (
        nextProps.resolutionDetail &&
        nextProps.resolutionDetail.results &&
        nextProps.resolutionDetail.results.length > 0
      ) {
        this.setState({
          survey_pages: nextProps.resolutionDetail.results[0].survey_pages_data,
          survey_current_page: 0,
          survey_page_length: nextProps.resolutionDetail.results[0].survey_pages_data.length,
        });
      }
    }
    if (this.props.businessInfo != nextProps.businessInfo) {
      this.setState({
        ticketResolutionEnabled:
          nextProps.businessInfo && nextProps.businessInfo.enable_ticket_resolution ? true : false,
        productIssueReasonsMappingEnabled:
          nextProps.businessInfo && nextProps.businessInfo.enable_product_issue_reasons_mapping_questions
            ? true
            : false,
      });
    }
  };

  searchReviews = (queryparams) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.getData({ ...queryparams, businessId: business_id });
  };

  toggleDownloadModal = () => {
    const { isDownloadModalOpen } = this.state;
    this.setState({ isDownloadModalOpen: !isDownloadModalOpen });
  };

  getData = (data) => {
    this.setState({ pageData: data });
    return this.props.ticketActions.ticketListing(data);
  };

  postTag = (tagData) => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const { object_id, tagMethod, tag_id, name, tag_name } = tagData;
    const data = { businessId: business_id, type: "ticket" };
    const query = queryString.parse(search);
    return this.props.businessActions.postTag({ ...data, object_id, tag_name, name, tag_id, tagMethod }).then((res) => {
      //eslint-disable-line
      this.props.businessActions.getTagsList(data);
      if (tagMethod) {
        this.getData({ ...query, businessId: business_id });
      }
      return res;
    });
  };

  handleTagFilter = (value) => {
    const {
      history,
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);
    const tagArray = query.tag && query.tag.split(",");
    delete query.page;
    if (tagArray && tagArray.includes(value)) {
      query.tag = tagArray.filter((item) => item !== value).join(",");
      !query.tag && delete query.tag;
    } else {
      query.tag = query.tag ? query.tag + `,${value}` : value;
    }
    history.push({ pathname, search: queryString.stringify({ ...query }) });
  };

  toggleConfirm = (functionToRun) => {
    this.setState({ isConfirmOpen: !this.state.isConfirmOpen, functionToRun });
  };

  toggleTagEditModal = (tag) => {
    const { isTagEditOpen } = this.state;
    if (tag) this.setState({ tagDetail: { ...tag }, isTagEditOpen: true });
    else this.setState({ isTagEditOpen: !isTagEditOpen, tagDetail: {} });
  };

  toggleManageTagModal = () => {
    this.setState({ isManageTagModalOpen: !this.state.isManageTagModalOpen });
  };

  addOrEditTag = (data) => {
    const {
      history,
      match: {
        params: { business_id },
      },
      location: { pathname, search },
    } = this.props;
    const {
      tagDetail,
      tagDetail: { id },
    } = this.state;
    const { name } = data;
    const query = queryString.parse(search);
    let functionToRun;
    let getMentions = true;
    const selectedTag = query.tag && query.tag.split(",");

    if (tagDetail.id) {
      if (selectedTag && selectedTag.includes(tagDetail.name)) {
        delete query.tag;
        getMentions = false;
        history.push({ pathname, search: queryString.stringify({ ...query }) });
      }
      functionToRun = this.props.businessActions.editTagName({
        businessId: business_id,
        type: "ticket",
        id,
        name,
      });
    } else
      functionToRun = this.props.businessActions.postTag({
        businessId: business_id,
        type: "ticket",
        name,
      });
    return functionToRun.then((res) => {
      //eslint-disable-line
      getMentions && this.getData({ ...query, businessId: business_id });
      this.props.businessActions.getTagsList({ businessId: business_id, type: "ticket" }).then((res) => {
        //eslint-disable-line
        this.toggleTagEditModal();
      });
    });
  };

  deleteTag = ({ id, name }) => {
    const {
      match: {
        params: { business_id },
      },
      history,
      location: { pathname, search },
    } = this.props;
    const query = queryString.parse(search);
    const tagArray = query.tag && query.tag.split(",");
    return this.props.businessActions.deleteTag({ businessId: business_id, id, type: "ticket" }).then((res) => {
      //eslint-disable-line
      if (tagArray && tagArray.includes(name)) {
        query.tag = tagArray.filter((item) => item !== name).join(",");
        !query.tag && delete query.tag;
        history.push({
          pathname,
          search: queryString.stringify({ ...query }),
        });
      } else {
        this.getData({ ...query, businessId: business_id });
      }
      this.props.businessActions.getTagsList({
        businessId: business_id,
        type: "ticket",
      });
    });
  };

  handleDownloadModal = (downloadFlag) => {
    this.setState({ isDownloadModalOpen: !this.state.isDownloadModalOpen });
    if (!downloadFlag) return;
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const url = `${URLS.TICKET}${business_id}/download/${search}`;
    return this.props.actions.downloadTemplate(url, "famepilot-ticket-report.csv");
  };

  //Ticket Details Modal Functions
  manageTeamList = () => {
    this.props.history.push({
      pathname: "central-setup",
      state: { tab: "2" },
    });
  };

  getTeamList = (query) => {
    const {
      match: { params },
    } = this.props;
    return this.props.teamActions.teamList({ ...params, ...query }, params.business_id);
  };

  toggleTicketModal = (item) => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const { isTicketModalOpen } = this.state;
    if (isTicketModalOpen) {
      this.setState({
        isTicketModalOpen: !isTicketModalOpen,
        survey_current_page: 0,
      });
      this.getTeamList({});
      return this.getData({
        ...queryString.parse(search),
        businessId: business_id,
      });
    }
    return this.props.ticketActions.singleTicketDetails(business_id, item.id).then(async (res) => {
      await this.getTeamList({ branch: res.data.branch_id });
      this.setState({ isTicketModalOpen: !isTicketModalOpen });
    });
  };

  patchTicket = (data, ticket_id) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.ticketActions.patchTicket(data, business_id, ticket_id);
  };

  sendMail = (data, ticket_id) => {
    const { match: { params: { business_id }, } } = this.props;
    return this.props.ticketActions.ticketMailSend(data, business_id,  ticket_id);
  }

  addComment = (description, ticket_id) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.ticketActions.addComment(description, business_id, ticket_id);
  };

  //Create Ticket Functions
  handleTicketForm = (data) => {
    let final_data = { ...data, source: "manual", source_id: 1 };
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    return this.props.ticketActions.createTicket(final_data, business_id).then(() => {
      this.toggleCreateTicketModal();
      this.getData({ ...queryString.parse(search), businessId: business_id });
    });
  };

  toggleCreateTicketModal = () => {
    const { isTicketModal } = this.state;
    if (isTicketModal) this.getTeamList({});
    this.setState({ isTicketModal: !isTicketModal });
  };

  getSurveyResponse(data) {
    this.setState({ surveyFormLoading: true });
    const req_que = [];
    const not_answered = [];
    this.state.survey_pages[this.state.survey_current_page]["allQuestions"].map((que, que_index) => {
      if (que.question_required) {
        req_que.push(que_index);
      }
    });
    req_que.map((qu_index) => {
      const result = data.SurveyQuestions.findIndex((question) => question.index == qu_index);
      if (result < 0) {
        not_answered.push(qu_index + 1);
      }
    });
    if (not_answered.length > 0) {
      this.setState({
        surveyFormLoading: false,
        surveySubmitError: "Question No:- " + not_answered.join() + " need to be answered.",
      });
    } else {
      let d = this.state.surveyPageQuestion;

      d.push(data);
      this.setState({
        survey_current_page: this.state.survey_current_page + 1,
        surveyPageQuestion: d,
        surveySubmitError: "",
        surveyFormLoading: false,
      });
    }
  }

  finalSubmitSurveyQuestin(new_data) {
    this.setState({ surveyFormLoading: true });
    var data = new_data.data;
    var ticket_id = new_data.ticket_id;
    var attached_files = new_data.attached_files;
    const req_que = [];
    const not_answered = [];
    this.state.survey_pages[this.state.survey_current_page]["allQuestions"].map((que, que_index) => {
      if (que.question_required) {
        req_que.push(que_index);
      }
    });
    req_que.map((qu_index) => {
      const result = data.SurveyQuestions.findIndex((question) => question.index == qu_index);
      if (result < 0) {
        not_answered.push(qu_index + 1);
      }
    });
    if (not_answered.length > 0) {
      this.setState({
        surveyFormLoading: false,
        surveySubmitError: "Question No:- " + not_answered.join() + " need to be answered.",
      });
    } else {
      const final_data_to_be_save = [...this.state.surveyPageQuestion, data];
      if (
        final_data_to_be_save.length > 0 &&
        final_data_to_be_save[0].SurveyQuestions &&
        final_data_to_be_save[0].SurveyQuestions.length === 0
      ) {
        this.setState({
          surveyFormLoading: false,
          surveySubmitError: "None of the Questions is answered.",
        });
      } else {
        let survey_response_d = [...this.state.surveyPageQuestion, data];
        const formData = new FormData();
        formData.append("survey_response", JSON.stringify(survey_response_d));
        formData.append("ticket_id", ticket_id);
        formData.append("businessId", this.props.match.params.business_id);

        if (attached_files) {
          attached_files.map((file_it) => {
            formData.append("attached_file", file_it, file_it.name);
          });
        }
        this.props.ticketActions.createTicketResolutionResponse(formData).then((res) => {
          if (res.data.success) {
            this.props.ticketActions.singleTicketDetails(this.props.match.params.business_id, ticket_id).then((res) => {
              if (res.data.resolution_data.survey_response) {
                this.setState({
                  surveyFormLoading: false,
                  survey_current_page: 0,
                  surveySubmitError: "",
                  surveyPageQuestion: [],
                  productMappingSubmitted: false,
                });
              }
            });
          }
          this.setState({ surveyFormLoading: false });
        });
      }
    }
  }

  handleSurveyPageChange(selected_page, mapping_length) {
    if (this.state.productIssueReasonsMappingEnabled && mapping_length > 0) {
      if (selected_page == 0) {
        this.setState({
          survey_current_page: selected_page,
          productMappingSubmitted: false,
        });
      } else {
        this.setState({
          survey_current_page: selected_page - 1,
          productMappingSubmitted: true,
        });
      }
    } else {
      this.setState({ survey_current_page: selected_page });
    }
  }

  submitProductIssueReasonsMappingQuestions(new_data) {
    this.setState({ surveyFormLoading: true });
    var mapping_data = new_data.data;
    var ticket_id = new_data.ticket_id;
    let allProductQuesAnswered = true;
    for (let i = 0; i < mapping_data.length; i++) {
      let product_obj = mapping_data[i];
      let selected_issue_index = product_obj.product_issue_list.findIndex((issue_obj) => issue_obj.selected == true);
      if (selected_issue_index < 0) {
        allProductQuesAnswered = false;
        this.setState({
          issueReasonError: "Issue and Reason need to be selected for all Questions",
          surveyFormLoading: false,
        });
        break;
      } else {
        let selected_reason_index = product_obj.product_issue_list[
          selected_issue_index
        ].product_issue_reasons_list.findIndex((res_obj) => res_obj.selected == true);
        if (selected_reason_index < 0) {
          allProductQuesAnswered = false;
          this.setState({
            issueReasonError: "Issue and Reason need to be selected for all Questions",
            surveyFormLoading: false,
          });
          break;
        }
      }
    }
    if (allProductQuesAnswered) {
      const formData = new FormData();
      formData.append("product_mapping_response", JSON.stringify(mapping_data));
      formData.append("ticket_id", ticket_id);
      formData.append("businessId", this.props.match.params.business_id);

      return axios_instance.post(URLS.SUBMIT_MAPPING_QUESTIONS, formData).then((res) => {
        if (res.data.success) {
          this.props.ticketActions.singleTicketDetails(this.props.match.params.business_id, ticket_id).then(() => {
            this.setState({
              productMappingSubmitted: true,
              surveyFormLoading: false,
            });
          });
        }
      });
    }
  }

  render() {
    const {
      tagsList,
      branchOptions,
      tickets,
      ticketDetails,
      ticketStatusOptions,
      responseOptions,
      lockedModules,
      ticketSourceOptions,
      teamList
    } = this.props;
    const {
      tagDetail,
      isTagEditOpen,
      isManageTagModalOpen,
      isDownloadModalOpen,
      totalTeamMember,
      survey_pages,
      ticketResolutionEnabled,
      survey_page_length,
      productIssueReasonsMappingEnabled,
    } = this.state;
    const tagsListing = tagsList && tagsList.length ? tagsList : [];
    const user = cookie.load("user");
    const { enable_ticket_creation, enable_ticket_activity } = user || {};
    const { business_id } = this.props.match.params;
    return (
      <div>
        <Row className="content-area dashboard-page">
          <LoaderComponent loader={this.props.loader} />
          <Col xs={12} sm={12} md={12} className="py-3 px-4 business-setup">
            <Row>
              <Col sm="12">
                <TicketFilter
                  history={this.props.history}
                  match={this.props.match}
                  downloadExcel={this.toggleDownloadModal}
                  teamList={
                    teamList &&
                    teamList?.results?.map((item) => ({
                      display_name: `${item.first_name} ${item.last_name}`,
                      value: item.id,
                    }))
                  }
                  replyOptions={ticketStatusOptions && ticketStatusOptions.choices}
                  ticketSourceOptions={ticketSourceOptions && ticketSourceOptions.choices}
                  pageSizeCookie="tickets_page"
                />
                <TicketActionBar
                  location={this.props.location}
                  history={this.props.history}
                  onManageTagClick={this.toggleManageTagModal}
                  enable_ticket_creation={!enable_ticket_creation}
                  toggleCreateTicketModal={this.toggleCreateTicketModal}
                />
              </Col>
              <Col>
                <TicketList
                  data={tickets && tickets.results}
                  responseOptions={responseOptions && responseOptions.choices}
                  ticketActivity={enable_ticket_activity}
                  toggleTicketModal={lockedModules["ticket"] && enable_ticket_creation && this.toggleTicketModal}
                  toggleViewTicketModal={this.toggleTicketModal}
                  location={this.props.location}
                  history={this.props.history}
                  patchTicket={this.patchTicket}
                  businessId={business_id}
                  getData={this.getData}
                  tags={tagsListing}
                  postTag={this.postTag}
                  handleTagFilter={this.handleTagFilter}
                  sendMail={this.sendMail}
                />
                {/* <TicketReviewList
                  location={this.props.location}
                  history={this.props.history}
                  match={this.props.match}
                  postTag={this.postTag}
                  handleTagFilter={this.handleTagFilter}
                  tags={tagsListing}
                  data={tickets && tickets.results}
                  toggle={this.toggleTicketModal}
                /> */}
              </Col>
              <PaginationComponent
                count={tickets && tickets.count}
                location={this.props.location}
                pageSizeCookie="tickets_page"
              />
              <FormModal
                isOpen={this.state.isTicketModal}
                toggle={this.toggleCreateTicketModal}
                size="lg"
                width={"700px"}
                heading="Create Ticket"
              >
                <CreateTicketForm
                  onSubmit={this.handleTicketForm}
                  // assigneesOptions={teamList && teamList.results ? teamList.results.map((item) => ({display_name: `${item.first_name} ${item.last_name}`, value: item.id })) : []}
                  assigneesOptions={
                    totalTeamMember &&
                    totalTeamMember.map((item) => ({
                      display_name: `${item.first_name} ${item.last_name}`,
                      value: item.id,
                    }))
                  } 
                  branchOptions={branchOptions && branchOptions.choices}
                  // getTeamList={teamList?.results}
                  getTeamList={this.getTeamList}
                />
              </FormModal>
                <TagManageModalWrapper
                  isOpen={isManageTagModalOpen}
                  toggle={this.toggleManageTagModal}
                  onAddNewTagClick={this.toggleTagEditModal}
                >
                  <ManageTags
                    reviewsPage={true}
                    location={this.props.location}
                    toggleTagEditModal={this.toggleTagEditModal}
                    deleteTag={this.deleteTag}
                    handleTagFilter={this.handleTagFilter}
                    toggleConfirm={this.toggleConfirm}
                    tagsListing={tagsListing}
                    bodyClass = {true}
                  />
                </TagManageModalWrapper>

              {isTagEditOpen && (
                <TagEditModal
                  header={!tagDetail.id ? "Add a tag" : "Edit tag name"}
                  isOpen={isTagEditOpen}
                  width={"600px"}
                  showCloseBtn={false}
                  toggle={this.toggleTagEditModal}
                  paddingDense = {true}
                >
                  <TagAddForm
                    toggle={this.toggleTagEditModal}
                    initialValues={tagDetail}
                    addOrEditTag={this.addOrEditTag}
                  />
                </TagEditModal>
              )}
              {isDownloadModalOpen && (
                <LimitModal
                  isOpen={isDownloadModalOpen}
                  isReviewsPage={true}
                  toggle={() => this.setState({ isDownloadModalOpen: !isDownloadModalOpen })}
                  HeaderText={tickets.count < 5000 ? "Alrighty" : "Attention!"}
                  message={
                    tickets.count < 5000
                      ? ["Hey you are good to go. Click ok to download reviews"]
                      : ["You can only download 5000 reviews at a time.Click ok to continue."]
                  }
                  handleDownloadModal={this.handleDownloadModal}
                />
              )}
              <ConfirmationModal
                header="Are you sure you want to delete?"
                subHeader="This cannot be undone."
                isOpen={this.state.isConfirmOpen}
                toggle={this.toggleConfirm}
                onConfirm={this.state.functionToRun}
              />
                <TicketingReviewsModal
                  isOpen={this.state.isTicketModalOpen}
                  size={"lg"}
                  toggle={this.toggleTicketModal}
                  data={ticketDetails}
                  // teamList={teamList && teamList.results}
                  teamList={teamList && teamList.results}
                  manageTeamList={this.manageTeamList}
                  patchTicket={this.patchTicket}
                  addComment={this.addComment}
                  singleTicketDetails={this.props.ticketActions.singleTicketDetails}
                  businessId={business_id}
                  survey_pages={survey_pages}
                  ticketResolutionEnabled={ticketResolutionEnabled}
                  survey_page_length={survey_page_length}
                  survey_current_page={this.state.survey_current_page}
                  surveySubmitError={this.state.surveySubmitError}
                  getSurveyResponse={this.getSurveyResponse}
                  finalSubmitSurveyQuestin={this.finalSubmitSurveyQuestin}
                  handleSurveyPageChange={this.handleSurveyPageChange}
                  surveyFormLoading={this.state.surveyFormLoading}
                  productIssueReasonsMappingEnabled={productIssueReasonsMappingEnabled}
                  submitProductIssueReasonsMappingQuestions={this.submitProductIssueReasonsMappingQuestions}
                  issueReasonError={this.state.issueReasonError}
                  productMappingSubmitted={this.state.productMappingSubmitted}
                  disableTicketCommentBox={this.state.disableTicketCommentBox}
                  restrictTicketClosingOnSurvey={this.state.restrictTicketClosingOnSurvey}
                  ticketPage
                />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
TicketsPage.propTypes = {
  cityOptions: PropTypes.instanceOf(Object),
  stateOptions: PropTypes.instanceOf(Object),
  countryOptions: PropTypes.instanceOf(Object),
  reviewSourceOptions: PropTypes.instanceOf(Object),
  branchTagOptions: PropTypes.instanceOf(Object),
  replyOptions: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  ratingOptions: state.common.ratings,
  sentimentOptions: state.common.sentiment_options,
  loader: state.common.loader,
  business_userq: state.profile.business_user,
  teamList: state.team,
  tagsList: state.business.tags_list,
  tickets: state.tickets.tickets,
  ticketStatusOptions: state.tickets.status_options,
  ticketSourceOptions: state.tickets.source_options,
  branchOptions: state.business.branches,
  ticketDetails: state.tickets.ticket_details,
  cityOptions: state.common.city_options,
  stateOptions: state.common.state_options,
  countryOptions: state.common.country_options,
  reviewSourceOptions: state.review.review_source_options,
  branchTagOptions: state.common.branch_tag_options,
  businessInfo: state.business.detail,
  replyOptions: state.common.reply_options,
  resolutionDetail: state.tickets.ticketResolution,
  lockedModules: state.business.lockedModules || {},
});
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ReviewsActions, dispatch),
    commonActions: bindActionCreators(commonAction, dispatch),
    businessActions: bindActionCreators(BusinessSetupActions, dispatch),
    ticketActions: bindActionCreators(ticketActions, dispatch),
    profileActions: bindActionCreators(ProfileActions, dispatch),
    teamActions: bindActionCreators(TeamAction, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TicketsPage);
