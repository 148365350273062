import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import CardWrapper from '../../modules/dashboard/component/CardWrapper';

const UsageReportTableComponent = (props) => {
  const { data, dataHeader } = props;
  return (
    <div>
      <CardWrapper className="p-3" headerTitle={dataHeader}>
        <div className="ui-table-responsive">
          <Table className="fp-table fp-table-center">
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Invite Type</TableCell>
                <TableCell>Invite Success</TableCell>
                <TableCell>1st Request Send</TableCell>
                <TableCell>2nd Request Send</TableCell>
                <TableCell>Unsubscribed</TableCell>
                <TableCell>Total Invites</TableCell>
              </TableRow>
            </TableHead>
            { data && Object.keys(data).length > 0  &&
              <TableBody >
              
                <TableRow key="sms" >
                  <TableCell>1</TableCell>
                  <TableCell>SMS</TableCell>
                  <TableCell>
                    { data.success_sms_invite && data.success_sms_invite.value}
                  </TableCell>
                  <TableCell>
                    { data.active_sms_invite && data.active_sms_invite.value}
                  </TableCell>
                  <TableCell>
                    { data.in_process_sms_invite && data.in_process_sms_invite.value}
                  </TableCell>
                  <TableCell>
                    { data.disabled_sms_invite && data.disabled_sms_invite.value}
                  </TableCell>
                  <TableCell>
                    {data.sms_invite_count}
                  </TableCell>
                </TableRow>
                <TableRow key="email">
                  <TableCell>2</TableCell>
                  <TableCell>E-Mail</TableCell>
                  <TableCell>
                    { data.success_email_invite && data.success_email_invite.value}
                  </TableCell>
                  <TableCell>
                    { data.active_email_invite && data.active_email_invite.value}
                  </TableCell>
                  <TableCell>
                    { data.in_process_email_invite && data.in_process_email_invite.value}
                  </TableCell>
                  <TableCell>
                    { data.disabled_email_invite && data.disabled_email_invite.value}
                  </TableCell>
                  <TableCell>
                    {data.email_invite_count}
                  </TableCell>
                </TableRow>
              </TableBody>}
            {
              (!data || !Object.keys(data).length) &&
              <TableBody>
                <TableRow><TableCell className="text-center" colSpan={4}>No records Found </TableCell></TableRow>
              </TableBody> 
            }             
          </Table>
        </div>
      </CardWrapper>
    </div>
  );
};

UsageReportTableComponent.propTypes = {
  data: PropTypes.array.isRequired,
};

export default UsageReportTableComponent;