import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

// Define colors for the busyness levels
const COLORS = {
  LEAST_BUSY: "#EDE7F6",
  LITTLE_BUSY: "#D1C4E9",
  MODERATELY_BUSY: "#B39DDB",
  VERY_BUSY: "#7E57C2",
  BUSIEST: "#5E35B1",
};

// Define readable names for the busyness levels
const nameMapping = {
  LEAST_BUSY: "Least Busy",
  LITTLE_BUSY: "Little Busy",
  MODERATELY_BUSY: "Moderately Busy",
  VERY_BUSY: "Very Busy",
  BUSIEST: "Busiest",
};

const DayBarChart = (props) => {
  const { listing_popular_times } = props;

  const [dynamicDayBusyness, setDynamicDayBusyness] = useState({});

  useEffect(() => {
    if (listing_popular_times?.days) {
      const footfallValues = Object.values(listing_popular_times.days);

      const maxFootfall = Math.max(...footfallValues);
      const minFootfall = Math.min(...footfallValues);
      const range = maxFootfall - minFootfall;

      const dayBusyness = Object.keys(listing_popular_times.days).reduce((acc, day) => {
        const footfall = listing_popular_times.days[day];

        let busynessLevel = "LEAST_BUSY";  

        // if (footfall === 0.1) busynessLevel = "LEAST_BUSY";  
        // else if (footfall === maxFootfall) busynessLevel = "BUSIEST";  
        // else if (footfall >= maxFootfall - range * 0.2) busynessLevel = "VERY_BUSY";  
        // else if (footfall >= maxFootfall - range * 0.4) busynessLevel = "MODERATELY_BUSY";  
        // else busynessLevel = "LITTLE_BUSY";  

        if (footfall === minFootfall) {
          busynessLevel = "LEAST_BUSY"; // Explicitly handle the least busy case
        } else if (footfall === maxFootfall) {
          busynessLevel = "BUSIEST"; // Explicitly handle the busiest case
        } else if (footfall >= maxFootfall - range * 0.2) {
          busynessLevel = "VERY_BUSY";
        } else if (footfall >= maxFootfall - range * 0.4) {
          busynessLevel = "MODERATELY_BUSY";
        } else {
          busynessLevel = "LITTLE_BUSY";
        }
        
        
        acc[day] = busynessLevel;
        return acc;
      }, {});

      setDynamicDayBusyness(dayBusyness);
    }
  }, [listing_popular_times]);

  // if (Object.keys(dynamicDayBusyness).length === 0) {
  //   return <div>Loading...</div>;
  // }

  const labels = Object.keys(dynamicDayBusyness);

  const datasets = [
    {
      label: "Footfall Traffic Level",
      backgroundColor: labels.map((day) => COLORS[dynamicDayBusyness[day]]),
      borderColor: labels.map((day) => COLORS[dynamicDayBusyness[day]]),    
      borderWidth: 3,
      borderRadius: 7,
      barThickness: 40,
      data: labels.map((day) => listing_popular_times?.days ? listing_popular_times?.days[day] : ""),
    },
  ];

  const shortDayLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat","Sun",];  // Shortened day names for X-axis

  const chartData = { labels: shortDayLabels, datasets };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          borderDash: [7],
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
      x: {
        grid: { display: false },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            const dataIndex = context.dataIndex; 
            const day = Object.keys(dynamicDayBusyness)[dataIndex]; 
            const level = nameMapping[dynamicDayBusyness[day]];
            return level;
            // return `Footfall Traffic Level: ${level}`;
          },
        },
      },
      
    },
    maintainAspectRatio: false,
    animation: { duration: 1000 },
  };

  return (
    <div style={{ height: "500px", width: "100%" }}>
      <Bar data={chartData} options={options} />
      <div style={{ textAlign: "center", marginTop: "10px" }}>
        {Object.entries(nameMapping).map(([key, name]) => (
          <span key={key} style={{ margin: "0 15px", display: "inline-block" }}>
            <span
              style={{
                display: "inline-block",
                width: "12px",
                height: "12px",
                backgroundColor: COLORS[key],  // Display the corresponding color
                borderRadius: "50%",
                marginRight: "5px",
              }}
            ></span>
            {name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default DayBarChart;
