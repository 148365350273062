import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Divider } from '@mui/material';
import CustomCheckbox from '../../../../components/common/CustomCheckbox';

const CheckboxGroup = ({ title, options, onSelectionChange, value = [] }) => {
  const [selectedValues, setSelectedValues] = useState(value);

  useEffect(() => {
    setSelectedValues(value); // Update state if the prop `value` changes
  }, [value]);

  const handleCheckboxChange = (event, id) => {
    const { checked } = event.target;
    let newSelected = [...selectedValues];

    if (checked) {
      newSelected.push(id);
    } else {
      newSelected = newSelected.filter(item => item !== id);
    }

    setSelectedValues(newSelected);
    onSelectionChange(newSelected); // Pass updated values back
  };

  return (
    <Paper
      elevation={0}
      sx={{
        border: '1px solid #FFD6E0',
        borderRadius: '16px',
        overflow: 'hidden',
        mb: 2,
        background:"#FAFAFB"
      }}
    >
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 'bold', m: 1 }}>
          {title}
        </Typography>

        <Box>
          {options.map((option, index) => (
            <React.Fragment key={option.id}>
              <CustomCheckbox
                label={option.title}
                checked={selectedValues.includes(option.id)}
                onChange={(e) => handleCheckboxChange(e, option.id)}
                name={`checkbox-${option.id}`}
              />
              {index < options.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default CheckboxGroup;
