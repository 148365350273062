import React from 'react';
import { Label, Icon } from 'semantic-ui-react';
import TendingKeyWordBadge from '../../modules/dashboard/component/TendingKeyWordBadge';

const WordCloudTable = (props) => {
  const { type, data, } = props;

  return (
    <div className='word-cloud-table'>
      {data && JSON.stringify(data) === '{}' ? (
        <Label className='tending-keyword-no-data'>
          <Icon name="warning circle" /> <span> No word data to show at the moment. </span>
        </Label>
      ) : (
        Object.keys(data).map((key, index) => {
          return (
            <TendingKeyWordBadge
              key={`${index}-${key}`}
              label={key}
              value={data[key]}
              type={type}
            />
          );
        })
      )}
    </div>
  );
};

export default WordCloudTable;
