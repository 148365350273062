import React from "react";
import FPButton from "../FPButton";
import { IconButton } from "@material-ui/core";
import { ReactComponent as ResetIcon } from "../../../../images/svg/Reset.svg";
import FPToolTip from "../FPToolTip";

const ActionButton = ({
  onResetClick,
  actionRender,
  applyBtnText,
  hideResetBtn,
  lightResetBtn,
  applyButtonDisable
}) => {
  return (
    <div className="d-flex justify-content-between align-items-start gap-10">
      <FPButton
        type="submit"
        label={applyBtnText || "Apply"}
        size="small"
        className="apply-Btn"
        disabled={!applyButtonDisable}
      />
      {actionRender && typeof actionRender === "function"
        ? actionRender()
        : actionRender}
      {!hideResetBtn && (
        <FPToolTip title={"Reset"}>
          <IconButton
            component="span"
            className="circular-button"
            style={{
              // backgroundColor: lightResetBtn ? "#ffffff" : "#1B1C1D",
              // color: lightResetBtn ? "#1B1C1D" : "#ffffff",
              backgroundColor: "#1B1C1D",
              color: "#ffffff",
            }}
            onClick={onResetClick}
          >
            <ResetIcon />
          </IconButton>
        </FPToolTip>
      )}
    </div>
  );
};

export default ActionButton;
