/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import axios_instance from "../../libs/interseptor";
import { Row, Col } from "reactstrap";
import {
  Field,
  reduxForm,
  formValueSelector,
  FieldArray,
  change,
} from "redux-form";
import { Form } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import { parse, stringify } from "query-string";

import Dropdown from "../../components/common/Dropdown/Dropdown";
import DateInput from "../../components/common/DateInput";
import InputComponent from "../../components/common/InputComponent";
import { CONTACTS_FILTER, CONTACTS_FILTER_OPTIONS } from "../../libs/apiUrls";
import { format } from "../../libs/utils";
import { IconButton } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { FPButton } from "../common/new";
import ResetIcon from "../../images/Reset.png";
import AddButton from "../../images/plus.png";

const Selector = ({ type, ...props }) => {
  const { input } = props;
  const handleDateChange = (date) => {
    input.onChange({ value: moment(date).format("YYYY-MM-DD") });
  };
  switch (type) {
    case "list":
      return <Dropdown multi {...props} />;
    case "text":
      return <InputComponent type="text" {...props} />;
    case "number":
      return <InputComponent type="number" min="0" {...props} />;
    case "date":
      return (
        <DateInput
          {...props}
          className="date-input"
          onChange={handleDateChange}
          value={input.value.value}
          openInContainer
        />
      );
    default:
      return <Dropdown {...props} />;
  }
};

const validate = (values) => {
  const errors = { filters: [] };
  values.filters &&
    values.filters.map(({ filter, type, field }, index) => {
      const err = {};
      if (!filter) err.filter = "Select Filter";
      if (!field || (Array.isArray(field) && field.length === 0))
        err.field = "Select Filter Values";
      if (!type) err.type = "Select Filter Type";
      errors.filters[index] = err;
    });
  return errors;
};

const renderFilters = ({
  fields,
  filterOptions,
  business_id,
  setFieldValue,
  selectedValue,
  reset,
}) => {
  const [fieldOptions, setFieldOptions] = useState({});
  const handleFilterChange = ({ value }) => {
    if (value && !fieldOptions[value])
      axios_instance
        .get(format(CONTACTS_FILTER_OPTIONS, [business_id, value]))
        .then((res) => {
          setFieldOptions({ ...fieldOptions, [value]: res.data.choices });
        })
        .catch(() => {
          setFieldOptions({ ...fieldOptions });
        });
  };
  return (
    <div>
      <Row className="mb-2 filterHead">
        <Col sm="11">
          {/* {!Array.isArray(selectedValue) || selectedValue.length<5 && <FPButton
            onClick={()=>fields.push({})}
            label="Add Rule"
          />} */}
          <span style={{ fontSize: "16px", fontWeight: 500 }}>Add Rule</span>
        </Col>
        <Col
          className="px-0 me-3"
          sm="1"
          style={{ marginTop: "-14px", color: "#000000", lineHeight: "19.2px" }}
        >
          {!Array.isArray(selectedValue) ||
            (selectedValue.length < 5 && (
              <IconButton className="addBtn" onClick={() => fields.push({})}>
                <img
                  src={AddButton}
                  style={{ width: "20px", height: "20px" }}
                />
              </IconButton>
            ))}
        </Col>
      </Row>

      {fields.map((member, index) => {
        const selectedFilterValue = (fields.get(index).filter || {}).value;
        const selectedFilterMember =
          filterOptions.find(
            (i) => i.filter_name.value === selectedFilterValue
          ) || {};
        const handleType = ({ value }) => {
          const temp =
            filterOptions.find((i) => i.filter_name.value === value) || {};
          if (temp.filter_type) {
            const val = temp.filter_type.map((value) => ({
              display_name: value.split("_").join(" "),
              value,
            }))[0];
            setFieldValue("contactsExtraFilters", `${member}.type`, val);
          } else {
            setFieldValue("contactsExtraFilters", `${member}.type`, "");
          }
        };
        return (
          <Row key={index} className="">
            <Col sm="4" className="pr-0">
              <Field
                name={`${member}.filter`}
                component={Selector}
                placeholder="Select Filter"
                options={filterOptions
                  .filter(
                    (i) =>
                      selectedValue.filter(
                        (j) => (j.filter || {}).value === i.filter_name.value
                      ).length === 0
                  )
                  .map((i) => ({
                    value: i.filter_name.value,
                    display: i.filter_name.display_name,
                  }))}
                labelKey="display"
                valueKey="value"
                onChange={(value) => {
                  handleFilterChange(value);
                  setFieldValue("contactsExtraFilters", `${member}.field`, "");
                  handleType(value);
                }}
              />
            </Col>
            <Col sm="4">
              <Field
                name={`${member}.field`}
                placeholder={`Select ${
                  (selectedFilterMember.filter_name || {}).display_name || ""
                }`}
                type={selectedFilterMember.field_type}
                component={Selector}
                options={fieldOptions[selectedFilterValue] || []}
                labelKey="display_name"
                valueKey="value"
                searchFilter
              />
            </Col>
            <Col sm="3" className="pl-0">
              <Field
                name={`${member}.type`}
                placeholder="Select Type"
                component={Selector}
                options={
                  selectedFilterMember.filter_type
                    ? selectedFilterMember.filter_type.map((value) => ({
                        display_name: value.split("_").join(" "),
                        value,
                      }))
                    : []
                }
                labelKey="display_name"
                valueKey="value"
              />
            </Col>
            <Col sm="1" className="px-0">
              <IconButton
                component="span"
                className="circular-button"
                onClick={(e) => {
                  e.preventDefault();
                  fields.remove(index);
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            </Col>
          </Row>
        );
      })}
      {/* <Divider/> */}
      {/* <Col sm="6">
          {!Array.isArray(selectedValue) || selectedValue.length<5 && <FPButton
            onClick={()=>fields.push({})}
            startIcon={
              <AddOutlinedIcon style={{ width: 16, height: 16 }} />
            }
            label="Add Rule"
            backgroundColor="#1A2544"
            size="small"
            fontSize="12px"
          />}
        </Col> */}
      {/* <Col sm="12" className='d-flex justify-content-between gap-10 filterFooterBtn'>
          <FPButton type='submit' label='Apply' fontSize='16px' className='button'/>
          <FPButton type="button" onClick={reset} label='Reset' fontSize='16px' backgroundColor='#00B5AD' className='button'
            startIcon={<img src={ResetIcon} />}
          />
        </Col> */}
      <div className="d-flex gap-10 justify-content-end">
        <FPButton type="submit" fontSize="16px" style={{ height: "34px" }}>
          Apply
        </FPButton>
        <FPButton
          type="button"
          onClick={reset}
          fontSize="16px"
          backgroundColor="#00B5AD"
          style={{ height: "34px" }}
          startIcon={<img src={ResetIcon} />}
        >
          Reset
        </FPButton>
      </div>
    </div>
  );
};

let ContactsExtraFilters = ({
  handleReset,
  className,
  history,
  location,
  reset,
  ...props
}) => {

  const [filterOptions, setFilterOptions] = useState([]);
  const wrapperRef = useRef(null);
  const { onSubmit, handleSubmit, close, business_id } = props;
  useEffect(() => {
    document.addEventListener("click", handleClick, false);
    axios_instance.get(CONTACTS_FILTER).then((res) => {
      setFilterOptions(res.data.response);
    });
    return () => {
      document.removeEventListener("click", handleClick, false);
    };
  }, []);

  const handleClick = (e) => {
    if (e.defaultPrevented) return;
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      // close?();
    }
  };

  const resetFilters = () => {
    const { pathname, search } = location;
    const queryparams = parse(search);
    filterOptions.map(({ filter_name }) => {
      delete queryparams[filter_name.value];
      delete queryparams[`${filter_name.value}_type`];
    });
    handleReset();
    history.push({ pathname, search: stringify(queryparams) });
  };
  

  return (
    <div className={className} id="contact-extra-filters" ref={wrapperRef}>
      <Form onSubmit={handleSubmit((data) => onSubmit(data))}>
        <FieldArray
          name="filters"
          component={renderFilters}
          filterOptions={filterOptions}
          business_id={business_id}
          setFieldValue={props.change}
          selectedValue={props.filters}
          reset={() => {
            resetFilters();
            reset();
          }}
        />
      </Form>
    </div>
  );
};

ContactsExtraFilters = connect(
  (state) => ({
    filters: formValueSelector("contactsExtraFilters")(state, "filters") || {},
  }),
  {
    change,
  }
)(ContactsExtraFilters);

export default reduxForm({
  form: "contactsExtraFilters",
  enableReinitialize: true,
  validate,
})(ContactsExtraFilters);
