import React, { useState } from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import SearchComponent from "../../components/common/SearchComponent";
import ContactsExtraFilters from "./ContactsExtraFIlters";
import queryString from "query-string";
import { connect } from "react-redux";
import { formValueSelector } from "redux-form";
import { FPButton } from "../common/new";
import { Popover } from "@material-ui/core";
import "../contacts/contact.css";

let ContactsFilter = (props) => {
  const [showFilters, setShowFilters] = useState(null);

  const {
    initialFilters,
    setInitialFilters,
    history,
    location,
    match: {
      params: { business_id },
    },
    extraFilters,
    selectedContacts,
    contactsSelector,
    reviewContacts,
    filterButton = false,
    campaignContacts,
    toggleAllSelected,
    contactsCount,
    clearContacts,
  } = props;

  const handleClose = () => {
    setShowFilters(null);
  };

  const open = Boolean(showFilters);

  const handleReset = () => {
    setInitialFilters && setInitialFilters({ filters: [{}] });
  };

  const onFilterBtnClick = (e) => {
    setShowFilters(e.currentTarget);
  };

  const extraFiltersSubmit = (data) => {
    setShowFilters(null);
    const params = queryString.parse(history.location.search);
    let query = { search: params.search, page: 1, page_size: params.page_size };
    data.filters.map((item) => {
      query[`${item.filter.value}`] = Array.isArray(item.field)
        ? item.field.map((i) => i.value).join()
        : item.field.value || item.field;
      query[`${item.filter.value}_type`] = item.type.value;
    });
    setInitialFilters && setInitialFilters(data);
    history.push({
      pathname: history.location.pathname,
      search: queryString.stringify(query),
    });
  };

  const pageSize = queryString.parse(history.location.search).pageSize || "10";

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center w-100">
          {/* <p className="mb-0 fp-card-header">All Contacts</p> */}
          {selectedContacts?.length > 0 &&
            (!clearContacts ? (
              <div>
                <span className="ml-10" style={{ fontSize: "13px", fontWeight: "500" }}>
                  {Number(pageSize) === selectedContacts.length
                    ? `All ${selectedContacts.length} contacts on this page are selected.`
                    : `${selectedContacts.length} contacts on this page are selected.`}
                </span>
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#00B5AD",
                    cursor: "pointer",
                    marginLeft: "4px",
                  }}
                  onClick={toggleAllSelected}
                >{`Select all ${contactsCount} contacts`}</span>
              </div>
            ) : (
              <div>
                <span className="ml-10" style={{ fontSize: "13px", fontWeight: "500" }}>
                  {`All ${selectedContacts.length} contacts are selected.`}
                </span>
                <span
                  style={{
                    fontSize: "15px",
                    fontWeight: "400",
                    color: "#00B5AD",
                    cursor: "pointer",
                    marginLeft: "4px",
                  }}
                  onClick={toggleAllSelected}
                >
                  Clear Selection
                </span>
              </div>
            ))}
        </div>
        {!reviewContacts && (
          <div className="d-flex align-items-center gap-10">
            {!!campaignContacts && (
              <FPButton
                label={`Filters${extraFilters && extraFilters.length > 1 ? ` +${extraFilters.length}` : ""}`}
                endIcon={<i className="fa fa-filter mr-1" />}
                className="contact-RightBtn"
                backgroundColor="#28314F"
                onClick={(e) => onFilterBtnClick(e)}
              />
            )}
            <Popover
              open={open}
              anchorEl={showFilters}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <ContactsExtraFilters
                className="p-3"
                onSubmit={extraFiltersSubmit}
                // close={() => setShowFilters(null)}
                business_id={business_id}
                initialValues={initialFilters || { filters: [{}] }}
                handleReset={handleReset}
                history={history}
                location={location}
              />
            </Popover>
            <SearchComponent {...props} className="contacts-filter-search" placeholder="Search here" />

            {!contactsSelector && (
              <Menu
                compact
                style={{
                  backgroundColor: "#F13A88",
                  borderRadius: "20px",
                }}
              >
                <Dropdown text="Add/Upload" className="text-light AddUploadDropDown" pointing item>
                  <Dropdown.Menu>
                    <Dropdown.Item key={"1"} value={"5"} onClick={() => props.handleMoreOptions("5")} text>
                      {" "}
                      Add Contacts
                    </Dropdown.Item>
                    <Dropdown.Item key={"2"} value={"6"} onClick={() => props.handleMoreOptions("6")} text>
                      {" "}
                      Upload Contacts
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu>
            )}
          </div>
        )}
      </div>
      <div className="mt-2"></div>
    </div>
  );
};

ContactsFilter = connect((state) => ({
  extraFilters: formValueSelector("contactsExtraFilters")(state, "filters"),
}))(ContactsFilter);

export default ContactsFilter;
