/* eslint-disable */
import React from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";

import { reduxForm, SubmissionError } from "redux-form";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import { TypeAnimation } from "react-type-animation";
// import FormModal from '../common/FormModal';
// import { FPButton, FPMenuBox } from '../common/new';
// import { FPButton, FPMenuBox, MessageBox } from '../common/new';
import { FPButton, FPToolTip } from "../common/new";
import FPTextArea from "../common/new/forms/ui/FPTextArea";

import SendIcon from "@material-ui/icons/Send";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import { IconButton } from "@material-ui/core";
import SmartResponseModel from "./SmartResponseModel";
import "./comment.css";
import AiIcon from "../../images/svg/AiIcon.svg";
import SmartIcon from "../../images/svg/SmartIcon";

const validate = (values) => {
  const errors = {};
  if (!values.comments) errors.comments = "Enter your comment";
  return errors;
};

class CommentModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSend = this.handleSend.bind(this);
    this.copyComment = this.copyComment.bind(this);
    this.getInteligentResponse = this.getInteligentResponse.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleSmartResponseChange = this.handleSmartResponseChange.bind(this);
    this.state = {
      buttonText: null,
      selectedResponse: null,
      inteligentResponse: null,
      gettingResponse: false,
      apiResponse: false,
      apiResponseCollected: true,
      isSmartResOpen: false,
      smartResponse: null,
    };
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.responseDetail !== this.props.responseDetail) {
      this.setState({ smartResponse: nextProps.responseDetail.content });
    }
  };

  handleCommentChange(e) {
    const input = e.target.value;
    this.setState({ inteligentResponse: input });

    if (input.trim() !== "") {
      this.setState({ error: null });
    }
  }

  handleSmartResponseChange(e) {
    this.setState({ smartResponse: e.target.value });
  }

  getInteligentResponse() {
    this.setState({ gettingResponse: true, apiResponseCollected: false });
    const url = URLS.INTELIGENT_RESPONSE;
    let data = {
      review: this.props.selectedItem.description,
      rating: this.props.selectedItem.rating,
      review_id: this.props.selectedItem.id,
    };
    axios_instance
      .post(url, data)
      .then((res) => {
        this.setState({
          gettingResponse: false,
          apiResponse: true,
          inteligentResponse: res.data.data.response,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          inteligentResponse: null,
          gettingResponse: false,
          apiResponse: false,
          apiResponseCollected: true,
        });
      });
  }

  handleCopyNRedirect = (e) => {
    e.preventDefault();
    this.copyComment();
    this.props.selectedItem
      ? window.open(this.props.selectedItem.review_link, "_blank", "height=600, width= 500, left=400, top=400")
      : null;
    const data = {
      object_id: this.props.review_id,
      tag_name: this.state.selectedResponse,
    };
    const url = `${URLS.REVIEWS_DETAILS}/${this.props.bId}/smart_response/`;
    axios_instance.post(url, data).catch((err) => console.log(err));
  };

  copyComment() {
    const copyText = document.getElementById("comments");
    const { selectedItem } = this.props;
    this.props.handleReplyOption("responded", selectedItem.id);
    function listener(e) {
      e.clipboardData.setData("text/plain", copyText.innerHTML);
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
  }

  handleSend(data) {
    this.props.toggle(data);
    const { addComment } = this.props;

    const comment = this.state.inteligentResponse || this.state.smartResponse || "";

    if (comment.trim() === "") {
      this.setState({ error: "Comment cannot be empty or whitespace only" });
      return;
    }

    data["comments"] = comment;

    return addComment(data)
      .then(() => {
        this.setState({
          inteligentResponse: null,
          smartResponse: null,
          isSmartResOpen: false,
          error: null,
        });
      })
      .catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        this.setState({ error: errobj.detail });
        throw new SubmissionError(errobj);
      });
  }

  getResponse = (val) => {
    this.props.fetchResponse(this.props.selectedItem.id, val.value);
    this.setState({ selectedResponse: val.display_name });
  };

  render() {
    const { handleSubmit, selectedItem, responseOptions, manageTemplates, getResponseOptions, businessInfo, description, location } =
      this.props;
    const { is_superuser } = cookie.load("user") || {};
    const height = "188.58px";
    return (
      <div style={{ marginBottom: "-37px" }}>
        {businessInfo && businessInfo.ai_response === false && (
          <div
            style={{
              fontSize: "12px",
              color: "rgb(214 6 0 / 88%)",
              marginBottom: "12px",
            }}
          >
            You are not authorised to AI Response please contact to famepilot!
          </div>
        )}
        {/* <div style={{ display: 'flex', marginBottom: '10px' }} className="gap-10">
          <div>
            {!is_superuser && (
              <FPButton
                startIcon={<BsMagic style={{width: '14px', height: '13px',transform: 'rotate(280deg)' }} />}
                type="submit"
                onClick={this.getInteligentResponse}
                label="Ai Reply"
                size="small"
                disabled={businessInfo && !businessInfo.ai_response}
              />
            )}
          </div>
          <div className="mb-4">
            {getResponseOptions &&
              businessInfo && businessInfo.smart_response && (
              <FPButton
                startIcon={<SmartResponse />}
                type="submit"
                label="Smart Response"
                size="small"
                backgroundColor="#ffffff"
                onClick={() => this.setState({ isSmartResOpen: true })}
                variant="outline"
              />
            )}
          </div>
        </div> */}

        <form>
          <div className="position-relative">
            {this.state.gettingResponse && (
              <TypeAnimation
                sequence={[".", 1000, ". .", 1000, ". . .", 1000]}
                wrapper="div"
                cursor={true}
                repeat={2}
                style={{
                  fontSize: "14px",
                  background: "rgb(246, 246, 248)",
                  height: height,
                  padding: "16px",
                  border: "none",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              />
            )}
            {this.state.apiResponse && (
              <TypeAnimation
                sequence={[
                  `${this.state.inteligentResponse}`,
                  1000,
                  () => {
                    this.setState({
                      apiResponseCollected: true,
                      apiResponse: false,
                    });
                  },
                ]}
                wrapper="div"
                cursor={true}
                style={{
                  fontSize: "14px",
                  background: "rgb(246, 246, 248)",
                  height: height,
                  padding: "16px",
                  border: "none",
                  borderRadius: "10px",
                  marginBottom: "10px",
                }}
              />
            )}
            {/* Used */}
            <div className="" style={{ marginBottom: "10px" }}>
              {this.state.apiResponseCollected && (
                <FPTextArea
                  autoHeight
                  name="comments"
                  onChange={this.handleCommentChange}
                  value={this.state.inteligentResponse}
                  placeholder="Comment"
                  sx={{
                    backgroundColor: "rgb(246, 246, 248)",
                    borderRadius: "10px",
                    paddingBottom: "0",
                    "& .fp-textarea": {
                      backgroundColor: "rgb(246, 246, 248)",
                      fontSize: "14px",
                      border: "none",
                      maxHeight: 140,
                      marginBottom: "30px",
                    },
                  }}
                  minRows={7}
                />
              )}

              <div
                className="ai_Smart-Icon"
                // style={{
                //   bottom: this.state.gettingResponse ? "18px" : "10px",
                // }}
              >
                {!is_superuser && (
                  <FPToolTip title={"AI Reply"} className="mr-2">
                    <div
                      onClick={this.getInteligentResponse}
                      disabled={businessInfo && !businessInfo.ai_response}
                      className="cursor-pointer d-flex"
                      style={businessInfo && !businessInfo.ai_response ? { opacity: 0.5, pointerEvents: "none" } : null}
                    >
                      <AiIcon />
                    </div>
                  </FPToolTip>
                )}
                {getResponseOptions && businessInfo && businessInfo.smart_response && (
                  <FPToolTip title={"Smart Template Reply"} className="mr-2">
                    <div onClick={() => this.setState({ isSmartResOpen: true })} className="cursor-pointer d-flex">
                      <SmartIcon />
                    </div>
                  </FPToolTip>
                )}
              </div>
            </div>
          </div>

          <p className="text-danger mb-10">{this.state.error}</p>
        </form>
        <div className="d-flex gap-10 justify-content-end">
          <FPButton
            label="Cancel"
            size="small"
            variant="outline"
            onClick={() => {
              this.setState({ inteligentResponse: "", error: null });
              this.props.onClose();
            }}
            style={{ height: "27px" }}
          />
          {selectedItem && selectedItem.is_reply_allow === true && (
            <FPButton
              type="submit"
              label="Send Reply"
              size="small"
              endIcon={<SendIcon style={{ height: "16px", width: "16px" }} />}
              onClick={handleSubmit(this.handleSend)}
              style={{ height: "27px" }}
            />
          )}
          {selectedItem && (selectedItem.provider_name === "zomato" || selectedItem.is_reply_allow === false) && (
            <FPToolTip title="Copy And Redirect">
              <IconButton
                component="span"
                className="circular-button"
                onClick={(e) => this.handleCopyNRedirect(e)}
                style={{
                  backgroundColor: "#438E96",
                  color: "white",
                  height: "28px",
                  width: "28px",
                }}
              >
                <FileCopyOutlinedIcon
                  style={{
                    width: "14px",
                    height: "14px",
                  }}
                />
              </IconButton>
            </FPToolTip>
          )}
        </div>

          <SmartResponseModel
          description={description}
            responseOptions={responseOptions}
            manageTemplates={manageTemplates}
            isOpen={this.state.isSmartResOpen}
            toggle={() => this.setState({ isSmartResOpen: false, smartResponse: "" })}
            onSend={handleSubmit(this.handleSend)}
            getResponse={this.getResponse}
            inteligentResponse={this.state.smartResponse}
            selectedItem={selectedItem}
            handleCommentChange={this.handleSmartResponseChange}
            location={location}
          />
      </div>
    );
  }
}

CommentModal = reduxForm({
  // eslint-disable-line
  form: "CommentModal",
  enableReinitialize: true,
  validate,
})(CommentModal);

const mapStateToProps = (state) => {
  return {
    businessInfo: state.business.detail,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(CommentModal);
