import React from "react";
import { Field, reduxForm,SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { InputField } from "react-semantic-redux-form";
import { FPButton } from "../common/new";
import FPLabel from "../common/new/forms/ui/FPLabel";

const validate = (values) => {
  const error = {};
  if (!values.name) error.name = "This field may not be blank.";
  if (values.name && values.name.length > 200)
    error.name = "Designation name must have less than 200 characters";
  return error;
};

class DesignationForm extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  submitDesign = (data) => {
    const { addDesignation } = this.props;

    return addDesignation(data).catch((err) => {
      const errObj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errObj);
    });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit(this.submitDesign)}>
        <div>
          <FPLabel>Designation</FPLabel>
          <Field
            name="name"
            component={InputField}
            className="w-100"
            placeholder="Designation"
            type="text"
          ></Field>
        </div>
        <FPButton
          disabled={submitting}
          type="submit"
          fullWidth
          className="mt-3"
          size="small"
        >
          Submit
        </FPButton>
      </form>
    );
  }
}

DesignationForm = reduxForm({
  form: "DesignationForm",
  enableReinitialize: true,
  validate,
})(DesignationForm);

DesignationForm = connect(() => ({}))(DesignationForm);

export default DesignationForm;
