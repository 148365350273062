import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import * as commonAction from "../common/commonAction";

export function getDesignationSuccess(data) {
  return { type: types.GET_DESIGNATION_SUCCESS, data };
}

export function getDesignationFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_DESIGNATION_FAILURE, data };
}

export const getDesignation = (data, businessId) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.GET_DESIGNATION, [businessId]), { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getDesignationSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getDesignationFailure(err));
        throw err.response.data;
      });
  };
};

export function designationAddSuccess(data) {
  return { type: types.CREATE_DESIGNATION_SUCCESS, data };
}

export function designationAddFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.CREATE_DESIGNATION_FAILURE, data };
}

export const designationAdd = (data, businessId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(`${utils.format(URLS.GET_DESIGNATION, [businessId])}`, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(designationAddSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(designationAddFailure(err));
        throw err.response.data;
      });
  };
};

export function designationEditSuccess(data) {
  return { type: types.EDIT_DESIGNATION_SUCCESS, data };
}

export function designationEditFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.EDIT_DESIGNATION_FAILURE, data };
}

export const designationEdit = (data) => {
  const { design: name, business_id: businessId, designationId: id } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .put(utils.format(URLS.DESIGNATION, [businessId, id]), name)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(designationEditSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(designationEditFailure(err));
        throw err.response.data;
      });
  };
};

export function designationDeleteSuccess(data) {
  return { type: types.DELETE_DESIGNATION_SUCCESS, data };
}

export function designationDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.DELETE_DESIGNATION_FAILURE, data };
}

export const designationDelete = (business_id, data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(utils.format(URLS.DESIGNATION, [business_id, data.id]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(designationDeleteSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(designationDeleteFailure(err));
        throw err.response.data;
      });
  };
};
