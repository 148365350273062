import React, { useEffect, useState } from "react";
import { FPMenu, FPMenuItem } from "../../components/common/new";
import { Avatar, ListItemText } from "@material-ui/core";
import { connect } from "react-redux";
import { nextPageUrl } from "../../libs/utils";
import { bindActionCreators } from "redux";
import * as ProfileActions from "../../actions/accounts/profileAction";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import cookie from "react-cookies";
import { Icon } from "semantic-ui-react";
import classNames from "classnames";
import * as dashboardAction from "../../actions/common/dashboardAction"; 

const RestaurantProvider = (props) => {
  const { businessList, history, profileActions, business, showList, uploadLogo, isStaff, dashboardAction } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [providerList, setProviderList] = useState([]);
  const currentBusiness = business && business.detail;

  useEffect(() => {
    const { is_superuser } = cookie.load("user") || {};
    let newBusinessList = [];
    if (isStaff) {
      newBusinessList = businessList && currentBusiness
        ? businessList?.results?.filter((provider) => currentBusiness.id !== provider.id)
        : [];
    } else {
      newBusinessList = businessList && Array.isArray(businessList)  
        ? businessList?.filter((provider) => currentBusiness?.id !== provider?.id) 
        : []
    }
    const newSuperList =
      businessList && currentBusiness && Array.isArray(businessList)
        ? businessList?.results?.filter((provider) => currentBusiness.id !== provider.id)
        : [];
    const newList = !!is_superuser ? newSuperList : newBusinessList;
    setProviderList(() => newList);
  }, [currentBusiness, businessList]);

  const handleClick = (event) => {
    showList && setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRestaurantChange = async (businessId)  => {
    await dashboardAction.clearDashboardApiData()
    profileActions.getBusinessProfile(businessId).then((res) => {
      const { business, corporate_branch, business_type, marketing_id, screen_name, is_superuser, is_business_owner } =
        res.data;

      const url = nextPageUrl(
        business_type,
        is_superuser,
        screen_name,
        business,
        corporate_branch,
        marketing_id,
        is_business_owner
      );
      history.push(url);
    });
    handleClose();
  };

  return currentBusiness && Object.keys(currentBusiness)?.length > 0 ? (
    <React.Fragment>
      <div
        onClick={handleClick}
        role="button"
        className={classNames("d-flex gap-6 align-center cursor-pointer provider-label svgMobile", {
          "": providerList?.length > 0,
        })}
      >
        <div className="logo-avatar-container">
          <Avatar
            alt={currentBusiness ? currentBusiness.name : "Provider"}
            src={currentBusiness ? currentBusiness.logo : ""}
            style={{ width: "25px", height: "25px" }}
            className="b-1"
          />
          <label className="overlay" htmlFor="logo_file">
            <Icon name="camera" size="small" />
            <input id="logo_file" hidden type="file" accept=".jpg, .jpeg, .png, .gif" onChange={(e) => uploadLogo(e)} />
          </label>
        </div>
        <span className="line-height-1">{currentBusiness ? currentBusiness.name : "Provider"}</span>
        {showList && providerList?.length > 0 && <KeyboardArrowDownIcon />}
      </div>
      {showList && (
        <FPMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {providerList && Array.isArray(providerList) &&
            providerList?.map((restaurant, index) => {
              return (
                <FPMenuItem
                  key={`${index}-restaurantProvider`}
                  onClick={() => handleRestaurantChange(restaurant.id)}
                  showDivider={false}
                >
                  <ListItemText primary={restaurant.name} />
                </FPMenuItem>
              );
            })}
        </FPMenu>
      )}
    </React.Fragment>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  business: state.business,
});

function mapDispatchToProps(dispatch) {
  return {
    profileActions: bindActionCreators(ProfileActions, dispatch),
    dashboardAction: bindActionCreators(dashboardAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(RestaurantProvider);
