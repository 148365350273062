import React, { createRef } from "react";
import queryString from "query-string";
import CommentModal from "./CommentModal";
// import SmartResponseModel from './SmartResponseModel';
import * as utils from "../../libs/utils";
import { CSSTransitionGroup } from "react-transition-group";
import FeedbackImage from "./FeedbackImage";
import "react-responsive-modal/styles.css";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import TicketingTagsModal from "../tickets/TicketingTagsModal";
import cookie from "react-cookies";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { displayMessage } from "../../libs/utils";
import { reduxForm } from "redux-form";
import "./reviewList.css";
import { FPAvatar, FPButton, FPToolTip, MessageBox } from "../common/new";
import UserDetails from "../../modules/review/UserDetails";
import {
  ReviewCardHeader,
  ReviewHeaderActivity,
  ReviewText,
} from "../../modules/review";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import BorderColorOutlinedIcon from "@material-ui/icons/BorderColorOutlined";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import FPChip from "../common/new/FPChip/FPChip";
import { Box, Collapse, Divider } from "@material-ui/core";
import FormModal from "../common/FormModal";
import FPTextArea from "../common/new/forms/ui/FPTextArea";
import ConfirmationModal from "../common/ConfirmationModal";
import { connect } from "react-redux";
import AddReviewDetailsModal from "./AddReviewDetailsModal";
import "./comment.css";
import whiteTag from "../../images/svg/whiteTag.svg";
import { ReactComponent as HorizontalArrow } from "../../images/svg/horizontalArrow.svg";
import timeClock from "../../images/svg/timeClock.svg";
import AddPlus from "../../images/svg/addPlus.svg";
import internalRemark from "../../images/svg/internalRemark.svg";
import CustomStarRatingComponent from "../common/StarComponent";
import ViewTagActivityModal from "../common/ViewTagActivityModal";
import FameAiTagModal from "../common/FameAiTagModal";
import ProductTagModal from "./ProductTagModal";
import OrderDetailsModal from "./OrderDetailsModal";
import DynamicHeightList from "../DynamicHeightList/DynamicHeightList";
import TicketCardSkeleton from "../common/new/TicketCardSkeleton";

const excludedProviders = [
  "swiggy",
  "offline",
  "magicpin",
  "dineout",
  "eazydiner",
  "ewards",
  "reservego",
  "rista",
  "zomato_ors",
  "inhouse",
];

class ReviewsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      addReviewDetailsModalHeading: "Add Details",
      inteligentResponse: null,
      selectedItem: null,
      isOpen: false,
      isPopupOpen: false,
      isViewConverstion: false,
      relevantTags: [],
      rId: null,
      CurrentItemId: null,
      isRemarksModalOpen: false,
      remarks: "",
      remarkId: null,
      err: null,
      confModalOpen: false,
      tagConfirmationModal: false,
      isTagConfirm: false,
      isReviewTagModalOpen: false,
      tagSelectedItem: null,
      addReviewDetailsModal: false,
      isName: false,
      isEmail: false,
      isPhone: false,
      isOrder_id: false,
      detail_review_id: null,
      closeTicketModal: false,
      closeTicketItem: {},
      deleteTagId: null,
      viewTagActivityModal: false,
      productTagModal: false,
      fameAiTagModal: false,
      review_tag_id: null,
      selectedTag: "",
      showFetchOrderDetailsBtn:
        this.props.orderDetailSettingObj &&
        this.props.orderDetailSettingObj.order_id_status
          ? this.props.orderDetailSettingObj.order_id_status
          : null,
      orderPopup: false,
      orderDetailData: null,
      isOrderDetailFetching: false,
    };

    this.getInteligentResponse = this.getInteligentResponse.bind(this);
    this.addComment = this.addComment.bind(this);
    this.manageTemplates = this.manageTemplates.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.fetchOrderDetails = this.fetchOrderDetails.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { tags, orderDetailSettingObj } = this.props;
    const { tags: prevTags } = prevProps;
    this.setState({
      showFetchOrderDetailsBtn:
        orderDetailSettingObj && orderDetailSettingObj.order_id_status
          ? orderDetailSettingObj.order_id_status
          : null,
    });

    if ((prevTags && prevTags.length) !== (tags && tags.length))
      this.setState({ relevantTags: [...tags] });
    else {
      for (let i = 0; prevTags && i < prevTags.length; i++) {
        if (tags[i].name !== prevTags[i].name) {
          this.setState({ relevantTags: [...tags] });
          break;
        }
      }
    }
  }

  addComment(data) {
    const {
      saveComment,
      location: { search },
      businessId,
    } = this.props;
    const { selectedItem } = this.state;
    const query = queryString.parse(search);
    query["businessId"] = businessId;
    return saveComment({ ...data, review: selectedItem.id }, () =>
      this.setState({ isPopupOpen: false })
    );
  }

  toggle(item) {
    const { getResponseOptions } = this.props;
    const { isOpen } = this.state;
    if (!isOpen) {
      this.setState({ selectedItem: item });
      getResponseOptions && this.props.getResponseOptions(item.id);
    }
    this.setState({ isOpen: !isOpen, rId: item.id });
  }

  handleReplay = (item) => {
    const { getResponseOptions } = this.props;
    this.setState({ isPopupOpen: false });
    this.setState({ rId: item.id });
    this.setState((prevState) => ({
      isPopupOpen: !prevState.isPopupOpen,
      CurrentItemId: item && item.id,
    }));
    this.setState({ selectedItem: item });
    getResponseOptions && this.props.getResponseOptions(item.id);
  };

  handleButtonClick = (item) => {
    const { getResponseOptions } = this.props;
    this.setState({ isOpen: true, rId: item.id });
    getResponseOptions && this.props.getResponseOptions(item.id);
    this.setState({ selectedItem: item });
  };

  manageTemplates() {
    this.props.history.push({
      pathname: "central-setup",
      state: { tab: "3" },
    });
  }

  handleTagChange = (e) => {
    const { tags } = this.props;
    const tagValue = e.target.value;
    this.setState({ tagValue, error: null });
    const newRelevantTags = tags.length
      ? tags.filter((tag) => {
          return tag.name.includes(tagValue);
        })
      : [];
    this.setState({ relevantTags: [...newRelevantTags] });
  };

  addtag = (review_id) => {
    const { tagValue } = this.state;
    if (tagValue.length > 254) {
      this.setState({
        error: "Ensure tag name has no more than 254 characters.",
      });
    } else {
      if (tagValue)
        this.props.postTag({ name: tagValue }).then((res) => {
          if (res.data) {
            this.props.postTag({
              tag_name: res.data.name,
              object_id: review_id,
              tag_id: res.data.id,
              tagMethod: "add_tags/",
            });
            this.setState({ isReviewTagModalOpen: false });
          } else {
            this.setState({ error: "Tag with this name already exists." });
          }
        });
      else
        this.setState({ error: "Please enter a tag name before submitting!" });
    }
  };

  postTagOnReview = (data, tags) => {
    const { postTag } = this.props;
    if (tags && tags.includes(data.tag_name)) return null;
    else postTag(data);
    this.setState({ isReviewTagModalOpen: false });
  };

  getInteligentResponse(item) {
    this.setState({ gettingResponse: true, apiResponseCollected: false });
    const url = URLS.INTELIGENT_RESPONSE;
    let data = {
      review: item.description,
      rating: item.rating,
      review_id: item.id,
    };
    axios_instance
      .post(url, data)
      .then((res) => {
        this.setState({
          gettingResponse: false,
          apiResponse: true,
          inteligentResponse: res.data.data.response,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          inteligentResponse: null,
          gettingResponse: false,
          apiResponse: false,
          apiResponseCollected: true,
        });
      });
  }

  postRemarks = async (remark, reviewId) => {
    const data = {
      review_remark: remark,
    };
    const {
      location: { search },
    } = this.props;
    const url = `${URLS.REVIEWS_DETAILS}${this.props.businessId}/review_remark/?review_id=${reviewId}`;
    await axios_instance
      .post(url, data)
      .then(() => {
        this.setState({ isRemarksModalOpen: false, remarks: "" });
        this.props.getData({
          ...queryString.parse(search),
          businessId: this.props.businessId,
        });
      })
      .catch((err) => {
        err && this.setState({ err: err.response.data.review_remark[0] });
      });
  };

  handleRemarksDelete = async (id) => {
    this.setState({ confModalOpen: false, remarks: "" });
    const {
      location: { search },
    } = this.props;
    const url = `${URLS.REVIEWS_DETAILS}${this.props.businessId}/review_remark/?review_id=${id}`;
    await axios_instance
      .delete(url)
      .then(() => {
        this.props.getData({
          ...queryString.parse(search),
          businessId: this.props.businessId,
        });
      })
      .catch((err) => {
        err && this.setState({ err: err.response.data.review_remark[0] });
      });
  };

  handleRemarkSubmit() {
    this.postRemarks(this.state.remarks, this.state.remarkId);
  }

  toggleReviewTagModal = () => {
    this.setState({ isReviewTagModalOpen: !this.state.isReviewTagModalOpen });
  };
  handleReviewTagsChange = (e, item) => {
    this.setState({
      tagSelectedItem: item,
      isReviewTagModalOpen: !this.state.isReviewTagModalOpen,
      error: null,
      relevantTags: [],
    });
  };

  changeStatus = (status, t_id) => {
    const {
      location: { search },
    } = this.props;
    this.props
      .patchTicket({ status }, t_id)
      .then((res) => {
        displayMessage(
          "positive",
          `Ticket ${
            res.data.status === "reopened" ? "Reopened" : "Closed"
          } Successfully`
        );
        this.props.getData({
          ...queryString.parse(search),
          businessId: this.props.businessId,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleToggle = async () => {
    this.setState({ addReviewDetailsModal: false });
    const {
      location: { search },
    } = this.props;
    await this.props.getData({
      ...queryString.parse(search),
      businessId: this.props.businessId,
    });
  };

  fetchOrderDetails = (order_id, review_id) => {
    const url = `${URLS.REVIEWS_DETAILS}${this.props.businessId}/order-detail-response/?order_id=${order_id}&review_id=${review_id}`;
    axios_instance.get(url).then((res) => {
      if (res.data.success) {
        window.location.reload();
      } else {
        alert("Something went wrong.");
      }
    });
  };

  onAddOrderId = (item, isItemFilled) => {
    this.setState({
      isName: item?.reviewer["name"],
      isEmail: item?.reviewer["email"],
      isPhone: item?.reviewer["phone"] || item?.reviewer["contact_number"],
      addReviewDetailsModal: true,
      addReviewDetailsModalHeading: isItemFilled
        ? "Edit Details"
        : "Add Details",
      isOrder_id: item.order_id ? item.order_id : "",
      detail_review_id: item.id,
    });
  };

  handleTagDelete = (id) => {
    const { businessId } = this.props;
    const {
      location: { search },
    } = this.props;
    axios_instance
      .delete(`${utils.format(URLS.ADD_EDIT_TAG_REVIEW, [businessId])}${id}/`)
      .then((res) => {
        this.setState({ deleteTagModal: false });
        displayMessage("positive", "Tag Deleted Successfully");
        console.log(res);
        this.props.getData({
          ...queryString.parse(search),
          businessId: businessId,
        });
      })
      .catch((err) => {
        displayMessage("negative", "Error");
        this.setState({ deleteTagModal: false });
        console.log(err);
      });
  };

  handleOrderDetails = (review_id, order_id) => {
    const { businessId } = this.props;
    this.setState({ orderPopup: true, isOrderDetailFetching: true });
    axios_instance
      .get(
        `${utils.format(URLS.ORDER_DETAILS, [
          businessId,
        ])}?review_id=${review_id}&order_id=${order_id}`
      )
      .then((res) => {
        this.setState({
          orderDetailData: res.data,
          isOrderDetailFetching: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ orderDetailData: [], isOrderDetailFetching: false });
      });
  };

  toggleConfirm = (confirmationFunction) => {
    this.setState({
      isTagConfirm: !this.state.isTagConfirm,
      confirmationFunction,
    });
  };

  getUserName(item) {
    if (["swiggy", "inhouse"].includes(item.provider_name)) {
      return item?.reviewer?.name?.toString() || "";
    }
    if (item.provider_name === "zomato_ors") {
      return item.order_id;
    }
    return item?.reviewer?.unique_code || item?.reviewer?.name?.toString() || "";
  }

  renderTagLabel(fame_tag, remarkId) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "4px",
          cursor: "pointer",
        }}
        onClick={() =>
          this.setState({
            isNewTag: false,
            selectedTag: fame_tag,
            fameAiTagModal: true,
            remarkId: remarkId,
          })
        }
      >
        {fame_tag.tag_category_aspect_name?.trim() && (
          <span>{fame_tag.tag_category_aspect_name.trim()}</span>
        )}
        <HorizontalArrow height="12px" width="12px" />
        {fame_tag.tag_category_attribute_name?.trim() && (
          <span>{fame_tag.tag_category_attribute_name.trim()}</span>
        )}
      </Box>
    );
  }

  renderTagChip(fame_tag, index, remarkId) {
    const borderColor =
      fame_tag.sentiment === "positive"
        ? "#81E050"
        : fame_tag.sentiment === "negative"
        ? "#EC554E"
        : fame_tag.sentiment === "neutral"
        ? "#FAE25A"
        : "#BFC2CB";

    return (
      <FPChip
        key={`${index}-review-tags`}
        size="small"
        variant="outlined"
        style={{
          borderColor,
          borderWidth: "1.5px",
        }}
        className="review-tag-chip"
        label={this.renderTagLabel(fame_tag, remarkId)}
        onDelete={() => {
          this.setState({
            tagConfirmationModal: true,
            deleteTagId: fame_tag.id,
          });
        }}
      />
    );
  }

  render() {
    const {
      data,
      isComplaints,
      replyDisabled,
      commentDisabled,
      replyOptions,
      handleReplyOption,
      tagsDisabled,
      tags,
      postTag,
      isCompetitors,
      responseOptions,
      fetchResponse,
      responseDetail,
      handleTagFilter,
      getResponseOptions,
      toggleTicketModal,
      createTicket,
      hideButtons,
      ticketActivity,
      getTagDetails,
      tagOptions,
    } = this.props;
    const {
      relevantTags,
      error,
      isNewTag,
      addReviewDetailsModal,
      isEmail,
      isName,
      isOrder_id,
      isPhone,
      detail_review_id,
      showFetchOrderDetailsBtn,
      addReviewDetailsModalHeading,
      productTagModal,
    } = this.state;
    const is_review_aspect_attribute = utils.getParams(
      this.props.location.search,
      "is_review_aspect_attribute"
    );
    const is_product_reviews = utils.getParams(
      this.props.location.search,
      "is_product_reviews"
    );
    const reviews = data ? (data.results ? data.results : data) : [];
    // const { is_superuser } = cookie.load('user') || {};
    const searched = this.props.location
      ? queryString.parse(this.props.location.search)
      : {};
    const searchText = searched ? searched.search : [];
    const tagsList = relevantTags.length
      ? relevantTags
      : tags && tags.length
      ? tags
      : null;
    let statuses = {};
    replyOptions &&
      replyOptions.map((item) => {
        statuses[item.value] = item.display_name;
      });
    const service_category = cookie.load("service_category");
    const { businessId } = this.props;
    return (
      <div>
        {Array.isArray(reviews) && reviews.length > 0 && (
          <DynamicHeightList
            dataList={reviews}
            defaultHeight={230}
            gap={15}
            loader={() => <TicketCardSkeleton className="h-100" />}
            renderRow={(item) => {
              return (
                <CardWrapper
                  className="fp-card-outline review-card-wrapper"
                  showDivider={false}
                >
                  <ReviewCardHeader
                    item={item}
                    isCompetitors={isCompetitors}
                    serviceCategory={service_category}
                    smiley={utils.getSmileyType(item.smiley_value)}
                    isComplaints={isComplaints}
                  />
                  <section className="review-card-body">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <FPAvatar
                        alt={item?.reviewer?.name}
                        src={item?.reviewer?.image}
                        labelClass={"user-name"}
                        userStatus={item?.reviewer?.segmentation}
                        typeChip={item?.reviewer?.type}
                        subLabel={
                          <UserDetails
                            item={item}
                            showFetchOrderDetailsBtn={showFetchOrderDetailsBtn}
                            onAddOrderId={this.onAddOrderId}
                            fetchOrderDetails={this.fetchOrderDetails}
                            service_category={service_category}
                            handleOrderDetails={this.handleOrderDetails}
                          />
                        }
                        userName={this.getUserName(item)}
                      />

                      <ReviewHeaderActivity
                        item={item}
                        replyDisabled={replyDisabled}
                        statuses={statuses}
                        replyOptions={replyOptions}
                        handleReplyOption={handleReplyOption}
                        ticketActivity={ticketActivity}
                        toggleTicketModal={toggleTicketModal}
                        createTicket={createTicket}
                        toggle={this.toggle}
                        changeStatus={() =>
                          this.changeStatus(
                            item.ticket_status &&
                              item.ticket_status === "resolved"
                              ? "reopened"
                              : "resolved",
                            item.t_id
                          )
                        }
                      />
                    </div>
                    {!!item.heading && (
                      <p className="review-details-heading m-0 mb-1 ">
                        {item.heading}
                      </p>
                    )}

                    {this.state.isPopupOpen &&
                    this.state.CurrentItemId === item.id &&
                    item.description &&
                    item.description.length > 0 ? (
                      <div className="rounded p-2 b-radius-8 fp-border">
                        <ReviewText
                          item={item}
                          searchText={searchText}
                          commentDisabled={commentDisabled}
                        />
                      </div>
                    ) : (
                      <ReviewText
                        item={item}
                        searchText={searchText}
                        commentDisabled={commentDisabled}
                      />
                    )}

                    {item.review_remark !== null &&
                      item.review_remark !== "" && (
                        <div className="remark-details mt-3">
                          <div style={{ width: "95%" }}>
                            <p>
                              <b>Remarks: </b>
                              {item.review_remark}
                            </p>
                          </div>
                          <div className="remark-details-icon">
                            <BorderColorOutlinedIcon
                              style={{
                                color: "#7A8193",
                                width: "16px",
                                height: "20px",
                              }}
                              role="button"
                              onClick={() =>
                                this.setState({
                                  remarks: item.review_remark,
                                  remarkId: item.id,
                                  isRemarksModalOpen: true,
                                })
                              }
                            />
                            <DeleteForeverOutlinedIcon
                              style={{
                                color: "#7A8193",
                                width: "20px",
                                height: "18px",
                              }}
                              role="button"
                              onClick={() =>
                                this.setState({
                                  confModalOpen: true,
                                  remarkId: item.id,
                                })
                              }
                            />
                          </div>
                        </div>
                      )}

                    {item.extra_data.image_links &&
                    item.extra_data.image_links.length !== 0 ? (
                      <FeedbackImage reviews={item.extra_data} />
                    ) : (
                      ""
                    )}
                    <Divider
                      className="my-3"
                      style={{ background: "#F6F6F8" }}
                    />
                    {/* <Collapse
                      in={
                        this.state.isPopupOpen &&
                        this.state.CurrentItemId === item.id
                      }
                    > */}
                    {this.state.isPopupOpen &&
                      this.state.CurrentItemId === item.id && (
                        <CommentModal
                        description={item.description}
                        location={this.props.location}
                          addComment={this.addComment}
                          toggle={this.toggle}
                          handleReplyOption={handleReplyOption}
                          selectedItem={this.state.selectedItem}
                          responseOptions={responseOptions}
                          fetchResponse={fetchResponse}
                          responseDetail={responseDetail}
                          manageTemplates={this.manageTemplates}
                          getResponseOptions={getResponseOptions}
                          review_id={this.state.rId}
                          bId={this.props.businessId}
                          onClose={() => this.setState({ isPopupOpen: false })}
                        />
                      )}
                    {/* </Collapse> */}

                    <div
                      className={`d-flex  ${
                        item.review_remark == null || item.review_remark == ""
                          ? "justify-content-end"
                          : "justify-content-end"
                      }`}
                    ></div>

                    <section className="d-flex gap-10 align-items-start justify-content-between mt-2">
                      <div
                        style={{
                          paddingRight:
                            this.state.isPopupOpen &&
                            this.state.CurrentItemId === item.id
                              ? "230px"
                              : "0px",
                        }}
                      >
                        <div className="d-flex gap-10">
                          {(item.review_remark == null ||
                            item.review_remark == "") && (
                            <FPToolTip
                              title={"Add Internal Remarks"}
                              className="me-3"
                            >
                              <div className="makeRemarkIcon">
                                <img
                                  src={internalRemark}
                                  onClick={() =>
                                    this.setState({
                                      isRemarksModalOpen: true,
                                      remarkId: item.id,
                                    })
                                  }
                                  alt=""
                                  loading="lazy"
                                  className="size-15"
                                />
                              </div>
                            </FPToolTip>
                          )}

                          {!hideButtons && (
                            <FPToolTip title="Manual Tags">
                              <div className="tagIcon">
                                <img
                                  src={whiteTag}
                                  onClick={(e) => {
                                    this.handleReviewTagsChange(e, item);
                                  }}
                                  alt=""
                                  loading="lazy"
                                  className="size-15"
                                />
                              </div>
                            </FPToolTip>
                          )}
                          {!!(item?.tag?.length > 0) && !tagsDisabled && (
                            <CSSTransitionGroup
                              transitionName="reviews-tags"
                              transitionEnterTimeout={500}
                              transitionLeaveTimeout={500}
                              className="d-flex flex-wrap gap-10 align-items-center"
                            >
                              {item.tag.map((tag, index) => {
                                return (
                                  <FPChip
                                    key={`${index}-review-tags`}
                                    size="small"
                                    variant="outlined"
                                    style={{
                                      borderColor: "#BFC2CB",
                                      borderWidth: "1.5px",
                                      maxHeight: "24px",
                                    }}
                                    className="review-tag-chip"
                                    label={
                                      <span style={{ fontSize: "10.5px" }}>
                                        {utils.snakeToCapital(tag)}
                                      </span>
                                    }
                                    onDelete={() =>
                                      this.toggleConfirm(() =>
                                        postTag({
                                          object_id: item.id,
                                          tag_name: tag,
                                          tagMethod: "remove_tags/",
                                        })
                                      )
                                    }
                                  />
                                );
                              })}
                            </CSSTransitionGroup>
                          )}
                        </div>
                        {is_review_aspect_attribute &&
                          is_review_aspect_attribute === "true" &&
                          item.fameai_tags &&
                          Array.isArray(item.fameai_tags) &&
                          item.fameai_tags.length > 0 && (
                            <div className="d-flex align-items-start gap-10 mt-10">
                              <Box
                                sx={{
                                  backgroundColor: "#00B5AD",
                                  borderRadius: "50px",
                                  padding: "4px 12px",
                                  height: "21px",
                                  minWidth: "115px",
                                }}
                                className="d-flex align-items-center gap-6"
                                // role="button"
                              >
                                <img
                                  src={whiteTag}
                                  className="size-15"
                                  alt=""
                                  loading="lazy"
                                />
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: "11px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Fame AI Tags
                                </span>
                              </Box>
                              <CSSTransitionGroup
                                transitionName="reviews-tags"
                                transitionEnterTimeout={500}
                                transitionLeaveTimeout={500}
                                className="d-flex flex-wrap gap-10 align-items-center"
                              >
                                {item.fameai_tags.map((fame_tag, index) =>
                                  this.renderTagChip(fame_tag, index, item.id)
                                )}
                                <img
                                  src={timeClock}
                                  onClick={() =>
                                    this.setState({
                                      viewTagActivityModal: true,
                                      review_tag_id: item.id,
                                    })
                                  }
                                  alt=""
                                  className="cursor-pointer"
                                  loading="lazy"
                                />
                                <img
                                  src={AddPlus}
                                  onClick={() =>
                                    this.setState({
                                      isNewTag: true,
                                      fameAiTagModal: true,
                                      remarkId: item.id,
                                      selectedTag: "",
                                    })
                                  }
                                  alt=""
                                  className="cursor-pointer"
                                  loading="lazy"
                                />
                              </CSSTransitionGroup>
                            </div>
                          )}
                        {
                        // is_product_reviews &&
                        //   is_product_reviews === "true" &&
                          service_category &&
                          service_category === "Restaurant" &&
                          item.menu_tags &&
                          Array.isArray(item.menu_tags) &&
                          item.menu_tags.length > 0 && (
                            <div className="d-flex align-items-center gap-10 mt-10">
                              <Box
                                style={{
                                  backgroundColor: "#F13A88",
                                  borderRadius: "50px",
                                  padding: "4px 12px",
                                  height: "21px",
                                  minWidth: "115px",
                                }}
                                className="d-flex align-items-center gap-6"
                                // role="button"
                              >
                                <img
                                  src={whiteTag}
                                  style={{ height: "15px", width: "15px" }}
                                />
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: "11px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Product Tags
                                </span>
                              </Box>
                              <CSSTransitionGroup
                                transitionName="reviews-tags"
                                transitionEnterTimeout={500}
                                transitionLeaveTimeout={500}
                                className="d-flex flex-wrap gap-10"
                              >
                                {item.menu_tags &&
                                  Array.isArray(item.menu_tags) &&
                                  item.menu_tags.map((menu_tag, index) => {
                                    return (
                                      <FPChip
                                        key={`${index}-review-tags`}
                                        size="small"
                                        variant="outlined"
                                        style={{
                                          borderColor:
                                            menu_tag.sentiment === "positive"
                                              ? "#81E050"
                                              : menu_tag.sentiment ===
                                                "negative"
                                              ? "#EC554E"
                                              : menu_tag.sentiment === "neutral"
                                              ? "#FAE25A"
                                              : "#BFC2CB",
                                          borderWidth: "1.5px",
                                        }}
                                        className="review-tag-chip"
                                        label={
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                              gap: "4px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() =>
                                              this.setState({
                                                selectedTag: menu_tag,
                                                productTagModal: true,
                                                remarkId: item.id,
                                              })
                                            }
                                          >
                                            {menu_tag.product_name && (
                                              <span>
                                                {menu_tag.product_name.trim()}
                                              </span>
                                            )}
                                            {menu_tag.associated_opinion && (
                                              <span>
                                                <HorizontalArrow
                                                  height="12px"
                                                  width="12px"
                                                />
                                              </span>
                                            )}
                                            {menu_tag.associated_opinion && (
                                              <span>
                                                {menu_tag.associated_opinion.trim()}
                                              </span>
                                            )}
                                            {menu_tag.product_rating && (
                                              <span>
                                                <HorizontalArrow
                                                  height="12px"
                                                  width="12px"
                                                />
                                              </span>
                                            )}
                                            {menu_tag.product_rating && (
                                              <>
                                                <CustomStarRatingComponent
                                                  name=""
                                                  starCount={1}
                                                  style={{ fontSize: "10px" }}
                                                  value={utils.setDecimalIndex(
                                                    menu_tag.product_rating
                                                  )}
                                                  editing={false}
                                                />
                                                <span className="font-blue-gray">
                                                  {utils.setDecimalIndex(
                                                    menu_tag.product_rating
                                                  )}
                                                </span>
                                              </>
                                            )}
                                          </Box>
                                        }
                                      />
                                    );
                                  })}
                              </CSSTransitionGroup>
                            </div>
                          )}
                      </div>
                      <div className="d-flex gap-10">
                        {(this.state.CurrentItemId !== item.id ||
                          !this.state.isPopupOpen) && (
                          <div>
                            {
                            // !excludedProviders.includes(
                            //   item.provider_name
                            // ) && 
                            item?.is_reply_allow &&(
                              <div className="d-flex gap-10 text-nowrap">
                                <MessageBox
                                  as="button"
                                  disable={replyDisabled}
                                  className={"messageBoxType-btn-small"}
                                  backgroundColor="#438E96"
                                  onClick={() => {
                                    !replyDisabled && this.handleReplay(item);
                                  }}
                                >
                                  <span className="font-white fp-size-13 d-flex justify-content-center" >
                                    Reply <ReplyOutlinedIcon />
                                  </span>
                                </MessageBox>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </section>
                  </section>
                </CardWrapper>
              );
            }}
          />
        )}

        {addReviewDetailsModal && (
          <AddReviewDetailsModal
            Order={isOrder_id}
            Email={isEmail}
            Name={isName}
            Phone={isPhone}
            isOpen={addReviewDetailsModal}
            toggle={this.handleToggle}
            review_id={detail_review_id}
            businessId={businessId}
            heading={addReviewDetailsModalHeading}
          />
        )}

        {/* Remark Modal */}
          <FormModal
            isOpen={this.state.isRemarksModalOpen}
            toggle={() => this.setState({ isRemarksModalOpen: false })}
            maxWidth="md"
            width={"580px"}
            heading="Remarks"
            showDivider
            bodyClass="pb-0"
            dialogActions={
              <FPButton
                className="ml-auto"
                onClick={() => this.handleRemarkSubmit()}
              >
                Save
              </FPButton>
            }
          >
            <FPTextArea
              rows={5}
              placeholder="Enter your remarks here..."
              value={this.state.remarks}
              onChange={(e) =>
                this.setState({ err: null, remarks: e.target.value })
              }
            />
            {this.state.err !== null && (
              <p style={{ color: "#ff2e2e", paddingBottom: "10px" }}>
                {this.state.err}
              </p>
            )}
          </FormModal>

        {this.state.confModalOpen && (
          <ConfirmationModal
            header="Warning !!"
            subHeader="Are you sure you want to delete the remark? This cannot be undone!"
            isOpen={this.state.confModalOpen}
            toggle={() => this.setState({ confModalOpen: false })}
            onConfirm={() => this.handleRemarksDelete(this.state.remarkId)}
          />
        )}

        {this.state.tagConfirmationModal && (
          <ConfirmationModal
            header="Are you sure"
            style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
            subHeader="Are you sure you want to delete the tag?"
            isOpen={this.state.tagConfirmationModal}
            toggle={() => this.setState({ tagConfirmationModal: false })}
            onConfirm={() => this.handleTagDelete(this.state.deleteTagId)}
          />
        )}

        {this.state.isTagConfirm && (
          <ConfirmationModal
            header="Are you sure"
            style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
            subHeader="Are you sure you want to delete the tag?"
            isOpen={this.state.isTagConfirm}
            toggle={() => this.setState({ isTagConfirm: false })}
            onConfirm={this.state.confirmationFunction}
          />
        )}

        {this.state.viewTagActivityModal && (
          <ViewTagActivityModal
            open={this.state.viewTagActivityModal}
            toggle={() =>
              this.setState({
                viewTagActivityModal: false,
              })
            }
            review_tag_id={this.state.review_tag_id}
          />
        )}

        {this.state.fameAiTagModal && (
          <FameAiTagModal
            open={this.state.fameAiTagModal}
            toggle={() =>
              this.setState({
                fameAiTagModal: false,
              })
            }
            reviewId={this.state.remarkId}
            business_id={businessId}
            selectedTag={this.state.selectedTag}
            getData={this.props.getData}
            location={this.props.location}
            history={this.props.history}
            isNewTag={isNewTag}
            tagOptions={tagOptions}
            getTagDetails={getTagDetails}
          />
        )}

        {productTagModal && (
          <ProductTagModal
            open={productTagModal}
            selectedTag={this.state.selectedTag}
            toggle={() =>
              this.setState({
                productTagModal: false,
              })
            }
            getData={this.props.getData}
            location={this.props.location}
            history={this.props.history}
            tagOptions={tagOptions}
            business_id={businessId}
          />
        )}

        {/* Tag manager */}
          <TicketingTagsModal
            tagsList={tagsList}
            isReviewTagModalOpen={this.state.isReviewTagModalOpen}
            toggleReviewTagModal={this.toggleReviewTagModal}
            item={this.state.tagSelectedItem}
            postTagOnReview={this.postTagOnReview}
            error={error}
            handleTagChange={this.handleTagChange}
            addtag={this.addtag}
          />

          <OrderDetailsModal
            open={this.state.orderPopup}
            toggle={() =>
              this.setState({
                orderPopup: false,
              })
            }
            orderDetailData={this.state.orderDetailData}
            isOrderDetailFetching={this.state.isOrderDetailFetching}
          />
      </div>
    );
  }
}

ReviewsList = reduxForm({
  // eslint-disable-line
  form: "ReviewsList",
  enableReinitialize: true,
})(ReviewsList);

const mapStateToProps = (state) => {
  return {
    businessInfo: state.business.detail,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  ReviewsList
);
