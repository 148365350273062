import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as overviewActions from "../../actions/listing/overviewAction";
import OverviewPage from "../../components/listing/Overview/OverviewPgae";
import LoaderComponent from "../../components/common/loaderComponent";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import queryString from "query-string";
import ListingLockedComponent from "../../components/common/new/Listing/ListingLockComponent";

const ListingOverview = (props) => {
  const {
    actions,
    Overviewactions,
    match: {
      params: { business_id },
    },
    location: { search },
    overviewList,
    error,
    loader,
    lockedModules,
    history,
  } = props;

  useEffect(() => {
    actions.setSelectedTab(2);
    const payload = queryString.parse(search);
    Overviewactions.getGmbListingOverview(business_id, payload);
  }, [actions, Overviewactions, business_id, search]);

  useEffect(() => {
    const prevSearch = search;
    if (prevSearch !== search) {
      const queryParams = queryString.parse(search);
      Overviewactions.getGmbListingOverview(business_id, queryParams);
    }
  }, [search, Overviewactions, business_id]);

  const getData = (data) => {
    Overviewactions.getGmbListingOverview(business_id, data);
  };

  return (
    <div>
      <LoaderComponent loader={loader} />
      <OverviewPage
        location={props.location}
        history={history}
        overviewList={overviewList}
        business_id={business_id}
        getData={getData}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    overviewList: state.gmbListingOverview.overview_list,
    error: state.gmbListingOverview.overview_error,
    loader: state.common.loader,
    lockedModules: state.business.lockedModules || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    Overviewactions: bindActionCreators(overviewActions, dispatch),
    actions: bindActionCreators(setSelectedTab, dispatch),
  };
};

ListingOverview.propTypes = {
  actions: PropTypes.object.isRequired,
  overviewList: PropTypes.object,
  error: PropTypes.object,
  loader: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingOverview);
