import React from "react";
import PropTypes from "prop-types";
import { Card, Divider } from "semantic-ui-react";
import classNames from "classnames";
import { DashboardRedirect } from "../../../images/svg";
import { Link } from "react-router-dom";
import { Skeleton } from "@mui/material";
import { IoMdRefresh } from "react-icons/io";

const CardWrapper = (props) => {
  const {
    children,
    onHeaderClick,
    className,
    contentClass,
    headerTitle,
    headerStyle,
    showDivider = true,
    headerClass,
    dividerClass,
    widthElevation,
    withPrimaryElevation,
    descriptionStyle,
    descriptionId,
    redirectionLink,
    skeletonLoader,
    isRefreshButton=false,
    handleRefreshButton,
    ...rest
  } = props;
  return (
    <Card
      fluid
      className={classNames(`m-0 fp-card`, className, {
        "fp-card-elevation": widthElevation,
        "fp-card-primary-elevation": withPrimaryElevation,
      })}
    >
      <Card.Content className={classNames(contentClass)}>
        {headerTitle &&
          (skeletonLoader ? (
            <div className="d-flex align-items-center">
              <Skeleton width="30%" height={22} />
            </div>
          ) : (
            <Card.Header
              content={
                typeof headerTitle === "string" ? (
                  <div className="d-flex justify-content-between align-items-center gap-6">
                    <div className="d-flex align-items-center gap-6">
                      <p className="m-0">{headerTitle}</p>
                      {redirectionLink && (
                        <Link to={redirectionLink}>
                          <DashboardRedirect />
                        </Link>
                      )}
                    </div>
                    {isRefreshButton && (
                      !skeletonLoader &&
                        <IoMdRefresh 
                          size={23} 
                          style={{ color: 'deeppink', cursor: 'pointer', transition: 'transform 0.3s ease-in-out',  marginTop: '2px'}}
                          onMouseLeave={(e) => e.currentTarget.style.transform = 'rotate(0deg)'} 
                          onClick={(e) => {
                            e.currentTarget.style.transform = 'rotate(180deg)'
                            handleRefreshButton();
                          }}
                        /> 
                    )}
                  </div>
                ) : (
                  headerTitle
                )
              }
              className={classNames(
                "fp-card-header",
                { "cursor-pointer": !!onHeaderClick },
                headerClass
              )}
              style={headerStyle}
              onClick={() => onHeaderClick && onHeaderClick()}
              {...rest}
            />
          ))}

        {showDivider && (
          <Divider className={classNames("mt-2", dividerClass)} />
        )}
        <Card.Description id={descriptionId} style={descriptionStyle}>
          {children}
        </Card.Description>
      </Card.Content>
    </Card>
  );
};

CardWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onHeaderClick: PropTypes.func,
  headerTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showDivider: PropTypes.bool,
  redirectionLink: PropTypes.string,
};

export default CardWrapper;
