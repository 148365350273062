import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import Pagination from "react-js-pagination";

const options = [
  { key: "10", text: "10", value: "10" },
  { key: "25", text: "25", value: "25" },
  { key: "50", text: "50", value: "50" },
  { key: "100", text: "100", value: "100" },
];

const MenuModalPagination = ({ className, totalItems = 0, defaultPageNumber = 1, defaultPageSize = 10, onChange }) => {
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  console.log("pageNumber", pageNumber);

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    onChange?.({
      perPage: size,
      currentPage: pageNumber,
    });
  };

  const handlePageNumberChange = (page) => {
    setPageNumber(page);
    onChange?.({
      perPage: pageSize,
      currentPage: page,
    });
  };

  useEffect(() => {
    setPageSize(pageSize);
    setPageNumber(pageNumber);
  }, [pageSize, pageNumber]);

  return (
    <div className={classNames("pagination-wrapper", className)}>
      <div className="d-flex align-items-center gap-10">
        <p className="mb-0">Display {""}</p>
        <Dropdown
          className="page-size-selector"
          onChange={(e, { value }) => handlePageSizeChange(value)}
          options={options}
          selection
          compact
          inline
          value={pageSize.toString()}
        />
      </div>
      <div className="d-flex justify-content-end align-items-center gap-10">
        <p className="mb-0">Total Records: {totalItems || "00"}</p>
        <Pagination
          activePage={pageNumber}
          itemsCountPerPage={pageSize}
          prevPageText="&#8249;"
          nextPageText="&#8250;"
          linkClass="page-link"
          totalItemsCount={totalItems}
          onChange={handlePageNumberChange}
        />
      </div>
    </div>
  );
};

export default MenuModalPagination;
