import React, { useEffect, useState, useMemo } from "react";
import Wrapper from "./Wrapper";
import {
  Box,
  LinearProgress,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import LoaderComponent from "../../components/common/loaderComponent";
import { useDebounceEffect } from "../../libs/hooks/useDebounceEffect";
import { NoData } from "../../components/common/new";

const StyledTableBodyCell = styled(TableCell)({
  border: "1px solid #ddd",
  textAlign: "center",
  fontFamily: "'Outfit', sans-serif",
  padding: "24px 12px",
  "&:first-of-type": {
    borderLeft: "none",
    borderRight: "none",
  },
});

const StyledTableHeadCell = styled(TableCell)({
  borderBottom: "none",
  textAlign: "center",
  fontFamily: "'Outfit', sans-serif",
  padding: "24px 12px",
});

const StyledTableHead = styled(TableHead)({
  "& th": {
    borderBottom: "none",
    background: "#F6F6F8",
  },
});

const CustomTableRow = styled(TableRow)({
  "&:first-of-type td": {
    borderTop: "none",
  },
});

const StyledLinearProgress = styled(LinearProgress)(({ progressvalue }) => ({
  height: "8px",
  borderRadius: "4px",
  backgroundColor: "#f0f0f0",
  "& .MuiLinearProgress-bar": {
    borderRadius: "4px",
    backgroundColor: getProgressBarColor(progressvalue),
  },
}));

const getProgressBarColor = (value) => {
  if (value >= 7) return "#4caf50";
  if (value >= 4) return "#ffeb3b";
  if (value >= 0) return "#ff9800";
  return "#f44336";
};

const RatingProgress = ({ value, type }) => {
  const maxValue = type ? 10 : 5;
  const progressValue = (value / maxValue) * 100;

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <StyledLinearProgress
          variant="determinate"
          value={progressValue}
          progressvalue={value}
        />
      </Box>
      <Box minWidth={35}>
        <Typography
          variant="body2"
          color="#1A2544"
          style={{ fontSize: "16px", whiteSpace: "nowrap" }}
        >
          {value}
          <span style={{ fontSize: "12px", color: "#646565" }}>
            /{maxValue}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

const CategoryRating = ({ location, history, match, categoryData, loader }) => {
  const [convertedData, setConvertedData] = useState([]);
  const [isCalculating, setIsCalculating] = useState(loader);

  const [categories, platforms] = useMemo(() => {
    const platforms = [];
    const categories = [];
    if (categoryData?.length) {
      const firstItem = categoryData[0];
      Object.keys(firstItem || {}).forEach((key) => {
        if (key !== "owner_name") platforms.push(key);
      });

      if (platforms[0]) {
        Object.keys(categoryData[0][platforms[0]] || {}).forEach((cat) => {
          categories.push(cat);
        });
      }
    }
    return [categories, platforms];
  }, [categoryData]);

  useDebounceEffect(
    async () => {
      console.log("out of try catch", {platforms, categoryData})
      if (!categoryData?.length) {
        setIsCalculating(false);
        return
      };

      setIsCalculating(true);
      try {
        const transformedData = platforms.map((platform) => ({
          platform,
          data: categoryData
            .filter((item) => item[platform])
            .map((hotel) => {
              const competitorName =
                hotel.competitor_name || hotel.owner_name || "unknown";
              const categoriesData = categories.reduce((acc, category) => {
                acc[category] = hotel[platform]?.[category] || 0;
                return acc;
              }, {});
              return {
                competitor_name: competitorName,
                categories: categoriesData,
              };
            }),
        }));
      setConvertedData(transformedData);
      } catch (error) {
        console.log("error", error);
      } 

      setIsCalculating(false);
    },
    0,
    [categoryData, platforms, categories]
  );
  return (
    <>
      <LoaderComponent loader={isCalculating} />
      <Wrapper
        title={"Category Wise Ratings"}
        subHeading={"* Above details is as on 2024-05-21"}
        location={location}
        history={history}
        match={match}
      >
        {(viewType) => (
          <>
            {viewType === "Graph" ? (
              <>
                {convertedData &&
                  convertedData.map((val, i) => {
                    let obj_key = "";
                    Object.keys(val).map((key) => (obj_key = key));
                    return (
                      <div key={i}>
                        <div className="d-flex justify-content-between align-items-center px-4">
                          <p
                            style={{
                              color: "#1B1C1D",
                              fontWeight: "600",
                              fontSize: "20px",
                            }}
                            className="m-0"
                          >
                            {obj_key}
                          </p>
                          {/* <img src={sourceImages.google} alt="google" width="24" height="24" /> */}
                        </div>

                        <TableContainer
                          component={Paper}
                          style={{ boxShadow: "none" }}
                          className="p-4"
                        >
                          <Table>
                            <StyledTableHead>
                              <CustomTableRow>
                                {val[obj_key] &&
                                  val[obj_key].map((main_val, index) => (
                                    <StyledTableHeadCell
                                      key={index}
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: index === 0 ? "600" : "400",
                                        color: "#1A2544",
                                      }}
                                    >
                                      {main_val.competitor_name || "-"}
                                    </StyledTableHeadCell>
                                  ))}
                              </CustomTableRow>
                            </StyledTableHead>
                            <TableBody>
                              {categories.map((cate, cate_i) => (
                                <CustomTableRow key={cate_i}>
                                  {val[obj_key] &&
                                    val[obj_key].map((main_val, index) => {
                                      return (
                                        <StyledTableBodyCell
                                          key={index}
                                          style={{
                                            background:
                                              index === 0 ? "#EEE3FF" : "",
                                            paddingTop: "10px",
                                          }}
                                        >
                                          <Box>
                                            {index === 0 && (
                                              <Typography
                                                style={{
                                                  textAlign: "start",
                                                  color: "#1B1C1D",
                                                  fontSize: "16PX",
                                                }}
                                              >
                                                {cate}
                                              </Typography>
                                            )}
                                            <RatingProgress
                                              value={main_val.categories[cate]}
                                              type={true}
                                            />
                                          </Box>
                                        </StyledTableBodyCell>
                                      );
                                    })}
                                </CustomTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    );
                  })}
              </>
            ) : (
              <h1>Table</h1>
            )}
          </>
        )}
      {!categoryData?.length && <NoData className={"my-3"}/>}
      </Wrapper>
    </>
  );
};

export default CategoryRating;
