import React from 'react';
import { Row, Col } from 'reactstrap';
import { Card, Divider, Popup } from 'semantic-ui-react';
import CompetitorsRatingsMeter from '../common/CompetitorsRatingsMeter';
import LoaderComponent from '../common/loaderComponent';

class CompetitorsOverview extends React.Component {
  render() {
    const { overviewDetails,loader } = this.props;
    return (
      <div>
        <LoaderComponent loader={loader}/>
        <Row>
          <Col sm="12" className="mt-2 mb-2">
            <Card fluid className="py-3">
              <Card.Description className="mb-0 fw3 mx-auto " style={{ fontWeight: 'bold', fontSize: 15 }}>Competitive Ranking</Card.Description>
            </Card>
          </Col>
          <Col sm="12">
            <Card fluid className="pt-2" style={{ paddingRight: '7px', paddingLeft: '7px' }}>
              <Row xs={12} sm={6}>
                <Col xs="12" sm="4">
                  <div className="shadow">
                    <Card className=" primary-overview-card " style={{ marginBottom: '10px' }}>
                      <Card.Content >
                        <Popup
                          position='top center'
                          size='mini'
                          content={overviewDetails && overviewDetails.primary && (overviewDetails.primary.hasOwnProperty('location_address') ? overviewDetails.primary.location_address.toUpperCase() : overviewDetails.primary.name.toUpperCase())}
                          trigger={<Card.Header style={{ height: '3rem', overflow: 'hidden', color: '#3475d3' }} content={overviewDetails && overviewDetails.primary && overviewDetails.primary.name.toUpperCase()} className="pt-1 text-center" />
                          }></Popup>
                        <Divider className="mb-2"></Divider>
                        <Card.Description style={{ height: '250px' }} className="mt-2 mb-3" >
                          <CompetitorsRatingsMeter item={overviewDetails && overviewDetails.primary} />
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </div>
                </Col>
                {overviewDetails && overviewDetails.secondary && overviewDetails.secondary.map((item, index) => {
                  console.log(item.location_address);
                  return (<Col key={index} xs="12" sm="4">
                    <div className="shadow">
                      <Card style={{ marginBottom: '10px' }}>
                        <Card.Content>
                          <Popup
                            position='top center'
                            size='mini'
                            content={ item.hasOwnProperty('location_address') ? item.location_address.toUpperCase() : item.name.toUpperCase()}
                            trigger={<Card.Header style={{ height: '3rem', overflow: 'hidden' }} content={item.name.toUpperCase()} className="pt-1 text-center" />
                            }></Popup>
                          <Divider className="mb-2"></Divider>
                          <Card.Description style={{ height: '250px' }} className="mt-2 mb-3" >
                            <CompetitorsRatingsMeter item={item && item} />
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    </div>
                  </Col>);
                })}
              </Row>
            </Card>
          </Col>
        </Row>

      </div >

    );
  }
}

export default CompetitorsOverview;
