import React from "react";
import HyperLocalRanking from "../../../components/listing/keywordPosition/HyperLocalRanking";

import KeywordRankingGraph from "../../../components/listing/keywordPosition/KeywordRankingGraph";
import KeywordPositionTracking from "../../../components/listing/keywordPosition/KeywordPositionTracking";
import MentionAndSentiment from "../../../components/listing/keywordPosition/MentionAndSentiment";

const RankingDashboard = () => {
  return (
    <>
      <div className="hyperlocal-ranking-data ">
        <h3>HyperLocal Ranking Data</h3>
        <HyperLocalRanking />
      </div>
      <div>
        <KeywordRankingGraph />
      </div>
      <div>
        <KeywordPositionTracking />
      </div>
      <div>
        <MentionAndSentiment />
      </div>
    </>
  );
};

export default RankingDashboard;
