import React, { useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { TabContent, TabPane } from "reactstrap";
import { FPToolTip } from "../../../components/common/new";
import SortIcon from "../../../images/svg/Sort.svg";
import "./keywords.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const COLORS = [
  "#77DAED",
  "#92E5F6",
  "#BBF0FB",
  "#D7F7FE",
  "#227aa5",
  "#EBFCFF",
  "#56C3D9",
  "#6DDCF2",
  "#31BBD6",
  "#C8EEF5",
];

const TrafficDrivingChart = ({ listing_search_keywords }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  // Limit the results to 20 entries and map them to the required format
  const dataEntries = (listing_search_keywords?.results || []).slice(0, 20);

  // Pie chart data
  const labels = dataEntries.map((item) => item.keyword);
  const impressionsData = dataEntries.map((item) => item.impression);

  const pieData = {
    labels,
    datasets: [
      {
        data: impressionsData,
        backgroundColor: COLORS,
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    plugins: {
      legend: { display: false },
    },
    maintainAspectRatio: false,
  };

  // Pagination logic for table
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedData = dataEntries.slice(startIndex, endIndex);


  return (
    <div
      className="custom-pie-table-box"
      style={{
        width: "100%",
      }}
    >
      <div
        style={{ margin: "10px", display: "flex", justifyContent: "center" }}
      >
        <div className="custom-size-cart">
          {/* {pieData?.labels?.length > 0 ? ( */}
            <Pie data={pieData} className="customPieChart" options={options} />
          {/* ) : (
            // <div>
            //   <span style={{ fontWeight: "400", fontSize: "24px" }}>
            //     Data Not Found
            //   </span>
            // </div>
          )} */}
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <TabContent activeTab={"tab1"}>
          <TabPane tabId={"tab1"}>
            <div className="ui-table-responsive">
              <Table className="fp-table">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ textAlign: "start" }}>
                      Keywords
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      Impressions
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      Traffic %
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((item, rowIndex) => (
                    <TableRow key={item.id}>
                      <TableCell>
                        <FPToolTip title={item.keyword}>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                            textAlign: "start",
                          }}
                          className=" text-truncate width-md"
                        >
                          <span
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.keyword}
                          </span>
                        </div>
                        </FPToolTip>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {item.impression}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        {item.traffic}%
                      </TableCell>
                    </TableRow>
                 ) )}
                </TableBody>
              </Table>
            </div>
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default TrafficDrivingChart;
