import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Icon, Checkbox } from "semantic-ui-react";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import InputComponent from "../../common/InputComponent";
import TextAreaComponent from "../../common/TextAreaComponent";
import ReviewMethodsOptions from "../../common/ReviewMethodsOptions";
import MultipleButtonSelect from "../../common/MultipleButtonSelect";
import * as utils from "../../../libs/utils";
import {
  FPBreadcrumbs,
  FPButton,
  FPMenuBox,
  FPToolTip,
} from "../../common/new";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
import FPLabel from "../../common/new/forms/ui/FPLabel";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import { Collapse } from "@material-ui/core";

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = "Enter Name";
  if (!values.subject) errors.subject = "Enter Subject";
  if (!values.body) errors.body = "Enter Body";
  if (!values.message) errors.message = "Enter Message";
  if (!values.heading) errors.heading = "Enter Heading";
  if (values.btn_text && !values.link) errors.link = "Link is required";
  if (values.link && !values.btn_text) errors.btn_text = "Text is required";
  return errors;
};

class TemplateEmailForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.handleReviewMethod = this.handleReviewMethod.bind(this);
    this.state = {
      landingPageBodyChars: 2000,
      emailBodyChar: 2000,
      subjectBodyChar: 150,
      reviewChannel: [],
      error: "",
      buttonTextChar: 60,
      selectReviewMethods:
        props.initialValues && props.initialValues.symbol_type ? true : false,
      showReviewMethods:
        props.initialValues && props.initialValues.symbol_type ? true : false,
      includeSurvey:
        props.initialValues && props.initialValues.survey_section
          ? true
          : false,
      reviewGating: props.initialValues && props.initialValues.review_gating,
      reviewMethod:
        props.initialValues && props.initialValues.symbol_type
          ? props.initialValues.symbol_type
          : "thumb",
      includeLocation: props.initialValues.include_location || false,
      userImage: null,
      ImageData: props.initialValues ? props.initialValues.image : null,
      ImageLink: props.initialValues ? props.initialValues.image : null,
      imageLoader: false,
      is_delete: false,
      selectedSubType:
        props.initialValues && props.initialValues.sub_type
          ? props.initialValues.sub_type
          : null,
    };
  }

  handleReviewChannel = (value) => {
    const providers =
      value &&
      value
        .filter((item) => {
          return item.status === "active";
        })
        .map((item) => {
          return item.id;
        });
    this.setState({ reviewChannel: providers });
  };

  handleReviewMethod(e, { value }) {
    this.setState({ reviewMethod: value });
  }

  handleShowReviewMethods = (e, { checked }) => {
    this.setState({ showReviewMethods: checked });
  };

  handleSelectReviewMethods = (e, { checked }) => {
    this.setState({ selectReviewMethods: checked });
  };

  handleIncludeSurvey = (e, { checked }) => {
    this.setState({ includeSurvey: checked });
  };

  handleReviewGating = (e, { checked }) => {
    this.setState({ reviewGating: checked });
  };
  handleSubTypeSelect = (text) => {
    this.setState({ selectedSubType: text });
  };

  uploadImage = (event) => {
    event.preventDefault();
    const userImage = event.target.files[0];
    if (!userImage.name.match(/.(jpg|jpeg|png|gif)$/i)) {
      utils.displayMessage("negative", "Please upload a valid Image file");
      return;
    }
    if (userImage.size > 2100000) {
      utils.displayMessage("negative", "Image too large");
      return;
    }
    if (userImage.width > 600) {
      utils.displayMessage(
        "negative",
        "Please upload a image with width less than 600"
      );
      return;
    }
    // if (userImage.height > 200) {
    //   utils.displayMessage('negative', 'Please upload a image with height less than 200');
    //   return;
    // }
    this.setState({
      ImageData: userImage,
      ImageLink: URL.createObjectURL(userImage),
    });
  };
  submit(data) {
    const {
      router: {
        history,
        route: {
          match: {
            params: { branch_id, business_id },
          },
        },
      },
    } = this.context;
    const {
      initialValues: { id, image: initialImage },
    } = this.props;
    const {
      reviewMethod,
      ImageData,
      reviewChannel,
      is_delete,
      showReviewMethods,
      selectReviewMethods,
      reviewGating,
      includeLocation,
      includeSurvey,
      selectedSubType,
    } = this.state;
    const { image, ...existingData } = data; // eslint-disable-line
    const { newsLetter } = this.props;
    const newData = id
      ? initialImage === ImageData
        ? { ...existingData }
        : { ...data, image: ImageData }
      : { ...data, image: ImageData ? ImageData : null };
    newData.template_type = "email_template";
    if (!newsLetter)
      newData.symbol_type = showReviewMethods
        ? reviewMethod
          ? reviewMethod
          : ""
        : null;
    else if (newsLetter) newData.symbol_type = "thumb";
    if (selectedSubType) {
      newData.sub_type = selectedSubType;
    }
    newData.review_gating =
      selectReviewMethods && showReviewMethods && reviewGating;
    newData.survey_section = includeSurvey && includeSurvey;
    newData.review_channels = reviewChannel;
    newData.body = existingData.body ? existingData.body : null;
    newData.is_delete = is_delete;
    newData.include_location = includeLocation;
    const url = newsLetter ? "/templates/news-letter/list" : "/templates/list";
    return this.props
      .submitData(newData)
      .then(() => history.push(`/${business_id}/${branch_id}${url}`))
      .catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        this.setState({ error: errobj });
        throw new SubmissionError(errobj);
      });
  }

  removeImage = () => {
    this.setState({ ImageData: null, ImageLink: null, is_delete: true });
  };

  componentDidUpdate() {
    // check for max charcter

    let { landingPageBody, emailBody, change, buttonText, subjectBody } =
      this.props;

    let landingPageBodyChars, emailBodyChar, buttonTextChar, subjectBodyChar;

    if (landingPageBody && landingPageBody.length > 2000) {
      landingPageBody = landingPageBody.substring(0, 2000);
      landingPageBodyChars = 0;
      change("body", landingPageBody);
    } else {
      landingPageBodyChars =
        2000 - (landingPageBody ? landingPageBody.length : 0);
    }

    if (emailBody && emailBody.length > 2000) {
      emailBody = emailBody.substring(0, 2000);
      emailBodyChar = 0;
      change("message", emailBody);
    } else {
      emailBodyChar = 2000 - (emailBody ? emailBody.length : 0);
    }

    if (subjectBody && subjectBody.length > 150) {
      subjectBody = subjectBody.substring(0, 150);
      subjectBodyChar = 0;
      change("subject", subjectBody);
    } else {
      subjectBodyChar = 150 - (subjectBody ? subjectBody.length : 0);
    }

    if (buttonText && buttonText.length > 60) {
      buttonText = buttonText.substring(0, 60);
      buttonTextChar = 0;
      change("btn_text", buttonText);
    } else {
      buttonTextChar = 60 - (buttonText ? buttonText.length : 0);
    }

    this.setState({
      landingPageBodyChars,
      emailBodyChar,
      buttonTextChar,
      subjectBodyChar,
    });
  }

  render() {
    const {
      submitting,
      handleSubmit,
      buttonText,
      newsLetter,
      errors,
      initialValues,
      reviewChannels,
      subTypeList,
      reset,
    } = this.props;
    const {
      landingPageBodyChars,
      emailBodyChar,
      buttonTextChar,
      reviewMethod,
      ImageLink,
      ImageData,
      reviewGating,
      showReviewMethods,
      subjectBodyChar,
      includeLocation,
      includeSurvey,
      selectReviewMethods,
    } = this.state;
    const {
      router: {
        route: {
          match: {
            params: { branch_id, business_id },
          },
        },
      },
    } = this.context;
    const breadCrumbList = [
      {
        label: "Templates",
        link: `/${business_id}/${branch_id}/templates/list?page_size=10`,
      },
      {
        label: newsLetter ? "News Letter" : "Review Request",
        link: newsLetter
          ? `/${business_id}/${branch_id}/templates/news-letter/list?page_size=10`
          : `/${business_id}/${branch_id}/templates/list?page_size=10`,
      },
      {
        label: newsLetter ? "News Letter Email" : "Review request email",
        link: "",
      },
    ];

    const genericSubTypeMenuList = subTypeList
      ? subTypeList.map((type) => ({
          label: utils.capitalize(type),
          value: type,
          onClick: () => this.handleSubTypeSelect(type),
        }))
      : [];

    return (
      <form className="grid-form" onSubmit={handleSubmit(this.submit)}>
        {errors && errors.non_field_errors
          ? errors.non_field_errors.map((error, index) => (
              <p key={index} className="text-danger">
                {error}
              </p>
            ))
          : null}

        <Row>
          <Col>
            <FPBreadcrumbs list={breadCrumbList} />
          </Col>
        </Row>

        <Row className="mt-20">
          <Col>
            <section className="d-flex flex-column gap-20">
              {/* Title section */}
              <CardWrapper
                headerTitle={newsLetter ? "NewsLetter Name" : "Template Name"}
                contentClass="p-4"
              >
                <FPLabel> Name </FPLabel>
                <Field
                  name="name"
                  type="text"
                  component={InputComponent}
                  serverError={errors && errors.name}
                  disabled={initialValues && initialValues.is_default}
                />
              </CardWrapper>

              {/* Email body data*/}
              <CardWrapper
                headerTitle={newsLetter ? "NewsLetter Body" : "Email Body"}
                contentClass="p-4"
              >
                <Row fluid className="align-center">
                  <Col xs={12} sm={12} md={6}>
                    <FPLabel>Subject</FPLabel>
                    <Field
                      name="subject"
                      type="text"
                      height={"40px"}
                      component={InputComponent}
                      serverError={errors && errors.subject}
                      minHeight={'unset'}
                    />
                    <small className="text-right mt-1 d-block">
                      {subjectBodyChar} characters left.
                    </small>
                  </Col>
                  {newsLetter ? (
                    <Col xs={12} sm={12} md={6}>
                      <FPLabel>Heading</FPLabel>
                      <Field
                        name="heading"
                        type="text"
                        component={InputComponent}
                        serverError={errors && errors.subject}
                      />
                    </Col>
                  ) : (
                    <Col xs={12} sm={12} md={6}>
                      <div className="d-flex justify-content-between align-items-center">
                        <FPLabel>
                          Include Location Address Banner
                        </FPLabel>
                        <Checkbox
                          toggle
                          checked={includeLocation}
                          onClick={(e, { checked }) =>
                            this.setState({ includeLocation: checked })
                          }
                        />
                      </div>
                    </Col>
                  )}
                </Row>
                <Row fluid>
                  <Col xs={12}>
                    <FPLabel>
                      {newsLetter ? "Content" : "Email Body"}
                    </FPLabel>
                    <Field
                      name="message"
                      type="text"
                      component={TextAreaComponent}
                      serverError={errors && errors.body}
                      placeholder="Enter a message body"
                    />
                    <small className="text-right mt-1 d-block">
                      {emailBodyChar} characters left.
                    </small>
                  </Col>
                </Row>
                {newsLetter && (
                  <Row fluid className="mt-3">
                    <Col xs={12}>
                      <div className="d-flex justify-content-between align-items-center">
                        <FPLabel>
                          Include Location Address Banner
                        </FPLabel>
                        <Checkbox
                          toggle
                          checked={includeLocation}
                          onClick={(e, { checked }) =>
                            this.setState({ includeLocation: checked })
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </CardWrapper>

              {/* News Letter Header */}
              {newsLetter && (
                <React.Fragment>
                  <CardWrapper
                    headerTitle={
                      <div className="d-flex align-items-center">
                        <span> News Letter Header &nbsp;</span>
                        <FPToolTip
                          title={
                            "Image should be .PNG/.JPG under 2 Mb with maximum size of 600px width."
                          }
                        >
                          <InfoOutlinedIcon />
                        </FPToolTip>
                      </div>
                    }
                    contentClass="p-4"
                  >
                    {!ImageData && (
                      <div>
                        <label htmlFor="file" className="cursor-pointer mb-0">
                          <FPButton
                            label="Upload Image"
                            className="pointer-events-none w-100"
                            startIcon={<BackupOutlinedIcon />}
                            fontWeight={400}
                            size="small"
                            variant="outline"
                            borderColor="#00B5AD"
                          >
                            Upload Image
                            <input
                              id="file"
                              type="file"
                              accept=".jpg, .jpeg, .png, .gif"
                              onChange={(e) => this.uploadImage(e)}
                              style={{ display: "none" }}
                            ></input>
                          </FPButton>
                        </label>
                      </div>
                    )}
                    {ImageData && (
                      <div className="nw-header-container">
                        <img className="nw-header-avatar" src={ImageLink} />
                        <label className="overlay" htmlFor="file">
                          <div className="nw-avatar-edit">
                            <div
                              style={{ marginTop: "35px", cursor: "pointer" }}
                            >
                              <Icon name="edit" size="small" />
                              Edit Image
                              <input
                                id="file"
                                hidden
                                type="file"
                                accept=".jpg, .jpeg, .png, .gif"
                                onChange={(e) => this.uploadImage(e)}
                              />
                            </div>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                this.removeImage();
                              }}
                              style={{ marginTop: "70px", cursor: "pointer" }}
                            >
                              <Icon name="remove" size="small" />
                              Delete Image
                            </div>
                          </div>
                        </label>
                      </div>
                    )}
                  </CardWrapper>

                  {/* Button Info */}
                  <CardWrapper headerTitle="Button Info" contentClass="p-4">
                    <div className="d-flex gap-20">
                      <div style={{ width: "45%" }}>
                        <FPLabel isRequired={false}>Button Text</FPLabel>
                        <Field
                          name="btn_text"
                          type="text"
                          component={InputComponent}
                          serverError={errors && errors.button_text}
                        />
                        <small className="text-right d-block">
                          {buttonTextChar} characters left.
                        </small>
                      </div>
                      <div style={{ width: "45%" }}>
                        <FPLabel isRequired={false}>Button Link</FPLabel>
                        <Field
                          name="link"
                          type="text"
                          component={InputComponent}
                          serverError={errors && errors.button_text}
                        />
                      </div>
                      <div style={{ width: "10%", alignSelf: "center" }}>
                        {buttonText && (
                          <FPButton
                            size="small"
                            disabled
                            label={buttonText}
                            className="w-100"
                          />
                        )}
                      </div>
                    </div>
                  </CardWrapper>
                </React.Fragment>
              )}

              {/* Show review methods Landing page data*/}
              {!newsLetter && (
                <CardWrapper
                  headerTitle={
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Show Review Methods</span>
                      <Checkbox
                        toggle
                        checked={showReviewMethods}
                        onClick={this.handleShowReviewMethods}
                      />
                    </div>
                  }
                  contentClass="pb-3 pt-4 px-4"
                  showDivider={showReviewMethods}
                >
                  <Collapse in={showReviewMethods}>
                    <React.Fragment>
                      <FPLabel
                        isRequired={false}
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          marginBottom: "10px",
                        }}
                      >
                        Landing Page
                      </FPLabel>
                      <Row>
                        <Col>
                          <FPLabel>Heading</FPLabel>
                          <Field
                            name="heading"
                            type="text"
                            component={InputComponent}
                            serverError={errors && errors.heading}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FPLabel>Body</FPLabel>
                          <Field
                            name="body"
                            type="text"
                            component={TextAreaComponent}
                            serverError={errors && errors.heading}
                          />
                          <small className="text-right mt-1 d-block">
                            {landingPageBodyChars} characters left.
                          </small>
                        </Col>
                      </Row>
                    </React.Fragment>
                  </Collapse>
                </CardWrapper>
              )}

              {/* Select Review Methods */}
              {!newsLetter && (
                <CardWrapper
                  headerTitle={
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Select Review Methods</span>
                      <Checkbox
                        toggle
                        checked={selectReviewMethods}
                        onClick={this.handleSelectReviewMethods}
                      />
                    </div>
                  }
                  contentClass="pb-3 pt-4 px-4"
                  showDivider={selectReviewMethods}
                >
                  <Collapse in={selectReviewMethods}>
                    <React.Fragment>
                      <ReviewMethodsOptions
                        className="mt-3"
                        reviewMethod={reviewMethod}
                        handleReviewMethod={this.handleReviewMethod}
                      />
                      <div className="d-flex my-3 justify-content-between align-item-center">
                        <FPLabel isRequired={false}>Review Gating</FPLabel>
                        <Checkbox
                          toggle
                          checked={reviewGating}
                          onClick={this.handleReviewGating}
                        />
                      </div>

                      {reviewChannels && reviewChannels ? (
                        <MultipleButtonSelect
                          reviewChannels={reviewChannels && reviewChannels}
                          handleReviewChannel={this.handleReviewChannel}
                          initialValues={
                            initialValues && initialValues.review_channels
                          }
                          serverError={errors && errors.name}
                        />
                      ) : (
                        ""
                      )}
                      <p className="text-danger">
                        {this.state.error.review_channels}
                      </p>

                      <div className="d-flex my-3 justify-content-between align-items-center">
                        <FPLabel isRequired={false}>Include Survey</FPLabel>
                        <Checkbox
                          toggle
                          checked={includeSurvey}
                          onClick={this.handleIncludeSurvey}
                        />
                      </div>

                      {includeSurvey && (
                        <div className="d-flex my-3 justify-content-between align-items-center">
                          <FPLabel isRequired={false}>
                            Generic Template Sub Type
                          </FPLabel>
                          <FPMenuBox
                            title={
                              this.state.selectedSubType
                                ? this.state.selectedSubType
                                : "Select"
                            }
                            options={genericSubTypeMenuList}
                            size={"small"}
                            className={"fp-size-12"}
                            rounded={true}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  </Collapse>
                </CardWrapper>
              )}
            </section>

            <div className="mt-4 d-flex align-items-center justify-content-end gap-10">
              <FPButton
                size="small"
                variant="outline"
                borderColor="#BFC2CB"
                textColor="#1B1C1D"
                label="Clear"
                onClick={() => reset()}
                className="min-w-100px"
              />
              <FPButton
                size="small"
                type="submit"
                disabled={submitting}
                label="Save"
                className="min-w-100px"
              />
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}

TemplateEmailForm.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

TemplateEmailForm = reduxForm({
  // eslint-disable-line
  form: "TemplateEmailForm",
  validate,
  enableReinitialize: false,
})(TemplateEmailForm);

const selector = formValueSelector("TemplateEmailForm");

TemplateEmailForm = connect((state) => {
  // eslint-disable-line
  const landingPageBody = selector(state, "body");
  const buttonText = selector(state, "btn_text");
  const subjectBody = selector(state, "subject");
  const emailBody = selector(state, "message");

  return {
    landingPageBody,
    buttonText,
    subjectBody,
    emailBody,
  };
})(TemplateEmailForm);

export default TemplateEmailForm;
