import { SET_SELECTED_TAB, SET_SELECTED_TAB_UPDATE_PHOTO } from '../actionTypes';

export const setSelectedTab = (tabIndex) => ({
  type: SET_SELECTED_TAB,
  payload: tabIndex,
});

export const setSelectedTabUpdatePhoto = (tabIndex) => ({
  type: SET_SELECTED_TAB_UPDATE_PHOTO,
  payload: tabIndex,
});