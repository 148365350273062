import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import { connect } from "react-redux";

import TemplateSmsForm from "../../../components/campaign/templates/TemplateSmsForm";
import TemplateWhatsappForm from "../../../components/campaign/templates/TemplateWhatsappForm";
import TemplateEmailForm from "../../../components/campaign/templates/TemplateEmailForm";
import LoaderComponent from "../../../components/common/loaderComponent";

import * as TemplateActions from "../../../actions/template/templateActions";
import * as ReviewsActions from "../../../actions/business/reviewsActions";
import axios_instance from "../../../libs/interseptor";
import * as utils from "../../../libs/utils";
import * as URLS from "../../../libs/apiUrls";

import { Row, Col } from "reactstrap";
import moment from "moment/moment";

class TemplateEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subTypeList: [],
      provider: null,
    };
    this.submitData = this.submitData.bind(this);
  }

  getProvider(param) {
    const { reviewChannels, ...params } = param;
    const url = reviewChannels
      ? `${URLS.REVIEW_SOURCE}?${reviewChannels}`
      : `${URLS.REVIEW_SOURCE}`;
    axios_instance
      .get(url, { params })
      .then((res) => {
        this.setState({ provider: res.data });
        return res;
      })
      .catch((err) => {
        throw err.response.data;
      });
  }

  submitData(data) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions.postTemplate(data, business_id);
  }

  componentDidMount() {
    // TO DO
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const {
      route: {
        location: { search },
      },
    } = this.context.router;
    const { id } = queryString.parse(search);
    const reviewChannels = "review_source=review_channels";
    if (id)
      this.props.actions.getTemplateDetails({ branch_id: business_id, id });
    this.getProvider({ reviewChannels, business_id });
    axios_instance
      .get(utils.format(URLS.TEMPLATE_SUBTYPE, [business_id]))
      .then((res) => {
        this.setState({ subTypeList: res.data.results });
      });
  }

  render() {
    const {
      route: {
        location: { search },
      },
    } = this.context.router;
    const { id } = queryString.parse(search);
    const {
      template: { detail },
    } = this.props;
    const { provider } = this.state;
    const { type } = queryString.parse(search);
    const newData = {
      name:
        `Review_${
          type === "sms" ? "SMS" : type === "whatsapp" ? "Whatsapp" : "Email"
        }` + moment().format("_DD-MM(HH:mm:ss)"),
      subject: "Follow up",
      heading: "How was your experience?",
      body: "Thank you for choosing us! Please take a moment to leave us a review. Your responses will help us serve you better in the future.",
      symbol_type: "thumb",
    };
    if (id && +id !== (detail && detail.id)) return null;
    const formData = id ? detail : newData;

    return (
      <Row className="content-area">
        <LoaderComponent loader={this.props.loader} />
        <Col xs={12} sm={12} className="business-setup p-3">
          {type === "sms" && (
            <TemplateSmsForm
              reviewChannels={provider && provider.choices}
              submitData={this.submitData}
              initialValues={formData}
              subTypeList={this.state.subTypeList}
            />
          )}
          {type === "email" && (
            <TemplateEmailForm
              reviewChannels={provider && provider.choices}
              submitData={this.submitData}
              initialValues={formData}
              subTypeList={this.state.subTypeList}
            />
          )}
          {type === "whatsapp" && (
            <TemplateWhatsappForm
              reviewChannels={provider && provider.choices}
              submitData={this.submitData}
              initialValues={formData}
              subTypeList={this.state.subTypeList}
            />
          )}
        </Col>
      </Row>
    );
  }
}

TemplateEditPage.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  return {
    template: state.template,
    loader: state.common.loader,
    // provider: state.review.review_source_options
  };
};

TemplateEditPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  template: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(TemplateActions, dispatch),
    reviewActions: bindActionCreators(ReviewsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TemplateEditPage);
