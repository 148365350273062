/* eslint-disable no-unused-vars */
import * as yup from "yup";

const useRegisterForm = () => {
  const isAlphaWithSpace = (value) => /^[a-zA-Z\s]+$/.test(value); // * allow only alphabetic characters and spaces.
  const validationSchema = yup.object().shape({
    email: yup.string().email().required().label("Email"),
    business_name: yup.string().required().label("Business Name"),
    first_name: yup.string().test("is-alpha", "Invalid First Name", isAlphaWithSpace).required().label("First Name"),
    last_name: yup.string().test("is-alpha", "Invalid Last Name", isAlphaWithSpace).required().label("Last Name"),
    contact_number: yup.string().required().label("Mobile number"),
    password: yup
      .string()
      .required()
      .label("Password")
      .min(6)
      .matches(/[^\w]/, "Password requires at least one symbol"),
    re_password: yup
      .string()
      .required()
      .label("Confirm password")
      .oneOf([yup.ref("password")], "Passwords must match"),
    business_type: yup.string().required().label("Select business type"),
    terms: yup.boolean().oneOf([true], "Select the checkbox"),
    business_category: yup.string().when("business_type", {
      is: (val) => val !== "marketing",
      then: yup.string().required("Select Business Category"),
    }),
    other: yup.string().when(["business_type", "business_category"], {
      is: (businessType, businessCategory) => businessType !== "marketing" && businessCategory === "other",
      then: yup.string().required("Enter Other Field"),
    }),
  });

  const initialValue = {
    email: "",
    business_name: "",
    first_name: "",
    last_name: "",
    contact_number: "",
    password: "",
    re_password: "",
    business_type: "",
    terms: false,
    business_category: "",
    other: "",
  };

  return { initialValue, validationSchema };
};

export default useRegisterForm;
