import React, { useEffect, useState } from "react";
import * as URLS from "../../libs/apiUrls";
import { getAllParamsInArrayForm, getParams, removeLifetimeFilter } from "../../libs/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonAction from "../../actions/common/commonAction";
import * as filterListActions from "../../actions/common/filterListActions";
import { FPButton, FPFilter, FPToolTip } from "../../components/common/new";
import { IconButton } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../images/svg/Download.svg";
import FormModal from "../../components/common/FormModal";
import moment from "moment";

const ServiceRequestFilter = (props) => {
  const {
    common,
    history,
    filter,
    filterAction,
    match,
    pageSizeCookie,
    location,
    departmentList,
    ticketRequestTypeList,
    teamList,
    commonActions,
    downloadExcel,
    getFilterData
  } = props;
  const [isMoreThanNinety, setIsMoreThanNinety] = useState(false);

  const dateType = getParams(location.search, "date_type");
  const startDate = getParams(location.search, "start_date");
  const endDate = getParams(location.search, "end_date");

  const fetchFilterList = async () => {
    const {
      params: { business_id },
    } = match;
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch("", `${URLS.BUSINESS_SETUP}${business_id}/branch/`),
        filterAction.getContentType("", `${URLS.CONTENT_OPTIONS}`),
        commonActions.cityOptions(business_id),
        commonActions.stateOptions(business_id),
        commonActions.countryOptions(business_id),
        commonActions.branchTagOptions(business_id),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  const statusData = [
    {
      value: "in_queue",
      display_name: "In Queue",
      parentKey: "status",
    },
    {
      value: "assigned",
      display_name: "Assigned",
      parentKey: "status",
    },
    {
      value: "resolved",
      display_name: "Resolved",
      parentKey: "status",
    },
    {
      value: "reopened",
      display_name: "Reopened",
      parentKey: "status",
    },
    {
      value: "waiting_for_customer",
      display_name: "Waiting for customer",
      parentKey: "status",
    },
    {
      value: "in_progress",
      display_name: "In Progress",
      parentKey: "status",
    },
  ];

  const handleReviewDownloadFlow = () => {
    if (dateType === "other") {
      const M_startDate = moment(startDate);
      const M_endDate = moment(endDate);
      const daysDifference = M_endDate.diff(M_startDate, "days");
      if (daysDifference > 90) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    } else {
      if (["prior_6_month", "current_year", "prior_12_months"].indexOf(dateType) > -1) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    }
  };

  useEffect(() => {
    fetchFilterList();
  }, []);

  const handleDefaultFilter = (list, key) => {
    const filter = location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable = defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list.map((item) => {
      item["isChecked"] = isKeyFilterAvailable && defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };

  const RequestModeList = [
    {
      value: "true",
      display_name: "Request",
    },
    {
      value: "false",
      display_name: "Complaint",
    },
  ];

  const requestTypeOption = ticketRequestTypeList?.map((item) => ({
    value: item.id,
    display_name: item.title,
  }));

  const departmentOption = departmentList?.map((item) => ({
    value: item.id,
    display_name: item.name,
  }));

  const priorityData = [
    { display_name: "Low", value: "low" },
    { display_name: "Medium", value: "medium" },
    { display_name: "High", value: "high" },
    { display_name: "Urgent", value: "urgent" },
  ];

  const filterList = [
    {
      id: "1",
      label: "Date Duration",
      key: "date_type",
      type: "radio",
      isSearchable: false,
      list: filter.date_type && handleDefaultFilter(removeLifetimeFilter(filter?.date_type?.choices), "date_type"),
    },
    {
      id: "2",
      label: "Request Mode",
      key: "is_request",
      type: "radio",
      isSearchable: false,
      list: RequestModeList && handleDefaultFilter(RequestModeList, "is_request"),
    },
    {
      id: "3",
      label: "Request Type",
      key: "service_request_type",
      type: "radio",
      isSearchable: false,
      list: requestTypeOption && handleDefaultFilter(requestTypeOption, "service_request_type"),
    },
    {
      id: "4",
      label: "Priority",
      key: "priority",
      type: "radio",
      isSearchable: false,
      isNotEllipsisLabel: true,
      list: priorityData && handleDefaultFilter(priorityData, "priority"),
    },
    {
      id: "5",
      label: "Department",
      key: "department",
      type: "radio",
      isSearchable: false,
      list: departmentOption && handleDefaultFilter(departmentOption, "department"),
    },
    {
      id: "6",
      label: "Status",
      key: "status",
      type: "checkbox",
      isSearchable: false,
      isNotEllipsisLabel: false,
      list: statusData && handleDefaultFilter(statusData, "status"),
    },
    {
      id: "7",
      label: "Location",
      key: "branch",
      type: "radio",
      isSearchable: true,
      list: filter.branch && handleDefaultFilter(filter.branch.choices, "branch"),
    },
    {
      id: "8",
      label: "Location Tag",
      key: "branch_tag",
      type: "checkbox",
      isSearchable: true,
      list:
        common.branch_tag_options &&
        handleDefaultFilter(
          common.branch_tag_options.results.map((item) => ({
            value: item.id,
            display_name: item.title,
          })),
          "branch_tag"
        ),
    },
    {
      id: "9",
      label: "Assigned To",
      key: "assigned",
      type: "radio",
      isSearchable: false,
      list: teamList && handleDefaultFilter(teamList, "assigned"),
    },
    {
      id: "10",
      label: "Country",
      screenName: "country",
      key: "country",
      type: "checkbox",
      isSearchable: true,
      list: common.country_options && handleDefaultFilter(common.country_options.choices, "country"),
    },
    {
      id: "11",
      label: "City",
      key: "city",
      type: "checkbox",
      isSearchable: true,
      list: common.city_options && handleDefaultFilter(common.city_options.choices, "city"),
    },
    {
      id: "12",
      label: "State",
      key: "state",
      type: "checkbox",
      isSearchable: true,
      list: common.state_options && handleDefaultFilter(common.state_options.choices, "state"),
    },
  ].filter((item) => !!item);
  return (
    <React.Fragment>
      <FPFilter
        data={filterList}
        className="mt-3"
        history={history}
        pageSizeCookie={pageSizeCookie}
        ticketFilter="TICKET"
        getData={getFilterData}
        match={match}
        actionRender={() => (
          <FPToolTip title={"Download"}>
            <IconButton component="span" className="circular-button" onClick={() => handleReviewDownloadFlow()}>
              <DownloadIcon />
            </IconButton>
          </FPToolTip>
        )}
      />
      {isMoreThanNinety && (
        <FormModal
          isOpen={isMoreThanNinety}
          toggle={() => setIsMoreThanNinety(false)}
          heading="Attention !"
          maxWidth="xs"
          showDivider={true}
          dialogActions={<FPButton size="small" onClick={() => setIsMoreThanNinety(false)} label="OK" />}
        >
          <p className="fp-size-16">Please contact to support to download data older than 90 days.</p>
        </FormModal>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    filter: state.filter,
    source: state.review.review_source_options,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ServiceRequestFilter);
