import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import * as ProfileActions from "../../actions/accounts/profileAction";
import LoaderComponent from "../../components/common/loaderComponent";
import BranchSetupForm from "../../components/business/BranchSetupForm";
import { Box } from "@material-ui/core";

class BranchSetupPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleBranchSetup = this.handleBranchSetup.bind(this);
  }

  handleBranchSetup(values) {
    const {
      router: {
        history,
        route: {
          match: { params },
        },
      },
    } = this.context;
    console.log(this.context);
    return this.props.actions.setupBranchLocation({ ...values, id: params.business_id }).then((res) => {
      // eslint-disable-line
      this.props.profileActions.getProfileInfo();
      let url = "/" + params.business_id + "/dashboard";
      history.push(url);
    });
  }

  render() {
    return (
      <Box sx={{ height: "100vh" }}>
        <div className="d-flex justify-content-center h-100 align-items-center">
          <LoaderComponent loader={this.props.loader} />
          <BranchSetupForm
            handleBranchSetup={this.handleBranchSetup}
            errors={this.props.errors}
            colStyle={{
              minWidth: "600px",
            }}
            style={{
              backgroundColor: "white",
              borderRadius: "12px",
              paddingBottom: "20px",
              boxShadow: "0px 38.4px 76.8px -14.4px rgba(16, 24, 40, 0.14)",
            }}
          />
        </div>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  errors: state.business.errors,
  loader: state.common.loader,
});

BranchSetupPage.contextTypes = {
  router: PropTypes.object.isRequired,
};

BranchSetupPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  error: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
    profileActions: bindActionCreators(ProfileActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(BranchSetupPage);
