export const sourceImages = {
  google: require('./google.png'),
  zomato: require('./zomato.png'),
  tripadvisor: require('./tripadvisor.png'),
  facebook: require('./facebook.png'),
  dineout: require('./dineout.png'),
  agoda: require('./agoda.png'),
  makemytrip: require('./makemytrip.png'),
  hotels: require('./hotels.png'),
  expedia: require('./expedia.png'),
  booking: require('./booking.png'),
  goibibo: require('./goibibo.png'),
  twitter: require('../twitter.png'),
  instagram: require('../instagram_logo.png'),
  youtube: require('../youtube-logo.png'),
  linkedin: require('../linkedin-logo.png'),
  playstore: require('../playstore.png'),
  gmail: require('../Gmail.png')
};