import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';
import axios_instance from '../../libs/interseptor';
import * as commonAction from '../common/commonAction';
import * as utils from '../../libs/utils';

export function createRecurringCampaignSuccess(data) {
  return { type: types.CREATE_RECURRING_CAMPAIGN_SUCCESS, data };
}

export function createRecurringCampaignFailure(data) {
  return { type: types.CREATE_RECURRING_CAMPAIGN_FAILURE, data };
}

export const createRecurringCampaign = (data, business_id) => {
  if (data.id){
    return (dispatch) => {
      dispatch( commonAction.showLoader() );
      return axios_instance.patch(`${utils.format(URLS.RECURRING_CAMPAIGN,[business_id])}${data.id}/`, data)
        .then(
          (res) => {
            dispatch( commonAction.hideLoader() );
            dispatch(createRecurringCampaignSuccess(res.data));
            return res;
          }
        )
        .catch(
          (err) => {
            dispatch( commonAction.hideLoader() );
            dispatch(createRecurringCampaignFailure(err));
            throw err.response.data;
          }
        );
    };
  }else{
    return (dispatch) => {
      dispatch( commonAction.showLoader() );
      return axios_instance.post(utils.format(URLS.RECURRING_CAMPAIGN,[business_id]), data)
        .then(
          (res) => {
            dispatch( commonAction.hideLoader() );
            dispatch(createRecurringCampaignSuccess(res.data));
            return res;
          }
        )
        .catch(
          (err) => {
            dispatch( commonAction.hideLoader() );
            dispatch(createRecurringCampaignFailure(err));
            throw err.response.data;
          }
        );
    };
  }
};

export function getAllRecurringCampaignSuccess(data) {
  return { type: types.GET_ALL_RECURRING_CAMPAIGN_SUCCESS, data };
}

export function getAllRecurringCampaignFailure(data) {
  return { type: types.GET_ALL_RECURRING_CAMPAIGN_FAILURE, data };
}

export const getAllRecurringCampaign = (params, business_id) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.RECURRING_CAMPAIGN,[business_id]), { params })
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getAllRecurringCampaignSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getAllRecurringCampaignFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getRecurringCampaignDetailsSuccess(data) {
  return { type: types.GET_RECURRING_CAMPAIGN_DETAILS_SUCCESS, data };
}

export function getRecurringCampaignDetailsFailure(data) {
  // let data = {};
  // data.errors = error.response.data;
  return { type: types.GET_RECURRING_CAMPAIGN_DETAILS_FAILURE, data };
}

export const getRecurringCampaignDetails = (business_id, id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(`${utils.format(URLS.RECURRING_CAMPAIGN,[business_id])}${id}/`)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(getRecurringCampaignDetailsSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(getRecurringCampaignDetailsFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function recurringCampaignDeleteSuccess(data) {
  return { type: types.RECURRING_CAMPAIGN_DELETE_SUCCESS, data };
}

export function recurringCampaignDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.RECURRING_CAMPAIGN_DELETE_FAILURE, data };
}

export const recurringCampaignDelete = (business_id, id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.delete(`${utils.format(URLS.RECURRING_CAMPAIGN,[business_id])}${id}/`)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(recurringCampaignDeleteSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(recurringCampaignDeleteFailure(err));
          throw err.response.data;
        }
      );
  };
};

//newsletter actions
export function getAllNewsletterRecurringCampaignSuccess(data) {
  return { type: types.GET_ALL_NEWSLETTER_RECURRING_CAMPAIGN_SUCCESS, data };
}

export function getAllNewsletterRecurringCampaignFailure(data) {
  return { type: types.GET_ALL_NEWSLETTER_RECURRING_CAMPAIGN_FAILURE, data };
}

export const getAllNewsletterRecurringCampaign = (params, business_id) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.NEWSLETTER_RECURRING_CAMPAIGN,[business_id]), { params })
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getAllNewsletterRecurringCampaignSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getAllNewsletterRecurringCampaignFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function createNewsletterRecurringCampaignSuccess(data) {
  return { type: types.CREATE_NEWSLETTER_RECURRING_CAMPAIGN_SUCCESS, data };
}

export function createNewsletterRecurringCampaignFailure(data) {
  return { type: types.CREATE_NEWSLETTER_RECURRING_CAMPAIGN_FAILURE, data };
}

export const createNewsletterRecurringCampaign = (data, business_id) => {
  if (data.id){
    return (dispatch) => {
      dispatch( commonAction.showLoader() );
      return axios_instance.patch(`${utils.format(URLS.NEWSLETTER_RECURRING_CAMPAIGN,[business_id])}${data.id}/`, data)
        .then(
          (res) => {
            dispatch( commonAction.hideLoader() );
            dispatch(createNewsletterRecurringCampaignSuccess(res.data));
            return res;
          }
        )
        .catch(
          (err) => {
            dispatch( commonAction.hideLoader() );
            dispatch(createNewsletterRecurringCampaignFailure(err));
            throw err.response.data;
          }
        );
    };
  }else{
    return (dispatch) => {
      dispatch( commonAction.showLoader() );
      return axios_instance.post(utils.format(URLS.NEWSLETTER_RECURRING_CAMPAIGN,[business_id]), data)
        .then(
          (res) => {
            dispatch( commonAction.hideLoader() );
            dispatch(createNewsletterRecurringCampaignSuccess(res.data));
            return res;
          }
        )
        .catch(
          (err) => {
            dispatch( commonAction.hideLoader() );
            dispatch(createNewsletterRecurringCampaignFailure(err));
            throw err.response.data;
          }
        );
    };
  }
};

export function getNewsletterRecurringCampaignDetailsSuccess(data) {
  return { type: types.GET_NEWSLETTER_RECURRING_CAMPAIGN_DETAILS_SUCCESS, data };
}

export function getNewsletterRecurringCampaignDetailsFailure(data) {
  // let data = {};
  // data.errors = error.response.data;
  return { type: types.GET_NEWSLETTER_RECURRING_CAMPAIGN_DETAILS_FAILURE, data };
}

export const getNewsletterRecurringCampaignDetails = (business_id, id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(`${utils.format(URLS.NEWSLETTER_RECURRING_CAMPAIGN,[business_id])}${id}/`)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(getNewsletterRecurringCampaignDetailsSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(getNewsletterRecurringCampaignDetailsFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function newsletterRecurringCampaignDeleteSuccess(data) {
  return { type: types.NEWSLETTER_RECURRING_CAMPAIGN_DELETE_SUCCESS, data };
}

export function newsletterRecurringCampaignDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.NEWSLETTER_RECURRING_CAMPAIGN_DELETE_FAILURE, data };
}

export const newsletterRecurringCampaignDelete = (business_id, id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.delete(`${utils.format(URLS.NEWSLETTER_RECURRING_CAMPAIGN,[business_id])}${id}/`)
      .then(
        (res) => {
          dispatch(commonAction.hideLoader());
          dispatch(newsletterRecurringCampaignDeleteSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(commonAction.hideLoader());
          dispatch(newsletterRecurringCampaignDeleteFailure(err));
          throw err.response.data;
        }
      );
  };
};