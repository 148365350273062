import React ,{ useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {Popup } from 'semantic-ui-react';
import SortIcon from '../../images/svg/Sort.svg';
import CardWrapper from '../../modules/dashboard/component/CardWrapper';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";


const TicketTable = (props) => {
  let [dataa ,setDataState] = useState('');
  let { data,tableHeader, column1, column2, column3, column4, column6, column5, column7, column8, column9, column10, dataHeader, handleSortTicketData,activeCol} = props;

  useEffect(() =>{
    setDataState(data);
  }, [props]);

  useEffect(() =>{
    if(props.newData !== undefined && props.newData !== ''){
      setDataState(props.newData);
    }
  }, [props.newData]);

  return (
    <div>
      <CardWrapper className="p-3" headerTitle={dataHeader}>
        <div className="ui-table-responsive">
          <Table className="fp-table fp-table-center">
            <TableHead>
              <TableRow>
                <TableCell>S.No </TableCell>
                <TableCell className="width-insights-th">{tableHeader}</TableCell>
                { column1 && <TableCell onClick={()=>handleSortTicketData(data, 'num of tickets')} className={`${activeCol==='num of tickets' ? 'sortCellStyleHeader': ''}`}>Num Of Tickets <SortIcon /></TableCell>}
                { column2 && <TableCell onClick={()=>handleSortTicketData(data, 'unresponded reviews')} className={`${activeCol==='unresponded reviews' ? 'sortCellStyleHeader': ''}`}>Unresponded Reviews <br /><SortIcon /></TableCell>}
                { column3 && <TableCell onClick={()=>handleSortTicketData(data, 'open tickets')} className={`${activeCol==='open tickets' ? 'sortCellStyleHeader': ''}`}>Open Tickets <SortIcon /></TableCell>}
                { column4 && <TableCell onClick={()=>handleSortTicketData(data, 'close tickets')} className={`${activeCol==='close tickets' ? 'sortCellStyleHeader': ''}`}>Closed Tickets <SortIcon /></TableCell>}
                { column5 && <TableCell onClick={()=>handleSortTicketData(data, 'percentage open tickets')} className={`${activeCol==='percentage open tickets' ? 'sortCellStyleHeader': ''}`}>% Open Tickets <SortIcon /></TableCell>}                                  
                { column6 && <TableCell onClick={()=>handleSortTicketData(data, 'response time msg')}>Avg Closure Duration (dd:hh:mi:ss) </TableCell>}                                                      
                { column10 && <TableCell onClick={()=>handleSortTicketData(data, 'assigned user response duration')}>Avg Assigned User Response Duration (dd:hh:mi:ss)</TableCell>}                                                      
                { column7 && <TableCell  onClick={()=>handleSortTicketData(data, 'open more than 24 hrs')} className={`${activeCol==='open more than 24 hrs' ? 'sortCellStyleHeader': ''}`}>{column7}<SortIcon /></TableCell>}                                  
                { column8 && <TableCell  onClick={()=>handleSortTicketData(data, 'open more than 48 hrs')} className={`${activeCol==='open more than 48 hrs' ? 'sortCellStyleHeader': ''}`}>{column8} <SortIcon /></TableCell>}                                  
                { column9 && <TableCell  onClick={()=>handleSortTicketData(data, 'open more than 7 days')} className={`${activeCol==='open more than 7 days' ? 'sortCellStyleHeader': ''}`}>{column9}<SortIcon /></TableCell>}                                  
              </TableRow>
            </TableHead>
            <TableBody>
              {
                dataa && Object.keys(dataa).length ?
                  Object.keys(dataa).map((item, index) => {
                    return(
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>
                          {dataa[item].hasOwnProperty('location_address') ? (<Popup trigger={<div>{item}</div>} size='mini' content={dataa[item].location_address}/>): (<div>{item}</div>)}
                          </TableCell>
                        {column1 && <TableCell className={`${activeCol==='num of tickets' ? 'sortCellStyle': ''}`}>
                          {dataa[item][column1]}
                        </TableCell>}
                        {column2 && <TableCell className={`${activeCol==='unresponded reviews' ? 'sortCellStyle': ''}`}>
                          {dataa[item][column2]}
                        </TableCell>}
                        {column3 && <TableCell className={`${activeCol==='open tickets' ? 'sortCellStyle': ''}`}>
                          {dataa[item][column3]}
                        </TableCell>}
                        {column4 && <TableCell className={`${activeCol==='close tickets' ? 'sortCellStyle': ''}`}>
                          {dataa[item][column4]}
                        </TableCell>}
                        {column5 && <TableCell className={`${activeCol==='percentage open tickets' ? 'sortCellStyle': ''}`}>
                          {dataa[item][column5] ? dataa[item][column5] : 0}
                        </TableCell>}
                        {column6 && <TableCell>
                          {dataa[item][column6] ? dataa[item][column6] : 0}
                        </TableCell>}
                        {column10 && <TableCell>
                          {dataa[item][column10] ? dataa[item][column10] : 0}
                        </TableCell>}
                        {column7&& <TableCell className={`${activeCol==='open more than 24 hrs' ? 'sortCellStyle': ''}`}>
                          {dataa[item]['open more than 24 hrs'] ? dataa[item]['open more than 24 hrs'] : 0}
                        </TableCell>}
                        {column8&& <TableCell className={`${activeCol==='open more than 48 hrs' ? 'sortCellStyle': ''}`}>
                          {dataa[item]['open more than 48 hrs'] ? dataa[item]['open more than 48 hrs'] : 0}
                        </TableCell>}
                        {column9&& <TableCell className={`${activeCol==='open more than 7 days' ? 'sortCellStyle': ''}`}>
                          {dataa[item]['open more than 7 days'] ? dataa[item]['open more than 7 days'] : 0}
                        </TableCell>}
                      </TableRow>);})
                  : <TableRow><TableCell className="text-center" colSpan={10}>No records Found </TableCell></TableRow>
              }
            </TableBody>
          </Table>
        </div>
      </CardWrapper>
    </div>
  );
};

TicketTable.propTypes = {
  data: PropTypes.array.isRequired,
};


export default TicketTable;
