import React from 'react';
import { Form, Header, Segment, Image, Grid } from 'semantic-ui-react';
import surveyThanks from '../../images/reviewMethods/surveyThanks.png';


class SurveyDuplicate extends React.Component {
  constructor(props) {
    super(props);
  }
  
  render() {
    const {business, duplicateReviewer} = this.props;
    return (
      <div className='BackGorund__img'>
        <Grid textAlign='center' style={{}} verticalAlign='middle'>
          <Grid.Column textAlign='left' text style={{ maxWidth: 380}}>
            <Form size='large' >
              <Segment stacked rounded style={{ height: 'auto', borderRadius: '20px' ,backgroundColor:'white'}}>
                <Header as='h2' textAlign='center' style={{ marginBottom: '20px', color: 'darkgreen', fontSize: '32px' }}>
                  <Image src={business ? business.logo : ''} />
                </Header>
                <Image style={{height:'250px' , marginLeft:'25px'}} src={surveyThanks}/>
                <Header as="h3" style={{color:'#0F62FE'}} textAlign='center'>
                  Hey {duplicateReviewer ? duplicateReviewer : ''},
                </Header>
                <Header as="h3" textAlign='center' style={{color:'red'}}>
                  You have already submitted Review.
                </Header>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default SurveyDuplicate;