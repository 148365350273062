import React from "react";
import VisibilitySensor from "react-visibility-sensor";
import LoaderComponent from "../messenger/Common/LoaderComponent";
import TopicsReviewCard from "./TopicsReviewCard";

class TopicsReviews extends React.Component {
  state = {
    activeIndex: -1,
  };

  handleClick = (e, titleProps, id) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex, reviewLoader: true });
    this.props
      .fullReview(id)
      .then(() => this.setState({ reviewLoader: false }));
  };

  loadReviews = (isVisible) => {
    const {
      reviews: { next },
    } = this.props;
    if (isVisible && next) {
      this.setState({ moreReviewsLoader: true });
      this.props
        .getChunks()
        .then(() => this.setState({ moreReviewsLoader: false }));
    }
  };

  render() {
    const {
      reviews,
      checked: {
        nouns,
        adjectives: { positive, negative },
      },
      loader,
    } = this.props;
    const { activeIndex, reviewLoader } = this.state;
    const searchWords = [
      ...nouns.keys(),
      ...positive.keys(),
      ...negative.keys(),
    ];
    return (
      <div className="mr-2 d-flex flex-column gap-15">
        {reviews.results ? (
          reviews.results.map((item, i) => {
            if (i == reviews.results.length - 1) {
              return (
                <VisibilitySensor onChange={this.loadReviews} key={i}>
                  <TopicsReviewCard
                    key={i}
                    {...this.props}
                    searchWords={searchWords}
                    i={i}
                    item={item}
                    reviewLoader={reviewLoader}
                    activeIndex={activeIndex}
                    handleClick={this.handleClick}
                  />
                </VisibilitySensor>
              );
            }
            return (
              <TopicsReviewCard
                key={i}
                {...this.props}
                searchWords={searchWords}
                i={i}
                item={item}
                reviewLoader={reviewLoader}
                activeIndex={activeIndex}
                handleClick={this.handleClick}
              />
            );
          })
        ) : (
          <></>
        )}
        <div className="d-flex justify-content-center mt-4">
          <LoaderComponent showLoader={loader} />
        </div>
      </div>
    );
  }
}

export default TopicsReviews;
