import React from 'react';
import './style.css';
import { Dimmer } from 'semantic-ui-react';

const lockContent = ({message}) => {
  return (
    <div className='locked'>
      <i className="fa fa-lock" aria-hidden="true" style={{fontSize: '200px'}}></i>
      <p style={{fontSize: '25px'}}>{message}</p>
    </div>
  );
};

const LockedComponent = ({message,hideDim}) => {
  return (
    <div>
      {message && (hideDim?lockContent({message}):<Dimmer style={{zIndex: 10}} active>{lockContent({message})}</Dimmer>)}
    </div>
  );
};

export default LockedComponent;