import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import SocialPostTable from "./SocialPostTable";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";

const SocialPostList = (props) => {
  const {
    postedList,
    scheduledList,
    draftList,
    handlePostEdit,
    handlePostDelete,
    setOpenAlert,
    list_type,
    filter
  } = props;
  
  
  const [listTab, setlistTab] = useState("posted");

  const toggleListTabs = (value) => {
    setlistTab(value);
    list_type(value)
  };

  if(listTab === "draft"){
    setOpenAlert(false);
  }

  return (
    <CardWrapper
      showDivider={false}
      headerTitle={
        <div className="float-start mt-2">
          <Nav className="Setup_Sub_Nav" style={{ fontSize: "1rem" }}>
            <NavItem>
              <NavLink
                onClick={() => toggleListTabs("posted")}
                active={listTab === "posted"}
              >
                Posted
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => toggleListTabs("scheduled")}
                active={listTab === "scheduled"}
              >
                Scheduled
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                onClick={() => toggleListTabs("draft")}
                active={listTab === "draft"}
              >
                Draft
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      }
    >
      <TabContent activeTab={listTab}>
        <TabPane tabId="posted">
          <SocialPostTable
            list_type="posted"
            post_list={postedList}
            handlePostEdit={handlePostEdit}
            handlePostDelete={handlePostDelete}
            branchList={filter?.branch?.choices}
            location={props.location}
          />
        </TabPane>
        <TabPane tabId="scheduled">
          <SocialPostTable
            list_type="scheduled"
            post_list={scheduledList}
            handlePostEdit={handlePostEdit}
            handlePostDelete={handlePostDelete}
            location={props.location}

          />
        </TabPane>
        <TabPane tabId="draft">
          <SocialPostTable
            list_type="draft"
            post_list={draftList}
            handlePostEdit={handlePostEdit}
            handlePostDelete={handlePostDelete}
            location={props.location}

          />
        </TabPane>
      </TabContent>
    </CardWrapper>
  );
};

export default SocialPostList;
