import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import queryString from "query-string";
import cookie from "react-cookies";

import { connect } from "react-redux";
import { Row, Col } from "reactstrap";

import * as TemplateActions from "../../actions/template/templateActions";

import TemplateList from "../../components/campaign/templates/TemplateList";
import LoaderComponent from "../../components/common/loaderComponent";
import * as utils from "../../libs/utils";
import LockedComponent from "../../components/common/LockedComponent/LockedComponent";
import ConfirmationModal from "../../components/common/ConfirmationModal";

class TemplatesPage extends React.Component {
  constructor(props) {
    super(props);
    this.switchToAddTemplate = this.switchToAddTemplate.bind(this);
    this.deleteTemplate = this.deleteTemplate.bind(this);
    this.cloneTemplate = this.cloneTemplate.bind(this);
    this.getTemplateList = this.getTemplateList.bind(this);
    this.state = {
      lock: "",
    };
    //PageSize Handling
    const pageSize = cookie.load("pageSize") || {};
    const {
      history,
      location: { pathname, search },
    } = props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        page_size: pageSize.templates_review || 10,
      }),
    });
  }

  componentDidMount() {
    this.getTemplateList(
      this.props,
      queryString.parse(this.props.location.search)
    );
    //Set Title
    document.title = "Templates|Review Request";
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-line
    const {
      location: { search: prevSearch },
    } = this.props;
    const {
      location: { search: nextSearch },
    } = nextProps;
    if (prevSearch !== nextSearch) {
      this.getTemplateList(nextProps, queryString.parse(nextSearch));
    }
  }

  getTemplateList(props, data) {
    const {
      match: {
        params: { business_id },
      },
    } = props;
    this.props.actions
      .templateList({ ...data, branch_id: business_id })
      .catch(
        (err) =>
          err === "lock" &&
          this.setState({ lock: "Templates Review Request Module is locked." })
      );
  }

  switchToAddTemplate(val) {
    const {
      history,
      match: {
        params: { branch_id, business_id },
      },
    } = this.props;
    history.push(`/${business_id}/${branch_id}/templates/edit?${val}`);
  }
  toggleConfirm = (confirmationFunction) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmationFunction,
    });
  };
  deleteTemplate(data) {
    const {
      history,
      location: { pathname, search },
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions.templateDelete(data, business_id).then(() => {
      const query = queryString.parse(search);
      if (query.page) {
        history.push({ pathname, search: `?page_size=${query.page_size}` });
      } else {
        this.getTemplateList(this.props, { page_size: query.page_size });
      }
    });
  }

  cloneTemplate(data) {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    return this.props.actions
      .templateClone(data, business_id)
      .then(() => this.getTemplateList(this.props, queryString.parse(search)));
  }

  render() {
    let {
      templateList,
      location: { search },
    } = this.props;
    const querySearch = queryString.parse(search);
    return (
      <Row className="content-area">
        <LockedComponent message={this.state.lock} />
        <LoaderComponent loader={this.props.loader} />
        <Col xs={12} sm={12} className="business-setup p-3">
          <TemplateList
            switchToAddTemplate={this.switchToAddTemplate}
            list={templateList}
            deleteTemplate={(item) =>
              this.toggleConfirm(() => this.deleteTemplate(item))
            }
            cloneTemplate={this.cloneTemplate}
            location={this.props.location}
            sortFunction={(on) =>
              utils.toggleSort(this.props.history, this.props.location, on)
            }
            ordering={querySearch.ordering}
            pageSizeCookie={"templates_review"}
            history={this.props.history}
          />
        </Col>
        <ConfirmationModal
          header="Are you sure!"
          subHeader="Are you sure you want to delete the selected items? This action cannot be undone."
          isOpen={this.state.isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={this.state.confirmationFunction}
        />
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    templateList: state.template.list,
    errors: state.business.errors,
    teamDetail: state.team.detail,
    loader: state.common.loader,
  };
};

TemplatesPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  CommonActions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  business: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(TemplateActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(TemplatesPage);
