import React from "react";
import Wrapper from "./Wrapper";
import StarRatingTable from "../competitors/StarRatingTable";
import StarRatingGraph from "./StarRatingGraph";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";

const StarRating = ({
  location,
  history,
  match,
  ratingDetails,
  downloadRatingReport,
  loader,
  businessDetails
}) => {
  return (
    <Wrapper
      title={"Star Wise Rating"}
      subHeading={"* Above details is as on 2024-05-21"}
      location={location}
      history={history}
      match={match}
      onDownload={downloadRatingReport}
    >
      {(viewType) => (
        <>
          {viewType === "Graph" ? (
            <StarRatingGraph
              ratingDetails={
                ratingDetails &&
                ratingDetails.data &&
                ratingDetails.data.star_ratings
              }
              loader={loader}
              businessDetails={businessDetails}
            />
          ) : (
            <CardWrapper showDivider={false}>
              <StarRatingTable
                ratingDetails={
                  ratingDetails &&
                  ratingDetails.data &&
                  ratingDetails.data.star_ratings
                }
                loader={loader}
                businessDetails={businessDetails}
              />
            </CardWrapper>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default StarRating;
