import React, { useRef, useEffect, useState } from "react";
import Flatpickr from "react-flatpickr";
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";
import ErrorMessage from "./new/forms/ui/ErrorMessage";
import FPLabel from "./new/forms/ui/FPLabel";

const DateInput = (field) => {
  const {
    input,
    label,
    onChange,
    serverError,
    meta: { touched, error },
    className,
    openInContainer,
    options,
    required,
  } = field;

  const { onChange: inputOnChange, ...restInput } = input;

  const wrapperRef = useRef();
  const [container, setContainer] = useState(null);

  const handleChange = (val) => {
    const date = val === "" ? null : moment(val).format();
    inputOnChange(date);
    if (onChange) onChange(date);
  };

  useEffect(() => {
    setContainer(wrapperRef.current);
  }, [wrapperRef.current]);

  return (
    <div ref={wrapperRef} className={className}>
      {label && <FPLabel required={required}>{label}</FPLabel>}
      {container && (
        <div className="fp-date-picker-wrapper">
          <Flatpickr
            {...restInput}
            {...field}
            onChange={(date) => handleChange(date[0])}
            options={{ appendTo: openInContainer && container, ...options }}
            className="fp-date-picker"
          />
          <EventIcon className="date-icon" style={{ color: "#7A8193" }} />
        </div>
      )}
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
      {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
    </div>
  );
};

export default DateInput;
