import React, { useEffect } from "react";
import { FPFilter, FPToolTip } from "../../../../components/common/new";
import ListingFPFilter from "../../../../components/common/new/FPFilter/Listing/ListingFPFilter";
import * as URLS from "../../../../libs/apiUrls";
import { getAllParamsInArrayForm } from "../../../../libs/utils";
import * as filterListActions from "../../../../actions/common/filterListActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

const ConsoleFilter = (props) => {
  const { history, pageSizeCookie, filterAction, formattedAccountData } = props;
  const fetchFilterList = async () => {
    try {
      await Promise.all([
        filterAction.getDateTypeAccount(
          "",
          `${URLS.GMB_LISTING_LOCATION}/${props?.business_id}/accounts/`
        ),
      ]);
    } catch (error) {
      console.error("Error in fetching filter list:", error);
    }
  };

  useEffect(() => {
    fetchFilterList();
  }, []);

  const handleDefaultFilter = (list, key) => {
    const filter = window.location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable =
      defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list.map((item) => {
      item["isChecked"] =
        isKeyFilterAvailable &&
        defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };

  const filterList = [
    {
      id: 1,
      label: "GMB Location Group",
      key: "gmb_location_type",
      type: "radio",
      isSearchable: false,
      list: handleDefaultFilter(formattedAccountData, "gmb_location_type"),
    },
    // {
    //   id: 2,
    //   label: "GMB Status",
    //   key: "status_type",
    //   type: "radio",
    //   isSearchable: false,
    //   list: handleDefaultFilter(
    //     [
    //       { value: "verified", display_name: "Verified" },
    //       { value: "suspended", display_name: "Suspended" },
    //       { value: "duplicate", display_name: "Duplicate" },
    //     ],
    //     "status_type"
    //   ),
    // },
    {
      id: 2,
      label: "Completion Status",
      key: "completion_status",
      type: "radio",
      isSearchable: false,
      list: handleDefaultFilter(
        [
          { value: "very_low", display_name: "Very Low" },
          { value: "low", display_name: "Low" },
          { value: "moderate", display_name: "Moderate" },
          { value: "high", display_name: "High" },
          { value: "very_high", display_name: "Very High" },
        ],
        "completion_status"
      ),
    },
    {
      id: 3,
      label: "Open Status",
      key: "openstatus",
      type: "radio",
      isSearchable: false,
      list: handleDefaultFilter(
        [
          { value: "OPEN", display_name: "Open" },
          { value: "CLOSED_PERMANENTLY", display_name: "Closed Permanently" },
          { value: "CLOSED_TEMPORARILY", display_name: "Closed Temporarily" },
        ],
        "openstatus"
      ),
    },
  ];
  return (
    <>
      <FPFilter
        data={filterList}
        className="mt-1"
        history={history}
        // pageSizeCookie={pageSizeCookie}
        ticketFilter="BULK_FILTER"
        applyBtnText={"Apply"}
        hideResetBtn={false}
        // customOnApply={(value) => {
        //   handleSubmit(value);
        // }}
      />
    </>
  );
};

// export default ConsoleFilter;
const mapStateToProps = (state) => {};

const mapDispatchToProps = (dispatch) => {
  return {
    filterAction: bindActionCreators(filterListActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ConsoleFilter);
