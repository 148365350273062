/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Select from "react-select";
import queryString from "query-string";

import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import classNames from "classnames";
import AutoSelect from "../common/AutoSelect";

class BranchSelector extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.state = {
      branch: context.router.route.match.params.branch_id,
    };
  }

  handleBranchChange(val) {
    if (val) {
      const {
        history,
        route: {
          match: {
            path,
            params: { business_id, tabKey, mode, type },
          },
          location: { search },
        },
      } = this.context.router;
      const page_size = queryString.parse(search).page_size;
      let url = path
        .replace(":branch_id", val)
        .replace(":business_id", business_id)
        .replace(":tabKey", tabKey)
        .replace(":type", type)
        .replace(":mode", mode);

      history.push({
        pathname: url,
        search: page_size ? `page_size=${page_size}` : "",
      });
      this.props.rerenderParentCallback
        ? this.props.rerenderParentCallback()
        : null;
      this.setState({ branch: val });
    }
  }

  handleEnterpriseClick() {
    const {
      history,
      route: {
        match: {
          params: { business_id },
        },
      },
    } = this.context.router;
    history.push(`/${business_id}/enterprise`);
  }

  componentDidMount() {
    const {
      route: {
        match: {
          params: { branch_id, business_id },
        },
      },
    } = this.context.router;
    const { blankField } = this.props;
    const data = {
      business: business_id,
      corporate_branch: branch_id,
    };
    this.props.actions.businessBranchOptions(data, blankField);
  }

  render() {
    const {
      business: { branches },
      disabled,
      className,
    } = this.props;
    const filteredChoices =
      branches &&
      branches.choices
        .map((item) => ({
          display_name: item.display_name,
          value: item.value.toString(),
        }))
        .filter((obj) => obj.display_name !== "All Locations");
    const s =
      branches &&
      branches.choices.filter((item) => item.value == this.state.branch)[0];

    return (
      <div>
        {/* <Select
          name="branchId"
          value={this.state.branch}
          labelKey="display_name"
          placeholder='Select Location'
          valueKey="value"
          options={filteredChoices}
          menuContainerStyle={{ zIndex: 999 }}
          onChange={(val) => this.handleBranchChange(val)}
          onBlurResetsInput={false}
          simpleValue
          clearable={false}
          disabled={disabled}
          className={classNames(className)}
        /> */}
        <AutoSelect
          name="branchId"
          placeholder="Select Location"
          value={this.state.branch}
          onChange={(event, val) => this.handleBranchChange(val)}
          multiple={false}
          options={filteredChoices}
          className={classNames(className)}
          disabled={disabled}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
  };
};

BranchSelector.propTypes = {
  actions: PropTypes.instanceOf(Object),
  business: PropTypes.instanceOf(Object),
  handleEnterpriseClick: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
  };
}

BranchSelector.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(BranchSelector);
