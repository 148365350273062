import React, { useState } from "react";
import { FPAvatar, FPButton, FPToolTip, MessageBox, NoData } from "../common/new";
import { snakeToCapital, starRatingColor } from "../../libs/utils";
import CustomStarRatingComponent from "../common/StarComponent";
import moment from "moment";
import LoaderComponent from "../common/loaderComponent";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import MenuModalPagination from "./MenuModalPagination";
import { Box } from "@material-ui/core";

const MenuPerformanceModal = (props) => {
  const { record, location, modalLoading, handleDownload, handlePageClick } = props;

  return (
    <>
      {record && record.results && record.results.length > 0 ? (
        <>
          <div>
            {/* {modalLoading ? (
              <div
                style={{
                  minHeight: "calc(100vh - 125px)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <LoaderComponent loader={modalLoading} />
              </div>
            ) : (
              
            )} */}

            <>
              <LoaderComponent loader={modalLoading} inline />
              <div className="d-flex justify-content-end mb-3">
                <FPButton
                  size="small"
                  variant="outline"
                  onClick={() => handleDownload()}
                  label="Download Report"
                  startIcon={<CloudDownloadOutlinedIcon />}
                />
              </div>
              <Box sx={{
                overflow: 'auto',
                height: '50vh',
                display: 'flex',
                flexDirection: 'column',
                paddingRight: '10px',
                gap: '15px'
              }}>
                {record?.results?.map((item, index) => {
                  const { star_border_color, star_bg_color } = starRatingColor(+item.rating || 1);
                  const { description } = item;
                  return (
                    <div className="border rounded" key={index}>
                      <header className="review-card-header">
                        <div className="d-flex justify-content-between">
                          <section className="d-flex align-item-center gap-10">
                            <FPToolTip title={snakeToCapital(item.branch)}>
                              <div className="provider-logo-wrapper" role="button">
                                <img src={item.provider} alt="" height="24" width="24" />
                              </div>
                            </FPToolTip>
                            <div className="d-flex align-items-start gap-10">
                              <div>
                                <span className="review-title">{snakeToCapital(item.branch)}</span>

                                <span className="d-block font-blue-gray">{moment(item.created_at).format("llll")}</span>
                              </div>

                              <div className="review-time">
                                <div
                                  className="rating"
                                  style={{
                                    borderColor: star_border_color,
                                    backgroundColor: star_bg_color,
                                  }}
                                >
                                  <CustomStarRatingComponent
                                    name="rate1"
                                    starCount={5}
                                    className="align-bottom"
                                    starColor="#FBBD08"
                                    emptyStarColor="#FFFFFF"
                                    value={+item.rating || 1}
                                    editing={false}
                                  />
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </header>
                      <div className="review-card-body">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                          <FPAvatar
                            src={item?.reviewer?.image}
                            alt={item?.reviewer?.name}
                            labelClass={"user-name"}
                            userName={item?.reviewer ? item?.reviewer?.name : "Reviewer"}
                          />
                        </div>
                        <MessageBox isSelfBox={false} className={"w-100 mw-100"}>
                          <span className="fp-size-14">
                            {description}
                          </span>
                        </MessageBox>
                      </div>
                    </div>
                  );
                })}
              </Box>
              <MenuModalPagination totalItems={record && record.count} onChange={handlePageClick} />
            </>
          </div>
        </>
      ) : (
        <div>
          {modalLoading ? (
            <div
              style={{
                minHeight: "calc(100vh - 125px)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoaderComponent loader={modalLoading} />{" "}
            </div>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </>
  );
};

export default MenuPerformanceModal;
