import React from 'react';
import { Row, Col } from 'reactstrap';

import RadioComponent from '../common/RadioComponent';
import DateInput from '../common/DateInput';

const SendImmediately = () => {
  return (
    <label>
      <div style={{fontWeight: '500', fontSize: '15px'}}>Send Immediately</div>
      <div className='font-blue-gray fp-size-12'>
        Your email will be sent immediately.
      </div>
    </label>
  );
};

const ScheduleForLater = ({ ...props }) => {
  return (
    <label className="mt-3">
      <div style={{fontWeight: '500', fontSize: '15px'}}>Schedule for later</div>
      <div className='fp-size-12 font-blue-gray mb-2'>Specify a date and time for your campaign.</div>
      <DateInput className="schedule-later-input date-input" {...props} meta={{}} options={{enableTime: true}} openInContainer/>
    </label>
  );
};

const ScheduleCampaign = ({ schedule, scheduleTime, onScheduleChange, onTimeChange }) => {
  return (
    <Row>
      <Col>
        <div>
          <RadioComponent
            options={[
              {display_name: <SendImmediately selected={schedule==='send_immediately'}/>, value: 'send_immediately'},
              {display_name: <ScheduleForLater input={{onChange: onTimeChange, value: scheduleTime}} selected={schedule==='schedule_campaign'}/>, value: 'schedule_campaign'}
            ]}
            input={{ onChange: onScheduleChange, value: schedule }}
            checked={schedule}
            meta={{}}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ScheduleCampaign;