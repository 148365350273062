/* eslint-disable */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Card } from 'reactstrap';
import { kFormatter } from '../../libs/utils';
import { Legend } from './Legend';

class MentionsChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      legend: []
    };
  }

  componentDidMount() {
    const legend = this.chart && this.chart.chartInstance && this.chart.chartInstance.generateLegend();
    this.setState({ legend });
  }

  static renderLegend(chart) {
    const legendSet = chart.data.datasets;
    const legendItems = legendSet.map((item, index) => {
      var ci = chart;
      var meta = ci.getDatasetMeta(index);
      return (
        <Legend
          key={item.label} 
          item={item} 
          ci={ci} 
          meta={meta} 
          index={index} 
        />
      );
    });
    return legendItems;
  }


  static getDerivedStateFromProps(props) {
    const { dataKeys, primaryYLabel, secondaryYLabel, location_addresses, data, minYPrimary, maxYPrimary, minYSecondary, scaleLabelCustom } = props;
    return {
      chartData: {
        datasets: data,
        labels: dataKeys,
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 2000,
        },
        title:
        {
          display: false
        },
        tooltips: {
          callbacks: {
            title: (value) => {
              location_addresses ? location_addresses[value[0].index] : dataKeys[value[0].index];
            }
          }
        },
        tooltip: {
          shared: true,
          valueDecimals: 2,
        },
        legend: {
          display: false
        },
        legendCallback: MentionsChart.renderLegend,
        scales: {
          xAxes: [{
            gridLines: {
              color: '#fff'
            },
            barPercentage: 0.5,
            ticks: {
              callback: (value) => { return value.substr(0, 20); }
            }
          }],
          yAxes: [{
            id: 'secondary-y-axis',
            position: 'right',
            scaleLabel: {
              display: true,
              labelString: secondaryYLabel,
              ...scaleLabelCustom.secondaryYAxis
            },
            gridLines: {
              display: false
            },
            ticks: {
              precision: 0,
              min: minYSecondary,
              callback: kFormatter
            },
            display: secondaryYLabel ? true : false,
          }, {
            id: 'primary-y-axis',
            position: 'left',
            scaleLabel: {
              display: true,
              labelString: primaryYLabel,
              ...scaleLabelCustom.primaryYAxis
            },
            fontStyle: 'italic',
            ticks: {
              min: minYPrimary,
              max: maxYPrimary,
              callback: kFormatter
            }
          }]
        },
      },
    };
  }
  render() {
    const { chartData, chartOptions, legend } = this.state;
    return (
      <Card body className="py-3 mb-3" >
        {legend && legend.length &&
          <div className="text-center">
            <ul className="chart-list-legend">
              {legend}
            </ul>
          </div>}
        <div className="mentions-chart-container">
          <Bar
            ref={el => { this.chart = el; }}
            data={chartData}
            options={chartOptions} />
        </div>
      </Card>
    );
  }
}

export default MentionsChart;