import * as types from "../../actions/actionTypes";

export default function overviewDetails(state = [], action) {
  switch (action.type) {
    case types.GET_LOCATIONS_SUCCESS:
      const mapData = action.data.map((item) => {
        // eslint-disable-line
        var obj = {
          id: item.id,
          branch_name: item.location_address,
          no_of_review: item.rating_data.total,
          avg_rating: item.rating_data.avg,
          shape_type: "Point",
          lat_long: { coordinates: [] },
        };
        const cords = item.location_coordinates.split(",").map((cord) => +cord);
        obj.lat_long.coordinates = [cords[1], cords[0]];
        return obj;
      });
      return Object.assign({}, state, { list: mapData });
    case types.GET_LOCATIONS_FAILURE:
      return Object.assign({}, state, { list: [] });
    case types.GET_STATS_SUCCESS:
      return Object.assign({}, state, { stats: { ...action.data } });
    case types.GET_STATS_FAILURE:
      return Object.assign({}, state, { stats: {} });
    case types.GET_TRENDS_SUCCESS: {
      return Object.assign({}, state, { trends: { ...action.data } });
    }
    case types.GET_TRENDS_FAILURE: {
      return Object.assign({}, state, { trends: {} });
    }
    case types.GET_DASHBOARD_FILTERS_SUCCESS:
      return Object.assign({}, state, { filters: { ...action.data } });
    case types.GET_DASHBOARD_FILTERS_FAILURE:
      return Object.assign({}, state, { filters: {} });
    //* Review summary when date own change
    case types.GET_REVIEW_SUMMARY_SUCCESS:
      return Object.assign({}, state, { stats: { ...state.stats, ["ratings"]: { ...action.data.ratings } } });
    case types.GET_REVIEW_SUMMARY_FAILURE:
      return Object.assign({}, state, { stats: { ["ratings"]: {} } });

    //* Review summary when date own change
    case types.GET_NPS_SUMMARY_SUCCESS:
      return Object.assign({}, state, { stats: { ...state.stats, ["promoter"]: { ...action.data.promoter } } });
    case types.GET_NPS_SUMMARY_FAILURE:
      return Object.assign({}, state, { stats: { ["promoter"]: {} } });

    default:
      return state;
  }
}
