import React, { Fragment } from "react";
import { Nav, NavItem, NavLink, Row, Col } from "reactstrap";
import classnames from "classnames";
import queryString from "query-string";
import moment from "moment/moment";
import "./reports.css";
import { FPButton, FPToolTip } from "../common/new";
import { Popover } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import emailIcon from "../../images/EmailReport.png";
import downloadIcon from "../../images/downloadReport.png";
import printIcon from "../../images/Printer.png";
import ReportsFilter from "../../components/reports/Filter";
import PropTypes from "prop-types";
import { Filter, NewFilter } from "../../images/svg";
import { connect } from "react-redux";

class ReportsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = { reviewReports: false, tabKey: "campaigns" };
    this.toggleReport = this.toggleReport.bind(this);
    this.toggleReportType = this.toggleReportType.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.state = {
      view: "graph",
      report: "reviews",
      activeSection: props.match.params.reportType,
      anchorEl: null,
    };
    this.handleBranchValue = this.handleBranchValue.bind(this);
    this.handleListValue = this.handleListValue.bind(this);
    this.handleProviderValue = this.handleProviderValue.bind(this);
    this.handleTagValue = this.handleTagValue.bind(this);
    this.handleLocationListBy = this.handleLocationListBy.bind(this);
    this.handleKeyword = this.handleKeyword.bind(this);
    this.handleTicketSourceValue = this.handleTicketSourceValue.bind(this);
  }

  toggleReportType(report, reportType) {
    const {
      history,
      match: {
        path,
        params: { business_id, view, sectionType },
      },
      location: { search },
      provider,
      list,
      list_by,
      branch,
      onTabChange,
    } = this.props;
    onTabChange();
    let querySearch, overtimeSearch;
    if (!(reportType === "overtime")) {
      querySearch = queryString.parse(search);
      if (!querySearch.branch && branch) querySearch.branch = branch;
      if (!querySearch.provider && provider) querySearch.provider = provider;
      if (!querySearch.list_by && list_by) querySearch.list_by = list_by;
      if (reportType === "source") {
        delete querySearch.provider;
        delete querySearch.list_by;
      }
      if (reportType === "location") delete querySearch.branch;
      delete querySearch.list;
    } else {
      const query = queryString.parse(search);
      query.list_by && delete query.list_by;
      overtimeSearch =
        "list" in query
          ? "?" + queryString.stringify({ ...query })
          : "?" + queryString.stringify({ ...query }) + (list ? `&list=${list}` : "&list=date");
      overtimeSearch = branch && !("branch" in query) ? overtimeSearch + `&branch=${branch}` : overtimeSearch;
      overtimeSearch = provider && !("provider" in query) ? overtimeSearch + `&provider=${provider}` : overtimeSearch;
    }

    let newView = view;

    if (report === "ticket-survey" && reportType === "textual") {
      newView = "table";
      //  this.setState({view : 'table'});
    }

    const url = path
      .replace(":business_id", business_id)
      .replace(":sectionType", sectionType)
      .replace(":reportType", reportType)
      .replace(":report", report)
      .replace(":view", newView);
    history.push(url + (querySearch ? "?" + queryString.stringify({ ...querySearch }) : overtimeSearch));
    this.setState({ report: report, activeSection: reportType, activeCol: "" });
  }

  toggleReport(report, activeSection) {
    let {
      history,
      match: {
        path,
        params: { business_id, view, sectionType },
      },
      location: { search },
      list_by,
      list,
      branch,
      provider,
      onTabChange,
    } = this.props;
    onTabChange();
    let reportType;
    if (report === "reviews" || report === "nps" || report === "sentiment" || report === "star" || report === "mention")
      reportType = "overtime";
    else if (report === "tags") reportType = "count";
    else if (report === "ticket-survey") reportType = "standard";
    else reportType = "report";
    if (report === "display-report" || report === "textual") {
      view = "table";
    }
    const url = path
      .replace(":business_id", business_id)
      .replace(":sectionType", sectionType)
      .replace(":reportType", reportType)
      .replace(":report", report)
      .replace(":view", view);
    let query = queryString.parse(search);
    if (!query.branch && branch) query.branch = branch;
    if (!query.provider && provider) query.provider = provider;
    if (!query.list && list) query.list = list;
    if (report === "visitor") {
      if (list_by) query.list_by = list_by;
      delete query.list;
      delete query.provider;
      delete query.branch;
    } else if (report !== "visitor") {
      query.list_by && delete query.list_by;
    }
    if (report === "conversion") {
      delete query.list;
      delete query.provider;
    } else if (!query.list && !(report === "visitor" || report === "conversion")) query.list = "date";
    if (report === "display-report") {
      query.date_type = moment(new Date()).format("YYYY-MM-DD");
      query.branch = "";
      delete query.list;
      delete query.report_type;
      delete query.start_date;
      delete query.end_date;
    } else if (report === "standard" || report === "textual") {
      query.branch && delete query.branch;
      query.list && delete query.list;
      query.report_type && delete query.report_type;
      // query.start_date && delete query.start_date;
      // query.end_date && delete query.end_date;
      query.list_by && delete query.list_by;
      query.provider && delete query.provider;
    } else if (report === "ticket-survey") {
      query.branch && delete query.branch;
      query.list && delete query.list;
      query.list_by && delete query.list_by;
      query.provider && delete query.provider;
      query.source && delete query.source;
    } else {
      query.date_type = "prior_30_day";
    }
    history.push(url + ("?" + queryString.stringify(query)));
    this.setState({ report, activeSection });
  }

  toggleView(view, newView) {
    const {
      history,
      match: {
        path,
        params: { business_id, reportType, report, sectionType },
      },
      location: { search },
    } = this.props;
    const url = path
      .replace(":business_id", business_id)
      .replace(":sectionType", sectionType)
      .replace(":reportType", reportType)
      .replace(":report", report)
      .replace(":view", view);
    history.push(url + search);
    this.setState({ view: newView });
  }

  toggleSection(section) {
    const {
      history,
      match: {
        path,
        params: { business_id, view },
      },
      location: { search },
    } = this.props;
    let report =
      section === "review-section"
        ? "reviews"
        : section === "survey-section"
        ? "standard"
        : section === "mention-section"
        ? "mention"
        : section === "tickets-section"
        ? "tickets"
        : "visitor";

    let reportType = report === "reviews" || report === "mention" || report === "tickets" ? "overtime" : "report";
    const viewType = section ? "graph" : view;
    const url = path
      .replace(":business_id", business_id)
      .replace(":sectionType", section)
      .replace(":reportType", reportType)
      .replace(":report", report)
      .replace(":view", viewType);
    const query = queryString.parse(search);
    let newSearch;

    ["list", "branch", "list_by", "provider", "keyword"].forEach((element) => {
      if (!query[element] && this.props[element] && section !== "survey-section") query[element] = this.props[element];
    });
    if (section === "review-section" || section === "tickets-section") {
      delete query.list_by;
      delete query.displayLevel;
    }
    if (section !== "mention-section") {
      delete query.keyword;
    } else if (section === "mention-section") {
      ["list_by", "provider", "branch", "displayLevel"].forEach((element) => delete query[element]);
    } else if (section === "campaign-section") {
      ["list", "branch", "provider", "displayLevel"].forEach((element) => delete query[element]);
    }
    if (section === "survey-section") {
      ["provider", "list_by"].forEach((element) => delete query[element]);
      query["date_type"] = "prior_7_day";
      query["displayLevel"] = "question";
    } else {
      query["date_type"] = "prior_30_day";
    }
    newSearch = queryString.stringify(query);
    history.push(url + ("?" + newSearch));
  }

  // Filter-PopUp
  onFilterBtnClick = (e) => {
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  // Report Filter - PopUp Data
  handleBranchValue = (branch) => {
    this.setState({ branchSelected: branch });
  };

  handleListValue = (list) => {
    this.setState({ listSelected: list });
  };

  handleProviderValue = (provider) => {
    this.setState({ providerSelected: provider });
  };

  handleTicketSourceValue = (source) => {
    this.setState({ source: source });
  };

  handleTagValue = (tag) => {
    this.setState({ tagSelected: tag });
  };

  handleLocationListBy = (listby) => {
    this.setState({ locationlistby: listby });
  };

  handleKeyword = (keyword) => {
    this.setState({ keywordSelected: keyword });
  };

  render() {
    const {
      match: {
        params: { report, reportType, view, sectionType, business_id },
      },
      printReport,
      downloadReport,
      emailReport,
      keywordOptions,
      reviewSourceOptions,
      branchTagOptions,
      ticketSourceOptions,
      branchList,
      listByData,
      common,
    } = this.props;
    const { activeSection, anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div className="pb-2">
        <div className="report-FirstRow">
          <Nav tabs className="tab-dropdown pos-relative mb-2 py-1 pb-2 box-shadow-none">
            <NavItem>
              <NavLink
                className={classnames({
                  active: sectionType === "review-section",
                })}
                onClick={() => this.toggleSection("review-section")}
              >
                Reviews
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: sectionType === "campaign-section",
                })}
                onClick={() => this.toggleSection("campaign-section")}
              >
                Campaign
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: sectionType === "tickets-section",
                })}
                onClick={() => this.toggleSection("tickets-section")}
              >
                Tickets
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: sectionType === "survey-section",
                })}
                onClick={() => this.toggleSection("survey-section")}
              >
                Survey
              </NavLink>
            </NavItem>
            <div className="filter-button">
              <FPToolTip title="Filter">
                <div className="contact-filter-view" onClick={(e) => this.onFilterBtnClick(e)}>
                  <NewFilter
                    color="white"
                    style={{
                      height: "15px",
                      width: "15px",
                    }}
                  />
                </div>
              </FPToolTip>
              {/* <FPButton
                label="Filter"
                startIcon={<Filter />}
                className="report-Filter"
                variant="outline"
                onClick={(e) => this.onFilterBtnClick(e)}
                backgroundColor="#28314F"
                textColor="#ffffff"
              /> */}
              {/* {showFilters && ()} */}
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                PaperProps={{
                  style: {
                    borderRadius: 10, // Adjust the value to set the desired border radius
                  },
                }}
                className="Report-Filter-Popup"
              >
                <ReportsFilter
                  ticket={report === "tickets"}
                  history={this.props.history}
                  location={this.props.location}
                  onApply={this.handleClose}
                  branchList={branchList}
                  listByData={listByData}
                  // handleBranchValue={this.handleBranchValue}
                  // handleListValue={this.handleListValue}
                  // handleProviderValue={this.handleProviderValue}
                  // handleTagValue={this.handleTagValue}
                  // handleLocationListBy={this.handleLocationListBy}
                  // handleKeyword={this.handleKeyword}
                  // handleTicketSourceValue={this.handleTicketSourceValue}

                  businessId={business_id}
                  match={this.props.match}
                  showTagFilter={reportType === "overtime" || reportType === "count"}
                  tagOptions={this.props.tagOptions}
                  showLocationFilter={report !== "mention" && report !== "visitor" && reportType !== "location"}
                  hideDateShowCalander={report === "display-report" ? true : false}
                  showTicketSourceFilter={report === "tickets"}
                  showListByLoctionFilter={report !== "mention" && (reportType === "location" || report === "visitor")}
                  keywordOptions={report === "mention" && keywordOptions && keywordOptions.results}
                  showSourceFilter={
                    reportType === "location" ||
                    (reportType === "overtime" && report !== "mention") ||
                    (reportType === "count" && report !== "mention") ||
                    reportType === "keyword" ||
                    reportType === "assignees" ||
                    reportType === "status"
                  }
                  common={common}
                  reviewSourceOptions={reviewSourceOptions && reviewSourceOptions.choices}
                  branchTagOptions={branchTagOptions && branchTagOptions.results}
                  showListByFilter={reportType === "overtime"}
                  ticketSourceOptions={ticketSourceOptions && ticketSourceOptions.choices}
                />
              </Popover>
            </div>
          </Nav>
        </div>

        <div className="report-SecondRow">
          <Nav tabs className="tab-dropdown pos-relative py-1 box-shadow-none">
            {/* Review-Section */}
            {sectionType === "review-section" && (
              <Fragment>
                <NavItem>
                  <NavLink
                    className={classnames({ active: report === "reviews" })}
                    onClick={() => {
                      this.toggleReport("reviews", "overtime");
                    }}
                  >
                    Avg Rating Review
                    {/* <Dropdown icon='dropdown icon' floating className='icon'> */}
                    {/* <Dropdown icon={null} floating trigger = {
                      <Icon
                        link
                        name='caret down'
                        size = 'large'
                        style={{
                          paddingLeft : '5px',
                          position: 'relative',
                          top: '-2px'
                        }}
                      />
                    }>
                      <Dropdown.Menu>
                        <Dropdown.Item text='Over time' onClick={() => this.toggleReportType('reviews', 'overtime')} />
                        <Dropdown.Item text='By Source' onClick={() => this.toggleReportType('reviews', 'source')} />
                        <Dropdown.Item text='By Location' onClick={() => this.toggleReportType('reviews', 'location')} />
                      </Dropdown.Menu>
                    </Dropdown> */}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: report === "nps" })}
                    onClick={() => {
                      this.toggleReport("nps", "overtime");
                    }}
                  >
                    NPS
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: report === "sentiment" })}
                    onClick={() => {
                      this.toggleReport("sentiment", "overtime");
                    }}
                  >
                    Sentiment
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: report === "star" })}
                    onClick={() => {
                      this.toggleReport("star", "overtime");
                    }}
                  >
                    Star Rating
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({ active: report === "tags" })}
                    onClick={() => {
                      this.toggleReport("tags", "count");
                    }}
                  >
                    Tags
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === "display-report",
                    })}
                    onClick={() => {
                      this.toggleReport("display-report");
                    }}
                  >
                    Review Channels Display Standing
                  </NavLink>
                </NavItem>
              </Fragment>
            )}

            {/* Ticket-Section */}
            {sectionType === "campaign-section" && (
              <Fragment>
                <NavItem>
                  <NavLink
                    className={classnames({ active: report === "visitor" })}
                    onClick={() => {
                      this.toggleReport("visitor");
                    }}
                  >
                    Visitor
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: report === "conversion" })}
                    onClick={() => {
                      this.toggleReport("conversion");
                    }}
                  >
                    Usage
                  </NavLink>
                </NavItem>
              </Fragment>
            )}

            {sectionType === "mention-section" && (
              <Fragment>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: reportType === "overtime",
                    })}
                    onClick={() => {
                      this.toggleReportType("mention", "overtime");
                    }}
                  >
                    Mention By Date
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: reportType === "source" })}
                    onClick={() => {
                      this.toggleReportType("mention", "source");
                    }}
                  >
                    Mention By Provider
                  </NavLink>
                </NavItem>
              </Fragment>
            )}

            {/* Ticket-Section */}
            {sectionType === "tickets-section" && (
              <Fragment>
                <NavItem>
                  <NavLink
                    className={classnames({ active: report === "tickets" })}
                    onClick={() => {
                      this.toggleReport("tickets", "overtime");
                    }}
                  >
                    Ticketing
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === "ticket-survey",
                    })}
                    onClick={() => {
                      this.toggleReport("ticket-survey", "standard");
                    }}
                  >
                    Ticket Survey
                  </NavLink>
                </NavItem>
              </Fragment>
            )}

            {/* Survey-Section */}
            {sectionType === "survey-section" && (
              <Fragment>
                <NavItem>
                  <NavLink
                    className={classnames({ active: report === "standard" })}
                    onClick={() => {
                      this.toggleReport("standard");
                    }}
                  >
                    Standard
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: report === "textual" })}
                    onClick={() => {
                      this.toggleReport("textual");
                    }}
                  >
                    Textual
                  </NavLink>
                </NavItem>
              </Fragment>
            )}
          </Nav>
        </div>

        <div className="report-ThirdRow">
          <Nav tabs className="py-1 box-shadow-none" style={{ borderBottom: "none" }}>
            {(report === "reviews" || report === "nps" || report === "sentiment" || report === "star") && (
              <Fragment>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === report && activeSection === "overtime",
                    })}
                    onClick={() => {
                      this.toggleReportType(report, "overtime");
                    }}
                  >
                    Overtime
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === report && activeSection === "source",
                    })}
                    onClick={() => {
                      this.toggleReportType(report, "source");
                    }}
                  >
                    Source
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === report && activeSection === "location",
                    })}
                    onClick={() => {
                      this.toggleReportType(report, "location");
                    }}
                  >
                    Location
                  </NavLink>
                </NavItem>
              </Fragment>
            )}

            {report === "tags" && (
              <Fragment>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === report && activeSection === "count",
                    })}
                    onClick={() => this.toggleReportType(report, "count")}
                  >
                    Count
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === report && activeSection === "overtime",
                    })}
                    onClick={() => this.toggleReportType(report, "overtime")}
                  >
                    Overtime
                  </NavLink>
                </NavItem>
              </Fragment>
            )}

            {/* Ticket Ticketing */}
            {report === "tickets" && (
              <Fragment>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === report && activeSection === "overtime",
                    })}
                    onClick={() => this.toggleReportType(report, "overtime")}
                  >
                    Overtime
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === report && activeSection === "assignees",
                    })}
                    onClick={() => this.toggleReportType(report, "assignees")}
                  >
                    Owner
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === report && activeSection === "location",
                    })}
                    onClick={() => this.toggleReportType(report, "location")}
                  >
                    Location
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === report && activeSection === "status",
                    })}
                    onClick={() => this.toggleReportType(report, "status")}
                  >
                    Status
                  </NavLink>
                </NavItem>
              </Fragment>
            )}

            {/* ticket survey */}
            {report === "ticket-survey" && (
              <Fragment>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === report && activeSection === "standard",
                    })}
                    onClick={() => {
                      this.toggleReportType(report, "standard");
                    }}
                  >
                    Standard
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: report === report && activeSection === "textual",
                    })}
                    onClick={() => {
                      this.toggleReportType(report, "textual");
                    }}
                  >
                    Textual
                  </NavLink>
                </NavItem>
              </Fragment>
            )}
          </Nav>
        </div>

        <div className="report-FourthRow">
          <Row className="m-0">
            <Col xs={8} sm={8} className="p-0">
              <Nav tabs className="box-shadow-none">
                {report !== "display-report" && report !== "textual" && reportType !== "textual" && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: view === "graph" })}
                      onClick={() => {
                        this.toggleView("graph");
                      }}
                    >
                      <FormControlLabel value="Graph" label="Graph" control={<Radio />} checked={view === "graph"} />
                    </NavLink>
                  </NavItem>
                )}
                {sectionType !== "mention-section" && report !== "textual" && reportType !== "textual" && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: view === "table" })}
                      onClick={() => {
                        this.toggleView("table");
                      }}
                    >
                      <FormControlLabel value="table" control={<Radio />} label="Table" checked={view === "table"} />
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
            </Col>
            <Col xs={4} sm={4} className="p-0 text-right">
              <div className="d-flex justify-content-end" style={{ height: "100%", borderRadius: "2px" }}>
                <FPToolTip title={"Email Report"}>
                  <div role="button" tabIndex="0" onClick={emailReport} className="graph-Table-RightButton">
                    <img src={emailIcon} />
                  </div>
                </FPToolTip>
                <FPToolTip title={"Download Report"}>
                  <div role="button" tabIndex="0" onClick={downloadReport} className="graph-Table-RightButton">
                    <img src={downloadIcon} />
                  </div>
                </FPToolTip>
                <FPToolTip title={"Print Report"}>
                  <div role="button" tabIndex="0" onClick={printReport} className="graph-Table-RightButton">
                    <img src={printIcon} />
                  </div>
                </FPToolTip>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reviewSourceOptions: state.review.review_source_options,
    keywordOptions: state.mentions.keywordsList,
    tagOptions: state.common.tag,
    branchTagOptions: state.common.branch_tag_options,
    ticketSourceOptions: state.tickets.source_options,
    common: state.common,
  };
};

ReportsTab.propTypes = {
  reviewSourceOptions: PropTypes.instanceOf(Object),
  branchTagOptions: PropTypes.instanceOf(Object),
};

export default connect(mapStateToProps)(ReportsTab);
