import React from 'react'

const UploadPhoto = ({ tabKey }) => {

  return (

    <div style={{ display: "flex", gap: "5px" }}>
      {tabKey === "cover" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Cover Photo
        </span>
      }
      {tabKey === "profile" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Profile Photo
        </span>
      }
      {tabKey === "logo" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Logo 
        </span>
      }
      {tabKey === "exterior" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Exterior Photo
        </span>
      }
      {tabKey === "interior" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Interior Photo
        </span>
      }
      {tabKey === "product" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Product Photo
        </span>
      }
      {tabKey === "at_work" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload At Work Photo
        </span>
      }
      {tabKey === "food_and_drink" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Food And Drink Photo
        </span>
      }
      {tabKey === "menu" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Menu Photo
        </span>
      }
      {tabKey === "common_area" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Common Area Photo
        </span>
      }
      {tabKey === "rooms" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Rooms Photo
        </span>
      }
      {tabKey === "team" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Team Photo
        </span>
      }
      {tabKey === "additional" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Additional Photo
        </span>
      }
      {tabKey === "video" &&
        <span
          style={{
            fontSize: "14px",
            fontWeight: "500",
            color: "#00B5AD",
          }}
        >
          Click to upload Video
        </span>
      }
      <span
        style={{
          fontSize: "14px",
          fontWeight: "500",
          color: "#7A8193",
        }}
      >
        or drag and drop
      </span>
    </div>

  )
}

export default UploadPhoto