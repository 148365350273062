import React from 'react';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { Button, Form, Grid,Segment} from 'semantic-ui-react';
import MultiSelect from '../common/SelectComponent';
import { InputField } from 'react-semantic-redux-form';
import  ImageUploadComponent  from '../common/ImageUpload';


const validate = (values) => {
  console.log("values :", values);
  
  const errors = {};
  if(!values.jsonFile) {
    errors.jsonFile = 'Please Select .p8 Service Key File';
  }
  if(!values.branch) {
    errors.branch = 'Please Select Branch';
  }
  if(!values.issuerID) {
    errors.issuerID = 'Please Enter issuer ID';
  }
  if(!values.keyID) {
    errors.keyID = 'Please Enter key ID';
  }
  return errors;
};

const SelectBranchForIOSAppStore = (props) => {
  const { handleSubmit, SelectBranchSubmit, BranchList } = props;
  const submit = (data) => {
    return SelectBranchSubmit(data)
      .catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  };
  

  return (
    <Grid textAlign='center' verticalAlign='middle'>
      <Grid.Column>
        <Form onSubmit={handleSubmit(submit)}>          
          <Segment stacked  textAlign='left'>
            <Field
              label="Upload .p8 Service Key file"
              name="jsonFile"
              component={ImageUploadComponent}
              placeholder="Select Json file"
              // labelKey='display_name'
              // valueKey='value'
              // simpleValue
              style={{marginBottom:'20px'}}
            />
            <div className='mb-10'>
            <Field
              label="Select Location"
              name="branch"
              component={MultiSelect}
              placeholder="Select Location"
              options={BranchList}
              labelKey='display_name'
              valueKey='value'
              simpleValue
              style={{marginBottom:'20px'}}
            />
            </div>
            <Field label="Issuer ID" name='issuerID' component={InputField} placeholder='Enter Issuer ID...' type='text' />
            <Field label="Key ID" name='keyID' component={InputField} placeholder='Enter Key ID...' type='text' />
          </Segment>
          <Form.Field control={Button} color='teal' fluid type='submit' style={{margin:'10px 0'}}>
            Submit
          </Form.Field>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default reduxForm({
  form: 'SelectBranchForIOSAppStore',
  enableReinitialize: true,
  validate
})(SelectBranchForIOSAppStore);
