import React from "react";
import { Progress, Button } from "semantic-ui-react";
import "./styles.css";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
import { Box } from "@material-ui/core";

class DownloadProgressComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  
  render() {
    const { updatedCount, refresh, keyword } = this.props;
    const keywords = keyword.map(item => item.keyword).join(', ');
    
    return (
      <div className="mx-3" style={{  borderRadius: "12px !important", overflow: "hidden"}}>
        <Progress percent={100} active style={{ marginBottom: "0px" }}  />
        <CardWrapper showDivider={false}>
          <div className="data-download text-center">
            <div className="content">
           
              <Box sx={{fontSize: '30px', color: '#1A2544', fontWeight: 600}}>Data Download in Progress for {keywords}</Box>
              <div className="subText">
                <span>
                  Famepilot has collected {updatedCount} new mentions.
                </span>
                <span>
                  We specialize in collecting public data from the moment you
                  setup a keyword.
                </span>
              </div>
              {updatedCount > 0 && (
                <Button className="refresh-button" onClick={refresh}>
                  Refresh
                </Button>
              )}
            </div>
          </div>
        </CardWrapper>
      </div>
    );
  }
}

export default DownloadProgressComponent;