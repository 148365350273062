import { SET_SELECTED_TAB, SET_SELECTED_TAB_UPDATE_PHOTO } from '../../actions/actionTypes';

const initialState = {
  selectedTab: 0,
};

const selectedTabReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };

    case SET_SELECTED_TAB_UPDATE_PHOTO:
      return {
        ...state,
        selectedTabupdatephoto: action.payload,
      };
    default:
      return state;
  }
};

export default selectedTabReducer;