import React from "react";
import { FPBadge } from "../../../components/common/new";
import classNames from "classnames";

const TendingKeyWordBadge = (props) => {
  const { label, type, value, className, onLabelClick , withBorder, ...rest } = props;

  const colorUI = () => {
    switch (type) {
      case "positive":
        return {
          textColor: "#81E050",
          backgroundColor: "rgba(129, 224, 80, 0.06)",
        };
      case "neutral":
        return {
          textColor: "#FFD800",
          backgroundColor: "rgba(255, 216, 0, 0.04)",
        };
      case "negative":
        return {
          textColor: "#FF0000",
          backgroundColor: "rgba(255, 0, 0, 0.04)",
        };
    }
  };

  return (
    <div
      style={{
        backgroundColor: colorUI().backgroundColor,
        border: withBorder ? `1px solid ${colorUI().textColor}` : 'unset'
      }}
      className={classNames("tending-keyword-badge", className)}
      {...rest}
    >
      <FPBadge color={colorUI().textColor} />
      <span style={{ color: colorUI().textColor }} onClick={onLabelClick}>{label}</span>
      <span style={{ color: "var(--secondary-font-blue, #1a2544)" }}>
        {" "}
        | {value}
      </span>
    </div>
  );
};

export default TendingKeyWordBadge;
