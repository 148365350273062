import React from "react";
import PropTypes from "prop-types";
import { setDecimalIndex, starRatingColor } from "../../libs/utils";
import CustomStarRatingComponent from "../common/StarComponent";
import ErrorMessage from "../common/new/forms/ui/ErrorMessage";
import FPLabel from "../common/new/forms/ui/FPLabel";

const ratingOption = [
  "one_rating",
  "two_rating",
  "three_rating",
  "four_rating",
  "five_rating",
];

class PrintStarRating extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStar: props.input.value || [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      this.setState({ selectedStar: this.props.input.value || [] });
    }
  }

  handleStarClick = (star) => {
    const { input } = this.props;
    const onChange = input?.onChange || (() => {});

    const selectedStar = this.state.selectedStar.includes(star)
      ? this.state.selectedStar.filter((s) => s !== star)
      : [...this.state.selectedStar, star];
    this.setState({ selectedStar });
    onChange(selectedStar);
  };

  render() {
    const { selectedStar } = this.state;
    const { meta: {touched, error}, serverError,label } = this.props;

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
           <FPLabel className="mb-0">{label}</FPLabel>
        <div className="d-flex">
          {ratingOption &&
            ratingOption.map((item, i) => {
              const index = i + 1;
              const { star_border_color } = starRatingColor(index);

              return (
                <span
                  key={index}
                  className="location-tag"
                  style={{
                    borderColor: selectedStar.includes(item)
                      ? star_border_color
                      : "transparent",
                    cursor: "pointer",
                    marginLeft: "20px",
                  }}
                  onClick={() => this.handleStarClick(item)}
                >
                  <CustomStarRatingComponent
                    name={`locationRating-${i}`}
                    starCount={1}
                    value={index}
                    editing={false}
                    className="AssignmentRule-Rating"
                  />
                  {setDecimalIndex(index) || 0}
                </span>
              );
            })}
        </div>
        </div>
        {touched && error && <ErrorMessage>{error}</ErrorMessage>}
        {serverError && <ErrorMessage>{serverError}</ErrorMessage>}
      </div>
    );
  }
}

PrintStarRating.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.array,
    onChange: PropTypes.func,
  }),
  touched: PropTypes.bool,
  error: PropTypes.string,
  serverError: PropTypes.string,
};

const RenderPrintStarRating = (props) => {
  return <PrintStarRating {...props} />;
};

export default RenderPrintStarRating;
