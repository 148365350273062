import React, { useState, useEffect } from "react";
import Chartwrapper from "../../modules/dashboard/component/Chartwrapper";
import { Box } from "@material-ui/core";
import { snakeToCapital } from "../../libs/utils";
// import { format } from "../../libs/utils";
// import apiUrls from "../../libs/apiUrls";
import LoaderComponent from "../common/loaderComponent";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { NoData } from "../common/new";
import queryString from "query-string";
import { Skeleton } from "@mui/material";

const ComplaintsCard = (props) => {
  const {
    business_id,
    params,
    categoryPerformanceData,
    fetchComplainData,
    loader,
  } = props;
  // const [categoryPerformanceData, setCategoryPerformance] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [typeData, setTypeData] = useState({});

  // const url = format(`${apiUrls.REVIEW_COMPLAINTS}`, [business_id]);

  // const fetchComplainData = async (params) => {
  //   console.log("params", params);
    
  //   setIsLoading(true);
  //   await axios_instance
  //     .get(url, { params: { ...params } })
  //     .then((res) => {
  //       setCategoryPerformance(res.data);
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      await fetchComplainData(queryString.parse(params));
      setIsLoading(false);
    };
    fetch();
  }, []);

  useEffect(() => {
    const type_data = categoryPerformanceData?.type_data;

    if (type_data) {
      let newData = { typeSet: [] };
      type_data &&
        Object.keys(type_data).map((item, index) => {
          const newItem = {
            name: item,
            count: type_data[item].count,
            percent: type_data[item].percent,
          };
          newData.typeSet.push(newItem);
        });
      setTypeData(newData);
    }
  }, [categoryPerformanceData]);

  const updateData = (datePayload) => {
    fetchComplainData(datePayload);
  };
  return (
    <>
      <Chartwrapper
        onDateChange={updateData}
        redirectionLink={`/${business_id}/complaints?date_type=prior_30_day&page_size=10`}
        descriptionStyle={{
          height: "calc(100% - 48px)",
        }}
        className="fp-dashboard-chart-card h-100"
        widthElevation={true}
        headerTitle={
          <div className="d-flex align-items-center gap-6">
            <span>
              Top 5 Complaints Breakdown{" "}
              <Box
                component="span"
                className="dash-text"
                sx={{ fontSize: "15px" }}
              >
                ({categoryPerformanceData?.provider_data?.total_count})
              </Box>
            </span>
          </div>
        }
        skeletonLoader={loader}
      >
        {loader ? (
          <>
          <div className="d-flex align-items-center gap-20 px-2 ">
          <Skeleton width="50%" height={40} />
          <Skeleton width="50%" height={40} />
          </div>
            <div className="ui-table-responsive">
              <Table className="fp-table fp-table-center">
                <TableHead>
                  <TableRow>
                    <TableCell className="text-nowrap p-2">
                      {loader ? (
                        <Skeleton width="100%" height={20} />
                      ) : (
                        "Complaint Type"
                      )}
                    </TableCell>
                    <TableCell className="text-nowrap p-2">
                      {loader ? (
                        <Skeleton width="100%" height={20} />
                      ) : (
                        "Complaint Count"
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loader
                    ? // Render 5 skeleton rows when loading
                      Array.from({ length: 5 }).map((_, index) => (
                        <TableRow key={index}>
                          <TableCell className="p-2">
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={20}
                            />
                          </TableCell>
                          <TableCell className="p-2">
                            <Skeleton
                              variant="rectangular"
                              width="100%"
                              height={20}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    : typeData.typeSet?.slice(0, 5).map((item, index) => (
                        <TableRow key={index}>
                          <TableCell className="p-2">
                            {snakeToCapital(item.name)}
                          </TableCell>
                          <TableCell className="p-2">{item.count}</TableCell>
                        </TableRow>
                      ))}
                </TableBody>
              </Table>
            </div>
          </>
        ) : typeData && typeData.typeSet && typeData.typeSet.length > 0 ? (
          <>
            <div className="d-flex align-items-center gap-20 px-2 mb-3">
              {categoryPerformanceData?.provider_data &&
                Object.keys(categoryPerformanceData?.provider_data).map(
                  (value, index) => {
                    return (
                      value !== "total_count" && (
                        <div
                          key={index}
                          className="d-flex align-items-center gap-10"
                          style={{ width: "260px" }}
                        >
                          <Box>
                            <img
                              src={
                                categoryPerformanceData?.provider_data[value][
                                  "icon"
                                ]
                              }
                              alt="Icon"
                              style={{ height: "35px", width: "35px" }}
                            />
                          </Box>
                          <div className="d-flex gap-6 align-items-center">
                            <p
                              className="font-blue-gray m-0"
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                textTransform: "capitalize",
                                color: "#646565",
                              }}
                            >
                              {value.replace(/_/g, " ")}
                            </p>
                            <p
                              className="font-dark m-0"
                              style={{ fontSize: "14px", fontWeight: "500" }}
                            >
                              {categoryPerformanceData?.provider_data[value][
                                "count"
                              ] || 0}{" "}
                              complaints
                            </p>
                          </div>
                        </div>
                      )
                    );
                  }
                )}
            </div>
            {/* <Divider className="my-3" /> */}
            <div className="ui-table-responsive">
              <Table className="fp-table fp-table-center">
                <TableHead>
                  <TableRow>
                    <TableCell className="text-nowrap p-2">
                      Complaint Type
                    </TableCell>
                    <TableCell className="text-nowrap p-2">
                      Complaint Count
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {typeData.typeSet?.slice(0, 5).map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className="p-2">
                          {snakeToCapital(item.name)}
                        </TableCell>
                        <TableCell className="p-2">{item.count}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </>
        ) : (
          <>
            <NoData />
          </>
        )}
      </Chartwrapper>
    </>
  );
};

export default ComplaintsCard;
