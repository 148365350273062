/* eslint-disable quotes */
import React from "react";
import "../review/userDetails.css";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import { Icon } from "semantic-ui-react";

const TicketUserDetails = ({
  item,
  showFetchOrderDetailsBtn,
  service_category,
  onAddOrderId,
  fetchOrderDetails,
}) => {
  const iconStyle = {
    width: "14px",
    height: "14px",
    color: "#F13A88",
    marginRight: '3px'
  };
  return (
    <div>
      <div className="d-flex mt-1 fp-size-12" style={{ gap: "4px" }}>
        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          <AssignmentOutlinedIcon style={iconStyle} />
          {item.source_data?.order_id ? (
            <React.Fragment>
              <span className="line-height-1" style={{color: "#2375ef"}}>{item.source_data?.order_id}</span>
              {showFetchOrderDetailsBtn &&
                showFetchOrderDetailsBtn[item.source_data?.order_id] !== "fetched" && (
                <Icon
                  className="ml-2 cursor-pointer"
                  name="sync"
                  title="Fetch order details"
                  onClick={() => {
                    fetchOrderDetails(item.source_data?.order_id, item.source_data.id);
                  }}
                />
              )}
            </React.Fragment>
          ) : (
            <span
              className="font-blue-gray line-height-1"
              onClick={() => onAddOrderId(item)}
              role="button"
            >
              {service_category === "Restaurant"
                ? "Add Order Id"
                : service_category === "hotel"
                  ? "Add Reservation Id"
                  : service_category === "hospital"
                    ? "Add Appointment Id"
                    : ""}
            </span>
          )}
        </div>
        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          <span className="fp-vertical-small-divider"></span>
          <EmailOutlinedIcon style={iconStyle} />
          {item.customer && item.customer.email ? (
            <span className="line-height-1" style={{color: "#2375ef"}}>{item.customer.email}</span>
          ) : (
            <span
              className="font-blue-gray line-height-1"
              onClick={() => onAddOrderId(item)}
              role="button"
            >
              Add Email
            </span>
          )}
        </div>

        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          <span className="fp-vertical-small-divider"></span>
          <PhoneOutlinedIcon style={iconStyle} />
          {item.customer && (item.customer.phone || item.customer.contact_number) ? (
            <span className="line-height-1" style={{color: "#2375ef"}}>
              {item.customer.phone || item.customer.contact_number}
            </span>
          ) : (
            <span
              className="font-blue-gray line-height-1"
              role="button"
              onClick={() => onAddOrderId(item)}
            >
              Add Phone
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default TicketUserDetails;
