import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as commonAction from "../common/commonAction";
import * as utils from "../../libs/utils";
import cookie from "react-cookies";

export const getInvoiceListSuccess = (data) => {
  return { type: types.GET_INVOICE_LIST_SUCCESS, data };
};
export const getInvoiceListFailure = (data) => {
  return { type: types.GET_INVOICE_LIST_FAILURE, data };
};
export const getInvoiceList = (data) => {
  const { businessId } = data;
  const params = { ...data };
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.PAYMENTS_INVOICE, [businessId]), { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getInvoiceListSuccess(res.data));
        return res.data;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getInvoiceListFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export const getInvoiceOptionsSuccess = (data) => {
  return { type: types.GET_INVOICE_OPTIONS_SUCCESS, data };
};
export const getInvoiceOptionsFailure = (data) => {
  return { type: types.GET_INVOICE_OPTIONS_FAILURE, data };
};
export const getInvoiceOptions = (businessId, invoiceId) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.INVOICE_OPTIONS, [businessId, invoiceId]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getInvoiceOptionsSuccess(res.data));
        return res.data;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getInvoiceOptionsFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export const postPaymentDetailsSuccess = (data) => {
  return { type: types.POST_PAYMENT_DETAILS_SUCCESS, data };
};
export const postPaymentDetailsFailure = (data) => {
  return { type: types.POST_PAYMENT_DETAILS_FAILURE, data };
};
export const postPaymentDetails = (businessId, data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(utils.format(URLS.PAYMENTS, [businessId]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(postPaymentDetailsSuccess(res.data));
        return res.data;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(postPaymentDetailsFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export const downloadPdf = (url, filename) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const token = cookie.load("authToken");
    return fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/pdf",
      },
    })
      .then((res) => res.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .finally(() => {
        dispatch(commonAction.hideLoader());
      });
  };
};

export const downloadInvoice = (businessId, invoiceId) => {
  return downloadPdf(utils.format(URLS.INVOICE_DOWNLOAD, [businessId, invoiceId]), `Invoice_${invoiceId}.pdf`);
};
