import React from "react";
import PageCard from "./pageCard";
import { FirstFeedbackPage } from "../../../images/svg";

const WelcomePage = () => {
  return (
    <>
    
      <div className="welcome-image-container">
        <FirstFeedbackPage style={{ width: "85%", height: "85%" }} />
      </div>
      <p style={{fontWeight:"450", fontSize:"18px", textAlign:"center", color:"#1B1C1D"}}>Your feedback matters a lot to us.</p>

    </>
  );
};

export default WelcomePage;
