import React from "react";
import { Progress } from "reactstrap";
import { Segment } from "semantic-ui-react";
import { truncatedNumber } from "../../../libs/helper";

const CompletionStatus = (props) => {
  return (
    <>
      <Segment
        className="nounContainercompletionstatus"
        style={{
          border: "none",
          boxShadow: "none",
          padding: "5px",
          display: "grid",
          gap: "40px",
          width: "100%",
          height: "200px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(290px, 1fr))",
            gap: "0px 25px",
          }}
        >
          <div
            className="d-flex align-center"
            style={{ justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "#1A2544",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Phone No.
            </div>
            <div className="d-flex align-center">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Progress
                  style={{
                    height: "8px",
                    width: "100px",
                  }}
                  barClassName={
                    truncatedNumber(props?.phone_number) <= 20
                      ? "very-low-color"
                      : truncatedNumber(props?.phone_number) <= 40
                      ? "low-color"
                      : truncatedNumber(props?.phone_number) <= 60
                      ? "moderate-color"
                      : truncatedNumber(props?.phone_number) <= 80
                      ? "high-color"
                      : "very-high-color"
                  }
                  value={truncatedNumber(props?.phone_number)}
                />
              </div>
              <div className="px-0 ml-0" style={{ width: "30px" }}>
                <div
                  style={{
                    paddingLeft: "8px",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#7A8193",
                  }}
                >
                  {truncatedNumber(props?.phone_number)
                    ? truncatedNumber(props?.phone_number)
                    : 0}
                  %
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex align-center"
            style={{ justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "#1A2544",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Description
            </div>
            <div className="d-flex align-center">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Progress
                  style={{
                    height: "8px",
                    width: "100px",
                  }}
                  barClassName={
                    truncatedNumber(props?.description) <= 20
                      ? "very-low-color"
                      : truncatedNumber(props?.description) <= 40
                      ? "low-color"
                      : truncatedNumber(props?.description) <= 60
                      ? "moderate-color"
                      : truncatedNumber(props?.description) <= 80
                      ? "high-color"
                      : "very-high-color"
                  }
                  value={truncatedNumber(props?.description)}
                />
              </div>
              <div className="px-0 ml-0" style={{ width: "30px" }}>
                <div
                  style={{
                    paddingLeft: "8px",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#7A8193",
                  }}
                >
                  {truncatedNumber(props?.description)
                    ? truncatedNumber(props?.description)
                    : 0}
                  %
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex align-center"
            style={{ justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "#1A2544",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              Website Url’s
            </div>
            <div className="d-flex align-center">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Progress
                  style={{
                    height: "8px",
                    width: "100px",
                  }}
                  barClassName={
                    truncatedNumber(props?.websiteUri) <= 20
                      ? "very-low-color"
                      : truncatedNumber(props?.websiteUri) <= 40
                      ? "low-color"
                      : truncatedNumber(props?.websiteUri) <= 60
                      ? "moderate-color"
                      : truncatedNumber(props?.websiteUri) <= 80
                      ? "high-color"
                      : "very-high-color"
                  }
                  value={truncatedNumber(props?.websiteUri)}
                />
              </div>
              <div className="px-0 ml-0" style={{ width: "30px" }}>
                <div
                  style={{
                    paddingLeft: "8px",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#7A8193",
                  }}
                >
                  {truncatedNumber(props?.websiteUri)
                    ? truncatedNumber(props?.websiteUri)
                    : 0}
                  %
                </div>
              </div>
            </div>
          </div>
          {props?.services ?  (
            <div
              className="d-flex align-center"
              style={{ justifyContent: "space-between" }}
            >
              <div
                style={{
                  color: "#1A2544",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                services
              </div>
              <div className="d-flex align-center">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Progress
                    style={{
                      height: "8px",
                      width: "100px",
                    }}
                    barClassName={
                      truncatedNumber(props?.services) <= 20
                        ? "very-low-color"
                        : truncatedNumber(props?.services) <= 40
                        ? "low-color"
                        : truncatedNumber(props?.services) <= 60
                        ? "moderate-color"
                        : truncatedNumber(props?.services) <= 80
                        ? "high-color"
                        : "very-high-color"
                    }
                    value={truncatedNumber(props?.services)}
                  />
                </div>
                <div className="px-0 ml-0" style={{ width: "30px" }}>
                  <div
                    style={{
                      paddingLeft: "8px",
                      fontWeight: "400",
                      fontSize: "16px",
                      color: "#7A8193",
                    }}
                  >
                    {truncatedNumber(props?.services)
                      ? truncatedNumber(props?.services)
                      : 0}
                    %
                  </div>
                </div>
              </div>
            </div>
          ):""}
          <div
            className="d-flex align-center"
            style={{ justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "#1A2544",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              opening hours
            </div>
            <div className="d-flex align-center">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Progress
                  style={{
                    height: "8px",
                    width: "100px",
                  }}
                  barClassName={
                    truncatedNumber(props?.opening_hours) <= 20
                      ? "very-low-color"
                      : truncatedNumber(props?.opening_hours) <= 40
                      ? "low-color"
                      : truncatedNumber(props?.opening_hours) <= 60
                      ? "moderate-color"
                      : truncatedNumber(props?.opening_hours) <= 80
                      ? "high-color"
                      : "very-high-color"
                  }
                  value={truncatedNumber(props?.opening_hours)}
                />
              </div>
              <div className="px-0 ml-0" style={{ width: "30px" }}>
                <div
                  style={{
                    paddingLeft: "8px",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#7A8193",
                  }}
                >
                  {truncatedNumber(props?.opening_hours)
                    ? truncatedNumber(props?.opening_hours)
                    : 0}
                  %
                </div>
              </div>
            </div>
          </div>
          {/* <div
            className="d-flex align-center"
            style={{ justifyContent: "space-between", marginRight: "8px" }}
          >
            <div
              style={{
                color: "#1A2544",
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              products
            </div>
            <div className="d-flex align-center">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Progress
                  style={{
                    height: "8px",
                    width: "100px",
                  }}
                barClassName={
                  truncatedNumber(props?.products) <= 20 ? "very-low-color" :
                    (truncatedNumber(props?.products) <= 40 ? "low-color" :
                      (truncatedNumber(props?.products) <= 60 ? "moderate-color" :
                        (truncatedNumber(props?.products) <= 80 ? "high-color" : "very-high-color")))
                }
                value={truncatedNumber(props?.products)}
              />
            </div>
            <div className="px-0 ml-0" style={{ width: "30px" }}>
                <div
                style={{
                  paddingLeft: "8px",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#7A8193",
                }}
              >
                {truncatedNumber(props?.products) ? truncatedNumber(props?.products) : 0}%
              </div>
            </div>
          </div> */}
          <div
            className="d-flex align-center"
            style={{ justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "#1A2544",
                fontSize: "16px",
                fontWeight: "400",
              }}
            >
              logo & photos
            </div>
            <div className="d-flex align-center">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Progress
                  style={{
                    height: "8px",
                    width: "100px",
                  }}
                  barClassName={
                    truncatedNumber(props?.logo_and_photos) <= 20
                      ? "very-low-color"
                      : truncatedNumber(props?.logo_and_photos) <= 40
                      ? "low-color"
                      : truncatedNumber(props?.logo_and_photos) <= 60
                      ? "moderate-color"
                      : truncatedNumber(props?.logo_and_photos) <= 80
                      ? "high-color"
                      : "very-high-color"
                  }
                  value={truncatedNumber(props?.logo_and_photos)}
                />
              </div>
              <div className="px-0 ml-0" style={{ width: "30px" }}>
                <div
                  style={{
                    paddingLeft: "8px",
                    fontWeight: "400",
                    fontSize: "14px",
                    color: "#7A8193",
                  }}
                >
                  {truncatedNumber(props?.logo_and_photos)
                    ? truncatedNumber(props?.logo_and_photos)
                    : 0}
                  %
                </div>
              </div>
            </div>
          </div>
          {props?.q_and_a ? (
            <div
              className="d-flex align-center"
              style={{ justifyContent: "space-between" }}
            >
              <div
                style={{
                  color: "#1A2544",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                Q & A’s
              </div>
              <div className="d-flex align-center">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Progress
                    style={{
                      height: "8px",
                      width: "100px",
                    }}
                    barClassName={
                      truncatedNumber(props?.q_and_a) <= 20
                        ? "very-low-color"
                        : truncatedNumber(props?.q_and_a) <= 40
                        ? "low-color"
                        : truncatedNumber(props?.q_and_a) <= 60
                        ? "moderate-color"
                        : truncatedNumber(props?.q_and_a) <= 80
                        ? "high-color"
                        : "very-high-color"
                    }
                    value={truncatedNumber(props?.q_and_a)}
                  />
                </div>
                <div className="px-0 ml-0" style={{ width: "30px" }}>
                  <div
                    style={{
                      paddingLeft: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#7A8193",
                    }}
                  >
                    {truncatedNumber(props?.q_and_a)
                      ? truncatedNumber(props?.q_and_a)
                      : 0}
                    %
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {/* <div
            className="d-flex align-center"
            style={{ justifyContent: "space-between", marginRight: "8px" }}
          >
            <div
              style={{
                color: "#1A2544",
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              posts & offers
            </div>
            <div className="col-3">
              <Progress
                style={{ height: "8px" }}
                // color={data.value <= 20 ? "danger" : (data.value <= 60 ? "warning" : "success")}
                value={34}
              />
            </div>
            <div className="px-0 ml-0" style={{ width: "30px" }}>
                <div
                style={{
                  paddingLeft: "8px",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#7A8193",
                }}
              >
                45%
              </div>
            </div>
          </div> */}
          {props?.opening_date ? (
            <div
              className="d-flex align-center"
              style={{ justifyContent: "space-between" }}
            >
              <div
                style={{
                  color: "#1A2544",
                  fontSize: "16px",
                  fontWeight: "400",
                }}
              >
                opening date
              </div>
              <div className="d-flex align-center">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Progress
                    style={{
                      height: "8px",
                      width: "100px",
                    }}
                    barClassName={
                      truncatedNumber(props?.opening_date) <= 20
                        ? "very-low-color"
                        : truncatedNumber(props?.opening_date) <= 40
                        ? "low-color"
                        : truncatedNumber(props?.opening_date) <= 60
                        ? "moderate-color"
                        : truncatedNumber(props?.opening_date) <= 80
                        ? "high-color"
                        : "very-high-color"
                    }
                    value={truncatedNumber(props?.opening_date)}
                  />
                </div>
                <div className="px-0 ml-0" style={{ width: "30px" }}>
                  <div
                    style={{
                      paddingLeft: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#7A8193",
                    }}
                  >
                    {truncatedNumber(props?.opening_date)
                      ? truncatedNumber(props?.opening_date)
                      : 0}
                    %
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </Segment>
    </>
  );
};

export default CompletionStatus;
