import React, { useState, useRef } from "react";
import ListingFPInput from "../../../../../components/common/new/Listing/ListingFPInput";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";
import { Chip, Stack } from "@mui/material";
import "./Addmenu.css"; // Create a CSS file for your styles
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";

const AddMenuSection = (props) => {
    const { location_food_menu_data, actions, selectReso, business_id, sectionIndexValue } = props;
    console.log("indexValueindexValueindexValueindexValueindexValue", sectionIndexValue)
    const fileInputRef = useRef(null);

    // State to store input values
    const [itemName, setItemName] = useState("");
    const [itemPrice, setItemPrice] = useState("");
    const [itemDescription, setItemDescription] = useState("");
    const [itemSection, setItemSection] = useState("");

    const [selectedDietary, setSelectedDietary] = useState("");

    const handleChipClick = (dietary) => {
        // Toggle selection: if already selected, unselect it; otherwise, select it
        setSelectedDietary((prevDietary) => (prevDietary === dietary ? "" : dietary));
    };



    const handleDivClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const files = event.target.files;
        console.log("Selected files:", files);
    };

    const updateMenuItem = async () => {
        const restodetail = { business_id, selectReso };

        // New section to be added
        const newSection = {
            labels: [
                {
                    displayName: itemSection,
                    languageCode: "en",
                },
            ],
            items: [
                {
                    labels: [
                        {
                            displayName: itemName,
                            description: itemDescription,
                            languageCode: "en"
                        },
                    ],
                    attributes: {
                        price: {
                            currencyCode: "INR",
                            units: itemPrice,
                        },
                        dietaryRestriction: selectedDietary,
                    },

                },
            ],
        };


        const updatedMenuData = { ...location_food_menu_data };
        updatedMenuData?.menus[sectionIndexValue]?.sections.push(newSection);

        const updateCode = {
            type: "updateMenu",
            updateMenuData: updatedMenuData,
        };

        // console.log("Payload being sent >>>>>>>>>", updateCode);

        // // Dispatch the action or make the API call
        await actions?.getGmbListingUpdateListing(updateCode, restodetail);
        await actions.getGmbLocationFoodmenu(business_id, selectReso);
    };

    const [selectedBusinessList, setSelectedBusinessList] = useState([]);
    const [showBusiness, setShowBusiness] = useState(true);
    const handleHideBusinessScreen = () => {
        setShowBusiness(false);
    }

    const handleShowBusinessScreen = () => {
        setShowBusiness(true);
    }

    const [selectedBusinessCount, setSelectedBusinessCount] = useState([]); // State to hold the selected businesses count
    console.log("==================================", selectedBusinessCount)

    const handleSelectedBusinessCount = (selectBusiness) => {
        setSelectedBusinessCount(selectBusiness);
    };

    return (
        <>
            {/* {showBusiness ?
                <div>
                    <BusinessList  />
                    {selectedBusinessCount?.length > 0 ?
                        // <div style={{ display: "flex", justifyContent: "end", marginTop: "20px" }}>
                        <div
                            className="d-flex mt-10 mb-15"
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                fontWeight: "600",
                                fontSize: "24px",
                                color: "#1A2544",
                                position: "sticky",
                                bottom: 0,
                                backgroundColor: "#fff",
                                zIndex: 1000,
                                // paddingBottom: "10px",
                                // paddingTop: "10px",
                                paddingBlock: "20px"
                            }}    >
                            < FPButton
                                label="Next"
                                onClick={handleHideBusinessScreen}
                            />
                        </div>
                        : ""
                    }
                </div >
                :
                <> */}
                    <div className="add-menu-container">
                        <div className="menu-header">
                            <h3>Create a new menu</h3>
                            <p className="menu-subtext">This menu will be publicly visible on your profile</p>
                        </div>
                        <div className="menu-section">
                            <label className="section-label">Add menu section</label>
                            <div className="section-input">
                                <ListingFPInput
                                    placeholder={"Section Name"}
                                    value={itemSection}
                                    onChange={(e) => setItemSection(e.target.value)}
                                />
                                <p>{itemSection?.length} / 40</p>
                            </div>
                        </div>
                        <div className="menu-items">
                            <div className="menu-item-header">
                                <label className="item-label">Add menu item</label>
                                <p className="item-subtext">Enter at least one item per section. You can add more later</p>
                            </div>
                            <div className="menu-item-grid">
                                <div className="menu-item-inputs">
                                    <div className="input-group">
                                        <ListingFPInput
                                            placeholder={"Item name*"}
                                            value={itemName}
                                            onChange={(e) => setItemName(e.target.value)}
                                        />
                                        <p>{itemName?.length} / 40</p>
                                    </div>
                                    <div className="input-group">
                                        <ListingFPInput
                                            placeholder={"Item price (INR)"}
                                            value={itemPrice}
                                            onChange={(e) => setItemPrice(e.target.value)}
                                        />
                                        <p>{itemPrice?.length} / 40</p>
                                    </div>
                                    <div className="input-group">
                                        <textarea
                                            className="textAreaUI"
                                            placeholder={"Item description"}
                                            value={itemDescription}
                                            onChange={(e) => setItemDescription(e.target.value)}
                                        />
                                        <p>{itemDescription?.length} / 40</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dietary-restrictions">
                            <label className="dietary-label">Dietary restrictions</label>
                            <p className="dietary-subtext">Select all that apply</p>
                        </div>
                        <Stack direction="row" spacing={1}>
                            <Chip
                                label="Vegetarian"
                                variant={selectedDietary === "Vegetarian" ? "filled" : "outlined"}
                                onClick={() => handleChipClick("Vegetarian")}
                                sx={{
                                    backgroundColor: selectedDietary === "Vegetarian" ? "#bfe0e2" : "#F0F1F3",
                                    color: selectedDietary === "Vegetarian" ? "#00b5ad" : "default",
                                    fontSize: "18px",
                                    fontWeight: "400",
                                    padding: "12px",
                                    border: "0",
                                }}
                            />
                            <Chip
                                label="Vegan"
                                variant={selectedDietary === "Vegan" ? "filled" : "outlined"}
                                onClick={() => handleChipClick("Vegan")}
                                sx={{
                                    backgroundColor: selectedDietary === "Vegan" ? "#bfe0e2" : "#F0F1F3",
                                    color: selectedDietary === "Vegan" ? "#00b5ad" : "default",
                                    fontSize: "18px",
                                    fontWeight: "400",
                                    padding: "12px",
                                    border: "0",
                                }}
                            />
                        </Stack>
                    </div>
                    <div className="menu-footer">
                        <FPButton label="Save" size="Small" onClick={updateMenuItem} />
                    </div>
                {/* </>
            } */}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        location_food_menu_data: state.gmbListingLocation.location_food_menu_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(locationActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMenuSection);
