import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as commonAction from "../common/commonAction";
import * as URLS from "../../libs/apiUrls";
import { hasTruthyValue } from "../../libs/utils";

export function getGmbListingLoactionSuccess(data, filterType) {
  return { type: types.GET_GMB_LISTING_LOCATION_SUCCESS, data, filterType };
}

export function getGmbListingLoactionFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LISTING_LOCATION_FAILURE, data };
}

export const getGmbListingLoaction = (
  business_id,
  account_id,
  status_type,
  completion_status,
  openstatus,
  search,
  update_listing_type,
  is_food_menu,
  page_size,
  page,
  page_type,
  branch,
  download,
) => {

  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/locations/?account=${
    account_id ? account_id : ""
  }&gmb_status=${status_type ? status_type : ""}&completion_status=${
    completion_status ? completion_status : ""
  }&open_status=${openstatus ? openstatus : ""}&q=${
    search ? search : ""
  }&update_listing_type=${
    update_listing_type ? update_listing_type : ""
  }&is_food_menu=${is_food_menu ? is_food_menu : ""}&page_size=${
    page_size || 10
  }&page=${page || 1}&branch=${branch ? branch : ""}&download=${download ? true : false}`;

  let business_url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/locations/?account=${
    account_id ? account_id : ""
  }&gmb_status=${status_type ? status_type : ""}&completion_status=${
    completion_status ? completion_status : ""
  }&open_status=${openstatus ? openstatus : ""}&q=${
    search ? search : ""
  }&update_listing_type=${
    update_listing_type ? update_listing_type : ""
  }&is_food_menu=${is_food_menu ? is_food_menu : ""}&branch=${branch ? branch : ""}&download=${download ? true : false}`;
  // if (account_id) {
  //   url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/locations/?account=${account_id}`;
  // } else {
  //   url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/locations/`;
  // }
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(page_type === "business_list" ? business_url: url,{responseType: download ? "blob" : "json"} )
      .then((res) => {
        dispatch(commonAction.hideLoader());
        if (download) {
          const blob = res.data;
          const downloadUrl = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = "listing-details.xlsx" || "download.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
        } else {
          // dispatch(successAction(response.data));
          dispatch(getGmbListingLoactionSuccess(res.data, update_listing_type));
        }
    
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingLoactionFailure(err));
      });
  };
};

export function getGmbListingMenuDetailSuccess(data) {
  return { type: types.GET_GMB_LISTING_MENU_DETAIL_SUCCESS, data };
}

export function getGmbListingMenuDetailFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LISTING_MENU_DETAIL_FAILURE, data };
}

export const getGmbListingMenuDetail = (business_id, selectedRestaurantId) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectedRestaurantId}/menu-detail`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingMenuDetailSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingMenuDetailFailure(err));
      });
  };
};

export function getGmbListingPrimaryDetailSuccess(data) {
  return { type: types.GET_GMB_LISTING_PRIMARY_DETAIL_SUCCESS, data };
}

export function getGmbListingPrimaryDetailFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LISTING_PRIMARY_DETAIL_FAILURE, data };
}

export const getGmbListingPrimaryDetail = (
  business_id,
  selectedRestaurantId
) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/locations/?id=${selectedRestaurantId}&full_detail=true`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingPrimaryDetailSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingPrimaryDetailFailure(err));
      });
  };
};

export function getGmbListingRefreshListingSuccess(data) {
  return { type: types.GET_GMB_LISTING_REFRESH_SUCCESS, data };
}

export function getGmbListingRefreshListingFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LISTING_REFRESH_FAILURE, data };
}

export const getGmbListingRefreshListing = (
  business_id,
  selectedRestaurantId
) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectedRestaurantId}/refresh-listing/`;
  return (dispatch) => {
    dispatch(commonAction.hideLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingRefreshListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingRefreshListingFailure(err));
      });
  };
};

export function getGmbListingBasicDetailSuccess(data) {
  return { type: types.GET_GMB_BASIC_DETAIL_SUCCESS, data };
}

export function getGmbListingBasicDetailFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_BASIC_DETAIL_FAILURE, data };
}

export const getGmbListingBasicDetail = (business_id, selectedRestaurantId) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/locations/basic-details/?location_id=${selectedRestaurantId}`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingBasicDetailSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingBasicDetailFailure(err));
      });
  };
};

export function getGmbListingGmbCategoriesSuccess(data) {
  return { type: types.GET_GMB_CATEGORIES_SUCCESS, data };
}

export function getGmbListingGmbCategoriesFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_CATEGORIES_FAILURE, data };
}

export const getGmbListingGmbCategories = (
  business_id,
  selectedRestaurantId
) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectedRestaurantId}/get-gmb-categories`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingGmbCategoriesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingGmbCategoriesFailure(err));
      });
  };
};

export function getGmbListingGmbBulkCategoriesSuccess(data) {
  return { type: types.GET_GMB_BULK_CATEGORIES_SUCCESS, data };
}

export function getGmbListingGmbBulkCategoriesFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_BULK_CATEGORIES_FAILURE, data };
}

export const getGmbListingGmbBulkCategories = (business_id) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/gmb-categories/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingGmbBulkCategoriesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingGmbBulkCategoriesFailure(err));
      });
  };
};

export function getGmbListingUpdateListingSuccess(data) {
  return { type: types.GET_GMB_UPDATE_LISTING_SUCCESS, data };
}

export function getGmbListingUpdateListingFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_UPDATE_LISTING_FAILURE, data };
}
export function getGmbListingPlaceActionMetaSuccess(data) {
  return { type: types.GET_GMB_PALCE_ACTION_METADATA_SUCCESS, data };
}

export function getGmbListingPlaceActionMetaFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_PALCE_ACTION_METADATA_FAILURE, data };
}

export const getGmbListingUpdateListing = (
  additionalCategories,
  businessData,
  formDATA = false
) => {
  console.log("additionalCategoriesadditionalCategories", additionalCategories);
  console.log(
    "businessData?.business_idbusinessData?.business_id",
    businessData?.business_id,
    "businessData?.selectResobusinessData?.selectReso",
    businessData?.selectReso
  );
  let config = {};
  if (hasTruthyValue(formDATA)) {
    config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  } else {
    config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }
  let url = `${URLS.GMB_LISTING_LOCATION}/${businessData?.business_id}/${businessData?.selectReso}/update-listing/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(url, additionalCategories, config)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingUpdateListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingUpdateListingFailure(err));
      });
  };
};
export const getGmbListingPlaceActionMetaData = (business_id, selectReso) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectReso}/get-place-action-metadata/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingPlaceActionMetaSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingPlaceActionMetaFailure(err));
      });
  };
};

export function getGmbListingAttributeSuccess(data) {
  return { type: types.GET_GMB_ATTRIBUTES_SUCCESS, data };
}

export function getGmbListingAttributeFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_ATTRIBUTES_FAILURE, data };
}

export const getGmbListingAttribute = (
  business_id,
  selectedRestaurantId,
  payload
) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectedRestaurantId}/get-attribute-list/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingAttributeSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingAttributeFailure(err));
      });
  };
};

export function getGmbListingMediaSuccess(data) {
  return { type: types.GET_GMB_MEDIA_SUCCESS, data };
}

export function getGmbListingMediaFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_MEDIA_FAILURE, data };
}

export const getGmbListingMedia = (
  business_id,
  selectedRestaurantId,
  payload
) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectedRestaurantId}/media/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingMediaSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingMediaFailure(err));
      });
  };
};

export function getGmbListingDeleteMediaSuccess(data) {
  return { type: types.GET_GMB_DELETE_MEDIA_SUCCESS, data };
}

export function getGmbListingDeleteMediaFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_DELETE_MEDIA_FAILURE, data };
}

export const getGmbListingDeleteMedia = (
  business_id,
  selectedRestaurantId,
  payload
) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectedRestaurantId}/delete-media/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingDeleteMediaSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingDeleteMediaFailure(err));
      });
  };
};

export function getGmbListingConsoleLogSuccess(data) {
  return { type: types.GET_GMB_CONSOLE_LIST_SUCCESS, data };
}

export function getGmbListingConsoleLogFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_CONSOLE_LIST_FAILURE, data };
}

export const getGmbListingConsoleLog = (
  business_id,
  account_id,
  status_type,
  completion_status,
  openstatus,
  search,
  update_listing_type,
  payload
) => {
  let url = `${
    URLS.GMB_LISTING_LOCATION
  }/${business_id}/console-list/?account=${
    account_id ? account_id : ""
  }&gmb_status=${status_type ? status_type : ""}&completion_status=${
    completion_status ? completion_status : ""
  }&open_status=${openstatus ? openstatus : ""}&q=${
    search ? search : ""
  }&update_listing_type=${update_listing_type ? update_listing_type : ""}`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingConsoleLogSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingConsoleLogFailure(err));
      });
  };
};

export function getGmbListingBulkUpdateListingSuccess(data) {
  return { type: types.GET_GMB_BULK_UPDATE_LISTING_SUCCESS, data };
}

export function getGmbListingBulkUpdateListingFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_BULK_UPDATE_LISTING_FAILURE, data };
}

export const getGmbListingBulkUpdateListing = (
  additionalCategories,
  businessData,
  formDATA = false
) => {
  console.log("additionalCategoriesadditionalCategories", additionalCategories);
  console.log(
    "businessData?.business_idbusinessData?.business_id",
    businessData?.business_id,
    "businessData?.selectResobusinessData?.selectReso",
    businessData?.selectReso
  );
  let config = {};
  if (hasTruthyValue(formDATA)) {
    config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
  } else {
    config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
  }
  let url = `${URLS.GMB_LISTING_LOCATION}/${businessData?.business_id}/bulk-update-listing/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(url, additionalCategories, config)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingBulkUpdateListingSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingBulkUpdateListingFailure(err));
      });
  };
};

export function getGmbServiceTypeSuccess(data) {
  return { type: types.GET_GMB_SERVICE_TYPE_SUCCESS, data };
}

export function getGmbServiceTypeFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_SERVICE_TYPE_FAILURE, data };
}

export const getGmbServiceType = (
  business_id,
  selectedRestaurantId,
  category_id,
  payload
) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectedRestaurantId}/get-service-types/?category_id=${category_id}`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbServiceTypeSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbServiceTypeFailure(err));
      });
  };
};

export function getGmbListingSyncDataSuccess(data) {
  return { type: types.GET_GMB_SYNC_DATA_SUCCESS, data };
}

export function getGmbListingSyncDataFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_SYNC_DATA_FAILURE, data };
}

export const getGmbListingSyncData = (business_id) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/sync-data/`;
  return (dispatch) => {
    dispatch(commonAction.hideLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingSyncDataSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingSyncDataFailure(err));
      });
  };
};

export function getGmbLocationFoodmenuSuccess(data) {
  return { type: types.GET_GMB_LOCATION_FOODMENU_SUCCESS, data };
}

export function getGmbLocationFoodmenuFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LOCATION_FOODMENU_FAILURE, data };
}

export const getGmbLocationFoodmenu = (
  business_id,
  selectedRestaurantId,
  payload
) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectedRestaurantId}/location-foodmenus/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationFoodmenuSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationFoodmenuFailure(err));
      });
  };
};

export function getGmbLocationSyncDataStatusSuccess(data) {
  return { type: types.GET_GMB_LOCATION_SYNC_DATA_STATUS_SUCCESS, data };
}

export function getGmbLocationSyncDataStatusFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LOCATION_SYNC_DATA_STATUS_FAILURE, data };
}

export const getGmbLocationSyncDataStatus = (
  business_id,
  sync_data_id,
  payload
) => {
  let url = `${
    URLS.GMB_LISTING_LOCATION
  }/${business_id}/get-sync-data-status/?sync_data_id=${
    sync_data_id ? sync_data_id : ""
  }`;
  return (dispatch) => {
    dispatch(commonAction.hideLoader());
    return axios_instance
      .get(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationSyncDataStatusSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationSyncDataStatusFailure(err));
      });
  };
};

export function getGmbLocationUpdateServiceSuccess(data) {
  return { type: types.GET_GMB_LOCATION_UPDATE_SERVICE_SUCCESS, data };
}

export function getGmbLocationUpdateServiceFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_GMB_LOCATION_UPDATE_SERVICE_FAILURE, data };
}

export const getGmbLocationUpdateService = (business_id, selectReso, data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    // const url = utils.format(URLS.CONTACT, [branchId]);
    let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectReso}/update-location-services/`;

    return axios_instance
      .post(url, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationUpdateServiceSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationUpdateServiceFailure(err));
        throw err.response.data;
      });
  };
};

export function getGmbLocationQusetionAnswerSuccess(data) {
  return { type: types.GET_GMB_LOCATION_QUESTION_ANSWER_SUCCESS, data };
}

export function getGmbLocationQusetionAnswerFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LOCATION_QUESTION_ANSWER_FAILURE, data };
}

export const getGmbLocationQusetionAnswer = (
  business_id,
  selectReso,
  payload
) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectReso}/questions/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationQusetionAnswerSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationQusetionAnswerFailure(err));
      });
  };
};

export function getGmbLocationUpdateDiffDataSuccess(data) {
  return { type: types.GET_GMB_LOCATION_UPDATE_DIFF_DATA_SUCCESS, data };
}

export function getGmbLocationUpdateDiffDataFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LOCATION_UPDATE_DIFF_DATA_FAILURE, data };
}

export const getGmbLocationUpdateDiffData = (
  business_id,
  selectReso,
  payload
) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectReso}/get-updated-diff-data/`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationUpdateDiffDataSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationUpdateDiffDataFailure(err));
      });
  };
};

export function getGmbGoogleLocationSuccess(data) {
  return { type: types.GET_GMB_GOOGLE_LOCATION_SUCCESS, data };
}

export function getGoogleLocationFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_GOOGLE_LOCATION_FAILURE, data };
}

export const getGmbGoogleLocation = (business_id, selectReso, payload) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/${selectReso}/get-google-location/?search=${payload}`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbGoogleLocationSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGoogleLocationFailure(err));
      });
  };
};

export function getGmbBulkChangeLogSuccess(data) {
  return { type: types.GET_GMB_BULK_CHANGE_LOG_SUCCESS, data };
}

export function getBulkChangeLogFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_BULK_CHANGE_LOG_FAILURE, data };
}

export const getGmbBulkChangeLog = (business_id, page_size, page, payload) => {
  let url = `${
    URLS.GMB_LISTING_LOCATION
  }/${business_id}/bulk_change_logs?page_size=${page_size || 10}&page=${
    page || 1
  }`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbBulkChangeLogSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getBulkChangeLogFailure(err));
      });
  };
};


export function getGmbLocationChangeLogSuccess(data) {
  return { type: types.GET_GMB_LOCATION_CHANGE_LOG_SUCCESS, data };
}

export function getGmbLocationChangeLogFailure(error) {
  let data = {};
  data.errors = error?.response?.data;
  return { type: types.GET_GMB_LOCATION_CHANGE_LOG_FAILURE, data };
}

export const getGmbLocationChangeLog = (business_id,location_id, page_size, page, payload) => {
  let url = `${
    URLS.GMB_LISTING_LOCATION
  }/${business_id}/${location_id}/gmb_change_logs?page_size=${page_size || 10}&page=${
    page || 1
  }`;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationChangeLogSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbLocationChangeLogFailure(err));
      });
  };
};
