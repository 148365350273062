import React from "react";
import { Row, Col } from "reactstrap";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import ContactsFilter from "./ContactsFilter";
import ContactsList from "./ContactsList";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import ConfirmationModal from "../../components/common/ConfirmationModal";

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedContacts: props.selectedContacts || [],
      clearContacts: false,
      deleteIconSize: "big",
    };
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.resetSelectedContacts !== this.props.resetSelectedContacts && this.props.resetSelectedContacts) {
      this.setState({ selectedContacts: [] });
    }
  }

  handleCheckbox(event, data) {
    const { contactList, handleResetSelectedContacts } = this.props;
    const { selectedContacts } = this.state;
    let uniqueContacts = new Set(selectedContacts);

    if (data.checked && data.id === "all") {
      contactList.forEach((item) => uniqueContacts.add(item.id));
    } else if (!data.checked && data.id === "all") {
      contactList.forEach((item) => uniqueContacts.delete(item.id));
    } else if (data.checked) {
      uniqueContacts.add(data.id);
    } else {
      uniqueContacts.delete(data.id);
    }
    const updatedContacts = Array.from(uniqueContacts).filter((item) => !!item);

    this.setState({ selectedContacts: updatedContacts });

    handleResetSelectedContacts && handleResetSelectedContacts(false);
  }

  downloadContacts(contacts) {
    let {
      contactIds,
      match: {
        params: { business_id },
      },
    } = this.props;
    const data = {
      option: contacts.length === contactIds.length ? "ALL" : contacts,
    };
    axios_instance
      .get(`${URLS.BUSINESS_SETUP}${business_id}/`)
      .then((res) => {
        let bName = res.data.name;
        axios_instance
          .post(utils.format(`${URLS.DOWNLOAD_CONTACT}`, [business_id]), data)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${bName}_contacts.csv`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  }

  handleMoreoptions = (value) => {
    const { deleteContacts } = this.props;
    const { selectedContacts } = this.state;
    if (value === "2") {
      deleteContacts(selectedContacts);
    } else if (value === "3") {
      this.downloadContacts(selectedContacts);
    } else if (value === "4") {
      this.props.gotToContactManageTags();
    } else if (value === "5") {
      this.props.toggle();
    } else if (value === "6") {
      this.props.toggleBulkUpload();
    }
  };

  toggleAllSelected = () => {
    const { contactIds, handleResetSelectedContacts } = this.props;
    const isAllContactSelected = this.state.clearContacts;
    if (isAllContactSelected) {
      this.setState({ selectedContacts: [], clearContacts: false });
    } else {
      this.setState({ selectedContacts: contactIds, clearContacts: true });
    }
    handleResetSelectedContacts && handleResetSelectedContacts(false);
  };

  clearAll = () => {
    this.setState({ selectedContacts: [], clearContacts: false });
  };

  toggleConfirm = (item) => {
    const { selectedContacts } = this.state;
    const selectedCount = selectedContacts.length;
    // const subHeader = subHeadingText
    //   ? `Are you sure you want to delete all ${selectedCount} contacts. This action cannot be undone.`
    //   : "Are you sure you want to delete the selected contact. This action cannot be undone.";
    let subHeader;
    if (item) {
      subHeader = "Are you sure you want to delete the selected contact. This action cannot be undone.";
      this.setState({
        selectedContacts: [item.id],
      });
    } else {
      if (selectedCount === this.props.contactList?.length) {
        subHeader = `Are you sure you want to delete all ${selectedCount} contacts. This action cannot be undone.`;
      } else if (selectedCount <= 1) {
        subHeader = "Are you sure you want to delete the selected contact. This action cannot be undone.";
      } else {
        subHeader = `Are you sure you want to delete selected ${selectedCount} contacts. This action cannot be undone.`;
      }
    }
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      subHeader,
    });
  };

  render() {
    const { selectedContacts, subHeader, clearContacts } = this.state;
    const {
      showTabs,
      gotToContactManageTags,
      toggle,
      toggleBulkUpload,
      deleteContacts,
      campaignContacts,
      contactsCount,
    } = this.props;
    return (
      <Row>
        <Col sm="12">
          {/* <ContactsFilter
            {...this.props}
            selectedContacts={selectedContacts}
            handleMoreOptions={this.handleMoreoptions}
            campaignContacts={campaignContacts}
            toggleAllSelected={this.toggleAllSelected}
            contactsCount={contactsCount}
            clearContacts={clearContacts}
          /> */}
          <CardWrapper showDivider={false}>
            <ContactsList
              {...this.props}
              toggleAllSelected={this.toggleAllSelected}
              clearAll={this.clearAll}
              contactsCount={contactsCount}
              clearContacts={clearContacts}
              handleMoreOptions={this.handleMoreoptions}
              campaignContacts={campaignContacts}
              handleCheckbox={this.handleCheckbox}
              selectedContacts={selectedContacts}
              showTabs={showTabs}
              downloadContacts={() => this.downloadContacts(selectedContacts)}
              gotToContactManageTags={() => gotToContactManageTags()}
              toggle={toggle}
              toggleBulkUpload={() => toggleBulkUpload()}
              deleteContact={(item) => this.toggleConfirm(item)}
              // deleteAllContacts={() => this.toggleConfirm(() => this.deleteAllContacts(), true)}
            />
            <ConfirmationModal
              header="Are you sure!"
              style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
              subHeader={subHeader}
              isOpen={this.state.isConfirmOpen}
              toggle={this.toggleConfirm}
              onConfirm={() => deleteContacts(selectedContacts)}
            />
          </CardWrapper>
        </Col>
      </Row>
    );
  }
}

export default Contacts;
