/* eslint-disable */
import React, { useState, useEffect } from "react";
import {
  Card,
  Icon,
  Modal,
  Feed,
  Popup,
  Button,
  Checkbox,
  Table,
} from "semantic-ui-react";
import { Row, Col, Label } from "reactstrap";
import LoaderComponent from "../messenger/Common/LoaderComponent";
import ReactPaginate from "react-paginate";
import moment from "moment";
import CustomStarRatingComponent from "../common/StarComponent";
import { CSSTransitionGroup } from "react-transition-group";
import Highlighter from "react-highlight-words";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import DefaultUserIcon from "../../images/boy.png";
import Linkify from "react-linkify";
import LimitModal from "../common/LimitModal";
import cookie from "react-cookies";

const TagsReviewCard = (data) => {
  let item = data.data;
  let smiley = utils.getsmileyIcon(item.smiley_value);
  const searchText = item ? item.tag : [];
  return (
    <Card
      fluid
      className="mt-4 review-shadow fade-in"
      style={{ padding: "10px" }}
    >
      <Row className="pl-3">
        <Col sm="11" className="pl-0 pt-1">
          <Feed>
            <Feed.Event>
              <Feed.Label className="review-time">
                {item.review_type === "star" && (
                  <div className="rating">
                    <CustomStarRatingComponent
                      name="rate1"
                      starCount={5}
                      className="align-bottom"
                      starColor="#FBBD08"
                      emptyStarColor="#FFFFFF"
                      value={+item.rating}
                      editing={false}
                    />
                  </div>
                )}
                {item.review_type === "thumb" && (
                  <Icon
                    color="teal"
                    size="large"
                    name={item.is_thum_up ? "thumbs up" : "thumbs down"}
                  />
                )}
                {item.review_type === "smiley" && (
                  <Icon color="teal" size="large" name={smiley} />
                )}
                {item.review_type === "nps_score" && (
                  <div
                    style={{
                      padding: "7px 7px",
                      fontWeight: "bold",
                      color: "rgba(0,0,0,0.7)",
                      background: "rgba(64,224,208)",
                    }}
                  >
                    {item.nps_score}/10
                  </div>
                )}
              </Feed.Label>
              <Feed.Content>
                <Feed.Date>
                  <span
                    className="text-dark"
                    style={{
                      fontSize: "13px",
                      fontWeight: "bold",
                      letterSpacing: "0",
                    }}
                  >
                    {item.branch}
                  </span>
                  <span className="d-block">
                    {moment(
                      item.review_create ? item.review_create : item.updated_at
                    ).format("llll")}
                  </span>
                </Feed.Date>
              </Feed.Content>
            </Feed.Event>
          </Feed>
        </Col>
        <Col sm="1" className="pt-2" style={{ paddingLeft: "25px" }}>
          <img
            src={item.provider}
            alt=""
            className="float-right cursor-pointer"
            height="25"
            width="25"
            onClick={() => {
              item.provider_link
                ? window.open(item.provider_link, "_blank")
                : null;
            }}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <div className="ui feed ml-3">
          <div className="event">
            <div className="label">
              <img
                src={
                  item.reviewer.image ? item.reviewer.image : DefaultUserIcon
                }
                alt={item.reviewer.name}
              />
            </div>
            <div className="mt-2 ml-2">
              <strong>
                {item.provider_name === "swiggy" ||
                item.provider_name === "zomato_ors"
                  ? item.order_id
                  : item.reviewer.unique_code
                  ? item.reviewer.unique_code
                  : item.reviewer.name && item.reviewer.name.toString()}
              </strong>
            </div>
            {(item.provider_name === "famepilot" ||
              item.provider_name === "reservego") && (
              <div className="mt-2 ml-2">
                {Object.keys(item.reviewer).length &&
                  Object.keys(item.reviewer).map((data, index) => {
                    return (
                      <span key={index}>
                        {data !== "unique_code" && (
                          <span
                            style={{
                              color: "gray",
                              fontStyle: "normal",
                              fontSize: "14px",
                            }}
                          >
                            {item.reviewer[data] && (
                              <span>&nbsp;|&nbsp;{item.reviewer[data]}</span>
                            )}
                          </span>
                        )}
                      </span>
                    );
                  })}
              </div>
            )}
            {["offline", "customercare"].includes(item.provider_name) && (
              <div className="mt-2">
                {item.reviewer.phone && item.reviewer.phone !== "None" && (
                  <span
                    style={{
                      color: "gray",
                      fontStyle: "normal",
                      fontSize: "14px",
                    }}
                  >
                    &nbsp;|&nbsp;{item.reviewer.phone}
                  </span>
                )}
                {item.reviewer.email && (
                  <span
                    style={{
                      fontWeight: "normal",
                      color: "gray",
                      fontStyle: "normal",
                      fontSize: "14px",
                    }}
                  >
                    &nbsp;|&nbsp;{item.reviewer.email}
                  </span>
                )}
              </div>
            )}
          </div>
        </div>
      </Row>
      <Row className="mt-2 ml-0">
        <div className="content content-break">
          <Linkify properties={{ target: "_blank" }}>
            <Highlighter
              highlightStyle={{
                color: +item.rating > 3 ? "green" : "red",
                fontWeight: "bold",
              }}
              searchWords={[searchText]}
              textToHighlight={item.description}
            ></Highlighter>
          </Linkify>
        </div>
      </Row>

      <Row className="mt-3">
        <Col sm={12} xs={12} className="px-3 center-position">
          <div
            style={{ color: "gray", alignItems: "center", display: "contents" }}
          >
            <div style={{ padding: "3px 0 0 0", height: "100%" }}>
              <Icon name="tag"></Icon>Tags: &nbsp;
            </div>
            <CSSTransitionGroup
              transitionName="reviews-tags"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
            >
              {item.tag.map((tag, index) => {
                return (
                  <div key={index} style={{ display: "inline-block" }}>
                    <span
                      style={{
                        padding: "4px 10px",
                        marginBottom: "10px",
                        background: "#ebebeb",
                        borderRadius: "10px",
                      }}
                      className="mr-2 cursor-pointer align-center"
                    >
                      <span style={{ padding: "0 0 3px 0" }}>{tag}</span>
                      &nbsp;&nbsp;
                    </span>
                  </div>
                );
              })}
            </CSSTransitionGroup>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

const TagReviews = (props) => {
  const {
    isOpen,
    toggle,
    headerText,
    business_id,
    sentimentType,
    tagName,
    queryData,
    querySearch,
  } = props;
  const [reviewsData, setReviewsData] = useState(null);
  const [reviewTagsReportData, setReviewTagsReportData] = useState(null);
  const [loading, setloading] = useState(false);
  const [downloadReviewModal, setDownloadReviewModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [showTagReport, setShowTagReport] = useState(false);

  const handlePageClick = (selectedPage) => {
    const url = `${URLS.REVIEWS_DETAILS}${business_id}/review/`;
    let params = queryData;
    params["tag"] = tagName;
    params["page"] = selectedPage.selected + 1;

    if (sentimentType !== "All") {
      params["sentiment"] = sentimentType;
    } else {
      delete params["sentiment"];
    }
    setPageNumber(selectedPage.selected);
    setloading(true);
    axios_instance
      .get(url, { params })
      .then((res) => {
        setReviewsData(res.data);
        setloading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const downloadAPICall = (url, filename) => {
    const token = cookie.load("authToken");
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/csv",
      },
    }).then((res) => {
      return res.blob().then((blob) => {
        let winUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = winUrl;
        a.download = filename || "famepilot.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    });
  };

  const downloadTagReport = () => {
    let url =
      `${URLS.REVIEWS_DETAILS}${business_id}/review_tags_report/${querySearch}&tag=${tagName}&download=` +
      true;
    if (sentimentType !== "All") {
      url =
        `${URLS.REVIEWS_DETAILS}${business_id}/review_tags_report/${querySearch}&tag=${tagName}&sentiment=${sentimentType}&download=` +
        true;
    }
    const token = cookie.load("authToken");
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/csv",
      },
    }).then((res) => {
      return res.blob().then((blob) => {
        let winUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = winUrl;
        a.download = "famepilot-associated-tag-report.csv";
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    });
  };

  const handleDownloadReviewModal = (download) => {
    setDownloadReviewModal(!downloadReviewModal);
    if (download) {
      if (reviewsData && reviewsData.count < 1500) {
        let url =
          `${URLS.REVIEWS_DETAILS}${business_id}/review/download/${querySearch}&tag=${tagName}&celery_download=` +
          false;
        if (sentimentType !== "All") {
          url =
            `${URLS.REVIEWS_DETAILS}${business_id}/review/download/${querySearch}&tag=${tagName}&sentiment=${sentimentType}&celery_download=` +
            false;
        }
        return downloadAPICall(url, "famepilot-review-report.csv");
      } else {
        let url =
          `${URLS.REVIEWS_DETAILS}${business_id}/review/download/${querySearch}&tag=${tagName}&celery_download=` +
          true;
        if (sentimentType !== "All") {
          url =
            `${URLS.REVIEWS_DETAILS}${business_id}/review/download/${querySearch}&tag=${tagName}&sentiment=${sentimentType}&celery_download=` +
            true;
        }
        axios_instance.get(url);
      }
    }
  };

  const fetchReviewData = () => {
    const url = `${URLS.REVIEWS_DETAILS}${business_id}/review/`;

    let params = queryData;
    params["tag"] = tagName;
    if (sentimentType !== "All") {
      params["sentiment"] = sentimentType;
    } else {
      delete params["sentiment"];
    }
    setloading(true);
    axios_instance
      .get(url, { params })
      .then((res) => {
        setReviewsData(res.data);
        setloading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchTagCountReport = () => {
    const url = `${URLS.REVIEWS_DETAILS}${business_id}/review_tags_report/`;
    let params = queryData;
    params["tag"] = tagName;
    if (sentimentType !== "All") {
      params["sentiment"] = sentimentType;
    } else {
      delete params["sentiment"];
    }
    axios_instance
      .get(url, { params })
      .then((res) => {
        setReviewTagsReportData(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    queryData["page"] = 1;
    setPageNumber(0);
    fetchReviewData();
    fetchTagCountReport();
  }, [sentimentType, tagName]);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={toggle}
        closeIcon
        size="small"
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          height: "100%",
          margin: 0,
          left: "auto",
          width: "60%",
        }}
      >
        <Modal.Header>
          {headerText}
          {reviewsData && reviewsData.count > 0 && (
            <div style={{ display: "flex", float: "right" }}>
              <Label style={{ fontSize: "15px" }}>
                Show Current Tag Location Summary
              </Label>
              <Popup
                content={"Show Current Tag Location Summary"}
                trigger={
                  <Checkbox
                    toggle
                    checked={showTagReport}
                    onClick={() => setShowTagReport(!showTagReport)}
                    style={{ marginRight: "10px", marginLeft: "10px" }}
                  ></Checkbox>
                }
              ></Popup>
              <Popup
                size="mini"
                position="top center"
                content="Download Reviews"
                trigger={
                  <Button
                    className="mr-5 teal icon circular"
                    type="button"
                    onClick={() => setDownloadReviewModal(!downloadReviewModal)}
                    style={{ float: "right", marginTop: "-8px" }}
                  >
                    <Icon name="download" />
                  </Button>
                }
              />
            </div>
          )}

          <Icon
            name="close"
            onClick={toggle}
            style={{
              position: "absolute",
              right: "20px",
              top: "20px",
              cursor: "pointer",
            }}
          />
        </Modal.Header>
        <Modal.Content
          scrolling
          style={{
            maxHeight: "calc(100vh - 70px)",
            overflowY: "auto",
            paddingTop: "0px",
          }}
        >
          {showTagReport &&
            reviewTagsReportData &&
            reviewTagsReportData.data &&
            reviewTagsReportData.data.length > 0 && (
              <Card
                fluid
                className="mt-4 review-shadow fade-in"
                style={{ padding: "10px" }}
              >
                <div style={{ display: "block" }}>
                  <h5 style={{ float: "left" }}>
                    Current Tag Location Summary
                  </h5>
                  <Popup
                    size="mini"
                    position="top center"
                    content="Download Current Tag Location Summary"
                    trigger={
                      <Button
                        className="mr-1 teal icon circular"
                        type="button"
                        onClick={() => downloadTagReport()}
                        style={{ float: "right" }}
                      >
                        <Icon name="download" />
                      </Button>
                    }
                  />
                </div>
                <div className="ui ui-table-responsive small single line sortable unstackable very basic compact table ">
                  <Table
                    sortable
                    stackable
                    className="mt-1 content-area"
                    style={{ height: "auto" }}
                  >
                    <Table.Header>
                      <Table.Row verticalAlign="middle" textAlign="center">
                        <Table.HeaderCell>Location</Table.HeaderCell>
                        {reviewTagsReportData.tags.map((item, index) => {
                          return (
                            <Table.HeaderCell key={index}>
                              {item}
                            </Table.HeaderCell>
                          );
                        })}
                        <Table.HeaderCell>Count</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {reviewTagsReportData &&
                        reviewTagsReportData.data &&
                        reviewTagsReportData.data.length > 0 &&
                        reviewTagsReportData.data.map((item, index) => {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell
                                verticalAlign="middle"
                                textAlign="center"
                                style={{
                                  maxWidth: "200px",
                                  whiteSpace: "break-spaces",
                                }}
                              >
                                {item["location"]}
                              </Table.Cell>
                              {reviewTagsReportData.tags.map(
                                (tag_item, tag_index) => {
                                  return (
                                    <Table.Cell
                                      verticalAlign="middle"
                                      textAlign="center"
                                      key={tag_index}
                                    >
                                      {item[tag_item]}
                                    </Table.Cell>
                                  );
                                }
                              )}
                              <Table.Cell
                                verticalAlign="middle"
                                textAlign="center"
                              >
                                {item["count"]}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                </div>
              </Card>
            )}
          {loading ? (
            <div
              style={{
                minHeight: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LoaderComponent showLoader={loading} />
            </div>
          ) : reviewsData &&
            reviewsData.results &&
            reviewsData.results.length > 0 ? (
            <React.Fragment>
              <React.Fragment>
                {
                  <React.Fragment>
                    {reviewsData.results.map((data, index) => {
                      return <TagsReviewCard key={index} data={data} />;
                    })}
                  </React.Fragment>
                }
              </React.Fragment>
              {reviewsData && reviewsData.next && (
                <ReactPaginate
                  previousLabel={"← Previous"}
                  nextLabel={"Next →"}
                  breakLabel="..."
                  pageCount={
                    reviewsData.count % reviewsData.limit === 0
                      ? Math.floor(reviewsData.count / reviewsData.limit)
                      : Math.floor(reviewsData.count / reviewsData.limit) + 1
                  }
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"pagination__link--disabled"}
                  activeClassName={"pagination__link--active"}
                  forcePage={pageNumber}
                />
              )}
            </React.Fragment>
          ) : (
            <div className="text-center mt-4">
              <h4
                style={{
                  color: "#000000",
                  fontSize: "20px",
                  fontWeight: "bold",
                  lineHeight: "24px",
                  textAlign: "center",
                }}
              >
                No {headerText} Found
              </h4>
            </div>
          )}
        </Modal.Content>
      </Modal>
      {downloadReviewModal && (
        <LimitModal
          isOpen={downloadReviewModal}
          isReviewsPage={true}
          toggle={handleDownloadReviewModal}
          HeaderText={"Alrighty"}
          message={[
            "Hey you are good to go. Click ok to download Reviews Report",
          ]}
          handleDownloadModal={handleDownloadReviewModal}
        />
      )}
    </div>
  );
};

export default TagReviews;
