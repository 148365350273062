import React, { useEffect, useState } from "react";
import SocialCommentsFilter from "../../modules/socialComments/SocialCommentsFilter";
import SocialCommentsCard from "../../modules/socialComments/SocialCommentsCard";
import { bindActionCreators } from "redux";
import * as SocialCommentsActions from "../../actions/socialComments/socialCommentsActions";
import * as SocialProfileActions from "../../actions/socialProfile/socialProfileActions";
import { connect } from "react-redux";
import { socialKeys } from "../../images";
import LoaderComponent from "../../components/common/loaderComponent";
import queryString, { parse } from "query-string";
import PaginationComponent from "../../components/common/Pagination";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";

const SocialComment = (props) => {
  const {
    businessInfo,
    history,
    location,
    socialCommentsActions,
    match,
    socialComment,
    socialProfileActions,
    location: { pathname, search },
  } = props;
  const {
    params: { business_id },
  } = match;

  const [mainData, setMainData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageData, setPageData] = useState(null);
  const [branchFilterData, setBranchFilterData] = useState([]);

  useEffect(() => {
    document.title = "social-comments";
    props.actionsSelectTab.setSelectedTab(1);
    !!pageData > 0 && getComments(pageData);
  }, [pageData, search]);

  useEffect(() => {
    const data = {
      business_id: business_id,
    };
    setLoader(true);
    if (businessInfo && businessInfo.id) {
      socialProfileActions
        .getSocialProfile(data)
        .then((res) => {
          handleSocialApi(res);
        })
        .catch((err) => console.log(err));
    }
  }, [businessInfo]); //eslint-disable-line

  const getPageLocationMapping = (data) => {
    if (!!data?.[0] && business_id) {
      const params = {
        business_id: business_id,
        web_portal_id: data[data?.length - 1].web_portal_id,
      };

      socialCommentsActions
        .getSocialPageLocationMapping(params)
        .then((res) => {
          setPageData(res.data);
        })
        .catch((err) => console.log(err));
    }
  };

  const getComments = (value) => {
    const queryparams = parse(search) || {};
    const page = queryparams.page;
    const page_size = queryparams.page_size;
    setLoader(true);
    if (!!value && business_id) {
      const ids = value?.linked_pages.map((item) => item.page_id).join(",");
      const filterData = value?.linked_pages.map((item) => ({
        display_name: item.branch,
        value: item.branch_id,
      }));
      setBranchFilterData(filterData);

      let dateParams = {};

      if (!!Object.keys(queryparams).length > 0 && !!queryparams?.date_type) {
        if (queryparams.date_type === "other") {
          dateParams = {
            end_date: queryparams.end_date,
            start_date: queryparams.start_date,
          };
        } else if (queryparams.date_type !== "other") {
          dateParams = {
            date_type: queryparams.date_type,
          };
        }
      }

      const data = {
        business_id: business_id,
        page_id: ids,
        page: page || 1,
        page_size: page_size || 10,
        ...dateParams,
      };
      socialCommentsActions
        .getSocialComment(data)
        .then((res) => {
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
        });
    }
  };

  const handleSocialApi = (res) => {
    const newData = res?.data;
    const keys = Object.keys(newData?.connected_profiles);
    const connected_profiles = newData?.connected_profiles;
    const test_data = [];
    keys.map((val) => {
      const ConnectedProfile = connected_profiles[val];
      test_data.push({
        business_id: ConnectedProfile.business_id,
        key: socialKeys[val],
        name: val,
        icon: connected_profiles[val].icon,
        email: ConnectedProfile.email,
        web_portal_id: ConnectedProfile.web_portal_id,
        branch_id: ConnectedProfile.branch_id,
        is_active: ConnectedProfile.is_active,
        created_at: ConnectedProfile.created_at,
      });

      return test_data;
    });
    setMainData(test_data);
    getPageLocationMapping(test_data);

    if (keys.length === 0) {
      setLoader(false);
    }
  };

  const  getFilterData=(data) => {
    console.log("call");
    
    const queryparams = parse(search) || {};
    const page = queryparams.page;
    const page_size = queryparams.page_size;
    const ids = pageData?.linked_pages
      .map((item) => item.page_id)
      .join(",");

    socialCommentsActions.getSocialComment({
      business_id: data?.businessId,
      date_type: data?.date_type,
      page: page || 1,
      page_id: ids,
      page_size: page_size || 10,
    });
  }

  return (
    <div>
      <LoaderComponent loader={loader} />
      <p className="fp-card-header py-3">Social Comments</p>
      <div>
        <SocialCommentsFilter
          history={history}
          location={location}
          match={match}
          branchFilterData={branchFilterData}
          getFilterData={getFilterData}
        />
        {socialComment &&
        socialComment?.results &&
        socialComment?.results?.length > 0 ? (
          <>
            {socialComment?.results?.map((item, index) => {
              return (
                <>
                  <SocialCommentsCard
                    location={location}
                    item={item}
                    key={`${item.id}-socialCommentCard`}
                    pageData={pageData}
                    socialCommentsActions={socialCommentsActions}
                    getComments={getComments}
                    setLoader={setLoader}
                  />
                </>
              );
            })}
            <PaginationComponent
              count={socialComment && socialComment.count}
              location={location}
              pageSizeCookie="social_comments"
            />
          </>
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-5">
            <span style={{ fontSize: "18px", fontWeight: "500" }}>
              {pageData?.linked_pages?.length > 0
                ? "No data Found"
                : "No Page Connected"}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    businessInfo: state.business.detail,
    socialComment: state.socialComments.comments,
    pageMapping: state.socialComments.pageLocation,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    socialProfileActions: bindActionCreators(SocialProfileActions, dispatch),
    socialCommentsActions: bindActionCreators(SocialCommentsActions, dispatch),
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(SocialComment);
