import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import queryString from "query-string";
import { bindActionCreators } from "redux";
import * as commonAction from "../../actions/common/commonAction";
import * as competitorsActions from "../../actions/competitors/competitorsActions";
import * as businessActions from "../../actions/business/businessSetupActions";
import * as utils from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import cookie from "react-cookies";
import "./competitor.css";

import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from "reactstrap";
import { Sticky } from "semantic-ui-react";
import classnames from "classnames";
import CompetitorsReviews from "../../components/competitors/CompetitorsReviews";
import CompetitorsInsights from "../../components/competitors/CompetitorsInsights.js";
import CompetitorsOverview from "../../components/competitors/CompetitorsOverview";
import CompetitorsFilter from "../../components/competitors/CompetitorsFilter";
import LockedComponent from "../../components/common/LockedComponent/LockedComponent";
import CompetitorsRating from "../../components/competitors/CompetitorsRating";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import classNames from "classnames";
import ProviderRating from "./ProviderRating";
import StarRating from "./StarRating";
import NetPromoterScore from "./NetPromoterScore";
import Sentiment from "./Sentiment";
import CategoryRating from "./CategoryRating";

class CompetitorsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: (props.location.state && props.location.state.tab) || "1",
      loader: {},
      params: {
        1: "date_type=prior_30_day",
        2: "date_type=prior_30_day",
        3: `date_type=prior_30_day`,
        4: "date_type=prior_30_day",
        5: "date_type=prior_30_day",
      },
    };
    this.toggleTab = this.toggleTab.bind(this);
    this.getData = this.getData.bind(this);
    this.handleReplyOption = this.handleReplyOption.bind(this);
    this.handleCompetitorSearch = this.handleCompetitorSearch.bind(this);
    this.downloadRatingReport = this.downloadRatingReport.bind(this);

    //PageSize Handling
    const pageSize = cookie.load("pageSize") || {};
    const {
      history,
      location: { pathname, search },
    } = props;
    history.replace({
      pathname,
      search: queryString.stringify({
        ...queryString.parse(search),
        page_size: pageSize.competiors_reviews || 10,
      }),
    });
  }

  componentDidMount() {
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    const { lock } = this.state;
    this.setLoader();
    this.props.commonActions.rationsOptions();
    // this.getData({ ...queryString.parse(search), businessId: business_id })
    //   .catch((err) => err === 'lock' && !lock && this.setState({ lock: 'Competitors module is locked' }))
    //   .finally(() => this.setState({ loader: { ...this.state.loader, '3': false } }));
    // this.props.compActions.competitorsOverview(business_id, { ...queryString.parse(search) })
    //   .catch((err) => err === 'lock' && !lock && this.setState({ lock: 'Competitors module is locked' }))
    //   .finally(() => this.setState({ loader: { ...this.state.loader, '1': false } }));
    this.props.compActions.competitorSourceOptions();
    this.props.commonActions
      .competitorBrandOptions(business_id)
      .catch((err) => err === "lock" && !lock && this.setState({ lock: "Competitors module is locked" }));
    // this.props.compActions.competitorsInsights(business_id, { ...queryString.parse(search) })
    //   .catch((err) => err === 'lock' && !lock && this.setState({ lock: 'Competitors module is locked' }))
    //   .finally(() => this.setState({ loader: { ...this.state.loader, '2': false } }));
    this.props.compActions
      .competitorsRating(business_id, { ...queryString.parse(search) })
      .catch((err) => err === "lock" && !lock && this.setState({ lock: "Competitors module is locked" }))
      .finally(() => this.setState({ loader: { ...this.state.loader, 4: false } }))
      .then(() => {
        // const newQuery = queryString.stringify({ ...queryString.parse(search)  });
        // this.getData({ ...queryString.parse(newQuery), businessId: business_id });
      });

    //Set Title
    document.title = "Competitors";
  }

  componentWillReceiveProps(nextProps) {
    // eslint-disable-line
    const {
      location: { search: prevSearch },
      match: { params: prevParams },
    } = this.props;
    const {
      location: { search: nextSearch },
      match: { params: nextParams },
    } = nextProps;
    if (prevSearch !== nextSearch || prevParams !== nextParams) {
      this.setLoader();

      setTimeout(() => {
        this.setState({ loader: { ...this.state.loader, 4: false } })
      }, 1000)
      // this.getData({ ...queryString.parse(nextSearch), businessId: nextParams.business_id })
      //   .then(() => this.setState({ loader: { ...this.state.loader, '3': false } }));
      // this.props.compActions.competitorsInsights(nextParams.business_id, { ...queryString.parse(nextSearch) })
      //   .then(() => this.setState({ loader: { ...this.state.loader, '2': false } }));
      // this.props.compActions.competitorsOverview(nextParams.business_id, { ...queryString.parse(nextSearch) })
      //   .then(() => this.setState({ loader: { ...this.state.loader, '1': false } }));
      this.props.compActions
        .competitorsRating(nextParams.business_id, {
          ...queryString.parse(nextSearch),
        })
      //   .then(() => this.setState({ loader: { ...this.state.loader, 4: false } }));
    }
  }

  setLoader = () => {
    this.setState({
      loader: {
        1: true,
        2: true,
        3: true,
        4: true,
      },
    });
  };

  getData(data) {
    let dt = { ...data };
    delete dt.businessId;
    // return this.props.compActions.competitorsActions(dt, data.businessId);
  }

  handleCompetitorSearch(params) {
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    let query = queryString.parse(search);
    query = { business_id, params, ...query };
    this.getData(query);
  }

  toggleTab(tab) {
    const { history } = this.props;
    const { activeTab, params } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
      history.push({ pathname: "competitors", search: params[tab] });
    }
  }

  handleReplyOption(status, id) {
    const data = { status: status };
    // const { location: { search }, match: { params: { business_id } } } = this.props;
    this.props.actions
      .statusUpdate(id, data)
      .then(() => {
        // this.getData({ ...queryString.parse(search), businessId: business_id });
      })
      .catch((err) => {
        console.log(err);
        utils.displayMessage("negative", err.status);
      });
  }

  downloadRatingReport() {
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    let new_search = queryString.parse(search);
    new_search["download_excel"] = "yes";
    let api_url = utils.format(URLS.COMPETITORS_RATING, [business_id]);
    let search_filters = queryString.stringify(new_search);
    let final_url = api_url + "?" + search_filters;
    this.props.compActions.downloadReport(final_url).catch((error) => console.error(error));
  }

  render() {
    const {
      ratingOptions,
      match: {
        params: { business_id },
      },
      data,
      brandOptions,
      insightDetails,
      ratingDetails,
      competitorSourceOptions,
      overviewDetails,
      replyOptions,
      businessDetails,
    } = this.props;
    const { loader, activeTab } = this.state;
    const reviewDetails = data;
    const ratingOption = ratingOptions ? ratingOptions : {};
    const ratingsList =
      ratingOption.choices && ratingOption.choices.length
        ? ratingOption.choices.map((item) => ({
            text: item.display_name,
            value: item.value,
          }))
        : [];
    const service_category = cookie.load("service_category");

    return (
      <div>
        <Row className="content-area dashboard-page">
          <LockedComponent message={this.state.lock} />
          <Col sx={12} sm={12} className="business-setup p-3 mt-3">
            <CardWrapper showDivider={false} contentClass="p-0" className="pb-3 px-4">
              <div className="insights-NavRow">
                <Nav tabs className="box-shadow-none" style={{ borderBottom: "none" }}>
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggleTab("1");
                      }}
                    >
                      Provider Wise Rating
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: this.state.activeTab === "2",
                      })}
                      onClick={() => {
                        this.toggleTab("2");
                      }}
                    >
                      Star Wise Rating
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      className={classNames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={() => {
                        this.toggleTab("3");
                      }}
                    >
                      Net Promoter Score
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      className={classNames({
                        active: this.state.activeTab === "4",
                      })}
                      onClick={() => {
                        this.toggleTab("4");
                      }}
                    >
                      Sentiment
                    </NavLink>
                  </NavItem>
                  {service_category === "Hotel" && (
                    <NavItem>
                      <NavLink
                        className={classNames({
                          active: this.state.activeTab === "5",
                        })}
                        onClick={() => {
                          this.toggleTab("5");
                        }}
                      >
                        Category Wise Ratings
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
              </div>
            </CardWrapper>
            <div className="mt-2">
              <TabContent activeTab={this.state.activeTab} className="mt-2">
                <TabPane tabId="1">
                  {activeTab === "1" && (
                    <ProviderRating
                      location={this.props.location}
                      match={this.props.match}
                      history={this.props.history}
                      ratingDetails={ratingDetails}
                      loader={loader["4"]}
                      downloadRatingReport={this.downloadRatingReport}
                      businessDetails={`${businessDetails?.first_name} ${businessDetails?.last_name}`}
                    />
                  )}
                </TabPane>
              </TabContent>
              <TabContent activeTab={this.state.activeTab} className="mt-2">
                <TabPane tabId="2">
                  {activeTab === "2" && (
                    <StarRating
                      location={this.props.location}
                      match={this.props.match}
                      history={this.props.history}
                      ratingDetails={ratingDetails && ratingDetails}
                      downloadRatingReport={this.downloadRatingReport}
                      loader={loader["4"]}
                      businessDetails={`${businessDetails?.first_name} ${businessDetails?.last_name}`}
                    />
                  )}
                </TabPane>
              </TabContent>
              <TabContent activeTab={this.state.activeTab} className="mt-2">
                <TabPane tabId="3">
                  {activeTab === "3" && (
                    <NetPromoterScore
                      location={this.props.location}
                      match={this.props.match}
                      history={this.props.history}
                      businessDetails={`${businessDetails?.first_name} ${businessDetails?.last_name}`}
                    />
                  )}
                </TabPane>
              </TabContent>
              <TabContent activeTab={this.state.activeTab} className="mt-2">
                <TabPane tabId="4">
                  {activeTab === "4" && (
                    <Sentiment
                      ratingDetails={ratingDetails}
                      loader={loader["4"]}
                      location={this.props.location}
                      match={this.props.match}
                      history={this.props.history}
                      businessDetails={`${businessDetails?.first_name} ${businessDetails?.last_name}`}
                    />
                  )}
                </TabPane>
              </TabContent>
              <TabContent activeTab={this.state.activeTab} className="mt-2">
                <TabPane tabId="5">
                  {activeTab === "5" && (
                    <CategoryRating
                      categoryData={(ratingDetails && ratingDetails.data.category_data) || []}
                      location={this.props.location}
                      match={this.props.match}
                      history={this.props.history}
                      businessDetails={`${businessDetails?.first_name} ${businessDetails?.last_name}`}
                      loader={loader["5"]}
                    />
                  )}
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </div>

      // <Row className="content-area">
      //   <LockedComponent message={this.state.lock} />
      //   <Col xs={12} sm={9} className="business-setup p-3">
      //     <Row className="m-0">
      //       <Col xs={12} sm={12} className="p-0">
      //         <Nav tabs className="bg-white">
      //           <NavItem>
      //             <NavLink
      //               className={classnames({ active: activeTab === '4' })}
      //               onClick={() => { this.toggleTab('4'); }}
      //             >
      //              Competitors Rating
      //             </NavLink>
      //           </NavItem>
      //           {/* <NavItem>
      //             <NavLink
      //               className={classnames({ active: activeTab === '1' })}
      //               onClick={() => { this.toggleTab('1'); }}
      //             >
      //               Overview
      //             </NavLink>
      //           </NavItem>
      //           <NavItem>
      //             <NavLink
      //               className={classnames({ active: activeTab === '2' })}
      //               onClick={() => { this.toggleTab('2'); }}
      //             >
      //               Insights
      //             </NavLink>
      //           </NavItem> <NavItem>
      //             <NavLink
      //               className={classnames({ active: activeTab === '3' })}
      //               onClick={() => { this.toggleTab('3'); }}
      //             >
      //               Competitors Reviews
      //             </NavLink>
      //           </NavItem> */}
      //         </Nav>
      //       </Col>
      //     </Row>
      //     <TabContent activeTab={activeTab}>

      //       <TabPane tabId='1'>

      //         <CompetitorsOverview
      //           overviewDetails={overviewDetails && overviewDetails}
      //           loader={loader['1']}
      //         />
      //       </TabPane>
      //       <TabPane tabId='2'>
      //         <CompetitorsInsights
      //           insightDetails={insightDetails && insightDetails}
      //           loader={loader['2']}
      //         />
      //       </TabPane>
      //       <TabPane tabId='3'>
      //         <CompetitorsReviews
      //           location={this.props.location}
      //           match={this.props.match}
      //           history={this.props.history}
      //           businessId={business_id}
      //           data={reviewDetails}
      //           replyOptions={replyOptions ? replyOptions.choices : null}
      //           handleReplyOption={this.handleReplyOption}
      //           getData={this.getData}
      //           loader={loader['3']}
      //         />
      //       </TabPane>
      //       <TabPane tabId= '4'>
      //         <CompetitorsRating
      //           ratingDetails={ratingDetails && ratingDetails}
      //           loader={loader['4']}
      //           downloadRatingReport={this.downloadRatingReport}
      //         />
      //       </TabPane>
      //     </TabContent>
      //   </Col>
      //   <Col xs={12} sm={3} className="my-3">
      //     <Sticky>
      //       <CompetitorsFilter
      //         ratingOptions={ratingsList}
      //         brandOptions={brandOptions && brandOptions.choices}
      //         handleCompetitorSearch={this.handleCompetitorSearch}
      //         competitorSourceOptions={competitorSourceOptions}
      //         location={this.props.location}
      //         match={this.props.match}
      //         history={this.props.history}></CompetitorsFilter>
      //     </Sticky>
      //   </Col>
      // </Row>
    );
  }
}

const mapStateToProps = (state) => ({
  reviewSourceOptions: state.review.review_source_options,
  ratingOptions: state.common.ratings,
  brandOptions: state.common.brand_options,
  data: state.competitors.list,
  replyOptions: state.common.reply_options,
  overviewDetails: state.competitors.overview_details,
  insightDetails: state.competitors.insights_details,
  ratingDetails: state.competitors.rating_details,
  competitorSourceOptions: state.competitors.competitor_source_options,
  businessDetails: state.profile.business_user,
});

CompetitorsPage.propTypes = {
  ratingOptions: PropTypes.instanceOf(Object),
  reviewSourceOptions: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    compActions: bindActionCreators(competitorsActions, dispatch),
    businessActions: bindActionCreators(businessActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CompetitorsPage);
