import React, { useState } from "react";
import { Box } from "@material-ui/core";
import "./central-branch.css";
import { FPButton } from "../common/new";
import ConfirmationModal from "../common/ConfirmationModal";
import { FaPlus } from "react-icons/fa6";
import LoaderComponent from "../common/loaderComponent";
import TicketAutoResolutionForm from "./TicketAutoResolutionForm";

function TicketAutoResolution(props) {
  const {
    ticketEscalationList,
    handleEscalationOpen,
    handleEscalationClose,
    addTicketEscalation,
    editTicketEscalation,
    deleteTicketEscalation,
    designation,
    openServiceEscalationForm,
    loader,
  } = props;

  const [edit, setEdit] = useState(null);
  const [escalationId, setEscalationId] = useState(1);
  const [isConfirm, setIsConfirm] = useState(false);
  const [escalationData, setEscalationData] = useState(null);

  const editHandler = (data, id) => {
    setEdit(data); // Set the item to edit
    setEscalationId(id); // Set the escalation ID
    handleEscalationOpen(); // Open the form
  };

  const addHandler = () => {
    setEdit(null); // Clear edit data for "Add" action
    setEscalationId(ticketEscalationList?.results?.length + 1 || 1); // Set new escalation ID
    handleEscalationOpen(); // Open the form
  };

  const toggleConfirm = () => {
    deleteTicketEscalation(escalationData);
    setIsConfirm(false);
  };

  const handleClick = (data) => {
    setEscalationData(data);
    setIsConfirm(!isConfirm);
  };

  return (
    <div className="mt-4">
      <LoaderComponent loader={loader} />

      {/* Hide this block when openServiceEscalationForm is true */}
      {!openServiceEscalationForm && (
        <div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="py-5">
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  color: "rgba(100, 101, 101, 1)",
                }}
              >
                No Auto Resolution Added
              </span>
              <FPButton
                className="d-flex mt-10"
                fullWidth
                label="Add Auto Resolution"
                fontWeight="400"
                fontSize={16}
                onClick={addHandler} // Call the Add handler
                endIcon={<FaPlus style={{ fontSize: "14px" }} />}
              />
            </div>
          </div>

          {ticketEscalationList?.results?.length === 0 && (
            <div>
              <span className="escalation-title">Auto Resolution</span>
              <Box className="border mt-3 p-3" sx={{ borderRadius: "8px" }}>
                <div className="d-block gap-30">
                  <span className="escalationText">
                    If ticket is open for more than
                  </span>
                  <span className="escalationColorText mx-4">24 Hours</span>
                  <span className="escalationText">
                    , then Resolve/Close the Ticket{" "}
                  </span>
                </div>
              </Box>
              <div className="mt-3 d-flex justify-content-end gap-20">
                <FPButton
                  size="small"
                  variant="outline"
                  label="Delete"
                  onClick={() => handleClick({ id: 1 })} // Example data
                />
                <FPButton
                  type="submit"
                  size="small"
                  label="Edit"
                  onClick={() => editHandler({ id: 1 }, 1)} // Pass example data for edit
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* Show the form when openServiceEscalationForm is true */}
      {openServiceEscalationForm && (
        <TicketAutoResolutionForm
          escalationId={
            edit ? escalationId : ticketEscalationList?.results?.length + 1 || 1
          }
          designation={designation}
          addTicketEscalation={addTicketEscalation}
          editTicketEscalation={editTicketEscalation}
          handleEscalationClose={() => {
            setEdit(null); // Reset edit state
            handleEscalationClose(); // Close the form
          }}
          onApply={() => {
            setEdit(null); // Reset edit state after apply
            handleEscalationClose(); // Close the form
          }}
          initialData={edit} // Pass the data to edit
          escalationLength={ticketEscalationList?.results?.length}
        />
      )}

      {/* Confirmation Modal */}
      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={`Are you sure you want to delete this Escalation Rule?`}
        isOpen={isConfirm}
        toggle={() => setIsConfirm(!isConfirm)}
        onConfirm={toggleConfirm}
      />
    </div>
  );
}

export default TicketAutoResolution;
