import React from "react";
import { Breadcrumbs, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    textDecoration: "none",
    color: "#1B1C1D",
    "&:hover": {
      color: "#F13A88",
      textDecoration: "underline",
    },
    cursor: 'pointer'
  },
}));

const FPBreadcrumbs = ({ list, className }) => {
  const classes = useStyles();

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon fontSize="small" style={{ color: "#A4A5A6" }} />
      }
      className={classNames(className)}
    >
      {list.map(
        (item, index) =>
          index !== list.length - 1 &&
          (!!item.onClick ? (
            <span className={classes.root} key={`${index}-${item.link}`} onClick={item.onClick}>
              {item.label}
            </span>
          ) : (
            <Link
              className={classes.root}
              to={item.link}
              key={`${index}-${item.link}`}
            >
              {item.label}
            </Link>
          ))
      )}
      <Typography className="font-primary">
        {list[list.length - 1].label}
      </Typography>
    </Breadcrumbs>
  );
};

export default FPBreadcrumbs;

FPBreadcrumbs.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired,
    })
  ).isRequired,
};
