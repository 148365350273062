import * as types from "../../actions/actionTypes";

export default function reviewEmailList(
  state = [],
  action
) {
  switch (action.type) {
    case types.REVIEW_EMAIL_LIST_SUCCESS:
      return Object.assign({}, state, {reviewEmailList: {...action.data}});
    case types.REVIEW_EMAIL_DETAILS_SUCCESS:
      return Object.assign({}, state, {reviewEmailDetails: {...action.data}});
    case types.SEND_EMAIL_SUCCESS:
      return Object.assign({}, state, {sendEmail: {...action.data}});
    default:
      return state;
  }
}
