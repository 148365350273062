import axios_instance from '../../libs/interseptor';
import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';
import * as utils from '../../libs/utils';

import * as commonAction from '../common/commonAction';

export function templateListSuccess(data) {
  return { type: types.TEMPLATE_LIST_SUCCESS, data};
}

export function templateListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.TEMPLATE_LIST_FAILURE, data };
}

export const templateList = (parameters) => {
  const { branch_id, ...params } = parameters;
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.TEMPLATE_LIST,[branch_id]), { params })
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(templateListSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(templateListFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getTemplateDetailsSuccess(data) {
  return { type: types.GET_TEMPLATE_DETAILS_SUCCESS, data };
}

export function getTemplateDetailsFailure(data) {
  return { type: types.GET_TEMPLATE_DETAILS_FAILURE, data };
}

export const getTemplateDetails = (parameters) => {
  const { branch_id, id } = parameters;
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.TEMPLATE_DETAILS,[branch_id,id]))
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getTemplateDetailsSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getTemplateDetailsFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function postTemplateSuccess(data) {
  return { type: types.POST_TEMPLATE_SUCCESS, data };
}

export function postTemplateFailure(data) {
  return { type: types.POST_TEMPLATE_FAILURE, data };
}

export const postTemplate = (data, branch_id) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    if (data.id) {
      return axios_instance.put(utils.format(URLS.TEMPLATE_DETAILS,[branch_id,data.id]), data)
        .then(
          (res) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postTemplateSuccess(res.data));
            return res;
          }
        )
        .catch(
          (err) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postTemplateFailure(err));
            throw err.response.data;
          }
        );
    } else {
      return axios_instance.post(utils.format(URLS.TEMPLATE_LIST,[branch_id]), data)
        .then(
          (res) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postTemplateSuccess(res.data));
            return res;
          }
        )
        .catch(
          (err) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postTemplateFailure(err));
            throw err.response.data;
          }
        );
    }
  };
};

export function templateDeleteSuccess(data) {
  return { type: types.TEMPLATE_DELETE_SUCCESS, data};
}

export function templateDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.TEMPLATE_DELETE_FAILURE, data };
}

export const templateDelete = (data, branch_id) => {

  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.delete(utils.format(URLS.TEMPLATE_DETAILS,[branch_id,data.id]), data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(templateDeleteSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(templateDeleteFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function templateCloneSuccess(data) {
  return { type: types.TEMPLATE_CLONE_SUCCESS, data};
}

export function templateCloneFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.TEMPLATE_CLONE_FAILURE, data };
}

export const templateClone = (data, branch_id) => {

  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(utils.format(URLS.TEMPLATE_CLONE,[branch_id,data.id]), data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(templateCloneSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(templateCloneFailure(err));
          throw err.response.data;
        }
      );
  };
};
