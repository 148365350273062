import React from "react";
import PropTypes from "prop-types";
import { Oval } from "react-loader-spinner";

const LoaderComponent = (props) => {
  const { showLoader, height, width } = props;
  return (
    <div className="messenger-loader">
      {showLoader ? (
        <Oval
          height={height || 40}
          width={width || 40}
          color="#e12369"
          wrapperStyle={{}}
          wrapperClass="loaderrr"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#e12369"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : null}
    </div>
  );
};

LoaderComponent.propTypes = {
  showLoader: PropTypes.bool.isRequired,
};
export default LoaderComponent;
