import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import FPLabel from "../../../../components/common/new/forms/ui/FPLabel";

const FeedbackNpsNumber = ({ onNpsChange, value, error, title, showLabel = true }) => {
  const [selectedScore, setSelectedScore] = useState(value || null);

  useEffect(() => {
    if (value !== undefined) setSelectedScore(value);
  }, [value]);

  const handleSelect = (num) => {
    setSelectedScore(num);
    if (onNpsChange) onNpsChange(num);
  };

  const getColor = (num) => {
    if (num <= 6) return "#FF0302"; // Red
    if (num <= 8) return "#FFE70D"; // Yellow
    return "#039855"; // Green
  };

  const getLabel = (num) => {
    if (num <= 6) return "Not Recommended";
    if (num <= 8) return "Neutral";
    return "Highly Recommended";
  };

  return (
    <>
    {title && (
      <FPLabel className="mb-3">
        {title}
      </FPLabel>
    )}
    <Box display="flex" flexDirection="column" mt={2} alignItems="center">
      <Box 
        sx={{
          display: 'flex',
          width: '100%',
          maxWidth: 700,
          border: '1px solid #e0e0e0',
          borderRadius: '5px',
          overflow: 'hidden',
          boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
        }}
      >
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => {
          const color = getColor(num);
          const isSelected = selectedScore === num;

          return (
            <Box
              key={num}
              onClick={() => handleSelect(num)}
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                cursor: "pointer",

                borderRight: num < 10 ? '1px solid #e0e0e0' : 'none',
                backgroundColor: isSelected ? '#f5f5f5' : 'transparent',
                transition: "all 0.2s ease",
                "&:hover": { 
                  backgroundColor: "#f9f9f9"
                },
              }}
            >
              <Typography 
                variant="body1" 
                sx={{ 
                  background:"#F0F1F3",
                  width:"100%",
                  textAlign:"center",
                  padding:"5px 0px  "
                }}
              >
                {num}
              </Typography>
              
              <Box
                sx={{
                  width: { xs: 16, md: 18 },
                height: { xs: 16, md: 18 },
                  borderRadius: '50%',
                  border: `2px solid ${isSelected ? "#fff" : color}`,
                  backgroundColor: isSelected ? color : 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  boxShadow: `0 0 8px ${color}`,
                  transition: "all 0.3s ease",
                  margin:"5px"
                }}
              />
            </Box>
          );
        })}
      </Box>
      
      {showLabel && selectedScore !== null && (
        <Box 
          mt={2}
          px={2}
          py={0.5}
          sx={{
            backgroundColor: getColor(selectedScore),
            color: "white",
            borderRadius: "4px",
            transition: "0.3s",
          }}
        >
          <Typography variant="body2">{getLabel(selectedScore)}</Typography>
        </Box>
      )}
      
      {selectedScore  == null && showLabel && (
        <Box display="flex" justifyContent="space-between" width="100%" mt={1}>
          <Typography variant="caption" color="textSecondary">
            Not Recommended
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Highly Recommended
          </Typography>
        </Box>
      )}
      
      {error && (
        <Typography color="error" mt={1}>
          {error}
        </Typography>
      )}
    </Box>
    </>
  );
};

export default FeedbackNpsNumber;