import React, { useEffect, useState } from "react";
import CustomStarRatingComponent from "../../common/StarComponent";
import verified_svg from "../../../images/verified.svg";
import { ListDescription } from "semantic-ui-react";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import { Chip } from "@mui/material";
import "./listing.css";
import select_svg from "../../../images/select.svg";
import { formatReviews } from "../../../libs/helper";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import google_svg from "../../../images/googlephoto.svg";
import duplicate_svg from "../../../images/dusplicate.svg";
import * as listingUtils from "../../../libs/listingToast";

const UpdateAttribute = (props) => {
  const { business_id, selectReso, primaryDetail, actions, attribute_data } = props;

  const [selectedItems, setSelectedItems] = useState({});
  const [beforeSelectedItems, setBeforeSelectedItems] = useState([]);
  const [afterSelectedItems, setAfterSelectedItems] = useState({});

  useEffect(() => {
    if (attribute_data) {
      const updatedAttributeData = JSON.parse(JSON.stringify(attribute_data));
      Object.keys(updatedAttributeData)?.forEach((attribute) => {
        updatedAttributeData[attribute]?.forEach((item) => {
          if (item.valueType === "REPEATED_ENUM") {
            const selectedValues = item.selected_data?.repeatedEnumValue?.setValues || [];
            item.valueMetadata.forEach((enumItem) => {
              enumItem.selected = selectedValues.includes(enumItem.value);
              enumItem.originalSelecte = enumItem.selected;
            });
          } else if (item.valueType === "ENUM") {
            const selectedValues = item.selected_data?.values || [];
            item.valueMetadata.forEach((enumItem) => {
              enumItem.selected = selectedValues.includes(enumItem.value);
              enumItem.originalSelecte = enumItem.selected;
            });
          } else if (item.valueType === "BOOL") {
            item.originalSelecte = item.selected;
          }
        });
      });
      console.log(updatedAttributeData, "updatedAttributeData");
      setSelectedItems(updatedAttributeData);
      setAfterSelectedItems(updatedAttributeData);
    }
  }, [attribute_data]);

  const handleChipClickService = (group, index, repeatedEnumIndex = null) => {
    const updatedItems = { ...afterSelectedItems };
    console.log("groupgroupgroupgroupgroupgroupgroupgroup", group);
    console.log("indexindexindexindexindexindexindexindex", index);
    if (repeatedEnumIndex !== null) {
      const item = updatedItems[group][index];
      console.log("itemitemitemitemitemitemitemitemitemitemitemitemitemitem", item);
      const repeatedEnum = item.valueMetadata[repeatedEnumIndex];
      console.log("repeatedEnumrepeatedEnumrepeatedEnum", repeatedEnum);
      repeatedEnum.selected = !repeatedEnum.selected;
      item.originalSelecte = true;
      item.valueMetadata[repeatedEnumIndex] = repeatedEnum;
    } else {
      const item = updatedItems[group][index];

      // Toggle the selection state for non-repeated items
      item.selected = !item.selected;

      // Update the selected_data field
      item.selected_data = {
        name: item.parent,
        values: [!item?.selected_data?.values?.[0]],
        valueType: item.valueType,
      };
    }

    setAfterSelectedItems(updatedItems);
  };

  const handleSingleSelectChipClick = (group, index, repeatedEnumIndex, repeatedEnumData) => {
    //only single select chip
    const updatedItems = { ...afterSelectedItems };
    const item = updatedItems[group][index];
    const repeatedEnum = item.valueMetadata[repeatedEnumIndex];
    updatedItems[group][index].valueMetadata = updatedItems[group][index].valueMetadata.map((enumItem) => ({
      ...enumItem,
      selected: false,
    }));
    repeatedEnum.selected = !repeatedEnum.selected;
    updatedItems[group][index].valueMetadata[repeatedEnumIndex] = repeatedEnum;
    setAfterSelectedItems(updatedItems);
  };

  const addressString = [primaryDetail?.addressLines, primaryDetail?.locality, primaryDetail?.postalCode].join(", ");

  useEffect(() => {
    actions.getGmbListingAttribute(business_id, selectReso);
  }, [actions, business_id, selectReso]);

  const renderChip = (item, idx, attribute, handleChipClickService, select_svg) => (
    <Chip
      key={idx}
      label={item.displayName}
      onClick={() => handleChipClickService(attribute, idx)}
      deleteIcon={item.selected_data?.values[0] ? <img src={select_svg} alt="selectImage" /> : null}
      onDelete={item.selected_data?.values[0] ? () => handleChipClickService(attribute, idx) : null}
      variant={item.selected_data?.values[0] ? "filled" : "outlined"}
      sx={{
        backgroundColor: item.selected_data?.values[0] ? "#bfe0e2" : "#F0F1F3",
        color: item.selected_data?.values[0] ? "#00b5ad" : "default",
        "& .MuiChip-deleteIcon": {
          color: item.selected_data?.values[0] ? "white" : "default",
        },
        fontSize: "12px",
        fontWeight: "400",
        padding: "2px 5px",
        border: "0",
      }}
    />
  );

  const renderRepeatedEnumChip = (
    repeatedEnum,
    indexenum,
    attribute,
    parentIndex,
    handleChipClickService,
    select_svg,
    valueType
  ) => (
    <Chip
      key={indexenum}
      label={repeatedEnum.displayName}
      onClick={() =>
        valueType === "ENUM"
          ? handleSingleSelectChipClick(attribute, parentIndex, indexenum, repeatedEnum)
          : handleChipClickService(attribute, parentIndex, indexenum)
      }
      deleteIcon={repeatedEnum.selected ? <img src={select_svg} alt="selectImage" /> : null}
      onDelete={repeatedEnum.selected ? () => handleChipClickService(attribute, parentIndex, indexenum) : null}
      variant={repeatedEnum.selected ? "filled" : "outlined"}
      sx={{
        backgroundColor: repeatedEnum.selected ? "#bfe0e2" : "#F0F1F3",
        color: repeatedEnum.selected ? "#00b5ad" : "default",
        "& .MuiChip-deleteIcon": {
          color: repeatedEnum.selected ? "white" : "default",
        },
        fontSize: "12px",
        fontWeight: "400",
        padding: "2px 5px",
        border: "0",
        marginBottom: "15px",
        marginRight: "15px",
      }}
    />
  );

  const generatePayload = (items) => {
    const payload = [];

    Object.keys(items).forEach((attribute) => {
      items[attribute].forEach((item) => {
        if (!(item.selected === false && item.originalSelecte === false)) {
          if (item.valueType === "ENUM") {
            const enumValues = item.valueMetadata
              .filter((enumItem) => enumItem.selected)
              .map((enumItem) => enumItem.value);
            enumValues?.length > 0 &&
              payload.push({
                name: item.parent,
                valueType: item.valueType,
                values: enumValues,
              });
          } else if (item.valueType === "REPEATED_ENUM") {
            const selectRepeatEmum = item.valueMetadata.filter((enumItem) => enumItem.selected);
            if (selectRepeatEmum?.length > 0) {
              payload.push({
                name: item.parent,
                valueType: item.valueType,
                repeatedEnumValue: {
                  setValues: item.valueMetadata
                    .filter((enumItem) => enumItem.selected)
                    .map((enumItem) => enumItem.value),
                  unsetValues: item.valueMetadata
                    .filter((enumItem) => !enumItem.selected)
                    .map((enumItem) => enumItem.value),
                },
              });
            }
          } else if (item.valueType === "BOOL") {
            // Here you can access the `selected_data[0].value` as item.selected_data.values[0]
            payload.push({
              name: item.parent,
              valueType: item.valueType,
              values: [item.selected_data?.values[0]], // Accessing the selected value
            });
          }
        }
      });
    });

    return payload;
  };

  const updateAttributeHandler = async () => {
    const payload = generatePayload(afterSelectedItems);
    const restodetail = {
      business_id,
      selectReso,
    };

    console.log("payload payload", payload);
    await actions.getGmbListingUpdateListing(
      { attributes: payload, type: "updateAttributes" },

      restodetail
    );
    listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    await actions.getGmbListingRefreshListing(business_id, selectReso);

    listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    listingUtils?.displayMessage("positive", "Listing updated successfully.");
    await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };

  return (
    <>
      <div className="listing-container" style={{ overflow: "hidden", gap: "15px" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          <div className="listing-header">
            <div className="listing-info">
              <div style={{ display: "flex", gap: "8px" }}>
                <div className="listing-name">{primaryDetail?.title}</div>
                <img
                  src={
                    primaryDetail?.is_varified
                      ? verified_svg
                      : primaryDetail?.is_suspend
                      ? duplicate_svg
                      : primaryDetail?.is_duplicate
                      ? duplicate_svg
                      : ""
                  }
                  alt="verifiedImage"
                />
              </div>
              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{formatReviews(primaryDetail?.totalReviewCount)} Reviews</span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>{addressString}</ListDescription>
          </div>
        </div>
      </div>
      <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
        <div className="UpdateScrollHeightSection">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              width: "calc(100% - 120px)",
            }}
          >
            <div
              className="d-flex flex-column"
              style={{
                display: "grid",
                gap: "10px",
                paddingBottom: "5px",
              }}
            >
              <div className="listing-name">Select attributes which best describe your business</div>
              {Object.keys(selectedItems || {}).map((attribute, index) => {
                if (attribute !== "Place page URLs") {
                  const items = selectedItems[attribute];
                  return (
                    <div
                      key={index}
                      style={{
                        backgroundColor: "#F6F6F8",
                        borderRadius: "8px",
                        padding: "10px",
                      }}
                    >
                      <div style={{ display: "grid", gap: "8px" }}>
                        <div
                          style={{
                            fontWeight: "400",
                            fontSize: "13px",
                            color: "#1B1C1D",
                          }}
                        >
                          {attribute}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          {items?.map((item, idx) => {
                            if (
                              // attribute === "Payments" &&
                              item.valueType === "ENUM" ||
                              item.valueType === "REPEATED_ENUM"
                            ) {
                              return (
                                <div className="enumOption">
                                  <p style={{ fontWeight: "400", fontSize: "16px", color: "#1B1C1D" }}>
                                    {item?.displayName}
                                  </p>
                                  {Array.isArray(item?.valueMetadata)
                                    ? item.valueMetadata.map((repeatedEnum, indexenum) =>
                                        renderRepeatedEnumChip(
                                          repeatedEnum,
                                          indexenum,
                                          attribute,
                                          idx,
                                          handleChipClickService,
                                          select_svg,
                                          item?.valueType
                                        )
                                      )
                                    : null}
                                </div>
                              );
                            }
                            // else if (item.valueType === "ENUM") {
                            //   return Array.isArray(item?.valueMetadata)
                            //     ? item.valueMetadata.map(
                            //         (repeatedEnum, indexenum) =>
                            //           renderRepeatedEnumChip(
                            //             repeatedEnum,
                            //             indexenum,
                            //             attribute,
                            //             idx,
                            //             handleChipClickService,
                            //             select_svg
                            //           )
                            //       )
                            //     : null;
                            // }
                            if (item.valueType !== "URL") {
                              return renderChip(item, idx, attribute, handleChipClickService, select_svg);
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "10px",
              }}
            >
              <FPButton
                label="Update"
                size="small"
                onClick={updateAttributeHandler}
                disabled={primaryDetail?.is_suspend}
              />
            </div>
          </div>
          <div className="RightImageSectionDialog">
            <img src={google_svg} alt="verifiedImage" />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    attribute_data: state.gmbListingLocation.attribute_data,
    attribute_error: state.gmbListingLocation.attribute_error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // getGmbListingUpdateListing: bindActionCreators(
  //   getGmbListingUpdateListing,
  //   dispatch
  // ),
  actions: bindActionCreators(locationActions, dispatch),
});

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators(locationActions, dispatch),
// });

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAttribute);
