import React from "react";

const PhotosMenu = () => {
  return (
    <div className="mt-4">
      <h1>Photos Menu Page</h1>
    </div>
  );
};

export default PhotosMenu;
