import React from "react";
import BulkMenuFilter from "./BulkMenuFilter";
import RootHeader from "../../../../modules/listing/bulkupdate/RootHeader";
import * as setSelectedTab from "../../../../actions/common/setSelectedTabAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MenuRootHeader from "./bulkMenu/RootHeader";
import LoaderComponent from "../../../../components/common/loaderComponent";
import * as locationActions from "../../../../actions/listing/listingLocationAction";
import queryString from "query-string";

class BulkMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryStringData: [],
      nextSearch: [],
      prevSearch: [],
    };
  }

  componentDidMount() {
    this.props.actionsSelectTab.setSelectedTab(2);
    document.title = "listing-bulk";
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;

    this.props.actions.getGmbListingLoaction(business_id, "", "verified", "", "", "", "", "food_menu"); // Replace 'yourProvider' with the actual provider
  }

  componentWillReceiveProps = (nextProps) => {
    const {
      location: { search: prevSearch },
    } = this.props;
    this.setState({ prevSearch: prevSearch });
    const {
      match: {
        params: { business_id },
      },
      location: { search: nextSearch },
    } = nextProps;
    this.setState({ nextSearch: nextSearch });
    if (prevSearch !== nextSearch) {
      const queryParams = queryString.parse(nextSearch);
      this.setState({ queryStringData: queryParams });
      this.props.actions.getGmbListingLoaction(
        business_id,
        queryParams?.gmb_location_type,
        "verified",
        queryParams?.completion_status,
        queryParams?.openstatus,
        queryParams?.search,
        "",
        "food_menu"
      );
    }
  };

  render() {
    const {
      loader,
      match: {
        params: { business_id },
      },
      locationList,
      filterBusinessCount,
      filter,
    } = this.props;
    const { queryStringData, nextSearch, prevSearch } = this.state;

    const formattedAccountData = (filter?.account_date_type?.results || []).map((item) => ({
      value: item.id,
      display_name: item.account_name,
      ...item,
    }));

    return (
      <>
        <LoaderComponent loader={loader} />
        <div style={{ padding: "16px" }}>
          <RootHeader defaultValue={3} history={this.props.history} />

          <div>
            <BulkMenuFilter
              history={this.props.history}
              location={this.props.location}
              business_id={business_id}
              formattedAccountData={formattedAccountData}
            />
          </div>
          <div>
            <div className="d-flex align-baseline gap-10" style={{ padding: "15px 0px 10px 0px" }}>
              <h2
                style={{
                  color: "#1A2544",
                  fontSize: "18px",
                  fontWeight: "500",
                  margin: "0",
                }}
              >
                Bulk Menu Update{" "}
              </h2>
              <h3
                style={{
                  color: "#F13A88",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                ({filterBusinessCount} Total listings)
              </h3>
            </div>
            {/* <BulkMuneList /> */}
            <MenuRootHeader
              defaultValue={0}
              business_id={business_id}
              locationList={locationList}
              queryStringData={queryStringData}
              filterBusinessCount={locationList ? locationList?.[0]?.verified_locations : 0}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locationList: state.gmbListingLocation.location_list,
    filterBusinessCount: state.gmbListingLocation.filterBusinessCount,
    loader: state.common.loader,
    filter: state.filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkMenu);
