import React, { useState } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { Table, TableBody, TableCell, TableHead, TableRow, Box } from "@material-ui/core";
import SortIcon from "../../images/svg/Sort.svg";
import { FPToolTip } from "../common/new";
import "./SmartResponseList.module.css";
import "./SmartResponse.css";
import editIcon from "../../images/svg/EditPen.svg";
import deleteIcon from "../../images/svg/deleteSvg.svg";
import ConfirmationModal from "../common/ConfirmationModal";
import { sortChangeColor } from "../../libs/utils";

function SmartReviewReply(props) {
  const { smartResponseOnCheck, ordering, smartResponseList, sortFunction, activeUser, businessId, deleteResponse } =
  props;
  const [toggle, setToggle] = useState("ascending");
  const [isConfirm, setIsConfirm] = useState(false);
  const [smartId, setSmartId] = useState(null);

  const toggleConfirm = () => {
    deleteResponse(smartId);
    setIsConfirm(false);
  };

  const handleClick = (id) => {
    setSmartId(id);
    setIsConfirm(!isConfirm);
  };

  const sort =
    ordering && ordering[0] === "-" ? { on: ordering.slice(1), by: "descending" } : { on: ordering, by: "ascending" };

  const sortCreatedAtFunction = () => {
    if (toggle === "ascending") {
      smartResponseList.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setToggle("descending");
    }
    if (toggle === "descending") {
      smartResponseList.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      setToggle("ascending");
    }
  };

  return (
    <div>
      {smartResponseOnCheck && (
        <Card body className="my-2">
          <div className="ui-table-responsive">
            <Table className="fp-table fp-table-center">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {/* <Checkbox label='S.No' id="all" className="d-flex">Select All</Checkbox> */}
                    S.No
                  </TableCell>
                  <TableCell style={{ textAlign: "justify" }}>Reply Title</TableCell>
                  <TableCell style={{ textAlign: "justify" }}>Reply Content</TableCell>

                  <TableCell
                    // sorted={sort.on === "review_type" ? sort.by : null}
                    className="text-nowrap"
                    onClick={() => sortFunction("review_type")}
                    style={
                      sort.on === "review_type"
                        ? {
                            ...sortChangeColor("#1f9095", "#d9f0f0"),
                          }
                        : null
                    }
                  >
                    Review Type
                    {sortFunction && sort.on !== "review_type" && <SortIcon />}
                  </TableCell>

                  <TableCell
                    onClick={() => sortFunction("content_choice")}
                    style={
                      sort.on === "content_choice"
                        ? {
                            ...sortChangeColor("#1f9095", "#d9f0f0"),
                          }
                        : null
                    }
                  >
                    Review Content Type
                    {sortFunction && sort.on !== "content_choice" && <SortIcon />}
                  </TableCell>

                  <TableCell
                    className="text-nowrap"
                    onClick={() => sortFunction("auto")}
                    style={
                      sort.on === "auto"
                        ? {
                            ...sortChangeColor("#1f9095", "#d9f0f0"),
                          }
                        : null
                    }
                  >
                    Auto Reply
                    {sortFunction && sort.on !== "auto" && <SortIcon />}
                  </TableCell>

                  <TableCell
                    className="text-nowrap"
                    // sorted={sort && sort.on === "created_at" ? sort.by : null}
                    onClick={() => sortCreatedAtFunction("created_at")}
                    style={
                      sort.on === "created_at"
                        ? {
                            ...sortChangeColor("#1f9095", "#d9f0f0"),
                          }
                        : null
                    }
                  >
                    Created At
                    {sortFunction && sort.on !== "created_at" && <SortIcon />}
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {smartResponseList && smartResponseList.length ? (
                  smartResponseList.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <span className="tableSpecificDataBold d-flex">{/* <Checkbox label={index + 1} /> */}</span>
                          <label className="text-center">{index + 1}</label>
                        </TableCell>
                        <TableCell>
                          {activeUser.is_business_owner ? (
                            <div className="d-flex">
                              <span
                                className="threeDot"
                                style={{
                                  width: "140px",
                                  color: "#007bff",
                                  textDecoration: "underline",
                                  textTransform: "capitalize",
                                  cursor:'pointer',
                                }}
                              >
                                <Link title={item.title} to={`/${businessId}/smart-response?id=${item.id}`}>
                                {item.title}
                              </Link>
                              </span>
                            </div>
                          ) : (
                            <label className="width-md">{item.title}</label>
                          )}
                        </TableCell>
                        <TableCell className="width-md">
                          <FPToolTip title={item.content}>
                            <div
                              style={{
                                width: "160px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                                textAlign: "justify",
                              }}
                            >
                              {item.content}
                            </div>
                          </FPToolTip>
                        </TableCell>

                        <TableCell onClick={() => sortFunction("review_type")}>
                          <span style={sort.on === "review_type" ? sortChangeColor("#1f9095") : null}>
                            {item.review_type}
                          </span>
                        </TableCell>

                        <TableCell onClick={() => sortFunction("content_choice")}>
                          <span style={sort.on === "content_choice" ? sortChangeColor("#1f9095") : null}>
                            {item.content_choice == "" ? "Not Selected" : item.content_choice}
                          </span>
                        </TableCell>

                        <TableCell onClick={() => sortFunction("auto")}>
                          <span style={sort.on === "auto" ? sortChangeColor("#1f9095") : null}>
                            {item.auto ? "On" : "Off"}
                          </span>
                        </TableCell>

                        <TableCell onClick={() => sortCreatedAtFunction("created_at")}>
                          <span style={sort.on === "created_at" ? sortChangeColor("#1f9095") : null}>
                            {moment(item.created_at).format("DD-MM-YYYY")}
                          </span>
                        </TableCell>

                        <TableCell>
                          <div className="actionButton">
                            {activeUser.is_business_owner ? (
                              <Link title={item.title} to={`/${businessId}/smart-response?id=${item.id}`}>
                                <FPToolTip title={"Edit"}>
                                  <img src={editIcon} role="button" alt="edit" />
                                </FPToolTip>
                              </Link>
                            ) : (
                              <Box></Box>
                            )}

                            <FPToolTip title={"Delete"}>
                              <img src={deleteIcon} role="button" alt="delete" onClick={() => handleClick(item.id)} />
                            </FPToolTip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell className="text-center" colSpan={4}>
                      No records Found{" "}
                    </TableCell>
                  </TableRow>
                )}
                {/* Both */}
                {/* {!smartResponseList.length && (
                <tr>
                  <td colSpan={5} className="text-center">
                    No Records Found
                  </td>
                </tr>
              )} */}
              </TableBody>
            </Table>
          </div>
        </Card>
      )}
      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={`Are you sure you want to delete this smart reply?`}
        isOpen={isConfirm}
        toggle={() => setIsConfirm(!isConfirm)}
        onConfirm={() => toggleConfirm()}
      />
    </div>
  );
}

export default SmartReviewReply;
