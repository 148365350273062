import * as types from '../../actions/actionTypes';
import cookie from 'react-cookies';

export default function profileReducer(state=[], action) {
  switch (action.type) {
    case types.PROFILE_INFO_SUCCESS:
      cookie.save('user', action.data, 
        { 
          path: '/',
          maxAge: 365 * 24 * 60 * 60,
        }
      );
      return Object.assign({}, state, {'user': action.data });
    case types.PROFILE_INFO_FAILURE:
      return action.data;
    case types.BUSINESS_PROFILE_SUCCESS:
      cookie.save('business_user', action.data, 
        { 
          path: '/',
          maxAge: 365 * 24 * 60 * 60,
        }
      );
      const user = cookie.load("user")
      const business_ids = cookie.load("business_ids");
      if(!business_ids && user.business && !user.marketing_id) {
        let business_ids = []
        business_ids.push(String(user.business))
        cookie.save('business_ids', business_ids,
          { 
            path: '/',
            maxAge: 365 * 24 * 60 * 60,
          }
        );
      }
      return Object.assign({}, state, {'business_user': action.data });
    case types.BUSINESS_PROFILE_FAILURE:
      return action.data;
    case types.REMOVE_BUSINESS_PROFILE:
      cookie.remove('business_user',{ path: '/'});
      return Object.assign({},state,{'business_user': null});
    default:
      return state;
  }
}
