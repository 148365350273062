import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import './style.css';
import { Divider, MenuItem } from '@material-ui/core';

const StyledMenuItem = withStyles({
  root: {
    padding: '10px 15px',
    color: '#646565',
  },
})((props) => <MenuItem className="fp-menuItem" {...props} />);

const FPMenuItem = ({ children, showDivider = true, ...rest }) => {
  return (
    <React.Fragment>
      <StyledMenuItem {...rest}>
        {children}
      </StyledMenuItem>
      {showDivider && <Divider className="" />}
    </React.Fragment>
  );
};

export default FPMenuItem;
