import React, { useEffect, useState } from "react";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
import { Popover } from "@material-ui/core";
import { Checkbox, } from "semantic-ui-react";
import { FPButton } from "../../common/new";
import { FaCheck } from "react-icons/fa6";
import axios_instance from "../../../libs/interseptor";
import * as URLS from "../../../libs/apiUrls";
import * as utils from "../../../libs/utils";
import { displayMessage } from "../../../libs/utils";
import LoaderComponent from "../../messenger/Common/LoaderComponent";
import BoxStockImg from "../../../images/box-stock.png";
import { IoIosArrowDown } from "react-icons/io";

const PopOverComponent = ({
  mainValue,
  open,
  anchorEl,
  handleClose,
  data,
  handleMainValue,
}) => {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose()}
      anchorOrigin={{ vertical: "bottom", horizontal: "bottom" }}
      transformOrigin={{ vertical: "top", horizontal: "bottom" }}
      PaperProps={{
        style: { borderRadius: 8, width: "90px", margin: 0, padding: 0 },
      }}
      className="Report-Filter-Popup"
    >
      <div
        style={{
          margin: 0,
          justifyContent: "center",
          maxHeight: "250px",
          height: "auto",
          overflowY: "auto",
        }}
      >
        {data.map((val) => {
          return (
            <span key={val}>
              <span
                className="mx-2 popover-menu-stock"
                style={{}}
                onClick={() => handleMainValue(val)}
              >
                <span>{val}</span>
                {mainValue === val && (
                  <FaCheck style={{ color: "deeppink", fontSize: "16px" }} />
                )}
              </span>
            </span>
          );
        })}
      </div>
    </Popover>
  );
};

function OfflineStore({ businessId }) {
  const [check, setCheck] = useState(false);
  const [loader, setLoader] = useState(false);
  const [preferTimeOne, setPreferTimeOne] = useState({
    openHour: false,
    hour: "12",
    minute: "00",
    openMinute: false,
    period: "PM",
    openPeriod: false,
    anchorElHour: null,
    anchorElMinute: null,
    anchorElPeriod: null,
    id: null,
  });

  const [preferTimeTwo, setPreferTimeTwo] = useState({
    openHour: false,
    hour: "03",
    minute: "00",
    openMinute: false,
    period: "PM",
    openPeriod: false,
    anchorElHour: null,
    anchorElMinute: null,
    anchorElPeriod: null,
    id: null,
  });

  const [preferTimeThree, setPreferTimeThree] = useState({
    openHour: false,
    hour: "06",
    minute: "00",
    openMinute: false,
    period: "PM",
    openPeriod: false,
    anchorElHour: null,
    anchorElMinute: null,
    anchorElPeriod: null,
    id: null,
  });

  const [preferTimeFour, setPreferTimeFour] = useState({
    openHour: false,
    hour: "09",
    minute: "00",
    openMinute: false,
    period: "PM",
    openPeriod: false,
    anchorElHour: null,
    anchorElMinute: null,
    anchorElPeriod: null,
    id: null,
  });

  const hourData = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  let minuteData = ["00", "15", "30", "45"];

  useEffect(() => {
    setLoader(true);
    axios_instance
      .get(utils.format(URLS.OFFLINE_STOCK_ITEM, [businessId]))
      .then((res) => {
        setLoader(false);
        if (Array.isArray(res.data) && res.data.length === 0) {
          setCheck(false);
        } else {
          setCheck(true);
          const convertToString = (val) => {
            if (val < 10) {
              return "0" + val;
            }
            return val;
          };
          Array.isArray(res.data) &&
            res.data.map((val, index) => {
              if (index === 0) {
                setPreferTimeOne({
                  ...preferTimeOne,
                  hour: convertToString(val.hours),
                  minute: convertToString(val.minutes),
                  period: val.period,
                  id: val.id,
                });
              } else if (index === 1) {
                setPreferTimeTwo({
                  ...preferTimeTwo,
                  hour: convertToString(val.hours),
                  minute: convertToString(val.minutes),
                  period: val.period,
                  id: val.id,
                });
              } else if (index === 2) {
                setPreferTimeThree({
                  ...preferTimeThree,
                  hour: convertToString(val.hours),
                  minute: convertToString(val.minutes),
                  period: val.period,
                  id: val.id,
                });
              } else {
                setPreferTimeFour({
                  ...preferTimeFour,
                  hour: convertToString(val.hours),
                  minute: convertToString(val.minutes),
                  period: val.period,
                  id: val.id,
                });
              }
              return val;
            });
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, []); // eslint-disable-line

  const handleCheckOff = () => {
    if (check) {
      axios_instance
        .delete(utils.format(URLS.OFFLINE_STOCK_ITEM, [businessId]))
        .then((res) => {
          setCheck(!check);
          setPreferTimeOne({
            openHour: false,
            hour: "12",
            minute: "00",
            openMinute: false,
            period: "PM",
            openPeriod: false,
            anchorElHour: null,
            anchorElMinute: null,
            anchorElPeriod: null,
            id: null,
          });
          setPreferTimeThree({
            openHour: false,
            hour: "03",
            minute: "00",
            openMinute: false,
            period: "PM",
            openPeriod: false,
            anchorElHour: null,
            anchorElMinute: null,
            anchorElPeriod: null,
            id: null,
          });
          setPreferTimeTwo({
            openHour: false,
            hour: "06",
            minute: "00",
            openMinute: false,
            period: "PM",
            openPeriod: false,
            anchorElHour: null,
            anchorElMinute: null,
            anchorElPeriod: null,
            id: null,
          });
          setPreferTimeFour({
            openHour: false,
            hour: "09",
            minute: "00",
            openMinute: false,
            period: "PM",
            openPeriod: false,
            anchorElHour: null,
            anchorElMinute: null,
            anchorElPeriod: null,
            id: null,
          });
        })
        .catch((err) => {
          displayMessage("negative", "Failed to switch button");
        });
    } else {
      setCheck(!check);
    }
  };

  const handleSubmit = () => {
    let payload = [];
    payload.push({
      id: preferTimeOne.id,
      business_id: businessId,
      hours: parseInt(preferTimeOne.hour),
      minutes: parseInt(preferTimeOne.minute),
      period: preferTimeOne.period,
    });
    payload.push({
      id: preferTimeTwo.id,
      business_id: businessId,
      hours: parseInt(preferTimeTwo.hour),
      minutes: parseInt(preferTimeTwo.minute),
      period: preferTimeTwo.period,
    });
    payload.push({
      id: preferTimeThree.id,
      business_id: businessId,
      hours: parseInt(preferTimeThree.hour),
      minutes: parseInt(preferTimeThree.minute),
      period: preferTimeThree.period,
    });
    payload.push({
      id: preferTimeFour.id,
      business_id: businessId,
      hours: parseInt(preferTimeFour.hour),
      minutes: parseInt(preferTimeFour.minute),
      period: preferTimeFour.period,
    });

    axios_instance
      .post(utils.format(URLS.OFFLINE_STOCK_ITEM, [businessId]), payload)
      .then((res) => {
        displayMessage("positive", "Saved Succesfully");
      })
      .catch((err) => console.log(err));
  };

  const handleCancel = () => {
    setPreferTimeOne({
      openHour: false,
      hour: "12",
      minute: "00",
      openMinute: false,
      period: "PM",
      openPeriod: false,
      anchorElHour: null,
      anchorElMinute: null,
      anchorElPeriod: null,
      id: null,
    });
    setPreferTimeThree({
      openHour: false,
      hour: "03",
      minute: "00",
      openMinute: false,
      period: "PM",
      openPeriod: false,
      anchorElHour: null,
      anchorElMinute: null,
      anchorElPeriod: null,
      id: null,
    });
    setPreferTimeTwo({
      openHour: false,
      hour: "06",
      minute: "00",
      openMinute: false,
      period: "PM",
      openPeriod: false,
      anchorElHour: null,
      anchorElMinute: null,
      anchorElPeriod: null,
      id: null,
    });
    setPreferTimeFour({
      openHour: false,
      hour: "09",
      minute: "00",
      openMinute: false,
      period: "PM",
      openPeriod: false,
      anchorElHour: null,
      anchorElMinute: null,
      anchorElPeriod: null,
      id: null,
    });
  };

  const periodOption = ["AM", "PM"];

  return (
    <>
      <div
        style={{
          color: "#646565",
          fontSize: "20px",
          fontWeight: "400",
          margin: "10px",
        }}
      >
        Offline Store Alerts
      </div>
      <CardWrapper showDivider={false} contentClass="p-3">
        <div className="d-flex align-items-center justify-content-between">
          <span className="fp-size-14" style={{ color: "#1B1C1D" }}>
            Offline Store Alerts
          </span>
          <Checkbox
            toggle
            value="location"
            checked={check}
            onChange={(e) => handleCheckOff()}
          ></Checkbox>
        </div>

        {!check &&
          (loader ? (
            <div style={{ height: "120px", marginTop: "100px" }}>
              <LoaderComponent showLoader={loader} />
              <div
                className="fp-size-18 text-center mt-2"
                style={{ color: "gray" }}
              >
                {" "}
                &nbsp; &nbsp; Loading ...{" "}
              </div>
            </div>
          ) : (
            <div
              className="text-center fp-size-16 mb-4"
              style={{ color: "gray" }}
            >
              <div className="d-flex justify-content-center">
                <div
                  className="mt-4"
                  style={{
                    width: "100px",
                    background: "rgb(255 20 147 / 6%)",
                    borderRadius: "50%",
                  }}
                >
                  <div
                    style={{
                      background: "rgb(255 20 147 / 8%)",
                      width: "79px",
                      padding: "18px",
                      borderRadius: "50%",
                      margin: "10px",
                      fontWeight: "400",
                    }}
                  >
                    <img src={BoxStockImg} alt="box-stock" width={40} />
                  </div>
                </div>
              </div>
              <div className="mt-4">
                Monitor and Get Instant alerts for "Offline Store" of
              </div>
              <div>
                Aggregator Platforms &#40;Zomato, Swiggy etc.&#41; on
                pre-defined timings.
              </div>
            </div>
          ))}

        {check && (
          <div
            className="d-flex gap-15 my-4 align-items-center justify-content-between"
            style={{ fontSize: "15px", width: "100%", overflowY: "auto" }}
          >
            <div
              className="w-100"
              style={{ border: "2px solid #d3d3d3c2", borderRadius: "12px" }}
            >
              <div
                className="d-flex w-100 justify-content-between align-items-center"
                style={{
                  background: "whitesmoke",
                  padding: "14px 12px",
                  borderBottom: "2px solid #d3d3d3c2",
                  borderRadius: "12px 12px 0px 0px",
                }}
              >
                <span style={{ fontWeight: 600 }}>Preferred Time 1</span>
                <span
                  style={{
                    color: "deeppink",
                    background: "#ff14930d",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  {preferTimeOne.hour}:{preferTimeOne.minute} &nbsp;
                  {preferTimeOne.period}
                </span>
              </div>
              <div
                className="d-flex justify-content-between "
                style={{ padding: "12px 10px", gap: "6px" }}
              >
                {/* Hour */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeOne({
                      ...preferTimeOne,
                      openHour: !preferTimeOne.openHour,
                      anchorElHour: preferTimeOne.anchorElHour
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeOne.hour}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeOne.hour}
                  open={preferTimeOne.openHour}
                  anchorEl={preferTimeOne.anchorElHour}
                  handleClose={() =>
                    setPreferTimeOne({
                      ...preferTimeOne,
                      openHour: !preferTimeOne.openHour,
                      anchorElHour: null,
                    })
                  }
                  data={hourData}
                  handleMainValue={(val) =>
                    setPreferTimeOne({
                      ...preferTimeOne,
                      hour: val,
                      openHour: !preferTimeOne.openHour,
                      anchorElHour: null,
                    })
                  }
                />

                {/* Minute */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeOne({
                      ...preferTimeOne,
                      openMinute: !preferTimeOne.openMinute,
                      anchorElMinute: preferTimeOne.anchorElMinute
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeOne.minute}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeOne.minute}
                  open={preferTimeOne.openMinute}
                  anchorEl={preferTimeOne.anchorElMinute}
                  handleClose={() =>
                    setPreferTimeOne({
                      ...preferTimeOne,
                      openMinute: !preferTimeOne.openMinute,
                      anchorElMinute: null,
                    })
                  }
                  data={minuteData}
                  handleMainValue={(val) =>
                    setPreferTimeOne({
                      ...preferTimeOne,
                      minute: val,
                      openMinute: !preferTimeOne.openMinute,
                      anchorElMinute: null,
                    })
                  }
                />

                {/* Period */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeOne({
                      ...preferTimeOne,
                      openPeriod: !preferTimeOne.openPeriod,
                      anchorElPeriod: preferTimeOne.anchorElPeriod
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeOne.period}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeOne.period}
                  open={preferTimeOne.openPeriod}
                  anchorEl={preferTimeOne.anchorElPeriod}
                  handleClose={() =>
                    setPreferTimeOne({
                      ...preferTimeOne,
                      openPeriod: !preferTimeOne.openPeriod,
                      anchorElPeriod: null,
                    })
                  }
                  data={periodOption}
                  handleMainValue={(val) =>
                    setPreferTimeOne({
                      ...preferTimeOne,
                      period: val,
                      openPeriod: !preferTimeOne.openPeriod,
                      anchorElPeriod: null,
                    })
                  }
                />
              </div>
            </div>

            <div
              className="w-100"
              style={{ border: "2px solid #d3d3d3c2", borderRadius: "12px" }}
            >
              <div
                className="d-flex w-100 justify-content-between align-items-center"
                style={{
                  background: "whitesmoke",
                  padding: "14px 12px",
                  borderBottom: "2px solid #d3d3d3c2",
                  borderRadius: "12px 12px 0px 0px",
                }}
              >
                <span style={{ fontWeight: 600 }}>Preferred Time 2</span>
                <span
                  style={{
                    color: "deeppink",
                    background: "#ff14930d",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  {preferTimeTwo.hour}:{preferTimeTwo.minute} &nbsp;
                  {preferTimeTwo.period}
                </span>
              </div>
              <div
                className="d-flex justify-content-between "
                style={{ padding: "12px 10px", gap: "6px" }}
              >
                {/* Hour */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeTwo({
                      ...preferTimeTwo,
                      openHour: !preferTimeTwo.openHour,
                      anchorElHour: preferTimeTwo.anchorElHour
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeTwo.hour}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeTwo.hour}
                  open={preferTimeTwo.openHour}
                  anchorEl={preferTimeTwo.anchorElHour}
                  handleClose={() =>
                    setPreferTimeTwo({
                      ...preferTimeTwo,
                      openHour: !preferTimeTwo.openHour,
                      anchorElHour: null,
                    })
                  }
                  data={hourData}
                  handleMainValue={(val) =>
                    setPreferTimeTwo({
                      ...preferTimeTwo,
                      hour: val,
                      openHour: !preferTimeTwo.openHour,
                      anchorElHour: null,
                    })
                  }
                />

                {/* Minute */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeTwo({
                      ...preferTimeTwo,
                      openMinute: !preferTimeTwo.openMinute,
                      anchorElMinute: preferTimeTwo.anchorElMinute
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeTwo.minute}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeTwo.minute}
                  open={preferTimeTwo.openMinute}
                  anchorEl={preferTimeTwo.anchorElMinute}
                  handleClose={() =>
                    setPreferTimeTwo({
                      ...preferTimeTwo,
                      openMinute: !preferTimeTwo.openMinute,
                      anchorElMinute: null,
                    })
                  }
                  data={minuteData}
                  handleMainValue={(val) =>
                    setPreferTimeTwo({
                      ...preferTimeTwo,
                      minute: val,
                      openMinute: !preferTimeTwo.openMinute,
                      anchorElMinute: null,
                    })
                  }
                />

                {/* Period */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeTwo({
                      ...preferTimeTwo,
                      openPeriod: !preferTimeTwo.openPeriod,
                      anchorElPeriod: preferTimeTwo.anchorElPeriod
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeTwo.period}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeTwo.period}
                  open={preferTimeTwo.openPeriod}
                  anchorEl={preferTimeTwo.anchorElPeriod}
                  handleClose={() =>
                    setPreferTimeTwo({
                      ...preferTimeTwo,
                      openPeriod: !preferTimeTwo.openPeriod,
                      anchorElPeriod: null,
                    })
                  }
                  data={periodOption}
                  handleMainValue={(val) =>
                    setPreferTimeTwo({
                      ...preferTimeTwo,
                      period: val,
                      openPeriod: !preferTimeTwo.openPeriod,
                      anchorElPeriod: null,
                    })
                  }
                />
              </div>
            </div>

            {/*Prefer Time Three*/}
            <div
              className="w-100"
              style={{ border: "2px solid #d3d3d3c2", borderRadius: "12px" }}
            >
              <div
                className="d-flex w-100 justify-content-between align-items-center"
                style={{
                  background: "whitesmoke",
                  padding: "14px 12px",
                  borderBottom: "2px solid #d3d3d3c2",
                  borderRadius: "12px 12px 0px 0px",
                }}
              >
                <span style={{ fontWeight: 600 }}>Preferred Time 3</span>
                <span
                  style={{
                    color: "deeppink",
                    background: "#ff14930d",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  {preferTimeThree.hour}:{preferTimeThree.minute} &nbsp;
                  {preferTimeThree.period}
                </span>
              </div>
              <div
                className="d-flex justify-content-between "
                style={{ padding: "12px 10px", gap: "6px" }}
              >
                {/* Hour */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeThree({
                      ...preferTimeThree,
                      openHour: !preferTimeThree.openHour,
                      anchorElHour: preferTimeThree.anchorElHour
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeThree.hour}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeThree.hour}
                  open={preferTimeThree.openHour}
                  anchorEl={preferTimeThree.anchorElHour}
                  handleClose={() =>
                    setPreferTimeThree({
                      ...preferTimeThree,
                      openHour: !preferTimeThree.openHour,
                      anchorElHour: null,
                    })
                  }
                  data={hourData}
                  handleMainValue={(val) =>
                    setPreferTimeThree({
                      ...preferTimeThree,
                      hour: val,
                      openHour: !preferTimeThree.openHour,
                      anchorElHour: null,
                    })
                  }
                />

                {/* Minute */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeThree({
                      ...preferTimeThree,
                      openMinute: !preferTimeThree.openMinute,
                      anchorElMinute: preferTimeThree.anchorElMinute
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeThree.minute}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeThree.minute}
                  open={preferTimeThree.openMinute}
                  anchorEl={preferTimeThree.anchorElMinute}
                  handleClose={() =>
                    setPreferTimeThree({
                      ...preferTimeThree,
                      openMinute: !preferTimeThree.openMinute,
                      anchorElMinute: null,
                    })
                  }
                  data={minuteData}
                  handleMainValue={(val) =>
                    setPreferTimeThree({
                      ...preferTimeThree,
                      minute: val,
                      openMinute: !preferTimeThree.openMinute,
                      anchorElMinute: null,
                    })
                  }
                />

                {/* Period */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeThree({
                      ...preferTimeThree,
                      openPeriod: !preferTimeThree.openPeriod,
                      anchorElPeriod: preferTimeThree.anchorElPeriod
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeThree.period}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeThree.period}
                  open={preferTimeThree.openPeriod}
                  anchorEl={preferTimeThree.anchorElPeriod}
                  handleClose={() =>
                    setPreferTimeThree({
                      ...preferTimeThree,
                      openPeriod: !preferTimeThree.openPeriod,
                      anchorElPeriod: null,
                    })
                  }
                  data={periodOption}
                  handleMainValue={(val) =>
                    setPreferTimeThree({
                      ...preferTimeThree,
                      period: val,
                      openPeriod: !preferTimeThree.openPeriod,
                      anchorElPeriod: null,
                    })
                  }
                />
              </div>
            </div>

            {/*Prefer Time Four*/}
            <div
              className="w-100"
              style={{ border: "2px solid #d3d3d3c2", borderRadius: "12px" }}
            >
              <div
                className="d-flex w-100 justify-content-between align-items-center"
                style={{
                  background: "whitesmoke",
                  padding: "14px 12px",
                  borderBottom: "2px solid #d3d3d3c2",
                  borderRadius: "12px 12px 0px 0px",
                }}
              >
                <span style={{ fontWeight: 600 }}>Preferred Time 4</span>
                <span
                  style={{
                    color: "deeppink",
                    background: "#ff14930d",
                    padding: "4px",
                    borderRadius: "4px",
                  }}
                >
                  {preferTimeFour.hour}:{preferTimeFour.minute} &nbsp;
                  {preferTimeFour.period}
                </span>
              </div>

              <div
                className="d-flex justify-content-between "
                style={{ padding: "12px 10px", gap: "6px" }}
              >
                {/* Hour */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeFour({
                      ...preferTimeFour,
                      openHour: !preferTimeFour.openHour,
                      anchorElHour: preferTimeFour.anchorElHour
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeFour.hour}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeFour.hour}
                  open={preferTimeFour.openHour}
                  anchorEl={preferTimeFour.anchorElHour}
                  handleClose={() =>
                    setPreferTimeFour({
                      ...preferTimeFour,
                      openHour: !preferTimeFour.openHour,
                      anchorElHour: null,
                    })
                  }
                  data={hourData}
                  handleMainValue={(val) =>
                    setPreferTimeFour({
                      ...preferTimeFour,
                      hour: val,
                      openHour: !preferTimeFour.openHour,
                      anchorElHour: null,
                    })
                  }
                />

                {/* Minute */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeFour({
                      ...preferTimeFour,
                      openMinute: !preferTimeFour.openMinute,
                      anchorElMinute: preferTimeFour.anchorElMinute
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeFour.minute}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeFour.minute}
                  open={preferTimeFour.openMinute}
                  anchorEl={preferTimeFour.anchorElMinute}
                  handleClose={() =>
                    setPreferTimeFour({
                      ...preferTimeFour,
                      openMinute: !preferTimeFour.openMinute,
                      anchorElMinute: null,
                    })
                  }
                  data={minuteData}
                  handleMainValue={(val) =>
                    setPreferTimeFour({
                      ...preferTimeFour,
                      minute: val,
                      openMinute: !preferTimeFour.openMinute,
                      anchorElMinute: null,
                    })
                  }
                />

                {/* Period */}
                <div
                  className="w-100 d-flex justify-content-between align-items-center fp-size-14 p-2"
                  style={{
                    borderRadius: "6px",
                    border: "2px solid #d3d3d3c2",
                    color: "gray",
                  }}
                  onClick={(e) => {
                    setPreferTimeFour({
                      ...preferTimeFour,
                      openPeriod: !preferTimeFour.openPeriod,
                      anchorElPeriod: preferTimeFour.anchorElPeriod
                        ? null
                        : e.currentTarget,
                    });
                  }}
                >
                  <span>{preferTimeFour.period}</span>
                  <IoIosArrowDown />
                </div>
                <PopOverComponent
                  mainValue={preferTimeFour.period}
                  open={preferTimeFour.openPeriod}
                  anchorEl={preferTimeFour.anchorElPeriod}
                  handleClose={() =>
                    setPreferTimeFour({
                      ...preferTimeFour,
                      openPeriod: !preferTimeFour.openPeriod,
                      anchorElPeriod: null,
                    })
                  }
                  data={periodOption}
                  handleMainValue={(val) =>
                    setPreferTimeFour({
                      ...preferTimeFour,
                      period: val,
                      openPeriod: !preferTimeFour.openPeriod,
                      anchorElPeriod: null,
                    })
                  }
                />
              </div>
            </div>
          </div>
        )}
        {check && (
          <div className="d-flex justify-content-end gap-10">
            <FPButton
              label="Cancel"
              variant="outline"
              type="button"
              style={{ height: "30px" }}
              onClick={() => handleCancel()}
              className="ml-10"
            />
            <FPButton
              label="Submit"
              type="button"
              onClick={() => handleSubmit()}
              style={{ height: "30px" }}
            />
          </div>
        )}
      </CardWrapper>
    </>
  );
}

export default OfflineStore;
