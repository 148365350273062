import React from 'react';
import { Label, Input, FormFeedback, FormGroup } from 'reactstrap';

const ImageUploadComponent = (field) => {
  const { input, name, onChange, label, serverError, meta: { touched, error } } = field;

  const handleChange = (val) => {
    input.onChange(val);
    if (onChange) onChange(val);
  };

  return (
    <FormGroup className="fg-height">
      <Label>{label}</Label>
      <Input
        { ...field }
        type="file"
        name={name}
        onChange={handleChange}
      />
      {touched && error && <FormFeedback>{error}</FormFeedback>}
      {(serverError) && <FormFeedback>{serverError}</FormFeedback>}
    </FormGroup>
  );
};

export default ImageUploadComponent;
