import * as types from '../../actions/actionTypes';

const initialState = {};

export default function recurringCampaignDetails(state = initialState, action) {
  switch (action.type) {
    case types.CREATE_RECURRING_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.CREATE_RECURRING_CAMPAIGN_FAILURE:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.GET_ALL_RECURRING_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, { recurringCampaignList: { ...action.data } });
    case types.GET_ALL_RECURRING_CAMPAIGN_FAILURE:
      return Object.assign({}, state, { recurringCampaignList: {} });
    case types.GET_RECURRING_CAMPAIGN_DETAILS_SUCCESS:
      return Object.assign({}, state, { recurring_campaign_details: { ...action.data } });
    case types.GET_ALL_NEWSLETTER_RECURRING_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, { newsletterRecurringCampaignList: { ...action.data } });
    case types.GET_ALL_NEWSLETTER_RECURRING_CAMPAIGN_FAILURE:
      return Object.assign({}, state, { newsletterRecurringCampaignList: {} });
    case types.CREATE_NEWSLETTER_RECURRING_CAMPAIGN_SUCCESS:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.CREATE_NEWSLETTER_RECURRING_CAMPAIGN_FAILURE:
      return Object.assign({}, state, { detail: { ...action.data } });
    case types.GET_NEWSLETTER_RECURRING_CAMPAIGN_DETAILS_SUCCESS:
      return Object.assign({}, state, { newsletter_recurring_campaign_details: { ...action.data } });
    default:
      return state;
  }
}
