import React, { useState } from "react";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";
import GoogleIcon from "@mui/icons-material/Google";
import warning_svg from "../../../../../images/warning.svg";
import delete_png from "../../../../../images/delete.png";
import emoji_svg from "../../../../../images/emoji.svg";
import google_svg from "../../../../../images/googlephoto.svg";
import ListingFPInput from "../../../../../components/common/new/Listing/ListingFPInput";
import { Dropdown } from "semantic-ui-react";
import BusinessList from "../BusinessList";
import validator from "validator";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import FPInput from "../../../../../components/common/new/forms/ui/FPInput";
import AddIcon from "@material-ui/icons/Add";
import * as listingUtils from "../../../../../libs/listingToast";

const UpdateCategory = ({
  gmc_bulk_categories_data,
  getGmbListingUpdateListing,
  primaryDetail,
  actions,
  business_id,
  queryStringData,
  busines_status,
  bulk_update_listing_data
}) => {
  const selectReso = 21;

  const [selectedBusinessList, setSelectedBusinessList] = useState([]);
  const [showBusiness, setShowBusiness] = useState(true);
  const handleHideBusinessScreen = () => {
    setShowBusiness(false);
  };

  const handleShowBusinessScreen = () => {
    setShowBusiness(true);
  };

  const [dropdownValues, setDropdownValues] = useState([""]);
  const [additionalCategories, setAdditionalCategories] = useState([]);

  React.useEffect(() => {
    if (primaryDetail?.category_name?.additional_category_list?.length > 0) {
      setAdditionalCategories(primaryDetail?.category_name?.additional_category_list);
    } else {
      setAdditionalCategories([]);
    }
    actions.getGmbListingGmbBulkCategories(business_id);
  }, [primaryDetail?.category_name]);

  const handleDropdownChange = (index, value) => {
    const newValues = [...dropdownValues];
    newValues[index] = value;
    setDropdownValues(newValues);
  };

  const handleAddClick = () => {
    setDropdownValues([...dropdownValues, ""]);
  };

  const handleDeleteClick = (index) => {
    const newValues = [...dropdownValues];
    newValues.splice(index, 1);
    setDropdownValues(newValues);
  };

  const handleAdditionalDeleteClick = (category) => {
    setAdditionalCategories(additionalCategories?.filter((item) => item?.id !== category?.id));
  };

  const categoryOptions = gmc_bulk_categories_data?.categories?.map((category) => ({
    key: category?.name,
    text: category?.displayName,
    value: category, // Adjust if `value` should be different
  }));

  const handleSaveClick = async () => {
    const extractFields = (category) => ({
      displayName: category.displayName,
      moreHoursTypes: category.moreHoursTypes,
      name: category.name,
    });

    const formattedDropdownValues = dropdownValues[0] !== "" ? dropdownValues?.map(extractFields) : [];
    const formattedAdditionalCategories =
      additionalCategories?.length > 0 ? additionalCategories.map(extractFields) : [];

    const mergedAdditionalCategories = [...formattedDropdownValues, ...formattedAdditionalCategories];

    const data = {
      type: "category",
      location_ids: selectedBusinessCount,
      additionalCategories: mergedAdditionalCategories,
    };

    const restodetail = {
      business_id,
      selectReso,
    };
    console.log("category payload", data);
    listingUtils?.displayMessage(
      "info",
      "Listing getting updated, It may take a few minutes."
    );
    await actions.getGmbListingBulkUpdateListing(data, restodetail);
    listingUtils?.displayMessage("close");
    if (bulk_update_listing_data) {
      listingUtils?.displayMessage("positive", "updated Successfully.");
    }
    // listingUtils?.displayMessage("info", "Listing getting updated, It may take a few minutes.");
    // await actions.getGmbListingRefreshListing(business_id, selectReso);

    // listingUtils?.displayMessage("close",); // Close the "refresh process" toast

    // listingUtils?.displayMessage("positive", "Listing updated successfully.");
    // await actions.getGmbListingPrimaryDetail(business_id, selectReso);
  };

  const [selectedBusinessCount, setSelectedBusinessCount] = useState([]); // State to hold the selected businesses count
  console.log("==================================", selectedBusinessCount);

  const handleSelectedBusinessCount = (selectBusiness) => {
    setSelectedBusinessCount(selectBusiness);
  };
  return (
    <>
      {showBusiness ? (
        <div>
          <BusinessList
            type="additional_categories"
            busines_status={busines_status}
            queryStringData={queryStringData}
            business_id={business_id}
            selectedBusinessList={selectedBusinessList}
            onSelectedBusinessCountChange={handleSelectedBusinessCount}
          />
          {selectedBusinessCount?.length > 0 ? (
            <div
              className="d-flex mt-10 mb-15"
              style={{
                display: "flex",
                justifyContent: "end",
                fontWeight: "600",
                fontSize: "24px",
                color: "#1A2544",
                position: "sticky",
                bottom: 0,
                backgroundColor: "#fff",
                zIndex: 1000,
                paddingBlock: "20px",
              }}
            >
              <FPButton label="Next" onClick={handleHideBusinessScreen} />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-12 col-lg-9">
            <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
              <div className="d-flex flex-column" style={{ gap: "32px" }}>
                <div className="listing-name">Select the categories that best describe your business</div>
                <div className="d-flex flex-column" style={{ gap: "4px" }}>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#1B1C1D",
                    }}
                  >
                    Primary Category
                  </div>
                  <ListingFPInput type="text" value={primaryDetail?.category_name?.primary} readOnly />
                </div>
                <div
                  style={{
                    backgroundColor: "#FEE4E2",
                    borderRadius: "12px",
                    padding: "8px 16px",
                    display: "flex",
                    alignItems: "center",
                    gap: "12px",
                  }}
                >
                  <img
                    src={warning_svg}
                    alt="warning-svg"
                    style={{
                      height: "16px",
                      width: "16px",
                    }}
                  />
                  <p
                    style={{
                      fontWeight: "400",
                      fontSize: "16px",
                      color: "#F04438",
                    }}
                  >
                    Primary Category change might lead to suspension or re-verification Business profile. If you are
                    sure, please make the changes directly from Google Business Profile
                  </p>
                </div>
                <div className="d-flex justify-content-end">
                  <a href={primaryDetail?.mapsUri} target="_blank" rel="noreferrer">
                    <FPButton label="Edit on Google" size="small" startIcon={<GoogleIcon />} />
                  </a>
                </div>
                <div className="d-flex flex-column" style={{ gap: "32px" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h2
                      style={{
                        fontWeight: "500",
                        fontSize: "20px",
                        color: "#1B1C1D",
                      }}
                    >
                      Additional Categories (Maximum 9)
                    </h2>
                    <FPButton
                      label="AI suggested additional categories"
                      size="small"
                      startIcon={<img src={emoji_svg} alt="emojiSvg" />}
                    />
                  </div>
                  <div style={{ display: "grid", gap: "10px" }}>
                    {additionalCategories.length > 0 &&
                      additionalCategories.map((category, index) => (
                        <div style={{ display: "flex", alignItems: "center" }} key={index}>
                          <FPInput type="text" placeholder="Restaurant" value={category.displayName} />
                          <img
                            src={delete_png}
                            alt="deletePng"
                            style={{
                              height: "16px",
                              width: "16px",
                              color: "#FFFFFF",
                              cursor: "pointer",
                            }}
                            onClick={() => handleAdditionalDeleteClick(category)}
                          />
                        </div>
                      ))}
                    {dropdownValues?.map((value, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Dropdown
                          fluid
                          search
                          selection
                          options={categoryOptions}
                          value={value}
                          onChange={(e, { value }) => handleDropdownChange(index, value)}
                          placeholder={`Additional Category ${index + 1}`}
                        />
                        {index !== dropdownValues.length - 1 ? (
                          <img
                            src={delete_png}
                            alt="deletePng"
                            style={{
                              height: "16px",
                              width: "16px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleDeleteClick(index)}
                          />
                        ) : (
                          additionalCategories?.length + dropdownValues?.length < 9 && (
                            <AddIcon
                              style={{
                                color: "#BFC2CB",
                                fontSize: "18px",
                                cursor: "pointer",
                              }}
                              onClick={handleAddClick}
                            />
                          )
                        )}
                      </div>
                    ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: "#fff",
                      zIndex: 1000,
                      // paddingBottom: "10px",
                      // paddingTop: "10px",
                      paddingBlock: "20px",
                    }}
                  >
                    <div>
                      <FPButton label="Back" size="small" onClick={handleShowBusinessScreen} />
                    </div>
                    <div>
                      <FPButton onClick={handleSaveClick} label="Update" size="small" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3">
            <img src={google_svg} alt="verifiedImage" width="100%" />
          </div>
        </div>
      )}
    </>
  );
};

// export default UpdateCategory
const mapStateToProps = (state) => {
  return {
    gmc_bulk_categories_data: state.gmbListingLocation.gmc_bulk_categories_data,
    gmc_bulk_categories_error: state.gmbListingLocation.gmc_bulk_categories_error,
    bulk_update_listing_data: state.gmbListingLocation.bulk_update_listing_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCategory);
