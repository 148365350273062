import React from "react";
import PieChart from "../common/PieChart";
import { Box } from "@material-ui/core";

const PerformancePieCard = ({ chartData, tag }) => {
  return (
    <>
      <Box
        key={tag}
        sx={{
          backgroundColor: "#F6F6F8",
          border: "1px solid #BFC2CB",
          borderRadius: "8px",
          justifyContent: "center",
          width: "200px",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          padding: "10px",
        }}
        className="w-100 gap-6"
      >
        <Box style={{ color: "#646565", fontSize: "16px", fontWeight: "400" }}>
          {tag}
        </Box>
        <PieChart data={chartData} />
      </Box>
    </>
  );
};

export default PerformancePieCard;
