import * as types from '../../actions/actionTypes';

export default function registerReducer(state=[], action) {
  switch (action.type) {
    case types.REGISTER_SUCCESS:
      return {
        isRegister:true
      };
    case types.REGISTER_FAILURE:
      return Object.assign({}, state, { detail: {...action.data } });
    case types.REGISTER_CONFIRM_FAILURE:
      return { isRegisterConfirm : false };
    case types.REGISTER_CONFIRM_SUCCESS:
      return { isRegisterConfirm : true };
    case types.GET_BUSINESS_TYPE_SUCCESS:
      return { businessOptions: action.data };
    case types.GET_BUSINESS_TYPE_FAILURE:
      return action.error;
    default:
      return state;
  }
}
