import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';
import axios_instance from '../../libs/interseptor';
import * as utils from '../../libs/utils';
import * as commonAction from '../common/commonAction';

export const ticketListingSuccess = (data) => {
  return { type: types.TICKET_DETAILS_SUCCESS, data };
};

export const ticketListingFailure = (data) => {
  return { type: types.TICKET_DETAILS_FAILURE, data };
};

export const ticketListing = (data) => {
  const { businessId, ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CREATE_TICKET, [businessId]);
    return axios_instance.get(url, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketListingSuccess(res.data));
        return res;
      }).catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketListingFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export function singleTicketDetailsSuccess(data) {
  return { type: types.SINGLE_TICKET_DETAILS_SUCCESS, data };
}

export function singleTicketDetailsFailure(data) {
  return { type: types.SINGLE_TICKET_DETAILS_FAILURE, data };
}

export const singleTicketDetails = (business_id, ticket_id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(utils.format(`${URLS.SINGLE_TICKET_DETAILS}`, [business_id, ticket_id]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(singleTicketDetailsSuccess(res.data));
        return res;
      }).catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(singleTicketDetailsFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export const patchTicket = (data,business_id,ticket_id) => {
  return dispatch => {
    dispatch(commonAction.showLoader());
    return axios_instance.patch(utils.format(URLS.SINGLE_TICKET_DETAILS,[business_id,ticket_id]),data)
      .then((res) => { 
        dispatch(commonAction.hideLoader());
        dispatch(singleTicketDetailsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(singleTicketDetailsFailure(err.response.data));
        throw err.response.data;
      });
  };
};


export const addComment = (data,business_id,ticket_id) => {
  return dispatch => {
    dispatch(commonAction.showLoader());
    return axios_instance.post(`${utils.format(URLS.SINGLE_TICKET_DETAILS,[business_id,ticket_id])}comment/`,data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(singleTicketDetailsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(singleTicketDetailsFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export const createTicketSuccess = (data) => {
  return { type: types.CREATE_TICKET_SUCCESS, data };
};

export const createTicketFailure = (data) => {
  return { type: types.CREATE_TICKET_FAILURE, data };
};

export const createTicket = (payload, business_id , showLoader = true) => {
  return (dispatch) => {
    console.log("call");
    if(showLoader) dispatch(commonAction.showLoader());
    const url = utils.format(URLS.CREATE_TICKET, [business_id]);
    return axios_instance.post(url, payload)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(createTicketSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(createTicketFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export const getTicketStatusOptionsSuccess = (data) => {
  return { type: types.TICKET_STATUS_OPTIONS_SUCCESS, data };
};

export const getTicketStatusOptionsFailure = (data) => {
  return { type: types.TICKET_STATUS_OPTIONS_FAILURE, data };
};

export const getTicketStatusOptions = () => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(`${URLS.TICKET}option/status/`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getTicketStatusOptionsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getTicketStatusOptionsFailure(err?.response?.data));
        throw err.response.data;
      });
  };
};

const getTicketSourceOptionsSuccess = (data) => {
  return { type: types.GET_TICKET_SOURCE_OPTIONS_SUCCESS, data };
};

const getTicketSourceOptionsFailure = (data) => {
  return { type: types.GET_TICKET_SOURCE_OPTIONS_FAILURE, data };
};

export const getTicketSourceOptions = () => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(`${URLS.TICKET}option/content`)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getTicketSourceOptionsSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getTicketSourceOptionsFailure(err.response.data));
        throw err.response.data;
      });
  };
};

const ticketResolutionSuccess = (data) => {
  return { type: types.TICKET_RESOLUTION_SUCCESS, data };
};

const ticketResolutionFailure = (data) => {
  return { type: types.TICKET_RESOLUTION_FAILURE, data };
};

export const ticketResolution = (business_id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(utils.format(URLS.TICKET_RESOLUTION, [business_id]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketResolutionSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketResolutionFailure(err.response.data));
        throw err.response.data;
      });
  };
};

const createTicketResolutionSuccess = (data) => {
  return { type: types.CREATE_TICKET_RESOLUTION_SUCCESS, data };
};

const createTicketResolutionFailure = (data) => {
  return { type: types.CREATE_TICKET_RESOLUTION_FAILURE, data };
};

export const createTicketResolution = (data) => {
  if (data.resolutionId){
    const edit_url = `${utils.format(URLS.TICKET_RESOLUTION, [data.businessId])}${data.resolutionId}/`;
    return (dispatch) => {
      return axios_instance.patch(edit_url, data.data)
        .then((res) => {
          dispatch(createTicketResolutionSuccess(res.data));
          return res;
        })
        .catch((err) => {
          dispatch(createTicketResolutionFailure(err.response.data));
          throw err.response.data;
        });
    };
  }else{
    return (dispatch) => {
    dispatch(commonAction.showLoader());
      return axios_instance.post(utils.format(URLS.TICKET_RESOLUTION, [data.businessId]), data.data)
        .then((res) => {
          dispatch(commonAction.hideLoader());
          dispatch(createTicketResolutionSuccess(res.data));
          return res;
        })
        .catch((err) => {
          dispatch(commonAction.hideLoader());
          dispatch(createTicketResolutionFailure(err.response.data));
          throw err.response.data;
        });
    };
  }
  
};

const ticketResolutionResponseSuccess = (data) => {
  return { type: types.GET_TICKET_RESOLUTION_RESPONSE_SUCCESS, data };
};

const ticketResolutionResponseFailure = (data) => {
  return { type: types.GET_TICKET_RESOLUTION_RESPONSE_FAILURE, data };
};

export const ticketResolutionResponse = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.get(utils.format(URLS.TICKET_RESOLUTION_RESPONSE, [data.business_id, data.ticket_id]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketResolutionResponseSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ticketResolutionResponseFailure(err.response.data));
        throw err.response.data;
      });
  };
};

const createTicketResolutionResponseSuccess = (data) => {
  return { type: types.CREATE_TICKET_RESOLUTION_RESPONSE_SUCCESS, data };
};

const createTicketResolutionResponseFailure = (data) => {
  return { type: types.CREATE_TICKET_RESOLUTION_RESPONSE_FAILURE, data };
};

export const createTicketResolutionResponse = (data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.post(URLS.SUBMIT_TICKET_RESOLUTION, data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(createTicketResolutionResponseSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(createTicketResolutionResponseFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export const ticketMailSend = (data, business_id, ticket_id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance.post(utils.format(URLS.TICKET_SEND_MAIL, [ business_id, ticket_id ]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  }
}