import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { InputLabel } from '@material-ui/core';

const DefaultLabel = withStyles({
  root: {
    fontSize: '13px',
    color: '#1B1C1D',
    textAlign: 'left',
  },
  asterisk:{
    color: '#EC554E'
  }
})((props) => <InputLabel {...props} />);

const FPLabel = ({ children, isRequired = true, ...rest }) => {
  return (
    <DefaultLabel required={isRequired} {...rest}>
      {children}
    </DefaultLabel>
  );
};

export default FPLabel;
