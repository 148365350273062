/* eslint-disable quotes */
import React, { memo, useCallback, useMemo } from "react";
import "./userDetails.css";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import { Icon } from "semantic-ui-react";

const IconText = ({ icon, text, onClick, extraClass = "" }) => (
  <div
    className={`d-flex align-items-center ${extraClass}`}
    onClick={onClick}
    role="button"
  >
    {icon}
    <span className="font-blue-gray line-height-1">{text}</span>
  </div>
);

const UserDetails = memo(
  ({
    item,
    showFetchOrderDetailsBtn,
    service_category,
    onAddOrderId,
    fetchOrderDetails,
    handleOrderDetails,
  }) => {
    const getActionLabel = useCallback((category) => {
      switch (category) {
        case "Restaurant":
          return "Add Order Id";
        case "hotel":
          return "Add Reservation Id";
        case "hospital":
          return "Add Appointment Id";
        default:
          return "Add Id";
      }
    }, []);

    const iconStyle = useMemo(
      () => ({
        width: "14px",
        height: "14px",
        color: "#F13A88",
        marginRight: "3px",
      }),
      []
    );

    const renderOrderId = useCallback(() => {
      if (!onAddOrderId && !item.order_id) return null;
      return (
        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          <AssignmentOutlinedIcon style={iconStyle} />
          {item.order_id ? (
            <>
              <span
                className="line-height-1 cursor-pointer"
                style={{color: "#2375ef"}}
                onClick={() => handleOrderDetails(item.id, item.order_id)}
              >
                {item.order_id}
              </span>
              {showFetchOrderDetailsBtn &&
                showFetchOrderDetailsBtn[item.order_id] !== "fetched" && (
                  <Icon
                    className="ml-2 cursor-pointer"
                    name="sync"
                    title="Fetch order details"
                    onClick={() => fetchOrderDetails(item.order_id, item.id)}
                  />
                )}
            </>
          ) : (
            onAddOrderId && (
              <span
                className="font-blue-gray line-height-1"
                onClick={() => onAddOrderId(item)}
                role="button"
              >
                {getActionLabel(service_category)}
              </span>
            )
          )}
        </div>
      );
    }, [item, showFetchOrderDetailsBtn, service_category]);

    const renderEmail = useCallback(() => {
      if (!onAddOrderId && !item?.reviewer?.email) return null;

      return (
        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          {(onAddOrderId || (item.order_id && item?.reviewer?.email)) && (
            <span className="fp-vertical-small-divider"></span>
          )}
          <EmailOutlinedIcon style={iconStyle} />
          {item?.reviewer?.email ? (
            <span
              className="line-height-1"
              role="button"
              style={{color: "#2375ef"}}
              onClick={() => onAddOrderId(item, true)}
            >
              {item.reviewer.email}
            </span>
          ) : (
            onAddOrderId && (
              <span
                className="font-blue-gray line-height-1"
                onClick={() => onAddOrderId(item)}
                role="button"
              >
                Add Email
              </span>
            )
          )}
        </div>
      );
    }, [item, onAddOrderId]);

    const renderPhone = useCallback(() => {
      const phone = item?.reviewer?.phone || item?.reviewer?.contact_number;
      if (!onAddOrderId && !phone) return null;

      return (
        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          {(onAddOrderId ||
            (item?.reviewer?.email &&
              (item?.reviewer?.phone || item?.reviewer?.contact_number))) && (
            <span className="fp-vertical-small-divider"></span>
          )}
          <PhoneOutlinedIcon style={iconStyle} />
          {phone ? (
            <span
              className="line-height-1"
              role="button"
              style={{color: "#2375ef"}}
              onClick={() => onAddOrderId(item, true)}
            >
              {phone}
            </span>
          ) : (
            onAddOrderId && (
              <span
                className="font-blue-gray line-height-1"
                role="button"
                onClick={() => onAddOrderId(item)}
              >
                Add Phone
              </span>
            )
          )}
        </div>
      );
    }, [item, onAddOrderId]);

    return (
      <div className="user-details-container">
        <div className="d-flex mt-1 fp-size-12" style={{ gap: "4px" }}>
          {renderOrderId()}
          {renderEmail()}
          {renderPhone()}
        </div>
      </div>
    );
  }
);

export default UserDetails;
