import "./whatsAppMessagesStyle.css";
import TemplateCard from "./components/TemplateCard";
import { useState } from "react";
import ListingFPTab from "../../components/common/new/Listing/tabs/listingFPTabs";
import { FPTab } from "../../components/common/new/tabs";
import { FPButton } from "../../components/common/new";

const WhatsAppMessagesTemplate = () => {
  const [activeTab, setActiveTab] = useState(0);

  const templates = [
    {
      id: 1,
      name: "Template Name A",
      date: "Mar 4, 2024",
      ticketNo: "#0012",
      greeting: "Hello Alexa,",
      message:
        "Thank you for your feedback. We value your opinion and are sorry for the inconvenience caused.",
      context: "What is needed: previous ticket to address your concern.",
      flow: "Ticket Closure",
      phone: "+91 7845213695",
      hasWhatsApp: true,
    },
    {
      id: 2,
      name: "Template Name B",
      date: "Mar 4, 2024",
      ticketNo: "#0012",
      greeting: "Hello Alexa,",
      message:
        "Thank you for your feedback. We value your opinion and are sorry for the inconvenience caused.",
      context: "What is needed: previous ticket to address your concern.",
      flow: "Ticket Closure",
      phone: "+91 7845213695",
      hasWhatsApp: true,
    },
    {
      id: 3,
      name: "Template Name C",
      date: "Mar 4, 2024",
      ticketNo: "#0012",
      greeting: "Hello Alexa,",
      message:
        "Thank you for your feedback. We value your opinion and are sorry for the inconvenience caused.",
      context: "What is needed: previous ticket to address your concern.",
      flow: "Welcome & Thank You",
      phone: "+91 7845213695",
      hasWhatsApp: true,
    },
    {
      id: 4,
      name: "Template Name D",
      date: "Mar 4, 2024",
      ticketNo: "#0012",
      greeting: "Hello Alexa,",
      message:
        "Thank you for your feedback. We value your opinion and are sorry for the inconvenience caused.",
      context: "What is needed: previous ticket to address your concern.",
      flow: "Hotel Welcome AI Video",
      phone: "+91 7845213695",
      hasWhatsApp: true,
    },
    {
      id: 5,
      name: "Template Name E",
      date: "Mar 4, 2024",
      ticketNo: "#0012",
      greeting: "Hello Alexa,",
      message:
        "Thank you for your feedback. We value your opinion and are sorry for the inconvenience caused.",
      context: "What is needed: previous ticket to address your concern.",
      flow: "Not Mapped",
      phone: "+91 7845213695",
      hasWhatsApp: true,
    },
    {
      id: 6,
      name: "Template Name F",
      date: "Mar 4, 2024",
      ticketNo: "#0012",
      greeting: "Hello Alexa,",
      message:
        "Thank you for your feedback. We value your opinion and are sorry for the inconvenience caused.",
      context: "What is needed: previous ticket to address your concern.",
      flow: "Not Mapped",
      phone: "+91 7845213695",
      hasWhatsApp: true,
    },
  ];
  const handleRootTabChange = (value) => {
    setActiveTab(value); // Update the active tab based on the selected value
  };

  return (
    <div className="template-showcase">
      <div className="tabs-container d-flex justify-content-between">
        <FPTab
          tabLabels={["Utility", "Promotional"]}
          minTabWidth={150}
          defaultValue={0}
          onChange={(value) => handleRootTabChange(value)}
        />
        <div className="action-button-container">
          <button className="create-bot-button">
            <span className="plus-icon">+</span>
            Create Bot Template
          </button>
        </div>
      </div>

      {activeTab === 0 && (
        <div className="template-grid">
          {templates.map((template) => (
            <TemplateCard key={template.id} template={template} />
          ))}
        </div>
      )}
    </div>
  );
};

export default WhatsAppMessagesTemplate;
