import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../actions/listing/listingLocationAction";
import * as setSelectedTab from "../../actions/common/setSelectedTabAction";
import { Box } from "@material-ui/core";
import CustomStarRatingComponent from "../../components/common/StarComponent";
import duplicate_svg from "../../images/dusplicate.svg";
import verified_svg from "../../images/verified.svg";
import OpenHour from "../../components/listing/ListingDetail/UpdateOpenHour";
import SpecialHour from "../../components/listing/ListingDetail/SpecialHour";
import google_svg from "../../images/googlephoto.svg";
import { formatReviews } from "../../libs/helper";
import { ListDescription } from "semantic-ui-react";
import { FPSwitch } from "../../components/common/new/Listing/index";

class UpdateOpenHour extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hour_tabKey: 0,
    };
  }

  handleTabChange = (value) => {
    this.setState({ hour_tabKey: value ? 1 : 0 });
  };

  render() {
    const { hour_tabKey } = this.state;
    const { primaryDetail, business_id, selectReso } = this.props;
    const addressString = [primaryDetail?.addressLines, primaryDetail?.locality, primaryDetail?.postalCode].join(", ");

    return (
      <>
        <div className="listing-container" style={{ overflow: "hidden", gap: "15px" }}>
          <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <div className="listing-header">
              <div className="listing-info">
                <div style={{ display: "flex", gap: "8px" }}>
                  <div className="listing-name">{primaryDetail?.title}</div>
                  <img
                    src={
                      primaryDetail?.is_varified
                        ? verified_svg
                        : primaryDetail?.is_suspend
                        ? duplicate_svg
                        : primaryDetail?.is_duplicate
                        ? duplicate_svg
                        : ""
                    }
                    alt="verifiedImage"
                  />
                </div>
                <div style={{ display: "flex", gap: "8px", alignItems: "center" }}></div>
                <div className="rating-container">
                  <CustomStarRatingComponent
                    name="rate"
                    starCount={5}
                    starColor="#FBBD08"
                    emptyStarColor="white"
                    value={primaryDetail?.averageRating}
                    editing={false}
                  />
                </div>
                <span>{primaryDetail?.averageRating?.toFixed(1)}</span>
                <div
                  style={{
                    height: "5px",
                    width: "5px",
                    backgroundColor: "#D9D9D9",
                    borderRadius: "50%",
                  }}
                ></div>
                <span>{formatReviews(primaryDetail?.totalReviewCount)} Reviews</span>
              </div>
            </div>
          </div>
          <div className="review-address">
            <ListDescription>{addressString}</ListDescription>
          </div>
        </div>
        <div className="listing-container" style={{ display: "grid", gap: "24px" }}>
          <div className="UpdateScrollHeightSection" style={{ position: "relative" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                width: "calc(100% - 120px)",
              }}
            >
              <Box
                sx={{
                  justifyContent: "space-between",
                  display: "flex",
                }}
              >
                <FPSwitch
                  id="2"
                  yesLabel="Open Hour"
                  noLabel="Special Hour"
                  defaultValue={hour_tabKey === 1}
                  onChange={this.handleTabChange}
                />
              </Box>
              {hour_tabKey === 1 ? (
                <SpecialHour primaryDetail={primaryDetail} business_id={business_id} selectReso={selectReso} />
              ) : (
                <OpenHour primaryDetail={primaryDetail} business_id={business_id} selectReso={selectReso} />
              )}
            </div>
            <div className="RightImageSectionDialog">
              <img src={google_svg} alt="verifiedImage" />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOpenHour);
