import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Field, reduxForm, SubmissionError } from "redux-form";
import InputComponent from "../common/InputComponent";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { Box } from "@material-ui/core";
import { Button, Form } from "semantic-ui-react";
import { FPButton } from "../common/new";

const validate = (values) => {
  const errors = {};

  if (!values.business_name) errors.business_name = "Enter Business Name";
  if (values.business_name && values.business_name.length > 200)
    errors.business_name = "Bussiness name must have less than 200 characters";
  if (!values.industry_category) errors.industry_category = "Enter Category";

  return errors;
};

let BusinessProfileSetup = (props) => {
  const { isEditable, handleBusinessSetup, handleSubmit, submitting, errors, isBusinessOwner } = props;

  const submitProfile = (data) => {
    const newData = { ...data };
    delete newData["location_address"];
    return handleBusinessSetup(newData).catch((err) => {
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  };

  return (
    <Form className="grid-form mt-2" onSubmit={handleSubmit(submitProfile)}>
      {errors && errors.non_field_errors
        ? errors.non_field_errors.map((error, index) => (
            <p key={index} className="text-danger">
              {error}
            </p>
          ))
        : null}
      <CardWrapper
        headerTitle={
          <div className="d-flex justify-content-between">
            <Box>Business profile</Box>
            <div>
              {/* {isBusinessOwner && (
                <>
                  {isEditable ? (
                    <FPButton label="Save" size="small" type="submit" disabled={submitting} />
                  ) : (
                    <FPButton
                      size="small"
                      variant="outline"
                      onClick={() => props.toggleState("isProfile")}
                      label="Edit"
                    />
                  )}
                </>
              )} */}
              {isBusinessOwner && (
                <div>
                  {isEditable ? (
                    <Form.Field
                      control={Button}
                      type="submit"
                      disabled={submitting}
                      className="submit-btn"
                      style={{ borderRadius: "80px", backgroundColor: "#F13A88", color: "white" }}
                    >
                      Save
                    </Form.Field>
                  ) : (
                    <Button basic type="button" onClick={() => props.toggleState("isProfile")} className="edit-btn">
                      Edit
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        }
      >
        <Row>
          <Col sm="6">
            <FPLabel isRequired={false}>Name</FPLabel>
            <Field
              name="business_name"
              placeholder="Enter Brand Name"
              type="text"
              component={InputComponent}
              disabled={!isEditable}
              serverError={errors && errors.business_name}
            />
          </Col>
          <Col sm="6">
            <FPLabel isRequired={false}>Category</FPLabel>
            <Field
              name="industry_category"
              placeholder="Enter Category"
              type="text"
              component={InputComponent}
              disabled={true}
              serverError={errors && errors.industry_category}
              simpleValue
            />
          </Col>
        </Row>
      </CardWrapper>
    </Form>
  );
};

BusinessProfileSetup = reduxForm({
  form: "BusinessProfileSetup",
  validate,
  enableReinitialize: true,
})(BusinessProfileSetup);

BusinessProfileSetup = connect((state) => ({
  initialValues: state.business.info, // pull initial values from account reducer
  categories: state.common.categories,
}))(BusinessProfileSetup);

export default BusinessProfileSetup;
