import React, { forwardRef } from "react";
import { Card } from "reactstrap";
import "./listing.css";
import success_svg from "../../../images/success.svg";
import { CircularProgressbar } from "react-circular-progressbar";
import { Box, Dialog, DialogActions, IconButton, Slide } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import UpdateOpenHour from "../../../containers/listing/UpdateOpenHour";
import UpdateMorehoureTag from "./UpdateMorehoureTag";
import UpdateOpenDate from "./UpdateOpenDate";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const BusinessHours = ({ primaryDetail, basic_detail_data, business_id, selectReso }) => {
  const [open, setOpen] = React.useState({
    hour: false,
    tags: false,
    openingDate: false,
  });
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (dialog, scrollType) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: true,
    }));
    setScroll(scrollType);
  };

  const handleClose = (dialog) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: false,
    }));
  };
  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const convertedArray =
    primaryDetail?.regularHours?.periods?.length > 0
      ? Object.groupBy(primaryDetail?.regularHours?.periods, ({ openDay }) => openDay)
      : null;

  return (
    <div className="row detail-card-container">
      <div
        className="select-box-hover col-md-6 col-lg-3 p-1"
        style={{ maxWidth: "100%" }}
        onClick={() => handleClickOpen("hour", "paper")}
      >
        <Card className="detail-card-item detail-special-card cursor-pointer">
          <div className="detail-card-content">
            <div style={{ width: "28px" }}>
              {basic_detail_data?.opening_and_closing_hours === true ? (
                <img src={success_svg} height="28px" alt="" className="successImg" />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>Opening & Closing Hours</span>
            <div className="d-flex" style={{ margin: "0px 2px", gap: "4px 13px" }}>
              <div className="col-6 p-0">
                {convertedArray && Object.keys(convertedArray).length > 0 ? (
                  Object.keys(convertedArray)
                    .slice(0, 4)
                    .map((openDay) => (
                      <div
                        key={openDay}
                        className="d-flex"
                        style={{
                          fontSize: "11px",
                          fontWeight: "400",
                          color: "#646565",
                        }}
                      >
                        <div style={{ width: "40px" }}>{openDay?.slice(0, 3)}:</div>
                        {convertedArray[openDay]?.map((openDay_values, index, arr) => (
                          <div className="d-flex" key={index}>
                            <div>
                              {openDay_values?.openTime?.hours || "12"}:{openDay_values?.openTime?.minutes || "00"}
                            </div>
                            <div>-</div>
                            <div>
                              {openDay_values?.closeTime?.hours || "12"}:{openDay_values?.closeTime?.minutes || "00"}
                            </div>
                            {index < arr.length - 1 && <span className="comma-spacing">,</span>}
                          </div>
                        ))}
                      </div>
                    ))
                ) : (
                  <div style={{ fontSize: "12px", color: "#646565" }}>No opening hours available.</div>
                )}
              </div>
              <div className="col-6 p-0">
                {convertedArray && Object.keys(convertedArray).length > 0 ? (
                  Object.keys(convertedArray)
                    .slice(4)
                    .map((openDay) => (
                      <div
                        key={openDay}
                        className="d-flex"
                        style={{
                          fontSize: "11px",
                          fontWeight: "400",
                          color: "#646565",
                        }}
                      >
                        <div style={{ width: "40px" }}>{openDay?.slice(0, 3)}:</div>
                        {convertedArray[openDay]?.map((openDay_values, index, arr) => (
                          <div className="d-flex" key={index}>
                            <div>
                              {openDay_values?.openTime?.hours || "12"}:{openDay_values?.openTime?.minutes || "00"}
                            </div>
                            <div>-</div>
                            <div>
                              {openDay_values?.closeTime?.hours || "12"}:{openDay_values?.closeTime?.minutes || "00"}
                            </div>
                            {index < arr.length - 1 && <span className="comma-spacing">,</span>}
                          </div>
                        ))}
                      </div>
                    ))
                ) : (
                  <div style={{ fontSize: "12px", color: "#646565" }}>No opening hours available.</div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>

      <Dialog
        open={open.hour}
        onClose={() => handleClose("hour")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height:"90vh",
            width: "100%",
          },
        }}        // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
      >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Opening Hours</span>
              <CloseIcon onClick={() => handleClose("hour")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("hour")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdateOpenHour primaryDetail={primaryDetail} business_id={business_id} selectReso={selectReso} />
          </div>
        </Box>
      </Dialog>

      <div className="select-box-hover col-md-6 col-lg-3  p-1" onClick={() => handleClickOpen("tags", "paper")}>
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.more_hours === true ? (
                <img src={success_svg} height="28px" alt="" className="successImg" />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>More Hours Tags</span>
            <span className="detail-business-card-review">Add specific hours for delivery, pickup, brunch etc.</span>
          </div>
        </Card>
      </div>
      <Dialog
        open={open.tags}
        onClose={() => handleClose("tags")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
        // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height:"90vh",
            width: "100%",
          },
        }}
              >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update More Hours Tags</span>
              <CloseIcon onClick={() => handleClose("tags")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("tags")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdateMorehoureTag primaryDetail={primaryDetail} business_id={business_id} selectReso={selectReso} />
          </div>
        </Box>
      </Dialog>

      <div className="select-box-hover col-md-6 col-lg-3  p-1" onClick={() => handleClickOpen("openingDate", "paper")}>
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.business_opening_date === true ? (
                <img src={success_svg} height="28px" alt="" className="successImg" />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>Business opening date</span>
            <span className="detail-business-card-review">Add your Business opening date to build trust.</span>
          </div>
        </Card>
      </div>
      <Dialog
        open={open.openingDate}
        onClose={() => handleClose("openingDate")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
        // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height:"90vh",
            width: "100%",
          },
        }}
      >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Opening Date</span>
              <CloseIcon onClick={() => handleClose("openingDate")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("openingDate")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdateOpenDate primaryDetail={primaryDetail} business_id={business_id} selectReso={selectReso} />
          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default BusinessHours;
