import React from 'react';
import { Label, FormFeedback, FormGroup } from 'reactstrap';
import { TextArea } from 'semantic-ui-react';

const TextAreaComponent = (field) => {
  const { input, label, serverError,placeholder, meta: { touched, error } } = field;
  return (
    <FormGroup>
      <Label>{label}</Label>
      <TextArea
      placeholder={placeholder}
        className='fp-textArea'
        {...field}
        {...input}
      />
      {touched && error && <FormFeedback>{error}</FormFeedback>}
      {(serverError) && <FormFeedback>{serverError}</FormFeedback>}
    </FormGroup>
  );
};

export default TextAreaComponent;
