import React from "react";
import TicketCardHeader from "./TicketCardHeader";
import "./ticketCard.css";
import "../../components/business/reviewList.css";
// import UserDetails from "../review/UserDetails";
import { FPAvatar, FPButton, FPMenuBox, MessageBox } from "../../components/common/new";
import CardWrapper from "../dashboard/component/CardWrapper";
import DefaultUserIcon from "../../images/boy.png";
import { CSSTransitionGroup } from "react-transition-group";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import classNames from "classnames";
import { colorList } from "../../libs/constant";
import FPChip from "../../components/common/new/FPChip/FPChip";
import { snakeToCapital } from "../../libs/utils";
import moment from "moment";
import OrderIdView from "./OrderIdView";
import FeedbackImage from "../../components/business/FeedbackImage";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuBox: {
    color: "#1B1C1D",
    fontSize: "13px",
    gap: 0,
    backgroundColor: "#F6F6F8",
    border: "unset",
  },
  button: {
    padding: "0 12px",
    fontSize: "13px",
    border: "unset",

    "& .MuiButton-label": {
      fontSize: "13px",
      fontWeight: "400",
    },
  },
}));

const TicketCard = ({ data, className, replyOptions, handleReplyOption, ticketPage }) => {
  const { source_data } = data;
  const tags = [...(data.review_tags ? data.review_tags : []), ...(data.tag ? data.tag : [])] || [];
  let statuses = {};
  replyOptions &&
    replyOptions.map((item) => {
      statuses[item.value] = item.display_name;
    });
  const modifyReplyOption = replyOptions
    ? replyOptions.map((option) => ({
        label: option.display_name,
        value: option.value,
        onClick: () => handleReplyOption(option.value, data.id),
      }))
    : [];
  const classes = useStyles({ isTicket: data.ticket });
  const serviceDelivery = [
    { label: "Delivery", value: "Delivery" },
    { label: "Dining", value: "Dining" },
    { label: "Takeaway", value: "Takeaway" },
  ];
  return (
    <CardWrapper
      className={classNames("fp-card-outline review-card-wrapper ticket-card-header", className)}
      showDivider={false}
      style={{ border: "2px solid #dee2e6" }}
    >
      <TicketCardHeader data={data} />
      <section className="review-card-body">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <FPAvatar
            src={(data.source_data.reviewer || {}).image || DefaultUserIcon}
            labelClass={"user-name"}
            subLabel={<OrderIdView data={data} />}
            userName={
              source_data && source_data.provider_name === "inhouse"
                ? source_data.reviewer.name && source_data.reviewer.name.toString()
                : (source_data.provider || {}).provider_name === "swiggy"
                ? source_data.order_id
                : (source_data.provider || {}).provider_name === "famepilot"
                ? source_data.unique_code
                : (data.customer || {}).name
            }
          />
        </div>
        <div className="d-flex align-items-center gap-6">
          {!ticketPage &&
            data.source_data.status &&
            data.source_data.provider_name !== "swiggy" &&
            data.source_data.provider_name !== "offline" &&
            data.source_data.provider_name !== "magicpin" &&
            data.source_data.provider_name !== "dineout" &&
            data.source_data.provider_name !== "eazydiner" &&
            data.source_data.provider_name !== "ewards" &&
            data.source_data.provider_name !== "reservego" &&
            data.source_data.provider_name !== "rista" &&
            data.source_data.provider_name !== "zomato_ors" &&
            data.source_data.provider_name !== "inhouse" && (
              <FPMenuBox
                title={statuses ? statuses[data.source_data.status] : "Not Responded"}
                options={modifyReplyOption}
                className={classes.menuBox}
                rounded={true}
                size={"small"}
              />
            )}

          {ticketPage ? (
            <FPMenuBox
              title={
                ["offline", "customercare", "tripadvisor"].includes(data.source_data.provider_name)
                  ? "Dining"
                  : "Delivery"
              }
              options={serviceDelivery}
              className={classes.menuBox}
              rounded={true}
              size={"small"}
            />
          ) : (
            data.source_data.service_type && (
              <FPMenuBox
                title={snakeToCapital(data.source_data.service_type)}
                options={serviceDelivery}
                className={classes.menuBox}
                rounded={true}
                size={"small"}
              />
            )
          )}
        </div>

        {/* //* Desc */}
        <Box sx={{ marginTop: "10px" }}>
          {source_data ? (
            source_data.provider_name === "inhouse" ? (
              <MessageBox
                isSelfBox={false}
                className={"max-w-100"}
                time={moment(source_data.created_at).format("llll")}
              >
                {data.description.split("\\n").map((item_split, item_index) => {
                  return (
                    <div key={`${item_index}`}>
                      {item_split.includes("Feedback Link") ? (
                        <span>
                          Feedback Link :
                          <a rel="noreferrer" href={item_split.split("Feedback Link :")[1]} target="_blank">
                            {item_split.split("Feedback Link :")[1]}
                          </a>
                        </span>
                      ) : (
                        <p>{item_split}</p>
                      )}
                    </div>
                  );
                })}
              </MessageBox>
            ) : (
              data.description && (
                <MessageBox
                  isSelfBox={false}
                  className={"max-w-100"}
                  time={moment(source_data.created_at).format("llll")}
                >
                  {data.description}
                </MessageBox>
              )
            )
          ) : (
            ""
          )}
        </Box>
        {data.source_data.extra_data &&
        data.source_data.extra_data.image_links &&
        data.source_data.extra_data.image_links.length !== 0 ? (
          <FeedbackImage reviews={data.source_data.extra_data} />
        ) : (
          ""
        )}
        {/* //* Tags */}
        {tags.length ? (
          <div style={{ color: "gray", display: "flex" }} className="mt-15">
            <FPButton
              startIcon={
                <LocalOfferOutlinedIcon
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "-5px",
                  }}
                />
              }
              className="px-3 mr-10"
              backgroundColor="#161616"
              size="small"
              fontWeight="400"
              fontSize="13px"
              label="Tags"
            />
            <CSSTransitionGroup
              transitionName="reviews-tags"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
              className="d-flex flex-wrap gap-10"
            >
              {tags.map((tag, index) => {
                return (
                  <FPChip
                    key={`${index}-ticket-tags`}
                    variant="outlined"
                    style={{
                      borderColor: colorList.length !== tags.length ? colorList[index] : "#BFC2CB",
                      borderWidth: "2px",
                    }}
                    className="review-tag-chip fp-size-13"
                    label={<span>{snakeToCapital(tag)}</span>}
                  />
                );
              })}
            </CSSTransitionGroup>
          </div>
        ) : (
          ""
        )}
      </section>
    </CardWrapper>
  );
};

export default TicketCard;
