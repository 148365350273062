import React from "react";
import { Pie } from "react-chartjs-2";

const PieChart = ({ data }) => {
  const chartData = {
    labels: ["Positive", "Negative", "Neutral"],
    datasets: [
      {
        data: [data.positive, data.negative, data.neutral],
        backgroundColor: ["#90D56D", "#F07771", "#FBE87B"],
        hoverBackgroundColor: ["#90D56D", "#F07771", "#FBE87B"],
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div style={{ width: "180px", height: "180px" }}>
      <Pie data={chartData} options={options} />
    </div>
  );
};

export default PieChart;
