import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { connect } from 'react-redux';
import * as utils from '../../libs/utils';
import * as recurringCampaignActions from '../../actions/campaign/recurringCampaignActions';
import { Row, Col } from 'reactstrap';
import RecurringCampaignForm from './RecurringCampaignForm';
import LoaderComponent from '../../components/common/loaderComponent';
import ConfirmationModal from '../../components/common/ConfirmationModal';
import axios_instance from '../../libs/interseptor';
import * as URLS from '../../libs/apiUrls';


class NewsletterRecurringCampaignEditPage extends React.Component{
  constructor(props){
    super(props);
    this.state={ 
      template_list : [],
      // branch_list : [],
    };
    this.submitData = this.submitData.bind(this);
    // this.deleteResponse = this.deleteResponse.bind(this);
  }

  submitData(data) {
    const { match: { params: { business_id }} } = this.props;
    return this.props.actions.createNewsletterRecurringCampaign(data,business_id );
  }

  componentDidMount() {
    const { match: { params: { business_id }}, location: { search } } = this.props;
    const { id } = queryString.parse(search);
    if (id) this.props.actions.getNewsletterRecurringCampaignDetails(business_id, id );
    
    axios_instance.get(utils.format(URLS.NEWSLETTER_LIST,[business_id]))
      .then((res)=>{
        const temp = res.data.results.map(i => ({ id: i.id, value: i.id, text:i.name }));
        this.setState({template_list:temp});
      });
    // axios_instance.get(`${URLS.BUSINESS_SETUP}${business_id}/enterprise/?page_size=1000`)
    //   .then(
    //     (res) => {
    //       const location_list = res.data.results.map(i => ({ id: i.id, value: i.id, text: i.alias ? i.alias : i.location_address }));
    //       this.setState({branch_list:location_list});
    //     }
    //   );
  }

  deleteResponse(id){
    const { history, match: { params: { business_id }} } = this.props;
    this.props.actions.newsletterRecurringCampaignDelete(business_id, id)
      .then(()=>{
        utils.displayMessage('positive','Newsletter Recurring Campaign Deleted Successfully');
        history.push({
          pathname: 'list'
        });
      })
      .catch((err)=>{
        utils.displayMessage('negative','Error Deleting Response');
        console.log(err);
      });
  }

  toggleConfirm = (confirmationFunction) => {
    this.setState({ isConfirmOpen: !this.state.isConfirmOpen, confirmationFunction });
  }

  render() {
    const { route: { location: { search }}} = this.context.router;
    const { id } = queryString.parse(search);
    const { newsletterRecurringCampaignDetails, match: { params: { business_id }} } = this.props;
    const { isConfirmOpen, confirmationFunction } = this.state;
    
    if (id && (+id !== (newsletterRecurringCampaignDetails && newsletterRecurringCampaignDetails.id))) return null;
    const formData = (id && id !== undefined) ? newsletterRecurringCampaignDetails : {};
    return (
      <Row className="content-area">
        <LoaderComponent loader={this.props.loader}/>
        <Col xs={12} sm={12} className="business-setup p-3">
          <RecurringCampaignForm
            isNewsletter={true}
            business_id={business_id}
            submitData={this.submitData}
            initialValues={formData}
            template_list={this.state.template_list}
            // branch_list={this.state.branch_list}
            deleteResponse={(id)=>this.toggleConfirm(()=>this.deleteResponse(id))}
          />
        </Col>
        
        <ConfirmationModal
          header='Are you sure you want to delete?'
          subHeader='This cannot be undone.'
          isOpen={isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={confirmationFunction}
        />
      </Row>
    );
  }
}

NewsletterRecurringCampaignEditPage.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => {
  return {
    recurringCampaign : state.recurringCampaign,
    newsletterRecurringCampaignDetails: state.recurringCampaign.newsletter_recurring_campaign_details,
    loader: state.common.loader,
    userq: state.profile.user,
    business_userq: state.profile.business_user
  };
};


NewsletterRecurringCampaignEditPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
};


function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(recurringCampaignActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps,null,{forwardRef: true})(NewsletterRecurringCampaignEditPage);
