import {
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  Menu,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import React, { useState } from "react";
import { Search, Filter, SortSecond, Menu as MenuIcon } from "../../images/svg";
import { MenuItem } from "semantic-ui-react";
import { FPMenuItem } from "../common/new";
import DebounceSearchbar from "../common/new/FPFilter/DebounceSearchbar";

const sortOptions = [
  { label: "Oldest", value: "oldest" },
  { label: "Newest", value: "newest" },
];

const actionOption = [
  { label: "Mark all as Read", value: "read" },
  { label: "Mark all as Unread", value: "unread" },
  { label: "Bulk Actions", value: "bulk" },
];


const UserListActionBar = ({ toggleFilter, showFilter, showThreeDot= true, setSearchValue, showFilters = true }) => {
  const [showSearchBar, setShowSearchBar] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [type, setType] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event, type) => {
    setAnchorEl(event.currentTarget);
    setType(type);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchValue();
  };

  const handlesearch = (search) => {
    setSearchValue({ search: search });
  };

  const handleSortOrder = (order) => {
    setSearchValue({ order: order });
    handleClose()
  };

  return (
    <Box
      sx={{
        paddingRight: 16,
      }}
    >
      <IconButton
        size="small"
        style={{ color: "#00B5AD" }}
        onClick={() => setShowSearchBar(true)}
      >
        <Search style={{ color: "#1B1C1D" }} />
      </IconButton>
     {showFilters && <IconButton
        size="small"
        style={{
          color: "#00B5AD",
          backgroundColor: showFilter ? "#DDEFF0" : "transparent",
        }}
        onClick={toggleFilter}
      >
        <Filter style={{ color: showFilter ? "#00B5AD" : "#1B1C1D" }} />
      </IconButton>}
      <IconButton
        size="small"
        style={{ color: "#00B5AD" }}
        onClick={(event) => handleClick(event, "sort")}
      >
        <SortSecond style={{ color: "#1B1C1D" }} />
      </IconButton>
      {showThreeDot && <IconButton
        size="small"
        style={{ color: "#00B5AD" }}
        onClick={(event) => handleClick(event, "menu")}
      >
        <MenuIcon style={{ color: "#1B1C1D" }} />
      </IconButton>}

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={
          {
            // style: {
            //   maxHeight: ITEM_HEIGHT * 4.5,
            // },
          }
        }
      >
        {(type === "menu" ? actionOption : sortOptions).map(
          (option, index, array) => (
            <FPMenuItem
              onClick={() => handleSortOrder(option.value)}
              value={option.value}
              showDivider={index !== array.length - 1}
              dense={true}
            >
              {option.label}
            </FPMenuItem>
          )
        )}
      </Menu>

      {showSearchBar && (
        <Box
          sx={{
            position: "absolute",
            left: "0",
            top: "7px",
            width: "100%",
            padding: "10px",
          }}
        >
          <DebounceSearchbar
            searchValue={handlesearch}
            className="w-100"
            onClose={() => setShowSearchBar(false)}
          />
        </Box>
      )}
    </Box>
  );
};

export default UserListActionBar;
