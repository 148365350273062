/* eslint-disable */
import React from "react";
import { connect } from "react-redux";
import "./EmailBox.style.css";
import * as profileAction from "../../actions/accounts/profileAction";
import { bindActionCreators } from "redux";
import isEmail from "validator/lib/isEmail";
import closeCancel from "../../images/Cancel Cross.png";
import { Box } from "@material-ui/core";

class EmailBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      value: "",
      error: null,
      cnt: 0,
      disable: false,
    };
  }

  componentDidMount() {
    this.props.actions.getProfileInfo();
  }

  handleKeyDown = (evt) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      var value = this.state.value.trim();

      if (value && this.isValid(value)) {
        this.setState({
          items: [...this.state.items, this.state.value],
          value: "",
        });
        this.props.fields.push(value);
      }
    }
  };

  handleChange = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null,
    });
  };

  handleDelete = (item) => {
    const index = this.state.items.indexOf(item);
    this.props.fields.remove(index);
    this.setState({
      items: this.state.items.filter((i) => i !== item),
    });
  };

  handlePaste = (evt) => {
    var re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(re);

    if (emails) {
      var toBeAdded = emails.filter((email) => !this.isInList(email));

      this.setState({
        items: [...this.state.items, ...toBeAdded],
      });
    }
  };

  isValid(email) {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error });

      return false;
    }

    return true;
  }

  isInList(email) {
    return this.state.items.includes(email);
  }

  showEmails = () => {
    this.props.userData !== undefined
      ? this.setState({
          items: this.props.userData.communication_emails,
          cnt: 1,
        })
      : null;
  };
  render() {
    this.state.cnt === 0 ? this.showEmails() : "";
    return (
      <Box className="gap-15 " sx={{display: 'grid', gridTemplateColumns: 'auto auto'}}>
          {this.state.items.map((item) => (
            <div className="tag-item d-flex justify-content-between px-2 w-100" key={item}>
              {item}
             <div>
                <img
                  src={closeCancel}
                  type="button"
                  style={{ width: "19px", height: "19px" }}
                  className="button"
                  onClick={() => this.handleDelete(item)}
                />
              </div>
            </div>
          ))}
      
        <div className="w-100">
          <input
            className={
              "inputEmail " + (this.state.error && " has-error") + (this.state.items.length === 5 && " cursor")
            }
            value={this.state.value}
            placeholder="add email address"
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            onPaste={this.handlePaste}
            disabled={this.state.items.length === 5}
            style={{ borderRadius: "10px", borderColor: "#BFC2CB", width: '100%' }}
          />
          {this.state.error && <p className="error">{this.state.error}</p>}
          {this.state.items.length === 5 && <p className="message">{"You can add only 5 email id's"}</p>}
          </div>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.profile.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(profileAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(EmailBox);
