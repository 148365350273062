  import React, { useState } from "react";
  import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
  import { TabContent, TabPane } from "reactstrap";
  import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
  } from "@material-ui/core";
  import { FPToolTip } from "../../../components/common/new";
  import SortIcon from "../../../images/svg/Sort.svg";
  import PaginationComponent from "../../../components/common/Pagination";

  const columnHeaders = ["Keyword", "Impressions", "Traffic %"];
  const nameMapping = {
    keyword: "Keyword",
    impression: "Impressions",
    traffic: "Traffic %",
  };

  const performanceNavTab = "tab1";
  const selectedBox = "TOTAL_INTERACTIONS";

  function TrafficDrivingKeywordsTable(props) {
    const { location, listing_search_keywords } = props;
    console.log("locationlocationlocationlocation keyword",location)

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 100;

    const [sortConfig, setSortConfig] = useState({
      key: "keyword",
      direction: "asc",
    });

    const dataEntries = listing_search_keywords?.results || [];

    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = dataEntries.slice(startIndex, endIndex);

    const handleSort = (column) => {
      let direction = "asc";
      if (sortConfig.key === column && sortConfig.direction === "asc") {
        direction = "desc";
      }
      setSortConfig({ key: column, direction });
    };
    
    const sortedData = [...paginatedData].sort((a, b) => {
      const valueA = a[sortConfig.key];
      const valueB = b[sortConfig.key];
    
      if (sortConfig.key === "keyword") {
        return sortConfig.direction === "asc"
          ? valueA.localeCompare(valueB)
          : valueB.localeCompare(valueA);
      }
    
      const numA = parseFloat(valueA) || 0;
      const numB = parseFloat(valueB) || 0;
    
      return sortConfig.direction === "asc" ? numA - numB : numB - numA;
    });
    
    return (
      <div>
        <CardWrapper showDivider={false}>
          <TabContent activeTab={performanceNavTab}>
            <TabPane tabId={performanceNavTab}>
              <div className="ui-table-responsive">
                <Table className="fp-table">
                  <TableHead>
                    <TableRow>
                      {columnHeaders.map((header, index) => (
                        <TableCell
                          key={index}
                          style={{ textAlign: "center" }}
                          onClick={() =>
                            handleSort(header.toLowerCase().replace(" %", ""))
                          }
                        >
                          <FPToolTip
                            title={nameMapping[header.toLowerCase()] || header}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              <span className="text-truncate width-sm">
                                {nameMapping[header.toLowerCase()] || header}
                              </span>
                              <span>
                                {header === "Keyword" || "Traffic %" ? <SortIcon /> : ""}
                              </span>
                            </div>
                          </FPToolTip>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((entry, index) => (
                      <TableRow key={index}>
                        <TableCell
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <FPToolTip title={entry.keyword}>
                            <div
                              style={{ textAlign: "center" }}
                              className="text-truncate width-md"
                            >
                              {entry.keyword || "-"}
                            </div>
                          </FPToolTip>
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {entry.impression !== undefined
                            ? entry.impression
                            : "-"}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          {entry.traffic !== undefined
                            ? `${entry.traffic}%`
                            : "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
              {/* Pagination component */}
              <PaginationComponent
                count={listing_search_keywords?.count}
                location={location}
                pageSizeCookie={pageSize}
              />
            </TabPane>
          </TabContent>
        </CardWrapper>
      </div>
    );
  }

  export default TrafficDrivingKeywordsTable;
