import React from "react";
import { Table } from "antd";
import { NoData } from "../../components/common/new";
import LoaderComponent from "../../components/common/loaderComponent";

const NetPromoterData = [
  {
    branchName: "Mughal Delight",
    promoters: "75",
    detractors: "15",
    netPromoterScore: "61",
  },
  {
    branchName: "Pizza Hut",
    promoters: "77",
    detractors: "15",
    netPromoterScore: "83",
  },
  {
    branchName: "KFC",
    promoters: "57",
    detractors: "6",
    netPromoterScore: "93",
  },
  {
    branchName: "Burger King",
    promoters: "23",
    detractors: "11",
    netPromoterScore: "72",
  },
  {
    branchName: "Dominos",
    promoters: "56",
    detractors: "4",
    netPromoterScore: "21",
  },
];

const NetPromoterScoreTable = ({ loader, businessDetails }) => {
  const columns = [
    {
      title: "Branch Name",
      dataIndex: "branchName",
    },
    {
      title: "Promoters",
      dataIndex: "promoters",
      sorter: {
        compare: (a, b) => a.promoters - b.promoters,
      },
    },
    {
      title: "Detractors",
      dataIndex: "detractors",
      sorter: {
        compare: (a, b) => a.detractors - b.detractors,
      },
    },
    {
      title: "Net Promoter Score",
      dataIndex: "netPromoterScore",
      sorter: {
        compare: (a, b) => a.netPromoterScore - b.netPromoterScore,
      },
    },
  ];

  const dataSource = NetPromoterData.map((object, index) => ({
    branchName: object.branchName,
    promoters: object.promoters,
    detractors: object.detractors,
    netPromoterScore: object.netPromoterScore,
  }));

  if (dataSource.length === 0) {
    return <NoData className="mb-3" />;
  }
  return (
    <div className="px-3">
      <LoaderComponent loader={loader} />
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        showSorterTooltip={{ title: "" }}
        rowClassName={(record) => (businessDetails === record.branchName ? "highlight-row" : "")}
        className="fp-table-center"
      />
    </div>
  );
};

export default NetPromoterScoreTable;
