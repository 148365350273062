import React, { useEffect, forwardRef } from "react";
import { Card } from "reactstrap";
import "./listing.css";
import success_svg from "../../../images/success.svg";
import { CircularProgressbar } from "react-circular-progressbar";
import { Box, Dialog, DialogActions, IconButton, Slide } from "@mui/material";
import UpdateCategory from "./UpdateCategory";
import CloseIcon from "@material-ui/icons/Close";
import UpdatePhoneNumber from "./UpdatePhoneNumber";
import UpdateBusinessStatus from "./UpdateBusinessStatus";
import UpdateLocation from "./UpdateLocation";
import NotificationList from "./NotificationList";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as locationActions from "../../../actions/listing/listingLocationAction";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const BasicBusinessDetail = ({
  primaryDetail,
  basic_detail_data,
  gmc_categories_data,
  business_id,
  selectReso,
  updateDiffDataData,
  actions,
}) => {
  const notificationCount =
    (updateDiffDataData?.updated_attributes?.updated_attributes?.length || 0) +
    (updateDiffDataData?.updated_attributes?.new_attributes?.length || 0) +
    (updateDiffDataData?.updated_attributes?.delete_attributes?.length || 0) +
    (updateDiffDataData?.location_updated_data ? Object.keys(updateDiffDataData?.location_updated_data)?.length : 0);

  const addressString = [
    primaryDetail?.addressLines, // Spread the address lines array
    primaryDetail?.locality, // Add locality
    primaryDetail?.postalCode, // Add postal code
  ].join(",");

  const [open, setOpen] = React.useState({
    category: false,
    phoneNumber: false,
    location: false,
    businessStatus: false,
    notification: false,
  });
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (dialog, scrollType) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: true,
    }));
    setScroll(scrollType);
  };

  const handleClose = (dialog) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: false,
    }));
  };
  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };

  useEffect(() => {
    actions.getGmbLocationUpdateDiffData(business_id, selectReso);
  }, [actions, business_id, selectReso]);

  return (
    <div className="row detail-card-container">
      <div
        className="select-box-hover col-12 col-md-6 col-lg-3 p-1"
        onClick={() => handleClickOpen("category", "paper")}
      >
        <Card className="detail-card-item detail-special-card cursor-pointer">
          <div className="detail-card-content">
            <div style={{ width: "28px" }}>
              {basic_detail_data?.additional_categories === true ? (
                <img src={success_svg} height="28px" alt="" className="successImg" />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px", marginBottom: "0px !important" }}>
              Primary & Additional Categories
            </span>
            <span className="detail-business-card-review">Add relevant categories</span>
          </div>
        </Card>
      </div>
      <Dialog
        open={open.category}
        onClose={() => handleClose("category")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        TransitionComponent={Transition}
        maxWidth="lg"
        // TransitionComponent={Transition}
        fullWidth
        // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}

        sx={{
          "& .MuiDialog-paper": {
            // position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height:"90vh",
            width: "100%",
          },
        }}
      >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Primary & Additional Categories</span>
              <CloseIcon onClick={() => handleClose("category")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("category")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdateCategory
              primaryDetail={primaryDetail}
              gmc_categories_data={gmc_categories_data}
              business_id={business_id}
              selectReso={selectReso}
            />
          </div>
        </Box>
      </Dialog>

      <div
        className="select-box-hover col-12 col-md-6 col-lg-3  p-1"
        onClick={() => handleClickOpen("phoneNumber", "paper")}
      >
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.phone_number === true ? (
                <img src={success_svg} height="28px" alt="" className="successImg" />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>Phone Number</span>
            <span className="detail-business-card-review">
              Phone Number:
              {primaryDetail?.phoneNumbers?.primaryPhone}
            </span>
            {/* <span className="detail-business-card-review">
                  {primaryDetail?.phoneNumbers?.primaryPhone}
                </span> */}
          </div>
        </Card>
      </div>
      <Dialog
        open={open.phoneNumber}
        onClose={() => handleClose("phoneNumber")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        TransitionComponent={Transition}
        fullWidth
        // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
        sx={{
          "& .MuiDialog-paper": {
            // position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height:"90vh",
            width: "100%",
          },
        }}
      >
        <Box sx={style}>
          <div style={{ padding: "15px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Phone Number</span>
              <CloseIcon onClick={() => handleClose("phoneNumber")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("phoneNumber")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdatePhoneNumber primaryDetail={primaryDetail} />
          </div>
        </Box>
      </Dialog>
      <div
        className="select-box-hover col-12 col-md-6 col-lg-3  p-1"
        onClick={() => handleClickOpen("location", "paper")}
      >
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.location_exist === true ? (
                <img src={success_svg} height="28px" alt="" className="successImg" />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>Location Update</span>
            <span className="detail-business-card-review">{addressString}</span>
          </div>
        </Card>
      </div>
      <Dialog
        open={open.location}
        onClose={() => handleClose("location")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height:"90vh",
            width: "100%",
          },
        }}
        // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
      >
        <Box sx={style}>
          <div style={{ padding: "15px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update location</span>
              <CloseIcon onClick={() => handleClose("location")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("location")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdateLocation primaryDetail={primaryDetail} business_id={business_id} selectReso={selectReso} />
          </div>
        </Box>
      </Dialog>
      <div
        className="select-box-hover col-12 col-md-6 col-lg-3  p-1"
        onClick={() => handleClickOpen("businessStatus", "paper")}
      >
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.business_status === true ? (
                <img src={success_svg} height="28px" alt="" className="successImg" />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>Business Status</span>
            <span
              className="detail-business-card-review"
              style={{
                color:
                  primaryDetail?.openInfo_status === "OPEN"
                    ? "#12B76A"
                    : primaryDetail?.openInfo_status === "CLOSED_TEMPORARILY"
                    ? "#F04438"
                    : "#F04438",
              }}
            >
              {" "}
              {primaryDetail?.openInfo_status === "OPEN"
                ? "Open"
                : primaryDetail?.openInfo_status === "CLOSED_TEMPORARILY"
                ? "Closed Temporarily"
                : "Closed Permanently"}
            </span>
          </div>
        </Card>
      </div>
      <Dialog
        open={open.businessStatus}
        onClose={() => handleClose("businessStatus")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height:"90vh",
            width: "100%",
          },
        }}        // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
      >
        <Box sx={style}>
          <div style={{ padding: "15px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Business Status</span>
              <CloseIcon onClick={() => handleClose("businessStatus")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("businessStatus")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdateBusinessStatus primaryDetail={primaryDetail} business_id={business_id} selectReso={selectReso} />
          </div>
        </Box>
      </Dialog>
      <div
        className="select-box-hover col-12 col-md-6 col-lg-3  p-1"
        onClick={() => handleClickOpen("notification", "paper")}
      >
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.business_status === true ? (
                <img src={success_svg} height="28px" alt="" className="successImg" />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>Notification</span>
            <span className="detail-business-card-review">{notificationCount}</span>
          </div>
        </Card>
      </div>
      {notificationCount > 0 ? (
        <Dialog
          open={open.notification}
          onClose={() => handleClose("notification")}
          // scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          maxWidth="lg"
          fullWidth
          TransitionComponent={Transition}
          sx={{
            "& .MuiDialog-paper": {
              position: "fixed",
              // right: 0,
              // margin: 0,
              maxHeight: "100vh",
              height:"90vh",
              width: "100%",
            },
          }}          // sx={{
          //   "& .MuiDialog-paper": {
          //     overflowY: "hidden",
          //   },
          // }}
        >
          <Box sx={style}>
            <div style={{ padding: "15px" }}>
              <div
                className="d-flex mb-10"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontWeight: "600",
                  fontSize: "24px",
                  color: "#1A2544",
                  alignItems: "center",
                }}
              >
                <span>Google Updates</span>
                <CloseIcon onClick={() => handleClose("notification")} />
                {/* <DialogActions>
                  <IconButton onClick={() => handleClose("notification")}>
                    <CloseIcon />
                  </IconButton>
                </DialogActions> */}
              </div>
              <NotificationList
                primaryDetail={primaryDetail}
                business_id={business_id}
                selectReso={selectReso}
                updateDiffDataData={updateDiffDataData}
              />
            </div>
          </Box>
        </Dialog>
      ) : null}
    </div>
  );
};

// export default BasicBusinessDetail;

const mapStateToProps = (state) => ({
  updateDiffDataData: state?.gmbListingLocation?.updateDiffDataData,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasicBusinessDetail);
