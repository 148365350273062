import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";
import LoaderComponent from "../../../../../components/common/loaderComponent";

const BusinessList = (props) => {
  const { locationList, actions, business_id, type, queryStringData, loader } =
    props;
  const [selectedBusinesses, setSelectedBusinesses] = useState([]);
  const [loaderStatus, setLoaderStatus] = useState(true);

  useEffect(() => {
    // if (busines_status === false) {
    //   if (type) {
    //     actions.getGmbListingLoaction(
    //       business_id,
    //       queryStringData?.gmb_location_type,
    //       "verified",
    //       queryStringData?.completion_status,
    //       queryStringData?.openstatus,
    //       queryStringData?.search,
    //       type
    //     );
    //   }
    // } else {
    actions.getGmbListingLoaction(
      business_id,
      queryStringData?.gmb_location_type,
      "verified",
      queryStringData?.completion_status,
      queryStringData?.openstatus,
      queryStringData?.search,
      // "shipra mall",
      "",
      "food_menu"
    );
    // }
  }, [actions, business_id, type, queryStringData]);

  useEffect(() => {
    if (locationList) {
      setLoaderStatus(false);
      setSelectedBusinesses(locationList.map((business) => business.id));
    } else {
      setLoaderStatus(true); // Reset the loader status if locationList is empty or loading
    }
  }, [locationList]);

  console.log(
    "locationListlocationListlocationListlocationListlocationList",
    locationList?.length
  );

  console.log(
    "selectedBusinessesselectedBusinessesselectedBusinesses",
    selectedBusinesses?.length
  );

  const handleCheckboxChange = (business) => {
    if (selectedBusinesses.includes(business.id)) {
      setSelectedBusinesses(
        selectedBusinesses.filter((b) => b !== business.id)
      );
    } else {
      setSelectedBusinesses([...selectedBusinesses, business.id]);
    }
  };

  const handleSelectAllChange = () => {
    if (selectedBusinesses.length === locationList.length) {
      setSelectedBusinesses([]);
    } else {
      setSelectedBusinesses(locationList.map((business) => business.id));
    }
  };

  useEffect(() => {
    props.onSelectedBusinessCountChange(selectedBusinesses);
  }, [selectedBusinesses, props]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        {/* Select All Checkbox */}
        <h4 style={{fontSize: '18px', marginBottom: '4px'}}>All the changes will be applicable for only verified listings.</h4>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <input
              type="checkbox"
              onChange={handleSelectAllChange}
              id="select_all"
              checked={selectedBusinesses.length === locationList?.length}
              disabled={loaderStatus} // Disable while loading
            />
            <label
              style={{
                cursor: "pointer",
                fontSize: "15px",
                fontWeight: "400",
                marginBottom: "0px",
              }}
              htmlFor="select_all"
            >
              Select All Businesses asdfa
            </label>
          </div>
          <div>
            <span
              style={{
                color: "rgb(233 70 133)",
                fontSize: "14px",
                fontWeight: "600",
              }}
            >
              (selected {selectedBusinesses?.length} / total{" "}
              {locationList?.length})
            </span>
          </div>
        </div>

        {/* Loader and Content */}
        <LoaderComponent loader={loaderStatus} />
        {!loaderStatus &&
          locationList?.length > 0 &&
          locationList.map((business, index) => {
            const addressString = [
              business?.addressLines,
              business?.locality,
              business?.postalCode,
            ]
              .filter(Boolean)
              .join(", ");
            return (
              <div
                key={business.id}
                style={{
                  display: "flex",
                  gap: "10px",
                  border: "1px solid #6e6d6d",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <div style={{ paddingTop: "3px" }}>
                    <input
                      type="checkbox"
                      id={`business${index}`}
                      checked={selectedBusinesses.includes(business.id)}
                      onChange={() => handleCheckboxChange(business)}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor={`business${index}`}
                      style={{ cursor: "pointer", marginBottom: "0px" }}
                    >
                      <h2
                        style={{
                          color: "#000000d9",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginBottom: "2px",
                        }}
                      >
                        {business?.title}
                      </h2>
                      <p
                        style={{
                          color: "#8b8b8d",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {addressString}
                      </p>
                    </label>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    locationList: state.gmbListingLocation.location_list,
    error: state.gmbListingLocation.location_error,
    loader: state.common.loader,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BusinessList);
