import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as overviewActions from "../../../actions/listing/overviewAction";
import * as locationActions from "../../../actions/listing/listingLocationAction";
import { Chip } from "@mui/material";
import select_svg from "../../../images/select.svg";
import FPButton from "../../common/new/FPFilter/Listing/FPButton";
import * as listingUtils from "../../../libs/listingToast";
const AllGoogleUpdates = (props) => {
  const {
    business_id,
    all_google_updates_list,
    actions,
    listingdetailactions,
  } = props;

  console.log(
    "all_google_updates_listall_google_updates_list",
    all_google_updates_list
  );

  const [selectedItems, setSelectedItems] = useState({});
  const [afterSelectedItems, setAfterSelectedItems] = useState({});

  useEffect(() => {
    actions.getGmbListingBulkGoogleUpdates(business_id);
    if (all_google_updates_list) {
      setAfterSelectedItems(all_google_updates_list);
    }
  }, [actions, business_id]);

  const renderChip = (
    item,
    idx,
    attribute,
    // handleChipClickService,
    select_svg,
    type,
    actual_selected_attributes
  ) => {
    console.log(
      "itemitemitemitemitemitemitemitemitemitemilsdufyhsdilfjd",
      item
    );
    const actual_select =
      actual_selected_attributes?.[item?.parent]?.values?.[0];

    return (
      <Chip
        key={idx}
        label={item.displayName}
        // onClick={() => handleChipClickService(attribute, idx)}
        deleteIcon={
          item.updated_data?.values[0] ? (
            <img src={select_svg} alt="selectImage" />
          ) : null
        }
        // onDelete={
        //   item.updated_data?.values[0]
        //     ? () => handleChipClickService(attribute, idx)
        //     : null
        // }
        variant={item.updated_data?.values[0] ? "filled" : "outlined"}
        sx={{
          backgroundColor: item.updated_data?.values[0] ? "#bfe0e2" : "#F0F1F3",
          color: item.updated_data?.values[0] ? "#00b5ad" : "default",
          "& .MuiChip-deleteIcon": {
            color: item.updated_data?.values[0] ? "white" : "default",
          },
          fontSize: "18px",
          fontWeight: "400",
          padding: "12px",
          border: "0",
        }}
      />
    );
  };

  const renderChipDelete = (
    item,
    idx,
    attribute,
    // handleChipClickService,
    select_svg,
    type,
    actual_selected_attributes
  ) => {
    const actual_select =
      actual_selected_attributes?.[item?.parent]?.values?.[0];

    // Determine if the chip is in "selected" state when type is "delete_actual"
    // type === "delete_actual";
    return (
      <Chip
        key={idx}
        label={item?.displayName || "unknown"} // Fallback for label
        // onClick={() =>
        //   type === "delete_actual" || "delete_suggestion"
        //     ? handleChipClickService(attribute, idx, null, type)
        //     : null
        // }
        deleteIcon={
          type === "delete_actual" && actual_select ? (
            <img src={select_svg} alt="selectImage" />
          ) : null
        }
        // onDelete={
        //   type === "delete_actual" && actual_select
        //     ? () => handleChipClickService(attribute, idx)
        //     : null
        // }
        variant={
          type === "delete_actual" && actual_select ? "filled" : "outlined"
        }
        sx={{
          backgroundColor:
            type === "delete_actual" && actual_select ? "#bfe0e2" : "#F0F1F3",
          color:
            type === "delete_actual" && actual_select ? "#00b5ad" : "default",
          "& .MuiChip-deleteIcon": {
            color:
              type === "delete_actual" && actual_select ? "white" : "default",
          },
          fontSize: "18px",
          fontWeight: "400",
          padding: "12px",
          border: "0",
        }}
      />
    );
  };

  const renderRepeatedEnumChip = (
    repeatedEnum,
    indexenum,
    attribute,
    parentIndex,
    // handleChipClickService,
    select_svg,
    valueType,
    type,
    update_type,
    item,
    actual_selected_attributes
  ) => {
    // For ENUM type
    console.log(
      "actual_selected_attributesactual_selected_attributesactual_selected_attributesactual_selected_attributes",
      actual_selected_attributes
    );
    if (update_type === "updated") {
      if (valueType === "ENUM") {
        console.log(
          "selectedItemsselectedItemsselectedItemsselectedItemsselectedItems",
          selectedItems
        );
        const actualSelect =
          actual_selected_attributes?.[item?.parent]?.values || [];
        console.log("actualSelectactualSelectactualSelect", actualSelect);
        let isSelected = actualSelect.includes(repeatedEnum.value);
        console.log(
          "isSelectedisSelectedisSelectedisSelectedisSelected",
          isSelected
        );
        // Handle the "delete_suggest" type if needed
        if (type === "delete_suggest") {
          isSelected = item.updated_data.values.includes(repeatedEnum.value);
        }

        console.log(
          "ENUM Value:",
          repeatedEnum.value,
          "isSelected:",
          isSelected
        );

        return (
          <Chip
            key={indexenum}
            label={repeatedEnum.displayName}
            // onClick={() =>
            //   update_type === "new"
            //     ? handleSingleSelectChipClick(
            //         attribute,
            //         parentIndex,
            //         indexenum,
            //         repeatedEnum
            //       )
            //     : handleChipClickService(attribute, parentIndex, indexenum)
            // }
            deleteIcon={
              isSelected &&
              (type === "delete_actual" || type === "delete_suggest") ? (
                <img src={select_svg} alt="selectImage" />
              ) : null
            }
            // onDelete={
            //   isSelected &&
            //   (type === "delete_actual" || type === "delete_suggest")
            //     ? () =>
            //         handleChipClickService(attribute, parentIndex, indexenum)
            //     : null
            // }
            variant={
              isSelected &&
              (type === "delete_actual" || type === "delete_suggest")
                ? "filled"
                : "outlined"
            }
            sx={{
              backgroundColor:
                isSelected &&
                (type === "delete_actual" || type === "delete_suggest")
                  ? "#bfe0e2"
                  : "#F0F1F3",
              color:
                isSelected &&
                (type === "delete_actual" || type === "delete_suggest")
                  ? "#00b5ad"
                  : "default",
              "& .MuiChip-deleteIcon": {
                color:
                  isSelected &&
                  (type === "delete_actual" || type === "delete_suggest")
                    ? "white"
                    : "default",
              },
              fontSize: "18px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
              marginBottom: "15px",
              marginRight: "15px",
            }}
          />
        );
      }

      // For REPEATED_ENUM type
      if (valueType === "REPEATED_ENUM") {
        const actual_select =
          actual_selected_attributes?.[item?.parent]?.repeatedEnumValue || {};
        const setValues = actual_select?.setValues || [];
        let isSelected = setValues.includes(repeatedEnum.value);

        if (type === "delete_suggest") {
          // Ensure updated_data.repeatedEnumValue.setValues is an array
          const updatedSetValues =
            item.updated_data.repeatedEnumValue?.setValues || [];
          isSelected = updatedSetValues.includes(repeatedEnum.value);
        }

        console.log(
          "REPEATED_ENUM Value:",
          repeatedEnum.value,
          "isSelected:",
          isSelected
        );

        return (
          <Chip
            key={indexenum}
            label={repeatedEnum.displayName}
            // onClick={() =>
            //   update_type === "new"
            //     ? handleSingleSelectChipClick(
            //         attribute,
            //         parentIndex,
            //         indexenum,
            //         repeatedEnum
            //       )
            //     : handleChipClickService(attribute, parentIndex, indexenum)
            // }
            deleteIcon={
              isSelected &&
              (type === "delete_actual" || type === "delete_suggest") ? (
                <img src={select_svg} alt="selectImage" />
              ) : null
            }
            // onDelete={
            //   isSelected &&
            //   (type === "delete_actual" || type === "delete_suggest")
            //     ? () =>
            //         handleChipClickService(attribute, parentIndex, indexenum)
            //     : null
            // }
            variant={isSelected ? "filled" : "outlined"}
            sx={{
              backgroundColor: isSelected ? "#bfe0e2" : "#F0F1F3",
              color: isSelected ? "#00b5ad" : "default",
              "& .MuiChip-deleteIcon": {
                color: isSelected ? "white" : "default",
              },
              fontSize: "18px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
              marginBottom: "15px",
              marginRight: "15px",
            }}
          />
        );
      }
    } else if (update_type === "deleted") {
      if (valueType === "ENUM") {
        const actual_select =
          actual_selected_attributes?.[item?.parent]?.values || [];
        const isSelected = actual_select.includes(repeatedEnum.value);

        console.log(
          "ENUM Value:",
          repeatedEnum.value,
          "isSelected:",
          isSelected
        );

        return (
          <Chip
            key={indexenum}
            label={repeatedEnum.displayName}
            // onClick={() =>
            //   update_type === "new"
            //     ? handleSingleSelectChipClick(
            //         attribute,
            //         parentIndex,
            //         indexenum,
            //         repeatedEnum
            //       )
            //     : handleChipClickService(attribute, parentIndex, indexenum)
            // }
            deleteIcon={
              isSelected && type === "delete_actual" ? (
                <img src={select_svg} alt="selectImage" />
              ) : null
            }
            // onDelete={
            //   isSelected && type === "delete_actual"
            //     ? () =>
            //         handleChipClickService(attribute, parentIndex, indexenum)
            //     : null
            // }
            variant={
              isSelected && type === "delete_actual" ? "filled" : "outlined"
            }
            sx={{
              backgroundColor:
                isSelected && type === "delete_actual" ? "#bfe0e2" : "#F0F1F3",
              color:
                isSelected && type === "delete_actual" ? "#00b5ad" : "default",
              "& .MuiChip-deleteIcon": {
                color:
                  isSelected && type === "delete_actual" ? "white" : "default",
              },
              fontSize: "18px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
              marginBottom: "15px",
              marginRight: "15px",
            }}
          />
        );
      }

      // For REPEATED_ENUM type
      if (valueType === "REPEATED_ENUM") {
        const actual_select =
          actual_selected_attributes?.[item?.parent]?.repeatedEnumValue || {};
        const setValues = actual_select?.setValues || [];
        const isSelected = setValues.includes(repeatedEnum.value);

        console.log(
          "REPEATED_ENUM Value:",
          repeatedEnum.value,
          "isSelected:",
          isSelected
        );

        return (
          <Chip
            key={indexenum}
            label={repeatedEnum.displayName}
            // onClick={() =>
            //   update_type === "new"
            //     ? handleSingleSelectChipClick(
            //         attribute,
            //         parentIndex,
            //         indexenum,
            //         repeatedEnum
            //       )
            //     : handleChipClickService(attribute, parentIndex, indexenum)
            // }
            deleteIcon={
              isSelected && type === "delete_actual" ? (
                <img src={select_svg} alt="selectImage" />
              ) : null
            }
            // onDelete={
            //   isSelected && type === "delete_actual"
            //     ? () =>
            //         handleChipClickService(attribute, parentIndex, indexenum)
            //     : null
            // }
            variant={
              isSelected && type === "delete_actual" ? "filled" : "outlined"
            }
            sx={{
              backgroundColor:
                isSelected && type === "delete_actual" ? "#bfe0e2" : "#F0F1F3",
              color:
                isSelected && type === "delete_actual" ? "#00b5ad" : "default",
              "& .MuiChip-deleteIcon": {
                color:
                  isSelected && type === "delete_actual" ? "white" : "default",
              },
              fontSize: "18px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
              marginBottom: "15px",
              marginRight: "15px",
            }}
          />
        );
      }
      return null;
    }
    return null;
  };

  const [itemSelectedItems, setItemSelectedItems] = useState(new Set());

  // useEffect(() => {
  //   itemSelectedItems.forEach((item) => {
  //     // setItemSelectedItems(item);
  //   });
  // }, [itemSelectedItems]);

  console.log(
    "itemSelectedItemsitemSelectedItemsitemSelectedItemsitemSelectedItems",
    itemSelectedItems
  );

  const handleCheckboxChange = (item, isChecked, id) => {
    setItemSelectedItems((prevSelectedItems) => {
      const newSelectedItems = new Set(prevSelectedItems);
      const newItem = { item, id }; // Create an object containing both item and id

      if (isChecked) {
        newSelectedItems.add(newItem); // Add the object if checked
      } else {
        // Find and delete the matching object
        newSelectedItems.forEach((selectedItem) => {
          if (selectedItem.id === id && selectedItem.item === item) {
            newSelectedItems.delete(selectedItem);
          }
        });
      }

      return newSelectedItems;
    });
  };

  const generatePayload = (items, location_ID) => {
    console.log("itemsitemsitemsitemsitemsitemsitemsitemsitemsitems", items);
    console.log("location_IDlocation_IDlocation_IDlocation_ID", location_ID);
    const payload = [];
    const deletePayload = []; // To handle deletions separately

    items.forEach((item) => {
      console.log(
        "fasdfsdfitemitemitemitemitemitemitemitemitemitem",
        location_ID,
        item.id
      );
      console.log("items.iditems.iditems.iditems.iditems.iditems.id", item.id);
      if (item.id === location_ID) {
        if (item?.item?.update_type !== "deleted") {
          if (item?.item?.valueType === "ENUM") {
            const enumValues = item?.item.updated_data?.values || [];

            if (enumValues.length > 0) {
              payload.push({
                name: item?.item?.parent,
                valueType: item?.item?.valueType,
                values: enumValues,
              });
            }
          } else if (item?.item?.valueType === "REPEATED_ENUM") {
            const selectedValues = item?.item?.valueMetadata
              .filter((enumItem) => enumItem.selected)
              .map((enumItem) => enumItem.value);
            const unselectedValues = item?.item?.valueMetadata
              .filter((enumItem) => !enumItem.selected)
              .map((enumItem) => enumItem.value);

            payload.push({
              name: item?.item?.parent,
              valueType: item?.item?.valueType,
              repeatedEnumValue: {
                setValues: selectedValues,
                unsetValues: unselectedValues,
              },
            });
          } else if (item?.item?.valueType === "BOOL") {
            if (item?.item?.updated_data?.values[0]) {
              payload.push({
                name: item?.item?.parent,
                valueType: item?.item?.valueType,
                values: [item?.item?.updated_data?.values[0]], // Accessing the selected value
              });
            }
          }
        }

        // Handle delete case
        else if (item?.item?.update_type === "deleted") {
          if (item?.item?.valueType === "ENUM") {
            deletePayload.push(item?.item?.parent);
          } else if (item?.item?.valueType === "REPEATED_ENUM") {
            deletePayload.push(item?.item?.parent);
          } else if (item?.item?.valueType === "BOOL") {
            deletePayload.push(item?.item?.parent);
          }
        }
      }
    });

    return { payload, deletePayload };
  };

  const updateAttributeHandler = async (id) => {
    const { payload, deletePayload } = generatePayload(itemSelectedItems, id);
    const selectReso = id;
    const restodetail = {
      business_id,
      selectReso,
    };

    if (deletePayload.length > 0) {
      await listingdetailactions.getGmbListingUpdateListing(
        {
          deleted_attrs: deletePayload,
          attributes: payload,
          type: "updateAttributes",
          notificationType: true,
        },
        restodetail
      );
    } else {
      await listingdetailactions.getGmbListingUpdateListing(
        {
          attributes: payload,
          type: "updateAttributes",
          notificationType: true,
        },
        restodetail
      );
    }
    // listingUtils?.displayMessage(
    //   "info",
    //   "Listing getting updated, It may take a few minutes."
    // );
    // await actions.getGmbListingRefreshListing(business_id, id);

    // listingUtils?.displayMessage("close"); // Close the "refresh process" toast

    // listingUtils?.displayMessage("positive", "Listing updated successfully.");
    // await actions.getGmbListingPrimaryDetail(business_id, id);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          paddingBottom: "10px",
        }}
      >
        {all_google_updates_list?.length > 0 &&
          all_google_updates_list.map((business, index) => {
            const addressString = [business?.address].join(", ");
            return (
              <div
                key={business.id}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  border: "1px solid #6e6d6d",
                  borderRadius: "20px",
                  padding: "10px",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <div>
                    <label
                      htmlFor={`business${index}`}
                      style={{ marginBottom: "0px" }}
                    >
                      <h2
                        style={{
                          color: "#000000d9",
                          fontSize: "16px",
                          fontWeight: "500",
                          marginBottom: "2px",
                        }}
                      >
                        {business?.title}
                      </h2>
                      <p
                        style={{
                          color: "#8b8b8d",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {addressString}
                      </p>
                    </label>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {Object.keys(
                    business?.google_updates?.updated_attributes
                  ).map((attribute, idx) => {
                    if (attribute === "new_attributes") {
                      return (
                        <div key={idx} className="enumOption">
                          <p>New Attributes - Suggested By Google</p>

                          {business?.google_updates?.updated_attributes?.[
                            attribute
                          ]?.length > 0 ? (
                            business?.google_updates?.updated_attributes?.[
                              attribute
                            ]?.map((item, itemIdx) => {
                              console.log(
                                "dfsdfjgsdifgasidufgoasdfhoasdjfoiasdjfioasjdofie",
                                item?.groupDisplayName
                              );
                              const isChecked = Array.from(
                                itemSelectedItems
                              ).some(
                                (selectedItem) =>
                                  selectedItem.item === item &&
                                  selectedItem.id === business?.id
                              );

                              console.log("New Attribute Item:", item);
                              if (item?.valueType === "BOOL") {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            item,
                                            e.target.checked,
                                            business?.id
                                          )
                                        }
                                        style={{
                                          transform: "scale(1.5)", // Increase or decrease this value as needed
                                          margin: "10px", // Adjust the spacing if needed
                                        }}
                                      />
                                      <p>{item?.groupDisplayName}</p>
                                    </div>
                                    {renderChip(
                                      item,
                                      itemIdx,
                                      attribute,
                                      //   handleChipClickService,
                                      select_svg
                                    )}
                                  </>
                                );
                              } else if (
                                item?.valueType === "ENUM" ||
                                item?.valueType === "REPEATED_ENUM"
                              ) {
                                return (
                                  <>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "start",
                                        alignItems: "center",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            item,
                                            e.target.checked,
                                            business?.id
                                          )
                                        }
                                        style={{
                                          transform: "scale(1.5)", // Increase or decrease this value as needed
                                          margin: "10px", // Adjust the spacing if needed
                                        }}
                                      />
                                      <p>{item?.groupDisplayName}</p>
                                    </div>
                                    <p key={itemIdx}>{item?.displayName}</p>
                                    {Array.isArray(item?.valueMetadata) &&
                                      item.valueMetadata.map(
                                        (repeatedEnum, indexenum) =>
                                          renderRepeatedEnumChip(
                                            repeatedEnum,
                                            indexenum,
                                            attribute,
                                            itemIdx,
                                            // handleChipClickService,
                                            select_svg,
                                            item?.valueType,
                                            item,
                                            idx
                                          )
                                      )}
                                  </>
                                );
                              }
                              return null; // Ensure a return for each branch
                            })
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              -- No New Attributes --
                            </div>
                          )}
                        </div>
                      );
                    } else if (attribute === "updated_attributes") {
                      return (
                        <div key={idx} className="enumOption">
                          <p>Updated Attributes</p>
                          {business?.google_updates?.updated_attributes?.[
                            attribute
                          ]?.length > 0 ? (
                            business?.google_updates?.updated_attributes?.[
                              attribute
                            ]?.map((item, itemIdx) => {
                              console.log("Updated Attribute Item:", item);
                              const isChecked = Array.from(
                                itemSelectedItems
                              ).some(
                                (selectedItem) =>
                                  selectedItem.item === item &&
                                  selectedItem.id === business?.id
                              );
                              // Handle BOOL valueType
                              if (item?.valueType === "BOOL") {
                                return (
                                  <>
                                    {/* <div key={itemIdx} className="enumOption"> */}
                                    {/* Render chips for updated attributes */}
                                    <p style={{ marginTop: "10px" }}>
                                      Actual Attributes
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        gap: "15px",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        {/* <input
                                                type="checkbox"
                                                checked={isChecked}
                                                onChange={(e) =>
                                                  handleCheckboxChange(
                                                    item,
                                                    e.target.checked
                                                  )
                                                }
                                              /> */}
                                      </div>

                                      {renderChip(
                                        item,
                                        itemIdx,
                                        attribute,
                                        // handleChipClickService,
                                        select_svg,
                                        "update_actual",
                                        business?.google_updates
                                          ?.actual_selected_attributes
                                      )}
                                    </div>
                                    <p style={{ marginTop: "10px" }}>
                                      Suggested By Google to update
                                    </p>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexWrap: "wrap",
                                        marginTop: "10px",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              item,
                                              e.target.checked,
                                              business?.id
                                            )
                                          }
                                          style={{
                                            transform: "scale(1.5)", // Increase or decrease this value as needed
                                            margin: "10px", // Adjust the spacing if needed
                                          }}
                                        />
                                        <p>{item?.groupDisplayName}</p>
                                      </div>

                                      {renderChip(
                                        item,
                                        itemIdx,
                                        attribute,
                                        // handleChipClickService,
                                        select_svg,
                                        "update_suggest"
                                      )}
                                    </div>
                                    {/* </div> */}
                                  </>
                                );
                              }
                              // Handle ENUM or REPEATED_ENUM valueType
                              else if (
                                item?.valueType === "ENUM" ||
                                item?.valueType === "REPEATED_ENUM"
                              ) {
                                return (
                                  <>
                                    <div style={{ marginTop: "10px" }}>
                                      <p>Actual Attributes</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        {/* <input
                                              type="checkbox"
                                              checked={isChecked}
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  item,
                                                  e.target.checked
                                                )
                                              }
                                            /> */}
                                      </div>
                                      <p>{item?.displayName}</p>
                                      {Array.isArray(item?.valueMetadata) &&
                                        item.valueMetadata.map(
                                          (repeatedEnum, indexenum) =>
                                            renderRepeatedEnumChip(
                                              repeatedEnum,
                                              indexenum,
                                              attribute,
                                              itemIdx,
                                              // handleChipClickService,
                                              select_svg,
                                              item?.valueType,
                                              "delete_actual",
                                              item?.update_type,
                                              item,
                                              business?.google_updates
                                                ?.actual_selected_attributes
                                            )
                                        )}
                                    </div>
                                    <div>
                                      <p>Suggested By Google to update</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              item,
                                              e.target.checked,
                                              business?.id
                                            )
                                          }
                                          style={{
                                            transform: "scale(1.5)", // Increase or decrease this value as needed
                                            margin: "10px", // Adjust the spacing if needed
                                          }}
                                        />
                                        <p>{item?.groupDisplayName}</p>
                                      </div>
                                      <p>{item?.displayName}</p>
                                      {Array.isArray(item?.valueMetadata) &&
                                        item.valueMetadata.map(
                                          (repeatedEnum, indexenum) =>
                                            renderRepeatedEnumChip(
                                              repeatedEnum,
                                              indexenum,
                                              attribute,
                                              itemIdx,
                                              // handleChipClickService,
                                              select_svg,
                                              item?.valueType,
                                              "delete_suggest",
                                              item?.update_type,
                                              item,
                                              business?.google_updates
                                                ?.actual_selected_attributes
                                            )
                                        )}
                                    </div>
                                  </>
                                );
                              }
                              return null; // Return null if none of the conditions match
                            })
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              -- No Update Attributes --
                            </div>
                          )}
                        </div>
                      );
                    } else if (attribute === "delete_attributes") {
                      return (
                        <div key={idx} className="enumOption">
                          <p>Delete Attributes</p>
                          {business?.google_updates?.updated_attributes?.[
                            attribute
                          ]?.length > 0 ? (
                            business?.google_updates?.updated_attributes?.[
                              attribute
                            ]?.map((item, itemIdx) => {
                              const isChecked = Array.from(
                                itemSelectedItems
                              ).some(
                                (selectedItem) =>
                                  selectedItem.item === item &&
                                  selectedItem.id === business?.id
                              );
                              console.log(
                                "attributeattributeattributeattributeattributeattributeattributeattributeattributeattributeattributeattribute",
                                attribute
                              );
                              if (item?.valueType === "BOOL") {
                                return (
                                  <>
                                    {/* <div key={itemIdx} className="enumOption"> */}
                                    <div>
                                      <p>Actual Attributes</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        {/* <input
                                              type="checkbox"
                                              checked={isChecked}
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  item,
                                                  e.target.checked
                                                )
                                              }
                                            /> */}
                                      </div>
                                      {renderChipDelete(
                                        item,
                                        itemIdx,
                                        attribute,
                                        //   handleChipClickService,
                                        select_svg,
                                        "delete_actual",
                                        business?.google_updates
                                          ?.actual_selected_attributes
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        marginTop: "10px",
                                      }}
                                    >
                                      <p>Suggested By Google to remove</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "start",
                                          alignItems: "center",
                                        }}
                                      >
                                        <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              item,
                                              e.target.checked,
                                              business?.id
                                            )
                                          }
                                          style={{
                                            transform: "scale(1.5)", // Increase or decrease this value as needed
                                            margin: "10px", // Adjust the spacing if needed
                                          }}
                                        />
                                        <p>{item?.groupDisplayName}</p>
                                      </div>
                                      {renderChipDelete(
                                        item,
                                        itemIdx,
                                        attribute,
                                        //   handleChipClickService,
                                        select_svg,
                                        "delete_suggest",
                                        business?.google_updates
                                          ?.actual_selected_attributes
                                      )}
                                    </div>
                                  </>
                                );
                              } else if (
                                item.valueType === "ENUM" ||
                                item.valueType === "REPEATED_ENUM"
                              ) {
                                return (
                                  <>
                                    <div>
                                      <p>Actual Attributes</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        {/* <input
                                              type="checkbox"
                                              checked={isChecked}
                                              onChange={(e) =>
                                                handleCheckboxChange(
                                                  item,
                                                  e.target.checked
                                                )
                                              }
                                            /> */}
                                      </div>

                                      <p>{item?.displayName}</p>
                                      {Array.isArray(item?.valueMetadata) &&
                                        item.valueMetadata.map(
                                          (repeatedEnum, indexenum) =>
                                            renderRepeatedEnumChip(
                                              repeatedEnum,
                                              indexenum,
                                              attribute,
                                              itemIdx,
                                              // handleChipClickService,
                                              select_svg,
                                              item?.valueType,
                                              "delete_actual",
                                              item?.update_type,
                                              item,
                                              business?.google_updates
                                                ?.actual_selected_attributes
                                            )
                                        )}
                                    </div>
                                    <div>
                                      <p>Suggested By Google to remove</p>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p>{item?.groupDisplayName}</p>
                                        <input
                                          type="checkbox"
                                          checked={isChecked}
                                          onChange={(e) =>
                                            handleCheckboxChange(
                                              item,
                                              e.target.checked,
                                              business?.id
                                            )
                                          }
                                          style={{
                                            transform: "scale(1.5)", // Increase or decrease this value as needed
                                            margin: "10px", // Adjust the spacing if needed
                                          }}
                                        />
                                      </div>
                                      <p>{item?.displayName}</p>
                                      {Array.isArray(item?.valueMetadata) &&
                                        item.valueMetadata.map(
                                          (repeatedEnum, indexenum) =>
                                            renderRepeatedEnumChip(
                                              repeatedEnum,
                                              indexenum,
                                              attribute,
                                              itemIdx,
                                              // handleChipClickService,
                                              select_svg,
                                              item?.valueType,
                                              "delete_suggest",
                                              item?.update_type,
                                              item,
                                              business?.google_updates
                                                ?.actual_selected_attributes
                                            )
                                        )}
                                    </div>
                                  </>
                                );
                              }
                              return null; // Ensure a return for each branch
                            })
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              -- No Delete Attributes --
                            </div>
                          )}
                        </div>
                      );
                    }
                  })}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: "#fff",
                      zIndex: 1000,
                      paddingBlock: "10px",
                    }}
                  >
                    <FPButton
                      label="Update"
                      size="small"
                      onClick={() => updateAttributeHandler(business?.id)}
                      // disabled={primaryDetail?.is_suspend}
                    />
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    all_google_updates_list: state.gmbListingOverview.all_google_updates_list,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(overviewActions, dispatch),
    listingdetailactions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AllGoogleUpdates);
