import React, { useState } from "react";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import { Table, TableBody, TableCell, TableHead, TableRow, Box } from "@material-ui/core";
import SortIcon from "../../images/svg/Sort.svg";
import { FPToolTip } from "../common/new";
import "./SmartResponse.css";
import editIcon from "../../images/svg/EditPen.svg";
import deleteIcon from "../../images/svg/trash.svg";
import ConfirmationModal from "../common/ConfirmationModal";
import { sortChangeColor } from "../../libs/utils";

function AIReviewReply(props) {
  const { aiResponseOnCheck, ordering, sortFunction, aiResponseList, activeUser, businessId, deleteAiResponse } = props;
  const [isConfirm, setIsConfirm] = useState(false);
  const [aiId, setAiId] = useState(null);

  const sort =
    ordering && ordering[0] === "-" ? { on: ordering.slice(1), by: "descending" } : { on: ordering, by: "ascending" };

  const toggleConfirm = () => {
    deleteAiResponse(aiId);
    setIsConfirm(false);
  };

  const handleClick = (id) => {
    setAiId(id);
    setIsConfirm(!isConfirm);
  };

  return (
    <div>
      {aiResponseOnCheck && (
        <Card body className="my-2">
          <div className="ui-table-responsive">
            <Table className="fp-table fp-table-center">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {/* <Checkbox label="S.No" id="all" className="d-flex"> Select All </Checkbox> */}
                    S.No
                  </TableCell>
                  <TableCell style={{ textAlign: "justify" }}>Reply Title</TableCell>
                  <TableCell>First Line</TableCell>
                  <TableCell>Last Line</TableCell>

                  <TableCell
                    className="text-nowrap"
                    // sorted={sort.on === "review_type" ? sort.by : null}
                    onClick={() => sortFunction("review_type")}
                    style={
                      sort.on === "review_type"
                        ? {
                            ...sortChangeColor("#1f9095", "#d9f0f0"),
                          }
                        : null
                    }
                  >
                    Review Type
                    {sortFunction && sort.on !== "review_type" && <SortIcon />}
                  </TableCell>

                  <TableCell
                    className="text-nowrap"
                    // sorted={sort.on === "auto" ? sort.by : null}
                    onClick={() => sortFunction("auto")}
                    style={
                      sort.on === "auto"
                        ? {
                            ...sortChangeColor("#1f9095", "#d9f0f0"),
                          }
                        : null
                    }
                  >
                    Auto Reply
                    {sortFunction && sort.on !== "auto" && <SortIcon />}
                  </TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {aiResponseList && aiResponseList.length ? (
                  aiResponseList.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          {/* <span className="tableSpecificDataBold d-flex">
                                    <Checkbox label={index + 1} />
                                  </span> */}
                          {index + 1}
                        </TableCell>
                        <TableCell>
                          {activeUser.is_business_owner ? (
                            <div
                              className="d-flex"
                              // title={item.title}
                              // to={`/${businessId}/ai-response?id=${item.id}`}
                            >
                              <span
                                className="threeDot"
                                style={{
                                  width: "140px",
                                  color: "#1B1C1D",
                                  textDecoration: "underline",
                                  textTransform: "capitalize",
                                }}
                              >
                                {item.title}
                              </span>
                            </div>
                          ) : (
                            <label className="width-md">{item.title}</label>
                          )}
                        </TableCell>
                        <TableCell>
                          <span style={{ textAlign: "justify" }}></span>
                          {item.first_line}
                        </TableCell>
                        <TableCell>
                          <span>{item.last_line}</span>
                        </TableCell>

                        <TableCell onClick={() => sortFunction("review_type")}>
                          <span style={sort.on === "review_type" ? sortChangeColor("#1f9095") : null}>
                            {item.review_type}
                          </span>
                        </TableCell>

                        <TableCell onClick={() => sortFunction("auto")}>
                          <span style={sort.on === "auto" ? sortChangeColor("#1f9095") : null}>
                            {item.auto ? "On" : "Off"}
                          </span>
                        </TableCell>
                        <TableCell>
                          <div className="actionButton">
                            {activeUser.is_business_owner ? (
                              <Link title={item.title} to={`/${businessId}/ai-response?id=${item.id}`}>
                                <FPToolTip title={"Edit"}>
                                  <img src={editIcon} role="button" alt="edit" />
                                </FPToolTip>
                              </Link>
                            ) : (
                              <Box></Box>
                            )}

                            <FPToolTip title={"Delete"}>
                              <img src={deleteIcon} role="button" alt="delete" onClick={() => handleClick(item.id)} />
                            </FPToolTip>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell className="text-center" colSpan={10}>
                      No records Found{" "}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </Card>
      )}
      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={`Are you sure you want to delete this Ai reply?`}
        isOpen={isConfirm}
        toggle={() => setIsConfirm(!isConfirm)}
        onConfirm={() => toggleConfirm()}
      />
    </div>
  );
}

export default AIReviewReply;
