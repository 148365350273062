import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as commonAction from "../common/commonAction";

export const ServiceTicketListingSuccess = (data) => {
  return { type: types.SERVICE_TICKET_DETAILS_SUCCESS, data };
};

export const ServiceTicketListingFailure = (data) => {
  return { type: types.SERVICE_TICKET_DETAILS_FAILURE, data };
};

export const ServiceTicketListing = (data, business_id) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(utils.format(URLS.SERVICE_TICKET, [business_id]), { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ServiceTicketListingSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ServiceTicketListingFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export const ServiceRequestTypeSuccess = (data) => {
  return { type: types.SERVICE_REQUEST_TYPE_SUCCESS, data };
};

export const ServiceRequestTypeFailure = (data) => {
  return { type: types.SERVICE_REQUEST_TYPE_FAILURE, data };
};

export const ServiceRequestTypeList = (business_id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.SERVICE_REQUEST_TYPE, [business_id]);
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(ServiceRequestTypeSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(ServiceRequestTypeFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export const AddServiceRequestTypeSuccess = (data) => {
  return { type: types.ADD_SERVICE_REQUEST_TYPE_SUCCESS, data };
};

export const AddServiceRequestTypeFailure = (data) => {
  return { type: types.ADD_SERVICE_REQUEST_TYPE_FAILURE, data };
};

export const AddServiceRequestType = (formData, business_id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = utils.format(URLS.SERVICE_REQUEST_TYPE, [business_id]);
    return axios_instance
      .post(url, formData)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(AddServiceRequestTypeSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(AddServiceRequestTypeFailure(err?.response.data));
        throw err?.response?.data;
      });
  };
};

export const patchServiceRequestType = (data, business_id, ticket_id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .patch(utils.format(URLS.SERVICE_UPDATE_REQUEST_TYPE, [business_id, ticket_id]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
};

export const createServiceTicketSuccess = (data) => {
  return { type: types.GET_SERVICE_TICKET_SUCCESS, data };
};

export const createServiceTicketFailure = (data) => {
  return { type: types.GET_SERVICE_TICKET_FAILURE, data };
};

export const createServiceTicket = (formData, business_id) => {
  return (dispatch) => {
    const url = utils.format(URLS.SERVICE_TICKET, [business_id]);
    return axios_instance
      .post(url, formData)
      .then((res) => {
        dispatch(createServiceTicketSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(createServiceTicketFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export const editServiceRequest = (data, business_id, ticket_id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .patch(utils.format(URLS.EDIT_SERVICE_TICKET, [business_id, ticket_id]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
};

export const addServiceRequestComment = (business_id, comment_id, data) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .post(utils.format(URLS.SERVICE_REQUEST_COMMENTS, [business_id, comment_id]), data)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        throw err.response.data;
      });
  };
};

export function serviceDeleteSuccess(data) {
  return { type: types.DELETE_SERVICE_REQUEST_SUCCESS, data };
}

export function serviceDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.DELETE_SERVICE_REQUEST_FAILURE, data };
}

export const deleteServiceRequest = (business_id, ticket_id) => {
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .delete(utils.format(URLS.SERVICE_UPDATE_REQUEST_TYPE, [business_id, ticket_id]))
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(serviceDeleteSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(serviceDeleteFailure(err));
        throw err.response.data;
      });
  };
};
