import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import React from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import queryString from "query-string";
import cookie from "react-cookies";
import EnterpriseList from "../../components/business/EnterpriseList";
import FormModal from "../../components/common/FormModal";
import PaginationComponent from "../../components/common/Pagination";
import BranchSetupForm from "../../components/business/BranchSetupForm";
import EnterpriseUserForm from "../../components/business/EnterpriseUserForm";
import MemberForm from "../../components/team/MemberForm";
import MemberComponent from "../../components/team/Member";
import SmartResponseList from "../../components/business/SmartResponseList";
import StaffResponseList from "../../components/business/StaffResponseList";
import LimitModal from "../../components/common/LimitModal";
import CentralComplaintsSetup from "../../components/business/CentralComplaintsSetup";
import CentralSetupInsights from "../../components/business/CentralSetupInsights";
import CompetitorsList from "../../components/competitors/CompetitorsList";
import CompetitorsForm from "../../components/competitors/CompetitorsForm";
import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import * as CompetitorActions from "../../actions/competitors/competitorsActions";
import * as ProfileActions from "../../actions/accounts/profileAction";
import * as TeamActions from "../../actions/team/teamAction";
import * as CommonActions from "../../actions/common/commonAction";
import * as TicketActions from "../../actions/ticket/ticketActions";
import * as DepartmentActions from "../../actions/department/departmentActions";
import * as DesignationActions from "../../actions/designation/designationActions";
import * as TicketEscalationActions from "../../actions/ticketEscalation/ticketEscalationActions";
import * as SocialProfileActions from "../../actions/socialProfile/socialProfileActions";
import * as utils from "../../libs/utils";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import LockedComponent from "../../components/common/LockedComponent/LockedComponent";
import AssignMultiLocationForm from "../../components/team/AssignMultiLocationForm";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import MenuResponseList from "../../components/business/MenuResponseList";
import SetupTicketsPage from "../../components/tickets/SetupTicketsPage";
import BranchRootHeader from "../setup/BranchRootHeader";
import SetupIntegrationPage from "../../components/tickets/SetupIntegrationPage";
import DepartmentForm from "../../components/team/DepartmentForm";
import DesignationForm from "../../components/team/DesignationForm";
import ReviewWidget from "../../components/business/ReviewWidget";
import ServiceRequestRule from "../../components/business/ServiceRequestRule";
import OfflineStore from "../../components/business/OfflineStoreAlerts/OfflineStoreAlerts";
// import BusinessProfilePage from '../../containers/business/BusinessProfilePage';

class CentralUserPage extends React.Component {
  constructor(props) {
    super(props);
    const pageSize = cookie.load("pageSize") || {};
    const {
      history,
      location: { pathname },
    } = props;
    this.toggleTab = this.toggleTab.bind(this);
    this.getEnterPriselist = this.getEnterPriselist.bind(this);
    this.toggleBranch = this.toggleBranch.bind(this);
    this.toggleUserModal = this.toggleUserModal.bind(this);
    this.handleBranchSetup = this.handleBranchSetup.bind(this);
    this.saveOwner = this.saveOwner.bind(this);
    this.deleteOwner = this.deleteOwner.bind(this);
    this.editLocation = this.editLocation.bind(this);
    this.toggleMember = this.toggleMember.bind(this);
    this.submitComplaintLinks = this.submitComplaintLinks.bind(this);
    this.editTeamMember = this.editTeamMember.bind(this);
    this.getCompetitorsList = this.getCompetitorsList.bind(this);
    this.deleteCompetitor = this.deleteCompetitor.bind(this);
    this.deleteStaff = this.deleteStaff.bind(this);
    this.deleteMenu = this.deleteMenu.bind(this);
    this.getDepartmentList = this.getDepartmentList.bind(this);
    this.getDesignationList = this.getDesignationList.bind(this);
    this.toggleDepartment = this.toggleDepartment.bind(this);
    this.addDepartment = this.addDepartment.bind(this);
    this.toggleDesignation = this.toggleDesignation.bind(this);
    this.addDesignation = this.addDesignation.bind(this);
    this.toggleCompetitor = this.toggleCompetitor.bind(this);
    this.toggleCentralLimitModal = this.toggleCentralLimitModal.bind(this);
    this.addCompetitors = this.addCompetitors.bind(this);
    this.teamList = this.teamList.bind(this);
    this.addTeamMember = this.addTeamMember.bind(this);
    this.deleteMember = this.deleteMember.bind(this);
    this.getSmartResponseList = this.getSmartResponseList.bind(this);
    this.getAiResponseList = this.getAiResponseList.bind(this);
    this.getStaffList = this.getStaffList.bind(this);
    this.toggleTicketResolution = this.toggleTicketResolution.bind(this);
    this.getCreatedRulesData = this.getCreatedRulesData.bind(this);
    this.addResponse = this.addResponse.bind(this);
    this.addStaff = this.addStaff.bind(this);
    this.addAiResponse = this.addAiResponse.bind(this);
    this.addRules = this.addRules.bind(this);
    this.functionalityToggle = this.functionalityToggle.bind(this);
    this.getStateOptions = this.getStateOptions.bind(this);
    this.handleMultiLocationModal = this.handleMultiLocationModal.bind(this);
    this.AssignMultiLocationSubmit = this.AssignMultiLocationSubmit.bind(this);
    this.addMenu = this.addMenu.bind(this);
    this.submitTicketResolutionData =
      this.submitTicketResolutionData.bind(this);
    this.deleteAllSurvey = this.deleteAllSurvey.bind(this);
    this.toggleProductIssueReasons = this.toggleProductIssueReasons.bind(this);
    this.toggleTicketCommentBox = this.toggleTicketCommentBox.bind(this);
    this.toggleRestrictTicketClosingWithoutSurvey =
      this.toggleRestrictTicketClosingWithoutSurvey.bind(this);
    this.deleteResponse = this.deleteResponse.bind(this);
    this.deleteAiResponse = this.deleteAiResponse.bind(this);
    this.updatedNavBar = this.updatedNavBar.bind(this);
    this.updateTeamParam = this.updateTeamParam.bind(this);
    this.handleSubTeamTab = this.handleSubTeamTab.bind(this);
    this.state = {
      activeTab: (props.location.state && props.location.state.tab) || "1",
      branchActiveTab: "A",
      selectedBranch: null,
      isAddCompetitorOpen: false,
      isLimitModalOpen: false,
      limitsMessage: "",
      headerText: "",
      compBrandName: "",
      compId: null,
      add: false,
      departmentName: "",
      departmentId: null,
      designationName: "",
      designationId: null,
      isAddDepartmentOpen: false,
      isAddDesignationOpen: false,
      params: {
        1: `page_size=${pageSize.c_enterprise || "10"}`,
        2: `page_size=${pageSize.c_team || "10"}`,
        3: `page_size=${pageSize.c_sr || "10"}`,
        4: "",
        5: `page_size=${pageSize.c_competitor || "10"}`,
        6: `page_size=${pageSize.c_insights || "10"}`,
        7: `page_size=${pageSize.c_ticket_automation || "10"}`,
        8: `page_size=${pageSize.c_setup_integration || "10"}`,
        9: `page_size=${pageSize.c_department || "10"}`,
        10: `page_size=${pageSize.c_designation || "10"}`,
        11: `page_size=${pageSize.c_staff || "10"}`,
        12: `page_size=${pageSize.c_menu || "10"}`,
      },
      teamParams: {
        Team: `page=1&page_size=10`,
        Department: `page=1&page_size=10`,
        Designation: `page=1&page_size=10`,
      },
      locks: {},
      loader: {},
      isMultiLocationOpen: false,
      totalTeamMember: [],
      menuList: null,
      resolutionEnabled:
        this.props.businessInfo &&
        this.props.businessInfo?.enable_ticket_resolution
          ? true
          : false,
      resolutionDataId:
        this.props.resolutionDetail &&
        this.props.resolutionDetail.results &&
        this.props.resolutionDetail.results.length > 0
          ? this.props.resolutionDetail.results[0].id
          : null,
      surveyFormLoading: false,
      updateNavTeam: "Team",
    };
    this.titles = [
      "Locations",
      "Team",
      "Smart Response",
      "Complaints",
      "Competitors",
      "Insights",
      "Ticket Automation",
      "Setup Integration",
      "Setup Staff",
      "Setup Menu",
      "Setup Widget",
      "Service Request Automation",
      "Offline Store Alerts",
    ];
    history.replace({
      pathname,
      search: this.state.params[this.state.activeTab],
    });
  }

  componentDidMount() {
    const { params } = this.state;
    this.setState({
      loader: {
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
      },
      surveyFormLoading: true,
    });
    const locks = {};
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.getEnterPriselist(queryString.parse(params["1"]));
    this.props.actions.getRelevantCountries(business_id);
    this.teamList(queryString.parse(params["2"]));
    this.getSmartResponseList(queryString.parse(params["3"]));
    this.getAiResponseList(queryString.parse(params["3"]));
    this.getStaffList(queryString.parse(params["11"]));
    this.getMenuList(queryString.parse(params["12"]));

    this.getComplaintLinks(queryString.parse(params["4"]));
    this.getCompetitorsList(queryString.parse(params["5"])).catch(
      (err) => err === "lock" && (locks["5"] = "Competitors module is locked.")
    );
    this.getInsightsCategoryList(queryString.parse(params["6"])).catch(
      (err) => err === "lock" && (locks["6"] = "Insights module is locked.")
    );
    this.getCreatedRulesData(queryString.parse(params["7"]));
    this.props.actions.getBranchList(business_id, true);
    // this.props.actions.facebookStatus(business_id);
    // this.props.actions.instaStatus(business_id);
    // this.props.actions.whatsappStatus(business_id);
    this.props.ticketActions.ticketResolution(business_id).then((res) => {
      if (res.data && res.data.results && res.data.results.length > 0) {
        this.setState({ resolutionDataId: res.data.results[0].id });
      }
      this.setState({ surveyFormLoading: false });
    });
    this.props.ticketEscalationActions.getTicketEscalation(business_id);
    this.getDepartmentList(queryString.parse(params["9"]));
    this.getDesignationList(queryString.parse(params["10"]));
    this.setState({ locks });

    return axios_instance
      .get(URLS.AGENCY_USERS + "?business_id=" + business_id)
      .then((res) => {
        this.setState({ totalTeamMember: res.data.user_list });
      });
  }

  getStateOptions = (country) => {
    const queryparams = { country };
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.actions.getRelevantStates(queryparams, business_id);
  };

  componentDidUpdate(prevProps, prevState) {
    const {
      location: { search },
      match: {
        params: { business_id },
      },
    } = this.props;
    const {
      location: { search: prevSearch },
    } = prevProps;
    const { activeTab, loader } = this.state;
    const { activeTab: prevActiveTab } = prevState;
    if (search !== prevSearch && activeTab === prevActiveTab) {
      this.setState({ loader: { ...loader, [activeTab]: true } });
      if (activeTab === "1") this.getEnterPriselist(queryString.parse(search));
      else if (activeTab === "2") {
        if (this.state.updateNavTeam === "Team") {
          this.teamList(queryString.parse(search));
        } else if (this.state.updateNavTeam === "Department") {
          this.getDepartmentList(queryString.parse(search));
        } else if (this.state.updateNavTeam === "Designation") {
          this.getDesignationList(queryString.parse(search));
        }
      } else if (activeTab === "3") {
        this.getSmartResponseList(queryString.parse(search));
        // this.getAiResponseList(queryString.parse(search));
      } else if (activeTab === "5")
        this.getCompetitorsList(queryString.parse(search));
      else if (activeTab === "6")
        this.getInsightsCategoryList(queryString.parse(search));
      else if (activeTab === "7")
        this.getCreatedRulesData(queryString.parse(search));
      else if (activeTab === "9") this.getStaffList(queryString.parse(search));
      else if (activeTab === "10") this.getMenuList(queryString.parse(search));
      // else if(activeTab === '11')
      //   this.getBranchSetup();
    }
    document.title = this.titles[parseInt(activeTab) - 1];
    if (this.props.businessInfo != prevProps.businessInfo) {
      if (
        this.props.businessInfo &&
        this.props.businessInfo?.enable_ticket_resolution
      ) {
        const {
          match: {
            params: { business_id },
          },
        } = this.props;
        this.props.ticketActions.ticketResolution(business_id).then((res) => {
          if (res.data && res.data.results && res.data.results.length > 0) {
            this.setState({ resolutionDataId: res.data.results[0].id });
          }
          this.setState({ surveyFormLoading: false });
        });
      }
      this.setState({
        resolutionEnabled: this.props.businessInfo?.enable_ticket_resolution,
      });
    }
  }

  getDepartmentList(queryparams) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.departmentActions
      .getDepartment(queryparams, business_id)
      .then(() =>
        this.setState({ loader: { ...this.state.loader, 2: false } })
      );
  }

  getDesignationList(queryparams) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.designationActions
      .getDesignation(queryparams, business_id)
      .then(() =>
        this.setState({ loader: { ...this.state.loader, 2: false } })
      );
  }

  toggleTicketResolution(value) {
    this.setState({ surveyFormLoading: true });
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const new_url = `${URLS.TICKET}enable-resolution/?business=${business_id}&resolution=${value}`;
    return axios_instance.get(new_url).then((res) => {
      this.props.actions.businessDetail({ id: business_id });
      if (res.data.success && value) {
        this.setState({ resolutionEnabled: true });
        this.props.ticketActions.ticketResolution(business_id).then((res) => {
          if (res.data && res.data.results && res.data.results.length > 0) {
            this.setState({ resolutionDataId: res.data.results[0].id });
          }
          this.setState({ surveyFormLoading: false });
        });
      } else {
        this.setState({ resolutionEnabled: false });
      }
      this.setState({ surveyFormLoading: false });
    });
  }

  toggleTab(tab) {
    const {
      history,
      location: { pathname, search },
    } = this.props;
    const { activeTab, params } = this.state;
    let query = { ...params };

    // console.log(query, "query");
    query[activeTab] = search;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
        params: query,
      });
      history.push({ pathname, search: params[tab] });
    }
  }

  toggleConfirm = (confirmationFunction) => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen,
      confirmationFunction,
    });
  };

  //Enterprise Location Functions starts from here
  getEnterPriselist(queryparmas) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const data = {
      id: business_id,
    };
    return this.props.actions
      .enterpriseList(data, queryparmas)
      .then(() =>
        this.setState({ loader: { ...this.state.loader, 1: false } })
      );
  }

  toggleBranch(branch) {
    const { isEditLocationOpen } = this.state;
    if (!isEditLocationOpen) {
      this.setState({ selectedBranch: branch });
    } else this.setState({ selectedBranch: null });
    this.setState({ isEditLocationOpen: !isEditLocationOpen });
  }

  toggleUserModal(branch) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const { isUserModalOpen } = this.state;
    if (!isUserModalOpen) {
      this.props.actions.enterpriseUserList(business_id, branch.id).then(() =>
        this.setState({
          isUserModalOpen: !isUserModalOpen,
          selectedBranch: branch,
        })
      );
    } else {
      this.setState({
        isUserModalOpen: !isUserModalOpen,
        selectedBranch: null,
      });
    }
  }

  handleBranchSetup(values) {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
      enterpriseList,
    } = this.props;
    const data = {
      id: business_id,
    };
    return this.props.actions
      .setupBranchLocation({ ...values, id: business_id })
      .then((res) => {
        // eslint-disable-line
        this.toggleBranch();
        this.props.actions.enterpriseList(data, queryString.parse(search));
        if (enterpriseList.count === 1)
          this.props.profileActions.getProfileInfo();
      });
  }

  saveOwner(data) {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const { selectedBranch } = this.state;
    const detail = {
      ...data,
      business_id: +business_id,
      id: selectedBranch.id,
    };
    return this.props.actions.userAdd(detail).then(() => {
      this.toggleUserModal();
      this.props.actions.enterpriseList(
        { id: business_id },
        queryString.parse(search)
      );
    });
  }

  deleteOwner(branch, user, callback) {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const detail = { business_id: +business_id, id: branch.id, user: user.id };
    return this.props.actions.deleteUser(detail).then(() => {
      this.props.actions.enterpriseList(
        { id: business_id },
        queryString.parse(search)
      );
      callback();
    });
  }

  editLocation(data) {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    return this.props.actions
      .editBranchLocation({ ...data, business_id: business_id })
      .then((res) => {
        // eslint-disable-line
        this.toggleBranch();
        this.props.actions.enterpriseList(
          { id: business_id },
          queryString.parse(search)
        );
      });
  }

  //Team Functions Starts From here
  toggleMember(item) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const { isEditMemberOpen } = this.state;
    if (!isEditMemberOpen && item !== "add") {
      this.setState({ add: false });
      this.props.teamActions.getTeam(item, business_id);
    } else if (!isEditMemberOpen && item === "add") {
      this.setState({ add: true });
    }
    this.setState({ isEditMemberOpen: !isEditMemberOpen });
  }

  handleMultiLocationModal() {
    this.setState({ isMultiLocationOpen: !this.state.isMultiLocationOpen });
  }

  editTeamMember(value) {
    const contant_num_str = value.contact_number
    let new_value = {...value, contact_number: contant_num_str.replace(/ /g, "")}
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    let location = [];
    if (value.location && Array.isArray(value.location)) {
      value.location.map((val) =>  val !== "" ? location.push(val) : "")
    }
    new_value = {...new_value, location : [...location]}
    return this.props.teamActions.teamEdit(new_value, business_id).then(() => {
      this.toggleMember();
      this.teamList(queryString.parse(search));
      this.getEnterPriselist(queryString.parse(search));
    });
  }

  teamList(query) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.teamActions
      .teamList(query, business_id)
      .then(() =>
        this.setState({ loader: { ...this.state.loader, 2: false } })
      );
  }

  addTeamMember(value) {
    const contant_num_str = value.contact_number
    let new_value = {...value, contact_number: contant_num_str.replace(/ /g, "")}
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    let location = [];
    if (value.location && Array.isArray(value.location)) {
      value.location.map((val) =>  val !== "" ? location.push(val) : "")
    }
    new_value = {...new_value, location : [...location]}
    return this.props.teamActions
      .teamAdd(new_value, business_id)
      .then((response) => {
        // eslint-disable-line
        this.teamList(queryString.parse(search));
        this.toggleMember();
        axios_instance.get(URLS.AGENCY_USERS + "?business_id=" + business_id).then((res) => {
          this.setState({ totalTeamMember: res.data.user_list });
        });
      });
  }

  addTicketEscalation(value, callback) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.ticketEscalationActions
      .ticketEscalationAdd(value, business_id)
      .then((res) => {
        this.setState({ loader: { ...this.state.loader, 7: true } });
        this.props.ticketEscalationActions
          .getTicketEscalation(business_id)
          .then(() => {
            callback();
            this.setState({ loader: { ...this.state.loader, 7: false } });
          });
      });
  }

  editTicketEscalation(data, callback) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.ticketEscalationActions
      .ticketEscalationEdit(data, business_id)
      .then((res) => {
        this.setState({ loader: { ...this.state.loader, 7: true } });
        this.props.ticketEscalationActions
          .getTicketEscalation(business_id)
          .then(() => {
            callback();
            this.setState({ loader: { ...this.state.loader, 7: false } });
          });
      });
  }

  deleteTicketEscalation(data) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.setState({ loader: { ...this.state.loader, 7: true } });
    return this.props.ticketEscalationActions
      .ticketEscalationDelete(data, business_id)
      .then((res) => {
        this.props.ticketEscalationActions
          .getTicketEscalation(business_id)
          .then(() => {
            this.setState({ loader: { ...this.state.loader, 7: false } });
          });
      });
  }

  addServiceReminder(value, callback) {
    callback();
    console.log(value, value);
    // const {
    //   match: {
    //     params: { business_id },
    //   },
    // } = this.props;
    // return this.props.ticketEscalationActions
    //   .ticketEscalationAdd(value, business_id)
    //   .then((res) => {
    //     this.setState({ loader: { ...this.state.loader, 7: true } });
    //     this.props.ticketEscalationActions
    //       .getTicketEscalation(business_id)
    //       .then(() => {
    //         callback();
    //         this.setState({ loader: { ...this.state.loader, 7: false } });
    //       });
    //   });
  }
  editServiceReminder(data, callback) {
    console.log(data);
    callback();
    // const {
    //   match: {
    //     params: { business_id },
    //   },
    // } = this.props;
    // return this.props.ticketEscalationActions
    //   .ticketEscalationEdit(data, business_id)
    //   .then((res) => {
    //     this.setState({ loader: { ...this.state.loader, 7: true } });
    //     this.props.ticketEscalationActions
    //       .getTicketEscalation(business_id)
    //       .then(() => {
    //         callback();
    //         this.setState({ loader: { ...this.state.loader, 7: false } });
    //       });
    //   });
  }

  deleteServiceReminder(data) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.setState({ loader: { ...this.state.loader, 7: true } });
    return this.props.ticketEscalationActions
      .ticketEscalationDelete(data, business_id)
      .then((res) => {
        this.props.ticketEscalationActions
          .getTicketEscalation(business_id)
          .then(() => {
            this.setState({ loader: { ...this.state.loader, 7: false } });
          });
      });
  }

  AssignMultiLocationSubmit(value) {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    value.business = business_id;
    return this.props.teamActions
      .assignMultiLocation(value)
      .then((response) => {
        // eslint-disable-line
        if (response.data.success === true) {
          this.teamList(queryString.parse(search));
          this.props.actions.enterpriseList(
            { id: business_id },
            queryString.parse(search)
          );
          this.handleMultiLocationModal();
        } else {
          alert(response.data.message);
        }
      });
  }

  deleteMember(member) {
    const {
      history,
      location: { pathname, search },
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.teamActions
      .teamMemberDelete(member, business_id)
      .then(() => {
        // eslint-disable-line
        const query = queryString.parse(search);
        if (query.page) {
          history.push(pathname);
        } else {
          this.teamList(queryString.parse(search));
        }
        this.getEnterPriselist(queryString.parse(search));
        axios_instance.get(URLS.AGENCY_USERS + "?business_id=" + business_id).then((res) => {
          this.setState({ totalTeamMember: res.data.user_list });
        });
      });
  }
  //Competitors response functions starts from here
  getCompetitorsList(queryparams) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.compActions
      .getCompetitorsList(business_id, queryparams)
      .then(() =>
        this.setState({ loader: { ...this.state.loader, 5: false } })
      );
  }
  toggleCompetitor(compBrand) {
    const { isAddCompetitorOpen } = this.state;
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    if (!isAddCompetitorOpen || (compBrand && !isAddCompetitorOpen)) {
      compBrand
        ? this.setState({
            compBrandName: compBrand.name,
            headerText: "Edit the Competitor",
            compId: compBrand.id,
          })
        : this.setState({ headerText: "Add a Competitor" });
      this.props.compActions
        .getCompetitorsList(business_id, queryString.parse(search))
        .then(() => {
          this.setState({
            addComp: true,
            isAddCompetitorOpen: !isAddCompetitorOpen,
          });
        });
    } else {
      this.setState({
        isAddCompetitorOpen: !isAddCompetitorOpen,
        compBrandName: "",
        compId: null,
      });
      this.props.compActions.getCompetitorsList(
        business_id,
        queryString.parse(search)
      );
    }
  }
  toggleCentralLimitModal(error) {
    const { isLimitModalOpen } = this.state;
    //const { match : { params : { business_id } }, location: { search } } = this.props;
    !isLimitModalOpen && this.toggleCompetitor();
    this.setState({
      isLimitModalOpen: !isLimitModalOpen,
      limitsMessage: error,
    });
    // this.props.compActions.getCompeptitorsList(business_id,);
  }
  addCompetitors = (comps) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const { compId } = this.state;
    let functionToRun;
    if (compId) {
      functionToRun = this.props.compActions.editCompetitor({
        comps,
        business_id,
        compId,
      });
    } else {
      functionToRun = this.props.compActions.addCompetitors(comps, business_id);
    }
    return functionToRun.then((response) => {
      // eslint-disable-line
      this.toggleCompetitor();
    });
  };
  deleteCompetitor = (id) => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    return this.props.compActions.deleteCompetitor(business_id, id).then(() => {
      this.getCompetitorsList(queryString.parse(search));
    });
  };
  addDepartment = (deps) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const { departmentId } = this.state;
    let functionToRun;
    this.setState({ loader: { ...this.state.loader, 2: true } });
    if (departmentId) {
      functionToRun = this.props.departmentActions.departmentEdit({
        deps,
        business_id,
        departmentId,
      });
    } else {
      functionToRun = this.props.departmentActions.departmentAdd(
        deps,
        business_id
      );
    }
    return functionToRun.then((response) => {
      this.toggleDepartment();
    });
  };

  toggleDepartment(dep, stopApiCall) {
    const { isAddDepartmentOpen } = this.state;
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const { params } = this.state;
    if (!isAddDepartmentOpen || (dep && !isAddDepartmentOpen)) {
      dep
        ? this.setState({
            departmentName: dep.name,
            headerText: "Edit the Department",
            departmentId: dep.id,
          })
        : this.setState({ headerText: "Add a Department" });
      this.setState({
        isAddDepartmentOpen: !isAddDepartmentOpen,
      });
      !stopApiCall &&
        this.props.departmentActions
          .getDepartment(queryString.parse(search), business_id)
          .then(() => {
            this.setState({ loader: { ...this.state.loader, 2: false } });
          });
    } else {
      this.setState({
        isAddDepartmentOpen: !isAddDepartmentOpen,
        departmentName: "",
        departmentId: null,
      });
      !stopApiCall &&
        this.props.departmentActions
          .getDepartment(queryString.parse(search), business_id)
          .then(() => {
            this.setState({ loader: { ...this.state.loader, 2: false } });
          });
    }
  }
  deleteDepartment = (id) => {
    const {
      history,
      match: {
        params: { business_id },
      },
      location,
    } = this.props;
    const { pathname } = location;
    const { params } = this.state;
    this.setState({ loader: { ...this.state.loader, 2: true } });
    return this.props.departmentActions
      .departmentDelete(business_id, id)
      .then(() => {
        history.push({
          pathname,
          search: `page_size=10`,
        });
        this.props.departmentActions
          .getDepartment(queryString.parse(params["9"]), business_id)
          .then(() => {
            this.setState({ loader: { ...this.state.loader, 2: false } });
          })
          .catch(() => {
            this.setState({ loader: { ...this.state.loader, 2: false } });
          });
      });
  };

  addDesignation = (design) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const { designationId } = this.state;
    let functionToRun;
    this.setState({ loader: { ...this.state.loader, 2: true } });
    if (designationId) {
      functionToRun = this.props.designationActions.designationEdit({
        design,
        business_id,
        designationId,
      });
    } else {
      functionToRun = this.props.designationActions.designationAdd(
        design,
        business_id
      );
    }
    return functionToRun.then((response) => {
      this.toggleDesignation();
    });
  };

  toggleDesignation(design, stopApiCall) {
    const { isAddDesignationOpen } = this.state;
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    const { params } = this.state;
    if (!isAddDesignationOpen || (design && !isAddDesignationOpen)) {
      design
        ? this.setState({
            designationName: design.name,
            headerText: "Edit the Designation",
            designationId: design.id,
          })
        : this.setState({ headerText: "Add a Designation" });
      this.setState({
        isAddDesignationOpen: !isAddDesignationOpen,
      });
      !stopApiCall &&
        this.props.designationActions
          .getDesignation(queryString.parse(search), business_id)
          .then(() => {
            this.setState({ loader: { ...this.state.loader, 2: false } });
          });
    } else {
      this.setState({
        isAddDesignationOpen: !isAddDesignationOpen,
        designationName: "",
        designationId: null,
      });
      !stopApiCall &&
        this.props.designationActions
          .getDesignation(queryString.parse(search), business_id)
          .then(() => {
            this.setState({ loader: { ...this.state.loader, 2: false } });
          });
    }
  }

  deleteDesignation = (id) => {
    const {
      history,
      match: {
        params: { business_id },
      },
      location,
    } = this.props;
    const { pathname } = location;
    const { params } = this.state;
    this.setState({ loader: { ...this.state.loader, 2: true } });
    return this.props.designationActions
      .designationDelete(business_id, id)
      .then(() => {
        history.push({
          pathname,
          search: `page_size=10`,
        });
        this.props.designationActions
          .getDesignation(queryString.parse(params["10"]), business_id)
          .then(() => {
            this.setState({ loader: { ...this.state.loader, 2: false } });
          })
          .catch(() => {
            this.setState({ loader: { ...this.state.loader, 2: false } });
          });
      });
  };

  deleteStaff = (id) => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    return this.props.actions.deleteStaff(business_id, id).then(() => {
      this.getStaffList(queryString.parse(search));
    });
  };

  deleteMenu = (id) => {
    const {
      match: {
        params: { business_id },
      },
      location: { search },
    } = this.props;
    return axios_instance
      .delete(utils.format(`${URLS.MENU_LIST}${id}/`, [business_id]))
      .then(() => {
        this.getMenuList(queryString.parse(search));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Smart Response Functions starts from here
  getSmartResponseList(queryparmas) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const data = {
      id: business_id,
    };
    return this.props.actions
      .smartResponseList(data, queryparmas)
      .then(() =>
        this.setState({ loader: { ...this.state.loader, 3: false } })
      );
  }

  // staff response functions starts from here
  getStaffList(queryParams) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const data = {
      business_id: business_id,
      ...queryParams,
    };
    return this.props.actions
      .staffList(data)
      .then(() => this.setState({ loader: { ...this.state.loader, 9: false } }))
      .catch(() =>
        this.setState({ loader: { ...this.state.loader, 9: false } })
      );
  }

  getMenuList(data) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const { ...params } = data;
    return axios_instance
      .get(utils.format(URLS.MENU_LIST, [business_id]), { params })
      .then((res) => {
        this.setState({
          menuList: res.data,
          loader: { ...this.state.loader, 10: false },
        });
      })
      .catch((err) => {
        this.setState({ loader: { ...this.state.loader, 10: false } });
        console.log(err);
      });
  }

  getAiResponseList(queryparmas) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const data = {
      id: business_id,
    };
    return this.props.actions
      .aiResponseList(data, queryparmas)
      .then(() =>
        this.setState({ loader: { ...this.state.loader, 3: false } })
      );
  }

  addResponse() {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    history.push(`/${business_id}/smart-response`);
  }

  addStaff() {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    history.push(`/${business_id}/staff`);
  }

  addMenu() {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    history.push(`/${business_id}/menu`);
  }

  addAiResponse() {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    history.push(`/${business_id}/ai-response`);
  }
  functionalityToggle(value) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.actions.toggleSmartResponse(business_id, {
      is_response_allowed: value,
    });
  }

  //Complaint functions start from here
  getComplaintLinks = () => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions
      .getComplaintLinksAction({ business: business_id })
      .then(() =>
        this.setState({ loader: { ...this.state.loader, 4: false } })
      );
  };

  resetFormState(key) {
    // eslint-disable-line
    const isFormEditable = {
      isProfile: false,
      isAbout: false,
      isPhotos: false,
      isHours: false,
      isSocialProfiles: false,
    };
    this.setState({ isFormEditable });
  }

  submitComplaintLinks(data) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const businessData = {};
    businessData.business = business_id;
    return this.props.actions
      .postComplaintLinksAction(data, businessData)
      .then(() => {
        this.resetFormState();
        this.getComplaintLinks();
      });
  }

  //SetupInsights function start from here
  getInsightsCategoryList = (queryparmas) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const data = {
      id: business_id,
    };
    return this.props.actions
      .getInsightsCategoryAction(data, queryparmas)
      .then(() =>
        this.setState({ loader: { ...this.state.loader, 6: false } })
      );
  };

  deleteInsightsList = (category) => {
    const {
      history,
      location: { pathname, search },
      match: {
        params: { business_id },
      },
    } = this.props;
    return this.props.actions
      .insightsListDelete(category, business_id)
      .then(() => {
        // eslint-disable-line
        const query = queryString.parse(search);
        if (query.page) {
          history.push(pathname);
        } else {
          this.getInsightsCategoryList(queryString.parse(search));
        }
      });
  };

  addCategory = () => {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    history.push(`/${business_id}/setup-insights`);
  };

  //Ticket Automation Page starts from here
  getCreatedRulesData = (queryData) => {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const data = {
      id: business_id,
    };
    return this.props.actions
      .getCreatedRulesAction(data, queryData)
      .then(() =>
        this.setState({ loader: { ...this.state.loader, 7: false } })
      );
  };

  addRules() {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    history.push(`/${business_id}/new-rule`);
  }

  submitTicketResolutionData(data) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.setState({ surveyFormLoading: true });
    this.props.ticketActions
      .createTicketResolution({
        data: { ...data, business: business_id },
        businessId: business_id,
        resolutionId: this.state.resolutionDataId,
      })
      .then(() => {
        this.setState({ surveyFormLoading: false });
        this.props.ticketActions.ticketResolution(business_id).then((res) => {
          if (res.data && res.data.results && res.data.results.length > 0) {
            this.setState({ resolutionDataId: res.data.results[0].id });
          }
          this.setState({ surveyFormLoading: false });
        });
      });
  }

  deleteAllSurvey() {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    this.setState({ surveyFormLoading: true });
    return axios_instance
      .delete(
        utils.format(
          `${URLS.TICKET_RESOLUTION}${this.state.resolutionDataId}/`,
          [business_id]
        )
      )
      .then((res) => {
        if (res.status === 204) {
          this.setState({ resolutionDataId: null, surveyFormLoading: false });
          this.props.ticketActions.ticketResolution(business_id);
        }
      });
  }

  toggleProductIssueReasons(value) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const new_url = `${URLS.TICKET}enable-product-issue-reasons/?business=${business_id}&product_issue_reasons=${value}`;
    return axios_instance.get(new_url).then((res) => {
      this.props.actions.businessDetail({ id: business_id });
      if (res.data.success && value) {
        this.setState({ enableProductIssueReasons: true });
      } else {
        this.setState({ enableProductIssueReasons: false });
      }
    });
  }

  toggleTicketCommentBox(value) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const new_url = `${URLS.TICKET}disable-comment-box/?business=${business_id}&disable_comment_box=${value}`;
    return axios_instance.get(new_url).then((res) => {
      this.props.actions.businessDetail({ id: business_id });
      if (res.data.success && value) {
        this.setState({ ticketCommentBoxDisabled: true });
      } else {
        this.setState({ ticketCommentBoxDisabled: false });
      }
    });
  }

  toggleRestrictTicketClosingWithoutSurvey(value) {
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    const new_url = `${URLS.TICKET}restrict-ticket-closing/?business=${business_id}&restrict_ticket_closing=${value}`;
    return axios_instance.get(new_url).then((res) => {
      this.props.actions.businessDetail({ id: business_id });
      if (res.data.success && value) {
        this.setState({ restrictTicketClosingWithoutSurvey: true });
      } else {
        this.setState({ restrictTicketClosingWithoutSurvey: false });
      }
    });
  }
  deleteResponse(id) {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.actions
      .responseDelete({ business_id, id })
      .then(() => {
        utils.displayMessage("positive", "Response Deleted Successfully");
        history.push({
          pathname: "central-setup",
          state: { tab: "3" },
        });
      })
      .catch((err) => {
        utils.displayMessage("negative", "Error Deleting Response");
        console.log(err);
      });
  }

  deleteAiResponse(id) {
    const {
      history,
      match: {
        params: { business_id },
      },
    } = this.props;
    this.props.actions
      .aiResponseDelete({ business_id, id })
      .then(() => {
        utils.displayMessage("positive", "Response Deleted Successfully");
        history.push({
          pathname: "central-setup",
          state: { tab: "3" },
        });
      })
      .catch((err) => {
        utils.displayMessage("negative", "Error Deleting Response");
        console.log(err);
      });
  }

  updatedNavBar(value) {
    this.setState({ updateNavTeam: value });
    const { search, pathname } = this.props.location;
    const searchQuery = queryString.parse(search);
    this.props.history.replace({
      pathname,
      search: queryString.stringify(Object.assign(searchQuery, { page: 1 })),
    });
  }

  handleSubTeamTab(item) {
    const { history, location } = this.props;
    const { pathname } = location;
    if (item !== this.state.updateNavTeam) {
      setTimeout(() => {
        switch (item) {
          case "Team":
            history.push({
              pathname,
              // search: this.state.teamParams.Team,
              search: `page_size=10`,
            });
            break;
          case "Department":
            history.push({
              pathname,
              // search: this.state.teamParams.Department,
              search: `page_size=10`,
            });
            break;
          case "Designation":
            history.push({
              pathname,
              // search: this.state.teamParams.Designation,
              search: `page_size=10`,
            });
            break;

          default:
            break;
        }
      }, 0);
    }
  }

  handleTeamItemParams = (item, params) => {
    // if (item !== this.state.updateNavTeam) {
    switch (item) {
      case "Team":
        this.updateTeamParam("Team", params);
        break;
      case "Department":
        this.updateTeamParam("Department", params);
        break;
      case "Designation":
        this.updateTeamParam("Designation", params);
        break;

      default:
        break;
    }
    // }
  };

  updateTeamParam(name, value) {
    const { teamParams } = this.state;
    const test = {
      ...teamParams,
      [name]: value,
    };
    this.setState({
      teamParams: test,
    });
  }

  // postCode = (code, provider) => {
  //   const {
  //     match: {
  //       params: { business_id },
  //     },
  //   } = this.props;
  //   console.log(code, 'code and provider in postcode function vvvvvv',provider);
  //   if (provider == "twitter") {
  //     return this.props.SocialProfileActions
  //       .postAllMentionsSocialCode(code, business_id, provider)
  //       .then(() => {
  //         utils.displayMessage(
  //           "positive",
  //           `${provider} Connected Successfully`
  //         );
  //       })
  //       .catch(() => {
  //         utils.displayMessage("negative", "Error");
  //       });
  //   } else {
  //     return this.props.SocialProfileActions
  //       .postAllMentionsSocialCode({ code }, business_id, provider)
  //       .then(() => {
  //         utils.displayMessage(
  //           "positive",
  //           `${provider} Connected Successfully`
  //         );
  //       })
  //       .catch(() => {
  //         utils.displayMessage("negative", "Error");
  //       });
  //   }
  // };

  // connectSocial = (data, provider) => {
  //   console.log(data, 'create social connect vvvvvv', provider);
  //   const code = provider === "twitter" ? data : data.code;
  //   window.removeEventListener("message", this.connectSocialRef);
  //   this.postCode(code, provider);
  //   this.newWindow.close();
  // };

  // getAuthUrl = (provider) => {
  //   const {match: {params: { business_id },},} = this.props;
  //   return this.props.SocialProfileActions.getSocialAuthUrl(business_id, provider).then((res) => {
  //     this.newWindow = window.open(
  //       res.auth_url,
  //       "_blank",
  //       "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
  //     );
  //     // this.connectSocialRef = (response) =>
  //     //   this.connectSocial(response.data, provider);
  //     // window.addEventListener("message", this.connectSocialRef);
  //     console.log(provider, 'provider check log vvvvvv');
  //     if (provider == 'instagram'){
  //       localStorage.removeItem('instagram_x_data');
  //     }else if (provider == 'facebook'){
  //       localStorage.removeItem('facebook_x_data');
  //     }else{
  //       localStorage.removeItem('x_data');
  //     }
  //     const interval = setInterval(() => {
  //       console.log('Waiting for message vvvvvv ...');
  //       let messagess = null;
  //       if (provider == 'instagram'){
  //         messagess = localStorage.getItem('instagram_x_data');
  //       }else if (provider == 'facebook'){
  //         messagess = localStorage.getItem('facebook_x_data');
  //       }else{
  //         messagess = localStorage.getItem('x_data');
  //       }
  //       console.log(messagess, 'messagess messagess after login vvvvvv', typeof(messagess));
  //       console.log(JSON.parse(messagess), 'messagess json load after login vvvvvv', typeof(JSON.parse(messagess)));
  //       if (messagess) {
  //         this.connectSocial(JSON.parse(messagess),provider);
  //         clearInterval(interval);
  //       }
  //     }, 10000);

  //     // Clear the interval after a certain time to avoid infinite logging
  //     setTimeout(() => clearInterval(interval), 60*1000);
  //   });
  // };

  render() {
    const {
      businessInfo,
      smartResponseList,
      aiResponseList,
      enterpriseList,
      countryOption,
      stateOption,
      insightsCategoryList,
      competitorsList,
      enterpriseUserList,
      teamDetail,
      rulesList,
      branchList,
      match: {
        params: { business_id },
      },
      location: { search },
      lockedModules,
      staffList,
      resolutionDetail,
      showTabs = true,
      department,
      designation,
      ticketEscalationList,
    } = this.props;
    const {
      isEditLocationOpen,
      isUserModalOpen,
      selectedBranch,
      isAddCompetitorOpen,
      isEditMemberOpen,
      isLimitModalOpen,
      limitsMessage,
      add,
      locks,
      loader,
      menuList,
      resolutionEnabled,
      resolutionDataId,
      surveyFormLoading,
      isAddDepartmentOpen,
      isAddDesignationOpen,
      teamParams,
      activeTab,
    } = this.state;
    const querySearch = queryString.parse(search).ordering;
    const countryOptions =
      countryOption &&
      countryOption.choices.map((item) => {
        return { text: item.display_name, value: item.value };
      });
    const stateOptions =
      stateOption &&
      stateOption.choices.map((item) => {
        return { text: item.display_name, value: item.value };
      });
    const service_category = cookie.load("service_category");
    const user = cookie.load("business_user");

    return (
      <div>
        <Row className="content-area">
          <Col xs={12} sm={12} className="business-set-- p-3">
            <BranchRootHeader defaultValue={0} history={this.props.history} />
            <Row className="m-0">
              <Col xs={12} sm={12} className="p-0">
                <Nav className="Central_nav">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "1",
                      })}
                      onClick={() => {
                        this.toggleTab("1");
                      }}
                    >
                      Locations
                    </NavLink>
                  </NavItem>
                  {user && user.is_business_owner && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2",
                        })}
                        onClick={() => {
                          this.toggleTab("2");
                        }}
                      >
                        Team
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "3",
                      })}
                      onClick={() => {
                        this.toggleTab("3");
                      }}
                    >
                      Smart Reply
                    </NavLink>
                  </NavItem>
                  {/* {(lockedModules && lockedModules['complaints']) && <NavItem>
                    <NavLink
                      className={classnames({ active: this.state.activeTab === '4' })}
                      onClick={() => { this.toggleTab('4'); }}
                    >
                      Setup Complaint Links
                    </NavLink>
                  </NavItem>} */}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "5",
                      })}
                      onClick={() => {
                        this.toggleTab("5");
                      }}
                    >
                      Competitor Brands
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "6",
                      })}
                      onClick={() => {
                        this.toggleTab("6");
                      }}
                    >
                      Insights
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "7",
                      })}
                      onClick={() => {
                        this.toggleTab("7");
                      }}
                    >
                      Ticket Rules
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "8",
                      })}
                      onClick={() => {
                        this.toggleTab("8");
                      }}
                    >
                      Integration
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "9",
                      })}
                      onClick={() => {
                        this.toggleTab("9");
                      }}
                    >
                      Staff
                    </NavLink>
                  </NavItem>
                  {service_category && service_category === "Restaurant" && (
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "10",
                        })}
                        onClick={() => {
                          this.toggleTab("10");
                        }}
                      >
                        Menu
                      </NavLink>
                    </NavItem>
                  )}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "11",
                      })}
                      onClick={() => {
                        this.toggleTab("11");
                      }}
                    >
                      Widget
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "12",
                      })}
                      onClick={() => {
                        this.toggleTab("12");
                      }}
                    >
                      Service Request Rules
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: this.state.activeTab === "13",
                      })}
                      onClick={() => {
                        this.toggleTab("13");
                      }}
                    >
                      Offline Store Alerts
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>

            {!locks[this.state.activeTab] ? (
              <TabContent activeTab={this.state.activeTab} className="mt-2">
                <TabPane tabId="1">
                  <EnterpriseList
                    enterpriseList={
                      enterpriseList ? enterpriseList.results : []
                    }
                    portalData={
                      enterpriseList ? enterpriseList.portal_data : []
                    }
                    category={enterpriseList && enterpriseList.category}
                    businessId={business_id}
                    countryOption={countryOptions && countryOptions}
                    stateOption={stateOptions && stateOptions}
                    getStateOptions={this.getStateOptions}
                    toggle={this.toggleBranch}
                    toggleUserModal={this.toggleUserModal}
                    deleteOwner={(branch, user, callback) =>
                      this.toggleConfirm(() =>
                        this.deleteOwner(branch, user, callback)
                      )
                    }
                    sortFunction={(on) =>
                      utils.toggleSort(
                        this.props.history,
                        this.props.location,
                        on
                      )
                    }
                    location={this.props.location}
                    history={this.props.history}
                    ordering={querySearch}
                    activeUser={user}
                    loader={loader["1"]}
                  />
                  <PaginationComponent
                    count={enterpriseList && enterpriseList.count}
                    location={this.props.location}
                    pageSizeCookie="c_enterprise"
                  />
                </TabPane>
                <TabPane tabId="2">
                  <MemberComponent
                    members={
                      this.props.team && this.props.team.results
                        ? this.props.team.results
                        : []
                    }
                    toggle={this.toggleMember}
                    toggleDepartment={this.toggleDepartment}
                    toggleDesignation={this.toggleDesignation}
                    deleteMember={(member) =>
                      this.toggleConfirm(() => this.deleteMember(member))
                    }
                    teamList={this.teamList}
                    loader={loader["2"]}
                    history={this.props.history}
                    location={this.props.location}
                    handleTeamItemParams={this.handleTeamItemParams}
                    handleSubTeamTab={this.handleSubTeamTab}
                    handleMultiLocationModal={this.handleMultiLocationModal}
                    // Pagination
                    count={this.props.team && this.props.team.count}
                    pageSizeCookie="c_team"
                    departmentPageSizeCookie="c_department"
                    designationPageSizeCookie="c_designation"
                    departmentCount={department && department?.count}
                    department={department && department?.results}
                    deleteDepartment={this.deleteDepartment}
                    designationCount={designation && designation?.count}
                    designation={designation && designation?.results}
                    deleteDesignation={this.deleteDesignation}
                    setNavActive={this.updatedNavBar}
                    navActive={this.state.updateNavTeam}
                    businessId={business_id}
                  />
                </TabPane>
                <TabPane tabId="3">
                  <SmartResponseList
                    smartResponseList={
                      smartResponseList && smartResponseList.results
                    }
                    aiResponseList={aiResponseList && aiResponseList.results}
                    addResponse={this.addResponse}
                    addAiResponse={this.addAiResponse}
                    sortFunction={(on) =>
                      utils.toggleSort(
                        this.props.history,
                        this.props.location,
                        on
                      )
                    }
                    location={this.props.location}
                    history={this.props.history}
                    businessId={business_id}
                    ordering={querySearch}
                    functionalityToggle={this.functionalityToggle}
                    smartResponseOnCheck={
                      businessInfo && businessInfo.smart_response
                    }
                    aiResponseOnCheck={businessInfo && businessInfo.ai_response}
                    activeUser={user}
                    loader={loader["3"]}
                    // Pagination
                    count={smartResponseList && smartResponseList.count}
                    aiResponseCount={aiResponseList && aiResponseList.count}
                    pageSizeCookie="c_sr"
                    deleteResponse={this.deleteResponse}
                    deleteAiResponse={this.deleteAiResponse}
                  />
                  {/* {businessInfo && businessInfo.smart_response === true ?
                  <PaginationComponent
                    count={enterpriseList && enterpriseList.count}
                    location={this.props.location}
                    pageSizeCookie='c_sr'
                  /> : ''} */}
                </TabPane>
                <TabPane tabId="4">
                  <CentralComplaintsSetup
                    complaintLinks={this.props.complaintLinks}
                    submitComplaintLinks={this.submitComplaintLinks}
                    loader={loader["4"]}
                  />
                </TabPane>
                <TabPane tabId="5">
                  <CompetitorsList
                    competitorsList={
                      competitorsList && competitorsList.results
                        ? competitorsList.results
                        : []
                    }
                    location={this.props.location}
                    businessID={business_id}
                    activeUser={user}
                    resolution
                    deleteCompetitor={this.deleteCompetitor}
                    toggle={this.toggleCompetitor}
                    disabled={isLimitModalOpen}
                    lockMessage={locks["5"]}
                    loader={loader["5"]}
                    count={competitorsList && competitorsList.count}
                    pageSizeCookie="c_competitor"
                  />
                </TabPane>
                <TabPane tabId="6">
                  <CentralSetupInsights
                    businessId={business_id}
                    addCategory={this.addCategory}
                    categoryList={insightsCategoryList}
                    deleteInsightsList={(category) =>
                      this.deleteInsightsList(category)
                    }
                    lockMessage={locks["6"]}
                    loader={loader["6"]}
                    location={this.props.location}
                  />
                  {/* <PaginationComponent
                    count={insightsCategoryList && insightsCategoryList.count}
                    location={this.props.location}
                    pageSizeCookie="c_insights"
                  /> */}
                </TabPane>
                {/* Setup Ticket Rules 7 */}
                <TabPane tabId="7">
                  <SetupTicketsPage
                    rulesList={rulesList && rulesList.results}
                    businessId={business_id}
                    lockMessage={locks["7"]}
                    loader={loader["7"]}
                    enterpriseList={
                      enterpriseList ? enterpriseList.results : []
                    }
                    location={this.props.location}
                    history={this.props.history}
                    addRules={this.addRules}
                    ordering={querySearch}
                    branchList={branchList && branchList.choices}
                    count={rulesList && rulesList.count}
                    pageSizeCookie="c_ticket_automation"
                    toggleTicketResolution={this.toggleTicketResolution}
                    resolutionEnabled={resolutionEnabled}
                    resolutionData={
                      resolutionDetail && resolutionDetail.results
                    }
                    submitTicketResolutionData={this.submitTicketResolutionData}
                    resolutionDataId={resolutionDataId}
                    surveyFormLoading={surveyFormLoading}
                    deleteAllSurvey={this.deleteAllSurvey}
                    toggleTicketCommentBox={this.toggleTicketCommentBox}
                    ticketCommentBoxDisabled={
                      this.state.ticketCommentBoxDisabled
                    }
                    toggleProductIssueReasons={this.toggleProductIssueReasons}
                    enableProductIssueReasons={
                      this.state.enableProductIssueReasons
                    }
                    restrictTicketClosingWithoutSurvey={
                      this.state.restrictTicketClosingWithoutSurvey
                    }
                    toggleRestrictTicketClosingWithoutSurvey={
                      this.toggleRestrictTicketClosingWithoutSurvey
                    }
                    ticketEscalationList={ticketEscalationList}
                    addTicketEscalation={(data, callback) =>
                      this.addTicketEscalation(data, callback)
                    }
                    editTicketEscalation={(data, callback) =>
                      this.editTicketEscalation(data, callback)
                    }
                    deleteTicketEscalation={(data) =>
                      this.deleteTicketEscalation(data)
                    }
                    designation={designation && designation?.results}
                    businessInfo={businessInfo}
                  />
                </TabPane>

                <TabPane tabId="8">
                  {activeTab === "8" && (
                    <SetupIntegrationPage
                      businessInfo={businessInfo}
                      branchList={branchList && branchList.choices}
                      business_id={business_id}
                      getAuthUrl={this.getAuthUrl}
                    />
                  )}
                </TabPane>
                <TabPane tabId="9">
                  <StaffResponseList
                    staffList={staffList && staffList}
                    addStaff={this.addStaff}
                    location={this.props.location}
                    history={this.props.history}
                    activeUser={user}
                    loader={loader["9"]}
                    businessId={business_id}
                    deleteStaff={this.deleteStaff}
                    pageSizeCookie="c_staff"
                  />
                </TabPane>
                <TabPane tabId="10">
                  <MenuResponseList
                    addMenu={this.addMenu}
                    location={this.props.location}
                    history={this.props.history}
                    activeUser={user}
                    loader={loader["10"]}
                    businessId={business_id}
                    deleteMenu={this.deleteMenu}
                    menuList={menuList}
                    match={this.props.match}
                    pageSizeCookie="c_menu"
                  />
                </TabPane>
                <TabPane tabId="11">
                  {activeTab === "11" && (
                    <ReviewWidget businessId={business_id} />
                  )}
                </TabPane>
                <TabPane tabId="12">
                  {activeTab === "12" && (
                    <ServiceRequestRule
                      rulesList={{ results: [] }}
                      loader={loader["12"]}
                      designation={designation && designation?.results}
                      addRules={this.addRules}
                      ticketEscalationList={{ results: [] }}
                      addServiceReminder={(data, callback) =>
                        this.addServiceReminder(data, callback)
                      }
                      editServiceReminder={(data, callback) =>
                        this.editServiceReminder(data, callback)
                      }
                      deleteServiceReminder={(data, callback) =>
                        this.deleteServiceReminder(data, callback)
                      }
                    />
                  )}
                </TabPane>
                <TabPane tabId="13">
                  {activeTab === "13" && (
                    <OfflineStore businessId={business_id} />
                  )}
                </TabPane>

              </TabContent>
            ) : (
              <LockedComponent
                message={locks[this.state.activeTab]}
                hideDim={true}
              />
            )}
          </Col>
        </Row>

          <FormModal
            isOpen={isEditLocationOpen}
            toggle={this.toggleBranch}
            heading={
              selectedBranch ? "Edit Branch Location" : "Add Business Branch"
            }
            width={"700px"}
          >
            <BranchSetupForm
              handleBranchSetup={this.handleBranchSetup}
              editLocation={this.editLocation}
              errors={this.props.errors}
              initialValues={selectedBranch}
              hideLogo
            />
          </FormModal>

          <FormModal
            isOpen={isUserModalOpen}
            toggle={this.toggleUserModal}
            heading="Add Owner"
            width={"500px"}
          >
            <EnterpriseUserForm
              userList={
                enterpriseUserList &&
                enterpriseUserList.choices.map((item) => ({
                  display_name: item.display_name,
                  value: item.value,
                }))
              }
              saveOwner={this.saveOwner}
              errors={this.props.errors}
            />
          </FormModal>
          <FormModal
            isOpen={isEditMemberOpen}
            toggle={this.toggleMember}
            heading={add ? "Add Team Member" : "Edit Team Member"}
            width={"700px"}
          >
            <MemberForm
              initialValues={add ? {} : teamDetail}
              addTeamMember={add ? this.addTeamMember : this.editTeamMember}
              branchList={branchList}
              department={department && department?.results}
              designation={designation && designation?.results}
            />
          </FormModal>

        <ConfirmationModal
          header="Are you sure!"
          style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
          subHeader={`Are you sure you want to delete this team?`}
          isOpen={this.state.isConfirmOpen}
          toggle={this.toggleConfirm}
          onConfirm={this.state.confirmationFunction}
        />

          <FormModal
            isOpen={isAddCompetitorOpen}
            toggle={this.toggleCompetitor}
            heading={this.state.headerText}
            bodyClass="pb-2"
            width={"480px"}
          >
            <CompetitorsForm
              addCompetitors={this.addCompetitors}
              toggleLimitModal={this.toggleCentralLimitModal}
              initialValues={{ name: this.state.compBrandName }}
            />
          </FormModal>

        {isLimitModalOpen && (
          <div>
            <LimitModal
              isOpen={isLimitModalOpen && isLimitModalOpen}
              toggle={this.toggleCentralLimitModal}
              message={limitsMessage}
            />
          </div>
        )}

          <FormModal
            isOpen={this.state.isMultiLocationOpen}
            toggle={this.handleMultiLocationModal}
            heading="Assign Multiple Locations to Multiple Team Members"
            width={"700px"}
            headerClass="align-center"
          >
            <AssignMultiLocationForm
              AssignMultiLocationSubmit={this.AssignMultiLocationSubmit}
              TeamMemberList={this.state.totalTeamMember.map((item) => ({
                display_name: `${item.first_name} ${item.last_name}`,
                id: item.id,
              }))}
              BranchList={
                branchList && branchList.choices ? branchList.choices : []
              }
            />
          </FormModal>

          <FormModal
            isOpen={isAddDepartmentOpen}
            toggle={() => this.toggleDepartment(null, true)}
            heading={this.state.headerText}
            bodyClass="pb-2"
            width={"480px"}
          >
            <DepartmentForm
              department={department && department?.results}
              addDepartment={this.addDepartment}
              initialValues={{ name: this.state.departmentName }}
            />
          </FormModal>

          <FormModal
            isOpen={isAddDesignationOpen}
            toggle={() => this.toggleDesignation(null, true)}
            heading={this.state.headerText}
            bodyClass="pb-2"
            width={"480px"}
          >
            <DesignationForm
              designation={designation && designation?.results}
              addDesignation={this.addDesignation}
              initialValues={{ name: this.state.designationName }}
            />
          </FormModal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    team: state.team,
    department: state.department.detail,
    designation: state.designation.detail,
    business: state.business,
    businessInfo: state.business.detail,
    smartResponseList: state.business.response_list,
    aiResponseList: state.business.airesponse_list,
    errors: state.business.errors,
    enterpriseList: state.business.enterprise,
    enterpriseUserList: state.business.userList,
    teamDetail: state.team.detail,
    complaintLinks: state.business.complaint_links,
    insightsCategoryList: state.business.insights_category_list,
    insightsList: state.business.insight,
    competitorsList: state.competitors.competitors_list,
    countryOption: state.business.countryList,
    stateOption: state.business.stateList,
    lockedModules: state.business.lockedModules,
    rulesList: state.business.ticket_list,
    branchList: state.business.branch_list,
    branchOptions: state.business.branches,
    staffList: state.business.staff_list,
    resolutionDetail: state.tickets.ticketResolution,
    ticketEscalationList: state.ticketEscalation.detail,
    socialProfile: state.socialProfile.details,
  };
};

CentralUserPage.propTypes = {
  actions: PropTypes.instanceOf(Object),
  CommonActions: PropTypes.instanceOf(Object),
  compActions: PropTypes.instanceOf(Object),
  teamActions: PropTypes.instanceOf(Object),
  ticketActions: PropTypes.instanceOf(Object),
  errors: PropTypes.instanceOf(Object),
  departmentActions: PropTypes.instanceOf(Object),
  designationActions: PropTypes.instanceOf(Object),
  ticketEscalationActions: PropTypes.instanceOf(Object),
  SocialProfileActions: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(BusinessSetupActions, dispatch),
    CommonActions: bindActionCreators(CommonActions, dispatch),
    compActions: bindActionCreators(CompetitorActions, dispatch),
    teamActions: bindActionCreators(TeamActions, dispatch),
    profileActions: bindActionCreators(ProfileActions, dispatch),
    ticketActions: bindActionCreators(TicketActions, dispatch),
    departmentActions: bindActionCreators(DepartmentActions, dispatch),
    designationActions: bindActionCreators(DesignationActions, dispatch),
    ticketEscalationActions: bindActionCreators(TicketEscalationActions,dispatch),
    SocialProfileActions: bindActionCreators(SocialProfileActions,dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(CentralUserPage);
