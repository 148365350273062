import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as commonAction from "../common/commonAction";
import * as URLS from "../../libs/apiUrls";

export function getGmbListingOverviewSuccess(data) {
  return { type: types.GET_GMB_LISTING_OVERVIEW_SUCCESS, data };
}

export function getGmbListingOverviewFailure(error) {
  let data = {};
  console.log("errorerrorerrorerrorrespons error", error);
  data.errors = error;
  return { type: types.GET_GMB_LISTING_OVERVIEW_FAILURE, data };
}

export const getGmbListingOverview = (business_id, data) => {
  const { ...params } = data;
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    const url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/overview/`;
    return axios_instance
      .get(url, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingOverviewSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingOverviewFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export function getGmbListingBulkGoogleUpdatesSuccess(data) {
  return { type: types.GET_GMB_LISTING_BULK_GOOGLE_UPDATES_SUCCESS, data };
}

export function getGmbListingBulkGoogleUpdatesFailure(error) {
  let data = {};
  console.log("errorerrorerrorerrorrespons error", error);
  data.errors = error;
  return { type: types.GET_GMB_LISTING_BULK_GOOGLE_UPDATES_FAILURE, data };
}

export const getGmbListingBulkGoogleUpdates = (business_id) => {
  let url = `${URLS.GMB_LISTING_LOCATION}/${business_id}/bulk-google-update-diff/`;

  return (dispatch) => {
    dispatch(commonAction.showLoader());
    return axios_instance
      .get(url)
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingBulkGoogleUpdatesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getGmbListingBulkGoogleUpdatesFailure(err));
      });
  };
};
