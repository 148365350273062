import React from "react";
import { Bar } from "react-chartjs-2";

const CampaignReportChart = ({ dataKeys, dataSets }) => {
  const chartData = {
    labels: dataKeys,
    datasets: dataSets,
  };
  const chartOptions = {
    animation: {
      duration: 2000,
    },
    barPercentage:0.3,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks:{
          callback: function(value, index, values) {
            return dataKeys[index].substr(0, 20);
          },
        }
      },
      y: {
        grid: {
          borderDash: [7],
        },
        border: {
          dash: [6, 6],
          color: "transparent",
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontSize: 16,
          fontStyle: "normal",
          fontFamily: "Outfit",
          boxWidth: 6,
          boxHeight: 6,
          padding: 10,
          usePointStyle: true,
        },
      },
    },
  };
  return (
    <div style={{height:'400px'}}>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};
export default CampaignReportChart;
