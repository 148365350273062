import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { Icon } from "semantic-ui-react";
// import AddUserMobile from "../../components/messenger/Common/AddUserMobile";
import Instagram from "../../images/instagram_logo.png";
import Fb from "../../images/Facebook_Messenger_Logo.png";
import WhatsappIcon from "../../images/whatsappicon.png";
// import FPAvatar from "../../components/common/new/FPAvatar.js";
import Avatar from "antd/lib/avatar/avatar.js";
import { avatarText } from "../../libs/utils.js";
import TwitterIcon from "../../images/twitter.png";
import FPMenuBox from "../common/new/menu/FPMenuBox.js";
import { Assign } from "../../images/svg";

const ActiveUserComponent = (props) => {
  const { active_user, userDupdateCallBack, afterResponse, userLoader, userUpdateError, unrespondedList } = props;
  const [showMobileModal, setShowMobileModal] = useState(false);
  const [showUEmail, setShowUEmail] = useState("");
  const [showAssignedName, setshowAssignedName] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [emailAddOrNot, setEmailAddOrNot] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  // const direction = "down";

  useEffect(() => {
    const email = active_user.cus_email;
    if (email) {
      if (email.includes("@facebook.com")) {
        setEmailAddOrNot(true);
        setShowUEmail("");
      } else {
        setEmailAddOrNot(false);
        setShowUEmail(active_user.cus_email);
      }
    } else {
      setShowUEmail("Add Email");
    }
    if (active_user.id != undefined) {
      const found = unrespondedList.some((el) => el.id === active_user.id);
      if (found) {
        setshowAssignedName(true);
      }
    }
  }, [active_user]);

  useEffect(() => {
    if (afterResponse) {
      setShowMobileModal(false);
    }
  }, [afterResponse]);

  function showMobileModalFn() {
    setShowMobileModal(true);
  }

  function closeMobileModal() {
    setShowMobileModal(false);
  }

  function userDetailCallBack(detail) {
    userDupdateCallBack(detail);
  }

  const [dropdown1Options] = useState([
    {
      label: "Unassigned",
      value: "unassigned",
      onClick: (val) => console.log(`Selected: ${val}`),
    },
  ]);

  const [dropdown2Options] = useState([
    {
      label: "Move to",
      value: "move-to",
      onClick: (val) => console.log(`Selected: ${val}`),
    },
  ]);

  const [dropdown3Options] = useState([
    {
      label: "Open",
      value: "open",
      onClick: (val) => console.log(`Selected: ${val}`),
    },
  ]);
  return (
    <div className="active-user-content">
      {active_user ? (
        <div className="userChatRow d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <Avatar
              className="mr-2"
              size={40}
              style={{
                backgroundColor: "#BFE0E2",
                color: "#1B1C1D",
                verticalAlign: "middle",
              }}
            >
              {active_user?.cus_name ? avatarText(active_user?.cus_name) : "Anonymous"}
            </Avatar>
            <div className="topusernamecol">
              <div className="d-flex gap-6 align-items-center">
                <h5 className="mb-0">{active_user.cus_name || "Anonymous"}</h5>
                <span className="d-inline-flex">
                  <Assign />
                </span>
              </div>
              <div className="imagetop d-flex align-items-center">
                {Object.keys(active_user).length > 0 ? (
                  <>
                    <img
                      src={
                        active_user.platform === "facebook"
                          ? Fb
                          : active_user.platform === "whatsapp"
                          ? WhatsappIcon
                          : active_user.platform === "twitter"
                          ? TwitterIcon
                          : Instagram
                      }
                      alt="platform logo"
                      className="mr-1"
                      style={{ width: "20px", height: "20px" }}
                    />
                    <span className="line-height-1">
                      {active_user.platform === "facebook"
                        ? "Facebook Messenger"
                        : active_user.platform === "whatsapp"
                        ? "Whatsapp"
                        : active_user.platform === "twitter"
                        ? "Twitter"
                        : "Instagram"}
                    </span>
                  </>
                ) : null}
                {/* <p className="line-height-1 mb-0">({active_user.page_name})</p> */}
              </div>
            </div>
          </div>
          <div className="d-flex  align-items-center">
            <FPMenuBox
              title="Unassigned"
              options={dropdown1Options}
              size="small"
              rounded={false}
              startIcon={null}
              hideDropDownArrowIcon={false}
              className="transparent"
              dropDownArrowMargin="-15px"
            />

            <FPMenuBox
              title="Move to"
              options={dropdown2Options}
              size="small"
              rounded={false}
              startIcon={null}
              hideDropDownArrowIcon={false}
              className="transparent"
              dropDownArrowMargin="-15px"
            />

            <FPMenuBox
              title="Open"
              options={dropdown3Options}
              size="small"
              rounded={false}
              startIcon={null}
              hideDropDownArrowIcon={false}
              className="transparent"
              dropDownArrowMargin="-15px"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

ActiveUserComponent.propTypes = {
  active_user: PropTypes.object.isRequired,
  userDupdateCallBack: PropTypes.func.isRequired,
  afterResponse: PropTypes.bool.isRequired,
  userLoader: PropTypes.bool.isRequired,
  userUpdateError: PropTypes.string.isRequired,
};

export default ActiveUserComponent;
