import * as types from "../../actions/actionTypes";

const initialState = {
  overview_list: null,
  overview_error: null,
};

export default function gmbListingOverviewReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_GMB_LISTING_OVERVIEW_SUCCESS:
      return { ...state, overview_list: action.data };
    case types.GET_GMB_LISTING_OVERVIEW_FAILURE:
      return { ...state, overview_error: action.data };

      case types.GET_GMB_LISTING_BULK_GOOGLE_UPDATES_SUCCESS:
        return { ...state, all_google_updates_list: action.data };
      case types.GET_GMB_LISTING_BULK_GOOGLE_UPDATES_FAILURE:
        return { ...state, all_google_updates_error: action.data };
  
      default:
      return state;
  }
}
