/* eslint-disable */
import React, { useEffect, useState } from "react";
import { serviceOptions } from "../../libs/constant";
import * as URLS from "../../libs/apiUrls";
import { getAllParamsInArrayForm, getParams, removeLifetimeFilter } from "../../libs/utils";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as commonAction from "../../actions/common/commonAction";
import * as filterListActions from "../../actions/common/filterListActions";
import { FPButton, FPFilter, FPToolTip } from "../../components/common/new";
import FormModal from "../../components/common/FormModal";
import cookie from "react-cookies";
import { IconButton } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../images/svg/Download.svg";

const InsightsFilter = (props) => {
  const {
    common,
    history,
    filter,
    filterAction,
    match,
    downloadExcel,
    reviewSourceOptions,
    pageSizeCookie,
    productItem,
    activeTab,
    getData,
  } = props;
  const [isMoreThanNinety, setIsMoreThanNinety] = useState(false);

  const dateType = getParams(location.search, "date_type");
  const startDate = getParams(location.search, "start_date");
  const endDate = getParams(location.search, "end_date");
  const service_category = cookie.load("service_category");

  const fetchFilterList = async () => {
    const {
      params: { business_id },
    } = match;
    try {
      await Promise.all([
        filterAction.getDateType("", `${URLS.BUSINESS_SETUP}dashboard-filter/`),
        filterAction.getBranch("", `${URLS.BUSINESS_SETUP}${business_id}/branch/`),
        filterAction.getContentType("", `${URLS.CONTENT_OPTIONS}`),
        filterAction.getProductItems(
          "",
          `${URLS.PRODUCT_ITEM_LIST}${business_id}/menu-analytics/product_items/?date_type=${dateType}&business_id=${business_id}`
        ),
      ]);
    } catch (error) {
      console.error("Error in on fetch filter list:", error);
    }
  };

  const handleReviewDownloadFlow = () => {
    if (dateType === "other") {
      var Difference_In_Time = new Date(endDate).getTime() - new Date(startDate).getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 90) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    } else {
      if (["prior_6_month", "current_year", "prior_12_months"].indexOf(dateType) > -1) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    }
  };

  useEffect(() => {
    fetchFilterList();
  }, []);

  const handleDefaultFilter = (list, key) => {
    const filter = location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable = defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list.map((item) => {
      item["isChecked"] = isKeyFilterAvailable && defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };

  const filterList = [
    {
      id: "1",
      label: "Date Duration",
      key: "date_type",
      type: "radio",
      isSearchable: false,
      list: filter.date_type && handleDefaultFilter(removeLifetimeFilter(filter?.date_type?.choices), "date_type"),
    },
    {
      id: "2",
      label: "Source",
      key: "provider",
      type: "checkbox",
      isSearchable: true,
      list: reviewSourceOptions && handleDefaultFilter(reviewSourceOptions, "provider"),
    },
    {
      id: "3",
      label: "City",
      key: "city",
      type: "checkbox",
      isSearchable: true,
      // list: handleDefaultFilter(serviceOptions, "city"),
      list: common.city_options && handleDefaultFilter(common.city_options.choices, "city"),
    },
    {
      id: "4",
      label: "State",
      key: "state",
      type: "checkbox",
      isSearchable: true,
      list: common.state_options && handleDefaultFilter(common.state_options.choices, "state"),
    },

    {
      id: "5",
      label: "Location",
      key: "branch",
      type: "radio",
      isSearchable: true,
      list: filter.branch && handleDefaultFilter(filter.branch.choices, "branch"),
    },
    {
      id: "6",
      label: "Branch Tag",
      key: "branch_tag",
      type: "checkbox",
      isSearchable: true,
      list:
        common.branch_tag_options &&
        handleDefaultFilter(
          common.branch_tag_options.results.map((item) => ({
            value: item.id,
            display_name: item.title,
          })),
          "branch_tag"
        ),
    },
    service_category === "Restaurant" && {
      id: "7",
      label: "Service Type",
      key: "service_type",
      type: "radio",
      isSearchable: false,
      list: handleDefaultFilter(serviceOptions, "service_type"),
    },
    activeTab === "5" && {
      id: "7",
      label: "Product Item",
      key: "item_name",
      type: "checkbox",
      isSearchable: true,
      list:
        productItem?.results &&
        handleDefaultFilter(
          Array.from(
            new Map(
              productItem.results.map((item) => [
                item.product__product_name,
                {
                  value: item.product__product_name,
                  display_name: item.product__product_name,
                },
              ])
            ).values()
          ),
          "item_name"
        ),
    },
  ].filter((item) => !!item);
  return (
    <React.Fragment>
      <FPFilter
        data={filterList}
        className="mt-3"
        history={history}
        pageSizeCookie={pageSizeCookie}
        getData={getData}
        match={match}
        actionRender={() => (
          <FPToolTip title={"Download"}>
            <IconButton component="span" className="circular-button" onClick={() => handleReviewDownloadFlow()}>
              <DownloadIcon />
            </IconButton>
          </FPToolTip>
        )}
      />
      {isMoreThanNinety && (
        <FormModal
          isOpen={isMoreThanNinety}
          toggle={() => setIsMoreThanNinety(false)}
          heading="Attention !"
          maxWidth="xs"
          showDivider={true}
          dialogActions={<FPButton size="small" onClick={() => setIsMoreThanNinety(false)} label="OK" />}
        >
          <p className="fp-size-16">Please contact to support to download data older than 90 days.</p>
        </FormModal>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    common: state.common,
    filter: state.filter,
    source: state.review.review_source_options,
    productItem: state.productItem.product_item,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    commonActions: bindActionCreators(commonAction, dispatch),
    filterAction: bindActionCreators(filterListActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(InsightsFilter);
