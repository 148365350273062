import React from "react";
import LoaderComponent from "../common/loaderComponent";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { FPButton } from "../common/new";
import { FaPlus, FaDownload } from "react-icons/fa6";
import "../tickets/central-branch.css";
import { Nav, NavItem, NavLink } from "reactstrap";
import TeamList from "./TeamList";
import DepartmentList from "./DepartmentList";
import DesignationList from "./DesignationList";
import SearchComponent from "../common/SearchComponent";
import * as URLS from "../../libs/apiUrls";
import cookie from "react-cookies";
import * as utils from "../../libs/utils";
import { displayMessage } from "../../libs/utils";

const MemberComponent = (props) => {
  const {
    members,
    toggle,
    deleteMember,
    loader,
    location,
    pageSizeCookie,
    count,
    toggleDepartment,
    toggleDesignation,
    department,
    designation,
    deleteDepartment,
    deleteDesignation,
    departmentCount,
    designationCount,
    departmentPageSizeCookie,
    designationPageSizeCookie,
    navActive,
    setNavActive,
    handleTeamItemParams,
    handleSubTeamTab,
    handleMultiLocationModal,
    businessId,
  } = props;

  const handleChangeItem = (item) => {
    handleSubTeamTab(item);
    setNavActive(item);
  };

  const handleDownloadTeam = async () => {
    const {
      location: { search },
    } = props;
    const url = `${utils.format(URLS.TEAM, [businessId])}download/${search}`;
    const token = cookie.load("authToken");
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + token);

      const bodyData = JSON.stringify({
        option: "ALL",
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: bodyData,
        redirect: "follow",
      };
      const res = await fetch(url, requestOptions);
      const blob = await res.blob();
      let winUrl = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = winUrl;
      a.download = "enterprise.xlsx";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (err) {
      console.log(err);
      displayMessage("negative", "Error !");
    }
  };

  return (
    <div>
      <LoaderComponent loader={loader} />
      <CardWrapper
        showDivider={false}
        contentClass="p-3"
        headerTitle={
          <div className="align-center justify-content-between mt-2">
            <div className="float-start">
              <Nav className="Setup_Sub_Nav" style={{ fontSize: "1rem" }} onChange={() => console.log("test")}>
                <NavItem>
                  <NavLink onClick={() => handleChangeItem("Team")} active={navActive === "Team"}>
                    Team
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={() => handleChangeItem("Department")} active={navActive === "Department"}>
                    Department
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={() => handleChangeItem("Designation")} active={navActive === "Designation"}>
                    Designation
                  </NavLink>
                </NavItem>
              </Nav>
            </div>

            {navActive === "Team" && (
              <div className="align-center gap-15">
                <SearchComponent {...props} className="contacts-filter-search" startIcon placeholder="Search here" />
                <FPButton
                  className="d-flex ml-auto"
                  label="Add Team Member"
                  size="small"
                  onClick={() => toggle("add")}
                  endIcon={<FaPlus style={{ fontSize: "14px" }} />}
                />
                <FPButton
                  className="d-flex ml-auto"
                  label="Assign Multi Locations"
                  size="small"
                  onClick={() => handleMultiLocationModal()}
                  endIcon={<FaPlus style={{ fontSize: "14px" }} />}
                />
                <FPButton
                  className="d-flex ml-auto"
                  label="Download"
                  size="small"
                  onClick={() => handleDownloadTeam()}
                  endIcon={<FaDownload style={{ fontSize: "14px" }} />}
                />
              </div>
            )}
            {navActive === "Department" && (
              <div className="align-center gap-15">
                <SearchComponent {...props} className="contacts-filter-search" startIcon placeholder="Search here" />
                <FPButton
                  className="d-flex ml-auto"
                  label="Add Department"
                  size="small"
                  onClick={() => toggleDepartment(null, true)}
                  endIcon={<FaPlus style={{ fontSize: "14px" }} />}
                />
              </div>
            )}
            {navActive === "Designation" && (
              <div className="align-center gap-15">
                <SearchComponent {...props} className="contacts-filter-search" startIcon placeholder="Search here" />
                <FPButton
                  className="d-flex ml-auto"
                  label="Add Designation"
                  size="small"
                  onClick={() => toggleDesignation(null, true)}
                  endIcon={<FaPlus style={{ fontSize: "14px" }} />}
                />
              </div>
            )}
          </div>
        }
      >
        <div className="mt-4">
          {navActive === "Team" && (
            <TeamList
              members={members}
              toggle={toggle}
              deleteMember={deleteMember}
              count={count}
              location={location}
              pageSizeCookie={pageSizeCookie}
              department={department}
              designation={designation}
              handleTeamItemParams={handleTeamItemParams}
            />
          )}

          {navActive === "Department" && (
            <DepartmentList
              department={department}
              toggleDepartment={toggleDepartment}
              deleteDepartment={deleteDepartment}
              pageSizeCookie={departmentPageSizeCookie}
              location={location}
              count={departmentCount}
              handleTeamItemParams={handleTeamItemParams}
            />
          )}
          {navActive === "Designation" && (
            <DesignationList
              designation={designation}
              toggleDesignation={toggleDesignation}
              deleteDesignation={deleteDesignation}
              pageSizeCookie={designationPageSizeCookie}
              location={location}
              count={designationCount}
              handleTeamItemParams={handleTeamItemParams}
            />
          )}
        </div>
      </CardWrapper>
    </div>
  );
};

MemberComponent.propTypes = {};

export default MemberComponent;
