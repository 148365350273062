import React from "react";
import { connect } from "react-redux";
import { FPTab } from "../../components/common/new/tabs";

const BranchRootHeader = ({ defaultValue, businessProfile, history, businessUser }) => {
  const redirectTo = (url) => {
    setTimeout(() => {
      history.push(url);
    }, 400);
  };

  const handleRootTabChange = (value) => {
    switch (value) {
      case 0:
        redirectTo(`/${businessUser.id}/central-setup?page_size=10`);
        break;
      case 1:
        redirectTo(`/${businessUser.id}/${businessUser.cooprate_branch}/business-profile`);
        break;
      default:
        break;
    }
  };

  return (
    <div className={"mb-4"}>
      <FPTab
        tabLabels={["Central Setup", "Branch Setup"]}
        minTabWidth={220}
        defaultValue={defaultValue}
        onChange={(value) => handleRootTabChange(value)}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    businessProfile: state.profile.user,
    businessUser: state.business.detail,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(BranchRootHeader);
