import React from "react";
import Chartwrapper from "../../modules/dashboard/component/Chartwrapper";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { starRatingColor } from "../../libs/utils";
import LoaderComponent from "../common/loaderComponent";
import { Box } from "@material-ui/core";
import { FPToolTip, NoData } from "../common/new";
import { Skeleton, useMediaQuery } from "@mui/material";
import { DashboardRedirect } from "../../images/svg";

const MenuPerformanceCard = (props) => {
  const {
    business_id,
    updateRouterState,
    params,
    fetchMenuPerformanceData,
    menuPerformanceData,
    isLoading,
  } = props;

  const large = useMediaQuery("(max-width: 1450px)");
  const medium = useMediaQuery("(max-width: 1250px)");
  const small = useMediaQuery("(max-width: 990px)");

  // useEffect(() => {
  //   const fetch = async () => {
  //     await fetchMenuPerformanceData(queryString.parse(params));
  //   };
  //   fetch();
  // }, []);

  const updateData = (datePayload) => {
    fetchMenuPerformanceData(datePayload);
  };
  return (
    <>
      <Chartwrapper
        onDateChange={updateData}
        skeletonLoader={isLoading}
        headerTitle={
          <div className="d-flex align-items-center gap-6">
            <p className="m-0">Menu Performance</p>
            <span
              className="cursor-pointer"
              onClick={() => {
                updateRouterState(
                  `/${business_id}/insights?date_type=prior_30_day`,
                  { activeTab: "5" }
                );
              }}
            >
              <DashboardRedirect />
            </span>
          </div>
        }
        className="fp-dashboard-chart-card h-100"
        widthElevation={true}
        descriptionStyle={{
          height: "calc(100% - 48px)%",
        }}
      >
        {menuPerformanceData?.top?.length > 0  || isLoading ? (
          <>
            <div className="d-flex gap-15">
              {/* Top 5 Dishes */}
              <CardWrapper
                headerTitle="Top 5 Dishes"
                className="fp-card-outline px-3 pt-3 pb-0"
                contentClass="py-1"
                skeletonLoader={isLoading}
              >
                {isLoading ? (
                  <table
                    style={{
                      border: "none",
                      width: "100%",
                      borderSpacing: "10px",
                    }}
                  >
                    <tbody>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <tr className="align-center w-100" key={index}>
                          <td
                            style={{
                              width: "60%",
                              padding: "8px",
                            }}
                          >
                            <Skeleton width="100%" height={20} />
                          </td>
                          <td
                            style={{
                              width: "20%",
                              padding: "8px",
                            }}
                          >
                            <Skeleton width="100%" height={20} />
                          </td>
                          <td
                            style={{
                              width: "20%",
                              padding: "8px",
                            }}
                          >
                            <Skeleton width="100%" height={20} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <table
                    style={{
                      border: "none",
                      width: "100%",
                      borderSpacing: "10px",
                    }}
                  >
                    <tbody>
                      {menuPerformanceData.top
                        .slice(0, 5)
                        .map((item, index) => {
                          const { star_border_color: topColor } =
                            starRatingColor(+item.avg_rating || 1);
                          return (
                            <tr className="align-center w-100" key={index}>
                              <td
                                style={{
                                  color: "#7A8193",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  padding: "8px",
                                  width: "60%",
                                }}
                              >
                                <FPToolTip title={item.product_name}>
                                  <Box
                                    className="d-inline-block threeDot"
                                    component={"span"}
                                    sx={{
                                      maxWidth: small
                                        ? "40px" // If screen width is <= 990px
                                        : medium
                                        ? "60px" // If screen width is <= 1250px
                                        : large
                                        ? "90px" // If screen width is <= 1450px
                                        : "130px", // If screen width is greater than 1450px
                                    }}
                                  >
                                    {item.product_name}
                                  </Box>
                                </FPToolTip>
                              </td>
                              <Box
                                className="d-flex justify-content-between gap-15"
                                sx={{ width: "40%" }}
                              >
                                <td
                                  style={{
                                    color: topColor,
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {Number(item.avg_rating).toFixed(1) || 0}
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {item.review_count || 0}
                                </td>
                              </Box>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </CardWrapper>

              {/* Bottom 5 Dishes */}
              <CardWrapper
                headerTitle="Bottom 5 Dishes"
                className="fp-card-outline px-3 pt-3 pb-0"
                headerStyle={{
                  position: "relative",
                  zIndex: 2,
                }}
                contentClass="py-1"
                widthElevation={true}
                skeletonLoader={isLoading}
              >
                {isLoading ? (
                  <table style={{ border: "none", width: "100%" }}>
                    <tbody>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <tr className="align-center" key={index}>
                          <td
                            style={{
                              width: "60%",
                              padding: "8px",
                            }}
                          >
                            <Skeleton width="100%" height={20} />
                          </td>
                          <td
                            style={{
                              width: "20%",
                              padding: "8px",
                            }}
                          >
                            <Skeleton width="100%" height={20} />
                          </td>
                          <td
                            style={{
                              width: "20%",
                              padding: "8px",
                            }}
                          >
                            <Skeleton width="100%" height={20} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <table style={{ border: "none", width: "100%" }}>
                    <tbody>
                      {menuPerformanceData.bottom
                        .slice(0, 5)
                        .map((item, index) => {
                          const { star_border_color: bottomColor } =
                            starRatingColor(+item.avg_rating || 1);
                          return (
                            <tr className="align-center" key={index}>
                              <td
                                style={{
                                  color: "#7A8193",
                                  fontSize: "12px",
                                  fontWeight: "400",
                                  padding: "8px",
                                  width: "60%",
                                }}
                              >
                                <FPToolTip title={item.product_name}>
                                  <Box
                                    className="d-inline-block text-truncate"
                                    component={"span"}
                                    sx={{
                                      maxWidth: small
                                        ? "40px" // If screen width is <= 990px
                                        : medium
                                        ? "60px" // If screen width is <= 1250px
                                        : large
                                        ? "90px" // If screen width is <= 1450px
                                        : "130px", // If screen width is greater than 1450px
                                    }}
                                  >
                                    {item.product_name}
                                  </Box>
                                </FPToolTip>
                              </td>
                              <Box
                                className="d-flex justify-content-between gap-15"
                                sx={{ width: "40%" }}
                              >
                                <td
                                  style={{
                                    color: bottomColor,
                                    fontSize: "14px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {Number(item.avg_rating).toFixed(1) || 0}
                                </td>
                                <td
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "500",
                                    paddingRight: "10px",
                                  }}
                                >
                                  {item.review_count || 0}
                                </td>
                              </Box>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </CardWrapper>
            </div>
          </>
        ) : (
          <>
            <NoData />
          </>
        )}
      </Chartwrapper>
    </>
  );
};

export default MenuPerformanceCard;
