import React, { useState, useEffect, useRef, useMemo } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import classNames from "classnames";
import FormModal from "../../FormModal";
import ReviewTag from "../../../business/ReviewTag";

const ItemSelectionList = ({
  allFilterList,
  activeItemKey,
  handelFilterItem,
  formikConfig,
  showAll,
  toggleShowAll,
  location,
  tagsListing,
  isReviewTag,
  isCategoryTag,
  categoryTagsListing,
  isProductName,
  productNameList,
  withContent,
  setCurrentOpenFilterItem,
  ReviewWidgetFilter,
  locationFilter,
}) => {
  const containerRef = useRef(null);
  const [visibleCount, setVisibleCount] = useState(allFilterList.length);
  const [tagPopupName, setTagPopupName] = useState(null); // Keeps track of opened modal

  const { getFieldMeta, setFieldValue } = formikConfig;
  const { value } = getFieldMeta();

  if (ReviewWidgetFilter && !withContent && value.max_char_len?.length) {
    setCurrentOpenFilterItem(null);
  }
  if (ReviewWidgetFilter && !withContent && value.min_char_len?.length) {
    setCurrentOpenFilterItem(null);
  }

  const isItemSelected = (values, key) =>
    values && key && values[key] && values[key].length;

  const totalItem = useMemo(
    () => allFilterList?.length + (isReviewTag ? 1 : 0), // Include Review Tag in total count
    [JSON.stringify(allFilterList), isReviewTag]
  );

  const handleClick = (modalType) => {
    setTagPopupName(modalType); // Set the modal type to open
  };

  const handleOnAdd = (tags) => {
    if (tagPopupName === "review") {
      formikConfig.setFieldValue(
        "tag_id",
        tags.map((tag) => ({
          ...tag,
          parentKey: "tag_id",
        }))
      );
    } else if (tagPopupName === "category") {
      formikConfig.setFieldValue(
        "tag_category_id",
        tags.map((tag) => ({
          ...tag,
          parentKey: "tag_category_id",
        }))
      );
    } else if (tagPopupName === "product") {
      
      formikConfig.setFieldValue(
        "product",
        tags.map((tag) => ({
          display_name: tag?.display_name,
          value: tag?.display_name,
          parentKey: "product",
        }))
      );
    }
  };

  const initialFormValues = () => {
    if (tagPopupName === "review") {
      return formikConfig.values.tag_id?.map((item) => ({
        display_name: item.display_name,
        value: Number(item.value),
      }));
    } else if (tagPopupName === "category") {
      return formikConfig.values.tag_category_id?.map((item) => ({
        display_name: item.display_name,
        value: Number(item.value),
      }));
    } else if (tagPopupName === "product") {
      return formikConfig.values.product?.map((item) => ({
        display_name: item.display_name,
        value: item.display_name,
      }));
    }

    return [];
  };

  // Handle visible item count based on container width
  useEffect(() => {
    const calculateVisibleCount = () => {
      const card = containerRef.current;
      const cardStyle = getComputedStyle(card);
      const cardPadding =
        parseFloat(cardStyle.paddingLeft) + parseFloat(cardStyle.paddingRight);
      const containerWidth = card.clientWidth - cardPadding;
  
      const averageItemWidth = 100; // Fixed average width for each item
      const maxVisibleCount = Math.floor(containerWidth / averageItemWidth); // Number of items that can fit
      setVisibleCount(showAll ? allFilterList.length : maxVisibleCount);
    };
  
    calculateVisibleCount();
    window.addEventListener("resize", calculateVisibleCount);
  
    return () => {
      window.removeEventListener("resize", calculateVisibleCount);
    };
  }, [allFilterList.length, showAll]);

  return (
    <section className="w-100 overflow-hidden" ref={containerRef}>
      <div
        className={classNames("gap-8 d-flex fp-flex-grow-1", {
          "flex-wrap": showAll,
        })}
      >
        {allFilterList?.map((filterItem, index) => {
          const isDisabled =
            locationFilter &&
            filterItem?.disable &&
            (value.status?.length > 0 || value.provider?.length > 0)
              ? false
              : filterItem?.disable;
          return (
            <div
              key={`${index}-filter-item`}
              className={classNames(
                "fp-filter-item vertical-align cursor-pointer",
                {
                  "disable-filter-item": isDisabled,
                  [`${filterItem.key}_disable`]: isDisabled,
                  "active-filter-item": activeItemKey === filterItem.key,
                  "hide-filter-item": index + 1 > visibleCount && !showAll,
                }
              )}
              role="button"
              onClick={() => handelFilterItem(filterItem)}
            >
              <p
                className={`fp-filter-item-label mb-0 ${
                  activeItemKey !== filterItem.key &&
                  isItemSelected(formikConfig.values, filterItem.key) &&
                  "filter-item-list-selected"
                }`}
                style={filterItem.labelStyle || {}}
              >
                {filterItem.label}
              </p>
              {activeItemKey === filterItem.key ? (
                <KeyboardArrowUpIcon color="disabled" />
              ) : (
                <KeyboardArrowDownIcon
                  color="disabled"
                  style={
                    isItemSelected(formikConfig.values, filterItem.key)
                      ? { color: "#00B5AD" }
                      : { color: "#C5C5C5" }
                  }
                />
              )}
            </div>
          );
        })}

        {/* Render Review Tag */}
        {isReviewTag && (
          <div
            className={classNames(
              "fp-filter-item vertical-align cursor-pointer",
              {
                "hide-filter-item":
                  allFilterList.length + 1 > visibleCount && !showAll,
              }
            )}
            onClick={() => handleClick("review")}
          >
            <p
              className={classNames("fp-filter-item-label mb-0", {
                "filter-item-list-selected":
                  formikConfig.values.tag_id?.length > 0,
              })}
            >
              Review Tag
            </p>
            {tagPopupName === "review" ? (
              <KeyboardArrowUpIcon
                color="disabled"
                style={{ color: "#00B5AD" }}
              />
            ) : (
              <KeyboardArrowDownIcon color="disabled" />
            )}
          </div>
        )}

        {/* Render Category Tag */}
        {isCategoryTag && (
          <div
            className={classNames(
              "fp-filter-item vertical-align cursor-pointer",
              {
                "hide-filter-item":
                  allFilterList.length + 1 > visibleCount && !showAll,
              }
            )}
            onClick={() => handleClick("category")}
          >
            <p
              className={classNames("fp-filter-item-label mb-0", {
                "filter-item-list-selected":
                  formikConfig.values.tag_category_id?.length > 0,
              })}
            >
              Category Tag
            </p>
            {tagPopupName === "category" ? (
              <KeyboardArrowUpIcon
                color="disabled"
                style={{ color: "#00B5AD" }}
              />
            ) : (
              <KeyboardArrowDownIcon color="disabled" />
            )}
          </div>
        )}

        {isProductName && (
          <div
            className={classNames(
              "fp-filter-item vertical-align cursor-pointer",
              {
                "hide-filter-item":
                  allFilterList.length + 1 > visibleCount && !showAll,
              }
            )}
            onClick={() => handleClick("product")}
          >
            <p
              className={classNames("fp-filter-item-label mb-0", {
                "filter-item-list-selected":
                  formikConfig.values.product?.length > 0,
              })}
            >
              Product
            </p>
            {tagPopupName === "product" ? (
              <KeyboardArrowUpIcon
                color="disabled"
                style={{ color: "#00B5AD" }}
              />
            ) : (
              <KeyboardArrowDownIcon color="disabled" />
            )}
          </div>
        )}


        {/* Show 'See More' or 'See Less' */}
        {visibleCount < totalItem && (
          <p
            className="fp-filter-item vertical-align cursor-pointer font-blue weight-500 text-nowrap"
            onClick={toggleShowAll}
          >
            {showAll ? "See Less" : `${(totalItem - visibleCount) + 1}+ More`}
          </p>
        )}
      </div>

        <FormModal
          isOpen={!!tagPopupName}
          heading={tagPopupName === "review" ? "Review Tag" : tagPopupName === "category" ? "Category Tag" : "Product Name"}
          toggle={() => setTagPopupName(null)}
          width="800px"
          paddingDense
        >
          <ReviewTag
            onAdd={handleOnAdd}
            isProduct={tagPopupName === "product" ? true : false }
            tagsListing={
              tagPopupName === "review" ? tagsListing : tagPopupName === "category" ? categoryTagsListing : productNameList 
            }
            location={location}
            initialValue={initialFormValues()}
            toggleModal={() => setTagPopupName(null)}
          />
        </FormModal>
    </section>
  );
};

export default ItemSelectionList;
