/* eslint-disable */
import React, { useState } from "react";
import { FPButton, FPFilter } from "../../components/common/new";
import FormModal from "../../components/common/FormModal";
import { getAllParamsInArrayForm } from "../../libs/utils";

const ReviewWidgetFilter = (props) => {
  const {
    minLen,
    maxLen,
    review,
    sourceOptions,
    locationOptions,
    handleSubmit,
    ProviderWidget,
    dateOptions,
    withContent,
  } = props;
  const [isMoreThanNinety, setIsMoreThanNinety] = useState(false);

  const handleDefaultFilter = (list, key) => {
    const filter = window.location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable = defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list.map((item) => {
      item["isChecked"] = isKeyFilterAvailable && defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };

  const filterList = [
    {
      id: "1",
      label: "Minimum Characters",
      key: "min_char_len",
      type: "radio",
      isSearchable: false,
      list: minLen && handleDefaultFilter(minLen, "min_char_len"),
      disable: !withContent,
    },
    {
      id: "2",
      label: "Maximum Characters",
      key: "max_char_len",
      type: "radio",
      isSearchable: false,
      list: maxLen && handleDefaultFilter(maxLen, "max_char_len"),
      disable: !withContent,
    },
    {
      id: "3",
      label: "Number of Reviews",
      key: "num_of_reviews",
      type: "radio",
      isSearchable: false,
      list: review && handleDefaultFilter(review, "num_of_reviews"),
    },
    {
      id: "4",
      label: "Source",
      key: "provider_id",
      type: "checkbox",
      isSearchable: true,
      list: sourceOptions && handleDefaultFilter(sourceOptions, "provider_id"),
    },
    {
      id: "5",
      label: "Locations",
      key: "location_id",
      type: "radio",
      isSearchable: true,
      list: locationOptions && handleDefaultFilter(locationOptions, "location_id"),
    },
  ];

  const providerList = [
    {
      id: "6",
      label: "Date Range",
      key: "date_type",
      type: "radio",
      isSearchable: false,
      list: dateOptions && handleDefaultFilter(dateOptions, "date_type"),
    },
    {
      id: "7",
      label: "Source",
      key: "provider_id",
      type: "checkbox",
      isSearchable: true,
      list: sourceOptions && handleDefaultFilter(sourceOptions, "provider_id"),
    },
    {
      id: "8",
      label: "Locations",
      key: "location_id",
      type: "radio",
      isSearchable: true,
      list: locationOptions && handleDefaultFilter(locationOptions, "location_id"),
    },
  ];

  return (
    <React.Fragment>
      <FPFilter
        data={ProviderWidget ? providerList : filterList}
        className="mt-1"
        history={history}
        // pageSizeCookie={pageSizeCookie}
        ticketFilter="REVIEW_WIDGET"
        applyBtnText={"Submit"}
        hideResetBtn={true}
        withContent={withContent}
        customOnApply={(value) => {
          handleSubmit(value);
        }}
        ReviewWidgetFilter
      />
      {isMoreThanNinety && (
        <FormModal
          isOpen={isMoreThanNinety}
          toggle={() => setIsMoreThanNinety(false)}
          heading="Attention !"
          maxWidth="xs"
          showDivider={true}
        ></FormModal>
      )}
    </React.Fragment>
  );
};

export default ReviewWidgetFilter;
