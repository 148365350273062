import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Card } from "reactstrap";
import CodeHighlighter from "./CodeHighlighter";
import { displayMessage } from "../../libs/utils";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import ReviewWidgetFilter from "./ReviewWidgetFilter";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { FPButton } from "../common/new";

const ProviderWidget = ({ businessId, sourceOptions, locationOptions, dateOptions }) => {
  const [code, setCode] = useState("");
  const [srcUrl, setSrcUrl] = useState("");
  const iframeRef = useRef(null);

  const [iframeHeight, setIframeHeight] = useState("0px");

  const handleApi = (value) => {
    console.log(value.date_type);
    let start_date = "";
    let end_date = "";
    let date_type = "";
    let branch_id = value.location_id.length > 0 ? value.location_id[0].value : ''
    let provider_id = value.provider_id ? value.provider_id.map((item) => item.value).join() : "";
    if(value.date_type && value.date_type.length>0)  {
      if (value.date_type[0].value === "other" )  {
        const parts = value.date_type[0].display_name.split(" to ");
        start_date = parts[0].replace("/", "-").replace("/", "-");
        start_date = start_date.split("-").reverse().join("-");
        end_date = parts[1].replace("/", "-").replace("/", "-");
        end_date = end_date.split("-").reverse().join("-");
      }
      date_type= value.date_type[0].value;
    } 
    else { 
      date_type="";
    }

    axios_instance.get(`${utils.format(URLS.PROVIDER_WIDGET, [businessId])}?date_type=${date_type}&branch=${branch_id}&provider=${provider_id}&start_date=${start_date}&end_date=${end_date}`)
      .then((res) => {
        setSrcUrl(res.data.url);
        let text = `<script src="https://api.famepilot.com/static/widget/review_widget.js"></script> \n<iframe id="fp_review_widget" src="${res.data.url}" frameborder="0" scrolling="no" style="min-width: 100%; width: 100%;" style="border: none;"></iframe>`;
        setCode(text);
      }).catch((err) => {
        console.log(err);
        displayMessage("Error", "Error");
      });
  };

  const handleSubmit = (value) => {
    handleApi(value);
  };

  useEffect(() => {
    const handleMessage = (e) => {
      if (Array.isArray(e.data) && e.data.length >= 2) {
        const eventName = e.data[0];
        const data = e.data[1];
        if (eventName === "fp.setHeight" && data && data.id === "fp_review_widget") {
          setIframeHeight(data.height + "px");
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [srcUrl]);

  return (
    <div>
      <Card className="my-4" body>
        <div style={{ display: "flex", gap: "20px", flexWrap: "wrap", alignItems: "center" }} >
          <ReviewWidgetFilter
            sourceOptions={sourceOptions}
            locationOptions={locationOptions}
            dateOptions={dateOptions}
            handleSubmit={handleSubmit}
            ProviderWidget={true}
          />
        </div>
      </Card>

      <CardWrapper headerTitle="Preview" className="my-4">
        <iframe
          ref={iframeRef}
          src={srcUrl}
          title="Provider Widget"
          style={{ width: "100%", height: 'auto', border: "none" }}
        />
      </CardWrapper>

      <Card body>
        <CodeHighlighter code={code} className="mb-2" />
        <div style={{ textAlign: "right" }}>
          <FPButton
            label="Copy Code"
            onClick={() => {
              navigator.clipboard.writeText(code);
              displayMessage("positive", "Copied to clipboard");
            }}
          />
        </div>
      </Card>
    </div>
  );
};

export default ProviderWidget;
