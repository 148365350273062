import React, { useState } from "react";
import Calendar from "react-calendar";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import filterIcon from "../../images/svg/filter.svg";
import { Box } from "@material-ui/core";
import { FPButton } from "../common/new";
import ResetIcon from "../../images/Reset.png";
import gmailgiftIcon from "../../images/svg/gmail business.svg";
import checkIcon from "../../images/svg/check_svg.svg";
import postImg from "../../images/momo.png";
import "../../containers/business/publisher.css";
import FPSelect from "../common/new/forms/ui/FPSelect";
import Popover from "@material-ui/core/Popover";
import checkCircle from "../../images/svg/check_svg.svg";
import WeeklyPost from "./WeeklyPost";
import { Divider } from "semantic-ui-react";
import img1 from "../../images/momo.png";
import img2 from "../../images/burger.jpg";
import editIcon from "../../images/svg/Edit.svg";
import deleteicon from "../../images/svg/delete.svg";
import MonthlyPost from "./MonthlyPost";
import calenderTimer from "../../images/svg/CalenderTimer.svg";
import PostModel from "../common/PostModel";
import ConfirmationModal from "../common/ConfirmationModal";

const SocialPostCalendra = () => {
  var disabledDate = new Date();
  disabledDate.setDate(disabledDate.getDate() - 1);
  const [listTab, setlistTab] = useState("week");
  const [showFilters, setShowFilters] = useState(null);
  const [openPost, setOpenPost] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState([]);
  const [activePost, setActivePost] = useState(null);
  const [isConfirm, setIsConfirm] = useState(false);

  const toggleListTabs = (value) => {
    setlistTab(value);
  };

  const openFilter = Boolean(showFilters);
  const openPostPopoup = Boolean(openPost);

  const openFilterBtn = (e) => {
    setShowFilters(e.currentTarget);
  };

  const closeFilterModel = () => {
    setShowFilters(!showFilters);
  };

  const openPostDetails = () => {
    setOpenPost(!openPost);
  };

  const togglePostModal = () => {
    setOpenPost(!openPost);
  };

  const statusOptions = [
    { value: "first", label: "First" },
    { value: "second", label: "Second" },
    { value: "third", label: "Third" },
  ];

  const platformOptions = [
    { value: "one", label: "one" },
    { value: "Two", label: "Two" },
    { value: "three", label: "three" },
  ];

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handlePlatformChange = (e) => {
    setSelectedPlatform(e.target.value);
  };

  const applyHandler = () => {};

  const resetHandler = () => {
    setSelectedStatus(null);
    setSelectedPlatform(null);
    setShowFilters(!showFilters);
  };

  const closePostModel = () => {
    setOpenPost(!openPost);
  };

  const calendarDate = [
    {
      weekDay: "Sun",
      month: "Jan",
      date: 10,
      time: "12:00pm",
      leftIconI: checkCircle,
      leftIconII: calenderTimer,
      rightIcon: gmailgiftIcon,
      divider: <Divider className="my-2" />,
      // offerText: ["30% off on all Italian delicacies plus 20% cashback!", "Buy 1 Get 1 Free"],
      offerI: ["30% off on all Italian delicacies plus 20% cashback!"],
      offerII: "Buy 1 Get 1 Free",
      images: [img1, img2, img1],
      editImg: editIcon,
      deletImg: deleteicon,
    },
    {
      weekDay: "Mon",
      month: "Feb",
      date: 11,
    },
    {
      weekDay: "Tue",
      month: "Mar",
      date: 12,
    },
    {
      weekDay: "Wed",
      month: "Apr",
      date: 13,
      time: "12:00pm",
      leftIconI: checkCircle,
      leftIconII: calenderTimer,
      rightIcon: gmailgiftIcon,
      divider: <Divider className="my-2" />,
      offerI: ["30% off on all Italian delicacies plus 20% cashback!"],
      offerII: "Buy 1 Get 1 Free",
      images: [img1, img2, img1],
      editImg: editIcon,
      deletImg: deleteicon,
    },
    {
      weekDay: "Thu",
      month: "May",
      date: 14,
    },
    {
      weekDay: "Fri",
      month: "Jun",
      date: 15,
    },
    {
      weekDay: "Sat",
      month: "July",
      date: 16,
      time: "12:00pm",
      leftIconI: checkCircle,
      leftIconII: calenderTimer,
      rightIcon: gmailgiftIcon,
      divider: <Divider className="my-2" />,
      offerI: ["30% off on all Italian delicacies plus 20% cashback!"],
      offerII: "Buy 1 Get 1 Free",
      images: [img1],
      editImg: editIcon,
      deletImg: deleteicon,
    },
  ];

  const addPostHandler = (index) => {
    setActivePost(index);
  };

  const deletePost = () => {
    setIsConfirm(!isConfirm);
  };

  const toggleConfirm = () => {
    setIsConfirm(false);
  };

  const dateObj = new Date();
  const day = dateObj.getDay();
  const daylist = ["Sun", "Mon", "Tue", "Wed ", "Thu", "Fri", "Sat"];
  const currentDay = daylist[day];
  const date = String(dateObj.getDate()).padStart(2, "0");
  const year = dateObj.getFullYear();

  return (
    <>
      <CardWrapper
        showDivider={false}
        className="mb-5"
        headerTitle={
          <div className="align-center justify-content-between">
            <div>
              <div className="todayDate">
                Today ({currentDay} {date}, {year})
              </div>
            </div>
            <div className="align-center gap-10">
              <Nav className="Setup_Sub_Nav" style={{ fontSize: "1rem" }}>
                <NavItem>
                  <NavLink onClick={() => toggleListTabs("week")} active={listTab === "week"}>
                    Week
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink onClick={() => toggleListTabs("month")} active={listTab === "month"}>
                    Month
                  </NavLink>
                </NavItem>
              </Nav>
              <img src={filterIcon} onClick={openFilterBtn} style={{ cursor: "pointer" }} />
            </div>
          </div>
        }
      >
        <TabContent activeTab={listTab}>
          <TabPane tabId="week">
            <WeeklyPost
              activePost={activePost}
              calendarDate={calendarDate}
              addPostHandler={addPostHandler}
              openPostDetails={openPostDetails}
            />
          </TabPane>
          <TabPane tabId="month">
            <MonthlyPost
              activePost={activePost}
              calendarDate={calendarDate}
              addPostHandler={addPostHandler}
              openPostDetails={openPostDetails}
            />
          </TabPane>
        </TabContent>
      </CardWrapper>

      {openFilter && (
        <Popover
          open={openFilter}
          anchorEl={showFilters}
          onClose={closeFilterModel}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="p-3">
            <FPSelect
              wrapperClass="w-360"
              name="status"
              placeHolder="Status"
              className=""
              options={statusOptions}
              onChange={handleStatusChange}
              value={selectedStatus}
            />
            <FPSelect
              className="mt-4"
              wrapperClass="w-360"
              name="platform"
              placeHolder="Platform"
              options={platformOptions}
              onChange={handlePlatformChange}
              value={selectedPlatform ? selectedPlatform : ""}
            />

            <div className="d-flex gap-15 justify-content-end mt-4">
              <FPButton type="submit" fontSize="16px" style={{ height: "34px" }} onClick={applyHandler}>
                Apply
              </FPButton>
              <FPButton
                type="button"
                fontSize="16px"
                backgroundColor="#00B5AD"
                style={{ height: "34px" }}
                startIcon={<img src={ResetIcon} />}
                onClick={resetHandler}
              >
                Reset
              </FPButton>
            </div>
          </div>
        </Popover>
      )}

      {openPostPopoup && (
        <>
          <Popover
            open={openFilter}
            anchorEl={openPostPopoup}
            onClose={closePostModel}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="align-center justify-content-between">
              <Box sx={{ fontSize: "15px" }}>
                <Box component="span" sx={{ color: "#1B1C1D" }} className="align-center gap-6">
                  Posted on 1 Feb 2024 <img src={checkIcon} />
                </Box>
                <Box component="span" sx={{ display: "block", color: "#7A8193" }}>
                  Steaming Savory Summits with ️
                  <Box component="span" sx={{ display: "block", color: "#7A8193" }}>
                    Himalayan Momo Delight
                  </Box>
                </Box>
              </Box>
              <div>
                <img src={gmailgiftIcon} />
              </div>
            </div>
            <div className="postDetails">
              <img src={postImg} />
            </div>
          </Popover>
        </>
      )}
      <PostModel
        isOpen={openPost}
        toggle={togglePostModal}
        heading="Post Details"
        width={"700px"}
        headerClass="align-center"
        deletePost={deletePost}
      >
        <div className="align-center justify-content-between">
          <Box component="span" sx={{ color: "#1B1C1D", fontSize: "15px" }} className="align-center gap-6">
            Posted on 1 Feb 2024 <img src={checkIcon} />
          </Box>

          <div>
            <img src={gmailgiftIcon} />
          </div>
        </div>
        <Box component="span" sx={{ display: "block", color: "#7A8193", fontSize: "15px" }}>
          Steaming Savory Summits with ️
          <Box component="span" sx={{ display: "block", color: "#7A8193" }}>
            Himalayan Momo Delight
          </Box>
        </Box>
        <div className="postDetails">
          <img src={postImg} />
        </div>
      </PostModel>
      <ConfirmationModal
        header="Are you sure!"
        style={{ fontWeight: 600, fontSize: "18px", color: "#1B1C1D" }}
        subHeader={`Are you sure you want to delete this`}
        isOpen={isConfirm}
        toggle={() => setIsConfirm(false)}
        onConfirm={() => toggleConfirm()}
      />
      <div className="container mt-2">
        <div className="row">
          <div className="col-md-12">
            <Calendar className="social_post_calendar" minDate={disabledDate} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialPostCalendra;
