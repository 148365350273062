import React from 'react';

const CommentIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21" fill="none">
      <path
        d="M11.5875 19.3496L10.5 18.7246L13 14.3496H16.75C17.0815 14.3496 17.3995 14.2179 17.6339 13.9835C17.8683 13.7491 18 13.4311 18 13.0996V5.59961C18 5.26809 17.8683 4.95015 17.6339 4.71573C17.3995 4.48131 17.0815 4.34961 16.75 4.34961H4.25C3.91848 4.34961 3.60054 4.48131 3.36612 4.71573C3.1317 4.95015 3 5.26809 3 5.59961V13.0996C3 13.4311 3.1317 13.7491 3.36612 13.9835C3.60054 14.2179 3.91848 14.3496 4.25 14.3496H9.875V15.5996H4.25C3.58696 15.5996 2.95107 15.3362 2.48223 14.8674C2.01339 14.3985 1.75 13.7627 1.75 13.0996V5.59961C1.75 4.93657 2.01339 4.30068 2.48223 3.83184C2.95107 3.363 3.58696 3.09961 4.25 3.09961H16.75C17.413 3.09961 18.0489 3.363 18.5178 3.83184C18.9866 4.30068 19.25 4.93657 19.25 5.59961V13.0996C19.25 13.7627 18.9866 14.3985 18.5178 14.8674C18.0489 15.3362 17.413 15.5996 16.75 15.5996H13.725L11.5875 19.3496Z"
        fill="#F13A88" />
      <path d="M15.5 6.84961H5.5V8.09961H15.5V6.84961Z" fill="#F13A88" />
      <path d="M11.75 10.5996H5.5V11.8496H11.75V10.5996Z" fill="#F13A88" />
    </svg>
  );
};

export default CommentIcon;