import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './fpSwitch.css';

const FPSwitch = (props) => {
  const { id, defaultValue, onChange, yesLabel, noLabel } = props;
  const [isChecked, setIsChecked] = useState(defaultValue);

  const handleChecked = (event) => {
    const hasChecked = event.currentTarget.checked;
    setIsChecked(hasChecked);
    onChange && onChange(hasChecked);
  };

  return (
    <div className="can-toggle">
      <input
        id={id}
        type="checkbox"
        checked={isChecked}
        onChange={handleChecked}
      />
      <label htmlFor={id} className='switch-label'>
        <div
          className="can-toggle__switch"
          data-checked={yesLabel || 'Yes'}
          data-unchecked={noLabel || 'No'}
        ></div>
      </label>
    </div>
  );
};

FPSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  yesLabel: PropTypes.string,
  noLabel: PropTypes.string,
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FPSwitch;
