import React, { useState, useRef, useEffect } from "react";
import ListingFPInput from "../../../common/new/Listing/ListingFPInput";
import FPButton from "../../../common/new/FPFilter/Listing/FPButton";
import { Chip, Stack } from "@mui/material";
import "./Addmenu.css"; // Create a CSS file for your styles
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../actions/listing/listingLocationAction";
import * as utils from "../../../../libs/utils";
import CloseIcon from "@material-ui/icons/Close";

const AddMenu = (props) => {
  const {
    location_food_menu_data,
    actions,
    selectReso,
    business_id,
    media_data,
    handleClose,
    update_listing_data,
    indexValue,
    sectionIndexValue,
    menuIndexValue,
  } = props;
  console.log("media_datamedia_datamedia_datamedia_datamedia_data", media_data);
  console.log("indexValueindexValueindexValueindexValueindexValue", indexValue);
  const fileInputRef = useRef(null);

  // State to store input values
  const [itemName, setItemName] = useState("");
  const [itemPrice, setItemPrice] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [selectedDietary, setSelectedDietary] = useState("");
  const [nameError, setNameError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  const handleValueHandler = (value, type) => {
    if (type === "itemName") {
      if (value?.length > 40) {
        setNameError("Item Name should be less than 40 characters");
      } else {
        setItemName(value);
        setNameError("");
      }
    } else if (type === "itemPrice") {
      if (value?.length > 40) {
        setPriceError("Item price should be less than 40 characters");
      } else {
        setItemPrice(value);
        setPriceError("");
      }
    } else if (type === "itemDescription") {
      if (value?.length > 1000) {
        setDescriptionError("Item Description should be less than 1000 characters");
      } else {
        setItemDescription(value);
        setDescriptionError("");
      }
    }
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleChipClick = (dietary) => {
    // Toggle selection: if already selected, unselect it; otherwise, select it
    setSelectedDietary((prevDietary) => (prevDietary === dietary ? "" : dietary));
  };

  // const handleFileChange = (event) => {
  //     const files = event.target.files;
  //     console.log("Selected files:", files);
  // };

  const [uploadImage, setUploadImage] = useState([]);
  const [validPhoto, setValidPhoto] = useState([]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    const minFileSize = 15 * 1024; // 15 KB in bytes
    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
    const minWidth = 250;
    const minHeight = 250;
    files.forEach((file) => {
      const newPhotos = [];
      const validImages = [];
      if (!validImageTypes.includes(file.type)) {
        utils.displayMessage("negative", "Please upload a valid Image file (JPG, JPEG, or PNG)");
        return;
      } else if (file.size < minFileSize || file.size > maxFileSize) {
        utils.displayMessage("negative", "Please upload an image between 15KB and 5MB");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          if (img.width < minWidth || img.height < minHeight) {
            utils.displayMessage("negative", `Minimum resolution required is ${minWidth}x${minHeight}px`);
            return;
          }

          // If the image is valid, add it to the state
          validImages.push(e.target.result);
          setValidPhoto(validImages);
          newPhotos.push(file);
          setUploadImage(newPhotos);
          // console.log("setUploadImagesetUploadImagesetUploadImagesetUploadImage", newPhotos)
          // console.log("validImagesvalidImagesvalidImagesvalidImagesvalidImages", validImages)
          // if (tabKey === "cover") {
          // this.setState((prevState) => ({
          //     coverPhoto: [...prevState.coverPhoto, ...newPhotos],
          //     coverPhotoDisplay: [
          //         ...prevState.coverPhotoDisplay,
          //         ...validImages,
          //     ],
          // }));
          // }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
    event.target.value = null;
  };

  const updateMenuItemImage = async () => {
    const restodetail = { business_id, selectReso };

    const uploadFormData = new FormData();

    if (uploadImage?.length > 0) {
      uploadImage?.forEach((photo) => {
        uploadFormData.append("FOOD_AND_DRINK", photo);
      });
      uploadFormData.append("type", "mediaUpload");
      const an = await actions.getGmbListingUpdateListing(uploadFormData, restodetail, true, "menuImage");
    }
  };

  const updateMenuItem = async (media_key) => {
    const restodetail = { business_id, selectReso };

    const newSection = {
      labels: [
        {
          displayName: itemName,
          description: itemDescription,
          languageCode: "en",
        },
      ],
      attributes: {
        price: {
          currencyCode: "INR",
          units: itemPrice,
        },
        ...(selectedDietary && { dietaryRestriction: selectedDietary }),
        ...(media_key?.name && {
          mediaKeys: [media_key.name.split("/").pop()],
        }),
      },
    };

    // Update the existing food menu data with the new section
    const updatedMenuData = { ...location_food_menu_data?.food_menus };
    updatedMenuData?.menus[menuIndexValue]?.sections[indexValue]?.items?.push(newSection);

    // Create the payload with updated menu data
    const updateCode = {
      type: "updateMenu",
      updateMenuData: updatedMenuData,
    };

    // Dispatch the action or make the API call
    await actions?.getGmbListingUpdateListing(updateCode, restodetail);
    await actions.getGmbLocationFoodmenu(business_id, selectReso);
    handleClose();
  };

  useEffect(() => {
    if (update_listing_data?.mediaFormat) {
      updateMenuItem(update_listing_data);
    }
  }, [update_listing_data?.mediaFormat]);

  return (
    <>
      <div className="add-menu-container">
        <div className="menu-header">
          <span style={{ fontSize: "20px" }}>Create a new menu</span>
          <p className="menu-subtext">This menu will be publicly visible on your profile</p>
        </div>
        <div className="menu-items">
          <div className="menu-item-header">
            <label className="item-label">Add menu item</label>
            <p className="item-subtext">Enter at least one item per section. You can add more later</p>
          </div>
          <div className="menu-item-grid">
            <div className="menu-item-inputs" style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <div className="input-group-listing-detail-menu">
                <ListingFPInput
                  placeholder={"Item name*"}
                  value={itemName}
                  onChange={(e) => handleValueHandler(e.target.value, "itemName")}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: nameError ? "space-between" : "end",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {nameError && <span style={{ color: "red" }}>{nameError}</span>}
                  <p>{itemName.length} / 40</p>
                </div>
              </div>
              <div className="input-group-listing-detail-menu" style={{ marginBlock: "5px 10px" }}>
                <ListingFPInput
                  placeholder={"Item price (INR)"}
                  value={itemPrice}
                  onChange={(e) => handleValueHandler(e.target.value, "itemPrice")}
                  type="number"
                />
              </div>
              <div className="input-group-listing-detail-menu">
                <textarea
                  className="textAreaUI"
                  placeholder={"Item description"}
                  value={itemDescription}
                  onChange={(e) => handleValueHandler(e.target.value, "itemDescription")}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: descriptionError ? "space-between" : "end",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {descriptionError && <span style={{ color: "red" }}>{descriptionError}</span>}
                  <p>{itemDescription.length} / 1000</p>
                </div>
              </div>
            </div>
            <div className="upload-container" onClick={handleDivClick}>
              <div className="upload-grid">
                <div className="upload-text">
                  <span className="upload-click">Drag Photo here or </span>
                  <span className="upload-or">Select Photo</span>
                </div>
                <input
                  type="file"
                  accept="image/png, image/jpeg, image/jpg"
                  ref={fileInputRef}
                  className="file-input"
                  onChange={handleFileChange}
                  multiple
                />
              </div>
              <div
                // key={index}
                style={{
                  position: "relative",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                  left: "60px",
                  top: "50px",
                }}
              >
                {validPhoto.map((photo, index) => (
                  <>
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "5px",
                      }}
                    >
                      <img
                        src={photo}
                        alt={`uploaded-${index}`}
                        style={{
                          width: "70px",
                          height: "70px",
                          objectFit: "cover",
                          borderRadius: "10px",
                        }}
                      />
                      <CloseIcon
                        // onClick={() =>
                        //   this.handleUploadedDeletePhoto(photo, index)
                        // }
                        // onClick={() => this.handleUploadedDeletePhoto(photo, index, 'coverPhotoDisplay')}

                        style={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          cursor: "pointer",
                          color: "red",
                          borderRadius: "50%",
                          backgroundColor: "white",
                          width: "16px",
                          height: "16px",
                        }}
                      />
                    </div>
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="dietary-restrictions">
          <label className="dietary-label">Dietary restrictions</label>
          <span className="dietary-subtext">Select all that apply</span>
        </div>
        <Stack direction="row" spacing={1}>
          <Chip
            label="Vegetarian"
            variant={selectedDietary === "Vegetarian" ? "filled" : "outlined"}
            onClick={() => handleChipClick("Vegetarian")}
            sx={{
              backgroundColor: selectedDietary === "Vegetarian" ? "#bfe0e2" : "#F0F1F3",
              color: selectedDietary === "Vegetarian" ? "#00b5ad" : "default",
              fontSize: "14px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
            }}
          />
          <Chip
            label="Vegan"
            variant={selectedDietary === "Vegan" ? "filled" : "outlined"}
            onClick={() => handleChipClick("Vegan")}
            sx={{
              backgroundColor: selectedDietary === "Vegan" ? "#bfe0e2" : "#F0F1F3",
              color: selectedDietary === "Vegan" ? "#00b5ad" : "default",
              fontSize: "14px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
            }}
          />
        </Stack>
      </div>
      <div className="menu-footer">
        <FPButton
          label="Save"
          size="small"
          onClick={uploadImage?.length > 0 ? updateMenuItemImage : updateMenuItem}
          style={{
            height: "32px",
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    location_food_menu_data: state.gmbListingLocation.location_food_menu_data,
    media_data: state.gmbListingLocation.media_data,
    update_listing_data: state.gmbListingLocation.update_listing_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddMenu);
