import React, { useState } from 'react';
import { Field, reduxForm, SubmissionError, formValueSelector } from 'redux-form';
import { Row, Col } from 'reactstrap';
import { InputField } from 'react-semantic-redux-form';
import PhoneInputField from '../common/phonefields';
import TextAreaComponent from '../common/TextAreaComponent';
import {Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import SelectComponent from '../../components/common/SelectComponent';
import { FPButton } from '../common/new';

const validate = (values) => {
  const errors = {};
  if (!values.customer_name) errors.customer_name = 'Enter Name';
  if (!values.branch) errors.branch = 'Select branch';
  if(values.customer_email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i.test(values.customer_email)){
    errors.customer_email = 'Invalid email address';
  }
  if (!values.customer_email && !values.customer_phone) {
    if (!values.customer_email) errors.customer_email = 'Enter Email or Mobile Number ';
    if (!values.customer_phone) errors.customer_phone = 'Enter Mobile Number or Email ';
  }
  if(!values.description)errors.description = 'Enter description';
  return errors;
};

let CreateTicketForm = (props) => {
  const { handleSubmit, onSubmit, branchOptions, assigneesOptions, isBranchSelected, getTeamList } = props;
  // const { handleSubmit, onSubmit, branchOptions, assigneesOptions, isBranchSelected, getTeamList } = props;
  const [isAssigneesLoading, setIsAssigneesLoading] = useState(false);
const [assignList, setAssignList] = useState(null);
  const submit = (data) => {
    return onSubmit(data)
      .catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  };

  const getTeam = (params) => {
    setIsAssigneesLoading(true);
    getTeamList(params)
      .then((res) => {
        setAssignList(res?.data?.results)
        setIsAssigneesLoading(false)});
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(submit)} size='large'>
        <Row>
          <Col>
            <Field
              name="customer_name"
              component={InputField}
              placeholder="Name"
              type="text"
              label="Name"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <Field
              name="customer_email"
              component={InputField}
              placeholder="E-mail "
              type="email"
              label="Email"
            />
          </Col>
          <Col sm="6">
            <Field
              name="customer_phone"
              placeholder="Mobile Number"
              type="text"
              component={PhoneInputField}
              label="Mobile Number"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="6">
            <Field
              name="branch"
              type="text"
              label="Branch"
              placeholder="Branch"
              component={SelectComponent}
              options={branchOptions}
              simpleValue
              labelKey="display_name"
              valueKey="value"
              onChange={(e,branch) => getTeam({ branch })}
            />
          </Col>
          <Col sm="6">
            <Field
              name="assignees"
              type="text"
              placeholder="Assign To"
              label="Assign To"
              component={SelectComponent}
              options={assignList && assignList.map((item) => ({
                display_name: `${item.first_name} ${item.last_name}`,
                value: item.id,
              }))}
              simpleValue
              labelKey="display_name"
              valueKey="value"
              disabled={!isBranchSelected}
              // isLoading={isAssigneesLoading}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Field
              name="description"
              placeholder='Description'
              label="Description"
              type="text"
              component={TextAreaComponent}
              rows="5"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <FPButton label="Save" type="submit" className="w-100 b-radius-8" />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

CreateTicketForm = connect(state => ({
  isBranchSelected: formValueSelector('createTicketForm')(state, 'branch')
}))(CreateTicketForm);

export default reduxForm({
  form: 'createTicketForm',
  validate,
  enableReinitialize: true
})(CreateTicketForm);
