import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Card } from 'semantic-ui-react';

class DoughnutChart extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      chartData: {}
    };
  }

  componentDidMount(){
    const { semi } = this.props;
    this.chart.chartInstance.canvas.parentNode.style.height = '300px';
    this.setState({
      chartOptions: {
        rotation: semi && 1 * Math.PI,
        circumference: semi && 1 * Math.PI,
        maintainAspectRatio: false
      }
    });
  }
  componentDidUpdate(prevProps,prevState){
    const { chartData: { datasets: prevData } } = prevState; 
    const { data: nextData, dataKeys } = this.props;
    if(prevData!==nextData){
      this.setState({
        chartData: {
          datasets: nextData,
          labels: dataKeys,
        }
      });
    }
  }

  render(){
    const { chartData, chartOptions } = this.state;
    return (
      <Card body className="py-3 mb-3 w-100">
        <Doughnut
          ref={el => this.chart = el}
          data={chartData}
          options={chartOptions}
        />
      </Card>
    );
  }
}

export default DoughnutChart;