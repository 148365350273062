import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { InputField } from "react-semantic-redux-form";
import { FPButton } from "../../common/new";
import AuthLayout from "../../../containers/common/AuthLayout";
import LogoIcon from "../../../images/fp-icon.png";

const validate = (values) => {
  const errors = {};

  if (!values.new_password) {
    errors.new_password = "Enter New Password";
  }
  if (!values.confirm_password) errors.confirm_password = "Confirm Password";
  if (values.new_password && values.new_password.length < 6)
    errors.new_password = "Password should contain atleast 6 characters";
  if (
    values.new_password &&
    values.new_password.length >= 6 &&
    values.new_password &&
    !values.new_password.match(
      "^(?=.*[a-z])(?=.*[0-9])[A-Za-z\\d$@$!%*#?&]{6,123}$"
    )
  )
    errors.new_password =
      "Password should have at least 6 characters & 1 numeric character";
  if (
    values.new_password &&
    values.new_password &&
    values.new_password !== values.confirm_password
  )
    errors.confirm_password = "Password does not match";
  return errors;
};

const ResetPasswordForm = (props) => {
  const { handleSubmit, submitting, handleResetPassword } = props;
  const submit = (data) => {
    console.log("Submit-dat--:", data);
    return handleResetPassword(data).catch((err) => {
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  };

  return (
    <AuthLayout
      logoIcon={LogoIcon}
      title={"Reset your Password"}
      form={
        <form onSubmit={handleSubmit(submit)}>
          <div className="mb-4">
            <Field
              name="new_password"
              // icon="user"
              // iconPosition="left"
              component={InputField}
              placeholder="New Password"
              type="password"
              className="w-100 mb-1"
            />
          </div>

          <Field
            name="confirm_password"
            // icon="user"
            // iconPosition="left"
            component={InputField}
            placeholder="Confirm Password"
            type="password"
            className="w-100"
          />
          <FPButton
            label="Submit"
            type="submit"
            disabled={submitting}
            className="w-100"
            style={{ marginTop: "20px" }}
          />
        </form>
      }
    />
  );
};

export default reduxForm({
  form: "ResetPasswordForm", // a unique identifier for this form
  validate,
})(ResetPasswordForm);
