import React, { useState } from "react";
import { reduxForm, SubmissionError } from "redux-form";
import FPTextArea from "../common/new/forms/ui/FPTextArea";
import { FPButton, FPToolTip } from "../common/new";
import { Box, IconButton } from "@material-ui/core";
import { Notebook, AIResponse, Emoji, Send } from "../../images/svg";
import { Popover } from "@material-ui/core";
import EmojiPicker from "emoji-picker-react";
import FormModal from "../common/FormModal";
import "../../containers/business/mention.css";
import TemplateModal from "../../modules/socialComments/TemplateModal";

const validate = (values) => {
  const errors = {};
  if (!values.comment) {
    errors.comment = "Please Enter comment";
  }
  return errors;
};

const ReplyTweetForm = (props) => {
  const { handleSubmit, handleReplyPost } = props;
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [textMessage, setTextMessage] = useState("");
  const [openTemplate, setOpenTemplate] = useState(false);

  const submit = () => {
    let new_data = {
      comment: textMessage,
    };
    return handleReplyPost(new_data).catch((err) => {
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  };

  const popOpen = Boolean(openEmojiPicker);

  const handleClose = () => {
    setOpenEmojiPicker(null);
  };

  const handleTextChange = (e) => {
    setTextMessage(e.target.value);
  };

  const handleEmojiSelect = ({ emoji }) => {
    setTextMessage((preValue) => preValue + " " + emoji);
  };

  const tempToggle = () => {
    setOpenTemplate(!openTemplate);
  };

  return (
    <>
      <div style={{ padding: "10px 0px !important" }}>
        <form onSubmit={handleSubmit(submit)}>
          <Box sx={{ padding: "10px", background: "rgb(246, 246, 248)", borderRadius: "10px" }}>
            <FPTextArea
              name="comment"
              autoHeight
              placeholder="write your comment here..."
              padding="0px"
              sx={{
                "&.MuiBox-root": {
                  border: "none",
                },
                boxShadow: "none !important",
              }}
              style={{
                fontSize: "16px",
                background: "rgb(246, 246, 248)",
                height: "100px",
                border: "none",
                borderRadius: "10px",
                marginBottom: "-7px",
              }}
              minRows={17}
              value={textMessage}
              onChange={handleTextChange}
            />
          </Box>
          <div className="d-flex justify-content-between mt-2">
            <div>
              <FPToolTip title={"Smart Template Reply"} className="ms-2">
                <IconButton onClick={() => setOpenTemplate(true)} className="font-secondary" size="small">
                  <Notebook style={{ margin: 5 }} />
                </IconButton>
              </FPToolTip>
              <FPToolTip title={"AI Reply"} className="ms-2">
                <IconButton
                  // onClick={() => getInteligentResponse()}
                  className="font-secondary"
                  size="small"
                >
                  <AIResponse />
                </IconButton>
              </FPToolTip>
              <FPToolTip title={""} className="ms-2">
                <IconButton onClick={(event) => setOpenEmojiPicker(event)} className="font-secondary" size="small">
                  <Emoji />
                </IconButton>
              </FPToolTip>
            </div>
            <div>
              <FPButton
                endIcon={<Send style={{ height: "13px", width: "13px" }} />}
                size="small"
                className="px-4"
                type="submit"
              >
                Send reply
              </FPButton>
            </div>
          </div>
        </form>

        {openTemplate && (
          <FormModal
            bodyClass="pb-0"
            isOpen={openTemplate}
            toggle={tempToggle}
            width={"700px"}
            heading="Templates"
            headerClass="d-flex align-items-center"
          >
            <TemplateModal />
          </FormModal>
        )}

        {popOpen && (
          <Popover
            open={popOpen}
            anchorEl={openEmojiPicker}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <EmojiPicker
              skinTonesDisabled={true}
              width={300}
              size={10}
              lazyLoad={true}
              lazyLoadEmojis={true}
              onEmojiClick={handleEmojiSelect}
            />
          </Popover>
        )}
      </div>
    </>
  );
};

export default reduxForm({
  form: "ReplyTweetForm",
  enableReinitialize: true,
  validate,
})(ReplyTweetForm);
