import React from "react";
import { Progress } from "reactstrap";
import { Segment } from "semantic-ui-react";
import { truncatedNumber } from "../../../libs/helper";

const CompletionStatus = ({
  completionStatus,
  canHaveFoodMenus,
  canModifyServiceList,
}) => {
  return (
    <>
      <div className="row d-flex align-center">
        <div
          className="col-7"
          style={{
            color: "#1A2544",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Phone No.
        </div>
        <div className="col-3">
          <Progress
            style={{
              height: "8px",
            }}
            barClassName={
              truncatedNumber(completionStatus?.phone_number) <= 20
                ? "very-low-color"
                : truncatedNumber(completionStatus?.phone_number) <= 40
                ? "low-color"
                : truncatedNumber(completionStatus?.phone_number) <= 60
                ? "moderate-color"
                : truncatedNumber(completionStatus?.phone_number) <= 80
                ? "high-color"
                : "very-high-color"
            }
            value={truncatedNumber(completionStatus?.phone_number)}
          />
        </div>
        <div className="col-2 px-0 ml-0">
          <div
            style={{
              paddingLeft: "8px",
              fontWeight: "400",
              fontSize: "16px",
              color: "#7A8193",
            }}
          >
            {truncatedNumber(completionStatus?.phone_number)
              ? truncatedNumber(completionStatus?.phone_number)
              : 0}
            %
          </div>
        </div>
      </div>
      <div className="row d-flex align-center">
        <div
          className="col-7"
          style={{
            color: "#1A2544",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Description
        </div>
        <div className="col-3">
          <Progress
            style={{
              height: "8px",
            }}
            barClassName={
              truncatedNumber(completionStatus?.description) <= 20
                ? "very-low-color"
                : truncatedNumber(completionStatus?.description) <= 40
                ? "low-color"
                : truncatedNumber(completionStatus?.description) <= 60
                ? "moderate-color"
                : truncatedNumber(completionStatus?.description) <= 80
                ? "high-color"
                : "very-high-color"
            }
            value={truncatedNumber(completionStatus?.description)}
          />
        </div>
        <div className="col-2 px-0 ml-0">
          <div
            style={{
              paddingLeft: "8px",
              fontWeight: "400",
              fontSize: "16px",
              color: "#7A8193",
            }}
          >
            {truncatedNumber(completionStatus?.description)
              ? truncatedNumber(completionStatus?.description)
              : 0}
            %
          </div>
        </div>
      </div>
      <div className="row d-flex align-center">
        <div
          className="col-7"
          style={{
            color: "#1A2544",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          Website Url’s
        </div>
        <div className="col-3">
          <Progress
            style={{
              height: "8px",
            }}
            barClassName={
              truncatedNumber(completionStatus?.websiteUri) <= 20
                ? "very-low-color"
                : truncatedNumber(completionStatus?.websiteUri) <= 40
                ? "low-color"
                : truncatedNumber(completionStatus?.websiteUri) <= 60
                ? "moderate-color"
                : truncatedNumber(completionStatus?.websiteUri) <= 80
                ? "high-color"
                : "very-high-color"
            }
            value={truncatedNumber(completionStatus?.websiteUri)}
          />{" "}
        </div>
        <div className="col-2 px-0 ml-0">
          <div
            style={{
              paddingLeft: "8px",
              fontWeight: "400",
              fontSize: "16px",
              color: "#7A8193",
            }}
          >
            {truncatedNumber(completionStatus?.websiteUri)
              ? truncatedNumber(completionStatus?.websiteUri)
              : 0}
            %
          </div>
        </div>
      </div>
      {canModifyServiceList && (
        <div className="row d-flex align-center">
          <div
            className="col-7"
            style={{
              color: "#1A2544",
              fontSize: "18px",
              fontWeight: "400",
            }}
          >
            services
          </div>
          <div className="col-3">
            <Progress
              style={{
                height: "8px",
              }}
              barClassName={
                truncatedNumber(completionStatus?.services) <= 20
                  ? "very-low-color"
                  : truncatedNumber(completionStatus?.services) <= 40
                  ? "low-color"
                  : truncatedNumber(completionStatus?.services) <= 60
                  ? "moderate-color"
                  : truncatedNumber(completionStatus?.services) <= 80
                  ? "high-color"
                  : "very-high-color"
              }
              value={truncatedNumber(completionStatus?.services)}
            />
          </div>
          <div className="col-2 px-0 ml-0">
            <div
              style={{
                paddingLeft: "8px",
                fontWeight: "400",
                fontSize: "16px",
                color: "#7A8193",
              }}
            >
              {truncatedNumber(completionStatus?.services)
                ? truncatedNumber(completionStatus?.services)
                : 0}
              %
            </div>
          </div>
        </div>
      )}

      <div className="row d-flex align-center">
        <div
          className="col-7"
          style={{
            color: "#1A2544",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          opening hours
        </div>
        <div className="col-3">
          <Progress
            style={{
              height: "8px",
            }}
            barClassName={
              truncatedNumber(completionStatus?.opening_hours) <= 20
                ? "very-low-color"
                : truncatedNumber(completionStatus?.opening_hours) <= 40
                ? "low-color"
                : truncatedNumber(completionStatus?.opening_hours) <= 60
                ? "moderate-color"
                : truncatedNumber(completionStatus?.opening_hours) <= 80
                ? "high-color"
                : "very-high-color"
            }
            value={truncatedNumber(completionStatus?.opening_hours)}
          />
        </div>
        <div className="col-2 px-0 ml-0">
          <div
            style={{
              paddingLeft: "8px",
              fontWeight: "400",
              fontSize: "16px",
              color: "#7A8193",
            }}
          >
            {truncatedNumber(completionStatus?.opening_hours)
              ? truncatedNumber(completionStatus?.opening_hours)
              : 0}
            %
          </div>
        </div>
      </div>
      {/* <div className="row d-flex align-center">
        <div
          className="col-7"
          style={{
            color: "#1A2544",
            fontSize: "18px",
            fontWeight: "400",
          }}
        >
          products
        </div>
        <div className="col-3">
          <Progress
            style={{
              height: "8px",
            }}
            barClassName={
              truncatedNumber(completionStatus?.products) <= 20 ? "very-low-color" :
                (truncatedNumber(completionStatus?.products) <= 40 ? "low-color" :
                  (truncatedNumber(completionStatus?.products) <= 60 ? "moderate-color" :
                    (truncatedNumber(completionStatus?.products) <= 80 ? "high-color" : "very-high-color")))
            }
            value={truncatedNumber(completionStatus?.products)}
          />
        </div>
        <div className="col-2 px-0 ml-0">
          <div
            style={{
              paddingLeft: "8px",
              fontWeight: "400",
              fontSize: "16px",
              color: "#7A8193",
            }}
          >
            {truncatedNumber(completionStatus?.products) ? truncatedNumber(completionStatus?.products) : 0}%
          </div>
        </div>
      </div> */}
      <div className="row d-flex align-center">
        <div
          className="col-7"
          style={{
            color: "#1A2544",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          logo & photos
        </div>
        <div className="col-3">
          <Progress
            style={{
              height: "8px",
            }}
            barClassName={
              truncatedNumber(completionStatus?.logo_and_photos) <= 20
                ? "very-low-color"
                : truncatedNumber(completionStatus?.logo_and_photos) <= 40
                ? "low-color"
                : truncatedNumber(completionStatus?.logo_and_photos) <= 60
                ? "moderate-color"
                : truncatedNumber(completionStatus?.logo_and_photos) <= 80
                ? "high-color"
                : "very-high-color"
            }
            value={truncatedNumber(completionStatus?.logo_and_photos)}
          />
        </div>
        <div className="col-2 px-0 ml-0">
          <div
            style={{
              paddingLeft: "8px",
              fontWeight: "400",
              fontSize: "16px",
              color: "#7A8193",
            }}
          >
            {truncatedNumber(completionStatus?.logo_and_photos)
              ? truncatedNumber(completionStatus?.logo_and_photos)
              : 0}
            %
          </div>
        </div>
      </div>
      {/* {!canHaveFoodMenus && (
        <div className="row d-flex align-center">
          <div
            className="col-7"
            style={{
              color: "#1A2544",
              fontSize: "18px",
              fontWeight: "400",
            }}
          >
            Q & A’s
          </div>
          <div className="col-3">
            <Progress
              style={{
                height: "8px",
              }}
              barClassName={
                truncatedNumber(completionStatus?.q_and_a) <= 20
                  ? "very-low-color"
                  : truncatedNumber(completionStatus?.q_and_a) <= 40
                  ? "low-color"
                  : truncatedNumber(completionStatus?.q_and_a) <= 60
                  ? "moderate-color"
                  : truncatedNumber(completionStatus?.q_and_a) <= 80
                  ? "high-color"
                  : "very-high-color"
              }
              value={truncatedNumber(completionStatus?.q_and_a)}
            />
          </div>
          <div className="col-2 px-0 ml-0">
            <div
              style={{
                paddingLeft: "8px",
                fontWeight: "400",
                fontSize: "16px",
                color: "#7A8193",
              }}
            >
              {truncatedNumber(completionStatus?.q_and_a)
                ? truncatedNumber(completionStatus?.q_and_a)
                : 0}
              %
            </div>
          </div>
        </div>
      )} */}
      {/* <div className="row d-flex align-center">
            <div
              className="col-7"
              style={{
                color: "#1A2544",
                fontSize: "18px",
                fontWeight: "400",
              }}
            >
              posts & offers
            </div>
            <div className="col-3">
              <Progress
                style={{ height: "8px" }}
                // color={data.value <= 20 ? "danger" : (data.value <= 60 ? "warning" : "success")}
                value={34}
              />
            </div>
            <div className="col-2 px-0 ml-0">
              <div
                style={{
                  paddingLeft: "8px",
                  fontWeight: "400",
                  fontSize: "16px",
                  color: "#7A8193",
                }}
              >
                45%
              </div>
            </div>
          </div> */}
      {/* {!canHaveFoodMenus && (
        <div className="row d-flex align-center">
          <div
            className="col-7"
            style={{
              color: "#1A2544",
              fontSize: "18px",
              fontWeight: "400",
            }}
          >
            opening date
          </div>
          <div className="col-3">
            <Progress
              style={{
                height: "8px",
              }}
              barClassName={
                truncatedNumber(completionStatus?.opening_date) <= 20
                  ? "very-low-color"
                  : truncatedNumber(completionStatus?.opening_date) <= 40
                  ? "low-color"
                  : truncatedNumber(completionStatus?.opening_date) <= 60
                  ? "moderate-color"
                  : truncatedNumber(completionStatus?.opening_date) <= 80
                  ? "high-color"
                  : "very-high-color"
              }
              value={truncatedNumber(completionStatus?.opening_date)}
            />
          </div>
          <div className="col-2 px-0 ml-0">
            <div
              style={{
                paddingLeft: "8px",
                fontWeight: "400",
                fontSize: "16px",
                color: "#7A8193",
              }}
            >
              {truncatedNumber(completionStatus?.opening_date)
                ? truncatedNumber(completionStatus?.opening_date)
                : 0}
              %
            </div>
          </div>
        </div>
      )} */}
    </>
  );
};

export default CompletionStatus;
