import * as types from "../../actions/actionTypes";

const initialState = {
  location_list: null,
  location_error: null,
};

export default function gmbListingLocationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.GET_GMB_LISTING_LOCATION_SUCCESS:
      if (!action.filterType)
        return {
          ...state,
          location_list: action.data.results,
          filterBusinessCount: action.data.count,
        };
      else return { ...state, location_list: action.data.results };
    case types.GET_GMB_LISTING_LOCATION_FAILURE:
      return { ...state, location_error: action.data };

    case types.GET_GMB_LISTING_MENU_DETAIL_SUCCESS:
      return { ...state, location_menu_detail: action.data };
    case types.GET_GMB_LISTING_MENU_DETAIL_FAILURE:
      return { ...state, location_menu_detail_error: action.data };

    case types.GET_GMB_LISTING_PRIMARY_DETAIL_SUCCESS:
      return { ...state, location_primary_detail: action.data };
    case types.GET_GMB_LISTING_PRIMARY_DETAIL_FAILURE:
      return { ...state, location_primary_detail_error: action.data };

    case types.GET_GMB_LISTING_REFRESH_SUCCESS:
      return { ...state, refresh_listing_data: action.data };
    case types.GET_GMB_LISTING_REFRESH_FAILURE:
      return { ...state, refresh_listing_error: action.data };

    case types.GET_GMB_BASIC_DETAIL_SUCCESS:
      return { ...state, basic_detail_data: action.data };
    case types.GET_GMB_BASIC_DETAIL_FAILURE:
      return { ...state, basic_detail_error: action.data };

    case types.GET_GMB_CATEGORIES_SUCCESS:
      return { ...state, gmc_categories_data: action.data };
    case types.GET_GMB_CATEGORIES_FAILURE:
      return { ...state, gmc_categories_error: action.data };

    case types.GET_GMB_BULK_CATEGORIES_SUCCESS:
      return { ...state, gmc_bulk_categories_data: action.data };
    case types.GET_GMB_BULK_CATEGORIES_FAILURE:
      return { ...state, gmc_bulk_categories_error: action.data };

    case types.GET_GMB_UPDATE_LISTING_SUCCESS:
      return { ...state, update_listing_data: action.data };
    case types.GET_GMB_UPDATE_LISTING_FAILURE:
      return { ...state, update_listing_error: action.data };

    case types.GET_GMB_BULK_UPDATE_LISTING_SUCCESS:
      return { ...state, bulk_update_listing_data: action.data };
    case types.GET_GMB_BULK_UPDATE_LISTING_FAILURE:
      return { ...state, bulk_update_listing_error: action.data };

    case types.GET_GMB_ATTRIBUTES_SUCCESS:
      return { ...state, attribute_data: action.data };
    case types.GET_GMB_ATTRIBUTES_FAILURE:
      return { ...state, attribute_error: action.data };

    case types.GET_GMB_PALCE_ACTION_METADATA_SUCCESS:
      return { ...state, placeLocationMetaData: action.data };
    case types.GET_GMB_PALCE_ACTION_METADATA_FAILURE:
      return { ...state, placeLoationMetadata_error: action.data };

    case types.GET_GMB_MEDIA_SUCCESS:
      return { ...state, media_data: action.data };
    case types.GET_GMB_MEDIA_FAILURE:
      return { ...state, media_error: action.data };

    case types.GET_GMB_DELETE_MEDIA_SUCCESS:
      return { ...state, media_delete_data: action.data };
    case types.GET_GMB_DELETE_MEDIA_FAILURE:
      return { ...state, media_delete_error: action.data };

    case types.GET_GMB_CONSOLE_LIST_SUCCESS:
      return { ...state, console_list_data: action.data };
    case types.GET_GMB_CONSOLE_LIST_FAILURE:
      return { ...state, console_list_error: action.data };

    case types.GET_GMB_SERVICE_TYPE_SUCCESS:
      return { ...state, service_type_data: action.data };
    case types.GET_GMB_SERVICE_TYPE_FAILURE:
      return { ...state, service_type_error: action.data };

    case types.GET_GMB_SYNC_DATA_SUCCESS:
      return { ...state, sync_data_data: action.data };
    case types.GET_GMB_SYNC_DATA_FAILURE:
      return { ...state, sync_data_error: action.data };

    case types.GET_GMB_LOCATION_FOODMENU_SUCCESS:
      return { ...state, location_food_menu_data: action.data };
    case types.GET_GMB_LOCATION_FOODMENU_FAILURE:
      return { ...state, location_food_menu_error: action.data };

    case types.GET_GMB_LOCATION_SYNC_DATA_STATUS_SUCCESS:
      return { ...state, syncDataStatus: action.data };
    case types.GET_GMB_LOCATION_SYNC_DATA_STATUS_FAILURE:
      return { ...state, syncDataStatusError: action.data };

    case types.GET_GMB_LOCATION_UPDATE_SERVICE_SUCCESS:
      return { ...state, updateServiceData: action.data };
    case types.GET_GMB_LOCATION_UPDATE_SERVICE_FAILURE:
      return { ...state, updateServiceError: action.data };

    case types.GET_GMB_LOCATION_QUESTION_ANSWER_SUCCESS:
      return { ...state, questionAnswerData: action.data };
    case types.GET_GMB_LOCATION_QUESTION_ANSWER_FAILURE:
      return { ...state, questionAnswerError: action.data };

    case types.GET_GMB_LOCATION_UPDATE_DIFF_DATA_SUCCESS:
      return { ...state, updateDiffDataData: action.data };
    case types.GET_GMB_LOCATION_UPDATE_DIFF_DATA_FAILURE:
      return { ...state, updateDiffDataError: action.data };

    case types.GET_GMB_GOOGLE_LOCATION_SUCCESS:
      return { ...state, googleLocationData: action.data };
    case types.GET_GMB_GOOGLE_LOCATION_FAILURE:
      return { ...state, googleLocationError: action.data };

    case types.GET_GMB_BULK_CHANGE_LOG_SUCCESS:
      return { ...state, bulkChangeLogData: action.data };
    case types.GET_GMB_BULK_CHANGE_LOG_FAILURE:
      return { ...state, bulkChangeLogError: action.data };

      case types.GET_GMB_LOCATION_CHANGE_LOG_SUCCESS:
        return { ...state, location_changes_logs: action.data };
      case types.GET_GMB_LOCATION_CHANGE_LOG_FAILURE:
        return { ...state, location_changes_logs_error: action.data };
  
    default:
      return state;
  }
}
