import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import SortIcon from "../../images/svg/Sort.svg";
import TicketStatusSummaryCard from "../../modules/dashboard/component/chart/TicketStatusSummaryCard";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
// import LoaderComponent from "../common/loaderComponent";
import { Col, Row } from "reactstrap";
import { Card } from "semantic-ui-react";
import { Skeleton } from "@mui/material";

const OpenTicketTable = (props) => {
  let { data, dataLoad, fetchOpenTickets, openTicketApiCalled } = props;
  let [countClick, setCountClick] = useState(0);
  let [countData, setCountData] = useState("");
  let [sortPercentage, setSortPercentage] = useState(false);
  let [sortCount, setSortCount] = useState(false);
  let [sortReviewCount, setSortReviewCount] = useState(false);

  useEffect(() => {
    if (data && data.final_data) {
      setCountData(data.final_data);
    }
  }, [props]);

  function sortwithKey(data, column, countClick) {
    var arr = [];
    for (var prop in data) {
      if (data.hasOwnProperty(prop)) {
        var obj = {};
        obj[prop] = data[prop];
        obj.tempSortName = data[prop][column];
        arr.push(obj);
      }
    }
    arr.sort(function (a, b) {
      var at = a.tempSortName,
        bt = b.tempSortName;
      if (countClick === 0) {
        setCountClick(1);
        return at > bt ? 1 : at < bt ? -1 : 0;
      } else {
        setCountClick(0);
        return at < bt ? 1 : at > bt ? -1 : 0;
      }
    });
    let final_obj = {};
    arr.map((item, index) => {
      let xx = Object.keys(item)[0];
      final_obj[index] = item[xx];
    });
    setCountData(final_obj);
  }

  function handleSortCountNew(data) {
    sortwithKey(data, "count", countClick);
    setSortCount(true);
    setSortReviewCount(false);
    setSortPercentage(false);
  }

  // function handleSortReviewCount(data) {
  //   setSortReviewCount(true);
  //   setSortCount(false);
  //   setSortPercentage(false);
  //   sortwithKey(data, "reviews_count", countClick);
  //   console.log(sortReviewCount, "sortReviewCount");
  // }

  function handleSortpercentage(data) {
    sortwithKey(data, "open_percent", countClick);
    setSortPercentage(true);
    setSortCount(false);
    setSortReviewCount(false);
  }

  return (
    <main className="openTicketDashboard">
      <>
        {dataLoad ? (
          <section className="ticket-status-wrapper">
            {Array.from({ length: 4 }).map((_, index) => (
              <Card className="ticket-status">
                <Card.Header>
                  <div className="d-flex justify-content-between w-100">
                    <Skeleton width="30%" height={20} />
                    <Skeleton width="30%" height={20} />
                  </div>
                </Card.Header>
                <Row
                  className="time-section-wrapper"
                  style={{ padding: "0.4rem" }}
                >
                  {["24h", "48h", "7d"].map((key) => (
                    <Col className="time-section" key={key}>
                      <Skeleton
                        width="70%"
                        height={20}
                        style={{ margin: "0 auto" }}
                      />
                      <Skeleton
                        width="50%"
                        height={20}
                        style={{ margin: "0 auto" }}
                      />
                    </Col>
                  ))}
                </Row>
              </Card>
            ))}
          </section>
        ) : (
          data && (
            <section className="ticket-status-wrapper">
              <TicketStatusSummaryCard
                leftHeader="New"
                rightHeader={data.new}
                timeData={{
                  "24h": data.new_24Hrs,
                  "48h": data.new_48Hrs,
                  "7d": data.new_7days,
                }}
              />
              <TicketStatusSummaryCard
                leftHeader="Assigned"
                rightHeader={data.assigned}
                timeData={{
                  "24h": data.assigned_24Hrs,
                  "48h": data.assigned_48Hrs,
                  "7d": data.assigned_7days,
                }}
              />
              <TicketStatusSummaryCard
                leftHeader="Reopened"
                rightHeader={data.reopened}
                timeData={{
                  "24h": data.reopened_24Hrs,
                  "48h": data.reopened_48Hrs,
                  "7d": data.reopened_7days,
                }}
              />
              <TicketStatusSummaryCard
                leftHeader="Resolved"
                rightHeader={data.resolved}
                timeData={{
                  "24h": data.resolved_24Hrs,
                  "48h": data.resolved_48Hrs,
                  "7d": data.resolved_7days,
                }}
              />
            </section>
          )
        )}
      </>

      <section className="mt-3">
  {dataLoad ? (
    <>
      {/* Skeleton Header */}
      <Table className="fp-table dashbord-table fp-table-center">
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: "0.6%" }}>
              <Skeleton variant="text" width="20%" height={20} style={{ margin: "0 auto" }} />
            </TableCell>
            <TableCell style={{ padding: "0.6%" }}>
              <Skeleton variant="text" width="60%" height={20} style={{ margin: "0 auto" }} />
            </TableCell>
            <TableCell style={{ padding: "0.6%" }}>
              <Skeleton variant="text" width="40%" height={20} style={{ margin: "0 auto" }} />
            </TableCell>
            <TableCell style={{ padding: "0.6%" }}>
              <Skeleton variant="text" width="30%" height={20} style={{ margin: "0 auto" }} />
            </TableCell>
          </TableRow>
        </TableHead>
        {/* Skeleton Body */}
        <TableBody>
          {Array.from({ length: 10 }).map((_, index) => (
            <TableRow key={index}>
              <TableCell style={{ padding: "0.5%" }}>
                <Skeleton variant="text" width="20%" height={20} style={{ margin: "0 auto" }} />
              </TableCell>
              <TableCell style={{ padding: "0.5%" }}>
                <Skeleton variant="text" width="60%" height={20} style={{ margin: "0 auto" }} />
              </TableCell>
              <TableCell style={{ padding: "0.5%" }}>
                <Skeleton variant="text" width="40%" height={20} style={{ margin: "0 auto" }} />
              </TableCell>
              <TableCell style={{ padding: "0.5%" }}>
                <Skeleton variant="text" width="30%" height={20} style={{ margin: "0 auto" }} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  ) : ( openTicketApiCalled ?
    <>
      <div className="header fp-card-header">Open Ticket Status</div>
      <Divider className="mt-2 mb-3" />
      <Table className="fp-table dashbord-table fp-table-center">
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: "0.6%" }} className="commonHeader">
              #
            </TableCell>
            <TableCell style={{ padding: "0.6%" }} className="commonHeader">
              Team Member
            </TableCell>
            <TableCell
              style={{ padding: "0.6%" }}
              className={`cursor-pointer commonHeader ${sortCount ? "sortCellStyleHeader" : ""}`}
              onClick={() => handleSortCountNew(data.final_data)}
            >
              Open Tickets <SortIcon />
            </TableCell>
            <TableCell
              style={{ padding: "0.6%" }}
              className={`cursor-pointer commonHeader ${sortPercentage ? "sortCellStyleHeader" : ""}`}
              onClick={() => handleSortpercentage(data.final_data)}
            >
              Open Tickets % <SortIcon />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {countData && Object.keys(countData).length > 0 ? (
            Object.keys(countData).map((item, index) => (
              <TableRow key={index}>
                <TableCell style={{ padding: "0.5%" }}>{index + 1}</TableCell>
                <TableCell style={{ padding: "0.5%" }}>
                  {`${countData[item]["first_name"]} ${countData[item]["last_name"]}`}
                </TableCell>
                <TableCell style={{ padding: "0.5%" }} className={sortCount ? "sortCellStyle" : ""}>
                  {countData[item]["count"]}
                </TableCell>
                <TableCell style={{ padding: "0.5%" }} className={sortPercentage ? "sortCellStyle" : ""}>
                  {countData[item]["open_percent"]}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell className="text-center" colSpan={4}>
                No records Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  : (
    <div 
      className="text-center p-4 fp-size-16 underline" 
      style={{ textDecoration: 'underline', color: 'deeppink', cursor: 'pointer' }}
      onClick={() => fetchOpenTickets()} 
    >Click to Load Data</div>
  ))}
  </section>

    </main>
  );
};

export default OpenTicketTable;
