import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, RadioGroup, Divider } from '@mui/material';
import CustomRadio from '../../../../components/common/CustomRadio';

const RadioButtonGroup = ({ title, options, onSelectionChange, value = null }) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value); // Update selected value if the prop `value` changes
  }, [value]);

  const handleRadioChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setSelectedValue(newValue);
    if (onSelectionChange) {
      onSelectionChange(newValue); // Pass updated value back
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        border: '1px solid #FFD6E0',
        borderRadius: '16px',
        overflow: 'hidden',
        mb: 2,
        background:"#FAFAFB"
      }}
    >
      <Box sx={{}}>
        <Typography variant="h6" sx={{ fontWeight: 'bold', m: 1 }}>
          {title}
        </Typography>
        
        <RadioGroup value={selectedValue || ''} onChange={handleRadioChange}>
          {options.map((option, index) => (
            <React.Fragment key={option.id}>
              <CustomRadio
                label={option.title}
                checked={selectedValue === option.id}
                value={option.id.toString()}
                name="radio-options"
                onChange={handleRadioChange} // Ensure event triggers update
              />
              {index < options.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </RadioGroup>
      </Box>
    </Paper>
  );
};

export default RadioButtonGroup;