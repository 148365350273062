import axios_instance from '../../libs/interseptor';
import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';
import * as utils from '../../libs/utils';


import * as commonAction from '../common/commonAction';

export function agencyListSuccess(data) {
  return { type: types.AGENCY_LIST_SUCCESS, data};
}

export function agencyListFailure(error) {
  let data = {};
  console.log(error);
  data.errors = error.response.data;
  return { type: types.AGENCY_LIST_FAILURE, data };
}

export const agencyList = (params, marketing_id) => {

  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(`${URLS.AGENCY_BUSINESS_LIST}`,[marketing_id]), { params })
      .then(
        (res) => {
          const modifyData = res.data;
          res.data.results.map((item) => {
            //* TOTAL_LOCATION
            if ('total_no_of_locations' in modifyData) {
              modifyData['total_no_of_locations'] = modifyData.total_no_of_locations + item.no_of_locations;
            }else{
              modifyData['total_no_of_locations'] = item.no_of_locations;
            }

            //* TOTAL_REVIEW
            if ('total_review' in modifyData) {
              modifyData['total_review'] = modifyData.total_review + item.total_rating;
            }else{
              modifyData['total_review'] = item.total_rating;
            }

            //* TOTAL_AVG_RATING
            if ('total_avg_rating' in modifyData) {
              modifyData['total_avg_rating'] = modifyData.total_avg_rating + item.avg_rating;
            }else{
              modifyData['total_avg_rating'] = item.avg_rating;
            }
          });
          modifyData.total_avg_rating = utils.setDecimalIndex(modifyData.total_avg_rating / modifyData.count); 
          modifyData.total_review = utils.setToLocalStringNumber(modifyData.total_review); 
          dispatch( commonAction.hideLoader() );
          dispatch(agencyListSuccess(modifyData));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(agencyListFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function agencyAllBusinessSucces(data) {
  return { type: types.AGENCY_ALL_BUSINESS_SUCCESS, data};
}

export function agencyAllBusinessFailure(error) {
  let data = {};
  console.log(error);
  data.errors = error.response.data;
  return { type: types.AGENCY_ALL_BUSINESS_FAILURE, data };
}

export const agencyAllBusiness = (params, marketing_id) => {

  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(`${URLS.AGENCY_BUSINESS_ALL}`,[marketing_id]), { params })
      .then((res) => {
        dispatch(agencyAllBusinessSucces(res.data));
      }).catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(agencyAllBusinessFailure(err));
          throw err.response.data;
        }
      );
  };
};


export function agencyAddSuccess(data) {
  return { type: types.AGENCY_ADD_SUCCESS, data};
}

export function agencyAddFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.AGENCY_ADD_FAILURE, data };
}

export const agencyAdd = (data) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  };
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(URLS.BUSINESS_SETUP, data, config)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(agencyAddSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(agencyAddFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getagencyBusinessSuccess(data) {
  return { type: types.GET_AGENCY_BUSINESS_SUCCESS, data};
}

export function getagencyBusinessFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_AGENCY_BUSINESS_FAILURE, data };
}

export const getagencyBusiness = (params) => {

  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(`${URLS.BUSINESS_SETUP}${params.id}/`)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getagencyBusinessSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getagencyBusinessFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function agencyEditSuccess(data) {
  return { type: types.AGENCY_EDIT_SUCCESS, data};
}

export function agencyEditFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.AGENCY_EDIT_FAILURE, data };
}

export const agencyEdit = (data, id) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.put(`${URLS.BUSINESS_SETUP}${id}/`, data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(agencyEditSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(agencyEditFailure(err));
          throw err.response.data;
        }
      );
  };
};


export function agencyMappingListSuccess(data) {
  return { type: types.AGENCY_MAPPING_LIST_SUCCESS, data};
}

export function agencyMappingListFailure(error) {
  let data = {};
  console.log(error);
  data.errors = error.response.data;
  return { type: types.AGENCY_MAPPING_LIST_FAILURE, data };
}

export const agencyMappingList = (marketing_id,query_search) => {

  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(`${URLS.TEAM_MAPPING}${query_search}`,[marketing_id]))
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(agencyMappingListSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(agencyMappingListFailure(err));
          throw err.response.data;
        }
      );
  };
};