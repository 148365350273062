import React from 'react';
import { Switch, Route} from 'react-router';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import BusinessProfilePage from '../business/BusinessProfilePage';

import TemplatesPage from '../templates/TemplatesPage';
import NewsLetterPage from '../templates/NewsLetterPage';
import TemplatesEditPage from '../campaign/templates/TemplateEditPage';
import NewsLetterEditPage from '../campaign/newsLetter/NewsLetterEditPage';
import GenericTemplatesPage from '../templates/GenericTemplatesPage';

import * as CommonAction from '../../actions/common/commonAction';
import GenericTemplateEditPage from '../campaign/genericTemplate/GenericTemplateEditPage';

class BranchPages extends React.Component {
  constructor(props){
    super(props);
  }

  componentDidUpdate(prevProps){
    if(prevProps && this.props.match.params.branch_id!==prevProps.match.params.branch_id){
      this.props.actions.updateRouteParams({ branch_id: this.props.match.params.branch_id});
    }
  }

  render(){
    return(
      <Switch>
        <Route path="/:business_id/:branch_id/business-profile" component={BusinessProfilePage} />

        <Redirect exact from="/:business_id/:branch_id/templates" to="/:business_id/:branch_id/templates/list" />
        <Route path="/:business_id/:branch_id/templates/list" component={TemplatesPage} />
        <Route path="/:business_id/:branch_id/templates/edit" component={TemplatesEditPage} />

        <Redirect exact from="/:business_id/:branch_id/templates/news-letter" to="/:business_id/:branch_id/templates/news-letter/list" />
        <Route path="/:business_id/:branch_id/templates/news-letter/list" component={NewsLetterPage}/>
        <Route path="/:business_id/:branch_id/templates/news-letter/edit" component={NewsLetterEditPage}/>

        <Redirect exact from="/:business_id/:branch_id/templates/generic" to="/:business_id/:branch_id/templates/generic/list"/>
        <Route path="/:business_id/:branch_id/templates/generic/list" component={GenericTemplatesPage} />
        <Route path="/:business_id/:branch_id/templates/generic/edit" component={GenericTemplateEditPage}/>

        
        <Redirect exact from="/:business_id/:branch_id/campaigns/:tabKey" to="/:business_id/:branch_id/campaigns/:tabKey/list" />
        <Redirect exact from="/:business_id/:branch_id/campaigns/review" to="/:business_id/:branch_id/campaigns/review/list" />

      </Switch>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(CommonAction, dispatch),
  };
}

export default connect(null,mapDispatchToProps,null,{forwardRef: true})(BranchPages);