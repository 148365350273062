import axios_instance from '../../libs/interseptor';
import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';
import * as utils from '../../libs/utils';

import * as commonAction from '../common/commonAction';

export function newsLetterListSuccess(data) {
  return { type: types.NEWS_LETTER_LIST_SUCCESS, data};
}

export function newsLetterListFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.NEWS_LETTER_LIST_FAILURE, data };
}

export const newsLetterList = (parameters) => {
  const { branch_id, ...params } = parameters;
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.NEWSLETTER_LIST,[branch_id]), { params })
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(newsLetterListSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(newsLetterListFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getnewsLetterDetailsSuccess(data) {
  return { type: types.GET_NEWS_LETTER_DETAILS_SUCCESS, data };
}

export function getnewsLetterDetailsFailure(data) {
  return { type: types.GET_NEWS_LETTER_DETAILS_FAILURE, data };
}

export const getnewsLetterDetails = (parameters) => {
  const { branch_id, id } = parameters;
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.NEWSLETTER_DETAILS,[branch_id,id]))
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getnewsLetterDetailsSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getnewsLetterDetailsFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function postNewsLetterSuccess(data) {
  return { type: types.POST_NEWS_LETTER_SUCCESS, data };
}

export function postNewsLetterFailure(data) {
  return { type: types.POST_NEWS_LETTER_FAILURE, data };
}

export const postNewsLetter = (data,id, branch_id) => {
  const headers = {
    headers: {
      'accept': 'application/json',
      'Accept-Language': 'en-US,en;q=0.8',
      'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
    }
  };
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    if (id) {
      return axios_instance.patch(utils.format(URLS.NEWSLETTER_DETAILS,[branch_id,id]), data,headers)
        .then(
          (res) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postNewsLetterSuccess(res.data));
            return res;
          }
        )
        .catch(
          (err) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postNewsLetterFailure(err));
            throw err.response.data;
          }
        );
    } else {
      return axios_instance.post(utils.format(URLS.NEWSLETTER_LIST,[branch_id]), data, headers)
        .then(
          (res) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postNewsLetterSuccess(res.data));
            return res;
          }
        )
        .catch(
          (err) => {
            dispatch( commonAction.hideLoader() );
            dispatch(postNewsLetterFailure(err));
            throw err.response.data;
          }
        );
    }
  };
};

export function newsLetterDeleteSuccess(data) {
  return { type: types.NEWS_LETTER_DELETE_SUCCESS, data};
}

export function newsLetterDeleteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.NEWS_LETTER_DELETE_FAILURE, data };
}

export const newsLetterDelete = (data, branch_id) => {

  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.delete(utils.format(URLS.NEWSLETTER_DETAILS,[branch_id,data.id]), data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(newsLetterDeleteSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(newsLetterDeleteFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function newsLetterCloneSuccess(data) {
  return { type: types.NEWS_LETTER_CLONE_SUCCESS, data};
}

export function newsLetterCloneFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.NEWS_LETTER_CLONE_FAILURE, data };
}

export const newsLetterClone = (data, branch_id) => {

  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(utils.format(URLS.NEWSLETTER_CLONE,[branch_id,data.id]), data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(newsLetterCloneSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(newsLetterCloneFailure(err));
          throw err.response.data;
        }
      );
  };
};
