import React, { memo } from "react";
import { Switch, Route } from "react-router";
import TicketServiceRequest from "./TicketServiceRequest";
import ServiceRequestOverView from "./OverView/ServiceRequestOverView";
import BranchPages from "../business/BranchPages";
import CentralUserPage from "../business/CentralUserPage";
import MyProfile from "../../components/accountSettings/MyProfile";

function serviceTemplateRouter({ match }) {
  return (
    <Switch>
      <Route 
        exact   
        path="/:business_id/ticket-service-overview"
        component={ServiceRequestOverView}
      />
      <Route path="/:business_id/ticket-service-request" component={TicketServiceRequest} />

      {/* {setup central-setup} */}
      <Route path="/:business_id/central-setup" component={CentralUserPage} />

      {/* Setup-Page  business-profile page*/}
      <Route path="/:business_id/:branch_id" component={BranchPages} />

      <Route path="/:business_id/:agency_id/account-settings" component={MyProfile} />
    </Switch>
  );
}

export default memo(serviceTemplateRouter);
