import React, { useEffect, useState, useRef } from "react";
import apiURL from "../../../../libs/apiUrls";
import { format } from "../../../../libs/utils";
import Chartwrapper from "../Chartwrapper";
import Donut from "../../../../components/common/new/Donut";
import { useMediaQuery } from "@material-ui/core";
import queryString from "query-string";
import { Skeleton } from "@mui/material";

function ResponseChart(props) {
  // const [data, setData] = useState(null);
  const isSmallScreen = useMediaQuery("(max-width: 1512px)");
  const { businessId, params, data, fetchResponseChartData, loader } = props;
  const [chartData, setChartData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const url = format(`${apiURL.RESPONSE_RATE}`, [businessId]);

  useEffect(() => {
    const pieData = {
      negative: {
        datasets: [
          {
            data: data
              ? [Math.ceil(data.negative), 100 - Math.ceil(data.negative)]
              : [0],
            backgroundColor: ["#ff4545", "#F6F6F8"],
          },
        ],
      },
      positive: {
        datasets: [
          {
            data: data
              ? [Math.ceil(data.positive), 100 - Math.ceil(data.positive)]
              : [0],
            backgroundColor: ["#57e32c", "#F6F6F8"],
          },
        ],
      },
    };
    setChartData(() => pieData);
  }, [data]);

  const updateData = (datePayload) => {
    const newParams = Object.assign(queryString.parse(params), datePayload);
    fetchResponseChartData(newParams);
  };

  const chartRef = useRef(null);
  const [chartDimension, setChartDimension] = useState({ height: 0, width: 0 });

  useEffect(() => {
    setTimeout(() => {
      if (chartRef && chartRef.current) {
        const { offsetHeight, offsetWidth } = chartRef.current;
        setChartDimension({ height: offsetHeight, width: (offsetWidth - 32) });
      }
    });
  }, [chartRef, chartData]);

  return (
    <Chartwrapper
      headerTitle="Overall Response Rate"
      className="h-100"
      onDateChange={updateData}
      widthElevation={true}
      skeletonLoader={loader}
      // isForLifeTimeSupported={false}
    >
      {loader ? (
        <section
          style={{ height: "50%", minHeight: "300px" }}
          className="d-flex"
        >
          <div className="d-flex flex-column justify-content-center align-items-center w-50">
            <Skeleton
              variant="text"
              width="50%"
              height={20}
              style={{ marginBottom: "20px" }}
            />
            <Skeleton variant="circular" width={200} height={200} />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center w-50">
            <Skeleton
              variant="text"
              width="50%"
              height={20}
              style={{ marginBottom: "20px" }}
            />
            <Skeleton variant="circular" width={200} height={200} />
          </div>
        </section>
      ) : (
        <section
          style={{ height: "100%", minHeight: "300px" }}
          className="d-flex mt-2"
        >
          {chartData && (
            <React.Fragment>
              <div className="flex-grow-1 d-flex justify-content-center align-items-center">
                <div>
                  <p className="responseHeadText">Responded To</p>
                  <Donut
                    key={`${chartDimension.height}`}
                    isLoading={isLoading}
                    cutout={isSmallScreen ? 65 : 110}
                    data={chartData.negative}
                    width={chartDimension.width - 30}
                    height={chartDimension.height - 36}
                    centerText={{
                      text: `${Math.ceil(data ? data.negative : 0)}%`,
                      label: "Of Negative Reviews",
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        cutout: '40%',
                        tooltip: {
                          callbacks: {
                            label: (context) => {
                              return `Negative : ${context.formattedValue}%`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "1px",
                  backgroundColor: "#BFC2CB",
                  // margin: "0 25px",
                }}
              />
              <div
                className="flex-grow-1 d-flex justify-content-center align-items-center"
                ref={chartRef}
              >
                <div>
                  <p className="responseHeadText">Responded To</p>
                  <Donut
                    key={`${chartDimension.height}`}
                    isLoading={isLoading}
                    cutout={isSmallScreen ? 65 : 110}
                    data={chartData.positive}
                    width={chartDimension.width - 30}
                    height={chartDimension.height - 36}
                    centerText={{
                      text: `${Math.ceil(data ? data.positive : 0)}%`,
                      label: "Of Positive Reviews",
                    }}
                    options={{
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          callbacks: {
                            label: (context) => {
                              return `Positive : ${context.formattedValue}%`;
                            },
                          },
                        },
                      },
                    }}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
        </section>
      )}
    </Chartwrapper>
  );
}

export default ResponseChart;
