import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NavBar from "../../components/common/NavBar";
import LoaderComponent from "../../components/common/loaderComponent";
import { Row, Col, Card, CardTitle, Label } from "reactstrap";
import { Button, Form, Popup, Icon } from "semantic-ui-react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import BackIcon from "../../images/svg/backIcon.svg";
import MultiSelect from "../common/SelectComponent";
import axios_instance from "../../libs/interseptor";
import * as URLS from "../../libs/apiUrls";
import * as utils from "../../libs/utils";
import PopupIconComponent from "../common/PopupIconComponent";

class TeamMappingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      agency_id: this.props.match.params.agency_id,
      businessList: [],
      selectedBusiness: null,
      businessBranches: [],
      agency_users: [],
      selectedBranches: [],
      selectedUsers: [],
      teamMappingId: null,
      existingTeam: [],
      newAssigneeOptions: [],
      mappingName: null,
      mappingDescription: null,
      loader: false,
    };
    this.handleBusinessChange = this.handleBusinessChange.bind(this);
    this.goBack = this.goBack.bind(this);
    this.submit = this.submit.bind(this);
    this.deleteTeamMapping = this.deleteTeamMapping.bind(this);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
  }

  handleBusinessChange(event) {
    if (event.target.value != "") {
      this.setState({ selectedBusiness: event.target.value });

      axios_instance.get(`${URLS.BUSINESS_SETUP}${event.target.value}/branch/`).then((res) => {
        this.setState({ businessBranches: res.data.choices.slice(1) });
      });
      axios_instance.get(`${utils.format(URLS.TEAM, [event.target.value])}`, { page_size: 100 }).then((res) => {
        const results = this.state.agency_users.filter(
          ({ id: id_1 }) => !res.data.results.some(({ id: id_2 }) => id_2 === id_1)
        );
        this.setState({ existingTeam: res.data.results, newAssigneeOptions: results });
      });
    }
  }

  handleBranchChange(event, val) {
    axios_instance
      .get(`${utils.format(URLS.TEAM, [this.state.selectedBusiness])}?branch=${val}`, { page_size: 100 })
      .then((res) => {
        const results = this.state.agency_users.filter(
          ({ id: id_1 }) => !res.data.results.some(({ id: id_2 }) => id_2 === id_1)
        );
        this.setState({ existingTeam: res.data.results, newAssigneeOptions: results });
      });
  }

  handleNameChange(event) {
    this.setState({ mappingName: event.target.value });
  }

  handleDescriptionChange(event) {
    this.setState({ mappingDescription: event.target.value });
  }

  submit(data) {
    if (this.state.teamMappingId) {
      this.setState({ loader: true });
      var branches = this.state.selectedBranches;
      if ("selected_branches" in data) {
        branches = data["selected_branches"];
      }
      var already_staff = this.state.existingTeam.map((a) => a.id).join(", ");
      if ("existing_team" in data) {
        already_staff = data["existing_team"];
      }
      let new_data = {
        name: this.state.mappingName,
        description: this.state.mappingDescription,
        business: this.state.selectedBusiness,
        marketing: this.state.agency_id,
        branch: branches,
        staff: data["new_assignee"],
        existing_staff: already_staff,
      };

      axios_instance
        .put(utils.format(`${URLS.TEAM_MAPPING}${this.state.teamMappingId}/`, [this.state.agency_id]), new_data)
        .then((res) => {
          if (res.data.success === true) {
            this.setState({ loader: false });
            const {
              history,
              match: {
                params: { agency_id },
              },
            } = this.props;
            history.push(`/${agency_id}/agency-team`);
          } else {
            this.setState({ loader: false });
            alert(res.data.message);
          }
        });
    } else {
      this.setState({ loader: true });
      var already_staff_post = this.state.existingTeam.map((a) => a.id).join(", ");
      if ("existing_team" in data) {
        already_staff_post = data["existing_team"];
      }
      let new_data = {
        name: this.state.mappingName,
        description: this.state.mappingDescription,
        business: this.state.selectedBusiness,
        marketing: this.state.agency_id,
        branch: data["selected_branches"],
        staff: data["new_assignee"],
        existing_staff: already_staff_post,
      };

      axios_instance.post(utils.format(`${URLS.TEAM_MAPPING}`, [this.state.agency_id]), new_data).then((res) => {
        if (res.data.success === true) {
          this.setState({ loader: false });
          const {
            history,
            match: {
              params: { agency_id },
            },
          } = this.props;
          history.push(`/${agency_id}/agency-team`);
        } else {
          this.setState({ loader: false });
          alert(res.data.message);
        }
      });
    }
  }

  componentDidMount() {
    const { location } = this.props;
    axios_instance.get(URLS.AGENCY_USERS + "?agency_id=" + this.state.agency_id).then((res) => {
      this.setState({ agency_users: res.data.user_list, newAssigneeOptions: res.data.user_list });
    });
    if (location.state != undefined) {
      var business_branches = location.state.mapping_item.item.branch;
      business_branches.forEach(function (element) {
        element.value = element.id;
        element.display_name = element.alias ? element.alias : element.location_address;
      });
      axios_instance
        .get(`${URLS.BUSINESS_SETUP}${location.state.mapping_item.item.business.id}/branch/`)
        .then((res) => {
          this.setState({ businessBranches: res.data.choices.slice(1) });
        });
      const results = this.state.agency_users.filter(
        ({ id: id_1 }) => !location.state.mapping_item.item.staff.some(({ id: id_2 }) => id_2 === id_1)
      );
      this.setState({
        mappingName: location.state.mapping_item.item.name,
        mappingDescription: location.state.mapping_item.item.description,
        selectedBusiness: location.state.mapping_item.item.business.id,
        selectedBranches: business_branches,
        existingTeam: location.state.mapping_item.item.staff,
        teamMappingId: location.state.mapping_item.item.id,
        newAssigneeOptions: results,
      });
    }
    axios_instance.get(utils.format(`${URLS.AGENCY_BUSINESS_ALL}`, [this.state.agency_id])).then((res) => {
      this.setState({ businessList: res.data });
    });
  }

  goBack() {
    const {
      history,
      match: {
        params: { agency_id },
      },
    } = this.props;
    history.push(`/${agency_id}/agency-team`);
  }

  deleteTeamMapping(id) {
    this.setState({ loader: true });
    const {
      history,
      match: {
        params: { agency_id },
      },
    } = this.props;
    axios_instance.delete(utils.format(`${URLS.TEAM_MAPPING}${id}/`, [this.state.agency_id])).then(() => {
      this.setState({ loader: false });
      history.push(`/${agency_id}/agency-team`);
    });
  }

  render() {
    const { loader } = this.state;
    const { handleSubmit, errors } = this.props;
    return (
      <div>
        <Row className="login-bg">
          <LoaderComponent loader={loader} />
          <Col xs={12} className="p-0">
            <NavBar match={this.props.match} history={this.props.history} />
          </Col>
        </Row>
        <Form className="grid-form" onSubmit={handleSubmit(this.submit)}>
          {errors && errors.non_field_errors
            ? errors.non_field_errors.map((error, index) => (
                <p key={index} className="text-danger">
                  {error}
                </p>
              ))
            : null}
          <Row className="ml-1 mr-0">
            <Card body className="py-2">
              <CardTitle className="mb-0 fw3">
                <Row>
                  <Col sm="6">
                    <img
                      src={BackIcon}
                      alt=""
                      height="20"
                      width="20"
                      className="align-bottom cursor-pointer"
                      onClick={this.goBack}
                    />
                    <span className="mt-2 d-inline-block ml-2">Manage Team Mapping</span>
                  </Col>
                  <Col className="cursor-pointer float-right mt-1" sm="6" style={{ textAlign: "right" }}>
                    {this.state.teamMappingId && (
                      <PopupIconComponent
                        iconName="trash alternate"
                        content="Delete Team Mapping"
                        onClick={() => this.deleteTeamMapping(this.state.teamMappingId)}
                      />
                    )}
                    <Button color="teal" type="submit" disabled={!this.state.selectedBusiness}>
                      Save
                    </Button>
                  </Col>
                </Row>
              </CardTitle>
            </Card>
          </Row>
          <Row className="mt-2 ml-1 mr-0">
            <Card body className="py-2 text-center">
              <Row>
                <Col sm="3">
                  <Label>Name</Label>
                </Col>
                <Col sm="5" className="no-label">
                  <input
                    type="text"
                    name="mapping_name"
                    value={this.state.mappingName}
                    onChange={this.handleNameChange}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: "20px", marginTop: "20px" }}>
                <Col sm="3">
                  <Label>Description</Label>
                </Col>
                <Col sm="5" className="no-label">
                  <textarea
                    name="mapping_description"
                    rows={3}
                    cols={40}
                    value={this.state.mappingDescription}
                    onChange={this.handleDescriptionChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <Label className="required-field">Business</Label>
                  <Popup content="Please select business to add team mapping" trigger={<Icon name="info circle" />} />
                </Col>
                <Col sm="5" className="no-label">
                  <select
                    onChange={this.handleBusinessChange}
                    value={this.state.selectedBusiness}
                    name="selected_business"
                  >
                    <option value="">Select Business</option>
                    {this.state.businessList.length != 0 &&
                      this.state.businessList.map((business) => (
                        <option key={business.id} value={business.id}>
                          {business.name}
                        </option>
                      ))}
                    ;
                  </select>
                </Col>
              </Row>
              <Row className="py-2">
                <Col sm="3">
                  <Label>Locations</Label>
                </Col>
                <Col sm="5" className="no-label">
                  <Field
                    name="selected_branches"
                    component={MultiSelect}
                    placeholder="Select Locations (All Locations by default)"
                    options={this.state.businessBranches.length != 0 && this.state.businessBranches}
                    onChange={this.handleBranchChange}
                    val={this.state.selectedBranches.length != 0 && this.state.selectedBranches}
                    labelKey="display_name"
                    valueKey="value"
                    multi
                    disabled={!this.state.selectedBusiness}
                    simpleValue
                  />
                </Col>
              </Row>
              <Row fluid className="py-2">
                <Col sm="3">
                  <Label>Existing Team</Label>
                </Col>
                <Col sm="5" className="no-label" fluid>
                  <Field
                    name="existing_team"
                    component={MultiSelect}
                    labelKey="email"
                    valueKey="id"
                    placeholder="Existing Team Members"
                    options={this.state.existingTeam.length != 0 && this.state.existingTeam}
                    multi
                    simpleValue
                    val={this.state.existingTeam.length != 0 && this.state.existingTeam}
                  />
                </Col>
              </Row>
              <Row fluid className="py-2">
                <Col sm="3">
                  <Label>New Assignee</Label>
                </Col>
                <Col sm="5" className="no-label" fluid>
                  <Field
                    name="new_assignee"
                    component={MultiSelect}
                    labelKey="email"
                    valueKey="id"
                    placeholder="Select Team Members to assign"
                    options={this.state.newAssigneeOptions.length != 0 && this.state.newAssigneeOptions}
                    multi
                    simpleValue
                  />
                </Col>
              </Row>
            </Card>
          </Row>
        </Form>
      </div>
    );
  }
}

TeamMappingForm.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

TeamMappingForm.propTypes = {
  actions: PropTypes.instanceOf(Object),
};

TeamMappingForm = reduxForm({
  // eslint-disable-line
  form: "TeamMappingForm",
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(TeamMappingForm);

const selector = formValueSelector("TeamMappingForm");

TeamMappingForm = connect((state) => {
  // eslint-disable-line
  const selectedBranches = selector(state, "selected_branches");
  const selectedUsers = selector(state, "new_assignee");
  const existingTeam = selector(state, "existing_team");
  return {
    selectedBranches,
    selectedUsers,
    existingTeam,
  };
})(TeamMappingForm);

export default TeamMappingForm;
