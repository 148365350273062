import React from "react";
import FPTextArea from "../../components/common/new/forms/ui/FPTextArea";
import { Box, IconButton } from "@material-ui/core";
import { Divider } from "semantic-ui-react";
import { AIResponse, Emoji, Notebook, Send } from "../../images/svg";
import { reduxForm } from "redux-form";
import { Avatar } from "@material-ui/core";

const AddComments = (props) => {
  const { item, commentText, handleCommentChange, toggleTemplateOpen, setOpenEmojiPicker, handleReplyComment } = props;
  return (
    <form>
      <div className="d-flex mt-3">
        <div style={{ marginRight: "10px" }}>
          <Avatar src={item.from_profile_pic} className="m-10" />
        </div>
        <div className="w-100">
          <Box className="pt-2 px-3 mb-2" sx={{ border: "1px solid #BFC2CB", borderRadius: "10px" }}>
            <FPTextArea
              autoHeight
              name="comments"
              onChange={handleCommentChange}
              value={commentText}
              placeholder="Enter Comment"
              sx={{
                borderRadius: "10px",
                border: "none",
                boxShadow: "none !important",
                padding: "9px 1px !important",
                "& .fp-textarea": {
                  fontSize: "14px",
                  border: "none",
                  maxHeight: 70,
                },
              }}
              minRows={4}
            />
            <Divider className="m-0" />
            <div className="">
              <IconButton onClick={() => toggleTemplateOpen()} className="font-secondary" size="small">
                <Notebook style={{}} />
              </IconButton>
              <IconButton onClick={() => {}} className="font-secondary" size="small">
                <AIResponse style={{ margin: "0px 9px", marginTop: "" }} />
              </IconButton>
              <IconButton onClick={(event) => setOpenEmojiPicker(event)} className="font-secondary" size="small">
                <Emoji style={{}} />
              </IconButton>
            </div>
          </Box>
          <Box component="span" sx={{ color: "#1B1C1D", fontSize: "15px" }}>
            Replying as<span style={{ color: "#7A8193" }}>: {item.from_name}</span>
          </Box>
        </div>
        <IconButton
          component="span"
          className="circular-button send-btn"
          style={{ backgroundColor: "#f13a88" }}
          onClick={() => handleReplyComment(item)}
        >
          <Send />
        </IconButton>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "AddComments",
  enableReinitialize: true,
})(AddComments);
