import axios_instance from "../../libs/interseptor";
import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import cookie from "react-cookies";
import * as commonAction from "../common/commonAction";

export function getReportSuccess(data) {
  return { type: types.GET_REPORT_SUCCESS, data };
}

export function getReportFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.GET_REPORT_FAILURE, data };
}

export const getReport = (parameters) => {
  const { report, business_id, ...params } = parameters;
  let urlHeader = "BUSINESS_SETUP",
    urlReport;
  if (report === "reviews") {
    urlReport = "dashboard-report";
  }
  if (report === "nps") {
    urlReport = "nps-report";
  }
  if (report === "visitor") {
    urlReport = "visitors-report";
  }
  if (report === "conversion") {
    urlReport = "conversion-report";
  }
  if (report === "sentiment") {
    urlReport = "sentiment-report";
  }
  if (report === "tags") {
    urlReport = "tags-report";
  }
  if (report === "tickets") {
    urlReport = "reports/ticket_stats";
    urlHeader = "TICKET";
  }
  if (report === "display-report") {
    urlReport = "display-report";
  }
  if (report === "star") {
    urlReport = "star-rating-report";
  }
  if (report === "standard") {
    urlReport = "survey-report";
  }
  if (report === "textual") {
    urlReport = "survey-textual-report";
  }
  if (report === "ticket-survey") {
    urlHeader = "TICKET";
    urlReport = "ticket-survey-report";
    if (params.report_type === "textual") {
      urlReport = "textual-ticket-survey-report";
    }
  }
  return (dispatch) => {
    dispatch(commonAction.showLoader());
    let full_url = `${URLS[urlHeader]}${business_id}/${urlReport}/`;
    if (report === "ticket-survey") {
      full_url = `${URLS[urlHeader]}${business_id}/${urlReport}`;
    }
    if (params.branch === "") {
      delete params.branch;
    }
    return axios_instance
      .get(full_url, { params })
      .then((res) => {
        dispatch(commonAction.hideLoader());
        dispatch(getReportSuccess(res.data));
        return res;
      })
      .catch((err) => {
        dispatch(commonAction.hideLoader());
        dispatch(getReportFailure(err));
        throw err.response.data;
      });
  };
};

export const emailReport = (url) => {
  return () => {
    return axios_instance
      .get(`${url}&action=send_email`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err.response.data;
      });
  };
};

export const downloadReport = (url, report) => {
  const token = cookie.load("authToken");
  return fetch(url, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
      "Content-Type":
        report === "display-report"
          ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          : "application/csv",
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download =
        report === "display-report"
          ? "famepilot-review-report.xlsx"
          : "famepilot-review-report.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    })
    .catch((err) => {
      return err;
    });
};
