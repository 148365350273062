/* eslint-disable */
import React, { memo, useMemo } from "react";
import CustomStarRatingComponent from "../../components/common/StarComponent";
import { displayMessage, snakeToCapital } from "../../libs/utils";
import { Redirect } from "../../images/svg";
import moment from "moment";
import { Icon } from "semantic-ui-react";
import { FPToolTip } from "../../components/common/new";
import { starRatingColor } from "../../libs/utils";
import { Box } from "@material-ui/core";

const ReviewCardHeader = ({
  item,
  isCompetitors,
  isComplaints,
  serviceCategory,
  smiley,
  businessName,
}) => {
  const { star_border_color, star_bg_color } = useMemo(
    () => starRatingColor(item.rating),
    [item.rating]
  );

  const formattedDate = useMemo(() => {
    return moment(item.review_create || item.updated_at).format("llll");
  }, [item.review_create, item.updated_at]);

  const filteredTags = useMemo(() => {
    if (!item.fameai_tags || !Array.isArray(item.fameai_tags)) return [];
    const uniqueTags = item.fameai_tags.reduce((acc, curr) => {
      if (
        curr.sentiment &&
        curr.tag_category_name &&
        curr.tag_category_name.trim().length > 0 &&
        !acc.some((tag) => tag.tag_category_name === curr.tag_category_name)
      ) {
        acc.push(curr);
      }
      return acc;
    }, []);
    return uniqueTags;
  }, [item.fameai_tags]);

  return (
    <header className="review-card-header">
      <div className="d-flex justify-content-between align-items-center">
        <section className="d-flex align-item-center gap-10">
          <FPToolTip title={snakeToCapital(item.provider_name)}>
            <div
              className="provider-logo-wrapper"
              onClick={() => {
                item.provider_link
                  ? window.open(item.provider_link, "_blank")
                  : null;
              }}
              role="button"
            >
              <img
                src={item.provider}
                alt=""
                height="24"
                width="24"
                loading="lazy"
              />
            </div>
          </FPToolTip>
          <div className="d-flex align-items-start gap-10">
            {!isCompetitors && (
              <div>
                <span 
                  className="review-title cursor-pointer" 
                  onClick={() => {
                    navigator.clipboard.writeText(item.id);
                    displayMessage("positive", "Review Id copied to clipboard");
                  }}  
                >
                  {!isComplaints
                    ? snakeToCapital(item.branch)
                    : snakeToCapital(businessName)}
                </span>
                {serviceCategory &&
                  serviceCategory === "Restaurant" &&
                  item.service_type && (
                    <span className="text-capitalize font-blue-gray"></span>
                  )}
                <span className="d-block font-blue-gray">{formattedDate}</span>
              </div>
            )}
            <div className="review-time">
              {isComplaints && (
                <div className="label">
                  <Icon color="red" size="big" name="copyright" />
                </div>
              )}
              {item.review_type === "thumb" && (
                <Icon
                  color="teal"
                  size="large"
                  name={item.is_thum_up ? "thumbs up" : "thumbs down"}
                />
              )}
              {item.review_type === "smiley" && (
                <Icon color="teal" size="large" name={smiley} />
              )}
              {item.review_type === "nps_score" && (
                <div className="review-nps">{item.nps_score}/10</div>
              )}
              {item.review_type === "star" && !isComplaints && (
                <div
                  className="rating"
                  style={{
                    borderColor: star_border_color,
                    backgroundColor: star_bg_color,
                    ...(isCompetitors && { marginTop: 26 }),
                  }}
                >
                  <CustomStarRatingComponent
                    name="rate1"
                    starCount={5}
                    className="align-bottom"
                    starColor="#FBBD08"
                    emptyStarColor="#FFFFFF"
                    value={+item.rating}
                    editing={false}
                  />
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="d-flex align-item-center gap-10">
          {item?.categories && Array.isArray(item?.categories) && item?.categories?.length > 0 && (
            <div className="d-flex flex-wrap justify-content-end gap-4">
              {item?.categories.map((tag, index) => {
                return (
                  <Box
                    key={index}
                    component={"p"}
                    sx={{
                      padding: "2px 15px",
                      color: "#1B1C1D",
                      border: "2px solid",
                      borderColor: tag.sentiment
                        ? tag.sentiment === "positive"
                          ? "#81e050"
                          : tag.sentiment === "negative"
                          ? "#EC554E"
                          : tag.sentiment === "neutral"
                          ? "#FA25A"
                          : "transparent"
                        : "black",
                      borderRadius: "99px",
                      fontSize: "10px",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      display: "flex",
                      alignItems: "center",
                      margin: "0px !important",
                      lineHeight: "10px",
                    }}
                  >
                    {tag.tag_category_name}
                  </Box>
                );
              })}
            </div>
          )}
          {item.provider_name === "zomato" ||
          item.provider_name === "tripadvisor" ? (
            <span
              role="button"
              title={`Redirect to ${item.provider_name}`}
              onClick={() => {
                window.open(item.review_link, "_blank");
              }}
            >
              <Redirect />
            </span>
          ) : item.provider_link ? (
            <span
              role="button"
              title={`Redirect to ${item.provider_name}`}
              onClick={() => {
                window.open(item.provider_link, "_blank");
              }}
            >
              <Redirect />
            </span>
          ) : (
            ""
          )}
        </section>
      </div>
    </header>
  );
};

// export default memo(ReviewCardHeader);
export default memo(
  ReviewCardHeader,
  (prevProps, nextProps) =>
    JSON.stringify(prevProps.item) === JSON.stringify(nextProps.item) &&
    prevProps.isCompetitors === nextProps.isCompetitors &&
    prevProps.isComplaints === nextProps.isComplaints
);
