import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Segment,
  Header,
  Label,
  Grid,
  Image,
  Icon,
} from "semantic-ui-react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { FPCheckbox } from "../../components/common/new";
import { Box } from "@material-ui/core";
import { DynamicConsentText } from "../../libs/utils";

const ContactDetailPage = (props) => {
  const [number, setNumber] = useState();
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [nameError, setNameError] = useState("");
  const [stateName, setStateName] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [branchName, setBranchName] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  // const [gender, setGender] = useState('');
  const [selectedDob, setselectedDob] = useState(null);
  const [selectedDoa, setselectedDoa] = useState(null);
  const [selectedGender, setselectedGender] = useState("");
  const [consentStatus, setConsentStatus] = useState(true);

  const {
    submit,
    business,
    landing_page_heading,
    branch_country,
    show_city,
    show_state,
    enable_consent,
    consent_text,
    show_store_options_in_uppercase,
  } = props;

  const handleState = (e) => {
    let data = e.target.value;
    setStateName(data);
    props.getCity(data, business.id);
  };

  const handleCity = (e) => {
    let data = e.target.value;
    setCityName(data);
    // setBranchName(null);
    // setBranchList([]);
    props.getBranch(stateName, data, business.id);
  };

  const handleBranch = (e) => {
    let data = e.target.value;
    setBranchName(data);
  };

  const handleDobDatePicker = (date) => {
    setselectedDob(moment(date).format("YYYY-MM-DD"));
  };
  const handleDoaDatePicker = (date) => {
    setselectedDoa(moment(date).format("YYYY-MM-DD"));
  };
  const handleConsentChange = (event) => {
    setConsentStatus(event.target.checked);
  };

  // useEffect(() => {
  //   console.log('props state+++++', props.state);
  //   setStateList(props.state);
  //   setCityName(null);
  //   setBranchName(null);
  //   setCityList([]);
  //   setBranchList([]);
  // }, [props.state]);

  // useEffect(() => {
  //   let city = props.city;
  //   console.log('props city+++++', city);
  //   setCityList(city);
  //   setBranchName(null);
  //   setBranchList([]);
  // }, [props.city]);

  // useEffect(() => {
  //   let branch = props.branch;
  //   console.log('props branch_+++++++', branch);
  //   setBranchList(branch);
  // }, [props.branch]);

  useEffect(() => {
    setStateList(props.state);
  }, [props.state]);

  useEffect(() => {
    setCityList(props.city);
  }, [props.city]);

  useEffect(() => {
    setBranchList(props.branch);
  }, [props.branch]);

  return (
    <Grid
      textAlign="center"
      style={{ height: "auto", background: "transparent", width: "415px" }}
      verticalAlign="middle"
    >
      <Grid.Column textAlign="left" text style={{ maxWidth: 350 }}>
        <Form
          size="large"
          onSubmit={(e) => {
            console.log(e);
            if (!isValidPhoneNumber(number)) {
              setError("Enter a valid phone number");
            } else if (name.length < 3) {
              setNameError("Enter minimum 3 characters");
            } else if (email && !email.includes("@")) {
              setEmailError("Please enter a valid Email");
            } else {
              setError("");
              setNameError("");
              setEmailError("");
              submit(
                number,
                name,
                stateName,
                cityName,
                branchName,
                email,
                selectedDob,
                selectedDoa,
                selectedGender
              );
            }
          }}
        >
          <Segment
            stacked
            rounded
            style={{
              height: props.include_location
                ? "auto"
                : nameError || error
                ? "auto"
                : "auto",
              borderRadius: "20px",
              paddingBottom: "60px",
            }}
          >
            <Header
              as="h2"
              textAlign="center"
              style={{ color: "darkgreen", fontSize: "32px" }}
            >
              <Image src={business ? business.logo : ""} />
            </Header>
            {landing_page_heading && (
              <Header
                textAlign="center"
                as="p"
                style={{ lineHeight: 1, fontWeight: 900, fontSize: "22px" }}
              >
                {landing_page_heading}
              </Header>
            )}
            {props.include_location && (
              <div>
                <p style={{ padding: "5px 5px 0px 0px" }}>Store Details</p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {show_state && (
                    <div style={{ width: "95%", marginRight: "10px" }}>
                      <select
                        name="state"
                        placeholder="State"
                        onChange={(e) => handleState(e)}
                        value={stateName}
                      >
                        <option value="" selected>
                          Select a State
                        </option>
                        {stateList.map((item, idx) => (
                          <option
                            value={item.value}
                            key={idx}
                            style={{ padding: "15px 0" }}
                          >
                            {/* {item.display_name} */}
                            {/* {show_store_options_in_uppercase
                              ? item.display_name.toUpperCase()
                              : item.display_name} */}
                            {show_store_options_in_uppercase
                              ? item.display_name.toUpperCase()
                              : item.display_name.includes("_")
                              ? item.display_name.replace(/^\w/, (char) =>
                                  char.toUpperCase()
                                )
                              : item.display_name.replace(/\b\w/g, (char) =>
                                  char.toUpperCase()
                                )}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  {show_city && (
                    <div style={{ width: "95%" }}>
                      <select
                        name="city"
                        placeholder="City"
                        onChange={(e) => handleCity(e)}
                        value={cityName}
                        disabled={cityList.length == 0}
                      >
                        <option value="" selected>
                          Select a City
                        </option>
                        {cityList &&
                          cityList.length > 0 &&
                          cityList.map((item, idx) => (
                            <option
                              value={item.value}
                              key={idx}
                              style={{ padding: "15px 0" }}
                            >
                              {/* {item.display_name} */}
                              {show_store_options_in_uppercase
                                ? item.display_name.toUpperCase()
                                : item.display_name.includes("_")
                                ? item.display_name.replace(/^\w/, (char) =>
                                    char.toUpperCase()
                                  )
                                : item.display_name.replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                            </option>
                          ))}
                      </select>
                    </div>
                  )}
                </div>
                <div style={{ marginTop: "10px", marginBottom: "20px" }}>
                  <select
                    name="branch"
                    placeholder="Branch Name"
                    onChange={(e) => handleBranch(e)}
                    value={branchName}
                    disabled={branchList.length == 0}
                  >
                    <option value="" selected>
                      Select a Branch
                    </option>
                    {branchList &&
                      branchList.length > 0 &&
                      branchList.map((item, idx) => (
                        <option
                          value={item.value}
                          key={idx}
                          title={item.display_name}
                        >
                          {/* {item.display_name.substring(0, 37) + "..."} */}
                          {/* {show_store_options_in_uppercase
                            ? item.display_name.substring(0, 37).toUpperCase() +
                              "..."
                            : item.display_name.substring(0, 37) + "..."} */}
                            {show_store_options_in_uppercase
                                ? item.display_name.toUpperCase()
                                : item.display_name.includes("_")
                                ? item.display_name.replace(/^\w/, (char) =>
                                    char.toUpperCase()
                                  )
                                : item.display_name.replace(/\b\w/g, (char) =>
                                    char.toUpperCase()
                                  )}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}
            <Form.Field required={true}>
              <label style={{ marginBottom: "8px" }}>
                Enter your name
              </label>
              <input
                style={{
                  border: "none",
                  outline: "none",
                  borderBottom: "1.1px solid black",
                  borderRadius: "0px",
                }}
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {nameError ? (
                <Label basic color="red" pointing>
                  {nameError}
                </Label>
              ) : null}
            </Form.Field>
            <Form.Field required={true}>
              <label style={{ marginBottom: "8px" }}>Enter your number</label>
              <PhoneInput
                className="surveyPhoneNumber"
                autoFocus
                placeholder="Enter your number"
                name={"number"}
                value={number}
                defaultCountry={branch_country}
                countrySelectProps={{ unicodeFlags: true }}
                required={true}
                inp
                onChange={(e) => setNumber(e)}
                style={{
                  border: "unset!important",
                  outline: "unset!important",
                  borderBottom: "1.1px solid black",
                  borderRadius: "0px",
                }}
              />
              {error ? (
                <Label basic color="red" pointing>
                  {error}
                </Label>
              ) : null}
            </Form.Field>
            {props.include_email && (
              <div style={{ marginBottom: "10px" }}>
                <label style={{ marginBottom: "0" }}>Enter your Email</label>
                <input
                  style={{
                    border: "none",
                    outline: "none",
                    borderBottom: "1.1px solid black",
                    borderRadius: "0px",
                  }}
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {emailError ? (
                  <Label basic color="red" pointing>
                    {emailError}
                  </Label>
                ) : null}
              </div>
            )}
            {props.include_dob && (
              <div className="dobDatePicker" style={{ marginBottom: "10px" }}>
                <label style={{ marginBottom: "0" }}>Enter your DOB</label>
                <DatePicker
                  name="dob"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => handleDobDatePicker(date)}
                  className="form-control form-control-sm"
                  type="text"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  placeholderText={"Select Date of Birth"}
                  value={selectedDob}
                  showYearDropdown
                />
              </div>
            )}
            {props.include_doa && (
              <div className="doaDatePicker" style={{ marginBottom: "10px" }}>
                <label style={{ marginBottom: "0" }}>Enter your DOA</label>
                <DatePicker
                  name="doa"
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => handleDoaDatePicker(date)}
                  className="form-control form-control-sm"
                  type="text"
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  placeholderText={"Select Date of Anniversary"}
                  value={selectedDoa}
                  showYearDropdown
                />
              </div>
            )}
            {props.include_gender && (
              <div style={{ marginBottom: "10px" }}>
                <label style={{ marginBottom: "0" }}>Enter your Gender</label>
                <select
                  name="select"
                  value={selectedGender}
                  onChange={(e) => setselectedGender(e.target.value)}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            )}
            {props.enable_consent && props.consent_text && (
              <div className="d-flex gap-10 align-items-start">
                <FPCheckbox
                  name="consent_value"
                  onClick={(event) => handleConsentChange(event)}
                  checked={consentStatus}
                />
                <DynamicConsentText consent_text={props.consent_text} />
                {/* <Box component="span" sx={{ fontSize: "12px", color: "#1B1C1D" }}>
                  {props.consent_text}
                </Box> */}
              </div>
            )}
            <Form.Field>
              <Button
                circular
                style={{ color: "white", backgroundColor: "blue" }}
                floated="right"
                icon
                labelPosition="right"
              >
                Next
                <Icon name="right arrow" />
              </Button>
            </Form.Field>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default ContactDetailPage;
