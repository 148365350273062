import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError, formValueSelector } from "redux-form";
import InputComponent from "../common/InputComponent";
import * as utils from "../../libs/utils";
import SelectComponent from "../common/SelectComponent";
import { FPBreadcrumbs, FPButton } from "../common/new";
import FPLabel from "../common/new/forms/ui/FPLabel";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = "Enter Name";
  if (!values.branch) errors.branch = "Select branch";
  if (!values.department) errors.department = "Enter department";
  if (!values.designation) errors.designation = "Enter designation";
  if (!values.aliases) errors.aliases = "Enter aliases";
  return errors;
};

class StaffForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  submit(data) {
    const {
      router: { history },
    } = this.context;
    return this.props
      .submitData(data)
      .then(() => {
        history.push({
          pathname: "central-setup",
          state: { tab: "9" },
        });
      })
      .catch((err) => {
        err && err.non_field_errors && err.non_field_errors.map((err) => utils.displayMessage("negative", err));
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  }

  render() {
    const { submitting, handleSubmit, errors, locationsList, isEditMode } = this.props;
    const breadCrumbList = [
      {
        label: "Central Setup",
        link: "",
      },
      {
        label: "Staff",
        onClick: () =>
          this.context.router.history.push({
            pathname: "central-setup",
            state: { tab: "9" },
          }),
      },
      {
        label: isEditMode ? "Edit Staff" : "Add Staff",
        link: "",
      },
    ];
    return (
      <div>
        <FPBreadcrumbs list={breadCrumbList} className="my-2 mb-4" />
        <CardWrapper headerTitle={isEditMode ? "Edit Staff" : "Add Staff"} contentClass="p-4">
          <form className="grid-form" onSubmit={handleSubmit(this.submit)}>
            <div className="mt-2">
              <FPLabel>Name</FPLabel>
              <Field
                name="name"
                type="text"
                component={InputComponent}
                serverError={errors && errors.name}
                placeholder="Name"
              />
              <FPLabel>branch</FPLabel>
              <Field
                name="branch"
                component={SelectComponent}
                labelKey="display_name"
                valueKey="value"
                placeholder="Select Branch"
                options={locationsList && locationsList}
                simpleValue
                serverError={errors && errors.branch}
                className="select-branch-dropdown"
                style={{
                  ".Select-value": {
                    maxWidth: "100%",
                  },
                }}
              />
              <FPLabel className="mt-4">department</FPLabel>
              <Field
                name="department"
                type="text"
                component={InputComponent}
                serverError={errors && errors.department}
                placeholder="Department Name"
              />
              <FPLabel>designation</FPLabel>
              <Field
                name="designation"
                type="text"
                component={InputComponent}
                serverError={errors && errors.designation}
                placeholder="Designation Name"
              />
              <FPLabel>aliases</FPLabel>
              <Field
                name="aliases"
                type="text"
                component={InputComponent}
                serverError={errors && errors.aliases}
                placeholder="Aliases"
              />
            </div>
            <div className="d-flex align-items-center justify-content-end gap-6 mt-2">
              <FPButton type="text" size="small" label="Cancel" variant="outline" />
              <FPButton
                type="submit"
                size="small"
                label="Save"
                // onClick={() =>
                //   this.context.router.history.push({
                //     pathname: "central-setup",
                //     state: { tab: "9" },
                //   })
                // }
              />
            </div>
          </form>
        </CardWrapper>
      </div>
    );
  }
}

StaffForm.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

StaffForm = reduxForm({
  // eslint-disable-line
  form: "StaffForm",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(StaffForm);

const selector = formValueSelector("StaffForm");

StaffForm = connect((state) => {
  // eslint-disable-line
  const responseBody = selector(state, "content");
  const autoReply = selector(state, "auto");
  return {
    responseBody,
    autoReply,
  };
})(StaffForm);

export default StaffForm;
