/* eslint-disable quotes */
import React from "react";
import "../review/userDetails.css";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";

const OrderIdView = ({ data }) => {
  const iconStyle = {
    width: "14px",
    height: "14px",
    color: "#F13A88",
    marginRight: "3px",
  };
  return (
    <>
      {data?.source_data && (
        <div className="d-flex align-items-center gap-6">
          {data?.source_data?.extra_data?.order_id && (
            <div className="d-flex align-items-center" style={{ gap: "3px" }}>
              <AssignmentOutlinedIcon style={iconStyle} />
              <span className="font-blue-gray line-height-1">{data?.source_data?.extra_data?.order_id}</span>
            </div>
          )}
          {data?.source_data?.reviewer?.email && <span className="fp-vertical-small-divider"></span>}
          {data?.source_data?.reviewer?.email && (
            <div className="d-flex align-items-center" style={{ gap: "3px" }}>
              <EmailOutlinedIcon style={iconStyle} />
              <span className="font-blue-gray line-height-1">{data?.source_data?.reviewer?.email}</span>
            </div>
          )}
          {(data?.source_data?.reviewer?.phone || data?.source_data?.reviewer?.contact_number) && (
            <span className="fp-vertical-small-divider"></span>
          )}
          {(data?.source_data?.reviewer?.phone || data?.source_data?.reviewer?.contact_number) && (
            <div className="d-flex align-items-center" style={{ gap: "3px" }}>
              <PhoneOutlinedIcon style={iconStyle} />
              <span className="font-blue-gray line-height-1">
                {data?.source_data?.reviewer?.phone || data?.source_data?.reviewer?.contact_number}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default OrderIdView;
