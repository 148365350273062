import React from "react";
import { Row, Col } from "reactstrap";
import IOSAppStorIcon from "../../images/app-store.png";
import axios_instance from "../../libs/interseptor";
import LoaderComponent from "../../components/common/loaderComponent";
import * as SocialSetupActions from "../../actions/business/socialSetupActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as URLS from "../../libs/apiUrls";
import { withRouter } from "react-router";
import SelectBranchForPlayStore from "./SelectBranchForPlayStore";
import FormModal from "../common/FormModal";
import SelectBranchForIOSAppStore from "./SelectBranchForIOSAppStore";
import * as listingUtils from "../../libs/listingToast";

class SetupIASAccounts extends React.Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.business_id !== prevState.business_id) {
      return { business_id: nextProps.business_id };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      business_id: this.props.business_id,
      btnText: "Connect to PlayStore",
      name: "",
      connected: false,
      loader: false,
      branchPopOpen: false,
    };
    this.toggleBranchPop = this.toggleBranchPop.bind(this);
    this.handleBranchSelect = this.handleBranchSelect.bind(this);
  }

  handleBranchSelect(value) {
    let formdata = new FormData();
    formdata.append("business", this.state.business_id);
    formdata.append("branch", value.branch);
    formdata.append("issuer_id", value.issuerID);
    formdata.append("key_id", value.keyID);
    formdata.append("p8File", value.jsonFile[0], value.jsonFile[0].name);
    var headers = {
      "content-type": "multipart/form-data",
    };

    return axios_instance
      .post(
        `${URLS.SOCIAL}${URLS.APP_STORE_BRANCH_SETUP_SOCIAL}`,
        formdata,
        headers
      )
      .then((res) => {
        if (res.data.success) {
          this.setState({
            branchPopOpen: false,
            name: res.data.name,
            connected: true,
          });
          listingUtils?.displayMessage("positive", res.data.message);
          this.props.callSocialProfileApi();
          this.props.handleConnsectProfile();
        } else {
          alert("Something went wrong, Please try again!");
        }
      });
  }

  toggleBranchPop() {
    const { branchPopOpen } = this.state;
    this.setState({ branchPopOpen: !branchPopOpen });
  }

  componentDidMount() {
    // this.props.business!==undefined && this.checkLoggedIn();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.business_id !== this.state.business_id) {
      // this.checkLoggedIn();
    }
  }

  // playstoreConnect(code){
  //   const details = {
  //     'code' : code,
  //     'business': this.state.business_id
  //   };
  //   return axios_instance.post(`${URLS.SOCIAL}playstore/`,details)
  //     .then(res=>{
  //       this.checkLoggedIn();
  //       console.log(res);
  //       window.removeEventListener('message',(data)=>console.log(data));
  //       // this.setState({branchPopOpen:true});
  //     })
  //     .catch(err=>{
  //       console.log(err);
  //       this.setState({
  //         loader: false
  //       });
  //     });
  // }

  // connectSocial(data){
  //   this.playstoreConnect(data.data.code);
  // }

  // getAuthUrl(data) {
  //   this.setState({
  //     loader: true
  //   });
  //   this.props.SocialSetupActions.getAuthUrl({ provider: data, business_id:this.state.business_id })
  //     .then(() => {
  //       const { socialSetupList } = this.props;
  //       const authUrl = socialSetupList.auth_url;
  //       this.newWindow = window.open(authUrl, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=600');
  //       this.setState({provider: data, loader: false});
  //       window.addEventListener('message',(data)=>this.connectSocial(data));
  //     })
  //     .catch(()=>{
  //       this.setState({
  //         loader: false
  //       });
  //     });
  // }

  // checkLoggedIn() {
  //   this.setState({
  //     loader: true
  //   });
  //   const { match: { params: { business_id } } } = this.props;
  //   axios_instance.get(`${URLS.SOCIAL}business/${business_id}/playstore/status/`)
  //     .then((res)=>{
  //       this.setState({loader: false,connected: res.data.connected,name: res.data.name});
  //     })
  //     .catch((err)=>{
  //       this.setState({loader: false});
  //       console.log(err);
  //     });
  // }

  logOut() {
    // const { match: { params: { business_id } } } = this.props;
    // this.setState({btnText: 'Login to PlayStore',jsonUploaded:false});
    // axios_instance.post(`${URLS.SOCIAL}business/${business_id}/playstore/status/`)
    //   .then(()=>{
    //     this.checkLoggedIn();
    //   })
    //   .catch((err)=>{
    //     this.setState({
    //       loader: false
    //     });
    //     console.log(err);
    //   });
  }

  render() {
    const { connectedStatus, toggleTab } = this.props;
    const { loader } = this.state;
    const { branchList } = this.props;
    return (
      <div>
        <div className="my-2">
          <Row>
            <LoaderComponent loader={loader} />
            <Col>
              <div className="text-centermb-2">
                <span
                  className="text-center google-button cursor-pointer"
                  style={{
                    display: "flex",
                    gap: "10px",
                    borderRadius: "25px",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#00abfa",
                  }}
                  onClick={() => this.toggleBranchPop()}
                >
                  <div style={{ background: "white", borderRadius: "4px" }}>
                    <img
                      src={IOSAppStorIcon}
                      alt="Playstore Icon"
                      height="25"
                      style={{ padding: "4px" }}
                    />
                  </div>
                  <span>Connect to IOS App Store</span>
                </span>
                <div
                  className="mt-2"
                  onClick={toggleTab}
                  style={{
                    textAlign: "center",
                    fontWeight: "600",
                    textDecoration: "underline",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  {connectedStatus
                    ? connectedStatus
                    : this.state.connected
                    ? 1
                    : 0}{" "}
                  profile already connected
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {this.state.branchPopOpen && (
          <FormModal
            isOpen={this.state.branchPopOpen}
            toggle={this.toggleBranchPop}
            heading="Select Branch"
            size="lg"
            width="800px"
          >
            <SelectBranchForIOSAppStore
              SelectBranchSubmit={this.handleBranchSelect}
              BranchList={
                branchList && branchList.choices ? branchList.choices : []
              }
            />
          </FormModal>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    business: state.business,
    socialSetupList: state.socialSetup.list,
    isLoggedIn: state.socialSetup.isLoggedIn,
    branchList: state.business.branch_list,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    SocialSetupActions: bindActionCreators(SocialSetupActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    SetupIASAccounts
  )
);
