import React from "react";
import userIcon from "../../images/svg/person.svg";
import { ReactComponent as EmailIcon } from "../../images/svg/emailIcon.svg";
import phoneIcon from "../../images/svg/phoneIcon.svg";
import receiptIcon from "../../images/svg/receipt.svg";
import diningIcon from "../../images/svg/dining.svg";
import keyIcon from "../../images/svg/key.svg";

const ServiceUserDetails = (props) => {
  const { item } = props;

  return (
    <div className="d-flex align-items-center gap-6">
      {item.customer_name && (
        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          <img src={userIcon} />
          <span className="font-blue-gray line-height-1">{item.customer_name}</span>
        </div>
      )}
      {item.customer_name && <span className="fp-vertical-small-divider"></span>}

      {item.customer_mobile && (
        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          <img src={phoneIcon} />
          <span className="font-blue-gray line-height-1">{item.customer_mobile}</span>
        </div>
      )}

      {item.customer_mobile && <span className="fp-vertical-small-divider"></span>}
      {item.customer_email && (
        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          <EmailIcon  style={{color: "#F13A88", height: "12px"}}/>
          <span className="font-blue-gray line-height-1">{item.customer_email}</span>
        </div>
      )}

      {item.customer_email && <span className="fp-vertical-small-divider"></span>}
      {item.room_number && (
        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          <img src={keyIcon} />
          <span className="font-blue-gray line-height-1">{item.room_number}</span>
        </div>
      )}

      {item.room_number && <span className="fp-vertical-small-divider"></span>}
      {item.table_number && (
        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          <img src={diningIcon} />
          <span className="font-blue-gray line-height-1">{item.table_number}</span>
        </div>
      )}

      {item.table_number && <span className="fp-vertical-small-divider"></span>}
      {item.bill_number && (
        <div className="d-flex align-items-center" style={{ gap: "3px" }}>
          <img src={receiptIcon} />
          <span className="font-blue-gray line-height-1">{item.bill_number}</span>
        </div>
      )}
    </div>
  );
};
export default ServiceUserDetails;
