import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { address: "" };
    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.onError = this.onError.bind(this);
  }

  componentDidMount() {
    this.setAddressFromProps(this.props.defaultValue);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      this.setAddressFromProps(this.props.input.value);
    }
  }

  setAddressFromProps(valueToUse) {
    const { input } = this.props;
    if (valueToUse) {
      geocodeByAddress(valueToUse)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          input.onChange({ address: valueToUse, coordinates: latLng });
        })
        .catch((error) => console.error("Error", error));
      typeof valueToUse === "object"
        ? this.setState({ address: valueToUse?.address || "" })
        : this.setState({ address: valueToUse });
    }
  }

  handleChange(address) {
    const { input } = this.props;
    input.onChange(address);
    this.setState({ address });
  }

  handleSelect(address) {
    const { input } = this.props;
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        input.onChange({ address, coordinates: latLng });
      })
      .catch((error) => console.error("Error", error));
    this.setState({ address });
  }

  onError(status, clearSuggestions) {
    clearSuggestions();
  }

  render() {
    const {
      placeholder,
      label,
      meta: { touched, error },
      height,
    } = this.props;
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.onError}
        debounce={800}
        shouldFetchSuggestions={this.state.address.length > 3}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
          return (
            <div className="pos-relative" style={{ height }}>
              {label && <label className="mb-1">{label}</label>}
              <input
                {...getInputProps({
                  placeholder: placeholder,
                  className: "location-search-input mb-0",
                })}
              />
              {suggestions.length > 0 ? (
                <div className="autocomplete-dropdown-container">
                  {loading && (
                    <div
                      style={{
                        width: "100%",
                        padding: "5px 6px",
                        borderRadius: "20px",
                      }}
                    >
                      Loading...
                    </div>
                  )}
                  {suggestions.map((suggestion, index) => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    const style = suggestion.active
                      ? { backgroundColor: "#fafafa", cursor: "pointer" }
                      : { backgroundColor: "#ffffff", cursor: "pointer" };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                        key={index}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              <small className="text-danger">{touched && error}</small>
            </div>
          );
        }}
      </PlacesAutocomplete>
    );
  }
}

export default LocationSearchInput;
