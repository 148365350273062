import React from "react";

const UserIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 2C8.99445 2 9.4778 2.14662 9.88892 2.42133C10.3 2.69603 10.6205 3.08648 10.8097 3.54329C10.9989 4.00011 11.0484 4.50277 10.952 4.98773C10.8555 5.47268 10.6174 5.91814 10.2678 6.26777C9.91813 6.6174 9.47268 6.8555 8.98773 6.95196C8.50277 7.04843 8.00011 6.99892 7.54329 6.8097C7.08648 6.62048 6.69603 6.30005 6.42133 5.88893C6.14662 5.4778 6 4.99445 6 4.5C6 3.83696 6.26339 3.20107 6.73223 2.73223C7.20107 2.26339 7.83696 2 8.5 2ZM8.5 1C7.80777 1 7.13108 1.20527 6.5555 1.58986C5.97993 1.97444 5.53133 2.52107 5.26642 3.16061C5.00151 3.80015 4.9322 4.50388 5.06725 5.18282C5.2023 5.86175 5.53564 6.48539 6.02513 6.97487C6.51461 7.46436 7.13825 7.7977 7.81718 7.93275C8.49612 8.0678 9.19985 7.99848 9.83939 7.73358C10.4789 7.46867 11.0256 7.02007 11.4101 6.4445C11.7947 5.86892 12 5.19223 12 4.5C12 3.57174 11.6312 2.6815 10.9749 2.02513C10.3185 1.36875 9.42826 1 8.5 1Z"
        fill="#1B1C1D"
      />
      <path
        d="M13.5 15H12.5V12.5C12.5 12.1717 12.4353 11.8466 12.3097 11.5433C12.1841 11.24 11.9999 10.9644 11.7678 10.7322C11.5356 10.5001 11.26 10.3159 10.9567 10.1903C10.6534 10.0647 10.3283 10 10 10H7C6.33696 10 5.70107 10.2634 5.23223 10.7322C4.76339 11.2011 4.5 11.837 4.5 12.5V15H3.5V12.5C3.5 11.5717 3.86875 10.6815 4.52513 10.0251C5.1815 9.36875 6.07174 9 7 9H10C10.9283 9 11.8185 9.36875 12.4749 10.0251C13.1312 10.6815 13.5 11.5717 13.5 12.5V15Z"
        fill="#1B1C1D"
      />
    </svg>
  );
};
export default UserIcon;
