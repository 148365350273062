import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { socialKeys } from "../../images";

const OfferTable = ({ dataList, provider_list }) => {
  const offerList = dataList.sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();  
    return dateB - dateA;
  });

  return (
    <CardWrapper showDivider={false} className="mt-3">
      {offerList && Array.isArray(offerList) && offerList.length > 0 ? <div className="ui-table-responsive">
        <Table className="fp-table fp-table-center">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              {provider_list.map((val) => <TableCell>{socialKeys[val] || val}</TableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {offerList.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{item.date}</TableCell>
                  {provider_list.map((val) => <TableCell>{item[val]}</TableCell> )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      : <h2 style={{ textAlign: 'center', color: 'gray'}}>No data found </h2>}
    </CardWrapper>
  );
};

export default OfferTable;
