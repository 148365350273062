import React, { useEffect, useState } from "react";
import SortIcon from "../../../images/svg/Sort.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import RootHeader from "../../../modules/listing/bulkupdate/RootHeader";
import { FPToolTip } from "../../../components/common/new";
import PaginationComponent from "../../../components/common/Pagination";
import * as setSelectedTab from "../../../actions/common/setSelectedTabAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import LoaderComponent from "../../../components/common/loaderComponent";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
import * as listingGeoGridRankerAction from "../../../actions/listing/GeoGridRankerAction";
// import { setDateFormat } from "../../../libs/utils";

const ScanHistory = (props) => {
  const {
    scan_history_data,
    actions,
    location_id,
    business_id,
    city,
    state,
    branch_tag,
    loader,
    location,
    page,
    page_size,
    date_type,
  } = props;

  useEffect(() => {
    actions.getGmbLocationScanHistory(
      business_id,
      location_id,
      page_size,
      page,
      city,
      branch_tag,
      state,
      date_type
    );
  }, [
    actions,
    city,
    date_type,
    state,
    branch_tag,
    business_id,
    page,
    page_size,
    location_id,
  ]);

  const [sortedData, setSortedData] = useState([]);
  const [isAscending, setIsAscending] = React.useState(true); // State to toggle sorting order

  useEffect(() => {
    setSortedData(scan_history_data?.results);
  }, [scan_history_data?.results]);

  console.log("sortedDatasortedDatasortedData", sortedData);

  const showUseHistoryLocation = (data) => {
    console.log("fffffffffffffffffffffffff", data);

    // useEffect(() => {
    //   if (locationId) {
    actions.useScanHistoryData(data);
    props.history.push(`geo-grid-ranker`);

    //   }
    // }, [location_id, history, business_id, locationId, pathname]);
  };

  const setDateFormat = (dateStr) => {
    if (!dateStr) return null;
    try {
      const date = new Date(dateStr);
      if (!isNaN(date)) {
        return date.toISOString().split("T")[0];
      }
    } catch (error) {
      console.error("Error formatting date:", { dateStr, error });
    }
    return null;
  };

  const handleSortingByDate = () => {
    const sorted = [...sortedData].sort((a, b) => {
      const dateA = new Date(setDateFormat(a?.created_at));
      const dateB = new Date(setDateFormat(b?.created_at));
      return isAscending ? dateA - dateB : dateB - dateA;
    });

    setSortedData(sorted);
    setIsAscending(!isAscending);
  };

  return (
    <div style={{ paddingBlock: "16px" }}>
      <LoaderComponent loader={loader} />
      <CardWrapper showDivider={false}>
        <div className="ui-table-responsive">
          <Table className="fp-table fp-table-center">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ cursor: "pointer" }}
                  onClick={handleSortingByDate}
                >
                  Scan Date <SortIcon />
                </TableCell>
                <TableCell>
                  Location Name
                  {/* <SortIcon /> */}
                </TableCell>
                <TableCell>
                  Keyword
                  {/* <SortIcon /> */}
                </TableCell>
                <TableCell>
                  Distance
                  {/* <SortIcon /> */}
                </TableCell>
                <TableCell>Grid Size</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData?.length > 0 ? (
                sortedData?.map((data) => {
                  const gridSize =
                    data?.grid_size === 3
                      ? " 3x3"
                      : data?.grid_size === 5
                      ? "5x5"
                      : data?.grid_size === 7
                      ? "7x7"
                      : data?.grid_size === 9
                      ? "9x9"
                      : "";
                  return (
                    <>
                      <TableRow>
                        <TableCell>
                          <div>{setDateFormat(data?.created_at)}</div>
                        </TableCell>
                        <TableCell
                          className="width-md"
                          onClick={() => showUseHistoryLocation(data)}
                        >
                          <FPToolTip
                            title={data?.location_name}
                            className="templateName"
                          >
                            {data?.location_name}
                          </FPToolTip>
                        </TableCell>

                        <TableCell>{data?.keyword}</TableCell>
                        <TableCell>{data?.distance}</TableCell>
                        <TableCell>{gridSize}</TableCell>
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell className="text-center" colSpan={10}>
                    No records Found{" "}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <PaginationComponent
            count={scan_history_data?.count}
            location={location}
            pageSizeCookie={"scan-history"}
          />
        </div>
      </CardWrapper>
    </div>
  );
};

// export default ScanHistory

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
    scan_history_data: state.listingGeoGridRanker.scan_history_data,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // filterAction: bindActionCreators(filterListActions, dispatch),
  // actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
  actions: bindActionCreators(listingGeoGridRankerAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScanHistory);
