import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as commonAction from "../common/commonAction";

export const WhatsAppListSuccess = (data) => {
  return { type: types.WHATS_APP_LIST_SUCCESS, data };
};

export const WhatsAppListFailure = (data) => {
  return { type: types.WHATS_APP_LIST_FAILURE, data };
};

export const WhatsAppListing = (business_id, page) => {
  return (dispatch) => {
    // dispatch(commonAction.showLoader());
    const url = `${utils.format(URLS.WHATSAPP_CONTACT_LIST, [
      business_id,
    ])}?page=${page}`;

    return axios_instance
      .get(url)
      .then((res) => {
        // dispatch(commonAction.hideLoader());
        dispatch(WhatsAppListSuccess(res.data));
        return res;
      })
      .catch((err) => {
        // dispatch(commonAction.hideLoader());
        dispatch(WhatsAppListFailure(err.response.data));
        throw err.response.data;
      });
  };
};

export const WhatsAppDetailsSuccess = (data) => {
  return { type: types.WHATSAPP_CONTACT_DETAILS_SUCCESS, data };
};

export const WhatsAppDetailsFailure = (data) => {
  return { type: types.WHATSAPP_CONTACT_DETAILS_FAILURE, data };
};

export const WhatAppDetails = (contact, business_id) => {
  return (dispatch) => {
    // dispatch(commonAction.showLoader());
    const url = `${utils.format(URLS.WHATSAPP_CONTACT_CON, [
      business_id,
    ])}?contact=${contact}`;
    return axios_instance
      .get(url)
      .then((res) => {
        // dispatch(commonAction.hideLoader());
        dispatch(WhatsAppDetailsSuccess(res?.data));
        return res;
      })
      .catch((err) => {
        // dispatch(commonAction.hideLoader());
        dispatch(WhatsAppDetailsFailure(err?.response?.data));
        throw err.response.data;
      });
  };
};
