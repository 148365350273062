import React from "react";
import { Dimmer } from "semantic-ui-react";
import { Backdrop } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    backdrop: {
      zIndex: theme.zIndex.tooltip + 1,
      color: "#fff",
    },
  };
});

const LoaderComponent = (props) => {
  const { loader, rounded, fullScreen, borderRadius, inline } = props;
  const classes = useStyles();
  return (
    <div>
      {inline ? (
        loader ? (
          // * make sure z-index higher then all above
          <Dimmer
            active
            className={`${fullScreen ? "vh-100" : ""} fp-loader`}
            style={{
              borderRadius: borderRadius ? borderRadius : rounded ? "12" : 0,
            }}
          >
            <span className="loader"></span>
          </Dimmer>
        ) : null
      ) : (
        <Backdrop open={loader} className={classes.backdrop}>
          <span className="loader"></span>
        </Backdrop>
      )}
    </div>
  );
};

export default LoaderComponent;
