import * as types from "../actionTypes";
import * as URLS from "../../libs/apiUrls";
import axios_instance from "../../libs/interseptor";
import * as utils from "../../libs/utils";
import * as commonAction from "../common/commonAction";

export const ReviewEmailSuccess = (data) => {
    return { type: types.REVIEW_EMAIL_LIST_SUCCESS, data };
  };
  
  export const ReviewEmailFailure = (data) => {
    return { type: types.REVIEW_EMAIL_LIST_FAILURE, data };
  };
  
  export const ReviewEmailListing = (data, business_id) => {
    const { ...params } = data;
    return (dispatch) => {
      dispatch(commonAction.showLoader());
      return axios_instance
        .get(utils.format(URLS.REVIEWS_EMAIL, [business_id]), { params })
        .then((res) => {
          dispatch(commonAction.hideLoader());
          dispatch(ReviewEmailSuccess(res.data));
          return res;
        })
        .catch((err) => {
          dispatch(commonAction.hideLoader());
          dispatch(ReviewEmailFailure(err.response.data));
          throw err.response.data;
        });
    };
  };

export const ReviewEmailDetailsSuccess = (data) => {
    return { type: types.REVIEW_EMAIL_DETAILS_SUCCESS, data };
  };
  
  export const ReviewEmailDetailsFailure = (data) => {
    return { type: types.REVIEW_EMAIL_DETAILS_FAILURE, data };
  };
  
  export const ReviewEmailDetails = (thread_id, business_id) => {
    return (dispatch) => {
      // dispatch(commonAction.showLoader());
    const url = `${utils.format(URLS.REVIEWS_EMAIL_DETAILS, [business_id])}?thread_id=${thread_id}`;
      return axios_instance
        .get(url)
        .then((res) => {
          // dispatch(commonAction.hideLoader());
          dispatch(ReviewEmailDetailsSuccess(res?.data));
          return res;
        })
        .catch((err) => {
          // dispatch(commonAction.hideLoader());
          dispatch(ReviewEmailDetailsFailure(err?.response?.data));
          throw err.response.data;
        });
    };
  };


  export const SendEmailSuccess = (data) => {
    return { type: types.SEND_EMAIL_SUCCESS, data };
  };
  
  export const SendEmailFailure = (data) => {
    return { type: types.SEND_EMAIL_FAILURE, data };
  };
  
  export const sendEmail = (formData, business_id) => {
    return (dispatch) => {
      // dispatch(commonAction.showLoader());
      const url = utils.format(URLS.REVIEWS_SEND_EMAIL, [business_id]);
      return axios_instance
        .post(url, formData)
        .then((res) => {
          // dispatch(commonAction.hideLoader());
          dispatch(SendEmailSuccess(res.data));
          return res;
        })
        .catch((err) => {
          // dispatch(commonAction.hideLoader());
          dispatch(SendEmailFailure(err?.response.data));
          throw err?.response?.data;
        });
    };
  };