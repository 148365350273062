import React from "react";
import { Segment, Checkbox, Label } from "semantic-ui-react";
import { Row, Col } from "reactstrap";
import queryString from "query-string";
import { Progress } from "reactstrap";
import dropDownArrow from "../../images/down-arrow.png";
import classNames from "classnames";

class TopicsKeywordLisitng extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: {},
      checkedKeyword: false,
      checkedPostiveAll: false,
      checkedNegativeAll: false,
    };
  }

  showMore = (item) => {
    const { expanded } = this.state;
    this.setState({
      expanded: {
        ...expanded,
        [item]: !expanded[item],
      },
    });
  };

  render() {
    const {
      nouns,
      adjectives,
      location: { search },
      nounClick,
      adjectiveClick,
      showPositive,
      showNegative,
      checked,
    } = this.props;
    const { expanded, checkedKeyword, checkedPostiveAll, checkedNegativeAll } =
      this.state;
    const sentiment = queryString.parse(search).sentiment || "All";
    const nounArray = Object.keys(nouns);
    const positiveAdjectives = Object.keys(adjectives.pos_adj || {});
    const negativeAdjectives = Object.keys(adjectives.neg_adj || {});
    const maxCount = {
      nouns: (Object.values(nouns)[0] || {}).count,
      adjectives: {
        positive: (Object.values(adjectives.pos_adj || {})[0] || {}).count,
        negative: (Object.values(adjectives.neg_adj || {})[0] || {}).count,
      },
    };
    let allKeyword = [];
    nouns &&
      nounArray.map((key) => {
        allKeyword.push(key);
      });
    let allPositive = [];
    adjectives &&
      adjectives.pos_adj &&
      positiveAdjectives &&
      positiveAdjectives.length &&
      positiveAdjectives.map((key) => {
        allPositive.push(key);
      });

    let allNegative = [];
    adjectives &&
      adjectives.neg_adj &&
      negativeAdjectives &&
      negativeAdjectives.length &&
      negativeAdjectives.map((key) => {
        allNegative.push(key);
      });

    return (
      <div className="mt-3 mb-3">
        <h6 className="font-gray">Filter By Keywords</h6>
        <div className="mb-4">
          <Segment className="nounContainer">
            {nouns && nounArray && nounArray.length > 0 && (
              <Checkbox
                name={"all"}
                label="Select All"
                checked={checkedKeyword}
                onClick={(e) => {
                  console.log(e);
                  nounClick(allKeyword, !checkedKeyword, true, nouns);
                  this.setState({ checkedKeyword: !checkedKeyword });
                }}
              />
            )}
            {nouns &&
              nounArray
                .slice(0, expanded.nouns ? nounArray.length : 10)
                .map((key) => {
                  const value = nouns[key];
                  return (
                    <div key={key}>
                      <Row className="segment-width">
                        <Col sm={5}>
                          <Checkbox
                            label={key}
                            name={key}
                            checked={checked.nouns.has(key)}
                            className="primary sr-label-capital"
                            id={key}
                            onClick={(e) =>
                              nounClick(e.target.name, e.target.checked, false, nouns)
                            }
                          />
                        </Col>
                        <Col sm={5}>
                          <Progress
                            style={{ marginTop: "6px", height: "6px" }}
                            value={value.count}
                            max={maxCount.nouns}
                            className="info"
                          />
                        </Col>
                        <Col sm={2} className="px-0 ml-0 font-blue">
                          {value.count}
                        </Col>
                      </Row>
                    </div>
                  );
                })}
          </Segment>
          {nounArray && nounArray.length > 10 ? (
            <div className="text-center">
              <button
                className="showMore_btn"
                onClick={() => {
                  this.showMore("nouns");
                }}
              >
                <span className="text-center">
                  {expanded.nouns ? "see less" : "see more"}
                  <img
                    alt="<"
                    src={dropDownArrow}
                    className={classNames("expand-icon", {
                      less: expanded.nouns,
                    })}
                  />
                </span>
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

        {showPositive && (
          <h6 className="font-gray">Filter By Positive Response</h6>
        )}
        {showPositive && adjectives && adjectives.pos_adj && (
          <div>
            <Segment className="nounContainer">
              {showPositive && adjectives && adjectives.pos_adj && positiveAdjectives.length > 0 && (
                <Checkbox
                  name={"positive"}
                  label="Select All"
                  checked={checkedPostiveAll}
                  onClick={(e) => {
                    console.log(e);
                    adjectiveClick(
                      allPositive,
                      !checkedPostiveAll,
                      "positive",
                      true
                    );
                    this.setState({ checkedPostiveAll: !checkedPostiveAll });
                  }}
                />
              )}

              {positiveAdjectives && positiveAdjectives.length
                ? positiveAdjectives
                    .slice(
                      0,
                      expanded.positive ? positiveAdjectives.length : 10
                    )
                    .map((key, index) => {
                      const value = adjectives.pos_adj[key];
                      return (
                        <div key={key}>
                          <Row className="segment-width">
                            <Col sm={5}>
                              <Checkbox
                                label={key}
                                value={key}
                                name={key}
                                checked={checked.adjectives.positive.has(key)}
                                className="primary sr-label-capital"
                                id={adjectives?.pos_adj[key]?.adj_id}
                                onClick={(e) =>
                                  adjectiveClick(
                                    key,
                                    !checked.adjectives.positive.has(key),
                                    "positive",
                                    false
                                  )
                                }
                              />
                            </Col>
                            <Col sm={5}>
                              <Progress
                                style={{ marginTop: "6px", height: "6px" }}
                                value={value.count}
                                max={maxCount.adjectives.positive}
                                className="success"
                              />
                            </Col>
                            <Col sm={2} className="px-0 ml-0 font-blue">
                              {value.count}
                            </Col>
                          </Row>
                        </div>
                      );
                    })
                : "No Positive adjectives"}
            </Segment>
            {positiveAdjectives && positiveAdjectives.length > 10 ? (
              <div className="text-center">
                <button
                  className="showMore_btn"
                  onClick={() => {
                    this.showMore("positive");
                  }}
                >
                  {expanded.positive ? "see less" : "see more"}
                  <img
                    alt="<"
                    src={dropDownArrow}
                    className={classNames("expand-icon", {
                      less: expanded.positive,
                    })}
                  />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {showNegative && (
          <h6 style={{ marginTop: "10px" }} className="font-gray">
            Filter By Negative Response
          </h6>
        )}
        {showNegative && adjectives && adjectives.neg_adj && (
          <div>
            <Segment className="nounContainer">
              {showNegative &&
                adjectives &&
                adjectives.neg_adj &&
                negativeAdjectives?.length > 0 && (
                  <Checkbox
                    name={"negative"}
                    label="Select All"
                    checked={checkedNegativeAll}
                    onClick={(e) => {
                      console.log(e);
                      adjectiveClick(
                        allNegative,
                        !checkedNegativeAll,
                        "negative",
                        true
                      );
                      this.setState({
                        checkedNegativeAll: !checkedNegativeAll,
                      });
                    }}
                  />
                )}

              {negativeAdjectives && negativeAdjectives.length
                ? negativeAdjectives
                    .slice(
                      0,
                      expanded.negative ? negativeAdjectives.length : 10
                    )
                    .map((key, index) => {
                      const value = adjectives.neg_adj[key];
                      return (
                        <div key={key}>
                          <Row className="segment-width">
                            <Col sm={5}>
                              <Checkbox
                                label={key}
                                value={key}
                                name={key}
                                checked={checked.adjectives.negative.has(key)}
                                className="primary sr-label-capital"
                                id={adjectives?.neg_adj[key]?.adj_id}
                                onClick={(e) => 
                                  adjectiveClick(
                                    key,
                                    !checked.adjectives.negative.has(key),
                                    "negative",
                                    false
                                  )
                                }
                              />
                            </Col>
                            <Col sm={5}>
                              <Progress
                                style={{ marginTop: "6px", height: "6px" }}
                                value={value.count}
                                max={maxCount.adjectives.negative}
                                className="danger"
                              />
                            </Col>
                            <Col sm={2} className="px-0 ml-0 font-blue">
                              {value.count}
                            </Col>
                          </Row>
                        </div>
                      );
                    })
                : "No Negative adjectives"}
            </Segment>
            {negativeAdjectives && negativeAdjectives.length > 10 ? (
              <div className="text-center">
                <button
                  className="showMore_btn"
                  onClick={() => {
                    this.showMore("negative");
                  }}
                >
                  {expanded.negative ? "see less" : "see more"}
                  <img
                    alt="<"
                    src={dropDownArrow}
                    className={classNames("expand-icon", {
                      less: expanded.negative,
                    })}
                  />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

export default TopicsKeywordLisitng;
