import React, { useState } from "react";
// import { displayMessage } from '../../../libs/utils';
import * as yup from "yup";
import { Formik, Form } from "formik";
import { FormCheckbox, FormInput } from "../../common/new/forms";
import { FPButton } from "../../common/new";
import { Link } from "react-router-dom";
import closeIcon from "../../../images/svg/x.svg";
import { Box } from "@material-ui/core";

const LoginForm = (props) => {
  const { handleSignIn } = props;
  const userAgent = navigator.userAgent.toLowerCase();
  console.log("userAgent.includes('safari')", userAgent.includes("safari"));
  // useEffect(() => {
  //   if (!isTermsChecked)
  //     displayMessage('negative', 'Please accept Terms & Conditions.');
  // }, [isTermsChecked]);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required()
      .label("Email"),
    password: yup.string().required("Enter Password").label("Password"),
    terms: yup.boolean().oneOf([true], "Select the checkbox"),
  });

  let initialValue = {
    email: "",
    password: "",
    terms: true,
  };

  const clearInput = (setFieldValue, email) => {
    setFieldValue(email, "");
  };

  const clearPassword = (setFieldValue, password) => {
    setFieldValue(password, "");
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValue}
        validationSchema={validationSchema}
        onSubmit={(value) => handleSignIn(value)}
      >
        {({ setFieldValue }) => (
          <Form>
            <FormInput
              name="email"
              placeholder="Email"
              size="large"
              type="email"
              autoComplete="on"
              loginEmail={true}
              endIcon={
                <Box
                  component="img"
                  src={closeIcon}
                  onClick={() => clearInput(setFieldValue, "email")}
                  style={{ cursor: "pointer" }}
                />
              }
            />
            <FormInput
              name="password"
              placeholder="Password"
              size="large"
              type="password"
              autoComplete="on"
              loginPass={true}
              endIcon={
                <Box
                  component="img"
                  src={closeIcon}
                  onClick={() => clearPassword(setFieldValue, "password")}
                  style={{ cursor: "pointer" }}
                />
              }
            />

            <Link
              className="d-block font-blue my-3 text-decoration-none text-right weight-500"
              font-blue
              to="forgot-password"
            >
              Forgot password
            </Link>
            <FormCheckbox
              name="terms"
              value={true}
              label={
                <div style={{ marginLeft: "5px", marginRight: "100px" }}>
                  <span className="font-gray-500">I agree to the&nbsp;</span>
                  <a
                    href="https://famepilot.com/famepilot-privacy-policy/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Terms and Conditions
                    <span style={{ color: "red", paddingLeft: "5px" }}>*</span>
                  </a>
                </div>
              }
            />
            <FPButton label="Submit" type="submit" className="w-100" />
          </Form>
        )}
      </Formik>
      <p className="font-gray-500 text-center mt-4">
        Don&apos;t have an account?&nbsp;
        <Link className="weight-500 font-primary" to="register">
          Sign Up
        </Link>
      </p>
    </React.Fragment>
  );
};

export default LoginForm;
