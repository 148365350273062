import React from "react";
import { getStaticPathString } from "../../../libs/utils";
import ReviewPageSummary from "./components/ReviewPageSummary";
import Logo from "../../../images/fp-logo-sm.png";
import { Link } from "react-router-dom";
import Header from "../../dashboard/Header";

const PageSummary = ({
  isSideBarOpen,
  history,
  location,
  business_id,
  defaultTab,
  handleRootTabChange,
  match,
  lockedModules,
}) => {
  const reviewPageName = getStaticPathString(2)?.toLowerCase();

  const renderSummary = () => {
    // if (reviewPageName === "reviews") {
    //   return <ReviewPageSummary />;
    // } else
    if (
      reviewPageName === "agency-brands" ||
      reviewPageName === "agency-staff-dashboard" ||
      location.pathname === "/admin" ||
      match.path === "/:agency_id/account-settings"
    ) {
      return (
        !isSideBarOpen && (
          <Link to={"/"}>
            <img src={Logo} height="" alt="famepilot logo" className="headerLogo" />
          </Link>
        )
      );
    } else {
      return (
        <Header
          history={history}
          location={location}
          business_id={business_id}
          defaultTab={defaultTab}
          handleRootTabChange={handleRootTabChange}
          lockedModules={lockedModules}
          match={match}
        />
      );
    }
  };

  return <div className="d-inline-block">{renderSummary()}</div>;
};

export default PageSummary;
