import React, { useEffect, useRef, memo, useState } from "react";
import {
  capitalize,
  format,
  getParams,
  setDecimalIndex,
} from "../../../../libs/utils";
import apiUrls from "../../../../libs/apiUrls";
import PropTypes from "prop-types";
import { pastelColorScale } from "../../../../libs/constant";
import Donut from "../../../../components/common/new/Donut";
import Chartwrapper from "../Chartwrapper";
import axios_instance from "../../../../libs/interseptor";
import { FPBadge } from "../../../../components/common/new";
import { Skeleton } from "@mui/material";

const ReviewPieChart = (props) => {
  const { businessId, loader, fetchData, data } = props;
  const [chartData, setChartData] = useState(null);
  const [totalReview, setTotalReview] = useState(0);
  const dateType = getParams(window.location.search, "date_type");
  const startDate = getParams(window.location.search, "start_date");
  const endDate = getParams(window.location.search, "end_date");
  const branch_tag = getParams(window.location.search, "branch_tag");

  const url = format(`${apiUrls.BUSINESS_REPORT}`, [businessId]);

  const queryParams = new URLSearchParams(props.search);
  const provider = queryParams.get("provider");
  const branch = queryParams.get("branch");
  const city = queryParams.get("city");
  const state = queryParams.get("state");
  const serviceType = queryParams.get("service_type");
  const user_filter = queryParams.get("team_department_member");

  useEffect(() => {
    const params = setParams(dateType, startDate, endDate, branch_tag);
    const fetch = async () => {
      await fetchData(params);
    };
    fetch();
  }, [
    dateType,
    branch_tag,
    provider,
    user_filter,
    branch,
    city,
    state,
    serviceType,
  ]); // eslint-disable-line

  const setParams = (date_type, start_date, end_date, branch_tag) => {
    if (dateType === "other") {
      return {
        date_type: date_type,
        start_date,
        end_date,
        branch_tag,
      };
    } else {
      return {
        date_type: date_type || "",
        branch_tag,
      };
    }
  };

  const getPercentage = (value, total) =>
    setDecimalIndex((100 * value) / total);

  useEffect(() => {
    let total = 0;
    const modifyData =
      (data &&
        Object.keys(data).map((provider) => {
          total = total + data[provider].review;
          setTotalReview(total);
          return {
            review: data[provider].review || 0,
            provider: provider,
          };
        })) ||
      [];
    const pieData = {
      labels:
        modifyData &&
        modifyData.map(({ provider }) =>
          capitalize(provider.replace(/_/g, " ")) // Remove underscores
        ),
      legends:
        modifyData &&
        modifyData.map(({ provider, review }) =>
          `${capitalize(provider.replace(/_/g, " "))} (${review})`
        ),
      datasets: [
        {
          data:
            modifyData?.length > 0
              ? modifyData.map(
                  ({ review }) => `${getPercentage(review, total)}`
                )
              : [],
          backgroundColor: pastelColorScale,
        },
      ],
    };

    if (pieData?.datasets[0]?.data?.length === 0) {
      setChartData(() => ({
        labels: ["No data"],
        legends: [],
        datasets: [{ data: [100], backgroundColor: ["#F6F6F8"] }],
      }));
      setTotalReview(0);
    } else {
      setChartData(() => pieData);
    }
  }, [data]);

  const updateData = (datePayload) => {
    fetchData(datePayload);
  };

  const chartRef = useRef(null);
  const legendRef = useRef(null);

  const [chartDimension, setChartDimension] = useState({ height: 0, width: 0 });
  const [legendDimension, setLegendDimension] = useState({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    setTimeout(() => {
      if (chartRef && chartRef.current) {
        const { offsetHeight, offsetWidth } = chartRef.current;
        setChartDimension({ height: offsetHeight, width: offsetWidth });
      }

      if (legendRef && legendRef.current) {
        const { clientHeight, clientWidth } = legendRef.current;
        setLegendDimension({ height: clientHeight, width: clientWidth });
      }
    });
  }, [chartRef, legendRef, chartData]);
  return (
    <section style={{ flexGrow: 1, }} ref={chartRef}>
      <Chartwrapper
        headerTitle="Total Reviews"
        className="h-100"
        onDateChange={updateData}
        widthElevation={true}
        descriptionStyle={{
          height: "90%",
        }}
        skeletonLoader={loader}
        // isForLifeTimeSupported={false}
      >
        {loader ? (
          <>
            <div className="w-100 mt-4">
              <Skeleton
                variant="circular"
                width={300}
                height={300}
                style={{ margin: "0 auto" }}
              />
              <div className="mt-5">
                <Skeleton width="100%" height={30} />
                <Skeleton width="60%" height={30} />
              </div>
            </div>
          </>
        ) : (
          <div
            className="d-flex flex-column justify-content-between w-100"
            style={{ height: "100%" }}
          >
            <div
              style={{  margin: "0 auto" }}
              className="totalReviewBox"
            >
              {chartData && (
                <Donut
                  key={`${chartDimension.height} - ${legendDimension.height} - ${totalReview}`}
                  isLoading={false}
                  // isLoading={isLoading}
                  cutout={105}
                  data={chartData}
                  width={300}
                  // width={chartDimension.width - 60}
                  centerText={{ text: totalReview, label: "Reviews" }}
                  height={300}
                  // height={chartDimension.height - legendDimension.height - 100}
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: totalReview > 0 ? true : false,
                        callbacks: {
                          label: (context) => {
                            return `${context.label} : ${context.formattedValue}%`;
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
            {!loader && (
              <div
                className="mb-2"
              >
                <div className="d-flex flex-wrap gap-5 " style={{gap: "8px"}} ref={legendRef}>
                  {chartData &&
                    chartData.legends &&
                    chartData.legends.map((item, index) => {
                      return (
                        <FPBadge
                          color={pastelColorScale[index]}
                          label={item}
                          textClass="font-blue-gray fp-size-13"
                          key={`${index}-review-legend`}
                        />
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        )}
      </Chartwrapper>
    </section>
  );
};
ReviewPieChart.propTypes = {
  businessId: PropTypes.string.isRequired,
};

export default memo(ReviewPieChart);
