import React, { useState } from "react";
import PropTypes from "prop-types";
import { TabContent, TabPane } from "reactstrap";
import { FPTab } from "../common/new/tabs";
import FPTextArea from "../common/new/forms/ui/FPTextArea";
import { AIResponse, Attachment, Emoji, Notebook } from "../../images/svg";
import FPButton from "../common/new/FPButton";
import SendIcon from "@material-ui/icons/Send";
import { Box, IconButton, Popover } from "@material-ui/core";
import EmojiPicker from "emoji-picker-react";
import { CloseRounded } from "@material-ui/icons";
import { Divider } from "antd";
import { FPToolTip } from "../common/new";

const ChatInputComponent = (props) => {
  const {
    messsageCallback,
    active_user,
    showDivider = true,
    textAreaRows = 5,
    sendButtonText = "send",
    placeholder,
    ws,
    business_id,
    selectedChat,
    className = "",
  } = props;
  const [textMessage, setTextMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [currentActiveTab, setCurrentActiveTab] = useState("1");
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);

  const handleClose = () => {
    setOpenEmojiPicker(null);
  };

  const open = Boolean(openEmojiPicker);

  const messageSent = (e) => {
    if (selectedFile === "") {
      setTextMessage(e.target.value);
    }
  };

  const handleEmojiSelect = ({ emoji }) => {
    setTextMessage((preValue) => preValue + " " + emoji);
  };

  //   const sendMessage = (e) => {
  //     if (e.key === "Enter") {
  //       e.preventDefault();
  //       const withoutLineBreaks = e.target.value.replace(/[\r\n\v]+/g, "");
  //       setTextMessage(withoutLineBreaks);
  //       handleSubmit(e);
  //     }
  //   };

  const handleSubmit = (e) => {
    e.preventDefault();
    let fileType = "text";

    if (
      textMessage != "" &&
      selectedFile === ""
    ) {
      if (ws && ws.readyState === WebSocket.OPEN && textMessage.trim() !== "") {
        const messageData = {
          type: "send_message",
          business_id,
          customer_number: selectedChat?.user_contact,
          user_contact: selectedChat?.contact_id,
          message: textMessage,
        };

        ws.send(JSON.stringify(messageData));  // Send message through WebSocket
      }
      else {
        console.error("WebSocket is not connected or message is empty.");
      }

      setTextMessage("");
    } else if (selectedFile != "" && textMessage === "") {
      if (selectedFile.type.split("/")[0] === "application") {
        fileType = "file";
      } else {
        fileType = selectedFile.type.split("/")[0];
      }
      let data = {
        msg_type: fileType,
        attached_file: selectedFile,
        message: textMessage,
        file_type: selectedFile.type,
        name: selectedFileName,
      };
      messsageCallback(data);
      setSelectedFile("");
      setSelectedFileName("");
    }
  };

  const attchFileSent = (e) => {
    if (textMessage === "") {
      setSelectedFile(e.target.files[0]);
      setSelectedFileName(e.target.files[0].name);
    }
  };

  function removeSelectFile() {
    setSelectedFile("");
    setSelectedFileName("");
  }

  function toggleTabb(tab) {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }

  return (
    <div>
      {showDivider && <Divider className="my-1" />}
      {selectedFileName != "" ? (
        <div className="position-relative">
          <Box
            sx={{
              position: "absolute",
              top: "-58px",
              backgroundColor: "#FDF2F7",
              color: "#e12369",
              padding: "5px 15px",
              borderRadius: "10px",
              border: "1px solid #e12369",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 10,
            }}
          >
            <p className="m-0">{selectedFileName}</p>
            <IconButton onClick={removeSelectFile} size="small">
              <CloseRounded
                style={{ height: 18, width: 18, color: "#e12369" }}
              />
            </IconButton>
          </Box>
        </div>
      ) : null}

      <FPTab
        tabLabels={["Direct Message", "Internal Note"]}
        minTabWidth={220}
        defaultValue={0}
        theme="secondary"
        onChange={(value) => toggleTabb((value + 1).toString())}
      />

      <div className="mt-4">
        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId="1">
            <FPTextArea
              autoHeight
              placeholder={placeholder ? placeholder : "Enter a Comment"}
              className={`massager-chatbot chat-input-container ${className}`}
              sx={{
                background: "#f6f6f8 !important",
                border: "none",
                borderRadius: "20px !important",
                padding: "9px 15px",
              }}
              value={textMessage}
              onChange={messageSent}
              // onKeyUp={sendMessage}
              disabled={selectedFileName !== ""}
              minRows={textAreaRows}
            />
            <div className="d-flex justify-content-between align-items-center mt-2">
              <div className="d-flex align-items-center gap-6 ml-2">
                {/* // TODO : manage action onclick */}
                <IconButton
                  onClick={() => { }}
                  className="font-secondary"
                  size="small"
                >
                  <FPToolTip title={"Template Base Reply"}>
                    <Notebook style={{ margin: 5 }} />
                  </FPToolTip>
                </IconButton>
                <IconButton
                  onClick={() => { }}
                  className="font-secondary"
                  size="small"
                >
                  <FPToolTip title={"AI Reply"}>
                    <AIResponse />
                  </FPToolTip>
                </IconButton>
                <label htmlFor="attchInputFile" className="mb-0">
                  <IconButton
                    component="span"
                    onClick={() => { }}
                    className="font-secondary"
                    size="small"
                  >
                    <FPToolTip title={"Attachment"}>
                      <Attachment />
                    </FPToolTip>
                    <input
                      style={{ display: "none" }}
                      id="attchInputFile"
                      type="file"
                      onChange={attchFileSent}
                    />
                  </IconButton>
                </label>
                <IconButton
                  onClick={(event) => setOpenEmojiPicker(event)}
                  className="font-secondary"
                  size="small"
                >
                  <FPToolTip title={"Emoji"}>
                    <Emoji />
                  </FPToolTip>
                </IconButton>
              </div>
              <FPButton
                onClick={(e) => handleSubmit(e)}
                endIcon={<SendIcon />}
                size="small"
              >
                {sendButtonText}
              </FPButton>
            </div>
          </TabPane>

          <TabPane tabId="2">
            <div className=""
            //  style={{ height: "16vh" }}
            >
              {active_user.remark ? <h5>{active_user.remark_note}</h5> : null}
            </div>
          </TabPane>
        </TabContent>

        <Popover
          open={open}
          anchorEl={openEmojiPicker}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <EmojiPicker
            skinTonesDisabled={true}
            width={300}
            size={10}
            lazyLoad={true}
            lazyLoadEmojis={true}
            onEmojiClick={handleEmojiSelect}
          />
        </Popover>
      </div>
    </div>
  );
};

ChatInputComponent.propTypes = {
  messsageCallback: PropTypes.func.isRequired,
  active_user: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default ChatInputComponent;
