import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';

// Register required Chart.js components for v5
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
);

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const COLORS = {
  LEAST_BUSY: "#EDE7F6",
  LITTLE_BUSY: "#D1C4E9",
  MODERATELY_BUSY: "#B39DDB",
  VERY_BUSY: "#7E57C2",
  BUSIEST: "#5E35B1",
};

const nameMapping = {
  LEAST_BUSY: 'Least Busy',
  LITTLE_BUSY: 'Little Busy',
  MODERATELY_BUSY: 'Moderately Busy',
  VERY_BUSY: 'Very Busy',
  BUSIEST: 'Busiest'
};

const colors = [
  COLORS.LEAST_BUSY,
  COLORS.LITTLE_BUSY,
  COLORS.MODERATELY_BUSY,
  COLORS.VERY_BUSY,
  COLORS.BUSIEST
];

export default function DotChart({ listing_popular_times }) {
  const hours = Array.from(
    { length: 24 },
    (_, i) => `${i % 12 || 12}${i < 12 ? "AM" : "PM"}`
  );

  const getBusynessLevel = (value, min, max) => {
    if (value === 0 || min === max) return 0;
    const range = max - min;
    const level = Math.floor(((value - min) / range) * 5);
    return Math.min(Math.max(level, 0), 4);
  };

  const generateData = (day_hours = {}) => {
    if (!day_hours || Object.keys(day_hours).length === 0) {
      return days.flatMap((_, dayIndex) =>
        Array.from({ length: 24 }, (_, hourIndex) => ({
          x: hourIndex,
          y: dayIndex,
          value: 0
        }))
      );
    }

    const allValues = Object.values(day_hours)
      .flat()
      .filter(val => val !== undefined && val !== null);

    if (allValues.length === 0) {
      return days.flatMap((_, dayIndex) =>
        Array.from({ length: 24 }, (_, hourIndex) => ({
          x: hourIndex,
          y: dayIndex,
          value: 0
        }))
      );
    }

    const min = Math.min(...allValues);
    const max = Math.max(...allValues);

    return days.flatMap((day, dayIndex) => {
      const hoursData = day_hours[day] || Array(24).fill(0);
      return hoursData.map((hour, hourIndex) => ({
        x: hourIndex,
        y: dayIndex,
        value: getBusynessLevel(hour || 0, min, max)
      }));
    });
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: true,
      mode: 'point'
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        min: -0.5,
        // max: 23.5,
        // offset: true, // Add this
        // border: {
        //   display: true // Add this
        // },
        ticks: {
          stepSize: 4,
          callback: (value) => hours[value] || '',
          padding: 10,
          color: '#666',
          font: {
            size: 12,
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
          },
          // autoSkip: false, // Add this
          // display: true // Add this
        },
        grid: {
          display: false,
          drawBorder: true // Change this to true
        }
      },
      y: {
        type: 'linear',
        min: -0.5,
        // max: 6.5,
        // offset: true, // Add this
        // border: {
        //   display: true // Add this
        // },
        ticks: {
          stepSize: 1,
          callback: (value) => days[value] || '',
          padding: 10,
          color: '#666',
          font: {
            size: 12,
            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
          },
          // autoSkip: false, // Add this
          // display: true // Add this
        },
        grid: {
          display: false,
          // drawBorder: true // Change this to true
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: (context) => {
            const { raw } = context;
            if (!raw) return '';
            
            const busyLevels = [
              'Least Busy',
              'Little Busy',
              'Moderately Busy',
              'Very Busy',
              'Busiest'
            ];
            const dayIndex = raw.y;
            const hourIndex = raw.x;
            const busyLevel = busyLevels[raw.value];

            return `${busyLevel} (${hours[hourIndex]} on ${days[dayIndex]})`;
          }
        }
      },
      legend: {
        display: false
      }
    }
  };


  const data = {
    datasets: [
      {
        data: generateData(listing_popular_times?.day_hours),
        backgroundColor: (context) => {
          const value = context?.raw?.value ?? 0;
          return colors[value] || colors[0];
        },
        pointRadius: 14,
        hoverRadius: 18,
        borderWidth: 0
      }
    ]
  };

  return (
    <div style={{ 
      width: '100%', 
      height: '500px',
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
      boxSizing: 'border-box'
    }}>
      <div style={{ flex: 1, minHeight: 0, marginBottom: '20px' }}>
        <Scatter options={options} data={data} />
      </div>
      <div style={{ 
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        gap: '16px',
        padding: '10px 0'
      }}>
        {Object.entries(nameMapping).map(([key, name]) => (
          <div 
            key={key} 
            style={{ 
              display: 'flex',
              alignItems: 'center',
              marginRight: '15px'
            }}
          >
            <span style={{
              width: '12px',
              height: '12px',
              backgroundColor: COLORS[key],
              borderRadius: '50%',
              display: 'inline-block',
              marginRight: '8px'
            }} />
            <span style={{
              fontSize: '14px',
              color: '#666',
              whiteSpace: 'nowrap'
            }}>
              {name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
