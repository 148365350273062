import React from 'react';
import { Row, Col } from 'reactstrap';
import XIcon from '../../images/X.jpg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as URLS from '../../libs/apiUrls';

import * as SocialSetupActions from '../../actions/business/socialSetupActions';
import axios_instance from '../../libs/interseptor';
import { withRouter } from 'react-router';
import LoaderComponent from '../common/loaderComponent';
import cookie from 'react-cookies';

class SetupTwitterAccount extends React.Component {

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.business_id !== prevState.business_id) {
      return { business_id: nextProps.business_id };
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = {
      business_id: this.props.business_id,
      btnText: 'Connect to X',
      name: '',
      connected: false,
      loader: false,
      xData: localStorage.getItem('x_data'),
    };
  }

  componentDidMount() {
    // this.props.business!==undefined && this.checkLoggedIn();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.business_id !== this.state.business_id) {
      // this.checkLoggedIn();
    }
    if (JSON.stringify(prevState.xData) !== JSON.stringify(this.state.xData)) {
      console.log('changing...');
      this.connectSocial();
    }
  }

  twitterConnect(data){
    console.log(data);
    let user = cookie.load('user');
    const details = {
      'code' : data?.code,
      'state' : data.state,
      'user_id' : user.id,
      'business_id': this.state.business_id
    };
    if (details.code)
    {
    return axios_instance.post(`${URLS.SOCIAL_PROFILE_INTEGRATION}twitter/setup/`,details)
      .then(res=>{
        this.props.callSocialProfileApi();
        this.props.handleConnectProfile();
        console.log(res);
      })
      .catch(err=>{
        console.log(err);
        this.setState({
          loader: false
        });
      });
    }
  }

  connectSocial(){
    let  { xData } = this.state;
    console.log(xData);
    this.twitterConnect(xData);
  }

  getAuthUrl(data) {
    this.setState({ loader: true });
    this.props.SocialSetupActions.getAuthUrl({ provider: data })
      .then(() => {
        const { socialSetupList } = this.props;
        if(socialSetupList.x_status) {
            const authUrl = socialSetupList.auth_url;
            this.newWindow = window.open(authUrl, 'twitterOpener', 'toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400');
            this.setState({provider: data, loader: false});
            localStorage.removeItem('x_data');
            const interval = setInterval(() => {
              console.log('Waiting for message...');
              let messagess = localStorage.getItem('x_data');
              if (messagess) {
                this.setState({
                  xData: JSON.parse(messagess)
                });
    
                clearInterval(interval);
              }
            }, 5000);
        
            // Clear the interval after a certain time to avoid infinite logging
            setTimeout(() => clearInterval(interval), 300*1000);
          } 
        }).catch(()=>{
          this.setState({ loader: false });
      });
  }

  logOut() {
    const { match: { params: { business_id } } } = this.props;
    this.setState({
      btnText: 'Connect to X'
    });
    axios_instance.get(`${URLS.MESSENGER}twitter-logout/?business_id=${business_id}`)
      .then(()=>{
        // this.checkLoggedIn();
      })
      .catch((err)=>{
        this.setState({
          loader: false
        });
        console.log(err);
      });
  }

  render() {
    const { connectedStatus, toggleTab } = this.props;
    const cssStyle = { display: 'flex', gap: '10px', borderRadius: '25px', justifyContent: 'center', alignItems: 'center', background: 'black', cursor: 'pointer', textAlign: 'center'};
    return (
      <div className=''>
        <LoaderComponent loader={this.state.loader}/>
        <div >
          <Row>
            <Col>
              <div className="text-center mb-2">
                <span className="google-button" style={cssStyle} onClick={() => this.getAuthUrl('twitter')} >
                  <div>
                    <img src={XIcon} alt='X Icon' height="25" />
                  </div>
                  <span>Connect to X</span>
                </span>
                <div className='mt-2' onClick={toggleTab} style={{ textAlign: 'center', fontWeight: '600', textDecoration: 'underline', fontSize: '14px', cursor: 'pointer' }}>
                {connectedStatus ? connectedStatus : this.state.connected ? 1 : 0} profile already connected
              </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
    socialSetupList: state.socialSetup.list,
    isLoggedIn: state.socialSetup.isLoggedIn
  };
};

function mapDispatchToProps(dispatch) {
  return {
    SocialSetupActions: bindActionCreators(SocialSetupActions, dispatch),
  };
}

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(SetupTwitterAccount));
