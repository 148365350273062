import * as types from '../../actions/actionTypes';

export default function paymentsReducer(state=[],action){
  switch(action.type){
    case types.GET_INVOICE_LIST_SUCCESS:
      return Object.assign({},state, { invoices: { ...action.data } });
    case types.GET_INVOICE_LIST_FAILURE:
      return Object.assign({},state,{ errors: { invoices: { ...action.data } }});
    case types.GET_INVOICE_OPTIONS_SUCCESS:
      return Object.assign({},state, { invoice_options: { ...action.data } });
    case types.GET_INVOICE_OPTIONS_FAILURE:
      return Object.assign({},state,{ errors: { invoice_options: { ...action.data } }});
    default:
      return state;
  }
}