import React from "react";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { currency_symbols } from "../../libs/utils";
import { FPToolTip } from "../common/new";

const InvoiceList = (props) => {
  const { invoices, payNow, downloadInvoice } = props;
  return (
    <div className="ui-table-responsive">
      <Table className="fp-table fp-table-center">
        <TableHead>
          <TableRow>
            <TableCell>Invoice No</TableCell>
            <TableCell>Invoice Date</TableCell>
            <TableCell>Invoice Amount</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Payment Date</TableCell>
            <TableCell>Paid Amount</TableCell>
            <TableCell>Remarks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoices &&
            invoices.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell style={{ whiteSpace: "normal" }}>
                    <span
                      onClick={() => downloadInvoice(item.invoice_id)}
                      className="cursor-pointer"
                    >
                      {item.invoice_id}
                    </span>
                  </TableCell>
                  <TableCell>
                    {moment(item.invoice_created).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>
                    {currency_symbols[item.invoice_amount_currency] +
                      item.invoice_amount}
                  </TableCell>
                  <TableCell>
                    {moment(item.due_date).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>{item.payment_status}</TableCell>
                  <TableCell>
                    {!(
                      item.payment_status === "success" ||
                      item.payment_status === "payment_deducted"
                    ) ? (
                      <span
                        className="pay-now-txt"
                        onClick={() => payNow(item.id)}
                      >
                        Pay Now
                      </span>
                    ) : (
                      "Paid"
                    )}
                  </TableCell>
                  <TableCell>
                    {item.payment_date &&
                      moment(item.payment_date).format("DD-MM-YYYY")}
                  </TableCell>
                  <TableCell>
                    {item.payment_amount &&
                      currency_symbols[item.invoice_amount_currency] +
                        item.payment_amount}
                  </TableCell>
                  <TableCell style={{ maxWidth: "15rem", margin: "0px auto" }}>
                    <span className="two-line">{item.remarks}</span>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </div>
  );
};

export default InvoiceList;
