import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as StaffActions from "../../actions/business/staffActions";
import LoaderComponent from "../common/loaderComponent";
import * as utils from "../../libs/utils";
import UploadFileModal from "../common/new/UploadFileModal";

class UploadStaffModal extends React.Component {
  constructor(props) {
    super(props);
    this.uploadStaffFile = this.uploadStaffFile.bind(this);
    this.state = {
      showMessage: false,
      message: null,
      messageType: null,
      isLoading: false,
    };
  }

  uploadStaffFile(data) {
    const {
      history,
      location: { pathname },
    } = this.props;
    const file = data.target.files[0];
    this.setState({ showMessage: false, isLoading: true });
    const fileExt = file && file.name.split(".").pop();
    const excelExt = ["xls", "xlsx", "xlsm", "csv"];
    if (file.size > 2100000) {
      utils.displayMessage("negative", "File cannot be larger than 2mb.");
      this.setState({
        isLoading: false,
      });
    } else {
      if (excelExt.indexOf(fileExt) > -1) {
        const newDetail = new FormData();
        newDetail.append("file", file);
        return this.props.actions
          .staffCsvUpload(newDetail)
          .then((res) => {
            utils.displayMessage("positive", res.data.message);
            this.setState({ isLoading: false });
            history.push({
              pathname,
              search: "?page=1&page_size=10",
              state: { tab: "9" },
            });
            this.props.toggle();
            // this.context.router.history.push({pathname: 'central-setup',state: { tab: '9'} });
          })
          .catch((err) => {
            utils.displayMessage("negative", err.excel_file);
            this.setState({ isLoading: false });
          });
      } else {
        document.getElementById("file").value = null;
        utils.displayMessage(
          "info",
          "Invalid file type! Allowed extensions are .xls, .xlsx, .csv, .xlsm."
        );
        this.setState({
          isLoading: false,
        });
      }
    }
    return true;
  }

  render() {
    const { isLoading } = this.state;
    const { isOpen, toggle } = this.props;
    return (
      <div>
        <LoaderComponent loader={isLoading}></LoaderComponent>
        <UploadFileModal
          isOpen={isOpen}
          toggle={toggle}
          heading={"Upload Staff"}
          uploadExcel={this.uploadStaffFile}
          downloadExcel={() => this.props.actions.downloadStaffTemplate()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
  };
};

UploadStaffModal.propTypes = {
  actions: PropTypes.instanceOf(Object),
  business: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(StaffActions, dispatch),
  };
}

UploadStaffModal.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(UploadStaffModal);
