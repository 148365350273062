import React from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col } from 'reactstrap';
import { Button, Form,  } from 'semantic-ui-react';
import {InputField, SelectField } from 'react-semantic-redux-form';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import ImageUpload from '../common/ImageUpload';

const validate = (values) => {// eslint-disable-line
  const errors = {};
  return errors;
};

class MarketingForm  extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = { file: null, fileUrl: null };
  }

  submit(data) {
    const { addOrEditMarketing } = this.props;
    const { file } = this.state;
    const userData = new FormData();
    userData.append('name', data.name);
    userData.append('category', data.category);
    userData.append('logo', file);
    return addOrEditMarketing(userData, data.id)
      .catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  }

  handleFileSelect(e){
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => this.setState({ file, fileUrl: reader.result });
    if (file) reader.readAsDataURL(file);
    return true;
  }

  render() {
    const { submitting, handleSubmit, errors, categoryList } = this.props;

    return (
      <Form className="grid-form mt-2" onSubmit={handleSubmit(this.submit)}>
        { errors && errors.non_field_errors
          ? errors.non_field_errors.map (
            (error, index) => (<p key={index} className="text-danger">{error}</p>)
          )
          : null
        }
        <Row>
          <Col sm="12" className="mt-1">
            <Card body>
              <Field
                name='name'
                component={InputField}
                placeholder='Name'
                type='text'
                label='Name'
              />
              <Field
                name="category"
                type="text"
                component={SelectField}
                label="Category"
                placeholder="Category"
                options={categoryList}
              />
              <Field
                id="logo"
                name="logo"
                component={ImageUpload}
                label="Logo"
                onChange={(e) => this.handleFileSelect(e)}
              />
              <div className="mt-3 text-right">
                <Form.Field control={Button} color='teal' type='submit' disabled={submitting}>
                  Save
                </Form.Field>
              </div>
            </Card>
          </Col>
        </Row>
      </Form>
    );
  }
}

MarketingForm =  reduxForm({// eslint-disable-line
  form: 'MarketingForm',
  validate,
  enableReinitialize:true,
})(MarketingForm);

MarketingForm = connect((state) => {// eslint-disable-line
  return {};
})(MarketingForm);

export default MarketingForm;
