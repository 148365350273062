import React, { forwardRef } from "react";
import { Card } from "reactstrap";
import "./listing.css";
import success_svg from "../../../images/success.svg";
import { CircularProgressbar } from "react-circular-progressbar";
import { Box, Dialog, DialogActions, IconButton, Slide } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import UpdateQuestion from "./UpdateQuestion";
import UpdateAttribute from "./UpdateAttribute";
import UpdateDescription from "./UpdateDescription";
import UpdateService from "./UpdateService";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ServiceQuestion = ({
  primaryDetail,
  basic_detail_data,
  attribute_data,
  business_id,
  selectReso,
}) => {
  const [open, setOpen] = React.useState({
    businessService: false,
    businessquestion: false,
    attribute: false,
    description: false,
  });
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (dialog, scrollType) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: true,
    }));
    setScroll(scrollType);
  };

  const handleClose = (dialog) => {
    setOpen((prevState) => ({
      ...prevState,
      [dialog]: false,
    }));
  };
  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };

  return (
    <div
      className="row detail-card-container"
      style={{ display: "flex", flexWrap: "wrap" }}
    >
      {primaryDetail?.canModifyServiceList ? (
        <div
          className="select-box-hover col-md-6 col-lg-3  p-1"
          onClick={() => handleClickOpen("businessService", "paper")}
        >
          <Card className="detail-card-item detail-special-card cursor-pointer ">
            <div className="detail-card-content">
              {/* <CardHeader className="card-heading"></CardHeader> */}
              <div style={{ width: "28px" }}>
                {basic_detail_data?.services === true ? (
                  <img
                    src={success_svg}
                    height="28px"
                    alt=""
                    className="successImg"
                  />
                ) : (
                  <CircularProgressbar value={0} text={`${0}%`} />
                )}
              </div>
              <span style={{ fontWeight: "500", fontSize: "13px" }}>
                Add Business Services
              </span>
              <span className="detail-business-card-review">
                Add service & service descriptions to rank better
              </span>
            </div>
          </Card>
        </div>
      ) : (
        ""
      )}
      <Dialog
        open={open.businessService}
        onClose={() => handleClose("businessService")}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height: "90vh",
            width: "100%",
          },
        }} // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
      >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "24px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Business Service</span>
              <CloseIcon onClick={() => handleClose("businessService")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("businessService")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdateService
              business_id={business_id}
              selectReso={selectReso}
              primaryDetail={primaryDetail}
              handleClose={handleClose}
            />
          </div>
        </Box>
      </Dialog>
      <div
        className="select-box-hover col-md-6 col-lg-3  p-1"
        onClick={() => handleClickOpen("businessquestion", "paper")}
      >
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.faq === true ? (
                <img
                  src={success_svg}
                  height="28px"
                  alt=""
                  className="successImg"
                />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>
              Answer Business FAQ’s
            </span>
            <span className="detail-business-card-review">
              Add FAQ’s & answer common questions about your business
            </span>
          </div>
        </Card>
      </div>
      <Dialog
        open={open.businessquestion}
        onClose={() => handleClose("businessquestion")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height: "90vh",
            width: "100%",
          },
        }} // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
      >
        <Box sx={style}>
          <div style={{ padding: "15px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Business Q&A’s</span>
              <CloseIcon onClick={() => handleClose("businessquestion")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("businessquestion")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdateQuestion
              primaryDetail={primaryDetail}
              business_id={business_id}
              selectReso={selectReso}
            />
          </div>
        </Box>
      </Dialog>

      <div
        className="select-box-hover col-md-6 col-lg-3 p-1"
        onClick={() => handleClickOpen("attribute", "paper")}
      >
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.attributes === true ? (
                <img
                  src={success_svg}
                  height="28px"
                  alt=""
                  className="successImg"
                />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>
              Listing Attributes
            </span>
            <span className="detail-business-card-review">
              Add details such as parking, amenities, payment methods, etc.
            </span>
          </div>
        </Card>
      </div>
      <Dialog
        open={open.attribute}
        onClose={() => handleClose("attribute")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height: "90vh",
            width: "100%",
          },
        }} // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
      >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Attributes</span>
              <CloseIcon onClick={() => handleClose("attribute")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("attribute")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdateAttribute
              business_id={business_id}
              selectReso={selectReso}
              primaryDetail={primaryDetail}
            />
          </div>
        </Box>
      </Dialog>

      <div
        className="select-box-hover col-md-6 col-lg-3   p-1"
        onClick={() => handleClickOpen("description", "paper")}
      >
        <Card className="detail-card-item detail-special-card cursor-pointer ">
          <div className="detail-card-content">
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div style={{ width: "28px" }}>
              {basic_detail_data?.description === true ? (
                <img
                  src={success_svg}
                  height="28px"
                  alt=""
                  className="successImg"
                />
              ) : (
                <CircularProgressbar value={0} text={`${0}%`} />
              )}
            </div>
            <span style={{ fontWeight: "500", fontSize: "13px" }}>
              Description
            </span>
            <span className="detail-business-card-review">
              Description with{" "}
              {primaryDetail?.description
                ? primaryDetail?.description?.length
                : ""}{" "}
              characters added
            </span>
          </div>
        </Card>
      </div>
      <Dialog
        open={open.description}
        onClose={() => handleClose("description")}
        // scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="lg"
        fullWidth
        TransitionComponent={Transition}
        sx={{
          "& .MuiDialog-paper": {
            position: "fixed",
            // right: 0,
            // margin: 0,
            maxHeight: "100vh",
            height: "90vh",
            width: "100%",
          },
        }} // sx={{
        //   "& .MuiDialog-paper": {
        //     overflowY: "hidden",
        //   },
        // }}
      >
        <Box sx={style}>
          <div style={{ padding: "20px" }}>
            <div
              className="d-flex mb-10"
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "600",
                fontSize: "20px",
                color: "#1A2544",
                alignItems: "center",
              }}
            >
              <span>Update Descriptions</span>
              <CloseIcon onClick={() => handleClose("description")} />
              {/* <DialogActions>
                <IconButton onClick={() => handleClose("description")}>
                  <CloseIcon />
                </IconButton>
              </DialogActions> */}
            </div>
            <UpdateDescription
              primaryDetail={primaryDetail}
              business_id={business_id}
              selectReso={selectReso}
            />
          </div>
        </Box>
      </Dialog>
    </div>
  );
};

export default ServiceQuestion;
