import React, { useState } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { FPBadge } from "../common/new";
import Chartwrapper from "../../modules/dashboard/component/Chartwrapper";
import { getNPSSummary } from "../../actions/overview/OverviewActions";
import { connect } from "react-redux";
import LoaderComponent from "../common/loaderComponent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Divider } from "semantic-ui-react";
import { Skeleton } from "@mui/material";

const NetScoreCard = (props) => {
  const { data, dispatch, businessId, loading } = props;
  const [isLoading, setIsLoading] = useState(loading);
  const matches = useMediaQuery("(max-width: 425px)");

  const updateData = async (datePayload) => {
    setIsLoading(() => true);
    await dispatch(getNPSSummary(businessId, datePayload));
    setIsLoading(() => false);
  };

  return (
    <Chartwrapper
      headerTitle="Net Promoter Score"
      className="h-100"
      onDateChange={updateData}
      widthElevation={true}
      skeletonLoader={loading}
      redirectionLink={`/${businessId}/reports/review-section/nps/overtime/table?date_type=prior_30_day&list=date`}
    >
      {loading ? (
        <>
          <div className="nps-skeleton" style={{ padding: "10px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                transform: "translate(0)",
              }}
            >
              <Skeleton width="40%" height={25} />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Skeleton
                width={"80%"}
                height={150}
                style={{ transform: "translate(0)" }}
              />
            </div>
            <Divider className="mt-4" />
            {/* Skeleton for Divider/Stat Text */}
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <Skeleton
                width="50%"
                height={30}
                style={{ margin: "0 auto", transform: "translate(0)" }}
              />
            </div>

            {/* Skeleton for NPS Formula */}
            <div className="">
              <Skeleton
                width="70%"
                height={30}
                style={{ margin: "0 auto", transform: "translate(0)" }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="text-center nps-stats">
          <div className="d-flex gap-20 mb-2 justify-content-end">
            <FPBadge label="Poor" textClass="trending-header" color="#ff4545" />
            <FPBadge
              label="Average"
              textClass="trending-header"
              color="#ffe234"
            />
            <FPBadge
              label="Excellent"
              textClass="trending-header"
              color="#57e32c"
            />
          </div>

          <div className="chart mt-4 fp-gaudge-chart">
            {data && (
              <ReactSpeedometer
                height={matches ? 150 : 200}
                width={matches ? 250 : 350}
                minValue={-100}
                maxValue={100}
                value={data.nps}
                needleColor="#1A2544"
                needleHeightRatio={0.6}
                needleTransitionDuration={1000}
                segments={10}
                maxSegmentLabels={10}
                segmentColors={[
                  "#FF3400",
                  "#FF6B01",
                  "#FE7F02",
                  "#FFA802",
                  "#FFD205",
                  "#FCFD09",
                  "#EEFC0A",
                  "#DBFC05",
                  "#B6FB1F",
                  "#7BFA0D",
                ]}
                textColor="#1A2544"
                valueTextFontSize="25px"
                valueTextFontWeight="700"
              />
            )}
          </div>
          <Divider className="mt-4" />
          {data && (
            <div
              className="nps-formula"
              style={{
                fontSize: "16px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ width: "20%" }}>
                <span className="nps-promoter" style={{ color: "#81E050" }}>
                  {data.promoter}%{" "}
                </span>
              </div>
              <span
                style={{
                  color: "var(--fp-secondary-font-blue)",
                  fontWeight: 900,
                }}
              >
                {" "}
                -{" "}
              </span>

              <div style={{ width: "20%" }}>
                <span className="nps-detractor" style={{ color: "#EC554E" }}>
                  {data.detractor}%{" "}
                </span>
              </div>
              <span
                style={{
                  color: "var(--fp-secondary-font-blue)",
                  fontWeight: 900,
                }}
              >
                {" "}
                ={" "}
              </span>
              <div style={{ width: "20%" }}>
                <span className="nps-nps" style={{ color: "#259BFF" }}>
                  {data.nps}%
                </span>
              </div>
            </div>
          )}

          <div
            className="nps-formula nps-Text px-3 py-2"
            style={{
              color: "var(--fp-secondary-font-blue)",
            }}
          >
            <span
              style={{
                color: "#81E050",
                fontWeight: 500,
                marginRight: "14px",
                fontSize: "16px",
              }}
            >
              % Promoters
            </span>
            <span style={{ fontWeight: 800, marginRight: "13px" }}>-</span>
            <span
              style={{
                color: "#EC554E",
                fontWeight: 500,
                marginRight: "14px",
                fontSize: "16px",
              }}
            >
              % Detractor
            </span>
            <span style={{ fontWeight: 800, marginRight: "13px" }}>=</span>
            <span
              style={{
                color: "#259BFF",
                fontWeight: 500,
                marginRight: "12px",
                fontSize: "16px",
              }}
            >
              % Net Promoters
            </span>
          </div>
        </div>
      )}
    </Chartwrapper>
  );
};

export default connect()(NetScoreCard);
