/* eslint-disable */
import React, { useEffect, useState } from "react";
import queryString from "query-string";
import CardWrapper from "../dashboard/component/CardWrapper";
import SearchComponent from "../../components/common/SearchComponent";
import { FPMenuBox, FPToolTip } from "../../components/common/new";
import { IconButton } from "@material-ui/core";
import { CustomerCare } from "../../images/svg";
import { Radio } from "semantic-ui-react";
import { getParams } from "../../libs/utils";
import tagIcon from "../../images/svg/reviewTag.svg";
import { Collapse } from "reactstrap";
import ReviewPageSummary from "../navbar/pagesummary/components/ReviewPageSummary";
import UpArrow from "../../images/svg/upArrow.svg";
import AutoSelect from "../../components/common/AutoSelect";
import { ReactComponent as DropdownIcon } from "../../images/svg/dropdownUpIcon.svg";

const ReviewActionBar = ({
  location,
  history,
  handleFilter,
  onManageTagClick,
  onCustomerCareClick,
  enableCustomerCare,
  uploadFilesOption,
  isReviewPage,
}) => {
  const [menuOption, setMenuOptions] = useState(uploadFilesOption);
  const [collapse, setCollapse] = useState(false);
  const [selectedOrdering, setSelectedOrdering] = useState(null);

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  const updateOrdering = (sortOrder) => {
    setSelectedOrdering(sortOrder);
    const { pathname, search } = location;
    let filters = { ...queryString.parse(search), ordering: sortOrder };

    history.push({ pathname, search: queryString.stringify(filters) });
  };

  const is_review_aspect_attribute = getParams(location.search, "is_review_aspect_attribute");
  const is_product_reviews = getParams(location.search, "is_product_reviews");

  const [checked, setChecked] = useState(is_review_aspect_attribute === "true" ? true : false);
  const [productChecked, setProductChecked] = useState(is_product_reviews === "true" ? true : false);

  useEffect(() => {
    const newMenuOption = uploadFilesOption.filter((item) => item.enable);
    setMenuOptions(newMenuOption);
  }, [uploadFilesOption]);

  const { search } = location;

  useEffect(() => {
    let querKey = Object.keys(queryString.parse(search));
    if (querKey.includes("ordering")) {
      setSelectedOrdering(queryString.parse(search).ordering);
    } else {
      setSelectedOrdering(null);
    }
  }, [search]);

  const applyFilters = (event, switchOn) => {
    event.preventDefault();
    const { pathname, search } = location;
    
    const preSearch = search;
    const queryparams = queryString.parse(search);
    history.push({
      pathname,
      search: queryString.stringify({
        ...queryparams,
        page: 1,
        is_review_aspect_attribute: switchOn ? true : "",
      }),
    });

    if (preSearch === window.location.search) {
      handleFilter();
    }
  };

  const handleSwitch = (event, value) => {
    event.preventDefault();
    setChecked(!value);
    applyFilters(event, !value);
  };

  const handleProductSwitch = (event, value) => {
    event.preventDefault();
    setProductChecked(!value);
    applyProductFilters(event, !value);
  };

  const applyProductFilters = (event, switchOn) => {
    event.preventDefault();
    const { pathname, search } = location;
    const preSearch = search;
    const queryparams = queryString.parse(search);
    history.push({
      pathname,
      search: queryString.stringify({
        ...queryparams,
        is_product_reviews: switchOn ? true : false,
      }),
    });

    if (preSearch === window.location.search) {
      handleFilter();
    }
  };
  const sortOptions = [
    {
      display_name: "Oldest",
      value: "review_create",
    },
    {
      display_name: "Newest",
      value: "-review_create",
    },
  ];

  return (
    <div className="my-20">
      <CardWrapper showDivider={false} contentClass="paddingClass">
        <section className="d-flex justify-content-between align-items-center">
          <div>
            <ReviewPageSummary />
          </div>
          <div className="d-flex align-items-center gap-15">
            <div className="d-flex align-items-center gap-6">
            <div className="d-flex gap-20 align-items-center fp-flex-grow-1">
              <SearchComponent
                location={location}
                history={history}
                className="review-search"
                placeholder="Search Review"
                startIcon
              />
            </div>
              <p className="mb-0 fp-size-13 weight-400" style={{ color: "#1B1C1D" }}>
                FameAI Tags
              </p>
              {isReviewPage && (
                <Radio toggle checked={checked} value={checked} onChange={(e, { value }) => handleSwitch(e, value)} />
              )}
            </div>
            {/* <div className="d-flex align-items-center gap-6">
              <p className="mb-0 fp-size-13 weight-400" style={{ color: "#1B1C1D" }}>
                Product Tags
              </p>
              <Radio
                toggle
                checked={productChecked}
                value={productChecked}
                onChange={(e, { value }) => handleProductSwitch(e, value)}
              />
            </div> */}
            <img
              src={UpArrow}
              onClick={handleCollapse}
              style={{
                rotate: collapse ? "180deg" : "none",
                cursor: "pointer",
              }}
            />
          </div>
        </section>
        <Collapse isOpen={collapse}>
          <section className="d-flex gap-20 align-items-center justify-content-between mt-3">
            
            <div className="fp-flex-grow-2 d-flex align-items-center justify-content-end gap-10">
              <div>
                <AutoSelect
                  placeholder="Sort By"
                  options={sortOptions}
                  onChange={(event, option) => {
                    updateOrdering(option);
                  }}
                  value={selectedOrdering}
                  className="sortingBox"
                  sx={{
                    width: 100,
                    "& fieldset": { borderRadius: 33 },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#F13A88 !important",
                    },
                    "& .MuiInputBase-input": {
                      height: 15,
                      minWidth: "60px !important",
                      fontSize: "12px",
                      color: "#646565 !important",
                      "&::placeholder": {
                        color: "#646565",
                      },
                    },
                    // "& .MuiAutocomplete-popupIndicator": {},
                  }}
                  disableClearable
                  popupIcon={<DropdownIcon className="dropDownICon" />}
                />
              </div>
              <FPMenuBox
                title={"Upload file"}
                options={menuOption}
                disabled={!menuOption.length > 0}
                className="upload-file"
              />
              <FPToolTip title={"Manage Tags"}>
                <img
                  src={tagIcon}
                  onClick={() => onManageTagClick()}
                  style={{ height: "32px", width: "32px", cursor: "pointer" }}
                />
              </FPToolTip>

              {enableCustomerCare && (
                <FPToolTip title={"Customer Care Support"}>
                  <IconButton
                    component="span"
                    className="circular-button"
                    onClick={onCustomerCareClick}
                    style={{ height: "32px", width: "32px" }}
                  >
                    <CustomerCare />
                  </IconButton>
                </FPToolTip>
              )}
            </div>
          </section>
        </Collapse>
      </CardWrapper>
    </div>
  );
};

export default ReviewActionBar;
