import React from "react";
import { Button } from "@material-ui/core";
import { alpha, withStyles } from "@material-ui/core/styles";

const DefaultButton = withStyles({
  root: {
    background: ({ backgroundColor, variant }) =>
      backgroundColor ? backgroundColor : variant === "outline" || variant === "default" ? "transparent" : "#F13A88",
    borderRadius: ({ borderRadius }) => (borderRadius ? borderRadius : "50px"),
    height: ({ size, height }) => (height ? height : size === "small" ? "32px" : "38px"),
    padding: ({ padding }) => (padding ? padding : "0 15px"),
    textTransform: "none",
    minWidth: ({ size }) => (size === "small" ? "60px" : "100px"),

    color: ({ borderColor, textColor, variant }) =>
      textColor
        ? textColor
        : variant === "outline" || variant === "default"
        ? borderColor
          ? borderColor
          : "#1A2544"
        : "white",
    border: ({ borderColor, borderWidth = 1, variant }) =>
      variant === "outline" ? `${borderWidth}px solid ${borderColor || "#BFC2CB"}` : "unset",
    fontWeight: ({ fontWeight }) => (fontWeight ? fontWeight : "500"),

    "&:hover": {
      background: ({ backgroundColor, variant }) =>
        `${
          backgroundColor
            ? alpha(backgroundColor, 0.95)
            : variant === "outline" || variant === "default"
            ? "transparent"
            : alpha("#F13A88", 0.95)
        } !important`,
      transition: "0.3s",
    },
  },
  label: {
    fontSize: ({ fontSize }) => (fontSize ? fontSize : "12px"),
    fontFamily: "Outfit",
    lineHeight: 1,
    textWrap: "nowrap",
  },
  disabled: {
    opacity: 0.7,
    background: ({ backgroundColor, variant }) =>
      `${
        backgroundColor ? backgroundColor : variant === "outline" || variant === "default" ? "transparent" : "#F13A88"
      } !important`,
    color: ({ borderColor, textColor, variant }) =>
      `${
        textColor
          ? textColor
          : variant === "outline" || variant === "default"
          ? borderColor
            ? borderColor
            : "#1A2544"
          : "white"
      } !important`,
      cursor: "not-allowed",
  },
})((props) => <Button color="default" {...props} />);

const FPButton = (props) => {
  const {
    className,
    borderColor,
    backgroundColor,
    fontWeight,
    label,
    size = "default",
    textColor,
    borderWidth,
    padding,
    fontSize,
    children,
    variant,
    height,
    disabled,  // Ensure disabled is passed correctly
    ...rest
  } = props;

  return (
    <DefaultButton
      borderColor={borderColor}
      backgroundColor={backgroundColor}
      textColor={textColor}
      fontWeight={fontWeight}
      borderWidth={borderWidth}
      variant={variant || "contained"}
      disableElevation
      className={`fp-button ${className}`}
      size={size}
      padding={padding}
      fontSize={fontSize}
      height={height}
      disabled={disabled} // Pass disabled to DefaultButton
      {...rest}
    >
      {children ? children : label}
    </DefaultButton>
  );
};

export default FPButton;
