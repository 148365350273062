import React from "react";
import { Label, Icon } from "semantic-ui-react";

import CustomStarRatingComponent from "../common/StarComponent";
import { trendsDateType } from "../../libs/utils";
import { Box } from "@material-ui/core";

class FirstPartyReview extends React.Component {
  render() {
    const {
      data,
      filters: { date_type },
      trendsData,
    } = this.props;
    const perc_first_avg_rating = trendsData && trendsData.perc_first_avg_rating;
    const perc_first_review_count = trendsData && trendsData.perc_first_review_count;
    return (
      <div className="align-center">
        <div className="w-50">
          <Box Component="span" className="dash-text" sx={{ fontSize: "20px", marginBottom: "4px" }}>
            {(data && data.avg && data.avg.toFixed(2)) || 0}
          </Box>
          <CustomStarRatingComponent
            name="famepilot-review"
            className="fp-size-18"
            value={(data && data.avg && data.avg.toFixed(2)) || 0}
            editing={false}
          />
        </div>
        <div className="w-50">
          {perc_first_avg_rating !== undefined && (
            <Label basic color={perc_first_avg_rating < 0 ? "red" : "green"} style={{ borderWidth: 0 }}>
              {perc_first_avg_rating === 0 ? null : (
                <Icon name={perc_first_avg_rating < 0 ? "long arrow alternate down" : "long arrow alternate up"} />
              )}
              {`${perc_first_avg_rating && perc_first_avg_rating.toFixed(2)}% `}
              <span style={{ color: "#777777" }}>Vs {trendsDateType(date_type)}</span>
            </Label>
          )}
          <Box className="dashboard_caption" sx={{ fontSize: "20px", marginBottom: "4px" }}>
            {(data && data.count) || 0}
          </Box>
          <Box className="dashboard_sub_caption mt-1">Total Reviews</Box>
        </div>
      </div>
    );
  }
}

export default FirstPartyReview;
