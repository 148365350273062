import React from "react";
import { Chip } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import "./fpchip.css";

const FPChip = ({ className, size, iconColor, ...rest }) => {
  return (
    <Chip
      deleteIcon={
        <ClearIcon
          style={{
            color: iconColor || "#7A8193",
            ...(size === "small"
              ? { width: "12px", height: "12px" }
              : { width: "18px", height: "18px", marginTop: "-1.5px" }),
          }}
        />
      }
      className={`fp-chip ${className ? className : ""} `}
      size={size}
      {...rest}
    />
  );
};

export default FPChip;
