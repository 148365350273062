import { Form, Formik } from "formik";
import React from "react";
import { FormInput, FormTextArea } from "../common/new/forms";
import { FPButton } from "../common/new";
import * as yup from "yup";


const TicketMailModal = (props) => {
  const { initialValues, onSubmit  } = props;

  const submit = (data) => {
    onSubmit(data)
  }

  const validateSchema = yup.object().shape({
    from: yup
      .string()
      .email("Invalid email address")
      .required()
      .label("Email"),
    to : yup.string()
      .email("Invalid email address")
      .required()
      .label("Email"),
  
    subject : yup.string().required("Enter Subject").label("Subject"),
    body : yup.string().required("Enter Body").label("Body"),
  
  })


  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validateSchema}
        onSubmit={(value) => submit(value)}
      >
        <Form>
          <div className="d-flex justify-content-between gap-15">
            <div style={{ width: '100%'}}>
              <FormInput
                name="from"
                placeholder="Enter email address"
                size="medium"
                type="email"
                autoComplete="on"
                label="From"
              />
            </div>
            <div style={{ width: '100%'}}>
              <FormInput
                name="to"
                placeholder="Enter email address"
                size="medium"
                type="email"
                autoComplete="on"
                label="To"
              />
            </div>
          </div>
          <FormInput
            name="cc"
            placeholder="Enter CC"
            size="medium"
            type="text"
            autoComplete="on"
            label="CC"
            isRequired={false}
          />
          <FormInput
            name="subject"
            placeholder="Enter Subject"
            size="medium"
            type="text"
            autoComplete="on"
            label="Subject"
          />
          <FormTextArea 
            name="body"
            placeholder="Enter Body"
            size="medium"
            type="text"
            autoComplete="on"
            label="Body"
          />
          <FPButton type="submit" label="Send"></FPButton>
        </Form>
      </Formik>
    </React.Fragment>
  )
}

export default TicketMailModal;