import React, { useState, useRef, useEffect } from "react";
import ListingFPInput from "../../../../../components/common/new/Listing/ListingFPInput";
import FPButton from "../../../../../components/common/new/FPFilter/Listing/FPButton";
import { Chip, Stack } from "@mui/material";
import "./Addmenu.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as locationActions from "../../../../../actions/listing/listingLocationAction";

// Utility function to capitalize the first letter

const EditMenu = (props) => {
  const {
    location_food_menu_data,
    actions,
    selectReso,
    business_id,
    indexValue,
    sectionIndexValue,
    menuIndexValue,
  } = props;
  const fileInputRef = useRef(null);

  // Get the existing item data
  const existingItem =
    location_food_menu_data?.menus?.[menuIndexValue]?.sections?.[
      sectionIndexValue
    ]?.items?.[indexValue] || {};

  // State to store input values, initialized with existing data if available
  const [itemName, setItemName] = useState(
    existingItem?.labels?.[0]?.displayName || ""
  );
  const [itemPrice, setItemPrice] = useState(
    existingItem?.attributes?.price?.units || ""
  );
  const [itemDescription, setItemDescription] = useState(
    existingItem?.labels?.[0]?.description || ""
  );

  useEffect(() => {
    if (existingItem?.attributes?.dietaryRestriction) {
      setSelectedDietary(
        existingItem?.attributes?.dietaryRestriction[0] === "VEGETARIAN"
          ? "Vegetarian"
          : existingItem?.attributes?.dietaryRestriction[0] === "VEGAN"
          ? "Vegan"
          : ""
      );
    }
  }, []);

  // Initialize selectedDietary with the existing value from location_food_menu_data
  const [selectedDietary, setSelectedDietary] = useState("");
  console.log("selectedDietaryselectedDietaryselectedDietary", selectedDietary);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    console.log("Selected files:", files);
  };

  const updateMenuItem = async () => {
    const restodetail = { business_id, selectReso };

    // New section to be added
    const newSection = {
      labels: [
        {
          displayName: itemName,
          description: itemDescription,
          languageCode: "en",
        },
      ],
      attributes: {
        price: {
          currencyCode: "INR",
          units: itemPrice,
        },
        dietaryRestriction: selectedDietary, // Save the selected dietary restriction
      },
    };

    // Create a copy of the existing food menu data to avoid mutating the original state
    const updatedMenuData = { ...location_food_menu_data };

    // Directly update the existing object in the items array with the newSection data
    updatedMenuData.menus[menuIndexValue].sections[sectionIndexValue].items[
      indexValue
    ] = {
      ...updatedMenuData.menus[menuIndexValue].sections[sectionIndexValue]
        .items[indexValue],
      ...newSection,
    };

    // Create the payload with updated menu data
    const updateCode = {
      type: "updateMenu",
      updateMenuData: updatedMenuData,
    };

    // Dispatch the action or make the API call
    await actions?.getGmbListingUpdateListing(updateCode, restodetail);
    await actions.getGmbLocationFoodmenu(business_id, selectReso);
  };

  const handleChipClick = (dietary) => {
    // Toggle selection: if already selected, unselect it; otherwise, select it
    setSelectedDietary((prevDietary) =>
      prevDietary === dietary ? "" : dietary
    );
  };

  return (
    <>
      <div className="add-menu-container">
        <div className="menu-header">
          <h3>Edit menu item</h3>
          <p className="menu-subtext">
            This menu will be publicly visible on your profile
          </p>
        </div>
        <div className="menu-items">
          <div className="menu-item-header">
            <label className="item-label">Edit menu item</label>
            <p className="item-subtext">
              Modify the details of the selected item
            </p>
          </div>
          <div className="menu-item-grid">
            <div className="menu-item-inputs">
              <div className="input-group">
                <ListingFPInput
                  placeholder={"Item ndfadame*"}
                  value={itemName}
                  onChange={(e) => setItemName(e.target.value)}
                  maxValue={40}
                />
                <p>{itemName.length} / 40</p>
              </div>
              {itemName.length > 40 && <p>{itemName.length} / 40</p>}

              <div className="input-group">
                <ListingFPInput
                  placeholder={"Item price (INR)"}
                  value={itemPrice}
                  onChange={(e) => setItemPrice(e.target.value)}
                  maxValue={40}
                />
                <p>{itemPrice.length} / 40</p>
              </div>
              {itemPrice.length > 40 && <p>{itemPrice.length} / 40</p>}

              <div className="input-group">
                <textarea
                  className="textAreaUI"
                  placeholder={"Item description"}
                  value={itemDescription}
                  onChange={(e) => setItemDescription(e.target.value)}
                />
                <p>{itemDescription.length} / 40</p>
              </div>
            </div>
          </div>
        </div>
        <div className="dietary-restrictions">
          <label className="dietary-label">Dietary restrictions</label>
          <p className="dietary-subtext">Select one that applies</p>
        </div>
        <Stack direction="row" spacing={1}>
          <Chip
            label="Vegetarian"
            variant={selectedDietary === "Vegetarian" ? "filled" : "outlined"}
            onClick={() => handleChipClick("Vegetarian")}
            sx={{
              backgroundColor:
                selectedDietary === "Vegetarian" ? "#bfe0e2" : "#F0F1F3",
              color: selectedDietary === "Vegetarian" ? "#00b5ad" : "default",
              fontSize: "18px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
            }}
          />
          <Chip
            label="Vegan"
            variant={selectedDietary === "Vegan" ? "filled" : "outlined"}
            onClick={() => handleChipClick("Vegan")}
            sx={{
              backgroundColor:
                selectedDietary === "Vegan" ? "#bfe0e2" : "#F0F1F3",
              color: selectedDietary === "Vegan" ? "#00b5ad" : "default",
              fontSize: "18px",
              fontWeight: "400",
              padding: "12px",
              border: "0",
            }}
          />
        </Stack>
      </div>
      <div className="menu-footer">
        <FPButton label="Save" size="Small" onClick={updateMenuItem} />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    location_food_menu_data: state.gmbListingLocation.location_food_menu_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(locationActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditMenu);
