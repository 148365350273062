import * as types from "../../actions/actionTypes";

const initialState = {
  location_list: null,
  location_error: null,
};

export default function listingPerformanceReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.GET_LISTING_PERFORMANCE_METRICS_SUCCESS:
      return { ...state, listing_Performance: action.data };
    case types.GET_LISTING_PERFORMANCE_METRICS_FAILURE:
      return { ...state, listing_Performance_error: action.data };

    case types.GET_LISTING_OVERALL_COUNT_SUCCESS:
      return { ...state, listing_overAll_count: action.data };
    case types.GET_LISTING_OVERALL_COUNT_FAILURE:
      return { ...state, listing_overAll_count_error: action.data };

    case types.GET_LISTING_POPULAR_TIMES_SUCCESS:
      return { ...state, listing_popular_times: action.data };
    case types.GET_LISTING_POPULAR_TIMES_FAILURE:
      return { ...state, listing_popular_times_error: action.data };

      case types.GET_LISTING_SEARCH_KEYWORD_SUCCESS:
        return { ...state, listing_search_keywords: action.data };
      case types.GET_LISTING_SEARCH_KEYWORD_FAILURE:
        return { ...state, listing_search_keywords_error: action.data };
  
    default:
      return state;
  }
}
