import React, { forwardRef } from "react";
import { Image } from "semantic-ui-react";
import website_svg from "../../../../images/websitelink.svg";
import menu_svg from "../../../../images/menu.svg";
import lock_svg from "../../../../images/lock.svg";
import { Card } from "reactstrap";
import { Box, Dialog, DialogActions, IconButton, Slide } from "@mui/material";
import CloseIcon from "@material-ui/icons/Close";
import UpdateWebsiteLink from "./Models/UpdateWebsiteLink";
import UpdateAppointment from "./Models/UpdateAppointment";
import FormModal from "../../../../components/common/FormModal";

const WebsiteUpdate = (props) => {
  const { business_id, locationList, queryStringData, filterBusinessCount } =
    props;

  const [openTab, setOpenTab] = React.useState(null);
  const [scroll, setScroll] = React.useState("paper");

  const handleClickOpen = (dialog, scrollType) => {
    setOpenTab(dialog);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpenTab(null);
  };

  const style = {
    position: "relative",
    border: "none",
    "&:focus-visible": {
      outline: "none",
    },
  };

  const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });
  return (
    <>
      <div className="listing-detail-card">
        <div
          className="d-flex align-items-baseline"
          style={{ padding: "16px 26px", gap: "5px" }}
        >
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "400",
              color: "#1A2544",
            }}
          >
            Websites, Menu, Appointment & Lock Update
          </h2>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#F13A88",
            }}
          >
            (for {filterBusinessCount} verified & connected listings)
          </h2>
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "1rem",
            padding: " 0px 24px 24px 24px",
          }}
        >
          <Card
            className="detail-card-item detail-special-card "
            onClick={() => handleClickOpen("website_link", "paper")}
          >
            {/* <CardHeader className="card-heading"></CardHeader> */}
            <div className="mb-2">
              <img
                src={website_svg}
                height="28px"
                alt=""
                className="successImg"
              />
            </div>
            <h2 className="bulk-business-heading">
              Bulk website & menu link changes
            </h2>
            <span className="bulk-business-card-review">
              Add or update your business websites & other URL’s with UTM
              tracker
            </span>
          </Card>

          <Card
            className="detail-card-item detail-special-card "
            onClick={() => handleClickOpen("appointment", "paper")}
          >
            <div className="mb-2">
              <img src={menu_svg} height="28px" alt="" className="successImg" />
            </div>
            <h2 className="bulk-business-heading">
              Appointment & Food ordering links
            </h2>
            <span className="bulk-business-card-review">
              Add appointment links to be shown on google search
            </span>
          </Card>

          {/* <div className="p-1" style={{ cursor: "pointer" }} onClick={() => handleClickOpen("", "paper")}>
            <Card className="detail-card-item detail-special-card ">
              <div className="detail-card-content">
                <div>
                  <img
                    src={lock_svg}
                    height="41px"
                    alt=""
                    className="successImg"
                  />
                </div>
                <h2 style={{ fontWeight: "500", fontSize: "16px" }}>
                  Bulk profile protection lock
                </h2>
                <span className="detail-business-card-review">
                  Protect your listings from unauthorized access
                </span>
              </div>
            </Card>
          </div> */}
        </div>
      </div>

      <FormModal
        isOpen={openTab === "website_link"}
        toggle={handleClose}
        heading="Update Website Links"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        bodyClass={"py-0"}
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdateWebsiteLink
          busines_status={true}
          queryStringData={queryStringData}
          business_id={business_id}
        />
      </FormModal>
      <FormModal
        isOpen={openTab === "appointment"}
        toggle={handleClose}
        heading="Update Appointments"
        onClose={handleClose}
        maxWidth="lg"
        fullWidth
        bodyClass={"py-0"}
        paddingDense={false}
        showCloseBtn={true}
      >
        <UpdateAppointment
          busines_status={true}
          queryStringData={queryStringData}
          business_id={business_id}
        />
      </FormModal>
    </>
  );
};

export default WebsiteUpdate;
