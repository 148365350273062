import * as types from "../../actions/actionTypes";

const initialState = {
  location_list: null,
  location_error: null,
};

export default function gmbListingGeoGridRankerReducer(
  state = initialState,
  action
) {  
  switch (action.type) {
    case types.GET_GMB_LOCATION_GEO_GRID_RANKER_SUCCESS:
      return { ...state, geo_grid_ranker_data: action.data };
    case types.GET_GMB_LOCATION_GEO_GRID_RANKER_FAILURE:
      return { ...state, geo_grid_ranker_error: action.data };

    case types.GET_GMB_LOCATION_TOP_KEYWORD_SUCCESS:
      return { ...state, top_keyword_data: action.data };
    case types.GET_GMB_LOCATION_TOP_KEYWORD_FAILURE:
      return { ...state, top_keyword_error: action.data };

    case types.GET_GMB_LOCATION_SCAN_HISTORY_SUCCESS:
      return { ...state, scan_history_data: action.data };
    case types.GET_GMB_LOCATION_SCAN_HISTORY_FAILURE:
      return { ...state, scan_history_error: action.data };
    case types.USE_SCAN_HISTORY:
      return { ...state, use_scan_history_data: action.data };

    default:
      return state;
  }
}
