import * as types from "../../actions/actionTypes";
import cookie from "react-cookies";

export default function loginReducer(state = [], action) {
  const showSideNav = cookie.load("showSideNav");
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      cookie.save("refreshToken", action.data.tokens.refresh, {
        path: "/",
        maxAge: 365 * 24 * 60 * 60,
      });
      
      cookie.save("authToken", action.data.tokens.access , {
        path: "/",
        maxAge: 365 * 24 * 60 * 60,
      });
      let screenName = action.data.screen_name; // eslint-disable-line
      cookie.save("screens", screenName, {
        path: "/",
        maxAge: 365 * 24 * 60 * 60,
      });
      if (!(showSideNav === "true" || showSideNav === "false"))
        cookie.save("showSideNav", true, {
          path: "/",
          maxAge: 365 * 24 * 60 * 60,
        });
      return {
        isLogin: true,
        ...action.data,
      };
    case types.LOGIN_FAILURE:
      return action.data;
    default:
      return state;
  }
}
