/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as URLS from "../../libs/apiUrls";
import { getAllParamsInArrayForm, getParams } from "../../libs/utils";
import { FPFilter, FPButton, FPToolTip } from "../../components/common/new";
import axios_instance from "../../libs/interseptor";
import { IconButton } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../images/svg/Download.svg";

const options = [
  {
    value: "active",
    display_name: "Connected",
    label: { color: "green", empty: true, circular: true },
  },
  {
    value: "inactive",
    display_name: "Disconnected",
    label: { color: "yellow", empty: true, circular: true },
  },
  {
    value: "blank",
    display_name: "Setup Review Links",
    label: { color: "red", empty: true, circular: true },
  },
];

const LocationFilter = (props) => {
  const {
    portalData,
    countryOption,
    stateOption,
    history,
    downloadExcel,
    pageSizeCookie,
    businessId,
    getStateOptions,
  } = props;
  const [isMoreThanNinety, setIsMoreThanNinety] = useState(false);
  const [branchTag, setBranchTag] = useState([]);

  const dateType = getParams(location.search, "date_type");
  const startDate = getParams(location.search, "start_date");
  const endDate = getParams(location.search, "end_date");

  const branchTagDataFetch = () => {
    axios_instance.get(`${URLS.BRANCH_TAG}?business_id=${businessId}`).then((res) => {
      setBranchTag(res.data.results);
    });
  };

  const handleReviewDownloadFlow = () => {
    if (dateType === "other") {
      var Difference_In_Time = endDate.getTime() - startDate.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 90) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    } else {
      if (["prior_6_month", "current_year", "prior_12_months"].indexOf(dateType) > -1) {
        setIsMoreThanNinety(true);
      } else {
        setIsMoreThanNinety(false);
        downloadExcel();
      }
    }
  };

  useEffect(() => {
    branchTagDataFetch();
  }, []);

  const handleDefaultFilter = (list, key) => {
    const filter = location.search;
    const defaultFilter = getAllParamsInArrayForm(filter);
    const isKeyFilterAvailable = defaultFilter && defaultFilter[key] && defaultFilter[key].length;
    return list.map((item) => {
      item["isChecked"] = isKeyFilterAvailable && defaultFilter[key].includes(item.value.toString());
      item["parentKey"] = key;
      return item;
    });
  };

  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    const initialFilterList = [
      {
        id: "1",
        label: "Provider",
        key: "provider",
        type: "radio",
        isSearchable: false,
        list:
          portalData &&
          handleDefaultFilter(
            portalData.map((item) => ({
              value: item.value,
              display_name: item.text,
            })),
            "provider"
          ),
      },
      {
        id: "2",
        label: "Status",
        key: "status",
        type: "radio",
        isSearchable: false,
        list: options ? handleDefaultFilter(options, "status") : [],
        disable: true,
      },
      {
        id: "3",
        label: "Country",
        key: "country",
        type: "radio",
        isSearchable: true,
        list: countryOption
          ? handleDefaultFilter(
              countryOption.map((item) => ({
                value: item.value,
                display_name: item.text,
              })),
              "country"
            )
          : [],
      },
      {
        id: "4",
        label: "State",
        key: "state",
        type: "radio",
        isSearchable: true,
        list: stateOption
          ? handleDefaultFilter(
              stateOption.map((item) => ({
                value: item.value,
                display_name: item.text,
              })),
              "state"
            )
          : [],
      },
      {
        id: "5",
        label: "Branch Tag",
        key: "branch_tag",
        type: "checkbox",
        isSearchable: false,
        list: branchTag
          ? handleDefaultFilter(
              branchTag.map((item) => ({
                value: item.title,
                display_name: item.title,
              })),
              "branch_tag"
            )
          : [],
      },
    ];

    setFilterList(initialFilterList);
  }, [portalData, options, countryOption, stateOption, branchTag]);

  const handleFilterOnChange = ({ values, activeItemKey }) => {
    if (activeItemKey === "country" && values.country.length > 0) {
      getStateOptions(values.country[0].value);
    }

    setFilterList((prevFilterList) =>
      prevFilterList.map((filter) => {
        if (filter.key === "status") {
          return {
            ...filter,
            disable: values?.provider?.length > 0 ? false : true,
          };
        }
        return filter;
      })
    );
  };

  return (
    <React.Fragment>
      <FPFilter
        data={filterList}
        className="mt-3"
        history={history}
        pageSizeCookie={pageSizeCookie}
        onChange={handleFilterOnChange}
        actionRender={() => (
          <FPToolTip title={"Download"}>
            <IconButton component="span" className="circular-button" onClick={() => handleReviewDownloadFlow()}>
              <DownloadIcon />
            </IconButton>
          </FPToolTip>
        )}
        locationFilter
        showActionBtn={true}
      />
    </React.Fragment>
  );
};

export default LocationFilter;
