import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import LinksForm from "./LinksForm";
import FormModal from "../common/FormModal";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { FPToolTip } from "../common/new";
import edit from "../../images/svg/edit-grey.svg";
import check from "../../images/svg/check-circle.svg";
import notCheck from "../../images/svg/not-Check.svg";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import BranchSelector from "./BranchSelector";
import FPLabel from "../common/new/forms/ui/FPLabel";

class BusinessReviewsSetup extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, selectedItem: null };
    this.toggleModal = this.toggleModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  toggleModal(selectedItem) {
    const { isOpen } = this.state;
    if (!isOpen) {
      this.setState({ selectedItem });
    }
    this.setState({ isOpen: !isOpen });
  }

  submit(data) {
    const { submitSocialLinks } = this.props;
    return submitSocialLinks(data).then(() => this.toggleModal());
  }

  render() {
    const { socialLinks, rerenderParentCallback, blankField, filteredChoices } = this.props;
    const { selectedItem } = this.state;
    return (
      <div>
        {/* <div className="mt-3 mb-4">
          <div className="mb-3">
            <span
              style={{
                fontSize: "18px",
                fontWeight: "400",
                color: "rgba(100, 101, 101, 1)",
              }}
            >
              Review Links
            </span>
          </div>
          <div className="selectLocation">
            <div className="col-6">
              <FPLabel style={{ color: "#00B5AD", fontSize: "15px" }}>Select Location</FPLabel>
              <BranchSelector rerenderParentCallback={rerenderParentCallback} blankField={blankField} />
            </div>
          </div>
        </div> */}
        <CardWrapper contentClass="px-3" showDivider={false}>
          <div className="ui-table-responsive">
            <Table fixed unstackable>
              <TableHead>
                <TableRow>
                  <TableCell style={{  fontSize: "14px" }}>Review Channel</TableCell>
                  <TableCell style={{ fontSize: "14px" }}>URL</TableCell>
                  <TableCell style={{ width: "300px", textAlign: "center", fontSize: "14px" }}>Status</TableCell>
                  <TableCell style={{ textAlign: "center", fontSize: "14px" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {socialLinks && socialLinks.length ? (
                  socialLinks.map((item) => (
                    <TableRow key={item.web_portal.id}>
                      <TableCell style={{minWidth: "140px"}}>
                        <img
                          src={item.web_portal.provider_logo || ""}
                          height="20"
                          width="20"
                          style={{ marginRight: "5px" }}
                        />
                        <span style={{ fontSize: "15px", fontWeight: "500" }}>{item.web_portal.provider}</span>
                      </TableCell>
                      <TableCell style={{ fontSize: "12px", fontWeight: "400", color: "#7A8193", maxWidth:"620px" }}>
                        {item.link || "Please Enter a URL"}
                      </TableCell>
                      <TableCell className="text-center">
                        {item.is_active ? (
                          <div>
                            <img src={check} role="button" alt="active" id={"tipId" + item.web_portal.id} />
                            <UncontrolledTooltip placement="right" target={"tipId" + item.web_portal.id}>
                              Active
                            </UncontrolledTooltip>
                          </div>
                        ) : (
                          <div>
                            <img src={notCheck} role="button" id={"tipId" + item.web_portal.id} alt="inActive" />
                            <UncontrolledTooltip placement="right" target={"tipId" + item.web_portal.id}>
                              In-Active
                            </UncontrolledTooltip>
                          </div>
                        )}
                      </TableCell>
                      <TableCell className="text-center">
                        <FPToolTip title={"Edit"}>
                          <img src={edit} role="button" alt="edit" onClick={() => this.toggleModal(item)} />
                        </FPToolTip>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="text-center" colSpan={10}>
                      No records Found{" "}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </CardWrapper>

        {this.state.isOpen && (
          <FormModal
            heading={`Edit ${selectedItem.web_portal.provider} URL`}
            isOpen={this.state.isOpen}
            toggle={this.toggleModal}
            width="600px"
            headerClass="d-flex align-items-center"
          >
            <LinksForm submitSocialLinks={this.submit} initialValues={selectedItem} />
          </FormModal>
        )}
      </div>
    );
  }
}

export default BusinessReviewsSetup;
