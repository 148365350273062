import * as types from '../actionTypes';
import axios_instance from '../../libs/interseptor';
import * as URLS from '../../libs/apiUrls';
import * as commonAction from '../common/commonAction';

export function changePasswordSuccess(data) {
  return { type: types.CHANGE_PASSWORD_SUCCESS, data };
}

export function changePasswordFailure(data) {
  return { type: types.CHANGE_PASSWORD_FAILURE, data };
}

export const changePasswordAction = (data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(URLS.CHANGE_PASSWORD, data) 
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(changePasswordSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(changePasswordFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function resetPasswordSuccess(data) {
  return { type: types.RESET_PASSWORD_SUCCESS, data };
}

export function resetPasswordFailure(data) {
  return { type: types.RESET_PASSWORD_FAILURE, data };
}

export const resetPasswordAction = (data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(URLS.RESET_PASSWORD, data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(resetPasswordSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(resetPasswordFailure(err));
          throw err.response.data;
        }
      );
  };
};
