import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { InputField } from "react-semantic-redux-form";
import { FPButton } from "../common/new";
import FPLabel from "../common/new/forms/ui/FPLabel";

const validate = (values) => {
  // eslint-disable-line
  const error = {};
  if (!values.name) error.name = "This field may not be blank.";
  if (values.name && values.name.length > 200)
    error.name = "Competitor name must have less than 200 characters";
  return error;
};

class CompetitorsForm extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  submitComp = (data) => {
    const { addCompetitors } = this.props;

    return addCompetitors(data).catch((err) => {
      err.non_field_errors && this.props.toggleLimitModal(err.non_field_errors);
      const errObj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errObj);
    });
  };

  clearName(e) {
    const { change } = this.props;
    change("name", "");
    e.preventDefault();
  }

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <form onSubmit={handleSubmit(this.submitComp)}>
       
          <div>
            <FPLabel>Competitor</FPLabel>
            <Field
              name="name"
              component={InputField}
              className="w-100"
              placeholder="Enter the first Competitor Brand"
              type="text"
            >
              {/* <input />
            <Button basic type='button' onClick={(e) => { this.clearName(e); }} icon><Icon name="close" /></Button> */}
            </Field>
          </div>
          <FPButton disabled={submitting} type="submit" fullWidth className='mt-3' size='small'>
            Submit
          </FPButton>
      </form>
    );
  }
}

CompetitorsForm = reduxForm(
  // eslint-disable-line
  {
    form: "CompetitorsForm",
    enableReinitialize: true,
    validate,
  }
)(CompetitorsForm);

CompetitorsForm = connect(() =>
  // eslint-disable-line
  ({})
)(CompetitorsForm);

export default CompetitorsForm;
