import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Checkbox } from "semantic-ui-react";
import {
  Field,
  reduxForm,
  SubmissionError,
  formValueSelector,
} from "redux-form";
import PropTypes from "prop-types";
import InputComponent from "../../common/InputComponent";
import TextAreaComponent from "../../common/TextAreaComponent";
import ReviewMethodsOptions from "../../common/ReviewMethodsOptions";
import MultipleButtonSelect from "../../common/MultipleButtonSelect";
import { FPBreadcrumbs, FPButton, FPMenuBox } from "../../common/new";
import FPLabel from "../../common/new/forms/ui/FPLabel";
import CardWrapper from "../../../modules/dashboard/component/CardWrapper";
import { capitalize } from "../../../libs/utils";
import { Collapse } from "@material-ui/core";

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = "Enter Name";
  if (!values.body) errors.body = "Enter Message";
  if (!values.message) errors.message = "Enter Message";
  if (!values.heading) errors.heading = "Enter Heading";
  return errors;
};

class TemplateWhatsappForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.state = {
      sMsMessageChars: 2000,
      landingMessageChars: 500,
      numberOfSMs: 0,
      selectReviewMethods:
        props.initialValues && props.initialValues.symbol_type ? true : false,
      showReviewMethods:
        props.initialValues && props.initialValues.symbol_type ? true : false,
      includeSurvey:
        props.initialValues && props.initialValues.survey_section
          ? true
          : false,
      reviewGating: props.initialValues && props.initialValues.review_gating,
      reviewMethod:
        props.initialValues && props.initialValues.symbol_type
          ? props.initialValues.symbol_type
          : "thumb",
      reviewChannel: [],
      error: "",
      selectedSubType:
        props.initialValues && props.initialValues.sub_type
          ? props.initialValues.sub_type
          : null,
    };
    this.handleReviewMethod = this.handleReviewMethod.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextIV = nextProps.initialValues
      ? JSON.stringify(nextProps.initialValues)
      : {};
    const thisIV = this.props.initialValues
      ? JSON.stringify(this.props.initialValues)
      : {};
    if (nextIV !== thisIV) {
      this.setState({ reviewMethod: nextProps.initialValues.symbol_type });
    }
  }

  handleShowReviewMethods = (e, { checked }) => {
    this.setState({ showReviewMethods: checked });
  };

  handleSelectReviewMethods = (e, { checked }) => {
    this.setState({ selectReviewMethods: checked });
  };

  handleIncludeSurvey = (e, { checked }) => {
    this.setState({ includeSurvey: checked });
  };

  handleReviewGating = (e, { checked }) => {
    this.setState({ reviewGating: checked });
  };

  handleReviewChannel = (value) => {
    const providers =
      value &&
      value
        .filter((item) => {
          return item.status === "active";
        })
        .map((item) => {
          return item.id;
        });
    this.setState({ reviewChannel: providers });
  };
  handleSubTypeSelect = (text) => {
    this.setState({ selectedSubType: text });
  };

  submit(data) {
    const {
      router: {
        history,
        route: {
          match: {
            params: { branch_id, business_id },
          },
        },
      },
    } = this.context;
    const {
      reviewMethod,
      reviewChannel,
      showReviewMethods,
      reviewGating,
      includeSurvey,
      selectedSubType,
      selectReviewMethods,
    } = this.state;
    const { newsLetter } = this.props;
    const newData = { ...data };
    newData.template_type = "whatsapp_template";
    if (!newsLetter) {
      newData.symbol_type = showReviewMethods
        ? reviewMethod
          ? reviewMethod
          : ""
        : null;
    } else {
      delete newData.symbol_type;
    }
    if (selectedSubType) {
      newData.sub_type = selectedSubType;
    }
    newData.review_gating =
      selectReviewMethods && showReviewMethods && reviewGating;
    newData.survey_section = includeSurvey && includeSurvey;
    newData.review_channels = reviewChannel;
    newData.body = !newsLetter ? data.body : null;
    const url = newsLetter ? "/templates/news-letter/list" : "/templates/list";
    return this.props
      .submitData(newData)
      .then(() => history.push(`/${business_id}/${branch_id}${url}`))
      .catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        this.setState({ error: errobj });
        throw new SubmissionError(errobj);
      });
  }

  handleReviewMethod(e, { value }) {
    this.setState({ reviewMethod: value });
  }

  componentDidUpdate() {
    const { change, smsMessageFieldValue, landingSectionMessageFieldValue } =
      this.props;

    if (smsMessageFieldValue && smsMessageFieldValue.length > 2000) {
      let newMsg =
        smsMessageFieldValue && smsMessageFieldValue.substring(0, 2000);
      change("message", newMsg);
    } else {
      var sMsMessageChars =
        2000 - (smsMessageFieldValue ? smsMessageFieldValue.length : 0);
    }

    if (
      landingSectionMessageFieldValue &&
      landingSectionMessageFieldValue.length > 500
    ) {
      let newMsg =
        landingSectionMessageFieldValue &&
        landingSectionMessageFieldValue.substring(0, 500);
      change("body", newMsg);
    } else {
      var landingMessageChars =
        500 -
        (landingSectionMessageFieldValue
          ? landingSectionMessageFieldValue.length
          : 0);
    }

    if (smsMessageFieldValue && smsMessageFieldValue.length > 0) {
      var numberOfSMs = Math.floor((smsMessageFieldValue.length - 1) / 160 + 1);
    }

    this.setState({ sMsMessageChars, landingMessageChars, numberOfSMs });
  }

  render() {
    const {
      submitting,
      handleSubmit,
      newsLetter,
      errors,
      initialValues,
      reviewChannels,
      subTypeList,
      reset,
    } = this.props;
    const {
      sMsMessageChars,
      landingMessageChars,
      numberOfSMs,
      reviewMethod,
      showReviewMethods,
      reviewGating,
      includeSurvey,
      selectReviewMethods,
    } = this.state;
    const {
      router: {
        route: {
          match: {
            params: { branch_id, business_id },
          },
        },
      },
    } = this.context;

    const breadCrumbList = [
      {
        label: "Templates",
        link: `/${business_id}/${branch_id}/templates/list?page_size=10`,
      },
      {
        label: newsLetter ? "News Letter" : "Review Request",
        link: newsLetter
          ? `/${business_id}/${branch_id}/templates/news-letter/list?page_size=10`
          : `/${business_id}/${branch_id}/templates/list?page_size=10`,
      },
      {
        label: newsLetter ? "NewsLetter WhatsApp" : "Review request WhatsApp",
        link: "",
      },
    ];

    const genericSubTypeMenuList = subTypeList
      ? subTypeList.map((type) => ({
          label: capitalize(type),
          value: type,
          onClick: () => this.handleSubTypeSelect(type),
        }))
      : [];

    return (
      <form className="grid-form" onSubmit={handleSubmit(this.submit)}>
        {errors && errors.non_field_errors
          ? errors.non_field_errors.map((error, index) => (
              <p key={index} className="text-danger">
                {error}
              </p>
            ))
          : null}

        <Row>
          <Col>
            <FPBreadcrumbs list={breadCrumbList} />
          </Col>
        </Row>

        <Row className="mt-20">
          <Col>
            <section className="d-flex flex-column gap-20">
              {/* Title section */}
              <CardWrapper
                headerTitle={
                  newsLetter ? "NewsLetter WhatsApp" : "Review request WhatsApp"
                }
                contentClass="pb-3 px-4 pt-4"
              >
                <FPLabel> Name </FPLabel>
                <Field
                  name="name"
                  type="text"
                  component={InputComponent}
                  serverError={errors && errors.name}
                  disabled={initialValues.is_default}
                />
              </CardWrapper>

              {/* WhatsApp message sent to user */}
              <CardWrapper
                headerTitle="WhatsApp message sent to user"
                contentClass="p-4"
              >
                <Row>
                  <Col>
                    <FPLabel>Message</FPLabel>
                    <Field
                      name="message"
                      type="text"
                      component={TextAreaComponent}
                      serverError={errors && errors.message}
                      placeholder="Enter message body"
                    />
                    <small className="text-right mt-1 d-block">
                      {2000 - sMsMessageChars} :Character(s), {numberOfSMs} :SMS
                    </small>
                  </Col>
                </Row>

                {newsLetter && (
                  <Row>
                    <Col>
                      <FPLabel isRequired={false}>Link</FPLabel>
                      <Field
                        name="link"
                        type="text"
                        component={InputComponent}
                        serverError={errors && errors.link}
                      />
                    </Col>
                  </Row>
                )}
              </CardWrapper>

              {/* Show review methods Landing page data*/}
              {!newsLetter && (
                <CardWrapper
                  headerTitle={
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Show Review Methods</span>
                      <Checkbox
                        toggle
                        checked={showReviewMethods}
                        onClick={this.handleShowReviewMethods}
                      />
                    </div>
                  }
                  contentClass="pb-3 pt-4 px-4"
                  showDivider={showReviewMethods}
                >
                  <Collapse in={showReviewMethods}>
                    <React.Fragment>
                      <FPLabel
                        isRequired={false}
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          marginBottom: "10px",
                        }}
                      >
                        Landing Page
                      </FPLabel>
                      <Row>
                        <Col>
                          <FPLabel>Heading</FPLabel>
                          <Field
                            name="heading"
                            type="text"
                            component={InputComponent}
                            serverError={errors && errors.heading}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <FPLabel>Body</FPLabel>
                          <Field
                            name="body"
                            type="text"
                            component={TextAreaComponent}
                            serverError={errors && errors.body}
                          />
                          <small className="text-right mt-1 d-block">
                            {landingMessageChars} characters left.
                          </small>
                        </Col>
                      </Row>
                    </React.Fragment>
                  </Collapse>
                </CardWrapper>
              )}

              {/* Select Review Methods */}
              {!newsLetter && (
                <CardWrapper
                  headerTitle={
                    <div className="d-flex justify-content-between align-items-center">
                      <span>Select Review Methods</span>
                      <Checkbox
                        toggle
                        checked={selectReviewMethods}
                        onClick={this.handleSelectReviewMethods}
                      />
                    </div>
                  }
                  contentClass="pb-3 pt-4 px-4"
                  showDivider={selectReviewMethods}
                >
                  <Collapse in={selectReviewMethods}>
                    <React.Fragment>
                      <ReviewMethodsOptions
                        className="mt-3"
                        reviewMethod={reviewMethod}
                        handleReviewMethod={this.handleReviewMethod}
                      />
                      <div className="d-flex my-3 justify-content-between align-item-center">
                        <FPLabel isRequired={false}>Review Gating</FPLabel>
                        <Checkbox
                          toggle
                          checked={reviewGating}
                          onClick={this.handleReviewGating}
                        />
                      </div>

                      {reviewChannels && reviewChannels ? (
                        <MultipleButtonSelect
                          reviewChannels={reviewChannels && reviewChannels}
                          handleReviewChannel={this.handleReviewChannel}
                          initialValues={
                            initialValues && initialValues.review_channels
                          }
                          serverError={errors && errors.name}
                        />
                      ) : (
                        ""
                      )}
                      <p className="text-danger">
                        {this.state.error.review_channels}
                      </p>

                      <div className="d-flex my-3 justify-content-between align-items-center">
                        <FPLabel isRequired={false}>Include Survey</FPLabel>
                        <Checkbox
                          toggle
                          checked={includeSurvey}
                          onClick={this.handleIncludeSurvey}
                        />
                      </div>

                      {includeSurvey && (
                        <div className="d-flex my-3 justify-content-between align-items-center">
                          <FPLabel isRequired={false}>
                            Generic Template Sub Type
                          </FPLabel>
                          <FPMenuBox
                            title={
                              this.state.selectedSubType
                                ? this.state.selectedSubType
                                : "Select"
                            }
                            options={genericSubTypeMenuList}
                            size={"small"}
                            className={"fp-size-12"}
                            rounded={true}
                          />
                        </div>
                      )}
                    </React.Fragment>
                  </Collapse>
                </CardWrapper>
              )}
            </section>

            <div className="mt-4 d-flex align-items-center justify-content-end gap-10">
              <FPButton
                size="small"
                variant="outline"
                borderColor="#BFC2CB"
                textColor="#1B1C1D"
                label="Clear"
                onClick={() => reset()}
                className="min-w-100px"
              />
              <FPButton
                size="small"
                type="submit"
                disabled={submitting}
                label="Save"
                className="min-w-100px"
              />
            </div>
          </Col>
        </Row>
      </form>
    );
  }
}

TemplateWhatsappForm.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

TemplateWhatsappForm = reduxForm({
  // eslint-disable-line
  form: "TemplateWhatsappForm",
  validate,
  enableReinitialize: false,
})(TemplateWhatsappForm);

const selector = formValueSelector("TemplateWhatsappForm");

TemplateWhatsappForm = connect((state) => {
  // eslint-disable-line
  const smsMessageFieldValue = selector(state, "message");
  const landingSectionMessageFieldValue = selector(state, "body");
  return {
    smsMessageFieldValue,
    landingSectionMessageFieldValue,
  };
})(TemplateWhatsappForm);

export default TemplateWhatsappForm;
