import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// import { Card, Row, Collapse, Col, Button } from "reactstrap";
import Instagram from "../../images/instagram_logo.png";
import Fb from "../../images/Facebook_Messenger_Logo.png";
import WhatsappIcon from "../../images/whatsappicon.png";
// import MessegeCount from "../../components/messenger/Common/MessegeCount";
import AssignUserModal from "../../components/messenger/Common/AssignUserModal";
import LoaderComponent from "../../components/common/loaderComponent";
import TwitterIcon from '../../images/twitter.png';

// import { Icon } from "semantic-ui-react";
import ChatUserCard from "./ChatUserCard";

const InboxListComponent = (props) => {
  const {
    expended,
    respondedList,
    unrespondedList,
    teamList,
    userCallback,
    showLoader,
    userAssignCallBackFn,
    userAssignModalClose,
    userAssignError,
    countArray,
    activeUser,
  } = props;
  
  const [showModal, setShowModal] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [selectUnconvensUser, setSelectUnconvensUser] = useState();

  useEffect(() => {
    if (userAssignModalClose) {
      setShowModal(false);
    }
  }, [userAssignModalClose]);

  useEffect(() => {
    if (expended === true) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [expended]);

  function getUser(user) {
    userCallback(user);
  }

  function showModalFn(user) {
    setSelectUnconvensUser(user);
    setShowModal(true);
  }

  function closeModal(close) {
    console.log(close);
    setShowModal(false);
  }

  function userAssignCallBack(detail) {
    userAssignCallBackFn(detail);
  }

  // const togglee = () => {
  //   setCollapse(!collapse);
  // };

  return (
    <div className="msg-user-list">
      <div className="contact">
        {unrespondedList &&
          unrespondedList.map((unresponded) => (
            <ChatUserCard
              key={unresponded.id}
              selected={activeUser?.id === unresponded.id}
              platFormImg={
                unresponded.platform === "facebook"
                  ? Fb
                  : unresponded.platform === "whatsapp"
                  ? WhatsappIcon
                  : unresponded.platform === "twitter"
                  ? TwitterIcon
                  : Instagram
              }
              userName={unresponded?.cus_name}
              countArray={countArray}
              tags={[unresponded?.page_name]}
              assignTo={unresponded?.assigned_user}
              lastMessage={unresponded?.last_message}
              onClick={() => getUser(unresponded)}
            />
          ))}
        {respondedList &&
          respondedList.map((responded) => (
            <ChatUserCard
              key={responded.id}
              selected={activeUser?.id === responded.id}
              platFormImg={
                responded.platform === "facebook"
                  ? Fb
                  : responded.platform === "whatsapp"
                  ? WhatsappIcon
                  : responded.platform === 'twitter'
                  ? TwitterIcon
                  : Instagram
              }
              userName={responded?.cus_name}
              countArray={countArray}
              tags={[responded?.page_name]}
              assignTo={responded?.assigned_user}
              lastMessage={responded?.last_message}
              onClick={() => getUser(responded)}
            />
          ))}
        <LoaderComponent loader={showLoader} />

        <AssignUserModal
          showModal={showModal}
          closeModal={closeModal}
          teamList={teamList}
          selectedUser={selectUnconvensUser}
          userAssignnCallBack={userAssignCallBack}
          userAssignError={userAssignError}
        />
      </div>
    </div>
  );
};

InboxListComponent.propTypes = {
  expended: PropTypes.bool.isRequired,
  respondedList: PropTypes.array.isRequired,
  unrespondedList: PropTypes.array.isRequired,
  teamList: PropTypes.array.isRequired,
  userCallback: PropTypes.func.isRequired,
  showLoader: PropTypes.bool.isRequired,
  userAssignCallBackFn: PropTypes.func.isRequired,
  userAssignModalClose: PropTypes.bool.isRequired,
  userAssignError: PropTypes.string.isRequired,
  countArray: PropTypes.array.isRequired,
};

export default InboxListComponent;
