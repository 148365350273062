import { Box } from "@material-ui/core";
import React from "react";
import { Divider } from "semantic-ui-react";

function TeamPopup(props) {
  const { memberList, department, designation } = props;
  const locationSection = {
    maxHeight: "170px",
    overflow: "auto",
    gap: "10px",
    display: "flex",
    flexDirection: "column",
  };
  const departmentId = department?.find(
    (item) => item.id == memberList.department
  )?.name;
  const designationId = designation?.find(
    (item) => item.id == memberList.designation
  )?.name;
  return (
    <div>
      <div className="d-flex justify-content-between">
        <Box className="" pl={0}>
          <span className="headText">Rule Name</span>
          <p className="textBold text-capitalize">
            {memberList.first_name + " " + memberList.last_name}
          </p>
        </Box>

        <Box className="" pr={0}>
          <span className="headText">Email</span>
          <p className="textBold text-capitalize">{memberList.email}</p>
        </Box>
        <Box className="" pr={0}>
          <span className="headText">Department</span>
          {departmentId ? (
            <p className="textBold text-capitalize">{departmentId}</p>
          ) : (
            <p className="textBold text-center">-</p>
          )}
        </Box>
        <Box className="" pr={0}>
          <span className="headText">Designation</span>
          {designationId ? (
            <p className="textBold text-capitalize">{designationId}</p>
          ) : (
            <p className="textBold text-center">-</p>
          )}
        </Box>
        <Box className="" pr={0}>
          <span className="headText">Contact Number</span>
          <p className="textBold text-capitalize">
            {memberList.contact_number}
          </p>
        </Box>
      </div>

      <Divider className="my-4" />
      <Box className="" sx={{ height: 204 }}>
        <Box className="" pl={0}>
          <Box
            component="p"
            className="font-dark"
            sx={{ fontSize: 17, fontWeight: 500, marginBottom: "10px" }}
          >
            {memberList?.location?.length > 0
              ? `Locations (${memberList?.location?.length})`
              : " No Locations"}
          </Box>
          {memberList?.location?.length > 0 ? (
            <Box sx={locationSection}>
              {memberList.location.map((data, index) => (
                <div key={index} style={{ color: "#1B1C1D" }}>
                  {data?.display_name}
                </div>
              ))}
            </Box>
          ) : (
            <Box sx={locationSection}>-</Box>
          )}
        </Box>
      </Box>
    </div>
  );
}

export default TeamPopup;
