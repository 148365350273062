import React from "react";
import CommonPerformanceFilter from "../../../containers/listing/CommonPerformanceFilter";

const GeoGridFilter = (props) => {
  const { location, history, match } = props;

  return (
    <div>
      <CommonPerformanceFilter
        history={history}
        location={location}
        match={match}
      />
    </div>
  );
};

export default GeoGridFilter;
