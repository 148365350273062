import * as types from '../actionTypes';
import * as URLS from '../../libs/apiUrls';
import axios_instance from '../../libs/interseptor';
import * as commonAction from '../common/commonAction';
import * as utils from '../../libs/utils';

export function createCampaignSuccess(data) {
  return { type: types.CREATE_CAMPAIGN_SUCCESS, data };
}

export function createCampaignFailure(data) {
  return { type: types.CREATE_CAMPAIGN_FAILURE, data };
}

export const createCampaign = (data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(URLS.CAMPAIGN, data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(createCampaignSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(createCampaignFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function sendFeedbackSuccess(data) {
  return { type: types.SEND_FEEDBACK_SUCCESS, data };
}

export function sendFeedbackFailure(data) {
  return { type: types.SEND_FEEDBACK_FAILURE, data };
}

export const sendFeedback = (id, data, params) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    const url = params ?  `${URLS.REVIEWS_DETAILS}${id}/feedback/` : `${URLS.FEEDBACK_INVITE}${id}/feedback/`;
    return axios_instance.post(url, data, {params})
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(sendFeedbackSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(sendFeedbackFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function postCampaignSuccess(data) {
  return { type: types.POST_CAMPAIGN_SUCCESS, data };
}

export function postCampaignFailure(error) {
  let data = {};
  data.error = error.response.data;
  return { type: types.POST_CAMPAIGN_FAILURE, data};
}

export const postCampaign = (data, branchId) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(utils.format(URLS.CAMPAIGN_DETAIL,[branchId]), data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(postCampaignSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(postCampaignFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getAllCampaignSuccess(data) {
  return { type: types.GET_ALL_CAMPAIGN_SUCCESS, data };
}

export function getAllCampaignFailure(data) {
  return { type: types.GET_ALL_CAMPAIGN_FAILURE, data };
}

export const getAllCampaign = (params, branchId) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.CAMPAIGN_DETAIL,[branchId]), { params })
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getAllCampaignSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getAllCampaignFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function deleteCampaignSuccess(data) {
  return { type: types.DELETE_CAMPAIGN_SUCCESS, data };
}

export function deleteCampaignFailure(data) {
  return { type: types.DELETE_CAMPAIGN_FAILURE, data };
}

export const deleteCampaign = (id, branchId) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    const url = `${URLS.CAMPAIGN}${branchId}/Review/${id}/`;
    return axios_instance.delete(url)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(deleteCampaignSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(deleteCampaignFailure(err));
          throw err.response.data;
        }
      );
  };
};


export function getCampaignNewsLetterSuccess(data) {
  return { type: types.GET_CAMPAIGN_NEWSLETTER_SUCCESS, data };
}

export function getCampaignNewsLetterFailure(data) {
  return { type: types.GET_CAMPAIGN_NEWSLETTER_FAILURE, data };
}

export const getCampaignNewsLetter = (params, branchId) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(utils.format(URLS.CAMPAIGN_NEWSLETTER_DETAIL,[branchId]), { params })
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getCampaignNewsLetterSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getCampaignNewsLetterFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function deleteNewsLetterCampaignSuccess(data) {
  return { type: types.DELETE_NEWS_LETTER_CAMPAIGN_SUCCESS, data };
}

export function deleteNewsLetterCampaignFailure(data) {
  return { type: types.DELETE_NEWS_LETTER_CAMPAIGN_FAILURE, data };
}

export const deleteNewsLetterCampaign = (id, branchId) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    const url = `${URLS.CAMPAIGN}${branchId}/Newsletter/${id}/`;
    return axios_instance.delete(url)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(deleteNewsLetterCampaignSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(deleteNewsLetterCampaignFailure(err));
          throw err.response.data;
        }
      );
  };
};


export function postNewsLetterCampaignSuccess(data) {
  return { type: types.POST_NEWS_LETTER_CAMPAIGN_SUCCESS, data };
}

export function postNewsLetterCampaignFailure(error) {
  let data = {};
  data.error = error.response.data;
  return { type: types.POST_NEWS_LETTER_CAMPAIGN_FAILURE, data};
}

export const postNewsLetterCampaign = (data, branchId) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(utils.format(URLS.CAMPAIGN_NEWSLETTER_DETAIL,[branchId]), data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(postNewsLetterCampaignSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(postNewsLetterCampaignFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getNewsLetterInvitedMembersSuccess(data) {
  return { type: types.GET_NEWS_LETTER_INVITED_MEMBERS_SUCCESS, data };
}

export function getNewsLetterInvitedMembersFailure(data) {
  return { type: types.GET_NEWS_LETTER_INVITED_MEMBERS_FAILURE, data };
}

export const getNewsLetterInvitedMembers = (data, params) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    const url = URLS.CAMPAIGN + data.branch_id + '/Newsletter/' + data.id + '/' + 'report/';
    return axios_instance.get(url, { params })
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getNewsLetterInvitedMembersSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getNewsLetterInvitedMembersFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getFeedbackInfoSuccess(data) {
  return { type: types.GET_CAMPAIGN_INFO_SUCCESS, data };
}

export function getFeedbackInfoFailure(data) {
  return { type: types.GET_CAMPAIGN_INFO_FAILURE, data };
}

export const getFeedbackInfo = (id) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    const url = URLS.FEEDBACK_INVITE + id + '/feedback/';
    return axios_instance.get(url)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getFeedbackInfoSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getFeedbackInfoFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getGenericTemplateFeedbackSuccess(data) {
  return { type: types.GET_GENERIC_TEMPLATE_FEEDBACK_SUCCESS, data };
}

export function getGenericTemplateFeedbackFailure(data) {
  return { type: types.GET_GENERIC_TEMPLATE_FEEDBACK_FAILURE, data };
}

export const getGenericTemplateFeedback = (id, params) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    const url = URLS.REVIEWS_DETAILS + id + '/feedback/';
    return axios_instance.get(url, {params})
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getGenericTemplateFeedbackSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getGenericTemplateFeedbackFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function reviewInviteSuccess(data) {
  return { type: types.REVIEW_INVITE_SUCCESS, data};
}

export function reviewInviteFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.REVIEW_INVITE_FAILURE, data };
}

export const reviewInvite = (branchId, data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    const url = utils.format(URLS.SEND_INVITE, [branchId]);
    return axios_instance.post(url, data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(reviewInviteSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(reviewInviteFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function getInvitedMembersSuccess(data) {
  return { type: types.GET_INVITED_MEMBERS_SUCCESS, data };
}

export function getInvitedMembersFailure(data) {
  return { type: types.GET_INVITED_MEMBERS_FAILURE, data };
}

export const getInvitedMembers = (data, params) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    const url = URLS.CAMPAIGN + data.branch_id + '/Review/' + data.id + '/' + 'report/';
    return axios_instance.get(url, { params })
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getInvitedMembersSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getInvitedMembersFailure(err));
          throw err.response.data;
        }
      );
  };
};

export function followUpSuccess(data) {
  return { type: types.FOLLOW_UP_SUCCESS, data };
}

export function followUpFailure(data) {
  return { type: types.FOLLOW_UP_FAILURE, data };
}

export const followUp = (data,branchId,campaignId) => {
  return (dispatch) => {
    const url = utils.format(URLS.CAMPAIGN_FOLLOW_UP,[branchId,campaignId]);
    return axios_instance.post(url,data)
      .then(
        (res) => {
          dispatch(followUpSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch(followUpFailure(err));
          throw err.response.data;
        }
      );
  };
};

export const unsubscribe = (id) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    const url = utils.format(URLS.UNSUBSCRIBE, [id]);
    return axios_instance.post(url)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          throw err.response.data;
        }
      );
  };
};
