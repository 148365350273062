import React from "react";

const FaceSeven = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31 16C31 24.2843 24.2843 31 16 31C7.71573 31 1 24.2843 1 16C1 7.71573 7.71573 1 16 1C24.2843 1 31 7.71573 31 16Z"
        fill="white"
        stroke="#BFDC50"
        stroke-width="2"
      />
      <path
        d="M9.80557 14.5584C11.0444 14.5584 12.0486 13.5542 12.0486 12.3153C12.0486 11.0765 11.0444 10.0723 9.80557 10.0723C8.56676 10.0723 7.5625 11.0765 7.5625 12.3153C7.5625 13.5542 8.56676 14.5584 9.80557 14.5584Z"
        fill="#1B1C1D"
      />
      <path
        d="M22.5559 14.5631C23.7787 14.3646 24.6091 13.2124 24.4107 11.9896C24.2122 10.7668 23.06 9.93634 21.8372 10.1348C20.6143 10.3333 19.7839 11.4854 19.9824 12.7083C20.1808 13.9311 21.333 14.7615 22.5559 14.5631Z"
        fill="#1B1C1D"
      />
      <path
        d="M12.0496 9.18512C11.9406 9.18517 11.8303 9.15581 11.7308 9.09422C9.24178 7.55336 7.49812 9.02184 7.48077 9.03681C7.22751 9.25638 6.84433 9.22892 6.62481 8.97566C6.40534 8.72246 6.43271 8.33922 6.68596 8.11975C6.75003 8.06424 7.34039 7.57008 8.33041 7.3177C9.24693 7.08418 10.6916 7.02362 12.3696 8.06235C12.6545 8.23875 12.7425 8.61275 12.5662 8.89769C12.4513 9.08309 12.2527 9.18512 12.0496 9.18512Z"
        fill="#1B1C1D"
      />
      <path
        d="M24.9187 9.22223C24.8097 9.22228 24.6994 9.19287 24.5999 9.13133C22.1109 7.59047 20.3672 9.059 20.3499 9.07392C20.0967 9.29349 19.7135 9.26602 19.494 9.01277C19.2745 8.75957 19.3018 8.37633 19.5551 8.15686C19.6192 8.10135 20.2095 7.60719 21.1995 7.35481C22.116 7.12129 23.5607 7.06072 25.2387 8.09945C25.5236 8.27586 25.6116 8.64985 25.4353 8.9348C25.3204 9.1202 25.1219 9.22223 24.9187 9.22223Z"
        fill="#1B1C1D"
      />
      <path
        d="M24.3641 21.2227C24.3337 21.0196 24.2001 20.8402 23.9974 20.7304C23.6424 20.5381 23.1735 20.5975 22.9066 20.8688C20.6601 23.1518 19.3439 24.136 15.9496 24.2156C12.0378 24.3073 9.15775 20.7758 9.13005 20.7433C8.88729 20.4543 8.42322 20.3667 8.05063 20.5394C7.8368 20.6384 7.68825 20.8115 7.64304 21.0146C7.60192 21.1993 7.6515 21.3922 7.77924 21.5442C7.81307 21.5844 8.62344 22.5397 10.002 23.5097C11.8663 24.8214 13.9026 25.5431 15.8907 25.5969C15.9745 25.5992 16.0577 25.6004 16.1413 25.6004C18.9644 25.6004 21.67 24.3055 24.1828 21.7517C24.3265 21.6058 24.3925 21.4129 24.3641 21.2227Z"
        fill="#1B1C1D"
      />
    </svg>
  );
};
export default FaceSeven;
