import React from "react";
import { Row, Col, Card } from "reactstrap";
import GmailIcon from "../../images/gmail_logo.png";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as URLS from "../../libs/apiUrls";

import * as SocialSetupActions from "../../actions/listing/SocialLoginAction";
import axios_instance from "../../libs/interseptor";
import { withRouter } from "react-router";
import LoaderComponent from "../common/loaderComponent";

class SetupLoginSocialAccounts extends React.Component {
  constructor(props) {
    super(props);
    console.log("props------------------------------", props);
    this.state = {
      business_id: 53,
      btnText: "Login to Gmail",
      name: "",
      connected: false,
      loader: false,
    };
  }

  // componentDidMount() {
  //   this.props.business!==undefined && this.checkLoggedIn();
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   if (prevState.business_id !== this.state.business_id) {
  //     this.checkLoggedIn();
  //   }
  // }

  gmailConnect(code) {
    console.log("this.state.business_idthis.state.business_id", this.state.business_id);
    const details = {
      code: code,
      business: this.state.business_id,
    };
    return axios_instance
      .post(`${URLS.SOCIAL}gmail/`, details)
      .then((res) => {
        this.checkLoggedIn();
        console.log(res);
        window.removeEventListener("message", (data) => console.log(data));
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loader: false,
        });
      });
  }

  connectSocial(data) {
    console.log("datatadatatdatdtatdatdtad-------------", data);
    this.gmailConnect(data.data.code);
  }

  getAuthUrl(data) {
    this.setState({
      loader: true,
    });
    this.props.SocialSetupActions.getGmailLoginUrl({ provider: data })
      .then(() => {
        console.log("---------------------", this.props);
        const { socialSetupList } = this.props;
        console.log(socialSetupList, "----------------sdsdsdsdsdd");
        const authUrl = socialSetupList.auth_url;
        this.newWindow = window.open(
          authUrl,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400"
        );
        this.setState({ provider: data, loader: false });
        // window.addEventListener('message',(data)=>this.connectSocial(data));
        window.addEventListener("message", (data) => this.connectSocial(data));
        console.log(
          "datadatadatadatadatadatadatadata///////////////////////",
          data
        );
      })
      .catch(() => {
        this.setState({
          loader: false,
        });
      });
  }

  checkLoggedIn() {
    this.setState({
      loader: true,
    });
    const {
      match: {
        params: { business_id },
      },
    } = this.props;
    axios_instance
      .get(`${URLS.SOCIAL}business/${business_id}/gmail/status/`)
      .then((res) => {
        this.setState({
          loader: false,
          connected: res.data.connected,
          name: res.data.name,
        });
      })
      .catch((err) => {
        this.setState({
          loader: false,
        });
        console.log(err);
      });
  }

  // logOut() {
  //   const { match: { params: { business_id } } } = this.props;
  //   this.setState({
  //     btnText: 'Login to Gmail'
  //   });
  //   axios_instance.post(`${URLS.SOCIAL}business/${business_id}/gmail/status/`)
  //     .then(()=>{
  //       this.checkLoggedIn();
  //     })
  //     .catch((err)=>{
  //       this.setState({
  //         loader: false
  //       });
  //       console.log(err);
  //     });
  // }

  render() {
    return (
      <div className="py-2">
        <LoaderComponent loader={this.state.loader} />
        <Card body>
          <Row>
            <Col>
              <div className="text-center mb-3">
                <h5 className="mt-2 font-weight-normal">Connect Gmail</h5>
              <img src={GmailIcon} height="60" />
              </div>
              <div className="text-center mt-4 mb-3">
                {this.state.connected === false ? (
                  <span
                    className="text-center mt-3 google-button cursor-pointer"
                    onClick={() => this.getAuthUrl("gmail")}
                  >
                    {this.state.btnText}
                  </span>
                ) : (
                  <div>
                    <p style={{ fontSize: "14px", fontWeight: "600" }}>
                      Email Id: <i>{`${this.state.name}`}</i>
                    </p>
                    <button
                      onClick={() => this.logOut()}
                      style={{
                        padding: "10px 40px",
                        border: "none",
                        outline: "none",
                        borderRadius: "4px",
                        backgroundColor: "#48f",
                        fontSize: "16px",
                        color: "#fff",
                        fontWeight: "600",
                      }}
                    >
                      Log Out
                    </button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
    socialSetupList: state.socialLoginSetup.list,
    isLoggedIn: state.socialLoginSetup.isLoggedIn,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    SocialSetupActions: bindActionCreators(SocialSetupActions, dispatch),
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    SetupLoginSocialAccounts
  )
);
