import React, { useState } from "react";
import { FPButton } from "../../components/common/new";
import { Box } from "@material-ui/core";
import tagsvg from "../../images/svg/Tag.svg";
import { FixedSizeList as List } from "react-window";
import FormModal from "../../components/common/FormModal";
import AddRequestTypeModal from "./AddRequestTypeModal";
import ConfirmationModal from "../../components/common/ConfirmationModal";
import check_Circle from "../../images/svg/check-successfull.svg";
import AddRequestSuccessModal from "../../components/common/TagEditModal";

function ManageRequestType(props) {
  const {
    requestTypeOption,
    actions,
    match: {
      params: { business_id },
    },
  } = props;
  const [editId, setEditId] = useState(null);
  const [requestTypeModal, setRequestTypeModal] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [addRequestTypeModal, setAddRequestTypeModal] = useState(false);

  const toggleAddRequest = () => {
    setAddRequestTypeModal(false);
  };

  const addNewRequest = ({ item, ticket_id }) => {
    const formData = new FormData();
    Object.keys(item).forEach((key) => {
      formData.append(key, item[key]);
    });
    if (ticket_id) {
      actions
        .patchServiceRequestType(formData, business_id, ticket_id)
        .then(() => {
          handleCloseRequestType();
          actions.ServiceRequestTypeList(business_id);
        });
    } else {
      actions.AddServiceRequestType(formData, business_id).then(() => {
        setAddRequestTypeModal(true);
        handleCloseRequestType();
        actions.ServiceRequestTypeList(business_id);
      });
    }
  };

  const handleDeleteTag = (id) => {
    actions.deleteServiceRequest(business_id, id).then(() => {
      actions.ServiceRequestTypeList(business_id).then(() => {
      });
    });
  };

  const handleCloseRequestType = () => {
    setRequestTypeModal(false);
    setEditId(null);
  };

  const handleOpenRequestType = () => {
    setRequestTypeModal(true);
  };

  const handleEdit = (tag) => {
    setEditId(tag);
    handleOpenRequestType();
  };

  const handleClick = (data) => {
    setDeleteId(data.id);
    setDeleteData(data);
    setIsConfirm(true);
  };

  const toggleConfirm = () => {
    handleDeleteTag(deleteId);
    setIsConfirm(false);
  };

  const Row = ({ index, style }) => {
    const tag = requestTypeOption && requestTypeOption[index];
    return (
      <div
        key={tag.id}
        style={style}
        className="filter-tags d-flex justify-content-between align-items-center pr-3 pb-0"
      >
        <div className="d-flex gap-10">
          <img src={tagsvg} alt="tag-icon" />
          <div className="manageTagModelText" style={{ fontSize: "14px" }}>
            {tag.title}
          </div>
        </div>

        {!tag?.is_default && (
          <div className="d-flex gap-30">
            <div
              role="button"
              className="manageTagModelText"
              style={{ fontSize: "14px" }}
              onClick={() => handleEdit(tag)}
            >
              Edit
            </div>
            <Box
              component="span"
              className="manageTagModelText"
              role="button"
              sx={{ color: "#F04438", fontSize: "14px" }}
              onClick={() => handleClick(tag)}
            >
              Delete
            </Box>
          </div>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div style={{ textAlign: "right", marginTop: "10px" }}>
        <FPButton
          label="Add Request Type"
          style={{ fontSize: "10px", height: "30px" }}
          onClick={handleOpenRequestType}
        />
      </div>
      <div className="d-flex flex-column gap-15">
        {requestTypeOption?.length > 0 ? (
          <List
            height={500}
            itemCount={requestTypeOption?.length}
            itemSize={45}
            width={"100%"}
          >
            {Row}
          </List>
        ) : (
          <div style={{ marginBottom: "25px", textAlign: "center" }}>
            <span>No tags found</span>
          </div>
        )}
      </div>
      <FormModal
        isOpen={requestTypeModal}
        toggle={handleCloseRequestType}
        heading={editId ? "Edit Request Type" : "Add New Request Type"}
        width="580px"
        paddingDense
      >
        <AddRequestTypeModal addNewRequest={addNewRequest} editId={editId} />
      </FormModal>
      {addRequestTypeModal && (
        <AddRequestSuccessModal
          isOpen={addRequestTypeModal}
          toggle={toggleAddRequest}
          width="380px"
          showCloseBtn={false}
        >
          <div className="text-center">
            <Box
              component="img"
              src={check_Circle}
              sx={{ textAlign: "center" }}
            />
            <Box
              className="mt-2"
              sx={{ color: "#1B1C1D", fontWeight: 500, fontSize: "17px" }}
            >
              Request Type Added Successfully
            </Box>
          </div>
        </AddRequestSuccessModal>
      )}
      <ConfirmationModal
        header="Do you want to continue?"
        style={{
          fontWeight: 600,
          fontSize: "18px",
          color: "#1B1C1D",
          marginBottom: "10px",
        }}
        subHeader={`This Request Type is connected with ${deleteData?.open_count} open and ${deleteData?.close_count} resolved service requests. Deleting this request type will delete all service requests associated with it.`}
        isOpen={isConfirm}
        toggle={() => setIsConfirm(!isConfirm)}
        onConfirm={() => toggleConfirm()}
        paddingDense
      />
    </React.Fragment>
  );
}

export default ManageRequestType;
