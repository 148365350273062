import React from 'react';

const Redirect = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.25 17.5H3.75C3.41862 17.4996 3.10093 17.3677 2.86661 17.1334C2.63229 16.8991 2.50045 16.5814 2.5 16.25V3.75C2.50045 3.41862 2.63229 3.10093 2.86661 2.86661C3.10093 2.63229 3.41862 2.50045 3.75 2.5H10V3.75H3.75V16.25H16.25V10H17.5V16.25C17.4996 16.5814 17.3677 16.8991 17.1334 17.1334C16.8991 17.3677 16.5814 17.4996 16.25 17.5Z"
        fill="#161616"
      />
      <path
        d="M12.5 1.25V2.5H16.6163L11.25 7.86625L12.1337 8.75L17.5 3.38375V7.5H18.75V1.25H12.5Z"
        fill="#161616"
      />
    </svg>
  );
};

export default Redirect;
