import React from "react";
import plus from "../../images/svg/plus-black.svg";
import { FaPlus } from "react-icons/fa6";
import { Box } from "@material-ui/core";

function WeeklyPost(props) {
  const { calendarDate, activePost, addPostHandler, openPostDetails } = props;
  return (
    <Box className="d-flex day_DateBox mt-4">
      {calendarDate &&
        calendarDate.map((cards, index) => {
          const allImages = cards.images;
          // Check relevant data is present ?
          const ifPostData = cards.leftIcon || cards.rightIcon;
          return (
            <>
              <Box
                component="span"
                sx={{
                  width: "400px",
                  // height: "100vh",
                }}
                onClick={() => addPostHandler(index)}
              >
                <Box
                  component="span"
                  className="d-flex justify-content-center"
                  sx={{
                    color: "#00B5AD",
                    textAlign: "center",
                    fontSize: "13px",
                    fontWeight: activePost === index ? 600 : 400,
                  }}
                >
                  {cards.weekDay}&nbsp;{cards.date}
                </Box>

                <Box
                  sx={{
                    height: "100vh",
                    borderRight: "1px dashed #BFC2CB",
                    borderTop: "1px solid #EAECF0",
                    // backgroundColor: activePost ? "#DDEFF0" : "transparent",
                    backgroundColor:
                      activePost === index ? "#DDEFF0" : "#F9FAFB",
                  }}
                >
                  <Box className="simpleBtn">
                    Add Post <FaPlus />
                  </Box>
                  {ifPostData && (
                    <div className="d-flex flex-column" >
                      <div className="postBox d-flex" onClick={openPostDetails}>
                        <div className="align-center justify-content-between">
                          <Box className="align-center">
                            <Box
                              component="span"
                              sx={{ color: "#7A8193", fontSize: "13px" }}
                            >
                              {cards.time}
                            </Box>
                            <Box
                              component="img"
                              src={cards.leftIconI}
                              sx={{
                                width: "14px",
                                height: "14px",
                                marginLeft: "6px",
                              }}
                            />
                          </Box>
                          <Box
                            component="img"
                            src={cards.rightIcon}
                            sx={{
                              width: "17px",
                              height: "17px",
                              marginLeft: "6px",
                            }}
                          />
                        </div>
                        {cards.divider}
                        <Box sx={{ fontSize: "13px", color: "#1B1C1D" }}>
                          {cards.offerI}
                        </Box>
                        <div className="position-relative d-flex">
                          {cards.images?.slice(0, 1).map((img, index) => {
                            return (
                              <>
                                <Box
                                  component="img"
                                  src={img}
                                  key={index}
                                  sx={{
                                    width: "70px",
                                    height: "70px",
                                    borderRadius: "8px",
                                    marginTop: "4px",
                                    marginRight: "10px",
                                  }}
                                />

                                {index === 1 && allImages.length > 2 ? (
                                  <div className="imageCount">
                                    <Box
                                      component="img"
                                      src={plus}
                                      sx={{ width: "17px", height: "23px" }}
                                    />
                                    <span>{allImages.slice(2).length}</span>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            );
                          })}
                        </div>
                        <Box className="align-center mt-5 gap-6">
                          <Box
                            component="img"
                            src={cards.editImg}
                            sx={{ marginTop: "2px", width: "16px" }}
                          />

                          <Box
                            component="img"
                            src={cards.deletImg}
                            sx={{ width: "16px" }}
                          />
                        </Box>
                      </div>
                      {allImages.length > 2 ? (
                        <div className="postBox d-flex" onClick={openPostDetails}>
                          <div className="align-center justify-content-between">
                            <Box className="align-center">
                              <Box
                                component="span"
                                sx={{ color: "#7A8193", fontSize: "13px" }}
                              >
                                {cards.time}
                              </Box>
                              <Box
                                component="img"
                                src={cards.leftIconII}
                                sx={{
                                  // width: "14px",
                                  height: "18px",
                                  marginLeft: "6px",
                                  marginTop: "1px",
                                }}
                              />
                            </Box>
                            <Box
                              component="img"
                              src={cards.rightIcon}
                              sx={{
                                width: "17px",
                                height: "17px",
                                marginLeft: "6px",
                              }}
                            />
                          </div>
                          {cards.divider}
                          <Box sx={{ fontSize: "13px", color: "#1B1C1D" }}>
                            {cards.offerII}
                          </Box>
                          <div className="position-relative d-flex">
                            {cards.images?.slice(1).map((img, index) => {
                              return (
                                <>
                                  <Box
                                    component="img"
                                    src={img}
                                    key={index}
                                    sx={{
                                      width: "70px",
                                      height: "70px",
                                      borderRadius: "8px",
                                      marginTop: "4px",
                                      marginRight: "10px",
                                    }}
                                  />

                                  {index === 1 && allImages.length > 2 ? (
                                    <div className="imageCount">
                                      <Box
                                        component="img"
                                        src={plus}
                                        sx={{ width: "17px", height: "23px" }}
                                      />
                                      <span>{allImages.slice(2).length}</span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })}
                          </div>
                          <Box className="align-center mt-5 gap-6">
                            <Box
                              component="img"
                              src={cards.editImg}
                              sx={{ marginTop: "2px", width: "16px" }}
                            />

                            <Box
                              component="img"
                              src={cards.deletImg}
                              sx={{ width: "16px" }}
                            />
                          </Box>
                        </div>
                      ) : null}
                    </div>
                  )}
                </Box>
              </Box>
            </>
          );
        })}
    </Box>
  );
}

export default WeeklyPost;
