import React from "react";
import { Divider } from "semantic-ui-react";
import { FPButton } from "../../common/new";
import { IconButton } from "@material-ui/core";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import "./genericTemplateForm.css";
import ErrorMessage from "../../common/new/forms/ui/ErrorMessage";
import { Label, Input, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import deleteIcon from "../../../images/svg/trash.svg";

const RemoveBtn = ({ onClick, disabled, iconType }) => (
  <IconButton size="small" disabled={disabled} onClick={onClick}>
    {iconType === "bin" ? <img src={deleteIcon} role="button" /> : <CloseIcon style={{ height: 17, width: 17 }} />}
  </IconButton>
);

const AddOptionBtn = ({ disabled, onClick }) => (
  <FPButton
    size="small"
    fontWeight={400}
    label="Add Option"
    variant="default"
    type="button"
    className="pl-0 mt-3"
    startIcon={<AddOutlinedIcon />}
    disabled={disabled}
    onClick={onClick}
  />
);

const QuestionActions = ({ isEdit, onDone, onEdit, onRequired, onDelete, id, defaultRequired, errorMessage }) => (
  <section>
    <Divider className="mt-1" />
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex align-items-center gap-20">
        {isEdit === false ? (
          <FPButton type="button" onClick={onDone} size="small" label="Done" />
        ) : (
          <FPButton
            endIcon={<EditOutlinedIcon style={{ height: 16, width: 16 }} />}
            type="button"
            onClick={onEdit}
            size="small"
            label="Edit"
          />
        )}
        <FormGroup style={{ minHeight: "unset" }}>
          <Input
            disabled={isEdit}
            id={id}
            defaultChecked={defaultRequired}
            name="checkbox"
            type="checkbox"
            onClick={onRequired}
          />
          <Label readOnly={isEdit} for={id}>
            Required
          </Label>
        </FormGroup>
      </div>

      <RemoveBtn iconType="bin" onClick={onDelete} disabled={!(isEdit === false)} />
    </div>
    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </section>
);

RemoveBtn.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  iconType: PropTypes.oneOf(["bin", "close"]).isRequired,
};

AddOptionBtn.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

QuestionActions.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRequired: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  defaultRequired: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
};

export { RemoveBtn, AddOptionBtn, QuestionActions };
