import React, { useState, useRef, useEffect } from "react";
import Wrapper from "./Wrapper";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import ReactSpeedometer from "react-d3-speedometer";
import { Box } from "@material-ui/core";
import "./competitor.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import smile from "../../images/svg/smile.svg";
import nervous from "../../images/svg/nervous.svg";
import star from "../../images/svg/star.svg";
import minus from "../../images/svg/minusSvg.svg";
import equal from "../../images/svg/equaltoSvg.svg";
import { Divider } from "semantic-ui-react";
import NetPromoterScoreTable from "./NetPromoterScoreTable";

const NetPromoterScore = ({ location, history, match, businessDetails }) => {
  const matches = useMediaQuery("(min-width:1441px)");
  const [speedometerDimension, setSpeedometerDimension] = useState({
    height: 0,
    width: 0,
  });

  const SpeedometerData = [
    { username: "KFC", review: "500" },
    { username: "Burger King", review: "500" },
    { username: "Haldiram", review: "500" },
    { username: "Bikanervala", review: "500" },
    { username: "Pizza Hut", review: "500" },
    { username: "Dilli Darbar", review: "500" },
  ];

  const meterRef = useRef(null);

  useEffect(() => {
    if (meterRef && meterRef.current) {
      const { offsetHeight, offsetWidth } = meterRef.current;
      setSpeedometerDimension({ height: offsetHeight, width: offsetWidth });
    }
  }, [meterRef]);

  return (
    <Wrapper
      title={"Net Promoter Score"}
      subHeading={"* Above details is as on 2024-05-21"}
      location={location}
      history={history}
      match={match}
    >
      {(viewType) => (
        <>
          {viewType === "Graph" ? (
            <>
              <div>
                <div
                  className="d-flex"
                  style={{
                    border: "2px solid #7F56D9",
                    margin: "20px 20px 0 20px",
                    borderRadius: "12px",
                  }}
                >
                  <div className="col-6 pl-10 pr-10">
                    <CardWrapper
                      headerTitle={
                        <div className="d-flex justify-content-between align-items-center">
                          <Box component="span">Mughal Delight</Box>
                          <Box component="span" sx={{ color: "#7A8193" }}>
                            500 Reviews
                          </Box>
                        </div>
                      }
                      className="h-100 p-0"
                      headerClass="font-dark pt-3 pb-1 px-3"
                    >
                      <div className="chart mt-4 p-3 fp-gaudge-chart d-flex justify-content-center">
                        <ReactSpeedometer
                          height={matches ? 150 : 210}
                          width={matches ? 250 : 350}
                          minValue={-100}
                          maxValue={100}
                          value={0}
                          needleColor="#1A2544"
                          needleHeightRatio={0.6}
                          needleTransitionDuration={1000}
                          segments={10}
                          maxSegmentLabels={10}
                          segmentColors={[
                            "#FF3400",
                            "#FF6B01",
                            "#FE7F02",
                            "#FFA802",
                            "#FFD205",
                            "#FCFD09",
                            "#EEFC0A",
                            "#DBFC05",
                            "#B6FB1F",
                            "#7BFA0D",
                          ]}
                          textColor="#1A2544"
                          valueTextFontSize="25px"
                          valueTextFontWeight="700"
                        />
                      </div>
                    </CardWrapper>
                  </div>
                  <div className="col-6 pl-10 pr-10">
                    <CardWrapper
                      headerTitle={"NPS Calculation"}
                      className="h-100 p-0"
                      headerClass="font-dark pt-3 pb-1 px-3"
                    >
                      <div className="container">
                        <div className="d-flex mt-5 responsive-boxes">
                          <div className="box-item">
                            <div className="icon-container">
                              <img src={smile} className="npsIcon" />
                            </div>
                            <Box className="npsBox" sx={{ background: "#EDFCF2", color: "#81E050" }}>
                              <Box component="span">76 %</Box>
                              <Box
                                sx={{
                                  fontSize: "14px",
                                }}
                                component="span"
                              >
                                % of Promoters
                              </Box>
                            </Box>
                          </div>
                          <div className="symbol-container">
                            <img src={minus} style={{}} />
                          </div>
                          <div className="box-item">
                            <div className="icon-container">
                              <img src={nervous} className="npsIcon" />
                            </div>
                            <Box className="npsBox" sx={{ background: "#FEF3F2", color: "#EC554E" }}>
                              <Box component="span">15 %</Box>
                              <Box
                                sx={{
                                  fontSize: "14px",
                                }}
                                component="span"
                              >
                                % of Promoters
                              </Box>
                            </Box>
                          </div>
                          <div className="symbol-container">
                            <img src={equal} style={{}} />
                          </div>
                          <div className="box-item">
                            <div className="icon-container">
                              <img src={star} className="npsIcon" />
                            </div>
                            <Box className="npsBox" sx={{ background: "#F2F4F7", color: "#1B1C1D" }}>
                              <Box component="span">61</Box>
                              <Box
                                sx={{
                                  fontSize: "14px",
                                }}
                                component="span"
                              >
                                Net Promoter Score
                              </Box>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </CardWrapper>
                  </div>
                </div>
              </div>

              <div className="row p-4">
                {SpeedometerData.map((item, index) => (
                  <div className="col-4 pr-10 pl-10 pb-4">
                    <CardWrapper
                      headerTitle={
                        <div className="d-flex justify-content-between align-items-center">
                          <Box component="span" sx={{ color: "#1B1C1D" }}>
                            {item.username}
                          </Box>
                          <Box component="span" sx={{ color: "#7A8193" }}>
                            {item.review} Reviews
                          </Box>
                        </div>
                      }
                      className="fp-border-light px-3 pt-3"
                      // headerClass="font-dark pt-3 pb-1"
                    >
                      <Box
                        className="d-flex justify-content-center align-items-center  speedometer"
                        ref={meterRef}
                        sx={{
                          height: 250,
                        }}
                      >
                        <ReactSpeedometer
                          key={`${speedometerDimension.height} - ${speedometerDimension.height} - ${index}`}
                          height={
                            matches ? (speedometerDimension.width - 50) / 2 : (speedometerDimension.width + 40) / 2
                          }
                          width={matches ? speedometerDimension.width - 100 : speedometerDimension.width - 50}
                          minValue={-100}
                          maxValue={100}
                          value={50}
                          needleColor="#1A2544"
                          needleHeightRatio={0.6}
                          needleTransitionDuration={1000}
                          segments={10}
                          maxSegmentLabels={10}
                          segmentColors={[
                            "#FF3400",
                            "#FF6B01",
                            "#FE7F02",
                            "#FFA802",
                            "#FFD205",
                            "#FCFD09",
                            "#EEFC0A",
                            "#DBFC05",
                            "#B6FB1F",
                            "#7BFA0D",
                          ]}
                          textColor="#1A2544"
                          valueTextFontSize="25px"
                          valueTextFontWeight="700"
                        />
                      </Box>
                    </CardWrapper>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <NetPromoterScoreTable businessDetails={businessDetails} />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default NetPromoterScore;
