import React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import TicketAutomation from "../../components/tickets/ticketAutomation";
import TicketResolutions from "../../components/tickets/TicketResolutions";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { FPButton } from "../common/new";
import { FaPlus } from "react-icons/fa6";
import "./central-branch.css";
import TicketEscalation from "./TicketEscalation";
import TicketAutoResolution from "./TicketAutoResolution";

class SetupTicketsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTicketTab: "1",
      openEscalationForm: false,
      openServiceEscalationForm: false
    };
  }

  // componentDidMount() {
  //   const { activeTicketTab } = this.state;

  // }

  // componentDidUpdate(prevProps, prevState) {
  //   const { location: { search } } = this.props;
  //   const { location: { search: prevSearch } } = prevProps;
  //   const { activeTicketTab } = this.state;
  //   const { activeTicketTab: prevActiveTicketTab } = prevState;
  // }

  toggleTicketTab(tab) {
    const { activeTicketTab } = this.state;
    if (activeTicketTab !== tab) {
      this.setState({ activeTicketTab: tab });
    }
  }

  render() {
    const {
      rulesList,
      businessId,
      lockMessage,
      loader,
      enterpriseList,
      addRules,
      ordering,
      branchList,
      count,
      resolutionEnabled,
      resolutionData,
      resolutionDataId,
      surveyFormLoading,
      ticketEscalationList,
      addTicketEscalation,
      editTicketEscalation,
      deleteTicketEscalation,
      designation,
      businessInfo
    } = this.props;
    return (
      <>
        <p className="mb-0 fp-card-header my-4">Service Ticketing Rules</p>
        <CardWrapper
          showDivider={false}
          headerTitle={
            <div className="d-flex justify-content-between align-items-center ticketAssignment">
              <Nav className="Setup_Sub_Nav ">
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTicketTab === "1",
                    })}
                    onClick={() => {
                      this.toggleTicketTab("1");
                    }}
                  >
                    Ticket Assignment Rule
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTicketTab === "2",
                    })}
                    onClick={() => {
                      this.toggleTicketTab("2");
                    }}
                  >
                    Ticket Resolution Survey123
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTicketTab === "3",
                    })}
                    onClick={() => {
                      this.toggleTicketTab("3");
                    }}
                  >
                    Ticket Escalation Rule
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: this.state.activeTicketTab === "4",
                    })}
                    onClick={() => {
                      this.toggleTicketTab("4");
                    }}
                  >
                    Ticket Auto Resolution
                  </NavLink>
                </NavItem>
              </Nav>
              {this.state.activeTicketTab === "1" && rulesList && rulesList.length > 0 && (
                <FPButton
                  className="d-flex ml-auto"
                  label="Add Assignment Rule"
                  size="small"
                  fontSize={15}
                  onClick={this.props.addRules}
                  endIcon={<FaPlus style={{ fontSize: "14px" }} />}
                />
              )}
              {this.state.activeTicketTab === "3" &&
                !this.state.openEscalationForm &&
                ticketEscalationList &&
                ticketEscalationList?.results?.length > 0 && (
                  <FPButton
                    className="d-flex ml-auto"
                    label="Add Escalation Rule"
                    size="small"
                    fontSize={15}
                    onClick={() => this.setState({ openEscalationForm: true })}
                    endIcon={<FaPlus style={{ fontSize: "14px" }} />}
                  />
                )}
            </div>
          }
        >
          <div>
            <TabContent activeTab={this.state.activeTicketTab} className="mt-2">
              <TabPane tabId="1">
                <TicketAutomation
                  rulesList={rulesList}
                  businessId={businessId}
                  lockMessage={lockMessage}
                  loader={loader}
                  enterpriseList={enterpriseList}
                  location={this.props.location}
                  history={this.props.history}
                  addRules={addRules}
                  ordering={ordering}
                  branchList={branchList}
                  count={count}
                  pageSizeCookie="c_ticket_automation"
                />
              </TabPane>
              <TabPane tabId="2">
                <TicketResolutions
                  location={this.props.location}
                  history={this.props.history}
                  businessId={businessId}
                  businessInfo={businessInfo}
                  toggleTicketResolution={this.props.toggleTicketResolution}
                  resolutionEnabled={resolutionEnabled}
                  resolutionData={resolutionData}
                  submitTicketResolutionData={this.props.submitTicketResolutionData}
                  resolutionDataId={resolutionDataId}
                  surveyFormLoading={surveyFormLoading}
                  deleteAllSurvey={this.props.deleteAllSurvey}
                  toggleTicketCommentBox={this.props.toggleTicketCommentBox}
                  toggleProductIssueReasons={this.props.toggleProductIssueReasons}
                  ticketCommentBoxDisabled={this.props.ticketCommentBoxDisabled}
                  enableProductIssueReasons={this.props.enableProductIssueReasons}
                  restrictTicketClosingWithoutSurvey={this.props.restrictTicketClosingWithoutSurvey}
                  toggleRestrictTicketClosingWithoutSurvey={this.props.toggleRestrictTicketClosingWithoutSurvey}
                />
              </TabPane>
              <TabPane tabId="3">
                <TicketEscalation
                  loader={loader}
                  ticketEscalationList={ticketEscalationList}
                  addTicketEscalation={addTicketEscalation}
                  editTicketEscalation={editTicketEscalation}
                  deleteTicketEscalation={deleteTicketEscalation}
                  designation={designation}
                  openEscalationForm={this.state.openEscalationForm}
                  handleEscalationOpen={() => this.setState({ openEscalationForm: true })}
                  handleEscalationClose={() => this.setState({ openEscalationForm: false })}
                />
              </TabPane>
              <TabPane tabId="4">
                <TicketAutoResolution 
                loader={loader}
                ticketEscalationList={ticketEscalationList}
                addTicketEscalation={addTicketEscalation}
                editTicketEscalation={editTicketEscalation}
                deleteTicketEscalation={deleteTicketEscalation}
                designation={designation}
                openServiceEscalationForm={this.state.openServiceEscalationForm}
                handleEscalationOpen={() => this.setState({ openServiceEscalationForm: true })}
                handleEscalationClose={() => this.setState({ openServiceEscalationForm: false })}
                />
              </TabPane>
            </TabContent>
          </div>
        </CardWrapper>
      </>
    );
  }
}

export default SetupTicketsPage;
