/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import useDebounce from "../../../../libs/hooks/useDebounce";
import SearchIcon from "@material-ui/icons/Search";
import FPInput from "../forms/ui/FPInput";
import ClearIcon from "@material-ui/icons/Clear";

const DebounceSearchbar = (props) => {
  const { onChange, className, onClose, searchValue, placeholder, debounceTime, ...rest } = props;
  const [searchEvent, setSearchEvent] = useState("");
  const [value, setValue] = useState("");

  const handleSearch = (event) => {
    setValue(event.target.value);
    setSearchEvent(event);
    debouncedRequest();
    searchValue(event.target.value)
  };

  const debouncedRequest = useDebounce(() => {
    onChange && onChange(searchEvent);
  }, debounceTime);

  return (
    <div className={`fp-search-wrapper ${className}`}>
      <FPInput
        type="text"
        placeholder={placeholder || "Search here..."}
        onChange={handleSearch}
        value={value}
        startIcon={<SearchIcon style={{ color: "#BFC2CB", fontSize: "18px" }} />}
        endIcon={
          <ClearIcon
            role="button"
            onClick={() => {
              setValue("");
              onChange?.(null);
              onClose?.();
              searchValue("")
            }}
            style={{ height: "15px", width: "15px" }}
          />
        }
        {...rest}
      />
    </div>
  );
};

export default DebounceSearchbar;
