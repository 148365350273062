import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { Box, ClickAwayListener } from "@material-ui/core";

const TrendingDateSelector = ({
  onChange,
  className,
  dropDownClassName,
}) => {
  const filterData = [
    {
      display_name: "Past 30 days",
      value: "past_30_days",
      day: 30, 
    },
    {
      display_name: "Past 60 days",
      value: "past_60_days",
      day: 60,
    },
    {
      display_name: "Past 90 days",
      value: "past_90_days",
      day: 90
    },
  ];

  const [selectedDate, setSelected] = useState("Past 30 days");
  const dateFilterRef = useRef(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleChange = (filterItem) => {
    setSelected(filterItem.display_name);
    onChange && onChange(filterItem);
  };

  return (
    <div ref={dateFilterRef} className="">
      <div
        role="listbox"
        aria-expanded={dropdownOpen}
        className={`ui dropdown select-range ${
          (dropdownOpen && "active", className && className)
        }`}
        tabIndex="0"
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <span
          style={selectedDate ? { opacity: 1 } : { opacity: 0.4 }}
          className="fp-flex-grow-1"
        >
          {selectedDate ? selectedDate : "Select Date"}
        </span>
        <i aria-hidden="true" className="dropdown icon"></i>
        {dropdownOpen && (
          <ClickAwayListener onClickAway={() => setDropdownOpen(false)}>
            <Box
              className={classNames(dropDownClassName)}
              sx={{
                boxShadow:
                  "0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
                position: "absolute",
                background: "white",
                padding: "10px 8px",
                borderRadius: "8px",
                top: "30px",
                right: "14px",
                width: "135px",
                "& .item:hover": {
                  backgroundColor: "#0000000d",
                  cursor: "pointer",
                },
              }}
            >
              {filterData &&
                filterData.map((item, key) => {
                  return (
                    <p
                      className="text-center item cursor-pointer m-0"
                      key={key}
                      onClick={() => handleChange(item)}
                      style={{
                        padding: "11px 16px",
                        lineHeight: '1'
                      }}
                    >
                      {item.display_name}
                    </p>
                  );
                })}
            </Box>
          </ClickAwayListener>
        )}
      </div>
    </div>
  );
};

export default connect(null, null, null, {
  forwardRef: true,
})(TrendingDateSelector);
