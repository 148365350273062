import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Select from "react-select";
import queryString from "query-string";

import * as ReviewsActions from "../../actions/business/reviewsActions";
import * as BusinessSetupActions from "../../actions/business/businessSetupActions";
import LoaderComponent from "../common/loaderComponent";
import * as utils from "../../libs/utils";
import { FPButton } from "../common/new";
import BackupOutlinedIcon from "@material-ui/icons/BackupOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import AutoSelect from "../common/AutoSelect";

class UploadReviewModal extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleBranchChange = this.handleBranchChange.bind(this);
    this.uploadReviewFile = this.uploadReviewFile.bind(this);
    this.state = {
      branchId: context.router.route.match.params.branch_id,
      businessId: context.router.route.match.params.business_id,
      showMessage: false,
      message: null,
      messageType: null,
      isLoading: false,
    };
  }

  handleBranchChange(val) {
    if (val) {
      this.setState({ branchId: val });
    }
  }

  componentDidMount() {
    const {
      route: {
        match: {
          params: { branch_id, business_id },
        },
      },
    } = this.context.router;
    const data = {
      business: business_id,
      corporate_branch: branch_id,
    };
    this.props.businessActions.businessBranchOptions(data, true);
  }

  uploadReviewFile(data) {
    const file = data.target.files[0];
    const {
      history,
      location: { pathname, search },
      toggle,
      type,
    } = this.props;
    const query = queryString.parse(search);
    const pageQuery = {
      page: query.page && query.page,
      page_size: query.page_size && query.page_size,
    };
    this.setState({ showMessage: false, isLoading: true });
    const fileExt = file && file.name.split(".").pop();
    const excelExt = ["xls", "xlsx", "xlsm", "csv"];
    if (file.size > 2100000) {
      utils.displayMessage("negative", "File cannot be larger than 2mb.");
      this.setState({
        isLoading: false,
      });
    } else {
      if (excelExt.indexOf(fileExt) > -1) {
        const newDetail = new FormData();
        if (file && type !== "zomato_ors") {
          newDetail.append("csv_file", file);
        }
        if (file && type === "zomato_ors") {
          newDetail.append("excel_file", file);
        }
        const { branchId, businessId } = this.state;
        return this.props.actions
          .reviewCsvUpload(branchId, businessId, newDetail, type)
          .then((res) => {
            console.log("res.data.message : ", res.data.message);

            utils.displayMessage("positive", res.data.message);
            this.setState({ isLoading: false });
            history.push({
              pathname,
              search: queryString.stringify({ ...pageQuery }),
            });
            toggle(type);
          })
          .catch((err) => {
            utils.displayMessage("negative", err.excel_file);
            this.setState({ isLoading: false });
          });
      } else {
        document.getElementById("file").value = null;
        utils.displayMessage("info", "Invalid file type! Allowed extensions are .xls, .xlsx, .csv, .xlsm.");
        this.setState({
          isLoading: false,
        });
      }
    }
    return true;
  }

  render() {
    const { branchId, isLoading } = this.state;
    const {
      business: { branches },
      downloadReviewTemplate,
      type,
    } = this.props;
    const s = branches && branches.choices.filter((item) => item.value == this.state.branchId)[0];

    return (
      <div>
        <LoaderComponent loader={isLoading}></LoaderComponent>

        {type === "offline" && (
          <React.Fragment>
            <h6 className="fw3">Choose Branch.</h6>
            {/* <Select
              name="branchId"
              value={s}
              labelKey="display_name"
              valueKey="value"
              options={branches && branches.choices}
              menuContainerStyle={{ zIndex: 999 }}
              onChange={(val) => this.handleBranchChange(val)}
              onBlurResetsInput={false}
              simpleValue
              clearable={false}
              className="mb-4"
            /> */}
            <AutoSelect
              name="branchId"
              placeholder="Select"
              // value={s}
              onChange={(e, val) => {
                this.handleBranchChange(val);
              }}
              multiple={false}
              options={branches && branches.choices}
              className="mb-4"
            />
          </React.Fragment>
        )}
        <div className="d-flex gap-20 justify-content-between align-items-center">
          {(branchId || type === "swiggy" || type === "zomato_ors" || type === "magicpin") && (
            <label htmlFor="file" className="cursor-pointer mb-0 w-50 flex-grow-1">
              <FPButton
                label="Upload File"
                className="pointer-events-none w-100"
                startIcon={<BackupOutlinedIcon />}
                fontWeight={400}
              >
                Upload File
                <input id="file" hidden type="file" onChange={this.uploadReviewFile} />
              </FPButton>
            </label>
          )}

          {type && type !== "zomato_ors" && (
            <div
              role="button"
              title="Download Sample Template"
              className="d-flex gap-10 w-50 mb-0 align-items-center font-blue"
              onClick={downloadReviewTemplate}
            >
              <CloudDownloadOutlinedIcon />
              <span>Download sample template</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    business: state.business,
  };
};

UploadReviewModal.propTypes = {
  actions: PropTypes.instanceOf(Object),
  business: PropTypes.instanceOf(Object),
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(ReviewsActions, dispatch),
    businessActions: bindActionCreators(BusinessSetupActions, dispatch),
  };
}

UploadReviewModal.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(UploadReviewModal);
