import React from 'react';
import AuthLayout from './AuthLayout';
import LogoIcon from '../../images/Checkmark--filled.svg';

const ResetPasswordSuccessfulpage = () => {
  return (
     <AuthLayout
    logoIcon={LogoIcon}
    title={"Password has been reset successfully!"}
    linkText={"Go back to login"}
    /> 
  );
};

export default ResetPasswordSuccessfulpage;
