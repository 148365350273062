import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {  Card, CardBody, Row, Col } from 'reactstrap';
import { Button, Form, Header, Grid, Segment, Image, Icon } from 'semantic-ui-react';
import {  Field, reduxForm, SubmissionError } from 'redux-form';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css';
import axios_instance from '../../libs/interseptor';
import TextAreaComponent from '../common/TextAreaComponent';
import CustomRatingComponent from '../common/customRating';
import * as URLS from '../../libs/apiUrls';
import SocialButtonsGroup from '../common/SocialButtonsGroup';
import starActive from '../../images/reviewMethods/star_Color.png';
import starInActive from '../../images/reviewMethods/star_B_W.png';
import thumbsUpActive from '../../images/reviewMethods/thumbs-up_Color.png';
import thumbsUpInActive from '../../images/reviewMethods/thumbs-up_B_W.png';
import thumbDownActive from '../../images/reviewMethods/thumb-down_Color.png';
import thumbDownInActive from '../../images/reviewMethods/thumb-down_B_W.png';
import smileyHappyActive from '../../images/reviewMethods/happy_Color.png';
import smileyHappyInActive from '../../images/reviewMethods/happy_B_W.png';
import smileyNeutralActive from '../../images/reviewMethods/neutral_Color.png';
import smileyNeutralInActive from '../../images/reviewMethods/neutral_B_W.png';
import smileyUnHappyActive from '../../images/reviewMethods/unhappy_Color.png';
import smileyUnHappyInActive from '../../images/reviewMethods/unhappy_B_W.png';
import SemiCircleProgressBar from 'react-progressbar-semicircle';
import FaceFour from "../../images/svg/FaceFour";
import FaceFillFour from "../../images/svg/FaceFillFour";
import FaceFive from "../../images/svg/FaceFive";
import FaceFillFive from "../../images/svg/FaceFillFive";
import FaceSix from "../../images/svg/FaceSix";
import FaceFillSix from "../../images/svg/FaceFillSix";
import FaceSeven from "../../images/svg/FaceSeven";
import FaceFillSeven from "../../images/svg/FaceFillSeven";
import FaceEight from "../../images/svg/FaceEight";
import FaceFillEight from "../../images/svg/FaceFillEight";

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = 'Enter Name';
  if (!values.email) errors.email = 'Enter Email';

  return errors;
};

class ExperienceForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.setStarRating = this.setStarRating.bind(this);
    this.setThumbRating = this.setThumbRating.bind(this);
    this.sendPayload = this.sendPayload.bind(this);
    this.setSmileyRating = this.setSmileyRating.bind(this);
    this.setNpsScore = this.setNpsScore.bind(this);
    this.submit = this.submit.bind(this);
    this.copyReview = this.copyReview.bind(this);
    this.showSurveyQuestionDiv = this.showSurveyQuestionDiv.bind(this);
    this.setEmojiRating = this.setEmojiRating.bind(this);
    const { location: { search } } = props;
    const { star, smiley, thumb, nps_score, emoji } = queryString.parse(search);
    this.state = {
      starRating: star ? parseInt(star.replace('star', '')) : 0,
      thumbRating: thumb ? thumb : null,
      smileyRating: smiley ? smiley : null,
      npsScore: nps_score ? nps_score : null,
      error: null,
      success: false,
      nps_array: Array(10).fill(false),
      review: '',
      thumbsUpHover: false,
      thumbsDownHover: false,
      smileyHappyHover: false,
      smileyNeutralHover: false,
      smileyUnHappyHover: false,
      reviewBtnText: 'Copy Review',
      toOnlyThankYou: false,
      emojiRating: emoji ? emoji : 0,
      smileyFourHover: false,
      smileyFiveHover: false,
      smileySixHover: false,
      smileySevenHover: false,
      smileyEightHover: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { is_feedback_given, initialValue, template, initialValue: { name, email }, location: { search } } = this.props;
    const query = queryString.parse(search);
    const prevQuery = queryString.parse(prevProps.location.search);
    if ((!is_feedback_given && prevProps.initialValue !== initialValue && template.type !== 'Generic') || (template.type === 'Generic' && (prevQuery.star !== query.star || prevQuery.thumb !== query.thumb || prevQuery.smiley !== query.smiley || prevQuery.nps_score !== query.nps_score))) {
      let initialData = {
        reviewer: { email, name }
      };
      this.sendPayload(initialData);
    }
  }

  sendPayload(data) {
    this.updatePayloadWithRatingType(data);
    this.props.sendFeedback(data);
  }

  setStarRating(val) {
    const { history, location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    this.setState({ starRating: val });
    if ((query.star && parseInt(query.star.replace('star', '')) !== val) || !query.star) {
      query.star = `star${val}`;
      history.push({ pathname, search: queryString.stringify({ ...query }) });
    }
  }

  setThumbRating(val) {
    const { history, location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    this.setState({ thumbRating: val });
    if ((query.thumb && query.thumb !== val) || !query.thumb) {
      query.thumb = val;
      history.push({ pathname, search: queryString.stringify({ ...query }) });
    }
  }

  setSmileyRating(val) {
    const { history, location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    this.setState({ smileyRating: val });
    if ((query.smiley && query.smiley !== val) || !query.smiley) {
      query.smiley = val;
      history.push({ pathname, search: queryString.stringify({ ...query }) });
    }
  }

  setEmojiRating(val){
    const { history, location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    this.setState({ emojiRating: val });
    if ((query.emoji && query.emoji !== val) || !query.emoji) {
      query.emoji = val;
      history.push({ pathname, search: queryString.stringify({ ...query }) });
    }
  }

  setNpsScore(val) {
    const { history, location: { pathname, search } } = this.props;
    const query = queryString.parse(search);
    this.setState({ npsScore: val });
    if ((query.nps_score && query.nps_score !== val) || !query.nps_score) {
      query.nps_score = val;
      history.push({ pathname, search: queryString.stringify({ ...query }) });
    }
  }

  copyReview() {
    const copyText = document.getElementById('description');
    function listener(e) {
      e.clipboardData.setData('text/plain', copyText.innerText);
      e.preventDefault();
    }
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    this.setState({ reviewBtnText: 'Review Copied' });
  }

  showSurveyQuestionDiv(){
    this.props.surveyShowOrNotSubmit(this.props.surveyQuestionShow);
  }

  handleHover(stateName, value) {
    this.setState({ [stateName]: value });
  }

  handleNpsHover = (index, value) => {
    let newArray = [...this.state.nps_array];
    newArray = newArray.fill(value, 0, index + 1);
    this.setState({ nps_array: [...newArray] });
  }

  updatePayloadWithRatingType(payload) {
    const { starRating, thumbRating, smileyRating, npsScore, emojiRating } = this.state;
    const { ratingType } = this.props;

    if (ratingType === 'star') {
      payload.rating = starRating;
    } else if (ratingType === 'thumb') {
      if(thumbRating === 'up'){
        payload.is_thum_up = true;
        payload.rating = 5;
      }else{
        payload.is_thum_up = false;
        payload.rating = 1;
      }
    } else if (ratingType === 'smiley') {
      if (smileyRating === 'sad'){
        payload.smiley_value = 0;
        payload.rating = 1;
      }else if (smileyRating === 'neutral'){
        payload.smiley_value = 1;
        payload.rating = 3;
      }else{
        payload.smiley_value = 2;
        payload.rating = 5;
      }
    }
    else if (ratingType === 'nps_score') {
      payload.nps_score = npsScore;
      if (npsScore <= 6){
        payload.rating =  1;
      }else if (npsScore <= 8){
        payload.rating = 3;
      }else{
        payload.rating = 5;
      }
    }
    else if (ratingType === 'smiley-5') {
      payload.emoji_value = emojiRating;
      payload.rating =  emojiRating;
    }else{
      payload.rating =  0;
    }
  }

  submit(data) {
    const { starRating, thumbRating, smileyRating, npsScore, emojiRating } = this.state;
    const review_id = this.props.id ? this.props.id : this.props.review_id;
    const newData = {};
    newData.description = data.description;
    this.setState({ review: `${data.description}` });
    this.setState({ success: false });
    this.setState({ error: null });
    if (this.props.ratingType) {
      if (!(starRating > 3 || thumbRating === 'up' || smileyRating === 'happy' || emojiRating  > 3)) this.setState({ toOnlyThankYou: true });
      if ((starRating === 0) && (thumbRating === null) && (smileyRating === null) && (npsScore === null) && (emojiRating === 0)) {
        this.setState({ error: 'Please choose a rating.' });
        return false;
      }
    }
    this.updatePayloadWithRatingType(newData);
    if (review_id){
      axios_instance.patch(`${URLS.REVIEWS_DETAILS}${review_id}/comment/`, newData)
        .then((res) => {
          this.setState({ success: true });
          this.props.surveyQuestionSubmit(this.state.success, res.data.id, res.data);
        })
        .catch((err) => {
          const errobj = {
            _error: err.non_field_errors,
            ...err,
          };
          throw new SubmissionError(errobj);
        });
    }else{
      this.props.sendFeedback(newData)
        .then((res)=>{
          this.setState({ success: true });
          this.props.surveyQuestionSubmit(this.state.success, res.data.id, res.data);
        });
    }
    
  }

  feedbackAlreadySubmittedPage = () => {
    const { initialValue, links, businessDetails, review_gating } = this.props;
    return (<div>
      <div className="text-center mb-3"><img src={businessDetails.logo} alt="" width={90} /></div>
      <Card className="text-center feedback-already-exist">
        <CardBody className="py-4 feedback-pink-bg">
          <Row className="py-3 feedback-title">
            <Col className="col-12 pb-2 text-capitalize">
              <h2>Hi {initialValue.name},</h2>
            </Col>
            <Col className="col-12">
              {(!review_gating) && <b>We already have your Valuable Feedback with us for {businessDetails.name}.</b>}
              {(review_gating) && <b>Thanks for your valuable Feedback.</b>}
            </Col>
            {(!review_gating) && <Col className="col-12">
              <b>Thanks for showing your interest again.</b>
            </Col>}
          </Row>
        </CardBody>
        <CardBody>
          <Row>
            <Col className="col-12 recommendation-text">
              Your Recommendation wil make a big impact on our business
            </Col>
          </Row>
          {(!review_gating) && <Row className="pt-3">
            <Col className="col-12">
              <SocialButtonsGroup links={links} />
            </Col>
          </Row>}
        </CardBody>
      </Card>
    </div>);
  };


  render() {
    const { submitting, handleSubmit, errors, ratingType, body, template, business, include_comment, designConfig } = this.props;
    const { starRating, thumbRating, smileyRating, thumbsUpHover, thumbsDownHover, smileyHappyHover, smileyNeutralHover, smileyUnHappyHover, emojiRating, smileyFourHover, smileyFiveHover, smileySixHover, smileySevenHover, smileyEightHover } = this.state;
    console.log(designConfig, 'designConfig at exp page', typeof(designConfig));
    return (
      <Grid textAlign='center' style={{}} verticalAlign='middle'>
        <Grid.Column textAlign='left' text style={{ maxWidth: 450 }}>
          <Form size='large' onSubmit={handleSubmit(this.submit)}>
            {errors && errors.non_field_errors
              ? errors.non_field_errors.map(
                (error, index) => (<p key={index} className="text-danger">{error}</p>)
              )
              : null
            }
            <Segment stacked rounded style={{ height: include_comment ? ratingType === 'nps_score' ? '660px' :'620px' : ratingType == 'smiley-5' ? '620px' : '470px', borderRadius: '20px' ,backgroundColor:'white'}}>
              <Header as='h2' textAlign='center' style={{ marginBottom: '20px', color: 'darkgreen', fontSize: '25px' }}>
                <Image src={business ? business.logo : ''} style={{maxHeight:'100px'}}/>
              </Header>
              {ratingType === 'star' &&
                (
                  <Col className='mb-3' style={{paddingBottom : designConfig.vertical ? '' : '10px', color: designConfig.que_text_color,fontFamily:designConfig.font}}>
                    <Header as="h3" textAlign='center' style={{ marginTop:'10px'}}>
                      {template.heading}
                    </Header>
                    <div className='' style={{fontFamily:designConfig.font, backgroundColor:designConfig.que_bg_color, borderRadius:'20px', color: designConfig.que_text_color, padding:'10px 0'}}>
                      <Col sm="12" className="no-label" style={{display: 'flex', justifyContent:'center', fontSize: '30px', marginLeft:'4px',marginTop:'20px'}}>
                        <Rater total={5} rating={starRating} onRate={({ rating }) => this.setStarRating(rating)}>
                          <CustomRatingComponent
                            activeImg={starActive}
                            inActiveImg={starInActive}
                            customClass="review-method-img"
                            imageWidth={35}
                            style={{marginRight:'12px'}}
                          />
                        </Rater>
                      </Col>
                      <Header as='h6' style={{fontFamily:designConfig.font, display: 'flex', justifyContent:'center',fontSize: '15px',alignItems:'center', marginTop:'10px'}}>
                        <h6 style={{fontFamily:designConfig.font,color: designConfig.que_text_color}}>Very Poor</h6>
                        <p style={{marginTop:'3px', fontFamily:designConfig.font,color: designConfig.que_text_color}}><Icon name='caret right' size='small' />----------------------<Icon name='caret left' size='small' /></p>
                        <h6 style={{fontFamily:designConfig.font,color: designConfig.que_text_color}}>Excellent</h6>
                      </Header>
                    </div>
                  </Col>
                )
              }
              {ratingType === 'thumb' &&
                (
                  <Col>
                    <Header as="h3" textAlign='center' style={{fontFamily:designConfig.font}}>
                      {template.heading}
                    </Header>
                    <Col sm="12" style={{display: 'flex', justifyContent:'center', fontSize: '30px',marginTop:'10px', marginBottom:'20px', fontFamily:designConfig.font, backgroundColor:designConfig.que_bg_color, borderRadius:'20px', color: designConfig.que_text_color, padding:'15px 0'}}>
                      <span className="mr-5" onClick={() => this.setThumbRating('up')}
                        onMouseOver={() => this.handleHover('thumbsUpHover', true)}
                        onMouseLeave={() => this.handleHover('thumbsUpHover', false)}>
                        <img width={50} className="review-method-img" src={(thumbRating === 'up' || thumbsUpHover) ? thumbsUpActive : thumbsUpInActive} />
                        <p style={{fontSize:'10px', marginLeft:'10px', marginTop:'10px'}}>Best</p>
                      </span>
                      <span onClick={() => this.setThumbRating('down')}
                        onMouseOver={() => this.handleHover('thumbsDownHover', true)}
                        onMouseLeave={() => this.handleHover('thumbsDownHover', false)}>
                        <img width={50} className="review-method-img" src={(thumbRating === 'down' || thumbsDownHover) ? thumbDownActive : thumbDownInActive} />
                        <p style={{fontSize:'10px', marginLeft:'18px', marginTop:'10px'}}>Worst</p>
                      </span>
                    </Col>
                  </Col>
                )
              }
              {ratingType === 'smiley' &&
                (
                  <Col>
                    <Header as="h3" textAlign='center' style={{fontFamily:designConfig.font}}>
                      {template.heading}
                    </Header>
                    <Col sm="12" style={{display: designConfig.vertical ? 'flow' : 'flex', justifyContent:'center', fontSize: '30px',marginTop:'10px', marginBottom:'20px', padding:'0 30px 0 70px'}}>
                      <span className="mr-5" onClick={() => this.setSmileyRating('sad')} style={{backgroundColor:designConfig.que_bg_color, fontFamily:designConfig.font, borderRadius:'25px', padding: designConfig.vertical ? '5px 30px 5px 30px':'15px 10px', fontSize: '15px', textAlign: designConfig.vertical ? 'left' : 'center', display: designConfig.vertical ? 'block' : 'grid', marginBottom : designConfig.vertical ? '10px' : '', cursor:'pointer'}}
                        onMouseOver={() => this.handleHover('smileyUnHappyHover', true)}
                        onMouseLeave={() => this.handleHover('smileyUnHappyHover', false)}>
                        <img width={40} className="review-method-img" src={(smileyRating === 'sad' || smileyUnHappyHover) ? smileyUnHappyActive : smileyUnHappyInActive} />
                        <span style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: designConfig.que_text_color, marginLeft : designConfig.vertical ? '20px' : '', fontSize : designConfig.vertical ? '15px' : '' }}>Bad</span>
                      </span>
                      <span className="mr-5" onClick={() => this.setSmileyRating('neutral')} style={{backgroundColor:designConfig.que_bg_color, fontFamily:designConfig.font, borderRadius:'25px', padding: designConfig.vertical ? '5px 30px 5px 30px':'15px 10px', fontSize: '15px', textAlign: designConfig.vertical ? 'left' : 'center', display:designConfig.vertical ? 'block' : 'grid', marginBottom : designConfig.vertical ? '10px' : '', cursor:'pointer'}}
                        onMouseOver={() => this.handleHover('smileyNeutralHover', true)}
                        onMouseLeave={() => this.handleHover('smileyNeutralHover', false)}>
                        <img width={40} className="review-method-img" src={(smileyRating === 'neutral' || smileyNeutralHover) ? smileyNeutralActive : smileyNeutralInActive} />
                        <span style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: designConfig.que_text_color, marginLeft : designConfig.vertical ? '20px' : '', fontSize : designConfig.vertical ? '15px' : ''}}>Avg</span>
                      </span>
                      <span className={designConfig.vertical ? 'mr-5' : 'mr-2'} onClick={() => this.setSmileyRating('happy')} style={{backgroundColor:designConfig.que_bg_color, fontFamily:designConfig.font, borderRadius:'25px', padding: designConfig.vertical ? '5px 30px 5px 30px':'15px 10px', fontSize: '15px', textAlign: designConfig.vertical ? 'left' : 'center', display:designConfig.vertical ? 'block' : 'grid', marginBottom : designConfig.vertical ? '10px' : '', cursor:'pointer'}}
                        onMouseOver={() => this.handleHover('smileyHappyHover', true)}
                        onMouseLeave={() => this.handleHover('smileyHappyHover', false)}>
                        <img width={40} className="review-method-img" src={(smileyRating === 'happy' || smileyHappyHover) ? smileyHappyActive : smileyHappyInActive} />
                        <span style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: designConfig.que_text_color, marginLeft : designConfig.vertical ? '20px' : '', fontSize : designConfig.vertical ? '15px' : ''}}>Good</span>
                      </span>
                    </Col>
                  </Col>
                )
              }
              {ratingType === 'smiley-5' &&
                (
                  <Col>
                    <Header as="h3" textAlign='center' style={{fontFamily:designConfig.font}}>
                      {template.heading}
                    </Header>
                    <Col sm="12" style={{display: designConfig.vertical ? 'flow' : 'flex', justifyContent:'center', fontSize: '30px',marginTop: include_comment ? '20px' : '50px', marginBottom:'10px', padding:'0 70px'}}>
                      <span className="mr-3" onClick={() => this.setEmojiRating('5')} style={{backgroundColor:designConfig.que_bg_color, fontFamily:designConfig.font, borderRadius:'25px', padding:  include_comment ? designConfig.vertical ? '5px 5px 5px 30px':'15px 10px' : designConfig.vertical ? '20px 5px 20px 30px':'15px 10px', fontSize:'10px', textAlign: designConfig.vertical ? 'left' : 'center', display:designConfig.vertical ? 'block' : 'grid', marginBottom : designConfig.vertical ? '5px' : '', cursor:'pointer'}}
                        onMouseOver={() => this.handleHover('smileyEightHover', true)}
                        onMouseLeave={() => this.handleHover('smileyEightHover', false)}>
                        {(emojiRating === '5' || smileyEightHover ) ? <FaceFillEight /> : <FaceEight />}
                        <span  style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: designConfig.que_text_color, marginLeft : designConfig.vertical ? '15px' : '', fontSize : designConfig.vertical ? '12px' : ''}}>VERY GOOD</span>
                      </span>
                      <span className="mr-3" onClick={() => this.setEmojiRating('4')} style={{backgroundColor:designConfig.que_bg_color, fontFamily:designConfig.font, borderRadius:'25px', padding:  include_comment ? designConfig.vertical ? '5px 5px 5px 30px':'15px 10px' : designConfig.vertical ? '20px 5px 20px 30px':'15px 10px', fontSize: '10px', textAlign: designConfig.vertical ? 'left' : 'center', display:designConfig.vertical ? 'block' : 'grid', marginBottom : designConfig.vertical ? '5px' : '', cursor:'pointer'}}
                        onMouseOver={() => this.handleHover('smileySevenHover', true)}
                        onMouseLeave={() => this.handleHover('smileySevenHover', false)}>
                        {(emojiRating === '4' || smileySevenHover ) ? <FaceFillSeven /> : <FaceSeven />}
                        <span  style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: designConfig.que_text_color, marginLeft : designConfig.vertical ? '15px' : '', fontSize : designConfig.vertical ? '12px' : ''}}>GOOD</span>
                      </span>
                      <span className="mr-3" onClick={() => this.setEmojiRating('3')} style={{backgroundColor:designConfig.que_bg_color, fontFamily:designConfig.font, borderRadius:'25px', padding:  include_comment ? designConfig.vertical ? '5px 5px 5px 30px':'15px 10px' : designConfig.vertical ? '20px 5px 20px 30px':'15px 10px', fontSize:'10px', textAlign: designConfig.vertical ? 'left' : 'center', display:designConfig.vertical ? 'block' : 'grid', marginBottom : designConfig.vertical ? '5px' : '', cursor:'pointer'}}
                        onMouseOver={() => this.handleHover('smileySixHover', true)}
                        onMouseLeave={() => this.handleHover('smileySixHover', false)}>
                        {(emojiRating === '3' || smileySixHover ) ? <FaceFillSix /> : <FaceSix />}
                        <span  style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: designConfig.que_text_color, marginLeft : designConfig.vertical ? '15px' : '', fontSize : designConfig.vertical ? '12px' : ''}}>AVG</span>
                      </span>
                      <span className="mr-3" onClick={() => this.setEmojiRating('2')} style={{backgroundColor:designConfig.que_bg_color, fontFamily:designConfig.font, borderRadius:'25px', padding:  include_comment ? designConfig.vertical ? '5px 5px 5px 30px':'15px 10px' : designConfig.vertical ? '20px 5px 20px 30px':'15px 10px', fontSize: '10px', textAlign: designConfig.vertical ? 'left' : 'center', display:designConfig.vertical ? 'block' : 'grid', marginBottom : designConfig.vertical ? '5px' : '', cursor:'pointer'}}
                        onMouseOver={() => this.handleHover('smileyFiveHover', true)}
                        onMouseLeave={() => this.handleHover('smileyFiveHover', false)}>
                        {(emojiRating === '2' || smileyFiveHover ) ? <FaceFillFive /> : <FaceFive />}
                        <span  style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: designConfig.que_text_color, marginLeft : designConfig.vertical ? '15px' : '', fontSize : designConfig.vertical ? '12px' : ''}}>BAD</span>
                      </span>
                      <span className="mr-3" onClick={() => this.setEmojiRating('1')} style={{backgroundColor:designConfig.que_bg_color, fontFamily:designConfig.font, borderRadius:'25px', padding: include_comment ? designConfig.vertical ? '5px 5px 5px 30px':'15px 10px' : designConfig.vertical ? '20px 5px 20px 30px':'15px 10px', fontSize: '10px', textAlign: designConfig.vertical ? 'left' : 'center', display:designConfig.vertical ? 'block' : 'grid', marginBottom : designConfig.vertical ? '5px' : '', cursor:'pointer'}}
                        onMouseOver={() => this.handleHover('smileyFourHover', true)}
                        onMouseLeave={() => this.handleHover('smileyFourHover', false)}>
                        {(emojiRating === '1' || smileyFourHover) ? <FaceFillFour /> : <FaceFour />}
                        <span style={{fontWeight:'bold',maxWidth:'40px', marginTop:'10px', color: designConfig.que_text_color, marginLeft : designConfig.vertical ? '15px' : '', fontSize : designConfig.vertical ? '12px' : ''}}>VERY BAD</span>
                      </span>
                    </Col>
                  </Col>
                )
              }
              {
                ratingType === 'nps_score' &&
                (
                  <Fragment>
                    <Header as="h3" textAlign='center' style={{ marginTop:'5px',fontFamily:designConfig.font}}>
                      {template.heading}
                    </Header>
                    <Row style={{margin:'auto', fontFamily:designConfig.font, backgroundColor:designConfig.que_bg_color, borderRadius:'20px', color: designConfig.que_text_color}}>
                      <Col sm="12" className="nps-score-center" style={{marginTop:'40px', marginBottom:'30px'}}>
                        <div className="nps-meter">
                          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => {
                            const selectedScore = this.state.npsScore;
                            let colorClass;

                            if (selectedScore && num <= selectedScore) {
                              if (selectedScore <= 6)
                                colorClass = "nps-rating-colour-red filled newDesignNps";
                              else if (selectedScore <= 8)
                                colorClass =
                                  "nps-rating-colour-yellow filled newDesignNps";
                              else
                                colorClass = "nps-rating-colour-green filled newDesignNps";
                            } else {
                              if (num <= 6)
                                colorClass = "nps-rating-colour-red newDesignNps";
                              else if (num <= 8)
                                colorClass = "nps-rating-colour-yellow newDesignNps";
                              else colorClass = "nps-rating-colour-green newDesignNps";
                            }

                            return (
                              <span
                                key={num}
                                className={colorClass}
                                onClick={() =>
                                  this.setNpsScore(num)
                                }
                                style={{"padding":"18px!important"}}
                              >
                                {num}
                              </span>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  </Fragment>
                )
              }
              { (include_comment && body !== 'null') && 
                <p style={{ textAlign: 'justify', marginBottom: '10px', paddingLeft:'12px', paddingRight:'12px', fontSize:'13px', fontFamily:designConfig.font }}>
                  {body}
                </p>}
              { include_comment &&
                <div style={{backgroundColor:designConfig.que_bg_color, fontFamily:designConfig.font, borderRadius:'25px', padding:'10px', color: designConfig.que_text_color}}>
                  <p style={{ textAlign: 'justify', paddingLeft:'12px', paddingRight:'12px', fontSize:'13px', color: designConfig.que_text_color }}>
                    What would you tell others about your experience?
                  </p>
                  <Field
                    name="description"
                    type="text"
                    placeholder='Write here...'
                    component={TextAreaComponent}
                    serverError={errors && errors.description}
                  />
                </div>
              }
              <Form.Field control={Button} color='teal' type='submit' disabled={submitting} style={{ color: 'white', backgroundColor: '#0F62FE', float:'right', marginTop:'10px' }}>Next<Icon name='right arrow' /></Form.Field>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

ExperienceForm.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

ExperienceForm = reduxForm({// eslint-disable-line
  form: 'FeedbackForm',
  validate,
  enableReinitialize: true,
})(ExperienceForm);


ExperienceForm = connect((state) => {// eslint-disable-line
  return {
  };
})(ExperienceForm);

export default ExperienceForm;