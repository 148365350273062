import React, { useEffect, useState } from "react";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import LoaderComponent from "../../components/common/loaderComponent";
import { snakeToCapital } from "../../libs/utils";
import { NoData } from "../../components/common/new";
import CompetitorsBarChart from "../../components/common/CompetitorsBarChart";

const CHART_BACKGROUND_COLOR_1 = "#9E74E9";
const CHART_HOVER_COLOR_1 = "#19E3E3";
const CHART_BACKGROUND_COLOR_2 = "#F13A88";

const CompetitorsProviderGraph = ({ loader, ratingDetails,businessDetails }) => {
  const [graphData, setGraphData] = useState([]);
  const [dataKeys, setDataKeys] = useState([]);

  useEffect(() => {
    const newData = [];
    const dataKeys = [];
    const providerRating = {};
    const providerReview = {};

    ratingDetails?.data?.ratings?.map((item, index) => {
      const branchName = index === 0 ? item.owner_name : item.competitor_name;
      dataKeys.push(branchName);

      const providerObj = item.provider_wise_ratings;

      Object.keys(providerObj).map((provider_item) => {
        if (providerRating[provider_item]) {
          providerRating[provider_item].push(
            providerObj[provider_item].provider_avg_rating
          );
        } else {
          providerRating[provider_item] = [
            providerObj[provider_item].provider_avg_rating,
          ];
        }

        if (providerReview[provider_item]) {
          providerReview[provider_item].push(
            providerObj[provider_item].total_reviews_count
          );
        } else {
          providerReview[provider_item] = [
            providerObj[provider_item].total_reviews_count,
          ];
        }
      });
    });

    Object.keys(providerRating).map((item) => {
      const dataSet = {
        providerName: item,
        dataSet: [
          {
            label: "Reviews",
            type: "bar",
            data: providerReview[item],
            yAxisID: "y-axis-1",
            backgroundColor: dataKeys.map(label =>
              label === businessDetails ? "#7F56D9" : CHART_BACKGROUND_COLOR_1
            ),
            hoverBackgroundColor: dataKeys.map(label =>
              label === businessDetails ? "#7F56D9" : CHART_HOVER_COLOR_1
            ),
            legendColor: CHART_BACKGROUND_COLOR_1,
            borderRadius: 5,
          },
          {
            label: "Ratings",
            type: "line",
            data: providerRating[item],
            yAxisID: "y-axis-2",
            backgroundColor: "rgba(225, 33, 105, 0.1)",
            borderColor: CHART_BACKGROUND_COLOR_2,
            legendColor: CHART_BACKGROUND_COLOR_2,
            lineTension: 0.6,
            fill: {
              target: "origin",
            },
          },
        ],
      };
      newData.push(dataSet);
    });

    setGraphData(newData);
    setDataKeys(dataKeys);
  }, [ratingDetails]);

  return (
    <div className="pt-0 px-3 pb-3">
      <LoaderComponent loader={loader} />
      {ratingDetails?.data?.ratings.length > 0 && graphData.length > 0 ? (
        <div className="row gap-y-20 fp-row-wrapper">
          {graphData.map((item, index) => (
            <div
              keys={`${index}-${item.providerName}`}
              className="col-6 pl-10 pr-10"
            >
              <CardWrapper
                headerTitle={snakeToCapital(item.providerName)}
                className="fp-border-light p-0"
                keys={`${index}-${item.providerName}`}
                headerClass="font-dark pt-3 pb-1 px-3"
              >
                <CompetitorsBarChart
                  dataKeys={dataKeys}
                  dataSets={item.dataSet}
                  height={280}
                  primaryYLabel="Reviews"
                  secondaryYLabel="Ratings"
                  businessDetails={businessDetails}
                />
              </CardWrapper>
            </div>
          ))}
        </div>
      ) : (
        !loader && <NoData />
      )}
    </div>
  );
};

export default CompetitorsProviderGraph;
