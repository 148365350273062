import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { reduxForm, Field, SubmissionError } from "redux-form";
import { Input, Form, Button } from "semantic-ui-react";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import FPLabel from "../common/new/forms/ui/FPLabel";
import { FPButton } from "../common/new";

const emptyString = (value) =>
  !value && "Please enter a value before submitting";

const charactersLimit = (value) =>
  value.length > 25 && "Please enter a value less than 25 characters.";

const isAlphaNumeric = (value) => {
  const regExp = /^[\w\-\s]+$/;
  if (!value.match(regExp))
    return "Please enter a value that only contains numbers and/or alphabets";
};

const isExtraComma = (value) => {
  const tagsArray = value.split(",");
  if (tagsArray.includes(""))
    return "You cannot have an empty string in between or an extra comma";
};

const renderTagInput = ({ input, type, meta: { error, touched } }) => {
  return (
    <div>
      <Input {...input} type={type} style={{ width: "100%" }}></Input>
      {touched && error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
};

let ContactTagsForm = (props) => {
  const { handleSubmit, submitting, toggleForm } = props;

  const submit = (data) => {
    const tagsArray = data.tags.split(",");
    const newData = { name: data.name, tags: [...tagsArray] };
    const { addContactTagGroup } = props;
    return addContactTagGroup(newData).catch((err) => {
      const errobj = {
        _error: err.non_field_errors,
        ...err,
      };
      throw new SubmissionError(errobj);
    });
  };

  return (
    <CardWrapper
      headerTitle="Enter the tags you want to add from your import file into the fields below."
      contentClass="p-4"
    >
      <form onSubmit={handleSubmit(submit)} className="grid-form mt-2">
        <section>
          <Row className="py-2">
            <Col xs={12} sm={12} md={6}>
              <FPLabel>Tag Group</FPLabel>
              <Field
                name="name"
                type="text"
                validate={[emptyString, charactersLimit, isAlphaNumeric]}
                component={renderTagInput}
                placeholder="Enter Name"
              />
            </Col>
            <Col xs={12} sm={12} md={6}>
              <FPLabel>Contact Tags</FPLabel>
              <Field
                name="tags"
                type="text"
                validate={[emptyString, isExtraComma]}
                component={renderTagInput}
                placeholder="Enter Tags"
              />
            </Col>
          </Row>
        </section>
        <div className="d-flex justify-content-lg-end justify-content-center mt-3">
          <FPButton
            size="small"
            label="Cancel"
            type="button"
            variant="outline"
            onClick={(e) => {
              e.preventDefault();
              toggleForm();
            }}
          />
          <FPButton
            size="small"
            label="Add Tags"
            type="submit"
            disabled={submitting}
            className="ml-10"
          />
        </div>
      </form>
    </CardWrapper>
    // <div>
    //   <Form onSubmit={handleSubmit(submit)}>
    //     <Row className="mb-5">
    //       <Col>
    //         <div>Enter the tags you want to add from your import file into the fields below.</div></Col>
    //     </Row>
    //     <Row>
    //       <Col sm="6">
    //         <div>Tag Group</div>
    //         <div>
    //           <Field
    //             name='name'
    //             type='text'
    //             validate={[emptyString, charactersLimit, isAlphaNumeric]}
    //             component={renderTagInput}
    //           ></Field>
    //         </div>
    //       </Col>
    //       <Col sm="6">
    //         <div>Contact Tags</div>
    //         <div>
    //           <Field
    //             name='tags'
    //             type='text'
    //             validate={[emptyString, isExtraComma]}
    //             component={renderTagInput}
    //           ></Field>
    //         </div>
    //       </Col>
    //     </Row>
    //     <Row>
    //       <Col>
    //         <div style={{ display: 'flex', justifyContent: 'flex-end' }} className="mr-5 mt-3">
    //           <Button onClick={(e) => { e.preventDefault(); toggleForm(); }} type='button' className="mr-3" basic >Cancel</Button>
    //           <Form.Field control={Button} style={{ background: '#2D85C8', color: 'white' }} type='submit' disabled={submitting}>
    //             Add Tags
    //           </Form.Field>
    //         </div>
    //       </Col>
    //     </Row>
    //   </Form>
    // </div>
  );
};

ContactTagsForm = reduxForm({
  form: "ContactTagsForm",
  enableReinitialize: true,
})(ContactTagsForm);

ContactTagsForm = connect((state) => {
  //eslint-disable-line
  return {};
})(ContactTagsForm);

export default ContactTagsForm;
