import { FormControlLabel, IconButton, Radio, RadioGroup } from "@material-ui/core";
import React, { useState } from "react";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import CompetitorFilterWrapper from "./CompetitorFilterWrapper";
import { FPToolTip } from "../../components/common/new";
import downloadIcon from "../../images/Download.png";
import classNames from "classnames";

const Wrapper = (props) => {
  const { title, subHeading, children, onDownload, disableDownload, location, history, match } = props;
  const [viewType, setViewType] = useState("Graph");

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <RadioGroup value={viewType} className="d-flex flex-row" onChange={(event, value) => setViewType(value)}>
          <FormControlLabel value="Graph" label="Graph" control={<Radio />} className="mb-0" />
          <FormControlLabel value="Table" label="Table" control={<Radio />} className="mb-0" />
        </RadioGroup>

        <div>
          <FPToolTip title={"Download the data"}>
            <IconButton onClick={onDownload} disabled={disableDownload} size="small">
              <img src={downloadIcon} alt="download" width={15} height={15} />
            </IconButton>
          </FPToolTip>
        </div>
      </div>
      <CardWrapper
        headerTitle={
          <div className="d-flex justify-content-between py-2 align-items-center">
            <div>
              <p className="m-0">{title}</p>
              <p className="m-0 fp-size-13 font-dark">{subHeading}</p>
            </div>
            <CompetitorFilterWrapper location={location} history={history} match={match} />
          </div>
        }
        contentClass={classNames("p-0")}
        headerClass={classNames("px-4 pt-3")}
        dividerClass="mx-3"
      >
        <div>{typeof children === "function" ? children(viewType) : children}</div>
      </CardWrapper>
    </div>
  );
};

export default Wrapper;
