import React, { useEffect } from "react";
import RootHeader from "../../../modules/listing/GeoScanCreadit/RootHeader";
import CoinDashboard from "../../../components/listing/GeoScanCreadit/CoinDashboard";
import "../../../components/listing/GeoScanCreadit/geoscancreadit.css";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as setSelectedTab from "../../../actions/common/setSelectedTabAction";
import * as listingCreditUsageHistoryAction from "../../../actions/listing/CreditUsageHistoryAction";
import LoaderComponent from "../../../components/common/loaderComponent";

const GioScanCreadit = (props) => {
  const {
    actions,
    credit_usage_history_data,
    location: { search, pathname },
    location,
    loader,
    history,
    match,
    match: {
      params: { business_id },
    },
  } = props;
  console.log(
    "credit_usage_history_datacredit_usage_history_data",
    credit_usage_history_data
  );

  useEffect(() => {
    actions.getGmbLocationCreditUsageHistory(business_id);
  }, [business_id, actions]);

  useEffect(() => {
    document.title = "Scan Credit History";
    props.actionsSelectTab.setSelectedTab(2);
  }, [props.actionsSelectTab]);

  return (
    <>
    <LoaderComponent loader={loader} />
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div
          style={{
            background: "white",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            borderRadius: "10px",
            padding: "5px",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            {/* <h2 style={{ fontSize: "21px", fontWeight: "500" }}>
              Hi Wat Aburger
            </h2> */}
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <span className="location-lable-value">
                Total Coins Available : {credit_usage_history_data?.total_avail_credits}
              </span>
              <span className="location-lable-value">
                Coins Used in Last 30 Days : {credit_usage_history_data?.last_30_days_use}
              </span>
              <span className="location-lable-value">
                Last Purchase :{" "}
                {credit_usage_history_data?.last_purchase ? credit_usage_history_data?.last_purchase : "Never"}
              </span>
            </div>
          </div>
          <div>
            <RootHeader defaultValue={0} />
          </div>
        </div>
        <div>
          <CoinDashboard coin_usage_history={credit_usage_history_data} />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loader: state.common.loader,
    credit_usage_history_data:
      state.gmbListingCreditUsageHistory.credit_usage_history_data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionsSelectTab: bindActionCreators(setSelectedTab, dispatch),
    actions: bindActionCreators(listingCreditUsageHistoryAction, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GioScanCreadit);
