import React, { useState } from "react";
import emailPdfIcon from "../../../images/svg/emailPdfIcon.svg";
import emailDocIcon from "../../../images/svg/emailDocIcon.svg";
import { Divider } from "@mui/material";
import DownloadForOfflineRoundedIcon from "@mui/icons-material/DownloadForOfflineRounded";

const getFileIcon = (mediaType) => {
  switch (mediaType) {
    case "document":
    case "pdf":
      return emailPdfIcon;
    case "doc":
    case "docx":
      return emailDocIcon;
    default:
      return emailDocIcon;
  }
};

const downloadFile = (url, filename) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = "filename" || "download";
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadAllFiles = async (attachments) => {
  if (!attachments || attachments.length === 0) {
    console.warn("No attachments found");
    return;
  }
  for (let index = 0; index < attachments.length; index++) {
    const file = attachments[index];
    if (!file?.media_url) {
      continue;
    }
    
    const fileName = file.filename || `file_${index + 1}`;

    await downloadFile(fileName);
    await new Promise((resolve) => setTimeout(resolve, 500)); 
  }
};
// const getFileNameFromUrl = (url) => url.substring(url.lastIndexOf("/") + 1);

const EmailAttachments = ({ data }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <>
      <Divider className="mt-4" />
      <div className="attachments-section">
        <div className="attachments-header">
          <span>{data?.attachments?.length} Attachments</span>
          <button
            className="download-all"
            onClick={() => downloadAllFiles(data?.attachments)}
          >
            Download All
          </button>
        </div>
        <div className="d-flex gap-10 flex-wrap">
          {data?.attachments?.map((file, index) => (
            <div key={index} className="attachment-item">
              <div
                style={{
                  position: "relative",
                  width: "50px",
                  height: "50px",
                  cursor: "pointer",
                }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
                onClick={() => downloadFile(file.media_url, file.filename )}
              >
                <img
                  style={{ width: "100%", height: "100%" }}
                  src={
                    file.media_type === "Image"
                      ? file.media_url
                      : getFileIcon(file.media_type)
                  }
                  alt=""
                />
                {hoveredIndex === index && (
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      padding: "5px",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center", 
                    }}
                  >
                    <DownloadForOfflineRoundedIcon
                      style={{ color: "white", fontSize: 24 }}
                    />
                  </div>
                )}
              </div>
              <div className="file-info">
                <span className="file-name text-truncate width-md">
                  {file?.filename}
                </span>
                <span className="file-size">2.4 MB</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default EmailAttachments;
