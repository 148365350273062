import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import LoaderComponent from "../common/loaderComponent";
import {
  setDecimalIndex,
  snakeToCapital,
  starRatingColor,
} from "../../libs/utils";
import CustomStarRatingComponent from "../common/StarComponent";
import { NoData } from "../common/new";

class CompetitorsRating extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { ratingDetails, loader,businessDetails } = this.props;
    let providers_array = [];
    if (
      ratingDetails &&
      ratingDetails.data &&
      ratingDetails.data.ratings.length > 0
    ) {
      providers_array = Object.keys(
        ratingDetails.data.ratings[0].provider_wise_ratings
      );
    }
    return (
      <div className="px-3">
        <LoaderComponent loader={loader} />
        <div className="provider_rating">
          <Table className="fp-table">
            <TableHead>
              {providers_array.length > 0 ? (
                <TableRow>
                  <TableCell>
                    <div>
                      <div className="normal-header">Business Name</div>
                      <div className="sub-header"></div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div>
                      <div className="normal-header">No. of location</div>
                      <div className="sub-header"></div>
                    </div>
                  </TableCell>
                  {providers_array.map((header, i) => {
                    return (
                      <TableCell key={i}>
                        <div className="review_rating_wrapper">
                          <div className="provider_name">
                            {snakeToCapital(header)}
                          </div>
                          <div className="all-center justify-content-between sub-header">
                            <span className="d-inline-block w-50">
                              Reviews
                            </span>
                            <span className="d-inline-block w-50">
                              Rating
                            </span>
                          </div>
                        </div>
                      </TableCell>
                    );
                  })}
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              {ratingDetails &&
              ratingDetails.data &&
              ratingDetails.data.ratings.length > 0 ? (
                ratingDetails.data.ratings.map((competitor, comp_index) => {
                  const displayName = comp_index === 0 ? competitor.owner_name : competitor.competitor_name;

                 const isMatch = displayName === businessDetails;
                  return (
                    <TableRow key={comp_index} style={{backgroundColor: isMatch ? '#EEE3FF' : ''}}>
                      <TableCell className={`text-center ${isMatch ? 'font-weight-bold' : ''}`}>
                        {displayName}
                      </TableCell>
                      <TableCell className="text-center">
                        {competitor.number_of_branches}
                      </TableCell>
                      {providers_array.length > 0
                        ? providers_array.map((header, header_index) => {
                            if (competitor.provider_wise_ratings[header]) {
                              const rating =
                                competitor.provider_wise_ratings[header]
                                  .provider_avg_rating;
                              const { star_border_color, star_bg_color } =
                                starRatingColor(
                                  setDecimalIndex(rating < 1 ? 1 : rating)
                                );

                              return (
                                <TableCell
                                  className="table_row"
                                  key={header_index}
                                >
                                  <div className="all-center justify-content-between">
                                    <span
                                      className="d-inline-block w-50 text-center"
                                      // style={{
                                      //   minWidth: "95px",
                                      // }}
                                    >
                                      {
                                        competitor.provider_wise_ratings[header]
                                          .total_reviews_count
                                      }
                                    </span>

                                    <span className="d-flex justify-content-center w-50">
                                      <div
                                        style={{
                                          borderColor: star_border_color,
                                          backgroundColor: star_bg_color,
                                        }}
                                        className="star-tag text-center "
                                      >
                                        <CustomStarRatingComponent
                                          name="locationRating"
                                          starCount={1}
                                          style={{ fontSize: "16px" }}
                                          value={setDecimalIndex(
                                            rating < 1 ? 1 : rating
                                          )}
                                          editing={false}
                                        />
                                        {Math.ceil(rating)}
                                      </div>
                                    </span>
                                  </div>
                                </TableCell>
                              );
                            }
                          })
                        : ""}
                    </TableRow>
                  );
                })
              ) : (
                <NoData className="mb-3" />
              )}
            </TableBody>
          </Table>
        </div>

        {/*         
        <Card body className="pt-3 mb-3" style={{ marginTop:'10px' }}>
          <CardTitle className="mb-2 fw3">
            Star Rating graph 
            <p className='compt-font'>* Above details is as on   {ratingDetails && ratingDetails.fetch_date}</p>
          </CardTitle>
          <CompetitorStarGraph
            starRatingData={ratingDetails && ratingDetails.data && ratingDetails.data.star_ratings}
          />
        </Card>

        <Card body className="pt-3 mb-3" style={{ marginTop:'10px' }}>
          <CardTitle className="mb-2 fw3">
            Category wise ratings
            <p className='compt-font'>* Above details is as on   {ratingDetails && ratingDetails.fetch_date}</p>
          </CardTitle>
          <SentimentTrend
            categoryData={ratingDetails && ratingDetails.data && ratingDetails.data.category_data}
          />
        </Card> */}
      </div>
    );
  }
}

export default CompetitorsRating;
