import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import { Icon, Popup } from "semantic-ui-react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import InputComponent from "../common/InputComponent";
import * as utils from "../../libs/utils";
import cookie from "react-cookies";
import LocationFiltersDropdown from "../common/LocationFiltersDropdown";
import { FPBreadcrumbs, FPButton, FPToolTip } from "../common/new";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import FPLabel from "../common/new/forms/ui/FPLabel";
import DeleteForeverOutlined from "@material-ui/icons/DeleteForeverOutlined";

const validate = (values) => {
  const errors = {};
  if (!values.name) errors.name = "Enter Name";
  if (!values.template) errors.template = "Select Template";
  if (!values.will_go) errors.will_go = "Select Will Go";
  if (!values.day) errors.day = "Select Day ";
  if (!values.event) errors.event = "Select Event ";
  if (!values.scheduled_time) errors.scheduled_time = "Select Schedule Time ";
  return errors;
};

const will_go_choices = [
  { id: "after", value: "after", text: "after" },
  { id: "before", value: "before", text: "before" },
];
const event_choices = [
  { id: "checkin", value: "checkin", text: "CheckIn" },
  { id: "checkout", value: "checkout", text: "CheckOut" },
];
const day_choices = [
  { id: 0, value: '0', text: 0 },
  { id: 1, value: 1, text: 1 },
  { id: 2, value: 2, text: 2 },
  { id: 3, value: 3, text: 3 },
  { id: 4, value: 4, text: 4 },
  { id: 5, value: 5, text: 5 },
  { id: 6, value: 6, text: 6 },
  { id: 7, value: 7, text: 7 },
  { id: 8, value: 8, text: 8 },
  { id: 9, value: 9, text: 9 },
  { id: 10, value: 10, text: 10 },
  { id: 11, value: 11, text: 11 },
  { id: 12, value: 12, text: 12 },
  { id: 13, value: 13, text: 13 },
  { id: 14, value: 14, text: 14 },
  { id: 15, value: 15, text: 15 },
  { id: 16, value: 16, text: 16 },
  { id: 17, value: 17, text: 17 },
  { id: 18, value: 18, text: 18 },
  { id: 19, value: 19, text: 19 },
  { id: 20, value: 20, text: 20 },
  { id: 21, value: 21, text: 21 },
  { id: 22, value: 22, text: 22 },
  { id: 23, value: 23, text: 23 },
  { id: 24, value: 24, text: 24 },
  { id: 25, value: 25, text: 25 },
  { id: 26, value: 26, text: 26 },
  { id: 27, value: 27, text: 27 },
  { id: 28, value: 28, text: 28 },
  { id: 29, value: 29, text: 29 },
  { id: 30, value: 30, text: 30 },
];
const schedule_time_choices = [
  { id: 0, value: '0', text: 0 },
  { id: 1, value: 1, text: 1 },
  { id: 2, value: 2, text: 2 },
  { id: 3, value: 3, text: 3 },
  { id: 4, value: 4, text: 4 },
  { id: 5, value: 5, text: 5 },
  { id: 6, value: 6, text: 6 },
  { id: 7, value: 7, text: 7 },
  { id: 8, value: 8, text: 8 },
  { id: 9, value: 9, text: 9 },
  { id: 10, value: 10, text: 10 },
  { id: 11, value: 11, text: 11 },
  { id: 12, value: 12, text: 12 },
  { id: 13, value: 13, text: 13 },
  { id: 14, value: 14, text: 14 },
  { id: 15, value: 15, text: 15 },
  { id: 16, value: 16, text: 16 },
  { id: 17, value: 17, text: 17 },
  { id: 18, value: 18, text: 18 },
  { id: 19, value: 19, text: 19 },
  { id: 20, value: 20, text: 20 },
  { id: 21, value: 21, text: 21 },
  { id: 22, value: 22, text: 22 },
  { id: 23, value: 23, text: 23 },
];

class RecurringCampaignForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.handleWillGo = this.handleWillGo.bind(this);
    this.handleTemplate = this.handleTemplate.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleEventChange = this.handleEventChange.bind(this);
    this.handleScheduleTime = this.handleScheduleTime.bind(this);
    this.state = {
      businessType:
        cookie.load("business_user") &&
        cookie.load("business_user").business_type,
      selectedWillGo: null,
      selectedDay: null,
      selectedEvent: null,
      selectedTime: null,
      selectedTemplate: null,
    };
  }

  submit(data) {
    const newError = validate(data);
    if (Object.keys(newError).length > 1) {
      throw new SubmissionError({
        _error: "Submission Error",
        ...newError,
      });
    } else {
      const {
        router: { history },
      } = this.context;
      let newData = { ...data };
      return this.props
        .submitData(newData)
        .then(() =>
          history.push({
            pathname: "list",
          })
        )
        .catch((err) => {
          err &&
            err.non_field_errors &&
            err.non_field_errors.map((err) =>
              utils.displayMessage("negative", err)
            );
          const errobj = {
            _error: err.non_field_errors,
            ...err,
          };
          throw new SubmissionError(errobj);
        });
    }
  }
  handleTemplate(val) {
    this.setState({ selectedTemplate: val });
  }
  handleWillGo(val) {
    this.setState({ selectedWillGo: val });
  }
  handleDayChange(val) {
    this.setState({ selectedDay: val });
  }
  handleEventChange(val) {
    this.setState({ selectedEvent: val });
  }
  handleScheduleTime(val) {
    this.setState({ selectedTime: val });
  }

  componentDidMount() {}

  render() {
    const {
      handleSubmit,
      business_id,
      errors,
      initialValues,
      template_list,
      deleteResponse,
      isNewsletter,
    } = this.props;

    const formType = initialValues && initialValues.id ? "Edit" : "Create";

    const breadCrumbList = [
      {
        label: "Campaigns",
        link: `/${business_id}/campaigns/review/list?page_size=10`,
      },
      {
        label: "Review Request",
        link: `/${business_id}/campaigns/review/list?page_size=10`,
      },
      {
        label: isNewsletter
          ? "Manage Newsletter Recurring Campaign"
          : "Manage Recurring Campaign",
        link: `/${business_id}/recurring-campaigns/review/list?page_size=10`,
      },
      {
        label: isNewsletter
          ? `${formType} Newsletter Recurring Campaign`
          : `${formType} Recurring Campaign`,
        link: "",
      },
    ];

    const CustomInput = ({ input, ...rest }) => (
      <InputComponent
        {...rest}
        minHeight={"auto"}
        input={{
          placeholder: "Enter Name",
          ...input,
        }}
      />
    );

    return (
      <div>
        <FPBreadcrumbs list={breadCrumbList} className="mb-3" />
        <CardWrapper
          contentClass="p-4"
          headerTitle={
            <div className="d-flex align-items-center justify-content-between">
              <span>
                {isNewsletter
                  ? `${formType} Newsletter Recurring Campaign`
                  : `${formType} Recurring Campaign`}
              </span>
              <span>
                {initialValues && initialValues.id && (
                  <FPToolTip title={"Delete Response"}>
                    <DeleteForeverOutlined
                      onClick={() => deleteResponse(initialValues.id)}
                      role="button"
                    />
                  </FPToolTip>
                )}
              </span>
            </div>
          }
        >
          <form className="grid-form" onSubmit={handleSubmit(this.submit)}>
            {errors && errors.non_field_errors
              ? errors.non_field_errors.map((error, index) => (
                  <p key={index} className="text-danger">
                    {error}
                  </p>
                ))
              : null}
            <div>
              <Row fluid className="py-2">
                <Col xs={12} sm={12} md={6}>
                  <FPLabel>
                    Name&nbsp;
                    <Popup
                      content="Enter the campaign name for future references"
                      trigger={<Icon name="info circle" />}
                    />
                  </FPLabel>
                  <Field
                    name="name"
                    type="text"
                    component={CustomInput}
                    serverError={errors && errors.name}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <FPLabel>Template</FPLabel>
                  <Field
                    name="template"
                    component={LocationFiltersDropdown}
                    handleFilterChange={this.handleTemplate}
                    options={template_list}
                    selectedValue={this.state.selectedTemplate}
                    search
                    selection
                    placeholder="Select Template"
                    serverError={errors && errors.template}
                  />
                </Col>
              </Row>

              <Row fluid className="py-2">
                <Col xs={12} sm={12} md={6}>
                  <FPLabel>Will Go</FPLabel>
                  <Field
                    name="will_go"
                    component={LocationFiltersDropdown}
                    handleFilterChange={this.handleWillGo}
                    options={will_go_choices}
                    selectedValue={this.state.selectedWillGo}
                    search
                    selection
                    placeholder="Select will go"
                    serverError={errors && errors.will_go}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <FPLabel>Day</FPLabel>
                  <Field
                    name="day"
                    component={LocationFiltersDropdown}
                    handleFilterChange={this.handleDayChange}
                    options={day_choices}
                    selectedValue={this.state.selectedDay}
                    search
                    selection
                    placeholder="Select Day"
                    serverError={errors && errors.day}
                  />
                </Col>
                <Col></Col>
              </Row>

              <Row fluid className="py-2">
                <Col xs={12} sm={12} md={6}>
                  <FPLabel>Event</FPLabel>
                  <Field
                    name="event"
                    component={LocationFiltersDropdown}
                    handleFilterChange={this.handleEventChange}
                    options={event_choices}
                    selectedValue={this.state.selectedEvent}
                    search
                    selection
                    placeholder="Select Event"
                    serverError={errors && errors.event}
                  />
                </Col>
                <Col xs={12} sm={12} md={6}>
                  <FPLabel>Schedule Time</FPLabel>
                  <Field
                    name="scheduled_time"
                    component={LocationFiltersDropdown}
                    handleFilterChange={this.handleScheduleTime}
                    options={schedule_time_choices}
                    selectedValue={this.state.selectedTime}
                    search
                    selection
                    placeholder="Select Schedule Time"
                    serverError={errors && errors.scheduled_time}
                  />
                </Col>
              </Row>
            </div>

            <FPButton
              type="submit"
              size="small"
              label="Save"
              style={{ float: "inline-end" }}
              className="mt-2"
            />
          </form>
        </CardWrapper>
      </div>
    );
  }
}

RecurringCampaignForm.contextTypes = {
  router: PropTypes.instanceOf(Object),
};

RecurringCampaignForm = reduxForm({
  // eslint-disable-line
  form: "RecurringCampaignForm",
  validate,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(RecurringCampaignForm);

export default RecurringCampaignForm;
