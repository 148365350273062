import React from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { Divider, Form } from "semantic-ui-react";
import { InputField } from "react-semantic-redux-form";
import { FPButton } from "../../common/new";
import { Row, Col } from "reactstrap";
import FPLabel from "../../common/new/forms/ui/FPLabel";

const validate = (values) => {
  const errors = {};
  if (!values.current_password) errors.current_password = "Enter Current Password";
  if (!values.new_password) errors.new_password = "Enter New Password";
  if (values.new_password && values.new_password.length < 6)
    errors.new_password = "Password should contain atleast 6 characters";
  if (
    values.new_password &&
    values.new_password.length >= 6 &&
    values.new_password &&
    !values.new_password.match("^(?=.*[a-z])(?=.*[0-9])[A-Za-z\\d$@$!%*#?&]{6,123}$")
  )
    errors.password = "Password should have at least 6 characters & 1 numeric character";
  if (!values.re_password) errors.re_password = "Enter Confirm Password";
  if (values.new_password !== values.re_password) {
    errors.re_password = "Password does not match";
  }
  return errors;
};

const ChangePasswordForm = (props) => {
  const { handleChangePassword, handleSubmit, submitting, errors, goBack } = props;

  const submit = (data) => {
    return handleChangePassword(data)
      .then((res) => {
        goBack();
      })
      .catch((err) => {
        const errobj = {
          _error: err.non_field_errors,
          ...err,
        };
        throw new SubmissionError(errobj);
      });
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(submit)} className="grid-form">
        <div className="d-flex justify-content-between align-items-center mt-2 mb-4">
          <span
            style={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#101828",
            }}
          >
            Password
          </span>
          <div className="d-flex gap-15">
            <FPButton size="small" variant="outline" onClick={() => goBack()} label="Cancel" />
            <FPButton label="Update Password" size="small" type="submit" disabled={submitting} />
          </div>
        </div>
        <Divider />
        {errors && errors.non_field_errors
          ? errors.non_field_errors.map((error, index) => (
              <p key={index} className="text-danger">
                {error}
              </p>
            ))
          : null}
        <Row>
          <Col sm="6" className="mt-1">
            <FPLabel>Current Password</FPLabel>
            <Field name="current_password" component={InputField} placeholder="Current Password" type="password" />
          </Col>
        </Row>
        <Row>
          <Col sm="6" className="mt-1">
            <FPLabel>New Password</FPLabel>
            <Field name="new_password" component={InputField} placeholder="New Password" type="password" />
          </Col>
          <Col sm="6" className="mt-1">
            <FPLabel>Confirm Password</FPLabel>
            <Field name="re_password" component={InputField} placeholder="Confirm Password" type="password" />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default reduxForm({
  form: "changePasswordForm",
  validate,
})(ChangePasswordForm);
