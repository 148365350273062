import React from "react";
import { FPToolTip } from "../../../../components/common/new";
import ListingFPFilter from "../../../../components/common/new/FPFilter/Listing/ListingFPFilter";
import { IconButton } from "@material-ui/core";
import { ReactComponent as DownloadIcon } from "../../../../images/svg/Download.svg";

const BulkProductFilter = (props) => {
  const { history, pageSizeCookie } = props;
  const filterList = [
    {
      id: 1,
      label: "Location",
      key: "location",
      type: "radio",
      isSearchable: false,
      // list: filter.date_type ? this.handleDefaultFilter(removeLifetimeFilter(filter?.date_type?.choices), 'date_type') : [],
    },
    {
      id: 3,
      label: "City",
      key: "city",
      type: "radio",
      isSearchable: true,
      // list: filter.city ? this.handleDefaultFilter(filter.city.choices, 'city') : [],
    },
    {
      id: 4,
      label: "State",
      key: "state",
      type: "radio",
      isSearchable: true,
      // list: filter.state ? this.handleDefaultFilter(filter.state.choices, 'state') : [],
    },
    {
      id: 5,
      label: "Country",
      key: "country",
      type: "radio",
      isSearchable: false,
      // list: this.handleDefaultFilter(serviceOptions, 'service_type'),
    },
    {
      id: 5,
      label: "Branch Tag",
      key: "branch_tag",
      type: "radio",
      isSearchable: false,
      // list: this.handleDefaultFilter(serviceOptions, 'service_type'),
    },
    {
      id: 5,
      label: "GMB Loaction Group",
      key: "service_type",
      type: "radio",
      isSearchable: false,
      // list: this.handleDefaultFilter(serviceOptions, 'service_type'),
    },
  ];
  return (
    <>
      <ListingFPFilter
        data={filterList}
        className="mt-3"
        pageSizeCookie={pageSizeCookie}
        history={history}
        actionRender={() => (
          <FPToolTip title={"Download"}>
            <IconButton
              component="span"
              className="circular-button"
              // onClick={() => handleReviewDownloadFlow()}
            >
              <DownloadIcon />
            </IconButton>
          </FPToolTip>
        )}
      />
    </>
  );
};

export default BulkProductFilter;
