import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AttachFilePreview from "./AttachFilePreview";
import FormModal from "../../common/FormModal";

const ChatFileShow = (props) => {
  const { showModal, closeModal, selectAttchFile } = props;
  const [modal, setModal] = useState(false);
  function toggle() {
    setModal(false);
    closeModal(modal);
  }

  useEffect(() => {
    setModal(showModal);
  }, [showModal]);

  const isImage =  selectAttchFile && (selectAttchFile.msg_type === 'image' ||  selectAttchFile.msg_type === 'video');

  return (
    <div className="messenger-assign-modal">
      <FormModal
        isOpen={modal}
        toggle={toggle}
        showDivider={true}
        heading="File preview"
        onClose={toggle}
        fullScreen={
          isImage ? false : true
        }
        width={isImage ? '40%' : '100%'}
        contentClass={isImage && "h-100"}
      >
        <div style={{ textAlign: "center", height: "100%" }}>
          <AttachFilePreview messageData={selectAttchFile} />
        </div>
      </FormModal>
    </div>
  );
};

ChatFileShow.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  selectAttchFile: PropTypes.object.isRequired,
};
export default ChatFileShow;
