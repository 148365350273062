import { sourceImages } from "../images/source";
import axios_instance from "./interseptor";
import queryString from "query-string";
import { alpha } from "@material-ui/core";

//* Action Creator Template
export function createAsyncAction(success, failure) {
  function successAction(data) {
    return { type: success, data };
  }

  function failureAction(data) {
    return { type: failure, data };
  }

  return (filters, url) => {
    return (dispatch) => {
      return axios_instance
        .get(url, { params: { ...filters } })
        .then((res) => {
          dispatch(successAction(res.data));
          return res;
        })
        .catch((err) => {
          dispatch(failureAction(err));
          throw err.response.data;
        });
    };
  };
}

// * handle Sorting
export const handelServerSorting = (sortValue, history) => {
  console.log("hs-va", sortValue);
  const { pathname, search } = window.location;
  let filters = { ...queryString.parse(search), ordering: sortValue };
  console.log("fil", filters);
  history.push({ pathname, search: queryString.stringify(filters) });
};

export const generateColorPalate = (color) => {
  const pattern = [0.2, 0.4, 0.6, 0.8, 1];
  const colorPalate = pattern.map((opcity) => alpha(color, opcity));
  return colorPalate;
};

export const setColorInChart = (index) => {
  const backgroundColors = [
    "#BC8A5F",
    "#B185DB",
    "#00B4D8",
    "#F08080",
    "#B19C5F",
    "#DB85B1",
    "#00D8C4",
    "#B1DB85",
    "#80F0AD",
  ];
  return generateColorPalate(backgroundColors[index] || "#000000");
};
// * replace logo using provider name (Add more provider as per requirement)
export const updateProviderLogo = (providerName) => {
  switch (providerName.toLowerCase()) {
    case "google":
      return sourceImages.google;
    case "zomato":
      return sourceImages.zomato;
    case "tripadvisor":
    case "trip advisor":
      return sourceImages.tripadvisor;
    case "facebook":
      return sourceImages.facebook;
    case "dineout":
      return sourceImages.dineout;
    case "makemytrip":
    case "make my trip":
      return sourceImages.makemytrip;
    case "agoda":
      return sourceImages.agoda;
    case "hotels":
      return sourceImages.hotels;
    case "expedia":
      return sourceImages.expedia;
    case "booking":
      return sourceImages.booking;
    case "goibibo":
      return sourceImages.goibibo;
    default:
      return "";
  }
};

export const truncatedNumber = (number) => {
  const truncatedNumber = Math.floor(number);
  return truncatedNumber;
};

export const formatReviews = (totalReviews) => {
  if (totalReviews >= 1000000) {
    return (totalReviews / 1000000).toFixed(1) + "M";
  } else if (totalReviews >= 1000) {
    return (totalReviews / 1000).toFixed(1) + "k";
  }
  return totalReviews;
};


const currencyCodes = [
  "USD", "EUR", "JPY", "GBP", "AUD", "CAD", "CHF", "CNY", "SEK", "NZD",
  "INR", "BRL", "RUB", "ZAR", "TRY", "MXN", "KRW", "SGD", "HKD"
];

export const getCurrencySymbol = (currencyCode) => {
  try {
    return new Intl.NumberFormat('en', {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    })
      .formatToParts(1)
      .find(part => part.type === 'currency').value;
  } catch (e) {
    // In case the currency code is not valid
    return '';
  }
};
