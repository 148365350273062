import React from 'react';
import { Button } from 'semantic-ui-react';
import FormModal from './FormModal';
import { FPButton } from './new';

const LimitModal = (props) => {
  const {
    isReviewsPage,
    HeaderText,
    isOpen,
    message,
    toggle,
    handleDownloadModal,
  } = props;

  return (
    <FormModal
      isOpen={isOpen}
      toggle={toggle}
      maxWidth='xs'
      showDivider={true}
      dialogActions={
        <React.Fragment>
          {!isReviewsPage && (
            <Button
              onClick={() => {
                toggle(true);
              }}
              labelPosition="right"
              color="teal"
              icon="checkmark"
              content="OK"
            />
          )}
          {isReviewsPage && (
            <div>
              <FPButton
                size="small"
                onClick={() => {
                  handleDownloadModal(true);
                }}
                label="OK"
              />
              
              <FPButton
                onClick={() => {
                  handleDownloadModal(false);
                }}
                size="small"
                variant='outline'
                label="Cancel"
                className='ml-10'
              />
            </div>
          )}
        </React.Fragment>
      }
      heading={
        <React.Fragment>{HeaderText ? HeaderText : 'OOPS!'}</React.Fragment>
      }
    >
      <p className='fp-size-16'>{message && message[0]}</p>
    </FormModal>
  );
};

export default LimitModal;
