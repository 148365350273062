import * as types from '../actionTypes';
import axios_instance from '../../libs/interseptor';
import * as URLS from '../../libs/apiUrls';
import * as commonAction from '../common/commonAction';
import * as utils from '../../libs/utils';

export function registerSuccess(data) {
  return { type: types.REGISTER_SUCCESS, data };
}

export function registerFailure(error) {
  let data = {};
  data.errors = error.response.data;
  return { type: types.REGISTER_FAILURE, data };
}


export const registerAction = (data, marketing_id) => {
  const url = marketing_id ? utils.format(`${URLS.AGENCY_BUSINESS_REGISTER}`,[marketing_id]) : URLS.REGISTER;
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(url, data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(registerSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(registerFailure(err));
          throw err.response.data;
        }

      );
  };
};

export function registerConfirmSuccess(data) {
  return { type: types.REGISTER_CONFIRM_SUCCESS, data };
}

export function registerConfirmFailure(error) {
  return { type: types.REGISTER_CONFIRM_FAILURE, error };
}

export const registerConfirm = (data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.post(URLS.REGISTER_CONFIRM, data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(registerConfirmSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(registerConfirmFailure(err));
          throw err;
        }

      );
  };
};

export function getUserTypeSuccess(data) {
  return { type: types.GET_BUSINESS_TYPE_SUCCESS, data };
}

export function getUserTypeFaliure(error) {
  return { type: types.GET_BUSINESS_TYPE_FAILURE, error };
}

export const getUserType = (data) => {
  return (dispatch) => {
    dispatch( commonAction.showLoader() );
    return axios_instance.get(URLS.BUSINESS_TYPE, data)
      .then(
        (res) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getUserTypeSuccess(res.data));
          return res;
        }
      )
      .catch(
        (err) => {
          dispatch( commonAction.hideLoader() );
          dispatch(getUserTypeFaliure(err));
          throw err;
        }

      );
  };
};
