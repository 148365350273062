import React, { useEffect, useState } from "react";
import ReviewChannelsIcon from "../../images/svg/ReviewChannelsIcon.svg";
import CardWrapper from "../../modules/dashboard/component/CardWrapper";
import { Divider, IconButton, Menu, Skeleton, Typography } from "@mui/material";
import { ReviewSlider } from "../../components/common/new";
import { MenuItem, styled } from "@material-ui/core";
import CheckIcon from '@mui/icons-material/Check';

const settings = ["All", "With Reviews & Ratings"];

const StyledMenuItem = styled(MenuItem)(({ theme, selected }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "5px",
  borderBottom: "1px solid #ddd",
  color: selected ? "#f13a88 !important" : "inherit",
  "&:last-child": {
    borderBottom: "none",
  },
  "&.Mui-selected":{
    background:'transparent',
  }
}));


function ReviewChannels(props) {
  const { data, history, redirectTo, loader } = props;
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState("With Reviews & Ratings");
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    if (!data) return;
    const newFilteredData = Object.entries(data)
      .filter(([_, item]) => item.count !== 0)
      .reduce((acc, [key, item]) => ({ ...acc, [key]: item }), {});
    setFilteredData(newFilteredData);
  }, [data, selectedOptions]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuItemClick = (setting) => {
    setSelectedOptions(setting);
    handleCloseUserMenu();
  };

  return (
    <div className="h-100 review-channels">
      <CardWrapper
        headerTitle={
          <div className="d-flex align-items-center gap-6">
            {loader ? (
              <Skeleton width="50%" height={22} />
            ) : (
              <div className="d-flex justify-content-between w-100">
                <div>Review Channels</div>
                <div>
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <img
                      src={ReviewChannelsIcon}
                      height="25px"
                      width="25px"
                      className="cursor-pointer"
                      alt="print"
                    />
                  </IconButton>
                  <Menu
                    sx={{ mt: "35px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                  >
                    {settings.map((setting, index) => (
                      <React.Fragment key={setting}>
                        <StyledMenuItem
                          onClick={() => handleMenuItemClick(setting)}
                          selected={selectedOptions === setting}
                        >
                          <Typography sx={{ textAlign: "center" }}>{setting}</Typography>
                          {/* {selectedOptions === setting && <CheckIcon fontSize="small" />} */}
                        </StyledMenuItem>
                        {index !== settings.length - 1 && <Divider sx={{ height: "0.1px" }} />}
                      </React.Fragment>
                    ))}

                  </Menu>
                </div>
              </div>
            )}
          </div>
        }
        className="h-100"
        widthElevation={true}
        skeletonLoader={loader}
      >
        <ReviewSlider key={selectedOptions} selectedOptions={selectedOptions} data={selectedOptions === "With Reviews & Ratings" ? filteredData : data} history={history} redirectTo={redirectTo} loader={loader} />
      </CardWrapper>
    </div>
  );
}

export default ReviewChannels;